<template>
  <div class="auditReport">
    <Report :res="res" :state="state" :id="reportId" ref="report" @onSummaryUpdate="onSummaryUpdate" :noPosition="true" :imgVis="imgVis"></Report>
    <div class="footer" v-if="state==1||['20','23'].includes(state)||restart=='restart'">
      <div class="btn" v-if="state==1">
        <el-button type="primary" @click="adopt">车型审核通过</el-button>
        <el-button type="primary" @click="Retransmission" >驳回重传</el-button>
      </div>
      <div class="btn" v-if="['20','23'].includes(state)||restart=='restart'">
          <el-button type="primary" @click="onVisible(0)" v-if="state==='20'||restart=='restart'">暂时不拍</el-button>
          <el-button type="primary" @click="adopt" >审核通过</el-button>
          <el-button type="primary" @click="Retransmission">驳回重传</el-button>
          <el-button type="primary" @click="onVisible(1)" v-if="!restart">终止竞拍</el-button>
        </div>
    </div>
    <el-dialog :title="visibleState?'终止竞拍':'暂时不拍'" :visible.sync="visible" width="500px">
      <el-form :model="form1">
        <el-form-item :label="visibleState?'终止原因':'暂不拍原因'" label-width="100px">
          <el-input v-model="form1.remark" type="textarea" :autosize="{ minRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="onTemporary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const car=new Map();//车辆返回值（车开新数据）
import Report from '@/components/common/report';//检测报告
export default {
  components:{
    Report
  },
  props:{
    pstate:{//与data的state值相同
      type:String,
    },
    pid:{//与data的id值相同
      type:String,
    },
    imgVis:{//是否显示分享二维码
      type:Boolean,
      defatult:false
    }
  },
  data() {
    return {
      form1: {},
      state:'20',//2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
      res:{},//数据返回值
      reportId:'',//检测报告ID
      visible:false,//
      visibleState:0,
      restart:'',//特定值需要重新审核
    }
  },
  created() {
    if(this.$route.query.state||this.pstate) this.state = this.pstate?this.pstate:this.$route.query.state;
    if(this.$route.query.id||this.pid) this.reportId = this.pid?this.pid:this.$route.query.id;
    if(this.$route.query.restart) this.restart=this.$route.query.restart;
    this.getDetails()//获取详情
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
      })
    }
  },
  mounted(){
    window.addEventListener('beforeunload', this.handleWindowClose)
  },
  methods: {
    handleWindowClose(event) {
      // 在这里处理窗口关闭逻辑
      if(['20','23'].includes(this.state)||this.restart=='restart'){
        let routerFlag=true;
        for(let i=0;i<this.$store.state.tagsView.visitedViews.length;i++){
          let item=this.$store.state.tagsView.visitedViews[i]
          if(item.path==='/testReviewer/auditReport'){
            routerFlag=false;
          }
        }
        if(routerFlag){
          this.postCheckAuditCourseStatus()
          window.removeEventListener('beforeunload', this.handleWindowClose)
          return
        }
    
        const message = '你确定要离开吗？';
        if(event){
          event.returnValue = message; // 标准的跨浏览器兼容性处理
        }
        
        return message;
      }
    },
    postCheckAuditCourseStatus(){
      let query={
          id:this.reportId,
          status:2,//检查审核人过程： 1-准备进行审核 2-退出审核页面
        }
      this.$api.postCheckAuditCourseStatus(this.reportId,query).then(res=>{
          if(res.code==0){
          }
        })
    },
    getDetails(){
      this.$api.getCheckReport(this.reportId).then(res =>{
        if(res.code==0){
          this.res=res.data;
          let newArr=JSON.parse(JSON.stringify(this.res.details))
          for(let i=0,len=newArr.length;i<len;i++){
            car.set(newArr[i].itemCode,newArr[i])
          }
        }
      })
    },
    // getCar(row,type){
    //   if(type=='obj'){
    //     return car.get(row)
    //   }else if(type=='color'){
    //     return car.get(row)?car.get(row).color:''
    //   }else{
    //     return car.get(row)?car.get(row).checkResult:'--'
    //   }
    // },
    adopt() {
      this.$confirm('您确认此车辆报告进行审核通过吗?', '审核通过', { confirmButtonText: '确定', cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        switch(this.state){
          case '20':
          case '23':
          case '25':
            this.postAuditReport(1)
            break;
          case '1':
            this.postAuditCarType(1)
            break;
        }
      }).catch(() => {});
    },
    Retransmission() {
      this.$confirm('您确认此车辆报告进行驳回重传吗?', '驳回重传', { confirmButtonText: '确定', cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        switch(this.state){
          case '20':
          case '23':
          case '25':
            this.postAuditReport(2)
            break;
          case '1':
            this.postAuditCarType(2)
            break;
        }
      }).catch(() => {});
    },
    onVisible(state){
      this.visible=!this.visible;
      this.visibleState=state;
    },
    onTemporary(){//暂时不拍
    //报告状态 0-待审核,1-审核通过,2-驳回重传,3-暂时不拍,5-终止竞拍
      switch(this.visibleState){
          case 0:
            this.postAuditReport(3)
            break;
          case 1:
            this.postAuditReport(5)
            break;
        }
    },
    postAuditCarType(auditStatus){//车型审核
      //车型审核状态 1-审核通过 2-审核驳回
      this.$api.postAuditCarType(this.reportId,auditStatus).then(res =>{
        if(res.code==0){
          // this.getDetails()//获取详情
          this.$message.success(res.message)
          this.bus.$emit("closeSelected");
          if(auditStatus==1){
            this.$router.push({
              path:'/testReviewer/auditReport',//审核报告
              query:{
                state:20,
                id:this.reportId
              }
            })
          }else{
            this.$router.push({
              path:'/testReviewer/VehicleType'//车型审核
            })
          }
        }
      })
    },
    onSummaryUpdate(query){//保存修改(修改总结)
      switch(this.state){
          case '20':
            this.updateResult(query)
            break;
          case '1':
            this.updateResult(query)
            break;
        }
    },
    updateResult(checkResult){//修改检测报告总结
      let query={
        checkResult:checkResult
      }
      this.$api.updateResult(this.reportId,query).then(res =>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getDetails()
        }
      })
    },
    postAuditReport(auditStatus){//车辆报告审核
      // let checkResult=this.$refs.report.checkResult?this.$refs.report.checkResult:this.$refs.report.summaryText;
      let checkResult=this.$refs.report.checkResult;
      let query={
        "auditStatus": auditStatus,//报告状态 0-待审核,1-审核通过,2-驳回重传,3-暂时不拍,5-终止竞拍
        // "remark": "string",//暂时不拍 终止竞拍原因
        "checkResult":checkResult,//修改后的检测总结
        "specialResult": String(this.$refs.report.specialResult),//特殊车况 事故车,改装车,车况差
        "ratingResult": this.$refs.report.ratingResult,//车辆评级 C A R S I N G O K
      }
      if(this.$refs.report.ratingResult&&String(this.$refs.report.specialResult)){
        query.specialResult=String([this.$refs.report.ratingResult,String(this.$refs.report.specialResult)])
      }
      switch(auditStatus){
        // case 1:
        //   //  query.specialResult='车况差'
        //   query.specialResult= String(this.$refs.report.specialResult);//特殊车况 事故车,改装车,车况差
        //   query.ratingResult= this.$refs.report.ratingResult;//车辆评级 C A R S I N G O K
        //   query.checkResult= this.$refs.report.checkResult;//修改后的检测总结
        //   break;
        case 5:
        case 3:
          query.remark= this.form1.remark;//暂时不拍 终止竞拍原因
          // query.checkResult= "string",//修改后的检测总结
          
          break;
        
      }
      // console.log(query,'----222----')
      // if(!query.specialResult&&auditStatus==1){
      //   this.$message('请选择车辆评级！')
        // return
      // }
      if(!query.ratingResult&&auditStatus==1){
        this.$message('请选择车辆评级结果！')
        return
      }
      this.$api.postAuditReport(this.reportId,query).then(res =>{
        if(res.code==0){
          // this.getDetails()//获取详情
          this.$message.success(res.message)
          this.bus.$emit("closeSelected");
          this.$router.push({
            path:'/testReviewer/vehicleAudit'//车辆审核
          })
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.auditReport{
   padding: 0 40px 140px;
}
</style>