<template>
  <div class="pageContainerConter">
    <el-row :gutter="24" class="clear">
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>车型</span>
        <el-input v-model="forms.customerName" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>商户姓名</span>
        <el-input v-model="forms.customerIdNo" size="medium"></el-input>
      </el-col>
      <el-button type="primary" @click="init(1)" size="medium" class="fr mr10" plain>查询</el-button>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="makerName" label="车型" align="center">
        <template slot-scope="scope">{{scope.row.makerName}}&nbsp;&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center"></el-table-column>
      <el-table-column prop="merchantDeadline" label="商户办证截至日期" align="center"></el-table-column>
      <el-table-column prop="certCompanyName" label="办证公司" align="center"></el-table-column>
      <el-table-column prop="status" label="办证状态" align="center">
        <template slot-scope="scope">{{scope.row.status===1?'待办证':scope.row.status===2?'办证中':scope.row.status===3?'结算中':scope.row.status===4?'交易完成':scope.row.status}}</template>
      </el-table-column>
      <el-table-column prop="signTime" label="商户合同签订时间" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="渠道拓展专员" align="center"></el-table-column>
      <el-table-column prop="shortName" label="4S店简称" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="onconfirm(scope.row)" size="mini">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
   
  </div>
</template>
<script>
export default {
  data() {
    return {
      formState: 0,
      forms: {
        date:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(curPage) {
      if(curPage){
        this.currentPage=curPage?curPage:this.currentPage
      }
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        contractSignDateBegin: this.forms.date?this.forms.date[0]:'', //查询客户合同签订日期的开始时间
        contractSignDateEnd: this.forms.date?this.forms.date[1]:'', //查询客户合同签订日期的结束时间
        customerName: this.forms.customerName, //客户名称
        customerIdNo: this.forms.customerIdNo, //客户身份证号码
        companyName: this.forms.companyName, //公司名称
        businessLicense: this.forms.businessLicense, //公司营业执照
      };
      switch (this.formState) {
        case 0:
          query.statuses=[1,2,3,4] //不传则查全部状态
          break;
        default:
          query.statuses = [this.formState]; //
      }
      this.$api.postProgressPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onconfirm(row) {
      let aurl = "/accreditationCommissioner/certificateQueryDetails";
      this.$router.push({
        path: aurl,
        query: {
          id:row.carId,
          state:'ac'
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainerConter {
  padding:20px 40px;
  .searchRowBox {
    margin: 20px 0;
  }
  .form_title_spanInbox {
    display: inline-block;
  }
  .formmodal_but {
    margin-left: 35px;
  }
  .lineDotted {
    display: inline-block;
    width: 600px;
    height: 1px;
    background-color: #cccccc;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    margin-left: 15px;
  }
  .w110 {
    width: 110px;
  }
  .dateline {
    width: 32px;
    height: 1px;
    background-color: #333333;
  }
  .w180 {
    width: 180px;
  }
}
</style>
