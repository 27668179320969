<template>
  <div class="queryCustomer">
    <el-row :gutter="20">
      <el-col :span="6" class="flex flex_align_center flex_between w3" >
        <span>车牌号: </span>
        <el-input clearable v-model="params.carNo" placeholder="请输入" ></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w2" >
        <span>VIN码: </span>
        <el-input clearable v-model="params.vin" placeholder="请输入" ></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w6">
        <span>订单创建时间: </span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20" >
      <el-table-column prop="orderId" label="订单号" align="center" />
      <el-table-column prop="vin" label="VIN码" align="center" >
        <template slot-scope="scope">{{$$.hideVinCode(scope.row.vin)}}</template>
      </el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center" />
      <el-table-column prop="carMaker" label="车型" align="center">
        <template slot-scope="scope">{{scope.row.carMaker}}{{scope.row.carSeries}}</template>
      </el-table-column>
      <el-table-column prop="createDate" label="订单创建时间" align="center"/>
      <el-table-column prop="createBy" label="创建人" align="center"/>
      <el-table-column prop="message" label="结果" align="center"/>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="onRouter(scope.row)" v-if="scope.row.reportUrl">查看车辆出险记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'queryCustomer',
  data() {
    return {
      params: {
        vin: '',
        date: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getData()
    },
    getData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        vin: this.params.vin,
        carNo:this.params.carNo,
        begDate: this.params.date?this.params.date[0]:'',
        endDate: this.params.date?this.params.date[1]:'',
      }
      
      let query=this.$$.delete_null_properties(params)
      this.$api.getMaintenanceReportPage(query).then(res => {
        if(res.code == 0){
          this.page_total = res.data.total;
          this.querylist = res.data.records;
        }else{
          this.page_total = 0;
          this.querylist = [];
        }
      })
    },
    search() {
      this.page_index = 1;
      this.getData();
    },
    onRouter(row){
      window.open(row.reportUrl,'_blank')
    },
  },
}
</script>

<style scoped lang="scss">
.queryCustomer{
  padding: 30px 50px;
}
</style>