<template>
  <div class="updatePsd">
    <div class="ckxTitle">密码修改</div>
    <updatePass></updatePass>
  </div>
</template>

<script>
import updatePass from '@/components/user/updatePass.vue'
export default {
  components:{
    updatePass
  },
}
</script>

<style scoped lang="scss">
.updatePsd{
  padding: 40px;
  .ckxTitle{font-size: 20px;}
}
</style>