<template>
  <div class="currencyConterBox">
    <el-row :gutter="24" class="">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>商户名称</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>收款状态</span>
        <el-select v-model="forms.financeGatheringStatuses" size="medium" clearable>
          <el-option label="待收款" :value="91"></el-option>
          <el-option label="收款成功" :value="93"></el-option>
          <el-option label="收款失败" :value="94"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5">
        <span>收款完成时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          size="medium"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd" clearable
        >
        </el-date-picker>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
        <el-button type="primary" @click="init(1)" size="medium" plain
          >查询</el-button
        >
        <el-button type="primary" @click="onExport" size="medium" plain
          >导出报表</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column
        prop="merchantNo"
        label="商户编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantName"
        label="商户名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="金额"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financeGatheringStatus"
        label="收款状态"
        align="center" :formatter="formatterState"
      ></el-table-column>
      <el-table-column
        prop="applyTime"
        label="申请时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="applyName"
        label="申请人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="receiverName"
        label="收款人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financeGatheringTime"
        label="收款完成时间"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button
            type="primary" 
            @click="operation(scope.row)"
            size="mini"
            >{{scope.row.financeGatheringStatus==91?'收款':'查看'}}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabClassify: "first",
      querylist: [], //
      forms: {
        merchantName: "",
        financeGatheringStatuses: "",
        date: "",
      },
      selectValue: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  created() {
    if(this.$route.params.state) this.forms.financeGatheringStatuses=Number(this.$route.params.state);

    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        merchantName:this.forms.merchantName,
        financeGatheringStatuses: this.forms.financeGatheringStatuses?[this.forms.financeGatheringStatuses]:'',
        financeGatheringBeginTime:this.forms.date?`${this.forms.date[0]} 00:00:00`:'',
        financeGatheringEndTime:this.forms.date?`${this.forms.date[1]} 23:59:59`:'',
      };
      this.$api.postFinanceMerchantGathering(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize=e
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage=e;
      this.init();
    },
    onExport() {
      let query = {
        financeGatheringStatuses: this.forms.financeGatheringStatuses?[this.forms.financeGatheringStatuses]:'',
        financeGatheringBeginTime:this.forms.date?`${this.forms.date[0]} 00:00:00`:'',
        financeGatheringEndTime:this.forms.date?`${this.forms.date[1]} 23:59:59`:'',
      };
      this.$api.postFinanceMerchantExport(this.$$.delete_null_properties(query)).then(res=>{
        this.$$.postExcle(res)
      })
    },
    operation(row) {
      this.$router.push({
        path: "/finance/merchant/marginManagementDetails",
        query: {
          id: row.id,
        },
      });
    },
    formatterState(e){
      let num;
      switch(e.financeGatheringStatus){
        case 91:
          num=0;
          break;
        case 92:
          num=1;
          break;
        case 93:
          num=2;
          break;
        case 94:
          num=3;
          break;
      }
      return ['待收款','收款中','收款成功','收款失败'][num]
    }
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
  .rowbox {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    .line {
      width: 15px;
      height: 1px;
      background-color: #999999;
      margin: 0 5px;
    }
  }
}
</style>