<template>
  <div class="department">
    <el-button type="primary" size="mini" plain @click="add">新增</el-button>
    <el-table class="mt20" :data="tableData" border row-key="id" default-expand-all :tree-props="{children: 'children'}">
      <el-table-column prop="name" label="部门名称"> </el-table-column>
      <el-table-column prop="upLetter" label="排序" width="100px" align="center"> </el-table-column>
      <el-table-column label="状态" width="100px" align="center">
        <template slot-scope="scope">
          <el-switch :value="!scope.row.isEnabled" @change="changeStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="创建日期" width="220px" align="center"> </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="edit(scope.row)" size="mini" plain>编辑</el-button>
          <el-button type="danger" @click="delDepartment(scope.row.id)" size="mini" plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增编辑部门 -->
    <el-dialog :title="departmentTitle" :visible.sync="dialogFormVisible" center>
      <el-form :model="form" :rules="formRules" label-width="120px" ref="form">
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门首字母" prop="upLetter">
          <el-input v-model="form.upLetter" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="顶级部门">
          <el-radio-group v-model="form.isTop" style="width: 140px">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否是门店">
          <el-radio-group v-model="form.isShop">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">不是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="部门状态">
          <el-radio-group v-model="form.isEnabled">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.isTop === 0" style="margin-bottom: 0;" label="上级部门" prop="pid">
          <treeselect v-model="form.pid" :options="tableData" style="width: 370px;" placeholder="选择上级部门"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDepartment">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect },
  data() {
    return {
      tableData: [],
      departmentTitle: '新增部门',
      form: {
        name: '',
        upLetter: '',
        isEnabled: 0,
        isTop: 1,
        isShop:1,
        pid: null
      },
      formRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur'}],
        upLetter: [{ required: true, message: '请输入名称', trigger: 'blur'}],
      },
      dialogFormVisible: false,
      handleType: '新增',
      currentRow: [],
    }
  },
  created() {
    this.departmentQuery();
  },
  methods: {
    departmentQuery() {
      this.$api.postDepartmentList({}).then(res => {
        if(res.code == 0){
          this.tableData = this.initData(res.data);

        }
      })
    },
    changeStatus(row) {
      let str = '';
      if(!row.isEnabled){
        str = '此操作将停用部门,是否继续?'
      }else{
        str = '此操作将启用部门,是否继续?'
      }
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(!row.isEnabled){
          row.isEnabled = 1
        }else{
          row.isEnabled = 0
        }//0启用，1禁用
        this.$api.postDepartmentIsEnable(row.id,row.isEnabled).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.departmentQuery();
          }
        })
      }).catch(() => {       
      });
    },
    initData(arr) {
      arr.sort((a,b)=>{
        return a.id - b.id
      })
      arr.forEach(item => {
        item.label = item.name;
      })
      let newArr = this.$$.toTree(arr)
      console.log(newArr);
      return newArr
    },
    handleDepartment() {
      if(this.type === 'add'){
        let params = {
          name: this.form.name,
          parentId: this.form.pid?this.form.pid:0,
          upLetter: this.form.upLetter,
          isEnabled: this.form.isEnabled,
          isShop:this.form.isShop
        };
        this.$api.departmentAdd(params).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.departmentQuery();
            this.dialogFormVisible=!this.dialogFormVisible;
          }
        })
      }else if(this.type === 'edit'){
        this.currentRow.name = this.form.name;
        this.currentRow.upLetter = this.form.upLetter;
        this.currentRow.isEnabled = this.form.isEnabled;
        this.currentRow.parentId = this.form.pid;
        this.currentRow.isShop = this.form.isShop;
        this.$api.departmentUpdate(this.currentRow.id,this.currentRow).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.dialogFormVisible = false;
            this.departmentQuery();
          }
        })
      }
    },
    edit(row) {
      this.type = 'edit'
      this.departmentTitle = '编辑部门';
      this.currentRow = row;
      this.form.name = row.name;
      this.form.upLetter = row.upLetter;
      this.form.isEnabled = row.isEnabled;
      this.form.isShop=row.isShop;
      if(row.parentId === 0){
        this.form.isTop = 1
      }else{
        this.form.isTop = 0;
        this.form.pid = row.parentId;
      }
      this.dialogFormVisible = true;
    },
    add() {
      this.form = {
        name: '',
        upLetter: '',
        isEnabled: 0,
        isTop: 1,
        isShop:1,
        pid: null
      }
      this.type = 'add'
      this.departmentTitle = '新增部门';
      this.dialogFormVisible = true;
    },
    delDepartment(id) {
      this.$confirm('此操作将删除部门,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.departmentDelete(id).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.departmentQuery();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });          
      });
    }
  }
}
</script>

<style scoped lang="scss">
.department{
  padding: 20px;
}
</style>