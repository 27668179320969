<template>
  <div class="testOrder">
    <el-row :gutter="20">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>联系电话</span>
        <el-input v-model="params.tel" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w4">
        <span>创建时间</span>
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']"  size="medium"  value-format="yyyy-MM-dd"> </el-date-picker>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="medium" plain @click="init(1)">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
      <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="channel" label="渠道来源" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column prop="nextContactTime" label="下次跟进时间" align="center"></el-table-column>
      <el-table-column prop="createDate" label="创建时间" align="center"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onRouter(scope.row)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        tel: '',
        carNo: '',
        createTime: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page){
      this.page_index=page?page:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        carNo:this.params.carNo,
        phone:this.params.tel,
        createStartTime:this.params.createTime?`${this.params.createTime[0]} 00:00:00`:'',
        createEndTime:this.params.createTime?`${this.params.createTime[1]} 23:59:59`:'',
      }
      this.$api.postCheckBookQueryFollowingUp(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist = res.data.records;
          this.page_total = res.data.total;
        }else{
          this.querylist = []
          this.page_total=0;
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onRouter(row){
      let pathData=this.$router.resolve({
          path: '/salesManager/customer',
          query: {
            followUpInfoId: row.followUpId
          }
      })
      window.open(pathData.href,'_blank')

      // this.$router.push({
      //   path: '/salesManager/customer',
      //   query: {
      //     followUpInfoId: row.followUpId
      //   }
      // })
    }
  }
}
</script>

<style scoped lang="scss">
.testOrder{
  padding: 20px 40px;
}
</style>