<template>
<!-- 客户载入 -->
<div class="customer" :style="`padding-bottom:${res.validity===1?'100px':''}`">
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="110px" class="customerForm">
    <el-row :gutter="20" style="display:flex;">
      <el-col :span="12">
        <div class="leftInfo">
          <div class="formItem">
            <div class="line">
              <div class="ckxTitle">客户基本信息</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户编号:">
                    <p>{{ruleForm.customerNo}}</p>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="创建时间:">
                    <p>{{ruleForm.createDate}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="24" :lg="24">
                  <el-form-item label="渠道来源:" prop="channel">
                    <div class="flex">
                      <el-cascader v-model="ruleForm.channel" :props="channeProp" :disabled="res.isCopy===0&&res.channel?true:false" :options="channellist" clearable size="medium" class="w300"></el-cascader>
                      <el-button size="mini" plain type="primary" @click="visibleChannel=true"  class="ml10">修改渠道</el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="400帮卖点选时间:" label-width="160px">
                    <p>{{ruleForm.firstComeTime}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户归属:">
                    <p>{{ruleForm.onArea}}</p>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户名称:" prop="customerName" label-width="120px">
                    <el-input v-model="ruleForm.customerName" size="medium"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="主要联系电话:" label-width="130px">
                    <p>{{ruleForm.mainPhone}}</p>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="次要联系电话:" label-width="130px">
                    <p>{{ruleForm.otherPhone}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
             
              <div class="flex flex_center" style="margin-bottom:10px">
                <el-button size="mini" plain type="primary" @click="onCall(1)" :disabled="res.validity===1?false:true" >呼叫</el-button>
                <el-button size="mini" plain type="primary" @click="onCall(2)" :disabled="res.validity===1?false:true" >保密呼</el-button>
                <!-- <el-button size="mini" plain type="primary">首导0</el-button> -->
                <el-button size="mini" plain type="primary" @click="onCall(3)" :disabled="res.validity===1?false:true" >主次互换</el-button>
                <el-button size="mini" plain type="primary" @click="onCall(4)" :disabled="res.validity===1?false:true" >修改次要联系电话</el-button>
              </div>
            </div>
          </div>
          <div class="formItem">
            <div class="line">
              <div class="ckxTitle">经营范围</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="车牌号码:" prop="carNo" label-width="100px">
                    <el-input v-model="ruleForm.carNo" class="numberPlate" disabled></el-input>
                    <!-- :disabled="res.validity===1&&checkStatus!=3?false:true" -->
                    <el-button plain size="mini" type="primary" @click="getCarId" >修改车牌</el-button>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="初登年份:">
                    <el-date-picker v-model="ruleForm.registrationYear" type="year" :disabled="checkStatus==3?true:false"  placeholder="选择年" size="medium" value-format="yyyy"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="品牌车型:">
                    <el-cascader
                    v-model="ruleForm.carModel" size="medium"
                    placeholder="请选择" @visible-change="getBaseCarBrandList"
                    :options="optionsCar"
                    :props="propCar" :disabled="checkStatus==3?true:false"
                    filterable></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="是否抵押贷款:" label-width="125px">
                    <el-radio-group v-model="ruleForm.isMortgage" >
                      <el-radio :label="0">否</el-radio>
                      <el-radio :label="1">是</el-radio>
                      <!-- <el-radio :label="-1">未识别</el-radio> -->
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="10" :lg="24">
                  <el-form-item label="是否黄牛:">
                    <el-radio-group v-model="ruleForm.isHn">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <!-- <el-col :xl="14" :lg="24">
                  <el-form-item label="推荐新能源：" label-width="120px">
                    <el-radio-group v-model="ruleForm.recommendNewEnergy" :disabled="res.recommendNewEnergy===2?true:false" :class="!ruleForm.recommendNewEnergy?'tipsColor':''">
                      <el-radio :label="1">待跟进</el-radio>
                      <el-radio :label="2">推荐成功</el-radio>
                      <el-radio :label="3">推荐失败</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </div>
          </div>
          <div class="formItem">
            <div class="ckxTitle">业务信息</div>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="24">
                <el-form-item label="客户类型:" label-width="110px" prop="customerType">
                  <el-select v-model="ruleForm.customerType" size="medium" placeholder="请选择客户类型" :disabled="customerType===0?true:false">
                    <el-option v-for="item in customerTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="24">
                <el-form-item label="下次联系时间:" label-width="140px" prop="nextContactTime">
                  <el-date-picker v-model="ruleForm.nextContactTime" type="datetime" placeholder="选择日期" size="medium" class="w250" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="24">
                <el-form-item label="服务类型:" prop="serviceType" label-width="110px">
                  <el-cascader clearable v-model="ruleForm.serviceType" :options="serviceOptions" :props="serviceProps" size="medium" ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="24">
                <el-form-item label="门店归属:">
                  <p>{{ruleForm.dealShop}}</p>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="12">
                <el-form-item label="成交归属:">
                  <p>{{ruleForm.assignTradeUserName}}</p>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="12">
                <el-form-item label="创建人:">
                  <p>{{ruleForm.createUserName}}</p>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="12">
                <el-form-item label="当前跟进人:">
                  <div class="flex">
                    <span>{{ruleForm.followUpUserName}}</span>
                    <Remind class="ml20" v-if="ruleForm.followUpUserName" :followUpInfoId="followUpInfoId" :disabled="res.validity===1?false:true"  :userId='ruleForm.followUpUserId' :plateNumber="ruleForm.carNo"></Remind>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="pdaBox" v-if="pdaFlag">
              <div class="ckxTitle">PDA问卷</div>
              <el-form-item label="1、其他渠道是否了解过价格" label-width="220px">
                  <el-radio-group v-model="pdaQuestion.knowPrice" :disabled="pdaDisabled">
                    <el-radio label="无"></el-radio>
                    <el-radio label="有">有<span class="ml10">渠道名称<el-input v-model="pdaQuestion.channelName" class="inputText w100"></el-input>价格<el-input v-model="pdaQuestion.price" class="inputText w100"></el-input>万</span></el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item label="2、新车订购情况" label-width="220px">
                  <el-radio-group v-model="pdaQuestion.newCar" :disabled="pdaDisabled">
                    <el-radio label="未订购"></el-radio>
                    <el-radio label="已预定">已预定<el-input v-model="pdaQuestion.newCarDay" class="inputText w100"></el-input>天可提车</el-radio>
                    <el-radio label="已提车"></el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item label="3、检测时是否是车主本人" label-width="220px">
                  <el-radio-group v-model="pdaQuestion.owner" :disabled="pdaDisabled">
                    <el-radio label="是"></el-radio>
                    <el-radio label="否"></el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item label="4、成交最方便的门店" label-width="220px">
                  <span class="borderMin inline-block">{{pdaQuestion.dealShop}}</span>
              </el-form-item>
              <el-form-item label="5、其他需求：" label-width="220px">
                  <!-- <span class="borderMin inline-block">{{pdaQuestion.remark}}</span> -->
                  <el-input v-model="pdaQuestion.remark" class="inputText"></el-input>
              </el-form-item>
            </div>
          </div>
          <!-- 预约状态（暂时隐藏） -->
          <div class="aStatus" v-show="res.validity===0">
            <div class="div1"><div class="div2"></div></div>
            <div class="div3">已放弃</div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="rightInfo">
          <Record 
          :customerDealBookList="customerDealBookList" 
          :priceNoteList="priceNoteList" 
          :serviceNoteList="serviceNoteList" :customerCheckBookList="customerCheckBookList" :relatedCallList="relatedCallList" 
          :res="res" 
          :resReport="resReport"
          :recordList="recordList"
          @cancelDeal="cancelDeal" @init="init"></Record>
          <div class="ckxTitle serviceRecord">记录类型</div>
          <el-form-item label="记录类型:" label-width="100px">
            <el-cascader clearable ref="service"
            @change="changeService"
            v-model="ruleForm.serviceTitle" size="medium" :options="recordOptions" :props="recordProps"
            class="recordTypeWidth"></el-cascader>
          </el-form-item>
          <el-form-item label="价格满意度:" label-width="110px" v-show="serviceFlag">
            <el-select v-model="ruleForm.priceSatisfaction" placeholder="请选择价格满意度" size="medium">
              <el-option v-for="item in priceSatisfaction" :key="item.value" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否继续跟进:" label-width="130px" v-show="serviceFlagno">
            <el-radio-group v-model="ruleForm.isFollowUp">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="记录内容:"  class="formContent" label-width="0">
            <el-input type="textarea" v-model="ruleForm.content" size="medium" :rows="4"></el-input>
          </el-form-item>
          <div class="flex flex_between mb10" v-if="res.phase>4">
            <span>销售预案:</span>
            <el-button type="primary" size="mini" plain @click="onPlan">历史预案</el-button>
          </div>
          <el-form-item label="" class="formContent" label-width="0" v-if="ruleForm.salePlan">
            <el-input type="textarea" v-model="ruleForm.salePlan" size="medium" :rows="4" readonly></el-input>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
  <el-dialog title="销售预案" :visible.sync="planVisible">
    <template v-if="salesPlanList.length>0">
      <div class="planItem" v-for="item in salesPlanList" :key="item.id">
        {{item.plan}}
        <span class="planDate">{{item.addDate}}</span>
      </div>
    </template>
    <div v-else class="centerFont">暂无数据</div>

  </el-dialog>
  <div class="footer" v-if="res.validity===1">
    <div class="btn">
      <el-button type="primary" @click="doSubmit()" >保存</el-button>
      <el-button type="primary" @click="onPrebook(1)" v-if="res.checkBookStatus!=1||res.phase>2">检测预约</el-button>
      <el-button type="primary" @click="cancelDeal(1)" v-if="res.checkBookStatus==1&&res.phase<3">取消检测</el-button>
      <el-button type="primary" @click="$router.push({path: '/salesManager/yuyuejiance',query:{followUpInfoId:res.followUpInfoId}})" v-if="res.checkBookStatus==1&&res.phase<3">检测改约</el-button>
      <el-button type="primary" @click="onRouter(2)" v-if="res.checkBookStatus==1&&res.isShop==0||res.checkBookStatus!=1">外勤检测</el-button>
       <el-button type="primary" @click="$router.push({path: '/salesManager/compensate',query:{customerId:res.id,carNo:res.carNo,carType:`${res.brand}/${res.series}`}})" v-if="res.phase>4">客户赔偿</el-button>

       <el-button type="primary" @click="onPrebook(2)" v-if="res.phase<=6&&[2].includes(res.auctionStatus)">寄售</el-button>

      <!-- <el-button type="primary" @click="onTermination" v-if="[3,4].includes(res.phase)&&[0,1].includes(res.auctionStatus)">终止竞拍</el-button> -->
      <el-button type="primary" @click="onDelay" v-if="[3,4].includes(res.phase)&&[0,1,2].includes(res.auctionStatus)">延期竞拍</el-button>
      <el-button type="primary" @click="onRouterMakePlan" v-if="res.phase==5">{{res.salePlanId?'查看预案':'制作预案'}}</el-button>
      <el-button type="primary" @click="$router.push({path: '/salesManager/chengjiaoyuyue',query:{dealBookId:res.dealBookId}})" v-if="res.phase>4&&res.dealBookStatus!=4">成交预约</el-button>
      <el-button type="primary" @click="cancelDeal(2)" v-if="res.phase>4&&res.dealBookStatus==4">取消成交</el-button>
      <el-button type="primary" @click="$router.push({path: '/salesManager/chengjiaoyuyue',query:{dealBookId:res.dealBookId}})" v-if="res.phase>4&&res.dealBookStatus==4">成交改约</el-button>
      <el-button type="primary" @click="addMoney(res.intentionMoneyStatus)" v-if="[1,2].includes(res.intentionMoneyStatus)">{{res.intentionMoneyStatus==1?'新建意向金':'查看意向金'}}</el-button>
      <el-button type="primary" @click="giveUpCustomer" >放弃</el-button>
      <el-button type="primary" @click="onSms" >发送短信</el-button>
      <el-popover placement="top" v-model="visible" style="margin-left:20px;margin-right:20px;">
        <div style="margin: 0; display:flex;">
          <el-button size="mini" type="primary" @click="carBrand(1)">同车不同牌</el-button>
          <el-button type="primary" size="mini" @click="carBrand(2)">同牌不同车</el-button>
          <el-button type="primary" size="mini" @click="copyBuild">复制新建</el-button>
        </div>
        <el-button slot="reference" type="primary">一人多车</el-button>
      </el-popover>
      <!-- <el-button type="primary" @click="doSubmit" >放弃</el-button> -->
    </div>
  </div>

  <el-dialog
  :title="AuctionTitle"
  :visible.sync="AuctionVisible"
  width="500px">
  <el-form ref="form" :model="auctModal" label-width="130px" size="medium">
    <el-form-item label="延期竞拍日期:" v-show="AuctionTitle=='延期竞拍'">
      <el-date-picker  v-model="auctModal.extensionAuctionTime" type="date" placeholder="选择日期" :picker-options="pickerOptions"  value-format="yyyy-MM-dd" class="w300"> </el-date-picker>
    </el-form-item>
    <el-form-item label="延期原因:" v-show="AuctionTitle=='延期竞拍'">
      <el-input v-model="auctModal.checkReportRemark" type="textarea" class="w300"></el-input>
    </el-form-item>
    <el-form-item label="终止原因:" v-show="AuctionTitle=='终止竞拍'">
      <el-input v-model="auctModal.checkReportRemark" type="textarea" class="w300"></el-input>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="onRevoke" v-show="res.extensionAuctionTime">撤销</el-button>
    <el-button type="primary" @click="AuctionSubmit">确 定</el-button>
  </span>
</el-dialog>

<el-dialog
    :title="statePhone==1?'修改次要电话':'导入其它客户主号码'"
    :visible.sync="dialogVisiblePhone">
    <div class="flex flex_align_center">
      <span>{{statePhone==1?'次要联系电话：':'客户编号：'}}</span>
      <el-input v-model="formPhone.phone" size="medium" class="w200" v-show="statePhone==1"></el-input>
      <el-input v-model="formPhone.customerNo" size="medium" class="w200" v-show="statePhone==2"></el-input>
      <span class="modelPhoneCur" v-show="statePhone==1" @click="statePhone=2">导入其他客户主联系号码</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitPhone">{{statePhone==1?'提交':'导入'}}</el-button>
      <el-button @click="dialogVisiblePhone = false">取 消</el-button>
    </span>
  </el-dialog>
  

  <el-dialog
    title="保密呼"
    :visible.sync="dialogVisibleSecrecy">
    <el-form ref="formSec" :model="formSec"  :label-position="'left'" :rules="formSecRules">
      <el-form-item label="选择呼叫号码:" prop='phoneType'>
        <el-radio-group v-model="formSec.phoneType">
          <el-radio :label="0" v-if="ruleForm.mainPhone">主要联系电话</el-radio>
          <el-radio :label="1" v-if="ruleForm.otherPhone">次要联系电话</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择设备:" prop='callerNum'>
        <el-radio-group v-model="formSec.callerNum">
          <el-radio v-for="item in fixedPhonelist" :key="item.phone" :label="item">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitMM">确定</el-button>
      <el-button @click="dialogVisibleSecrecy = false">取 消</el-button>
    </span>
  </el-dialog>

  <el-dialog
    :title="carState===1?'请输入车牌号':'同车不同牌'"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" v-if="carState===1">原车牌号码：{{ruleForm.carNo}}</p>
    <div class="flex flex_align_center">
      <p>新车牌号码：</p>
      <el-input v-model="formCar" class="w200" placeholder="请输入车牌号"></el-input>
    </div>
    
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>

  <el-dialog
    title="温馨提示："
    :visible.sync="visibleCarCode" width="380px">
    <p class="tipsColor mb10">车辆已检测成功，需验证通过才可以改车牌！</p>
    <el-input v-model="formCarCode" class="w300" placeholder="请输入验证码"></el-input>
    
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCarCode">确定</el-button>
      <el-button @click="visibleCarCode = false">取 消</el-button>
    </span>
  </el-dialog>

  <!--修改渠道-->
  <el-dialog
    title="修改渠道"
    :visible.sync="visibleChannel" width="380px">
    <el-cascader v-model="channelArr" :props="channeProp" :options="channellist" clearable size="medium" filterable></el-cascader>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onChannelSubmit">确定</el-button>
      <el-button @click="visibleChannel = false">取 消</el-button>
    </span>
  </el-dialog>
  
</div>
</template>

<script>
import Record from '@/components/common/cServiceRecord.vue'
import Remind from '@/components/common/remind.vue'
export default {
  components: {
    Record,Remind
  },
  data() {
    const validateOrg = (rule, value, callback) => {
      if (this.ruleForm.serviceType.length === 0) {
        callback(new Error("请选择！"));
      } else {
        callback();
      }
    };
    return {
      dialogVisibleCar:false,
      formCar:'',
      carState:1,//显示内容
      ruleForm: {
        customerNo: '',
        channel: '',
        mainPhone: '',
        otherPhone: '',
        createDate: '',
        firstComeTime: '',
        customerName: '',
        carNo: '',
        carType: '',
        isHn: '',
        registrationYear: '',
        isMortgage: null,//
        customerType: '',
        serviceType: [],//
        nextContactTime: '',
        createUserName: '',
        assignTradeUserName: '',
        serviceTitle: [],//
        content: '',
        id: '',
        followUpInfoId: '',//业务跟进Id
        name1: '占用',
        carModel:[]
      },
      followUpInfoId: '',//业务跟进Id
      rules: {
        channel: [{ required: true, message: '请选择', trigger: 'change' }],
        customerName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        carNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        customerType: [{ required: true, message: '请选择', trigger: 'change' }],
        nextContactTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        serviceType: [{ required: true, message: '请选择', trigger: 'change' }],
        // serviceType: [{ type: "array", validator: validateOrg,required: true, message: '请选择', trigger: 'change' }],
        serviceTitle: [{ required: true, message: '请选择', trigger: 'change' }],
        content: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
      },
      restaurants: [],
      serviceOptions: [],//服务大小标题
      serviceProps:{
        label:'type',
        value:'id',
        children:'smallTypes'
      },
      serviceNoteList: [],//服务记录信息
      serviceFlag:false,//当选择竞拍后出现价格满意度字段
      serviceFlagno:false,//是否继续跟进
      priceSatisfaction:[],//价格满意度
      customerTypeList:[],//客户类型
      customerCheckBookList: [],//检测预约列表
      customerDealBookList: [],//成交预约列表
      relatedCallList: [],//相关来电列表
      priceNoteList: [],//竞价历史列表
      recordList:[],//客户概况
      visible: false,
      isBookSuccess: false,
      planVisible: false,
      channelId: null,
      res:{},//获取的详情返回值
      pdaFlag:false,//是否展示PDA
      pdaQuestion:{},//PDA调查问卷
      AuctionVisible:false,//竞拍
      AuctionTitle:'延期竞拍',
      auctModal:{
        extensionAuctionTime:'',
        checkReportRemark:''
      },//
      optionsCar:[],//品牌车型
      propCar:{
        label:'name',
        value:'id',
        children:'carSeriesList',
        checkStrictly: true 
      },
      channellist:[],//渠道列表
      channeProp:{
        label:'channelName',
        value:'id',
        children:'channelSubs'
      },
      recordOptions:[],//记录类型
      recordProps:{
        label:'title',
        value:'id',
        children:'smallTitles'
      },
      salesPlanList:[],//历史销售预案
      resReport:{},//检测报告
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pdaDisabled:false,//PDA问卷
      loginType:false,//软电话是否登陆
      userData:{},
      cno:'',
      dialogVisiblePhone:false,
      statePhone:1,
      formPhone:{
        phone:'',
        customerNo:''
      },
      customerType:'',
      dialogVisibleSecrecy:false,
      formSec:{},
      formSecRules:{
        phoneType: [{ required: true, message: '请选择', trigger: 'change' }],
        callerNum: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      fixedPhonelist:[],
      checkStatus:'',//检测状态 0-待调度,1-待检测,2-检测中,3-检测成功,4-检测失败
      mainUniqueId:'',//通话唯一标识
      pickerOptions:{
        disabledDate(date) {//禁止选择当前日期之前的日期（今天可选）
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
        selectableRange: new Date().getHours() + ":" + (new Date().getMinutes()) + ":00 - 23:59:00",//禁止选择当前时间之前的时间
      },
      formCarCode:'',//修改车牌号的验证码（约定修改）
      formCarFlag:false,
      visibleCarCode:false,//输入验证码修改车牌显示
      visibleChannel:false,
      channelArr:[],//新渠道
      channelFlag:false,//是否可以修改渠道,true不可以修改，false可以修改
    }
  },
  created() {
    if(this.$route.query.mainUniqueId){
      this.mainUniqueId=this.$route.query.mainUniqueId
    }
    this.priceSatisfaction=this.$store.state.setting.priceSatisfaction;//价格满意度
    this.customerTypeList=this.$store.state.setting.customerTypeList;//客户类型

    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;
    
    this.customerGetTaskType();//服务大小类所有选项
    this.customerGetServiceTitle();//获取记录类型
    if(this.$route.query.followUpId){
      this.followUpInfoId = Number(this.$route.query.followUpId);
      this.customerQueryCustomerByFollowUpId();
    }
    if(this.$route.query.followUpInfoId){
      this.followUpInfoId = Number(this.$route.query.followUpInfoId);
      this.customerQueryCustomerByFollowUpId();
    }
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.optionsCar=res;
      })
    }else{
      this.optionsCar=this.$store.state.setting.carBrandList;
    }
    this.customerQueryChannel();//获取渠道列表

    this.getFixedPhone()//获取拨打保密呼叫的固定手机列表
  },
  mounted() {
    this.getChannel()//根据返回的channelId,遍历channelList得到channel
  },
  methods: {
    getBaseCarBrandList(e){
      if(e){
        this.$api.baseCarBrandList().then((res)=>{
          if(res.code==0){
            this.optionsCar=res.data;
          }
        })
      }
    },
    onChannelSubmit(){
      if(this.channelArr.length==0){
        this.$message.warning('请选择变更渠道!')
        return
      }
      let query={
        customerId:this.res.id,
        newChannelId:this.channelArr.length>0?this.channelArr[1]:'',
        oldChannelId:this.channelId,
      }
      this.$api.postAlterCustomerChannel(query).then(res=>{
        if(res.code==0){
          this.visibleChannel=false;
          this.customerQueryCustomerByFollowUpId();
          this.$message.success(res.message)
        }
      })
    },
    onRouterMakePlan(){
    let pathData=this.$router.resolve({
        path: '/salesManager/makePlan',
        query: {
          salePlanId:this.res.salePlanId,
          followUpInfoId:this.res.followUpInfoId,
          checkReportId:this.res.checkReportId,
          enquiryId:this.res.enquiryId
        }
    })
    window.open(pathData.href,'_blank')
  },
    onRouter(state){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.ruleForm.customerType!==0&&state!=3){
            if(state===1){
              this.$message.warning('"只有400帮卖”才可以增加门店检测预约!')
              return
            }else if(state===2){
              this.$message.warning('"只有400帮卖”才可以增加外勤检测预约!')
              return
            }
          }
          switch(state){//state=1,门店检测,2=外勤检测,3=外勤回访
            case 1:
              if(this.ruleForm.isMortgage===''||this.ruleForm.isMortgage===null){
                this.$message.warning('是否抵押贷款不可为空！')
                return
              }
              this.doSubmit('no')
              if(this.res.checkBookStatus==1){
                this.$router.push({path: '/testService/reschedule', query: {checkBookId:this.res.checkBookId}})
              }else{
                this.$router.push({path: '/testService/storeDR', query: {customerId:this.res.id,followUpInfoId:this.followUpInfoId}})
              }
              break;
            case 2:
              if(this.ruleForm.isMortgage===''||this.ruleForm.isMortgage===null){
                this.$message.warning('是否抵押贷款不可为空！')
                return
              }
              this.doSubmit('no')
              this.getCheckResourceGetFieldPlan()
              break;
            case 3:
              this.dialogVisible=true;
              break;
          }
        }else{
          this.$nextTick(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      })
    },
    getCheckResourceGetFieldPlan(){//根据客户Id获取未取消的五方格勤虚拟任务
      this.$api.getCheckResourceGetFieldPlan(this.res.id).then(res=>{
        if(res.code==0){
          if(res.data){
            this.$router.push({path: '/testService/fieldDR', query: {checkBookId:this.ruleForm.checkBookId,customerId:this.ruleForm.id}})//外勤检测预约
          }else{
            this.$message.warning('需要先申请外勤资源再预约!')
          }
        }
      })
    },
    copyBuild() {//复制新建
      if(this.ruleForm.carNo && this.followUpInfoId ){
        this.postCustomerCopyCustomer(0,this.ruleForm.carNo)
      }else{
        this.$message.warning('关键信息缺失')
      }
      // window.open(window.location.href, "_blank");
    },
    carBrand(state){//state=1同车不同牌，state=2同牌不同车
      let _this=this;
      if(state==1){
        this.dialogVisibleCar=true;
        this.formCar='';
        this.carState=2;//修改车牌
      }else{
        _this.$confirm('您确定要创建同牌不同车的客户信息吗?', '同牌不同车', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postCustomerCopyCustomer(2)
        }).catch(() => {});
      }
    },
    postCustomerCopyCustomer(copyType,carNo){//复制客户 复制新建/同牌不同车/同车不同牌
      let query={
        "copyType": copyType,//复制类型 0-复制新建 1-同车不同牌 2-同牌不同车
        "followUpInfoId": this.followUpInfoId,
        "carNo": carNo
      }
      this.$api.postCustomerCopyCustomer(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.customerQueryCustomerByFollowUpId();
          this.$message.success(res.message)
          setTimeout(()=>{
            this.$router.push({
              path:'/salesManager/customer',
              query:{
                followUpInfoId:res.data
              }
            })
          },500)
        }
      })
    },
    onSms(){
      this.$router.push({
        path:'/sendSMS',
        query:{
          followUpInfoId:this.followUpInfoId
        }
      })
    },
    onSubmitCarCode(){//验证修改车牌
      if(this.formCarCode==='ckx888'){
        this.visibleCarCode=false;
        this.formCarCode='';
        this.dialogVisibleCar=true;
        this.formCar='';
        this.carState=1;//修改车牌
      }else{
        this.$message.warning('验证码不正确，请重新输入！')
      }
    },
    getCarId(){
      if(this.res.validity===1&&this.checkStatus!=3){
        this.dialogVisibleCar=true;
        this.formCar='';
        this.carState=1;//修改车牌
      }else{
        this.visibleCarCode=true;
        this.formCarCode='';
      }
      
    },
    onSubmitCar(){//修改车牌号
      if(!this.formCar){
        this.$message.warning('请输入车牌号！')
        return
      }else{
        const reg = /[!@#$%^&*(),.?":。，=·～{}|<> ]/;
        if (reg.test(this.formCar)) {
          this.$message.warning('车牌号不能输入特殊字符！')
          return
        }
      }
      if(this.carState===1){
        let query={
          newCarNo:this.formCar,
          customerId:this.res.id
        }
        this.$api.postAlterCarNo(query).then(res=>{
          if(res.code===0){
            this.customerQueryCustomerByFollowUpId();
            this.$message.success(res.message)
            this.dialogVisibleCar=false;
          }
        })
      }else if(this.carState===2){
        this.postCustomerCopyCustomer(1,this.formCar)
      }
    },
    onUpload(){//导入联系电话
      this.statePhone=2;
    },
    onCall(state){
      switch(state){
        case 1://呼叫
          // this.getCallDetailByCno().then(()=>{
            if(this.ruleForm.mainPhone&&this.ruleForm.otherPhone){
              this.$confirm('请选择当前拨打的电话号码', '提示', {
                confirmButtonText: '主要电话',
                cancelButtonText: '次要电话',
                type: 'warning'
              }).then(() => {
                this.postCallout(this.ruleForm.mainPhone,1)
              }).catch(() => {
                this.postCallout(this.ruleForm.otherPhone,2)        
              });
            }else{
              this.postCallout(this.ruleForm.mainPhone,1)
            }
          // }).catch(()=>{
          // })//根据员工工号查询坐席信息(拨打电话)
          
          break;
        case 2://华为云
          this.dialogVisibleSecrecy=true;
          break;
        case 3://主次互换
          this.$confirm('请确认是否进行主次互换？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$api.postExchangePhone(this.res.id).then(res=>{
                if(res.code==0){
                  this.customerQueryCustomerByFollowUpId();//查询详情
                  this.$message.success(res.message)
                }
              })
            }).catch(() => {});
          break;
        case 4://修改次要联系电话
          this.statePhone=1;
          this.formPhone.phone=this.ruleForm.otherPhone;
          this.dialogVisiblePhone=true;
          break;
      }
    },
    giveUpCustomer(){//放弃
      this.$confirm('是否确认放弃此数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.postGiveUpCustomer({followUpInfoId:this.followUpInfoId}).then(res=>{
            if(res.code==0){
              this.customerQueryCustomerByFollowUpId()//详情
              this.$message.success(res.message)
            }
          })
        }).catch(() => {});
    },
    onSubmitPhone(){//修改次要电话
      let query={
        "customerId": this.res.id,
        // "customerNo": "string",
        // "phone": "string"
      }
      if(this.statePhone==1){
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (!reg.test(this.formPhone.phone)) {
          this.$message.warning('请输入正确的手机号!')
          return
        }
        query.phone=this.formPhone.phone;

      }else{
        query.customerNo=this.formPhone.customerNo
      }
      this.$api.postUpdateOtherPhone(this.res.id,query).then(res=>{
        if(res.code==0){
          this.dialogVisiblePhone=false;
          this.customerQueryCustomerByFollowUpId()
          this.$message.success(res.message)
        }
      })
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber,phoneType){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      if(phoneType){
        query.phoneType=phoneType;
        query.customerId=this.res.id;
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    init(){
      this.customerQueryCustomerByFollowUpId();//重新获取数据
    },
    // 客户详情查询
    customerQueryCustomerByFollowUpId() {
      this.$api.customerQueryCustomerByFollowUpId(this.followUpInfoId).then(res => {
        if(res.code == 0){
          this.res=JSON.parse(JSON.stringify(res.data));
          this.ruleForm=JSON.parse(JSON.stringify(res.data));
          this.customerType=res.data.customerType;
          let user=this.$store.state.user.userData;
          let roleName=this.$$.roleName(user)
          if(roleName.name=='帮卖经理'){
            this.pdaFlag=true;
          }
          this.pdaQuestion=this.res.pdaQuestion?this.res.pdaQuestion:{};
         
          
          
          if(res.data.checkReportId) this.getCheckReport()//获取检测报告
          if(res.data.checkBookId){
            this.getCustomerRecordList();//根据业务跟进ID查询客户概况
          }
          if(res.intentionMoneyStatus==3){//当前意向金状态 0-不显示按钮,1-新建意向金,2-查看意向金,3-意向金违约
            this.$message({
              message: '提示语:此客户有违约款项不可签意向协议和进行成交预约沟通。',
              type: 'warning',
              duration: 0,
              offset:'60'
            });
          }
          this.isBookSuccess = Boolean(res.data.isBookSuccess);
          this.channelId = res.data.channelId;
          // console.log(res.data.channelId)
          if(res.data.bigChannelId) this.ruleForm.channel=[res.data.bigChannelId,res.data.channelId];
          //品牌车型
          if(res.data.seriesId){
            this.ruleForm.carModel=[res.data.makerId,res.data.seriesId]
          }else{
            if(res.data.makerId){
              this.ruleForm.carModel=[res.data.makerId]
            }else{
              this.ruleForm.carModel=[]
            }
          }
          if(res.data.bigType){
            this.ruleForm.serviceType=[res.data.bigType,res.data.smallType]//服务类型
          }
           if(['大客户'].includes(this.res.bigChannelName)){
            if(this.res.isMortgage===''||this.res.isMortgage===null){
              this.ruleForm.isMortgage=0;
            }
          }

          //判断渠道是否可以修改
          // if(['大客户'].includes(this.res.bigChannelName)&&this.res.ccStatus
          if(this.res.ccStatus){//主渠道为大客户，客户合同签订成功之前帮卖经理可以改渠道
            this.channelFlag=true;
          }
          
          // this.ruleForm.serviceTitle=[res.data.serviceBigTitle,res.data.serviceTitle]//记录类型（不要默认回显）
          this.ruleForm.serviceTitle=[];//记录类型（不要默认回显）
          
          if(res.data.createDate){
            this.ruleForm.createDate = this.$$.getFormatMomentTime('YYYY-MM-DD HH:mm:ss',res.data.createDate);
          }
          
          this.$set(this.ruleForm, 'assignMaintainUserId', res.data.assignMaintainUserId)
          this.$set(this.ruleForm, 'assignTradeUserId', res.data.assignTradeUserId)
          if(res.data.serviceNoteList) if(res.data.serviceNoteList.length){//服务记录信息
            this.serviceNoteList = res.data.serviceNoteList;
          }
          if(res.data.customerCheckBookList){//检测预约列表
            this.customerCheckBookList = res.data.customerCheckBookList?res.data.customerCheckBookList:[];
          }
          this.checkStatus=this.customerCheckBookList.length?this.customerCheckBookList[0].checkStatus:'';//检测状态 0-待调度,1-待检测,2-检测中,3-检测成功,4-检测失败

          if(res.data.customerDealBookList){//成交预约列表
            this.customerDealBookList = res.data.customerDealBookList?res.data.customerDealBookList:[];
          }
          if(res.data.relatedCallList){//相关来电列表
            this.relatedCallList = res.data.relatedCallList?res.data.relatedCallList:[];
          }
          if(res.data.priceNoteList){//竞价历史列表
            this.priceNoteList = res.data.priceNoteList?res.data.priceNoteList:[];
          }
          this.$nextTick(()=>{
            this.changeService()//控制显示价格类型等关联
            if(this.$refs['ruleForm']){
              this.$refs['ruleForm'].clearValidate();
            }
          })
        }
      })
    },
    customerGetTaskType() {//获取服务类型
      this.$datadic.getTaskType().then(res => {
        this.serviceOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    customerGetServiceTitle() {//服务记录标题-大小标题
      this.$datadic.getServiceTitle().then(res => {
        this.recordOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    fieldReserve() {
      if(this.isBookSuccess){
        this.$router.push({path: '/testService/reschedule', query: {customerNo:this.ruleForm.customerNo}})
      }else{
        this.$router.push({path: '/testService/fieldDR', query: {customerNo:this.ruleForm.customerNo}})
      }
    },
    doSubmit(type) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let pdaQuestionForm={
              channelName:this.pdaQuestion.channelName,//了解价格的渠道名称
              checkTaskId:this.pdaQuestion.checkTaskId,//检测任务Id
              dealShop:this.pdaQuestion.dealShop,//最近的成交门店名称
              dealShopId:this.pdaQuestion.dealShopId,//最近的成交门店Id
              // id:this.pdaQuestion.id,
              knowPrice:this.pdaQuestion.knowPrice,//有无了解过价格
              newCar:this.pdaQuestion.newCar,//新车订购
              newCarDay:this.pdaQuestion.newCarDay,//多少天可提车
              owner:this.pdaQuestion.owner,//是否是车主本人
              price:this.pdaQuestion.price,//了解到的价格（单位/万）
              remark:this.pdaQuestion.remark,//其他需求
            }
          let params = {
            id: this.ruleForm.id,
            customerName: this.ruleForm.customerName,//客户名称
            // otherPhone:this.ruleForm.otherPhone,//其他联系电话
            isHn: this.ruleForm.isHn,//是否黄牛
            isFollowUp:this.ruleForm.isFollowUp,//是否继续跟进
            channelId:this.ruleForm.channel.length>0?this.ruleForm.channel[1]:'',//子渠道Id
            // provinceId:'',//省份ID
            // cityId:'',//城市ID
            // regionId:'',//区域ID
            // address:'',//客户地址
            customerType: this.ruleForm.customerType,//客户类型（0,400帮卖；1，非经营范围；2，非卖车咨询
            // isPutStorage:'',//是否入库
            content: this.ruleForm.content,//内容
            serviceTitle:this.ruleForm.serviceTitle?this.ruleForm.serviceTitle[1]:'',//小标题ID
            // carNo:this.ruleForm.carNo,//车牌号码
            registrationYear: this.ruleForm.registrationYear,//初登年份
            isMortgage:this.ruleForm.isMortgage,//是否抵押车 -1-未识别 0-否 1-是
            makerId:this.ruleForm.carModel.length>0?this.ruleForm.carModel[0]:'',//车品牌厂家ID
            seriesId:this.ruleForm.carModel.length>1?this.ruleForm.carModel[1]:'',//车系ID
            nextContactTime: this.ruleForm.nextContactTime,//下次联系时间
            serviceType:this.ruleForm.serviceType.length>0?this.ruleForm.serviceType[1]:'',//服务类型-小类ID
            priceSatisfaction:this.ruleForm.priceSatisfaction,//价格满意度
            sessionId:this.mainUniqueId,//通话唯一标识
            pdaQuestionForm:this.pdaFlag?JSON.stringify(pdaQuestionForm)==='{}'?null:pdaQuestionForm:null,
            // recommendNewEnergy:this.ruleForm.recommendNewEnergy,//推荐新能源
            // makerBrandCode: 1997,
            // familyCode: 26,
          }
          this.$api.customerEditCustomer(params).then(res => {
            if(res.code == 0){
              if(this.mainUniqueId){
                this.bus.$emit("closeSelected",'router');
                this.$router.push({
                  path: "/salesManager/customer",
                  query:{
                    followUpInfoId:this.followUpInfoId
                  }
                });
              }else{
                if(!type){
                  this.customerQueryCustomerByFollowUpId()//详情
                  this.$message.success(res.message)
                }
              }
              
            }
          })
        } else {
          this.$nextTick(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      });
    },
    onPrebook(state){//点击检测预约
      
      let query={
        followUpInfoId:this.res.followUpInfoId
      }
      if(this.res.checkBookStatus==1){
        query.checkBookId=this.res.checkBookId
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.ruleForm.isMortgage===''||this.ruleForm.isMortgage===null){
            this.$message.warning('是否抵押贷款不可为空！')
            return
          }
          this.doSubmit('no')
          if(state===1){
            this.$router.push({//门店检测
              path: '/salesManager/yuyuejiance',
              query:query
            })
          }else{
            this.$router.push({
              path: '/salesManager/consign',
              query:{
                enquiryId:this.res.enquiryId,
                customerId:this.res.id
              }
            })
          }
          
        }else{
          this.$nextTick(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
        
      })
    },
    onSubmitMM(){
      this.$refs['formSec'].validate((valid) => {
        if (valid) {
          let query={
            callerNum:this.formSec.callerNum.phone,
            phoneType:this.formSec.phoneType,
            customerId:this.res.id,
          }
          this.$api.postPrivacyCallOut(query).then(res=>{
            if(res.code==0){
              this.$alert(`请在${this.formSec.callerNum.name}手机上拨打${res.data}`, '提示', {
                confirmButtonText: '确定',
                callback: () => {
                  this.dialogVisibleSecrecy=false;
                }
              });
            }
          })
        }
      });
    },
    getFixedPhone(){//获取拨打保密呼叫的固定手机列表
      this.$api.getFixedPhone().then(res=>{
        if(res.code==0){
          this.fixedPhonelist=res.data;
        }
      })
    },
    cancelDeal(state){//state：1:取消检测；state：2:取消成交
      this.$confirm(`您是否确认取消${state==1?'检测预约':'成交预约'}`, '二次确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(state==1){
          this.updateCheckBookCancel()
        }else{
          this.postDealBookCancel()
        }
      }).catch(() => {});
    },
    postDealBookCancel(){//预约成功之后, 取消成交, 传递预约成交id
      // let query={
      //   id:this.res.dealBookId,//预约Id
      //   customerStatus:customerStatus//客户意愿 0同意 1改约 2待定 3放弃
      // }
      this.$api.postDealBookCancel(this.res.dealBookId).then(res=>{
        if(res.code==0){
          this.customerQueryCustomerByFollowUpId()//获取详情
          this.$message.success(res.message)
        }
      })
    },
    updateCheckBookCancel(){//取消检测预约
      let query={
        "checkBookId": this.res.checkBookId,
        "cancelReason": "帮卖经理取消检测",
        // "remark": ""
      }
      this.$api.updateCheckBookCancel(query).then(res=>{
        if(res.code==0){
          this.customerQueryCustomerByFollowUpId()//获取详情
          this.$message.success(res.message)
        }
      })
    },
    reserve() {//门店检测预约
      if(this.isBookSuccess){
        this.$router.push({path: '/testService/reschedule', query: {customerNo:this.ruleForm.customerNo}})
      }else{
        this.$router.push({path: '/testService/storeDR', query: {customerNo:this.ruleForm.customerNo}})
      }
    },
    refreshSelected() {
      this.bus.$emit('refreshPage')
      // this.$store.dispatch('tagsView/delCachedView', view).then(() => {
      //   const { fullPath } = view
      //   this.$nextTick(() => {
      //     this.$router.replace({
      //       path: '/redirect' + fullPath
      //     })
      //   })
      // })
    },
    getChannel() {
      if(!this.channelList){
        return
      }
      this.channelList.forEach(item => {
        if(item.channelSubs&&item.channelSubs.length){
          item.channelSubs.forEach(ite => {
            if(ite.id === this.channelId){
              this.ruleForm.channel = [item.id, ite.id]
            }
          })
        }
      })
    },
    //新建意向金
    addMoney(state){//state1新建2查看
      let query={
          // state:state,
          dealBookId:this.res.dealBookId
        }
      if(state==2){
        query.intentionMoneyId=this.res.intentionMoneyId
      }
      this.$router.push({
        path:'/salesManager/intentionMoney/agreement',
       query:query
      })
    },
    //终止竞拍
    onTermination(){
      this.AuctionVisible=!this.AuctionVisible;
      this.AuctionTitle='终止竞拍';
    },
    //延期竞拍
    onDelay(){
      this.AuctionVisible=!this.AuctionVisible;
      this.AuctionTitle='延期竞拍';
      this.auctModal.extensionAuctionTime=this.res.extensionAuctionTime;//延期竞拍日期
      this.auctModal.checkReportRemark=this.res.checkReportRemark;//延期竞拍原因
      
    },
    onRevoke(){//撤销
      this.$api.postAuctionCancel(this.res.checkReportId,this.auctModal.checkReportRemark?this.auctModal.checkReportRemark:'').then(res=>{
        if(res.code==0){
          this.AuctionVisible=!this.AuctionVisible;
          this.customerQueryCustomerByFollowUpId()//获取详情
          this.$message.success(res.message)
        }
      })
    },
    AuctionSubmit(){//竞拍modal
      if(this.AuctionTitle=='延期竞拍'){
        this.postExtensionAuction()//延期竞拍
      }else{
        this.postAuditReport()//车辆报告审核(终止竞拍)
      }
    },
    postExtensionAuction(){//延期竞拍
      this.$api.postExtensionAuction(this.res.checkReportId,this.auctModal.extensionAuctionTime,this.auctModal.checkReportRemark).then(res =>{
        if(res.code==0){
          this.AuctionVisible=false;
          this.customerQueryCustomerByFollowUpId()//获取详情
        }
      })
    },
    postAuditReport(){//车辆报告审核
      let query={
        auditStatus:5,//报告状态 0-待审核,1-审核通过,2-驳回重传,3-暂时不拍,5-终止竞拍
        remark:this.remark
      }
      this.$api.postAuditReport(this.res.checkReportId,query).then(res=>{
         if(res.code==0){
          this.AuctionVisible=!this.AuctionVisible;
          this.customerQueryCustomerByFollowUpId()//获取详情
        }
      })
    },
    customerQueryChannel(){//渠道列表
      this.$api.customerQueryChannel().then(res=>{
        if(res.code==0){
          this.channellist=res.data;
        }
      })
    },
    changeService(){//选择记录类型
      let node=this.$refs.service.getCheckedNodes()
      if(node.length>0) if(node[0]){
        this.serviceFlag=node[0].parent.label=="竞拍后"?true:false;
        if(node[0].parent.label=="竞拍后"){
          this.serviceFlagno=node[0].label.indexOf('第一天')!=-1||node[0].label.indexOf('第三天')!=-1?true:false;
        }
      }
    },
    onPlan(){//点击销售预案
      this.planVisible=!this.planVisible
      this.getSalePlan()//获取历史销售预案
    },
    getSalePlan(){//获取历史销售预案
      this.$api.getSalePlan(this.followUpInfoId).then(res=>{
        if(res.code==0){
          this.salesPlanList=res.data;
        }
      })
    },
    getCheckReport(){//获取检测报告
      this.$api.getCheckReport(this.res.checkReportId).then(res=>{
        if(res.code==0){
          this.resReport=res.data;
        }
      })
    },
    getCustomerRecordList(){//根据业务跟进ID查询客户概况
      this.$api.getCustomerRecordList(this.followUpInfoId,1).then(res=>{
        if(res.code==0){
          this.recordList=res.data;
        }
      })
    },
  },

}
</script>

<style scoped lang="scss">
.customer{
  background: #f5f5f5;
  min-height: 100vh;
  padding-top: 20px;
  // padding-bottom: 100px;
  .leftInfo, .rightInfo{background: #fff;height: 100%;}
  .rightInfo{padding: 30px 40px;}
  .customerForm{
    .formItem{
      padding: 30px 40px 10px;
      .ckxTitle{margin-bottom: 30px;}
      &>.flex:last-child{border-width: 0;}
      .tag-read{margin-left: 20px;}
      .numberPlate{width: 130px;margin-right: 20px;}
      .ml20{margin-left: 20px;}
      .line{border-bottom: 1px dashed #CCCCCC;}
    }
  }
  .serviceRecord{margin: 40px 0 30px;}
  .leftInfo{position: relative;}
  .aStatus{
    position: absolute;
    width: 144px;
    height: 144px;
    top: 0;
    right: 0;
    .div1{
      border: 72px solid transparent;
      border-top: 72px solid #0099FF;
      border-right: 72px solid #0099FF;
      position: relative;
      .div2{
        border: 30px solid transparent;
        border-top: 30px solid #fff;
        border-right: 30px solid #fff;
        position: absolute;
        top: -72px;
        right: -72px;
      }
    }
    .div3{
      position: absolute;
      top: 31px;
      right: -11px;
      color: #fff;
      width: 120px;
      line-height: 40px;
      text-align: center;
      transform: rotate(45deg);
      font-size: 22px;
    }
  }
  .recordTypeWidth{width: 322px;}
  .planItem{
    padding: 10px 10px 30px;
    position: relative;
    text-align: justify;
    background: #f5f5f5;
    .planDate{
      position: absolute;
      bottom: 3px;
      right: 10px;
    }
  }
  .centerFont{
    text-align: center;
  }
  .modelPhoneCur{
    color: #409eff;
    cursor: pointer;
    margin-left: 30px;
  }
  .inputText{
    border:none;
    border-bottom: 1px solid;
    width:200px;
    &::v-deep .el-input__inner{
      border:none;
      font-size:16px
    }
  }
  .pdaBox{
    padding-bottom: 20px;
  }
  .w100{
    width:100px;
  }
  ::v-deep .tipsColor .el-radio__label{
    color: #ff0000;
  }
}
@media screen and (min-width: 1366px) and (max-width:1760px){
 .customer .customerForm .formItem{
    padding-left:20px;
    padding-right:20px;
  }
  .customer .rightInfo{
    padding-left:20px;
    padding-right:20px;
  }
}
</style>
<style>
.customer .formContent .el-form-item__label{ text-align: left; width: 100px !important;}
.customer .formContent .el-form-item__content{ margin-left: 0px;}
.pdaBox .el-form-item__label{text-align:left;}
</style>
