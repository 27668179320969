<template>
  <!-- 验车入库分配 -->
  <div class="yancherukufenpei">
    <el-tabs v-model="activeName" @tab-click="init(1)" type="card">
      <el-tab-pane label="验车待分配" name="first">
        <el-row :gutter="24" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>客户编号</span>
            <el-input v-model="params.customerNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>品牌车型</span>
            <el-input v-model="params.brandName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <el-date-picker size="medium"
            v-model="params.date" 
            type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" > </el-date-picker>
          </el-col>
          <el-col :span="2" style="text-align:right;">
            <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="mt20 table-mainbg">
          <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}/{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="isPledgeName" label="是否抵押车" align="center"></el-table-column>
          <el-table-column prop="appointTime" label="预约时间" align="center"></el-table-column>
          <el-table-column prop="enquiryTypeName" label="询价类型" align="center"></el-table-column>
          <el-table-column prop="auctionDate" label="竞拍日期" align="center"></el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="onOperation(scope.row)">分配</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
        class="mt30"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next,sizes, jumper"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page_size"
        :current-page="page_index"
        :total="page_total"
      ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="已分配" name="second">
        <el-row :gutter="24" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>客户编号</span>
            <el-input v-model="params1.customerNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>品牌车型</span>
            <el-input v-model="params1.brandName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <el-date-picker size="medium"
            v-model="params1.date" 
            type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" 
            :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" > </el-date-picker>
          </el-col>
          <el-col :span="2" style="text-align:right;">
            <el-button type="primary" size="medium" plain @click="init(1)">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="mt20 table-mainbg">
          <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}/{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="isPledgeName" label="是否抵押车" align="center"></el-table-column>
          <el-table-column prop="allotTime" label="分配时间" align="center"></el-table-column>
          <el-table-column prop="validFinishTime" label="验车完成时间" align="center"></el-table-column>
          <el-table-column prop="validStatusName" label="验车状态" align="center"></el-table-column>
          <el-table-column prop="hasDifferenceName" label="是否有差异" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="120px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="onRouter(scope.row)" :disabled="[1,2].includes(scope.row.validStatus)">查看报告</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size1"
          :current-page="page_index1"
          :total="page_total1"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :visible.sync="distributionDialog" title="检测师分配" class="customDialog">
      <div class="flex flex_around flex_align_center">
        <span>客户姓名：{{itemRow.customerName}}</span>
        <span>品牌车型：{{itemRow.brandName}}/{{itemRow.modelName}}</span>
        <span>车牌号码：{{itemRow.carNo}}</span>
      </div>
      <el-row :gutter="24" class="mb20 mt20">
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>检测师姓名</span>
          <el-input v-model="form.userName" size="medium"></el-input>
        </el-col>
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>检测师账号</span>
          <el-input v-model="form.userNo" size="medium"></el-input>
        </el-col>
        <el-col :span="2" class="flex flex_align_center flex_between ">
          <el-button type="primary" @click="getUserRole" size="medium" plain>查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="checklist" border max-height="300">
        <el-table-column prop="name" label="检测师姓名" align="center"></el-table-column>
        <el-table-column prop="no" label="检测师账号" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="onSubmit(scope.row)"
              size="mini"
            >完成分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="mt20 mr10">请选择检测师:</div>
      <div class="flex flex_wrap">
        <el-radio-group v-model="inspector" size="medium">
          <el-radio v-for="item in checklist" :key="item.id" :label="item.id" class="mt10">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">完成分配</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo: '',
        date: '',
      },
      form:{
        userName:'',
        userNo:''
      },
      params1:{},
      querylist: [],
      querylist1:[],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      activeName: 'first',
      distributionDialog: false,
      // inspector: '',
      itemRow:{},
      checklist:[],//检测师
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page){
      let user=this.$store.state.user.userData;
      let role=this.$$.roleName(user,'department')
      let tradeShopId='';
      if(role){
        tradeShopId=role.shop?role.shop.id:'';
      }
      let query={
        tradeShopId:tradeShopId
      }//帮卖经理-验车分配状态：11待分配，12已分配
      query.recheckType=0;//复检类型 0-垫资复检 1-商户验车 2-商户差异复检
      switch(this.activeName){
        case 'first':
          this.page_index=page?page:this.page_index;

          query.curPage=this.page_index
          query.pageSize=this.page_size;
          query.allotStatus=11//验车分配状态：11待分配，12已分配
          query.customerNo=this.params.customerNo;
          query.carNo=this.params.carNo;
          query.appointTimeBegin=this.params.date?this.params.date[0]:'';
          query.appointTimeEnd=this.params.date?this.params.date[1]:'';
          query.carType=this.params.brandName;
          break;
        case 'second':
          this.page_index1=page?page:this.page_index1;

          query.curPage=this.page_index1;
          query.pageSize=this.page_size1;
          query.allotStatus=12
          query.customerNo=this.params1.customerNo;
          query.carNo=this.params1.carNo;
          query.appointTimeBegin=this.params1.date?this.params1.date[0]:'';
          query.appointTimeEnd=this.params1.date?this.params1.date[1]:'';
          query.carType=this.params1.brandName;
          break;
      }
      this.$api.postValidList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          switch(this.activeName){
            case 'first':
              this.querylist = res.data.records;
              this.page_total = res.data.total;
              break;
            case 'second':
              this.querylist1 = res.data.records;
              this.page_total1 = res.data.total;
              break;
          }
        }else{
          switch(this.activeName){
            case 'first':
              this.querylist = [];
              this.page_total = 0;
              break;
            case 'second':
              this.querylist1 = [];
              this.page_total1 = 0;
              break;
          } 
        }
      })
    },
    handleSizeChange(row){
      switch(this.activeName){
        case 'first':
          this.page_size=row;
          this.page_index=1;
          break;
        case 'second':
          this.page_size1=row;
          this.page_index1=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(row){
      switch(this.activeName){
        case 'first':
          this.page_index=row;
          break;
        case 'second':
          this.page_index1=row;
          break;
      }
      this.init()
    },
    onRouter(row){
      this.$router.push({
        path:'/storeManager/difEvaluationDetail',
        query:{
          carValidId:row.carValidId,
          title:'复检报告'
        }
      })
    },
    onOperation(row){
      this.form.userName='';
      this.form.userNo='';
      this.getUserRole()//查询检测师
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.distributionDialog=!this.distributionDialog;
    },
    onSubmit(row){//帮卖经理-验车入库 分配帮卖,检测师 操作
      let query={
        validId:this.itemRow.carValidId,//车牌号,
        checkerId:row.id,//检测师工号
        operaType:'checker_allot',//操作类型 商户验车-分配检测师 checker_allot, 商户验车-发送看车单 merchant_send, 商户验车-本地查验-商户领取完钥匙 merchant_key_get, 商户验车完成 merchant_valid_finish, 商户取消验车 merchant_valid_cancel
      }
      this.$api.postValidCarValid(query).then(res=>{
        if(res.code==0){
          this.distributionDialog=!this.distributionDialog;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    getUserRole(){
      let query={
        roleName:'检测师',
        userName:this.form.userName,
        loginName:this.form.userNo
      }
      this.$api.queryUserListByRoleAndRoleLevelAndDepartAndUser(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.checklist=res.data;
        }
      })
    }
  }
}
</script>


<style scoped lang="scss">
.yancherukufenpei{
  padding: 20px 40px;
}
</style>