<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店简称</span>
        <el-input v-model="form.partnerShortName" size="medium"   @keyup.enter.native="init(1)"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>所属集团</span>
        <el-input v-model="form.ownerGroup" size="medium"></el-input>
      </el-col>
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店全称</span>
        <el-input v-model="form.partnerFullName" size="medium"></el-input>
      </el-col> -->
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>所属区域</span>
        <el-cascader
          v-model="areaArr"
          :options="addresslist"
          :props="props" clearable></el-cascader>
      </el-col> -->
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>拓展专员</span>
        <!-- <el-input v-model="form.extensionerName" size="medium"></el-input> -->
        <el-select v-model="form.extensionerId" size="medium" clearable>
          <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
       <el-col :span="5" class="flex flex_align_center flex_between w4">
          <el-button type="primary" class="button_primary" @click="init(1)" size="medium" plain>搜索</el-button>
        </el-col>
    </el-row>
    <!-- <el-row :gutter="24" class="mt20"> -->
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>所属集团</span>
        <el-input v-model="form.ownerGroup" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>拓展专员</span>
        <el-select v-model="form.contactName" size="medium" clearable>
          <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <el-button type="primary" class="button_primary" @click="init" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row> -->
    <el-table :data="querylist" border stripe class="mt30 table-mainbg">
      <el-table-column prop="shortName" label="合作店简称" align="center"></el-table-column>
      <el-table-column prop="name" label="合作店全称" align="center"></el-table-column>
      <el-table-column prop="ownerGroup" label="所属集团" align="center"></el-table-column>
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="拓展专员姓名" align="center"></el-table-column>
      <el-table-column prop="statusName" label="审核状态" align="center">
        <!-- <template slot-scope="scop">{{scop.row.taskStatus==5?'待签约':scop.row.taskStatus}}</template> -->
      </el-table-column>
      <el-table-column prop="auditTime" label="审核通过时间" align="center"></el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onsee(scope.row)" size="mini" class="group-custom">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      querylist: [],
      addresslist:[],
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      areaArr:[],//选择的地区
      userlist:[],//
      total: 0,
      currentPage: 1,
      pageSize: 10,
      loading: false,
    };
  },
  created() {
    this.init();
    //经营省份城市区域3级联动查询(无参数查所有)
    // if(this.$store.state.setting.areaList.length==0){
    //   this.$datadic.getProvince(0).then(res=>{
    //     this.addresslist=res;
    //   })
    // }else{
    //   this.addresslist=this.$store.state.setting.areaList;
    // }
    this.queryUserListByRoleAndDepart()//获取拓展专员
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      //数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-已解约 7-已过期
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        status: 1,
        shortName: this.form.partnerShortName, //合作店简称
        // name: this.form.partnerFullName, //合作店全称
        ownerGroup: this.form.ownerGroup, //所属集团
        // "provinceId": this.areaArr.length>0?this.areaArr[0]:'',
        // "cityId":  this.areaArr.length>0?this.areaArr[1]:'',
        // "areaId":  this.areaArr.length>0?this.areaArr[2]:'',
        extensionerId:this.form.extensionerId,//拓展专员
      };
      this.$api.getChannelPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = []
            this.total=0;
          }
        }else{
          this.querylist = []
          this.total=0;
        }
      });
    },
    onsee(row) {
      this.$router.push({
        path: "/channel/examineDetails",
        query: {
          // state:row.status,//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-已解约 7-已过期
          id:row.id
        }
      });
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//获取拓展专员
      let query={
        roleName:'拓展专员'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {

}
</style>
