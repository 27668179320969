<template>
  <el-table :data="tipsTableData" border stripe class="table-mainbg" :max-height="height" :row-class-name="tableRowClassName">
    <el-table-column prop="title" label="类型" width="100" align="center"></el-table-column>
    <el-table-column prop="plateNumberNotSen" label="车牌" width="80" align="center"></el-table-column>
    <el-table-column prop="content" label="内容" align="center"></el-table-column>
    <el-table-column label="操作" align="center" width="160">
      <template slot-scope="scope">
        <el-button type="primary" size="mini" plain @click="goCustomer(scope.row)">载入</el-button>
        <el-button type="primary" size="mini" plain @click="delNews(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    tipsTableData: {
      type: Array,
      default:[]
    },
    height:{
      type:Number,
    },
    homePageFlag:{//是否首页过来的数据
      type:Boolean,
      default:false
    }
  },
  methods: {
    tableRowClassName({row}){
      if(this.homePageFlag){
        if(row.isRead===0){
          return 'tipsColor'
        }
      }
    },
    formatType(row) {
      if(row.type === 0){
        return '下次联系人提醒'
      }else if(row.type === 1){
        return '沟通消息'
      }else if(row.type === 2){
        return '加急消息'
      }else{
        return ''
      }
    },
    goCustomer(row){
      let roleObj=this.$$.roleName(this.$store.state.user.userData)
      let path='';
      switch(roleObj.name){
        case '在线坐席':
        case '在线客服':
          path= '/onlineService/customer';
          break;
        case '检测坐席组长':
        case '检测坐席':
          path= '/testService/customer';
          break;
        case '帮卖经理':
          path= '/salesManager/customer';
          break;
          default:
          path= '/testService/customer';
      }
      if(this.homePageFlag){
        this.$api.messageReadMessage({id:row.id}).then(res => {
          if(res.code == 0&&res.data===true){
            this.$router.push({
              path: path, 
              query: {
                followUpInfoId:row.followUpInfoId
              }
            })
          }
        })
      }else{
        this.$router.push({
          path: path, 
          query: {
            followUpInfoId:row.followUpInfoId
          }
        })
      }
    },
    delNews(row) {//删除消息
      this.$api.DeleteMessage({id:row.id}).then(res => {
        if(res.code == 0&&res.data===true){
          this.$message.success(res.message)
          this.$emit('messageQueryMessage')
        }else if(res.data===false){
          this.$message.error('操作失败！')
        }
      })
    }
  }

}
</script>

<style>

</style>