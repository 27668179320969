<template>
  <div class="signContract">
    <el-tabs
      v-model="activeName"
      @tab-click="init(1)"
      type="card"
      class="groupbg"
    >
      <el-tab-pane label="待领取" name="first">
        <el-row :gutter="20" class="mt30">
          <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params.carNo"></el-input>
          </el-col>
          <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交归属</span>
            <el-select v-model="params.sellerNo" placeholder="">
              <el-option v-for="(item,index) in sellerNamelist" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col> -->
          <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4">
            <span>成交门店</span>
            <el-select v-model="params.tradeShopId" clearable>
              <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <!-- <el-date-picker
              v-model="params.beginContractDate"
              type="date"
              size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
            <el-date-picker
              v-model="params.createTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.setting.pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              @change="timeChange"
            >
            </el-date-picker>
          </el-col>
          <el-col :xl="4" :lg="6">
            <el-button type="primary" plain size="medium" @click="search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg mt30">
          <el-table-column prop="contractNo" label="合同编号" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column
            prop="carTradedPrice"
            label="成交价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tradeTypeName"
            label="成交类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="isPledge" label="抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isPledge==42?'是':'否'}}</template>
          </el-table-column>
          <!-- <el-table-column
            prop="sellerName"
            label="成交归属"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="gatheringStatus"
            label="收款状态"
            align="center"
          >
            <template slot-scope="scope">{{scope.row.gatheringStatus==81?'未收款':scope.row.gatheringStatus==82?'已收款':''}}</template>
          </el-table-column>
          <el-table-column
            prop="tradeShopName"
            label="成交门店"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="appointTradeTime"
            label="预约成交时间"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="receive(scope.row, '领取')"
                >领取</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper" 
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          :current-page="page_index"
          :total="page_total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="制作合同" name="second">
        <el-row :gutter="20" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params1.carNo"></el-input>
          </el-col>
          <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交归属</span>
            <el-select v-model="params1.sellerNo" placeholder="">
              <el-option v-for="(item,index) in sellerNamelist" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col> -->
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交门店</span>
            <el-select v-model="params1.tradeShopId" clearable>
              <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <!-- <el-date-picker
              v-model="params.beginContractDate"
              type="date"
              size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
            <el-date-picker
              v-model="params1.createTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.setting.pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              @change="timeChange"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" plain @click="search">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt30">
          <el-table-column
            prop="contractNo"
            label="合同编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号码"
            align="center"
          ></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column
            prop="carTradedPrice"
            label="成交价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tradeTypeName"
            label="成交类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="isPledge" label="抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isPledge==42?'是':'否'}}</template>
          </el-table-column>
          <!-- <el-table-column
            prop="sellerName"
            label="成交归属"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="gatheringStatus"
            label="收款状态"
            align="center"
          >
             <template slot-scope="scope">{{scope.row.gatheringStatus==81?'未收款':scope.row.gatheringStatus==82?'已收款':''}}</template>
          </el-table-column>
          <el-table-column
            prop="tradeShopName"
            label="成交门店"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="appointTradeTime"
            label="预约成交时间"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="220" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini" plain
                @click="receive(scope.row, 1)">制作合同</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="receive(scope.row, '维保查询')"
                >维保查询</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size1"
          :current-page="page_index1"
          :total="page_total1"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="签订中" name="third">
        <el-row :gutter="20" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params2.carNo"></el-input>
          </el-col>
          <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交归属</span>
            <el-select v-model="params2.sellerNo" placeholder="">
              <el-option v-for="(item,index) in sellerNamelist" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col> -->
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交门店</span>
            <el-select v-model="params2.tradeShopId" clearable>
              <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <!-- <el-date-picker
              v-model="params2.beginContractDate"
              type="date"
              size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
            <el-date-picker
              v-model="params2.createTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.setting.pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              @change="timeChange"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" plain @click="search">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist2" border stripe class="table-mainbg mt30">
          <el-table-column
            prop="contractNo"
            label="合同编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号码"
            align="center"
          ></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column
            prop="carTradedPrice"
            label="成交价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tradeTypeName"
            label="成交类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="isPledge" label="抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isPledge==42?'是':'否'}}</template>
          </el-table-column>
          <!-- <el-table-column
            prop="sellerName"
            label="成交归属"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="gatheringStatus"
            label="收款状态"
            align="center"
          >
             <template slot-scope="scope">{{scope.row.gatheringStatus==81?'未收款':scope.row.gatheringStatus==82?'已收款':''}}</template>
          </el-table-column>
          <el-table-column
            prop="tradeShopName"
            label="成交门店"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="appointTradeTime"
            label="预约成交时间"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="updateBy"
            label="操作人"
            align="center"
          ></el-table-column> -->
          <el-table-column label="操作" width="220" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini" plain
                @click="receive(scope.row, 2)"
                >载入合同</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="receive(scope.row, '维保查询')"
                >维保查询</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size2"
          :current-page="page_index2"
          :total="page_total2"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="签订成功" name="fourth">
        <el-row :gutter="20" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params3.carNo"></el-input>
          </el-col>
          <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交归属</span>
            <el-select v-model="params3.sellerNo" placeholder="">
              <el-option v-for="(item,index) in sellerNamelist" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col> -->
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交门店</span>
            <el-select v-model="params3.tradeShopId" clearable>
              <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <!-- <el-date-picker
              v-model="params.beginContractDate"
              type="date"
              size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
            <el-date-picker
              v-model="params3.createTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.setting.pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              @change="timeChange"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" plain @click="search">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist3" border stripe class="table-mainbg mt30">
          <el-table-column
            prop="contractNo"
            label="合同编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号码"
            align="center"
          ></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column
            prop="carTradedPrice"
            label="成交价" width="100px"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tradeTypeName"
            label="成交类型"
            align="center" width="100px"
          ></el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="isPledge" label="抵押车" align="center" width="80px">
            <template slot-scope="scope">{{scope.row.isPledge==42?'是':'否'}}</template>
          </el-table-column>
          <!-- <el-table-column
            prop="sellerName"
            label="成交归属"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="tradeShopName"
            label="成交门店"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="signTime"
            label="签订成功时间"
            align="center"
          ></el-table-column>
           <el-table-column prop="paymentFinishedTime" label="付款完成时间" align="center" width="190px"></el-table-column>
          <el-table-column
            prop="signUserName"
            label="操作人"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="220" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="receive(scope.row, 0)" >查看合同</el-button>
              <el-button type="primary" size="mini" @click="receive(scope.row, 3)" >修改账号</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size3"
          :current-page="page_index3"
          :total="page_total3"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="签订失败" name="fifth">
        <el-row :gutter="20" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params4.carNo"></el-input>
          </el-col>
          <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交归属</span>
            <el-select v-model="params4.sellerNo" placeholder="">
              <el-option v-for="(item,index) in sellerNamelist" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col> -->
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>成交门店</span>
            <el-select v-model="params4.tradeShopId" clearable>
              <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <!-- <el-date-picker
              v-model="params.beginContractDate"
              type="date"
              size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
            <el-date-picker
              v-model="params4.createTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.setting.pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              @change="timeChange"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" plain @click="search">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist4" border stripe class="table-mainbg mt30">
          <el-table-column prop="contractNo" label="合同编号" align="center" ></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="carTradedPrice" label="成交价" align="center" ></el-table-column>
          <el-table-column prop="tradeTypeName" label="成交类型" align="center" ></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center" ></el-table-column>
          <el-table-column prop="isPledge" label="抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isPledge==42?'是':'否'}}</template>
          </el-table-column>
          <!-- <el-table-column prop="sellerName" label="成交归属" align="center" ></el-table-column> -->
          <el-table-column prop="tradeShopName" label="成交门店" align="center" ></el-table-column>
          <el-table-column prop="signTime" label="失败时间" align="center" ></el-table-column>
          <el-table-column prop="contractBreakRemark" label="失败原因" align="center" ></el-table-column>
          <!-- <el-table-column prop="updateBy" label="操作人" align="center"  ></el-table-column> -->
          <el-table-column label="操作" width="240" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="receive(scope.row, 0)" plain>查看合同</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size4"
          :current-page="page_index4"
          :total="page_total4"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo: "",
        createTime: "",
        tradeShopId:''
      },
      params1: {
        tradeShopId:''
      },
      params2: {
        tradeShopId:''
      },
      params3: {
        tradeShopId:''
      },
      params4: {
        tradeShopId:''
      },
      querylist: [],
      querylist1: [],
      querylist2: [],
      querylist3: [],
      querylist4: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      page_index2: 1, // 页数
      page_total2: 0, // 总页数
      page_size2: 10,
      page_index3: 1, // 页数
      page_total3: 0, // 总页数
      page_size3: 10,
      page_index4: 1, // 页数
      page_total4: 0, // 总页数
      page_size4: 10,
      activeName: "first",
      sellerNamelist:[],
      itemrow:{},//获取当前数据
      shoplist:[],//成交门店
    };
  },
  created() {
    if(this.$route.params.state){
      this.activeName=this.$route.params.state
    }
    this.getlistTradeOwned();//成交归属下拉 列表
    this.getShopList().then(res=>{
      if(res){
        this.init();
      }
    });//成交门店
  },
  methods: {
     getPageSize(){
      //利用Math.ceil算出新的分页 total为什么要-1,因为删完后total还没更新
      let totalPage = Math.ceil((this.page_total - 1) / this.page_size);
      //计算出当前页码
      this.page_index = this.page_index > totalPage ? totalPage : this.page_index;
      //如果页码为1的话，则无效
      this.page_index = this.page_index < 1 ? 1 : this.page_index;
    },
    init(page) {
      let carNo = this.params.carNo; //车牌号
      let sellerNo = this.params.sellerNo;
      let tradeShopId = this.params.tradeShopId;
      let beginAppointTradeDate = this.params.createTimeBegin;
      let endAppointTradeDate = this.params.createTimeEnd;
      let curPage = page?page:this.page_index;
      let pageSize = this.page_size;
      let contractStatus=11;//客户合同状态，11待领取，12制作中，13签订中，14签订成功，15签订失败
      let user=this.$store.state.user.userData;
      let query = {
        curPage: curPage,
        pageSize: pageSize,
        carNo: carNo,
        // sellerNo: user.no, //成交归属人
        tradeShopIds: tradeShopId?[tradeShopId]:'', //成交门店ID
        beginAppointTradeDate: beginAppointTradeDate, //预约成交日期begin
        endAppointTradeDate: endAppointTradeDate, //预约成交日期end
        // contractStatus:[contractStatus]//客户合同状态，11待领取，12制作中，13签订中，14签订成功，15签订失败
      };
      switch (this.activeName) {
        case "first":
          this.page_index=page?page:this.page_index;

          query.carNo = this.params.carNo; //车牌号
          // sellerNo = this.params.sellerNo;
          query.tradeShopIds = this.params.tradeShopId?[this.params.tradeShopId]:'';
          query.beginAppointTradeDate = this.params.createTimeBegin;
          query.endAppointTradeDate = this.params.createTimeEnd;
          query.curPage = this.page_index;
          query.pageSize = this.page_size;
          query.contractStatus=[11];
          break;
        case "second":
          this.page_index1=page?page:this.page_index1;

          query.carNo = this.params1.carNo; //车牌号
          // query.sellerNo = user.no;
          query.tradeShopIds = this.params1.tradeShopId?[this.params1.tradeShopId]:'';
          query.beginAppointTradeDate = this.params1.createTimeBegin;
          query.endAppointTradeDate = this.params1.createTimeEnd;
          query.curPage = this.page_index1;
          query.pageSize = this.page_size1;
          query.contractStatus=[12];
          break;
        case "third":
          this.page_index2=page?page:this.page_index2;

          query.carNo = this.params2.carNo; //车牌号
          // sellerNo = this.params2.sellerNo;
          query.tradeShopIds = this.params2.tradeShopId?[this.params2.tradeShopId]:'';
          query.beginAppointTradeDate = this.params2.createTimeBegin;
          query.endAppointTradeDate = this.params2.createTimeEnd;
          query.curPage = this.page_index2;
          query.pageSize = this.page_size2;
          query.contractStatus=[13];
          break;
        case "fourth":
          this.page_index3=page?page:this.page_index3;

          query.carNo = this.params3.carNo; //车牌号
          // sellerNo = this.params3.sellerNo;
          query.tradeShopIds = this.params3.tradeShopId?[this.params3.tradeShopId]:'';
          query.beginAppointTradeDate = this.params3.createTimeBegin;
          query.endAppointTradeDate = this.params3.createTimeEnd;
          query.curPage = this.page_index3;
          query.pageSize = this.page_size3;
          query.contractStatus=[14];
          break;
        case "fifth":
          this.page_index4=page?page:this.page_index4;

          query.carNo = this.params4.carNo; //车牌号
          // sellerNo = this.params4.sellerNo;
          query.tradeShopIds = this.params4.tradeShopId?[this.params4.tradeShopId]:'';
          query.beginAppointTradeDate = this.params4.createTimeBegin;
          query.endAppointTradeDate = this.params4.createTimeEnd;
          query.curPage = this.page_index4;
          query.pageSize = this.page_size4;
          query.contractStatus=[15];
          break;
      }
      
      this.$api.getCustomerList(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            switch (this.activeName) {
              case "first":
                this.querylist = res.data.records;
                this.page_total = res.data.total;
                break;
              case "second":
                this.querylist1 = res.data.records;
                this.page_total1 = res.data.total;
                break;
              case "third":
                this.querylist2 = res.data.records;
                this.page_total2 = res.data.total;
                break;
              case "fourth":
                this.querylist3 = res.data.records;
                this.page_total3 = res.data.total;
                break;
              case "fifth":
                this.querylist4 = res.data.records;
                this.page_total4 = res.data.total;
                break;
            }
          }else{
            switch (this.activeName) {
              case "first":
                this.querylist = [];
                this.page_total = [];
                break;
              case "second":
                this.querylist1 = [];
                this.page_total1 = [];
                break;
              case "third":
                this.querylist2 = [];
                this.page_total2 = [];
                break;
              case "fourth":
                this.querylist3 = [];
                this.page_total3 = [];
                break;
              case "fifth":
                this.querylist4 = [];
                this.page_total4 = [];
                break;
            }
          }
        }
      });
    },
    //时间控件改变, 数据展示间隔也相应变化.
    timeChange() {
      switch (this.activeName) {
        case "first":
          if (
            this.params.createTime === null ||
            this.params.createTime.length === 0
          ) {
            this.params.createTimeBegin = this.params.createTimeEnd = "";
          } else {
            this.params.createTimeBegin =this.params.createTime[0]
            this.params.createTimeEnd = this.params.createTime[1]
          }
          // console.log(this.params.createTime,'=====99===',this.params.createTimeBegin,this.params.createTimeEnd)
          break;
        case "second":
          if (
            this.params1.createTime === null ||
            this.params1.createTime.length === 0
          ) {
            this.params1.createTimeBegin = this.params1.createTimeEnd = "";
          } else {
            this.params1.createTimeBegin = this.params1.createTime[0]
            this.params1.createTimeEnd = this.params1.createTime[1]
          }
          break;
        case "third":
          if (
            this.params2.createTime === null ||
            this.params2.createTime.length === 0
          ) {
            this.params2.createTimeBegin = this.params2.createTimeEnd = "";
          } else {
            this.params2.createTimeBegin = this.params2.createTime[0]
            this.params2.createTimeEnd = this.params2.createTime[1]
          }
          break;
        case "fourth":
          if (
            this.params3.createTime === null ||
            this.params3.createTime.length === 0
          ) {
            this.params3.createTimeBegin = this.params3.createTimeEnd = "";
          } else {
            this.params3.createTimeBegin = this.params3.createTime[0]
            this.params3.createTimeEnd = this.params3.createTime[1]
          }
          break;
        case "fifth":
          if (
            this.params4.createTime === null ||
            this.params4.createTime.length === 0
          ) {
            this.params4.createTimeBegin = this.params4.createTimeEnd = "";
          } else {
            this.params4.createTimeBegin = this.params4.createTime[0]
            this.params4.createTimeEnd = this.params4.createTime[1]
          }
          break;
      }
    },
    search() {
      this.init(1);
    },
    handleSizeChange(row){
      switch(this.activeName){
        case 'first':
          this.page_size=row;
          this.page_index=1;
          break;
        case 'second':
          this.page_size1=row;
          this.page_index1=1;
          break;
        case 'third':
          this.page_size2=row;
          this.page_index2=1;
          break;
        case 'fourth':
          this.page_size3=row;
          this.page_index3=1;
          break;
        case 'fifth':
          this.page_size4=row;
          this.page_index4=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(row){
      switch(this.activeName){
        case 'first':
          this.page_index=row;
          break;
        case 'second':
          this.page_index1=row;
          break;
        case 'third':
          this.page_index2=row;
          break;
        case 'fourth':
          this.page_index3=row;
          break;
        case 'fifth':
          this.page_index4=row;
          break;
      }
      this.init()
    },
    receive(row,state) {//0 查看合同 1制作合同 2载入合同
      this.itemrow=row;
      switch(state){
        case '领取':
          this.$confirm(`您确认领取车牌号${row.carNo}的客户合同吗？`, "领取合同", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
        .then(() => {
          this.getCustomerCheck(row.id,'get')//领取
        })
        .catch(() => {});
          break;
        case '维保查询':
          this.$api.getMaintenanceReportQuery({vin:row.vinCode}).then(res=>{
            if(res.code==0){
              if(res.data.status===3){
                window.open(res.data.result,'_blank')
                //  window.open('https://newsys-test02.oss-cn-shanghai.aliyuncs.com/1689758972439.html?state=1','_blank')
              }else{
                this.$message.warning(res.data.result)
              }
            }
          })
          break;
        case 1://制作合同
          const pathData2=this.$router.resolve({
                path:'/salesManager/contractMaking',
                query: { 
                  id:row.id,
                  title:'制作客户合同'
                }
            })
          window.open(pathData2.href,'_blank')
          // this.$router.push({
          //   path: '/salesManager/contractMaking',
          //   query: { 
          //     id:row.id,
          //     title:'制作客户合同'
          //   }
          // })
          break;
        case 0://查看合同
        case 2://载入合同
          const pathData=this.$router.resolve({
              path:'/salesManager/contractMaking',
              query: { 
                id:row.id,
              }
          })
          window.open(pathData.href,'_blank')
          // this.$router.push({
          //   path: '/salesManager/contractMaking',
          //   query: { 
          //     id:row.id
          //   }
          // })
          break;
        case 3://修改账号
          this.$router.push({
            path:'/salesManager/accountChangeDetail',
            query:{
              id:row.id
            }
          })
          break;
        case 4://回退到待领取
          this.$confirm(`您确定将车牌号为${row.carNo}的客户合同回退到待领取吗？注意：渠道车最好由渠道部营销部的员工来回滚，否则库存和物流恢复不了`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$api.postSignFailRollback(row.id).then(res=>{
              if(res.code==0){
                this.$message.success(res.message)
                this.init()
              }
            })
          }).catch(() => {});
          break;
        
      }
    },
    getlistTradeOwned(){//成交归属下拉 列表
      this.$api.getlistTradeOwned('帮卖经理').then((res)=>{
        if(res){
          if(res.code==0){
            this.sellerNamelist=res.data;
          }else{
            this.sellerNamelist=[];
          }
        }
      })
    },
    getCustomerCheck(contractId,checkType){//领取/制作/签订 合同
    //checkType操作类型，领取get, 制作make, 签订成功sign_success，签订失败sign_fail
      this.$api.getCustomerCheck(contractId,checkType).then((res)=>{
        if(res){
          if(res.code==0){
            this.getPageSize()
            this.init();
            this.$message.success(res.message);
          }
        }
      })
    },
   
    getShopList(){//搜索门店
      return new Promise((resolve, reject)=>{
        let user=this.$store.state.user.userData;
        let role=this.$$.roleName(user)
        let de=this.$$.roleName(user,'department')
        this.$api.getListShopByRoleId(role.id).then(res=>{
          if(res.code==0){
            for(let i=0;i<res.data.length;i++){
              let item=res.data[i]
              if(de.shop){
                if(item.id===de.shop.id){
                  this.params.tradeShopId=item.id;
                  this.params1.tradeShopId=item.id;
                  this.params2.tradeShopId=item.id;
                  this.params3.tradeShopId=item.id;
                  this.params4.tradeShopId=item.id;
                }
              }
            }
            this.shoplist=res.data;
          }
          resolve(true)
        })
      })
    },
  },
};
</script>

<style scoped lang="scss">
.signContract {
  padding: 20px 40px;
}
</style>