<template>
  <div class="currencyConterBox">
    <el-row :gutter="24" class="">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车型代码</span>
        <el-input v-model="forms.modelCode" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>目录日期</span>
         <el-date-picker
          v-model="forms.recordDate"
          type="date" size="medium"
          placeholder="选择日期"
          value-format="yyyy-MM-dd">
        </el-date-picker>
        <!-- <el-input v-model="forms.recordDate" size="medium"></el-input> -->
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>发动机型号</span>
        <el-input v-model="forms.engineModel" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车辆型号</span>
        <el-input v-model="forms.carModel" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>排放标准</span>
        <el-input v-model="forms.emissionStd" size="medium"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt10 flex flex_end">
      <el-button type="primary" @click="init(1)" size="medium" class="" plain>搜索</el-button
      >
      <el-button type="primary" @click="onAdd" size="medium" class="" plain
        >新增</el-button
      >
      <el-button type="primary" @click="onBatch" size="medium" class="" plain
        >批量新增</el-button
      >
      <el-button
        type="primary"
        @click="onExport"
        size="medium"
        class="mr10"
        plain
        >导出</el-button
      >
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <!-- <el-table-column
        prop="modelId"
        label="车型ID"
        align="center"
      ></el-table-column> -->
      <el-table-column
        prop="modelCode"
        label="车型代码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recordDate"
        label="目录日期"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="engineModel"
        label="发动机型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carModel"
        label="车辆型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="emissionStd"
        label="排放标准"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createBy"
        label="创建人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="updateDate"
        label="更新时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="updateBy"
        label="更新人"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="primary" @click="onUpdate(scope.row)" size="mini"
            >修改</el-button
          >
          <el-button type="primary" @click="onDelect(scope.row)" size="mini"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="550px">
      <el-form
        ref="form"
        :model="itemRow"
        v-show="modalType != 2"
        label-width="120px"
      >
        <el-form-item label="车型代码">
          <el-input
            v-model="itemRow.modelCode"
            class="w200"
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="目录日期">
          <el-date-picker
            v-model="itemRow.recordDate"
            type="date" size="medium"
            placeholder="选择日期" class="w200"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发动机型号">
          <el-input
            v-model="itemRow.engineModel"
            class="w200"
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="车辆型号">
          <el-input
            v-model="itemRow.carModel"
            class="w200"
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="排放标准">
          <el-input
            v-model="itemRow.emissionStd"
            class="w200"
            size="medium"
          ></el-input>
        </el-form-item>
      </el-form>
      <div v-show="modalType == 2">
        <el-form ref="form" :model="itemRow">
          <el-form-item label="操作方式:">
            <el-radio-group v-model="itemRow.radio3" size="small">
              <el-radio-button label="添加数据"></el-radio-button>
              <el-radio-button label="更新数据"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择文件">
            <el-upload ref="upload"
                :auto-upload="false"
                :show-file-list="false"
                action=""
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :http-request="httpRequest"
                :on-change="handleChange">
              <el-input v-model="filename" size="medium" readonly>
                <template slot="append">浏览</template>
              </el-input>
            </el-upload>
          </el-form-item>
        </el-form>
        <p>注意：</p>
        <p>1、请先选择操作方式，再进行数据导入的操作；</p>
        <p>2、请选择XLS格式的文件进行上传，数据量限制在10000条以下；</p>
        <p>3、当提示开始导入数据时，请耐心等待，文件越大等待时间越长；</p>
        <p>4、10000条数据的响应时间大约10分钟左右。</p>
        <p>5、添加新数据时，ID为空；更新数据时，ID要和老数据保持一致。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit" >{{modalType==2?'开始上传':'保存'}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {},
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      itemRow: {},
      title: "新增车辆排放标准",
      modalType: 0, //0-新增，1-修改，2-批量新增
      filename: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        "curPage": this.currentPage,
        "modelCode": this.forms.modelCode,//车型代码
        "pageSize": this.pageSize,
        "recordDate": this.forms.recordDate,//目录日期
        "engineModel": this.forms.engineModel,//发动机型号
        "carModel": this.forms.carModel,//车辆型号
        "emissionStd": this.forms.emissionStd,//排放标准
      }
      this.$api.postEmissionQuery(query).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize=e;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage=e;
      this.init();
    },
    onAdd() {
      this.dialogVisible = !this.dialogVisible;
      this.title = "新增车辆排放标准";
      this.itemRow = {};
      this.modalType = 0;
    },
    onUpdate(row) {
      this.dialogVisible = !this.dialogVisible;
      this.title = "修改车辆排放标准";
      this.itemRow = JSON.parse(JSON.stringify(row));
      this.modalType = 1;
    },
    onBatch() {
      //批量上传
      this.dialogVisible = !this.dialogVisible;
      this.title = "批量上传";
      this.modalType = 2;
    },
    onSubmit(){
      switch(this.modalType){ //0-新增，1-修改，2-批量新增
        case 0:
          this.postEmissionAdd()
          break;
        case 1:
          this.updateEmission()
          break;
        case 2:
          this.$refs.upload.submit();
          break;
      }
    },
    onDelect(row) {
      this.$api.deleteEmission(row.id).then(res =>{
        if(res.code==0){
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    onExport() {
      let query = {
        "modelCode": this.forms.modelCode,//车型代码
        "recordDate": this.forms.recordDate,//目录日期
        "engineModel": this.forms.engineModel,//发动机型号
        "carModel": this.forms.carModel,//车辆型号
        "emissionStd": this.forms.emissionStd,//排放标准
      }
      this.$api.exportCarEmission(query).then(res=>{
        this.$$.postExcle(res)
      })
    },
    postEmissionAdd(){//车型排放标准 新增
      let query={
        "modelCode": this.itemRow.modelCode,
        "recordDate": this.itemRow.recordDate,
        "engineModel": this.itemRow.engineModel,
        "carModel": this.itemRow.carModel,
        "emissionStd": this.itemRow.emissionStd,
      }
      this.$api.postEmissionAdd(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.dialogVisible=!this.dialogVisible;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    updateEmission(){//车型排放标准 更新
      let query={
        "modelCode": this.itemRow.modelCode,
        "recordDate": this.itemRow.recordDate,
        "engineModel": this.itemRow.engineModel,
        "carModel": this.itemRow.carModel,
        "emissionStd": this.itemRow.emissionStd,
      }
      this.$api.updateEmission(this.itemRow.id,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.dialogVisible=!this.dialogVisible;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    handleChange(data){//选择文件
      this.filename=data.name;
    },
    httpRequest(data){//上传文件
      const formData = new FormData() //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append('file', data.file) //添加文件对象
      this.$api.importCarEmissionList(formData).then(res =>{
        if(res.code==0){
          this.init()
          this.dialogVisible=!this.dialogVisible;
          this.$message.success(res.message)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
  .rowbox {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    .line {
      width: 15px;
      height: 1px;
      background-color: #999999;
      margin: 0 5px;
    }
  }
}
</style>