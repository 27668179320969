<template>
  <div class="capacityTable">
    <div :class="{'disabledTable':!operable, 'table':true}">
      <div class="tr thead">
        <div class="td store">门店名称</div>
        <div class="td cap">{{title?title:'检测产能'}}</div>
      </div>
      <template v-if="tableList.length>0">
        <div class="tr tbody" v-for="item in tableList" :key="item.id">
          <div class="td store">{{item.shopName}}</div>
          <div :class="{'num9': item.checkTimeCapacity.length>9,'cap':true, 'num18': item.checkTimeCapacity.length>18}">
            <div v-for="i in item.checkTimeCapacity" :key="i.time" :class="{'isBackup': i.isBackup,'td':true,'blueBG':i.isOccupy}" @click="clickTime(item,i)" class="cursor">
              <div class="tdRow" v-if="i.isBackup">{{state==1?`(${i.isBackup})`:'候补+'}}</div>
              <div class="tdRow">{{i.time}}</div>
              <div class="tdRow taborder" :class="i.color===2?'tipsColor':i.color===1?'yellowColor':''">{{i.haveBeenBookedCount}}单</div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="noData">暂无数据</div>
    </div>
    <el-dialog title="检测预约" :visible.sync="dialogVisible" center class="customDialog" @close="onClose">
      <el-row :gutter="20">
        <el-col :span="12"><span>客户姓名：{{ruleForm.customerName}}</span></el-col>
        <el-col :span="12" v-if="ruleForm.isMortgage == '是' || ruleForm.isMortgage == '1'"><span>抵押车</span></el-col>
      </el-row>
      <el-row :gutter="20" class="mt10">
        <el-col :span="12"><span>车牌号码：{{ruleForm.carNo}}</span></el-col>
        <el-col :span="12"><span>品牌车型：{{ruleForm.carType}}</span></el-col>
      </el-row>
      <el-row :gutter="20" class="mt10 mb20">
        <el-col :span="12"><span>检测时间：{{bookDay}}  {{ruleForm.curDate}}</span></el-col>
        <el-col :span="12"><span>检测门店：{{ruleForm.storeName}}</span></el-col>
      </el-row>
      <el-form :model="form" label-width="100px">
        <el-form-item label="询价类型：">
          <el-radio-group v-model="form.subType">
            <el-radio  v-for="item in checkTypeList" :key="item.type" :label="item.type" class="mt10">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="form.remark" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitBook">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    tableList: {//总数据
      type: Array,
      default:function(){
        return []
      }
    },
    operable: {
      type: Boolean,
      default: false,
    },
    state:{//从什么页面过来，1成交预约
      type:Number
    },
    title:{
      type:String
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        remark: '',
        subType: '',
      },
      checkTypeList: [],
      followUpInfoId: '',
      ruleForm: {
        customerName: null,
        carNo: '',
        isMortgage: '',
        carType: '',
        curDate: '',
        storeName: '',
        storeNo: '',
        id: '',
        isBackup: 0,
      },
      bookDay:'',
      res:{},
      shopFlage:false,//是否打开门店资源
      itemRows:{},
      itemRow:{},
      shopRes:{},//门店返回资源对象
    }
  },
  created(){
    this.getCheckBookCheckType()//获取检测类型集合
  },
  mounted() {
    if(this.operable){
      if(this.$route.query.followUpInfoId){
        this.followUpInfoId = this.$route.query.followUpInfoId;
        this.customerQueryCustomerByFollowUpId(this.followUpInfoId);
      }else{
        this.$message.warning('未获取用户编号')
      }
    }
  },
  methods: {
    clickTime(rows,row) {
      this.itemRows=rows;
      this.itemRow=row;
      let query={
        "shopId": rows.shopId,
        "date": rows.date,
        "planTime": row.time,
        // "resourceNo": 0,
        "customerId": this.res.id,
        "status": 3//状态 0-空闲 3-未确认 4-关闭
      }
      if(this.state==1){//成交预约页面
        let obj={
          "date": rows.date,
          "planTime": row.time,
        }
        this.$emit('clickTime',obj)
      }else{
        this.postCheckResourceShopSwitch(query)
        if(!this.operable){
          return
        }
        this.ruleForm.curDate = row.time;
        this.ruleForm.storeName = rows.shopName;
        this.ruleForm.storeNo = rows.shopId;
        this.bookDay=rows.date;
      }
      
      // this.ruleForm.isBackup = isBackup
    },
    onClose(){
      if(!this.shopFlage){
        let query={
          "shopId": this.itemRows.shopId,
          "date": this.itemRows.date,
          "planTime": this.itemRow.time,
          "resourceNo": this.shopRes.resourceNo,
          "customerId": this.res.id,
          "status": 0//状态 0-空闲 3-未确认 4-关闭
        }
        this.postCheckResourceShopSwitch(query)
      }
      
    },
    // 客户详情查询
    customerQueryCustomerByFollowUpId(followUpInfoId) {
      this.$api.customerQueryCustomerByFollowUpId(followUpInfoId).then(res => {
        if(res.code == 0){
          this.res=res.data;
          this.ruleForm.customerName = res.data.customerName;
          this.ruleForm.carNo = res.data.carNo;
          this.ruleForm.carType = res.data.brand+ '-' +res.data.series;
          this.ruleForm.isMortgage = res.data.isMortgage;
          this.ruleForm.id = res.data.id;
        }
      })
    },
    submitBook() {
      if(!this.shopRes.id){
        this.$message.warning('请重新选择时间！')
        return
      }
      let query={
        "checkBookId": this.res.checkBookId,//检测预约Id(改约时需填)
        "customerId": this.res.id,
        "resourceId": this.shopRes.id,//门店已占用资源Id
        "checkType": this.form.subType,
        "remark": this.form.remark
      }
      this.$api.postCheckBookShopSubmit(query).then(res=>{
        if(res.code==0){
          this.shopFlage=true;
          this.dialogVisible = false;
          this.$message.success(res.message)
          this.bus.$emit("closeSelected");
          // this.customerQueryCustomerByFollowUpId(this.followUpInfoId);
        }
      })
    },
    getCheckBookCheckType(){//获取检测类型集合
      this.$api.getCheckBookCheckType({}).then(res=>{
        if(res.code==0){
          this.checkTypeList=res.data;
        }
      })
    },
    postCheckResourceShopSwitch(query){//门店检测资源关闭或打开
      this.$api.postCheckResourceShopSwitch(query).then(res=>{
        if(res.code==0){
          this.shopRes=res.data;
          if(query.status==0){//空闲
            this.shopFlage=true;
            this.dialogVisible = false;
            this.$emit('init')
          }else{
            this.shopFlage=false;
            this.dialogVisible = true;
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.capacityTable{
  .table{
    border: 1px solid #CCCCCC;
    .tr{
      display: flex;
      align-items: stretch;
      border-bottom: 1px solid #CCCCCC;
      &:last-child{border-bottom: none;}
      &:nth-child(2n+1){background: #EAF4FD;}
      .td{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        flex-wrap: wrap;
      }
      .cap{
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        border: none;
        .td{
          width: 11.111111%;
          border-right: 1px solid #CCCCCC;
          // height: 100px;
          &:nth-child(9){border-right: none;}
        }
        .isBackup {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #0099FF;
          span:first-child{
            margin-bottom: 10px;
          }
        }
      }
      .store{
        border-right: 1px solid #CCCCCC;
        cursor: auto;
      }
      .num9>.td:nth-child(-n+9){border-bottom: 1px solid #CCCCCC;;}
      .num18>.td:nth-child(-n+18){border-bottom: 2px solid #0099FF;}
      .tdRow{
        width:100%;
        text-align:center;
        height: 50px;
        line-height: 50px;
      }
      .taborder{
        border-top:1px solid #cdcdcd;
      }
    }
    .thead{
      height: 50px;
    }
  }
  .disabledTable .tr .td {cursor: auto;}
  .noData{height: 50px; text-align: center;line-height: 50px;color: #aaa;}
}
</style>
