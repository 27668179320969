<template>
  <div class="contentPage">
    <div class="clear">
      <el-button type="primary" @click="onAdd" size="medium" class="fr mb20" plain>新增标准项目</el-button>
    </div>
    <el-table :data="querylist" border stripe class="table-mainbg" height="700">
      <el-table-column prop="itemName" label="标准项目" align="center"></el-table-column>
      <el-table-column prop="plateType" label="牌照归属" align="center"></el-table-column>
      <el-table-column prop="ownerType" label="持有人性质" align="center">
        <template slot-scope="scope">{{scope.row.ownerType?scope.row.ownerType:'-'}}</template>
      </el-table-column>
      <el-table-column prop="feeBearerName" label="承担方" align="center"></el-table-column>
      <el-table-column prop="chargingFeeAmount" label="收取金额" align="center"></el-table-column>
      <el-table-column prop="beneficiaryName" label="受益方" align="center"></el-table-column>
      <el-table-column prop="payFeeAmount" label="支付金额" align="center"></el-table-column>
      <el-table-column prop="profitName" label="获利方" align="center">
         <template slot-scope="scope">{{scope.row.profitName?scope.row.profitName:'-'}}</template>
      </el-table-column>
      <el-table-column prop="profitAmount" label="获利金额" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row,1)" size="mini">编辑</el-button>
          <el-button type="primary" @click="onapply(scope.row,2)" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination> -->
    <el-dialog
      :title="`${textModel}标准项目`"
      :visible.sync="dialogVisible" class="customDialog"
      width="700px">
      <el-form ref="form" :model="itemRow" label-width="120px" size="medium" :inline="true" :rules="rules" >
        <el-form-item label="项目名称" prop="itemName">
          <el-input v-model="itemRow.itemName" class="w200"></el-input>
        </el-form-item>
        <el-form-item label="牌照归属" prop="plateType">
          <el-select v-model="itemRow.plateType" class="w200" placeholder="请选择">
            <el-option value="沪牌"></el-option>
            <el-option value="非沪牌"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="持有人性质">
          <el-select v-model="itemRow.ownerType" class="w200" placeholder="请选择">
            <el-option value="公司"></el-option>
            <el-option value="个人"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="承担方" prop="feeBearerId">
          <el-select v-model="itemRow.feeBearerId" class="w200" placeholder="请选择">
            <el-option v-for="item in whichSidelistId" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收取费用" prop="chargingFeeAmount">
           <el-input v-model.number="itemRow.chargingFeeAmount" class="w200"></el-input>
        </el-form-item>
        <el-form-item label="受益方" prop="beneficiaryId">
          <el-select v-model="itemRow.beneficiaryId" class="w200" placeholder="请选择">
            <el-option v-for="item in whichSidelistId" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付费用" prop="payFeeAmount">
          <el-input v-model.number="itemRow.payFeeAmount" class="w200"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="payUnit">
           <el-input v-model="itemRow.payUnit" class="w200"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], 
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dialogVisible:false,
      textModel:'新增',
      whichSidelistId:[],
      itemRow:{},
      rules: {
        itemName: [{ required: true, message: '不可为空', trigger: 'blur' }],
        plateType: [{ required: true, message: '不可为空', trigger: 'change' }],
        ownerType: [{ required: true, message: '不可为空', trigger: 'change' }],
        feeBearerId: [{ required: true, message: '不可为空', trigger: 'change' }],
        chargingFeeAmount: [{ required: true, message: '不可为空', trigger: 'blur' }],
        beneficiaryId: [{ required: true, message: '不可为空', trigger: 'change' }],
        payFeeAmount: [{ required: true, message: '不可为空', trigger: 'change' }],
        payUnit: [{ required: true, message: '不可为空', trigger: 'blur' }],
      }
    };
  },
  created() {
    this.whichSidelistId=this.$store.state.setting.whichSidelistId;
    this.init();
  },
  methods: {
    init() {
      let query = {
        // curPage: this.currentPage, //当前页数
        // pageSize: this.pageSize,
        types:[1]//类型：1标准服务收费，2延伸服务收费，3办证逾期收费
      };
      this.$api.postFeeStandardList(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data;
            // this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onSubmit(){
      if(this.textModel=='新增'){
        this.postFeeStandardAdd()
      }else{
        this.updateFeeStandard()
      }
    },
    postFeeStandardAdd(){//新增
      let type=false;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          type=true;
        }
      });
      if(!type){
        return
      }
      let query={
        "itemName": this.itemRow.itemName,
        "plateType": this.itemRow.plateType,
        "ownerType": this.itemRow.ownerType,
        "feeBearerId": this.itemRow.feeBearerId,//承担方：1客户,2商户,3车开新,4办证公司，5违章供应商
        "chargingFeeAmount": this.itemRow.chargingFeeAmount,
        "feeUnit": this.itemRow.payUnit,
        "beneficiaryId": this.itemRow.beneficiaryId,//受益方：1客户,2商户,3车开新,4办证公司，5违章供应商
        "payFeeAmount": this.itemRow.payFeeAmount,
        "payUnit": this.itemRow.payUnit,
        // "profitName": "xx",
        "type": 1//类型：1标准服务收费，2延伸服务收费，3办证逾期收费
      }
      this.$api.postFeeStandardAdd(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.dialogVisible=!this.dialogVisible;
        }
      })
    },
    updateFeeStandard(){
      let type=false;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          type=true;
        }
      });
      if(!type){
        return
      }
      let query={
        "itemName": this.itemRow.itemName,
        "plateType": this.itemRow.plateType,
        "ownerType": this.itemRow.ownerType,
        "feeBearerId": this.itemRow.feeBearerId,
        "chargingFeeAmount": this.itemRow.chargingFeeAmount,
        "feeUnit": this.itemRow.payUnit,
        "beneficiaryId": this.itemRow.beneficiaryId,
        "payFeeAmount": this.itemRow.payFeeAmount,
        "payUnit": this.itemRow.payUnit,
        // "profitName": "xx",
        "type": 1//类型：1标准服务收费，2延伸服务收费，3办证逾期收费
      }
      this.$api.updateFeeStandard(this.itemRow.id,query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.dialogVisible=!this.dialogVisible;
        }
      })
    },
    onAdd(){
      this.itemRow={};
      this.dialogVisible=true;
      this.textModel='新增';
      this.$nextTick(()=>{
        this.$refs['form'].clearValidate();
      })
    },
    onapply(row,state) {
      this.itemRow=JSON.parse(JSON.stringify(row));
      if(state==1){
        this.dialogVisible=true;
        this.textModel='修改';
        this.$nextTick(()=>{
          this.$refs['form'].clearValidate();
        })
      }else{
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deleteFeeStandard(row.id).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
              this.init()
            }
          })
        }).catch(() => {});
      }
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.contentPage {
  padding: 40px;
}
</style>