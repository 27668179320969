<template>
  <!-- 差异处理 -->
  <div class="handleDif">
    <div class="ckxTitle">商户信息</div>
    <div class="mt10">
      <span class="label">商户编号：</span><span class="value">{{carMerchant.merchantNo}}</span>
    </div>
    <div class="flex mt10 merchantInfo">
      <div><span class="label">商户名次：</span><span class="value">{{res.merchantOrder}}</span></div>
      <div><span class="label">商户电话：</span><span class="value mr30">{{carMerchant.merchantMobile}}</span><el-button size="mini" type="primary" plain v-if="carMerchant.merchantMobile" @click="callPhone(carMerchant.merchantMobile)">呼叫</el-button></div>
    </div>
    <div class="flex mt10 merchantInfo">
      <div><span class="label">商户名称：</span><span class="value">{{res.merchantName}}</span></div>
      <div><span class="label">商户电话2：</span><span class="value mr30">{{carMerchant.merchantOtherMobile}}</span><el-button size="mini" type="primary" plain v-if="carMerchant.merchantOtherMobile" @click="callPhone(carMerchant.merchantOtherMobile)">呼叫</el-button></div>
    </div>
    <div class="flex mt10 merchantInfo">
      <div><span class="label">商户注册地：</span><span class="value">{{carMerchant.registerCityName}}</span></div>
      <div><span class="label">商户维护员：</span><span class="value mr30">{{carMerchant.maintainerName}}</span></div>
    </div>
    <div class="flex mt10 merchantInfo">
      <div><span class="label">商户经营地：</span><span class="value">{{carMerchant.businessAddress}}</span></div>
      <div><span class="label">商户维护员手机：</span><span class="value mr30">{{carMerchant.maintainerPhone}}</span><el-button size="mini" type="primary" plain @click="callPhone(carMerchant.maintainerPhone)">呼叫</el-button></div>
    </div>
    <!-- <div class="mt20 red mb20">商户已确认提差异，请尽快联系商户。</div> -->
    <el-tabs v-model="activeName"  type="card"  class="mt10">
      <el-tab-pane label="复检结果" name="first" class="mt20">
        <div class="line flex flex_align_center flex_center">
          <span class="mr30">复检时间：{{result1.uploadTime}}</span>
          <span class="mr30">检测师：{{result1.checkerName}}</span>
          <span class="mr30">车型：{{result1.carType}}</span>
          <span class="mr30">车牌号：{{result1.carNo}}</span>
        </div>
        <div class="nodiff" v-if="result1.checkDiffList.length==0">{{res.recheckReportId?'无差异':''}}</div>
        <el-row :gutter="50" v-for="(item,index) in result1.checkDiffList" :key="item.id">
          <el-col :span="6">
            <div class="mt20 mb20 strong">差异{{index+1}}</div>
            <table>
              <tr><td>{{item.differenceType==0?'类型程度不准确':item.differenceType==1?'原报告未描述':item.differenceType==2?'非差异':''}}</td></tr>
              <tr><td>{{item.differenceFuc1?'实车':''}}{{item.differenceFuc1&&item.differenceFuc2?'、':''}}{{item.differenceFuc2?'记录':''}}{{item.differenceFuc2&&item.differenceFuc3||item.differenceFuc1&&item.differenceFuc3?'、':''}}{{item.differenceFuc3?'路试':''}}</td></tr>
              <tr><td>{{item.judgeType==1?'外观':item.judgeType==2?'内饰':item.judgeType==3?'机械':item.judgeType==4?'骨架':item.judgeType==5?'识别':item.judgeType==6?'手续':''}}</td></tr>
              <tr><td>{{item.judgeContent}}</td></tr>
              <tr><td>{{item.finalPunishType}}</td></tr>
            </table>
          </el-col>
          <el-col :span="18">
            <div class="imgList">
              <el-image class="imgBox"
              v-for="(ite,indexx) in item.photoList" :key="`${indexx}_img`" :src="ite"
              :preview-src-list="[ite]">
                <div slot="error">暂无图片</div>
              </el-image>
            </div>
          </el-col>
        </el-row>
        <div class="pb100">
          <div class="ckxTitle mt20 mb10">其他备注</div>
          <el-input type="textarea" v-model="result1.remark" :autosize="{minRows: 4}" readonly="readonly"></el-input>
          <!-- <div class="mt15" v-if="res.isTrade!==null">商户沟通结果：{{res.isTrade===1?'确认成交':'放弃成交'}}</div> -->
        </div>
      </el-tab-pane>
      <el-tab-pane label="初检报告" name="second" class="mt20">
        <Report :res="checkRes" state="3" ref="report" :noPosition="false"></Report>
      </el-tab-pane>
    </el-tabs>
    <!--state:1查看-->
    <div class="footer" v-if="state!=1">
      <div class="btn">
        <!-- <el-button type="primary" @click="onSubmit(1)" v-if="res.isTrade===null">确认成交</el-button>
        <el-button type="primary" @click="onSubmit(2)" v-if="res.isTrade===null">放弃成交</el-button> -->
        <el-button type="primary" v-if="!res.diffValid" @click="onSubmit(5)">去签合同</el-button>
        <el-button type="primary" @click="onSubmit(3)" v-if="!res.diffValid">提差异</el-button>
        <el-button type="primary" v-if="res.diffValid" @click="onSubmit(4)">已提差异</el-button>
        <!-- <el-button type="primary" v-if="res.isTrade==1" @click="onSubmit(5)">查看合同</el-button> -->
        
      </div>
    </div>

    <!-- 提差异 -->
    <el-dialog title="商户提差异" :visible.sync="dialogVisible" class="customDialog">
      <table class="dialogTable">
        <tr v-for="item in checkboxList" :key="item.title">
          <td><el-checkbox v-model="item.checked" @change="checkChange" :disabled="item.disabled||res.diffValid?true:false">{{item.title}}</el-checkbox></td>
          <td>
            <el-input v-show="item.checked" v-model="item.content" :disabled="item.disabled||res.diffValid?true:false"></el-input>
          </td>
        </tr>
      </table>
      <el-form :model="form" ref="form" label-width="160px" class="mt20" >
        <el-form-item label="请选择核验检测师">
          <el-select v-model="form.checkerId" :disabled="res.diffValid?true:false">
            <el-option v-for="item in InspectorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- <el-input v-model="form.name" autocomplete="off"></el-input> -->
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.remark" :disabled="res.diffValid?true:false"></el-input>
        </el-form-item>
      </el-form>
      <div class="mt20" style="text-align:center;">
        <el-button type="primary" plain @click="submits" v-if="!res.diffValid">提交</el-button>
        <el-button type="primary" plain @click="dialogVisible=false" v-if="res.diffValid">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Report from '@/components/common/report';//检测报告
export default {
  components: {
    Report
  },
  data() {
    return {
      activeName: 'first',
      dialogVisible: false,
      remark: '',
      checkboxList: [],
      validType:'',//验车-查验方式：51现场查验，52远程查验
      carId:'',
      carMerchant:{},//车辆商户信息
      form:{
        name:'',
        remark:'',
      },
      rules:{
        name:[{ required: true, message: '不能为空！', trigger: 'change' }]
      },
      InspectorList:[],//检测师
      isSureSubmitDifferenceName:'否',
      res:{},//商户车辆详情
      result1:{
        checkDiffList:[]
      },//复检报告详情
      checkRes:{},//初检报告
      recheckReportId:'',//复检报告id
      carValidId:'',
      state:'',//查看
      userData:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
    }
  },
  created(){
    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    this.checkboxList=JSON.parse(JSON.stringify(this.$store.state.setting.diffTypeslist));//差异类型

    if(this.$route.query.carValidId) {//获取
      this.carValidId=this.$route.query.carValidId;
      this.getValidDetail(this.carValidId) //获取验车详情
    }
    if(this.$route.query.state) this.state=this.$route.query.state;
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
      })
    }
    this.queryUserListByRoleAndDepart()//交付中心检测师

  },
  methods: {
    checkChange(){
      var arr=[]
      for(let i = 0;this.checkboxList.length>i;i++){
        let item=this.checkboxList[i]
        if(this.checkboxList[i].checked==true){
          arr.push(item)
        }
        if(arr.length>2){
          for(let j=0;j<this.checkboxList.length;j++){
            if(!this.checkboxList[j].checked){
              this.checkboxList[j].disabled=true
            }
          }
        }else{
          item.disabled=false
        }
      }
    },
    getValidDetail(carValidId){//根据 车辆ID 查询验车详情
      this.$api.getValidDetail(carValidId).then((res) => {
        if(res.code==0){
          if(res.data.recheckReportId){
            this.getRecheckReport(res.data.recheckReportId);//获取复检报告
          }
          if(res.data.checkReportId){
            this.getCheckReport(res.data.checkReportId)//获取详情(复检详情初检报告)
          }

          this.validType=res.data.validType;////验车-查验方式：51现场查验，52远程查验
          this.res=JSON.parse(JSON.stringify(res.data));
          this.carMerchant=res.data.merchant?res.data.merchant:{}
        }
      })
    },
    getRecheckReport(recheckReportId){//获取复检报告
      let query={
        reportId:recheckReportId,
      }
      this.$api.getRecheckReport(query).then(res=>{
        if(res.code==0){
          this.result1=res.data;
        }
      })
    },
    getCheckReport(checkReportId){//获取复检报告
      this.$api.getCheckReport(checkReportId).then(res=>{
        if(res.code==0){
          this.checkRes=res.data;
        }
      })
    },
    queryUserListByRoleAndDepart(){//获取交付中心的检测师
      let query={
        "departmentName": "交付中心",
        roleName:'检测师'
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.InspectorList=res.data?res.data:[]
        }
      })
    },
    onSubmit(state){
      switch(state){
        case 1:// 确认成交
          this.$confirm('确认商户验车通过，并继续交易！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postValidTradeConfirm(this.carValidId,1)
          }).catch(() => {});
          break;
        case 2://放弃成交
          this.$confirm('确认商户放弃成交！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postValidTradeConfirm(this.carValidId,0)
          }).catch(() => {});
          break;
        case 3://提差异
          this.dialogVisible=true;
          break;
        case 4://已提差异
          this.dialogVisible=true;
          this.form.checkerId=this.res.checkerId;
          this.form.remark=this.res.diffRemark;
          if(this.res.diffValid.carDiffDetails){
            this.checkboxList.map((obj)=>{
              this.res.diffValid.carDiffDetails.map(item =>{
                if(obj.title===item.diffTypeName){
                  obj.title=item.diffTypeName;
                  obj.content=item.diffDesc;
                  obj.checked=true;
                }
              })
            })
          }
          break;
        case 5://查看合同
          this.$router.push({
            path:'/salesAssistant/merchantContract',
            query:{
              id:this.res.contractId,//商户合同ID
              // state:1//state:1表示仅查看
            }
          })
          break;
      }
    },
    submits() {
      var arr=[]
      for(let i = 0;this.checkboxList.length>i;i++){
        let item=this.checkboxList[i]
        if(this.checkboxList[i].checked==true){
          let obj={
            diffType:item.id,
            diffDesc:item.content
          }
          arr.push(obj)
        }
      }
      
      if(arr.length==0){
        this.$message.warning('请选择差异！')
        return
      }
      if(!this.form.checkerId){
        this.$message.warning('请选择核验检测师！')
        return
      }
      // console.log(arr,'------')
      let query={
        "carValidId": this.carValidId,//车辆ID, 新增时必填，修改时不填
        "diffTypes": arr,//差异类型：51外观，52内饰，53机械，54骨架，55识别，56手续
        "checkerId": this.form.checkerId,//核验检测师
        "diffRemark": this.form.remark//商户提差异的备注
      }
      this.$api.postSubmitDiff(this.$$.delete_null_properties(query)).then(res => {
        if(res.code==0){
          this.$message.success(res.message)
          this.dialogVisible=!this.dialogVisible;
          this.routePage()
        }
      })
    },
    postValidTradeConfirm(carValidId,isTrade){//确认是否成交
      this.$api.postValidTradeConfirm(carValidId,isTrade).then(res=>{
        if(res.code==0){
          this.getValidDetail(this.carValidId) //获取验车详情
          this.$message.success(res.message)
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/salesAssistant/vehicleInspection'
      })
    },
    callPhone(row) {
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row)
      // })//根据员工工号查询坐席信息(拨打电话)
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.handleDif{
  padding: 20px 40px 140px;
  .label{width: 120px; text-align: right;display: inline-block;}
  .merchantInfo>div:nth-child(1){width: 400px;}
  .merchantInfo>div:nth-child(2) .label{width: 140px;}
  .mt30 {margin-top: 30px;}
  .strong{ font-weight: 700; }
  .line{
    height: 60px;
    background-color: #eeeeee;
  }
  table{
    width: 100%;
    border-collapse: collapse;
    td{
      border: 1px solid #ccc;
      padding: 10px 20px;
    }
  }
  .dialogTable td{height: 41px; padding: 0;}
  .dialogTable td:nth-child(1){
    width: 150px;
    padding-left: 35px;
  }
  .imgList{
    padding-top: 55px;
    .imgBox{
      width: 32%;
      // height: auto;
      height:250px;
      display: inline-block;
      margin-right: 2%;
      &:nth-last-child(1){margin-right: 0px;}
    }
  }
  .dialog-text{
    margin-bottom: 20px;
  }
  .nodiff{
    font-size:18px;
    text-align:center;
    padding:40px;
    margin-top: 20px;
    border: 1px solid #cccccc;
  }
}
</style>