<template>
  <div class="carDeliveryNote">
    <div class="title">物流发货单</div> 
    <div class="line">发货单号：{{logistNo}}</div>
    <DeliverInfo ref="deliverInfo" :shipperInfo="shipperInfo" :tradeShopName="tradeShopName" :takeDelivery="takeDelivery" :roleItem="roleItem" :state="state"></DeliverInfo>
    <template v-if="state!=1&&carId">
      <div class="ckxTitle">车辆信息</div>
      <el-table :data="logistCarInfo" border class="mt10 mb20 table-mainbg" stripe @selection-change="carChange" ref="multipleTable">
        <el-table-column type="selection" width="55" align="center"  v-if="state"></el-table-column>
        <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column label="品牌车型" align="center">
          <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
        </el-table-column>
        <el-table-column prop="carColor" label="颜色" align="center"></el-table-column>
        <el-table-column prop="registerDate" label="初次登记日期" align="center"></el-table-column>
        <el-table-column prop="checkExpire" label="年检有效期" align="center"></el-table-column>
        <!-- <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column> -->
      </el-table>
    </template>
    <MaterialsInfo ref="mList" :logistMaterialsList="logistMaterialsList" :roleItem="roleItem" :state="state" :carNo="res.carNo"></MaterialsInfo>
    <!-- <template v-if="state!=1">
      <div class="ckxTitle mb10">留存照片</div>
      <div class="flex">
        <div class="imgRowBox">
          <el-image v-if="imageUrl1||!state" :src="imageUrl1" class="avatar" :preview-src-list="[imageUrl1]">
            <div slot="error" class="errorText">暂无图片</div>
          </el-image>
          <i class="el-icon-delete iconDelete" @click="onIconDelect(1)" v-show="imageUrl1&&state"></i>
          <el-upload class="avatar-uploader " action               accept="image/png, image/gif, image/jpeg,image/jpg"
          v-if="!imageUrl1&&state" :show-file-list="false" :on-change="(file, fileList)=>requestUpload(file,fileList,1)" :auto-upload="false">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="imgName">油表指针</div>
        </div>
        <div class="imgRowBox">
          <el-image v-if="imageUrl2||!state" :src="imageUrl2" class="avatar" :preview-src-list="[imageUrl2]">
            <div slot="error" class="errorText">暂无图片</div>
          </el-image>
          <i class="el-icon-delete iconDelete" @click="onIconDelect(2)" v-show="imageUrl2&&state"></i>
          <el-upload class="avatar-uploader " action 
          accept="image/png, image/gif, image/jpeg,image/jpg" :show-file-list="false" :on-change="(file, fileList)=>requestUpload(file,fileList,2)" :auto-upload="false" v-if="!imageUrl2&&state">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="imgName">三元催化</div>
        </div>
      </div>
    </template> -->
    <template v-if="res.logistStatus===14">
      <div class="ckxTitle mt20">处置预警历史</div>
      <el-table :data="alarmList" border class="mt10 mb20 table-mainbg" stripe>
        <el-table-column prop="updateDate" label="处置时间" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="操作人" align="center"></el-table-column>
        <el-table-column prop="preArriveTime" label="修改预计到达时间" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      </el-table>
    </template>
    <div class="footer" v-if="state">
      <div class="btn">
        <el-button type="primary" @click="doSubmit('save')" v-if="state==2">保存</el-button>
        <el-button type="primary" @click="doSubmit('submit')" >提交发货单</el-button>
        <el-button type="primary" @click="onCancel" >作废</el-button></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DeliverInfo from '@/components/deliver/info.vue';
import MaterialsInfo from '@/components/deliver/materialsInfo.vue';
export default {
  components: {
    DeliverInfo, MaterialsInfo
  },
  data() {
    return {
      shipper:null,
      logistCarInfo: null,
      logistMaterialsList: [],
      shipperInfo: {
        logistType: 11,
        agencyName: '',
        agencyMobile: '',
        fromMobile: '',
        fromNo: '',
        expressCompName: '',
        expressLogistNo: '',
        fromTime: '',
        preArriveTime: ''
      },
      imageUrl1: null,
      imageUrl2: null,
      imageUrlObj1:{},
      imageUrlObj2:{},
      logistNo: null,
      tradeShopName: '',//发货方：
      takeDelivery:'',//收货方
      roleItem:{},//当前门店
      res:{},
      stockItem:{},//获取收货方
      state:'',//不显示车辆信息（新建专用）1新增，2修改，3查看
      alarmList:[],//预警处置历史记录
      id:'',
      userData:{},
      carChangelist:[],//选中车辆
      departTime:{},
      carId:'',
    }
  },
  created() {
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
      })
    }
    if(this.$route.query.state) this.state=this.$route.query.state;
    this.userData=this.$store.state.user.userData;
    this.roleItem=this.$$.roleName(this.userData,'department')
    this.departTime=this.$$.roleName(this.userData)
    // if(this.$route.query.carId) this.carId = this.$route.query.carId;
    if(this.$route.query.id){
      this.id=this.$route.query.id;
      this.logisticsDetail(this.$route.query.id);
    }else{
      this.tradeShopName=this.roleItem.name;//发货方：
    }
    if(this.state==1){
      this.getShopList()//根据查询门店收货方
    }
   
  },
  methods: {
    onCancel(){//作废
      this.$confirm('是否作废当前发货单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.getLogistDiscard(this.id).then(res=>{
          if(res.code==0){
            this.$message.success(res.message)
            this.routePage();
            // this.logisticsDetail(this.id)
          }
        })
      }).catch(() => {});
      
    },
    carChange(e){
      this.carChangelist=e;
    },
    logisticsDetail(id) {
      // let query={
      //   businessId:this.carId,
      //   businessType: "logistics"
      // }
      this.$api.logisticsDetail(id).then(res => {
        if(res.code === 0){
          this.res=res.data;
          this.alarmList=res.data.alarmList;
          
          let car=res.data.logistCarInfo?res.data.logistCarInfo:{};
          this.carId=car.carId;
          let row={
            id:car.carId,
            carNo:car.carNo,
            brandName:car.brandName,
            modelName:car.modelName,
            carColor:car.carColor,
            registerDate:car.registerDate,
            checkExpire:car.checkExpire,
          }
          this.logistCarInfo = [row];
          this.logistNo = res.data.logistNo;
          this.takeDelivery=res.data.toObjName;

          this.logistMaterialsList = res.data.logistMaterialsList;
          this.tradeShopName=res.data.logistStatus==11?this.roleItem.name:res.data.fromObjName;
          this.shipperInfo=res.data;
          if(res.data.imgList){
            if(res.data.imgList.length>0){
              let im=res.data.imgList.filter(item => item.sysFileBusinessList[0].businessName==='油表指针');
              if(im.length>0){
                this.imageUrl1 = im.reverse()[0].fullFilePath;
                this.imageUrlObj1=im.reverse()[0];
              }
            } 
          }
          if(res.data.imgList){
            if(res.data.imgList.length>0){
              let im = res.data.imgList.filter(item => item.sysFileBusinessList[0].businessName==='三元催化');
              if(im.length>0){
                this.imageUrl2=im.reverse()[0].fullFilePath;
                this.imageUrlObj2=im.reverse()[0];
              } 
            }
          }
          
          
          this.$nextTick(()=>{
            if(this.$refs.multipleTable){
              this.$refs.multipleTable.toggleAllSelection()
            }
            
            this.$forceUpdate()
          })
          if(this.state){
            this.$nextTick(()=>{
              this.$refs.deliverInfo.$refs.shipperInfo.clearValidate();
            })
          }
          
        }
      })
    },
    getShopList(){//根据仓库名称查询详情查询
      let query={
        shopName:'交付中心',
        isEnabled:0
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.stockItem=res.data?res.data[0]:{};
          this.takeDelivery=this.stockItem.shopName;
        }
      })
    },
    requestUpload(file,fileList,id) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.res.carId);
      if(id === 1){
        obj.append("businessName", '油表指针');
      }else if(id === 2){
        obj.append("businessName", '三元催化');
      }
      obj.append("businessType", "logistics");
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          if(id === 1){
            this.imageUrl1 = res.data[0].fullFilePath;
          }else if(id === 2){
            this.imageUrl2 = res.data[0].fullFilePath;
          }
          
          // this.logisticsDetail(this.$route.query.id); //获取详情
          this.$message.success("上传成功");
        }
      });
    },
    doSubmit(operaType) {
      this.$refs.deliverInfo.$refs.shipperInfo.validate(valid => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.$refs.deliverInfo.shipperInfo))
          // params.carId = this.logistCarInfo[0].id;
          // params.operaType = operaType;
          let arr=[];
          if(this.$refs.mList.logistMaterialsListNew && this.$refs.mList.logistMaterialsListNew.length){
            for(let i=0;i<this.$refs.mList.logistMaterialsListNew.length;i++){
              let item=this.$refs.mList.logistMaterialsListNew[i]
              let obj={
                materialsId:item.materialsId?item.materialsId:item.id,
                sendNums:item.sendNums
              }
              if(!item.sendNums){
                this.$message('材料数量不可为空！')
                return 
              }
              arr.push(obj)
            }
          }
          
          let query={
            // "carId": this.res.carId,
            // "id": 1,
            "logistType": params.logistType,//物流方式，11代驾，12车开新，13快递
            "operaType": operaType,//操作类型：保存 save，提交 submit， save只保存数据，提交会变更状态
            // "fromNo": params.fromNo?params.fromNo.no:'',
            // "fromName": params.fromNo?params.fromNo.name:'',
            // "fromMobile": params.fromMobile,
            
            "fromShopId": this.roleItem.shop?this.roleItem.shop.id:'',//发货方门店ID
            fromShopName:this.roleItem.shop?this.roleItem.shop.shopName:'',
            // "fromShopType": 41,
            "fromTime": params.fromTime,
            "preArriveTime": params.preArriveTime,
            toObjType:46,//收货方类型：41门店，42办证公司，43渠道（4S店）,44商户，45客户，46交互中心
            "toShopId": this.stata==1?this.stockItem.id:this.res.toShopId,
            toShopName:this.state==1?this.stockItem.shopName:this.res.toShopName,
            "materialsFormList": arr,
            fromNo:this.userData.no,
            fromName:this.userData.name,
            fromId:this.userData.id,
            fromMobile:this.userData.mobile,
          }
          if(params.logistType===11){
            query.agencyName= params.agencyName;
            query.agencyMobile= params.agencyMobile;
          }else if(params.logistType===12){
            query.empName= params.empName;
            query.empMobile= params.empMobile;
          }else if(params.logistType===13){
            query.expressCompName= params.expressCompName;
            query.expressLogistNo= params.expressLogistNo;
          }
          if(this.carChangelist.length>0){
            query.carId=this.carChangelist[0].id
          }

          if(this.state==1){
            this.logisticsAdd(query)
          }else{
            this.updateLogistics(query)
          }
        } else {
          return false;
        }
      });
    },
    logisticsAdd(query){//新增
      this.$api.logisticsAdd(this.$$.delete_null_properties(query)).then(res => {
          if(res.code === 0){
            this.routePage(11)
            this.$message.success(res.message)
          }
      })  
    },
    updateLogistics(query){//修改
      this.$api.updateLogistics(this.$route.query.id,this.$$.delete_null_properties(query)).then(res => {
        if(res.code === 0){
          if(query.operaType=='submit'){
            this.routePage(12)
          }else{
            this.logisticsDetail(this.$route.query.id);
          }
          this.$message.success(res.message)
        }
      })
  },
    onIconDelect(state){//删除图片
      switch(state){
        case 1:
          // this.imageUrl1='';
          this.postFileDelete(this.imageUrlObj1.id,1)
          break;
        case 2:
          // this.imageUrl2='';
          this.postFileDelete(this.imageUrlObj2.id,2)
          break;
      }
    },
    postFileDelete(fileId,state){//删除文件
      let query={
        businessId:this.res.carId,
        businessType:'logistics',
        fileId:fileId,
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          if(state==1){
            this.imageUrl1='';
          }else{
            this.imageUrl2='';
          }
          this.logisticsDetail(this.id);
          this.$message.success(res.message)
        }
      })
    },
    routePage(logistStatus){
      this.bus.$emit("closeSelected");
      let state='first';
      if(logistStatus==11){
        state='first'
      }else if(logistStatus==12){
        state='second'
      }else if(logistStatus==13){
        state='third'
      }
      if(this.departTime.name=='库管员'){
        this.$router.push({
          path:'/warehouseKeeper/deliveryManagement',
          query:{
            state:state
          }
        })
      }else{
        this.$router.push({
          path:'/salesManager/deliverGoods/deliverGoods',
          query:{
            state:state
          }
        })
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
.carDeliveryNote{
  padding: 20px 40px 140px;
  .title{
    text-align: center;
    padding: 0px 0 20px;
    font-size: 24px;
  }
  .line{
    padding: 12px 0 12px 40px;
    margin: 0 -40px;
    background: #EDEDED;
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
    background-color:#d1d1d1;
    .errorText{
      line-height:160px;
      text-align:center;
    }
  }
  .imgRowBox{
    position: relative;
    margin-right:15px;
    .imgName{
      text-align:center;
      margin-top:10px;
      font-size:14px;
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      // background: rgba(0,0,0,0.2);
      background: rgba(255, 255, 255, 0.6);
      line-height: 40px;
    }
    .iconDelete{
      position:absolute;
      top:10px;
      right:10px;
      cursor:pointer;
    }
  }
}
</style>