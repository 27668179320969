<template>
  <div class="merchantContract">
    <div class="ckxTitle mb15">办证及违章处理</div>
    <el-row>
      <el-col :span="8" class="flex flex_align_center">
        <div>办证方：<span v-show="hideModel!=1">{{form.certTypeName}}</span></div>
        <el-input v-model="form.certTypeName" v-show="hideModel==1" class="w300"></el-input>
        <!-- <el-button type="primary" size="mini" class="ml30" @click="hideModel=1">修改</el-button> -->
      </el-col>
      <el-col :span="6" class="ml30">自理原因：{{dataForm.merchantSelfDesc}}</el-col>
    </el-row>
    <!-- <el-row class="mt10">
      <el-col :span="8" class="flex  flex_align_center">
        <div><span class="wspan">违章处理方：</span><span v-show="hideModel!=2">{{dataForm.breakRuleDispose}}</span></div>
        <el-select v-model="dataForm.breakRuleDispose" v-show="hideModel==2" class="w300" clearable>
          <el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.name"></el-option>
        </el-select>
        <el-button type="primary" size="mini" class="ml30" @click="hideModel=2">修改</el-button>
      </el-col>
    </el-row> -->
    <div class="ckxTitle mt20 mb15">交易信息</div>
    <table class="carInfoTable">
      <tr>
        <td>车牌</td><td>{{form.carNo}}</td>
        <td>车型</td><td>{{form.makerName}}&nbsp;{{form.seriesName}}</td>
        <td>是否带牌</td><td>{{form.hasCarPlate}}</td>
        <td>商户姓名</td><td>{{dataForm.merchantName}}</td>
      </tr>
      <tr>
        <td>成交门店</td><td>{{customerContract.tradeShopName}}</td>
        <td>客户合同签订时间</td><td>{{customerContract.signTime}}</td>
        <td>办证预计完成时间</td><td>{{customerContract.customerDeadline}}</td>
        <td></td><td></td>
      </tr>
      <tr>
        <td>备注信息</td><td colspan="7"></td>
      </tr>
    </table>
    <!-- <div class="ckxTitle mt20 mb15">车主证件及费用信息</div> -->
    <!-- 车主证件 idcardlistOBJ-->
    <!-- <table class="mt20">
      <tr>
        <td>车主证件</td>
        <td>
          <el-radio-group v-model="idcardlistOBJ.realData1" :disabled="disabled">
            <el-radio :label="3">{{idcardlistOBJ.realData3}}提供</el-radio>
            <el-radio :label="9">客户无法提供</el-radio>
          </el-radio-group>
        </td>
        <td><p>{{idcardlistOBJ.remark4}}车开新将证件交回车主</p></td>
      </tr>
    </table> -->
    <!-- 项目名称 -->
    <div class="ckxTitle mt20 mb15">办证费用</div>
     <el-table :data="clearFeeList" border class="table_top_bg">
        <el-table-column prop="name" label="项目" align="center"></el-table-column>
        <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
        <el-table-column prop="feeAmount" label="承担方金额（元）" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center"></el-table-column>
        <el-table-column prop="beneficiaryAmount" label="受益方金额（元）" align="center" ></el-table-column>
        <!-- <el-table-column  label="费用确认" align="center" >
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.feeChecked"></el-checkbox>
          </template>
        </el-table-column> -->
      </el-table>
    <!-- <table class="carGoods table-cusHeadbg">
      <thead>
         <tr>
          <td>项目名称</td>
          <td colspan="2">性质</td><td>补送日期</td>
          <td>费用承担方</td><td>实际费用</td><td>备注</td><td>费用确认</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in carMaterialsList" :key="index">
          <td>{{item.materialName}}</td>
          <td>{{item.realData}}</td>
          <td>{{item.materialName.indexOf('身份证')>-1?`号码：${item.realData2}`:''}}{{item.materialName.indexOf('违章')>-1?`${item.options2}次`:''}}</td>
          <td>
            {{['身份证','暂住证'].includes(item.materialName)?`${item.realData2}客户提供`:''}}
            {{item.materialName.indexOf('违章')>-1?`${item.options3}前处理完毕`:''}}</td>
          <td>
            <el-radio-group v-model="item.feeBearerId" :disabled="disabled">
              <el-radio :label="1">客户</el-radio>
              <el-radio :label="3">车开新</el-radio>
              <el-radio :label="2">商户</el-radio>
            </el-radio-group>
          </td>
          <td>{{item.feeAmount}}</td>
          <td>备注</td>
          <td><el-checkbox v-model="item.checked"></el-checkbox></td>
        </tr>
        <tr v-if="carMaterialsList.length==0">
          <td colspan="8">暂无数据</td>
        </tr>
      </tbody>
    </table> -->
    <div class="ckxTitle mt20 mb15">手续类型</div>
    <el-radio-group v-model="dataForm.certKind" size="medium">
      <!--51过户，52转籍，53退牌上沪C，54退牌上沪B，55沪C-沪B，【说明：此属性所有自理费用共用】-->
      <el-radio-button :label="item.id" v-for="item in standardList" :key="item.id">{{item.name}}</el-radio-button>
      <!-- <el-radio-button :label="51" >转籍</el-radio-button>
      <el-radio-button label="2" >退牌上沪C</el-radio-button>
      <el-radio-button label="2" >退牌上沪B</el-radio-button>
      <el-radio-button label="2" >沪C-沪B</el-radio-button> -->
    </el-radio-group>
    <!--过户凭证-->
    <div class="ckxTitle mb15 mt20">过户凭证</div>
    <div class="photo_content_box">
      <div class="photobox_row" v-for="(item,index) of fileListImg" :key="index">
        <div class="photo_row_imgBox" @mouseenter="divMouseover(item,index,'gh')"
              @mouseleave="divMouseout(item)" >
          <div class="dialogBG" v-show="dialogFlag&&diaIndex==='gh_'+index">
            <i class="el-icon-delete cursor mr20" @click="delectImg(item,index,1)" ></i>
            <i class="el-icon-zoom-in cursor " @click="onPreview"></i>
          </div>
          <el-image 
            :src="item.url" 
            :preview-src-list="[item.url]" v-if="item.url||dataForm.taskOverdueCheckStatus==152">
            <div slot="error">暂无图片</div>
          </el-image>
          <div @click="onUpload(item,index,1)" v-if="!item.url&&dataForm.taskOverdueCheckStatus!=152" 
              class="uploadBoxTop">
              <el-upload
              class="tableUpload"
              action
              accept="image/png, image/gif, image/jpeg,image/jpg"
              :on-change="requestUpload"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <!-- <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span> -->
                </div>
              </el-upload>
            </div>
          <div class="photo_row_imgtiltle">{{item.name}}</div>
        </div>
      </div>
    </div>
    <!--特殊凭证-->
    <div class="ckxTitle mb15 mt20">特殊凭证</div>
    <div class="photo_content_box">
      <div class="photobox_row" v-for="(item,index) of fileList1" :key="index" >
        <div class="photo_row_imgBox" @mouseenter="divMouseover(item,index,'ts')"
              @mouseleave="divMouseout(item)">
          <div class="dialogBG" v-show="dialogFlag&&diaIndex==='ts_'+index">
            <i class="el-icon-delete cursor mr20" @click="delectImg(item,index,2)" ></i>
            <i class="el-icon-zoom-in cursor " @click="onPreview"></i>
          </div>
          <el-image 
            :src="item.url" v-if="item.url||dataForm.taskOverdueCheckStatus==152" 
            :preview-src-list="[item.url]">
            <div slot="error">暂无图片</div>
          </el-image>
          <div @click="onUpload(item,index,2)" v-if="!item.url&&dataForm.taskOverdueCheckStatus!=152" class="uploadBoxTop">
              <el-upload
              class="tableUpload"
              action
              accept="image/png, image/gif, image/jpeg,image/jpg"
              :on-change="requestUpload"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <!-- <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span> -->
                </div>
              </el-upload>
            </div>
          <div class="photo_row_imgtiltle">{{item.name}}</div>
        </div>
      </div>
    </div>

   <el-dialog
        :title="`添加补办项`"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="660px"
      >
        <el-form
          ref="forms"
          :model="forms"
          label-width="120px"
          :rules="rules"
          :hide-required-asterisk="true"
          class="mt20" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="补办项目:" prop="name">
                <el-select v-model="forms.name" placeholder="请选择补办项目" class="w180" size="medium" v-show="!projectType">
                  <el-option v-for="item in replaceList" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
                <el-input v-model="forms.name" class="w180" size="medium" v-show="projectType"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-button type="primary" size="medium" @click="projectType=true" plain>选不到加一个</el-button> 
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="费用承担方:" prop="feeBearerId">
                <el-select
                  v-model="forms.feeBearerId"
                  placeholder="请选择费用承担方"
                  class="w180" clearable 
                  size="medium">
                  <el-option v-for="item in whichSidelistId" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收取费用:" prop="chargingFeeAmount">
                <el-input type="number" v-model.number="forms.chargingFeeAmount" class="w180" size="medium"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="办证供应商:" prop="certCompanyId">
                <el-select
                  v-model="forms.certCompanyId"
                  class="w180" clearable>
                  <el-option v-for="item in companylist" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付费用:" prop="payFeeAmount">
                <el-input type="number" v-model.number="forms.payFeeAmount" class="w180" size="medium"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="footer-modalBut"
            @click="onSubmitform('forms')"
          >提交</el-button>
        </span>
      </el-dialog>

      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="saveContract(1)" v-if="dataForm.taskOverdueCheckStatus!=152">保存</el-button>
          <el-button type="primary" @click="saveContract(2)" v-if="dataForm.taskOverdueCheckStatus!=152">审核通过</el-button>
          <!-- <el-button type="primary" @click="saveContract(3)">增加办证项</el-button> -->
          <el-button type="primary" @click="saveContract(4)">返回</el-button>
        </div>
      </div>


      <el-image-viewer v-show="showViewer" :on-close="closeViewer" :url-list="srcList" />
  </div>
</template>

<script>
import print from 'vue-print-nb'

import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  directives: {
    print   
  },
  components: { ElImageViewer },
  name:'merchantSelfCareDetails',
  data() {
    return {
      showViewer:false,
      srcList:[],
      hideModel:0,
      form:{
        carCustomer:{},
        carMerchant:{}
      },
      customerContract:{},
      dataForm:{
        car:{},
        certType:'',
      },//所有数据
      taskId:'',//id
      carMaterialsList:[],//车辆材料列表
      clearFeeList:[],//办证费用列表
      idcardlistOBJ:{},//身份证列表
      carlistPro:[],//车辆手续列表
      recheckForm:{},//复检结果表单
      evaluateForm:{},//复检结果评定
      checkedList2:[],//过户凭证特殊要求（选中）
      companylist:[],//办证公司
      projectType:false,//新增补办是否要添加input
      replaceList:[],
      dialogVisible:false,
      modalForm:{},
      rules:{
        carLogistTypeName: [{ required: true, message: '请选择车辆物流方式', trigger: 'change' }],
        certTypeName:[{ required: true, message: '请选择办证方式', trigger: 'change' }],
        'car.certCompany':[{ required: true, message: '请选择办证公司', trigger: 'change' }],
      },
      forms:{},
      fileListImg: [
        { name: "产证1-2页", url: "", uploader: "", uploaderDate: "" },
        { name: "产证3-4页", url: "", uploader: "", uploaderDate: "" },
        { name: "产证5-6页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户发票", url: "", uploader: "", uploaderDate: "" },
        {
          name: "行驶证照片或临牌照片",
          url: "",
          uploader: "",
          uploaderDate: ""
        }
      ], //图片
      fileList1: [
        { name: "过户后产证1-2页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户后产证3-4页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户后产证5-6页", url: "", uploader: "", uploaderDate: "" }
      ], //图片
      disabled:true,
      itemRow:{},
      standardList:[],//
      supplierlist:[],//供应商
      dialogFlag:false,
      diaIndex:'',
    }
  },
  created(){
    this.taskId=this.$route.query.id;
    this.getCertificateTaskDetails();//获取详情
    this.whichSidelistId=this.$store.state.setting.whichSidelistId;
    this.getReplaceItem(); //获取补办项目
    this.getCertKind()//查询办证项目类型状态
    this.postSupplierList();//获取供应商
    // this.postFeeStandardList()//办证费用标准 列表查询
  },
  methods: {
    onPreview() {
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    divMouseover(row,index,state){//移入
      if(row.url){
        this.dialogFlag=true;
        this.diaIndex=`${state}_${index}`;
        this.srcList=[row.url]
      }
    },
    divMouseout(row){//移除
      if(row.url){
        this.dialogFlag=false;
        this.diaIndex='';
      }
    },
    delectImg(row,index,typeImg){
      this.itemRow=row;
      this.itemRow.index=index;
      this.itemRow.typeImg=typeImg;
      this.postFileDelete(row.id,index)
    },
    getCertificateTaskDetails(){//获取详情
      let query={
        businessId:this.taskId,
        "businessType": "merchantSelfCare",
        // "businessName": ""
      }
      this.$api.getCertificateTaskDetails(this.taskId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.dataForm=res.data;
            this.form=res.data.car;
            if(res.data.car){
              if(res.data.car.customerContract) this.customerContract=res.data.car.customerContract;
              if(res.data.car.carMerchant) this.form.carMerchant=res.data.car.carMerchant;
            } 
            if(res.data.fileList){
              for(let i=0;i<this.fileListImg.length;i++){
                for(let j=0;j<res.data.fileList.length;j++){
                  let aitem=res.data.fileList[j]
                  if(this.fileListImg[i].name===aitem.sysFileBusinessList[0].businessName){
                    this.fileListImg[i].url=aitem.fullFilePath;
                    this.fileListImg[i].id=aitem.id;
                  }
                }
              }
              for(let i=0;i<this.fileList1.length;i++){
                for(let j=0;j<res.data.fileList.length;j++){
                  let aitem=res.data.fileList[j]
                  if(this.fileList1[i].name===aitem.sysFileBusinessList[0].businessName){
                    this.fileList1[i].url=aitem.fullFilePath;
                    this.fileList1[i].id=aitem.id;
                  }
                }
              }
            }
            let arrMerchant=res.data.merchantSelfFeeList?res.data.merchantSelfFeeList:[];//商户自理费用
            let arrClear=res.data.clearFeeList?res.data.clearFeeList:[];
            this.clearFeeList=arrMerchant.concat(arrClear);
            if(this.clearFeeList){
              for(let i=0;i<this.clearFeeList.length;i++){
                let item=this.clearFeeList[i]
                item.feeChecked=item.feeChecked===1?true:item.feeChecked;
                item.name=item.name?item.name:item.itemName;
              }
            }
            
            //车辆材料列表
            let cardatalist=this.form.carMaterialsList?this.form.carMaterialsList:[];
            let TankerArr=[];//油车
            let energyArr=[];//电车
            let nullobj=new Object;
            let isSelectedCheck=false;
            let idcardlist=[];//车主证件
            for(let i=0;i<cardatalist.length;i++){
              let item=cardatalist[i];
              item.options1='';
              item.options2='';
              item.options3='';
              item.options4='';
             
              if(cardatalist[i]){
                nullobj=item;
                
                switch(cardatalist[i].type){
                  case 'car':
                    if(item.pdaData=='无'&&item.materialName.indexOf('车辆牌照')!=-1){
                      item.feeBearerId=2;
                      item.feeBearerName='商户';
                    }else if(item.pdaData=='无'&&['桩充','线充','备胎'].indexOf(item.materialName)!=-1){
                      item.feeBearerId=1;
                      item.feeBearerName='客户';
                    }else if(item.materialName.indexOf('保险单')!=-1){
                      item.options1=item.options?JSON.parse(item.options):[]
                    }else if(item.materialName.indexOf('钥匙')!=-1||item.materialName.indexOf('登记证书')!=-1){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                      }
                    }else if(item.materialName.indexOf('行驶证')!=-1){
                     if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                      }
                    }else if(item.materialName=='违章'){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                        item.options4=item.options.split(',')[3]
                        item.options5=item.options.split(',')[4]
                        item.options6=item.options.split(',')[5]?Boolean(item.options.split(',')[5]):item.options.split(',')[5]
                      }
                    }
                    /**-- 需要询问4S点费用，只有“沪电动车牌”会体现这个字段 
                    *--isHuPlate是否沪牌，1是,2否，默认1,isNewEnergy是否新能源车，81否，82是,默认81**/
                    if(this.form.isHuPlate==1&&this.form.isNewEnergy!=82&&['桩充','线充'].indexOf(cardatalist[i].materialName)==-1){
                      TankerArr.push(cardatalist[i])//油车
                    }
                    energyArr.push(cardatalist[i])//电车
                    break;
                  case 'customer':
                    if(cardatalist[i].realData){
                      if(cardatalist[i].realData.indexOf(',')!=-1){
                        let a=cardatalist[i].realData.split(',')
                        cardatalist[i].realData1=a[0];
                        cardatalist[i].realData2=a[1];
                        cardatalist[i].realData3=a[2];
                      }
                    }else{
                      cardatalist[i].realData1='';
                      cardatalist[i].realData2='';
                      cardatalist[i].realData3='';
                    }
                    if(cardatalist[i].remark){
                      if(cardatalist[i].remark.indexOf('|')!=-1&&cardatalist[i].materialName=='身份证'){
                        let a=cardatalist[i].remark.split('|')
                        if(a[0]){
                          cardatalist[i].remark1=JSON.parse(a[0]);
                        }
                        if(a[1]){
                          if(a[1].indexOf(',')!=-1){
                            cardatalist[i].remark2=[a[1].split(',')[0],a[1].split(',')[1]];
                          }
                        }
                        if(a[2]){
                          cardatalist[i].remark3=JSON.parse(a[2]);
                        }
                        cardatalist[i].remark4=a[3];
                      }
                    }

                    idcardlist.push(cardatalist[i])//身份证列表
                    break;
                  case 'procedure'://手续
                    if(cardatalist[i].materialName.indexOf('父母')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=0;
                    }else if(cardatalist[i].materialName.indexOf('夫妻')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=1;
                    }else if(cardatalist[i].materialName.indexOf('抬头')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=2;
                    }else if(cardatalist[i].materialName.indexOf('合并')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=3;
                    }
                    item.realData=item.realData?Boolean(item.realData):item.realData
                    if(cardatalist[i].materialName.indexOf('额度变更')!=-1){
                      if(cardatalist[i].isSelected==1){
                        this.carlistPro.push(cardatalist[i])//车辆手续
                        isSelectedCheck=true;
                      }
                    }else if(cardatalist[i].materialName.indexOf('额度变更')==-1){
                        this.carlistPro.push(cardatalist[i])//车辆手续
                    }
                    break; 
                }
              }
            }
            this.idcardlistOBJ=idcardlist.length>0?idcardlist[0]:{};
            if(this.form.isHuPlate==1&&this.form.isNewEnergy==82){
              this.carMaterialsList=energyArr;
            }else{
              this.carMaterialsList=TankerArr;//油车
            }
            for(let i=0;i<this.carMaterialsList.length;i++){
              let item=this.carMaterialsList[i]
              
               this.$set(this.carMaterialsList,i,item)
            }
           
            // console.log(this.checkedList2,'----this----')
            //清空对象值
            let newObj=JSON.parse(JSON.stringify(nullobj))
            Object.keys(newObj).forEach(key => newObj[key] = '')
            if(isSelectedCheck==false){
              newObj.materialName='额度变更';
              this.carlistPro.push(newObj)
            }
            // this.$nextTick(() => {
            //     this.$refs["form"].clearValidate();
            //   });

            // setTimeout(() => {
            //   this.deepType=true;//触发监听
            // }, 3000);
          }
        }
      })
    },
    postFeeStandardList(){//办证费用标准 列表查询
      this.$api.postFeeStandardList({}).then(res=>{
        if(res.code==0){
          this.standardList=res.data;
        }
      })
    },
    saveContract(state){//操作合同按钮
      switch(state){
        case 1://保存
          this.postOperaSelf('self_save')
          break;
        case 2://办证完成
          this.$confirm('您确定反馈材料和费用已通过，并标注为办证完成吗？', '办证完成提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postOperaSelf('self_check_success')
          })
          break;
        // case 3://增加办证项
        //   this.dialogVisible=!this.dialogVisible;
        //   this.projectType=false;
        //   break;
        case 4://返回
          this.onrouterPage();
          break;
      }
    },
    onSubmitform(formdata){
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.postProcedureMaterialAdd();
        } else {
          return false;
        }
      });
    },
    routePage(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/salesAssistant/signContract'
      })
    },
    postOperaSelf(state){//商户自理费用操作【保存 self_save, 审核通过 self_check_success】
      let arr=[];
      // let oldArr=JSON.parse(JSON.stringify(this.clearFeeList))
      // for(let i=0;i<oldArr.length;i++){
      //   arr.push(oldArr[i].id)
      // }
      if(!this.dataForm.certKind&&state=='self_check_success'){
        this.$message.warning('手续类型不可为空！')
        return
      }
      let query={
        taskId:this.taskId,
        taskMerchantSelfCheckResult:state,
        merchantSelfFees:[{
          breakRuleDispose:this.dataForm.breakRuleDispose,//违章处理方
          certKind:this.dataForm.certKind,//办证类型51过户，52转籍，53退牌上沪C，54退牌上沪B，55沪C-沪B，【说明：此属性所有自理费用共用】
          // feeChecked:this.clearFeeList.length>0?this.clearFeeList[0].feeChecked===true?1:2:'',
          feeChecked:1,//默认已确认，；办证费用是否已确认：1已确认，2未确认
        }]
      }
      this.$api.postOperaSelf(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails();//获取详情
          this.$message.success(res.message)
        }
      })
    },
    postProcedureMaterialAdd(){//新增补办
      let query={
        "name": this.forms.name,//补办项目ID
        "carId": this.form.id,
        "feeBearerId": this.forms.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
        "chargingFeeAmount": this.forms.chargingFeeAmount,//收取费用金额
        // "beneficiaryName": "xx",
        "payFeeAmount": this.forms.payFeeAmount,//支付费用金额
        certCompanyId:this.forms.certCompanyId,
      }
      this.$api.postProcedureMaterialAdd(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.ondialogVisible();
            this.getCertificateTaskDetails();
          }else{
            this.$message({
              type:'error',
              message:res.data.message
            })
          }
        }
      })
    },
     onUpload(row,index,typeImg){
      this.itemRow=row;
      this.itemRow.index=index;
      this.itemRow.typeImg=typeImg;
    },
    requestUpload(file) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.taskId);
      obj.append("businessName", this.itemRow.name);
      obj.append("businessType", "merchantSelfCare");//商户自理
      
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          if(this.itemRow.typeImg===1){
            for(let i=0;i<this.fileListImg.length;i++){
              if(i===this.itemRow.index&&this.fileListImg[i].name===this.itemRow.name){
                this.fileListImg[i].url=res.data[0].fullFilePath;
                this.fileListImg[i].id=res.data[0].fileId;
              }
            }
          }else{
            for(let i=0;i<this.fileList1.length;i++){
              if(i===this.itemRow.index&&this.fileList1[i].name===this.itemRow.name){
                this.fileList1[i].url=res.data[0].fullFilePath;
                this.fileList1[i].id=res.data[0].fileId;
              }
            }
          }
          // this.getCertificateTaskDetails();//获取详情
          this.$message.success(res.message)
          this.$nextTick(()=>{
            this.$forceUpdate()
          })
        }
      });
    },
    postFileDelete(fileId,index){//删除文件
      let query={
        businessId:this.taskId,
        businessType:'merchantSelfCare',
        fileId:fileId
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          if(this.itemRow.typeImg===1){
            for(let i=0;i<this.fileListImg.length;i++){
              if(i==index&&this.fileListImg[i].name===this.itemRow.name){
                this.fileListImg[i].url='';
              }
            }
          }else{
            for(let i=0;i<this.fileList1.length;i++){
              if(i==index&&this.fileList1[i].name===this.itemRow.name){
                this.fileList1[i].url='';
              }
            }
          }
           
            
            // this.getCertificateTaskDetails()
          this.$message.success(res.message)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getReplaceItem() {
      //查询补办项目状态
      this.$api.getReplaceItem().then(res => {
        if (res) {
          if (res.code == 0) {
            this.replaceList = res.data;
          }
        }
      });
    },
    onrouterPage() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/accreditationCommissioner/merchantSelfCare"
      });
    },
    getCertKind(){
      this.$api.getCertKind().then(res=>{
        if(res.code==0){
          this.standardList=res.data;
        }
      })
    },
    postSupplierList(){//搜索供应商
      let query={
        isEnabled:0
      }
      this.$api.postSupplierList(query).then(res=>{
        if(res.code==0){
          this.supplierlist=res.data;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.merchantContract{
  padding: 20px 40px 100px;
  h1{font-weight: bold;}
  .mr40{margin-right: 40px;}
  .formItemWidth{width: 220px;}
  table{
    border-collapse: collapse;
    width: 100%;
    td{
      border: 1px solid #ccc;
      padding: 10px 20px;
      text-align: center;
    }
  }
  .carInfoTable td:nth-child(2n+1){background-color: #f5f5f5;}
  ::v-deep .lineInput{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
  }
  ::v-deep .lineInput .el-input__inner{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
  }
  ::v-deep .lineInput .el-input__inner:disabled{
    background-color: #ffffff;
    color: #333333;
    text-align: center;
  }
  ::v-deep .inputNum .el-input__inner{
    padding-right:0
  }
  .inputNum{width: 80px;}
  .inputDate{width: 150px;}
  .inputSelect{width: 150px;}
  .carGoods{margin-top: 20px;margin-bottom: 20px;}
  .alignLeft{text-align: left;}
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 342px;
      margin: 0 27px 20px 0;
      .photo_row_imgBox {
        height: 255px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        // img {
        //   width: auto;
        //   max-height: 100%;
        // }
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          line-height: 44px;
        }
      }
      .photo_row_textBox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
    // .uploadBoxTop{
    //   margin-top:80px;
    // }
    .dialogBG{
      position:absolute;
      left:0;
      top:0;
      z-index:1;
      width:100%;
      height:100%;
      background:rgb(255,255,255,0.7);
      display:flex;
      justify-content:center;
      align-items: center;
      font-size: 30px;
      .el-icon-delete{
        position:absolute;
        left:10px;
        top:10px;
      }
    }
  }
  .wspan{
    width:100px;
    display:inline-block;
  }
}
</style>
