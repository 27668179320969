<template>
  <div class="pageContainer">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w5">
        <span>检测师账号</span>
        <el-input v-model="params.account" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w5">
        <span>检测师姓名</span>
        <el-input v-model="params.name" size="medium"></el-input>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="name" label="检测师姓名" align="center"></el-table-column>
      <el-table-column prop="loginName" label="检测师账号" align="center"></el-table-column>
      <el-table-column prop="online" label="在线情况" align="center">
        <template slot-scope="scope">{{scope.row.online==1?'在线':'离线'}}</template>
      </el-table-column>
      <el-table-column prop="loginTime" label="最近登陆时间" align="center"></el-table-column>
      <el-table-column prop="appVersion" label="APP版本" align="center"></el-table-column>
      <el-table-column prop="databaseVersion" label="车型库版本" align="center"></el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params:{
        account: '',
        name: '',
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query={
        curPage:this.currentPage,
        pageSize:this.pageSize,
        loginName:this.params.account,
        name:this.params.name
      }
      this.$api.postCheckerPage(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist = res.data.records;
          this.total = res.data.total;
        }else{
          this.querylist = []
          this.total=0;
        }
      })
    },
     handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
  }
}
</script>

<style scoped lang="scss">

</style>