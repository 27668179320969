<template>
  <div class="inventBoxBG">
    <div class="inventBox">
      <!--商户信息-->
      <div class="content_modal_textBox">
        <div class="title_top_nameBox">商户信息</div>
        <el-row :gutter="20" class="mt20">
          <el-col :span="6">商户编号：{{form.merchantNo}}</el-col>
          <el-col :span="6">商户姓名：{{form.merchantName}}</el-col>
        </el-row>
      </div>
      <!--付款人信息-->
      <div class="content_modal_textBox mt30">
        <div class="title_top_nameBox">付款人信息</div>
        <table class="table-oddwholebg table-custom mt20">
          <tbody>
            <tr>
              <td>付款人</td>
              <td>{{form.merchantAccount.accountName}}</td>
              <td>付款账号</td>
              <td>{{form.merchantAccount.account}}</td>
              <td>付款银行</td>
              <td>{{form.merchantAccount.depositBank}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--收款信息-->
      <div class="content_modal_textBox mt30">
        <div class="title_top_nameBox">收款信息</div>
        <el-row :gutter="20" class="mt20">
          <el-col :span="6">收款单号：{{form.orderNo}}</el-col>
          <el-col :span="6">收款状态：{{form.payState==92?'收款中':form.financeGatheringStatus==93?'收款成功':form.financeGatheringStatus==94?'收款失败':'待收款'}}</el-col>
          <el-col :span="6">金额：{{form.amount}}</el-col>
        </el-row>
        <el-row :gutter="20" class="mt20" v-show="[93,94].includes(payState)">
          <el-col :span="6">操作人：{{form.receiverName}}</el-col>
          <el-col :span="6">{{payState==93?'收款完成时间':'收款失败时间'}}：{{form.financeGatheringTime}}</el-col>
        </el-row>
      </div>
      <div class="footer" v-if="payState==91">
        <div class="btn">
          <el-button type="primary" @click="onPayment(1)">收款成功</el-button>
          <el-button type="primary" @click="onPayment(2)">收款失败</el-button>
          <el-button type="primary" @click="onReturn">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        merchantAccount:{},//商户银行账户信息
      },
      payState:91,// 财务收款状态，91待收款，92收款中，93收款成功，94收款失败，默认91
      merchantMarginGatheringId:'',//商户保证金收款单ID
    };
  },
  created() {
    this.merchantMarginGatheringId=this.$route.query.id;
    this.getDetailsByGatheringId();
  },
  methods: {
    getDetailsByGatheringId(){//获取详情
      this.$api.getDetailsByGatheringId(this.merchantMarginGatheringId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payState=res.data.financeGatheringStatus;
            if(!this.form.merchantAccount) this.form.merchantAccount={}
          }
        }
      })
    },
    onPayment(state){
      if(state==1){
        this.$confirm('请确认是否付款成功?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => { 
          this.postMerchantFinanceConfirmPaySuccess()//财务确认付款成功
        }).catch(() => {});
      }else{
        this.$confirm('请确认是否付款失败?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => { 
          this.postMerchantFinanceConfirmPayFail()//财务确认付款失败
        }).catch(() => {});
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/merchant/marginManagement',
        query:{
          state:this.stateType
        }
      })
    },
    postMerchantFinanceConfirmPaySuccess(){//财务确认付款成功
      let query={
        id:this.form.id
      }
      this.$api.postMerchantFinanceConfirmPaySuccess(query).then(res=>{
        if(res.code==0){
          this.getDetailsByGatheringId();
          this.$message.success(res.message)
        }
      })
    },
    postMerchantFinanceConfirmPayFail(){//财务确认付款失败
      let query={
        id:this.form.id
      }
      this.$api.postMerchantFinanceConfirmPayFail(query).then(res=>{
        if(res.code==0){
          this.getDetailsByGatheringId();
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px 100px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>