<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="6" class="flex flex_align_center flex_between w5">
        <span>推荐客户姓名</span>
        <el-input v-model="forms.name" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <!-- <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>荐车达人</span>
        <el-input v-model="forms.ownerGroup" size="medium"></el-input>
      </el-col> -->
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>客户编号</span>
        <el-input v-model="forms.customerNo" size="medium"></el-input>
      </el-col>

      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>绑定门店</span>
        <el-select v-model="forms.shopId" size="medium" clearable>
          <el-option v-for="item in shoplist" :key="item.id" :value="item.id" :label="item.shopName"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt10">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>业务状态</span>
        <el-select v-model="forms.tradeStatus" size="medium" clearable>
          <el-option v-for="item in stateModellist" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>提交时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange" clearable :default-time="['00:00:00', '23:59:59']"
          size="medium" value-format="yyyy-MM-dd HH:mm:ss" 
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center">
        <el-button type="primary" class="button_primary" @click="onsearch" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="id" label="客户ID" align="center"></el-table-column>
      <el-table-column prop="name" label="客户姓名" align="center"></el-table-column>
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号-1" align="center"></el-table-column>
      <el-table-column prop="realCarNo" label="车牌号-2" align="center"></el-table-column>
      <el-table-column prop="tradeStatusName" label="业务状态" align="center">
        <!-- <template slot-scope="scope">{{scope.row.tradeStatus===1?'跟进中':scope.row.tradeStatus===2?'已成交':scope.row.tradeStatus===3?'已过期':scope.row.tradeStatus===4?'办证完成':scope.row.tradeStatus===5?'结算完成':scope.row.tradeStatus}}</template> -->
      </el-table-column>
      <el-table-column prop="recommendTime" label="提交时间" align="center"></el-table-column>
      <el-table-column prop="jcdrAgentName" label="荐车达人" align="center"></el-table-column>
      <el-table-column prop="shopName" label="绑定门店" align="center"></el-table-column>
      <el-table-column prop="source" label="录入来源" align="center">
        <template slot-scope="scope">{{scope.row.source===1?'代理录入':scope.row.source===2?'扫码录入':scope.row.source}}</template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>


  </div>
</template>
<script>
export default {
  data() {
    return {
      forms:{
        date:'',
        shopId:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      stateModellist:[{id:1,name:'跟进中'},{id:3,name:'已过期'},{id:2,name:'已成交'},{id:4,name:'过户完成'},{id:5,name:'结算完成'}],
      shoplist:[],
      id:''
    };
  },
  created() {
    if(this.$route.query.id) this.id=this.$route.query.id
    this.init()
    this.getShopList()//门店
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;

      let query={
        "agentId": this.id,
        "curPage": this.currentPage,
        "mobile": this.forms.mobile,
        "pageSize": this.pageSize,
        "name": this.forms.name,
        "carNo": this.forms.carNo,
        // "realCarNo": "string",
        "tradeStatus": this.forms.tradeStatus,
        "customerNo": this.forms.customerNo,
        "recommendTimeBegin": this.forms.date?this.forms.date[0]:'',
        "recommendTimeEnd": this.forms.date?this.forms.date[1]:'',
        shopId:this.forms.shopId,
      }
      this.$api.postCustomerPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.total=res.data.total;
        }else{
          this.querylist = [];
          this.total=0;
        }
      })
    },
    onsearch() {
      this.currentPage=1;
      this.init()
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    onSubmit(){},
    getShopList(){//搜索门店
      let query={
        isEnabled:0,
        shopTypeList:[0,3]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>

</style>
