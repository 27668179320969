<template>
  <div class="inventoryDetail">
    <div class="ckxTitle mb10">车辆信息</div>
    <table class="table-oddwholebg table-custom">
      <tbody>
         <tr>
           <td>车辆号牌</td><td>{{res.carNo}}</td>
           <td>厂牌型号</td><td>{{res.brandName}}&nbsp;&nbsp;{{res.modelName}}</td>
           <td>发动机号</td><td>{{res.engineNo}}</td>
           <td>颜色</td><td>{{res.carColor}}</td>
          </tr>
          <tr>
            <td>初登日期</td><td>{{res.registerDate}}</td>
            <td>年检有效期</td><td>{{res.checkExpire}}</td>
            <td>车架号</td><td class="wordWrap">{{res.vinCode}}</td><td></td><td></td>
          </tr>
      </tbody>
    </table>
    <div class="ckxTitle mt20 mb10">合同约定时间</div>
    <table class="table-custom table-cusHeadbg">
      <thead>
        <tr><td>合同类型</td><td>签订时间</td><td>材料完成时间</td></tr>
      </thead>
      <tbody>
        <tr><td>客户合同</td><td>{{res.customerContractSignTime}}</td><td>{{res.customerDeadline}}</td></tr>
        <tr><td>商户合同</td><td>{{res.merchantContractSignTime}}</td><td>{{res.merchantDeadline}}</td></tr>
      </tbody>
    </table>
    <div class="ckxTitle mt20 mb10">商户信息</div>
    <table class="table-custom table-oddbg">
      <tbody>
        <tr><td>商户姓名</td><td>{{res.merchantName}}</td><td>办证公司</td><td>{{res.certCompany}}</td></tr>
      </tbody>
    </table>
    <div class="ckxTitle mt20 mb10">车辆库存</div>
    <table class="table-custom table-cusHeadbg">
      <thead>
        <tr><td></td><td>库存状态</td><td>所在库</td><td>操作</td></tr>
      </thead>
      <tbody>
        <tr>
          <td><el-checkbox v-model="stockCarInfo.carCheckbox" @input="forceUpdate"></el-checkbox></td>
          <td>{{stockCarInfo.stockStatusName}}</td>
          <td>{{stockCarInfo.stockObjName?stockCarInfo.stockObjName:'-'}}</td>
          <td><el-button type="primary" @click="read(1)" size="mini" plain>明细</el-button></td>
        </tr>
      </tbody>
    </table>
    <div class="flex flex_between flex_align_center">
      <div class="ckxTitle mt20 mb10">手续材料库存</div>
      <el-button type="primary" size="mini" plain @click="showAddProjectDialog">添加项目</el-button>
    </div>
    <el-table :data="carMaterialsList" border @selection-change="handleSelectionChange2">
      <el-table-column type="selection" width="55" align="center"> </el-table-column>
      <el-table-column prop="materialName" label="内容" align="center"></el-table-column>
      <el-table-column prop="pdaData" label="PDA采集数据" align="center"></el-table-column>
      <el-table-column prop="materialType" label="材料类型" width="90" align="center"></el-table-column>
      <el-table-column prop="materialStatus" label="材料状态" align="center" :formatter="formatTypeBox"></el-table-column>
      <el-table-column :formatter="formatStockStatus" label="库存状态" width="90" align="center"></el-table-column>
      <el-table-column prop="stockObjName" label="所在库" align="center"></el-table-column>
      <el-table-column prop="outStorageOperaName" label="操作人" align="center">
        <template slot-scope="scope">{{scope.row.outStorageOperaName}}{{scope.row.inStorageOperaName}}</template>
      </el-table-column>
      <el-table-column prop="updateDate" label="上次操作时间" align="center"></el-table-column>
      <el-table-column prop="screenshotList" label="附件缩略图" width="202px" align="center">
        <template slot-scope="scope">
          <el-upload
          class="tableUpload"
          action
          accept="image/png, image/gif, image/jpeg,image/jpg"
          list-type="picture-card" 
          :on-change="(file, fileList)=>requestUpload(file,fileList,scope.row)"
          :file-list="handleFileList(scope.row.screenshotList)"
          :auto-upload="false">
            <el-button slot="default" type="primary" size="mini" class="group-custom" >上传附件</el-button>
            <div slot="file" slot-scope="{file}">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url" alt=""
              >
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file,scope.row)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="read(2,scope.row)" size="mini" plain>明细</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="handleIn" >入库</el-button>
        <el-button type="primary" @click="handleOut" >出库</el-button>
      </div>
    </div>

    <el-dialog :title="showOperationTltie" :visible.sync="showOperation">
      <el-table border :data="operationData">
        <!-- <el-table-column prop="stockObjOutName" label="出库仓库" v-if="showOperationState==1" key="1"></el-table-column> -->
        <!-- <el-table-column prop="stockObjInName" label="入库仓库" v-if="showOperationState==1" key="2"></el-table-column> -->
        <el-table-column prop="stockTypeName" label="出入库类型" key="3"></el-table-column>
        <!-- <el-table-column prop="createBy" label="接收人" v-if="showOperationState==1" key="4"></el-table-column> -->
        <el-table-column prop="outObjName" label="出库对象"  key="5"></el-table-column>
        <el-table-column prop="updateDate" label="操作时间" key="6"></el-table-column>
        <el-table-column prop="operaTypeName" label="操作"  key="7"></el-table-column>
        <el-table-column prop="operaEmpName" label="操作人" key="8"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="添加项目" :visible.sync="showAddProject" class="addProject customDialog" :close-on-click-modal="false" center>
      <el-checkbox-group v-model="checkList" class="modalCheckBox">
        <el-checkbox v-for="item in projectList" :key="item.id"  :label="item">{{item.materialName}}{{item.selfDefineRemark}}</el-checkbox>
      </el-checkbox-group>
      <!-- <el-form :model="projectForm" :rules="projectRules" ref="projectForm" class="mt10">
        <el-form-item prop="project">
          <div class="flex flex_align_center">
            <el-input clearable v-model.trim="projectForm.project" style="width: 220px;" class="mr20" placeholder="请输入添加的项目名" @keyup.enter.native="addProject"></el-input>
            <el-button type="primary" plain @click="addProject">添加项目</el-button>
          </div>
        </el-form-item>
      </el-form> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddProject = false">取 消</el-button>
        <el-button type="primary" @click="submitProject">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <!-- 入库操作 -->
    <el-dialog title="入库信息" class="customDialog" :visible.sync="inDialogVisible">
      <el-form :model="inOutForm" ref="inOutForm" label-width="100px" :rules="inOutFormRules">
        <el-form-item label="入库仓库" prop="stockObjInId">
          <el-select v-model="inOutForm.stockObjInId" placeholder="请选择" clearable>
            <el-option v-for="item in stockList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库类型" prop="stockInType">
          <el-select v-model="inOutForm.stockInType" placeholder="请选择" clearable>
            <el-option v-for="item in stockTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="inDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitIn">确认入库</el-button>
      </div>
    </el-dialog>

    <!-- 出库操作 -->
    <el-dialog title="出库信息" class="customDialog" :visible.sync="outDialogVisible">
      <el-form :model="inOutForm" ref="inOutForm" label-width="100px" :rules="inOutFormRules">
        <el-form-item label="出库对象" prop="stockObjOutIdObj">
          <el-select v-model="inOutForm.stockObjOutIdObj" placeholder="请选择" clearable @change="changeStockObjOutId" value-key="id">
            <el-option v-for="item in stockObjList" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库类型" prop="stockOutType">
          <el-select v-model="inOutForm.stockOutType" placeholder="请选择" clearable>
            <el-option v-for="item in stockTypeOutList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitOut" v-show="inOutForm.stockObjOutName=='客户'||!inOutForm.stockObjOutName">出 库</el-button>
        <el-button type="primary" @click="submitOut('send')" v-show="inOutForm.stockObjOutName=='交付中心'||!inOutForm.stockObjOutName">出库并发货</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    // let self = this;
    // let validateProject = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入'));
    //   } else {
    //     if(self.projectList.indexOf(value) > -1){
    //       callback(new Error('名称不可重复 !'));
    //     }
    //     callback();
    //   }
    // };
    return {
      res:{},
      stockCarInfo: {
        carCheckbox:'',
      },//车辆表数据
      customerContract:{},//客户合同
      merchantContract:{},//商户合同
      carMaterialsList: [],
      tableData: [],
      multipleSelection: [],
      multipleSelection2: [],
      inDialogVisible: false,
      outDialogVisible: false,
      inOutForm: {
        stockObjInId: '',
        stockInType: '',
        stockObjOutId: '',
        stockObjOutIdObj:null,
        stockOutType: '',
        handlerType: ''
      },
      stockObjList: [],//出库对象
      stockList: [],//入库仓库
      stockTypeList: [],//入库类型
      stockTypeOutList:[],//出库类型
      inOutFormRules: {
        stockObjOutIdObj: [{ required: true, message: '请选择', trigger: 'change' }],
        stockOutType: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      operationData: [],
      showOperation: false,
      showAddProject: false,
      showOperationState:1,
      showOperationTltie:'',
      checkList: [],
      // projectForm: {
      //   project: '',
      // },
      // projectRules: {
        // project: [{ validator: validateProject, trigger: 'blur'}],
      // },
      projectList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      departmentObj:{},//当前用户登录的部门门店
    }
  },
  created() {
    if(this.$route.query.id) this.stockId = this.$route.query.id;
    this.stockDetail();
  },
  mounted() {
    this.getQueryStockObjOut();  //入库/出库对象
    this.logisticsQueryStockInOutType('stockType_in'); //入库/出库类型
    this.logisticsQueryStockInOutType('stockType_out'); //入库/出库类型
    let user=this.$store.state.user.userData;
    let roleId=this.$$.roleName(user)
    this.departmentObj=this.$$.roleName(user,'department')
    this.getStockObjList(user.id) 
  },
  methods: {
    forceUpdate(){
      this.$forceUpdate();
    },
    stockDetail() {//获取详情
      let query={
        "businessId": this.stockId,
        "businessType": "materials",
      }
      this.$api.getStockDetail(this.stockId,query).then(res => {
        if(res.code === 0){
          this.res=res.data;
          this.stockCarInfo = res.data.stockCarInfo;
          this.stockCarInfo.carCheckbox='';
          this.carMaterialsList = res.data.stockMaterialsInfos;
          if(res.data.stockCarInfo.customerContract) this.customerContract=res.data.stockCarInfo.customerContract;
          if(res.data.stockCarInfo.merchantContract) this.merchantContract=res.data.stockCarInfo.merchantContract;
        }
      })
    },
    formatStockStatus(row) {
      switch(row.stockStatus){
        case 11: 
          return '待入库';
        case 12: 
          return '在库';
        case 13: 
          return '在途';
        case 14: 
          return '已出库';
        default:
          return '';
      }
    },
    formatTypeBox(row){
      if(row.materialName.indexOf('钥匙')!=-1){
        let str=''
        if(row.realData){
          str+=`有${row.realData}把`
        }
        if(row.options){
          let a=row.options.split(',')
          if(a[0]){
            str+=`,缺${a[0]}把`
          }
          if(a[1]){
            str+=`,暂缺${a[1]}把`
          }
        }
        return str
      }else{
        return row.materialStatus
      }
    },
    requestUpload(file,fileList,row) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", row.materialsId);
      obj.append("businessName", row.materialName);
      obj.append("businessType", "materials");
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.stockDetail(); //获取详情
          this.$message.success("上传成功");
        }
      });
    },
    //将fullFilePath换成url
    handleFileList(arr) {
      let newArr=[]
      if(arr && arr.length){
        arr.forEach(item => {
          if(item){
            item.url = item.fullFilePath;
            newArr.push(item)
          }
        })
      }
      return newArr
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file,row) {
      let query={
        businessId:row.id,
        businessType:'materials',
        fileId:file.id,
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          this.stockDetail(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    handleIn() {
      if(this.multipleSelection2.length > 0||this.stockCarInfo.carCheckbox){
        this.inDialogVisible = true;
        this.inOutForm= {
          stockObjInId: '',
          stockInType: '',
          stockObjOutId: '',
          stockObjOutIdObj:null,
          stockOutType: '',
          handlerType: 'stock_in'
        }
        // this.inOutForm.handlerType = 'stock_in';
        // this.inOutForm.stockObjInId=this.departmentObj.shop?this.departmentObj.shop.id:'';
      }else{
        this.$message.warning('请选择入库材料！')
      }
    },
    handleOut() {
      if(this.multipleSelection2.length > 0||this.stockCarInfo.carCheckbox){
        // this.validateMaterials(this.multipleSelection2).then(res => {
        //   if(res===true) {
            this.outDialogVisible = true;
            this.inOutForm= {
              stockObjInId: '',
              stockInType: '',
              stockObjOutId: '',
              stockObjOutIdObj:null,
              stockOutType: '',
              handlerType: 'stock_out'
            }
            this.$nextTick(()=>{
              this.$refs.inOutForm.clearValidate()
            })
          // }else{
          //   this.$message.warning(`请上传${res}材料附件`)
          // }
        // })
      }else{
        this.$message.warning('请选择出库材料！')
      }
    },
    submitIn() {
      let params = {
        stockId: this.stockId,
        handlerType: 'stock_in',
        materialsIds: this.multipleSelection2.map(item => {
          if(item.materialsId){
            return item.materialsId
          }else{
            return item.id
          }
        }),
        stockInType: this.inOutForm.stockInType,
        // stockObjInId: this.inOutForm.stockObjInId,
        carId: this.res.carId,
      }
      if(this.stockCarInfo.carCheckbox){
        params.carStockObjInId=this.inOutForm.stockObjInId;//车辆入库仓库ID
        params.carSelected=0//是否选择车辆：0已选择，1未选择，默认1
      }else{
        params.carSelected=1;
      }
      if(this.multipleSelection2.length>0){
        params.materialsStockObjInId=this.inOutForm.stockObjInId;//材料入库仓库ID
      }
      this.postStockHandling(params);
    },
    submitOut(type) {
      this.$refs['inOutForm'].validate((valid) => {
        if (valid) {
          if(type === 'send') {
            if(this.inOutForm.stockOutType !== 31){
                this.$message.warning('出库类型须为调拨出库!');
                return
              }
              this.inOutForm.handlerType='stock_out_send';
            }else{
              this.inOutForm.handlerType='stock_out';
            }

            let params = {
              stockId: this.stockId,
              handlerType: this.inOutForm.handlerType,//处理类型：出库stock_out，出库并发货stock_out_send，入库stock_in ；carId和materialsIds不能全空
              stockOutType: this.inOutForm.stockOutType,//车辆出库类型：31调拨出库, 32证件归还出库, 33证件借用出库, 34其他，35办证出库
              outObjId: this.inOutForm.stockObjOutId,//材料出库仓库ID
              carId: this.res.carId
            }
            params.materialsIds = this.multipleSelection2.map(item => {
              if(item.materialsId){
                return item.materialsId
              }else{
                return item.id
              }
            })
            if(this.stockCarInfo.carCheckbox){
              params.carSelected=0//是否选择车辆：0已选择，1未选择，默认1
            }else{
              params.carSelected=1;
            }
            this.postStockHandling(params);
          }
        });
      
    },
    postStockHandling(params) {//出库，出库并发货，入库 等处理；
      this.$api.postStockHandling(params).then(res => {
        if(res.code === 0){
          this.$message.success(res.message);
          this.inDialogVisible = this.outDialogVisible = false;
          if(params.handlerType==='stock_out_send'){//出库并发货stock_out_send
            this.bus.$emit("closeSelected",'router');
            this.$router.push({
              path: "/salesManager/deliverGoods/carDeliveryNote",
              query:{
                id:res.data,
                state:2//1新增，2修改，3查看
              }
            });
          }else{
            this.stockDetail();
            this.$refs['inOutForm'].resetFields();
            this.stockCarInfo.carCheckbox='';
          }
         
        }
      })
    },
    validateMaterials(arr) {
      // 校验三项图片是否上传
      //机动车交通事故责任强制保险单 机动车行驶证 机动车登记证书
      return new Promise((resolve) => {
        let valid = true;
      
        arr.forEach(item => {
          if(item.materialName === '机动车交通事故责任强制保险单' && item.contractRequiredNums>0 && item.screenshotList && item.screenshotList.length < 1){
            valid = '机动车交通事故责任强制保险单'
          }
          if(item.materialName === '机动车行驶证' && item.contractRequiredNums>0 && item.screenshotList && item.screenshotList.length < 1){
            valid = '机动车行驶证'
          }
          if(item.materialName === '机动车登记证书' && item.contractRequiredNums>0 && item.screenshotList && item.screenshotList.length < 1){
            valid = '机动车登记证书'
          }
        })
        resolve(valid)
      })
      
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
    },
    getQueryStockObjOut() {//出库对象 查询
      this.$api.getQueryStockObjOut().then(res => {
        if(res.code === 0){
          this.stockObjList = res.data;
        }
      })
    },
    logisticsQueryStockInOutType(inOutType) {//入库/出库类型 查询
      this.$api.logisticsQueryStockInOutType(inOutType).then(res => {
        if(res.code === 0){
          if(inOutType=='stockType_in'){//stockType_in入库，stockType_out出库 
            this.stockTypeList = res.data;
          }else{
            this.stockTypeOutList=res.data;
          }
        }
      })
    },
    getStockObjList(userId){//入库/出库仓库查询
      let query={
        shopId:this.departmentObj.shop?this.departmentObj.shop.id:'',
        userId:userId
      }
      this.$api.getStockObjList(query).then(res => {
        if(res.code === 0){
          this.stockList = res.data;
        }
      })
    },
    read(state,row) {
      this.showOperation = true;
      this.showOperationState=state;
      if(state===1){
        this.showOperationTltie='车辆库存流转明细';
      }else{
        this.showOperationTltie=`${row.materialName}-操作明细`;
      }
      if(row){
        this.getStockFlow({
        materialsId: row.materialsId?row.materialsId:row.id,
        })
      }else{
        this.getStockFlow({carId:this.res.carId})
      }
    },
    getStockFlow(query){
      this.$api.getStockFlow(this.$route.query.id,query).then(res => {
          if(res.code === 0){
            this.operationData = res.data;
          }
        })
    },
    // addProject() {
    //   let _self = this;
    //   this.$refs['projectForm'].validate((valid) => {
    //     if (valid) {
    //       _self.projectList.push(_self.projectForm.project)
    //       _self.checkList.push(_self.projectForm.project)
    //       _self.$refs['projectForm'].resetFields()
    //     } else {
    //       return false;
    //     }
    //   })
    // },
    submitProject() {
      if(!this.carMaterialsList){
        this.carMaterialsList = this.checkList
      }else{
        let ids = this.carMaterialsList.map(item => item.id);
        this.checkList.forEach(item => {
          if(!item.screenshotList) {//初始化附件图片列表,避免报错
            item.screenshotList = [];
          }
          if(ids.indexOf(item.id) === -1) {//去重
            this.carMaterialsList.push(item)
          }
        })
      }
      this.showAddProject = false;
      // this.$message.success('操作成功');
    },
    changeStockObjOutId(item) {
      if(item.name==='交付中心'){
        this.inOutForm.stockOutType = 31;
      }
      this.inOutForm.stockObjOutName=item.name;
      this.inOutForm.stockObjOutId=item.id;
    },
    showAddProjectDialog() {//点击添加项目,查询所有项目
      this.$api.getMaterialsList({carId:this.res.carId}).then(res => {
        if(res.code === 0){
          this.projectList = res.data;
          this.showAddProject = true;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.inventoryDetail{
  padding: 20px 40px 140px;
  // table{
  //   border-collapse: collapse;
  //   min-width: 360px;
  //   width: 100%;
  //   td,th{
  //     border: 1px solid #ccc;
  //     padding: 15px 20px;
  //     text-align: center;
  //   }
  // }
  // .carTable td:nth-child(2n-1),.merchant td:nth-child(2n-1){background-color: #eeeeee;}
  .addProject{
    ::v-deep .el-checkbox-group{
      display: flex;
      flex-direction: column;
    }
  }
  .procedureImg{
    width: 50px;
    height: auto;
    margin: 5px;
  }
  .tableUpload{
    ::v-deep .el-upload-list--picture-card .el-upload-list__item{
      width: 100px;
      height: 50px;
    }
    ::v-deep .el-upload--picture-card{
      height: 28px;
      line-height: 26px;
      border: none;
      background-color: transparent;
    }
    ::v-deep .el-upload-list__item-actions{
      line-height: 26px;
    }
    ::v-deep .el-upload--picture-card .el-icon-plus {
      display: none;
    }
  }
  .modalCheckBox{
    max-height:500px;
    overflow:auto;
  }
}
</style>
