<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店简称</span>
        <el-input v-model="form.partnerShortName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>流水类型</span>
        <el-select v-model="form.flowType" size="medium" placeholder="请选择" clearable>
          <el-option v-for="item in flowinglist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>收支类型</span>
        <el-select v-model="form.inOrOut" placeholder="请选择" size="medium" clearable>
          <el-option label="收入" value="0"></el-option>
          <el-option label="支出" value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
        <el-button type="primary" @click="onsee" size="medium" plain>钱包充值</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="shortName" label="合作店简称" align="center"></el-table-column>
      <el-table-column prop="flowTypeName" label="钱包流水类型" align="center"></el-table-column>
      <el-table-column prop="inOrOutName" label="收支类型" align="center"></el-table-column>
      <el-table-column prop="amount" label="金额（元）" align="center"></el-table-column>
      <el-table-column prop="updateDate" label="操作时间" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carTradePrice" label="成交价格（元）" align="center"></el-table-column>
      <el-table-column prop="totalFax" label="所扣总税" align="center"></el-table-column>
      <!-- <el-table-column prop="linkChannelName" label="产生费用的渠道简称" align="center"></el-table-column> -->
      <el-table-column prop="remark" label="余额变动说明" align="center"></el-table-column>
      <!-- <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onsee(scope.row,'充值')"
            size="mini"
            class="group-custom"
          >充值</el-button>
          <el-button
            type="primary"
            @click="onsee(scope.row,'冲账')"
            size="mini"
            class="group-custom"
          >冲账</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      form: {},
      flowinglist: [], //流水类型
      walletId:'',//钱包id
      channelId:'',//渠道id
    };
  },
  created() {
    this.walletId=this.$route.query.walletId;
    this.channelId=this.$route.query.channelId;
    if(this.$route.params.shortName){
      this.form.partnerShortName=this.$route.params.shortName;
    }
    this.init();
    this.getWalletFlowTypeList()//获取流水类型
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        "curPage": this.currentPage,
        shortName:this.form.partnerShortName,//合作店简称
        "pageSize": this.pageSize,
        flowType:this.form.flowType,//流水类型
        inOrOutType:this.form.inOrOut,//收支类型，收入:0，支出:1
        "carNo": this.form.carNo
      };
      this.$api.postChannelWalletFlowPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist=[]
            this.total=0;
          }
        }else{
          this.querylist=[]
          this.total=0;
        }
      });
    },
    onsee() {
      this.$router.push({
        path: "/channel/walletRecharge",
        // query: {
        //   state: state,
        //   channelId: row.channelId
        // }
      });
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    getWalletFlowTypeList(){//查询钱包流水类型列表
      this.$api.getWalletFlowTypeList().then((res)=>{
        if(res){
          if(res.code==0){
            this.flowinglist=res.data
          }
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .w90 {
    width: 90%;
  }
  .wSelect {
    width: 87%;
  }
}
</style>
