<template>
  <div class="pageContainer">
    <!-- <el-radio-group v-model="activeName" class="groupbg" @change="init(1)">
      <el-radio-button label="全部"></el-radio-button>
      <el-radio-button label="待入库"></el-radio-button>
      <el-radio-button label="在库"></el-radio-button>
      <el-radio-button label="已出库"></el-radio-button>
    </el-radio-group> -->
    <el-row :gutter="24" class="flex flex_wrap">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>车牌号</span>
        <el-input clearable v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="8" :lg="6" class="flex flex_align_center flex_between w5 mb15">
        <span>合同主项日期</span>
        <el-date-picker
            v-model="form.date"
            type="daterange" clearable
            start-placeholder="开始日期" size="medium"
            end-placeholder="结束日期" value-format="yyyy-MM-dd"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>成交门店</span>
        <el-select clearable v-model="form.shopId" size="medium">
          <el-option v-for="item in storeList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
        <!-- <el-select v-model="form.branchCompanyValue" placeholder="请选择分公司" class="w150" @change="changelink($event,1)" value-key="id">
          <el-option v-for="item in departmentlist" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
        <el-select v-model="form.cityValue" placeholder="请选择城市" class="w150 mr10 ml10" @change="changelink($event,2)" value-key="id">
          <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
        <el-select v-model="form.shopId" placeholder="请选择门店" class="w150">
          <el-option v-for="item in shopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select> -->
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>车主姓名</span>
        <el-input clearable v-model="form.customerName"  size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15">
        <span>车主身份证</span>
        <el-input clearable v-model="form.customerIdNo"  size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>企业名称</span>
        <el-input clearable v-model="form.companyName"  size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15">
        <span>营业执照号</span>
        <el-input clearable v-model="form.businessLicense"  size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <el-button type="primary" @click="init(1)" size="medium">查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg table-cursor" >
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="makerName" label="车型" align="center">
        <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="customerContractSignTime" label="客户合同签订时间" align="center"></el-table-column>
      <el-table-column prop="customerName" label="车主姓名/企业名称" align="center">
        <template slot-scope="scope">{{scope.row.customerName}}{{scope.row.companyName}}</template>
      </el-table-column>
      <el-table-column prop="customerIdNo" label="车主身份证号/营业执照号" align="center">
        <template slot-scope="scope">{{scope.row.customerIdNo}}{{scope.row.businessLicense}}</template>
      </el-table-column>
      <el-table-column prop="customerDeadline" label="合同主项材料日期" align="center"></el-table-column>
      <el-table-column prop="stockWaitInStoreCounts" label="待入库" align="center" width="87px" v-if="activeName=='待入库'||activeName=='全部'">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockWaitInStoreCounts">{{scope.row.stockWaitInStoreCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/warehouseKeeper/inventoryManagementDetails', query: {id:scope.row.id}})">{{scope.row.stockWaitInStoreCounts}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="stockOnRoadCounts" label="在途" align="center" width="87px" v-if="activeName=='在途'||activeName=='全部'">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockOnRoadCounts">{{scope.row.stockOnRoadCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/warehouseKeeper/inventoryManagementDetails', query: {id:scope.row.id}})">{{scope.row.stockOnRoadCounts}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="stockInStoreCounts" label="在库" align="center" width="87px" v-if="activeName=='在库'||activeName=='全部'">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockInStoreCounts">{{scope.row.stockInStoreCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/warehouseKeeper/inventoryManagementDetails', query: {id:scope.row.id}})">{{scope.row.stockInStoreCounts}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="stockOutStoreCounts" label="已出库" align="center" width="87px" v-if="activeName=='已出库'||activeName=='全部'">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockOutStoreCounts">{{scope.row.stockOutStoreCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/warehouseKeeper/inventoryManagementDetails', query: {id:scope.row.id}})">{{scope.row.stockOutStoreCounts}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "全部",
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:20,
      form: {
        carNo:''
      },
      departmentlist:[],//门店列表
      cityList:[],//城市
      shopList:[],//门店
      storeList:[],
    };
  },
  created() {
    this.init();
    this.getShopList();//成交门店
    // this.departmentQuery();//获取分公司
  },
  methods: {
    init(page) {
      let stockStatus=[];
      switch(this.activeName){
        case '全部':
          stockStatus='';
          break;
        case '待入库':
          stockStatus=[11];
          break;
        case '在库':
          stockStatus=[12];
          break;
        case '在途':
          stockStatus=[13];
          break;
        case '已出库':
          stockStatus=[14];
          break;
      }
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,
        "carNo": this.form.carNo,
        "pageSize": this.pageSize,
        "carStockStatus": stockStatus,//库存状态，不传查全部，不是列表查询中的条件：11待入库, 12在库, 14已出库
        // "carOrMaterialsStockStatus": stockStatus,//车辆或者材料的库存状态，不传查全部，列表查询中的条件：11待入库, 12在库, 13在途, 14已出库
        "customerName": this.form.customerName,//客户名称
        "customerIdNo":this.form.customerIdNo,//客户身份证号
        "companyName": this.form.companyName,//公司名称
        "businessLicense": this.form.businessLicense,//公司营业执照
        departLevel1Id:this.form.branchCompanyId,//分公司ID
        cityIds:this.form.cityId?[this.form.cityId]:'',//城市ID集合
        "tradeShopIds": this.form.shopId?[this.form.shopId]:'',//成交门店ID
        "customerDeadlineBegin": this.form.date?this.form.date[0]:'',//客户办证截止开始日期
        "customerDeadlineEnd": this.form.date?this.form.date[1]:'',//客户办证截止结束日期
        // "stockObjId": 1,//仓库ID
        "materialType":this.form.materialType?[this.form.materialType]:'' //项目类型：31车辆，32身份证，33暂住证，34车辆登记证
      }
      this.$api.getStockList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = [];
            this.total = 0;
          }
        }
      })
    },
    dbclick(row){
      // let stockStatus=11;
      // switch(this.activeName){
      //   case ''
      // }
      // this.$router.push({
      //   // /inventoryDetail?stockStatus=11&id=99
      //   path: "/warehouseKeeper/inventoryManagementDetails",
      //   query: {
      //     stockStatus: stockStatus,
      //     id:row.id
      //   }
      // });
      // this.$router.push({
      //   path: "/warehouseKeeper/inventoryManagementDetails",
      //   query: {
      //     state: this.activeName,
      //     id:row.id
      //   }
      // });
    },
    changelink(e,state){
      if(state==1){
        this.recursionQueryUserListByDepartIds(e.id)
        this.form.branchCompanyId=e.id;
      }else if(state==2){
        this.shopList=e.shopList;
        this.form.cityId=e.id;
      }
    },
    handleSizeChange(e) {
       this.pageSize=e;
       this.currentPage=1;
       this.init()
    },
    handleCurrentChange(e) {
      this.currentPage=e;
      this.init()
    },
    getShopList() {//获取成交门店
      let query={
        isEnabled:0,
        shopTypeList:[0]
      }
      this.$api.getShopList(query).then(res => {
        if(res.code == 0){
          this.storeList = res.data;
        }
      })
    },
    departmentQuery(){//获取部门（门店）
      let query={
        level:1,//部门层级, 总公司0，分公司1，部门2，子部门3.4.5等往下递进
      }
      this.$api.departmentQuery(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.departmentlist=res.data.records;
          }
        }
      })
    },
    recursionQueryUserListByDepartIds(departIds){//三级联动获取城市和门店
      let query={
        departIds:departIds
      }
      this.$api.recursionQueryUserListByDepartIds(query).then((res)=>{
        if(res){
          if(res.code==0){
            if(res.data.length>0){
              this.cityList=res.data[0].cityList;
            }
          }
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .formRowBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .line{
      margin: 0 10px;
    }
  }
  .w150 {
    width: 150px;
  }
}
</style>
