<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="待补办" name="first">
        <el-row :gutter="20" class="searchRowBox clear">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>客户名称</span>
            <el-input v-model="forms.customerName" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>办证截至时间</span>
            <el-date-picker v-model="forms.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w4 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
          <el-button type="primary" @click="onadd('form')" size="medium" class="fr mr10">新增补办</el-button>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
          <el-table-column prop="contractDate" label="客户合同签订日期" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="customerDeadline" label="办证截止日期" align="center"></el-table-column>
          <el-table-column prop="taskProcedureStatusName" label="办证状态" align="center"></el-table-column>
          <el-table-column prop="sellerName" label="帮卖经理" align="center"></el-table-column>
          <!-- <el-table-column prop="name" label="收款时间" align="center"></el-table-column> -->
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button type="primary" @click="onconfirm(scope.row,1)" size="mini">补办手续</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        ></el-pagination>
      </el-tab-pane>
      <!-- <el-tab-pane label="补办中" name="second">
        <el-row :gutter="20" class="searchRowBox">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>客户名称</span>
            <el-input v-model="forms1.customerName" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>补办下单时间</span>
            <el-date-picker v-model="forms1.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w4 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
          <el-table-column prop="contractDate" label="客户合同签订日期" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="customerDeadline" label="办证截止日期" align="center"></el-table-column>
          <el-table-column prop="taskProcedureStatusName" label="办证状态" align="center"></el-table-column>
          <el-table-column prop="sellerName" label="帮卖经理" align="center"></el-table-column>
          <el-table-column prop="createDate" label="下单时间" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button type="primary" @click="onconfirm(scope.row,2)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        ></el-pagination>
      </el-tab-pane> -->
      <el-tab-pane label="补办完成" name="third">
        <el-row :gutter="20" class="searchRowBox">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms2.carNo"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>客户名称</span>
            <el-input v-model="forms2.customerName"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>补办完成时间</span>
            <el-date-picker v-model="forms2.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w4 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist2" border stripe class="table-mainbg">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
          <el-table-column prop="contractDate" label="客户合同签订日期" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="customerDeadline" label="办证截止日期" align="center"></el-table-column>
          <el-table-column prop="taskProcedureStatusName" label="办证状态" align="center"></el-table-column>
          <el-table-column prop="sellerName" label="帮卖经理" align="center"></el-table-column>
          <!-- <el-table-column prop="updateDate" label="完成时间" align="center"></el-table-column> -->
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button type="primary" @click="onconfirm(scope.row,3)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize2"
          :current-page="currentPage2"
          :total="total2"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="新增补办"
      :visible.sync="dialogVisible"
      :show-close="false"
      class="customDialog"
      width="750px"
    >
      <el-form :model="formCar" ref="formCar" :rules="rules" :hide-required-asterisk="true" label-width="120px"  class="topFormTitle">
        <el-row>
          <el-col :span="12" >
            <el-form-item label="车牌号:" prop="carNo">
              <el-input
                type="text"
                v-model="formCar.carNo"
                class="w180"
                size="medium"
                placeholder="请输入完整车牌号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-button type="primary" size="medium" @click="onSearch" plain >查询</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="品牌车型:">
              <p class="w200 form_title_spanInbox">{{formCar.brandName}}&nbsp;{{formCar.modelName}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户办证截止日期:" label-width="150px">
              <p class="w180 form_title_spanInbox">{{formCar.merchantContract?formCar.merchantContract.merchantDeadline:formCar.merchantDeadline}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户名称:">
              <p class="w200 form_title_spanInbox">{{formCar.carMerchant?formCar.carMerchant.merchantType===1?formCar.carMerchant.authCompanyName:formCar.carMerchant.merchantName:formCar.merchantName}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称:" label-width="150px">
              <p class="w180 form_title_spanInbox">{{formCar.customerName}}</p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <i class="lineDotted"></i>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :rules="rules"
        :hide-required-asterisk="true"
        class="mt20" :disabled="formCar.id?false:true"
      >
      <el-row>
        <el-col :span="12">
          <el-form-item label="补办项目:" prop="name">
            <el-select v-model="form.obj" placeholder="请选择补办项目" class="w180" size="medium" clearable value-key="id" v-show="!projectType" @change="onSelect(form.obj)">
              <el-option v-for="item in replaceList" :key="item.id" :label="item.itemName" :value="item"></el-option>
            </el-select>
            <el-input v-model="form.name" class="w180" size="medium" v-show="projectType"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
         <el-button type="primary" size="medium" @click="projectType=true" plain>选不到加一个</el-button> 
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="补办要求:" >
            <el-select
              v-model="form.nature"
              placeholder="请选择补办要求"
              class="w180"
              size="medium" :disabled="projectType?false:true">
              <el-option label="需变更" value="需变更"></el-option>
              <el-option label="补办" value="补办"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="费用承担方:" prop="feeBearerId">
            <el-select
              v-model="form.feeBearerId"
              placeholder="请选择费用承担方"
              class="w180"
              size="medium">
              <el-option v-for="item in feeBearerlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收取费用:" prop="chargingFeeAmount">
            <el-input type="number" v-model.number="form.chargingFeeAmount" class="w180" size="medium"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="办证供应商:" prop="certCompanyObj">
            <el-cascader :options="whichSidelistId" :props="props"  v-model="form.certCompanyObj" clearable ref="refcascader" @change="changeSupplie" :show-all-levels="false"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支付费用:" prop="payFeeAmount">
            <el-input type="number" v-model.number="form.payFeeAmount" class="w180" size="medium"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="footer-modalBut" @click="onSubmitform('form')">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      forms: {},
      forms1: {},
      forms2: {},
      querylist: [],
      querylist1: [],
      querylist2: [], //
      total: 0,
      pageSize: 20,
      currentPage: 1,
      total1: 0,
      pageSize1: 20,
      currentPage1: 1,
      total2: 0,
      pageSize2: 20,
      currentPage2: 1,
      dialogVisible: false,
      form: {
        name: "",
        beneficiaryId: "",
        certCompanyObj:[],
        payFeeAmount: "",
        chargingFeeAmount: "",
        feeBearerId:'',
        nature:'',
      },
      formCar:{
        carNo:''
      },
      rules: {
        carNo: [{ required: true, message: "车牌号码不能为空" }],
        certCompanyObj: [{ required: true, message: "办证供应商不能为空" }],
        name: [{ required: true, message: "补办项目不能为空" }],
        feeBearerId: [{ required: true, message: "费用承担方不能为空" }],
        chargingFeeAmount: [{ required: true, message: "收取费用不能为空" }],
        payFeeAmount: [{ required: true, message: "支付费用不能为空" }],
        nature: [{ required: true, message: "不能为空" }]
      },
      replaceList:[],//补办项目
      projectType:false,//新增补办是否要添加input
      feeBearerlist:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司',children:[]}],//
      whichSidelistId:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司',children:[]},{id:5,name:'违章供应商',children:[]}],//供应商列表,//受益方下拉查询
      props:{
        label:'name',
        value:'id',
        children:'children'
      }
    };
  },
  created() {
    this.init();
    this.postSupplierList();//获取供应商
  },
  methods: {
    onSelect(row){
      if(row.feeBearerId){
        this.form.feeBearerId= row.feeBearerId;
      }
      if(row.itemName.indexOf('补办')!=-1||['机动车行驶证','车辆牌照'].includes(row.itemName)){
        this.form.nature='补办'
      }else if(row.itemName.indexOf('变更')!=-1){
        this.form.nature='需变更'
      }
      if(row.payFeeAmount) this.form.payFeeAmount= row.payFeeAmount;
      if(row.chargingFeeAmount) this.form.chargingFeeAmount= row.chargingFeeAmount;
      if(row.itemName) this.form.name=row.itemName;
    },
    changeSupplie(e){
      let node=this.$refs.refcascader.getCheckedNodes();
      if(node.length>0){
        this.form.beneficiaryId=e[0];
        this.form.benefitCertRuleSupplierId=node[0].path[1]
        this.form.benefitCertRuleSupplierName=node[0].pathLabels[1]
      }
    },
    init(page) {
      let query = {
        // curPage: currentPage, //当前页数
        // pageSize: pageSize,
        taskType: 7,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        // customerName:customerName,
        // taskProcedureStatuses:[taskStatus],
        // carNo:carNo,
        // merchantDeadlineBegin:this.params.date?`${this.params.date[0]} 00:00:00`:'',
        // merchantDeadlineEnd:this.params.date?`${this.params.date[1]} 23:59:59`:'',
      };
      switch (this.activeName) {
        case "first":
          this.currentPage=page?page:this.currentPage;

          query.taskProcedureStatuses = [81,82]; //待补办
          query.pageSize = this.pageSize;
          query.curPage = this.currentPage;
          query.customerName=this.forms.customerName;
          query.carNo=this.forms.carNo;
          query.merchantDeadlineBegin=this.forms.date?`${this.forms.date[0]}`:'';
          query.merchantDeadlineEnd=this.forms.date?`${this.forms.date[1]}`:'';
          break;
        // case "second":
        //   query.taskProcedureStatuses = [82]; //补办中
        //   query.pageSize = this.pageSize1;
        //   query.curPage = page?page:this.currentPage1;
        //   query.customerName=this.forms1.customerName;
        //   query.carNo=this.forms1.carNo;
        //   query.merchantDeadlineBegin=this.forms1.date?`${this.forms1.date[0]}`:'';
        //   query.merchantDeadlineEnd=this.forms1.date?`${this.forms1.date[1]}`:'';
        //   break;
        case "third":
          this.currentPage2=page?page:this.currentPage2;

          query.taskProcedureStatuses = [83]; //补办完成
          query.pageSize = this.pageSize2;
          query.curPage = this.currentPage2;
          query.customerName=this.forms2.customerName;
          query.carNo=this.forms2.carNo;
          query.merchantDeadlineBegin=this.forms2.date?`${this.forms2.date[0]}`:'';
          query.merchantDeadlineEnd=this.forms2.date?`${this.forms2.date[1]}`:'';
          break;
      }
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            switch (this.activeName) {
              case "first":
                this.querylist = res.data.records;
                this.total = res.data.total;
                break;
              case "second":
                this.querylist1 = res.data.records;
                this.total1 = res.data.total;
                break;
              case "third":
                this.querylist2 = res.data.records;
                this.total2 = res.data.total;
                break;
            }
          } else {
            switch (this.activeName) {
              case "first":
                this.querylist = [];
                this.total = 0;
                break;
              case "second":
                this.querylist1 = [];
                this.total1 = 0;
                break;
              case "third":
                this.querylist2 = [];
                this.total2 = 0;
                break;
            }
          }
        }
      });
    },
    onconfirm(row) {
      let aurl = "/accreditationCommissioner/vehicleFormalitiesDetails";
      this.$router.push({
        path: aurl,
        query: {
          id:row.id
        }
      });
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onadd(formdata) {
      this.ondialogVisible();
      this.projectType=false;
      this.formCar={};
      if (this.$refs[formdata]) {
        this.$refs[formdata].resetFields();
      }
      if (this.$refs['formCar']) {
        this.$refs['formCar'].resetFields();
      }
    },
    onSearch() {//搜索车号弹出框专用
      this.$api.getSimpledetailCar(this.formCar.carNo).then((res)=>{
        if(res){
          if(res.code==0){
            if(res.data){
              this.formCar=res.data;
              this.getReplaceItem(res.data.isHuPlateName);//查询补办项目状态
            }
          }
        }
      })
    },
    onSubmitform(formdata) {
      let formCarVal=new Promise((resolve,reject)=>{
        this.$refs['formCar'].validate(valid => {
        if (valid) {
          return resolve(true)
        } else {
          return reject(false);
        }
      });
      }).catch(()=>{
        reject(false);
      })
      let formVal=new Promise((resolve,reject)=>{
        this.$refs[formdata].validate(valid => {
        if (valid) {
          return resolve(true)
        } else {
          return reject(false);
        }
      });
      }).catch(()=>{
        reject(false);
      })
      Promise.all([formCarVal,formVal]).then(()=>{
        this.postProcedureMaterialAdd()
      }).catch((error)=>{
        return error
      })
    },
    handleSizeChange(data) {
      switch (this.activeName) {
        case "first":
          this.pageSize = data;
          this.currentPage=1;
          break;
        case "second":
          this.pageSize1 = data;
          this.currentPage1=1;
          break;
        case "third":
          this.pageSize2 = data;
          this.currentPage2=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(data) {
      switch (this.activeName) {
        case "first":
          this.currentPage = data;
          break;
        case "second":
          this.currentPage1 = data;
          break;
        case "third":
          this.currentPage2 = data;
          break;
      }
      this.init()
    },
    postProcedureMaterialAdd(){//新增补办
      if(!this.formCar.id){
        this.$message('请先查询车牌号！')
        return
      }
      let query={
        "name": this.form.name,//补办项目ID
        "carId": this.formCar.id,
        "feeBearerId": this.form.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
        "chargingFeeAmount": this.form.chargingFeeAmount,//收取费用金额
        // "beneficiaryName": "xx",
        "payFeeAmount": this.form.payFeeAmount,//支付费用金额
        beneficiaryId:this.form.beneficiaryId,//受益方Id：1客户，2商户，3车开新，4办证公司，5违章供应商
        benefitCertRuleSupplierId:this.form.benefitCertRuleSupplierId,//受益方供应商ID
        benefitCertRuleSupplierName:this.form.benefitCertRuleSupplierName,//受益方供应商名字
        nature:this.form.nature,
      }
      this.$api.postProcedureMaterialAdd(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.ondialogVisible();
            this.init();
            this.$message.success(res.message)
          }
        }
      })
    },
    getReplaceItem(plateType){//查询补办项目状态
      let query={
        plateType:plateType,
        types:[2]
      }
      this.$api.postFeeStandardList(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.replaceList=res.data;
          }
        }
      })
    },
    postSupplierList(){//供应商列表
      this.$api.postSupplierList({}).then(res=>{
        if(res.code==0){
          for(let i=0;i<this.whichSidelistId.length;i++){
            let item=this.whichSidelistId[i]
            if(['办证公司','违章供应商'].includes(item.name)){
              item.children=res.data;
            }
          }
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
  .form_title_spanInbox {
    display: inline-block;
  }
  .lineDotted {
    display: inline-block;
    width: 600px;
    height: 1px;
    background-color: #cccccc;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    margin-left: 15px;
  }
}
</style>