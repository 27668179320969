<template>
  <div class="pageContainer">
    <div class="flex flex_between">
      <div class="softHeadBox">
        <!-- <iframe id="iframe" :src="iframeSrc" frameborder="0" class="iframeBox" ref="iframeref"></iframe> -->
        <!-- <input id="extension" v-model="extension" :data-url="objUrl" hidden/> -->
        <!-- <input id="device" v-model="device" :data-url="objUrl" hidden/> -->

        <h2 class="titleConter" v-if="!softCodeStatus&&loginType">重要提示：当前已登录浏览器窗口不是初次登录窗口，话机置忙等操作请去初始页面操作！</h2>
        <h2 class="titleConter" v-if="softCodeStatus&&loginType">重要提示：当前窗口为初始登录窗口，当前浏览器窗口不可刷新、不可关闭，不然会接收不到客户来电弹屏信息！</h2>
        <div class="gatterButton">
          <el-button type="primary" @click="onCall(1)" v-if="!loginType">登录</el-button>
          <el-button type="primary" plain @click="onCall(9)" v-show="['OFFLINE','Offline'].includes(onlineStatus)">上线</el-button>
          <!-- <el-button type="primary" @click="onCall(2)" v-if="loginType">下线</el-button> -->
           <el-button type="primary" plain @click="onCall(10)" v-if="loginType">下线</el-button>

          <template v-if="loginType">

          
          <!-- <el-button type="primary" plain @click="onCall(10)" v-show="onlineStatus==='GoLive'">下线</el-button> -->

          <el-button  plain @click="onCall(3)" v-show="softCodeStatus==='IDLE'">置忙</el-button>
          <el-button  plain @click="onCall(4)" v-show="softCodeStatus==='PAUSE'">置闲</el-button>
          <!-- <el-button plain @click="onCall(5)" v-show="softStatus!=='hold'&&softCodeStatus==='BUSY'">保持</el-button>
          <el-button plain @click="onCall(6)" v-show="softCodeStatus==='BUSY'">取消保持</el-button> -->
          <el-button plain @click="onCall(7)" v-show="softCodeStatus==='BUSY'">静音</el-button>
          <el-button plain @click="onCall(8)" v-show="softCodeStatus==='BUSY'">取消静音</el-button>
          </template>
        </div>
        <!-- <el-button type="primary" plain @click="onCall(5)">静音</el-button> -->
      </div>
      <div class="flex flex_align_center">
        <div class="mr20">
          <span class="mr30">座席号：{{cno}}</span>
          <span>绑定电话：{{device}}</span>
        </div>
        <div class="flex">
          <el-button type="text" class="el-icon-s-custom" @click="dialogVisible=true" v-if="!device">绑定电话</el-button>
          <el-button type="text" @click="onlogin(2)" v-if="device">解绑</el-button>
        </div>
      </div>
    </div>

    
    <div class="softConter"> 
      <div class="ckxTitle mb10 mt30">用户队列</div>
      <div class="mb10">
        <el-input v-model="userSear" @input="onSearch" class="w300" placeholder="请输入名称搜索" size="medium"></el-input>
      </div>
      <el-radio-group v-model="queue">
        <!-- <div class="ckxTitle mb10 mt30">互动坐席</div> -->
        <!-- <el-radio v-for="item in interactionList" :key="item.id" :label="item" class="mt10">{{item.empName}}</el-radio> -->
        
        <el-radio v-for="item in storeQueue" :key="item.id" :label="item.no" class="mt15">【{{item.no}}】{{item.name}}</el-radio>
        <!-- <div class="ckxTitle mb10 mt30">其他队列</div>
        <el-radio v-for="item in otherQueues" :key="item.id" :label="item.id" class="mt10">{{item.name}}</el-radio> -->
      </el-radio-group>
    </div>
    <div class="footer" v-if="loginType">
      <div class="btn">
        <el-button type="primary" @click="onTransfer" >转接</el-button>
      </div>
    </div>
    <el-dialog
      title="绑定电话" width="400px"
      :visible.sync="dialogVisible">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="坐席号">
          <el-input v-model="form.extension" placeholder="请输入" ></el-input>
        </el-form-item>
        <el-form-item label="绑定电话">
          <el-input v-model="form.device" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- <el-form-item label="">
          <el-checkbox v-model="form.checked">记住我的选择，下次不再询问</el-checkbox>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onlogin(1)">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
    title="提示"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" >请输入密码：</p>
    <el-input auto-complete="off" v-model="formCar" show-password @keyup.enter.native="onSubmitCar" class="w300">
          </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
//  var options = {
//     isAutoLogin: true, // 展示登录界面
//     // 隐藏登录界面中的部分版块配置
//     toolbarMsgShowConfiguration: {
//         showEnterprise: false, // 展示企业编码、座席工号、密码
//         showTel: true, //展示电话类型和电话号码
//         showAgentInitStatus: true, //展示座席初始状态
//         showTelInput: true, // 登录状态下是否显示号码输入框、默认为true
//         showCallingBth: true, // 登录状态下是否显示呼叫按钮、默认为true
//     },
//     // 话务管理配置
//     showHold: true, // 支持保持
//     showConsult: true, //支持咨询,
//     showTransfer: true, //支持转移
//     showSatisfaction: true, //支持满意度
//     showMute: true, //支持静音
//     debug: true // 是否开启debug 默认开启
// }
//   const dom = document.getElementById('root');
//   const clinkToolbar = new ClinkToolbar(options, dom, () => {
//     console.log('电话条初始化完成');
//   });

export default {
  data(){
    return{
      dialogVisibleCar:false,
      formCar:'',
      dialogVisible:false,
      form:{},
      extension:'',//坐席号
      device:'',
      disabled:true,
      queue:'',
      iframeSrc:'soft.html',
      objUrl:{},
      baseUrl:'',//本服务地址
      severSingUrl:'',//深海捷地址
      interactionList:[],//互动队列
      storeQueue:[],//门店队列
      otherQueues:[],//其他队列
      cno:'',
      loginType:false,//当前是否登录
      softStatus:'',
      softCodeStatus:'',
      onlineStatus:'GoLive',
      userData:{},
      resJson:{},
      storeQueueOld:[],
      userSear:'',
    }
  },
  created(){
    this.userData=this.$store.state.user.userData;
    
    // this.storeQueue=this.$store.state.setting.storeQueue;
    this.otherQueues=this.$store.state.setting.otherQueues;
    let user=this.$store.state.user.userData;
    this.extension=user.no;
    // this.postlistCnoEmpRela()//坐席与员工工号的绑定关系列表【坐席和绑定员工是1V1关系】
    this.getCallDetailByCno()//根据员工工号查询坐席信息
  },
  mounted(){
    let _this=this;
    let roleName=this.$$.roleName(this.userData)
    // console.log(this.userData,'===userData===',roleName.name)
    // this.loginType=this.$store.state.user.softloginType;
    // this.softStatus=this.$store.state.user.softStatus;
    this.softCodeStatus=this.$store.state.user.softCodeStatus;
    ClinkAgent.setup({sipPhone: false, debug: false}, function () {
        ClinkAgent.registerListener(ClinkAgent.EventType.STATUS, function (e) {
          // console.log(e.agentStatus,e.code,'---******888-----',e)
          _this.softStatus=e.action;
          _this.softCodeStatus=e.code;
          _this.$store.dispatch('user/setSoftStatus',e.action); 
          _this.$store.dispatch('user/setSoftCodeStatus',e.code); 
          _this.$forceUpdate()
          /**离线操作**/
          if(e.code==='OFFLINE'){
            _this.getCallDetailByCno()
          }
          /**接听与转接**/
          if(e.action=='busyIb'||e.action=="busyTransfer"){
              let query={
                sessionId:e.mainUniqueId,
                tele:e.customerNumberEncrypt,
              }
              _this.$api.postCustomerCallIn(query).then(res=>{
                if(res.code==0){
                  if(res.data){
                    if(roleName.name=='帮卖经理'){
                      let pathData1=_this.$router.resolve({
                        path:'/salesManager/customer',
                        query:{
                          mainUniqueId:e.mainUniqueId,
                          followUpInfoId:res.data
                        }
                      })
                      window.open(pathData1.href,'_blank')
                    }else{
                      let pathData2=_this.$router.resolve({
                          path:'/testService/customer',
                          query:{
                            mainUniqueId:e.mainUniqueId,
                            followUpInfoId:res.data
                          }
                      })
                      window.open(pathData2.href,'_blank')
                    }
                  }else{
                    let pathData=_this.$router.resolve({
                      // name:'testServiceAddCustomer',
                      path:'/testService/addCustomer',
                      query:{
                        mainUniqueId:e.mainUniqueId,
                        tel:e.customerNumberEncrypt
                      },
                      params:{
                         mainUniqueId:e.mainUniqueId,
                        tel:e.customerNumberEncrypt
                      }
                    })
                    window.open(pathData.href,'_blank')
                  }
                }
              })
          }
        });

        ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGIN, function (result) {
          if(result.code === 0){
            _this.getCallDetailByCno()
          } else {
            _this.$message.error(result.msg)
          }
          // console.log('登录事件回调:', result);
        })

        clinkToolbar.userCustomEvent.on(ClinkAgent.EventType.KICKOUT, e => {
           _this.getCallDetailByCno()
            // console.log('被踢下线事件回调:', e);
        });

    });
    
    this.queryUserListByRoleAndDepart()//根据角色和部门查询用户

    // clinkToolbar.userCustomEvent.on(ClinkAgent.EventType.PREVIEW_OUTCALL_BRIDGE, e => {
    //   if(_this.$route.query.followUpInfoId){
    //     let query={
    //       followUpInId:_this.$route.query.followUpInfoId,
    //       sessionId:e.mainUniqueId,
    //       tele:e.customerNumber,
    //     }
    //     _this.$api.postCustomerCallOut(query).then(()=>{})
    //   }
		//     // console.log('预览外呼客户接听事件回调:', e);
		// });

    // clinkToolbar.userCustomEvent.on(ClinkAgent.EventType.RINGING, e => {
    //     console.log('响铃事件回调:', e);
    // });


      // clinkToolbar.userCustomEvent.on(ClinkAgent.EventType.STATUS, e => {
      //     console.log('座席状态事件回调----:', e);
      //     _this.loginType=true;
      // });

  },
  methods:{
    onSubmitCar(){
      if(!this.device){
        this.$message.warning('请先绑定电话再继续登录！')
        return
      }
      if(!this.formCar){
        this.$message.warning('请输入密码!')
        return
      }
      var params = {
          identifier: 'chekaixin',
          cno: this.cno,
          needPasswordEncry:0,
          password: this.formCar,
          bindTel: this.device,
          bindType: 2,
          loginStatus: 1
      };
      ClinkAgent.login(params)
      this.dialogVisibleCar=false;
    },
    onCall(state){
      let _this=this;
      switch(state){
        case 1://上线
          if(['OFFLINE','Offline'].includes(this.onlineStatus)){
            _this.$message.warning('请先上线再进行登录！')
            return
          }
          if(!this.cno){
            _this.$message.warning('请先绑定电话再进行登录！')
            return
          }
          this.dialogVisibleCar=true;
          this.formCar='';
          break;
        case 2://退出
          var params1={
            logoutMode:1,//0：退出后台在线、1：完全退出
            removeBinding:0,//解绑电话，0：不解绑、1：解绑
          }
          ClinkAgent.logout(params1)
          break;
        case 3://置忙
          var params2={
            pauseType:1,//类型，1：置忙、2：休息
            pauseDescription:'置忙',
          }
          ClinkAgent.pause(params2)
          break;
        case 4://置闲
          ClinkAgent.unpause({})
          break;
        case 5://保持
          var params4={
            holdType:0,//保持类型，0：通话保持、1：咨询保持、2：转移保持，默认通话保持
          }
          ClinkAgent.pause(params4)
          break;
        case 6://取消保持
          var params5={
            holdType:0,//保持类型，0：通话保持、1：咨询保持、2：转移保持，默认通话保持
          }
          ClinkAgent.unhold(params5);
          break;
        case 7://静音
          var params6={
            direction:'all',//静音方向，in：座席侧、out：客户侧、all：全部侧
          }
          ClinkAgent.mute(params6);
          break;
        case 8://取消静音
          var params7={
            direction:'all',//静音方向，in：座席侧、out：客户侧、all：全部侧
          }
          ClinkAgent.unmute(params7);
          break;
        case 9://上线
          var params8={
            cno:this.cno,
          }
          this.$api.postCallOnline(params8).then(res=>{
            if(res.code==0){
              _this.softStatus='GoLive';
              _this.$store.dispatch('user/setOnlineStatus','GoLive')
              _this.$message.success(res.message)
              _this.getCallDetailByCno()
            }
          })
          break;
        case 10://下线
          var params9={
            cno:_this.cno,
          }
          this.$api.postCallOffline(params9).then(res=>{
            if(res.code==0){
              _this.loginType=false;
              _this.$store.dispatch('user/setSoftloginType',false)
              _this.$message.success(res.message)
              _this.getCallDetailByCno()//根据员工工号查询坐席信息
            }
          })
          break;
      }
    },
    onTransfer(){//转接
      // let params={
      //   objectType:2,
      //   transferObject:''
      // }
      // ClinkAgent.transfer(params);
      if(!this.queue){
        this.$message.warning('请选择需要转接的人员！')
        return
      }
      if(!this.cno){
        this.$message.warning('当前坐席未绑定！')
        return
      }
      let query={
        cno:this.cno,
        transferCno:this.queue
        // transferNumber:this.queue.ccTel,
        // transferType:1//转移类型，0：电话号码；1：座席号；2：IP话机号；3：语音导航节点；4：语音导航id
      }
      this.$api.postTransfer(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    onlogin(state){
      if(state==1){
        this.postBindClientTel()
      }else{
        this.postUnBindClientTel()
      }
    },
    postBindClientTel(){//用户登陆
      let query={
        cno:this.form.extension,
        tel:this.form.device,
        isBind:1//是否绑定 1: 是，0: 否
      }
      this.$api.postBindClientTel(query).then(res=>{
        if(res.code==0){
          this.dialogVisible=false;
          this.device=this.form.device;
          this.$message.success(res.message)
        }
      })
    },
    postUnBindClientTel(){//解绑坐席电话
      let query={
        cno:this.cno
      }
      this.$api.postUnBindClientTel(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.device='';
          this.form.extension='';
          // this.cno='';
          this.getCallDetailByCno()
        }
      })
    },
    // postlistCnoEmpRela(){//互动队列
    //   let query={
    //     // roleName:'检测坐席'
    //   }
    //   this.$api.postlistCnoEmpRela(query).then(res=>{
    //     if(res.code==0){
    //       this.interactionList=res.data
    //     }
    //   })
    // },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      let query={
        cno:this.extension
      }
      this.$api.getCallDetailByCno(query).then(res=>{
        if(res.code==0){
          if(res.data){
            let resJson=JSON.parse(res.data)
            this.resJson=resJson;
            this.loginType=resJson.status===1?true:false;
            this.onlineStatus=resJson.status;//座席状态，0: 离线；1: 在线
            this.device=resJson.bindTel;
            this.form.extension=resJson.cno;
            this.cno=resJson.cno;
          }
        }
      })
    },

    searchCascadeData(data, searchTerm) {
          const results = [];
        
          function search(nodes) {
            nodes.forEach(node => {
              if (node.name.indexOf(searchTerm)!=-1) {
                results.push(node);
              }
              if (node.children) {
                search(node.children);
              }
            });
          }
        
          search(data); 
          return results;
    },
    onSearch(e){
      this.storeQueue=this.searchCascadeData(this.storeQueueOld,e)
    },
    queryUserListByRoleAndDepart(){//根据角色和部门查询用户
      let query={
        roleEnabled:0,
        deptEnabled:0,
        userEnabled:0,
        roleName:'帮卖经理'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.storeQueue=res.data;
          this.storeQueueOld=JSON.parse(JSON.stringify(res.data));
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.pageContainer{
  padding:40px;
  position: relative;
  .softHeadBox{
    // position: relative;
    .titleConter{
      position:absolute;
      width:100%;
      top:10px;
      font-size:20px;
      color:#F56C6C;
    }
  }
  .softConter{
    margin-top:70px;
  }
  .iframeBox{
    position: absolute;
    top: 90px;
    z-index: 1;
    width:760px;
    height:100px;
    // border:1px solid;
  }
  .gatterButton{
    margin-top:20px;
  }
}
#app .gatterButton .el-button{
  margin:0 10px 20px;
  margin-left:10px;
}
</style>
