<template>
    <div class="currencyConterBox">
        <el-row :gutter="24" class="">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="8" class="flex flex_align_center flex_between w4">
            <span>归档编号</span>
            <div class="rowbox">
              <el-input v-model="forms.archiveBeginNo" size="medium"></el-input>
              <i class="line"></i>
              <el-input v-model="forms.archiveEndNo" size="medium"></el-input>
            </div>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>归档状态</span>
            <el-select v-model="forms.archiveStatus" size="medium">
              <el-option label="全部" value=""></el-option>
              <el-option label="未归档" :value="61"></el-option>
              <el-option label="已归档" :value="62"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
            <el-button type="primary" @click="onExport" size="medium" plain
          >导出数据报表</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg mt20">
          <el-table-column prop="carNo" label="车牌号" align="center" ></el-table-column>
          <el-table-column prop="archiveNo" label="归档编号" align="center" ></el-table-column>
          <el-table-column prop="contractNo" label="客户合同编号" align="center" ></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center" ></el-table-column>
          <!-- <el-table-column prop="name" label="店长审核时间" align="center" ></el-table-column> -->
          <el-table-column prop="archiveUser" label="归档人" align="center" ></el-table-column>
          <el-table-column label="归档状态" align="center" width="120" >
            <template slot-scope="scope">
              <el-button v-if="scope.row.archiveStatus==61"
                type="primary"
                @click="operation(scope.row)"
                size="mini"
                class="group-custom"
              >归档</el-button>
              <div v-else>已归档</div>
            </template>
          </el-table-column>
        </el-table>
          <el-pagination class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total">
          </el-pagination>
    </div>
</template>
<script>
export default {
    data(){
        return{
            tabClassify:'first',
            querylist:[],//
            forms:{
              merchantName:'',
              state:'',
              date:''
            },
            selectValue:'',
            total:0,
            currentPage:1,
            pageSize:10,
        }
    },
    created(){
      this.init();
    },
    methods:{
      init(page){
        this.currentPage=page?page:this.currentPage;
        let query={
          "curPage": this.currentPage,//当前页数
          "pageSize": this.pageSize,
          "archiveStatus": this.forms.archiveStatus?[this.forms.archiveStatus]:'',//客户合同归档状态，61未归档，62已归档，默认61
          carNo:this.forms.carNo,
          archiveBeginNo:this.forms.archiveBeginNo,//合同归档开始编号
          archiveEndNo:this.forms.archiveEndNo,//合同归档结束编号
        }
        this.$api.postContractQuery(this.$$.delete_null_properties(query)).then((res)=>{
          if(res){
            if(res.code==0){
              this.querylist=res.data.records;
              this.total=res.data.total;
            }else{
               this.querylist=[];
                this.total=0;
            }
          }
        })
      },
      handleSizeChange(data){
        this.pageSize=data;
        this.currentPage=1;
        this.init()
      },
      handleCurrentChange(data){
        this.currentPage=data
        this.init()
      },
      onExport(){
        let query={
          "archiveStatus": this.forms.archiveStatus?[this.forms.archiveStatus]:'',//客户合同归档状态，61未归档，62已归档，默认61
          carNo:this.forms.carNo,
          archiveBeginNo:this.forms.archiveBeginNo,//合同归档开始编号
          archiveEndNo:this.forms.archiveEndNo,//合同归档结束编号
        }
        this.$api.postContractExport(this.$$.delete_null_properties(query)).then(res=>{
          this.$$.postExcle(res)
        })
      },
      operation(row){//归档
        this.$confirm('请确认是否归档？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.postContractArchive(row.id).then(res=>{
            if(res.code==0){
              this.init();
              this.$message.success(res.message)
            }
          })
        }).catch(() => {});       
      }
    }
}
</script>

<style lang="scss" scoped>
.currencyConterBox{
    padding: 40px;
    .rowbox{
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      .line{
        width:15px;
        height:1px;
        background-color: #999999;
        margin: 0 5px;
      }
    }
}
</style>