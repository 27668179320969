<template>
  <div class="news">
    <tipsTableData :tipsTableData="tipsTableData" @messageQueryMessage="messageQueryMessage"></tipsTableData>
  </div>
</template>

<script>
import tipsTableData from '@/components/common/tipsTableData'
export default {
  data() {
    return {
      tipsTableData: []
    }
  },
  components: {
    tipsTableData
  },
  created() {
    this.messageQueryMessage()
    // this.$datadic.getTaskType().then(res => {
    //   console.log(res);
    // }).catch((msg) => {
    //   this.$message.warning(msg);
    // })
  },
  methods: {
    messageQueryMessage() {
      let query={
        curPage:1,
        pageSize:999,
        // types:[0,3,4,5]
      }
      this.$api.postMessageWarningPage(query).then(res=>{
        if(res.code === 0){
          this.tipsTableData = res.data.records;
        }else{
          this.tipsTableData=0;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.news{
  padding: 40px;
}
</style>