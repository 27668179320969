<template>
  <div class="materialsInfo">
    <div class="flex flex_align_center flex_between">
      <div class="title_top_nameBox">材料信息</div>
      <el-button size="mini" type="primary" plain @click="showAddProject=true" v-if="state">添加手续材料</el-button>
    </div>
    <el-table :data="logistMaterialsListNew" border class="mt10 mb20 table-mainbg" stripe>
      <el-table-column type="index" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
      <el-table-column prop="sendNums" label="数量" align="center">
         <template slot-scope="scope">
            <el-input v-model.number="scope.row.sendNums"></el-input>
          </template>
      </el-table-column>
      <!-- <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column> -->
      <el-table-column label="操作" align="center" v-if="state">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain v-if="scope.row.carId" @click="delProject(scope.$index)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="添加手续" :visible.sync="showAddProject">
      <el-row :gutter="20">
        <el-col :span="12" class="flex flex_align_center flex_between w3">
          <span>车牌号: </span>
          <el-input v-model="carNoPen" placeholder="请输入车牌号"></el-input>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" icon="el-icon-search" @click="getProjectList">查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="projectList" border ref="multipleTable" class="mt10 mb20 table-mainbg"  @selection-change="handleSelectionChange" max-height="400px">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
        <el-table-column prop="sendNums" label="数量" align="center">
          <template slot-scope="scope">
            <el-input v-model.number="scope.row.sendNums"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
        <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddProject = false">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    carNo:{
      type: String,
      default() {
        return ''
      }
    },
    logistMaterialsList: {
      type: Array,
      default() {
        return []
      }
    },
    roleItem: {
      type: Object,
      default() {
        return {}
      }
    },
    state:{//不显示车辆信息（新建专用）1新增，2修改，3查看
      type: String,
      default() {
        return ''
      }
    }
  },
  watch:{
    logistMaterialsList(res){
      this.logistMaterialsListNew=JSON.parse(JSON.stringify(res))
      // console.log(res,'---555---')
    }
  },
  data() {
    return {
      projectList: [],
      showAddProject: false,
      carNoPen: '',
      multipleSelection: [],
      logistMaterialsListNew:[],//材料列表
    }
  },
  created(){
  },
  methods: {
    delProject(index) {
      this.logistMaterialsListNew.splice(index, 1)
    },
    getProjectList(type) {//点击添加项目,查询所有项目
      if(!this.carNoPen){
        this.$message.warning('请输入车牌号再查询！')
        return
      }
      // let query={
      //   shopIds:this.roleItem.shop?[this.roleItem.shop.id]:[],
      //   carNo:this.carNoPen,
      //   stockStatuses:[11,12],//库存状态：10初始化,11待入库, 12在库, 14已出库
      // }
      this.$api.getStockMaterialsRela(this.carNoPen).then(res=>{
        if(res.code==0){
          let arr=res.data?res.data:[];
          let newArr=[];
          for(let i=0;i<arr.length;i++){
            let item=arr[i]
            if(item){
              if(item.materialName.indexOf('钥匙')==-1){
                item.sendNums=1;
              }
              newArr.push(item)
            }
          }
          this.projectList=newArr;
        }else{
          this.projectList=[]
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submitAdd() {
      if(!this.logistMaterialsListNew){
        this.logistMaterialsListNew = this.multipleSelection
      }else{
        let ids = this.logistMaterialsListNew.map(item => item.id);
        this.multipleSelection.forEach(item => {
          if(ids.indexOf(item.id) === -1) {
            this.logistMaterialsListNew.push(item)
          }
        })
      }
      this.showAddProject = false;
    }
  }
}
</script>

<style scoped lang="scss">
.materialsInfo{

}
</style>
