<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w5">
        <span>所属渠道简称</span>
        <el-input v-model="forms.channelName" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>所属集团</span>
        <el-input v-model="forms.ownerGroup" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>拓展专员</span>
        <el-select v-model="forms.extensionerId" size="medium" clearable filterable>
          <el-option v-for="item in rolelist" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>业务状态</span>
        <el-select v-model="forms.customerStatus" size="medium" clearable>
          <el-option value="" label="全部"></el-option>
          <el-option v-for="item in statelist" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>检测时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange" clearable :default-time="['00:00:00', '23:59:59']"
          size="medium" value-format="yyyy-MM-dd HH:mm:ss" 
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>成交时间</span>
        <el-date-picker
          v-model="forms.date1"
          type="daterange" clearable :default-time="['00:00:00', '23:59:59']"
          size="medium" value-format="yyyy-MM-dd HH:mm:ss" 
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center">
        <el-button type="primary" class="button_primary" @click="onsearch" size="medium" plain>查询</el-button>
        <el-button type="primary" class="button_primary" @click="onExport" size="medium" plain>导出数据</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt30 table-mainbg">
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="channelName" label="所属渠道简称" align="center"></el-table-column>
      <el-table-column prop="ownerGroup" label="所属集团" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="拓展专员" align="center"></el-table-column>
      <el-table-column prop="createTime" label="申请时间" align="center"></el-table-column>
      <el-table-column prop="checkTime" label="检测时间" align="center"></el-table-column>
      <el-table-column prop="tradeTime" label="成交时间" align="center"></el-table-column>
      <el-table-column prop="customerStatusName" label="业务状态" align="center"></el-table-column>
      <el-table-column prop="auctionPassword" label="竞拍回放密码" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="220px">
        <template slot-scope="scope">
          <el-button type="primary" @click="onSee(scope.row)" plain size="mini">查看报告</el-button>
          <el-button type="primary" @click="onCopy(scope.row)" plain size="mini">复制链接</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms:{
        date:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      statelist:[{id:2,name:'已检测'},{id:3,name:'成交待预约'},{id:4,name:'预约成功'},{id:5,name:'预约失败'},{id:6,name:'合同签订失败'},{id:7,name:'待付款'},{id:8,name:'待过户'},{id:9,name:'过户成功'}],
      rolelist:[],
    };
  },
  created() {
    this.init()
    this.queryUserListByRoleAndDepart();//获取拓展专员
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query={
        "carNo": this.forms.carNo,
        "curPage": this.currentPage,
        "channelName": this.forms.channelName,
        "pageSize": this.pageSize,
        "ownerGroup": this.forms.ownerGroup,
        "extensionerId": this.forms.extensionerId,
        "customerStatus": this.forms.customerStatus,
        "checkStartTime": this.forms.date?this.forms.date[0]:'',
        "checkEndTime": this.forms.date?this.forms.date[1]:'',
        "tradeStartTime": this.forms.date1?this.forms.date1[0]:'',
        "tradeEndTime": this.forms.date1?this.forms.date1[1]:'',
      }
      this.$api.postCustomerChannelPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.total=res.data.total;
        }else{
          this.querylist = [];
          this.total=0;
        }
      })
    },
    onSee(row){
      if(!row.checkReportId){
        this.$message.warning('当前数据暂无检测报告！');
        return 
      }
      this.$router.push({
        path:'/testReviewer/auditReport',
        query:{
          id:row.checkReportId,
          state:0
        }
      })
    },
    onCopy(row){
      if(!row.checkReportId){
        this.$message.warning('当前数据暂无检测报告！');
        return 
      }
      let content=`http://pc.kx.cn/internalPage/auditReport?id=${row.checkReportId}`;//地址链接
      const input = document.createElement('input');
      // 将想要复制的值
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      input.remove();
    },//参数content为被被复制的内容
    onsearch() {
      this.currentPage=1;
      this.init()
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    onExport(){
      let query={
        "carNo": this.forms.carNo,
        // "curPage": page?page:this.currentPage,
        "channelName": this.forms.channelName,
        // "pageSize": this.pageSize,
        "ownerGroup": this.forms.ownerGroup,
        "extensionerId": this.forms.extensionerId,
        "customerStatus": this.forms.customerStatus,
        "checkStartTime": this.forms.date?this.forms.date[0]:'',
        "checkEndTime": this.forms.date?this.forms.date[1]:'',
        "tradeStartTime": this.forms.date1?this.forms.date1[0]:'',
        "tradeEndTime": this.forms.date1?this.forms.date1[1]:'',
      }
      this.$api.postCustomerChannelexport(this.$$.delete_null_properties(query)).then((res)=>{
        this.$$.postExcle(res)
      })
    },
    queryUserListByRoleAndDepart(){//获取拓展专员
      let query={
        roleName:'拓展专员'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.rolelist=res.data;
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>

</style>
