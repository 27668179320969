<template>
  <div class="newTask">
    <el-row :gutter="20">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>优质数据:</span>
        <el-select clearable v-model="params.isFine" placeholder="请选择" @change="changeIsFine">
          <el-option v-for="item in $store.state.setting.isFineList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="10" class="flex flex_align_center flex_between w4">
        <span>渠道来源:</span>
        <el-cascader clearable v-model="params.channel" :options="channelOptions"></el-cascader>
      </el-col>
      <el-col :span="8" style="text-align:right;">
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="cList" border stripe class="table-mainbg" style="width: 100%;margin-top:30px">
      <el-table-column prop="customerNo" label="客户编号" align="center">
        <template slot-scope="scope">
          <div class="flex flex_align">
            <span>{{scope.row.customerNo}}</span>
            <span class="callLoss" v-if="scope.row.isCallLoss">损</span>
            <span class="expedited" v-if="scope.row.isUrgent">急</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center" width="115px"/>
      <el-table-column prop="channel" label="渠道来源" align="center" />
      <el-table-column prop="isFine" label="数据类别" width="100" align="center" :formatter="formatIsFine"/>
      <el-table-column prop="assignTime" label="分配时间" align="center"/>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        isFine: '',
        channel: '',
      },
      channelOptions: [],
      cList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getData()
    },
    //所有渠道
    customerQueryChannel(isFine) {
      if(isFine === undefined){
        this.$datadic.getCustomerQueryChannel().then(res => {
          this.channelOptions = res;
        }).catch(msg => {
          this.$message.error(msg);
        })
      }else{
        let result = [];
        this.$api.customerQueryChannel({isFine:isFine}).then(res => {
          if(res.code == 0){
            result = res.data
            result.forEach(item => {
              item.value = item.id;
              item.label = item.channelName;
              if(item.channelSubs){
                item.children = item.channelSubs
                item.children.forEach(i => {
                  i.value = i.id;
                  i.label = i.channelName;
                })
              }
            })
            this.channelOptions = result;
          }
        })
      }
    },
    search() {
      this.page_index = 1;
      this.getData();
    },
    getData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        isFine: this.params.isFine,
        channelId: '',
      }
      if(this.params.channel && this.params.channel.length > 0){
        params.channelId = this.params.channel[1];
      }else{
        params.channelId = '';
      }
      this.$api.customerQueryNoFollowUp(this.$$.delete_null_properties(params)).then(res => {
        if(res.code == 0){
          this.page_total = res.data.total;
          this.cList = res.data.records;
        }
      })
    },
    formatIsFine(row) {
      if(row.isFine == 1){
        return '优质'
      }else if(row.isFine == 0){
        return '非优质'
      }else{
        return ''
      }
    },
    goCustomer(followUpId) {
      if(followUpId){
        this.$router.push({path: '/testService/customer', query: {followUpInfoId: followUpId}})
      }else{
        this.$message.warning('客户跟进业务ID缺失')
      }
    },
    changeIsFine(val) {
      this.customerQueryChannel(val)
    }
    
  },
  created() {
    this.customerQueryChannel();
    this.getData();
  }
}
</script>

<style scoped lang="scss">
.newTask{
  padding: 40px 50px;
}
.callLoss, .expedited{
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  background: #f4c01e;
  color: #fff;
  margin-left: 5px;
}
.expedited{background: #ff0000;}
</style>