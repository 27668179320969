<template>
  <div class="pageContainer">
    <!--车辆信息-->
    <div class="content_modal_textBox">
      <div class="flex flex_between flex_align_center mb15">
        <div class="ckxTitle">车辆信息</div>
        <div class="routerBoxTitle" v-if="['ac'].includes(state)">
          <div @click="onRouter(1)" class="mr30">客户合同</div>
          <div @click="onRouter(2)">商户合同</div>
        </div>
      </div>
      <table class="table-oddbg table-custom">
        <tbody>
          <tr>
            <td>车辆号牌</td>
            <td>{{res.carNo}}</td>
            <td>厂牌型号</td>
            <td>{{res.makerName}}&nbsp;{{res.seriesName}}</td>
            <td>发动机号</td>
            <td>{{res.engineNo}}</td>
            <td>颜色</td>
            <td>{{res.carColor}}</td>
          </tr>
          <tr>
            <td>初登日期</td>
            <td>{{res.registerDate}}</td>
            <td>年检有效期</td>
            <td>{{res.checkExpire}}</td>
            <td>车架号</td>
            <td class="wordWrap">{{res.vinCode}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--办证进度信息-->
    <div class="content_modal_textBox mt30">
      <div class="ckxTitle mb15">办证进度信息</div>
      <el-table
        :data="dataList"
        border
        class="table_top_bg"
        :span-method="objectSpanMethod"
        :cell-style="cellStyle"
      >
        <el-table-column prop="type" label="业务阶段"  align="center" width="100px"></el-table-column>
        <el-table-column prop="operateTime" label="日期" align="center"></el-table-column>
        <el-table-column prop="event" label="事件" align="center"></el-table-column>
        <el-table-column prop="description" label="描述" align="center"></el-table-column>
        <el-table-column prop="roleName" label="角色" align="center"></el-table-column>
        <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

 <script>
export default {
  name: "ModuleIndex",
  data() {
    return {
      dataList: [], // 当前展示的数据
      testArr1: [],
      testPosition1: 0,
      carId: "",
      res: {},
      state:'',
    };
  },
  created() {
    if(this.$route.query.id) this.carId = this.$route.query.id;
    this.postProgressDetail();
    if(this.$route.query.state) this.state = this.$route.query.state;
  },
  methods: {
    onRouter(state){
      if(state===1){
        this.$router.push({
          path:'/salesManager/contractMaking',
          query:{
            id:this.res.contractId
          }
        })
      }else if(state===2){
        this.$router.push({
          path:'/salesAssistant/merchantContract',
          query:{
            id:this.res.contractId
          }
        })
      }
    },
    postProgressDetail() {
      this.$api.postProgressDetail(this.carId).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = JSON.parse(JSON.stringify(res.data));
            this.dataList = res.data.certProgressList;
            let arr = this.dataList;
            for (let i = 0; arr.length > i; i++) {
              let type = "待办证";
              switch (
                arr[i].status //状态：1 待办证，2 办证中，3 结算中，4 交易完成
              ) {
                case 1:
                  type = "待办证";
                  break;
                case 2:
                  type = "办证中";
                  break;
                case 3:
                  type = "结算中";
                  break;
                case 4:
                  type = "交易完成";
                  break;
              }
              arr[i].type = type;
            }
            this.rowspan(this.testArr1, this.testPosition1, "type");
          }
        }
      });
    },
    cellStyle({ row, columnIndex }) {
      // console.log(columnIndex)
      // console.log(row, column, rowIndex, columnIndex)
      if (columnIndex === 0) {
        let color = "";
        switch (row.type) {
          case "办证中":
            color = "#0099FF";
            break;
          case "结算中":
            color = "#FFC475";
            break;
          case "交易完成":
            color = "#9BFF94";
            break;
        }
        return { background: color };
      }
    },
    rowspan(spanArr, position, spanName) {
      this.dataList.forEach((item, index) => {
        if (index === 0) {
          spanArr.push(1);
          position = 0;
        } else {
          if (
            this.dataList[index][spanName] ===
            this.dataList[index - 1][spanName]
          ) {
            spanArr[position] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            position = index;
          }
        }
      });
    },
    // 表格合并行
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.testArr1[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  // padding: 30px 50px;
  .routerBoxTitle{
    display:flex;
    font-size:16px;
    color:#409EFF;
    div{
      cursor:pointer;
    }
  }
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    .content_title_row {
      label {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
}
</style>