<template>
  <!-- 结算查询 -->
  <div class="settlement">
    <el-row :gutter="20" class="mb20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码：</span>
        <el-input v-model="params.carNo"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w6">
        <span>客户结算时间：</span>
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w6">
        <span>客户结算状态：</span>
        <el-select v-model="params.status" placeholder="请选择" clearable>
          <el-option label="未结算" :value="121"></el-option>
          <el-option label="已结算" :value="125"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="init(1)">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.brandName}}{{scope.row.modelName}}</template>
      </el-table-column>
      <el-table-column prop="contractDate" label="客户合同签订日期" align="center"></el-table-column>
      <el-table-column prop="merchantDeadline" label="合同办证日期" align="center"></el-table-column>
      <el-table-column prop="financeCustomerDepositPaymentTime" label="客户结算时间" align="center"></el-table-column>
      <el-table-column prop="certOverdueCheckedTime" label="售后提交结算时间" align="center"></el-table-column>
      <el-table-column prop="customerClearStatusName" label="客户清账状态" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onRouter(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog
      :visible.sync="dialogVisible" width="1000px">
      <el-table :data="detailist" border  class="querylistTable " 
      height="350">
        <el-table-column prop="itemName" label="费用项目" align="center" ></el-table-column>
        <el-table-column prop="customerFeeAmount" label="客户" align="center" ></el-table-column>
        <el-table-column prop="merchantFeeAmount" label="商户" align="center" ></el-table-column>
        <el-table-column prop="certFeeAmount" label="办证公司" align="center" ></el-table-column>
        <el-table-column prop="ckxFeeAmount" label="车开新" align="center" ></el-table-column>
        <el-table-column prop="ruleFeeAmount" label="违章处理公司" align="center" ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo: '',
        createTime: '',
        status: '',
      },
      dialogVisible:false,
      querylist: [],
      detailist:[],
      currentPage: 1, // 页数
      total: 0, // 总页数
      pageSize: 10,
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page){
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo:this.params.carNo,
        merchantName:this.params.merchantName,
        customerClearStatuses:this.params.status?[this.params.status]:[121,125],//客户结算: 121 待结算,125 已结算
        customerClearBeginTime:this.params.createTime?this.params.createTime[0]:'',
        customerClearEndTime:this.params.createTime?this.params.createTime[1]:'',
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(row){
      this.pageSize=row;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(row){
      this.currentPage=row;
      this.init()
    },
    onRouter(row){
      this.dialogVisible=!this.dialogVisible;
      this.$api.getThirdClearList(row.carId).then(res=>{
        if(res.code==0){
          this.detailist=res.data.thirdClearFees;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.settlement{
  padding: 20px 40px;
}
</style>