<template>
  <div class="auctionForm">
    <el-form :model="formData" :rules="rules" ref="formData" class="formMain" label-width="80px" :hide-required-asterisk="true" size="medium">
      <div class="flex flex_align_center mb20">进入场次的截止时间为竞拍结束时间前
        <el-form-item label-width="0px" prop="inSeatEndLength" class="minute">
          <el-input type="number" v-model="formData.inSeatEndLength" class=""></el-input>
        </el-form-item>分钟 
        <template v-if="formData.id">
        <el-button type="primary" icon="el-icon-video-pause" v-if="formData.isEnable" @click="onPlay()" style="margin-left:20px;" size="mini">暂停</el-button>
        <el-button type="primary" icon="el-icon-video-play" v-if="!formData.isEnable" @click="onPlay()" size="mini" style="margin-left:20px;">启动</el-button>
        </template>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="auctionBeginTime" label="竞拍开始时间" label-width="110px">
            <el-time-picker class="itemWidth" v-model="formData.auctionBeginTime" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" placeholder="请选择" value-format='HH:mm:ss'> </el-time-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="竞拍时长" prop="auctionTimeLength">
            <el-input class="itemWidth" type="number" v-model="formData.auctionTimeLength"><template slot="append">分钟</template></el-input>
          </el-form-item>
          </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label-width="110px" label="车辆业务归属" prop="businessOwners">
            <el-checkbox-group v-model="formData.businessOwners" class="itemWidth">
              <el-checkbox-button :label="1">门店</el-checkbox-button>
              <el-checkbox-button :label="2">渠道</el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车辆品牌">
            <!-- <el-input class="itemWidth" v-model="formData.style"></el-input> -->
            <el-select class="itemWidth" v-model="formData.brandIds" clearable>
              <el-option label="全部" :value="0"></el-option>
              <el-option v-for="item in brandlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label-width="110px" label="上架车辆上限" prop="maxAddedCar">
            <el-input type="number" v-model="formData.maxAddedCar" class="itemWidth"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车牌归属">
            <!-- <el-input class="itemWidth" v-model="formData.carArea"></el-input> -->
            <el-select class="itemWidth" v-model="formData.plateOwnerCityName" clearable>
              <el-option label="全部" value="全部"></el-option>
              <el-option v-for="item in citylist" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12"><el-form-item label-width="110px" label="渠道来源">
          <el-cascader  class="itemWidth" v-model="formData.sourceType" :options="channelList" @change="handleChange" clearable ref="cascader"></el-cascader>
          <!-- <el-select class="itemWidth" v-model="formData.sourceType" >
            <el-option v-for="item in channelList" :key="item.id" :label="item.name" :value="item.id"></el-option>>
          </el-select> -->
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="车辆年限">
            <el-input class="itemWidth" v-model="formData.maxYears" type="number"></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <div>
        <el-form-item label-width="110px" label="车辆询价类型" prop="enquiryTypes">
          <el-checkbox-group v-model="formData.enquiryTypes">
            <el-checkbox v-for="item in checkList" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="flex flex_center mb20">
        <el-button type="primary" @click="onButtom(1)">保存</el-button>
        <!-- <el-button type="primary" @click="onButtom(2)">修改</el-button> -->
        <el-button type="primary" @click="onButtom(3)" plain>删除</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    formData: {//主数据
      type: Object,
    },
    brandlist:{//品牌列表
      type:Array
    },
    channelList:{//渠道列表
      type:Array
    },
    citylist:{//省列表
      type:Array
    },
    checkList:{//询价类型
      type:Array
    }
  },
  data() {
    return {
      rules: {
        inSeatEndLength: [{ required: true, message: '请输入', trigger: 'blur' },],
        auctionTimeLength: [{ required: true, message: '请输入', trigger: 'blur' },],
        maxAddedCar: [{ required: true, message: '请输入', trigger: 'blur' }],
        auctionBeginTime: [{required: true, message: '请选择日期', trigger: 'change'}],
        businessOwners: [{required: true, message: '请选择', trigger: 'change'}],
        enquiryTypes: [{required: true, message: '请选择', trigger: 'change'}]
      },
    }
  },
  created(){
    
  },
  methods: {
    handleChange() {
      if(this.$refs.cascader){
        if(this.$refs.cascader.getCheckedNodes().length>0){
          let pathLabels=this.$refs.cascader.getCheckedNodes()[0].pathLabels;
          this.formData.pathLabels=pathLabels;
        }else{
          this.formData.pathLabels=['','']
          this.formData.sourceType=[0,0]
        }
      }
    },
    onButtom(state){
      if(state==3){
        this.$emit('onButtom',state,this.formData)
      }else{
        // if(this.formData.maxYears<0){
        //   this.$message.warning('车辆年限不可为负数')
        //   return
        // }
        this.$refs['formData'].validate((valid) => {
          if (valid) {
            if(this.formData.id&&state==1){
              state=2
            }
            this.$emit('onButtom',state,this.formData)
          }
        });
      }
    },
    onPlay(){
      this.formData.isEnable=!this.formData.isEnable;
      if(this.formData.id){
        this.$emit('updateEnableSeat',this.formData.id,this.formData.isEnable?0:1)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.auctionForm{
  .formMain{
    .el-form-item{
      margin-bottom:15px;
    }
    .minute{
      width: 80px;
      display: inline-block;
      margin-bottom:0
    }
    .itemWidth{min-width: 105px;max-width: 220px;}
  
  }
}
</style>