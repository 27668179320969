<template>
  <div class="pageContainer">
    <el-row :gutter="20">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w5">
        <span>预约成交时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>付款状态</span>
        <el-select v-model="forms.financePayStatuses" multiple size="medium">
          <el-option v-for="item in paymentStatuslist" :key="item.value" :label="item.label" :value="item.value" ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30" @row-click="onRowClick">
      <el-table-column prop="customerContractNo" label="合同编号" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="sellerName" label="客成经理" align="center"></el-table-column>
      <el-table-column prop="ccTradePrice" label="成交价（元）" align="center"></el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
      <el-table-column prop="appointTradeTime" label="预约成交时间" align="center"></el-table-column>
      <el-table-column prop="totalPayAmount" label="应付金额（元）" align="center"></el-table-column>
      <el-table-column prop="financePayStatusName" label="付款状态" align="center"></el-table-column>
      <el-table-column prop="financePayTime" label="付款完成时间" align="center"></el-table-column>
      <el-table-column prop="createDate" label="创建时间" align="center"></el-table-column>
      <el-table-column prop="financeMerchantGatheringStatusName" label="商户收款状态" align="center"></el-table-column>
      <el-table-column prop="remark" label="备注" align="center"></el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      forms:{
        carNo:'',
        date:'',
        financePayStatuses:[31,32]
      },
      paymentStatuslist:[],
      total: 0,
      currentPage: 1,
      pageSize: 10
    };
  },
  created() {
    this.paymentStatuslist=this.$store.state.setting.paymentStatuslist;
    if(this.$route.params.state) this.forms.financePayStatuses=[Number(this.$route.params.state)];
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        appointTradeBeginTime:this.forms.date?this.forms.date[0]+' 00:00:00':'' ,
        appointTradeEndTime:this.forms.date?this.forms.date[1]+' 23:59:59':'',
        financePayStatuses:this.forms.financePayStatuses?this.forms.financePayStatuses:'' 
      };
      this.$api.postCustomerTradeQuery(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init()
    },
    onRowClick(row){
      this.$router.push({
        path:'/finance/customerCarPaymentDetails',
        query:{
          id:row.id
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>