<template>
  <!-- 客户成交预约 -->
  <div class="transactionReservation">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card" class="mb20">
      <el-tab-pane label="待分配" name="first"></el-tab-pane>
      <el-tab-pane label="新任务" name="second"></el-tab-pane>
      <el-tab-pane label="跟进任务" name="third"></el-tab-pane>
      <el-tab-pane label="沟通中" name="fourth"></el-tab-pane>
      <el-tab-pane label="预约成功" name="fifth"></el-tab-pane>
      <el-tab-pane label="预约失败" name="sixth"></el-tab-pane>
    </el-tabs>

    <!-- 搜索 -->
    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15" v-if="['fourth','fifth', 'sixth'].includes(activeName)">
        <span>客户编号: </span>
        <el-input v-model="params.customerNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15">
        <span>车牌号码: </span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15" v-if="['fourth', 'sixth'].includes(activeName)">
        <span>商户意愿: </span>
        <el-select clearable v-model="params.merchantStatus" size="medium">
          <el-option v-for="item in merchantStatusList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15" v-if="['first','second', 'third'].includes(activeName)">
        <span>成交归属: </span>
        <el-select v-model="params.trader" size="medium" placeholder="请选择成交归属人" clearable>
          <el-option label="请选择" value=""></el-option>
          <el-option v-for="item in deallist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w6 mb15" v-if="['first','second'].includes(activeName)">
        <span>价格确认时间: </span>
        <el-date-picker v-model="params.confirmPriceTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w6 mb15" v-if="['third','sixth'].includes(activeName)">
        <span>下次联系时间: </span>
        <el-date-picker v-model="params.followUpTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w4 mb15" v-if="['fourth'].includes(activeName)">
        <span>竞拍时间: </span>
        <el-date-picker v-model="params.priceTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w4 mb15" v-if="['fifth','sixth'].includes(activeName)">
        <span>预约时间: </span>
        <el-date-picker v-model="params.bookTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15" v-if="['sixth'].includes(activeName)">
        <span>数据状态: </span>
        <el-select v-model="params.validity" size="medium" placeholder="请选择" clearable>
          <el-option label="无效" :value="0"></el-option>
          <el-option label="有效（未成交）" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="mb15">
        <el-button type="primary" plain @click="search" size="medium">搜索</el-button>
      </el-col>
    </el-row>

    <!-- 列表 -->
    <el-table :data="dataList" border stripe class="table-mainbg mt5">
      <el-table-column prop="unread" label="未读" v-if="activeName==='fourth'" align="center" key="1"></el-table-column>
      <el-table-column prop="customerNo" label="客户编号" align="center" key="2"></el-table-column>
      <el-table-column prop="carType" label="品牌车型" align="center" key="3"></el-table-column>
      <el-table-column prop="carNoNotSen" label="车牌号码" align="center" key="4"></el-table-column>
      <el-table-column prop="merchantConfirmPrice" label="商户确认价格" align="center" key="5"></el-table-column>
      <el-table-column prop="merchantOrder" label="商户名次" v-if="['fifth','sixth'].includes(activeName)" align="center" key="6"></el-table-column>
      <el-table-column prop="merchantStatusName" label="商户意愿" v-if="['fourth','sixth'].includes(activeName)"  align="center" key="7"></el-table-column>
      <el-table-column prop="traderName" label="成交归属"  v-if="['second'].includes(activeName)" align="center" key="8"></el-table-column>
      <el-table-column prop="quoteValidityDay" label="有效期" v-if="['first','second'].includes(activeName)" align="center" key="9">
        <template slot-scope="scope"><p :class="scope.row.quoteValidityDay==1?'tipsColor':''">{{scope.row.quoteValidityDay}}{{scope.row.quoteValidityDay?'天':''}}</p></template>
      </el-table-column>
      <el-table-column prop="nextContactTime" label="下次联系时间" v-if="['third','fourth','sixth'].includes(activeName)" align="center" key="10"></el-table-column>
      <el-table-column prop="priceConfirmTime" label="价格确认时间" align="center" key="11"></el-table-column>
      <el-table-column prop="cancelTime" label="放弃时间" v-if="activeName==='sixth'" align="center" key="12"></el-table-column>
      <el-table-column prop="priceFailureTime" label="价格有效期" v-if="activeName==='sixth'" align="center" key="13"></el-table-column>
      <el-table-column prop="bookTime" label="预约时间" align="center" v-if="activeName==='fifth'" key="14"></el-table-column>
      <el-table-column prop="followUpDays" label="跟进天数" align="center" v-if="['third'].includes(activeName)" key="15"></el-table-column>
      <el-table-column prop="dealType" label="成交类型" align="center" :formatter="formatDealType" v-if="['fifth'].includes(activeName)" key="16"></el-table-column>
      <el-table-column prop="traderName" label="成交归属" align="center" v-if="['third','fifth'].includes(activeName)" key="17"></el-table-column>
      <el-table-column prop="checkTypeName" label="询价类型" align="center" v-if="['third'].includes(activeName)" key="18"></el-table-column>
      <el-table-column prop="dealType" label="成交类型" align="center" :formatter="formatDealType" v-if="['first','second','third','fourth'].includes(activeName)" key="19"></el-table-column>
      <el-table-column prop="operatorName" label="操作人" align="center" v-if="['second','third','fourth','fifth','sixth'].includes(activeName)" key="20"></el-table-column>
      <el-table-column label="操作" align="center" :width="['fifth'].includes(activeName)?'200':'120'">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="chooseRow(scope.row)" v-if="['first'].includes(activeName)">分配</el-button>
          <el-button type="primary" size="mini" @click="onRouter(scope.row)" v-if="['second','third','fourth','fifth','sixth'].includes(activeName)">载入</el-button>
           <el-button size="mini" type="primary" @click="compensate(scope.row,1)" v-if="['fifth'].includes(activeName)&&scope.row.merchantDefaultStatus==0&&scope.row.customerDefaultStatus!=1&&scope.row.dealType===1">补偿申请</el-button>
          <el-button size="mini" type="primary" @click="compensate(scope.row,2)" v-if="['fifth'].includes(activeName)&&scope.row.merchantDefaultStatus==1&&scope.row.customerDefaultStatus!=1&&scope.row.dealType===1">申请查看</el-button>
          <el-button size="mini" type="danger" @click="compensate(scope.row,3)" v-if="['fifth'].includes(activeName)&&scope.row.merchantDefaultStatus==3&&scope.row.customerDefaultStatus!=1&&scope.row.dealType===1">申请未通过</el-button>
          <el-button size="mini" type="primary" plain @click="compensate(scope.row,4)" v-if="['fifth'].includes(activeName)&&scope.row.merchantDefaultStatus==2&&scope.row.customerDefaultStatus!=1&&scope.row.dealType===1">申请通过</el-button>
          <el-button size="mini" type="primary" @click="compensate(scope.row,5)" v-if="['fifth'].includes(activeName)&&scope.row.customerDefaultStatus==1&&scope.row.dealType===1">违约确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <el-dialog :visible.sync="distributionDialog" title="帮卖经理分配" center class="customDialog">
      <div class="flex flex_around flex_align_center">
        <span>客户姓名：{{currentRow.customerName}}</span>
        <span>品牌车型：{{currentRow.carType}}</span>
        <span>车牌号码：{{currentRow.carNo}}</span>
      </div>
      <el-row :gutter="24" class="mb20 mt20">
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>帮卖经理</span>
          <el-input v-model="form.userName" size="medium"></el-input>
        </el-col>
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>帮卖经理账号</span>
          <el-input v-model="form.userNo" size="medium"></el-input>
        </el-col>
        <el-col :span="2" class="flex flex_align_center flex_between ">
          <el-button type="primary" @click="getUserRole" size="medium" plain>查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="deallist" border max-height="300">
        <el-table-column prop="name" label="帮卖经理" align="center"></el-table-column>
        <el-table-column prop="no" label="帮卖经理账号" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="distribute(scope.row)"
              size="mini"
            >完成分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="mt20 mb20">请选择帮卖经理:</div>
      <div class="flex flex_wrap">
        <el-radio-group v-model="inspector" size="medium">
          <el-radio v-for="item in deallist" :key="item.id" :label="item.id" class="mb10">{{item.name}}</el-radio>
        </el-radio-group>
      </div> -->
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="distribute">完成分配</el-button>
      </span> -->
    </el-dialog>

    <el-dialog :title="titleModal" :visible.sync="applyCompensationVisible" center class="applyCompensation customDialog">
      <div class="mt20">
        <span class="label">{{[1,2,3,4].includes(compensateState)?'商户':'客户'}}预约成交时间：</span><span>{{compensateForm.bookTime}}</span>
      </div>
      <div class="mt20">
        <span class="label">{{[1,2,3,4].includes(compensateState)?'商户':'客户'}}实际到店时间：</span>
        <el-date-picker v-model="compensateForm.arrivalTime" type="datetime" placeholder="选择日期时间" :disabled="[1,3].includes(compensateState)?false:true" class="w300" @change="changeDate" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </div>
      <div class="mt20"><span class="label">{{[1,2,3,4].includes(compensateState)?'商户':'客户'}}迟到时长：</span><span>{{compensateForm.lateTime?compensateForm.lateTime:'--'}}分钟</span></div>
      <div class="mt20" v-if="[1,2,3,4].includes(compensateState)">
        <span class="label">客户放弃：</span>
        <el-radio-group v-model="compensateForm.isGiveUp" size="medium" :disabled="[1].includes(compensateState)?false:true">
          <el-radio-button :label="0" >客户继续等</el-radio-button>
          <el-radio-button :label="1" >客户放弃成交</el-radio-button>
        </el-radio-group>
      </div>
      <div class="mt20">
        <span class="label">{{[1,2,3,4].includes(compensateState)?'补偿客户':'赔偿商户'}}金额：</span>
        <span v-if="!updateFlag" >{{compensateForm.compAmount}}元</span>
        <el-input v-model="compensateForm.compAmount" v-if="updateFlag&&[1,3].includes(compensateState)" class="w300" ></el-input>
        <el-button @click="onAmountBut" size="mini" class="ml30" type="primary" plain v-if="[1,3].includes(compensateState)">修改</el-button>
      </div>
      <div class="mt20" v-if="[3,5].includes(compensateState)&&compensateForm.rejectReason">
        <span class="label">驳回原因：</span>
        <span >{{compensateForm.rejectReason}}</span>
      </div>
      <div class="mt20 flex" v-if="updateFlag||compensateForm.updateAmountReason">
        <span class="label">修改金额原因：</span>
        <el-input type="textarea" v-model="compensateForm.updateAmountReason" class="w300" :disabled="compensateForm.state"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applyCompensationVisible = false">取消</el-button>
        <el-button type="primary" @click="onReject" v-if="itemRow.customerDefaultStatus==1">驳回</el-button>
        <el-button type="primary" @click="doSubmit(0)" v-if="itemRow.merchantDefaultStatus==1">撤销申请</el-button>
        <el-button type="primary" @click="doSubmit(2)" v-if="itemRow.customerDefaultStatus==1">审核通过</el-button>
        <el-button type="primary" @click="doSubmit(1)" v-if="itemRow.customerDefaultStatus!=1&&[0,3].includes(itemRow.merchantDefaultStatus)">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
    title="提示"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" >驳回原因：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      params: {
        customerNo: '',
        merchantStatus: '',
        trader: '',
        confirmPriceTime: [],
        followUpTime: [],
        priceTime: [],
        bookTime: [],
        id: '',
        style: '',
        carNo: '',
        createTime: '',
        status: '',
        validity:1,
      },
      distributionDialog: false,
      form:{
        userName:'',
        userNo:''
      },
      dataList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      activeName: 'first',
      currentRow: {},//当前选择的数据
      inspector: '',//帮卖经理
      deallist:[],//成交归属
      departmentId:'',//当前部门id
      applyCompensationVisible: false,
      compensateForm: {
        bookTime: null,
        arrivalTime: null,
        compAmount: '-',
        lateTime: '-',
      },
      itemRow:{},
      titleModal:'客户补偿金申请',
      updateFlag:false,
      compensateState:'',//点击按钮操作
      merchantStatusList:[],
    }
  },
  created() {
    if(this.$route.params.state){
      this.activeName=this.$route.params.state;
      // if(this.activeName==='third'){
      //   let to=this.$$.getTody()
      //   this.params.followUpTime=[`${to} 00:00:00`,`${to} 23:59:59`]
      // }
    } 

    let userlist=this.$store.state.user.userData
    if(userlist){
      let arr=[...userlist.departmentList].filter(x =>x.isCurrent==0)
      if(arr.length>0){
        this.departmentId=arr[0].id
      }
    }
    this.getMerchantStatus()//获取商户预约状态下拉框
    this.handleClick();
    this.getUserRole()//获取归属人
  },
  methods: {
     onRouter(row){
      let pathData=this.$router.resolve({
          path: '/salesManager/customer',
          query: {
            followUpInfoId: row.followUpId
          }
      })
      window.open(pathData.href,'_blank')
    },
     onSubmitCar(){//驳回原因
      if(!this.formCar){
        this.$message.warning('请输入驳回原因！')
        return
      }
      this.compensateForm.rejectReason=this.formCar;
      this.doSubmit(3)//提交
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    formatDealType(row) {
      return ['垫资成交','预约成交','中介成交'][row.dealType]
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    onAmountBut(){
      this.updateFlag=true;
    },
    initData(page) {
      this.page_index=page?page:this.page_index;
      let query={
        "curPage": this.page_index,
        "pageSize": this.page_size,
        "carNo": this.params.carNo,
      }
      switch(this.activeName){
        case 'first':
          query.trader=this.params.trader;//成交归属人Id
          query.confirmPriceStartTime = this.params.confirmPriceTime?this.params.confirmPriceTime[0]:'';
          query.confirmPriceEndTime = this.params.confirmPriceTime?this.params.confirmPriceTime[1]:'';
          this.queryNoAssignList(this.$$.delete_null_properties(query));
          break;
        case 'second':
          query.trader=this.params.trader;//成交归属人Id
          query.confirmPriceStartTime = this.params.confirmPriceTime?this.params.confirmPriceTime[0]:'';
          query.confirmPriceEndTime = this.params.confirmPriceTime?this.params.confirmPriceTime[1]:'';
          this.queryNoFollowUpList(this.$$.delete_null_properties(query));
          break;
        case 'third':
          query.trader=this.params.trader;//成交归属人Id
          query.followUpStartTime = this.params.followUpTime?this.params.followUpTime[0]:'';
          query.followUpEndTime = this.params.followUpTime?this.params.followUpTime[1]:'';
          this.queryFollowUpList(this.$$.delete_null_properties(query));
          break;
        case 'fourth':
          query.customerNo=this.params.customerNo;
          query.merchantStatus=this.params.merchantStatus;
          query.priceStartTime = this.params.priceTime?this.params.priceTime[0]:'';
          query.priceEndTime = this.params.priceTime?this.params.priceTime[1]:'';
          this.queryConcernList(this.$$.delete_null_properties(query));
          break;
        case 'fifth':
          query.customerNo=this.params.customerNo;
          query.bookStartTime = this.params.bookTime?this.params.bookTime[0]:'';
          query.bookEndTime = this.params.bookTime?this.params.bookTime[1]:'';
          this.queryBookSuccessList(this.$$.delete_null_properties(query));
          break;
        case 'sixth':
          query.customerNo=this.params.customerNo;
          query.merchantStatus=this.params.merchantStatus;
          query.bookStartTime = this.params.bookTime?this.params.bookTime[0]:'';
          query.bookEndTime = this.params.bookTime?this.params.bookTime[1]:'';
          query.followUpStartTime = this.params.followUpTime?this.params.followUpTime[0]:'';
          query.followUpEndTime = this.params.followUpTime?this.params.followUpTime[1]:'';
          query.validity=this.params.validity;
          this.queryBookFailList(this.$$.delete_null_properties(query));
          break;
      }
      
    },
    async queryNoAssignList(params) {
      let res = await this.$api.queryNoAssignList(params);
      this.handleData(res);
    },
    async queryNoFollowUpList(params) {
      let res = await this.$api.queryNoFollowUpList(params);
      this.handleData(res);
    },
    async queryFollowUpList(params) {
      let res = await this.$api.queryFollowUpList(params);
      this.handleData(res);
    },
    async queryConcernList(params) {
      let res = await this.$api.queryConcernList(params);
      this.handleData(res);
    },
    async queryBookSuccessList(params) {
      let res = await this.$api.queryBookSuccessList(params);
      this.handleData(res);
    },
    async queryBookFailList(params) {
      let res = await this.$api.queryBookFailList(params);
      this.handleData(res);
    },
    handleData(res) {//处理接口数据
      if(res.code === 0){
        this.dataList = res.data.records;
        this.page_total = res.data.total;
        // this.dataList = [0];
      }else{
        this.dataList = [];
        this.page_total = 0;
      }
    },
    chooseRow(row) {
      this.form.userName='';
      this.form.userNo='';
      this.getUserRole()//查询检测师
      this.$api.getExistValidContractByCarAuctionId(row.enquiryId).then(res=>{
        if(res.code==0){
          if(res.data===false){
            this.distributionDialog=true;
            this.currentRow = JSON.parse(JSON.stringify(row));
          }else{
            this.$message.error('需先将上一名的成交合同签定失败后，才可以分配！')
          }
        }
      })
    },
    distribute(row) {//分配
      this.$api.assignOperator(this.currentRow.id,row.id).then(res => {
        if(res.code === 0){
          this.$message.success(res.message);
          this.distributionDialog=false;
          
          if(this.dataList.length===1){
            this.page_index=1;
          }
          this.initData()
        }
      })
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    getUserRole(){//获取归属人
      let query={
        roleName:'帮卖经理',
        departmentIds:this.departmentId?[this.departmentId]:'',
        userName:this.form.userName,
        loginName:this.form.userNo
      }
      this.$api.queryUserListByRoleAndRoleLevelAndDepartAndUser(query).then(res=>{
        if(res.code==0){
          this.deallist=res.data
        }
      })
    },
    compensate(row,state) {
      this.compensateState=state;
      this.compensateForm= {
        bookTime: null,
        arrivalTime: null,
        compAmount: '-',
        lateTime: '-',
      }
      this.itemRow=JSON.parse(JSON.stringify(row));
      let defaultParty=1;//只有按钮是违约状态时，这个地方传值才是商户，其他查询传值时传当前操作方（客户，商户）本身的值
      //违约方 0-客户 1-商户
      if(row.customerDefaultStatus==1){
        defaultParty=0;
        this.titleModal='客户违约确认';
      }else{
        //商户违约状态 -1-不展示操作 0-可提交申请（补偿申请） 1-已提交申请（申请查看） 2-申请通过 3-申请驳回（申请未通过
        switch(row.merchantDefaultStatus){
          case 0:
            this.titleModal='客户补偿金申请';
            break;
          case 2:
          case 1:
            this.titleModal='客户补偿金申请查看';
            break;
          case 3:
            this.titleModal='客户补偿金申请驳回';
            break;
        }
      }
     
      // if([1,2].includes(row.merchantDefaultStatus)||row.customerDefaultStatus){
      if([2,3,4,5].includes(state)){
        this.$api.merchantQueryDefault({
          dealBookId: state==5?row.id:row.merchantBookId,
          defaultParty: defaultParty
        }).then(res => {
          if(res.code == 0){
            this.compensateForm = res.data;
            this.compensateForm.lateTime=this.getLateTime(res.data.bookTime, res.data.arrivalTime);
            this.applyCompensationVisible = true;
            this.updateFlag=false;
          }
        })
      }else{
        this.compensateForm.bookTime=row.bookTime;
        this.applyCompensationVisible = true;
        this.updateFlag=false;
      }
    },
    doSubmit(state) {
      let de=this.compensateForm.defaultParty;
      if(state==0||!this.compensateForm.defaultParty){
        de=1;//违约方 0-客户 1-商户
      }
      let query={
        id: this.compensateForm.id?this.compensateForm.id:'',//通过查询得到id，补偿申请没有id
        arrivalTime: this.compensateForm.arrivalTime,
        dealBookId:  this.compensateState==1?this.itemRow.merchantBookId:this.compensateForm.dealBookId,//列表id
        bookTime:this.compensateForm.bookTime,
        defaultParty: state==3?0:de,//违约方 0-客户 1-商户
        state: state,//违约状态 1-提交申请 2-申请通过 3-申请驳回
        compAmount:this.compensateForm.compAmount,//补偿金额 单位 元
        rejectReason:this.compensateForm.rejectReason,//驳回原因
        updateAmountReason:this.compensateForm.updateAmountReason,//修改金额原因
        isGiveUp:this.compensateForm.isGiveUp,
      }
      if(!this.compensateForm.arrivalTime){
        this.$message.warning(`${[1,2,3,4].includes(this.compensateState)?'商户':'客户'}实际到店时间不能为空！`)
        return
      }
      if(this.compensateForm.isGiveUp==undefined&&[1,2,3,4].includes(this.compensateState)){
        this.$message.warning(`客户放弃不能为空！`)
        return
      }
      this.$api.merchantSubmitDefault(this.$$.delete_null_properties(query)).then( res => {
        if(res.code == 0){
          if(this.formCar){
            this.dialogVisibleCar=false;
          }
          this.applyCompensationVisible = false;
          this.initData()
          this.$message.success(res.message);
        }
      })
    },
    onReject(){
      this.dialogVisibleCar=true;
      this.formCar='';
    },
    getLateTime(bookTime, arrivalTime) {
      if(!bookTime || !arrivalTime) {
        return ' / ';
      }else{
        // let time = new Date(arrivalTime.replaceAll('-', '/')).getTime() - new Date(bookTime.replaceAll('-', '/')).getTime();
        let aa=arrivalTime.replace(new RegExp('-',"gm"),'/')
        let bb=bookTime.replace(new RegExp('-',"gm"),'/')
        let time = new Date(aa).getTime() - new Date(bb).getTime();
        return Math.ceil(time/1000/60);
      }
    },
    changeDate(e){
      let time=this.getLateTime(this.compensateForm.bookTime, e);
      this.compensateForm.lateTime = time;
      if(this.compensateForm.bookTime){
        if(time<30||time==30){
          this.compensateForm.compAmount=0;
        }else if(30<time&&time<60){
          this.compensateForm.compAmount=100
        }else{
          let ab=Math.ceil(time/60);
          this.compensateForm.compAmount=100*ab;
          if(this.compensateForm.compAmount>800){
            this.compensateForm.compAmount=800;
          }
        }
      }
    },
    getMerchantStatus(){//获取商户预约状态下拉框
      this.$api.getMerchantStatus().then(res=>{
        if(res.code==0){
          this.merchantStatusList=res.data;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.transactionReservation{
  padding: 20px 40px;
}
</style>