<template>
  <div class="accountChange">
    <el-row :gutter="24">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>门店编号</span>
        <el-input v-model="params.shopNo" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>门店名称</span>
        <el-input v-model="params.shopName" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>有效性</span>
        <el-select v-model="params.isEnabled" size="medium" clearable>
          <el-option value="" label="全部"></el-option>
          <el-option :value="0" label="有效"></el-option>
          <el-option :value="1" label="无效"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>门店类型</span>
        <el-select v-model="params.shopType" size="medium" clearable>
          <el-option value="00" label="全部"></el-option>
          <el-option :value="1" label="商户成交门店"></el-option>
          <el-option :value="0" label="客户成交"></el-option>
          <el-option :value="3" label="其他"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>行政区</span>
        <el-select v-model="params.businessAreaId" size="medium" clearable>
          <el-option v-for="item in arealist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
       <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column prop="shopNo" label="门店编号" align="center" ></el-table-column>
       <el-table-column prop="financeCode" label="财务系统部门编号" align="center"></el-table-column>
      <el-table-column prop="shopName" label="门店名称" align="center" ></el-table-column>
      <el-table-column prop="shopAddr" label="门店地址" align="center"></el-table-column>
      <el-table-column prop="shopTel" label="门店电话" align="center"></el-table-column>
      <el-table-column prop="shopType" label="门店类型" align="center">
        <template slot-scope="scope">{{scope.row.shopType===0?'客户成交':scope.row.shopType===1?'商户成交':scope.row.shopType===3?'其它':scope.row.shopType}}</template>
      </el-table-column>
      <el-table-column prop="isEnabled" label="有效性" align="center">
        <template slot-scope="scope">{{scope.row.isEnabled===0?'有效':scope.row.isEnabled===1?'无效':scope.row.shopType}}</template>
      </el-table-column>
      <el-table-column prop="lng" label="经度" align="center"></el-table-column>
      <el-table-column prop="lat" label="纬度" align="center"></el-table-column>
      <el-table-column prop="shopManager" label="店长" align="center"></el-table-column>
      <el-table-column prop="businessAreaName" label="行政区" align="center"></el-table-column>
      <el-table-column prop="weComQrCodeUrl" label="门店二维码" align="center">
        <template slot-scope="scope">
          <el-image 
            style="width: 100px; height: 100px"
            fit="cover"
            :src="scope.row.weComQrCodeUrl" 
            :preview-src-list="[scope.row.weComQrCodeUrl]">
            <div slot="error">暂无图片</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onsee(scope.row)" size="mini">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination> -->

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="800px">
      <el-form ref="form" :model="itemRow" :rules="rules" label-width="160px" size="medium">
        <el-form-item label="门店ID:"><p>{{itemRow.shopNo}}</p></el-form-item>
        <el-form-item label="门店名称:">
          <el-input v-model="itemRow.shopName" disabled></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="门店地址:">
              <el-input v-model="itemRow.shopAddr"  type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="门店电话:">
              <el-input v-model="itemRow.shopTel" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="门店类型:">
              <el-select v-model="itemRow.shopType" >
                <el-option label="客户成交" :value="0"></el-option>
                <el-option label="商户成交" :value="1"></el-option>
                <!-- <el-option label="售后服务" :value="2"></el-option> -->
                <el-option label="其它" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效性:">
              <el-select v-model="itemRow.isEnabled" >
                <el-option label="有效" :value="0"></el-option>
                <el-option label="无效" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度:">
              <el-input v-model="itemRow.lng" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度:">
              <el-input v-model="itemRow.lat"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="店长:">
              <el-input v-model="itemRow.shopManager" readonly>
                <template slot="append" >
                  <el-button @click="dialogVisibleShop=true">修改</el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行政区:">
              <el-select v-model="itemRow.businessAreaId" clearable>
                <el-option v-for="item in arealist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-row>
          <el-col :span="12">
            <el-form-item label="财务编号:">
              <el-input v-model="itemRow.financeCode" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="门店二维码:">
          <div v-if="itemRow.weComQrCodeUrl" class="modelImg">
            <el-image 
              style="width: 100px; height: 100px"
              :src="itemRow.weComQrCodeUrl" 
              :preview-src-list="[itemRow.weComQrCodeUrl]" >
              <div slot="error">暂无图片</div>
            </el-image>
            <i class="el-icon-delete imgDelete" @click="onDeleteImg"></i>
          </div>
          <el-upload v-else
              class="tableUpload"
              action
              accept="image/png, image/gif, image/jpeg,image/jpg"
              list-type="picture-card" 
              :on-change="requestUpload"
              :file-list="fileList"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
        </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="`门店名称：${itemRow.shopName}`"
      :visible.sync="dialogVisibleShop"
      width="600px">
      <el-row :gutter="24">
        <el-col :span="10" class="flex flex_align_center flex_between w2">
          <span>姓名:</span>
          <el-input v-model="forms.name" size="medium"></el-input>
        </el-col>
        <el-col :span="10"  class="flex flex_align_center flex_between w2">
          <span>工号:</span>
          <el-input v-model="forms.no" size="medium"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="postQueryUserList" size="medium">查询</el-button>
        </el-col>
      </el-row>
       <el-table :data="userlist" border stripe class="table-mainbg mt20" max-height="500px">
      <el-table-column prop="name" label="姓名" align="center" ></el-table-column>
      <el-table-column prop="no" label="工号" align="center" ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onBind(scope.row)" size="mini">绑定</el-button>
        </template>
      </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        customerName: '',
        carNo: '',
        createTime: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      dialogVisible:false,
      itemRow:{},
      dialogImageUrl:'',
      fileList:[],
      rules: {
          region: [{ required: true, message: '不能为空'}],
      },
      dialogVisibleShop:false,
      arealist:[],//区
      userlist:[],//用户
      forms:{},
      isEnabled:'',
    }
  },
  created(){
    this.init()
    this.getRecursionRegionByCode('310000')//上海市
    // this.postQueryUserList()//搜索用户,此接口不做模糊查询【不分页】
  },
  methods: {
    onDeleteImg(){
      this.itemRow.weComQrCodeUrl='';
    },
    onBind(row){
      this.itemRow.shopManager=row.name;
      this.itemRow.managerNo=row.no;
      this.dialogVisibleShop=false;
    },
    init(page){
      this.page_index=page?page:this.page_index;

      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        shopNo:this.params.shopNo,
        shopName:this.params.shopName,
        shopTypeList:this.params.shopType==='00'?'':this.params.shopType?[this.params.shopType]:'',
        businessAreaId:this.params.businessAreaId,
        isEnabled:this.params.isEnabled,
      }
      this.$api.getShopList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data;
            // this.page_total=res.data.total;
          }else{
            this.querylist=[];
            // this.page_total=0;
          }
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onsee(row){
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.isEnabled=row.isEnabled;
      this.dialogVisible=true;
    },
    onSubmit(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let query={
            // "fullName": this.itemRow.shopName,
            "shopAddr": this.itemRow.shopAddr,
            "shopTel": this.itemRow.shopTel,
            "lng": this.itemRow.lng,
            "lat": this.itemRow.lat,
            "shopManager": this.itemRow.shopManager,//店长名称
            "managerNo": this.itemRow.managerNo,//店长工号
            // "managerTel": this.itemRow.shopTel,//店长电话
            // "description": "主营二手车业务",//该经营门店相关情况描述
            "shopType": this.itemRow.shopType,//门店类型
            "businessAreaId": this.itemRow.businessAreaId,//门店经营所在区ID
            financeCode:this.itemRow.financeCode,
            // "businessCityId": 2,
            // "businessProvinceId": 1,
            // "customerContractTitle": "车开新顾戴路店",
            "weComQrCodeUrl": this.itemRow.weComQrCodeUrl
          }
          if(this.isEnabled!=this.itemRow.isEnabled){
              //生效/作废 门店
            this.getShopEnable(this.itemRow.id,{operaType:this.itemRow.isEnabled})
          }
          this.$api.putShopList(this.itemRow.id,query).then(res=>{
            if(res.code==0){
              this.init()
              this.dialogVisible=false;
              this.$message.success(res.message)
            }
          })
        }
      });
    },
    getShopEnable(id,params){//生效/作废 门店
      this.$api.getShopEnable(id,params).then(res=>{
        // this.init()
      })
    },
     requestUpload(file) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.itemRow.id);
      obj.append("businessName", '门店管理');
      obj.append("businessType", "shopManage");
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.itemRow.weComQrCodeUrl=res.data[0].fullFilePath;
          this.$message.success(res.message)
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    getRecursionRegionByCode(code){//根据code递归获取行政地区,含级联数据
      let query={
        code:code
      }
      this.$api.getRecursionRegionByCode(query).then(res=>{
        if(res.code==0&&res.data){
          if(res.data.length>0){
            this.arealist=res.data[0].children;
          }
        }
      })
    },
    postQueryUserList(){//搜索用户,此接口不做模糊查询【不分页】
      let query={
        no:this.forms.no,
        name:this.forms.name,
      }
      this.$api.postQueryUserList(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.accountChange{
  padding: 20px 40px;
  .modelImg{
    position:relative;
    width:100px;
    height:100px;
    .imgDelete{
      position:absolute;
      right:10px;
      top:10px;
      color:#F56C6C;
      font-weight:bold;
    }
  }
}
</style>