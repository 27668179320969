<template>
  <div class="currencyConterBox">
    <el-tabs v-model="tabClassify" type="card" @tab-click="init(1)">
      <el-tab-pane label="付违章费用列表" name="first">
        <el-row :gutter="24" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w5">
            <span>违章付款状态</span>
            <el-select v-model="forms.financePayStatuses" size="medium" clearable>
              <el-option label="全部" value="" ></el-option>
              <el-option
                v-for="item in paymentStatuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" class="flex flex_align_center flex_between w5">
            <span>订单创建时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button
            >
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
            <el-button type="primary" @click="onBatchPayment" size="medium"
              >批量付款</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="querylist"
          border
          stripe
          class="table-mainbg mt20"
          ref="table"
          @selection-change="selectChange"
        >
          <el-table-column
            type="selection"
            label="选择"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="breakRuleAmount"
            label="付款金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="accountName"
            label="账户名"
            align="center"
          >
            <!-- <template slot-scope="scope">{{scope.row.car.carCustomer?scope.row.car.carCustomer.carCustomerAccount?scope.row.car.carCustomer.carCustomerAccount.accountName:'':''}}</template> -->
          </el-table-column>
          <el-table-column
            prop="account"
            label="账号"
            align="center"
          >
            <!-- <template slot-scope="scope">{{scope.row.car.carCustomer?scope.row.car.carCustomer.carCustomerAccount?scope.row.car.carCustomer.carCustomerAccount.account:'':''}}</template> -->
          </el-table-column>
          <el-table-column
            prop="depositBankShortName"
            label="银行简称"
            align="center"
          >
            <!-- <template slot-scope="scope">{{scope.row.car.carCustomer?scope.row.car.carCustomer.carCustomerAccount?scope.row.car.carCustomer.carCustomerAccount.depositBankShortName:'':''}}</template> -->
          </el-table-column>
          <el-table-column
            prop="depositBank"
            label="开户行"
            align="center"
          >
            <!-- <template slot-scope="scope">{{scope.row.car.carCustomer?scope.row.car.carCustomer.carCustomerAccount?scope.row.car.carCustomer.carCustomerAccount.depositBank:'':''}}</template> -->
          </el-table-column>
          <el-table-column
            prop="payerName"
            label="付款人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="financePayTime"
            label="付款时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="financePayStatusName"
            label="违章付款状态"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row, 0)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="批量付违章费用列表" name="second">
        <el-row :gutter="24" class="mt30">
          <el-col :span="7" class="flex flex_align_center flex_between w6">
            <span>批量支付日期</span>
            <el-date-picker
              v-model="forms1.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w5">
            <span>批量支付状态</span>
            <el-select v-model="forms1.batchPayStatus" size="medium">
              <el-option label="请选择状态" value=""></el-option>
              <el-option v-for="item in BatchPayStatuslist" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="querylist1"
          border
          stripe
          class="table-mainbg mt20"
        >
          <el-table-column
            prop="batchPayOrderNo"
            label="批次编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayAmount"
            label="批量支付金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayTime"
            label="批量支付日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayStatusName"
            label="批量支付状态"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row, 1)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabClassify: "first",
      querylist: [], //
      querylist1: [], //
      forms: {
        merchantName: "",
        state: "",
        date: "",
        financePayStatuses:'',
      },
      forms1:{},
      total: 0,
      total1: 0,
      currentPage: 1,
      currentPage1: 1,
      pageSize: 10,
      pageSize1: 10,
      paymentStatuslist: [],
      BatchPayStatuslist: [],
      selectlist: [], //选中的数据
      stateType: "", //判断是否从详情来展示对应的tab
    };
  },
  created() {
    this.paymentStatuslist = this.$store.state.setting.paymentStatuslist;
    this.BatchPayStatuslist = this.$store.state.setting.BatchPayStatuslist;
    if(this.$route.params.state) this.forms.financePayStatuses=Number(this.$route.params.state);
    this.init();
  },
  methods: {
    init(page) {
      if (this.tabClassify == "first") {
        this.postBreakRuleQuery(page)
      } else {
        this.postBreakRuleBatchQueryy(page)
      }
    },
    postBreakRuleQuery(page){//违章付款条件查询
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,
        "createBeginTime": this.forms.date?`${this.forms.date[0]} 00:00:00`:'',
        "pageSize": this.pageSize,
        "createEndTime": this.forms.date?`${this.forms.date[1]} 23:59:59`:'',
        "carNo": this.forms.carNo,
        "financePayStatuses": this.forms.financePayStatuses?[this.forms.financePayStatuses]:''//
      }
      this.$api.postBreakRuleQuery(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.total = res.data.total;
        }else{
          this.querylist = [];
          this.total = 0;
        }
      })
    },
    postBreakRuleBatchQueryy(page){//违章付款批量条件查询
      this.currentPage1=page?page:this.currentPage1;
      let query={
        "curPage": this.currentPage1,
        "batchPayBeginTime": this.forms1.date?`${this.forms1.date[0]} 00:00:00`:'',
        "pageSize": this.pageSize1,
        "batchPayEndTime": this.forms1.date?`${this.forms1.date[1]} 23:59:59`:'',
        "carNos": this.forms1.carNo?[this.forms1.carNo]:'',
        "batchPayStatus": this.forms1.batchPayStatus?[this.forms1.batchPayStatus]:''//
      }
      this.$api.postBreakRuleBatchQueryy(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist1 = res.data.records;
          this.total1 = res.data.total;
        }else{
          this.querylist1 = [];
          this.total1 = 0;
        }
      })
    },
    handleSizeChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.pageSize = data;
          this.currentPage=1;
          break;
        case "second":
          this.pageSize1 = data;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.currentPage = data;
          break;
        case "second":
          this.currentPage1 = data;
          break;
      }
      this.init();
    },
    onRowClick(row, state) {
      let query={};
      if(state==0){
        query.breakRulePayId = row.id
      }else{
        query.batchPayOrderNo=row.batchPayOrderNo;
      }
      this.$router.push({
        path: "/finance/illegalPaymentDetail",
        query: query,
      });
    },
    onBatchPayment() {
      let arr=[];
      let payType=false;//当前是否有单存在付款完成
      for(let i=0;i<this.selectlist.length;i++){
        if(this.selectlist[i].financePayStatus!=31){
          payType=true;
        }
        arr.push(this.selectlist[i].carNo)
      }
      if(payType){
        this.$message.warning('当前订单中存在不需要付款的数据，请确认再付款！');
        return
      }
      // for(let i=0;i<this.selectlist.length;i++){
      //   arr.push(this.selectlist[i].id)
      // }
      if (this.selectlist.length == 0) {
        this.$message("请选择需要批量付款的车辆！");
        return;
      }
      this.$store.dispatch('setting/setViolationList',this.selectlist); 
      let aurl = "/finance/illegalPaymentDetail";
      this.$router.push({
        path: aurl,
        query: {
          carNos:arr
        },
      });
    },
    selectChange(rows) {
      this.selectlist = rows;
    },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
}
</style>