<template>
  <!-- 供应商列表 -->
  <div class="supplier">
    <div class="mb20">
      <el-button type="primary" size="mini" plain icon="el-icon-plus" @click="handleRole('add')">添加供应商</el-button>
    </div>
    <el-table :data="tableData" border stripe class="table-mainbg">
      <el-table-column prop="id" label="供应商ID" align="center"></el-table-column>
      <el-table-column prop="name" label="供应商名称"></el-table-column>
      <el-table-column prop="contacts" label="联系人"></el-table-column>
      <el-table-column prop="contactsMobile" label="联系方式" width="130" align="center"></el-table-column>
      <el-table-column prop="businessRange" label="业务范围" ></el-table-column>
      <el-table-column prop="businessCity" label="开展业务城市" ></el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain icon="el-icon-edit" @click="handleRole('edit',scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" plain icon="el-icon-view" @click="handleRole('check',scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <!-- 供应商信息 -->
    <el-dialog title="供应商信息" :visible.sync="dialogFormVisible" center>
      <el-form :model="form" :rules="rules" label-width="120px" ref="form" size="medium">
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商名称">
              <el-input v-model="form.name" autocomplete="off" :disabled="handleType === 'check'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务范围">
              <el-select multiple v-model="form.businessRangeDictIdList" style="width:100%;" :disabled="handleType === 'check'" value-key="id"  @change="changeBusines">
                <el-option v-for="item in businessRangeList" :key="item.id" :label="item.label" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人">
              <el-input v-model="form.contacts" autocomplete="off" :disabled="handleType === 'check'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经营范围">
              <el-select v-model="form.businessCityDictIds" style="width:100%;" :disabled="handleType === 'check'">
                <el-option v-for="item in businessCityList" :key="item.id" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="contactsMobile">
              <el-input v-model="form.contactsMobile" autocomplete="off" :disabled="handleType === 'check'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商类型">
              <el-select v-model="form.supplierType" style="width:100%;" :disabled="handleType === 'check'" >
                <el-option label="办证" value="办证"></el-option>
                <el-option label="违章" value="违章"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="账户名称">
              <el-input v-model="form.accountName" autocomplete="off" :disabled="handleType === 'check'" @input="onInput"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账号">
              <el-input v-model="form.account" autocomplete="off" :disabled="handleType === 'check'" @input="onInput"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行简称">
              <el-select v-model="form.depositBankShortName" style="width:100%;" :disabled="handleType === 'check'" @input="onInput">
                <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户行" class="bankULBoxConter" > <el-cascader ref="address"
        v-model="address" @change="changeAddress"
        :options="addresslist" :props="props"></el-cascader>
              <el-input v-model.trim="form.depositBank" class="lineInput  bankInput" :disabled="handleType === 'check'" @input="onInputBank" placeholder="请输入开户行银行名称"></el-input>
              <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
                <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item)">{{item.bankName}}</li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="supplierSubmit" :disabled="handleType === 'check'">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      businessRangeList: [],
      businessCityList: [],
      dialogFormVisible: false,
      form: {
        name: '',
        contacts: '',
        contactsMobile: '',
        businessRangeDictIdList: [],
        businessCityDictIds: '',
        supplierType:''
      },
      rules: {
        contactsMobile:[{ validator: checkPhone, trigger: 'blur' } ],
      },
      handleType: '',
      currentRow: null,
      bankVis:false,
      banklistSear:[],
      banklist:[],
      addresslist:[],//地址
      address:[],//地址名称
      props:{
        label:'name',
        value:'code',
        children:'children'
      },
    }
  },
  created() {
    this.getSupplierList();//获取供应商列表
    this.supplierGetDictsByType("business_range");//获取业务范围下拉框
    this.supplierGetDictsByType("business_city");//获取业务范围下拉框
    this.postbankHeadList();//获取银行名称
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.addresslist=res;
      })
    }else{
      this.addresslist=this.$store.state.setting.cityList;
    }
  },
  methods: {
    changeBusines(e){
      let arr=[]
      if(e){
        for(let i=0;i<e.length;i++){
            if(e[i].label){
              arr.push(e[i].label)
            }else{
              arr.push(e[i])
            }
          }
      }
      this.form.businessRangeDictIdList=arr;
    },
    changeAddress(){
      let node=this.$refs.address.getCheckedNodes()
      // this.address=node[0].pathLabels;
    },
    getSupplierList() {
      this.$api.supplierQuery({
        businessCityId: 0,
        isEnabled: 0,
        curPage: this.page_index,
        pageSize: this.page_size,
      }).then(res => {
        if(res.code == 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
        }
      })
    },
    async supplierGetDictsByType(type) {
      let res = await this.$api.supplierGetDictsByType({
        curPage: 1,
        typeList: [type],
        pageSize: 49
      });
      if(res.code == 0){
        if(type === 'business_city'){
          this.businessCityList = res.data.records;
        }else if(type === 'business_range') {
          this.businessRangeList = res.data.records;
        }
      }
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getSupplierList()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getSupplierList()
    },
    onInput(){
      this.$forceUpdate()
    },
    handleRole(type, row) {
      this.handleType = type;
      this.dialogFormVisible = true;
      
      if(type === 'add'){
        this.form= {
          name: '',
          contacts: '',
          contactsMobile: '',
          businessRangeDictIdList: [],
          businessCityDictIds: '',
          supplierType:'',
        }
        this.address=[];
      }else if(type === 'edit'||type === 'check'){
        this.currentRow = JSON.parse(JSON.stringify(row));
        this.form.name = row.name;
        this.form.contacts = row.contacts;
        this.form.contactsMobile = row.contactsMobile;
        this.form.businessRangeDictIdList = row.businessRange?row.businessRange.split(','):[];
        this.form.businessCityDictIds = row.businessCity;
        this.form.account = row.account;
        this.form.accountName = row.accountName;
        this.form.depositBank = row.depositBank;
        this.form.depositBankShortName = row.depositBankShortName;
        this.form.supplierType = row.supplierType;
        this.address=[row.provinceNo,row.cityNo]
      }
    },
    supplierSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            if(this.handleType === 'add'){
              let query={
                name: this.form.name,
                contacts: this.form.contacts,
                contactsMobile: this.form.contactsMobile,
                businessRangeList: this.form.businessRangeDictIdList,
                businessCityList: [this.form.businessCityDictIds],
                account:this.form.account,
                accountName:this.form.accountName,
                depositBank:this.form.depositBank,
                depositBankShortName:this.form.depositBankShortName,
                supplierType:this.form.supplierType,
                provinceNo:this.address.length>0?this.address[0]:'',
                cityNo:this.address.length>0?this.address[1]:'',
                // areaNo:this.address.length>0?this.address[2]:'',
              }
              this.$api.supplierAdd(query).then(res => {
                if(res.code == 0){
                  this.$message.success(res.message)
                  this.$refs['form'].resetFields();
                  this.getSupplierList();
                  this.dialogFormVisible = false;
                }
              })
            }else if(this.handleType === 'edit'){
                let query={
                  "id": this.currentRow.id,
                  "name": this.form.name,
                  "code": this.form.code,
                  "contacts": this.form.contacts,
                  "contactsMobile": this.form.contactsMobile,
                  "businessRangeList":this.form.businessRangeDictIdList,
                  "businessCityList":[this.form.businessCityDictIds],//开展业务城市,
                   account:this.form.account,
                  accountName:this.form.accountName,
                  depositBank:this.form.depositBank,
                  depositBankShortName:this.form.depositBankShortName,
                  supplierType:this.form.supplierType,
                  provinceNo:this.address.length>0?this.address[0]:'',
                  cityNo:this.address.length>0?this.address[1]:'',
                  // areaNo:this.address.length>0?this.address[2]:'',
                  // "remark": "string",
                  // "isEnabled": 0,
                }
              this.$api.supplierUpdate(this.currentRow.id,query).then(res => {
                if(res.code == 0){
                  this.$message.success(res.message)
                  this.$refs['form'].resetFields();
                  this.getSupplierList();
                  this.dialogFormVisible = false;
                }
              })
            }
          } else {
            return false;
          }
      })
    },
    // formatRange(row) {
    //   if(!row.businessRangeDictIds){
    //     return '-'
    //   }else{
    //     let arr = row.businessRangeDictIds.split(',')
    //   return this.businessRangeList
    //   .filter(item => {
    //     return arr.indexOf(String(item.id)) > -1
    //   })
    //   .map(item => item.label)
    //   .join(',');
    //   }
    // },
    // formatCity(row) {
    //   if(!row.businessCityDictIds){
    //     return '-'
    //   }else{
    //     let arr = row.businessCityDictIds.split(',')
    //   return this.businessCityList
    //   .filter(item => {
    //     return arr.indexOf(String(item.id)) > -1
    //   })
    //   .map(item => item.label)
    //   .join(',');
    //   }
    // },
    postbankHeadList(){//查询银行简称
      this.$api.postbankHeadList({}).then(res=>{
        if(res.code==0){
          this.banklist=res.data;
        }
      })
    },
    remoteMethod(e){
      if(e){
        this.loading = true;
        this.postbankList(e)
      }
    },
    onBankTage(row){
      this.form.depositBank=row.bankName;
      this.bankVis=false;
    },
    onInputBank(e){
      if(this.address.length==0){
        this.$message.warning('请选择省市区再进行输入！')
        return
      }
      if(e){
        this.bankVis=true;
        this.postbankList(e)
      }else{
        this.bankVis=false;
      }
    },
    postbankList(bankName){//根据银行简称
      let query={
        bankName:bankName,
        headBankName:this.form.depositBankShortName,
        cityNo:this.address[1]
      }
      this.$api.postbankList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.banklistSear=res.data;
        }
      })
    },
  }

}
</script>

<style scoped lang="scss">
.supplier{
  padding: 20px;
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:1;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
}
</style>
