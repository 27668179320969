<template>
<div>
  <!-- 商户合同 -->
  <div class="merchantContract" id="printMe">
    <h1>合同编号: {{dataForm.contractNo}}</h1>
    <div class="flex flex_between flex_align_center mb10 mt10">
      <div class="ckxTitle">车辆信息</div>
      <el-button type="primary" plain @click="onRouter">报告查看>></el-button>
    </div>
    <table class="carInfoTable">
      <tbody>
        <tr>
          <td>车牌号码</td><td>{{form.carNo}}</td>
          <td>厂牌型号</td><td>{{dataForm.car.makerName}}&nbsp;{{dataForm.car.seriesName}}</td>
          <td>发动机号</td><td>{{form.engineNo}}</td>
          <td>颜色</td><td>{{form.carColor}}</td>
        </tr>
        <tr>
          <td>初次登记日期</td><td>{{form.registerDate}}</td>
          <td>年检有效期</td><td>{{form.checkExpire}}</td>
          <td>车架号</td><td>{{form.vinCode}}</td>
          <td>电子保单</td>
          <td>
            <el-button type="primary" @click="onOperate(1)" size="mini" plain>查看</el-button>
            <el-button type="primary" @click="onOperate(2)" size="mini" plain>下载</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="ckxTitle mt20 mb10">成交信息</div>
    <el-row class="mb20">
      <el-col :span="5">成交价：{{form.mcTradePrice}}元</el-col>
      <el-col :span="5">报价有效期：{{form.tradedPriceExpire}}</el-col>
      <el-col :span="5">成交类型：{{form.tradeTypeName}}</el-col>
      <!-- <el-col :span="5">商户办证意向：{{dataForm.merchantCertIntention}}</el-col> -->
      <el-col :span="4" v-if="merchantFailedType">预约沟通备注：{{dataForm.preLinkBak}}</el-col>
    </el-row>
    <!--客户合同签订失败商户处理-->
    <template v-if="merchantFailedType">
    <div class="ckxTitle mt20 mb10">车辆性质</div>
    <el-row class="mb20">
      <el-col :span="5">车籍：{{form.carRegister}}</el-col>
      <el-col :span="5">公私性质：{{form.publicOrPrivateName}}</el-col>
      <el-col :span="5">车牌颜色：{{form.plateColorName}}</el-col>
      <el-col :span="5">进口国产：{{form.inOrOutName}}</el-col>
    </el-row>
    <!--是否渠道车辆，71：否，72是，默认71-  说明：渠道4S店客户会展示此版块 -->
    <el-row v-if="form.isChannelCar==72" class="mt20 mb20 conterBg">
      <el-col :span="5">4S店名：{{channelForm.shortName}}</el-col>
      <el-col :span="5">扩展专员：{{channelForm.extensionerName}}</el-col>
    </el-row>
    </template>
    
    <el-form ref="dataForm" :model="dataForm" :rules="rules">
      <div class="ckxTitle mt10 mb10">商户信息</div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="商户姓名:"><p>{{dataForm.merchantName}}</p></el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="商户编号:"><p>{{dataForm.merchantNo}}</p></el-form-item>
        </el-col>
        <template v-if="merchantFailedType">
          <el-col :span="6">
            <el-form-item label="车辆物流方式：" prop="carLogistType">
              <el-select v-model="dataForm.carLogistType" class="formItemWidth" placeholder="请选择" @change="selectChange($event,'物流')" :disabled="disabledAll">
                <el-option label="商户自提" :value="22"></el-option>
                <el-option label="委托物流" :value="21"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
      <el-row v-if="merchantFailedType">
        <el-col :span="6">
          <el-form-item label="办证方式：" prop="certType" :key="1" :rules="[{ required: true, message: '请选择办证方式',trigger: 'change'}]">
            <el-select v-model="dataForm.certType" class="formItemWidth" @change="selectChange($event,'办证')" placeholder="请选择" :disabled="disabledAll&&!changeState">
              <el-option label="代办公司" :value="12" v-if="merchantType==false||changeState==1"></el-option>
              <el-option label="商户自理" :value="11"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="办证公司：" prop="certCompanyId" v-if="dataForm.certType===12" :rules="[{ required: true, message: '请选择办证公司',trigger: 'change' }]" :key="0">
            <el-select v-model="dataForm.certCompanyId" class="formItemWidth" placeholder="请选择" clearable :disabled="changeState===1?false:true&&disabledAll||disabledAll">
              <el-option v-for="item in companylist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="自理原因：" v-if="dataForm.certType===11">
            <el-radio-group v-model="dataForm.merchantSelfDesc" :disabled="changeState==1?false:true&&disabledAll||disabledAll">
              <el-radio label="改装车"></el-radio>
              <el-radio label="非帮办"></el-radio>
              <el-radio :label="dataForm.other">其他<el-input v-model="dataForm.other" class="w200 ml10" :disabled="changeState===1?false:true&&disabledAll||disabledAll" @input="inputMerchantSelf"></el-input></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template v-if="merchantFailedType">
    <!--验车方式-->
    <div class="conterBg mb20">
    <div class="mb10 ">验车方式：{{dataForm.merchantValid?dataForm.merchantValid.validTypeName:''}}</div>
    <!---->
    <template v-if="dataForm.merchantValid">
      <div class="ckxTitle mb10">
        <span>复检结果</span>
        <span class="ml30 blue pointer" @click="onRouterRecheck(1)" v-if="dataForm.merchantValid.validType==52">远程代看及评定结果</span>
        <span class="ml30 blue pointer" @click="onRouterRecheck(2)" v-if="dataForm.dzValid">垫资复检及评定结果（客）</span>
      </div>
      <template v-if="dataForm.merchantValid.validStatus==3"> 
        <template v-if="dataForm.merchantValid.validType==52">
        <el-row class="mb10">
          <el-col :span="6">复检检测师：{{recheckForm.checkerName}}</el-col>
          <el-col :span="6">复检结果：{{recheckForm.hasDifference==11?'无差异':recheckForm.hasDifference==12?'有差异':recheckForm.hasDifferenceName}}</el-col>
          <el-col :span="6">复检时间：{{recheckForm.validFinishTime}}</el-col>
        </el-row>
        <div>复检备注：{{recheckForm.remark}}</div>
        </template>
      
        <div class="ml30" v-if="dataForm.merchantValid.validType==51">{{recheckForm.hasDifference==11?'无差异':recheckForm.hasDifference==12?'有差异':recheckForm.hasDifferenceName}}</div>
      </template>
      <p v-if="dataForm.merchantValid.validStatus!=3">{{dataForm.merchantValid.validStatusName}}</p>
    </template>
    </div>
    <!--垫资车且复检结果有差异时会展示此版块 成交类型: 21预约成交，22垫资成交 检测结果，11有差异，12无差异-->
    <div v-if="dataForm.merchantValid.recheckEvaluateResult">
      <div class="ckxTitle mt20 mb10">复检差异评定结果</div>
      <div class="mb10">
        <span class="mr40">复检差异评定结果：{{dataForm.merchantValid.recheckEvaluateResult}}</span>
      </div>
      <!-- <div class="mb20">评定备注：{{evaluateForm.remark}}</div> -->
    </div>
    
    <div class="ckxTitle mb10">车主信息</div>
    <div class="mb20">
      <el-row>
        <el-col :span="6">车辆持有人性质：{{form.carCustomer.carOwnerNatureName}}</el-col>
        <el-col :span="6">海车集：{{form.carCustomer.seaGather}}</el-col>
        <el-col :span="6">身份证刷验：{{form.carCustomer.idFaceValid}}</el-col>
        <el-col :span="6">车主证件复印件：{{form.carCustomer.idCopy}}</el-col>
      </el-row>
    </div>
    <div class="ckxTitle mb10">过户手续约定及车辆、过户材料交接表</div>
    <!-- 车主证件 idcardlist-->
    <table class="ownerCertificate">
      <tr>
        <td>车主证件</td><td>状态</td><td>费用</td><td>押金</td><td>费用承担方</td><td>备注</td>
      </tr>
      <tr v-for="(item,index) in idcardlist" :key="`car${index}`">
        <td>{{item.materialName}}</td>
        <td><!--状态(实际情况)-->
          <div class="alignLeft" v-if="item.materialName.indexOf('居住证')==-1&&item.materialName.indexOf('盖章材料')==-1">
            <p>{{item.realData1?`${item.realData1}${item.materialName}`:item.realData1}}</p>
            <p>证件号码：{{item.realData2}}</p>
            <p v-if="item.realData1!='有'">提供日期：{{item.realData3}}</p>
          </div>
          <div class="alignLeft" v-if="item.materialName.indexOf('居住证')!=-1||item.materialName.indexOf('盖章材料')!=-1">
            <p>{{item.realData1?`${item.realData1}${item.materialName}`:item.realData1}}</p>
            <p v-if="item.realData1!='有'">提供日期：{{item.realData2}}</p>
          </div>
          <!-- <div class="alignLeft" v-if="item.materialName.indexOf('盖章材料')!=-1">
            <p>{{item.realData1}}</p>
          </div> -->
        </td>
        <td>{{item.feeAmount?`${item.feeAmount}元`:'-'}}</td><td>{{item.depositAmount?`${item.depositAmount}元`:'-'}}</td><td>{{item.feeBearerName?item.feeBearerName:'-'}}</td>
        <td :rowspan="idcardlist.length+1" 
          v-if="index==0" >
          <div class="flex flex_align_center">
            <el-date-picker class="ml20 mr20 lineInput " v-model="item.remark2" type="daterange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" readonly clear-icon="el-icon-date"></el-date-picker>
            <span>甲方无法提供证件</span>
          </div>
          <div class="flex flex_align_center mt20">
            <el-date-picker class="ml20 mr20 lineInput" v-model="item.remark4" type="date" value-format="yyyy-MM-dd" readonly placeholder="选择日期" clear-icon="el-icon-date"></el-date-picker>
            <span>乙方须将证件交回甲方</span>
          </div>
        </td>
      </tr>
      <tr><td :colspan="idcardlist.length>0?5:6">暂缺项条款：自该委托书约定送达日起每延迟一个工作日交予乙方，甲方支付乙方相应逾期补偿金人民币100元/工作日，延长相应工作日</td></tr>
    </table>
    <!-- 项目名称 -->
    <table class="carGoods">
      <tr>
        <td>项目名称</td><td>PDA 采集数据</td><td>实际情况</td><td>选项</td><td>差异选项</td><td>费用</td><td>押金</td><td>费用承担方</td>
      </tr>
      <!-- 需要询问4S点费用，只有“沪电动车牌”会体现这个字段 -->
      <!--isHuPlate是否沪牌，1是,2否，默认1,isNewEnergy是否新能源车，81否，82是,默认81,readonly-->
      <tr v-for="(item,index) in carMaterialsList" :key="`mate_${index}`">
          <td><i class="tipsColor" v-if="['车辆牌照','备胎'].indexOf(item.materialName)!=-1||item.materialName.indexOf('机动车')!=-1">*</i>{{item.materialName}}</td>
          <td>{{item.pdaData}}</td>
          <td><!--实际情况-->
            <div v-if="item.materialName.indexOf('钥匙')>-1">
              {{item.realData?`有${item.realData}把`:item.realData}}
            </div>
            <div v-else>{{item.realData}}</div>
          </td>
          <td :colspan="item.materialName.indexOf('违章')>-1?2:0"><!--选项-->
            <div v-if="item.materialName.indexOf('钥匙')>-1">
              <div class="mb20 alignLeft">
                <span class="spanwidth">缺</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options1" type="number" size="medium" readonly></el-input>
                <span>把</span>
              </div>
              <div class="alignLeft">
                <span class="spanwidth">暂缺</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options2" type="number" size="medium" readonly></el-input>
                <span>把</span>
                <el-date-picker class="ml20 mr10 inputDate lineInput" disabled v-model="item.options3" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" size="medium" ></el-date-picker>
                <span>客户提供</span>
              </div>
            </div>
            <div class="textleft" 
            v-if="['桩充','线充','备胎'].includes(item.materialName)&&['有'].indexOf(item.pdaData)>-1&&['暂缺'].indexOf(item.realData)!=-1||
            item.materialName.indexOf('审核单')>-1&&['暂缺'].indexOf(item.realData)!=-1||
            ['车辆牌照'].includes(item.materialName)&&['有'].indexOf(item.pdaData)>-1&&['暂缺'].indexOf(item.realData)!=-1">
              <el-date-picker class="mr10 inputDate lineInput" v-model="item.options" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" size="medium"  disabled></el-date-picker>
              <span>客户提供</span>
            </div>
            <div v-if="['机动车登记证书','机动车行驶证'].includes(item.materialName)"><!-- ['有'].indexOf(item.pdaData)!=-1这个地方不许PDA的数据做判断--->
              <div v-if="['有'].indexOf(item.realData)!=-1">
                <div class="alignLeft">
                  <el-radio-group v-model="item.options1" size="medium"  readonly>
                    <el-radio label="正常">正常</el-radio>
                    <el-radio label="需变更">需变更</el-radio>
                    <el-radio label="如需变更">如需变更</el-radio>
                  </el-radio-group>
                </div>
                <div class="mt10 alignLeft" v-if="['机动车登记证书'].includes(item.materialName)&&form.isHuPlate===1">
                  <span>产证编号：</span>
                  <el-input maxlength="12" type="number" v-model="item.options2" class="inputDate lineInput" size="medium" readonly></el-input>
                </div>
                <div class="flex mt10" v-if="['需变更','如需变更'].includes(item.options1)">
                  <span>变更内容：</span>
                  <el-input maxlength="100" class="inputText" v-model="item.options3" type="textarea" :autosize="{minRows: 2}" size="medium"  readonly></el-input>
                </div>
              </div >
              <el-checkbox v-if="['缺'].indexOf(item.realData)!=-1" v-model="item.options4" label="补办">补办</el-checkbox>
              <div v-if="['暂缺'].indexOf(item.realData)!=-1">
                 <el-date-picker class="mr10 inputDate lineInput" v-model="item.options5" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" size="medium"  disabled></el-date-picker>
                <span>客户提供</span>
              </div>
            </div>
            <div class="textleft" v-if="item.materialName.indexOf('保险单')>-1&&['有'].indexOf(item.realData)!=-1">
              <el-checkbox-group v-model="item.options1" class="size16" readonly>
                <el-checkbox label="正本">正本</el-checkbox>
                <el-checkbox label="副本">副本</el-checkbox>
                <el-checkbox label="电子保单">电子保单</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="textleft" v-if="item.materialName.indexOf('违章')>-1&&['无'].indexOf(item.realData)==-1">
              <div class="mb10 alignLeft" v-if="item.realData&&item.realData!='无'">
                <el-date-picker class="mr10 inputDate" v-model="item.options1" type="date" placeholder="选择日期" size="medium" value-format="yyyy-MM-dd" disabled></el-date-picker>
                <span>前的</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options2"  type="number" size="medium" readonly></el-input><span>次</span>
              </div>
              <div class="alignLeft" v-if="item.realData&&item.realData!='无'">
                <el-date-picker class="mr10 inputDate" v-model="item.options3" type="date" placeholder="选择日期" size="medium"  value-format="yyyy-MM-dd" disabled></el-date-picker>
                <span>处理完毕</span>
              </div>
              <div class="alignLeft mt10"  v-if="item.realData=='代办'">
                <span>违章本金</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options4" type="number" size="medium" readonly></el-input>
                <span class="mr40">元</span>
                <span>驾照扣分</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options5" type="number" size="medium" readonly></el-input>
                <span>分</span>
              </div>
              <!-- <div class="mt10">
                <el-checkbox v-model="item.options6" v-if="item.options2>=1" @input="changeUpdate($event,item)">一项超过12分</el-checkbox>
              </div> -->
            </div>
          </td>
          <td v-if="item.materialName.indexOf('违章')==-1"><!--差异选项--> 
            <!-- 车辆牌照、机动车登记证、行驶证选择“缺”时出现 -->
            <!-- 车辆牌照(item.realData=1缺)、机动车登记证(goodsValue5=1缺)、行驶证(goodsValue6=1缺)选择“缺”时出现-->
            {{item.differenceOptions}}
          </td>
          <td><!--费用-->
            {{item.feeAmount?`${item.feeAmount}元`:''}}
          </td>
          <td><!--押金-->
            {{item.depositAmount?`${item.depositAmount}元`:''}}
          </td>
          <td><!--费用承担方-->
            {{item.feeBearerName}}
          </td>
        </tr>
      <tr v-if="carMaterialsList.length==0"><td colspan="8">暂无数据</td></tr>
    </table>
    <!-- 车辆手续 carlistPro-->
    <table class="carProcedures">
      <tr>
        <td>车辆手续</td><td>状态</td><td>费用</td><td>费用承担方</td><td>备注</td>
      </tr>
      <template v-for="(item,index) in carlistPro" >
      <tr :key="`pro_${index}`" v-if="item.materialName.indexOf('额度变更')!=-1&&limitFlag||item.materialName.indexOf('额度变更')==-1">
        <td><!--车辆手续-->
          <div v-if="item.materialName.indexOf('额度变更')==-1&&item.materialName.indexOf('备注')==-1">{{item.materialName}}</div>
          <div v-if="item.materialName.indexOf('额度变更')!=-1">
            <div class="alignLeft">额度变更</div>
            <div class="alignLeft mt10" style="width:300px;">
              <el-radio-group v-model="item.materialName1" size="medium" readonly disabled>
                <el-radio :label="0" >父母与子女</el-radio>
                <el-radio :label="1">夫妻变更</el-radio>
                <el-radio :label="2">公司抬头变更</el-radio>
                <!-- <el-radio :label="3">公司合并或拆分</el-radio> -->
              </el-radio-group>
            </div>
          </div>
          <div v-if="item.materialName.indexOf('备注')!=-1">
            <span>{{item.materialName}}</span>: 
            <el-input class="ml10 lineInput formItemWidth" v-model="item.selfDefineRemark" size="medium" readonly></el-input>
          </div>
        </td>
        <td><!--状态(实际情况)-->
        {{item.realData}}
          <!-- <el-checkbox v-model="item.realData" >{{item.materialName.indexOf('备注')==-1?'是':''}}</el-checkbox> -->
        </td>
        <td><!--费用-->
          <div>
            <el-input class="ml10 mr10 inputNum lineInput" v-model="item.feeAmount" type="number" size="medium" readonly></el-input>
            <span>元</span>
          </div>
        </td>
        <td><!--费用承担方-->{{item.feeBearerName}}</td>
        <td>{{item.remark}}</td><!--备注-->
      </tr>
      </template>
      <tr v-if="carlistPro.length==0"><td colspan="5">暂无数据</td></tr>
    </table>
    <div>
      <div class="ckxTitle mt20 mb10">过户凭证特殊要求：</div>
      <p v-for="(item,index) in checkedList2" :key="index">{{index+1}}、{{item.requireName}}{{item.bak}};</p>
      <p v-if="checkedList2.length==0">无</p>
    </div>
    <div>
      <div class="ckxTitle mt20 mb10">其他约定事项（客户）：</div>
      <p>{{covenants?covenants:'无'}}</p>
    </div>
    <div class="ckxTitle mt20 mb10">其他约定事项（商户）：</div>
    <el-input type="textarea" v-model="covenantsMater" :autosize="{minRows: 4}" size="medium" :disabled="disabledAll"></el-input>
    <div>
      <div class="ckxTitle mt20 mb10">办证日期约定：</div>
      <p>材料在<el-date-picker v-model="dataForm.merchantDeadline" class="lineInput" placeholder=""  value-format="yyyy-MM-dd" :disabled="disabledAll"></el-date-picker>完成办证手续</p>
    </div>
    <div class="mt20" :class="stateType?'pb100':''">
      <div class="flex flex_align_center">
        <div class="ckxTitle">委托收购价格</div>
        <el-button type="parmary" size="medium" plain class="ml20" @click="onAutooper('价格')" :disabled="disabledAll">自动计算</el-button>
      </div>
      <div class="mt10">
        <span>甲方支付乙方人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.totalPayAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.totalPayAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10">明细如下：</div>
      <div class="mt10">
        <span>1. 该车委托收购价格为人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.carTradedPriceCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.carTradedPrice" disabled size="medium"></el-input><span>）（不带牌）</span>
      </div>
      <div class="mt10">
        <span>2. 甲方支付乙方交易技术服务费为人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.feeTechnoAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.feeTechnoAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10">
        <span>3. 甲方预付过户手续保证金</span>
        <span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.depositAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10">
        <span>4. 库存周转费</span>
        <span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.feeTurnoverAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10" v-if="payForm.insureAmount">
        <span>5. 拿车无忧（{{payForm.merchantInsure}}）服务费：</span>
        <span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.insureAmount" disabled size="medium"></el-input><span>）</span>
      </div>
    </div>
    </template>
    <template v-if="!merchantFailedType">
      <div class="ckxTitle mb15">客户合同失败原因</div>
      <el-row>
        <el-col :span="12">签订结果：{{ccReasonRsp.customerContractStatusName}}</el-col>
        <el-col :span="12">是否已付意向金：{{ccReasonRsp.indemnifyRsp.payIntentionMoney===1?'已支付':ccReasonRsp.indemnifyRsp.payIntentionMoney===0?'未支付':ccReasonRsp.indemnifyRsp.payIntentionMoney}}</el-col>
        <el-col :span="24" class="mt10 mb10">责任方：{{ccReasonRsp.responsiblePartyName}}</el-col>
        <el-col :span="24">备注：{{ccReasonRsp.remark}}</el-col>
        <el-col :span="24" class="mt10 mb10 flex">
          <p>赔偿情况：</p>
          <el-radio-group v-model="ccReasonRsp.indemnifyRsp.indemnifyType" disabled >
            <el-radio :label="0">无需赔偿</el-radio>
            <el-radio :label="2">车开新赔偿客户：<span class="lineMin">{{ccReasonRsp.indemnifyRsp.indemnifyType==2?ccReasonRsp.indemnifyRsp.indemnifyMoney:''}}</span>元</el-radio>
            <el-radio :label="4">客户赔偿车开新：<span class="lineMin">{{ccReasonRsp.indemnifyRsp.indemnifyType==4?ccReasonRsp.indemnifyRsp.indemnifyMoney:''}}</span>元</el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="24">帮卖经理：{{ccReasonRsp.managerName}}</el-col>
      </el-row>
      <div class="ckxTitle mt30 mb15">商户处理</div>
      <el-radio-group v-model="merchantProcessingRad" :disabled="dataForm.contractStatus===27?false:true">
        <el-radio :label="0">无需赔偿</el-radio>
        <el-radio :label="1">
          <span>赔偿商户</span>
          <el-select v-model="merchantProcessing" placeholder="请选择赔偿金额" clearable :disabled="merchantProcessingRad===1&&dataForm.contractStatus===27?false:true">
            <el-option label="100" :value="1"></el-option>
            <el-option label="1000" :value="4"></el-option>
          </el-select>
        </el-radio>
        <el-radio :label="2">
          <span>处罚商户</span>
          <el-select v-model="merchantProcessing1" placeholder="请选择处罚金额" clearable :disabled="merchantProcessingRad===2&&dataForm.contractStatus===27?false:true">
            <el-option label="2000" :value="2"></el-option>
            <el-option label="3000" :value="3"></el-option>
          </el-select>
        </el-radio>
      </el-radio-group>
    </template>


  </div>
  <div class="footer" v-if="stateType||dataForm.contractStatus===27&&ccResDis||changeState==1">
      <div class="btn">
        <template v-if="dataForm.contractStatus===27&&ccResDis">
          <el-button type="primary" @click="updateContractMerchant" >确定</el-button>
        </template>
        <template v-if="stateType||changeState==1">
        <el-button type="primary" @click="updateMerchant" v-if="stateType==1||stateType==2||changeState==1">保存</el-button>
        <el-button type="primary" @click="saveContract('签订失败')" v-if="stateType==1||stateType==2">签订失败</el-button>
        <el-button type="primary" @click="saveContract('发送合同')" v-if="stateType==1||stateType==2">发送合同</el-button>
        <!-- <el-button type="primary" v-print="'#printMe'" v-if="stateType==3">打印合同</el-button> -->
        <!-- <el-button type="primary" @click="saveContract('作废合同')" v-if="stateType==2||stateType==3">作废合同</el-button> -->
        <el-button type="primary" v-if="stateType" @click="dialogVisibleSES=true">发送短信</el-button>
        </template>
      </div>
    </div>

        <el-dialog
    title="签订失败"
    :visible.sync="dialogVisible"
    width="500px"
    class="customDialog">
    <el-form :model="modalForm" ref="modalForm" :rules="rulesModal" size="medium" >
      <el-form-item label="责任方:" prop="responsibleParty">
        <el-select v-model="modalForm.responsibleParty" placeholder="请选择责任方">
          <el-option label="客户责任" :value="51"></el-option>
          <el-option label="商户责任" :value="52"></el-option>
          <el-option label="检测差异" :value="53"></el-option>
          <el-option label="车开新责任" :value="54"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否需要赔偿商户" v-show="[51,53,54].includes(modalForm.responsibleParty)" prop="isComp">
        <el-radio-group v-model="modalForm.isComp"  size="small">
          <el-radio-button :label="1">需要</el-radio-button>
          <el-radio-button :label="0">不需要</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="车开新赔偿商户金额:" v-show="[51,53,54].includes(modalForm.responsibleParty)&&modalForm.isComp==1">
        <p>100元</p>
      </el-form-item>
      <el-form-item label="违约类型" prop="breakType" v-show="modalForm.responsibleParty===52">
        <el-radio-group v-model="modalForm.breakType"  size="small" @input="getCompMoneyMerchant">
          <el-radio-button :label="13">预约确认违约</el-radio-button>
          <!-- <el-radio-button :label="14">成交差异违约</el-radio-button> -->
          <!-- <el-radio-button label="43">成交确认违约</el-radio-button> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商户赔偿车开新:" v-show="modalForm.responsibleParty===52">
        <p>{{compensateMoney}}</p>
      </el-form-item>
      <!-- <el-form-item label="失败原因" prop="failReason">
        <el-radio-group v-model="modalForm.failReason"  size="small">
          <el-radio-button label="41">预约确认违约</el-radio-button>
          <el-radio-button label="42">成交差异违约</el-radio-button>
          <el-radio-button label="43">成交确认违约</el-radio-button>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="备注:">
        <el-input type="textarea" v-model="modalForm.remark" class="w300"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisibleSave">确 定</el-button>
    </span>
  </el-dialog>


    <el-dialog
    title="发送短信"
    :visible.sync="dialogVisibleSES"
    width="600px"
    class="customDialog">
      <div class="sesConter">
        <p class="sesTilte">短信内容:</p>
        <el-row>
          <el-col :span="12"><p>车牌号：{{form.carNo}}</p></el-col>
          <el-col :span="12"><p>车型：{{form.makerName}}&nbsp;{{form.seriesName}}</p></el-col>
        </el-row>
        <p>需支付{{payForm.totalPayAmount}}元<span v-if="form.tradeType===22">，垫资车辆请于今日支付车款</span></p>
        <p>明细：</p>
        <el-row>
          <el-col :span="12"><p>车款：{{form.mcTradePrice}}元</p></el-col>
          <el-col :span="12"><p>服务费{{payForm.feeTechnoAmount}}元</p></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"><p>保证金 {{payForm.depositAmount}}元</p></el-col>
          <el-col :span="12"><p>库存周转费：{{payForm.feeTurnoverAmount}}元</p></el-col>
        </el-row>
        <p>户名：上海铠寻机动车经纪有限公司</p>
        <p>开户行：上海银行股份有限公司张江支行</p>
        <p>账号：0300 3578746</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSES = false">取消</el-button>
        <el-button type="primary" @click="onSES">确认</el-button>
      </span>
    </el-dialog>

    <!--查看图片-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
  </div>
</template>

<script>
import print from 'vue-print-nb'

import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  directives: {
    print   
  },
  components: { ElImageViewer },
  data() {
    return {
      stateType:0,//入口状态/**state=0签订失败（全部隐藏）,state=1待签订,state=2签订中,state=3签订成功**/
      merchantFailedType:true,//商户合同失败商户处理
      payForm:{},
      covenants:'',//其他约定事项（客户）
      covenantsMater:'',//其他约定事项（商户)
      form:{
        carCustomer:{},
        carMerchant:{}
      },
      dataForm:{
        car:{},
        certType:'',
        merchantValid:{},
        certCompanyId:'',
        certType:'',
        carLogistType:'',
      },//所有数据
      contractId:'',//合同id
      carMaterialsList:[],//车辆材料列表
      idcardlist:[],//身份证列表
      carlistPro:[],//车辆手续列表
      recheckForm:{},//复检结果表单
      channelForm:{},//4s店信息
      checkedList2:[],//过户凭证特殊要求（选中）
      companylist:[],//办证公司
      dialogVisible:false,
      dialogVisibleSES:false,
      modalForm:{},
      rules:{
        carLogistType: [{ required: true, message: '请选择车辆物流方式', trigger: 'change' }],
        certType:[{ required: true, message: '请选择办证方式',trigger: 'change'}],
        'certCompanyId':[{ required: true, message: '请选择办证公司',trigger: 'change' }],
      },
      rulesModal:{
        responsibleParty: [{ required: true, message: '请选择责任方', trigger: 'change' }],
        breakType: [{ required: true, message: '请选择', trigger: 'change' }],
        isComp:[{ required: true, message: '请选择', trigger: 'change' }],
      },
      disabledAll:false,//一键禁用（针对详情）
      compensateMoney:'',//商户赔偿车开新
      limitFlag:false,//额度变更单默认不展示
      merchantProcessing:'',//选择商户处理
      merchantProcessingRad:'',
      merchantProcessing1:'',
      ccReasonRsp:{},
      ccResDis:true,//默认展示底部处理商户的提交按钮
      merchantType:false,//【true 必须是商户自理，false 自理和代办都可】
      changeState:'',//是否变更办证类型，0否1是
      res:{},
      showViewer:false,
      srcList:[],
    }
  },
  created(){
    if(this.$route.query.changeState) this.changeState=Number(this.$route.query.changeState);
    this.contractId=this.$route.query.id;
    this.getMerchantDetail();//获取详情
    this.postSupplierList();//获取办证公司列表
  },
  methods: {
    closeViewer() {
      this.showViewer = false
    },
    onOperate(state){
      if(!this.res.insurancePolicyUrl){
        this.$message.warning('当前数据暂无电子保单！')
        return
      }
      switch(state){
        case 1://查看
          this.srcList=[this.res.insurancePolicyUrl]
          this.showViewer=true;
          break;
        case 2://下载
          window.open(this.res.insurancePolicyUrl,'_blank')
          break;
      }
    },
    inputMerchantSelf(e){
      this.dataForm.merchantSelfDesc=e;
    },
    onSES(){
      this.$api.postMerchantSendMsg(this.contractId).then(res=>{
        if(res.code==0){
          this.dialogVisibleSES=true;
          this.$message.success(res.message)
        }
      })
    },
    onAutooper(state){//自动计算操作
      switch(state){
        case '价格':
          this.getMerchantPay()//自动计算委托出售价格 客户合同中的押金与费用以及应支付的车款
          break;
      }
    },
    selectChange($event,state){
      switch(state){
        case '物流':
          this.dataForm.carLogistType=$event;
          break;
        case '办证':
          this.dataForm.certType=$event;
      }
    },
    postMerchantAlterCertType(){//修改办证类型
      if(!this.form.merchantContract){
        this.$message.warning('当前数据没有存在商户合同！')
        return
      }
      let query={
        mcId:this.form.merchantContract.id,
        newCertType:this.dataForm.certType
      }
      if(this.dataForm.certType===12){//办证方式：11商户自理，12代办公司
        query.certCompanyId=this.dataForm.certCompanyId;//办证公司
        query.merchantSelfDesc=null;
      }else if(this.dataForm.certType===11){
        query.merchantSelfDesc=this.dataForm.merchantSelfDesc;
        query.certCompanyId=null;
      }
      this.$api.postMerchantAlterCertType(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getMerchantDetail()
        }
      })
    },
    updateContractMerchant(){//客户合同签订失败商户处理
      if(this.merchantProcessingRad===''){
        this.$message.warning('请选择商户处理！')
        return
      }
      let merchantProcessing='';
      switch(this.merchantProcessingRad){
        case 0:
          merchantProcessing=0;
          break;
        case 1:
          merchantProcessing=this.merchantProcessing;
          if(this.merchantProcessing===''){
            this.$message.warning('请选择赔偿金额！')
            return
          }
          break;
        case 2:
          merchantProcessing=this.merchantProcessing1;
          if(this.merchantProcessing1===''){
            this.$message.warning('请选择处罚金额！')
            return
          }
          break;
      }
      let query={
        "contractNo": this.dataForm.contractNo,
        "merchantProcessing":merchantProcessing,
        "merchantId": this.dataForm.merchantId
      }
      this.$api.updateContractMerchant(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          if(res.data===true){
            this.ccResDis=false;
          }
          setTimeout(()=>{
            this.getMerchantDetail()
          },1000)
        }
      })
    },
    updateMerchant(){
      if(this.changeState===1){
        this.$refs.dataForm.validate(valid => {
          if (valid) {
            this.postMerchantAlterCertType()
          }else{
            this.$nextTick(() => {
              let isError = document.getElementsByClassName("is-error");
              isError[0].scrollIntoView({
                // 滚动到指定节点
                // 值有start,center,end，nearest
                block: "center",
                // 值有auto、instant,smooth，缓动动画
                behavior: "smooth",
              });
            });
          }
        });
        return
      }
      //校验
      let validType=false;
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          validType=true;
        }else{
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      });
      if(!validType){
        return
      }
      let newArr=[];//合同复选列表
      let newType=false;
      for(let i=0;i<this.contractRequireList.length;i++){
        let item=this.contractRequireList[i];
        if(item.requireType==13){
          if(item.contractType===2){
            item.requireName=this.covenantsMater;
            newType=true;
          }
          
        }
        newArr.push(item)
      }
      if(!newType){
        let obj={
          requireName:this.covenantsMater,
          requireDesc:'其他约定事项（商户）',
          "requireType": 13,//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
          isSelfDefined:2
        }
        newArr.push(obj)
      }
      // console.log(newArr,'=====newArr====',newType)
      let query={
        carAuctionId:this.dataForm.carAuctionId,
        // carId:this.dataForm.carId,
        merchantCarForm:{
          // carNo:this.form.carNo,
          brandId:this.form.brandId,//车辆品牌ID
          brandName:this.form.brandName,//车辆品牌名称
          modelId:this.form.modelId,//车辆车型ID
          modelName:this.form.modelName,//车辆车型名称
        },
        merchantDeadline:this.dataForm.merchantDeadline,//约定办证完成时间
        certCompanyId:this.dataForm.certCompanyId,//办证公司
        carLogistType:this.dataForm.carLogistType,//车辆物流方式
        certType:this.dataForm.certType,//办证方式
        contractRequireFormList:newArr,
        merchantSelfDesc:this.dataForm.merchantSelfDesc,
      }
      if(this.dataForm.certType===12){//办证方式：11商户自理，12代办公司
        query.certCompanyId=this.dataForm.certCompanyId;//办证公司
        query.merchantSelfDesc=null;
      }else if(this.dataForm.certType===11){
        query.merchantSelfDesc=this.dataForm.merchantSelfDesc;
        query.certCompanyId=null;
      }
      if(this.dataForm.merchantSelfDesc===undefined){
        if(!this.dataForm.other){
          this.$message.warning('请填写其他原因！')
          return
        }
        query.merchantSelfDesc=this.dataForm.other;
      }
      
      this.$api.updateMerchant(this.contractId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.getMerchantDetail()//获取详情
          }
        }
      })
    },
    getMerchantDetail(){//获取详情
      this.accountList=[]//银行账户列表
      this.carMaterialsList=[];//车辆材料列表
      this.idcardlist=[];//身份证列表
      this.carlistPro=[];//车辆手续列表
      this.contractRequireList=[];//合同列表（所有复选框）
      this.radiolist=[];
      this.checkList= [];
      this.checkedList=[];//以选择的列表
      this.checkList2= [];//过户凭证特殊要求列表
      this.checkedList2=[];//以选择的列表
      this.customChecklist=[{},{},{}]//自定义复选框和输入框
      this.$api.getMerchantDetail(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            //是否展示商户处理的页面
            this.merchantFailedType=res.data.contractStatus===27||res.data.contractStatus===24?false:true;
            //商户合同状态，21待签订，22签订中，23签订成功，24签订失败，25待付款，26已付款
            /**state=0签订失败,state=1待签订,state=2签订中,state=3签订成功**/
            if(this.$route.query.state==0){
              this.stateType=0;
              this.disabledAll=true;
            }else{
              switch(res.data.contractStatus){
                case 21:
                  this.stateType=1;//1待签订
                  break;
                case 22:
                  this.stateType=2;//2签订中
                  break;
                case 23:
                  this.stateType=3;//3签订成功
                  break;
                  default:
                  this.stateType=0;
                  this.disabledAll=true;
              }
            } 
            this.res=JSON.parse(JSON.stringify(res.data));//用来做数据判断
            this.dataForm=JSON.parse(JSON.stringify(res.data));
            if(['非帮办','改装车'].includes(this.dataForm.merchantSelfDesc)===false){
              this.dataForm.other=this.dataForm.merchantSelfDesc;//自理原因单选输入框赋值
            }

            if(!this.dataForm.merchantValid) this.dataForm.merchantValid={}
            this.form=JSON.parse(JSON.stringify(res.data.car));
            if(res.data.car) this.form.carMerchant=res.data.car.carMerchant?res.data.car.carMerchant:{}
            this.recheckForm=res.data.merchantValid?res.data.merchantValid:{}///复检结果表单
            this.channelForm=this.form.channel?this.form.channel:{}//4s店表单信息
            this.ccReasonRsp=res.data.customerContractFailureReasonRsp?res.data.customerContractFailureReasonRsp:{};//商户处理的客户详情
            this.ccReasonRsp.indemnifyRsp=this.ccReasonRsp.indemnifyRsp?this.ccReasonRsp.indemnifyRsp:{};
              
            if(!this.form.carCustomer){//客户信息（车主信息）
              this.form.carCustomer={}
            }

            if(!this.changeState&&!res.data.certType){
              this.getCertSelfValid(this.form.id)
            }
            

            // this.merchantProcessingRad=res.data.merchantProcessingType;//商户处理
            switch(res.data.merchantProcessingType){
              case 1:
              case 4:
                if(res.data.merchantProcessingMoney==100){
                  this.merchantProcessing=1;
                }else if(res.data.merchantProcessingMoney==1000){
                  this.merchantProcessing=4;
                }else{
                  this.merchantProcessing=res.data.merchantProcessingMoney;
                }
                this.merchantProcessingRad=1;//商户处理
                break;
              case 2:
              case 3:
                if(res.data.merchantProcessingMoney==2000){
                  this.merchantProcessing1=2;
                }else if(res.data.merchantProcessingMoney==3000){
                  this.merchantProcessing1=3;
                }else{
                  this.merchantProcessing1=res.data.merchantProcessingMoney;
                }
                this.merchantProcessingRad=2;//商户处理
                break;
                default:
                this.merchantProcessingRad=res.data.merchantProcessingType;//商户处理
            }


            if(this.form.channel){
              if(this.form.channel.accountList.length>0){//渠道4s店银行信息
                this.bankChannelForm=this.form.channel.accountList[0];
              }
            }
            //车主证件列表
            for(let i=0;i<this.form.customerMaterialsList.length;i++){
              let item=this.form.customerMaterialsList[i]
              if(item.realData){
                if(item.realData.indexOf(',')!=-1){
                  let a=item.realData.split(',')
                  item.realData1=a[0];
                  item.realData2=a[1];
                  item.realData3=a[2];
                }else{
                  item.realData1=item.realData;
                }
              }else{
                item.realData1='';
                item.realData2='';
                item.realData3='';
              }
              if(item.remark){
                if(item.remark.indexOf('|')!=-1){
                  let a=item.remark.split('|')
                  if(a[0]){
                    item.remark1=a[0]=='true'?true:'';
                  }
                  if(a[1]){
                    if(a[1].indexOf(',')!=-1){
                      item.remark2=[a[1].split(',')[0],a[1].split(',')[1]];
                    }
                  }
                  if(a[2]){
                    if(a[2].indexOf('{')!=-1){
                      item.remark3=a[2]=='true'?true:'';
                    }else{
                    item.remark3=a[2];
                    }
                  }
                  item.remark4=a[3];
                }
              }
              this.idcardlist.push(item)//身份证列表
            }

            //车辆材料列表
            let cardatalist=this.form.otherMaterialsList;
            let TankerArr=[];//油车
            let energyArr=[];//电车
            let nullobj=new Object;
            let isSelectedCheck=false;
            for(let i=0;i<cardatalist.length;i++){
              let item=cardatalist[i];
              item.options1='';
              item.options2='';
              item.options3='';
              item.options4='';
             
              if(cardatalist[i]){
                nullobj=item;
                
                switch(cardatalist[i].type){
                  case 'car':
                    if(item.pdaData=='无'&&item.materialName.indexOf('车辆牌照')!=-1){
                      // item.feeBearerId=2;
                      // item.feeBearerName='商户';
                    }else if(item.pdaData=='无'&&['桩充','线充','备胎'].indexOf(item.materialName)!=-1){
                      
                      // item.feeBearerId=1;
                      // item.feeBearerName='客户';
                    }else if(item.materialName.indexOf('保险单')!=-1){
                      item.options1=item.options?item.options.split(','):[]
                    }else if(item.materialName.indexOf('钥匙')!=-1){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                      }
                    }else if(['机动车登记证书','机动车行驶证'].includes(item.materialName)){
                     if(item.options){
                       if(['缺'].indexOf(item.realData)!=-1){
                         item.options4=item.options=='补办'?true:'';
                       }else if(['暂缺'].indexOf(item.realData)!=-1){
                         item.options5=item.options;
                       }else if(['有'].indexOf(item.realData)!=-1){
                         if(['机动车行驶证'].includes(item.materialName)){
                          item.options1=item.options.split(',')[0]
                          item.options3=item.options.split(',')[1]
                         }else{
                          item.options1=item.options.split(',')[0]
                          item.options2=item.options.split(',')[1]
                          item.options3=item.options.split(',')[2]
                         }
                        
                       }else{
                        item.options1='';
                        item.options2='';
                        item.options3='';
                        item.options4='';
                        item.options5='';
                       }
                      }
                    }else if(item.materialName=='违章'){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                        item.options4=item.options.split(',')[3]
                        item.options5=item.options.split(',')[4]
                        // item.options6=item.options.split(',')[5]?Boolean(item.options.split(',')[5]):item.options.split(',')[5]
                      }
                    }else if(item.materialName.indexOf('变更审核单')!=-1){
                      if(['有','暂缺'].includes(item.realData)){
                        this.limitFlag=true;
                      }else{
                        this.limitFlag=false;
                      }
                    }
                    /**-- 需要询问4S点费用，只有“沪电动车牌”会体现这个字段 
                    *--isHuPlate是否沪牌，1是,2否，默认1,isNewEnergy是否新能源车，81否，82是,默认81**/
                    // if(this.form.isHuPlate==1&&this.form.isNewEnergy!=82&&['桩充','线充'].indexOf(cardatalist[i].materialName)==-1){
                    //   TankerArr.push(cardatalist[i])//油车
                    // }
                    if(this.form.isNewEnergy!=82&&['桩充','线充'].indexOf(cardatalist[i].materialName)==-1){
                      TankerArr.push(cardatalist[i])//油车
                    }
                    energyArr.push(cardatalist[i])//电车
                    break;
                  case 'procedure'://手续
                    if(cardatalist[i].materialName.indexOf('父母')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=0;
                    }else if(cardatalist[i].materialName.indexOf('夫妻')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=1;
                    }else if(cardatalist[i].materialName.indexOf('抬头')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=2;
                    }else if(cardatalist[i].materialName.indexOf('合并')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=3;
                    }
                    item.realData=item.realData?'是':item.realData
                    if(cardatalist[i].materialName.indexOf('额度变更')!=-1){
                      if(cardatalist[i].isSelected==1){
                        this.carlistPro.push(cardatalist[i])//车辆手续
                        isSelectedCheck=true;
                      }
                    }else if(cardatalist[i].materialName.indexOf('额度变更')==-1){
                        this.carlistPro.push(cardatalist[i])//车辆手续
                    }
                    break; 
                }
              }
            }
            if(this.form.isNewEnergy==82){
              this.carMaterialsList=energyArr;
            }else{
              this.carMaterialsList=TankerArr;//油车
            }
            for(let i=0;i<this.carMaterialsList.length;i++){
              let item=this.carMaterialsList[i]
              
               this.$set(this.carMaterialsList,i,item)
            }
            //合同要求列表(2个复选列表)
            let contractRequireList=res.data.contractRequireList;
            this.contractRequireList=res.data.contractRequireList;
            for(let i=0;i<contractRequireList.length;i++){
              let item=contractRequireList[i]
              //要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
              switch(item.requireType){
                case 11:
                  if(item.isSelected==1){
                    this.checkedList2.push(item)//选中
                  }
                  break;
                case 13:
                  if(item.contractType===2){
                    this.covenantsMater=item.requireName;//商户
                  }else if(item.contractType===1){
                    this.covenants=item.requireName;//客户
                  }
                  break;
              }
            }
            // console.log(this.checkedList2,'----this----')
            //清空对象值
            let newObj=JSON.parse(JSON.stringify(nullobj))
            Object.keys(newObj).forEach(key => newObj[key] = '')
            if(isSelectedCheck==false){
              newObj.materialName='额度变更';
              this.carlistPro.push(newObj)
            }
            //获取自动计算的价格
            this.payForm={
              totalPayAmountCn:this.dataForm.totalPayAmountCn,
              totalPayAmount:this.dataForm.totalPayAmount,
              carTradedPriceCn:this.dataForm.carTradedPriceCn,
              carTradedPrice:this.dataForm.carTradedPrice,
              feeTechnoAmountCn:this.dataForm.feeTechnoAmountCn,
              feeTechnoAmount:this.dataForm.feeTechnoAmount,
              depositAmount:this.dataForm.depositAmount,
              feeTurnoverAmount:this.dataForm.feeTurnoverAmount,
              merchantInsure:this.dataForm.merchantInsure,
              insureAmount:this.dataForm.insureAmount,
              insureAmountCn:this.dataForm.insureAmountCn,
            }
            this.tradedCarCertDate=this.dataForm.tradedCarCertDate;
            this.$nextTick(() => {
              this.$refs["dataForm"].clearValidate();
            });

            // setTimeout(() => {
            //   this.deepType=true;//触发监听
            // }, 3000);
          }
        }
      })
    },
    getMerchantPay(){//自动计算委托出售价格 客户合同中的押金与费用以及应支付的车款
      this.$api.getMerchantPay(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            this.payForm=res.data;
          }
        }
      })
    },
    dialogVisibleSave(){
      if(!this.modalForm.responsibleParty){
       this.$refs.modalForm.validateField("responsibleParty", err => {
          if (!err) {
            this.postMerchantSign()
          }
        });
      }else if(this.modalForm.responsibleParty==52){
        this.$refs.modalForm.validateField("breakType", err => {
          if (!err) {
          this.postMerchantSign()
          }
        });
      }else{
        this.$refs.modalForm.validateField("isComp", err => {
          if (!err) {
            this.postMerchantSign()
          }
        });
      }
    },
    saveContract(state){//操作合同按钮
      switch(state){
        case '签订失败':
          this.dialogVisible=!this.dialogVisible;
          break;
        case '作废合同':
          this.$confirm('你确认要作废当前合同吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
             this.deleteMerchant()
          }).catch(()=>{})
          break;
        case '发送合同':
          if(this.dataForm.isCcPrinted===22||this.dataForm.isChannelCar==72){
            this.$confirm('您确认要发送电子合同给车商吗？', '发送合同', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.postMerchantSend()
            }).catch(()=>{})
          }else{
            this.$message.warning('当前客户合同未打印，请先打印客户合同后再发送商户合同！')
          }
          
          break;
      }
    },
    onRouterRecheck(state){
      if(state==1){//远程代看及评定结果
        this.$router.push({
          path:'/salesAssistant/handleDif',
          query:{
            carValidId:this.dataForm.merchantValid.id,
            state:1,
            title:'远程查验结果及评定结果'
          }
        })
      }else{//垫资复检及评定结果（客）
        this.$router.push({
          path:'/storeManager/difEvaluationDetail',
          query:{
            carValidId:this.dataForm.dzValid.id,
            title:'垫资车复检及评定结果'
          }
        })
      }
      //?carValidId=17&state=3&title=商户差异评定
    },
    postMerchantSign(){//待签订商户合同签订失败
      let query={   
        "responsibleParty": this.modalForm.responsibleParty,//责任方: 51客户责任，52商户责任，53检测差异，54车开新责任
        carId:this.dataForm.carId,
        carAuctionId:this.dataForm.carAuctionId,
        "merchantId": this.dataForm.merchantId,//商户ID
        "remark": this.modalForm.remark,
      }
      
      if(this.modalForm.responsibleParty===52){
        query.breakType= this.modalForm.breakType;//违约类型: 13预约确认违约，14成交差异违约
      }
      if(this.modalForm.responsibleParty!==52&&this.modalForm.responsibleParty){
        query.isComp=this.modalForm.isComp
      }
      this.$api.postMerchantSign(this.contractId,this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.routePage()
          }
        }
      })
    },
    getCompMoneyMerchant(){//查询商户违约需赔偿车开新金额
      this.$api.getCompMoneyMerchant(this.contractId,this.modalForm.breakType).then(res=>{
        if(res.code==0){
          this.compensateMoney=res.data;
        }
      })
    },
    deleteMerchant(){//删除/作废 商户合同
      this.$api.deleteMerchant(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            this.routePage()
          }
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/salesAssistant/signContract'
      })
    },
    postSupplierList(){//获取办证公司列表
      this.$api.postSupplierList({}).then(res=>{
        if(res.code==0){
          this.companylist=res.data;
        }
      })
    },
    postMerchantSend(){//发送合同
      this.$api.postMerchantSend(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            // this.routePage();
            this.$message({
              type:"success",
              message:res.message
            })
            this.getMerchantDetail();//获取详情
          }
        }
      })
    },
    onRouter(){//跳到检测报告
      let pathData=this.$router.resolve({
        path: '/testReviewer/auditReport', 
        query:{
          id:this.form.checkReportId,
          state:3////2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
        }
      })
      window.open(pathData.href,'_blank')
      // this.$router.push({
      //   path:'/testReviewer/auditReport',
      //   query:{
      //     id:this.form.checkReportId,
      //     state:3////2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
      //   }
      // })
    },
    getCertSelfValid(carId){//根据车ID查询是否必须是商户自理【true 必须是商户自理，false 自理和代办都可】
      this.$api.getCertSelfValid(carId).then(res=>{
        if(res.code==0&&res.data===true){
          this.merchantType=true;
          this.dataForm.certType=11;
          // this.dataForm.merchantSelfDesc='自理名单';
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.sesConter{
  padding-bottom:10px;
  p{
    line-height:28px;
  }
  .sesTilte{
    font-weight:bold;
  }
}
.merchantContract{
  padding: 20px 40px 140px;
  h1{font-weight: bold;}
  .mr40{margin-right: 40px;}
  .formItemWidth{width: 220px;}
  table{
    border-collapse: collapse;
    width: 100%;
    td{
      border: 1px solid #ccc;
      padding: 10px 20px;
      text-align: center;
    }
  }
  .carInfoTable td:nth-child(2n+1){background-color: #f5f5f5;}
  ::v-deep .lineInput{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
  }
  ::v-deep .lineInput .el-input__inner{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
  }
  ::v-deep .lineInput .el-input__inner:disabled{
    background-color: #ffffff;
    color: #333333;
    text-align: center;
  }
  ::v-deep .inputNum .el-input__inner{
    padding-right:0
  }
  .inputNum{width: 80px;}
  .inputDate{width: 150px;}
  .inputSelect{width: 150px;}
  .carGoods{margin-top: 20px;margin-bottom: 20px;}
  .alignLeft{text-align: left;}
  .conterBg{
    background-color:#f5f5f5;
    padding:10px;
  }
  .lineMin{
    min-width:100px;
    border-bottom:1px solid;
    display:inline-block;
    text-align:center;
  }
}
</style>
