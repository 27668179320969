<template>
  <div class="pageContainer">
    <el-row :gutter="20">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w4">
        <span>意向金类型</span>
        <el-select v-model="forms.state" size="medium" clearable>
          <el-option label="请选择" value="" ></el-option>
          <el-option v-for="item in paymentStatuslist" :key="item.value" :label="item.label" :value="item.value" ></el-option>
        </el-select>
      </el-col>
       <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>收款状态</span>
        <el-select v-model="forms.statePay" size="medium" clearable>
          <el-option
            v-for="item in paymentlist"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w5">
        <span>收款完成时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-col>
      
      <el-col :span="3" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="orderNo" label="收款单号" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="dealSignTime" label="协议签订时间" align="center"></el-table-column>
      <el-table-column prop="intentionMoney" label="待收金额" align="center"></el-table-column>
      <el-table-column prop="payTypeName" label="意向金类型" align="center"> </el-table-column>
      <el-table-column prop="financeGatheringStatusName" label="收款状态" align="center"> </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onSee(scope.row)" size="mini">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      forms:{
        state:'',
        statePay:''
      },
      paymentStatuslist:[{label:'收客户返还的意向金',value:3},{label:'收客户违约金',value:4}],
      paymentlist:[{label:'待收款',value:91},{label:'收款中',value:92},{label:'收款成功',value:93},{label:'收款失败',value:94}],//收款状态
      total: 0,
      currentPage: 1,
      pageSize: 10
    };
  },
  created() {
    if(this.$route.params.state){
      this.forms.statePay=Number(this.$route.params.state);
    }
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        financeGatheringBeginTime:this.forms.date?this.forms.date[0]+' 00:00:00':'' ,
        financeGatheringEndTime:this.forms.date?this.forms.date[1]+' 23:59:59':'',
        payTypes:this.forms.state?[this.forms.state]:[3,4],
        financeGatheringStatuses:this.forms.statePay?[this.forms.statePay]:''
      };
      this.$api.postIntentionMoneyPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init()
    },
    formatter(row){
      if(row.payType==3&&row.financeGatheringStatus==91){
        return '待收返回意向金'
      }else if(row.payType==4&&row.financeGatheringStatus==91){
        return '待收客户违约赔偿'
      }else if(row.payType==3&&row.financeGatheringStatus==93){
        return '已收返回意向金'
      }else if(row.payType==4&&row.financeGatheringStatus==91){
        return '已收客户违约赔偿'
      }
    },
    onSee(row){
      this.$router.push({
        path:'/finance/intentionCollectionDetails',
        query:{
          id:row.id
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
}
</style>