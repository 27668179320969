<template>
  <!-- 经营省份城市区域3级联动 -->
  <el-cascader ref="address" :options="addressOptions" :props="props" :value="address" @change="handleChange" class="addressWidth" placeholder="省/市/区" clearable></el-cascader>
</template>

<script>
export default {
  props: {
    address: {
      type: Array
    }
  },
  data() {
    return {
      addressOptions: [],
      props: {
        value: 'id',
        label: 'name'
      }
    }
  },
  
  created() {
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.areaList.length==0){
      this.$datadic.getProvince(0).then(res=>{
        this.addressOptions=res;
      })
    }else{
      this.addressOptions=this.$store.state.setting.areaList;
    }
  },
  methods: {
    handleChange(value) {
      let row=this.$refs.address.getCheckedNodes()
      let name=row[0].pathLabels;
      this.$emit('changeAddress', value,name);
    },
  },
}
</script>

<style scoped>
.addressWidth{
  min-width: 370px;
}
</style>