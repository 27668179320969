<template>
  <!-- 外勤检测预约 -->
  <div class="fieldDR">
    <div class="flex flex_align_center flex_between">
      <div class="ckxTitle mt20 mb20">预约车辆</div>
    </div>
    <el-table :data="carInfoList" style="width: 100%" border>
      <el-table-column align="center" prop="customerNo" label="客户编号"></el-table-column>
      <el-table-column align="center" prop="carType" label="品牌车型"></el-table-column>
      <el-table-column align="center" prop="carNo" label="车牌号码"></el-table-column>
      <el-table-column align="center" prop="channel" label="渠道来源"></el-table-column>
      <el-table-column align="center" prop="isMortgage" label="是否抵押车" :formatter="formatIsMortgage"></el-table-column>
    </el-table>
    <div class="ckxTitle mt20 mb20">预约需求</div>
    <div>
      <span class="label">检测日期：</span>
      <span>{{form.date}}</span>
    </div>
    <div class="mt20">
      <span class="label">外环外：</span>
      <span>{{form.isOuter==1?'是':form.isOuter==0?'否':form.isOuter}}</span>
    </div>
    <div class="mt20">
      <span class="label">检测地址：</span>
      <span>{{form.address}}</span>
    </div>
    
    <div class="mt20">
      <span class="label">检测类型：</span>
      <el-radio-group v-model="form.checkType" size="medium">
        <el-radio  v-for="item in checkTypeList" :key="item.type" :label="item.type" >{{item.name}}</el-radio>
      </el-radio-group>
    </div>
    <div class="flex mt20">
      <span class="label">备注：</span>
      <el-input v-model="form.remark" type="textarea" :autosize="{minRows: 4}"></el-input>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onReturn" >返回</el-button>
        <el-button type="primary" @click="submitBook" v-if="!checkFlag" >提交预约单</el-button>
        <el-button type="primary" @click="visible=true" v-if="checkFlag">取消预约单</el-button>
      </div>
    </div>

    <!-- 取消预约 -->
    <el-dialog title="取消预约" center :visible.sync="visible" class="customDialog">
      <el-form ref="modelForm" :rules="rules" :model="modelForm" size="medium" label-width="100px">
        <el-form-item label="取消原因:" prop="cancelReason">
          <el-select v-model="modelForm.cancelReason">
            <el-option v-for="item in cannellist" :key="item.id" :label="item.reasonName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="modelForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitModel">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{
        date:'',
        isOuter:'',
        address:'',
        checkType:'',
        remark:''
      },
      rules: {
          cancelReason: [{ required: true, message: '请选择取消原因', trigger: 'change' },]
      },
      modelForm:{},
      carInfoList: [],
      address: [],
      visible: false,
      externalShopId: '',
      nextDayTimePeriod: [new Date(2022, 3, 1, 9, 0, 0),new Date(2022, 3, 1, 18, 0, 0)],
      shopList: [],
      checkTypeList:[],//检测类型
      customerId:'',//客户id
      checkBookId:"",//检测预约id
      cannellist:[],//取消原因
      checkFlag:false,//默认展示提交预约，true为取消预约
    }
  },
  created(){
    if(this.$route.query.customerId) this.customerId=this.$route.query.customerId;
    this.customerQueryCustomerById()//根据客户ID查询客户详情
    if(this.$route.query.checkBookId){
      this.checkBookId=this.$route.query.checkBookId;
      this.getQueryCheckBook();//获取检测预约详情
      this.getCheckBookCannelReason()//取消原因
    }else{
      this.getCheckResourceGetFieldPlan();
    }
    this.getCheckBookCheckType()//获取检测类型集合
  },
  methods: {
    // 获取检测预约详情
    getQueryCheckBook() {
      this.$api.getQueryCheckBook(this.checkBookId).then(res => {
        if(res.code == 0){
          this.form={
              date:res.data.bookTime,
              isOuter:res.data.isOuter,
              address:res.data.bookAddress,
              checkType:res.data.checkType,
              remark:res.data.remark
          }
          if(res.data.checkBookStatus==2) this.getCheckResourceGetFieldPlan();
          if(res.data.checkBookStatus==1) this.checkFlag=true;
            
        }
      })
    },
    getCheckResourceGetFieldPlan(){//根据客户Id获取未取消的五方格勤虚拟任务
      this.$api.getCheckResourceGetFieldPlan(this.customerId).then(res=>{
        if(res.code==0){
          this.form={
              date:`${res.data.date} ${res.data.planTime?res.data.planTime:''}`,
              isOuter:res.data.isOuter,
              address:res.data.checkAddress,
              checkType:'',
              remark:res.data.remark,
              planId:res.data.id,//外勤五方格任务Id
            }
        }
      })
    },
    customerQueryCustomerById(){//根据客户ID查询客户详情
      this.$api.customerQueryCustomerById(this.customerId).then(res=>{
        if(res.code==0){
          this.carInfoList = [{
            customerNo: res.data.customerNo,
            carType: res.data.carType,
            carNo: res.data.carNo,
            channel: res.data.channel,
            isMortgage: res.data.isMortgage,
            id: res.data.id
          }]
        }
      })
    },
    formatIsMortgage(row) {
      if(row.isMortgage === -1){
        return '未识别'
      }else if(row.isMortgage === 0){
        return '不是'
      }else if(row.isMortgage === 1){
        return '是'
      }
    },
    submitBook() {
      let params = {
        "customerId": this.customerId,
        "planId": this.form.planId,
        "checkType": this.form.checkType,
        "remark": this.form.remark
      }
      this.$api.checkBookSubmit(this.$$.delete_null_properties(params)).then(res => {
        if(res.code == 0){
          this.$message.success(res.message)
          setTimeout(()=>{
            this.onReturn()
            // this.bus.$emit("closeSelected",'router');
            // this.bus.$emit("closeSelected");
            // this.$router.go(-1);
          },2000)
        }
      })
    },
    onReturn() {
      this.bus.$emit("closeSelected");
      this.$router.go(-1);
    },
    onSubmitModel(){//取消任务
     this.$refs['modelForm'].validate((valid) => {
        if (valid) {
          let query={
            "checkBookId": this.checkBookId,
            "cancelReason": this.modelForm.cancelReason,
            "remark": this.modelForm.remark
          }
          this.$api.updateCheckBookCancel(query).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
              this.visible=false;
              this.onReturn()
              // this.bus.$emit("closeSelected");
            }
          })
        }
     })
    },
    getCheckBookCheckType(){//获取检测类型集合
      this.$api.getCheckBookCheckType({}).then(res=>{
        if(res.code==0){
          this.checkTypeList=res.data;
        }
      })
    },
    getCheckBookCannelReason(){//获取取消原因集合
      let query={type:'取消'}
      this.$api.getCheckBookCannelReason(query).then(res=>{
        if(res.code==0){
          this.cannellist=res.data;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.fieldDR{
  padding: 20px 40px 120px;
  table{
    border-collapse: collapse;
    width: 100%;
    td{
      border: 1px solid #ccc;
      padding: 10px 20px;
      text-align: center;
    }
  }
  .label{
    display: inline-block;
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
  .dialogLabel{display: inline-block;width: 100px; text-align: right;margin-right: 10px;}
  .capacity{
    width: 450px;
    li{
      width: 150px;
      text-align: center;
      height: 50px;
      line-height: 50px;
      border: 1px solid #ccc;
    }
  }
  .bookAddress{
    display: inline-block;
    width: 200px;
  }
}
</style>