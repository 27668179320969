<template>
  <div class="sendSMS">
    <div class="sendLeft">
      <el-tabs v-model="activeName" v-if="!!followUpInfoId" type="card" @tab-click="changeTabs">
        <el-tab-pane label="当前用户" name="first" class="mt20"></el-tab-pane>
        <el-tab-pane label="其他用户" name="second" class="mt20"></el-tab-pane>
      </el-tabs>
      <div class="flex" v-if="!!followUpInfoId && activeName==='first'">
        <span class="label">客户名称：</span>
        <span style="margin-right: 10%;">{{customerName}}</span>
        <span>车牌号：</span>
        <span>{{carNo}}</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="105px">
        <el-form-item label="接收号码：" prop="tel" v-if="activeName==='first'">
          <el-checkbox-group v-model="ruleForm.tel">
            <el-checkbox :label="1">主要联系电话</el-checkbox>
            <el-checkbox :label="2">次要联系电话</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="接收号码：" prop="batchSmsPhone" v-if="activeName==='second'">
          <el-input v-model="ruleForm.batchSmsPhone"></el-input>
        </el-form-item>
        <el-form-item label="短信内容：" prop="content">
          <el-input type="textarea" v-model="ruleForm.content" :rows="4" class="textBox"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex flex_center">
        <el-button type="primary" size="mini" plain @click="sendSMS">发送</el-button>
      </div>
    </div>
    <div class="sendRight">
      <div class="flex flex_align_center">
        <span class="label">分类</span>
        <el-cascader v-model="type" class="type" :options="typeOptions" @change="smsQuerySmsTemplate"></el-cascader>
      </div>
      <el-table :data="tableData" class="typeTable" border highlight-current-row @current-change="handleCurrentChange">
        <el-table-column type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="smsContent" label="内容"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sendSMS',
  data() {
    return {
      customerName: '',
      carNo: '',
      ruleForm: {
        tel: [],
        content: '',
        batchSmsPhone: ''
      },
      rules: {
        tel: { type: 'array',required: true, message: '请至少选择一个', trigger: 'change' },
        content: [{ required: true, message: '请填写发送内容', trigger: 'blur' }],
        batchSmsPhone: [{ required: true, message: '请输入手机号', trigger: 'blur' }]
      },
      type: '',
      typeOptions: [],
      tableData: [],
      followUpInfoId: '',
      activeName: 'second',
      customerNo: '',
      res:{},
    }
  },
  created() {
    this.initQuery()
  },
  methods: {
    initQuery() {
      // this.followUpInfoId=this.getFollowUpInfoId()
      this.followUpInfoId=this.$route.query.followUpInfoId;
      if(this.followUpInfoId){
        this.activeName = 'first';
        this.customerQueryCustomerByFollowUpId(this.followUpInfoId)
      }else{
        this.smsQueryTitle()
      }
    },
    getFollowUpInfoId(){//获取id
      let visitedViews=this.$store.state.tagsView.visitedViews;
      let arr=[]
      for(let i=0;i<visitedViews.length;i++){
        let item=visitedViews[i];
        if(item.path.indexOf("/customer")!=-1){
          arr.push(item.query.followUpInfoId)
        }
      }
      if(arr.length>0){
        return arr[arr.length-1]
      }
    },

    // 客户详情查询
    customerQueryCustomerByFollowUpId(followUpInfoId) {
      this.$api.customerQueryCustomerByFollowUpId(followUpInfoId).then(res => {
        if(res.code == 0){
          this.res=res.data;
          this.customerName = res.data.customerName;
          this.customerNo = res.data.customerNo;
          this.carNo = res.data.carNo;
          if(res.data.phase>1){//状态 不传-全部 0-预约前 1-预约后
            this.smsQueryTitle(1)
          }else{
            this.smsQueryTitle(0)
          }
        }
      })
    },

    //短信模板查询条件下拉框
    smsQueryTitle(params) {
      this.$datadic.getSmsQueryTitle(params).then(res => {
        this.typeOptions = res;
      }).catch(msg => {
        this.$message.error(msg)
      })
    },

    //根据条件查询短信模板
    smsQuerySmsTemplate(val) {
      this.$api.smsQuerySmsTemplate({
        pSmsType: val[0],
        cSmsType: val[1],
      }).then(res => {
        if(res.code == 0){
          this.tableData = res.data;
          this.ruleForm.content=res.data?res.data[0].smsContent:'';
        }
      })
    },

    changeTabs() {
      if(this.$refs.ruleForm){
        this.$refs['ruleForm'].resetFields();
      }
    },
    handleCurrentChange(row){
      this.ruleForm.content = row.smsContent;
    },
    sendSMS() {
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          if(this.activeName === 'first'){
            let params = {
              customerId:this.res.id,
              content: this.ruleForm.content,
              // customerNo: this.customerNo,
              mainPhone: this.ruleForm.tel.indexOf(1) > -1?true:false,
              otherPhone: this.ruleForm.tel.indexOf(2) > -1?true:false,
            }
            this.$api.smsSendCustomerSms(this.res.id,params).then(res => {
              if(res.code == 0){
                this.$message.success(res.message)
              }
            })
          }else if(this.activeName === 'second') {
            let query={
              phone: this.ruleForm.batchSmsPhone,
              content: this.ruleForm.content
            }
            this.$api.smsSendSms(query).then(res => {
              if(res.code == 0){
                this.$message.success(res.message)
              }
            })
          }
        }else{
          return false;
        }
      })
    },
  }
}
</script>
<style>
.textBox .el-textarea__inner{
  height:150px;
}
</style>
<style scoped lang="scss">
.sendSMS{
  margin: 40px 50px 100px;
  display: flex;
  justify-content: space-between;
  .sendLeft{
    width: 35%;
    &>div{
      margin-bottom: 20px;
      font-size: 16px;
      .label{
        width: 81px;
        text-align: right;
      }
    }
    .receiveM{
      display: flex;
      align-items: center;
    }
  }
  .sendRight{
    width: 55%;
    .label{
      margin-right: 10px;
    }
    .type{
      width: 400px;
    }
    .typeTable{
      width: 100%;
      margin-top: 20px;
      ::v-deep .el-table__body tr:hover>td {
        cursor: pointer;
      }
    }
  }
}
</style>
