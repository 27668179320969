<template>
  <div class="procedures">
    <div class="ckxTitle mb10">基本信息</div>
    <el-row v-if="carInfo" class="mb20">
      <el-col :span="5">车牌号：{{carInfo.carNo}}</el-col>
      <el-col :span="5">办证类型：{{carInfo.certTypeName}}</el-col>
      <el-col :span="5">品牌车型：{{carInfo.brandName}}&nbsp;&nbsp;{{carInfo.modelName}}</el-col>
      <el-col :span="5">产证编号：{{carInfo.certNo}}</el-col>
      <el-col :span="4">车辆所在库：{{carInfo.stockObjInName}}</el-col>
    </el-row>
    <!-- <div v-if="carInfo" class="mb20"><span class="mr30">车牌号：{{carInfo.carNo}}</span><span class="mr30">办证类型：{{carInfo.certTypeName}}</span><span class="mr30">品牌车型：{{carInfo.brandName+'-'+carInfo.modelName}}</span><span class="mr30">产证编号：{{carInfo.certNo}}</span><span class="mr30">车辆所在库：{{carInfo.carWarehouseName}}</span></div> -->
    <div class="flex flex_align_center mb10">
      <div class="ckxTitle mr30">办证信息-客户方</div><span class="color ml30 pointer" @click="onRouter">客户合同查看>></span>
    </div>
    <el-row v-if="carInfo">
      <el-col :span="5">客户姓名：{{carInfo.customerName}}</el-col>
      <el-col :span="5">
        <span class="mr20">客户电话：{{carInfo.customerMobile}}</span>
        <el-button class="mr30" type="primary" plain size="mini" v-if="carInfo.customerMobile" @click="callPhone(carInfo.customerMobile,1)">呼叫</el-button>
      </el-col>
      <el-col :span="5">客户办证截止日期：{{customerContract.customerDeadline}}</el-col>
      <el-col :span="5">成交门店：{{customerContract.tradeShopName}}</el-col>
    </el-row>
    <el-row v-if="carInfo" class="mt10">
      <el-col :span="5">帮卖经理：{{customerContract.sellerName}}</el-col>
      <el-col :span="5">
        <span class="mr20">客户电话：{{carInfo.carCustomer.customerPhone}}</span>
        <el-button class="mr30" type="primary" plain size="mini" v-if="carInfo.carCustomer.customerPhone" @click="callPhone(carInfo.carCustomer.customerPhone,2)">呼叫</el-button>
      </el-col>
      <el-col :span="5">身份证号：{{carInfo.carCustomer.customerIdNo}}</el-col>
      <el-col :span="5">客户合同签订日期：{{customerContract.signTime}}</el-col>
    </el-row>
    <!-- <div v-if="carInfo">
      <span class="mr30">客户姓名：{{carInfo.customerName}}</span><span class="mr10">客户电话：{{carInfo.customerMobile}}</span><el-button class="mr30" type="primary" plain size="mini">呼叫</el-button><span class="mr30">客户办证截止日期：{{carInfo.customerDeadline}}</span><span class="mr30">成交门店：{{carInfo.tradeShopName}}</span>
    </div> -->
    <!-- <div v-if="carInfo" class="mt10 mb20">
      <span class="mr30">帮卖经理：{{carInfo.sellerName}}</span><span class="mr10">客户电话：{{carInfo.customerMobile}}</span><el-button class="mr30" type="primary" plain size="mini">呼叫</el-button><span class="mr30">身份证号：{{carInfo.customerIdNo}}</span><span class="mr30">客户合同签订日期：{{carInfo.customerContractDate}}</span>
    </div> -->
    <div class="ckxTitle mb10 mt15">身份证件跟进</div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick('customer')">
      <el-tab-pane label="待跟进" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="跟进完成" name="second" class="mt20"></el-tab-pane>
      <el-table :data="idcardlist1" border>
        <el-table-column type="index" align="center"></el-table-column>
        <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
        <el-table-column prop="materialStatus" label="合同状态" align="center"></el-table-column>
        <el-table-column prop="repairSendTime" label="补充信息" align="center"></el-table-column>
        <el-table-column label="" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="uploadImg(scope.row, scope.$index, 1)" v-if="scope.row.materialName.indexOf('身份证')!=-1&&activeName==='first'||scope.row.materialName.indexOf('居住证')!=-1&&activeName==='first'" size="mini">上传附件</el-button>
            <el-button type="primary" @click="uploadImg(scope.row, scope.$index, 1)" v-if="scope.row.materialName.indexOf('身份证')!=-1&&activeName==='second'||scope.row.materialName.indexOf('居住证')!=-1&&activeName==='second'" size="mini">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="activeName==='first'">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="complete(scope.row)">完成</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="followTime" label="跟进完成时间" align="center" v-if="activeName==='second'"></el-table-column>
      </el-table>
    </el-tabs>
    <div class="ckxTitle mb10 mt20">车辆材料手续跟进</div>
    <el-tabs v-model="activeName2" type="card" @tab-click="handleClick('car')">
      <el-tab-pane label="待跟进" name="third" class="mt20"></el-tab-pane>
      <el-tab-pane label="跟进完成" name="fourth" class="mt20"></el-tab-pane>
      <el-table :data="carMaterialsList2" border>
        <el-table-column type="index" align="center"></el-table-column>
        <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
        <el-table-column prop="materialStatus" label="合同状态" align="center">
          <!-- <template slot-scope="scope">
            <div v-if="scope.row.materialName.indexOf('钥匙')!=-1">{{scope.row.realData?`${scope.row.realData}把`:scope.row.realData}}</div>
            <div v-else>{{scope.row.realData}}</div>
          </template> -->
        </el-table-column>
        <el-table-column prop="repairSendTime" label="补充信息" align="center">
          <!-- <template slot-scope="scope">
            <div v-if="scope.row.materialName==='违章'">{{scope.row.options1?`${scope.row.options1}前的`:scope.row.options1}}{{scope.row.options2?`${scope.row.options2}次，`:scope.row.options2}}{{scope.row.options3?`${scope.row.options3}处理完毕，`:scope.row.options3}}{{scope.row.options4?`违章本金${scope.row.options4}元，`:scope.row.options4}}{{scope.row.options5?`驾照扣分${scope.row.options5}分`:scope.row.options5}}</div>
            <div v-else>{{scope.row.options}}</div>
          </template> -->
        </el-table-column>
        <el-table-column label="" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="uploadImg(scope.row, scope.$index, 1)" v-if="['机动车登记证书','机动车行驶证'].includes(scope.row.materialName)&&activeName2==='third'&&scope.row.screenshotList.length===0">上传附件</el-button>
            <el-button type="primary" size="mini" @click="uploadImg(scope.row, scope.$index, 1)" v-if="['机动车登记证书','机动车行驶证'].includes(scope.row.materialName)&&activeName2==='fourth'||activeName2==='third'&&scope.row.screenshotList.length>0">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="activeName2==='third'">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="complete(scope.row)">完成</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="followTime" label="跟进完成时间" align="center" v-if="activeName2==='fourth'"></el-table-column>
      </el-table>
    </el-tabs>
    <div class="ckxTitle mb10 mt20">跟进结果</div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="跟进结果:">
        <el-radio-group v-model="ruleForm.followResult">
          <el-radio-button label="next">继续跟进</el-radio-button>
          <el-radio-button label="finish">完成</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="下次跟进时间: " prop="name" v-if="ruleForm.followResult=='next'">
        <el-date-picker class="w300" type="datetime" placeholder="选择日期" v-model="ruleForm.date1" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
      </el-form-item>
      <el-form-item label="结果描述: " >
        <el-input class="w300" type="textarea" v-model="ruleForm.desc" :autosize="{minRows: 4}"></el-input>
      </el-form-item>
    </el-form>
    <div class="pb100">
      <div class="ckxTitle mb10 mt20">材料跟进记录</div>
      <el-table :data="carFollowFlowList" border>
        <el-table-column type="index" align="center"></el-table-column>
        <el-table-column prop="operateTime" label="操作时间" align="center"></el-table-column>
        <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
        <el-table-column prop="followTime" label="下次跟进时间" align="center"></el-table-column>
        <el-table-column prop="followResult" label="跟进结果" align="center"></el-table-column>
        <el-table-column prop="followRemark" label="跟进结果描述" align="center"></el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >保存</el-button>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <el-upload v-if="activeName2==='third'&&this.itemRow.type==='car'||activeName==='first'&&this.itemRow.type==='customer'"
      class="tableUpload"
      action 
      accept="image/png, image/gif, image/jpeg,image/jpg"
      list-type="picture-card"
      :on-change="requestUpload"
      :file-list="handleFileList(fileList)"
      :auto-upload="false" >
        <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
        <div slot="file" slot-scope="{file}">
          <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
        </div>
      </el-upload>
      <!-- <div class="imgboxCon">
        <div v-if="fileList.length==0" class="mt10">暂无图片</div>
        <template v-if="fileList.length>0">
          <div v-for="(item,index) in fileList" :key="index" class="imgboxRow" >
            <el-image  fit="cover"
              style="width: 100px; height: 100px"
              :src="item.fullFilePath" alt="" 
              :preview-src-list="[item.fullFilePath]">
              <div slot="error">暂无图片</div>
            </el-image>
          </div>
        </template>
      </div> -->
    </el-dialog>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="dialogVisibleImg">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      carInfo: {
        carCustomer:{},
      },
      idcardlist: [],
      idcardlist1:[],
      carMaterialsList: [],
      carMaterialsList2: [],
      activeName: 'first',
      activeName2: 'third',
      fileList: [],//
      itemRow:{},
      dialogVisible: false,
      ruleForm: {
        date1: '',
        desc: '',
      },
      rules: {
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        desc: [{required: true, message: '请填写活动形式', trigger: 'blur'}]
      },
      carFollowFlowList: [],
      carId:'',
      userData:{},
      customerContract:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
      dialogVisibleImg:false,
      dialogImageUrl:'',
    }
  },
  created() {
    //软电话是否登陆
    // this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    if(this.$route.query.id) this.carId=this.$route.query.id;

    this.carFollowDetail();

  },
  methods: {
    carFollowDetail() {
      let query={
        businessId:this.carId,
        businessType: 'materials'
      }
      this.$api.carFollowDetail(this.carId,query).then(res => {
        if(res.code === 0){
          this.carInfo = res.data;
          if(!res.data.carCustomer){
            this.carInfo.carCustomer={};
          }
          if(res.data.customerContract) this.customerContract=res.data.customerContract;

          //车辆材料列表/身份证列表
          let carArr=[];
          let idCarArr=[];
          let cardatalist=res.data.carMaterialsList;
          for(let i=0;i<cardatalist.length;i++){
              let item=cardatalist[i];
              item.options1='';
              item.options2='';
              item.options3='';
              item.options4='';
            
              if(item){
                switch(item.type){
                  case 'customer':
                    if(item.realData){
                      if(item.realData.indexOf(',')!=-1){
                        let a=item.realData.split(',')
                        item.realData1=a[0];
                        item.realData2=a[1];
                        item.realData3=a[2];
                      }else{
                        item.realData1=item.realData;
                      }
                    }else{
                      item.realData1='';
                      item.realData2='';
                      item.realData3='';
                    }
                    if(item.remark){
                      if(item.remark.indexOf('|')!=-1&&item.materialName=='身份证'){
                        let a=item.remark.split('|')
                        if(a[0]){
                          item.remark1=JSON.parse(a[0]);
                        }
                        if(a[1]){
                          if(a[1].indexOf(',')!=-1){
                            item.remark2=[a[1].split(',')[0],a[1].split(',')[1]];
                          }
                        }
                        if(a[2]){
                          if(a[2].indexOf('{')!=-1){
                            item.remark3=JSON.parse(a[2]);
                          }else{
                          item.remark3=a[2];
                          }
                        }
                        item.remark4=a[3];
                      }
                    }
                    idCarArr.push(item)//身份证列表
                    break;
                  case 'car':
                    if(item.pdaData=='无'&&item.materialName.indexOf('车辆牌照')!=-1){
                      item.feeBearerId=2;
                      item.feeBearerName='商户';
                    }else if(item.pdaData=='无'&&['桩充','线充','备胎'].indexOf(item.materialName)!=-1){
                      item.feeBearerId=1;
                      item.feeBearerName='客户';
                    }else if(item.materialName.indexOf('保险单')!=-1){
                      item.options1=item.options?item.options.split(','):[]
                    }else if(item.materialName.indexOf('钥匙')!=-1||item.materialName.indexOf('登记证书')!=-1){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                      }
                    }else if(item.materialName.indexOf('行驶证')!=-1){
                     if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                      }
                    }else if(item.materialName=='违章'){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                        item.options4=item.options.split(',')[3]
                        item.options5=item.options.split(',')[4]
                        item.options6=item.options.split(',')[5]?Boolean(item.options.split(',')[5]):item.options.split(',')[5]
                      }
                    }
                    carArr.push(item)
                    break;
                }
              }
            }

          this.carFollowFlowList=res.data.carFollowFlowList;
          this.idcardlist=idCarArr;
          this.carMaterialsList=carArr;
          this.handleClick('car')
          this.handleClick('customer')
        }
      })
    },
    handleClick(type) {
      if(type === 'customer'){
        if(this.activeName === 'first'){
          this.idcardlist1 = this.idcardlist.filter(item => {
            item.screenshotList=item.screenshotList?item.screenshotList:[]
            return item.type === type && item.followStatus === 1;
          })
        }else if(this.activeName === 'second') {
          this.idcardlist1 = this.idcardlist.filter(item => {
            item.screenshotList=item.screenshotList?item.screenshotList:[]
            return item.type === type && item.followStatus === 3;
          })
        }
      }else if(type === 'car') {
        if(this.activeName2 === 'third'){
          this.carMaterialsList2 = this.carMaterialsList.filter(item => {
            item.screenshotList=item.screenshotList?item.screenshotList:[]
            return item.type === type && item.followStatus === 1;
          })
        }else if(this.activeName2 === 'fourth') {
          this.carMaterialsList2 = this.carMaterialsList.filter(item => {
            item.screenshotList=item.screenshotList?item.screenshotList:[]
            return item.type === type && item.followStatus === 3;
          })
        }
      }
    },
    complete(row) {
      this.$confirm(`您确认已完成${row.materialName}的跟进工作？`, '跟进完成', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          materialsIds:row.id
        }
        this.$api.postMaterialsFinish(row.carId,query).then(res=>{
          if(res.code==0){
            this.$message.success(res.message)
            this.carFollowDetail();
          }
        })
      }).catch(() => {});
    },
    uploadImg(row, index, num) {
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.fileList=row.screenshotList?row.screenshotList:[];
      this.$nextTick(()=>{
        if(num===1){//除了查看还有上传
          this.dialogVisible = true;
        }else{
          this.dialogVisible = true;
        }
      })
    },
    //将fullFilePath换成url
    handleFileList(arr) {
      if(arr && arr.length){
        arr.forEach(item => {
          item.url = item.fullFilePath;
        })
      }else{
        arr = [];
      }
      return arr
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    handleRemove(file) {
      let query={
        businessId:this.itemRow.id,
        businessType:'materials',
        fileId:file.id,
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          this.carFollowDetail(); //获取详情
          this.dialogVisible=false;
          this.$message.success(res.message)
        }
      })
    },
    requestUpload(file) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.itemRow.id);
      obj.append("businessName", this.itemRow.materialName);
      obj.append("businessType", "materials");//材料
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.carFollowDetail();//获取详情
          this.dialogVisible=false;
          this.$message.success(res.message)
        }
      });
    },
    doSubmit() {
      let query={
        "carId": this.carId,
        "followTime": this.ruleForm.date1,
        "followResult": this.ruleForm.followResult,
        "followRemark": this.ruleForm.desc,
        // "operator": "丁三",
        // "operateTime": "2021-01-02 12:12:12"
      }
      this.$api.postFlowCarCreate(query).then(res=>{
        if(res.code==0){
          this.carFollowDetail();//获取详情
          this.$message.success(res.message)
        }
      })
    },
    onRouter(){
      if(this.carInfo.customerContract){
        this.$router.push({
          path:'/salesManager/contractMaking',
          query:{
            id:this.carInfo.customerContract?this.carInfo.customerContract.id:'',
          }
        })
      }else{
        this.$message('当前材料未存在合同！')
      }
    },
    callPhone(row,phoneType) {
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row,phoneType)
      // })//根据员工工号查询坐席信息(拨打电话)
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber,phoneType){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      if(phoneType){
        query.phoneType=phoneType;
        query.customerId=this.carInfo.carCustomer.id;
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.procedures{
  padding: 20px 40px 100px;
  ::v-deep .el-upload-list--picture .el-upload-list__item{
    display: flex;
    align-items: center;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item-thumbnail{height: auto;margin-right: 10px;}
  ::v-deep .el-dialog__body{max-height: 523px; overflow-y: auto;}
  .imgboxCon{
    display:flex;
    .imgboxRow{
      text-align:center;
      margin:10px;
    }
  }
}
</style>
