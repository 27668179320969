<template>
  <div class="inventBoxBG">
    <div class="inventBox" id="print">
      <!--车辆信息-->
      <div class="content_modal_textBox">
        <div class="flex flex_between">
          <div class="ckxTitle mb10">车辆信息</div>
          <div>合同编号：{{form.customerContract.contractNo}}</div>
        </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>车辆号牌</td>
              <td>{{form.carNo}}</td>
              <td>厂牌型号</td>
              <td>{{form.carBrandModelName}}</td>
              <td>发动机号</td>
              <td>{{form.engineNo}}</td>
              <td>颜色</td>
              <td>{{form.carColor}}</td>
            </tr>
            <tr>
              <td>初次登记日期</td>
              <td>{{form.registerDate}}</td>
              <td>年检有效期</td>
              <td>{{form.checkExpire}}</td>
              <td>车架号</td>
              <td class="wordWrap">{{form.vinCode}}</td>
              <td>成交类型</td>
              <td>{{form.customerContract?form.customerContract.tradeTypeName:form.tradeTypeName}}</td>
            </tr>
            <tr>
              <td>成交价（元）</td>
              <td>{{form.ccTradePrice}}</td>
              <td>报价有效期</td>
              <td>{{form.tradedPriceExpire}}</td>
              <td>预约成交时间</td>
              <td>{{form.appointTradeTime}}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
       <!--车主信息-->
      <div class="content_modal_textBox mt30">
        <div class="ckxTitle mb10">车主信息</div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>车主姓名</td>
              <td>{{form.customerName}}</td>
              <td>客户电话</td>
              <td class="wordWrap">{{form.customerMobile}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>收款账号户名</td>
              <td>{{form.accountName}}</td>
              <td>开户行</td>
              <td>{{form.depositBank}}</td>
              <td>开户行简称</td>
              <td>{{form.depositBankShortName}}</td>
              <td>账号</td>
              <td class="wordWrap">{{form.account}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--付款信息-->
      <div class="content_modal_textBox mt30 pb100">
        <div class="ckxTitle mb10">付款信息</div>
        <div class="fontBox">
          <p>乙方支付的二手车款人民币（大写）：<span class="text_decoration">{{form.customerContract.totalPayAmountCn}}</span>（￥{{form.customerContract.totalPayAmount}}）。其中车款（￥{{form.customerContract.totalPayAmount}}），补贴车价：（￥明细如下：）</p>
          <p>1、该车委托出售价格为人民币（大写）<span class="text_decoration">{{form.customerContract.carTradedPriceCn}}</span>（ ￥ {{form.customerContract.carTradedPrice}}）（不带牌）。</p>
          <p>2、如该车发生补缴、违章、遗失补证、随车附件缺少等业务且甲方委托乙方办理的，则甲方支付乙方办证押金人民币（大写）<span class="text_decoration">{{form.customerContract.feeDepositTotalAmountCn}}</span>（￥{{form.customerContract.feeDepositTotalAmount}}）。</p>
          <p>3、甲方支付乙方办证风险押金人民币（大写）<span class="text_decoration">{{form.customerContract.riskDepositTotalAmountCn}}</span>（￥{{form.customerContract.riskDepositTotalAmount}}）。</p>
          <p v-if="form.customerContract.channelSubsidyAmount">4、该车的补贴车款为人民币（大写）<span class="text_decoration">{{form.customerContract.channelSubsidyAmountCn}}</span>（￥{{form.customerContract.channelSubsidyAmount}}）。</p>
          <p v-if="form.customerContract.intentionMoneyAmount">{{form.customerContract.channelSubsidyAmount?5:4}}、乙方已向甲方支付协议意向金人民币（大写）<span class="text_decoration">{{form.customerContract.intentionMoneyAmountCn}}</span>（￥{{form.customerContract.intentionMoneyAmount}}）。</p>
          <p>客成经理：{{form.sellerName}}</p>
          <template v-if="[31,34].includes(paymentState)">
            <p class="mt15">付款方式：
            <el-select v-model="payTypeState" size="medium" class="w300">
              <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </p>
          <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
            <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
          </template>
          <template v-if="[33].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
          <p class="tipsColor mt15" v-if="[31,34].includes(paymentState)">当前余额：{{form.availableBalance}}</p>
          <!-- <p class="mt15"  v-if="paymentState==33">付款时间：{{res.financePayTime}}</p> -->
          <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31||paymentState==34">{{paymentState==34?'重新付款':'开始付款'}}</el-button>
        <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32">付款完成</el-button>
        <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32">付款失败</el-button>
        <el-button type="primary" v-print="'#print'" v-show="paymentState!=33">打印</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import print from 'vue-print-nb'
export default {
  directives: {
    print
  },
  data() {
    return {
      payTypeState:1,//付款方式
      form: {
        carCustomer:{},
        customerContract:{},//客户合同表
      },
      carCustomerAccount:{},//客户银行账号列表
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      paymentState:31,//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
      customerCarTradePayId:'',//客户成交/退车辆付款ID
    };
  },
  created() {
    this.customerCarTradePayId=this.$route.query.id;
    this.getDetailByCustomerCarTradePayId();
  },
  methods: {
    getDetailByCustomerCarTradePayId(){//获取详情
      this.$api.getDetailByCustomerCarTradePayId(this.customerCarTradePayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.financePayStatus;
            if(this.form.carCustomer){
              this.carCustomerAccount=this.form.carCustomer.carCustomerAccount?this.form.carCustomer.carCustomerAccount:{};
            }else{
              this.form.carCustomer={};
            }
          }
        }
      })
    },
    onPayment(state){
      switch(state){
        case 1:
          if(!this.payTypeState){
            this.$message('请选择付款方式！')
            return
          }else{
            if(!this.form.payType&&this.payTypeState!=5){
              this.$message('请选择付款方式！')
              return
            }
          }
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postFinancePay();//付款
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postFinanceConfirmPaySuccess()//成功
          }).catch(() => {});
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postFinanceConfirmPayFail()//失败
          }).catch(() => {});
          break;
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/customerCarPayment'
      })
    },
    postFinancePay(){//财务付款
      let query={
        "customerCarTradePayId": this.customerCarTradePayId,//客户成交/退车辆付款ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postFinancePay(query).then(res=>{
        if(res.code==0){
          this.getDetailByCustomerCarTradePayId();
          this.$message.success(res.message)
        }
      })
    },
    postFinanceConfirmPaySuccess(){//财务确认付款成功
      this.$api.postFinanceConfirmPaySuccess(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.getDetailByCustomerCarTradePayId();
          this.$message.success(res.message)
        }
      })
    },
    postFinanceConfirmPayFail(){//财务确认付款失败
      this.$api.postFinanceConfirmPayFail(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.getDetailByCustomerCarTradePayId();
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
@import url('../../assets/css/print.css') print;
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .fontBox{
    p{
      margin-bottom:10px
    }
  }
}
</style>