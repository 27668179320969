<template>
  <div class="inventBoxBG">
    <div class="inventBox">
      <!--客户信息-->
      <div class="content_modal_textBox">
        <div class="head_top_title">
          <i></i>
          <span>客户信息</span>
        </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>客户姓名</td>
              <td>{{carCustomer.customerName}}</td>
              <td>车牌</td>
              <td>{{res.carNo}}</td>
              <td>车型</td>
              <td>{{res.carBrandModelName}}</td>
            </tr>
            <tr>
              <!-- <td>客户成交时间</td>
              <td>{{res.signDate}}</td> -->
              <td>合同签定时间</td>
              <td>{{res.signDate}}</td>
              <td>车款</td>
              <td>{{res.carTradedPrice}}</td>
              <td>收款人姓名</td>
              <td>{{carCustomerAccount.accountName}}</td>
            </tr>
            <tr>
              <td>账号</td>
              <td>{{carCustomerAccount.account}}</td>
              <td>开户行</td>
              <td>{{carCustomerAccount.depositBank}}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--费用信息-->
      <div class="content_modal_textBox mt30 pb140">
        <div class="head_top_title">
          <i></i>
          <span>费用信息</span>
        </div>
        <el-table :data="thirdClearFees" border stripe class="table-mainbg"  :default-sort = "{prop: 'orderNo', order: 'ascending'}">
          <el-table-column prop="itemName" label="项目名称" align="center" >
            <template slot-scope="scope">{{scope.row.itemName}}&nbsp;{{scope.row.selfDefineRemark}}</template>
          </el-table-column>
          <el-table-column prop="customerFeeAmount" label="客户" align="center" ></el-table-column>
          <el-table-column prop="merchantFeeAmount" label="商户" align="center" ></el-table-column>
          <el-table-column prop="certFeeAmount" label="办证公司" align="center" ></el-table-column>
          <el-table-column prop="ckxFeeAmount" label="车开新" align="center" ></el-table-column>
          <el-table-column prop="ruleFeeAmount" label="违章供应商" align="center" ></el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onReturn">返回</el-button>
          <!-- <el-button type="primary" @click="onPayment">数据退回</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      thirdClearFees: [],
      res: {},
      carCustomer:{},
      carCustomerAccount:{},//银行信息
    };
  },
  created() {
    this.stockId=this.$route.query.id;
    this.getStockDetail()
  },
  methods: {
    getStockDetail(){//获取详情
      this.$api.getThirdClearDetail(this.stockId).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=res.data;
            if(res.data.carCustomer){
              this.carCustomer=res.data.carCustomer;

              //银行信息
              if(res.data.carCustomer.carCustomerAccount){
                this.carCustomerAccount=res.data.carCustomer.carCustomerAccount;
              }
            } 
            
            this.thirdClearFees=res.data.thirdClearFees;
          }
        }
      })
    },
    onPayment(){},
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/report/balanceAccounts',
        query:{
          state:this.stateType
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>