<template>
  <!-- 商户验车 -->
  <div class="vehicleInspection">
    <el-row :gutter="20" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户名称</span>
        <el-input v-model="params.merchantName"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="params.carNo"></el-input>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w5">
        <span>商户预约时间</span>
        <el-date-picker v-model="params.date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>销售助理</span>
        <el-input v-model="params.sellerAssistantName"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="ccTradePrice" label="成交价" align="center"></el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
      <el-table-column prop="appointTime" label="商户预约时间" align="center">
        <template slot-scope="scope">
            <p :class="new Date(scope.row.appointTime?scope.row.appointTime.split(' ')[0]:scope.row.appointTime).getTime()==new Date($$.getTody()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p>
          <!-- <p :class="new Date(scope.row.appointTime).getTime()<new Date(new Date().toLocaleDateString()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p> -->
        </template>
      </el-table-column>
      <el-table-column prop="isPledgeName" label="抵押车" align="center"></el-table-column>
      <!-- <el-table-column prop="validTypeName" label="查验方式" align="center"></el-table-column> -->
      <el-table-column prop="followUpUserName" label="销售助理" align="center"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onresults(scope.row)" size="mini">结果处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      params: {
        merchantName: '',
        carNo: '',
        date: '',
        status: ''
      },
      sendTicketDialog: false,
      distributionDialog: false,
      inspector: '',
      itemrow:{},
      roleList:[],//检测师列表
      modalform:{},
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page){
      this.page_index=page?page:this.page_index;

      let query={
          recheckType:1,//复检类型 0-垫资复检 1-商户验车 2-商户差异复检
          validStatus:3,//验车状态，1待验车，2验车中，3验车完成，4取消验车
          merchantName:this.params.merchantName,
          carNo:this.params.carNo,
          followUpUserName:this.params.sellerAssistantName,
          appointTimeBegin:this.params.date?`${this.params.date} 00:00:00`:'',
          appointTimeEnd:this.params.date?`${this.params.date} 23:59:59`:'',
          curPage:this.page_index,
          pageSize:this.page_size,
      }
      this.$api.postValidList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist = res.data.records;
            this.page_total= res.data.total;
          }else{
            this.querylist = [];
            this.page_total = 0;
          }
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onresults(row){
      this.$router.push({
        path: '/salesAssistant/handleDif',
        query:{
          carValidId:row.carValidId,
          title:'验车结果'
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.vehicleInspection{
  padding: 20px 40px;
}
</style>