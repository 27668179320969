<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="待结算" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="审核中" name="second" class="mt20"></el-tab-pane>
      <el-tab-pane label="审核驳回" name="third" class="mt20"></el-tab-pane>
      <el-tab-pane label="审核通过" name="fourth" class="mt20"></el-tab-pane>
      <!-- <el-tab-pane label="结算完成" name="fifth" class="mt20"></el-tab-pane> -->
    </el-tabs>

    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户名称:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5" v-show="activeName=='first'">
        <span>办证完成时间:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5" v-show="activeName=='second'">
        <span>提交结算时间:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5" v-show="activeName=='third'">
        <span>审核驳回时间:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5" v-show="activeName=='fourth'">
        <span>审核通过时间:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5" v-show="activeName=='fifth'">
        <span>办证完成时间:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="carNo" align="center" label="车牌号码"></el-table-column>
      <el-table-column prop="modelName" align="center" label="车型">
        <template slot-scope="scope">{{scope.row.brandName}}{{scope.row.modelName}}</template>
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户名称"></el-table-column>
      <el-table-column prop="merchantName" align="center" label="商户名称"></el-table-column>
      <el-table-column prop="merchantDeadline" align="center" label="商户办证截止日期"></el-table-column>
      <el-table-column prop="processFinishDate" align="center" label="办证完成日期"></el-table-column>
      <el-table-column prop="clearSubmitTime" align="center" label="结算提交时间" v-if="activeName=='second'"></el-table-column> 
      <el-table-column prop="checkRejectTime" align="center" label="审核驳回时间" v-if="activeName=='third'"></el-table-column> 
      <el-table-column prop="checkSuccessTime" align="center" label="审核通过时间" v-if="activeName=='fourth'"></el-table-column> 
      <!-- <el-table-column prop="bookShop" align="center" label="结算完成时间" v-if="activeName=='fifth'"></el-table-column>  -->
      <el-table-column prop="clearAmount" align="center" label="结算金额" v-if="['third','fourth','fifth'].includes(activeName)"></el-table-column> 
      <el-table-column prop="taskComClearStatusName" align="center" label="状态"></el-table-column>
      <el-table-column label="操作" align="center" width="120px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="onSee(scope.row)" v-if="activeName=='first'">去结算</el-button>
          <el-button size="mini" type="primary" plain @click="onSee(scope.row)" v-if="['third'].includes(activeName)">重新结算</el-button>
          <el-button size="mini" type="primary" plain @click="onSee(scope.row)" v-if="['second','fourth','fifth'].includes(activeName)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        carNo: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      itemRow:{},
    }
  },
  created() {
    if(this.$route.query.state) this.activeName=this.$route.query.state;
    this.handleClick();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let taskStatus=121;
      switch(this.activeName){//办证公司-结算管理: 121 待结算,122 审核中,123 审核驳回,124 审核通过,125 结算完成
        case 'first':
          taskStatus=121;
          break;
        case 'second':
          taskStatus=122;
          break;
        case 'third':
          taskStatus=123;
          break;
        case 'fourth':
          taskStatus=124;
          break;
        case 'fifth':
          taskStatus=125;
          break;
      }
      let query = {
        curPage: this.page_index, //当前页数
        pageSize: this.page_size,
        taskType: 5,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        merchantName:this.params.merchantName,
        taskComClearStatuses:[taskStatus],
        carNo:this.params.carNo,
        merchantDeadlineBegin:this.params.date?`${this.params.date[0]}`:'',
        merchantDeadlineEnd:this.params.date?`${this.params.date[1]}`:'',
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    onSee(row){
      this.$router.push({
        path: '/certificationCompany/settlementDetails', 
        query: {
          id: row.id,
          // state:state
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.pageContainer{
  padding: 20px 40px;
  .tag{
     position: absolute;
    width: 53px;
    height: 53px;
    top:0;
    right: 0;
    background: #F56C6C;
    clip-path: polygon(0 0, 100% 0, 100% 100%); /*三角*/
    color:#ffffff;
    .fontText{
      width:53px;
      height:53px;
      font-size:12px;
      transform: rotate(45deg);
    }
  }
}
</style>