import $$ from '@/utils'
export default {
  namespaced: true,
	state: {
    pickerOptions: {
      shortcuts: [{
        text: '最近一周',
        onClick(picker) {
          picker.$emit('pick', $$.getTimeRange(7));
        }
      }, {
        text: '最近一个月',
        onClick(picker) {
          picker.$emit('pick', $$.getTimeRange(30));
        }
      }, {
        text: '最近三个月',
        onClick(picker) {
          picker.$emit('pick', $$.getTimeRange(90));
        }
      }]
    },
    system: '定价系统平台',

    tokenCookieExpires: 1,

    TokenKey: 'EL-ADMIN-TOEKN',

    // 是否开启菜单水平折叠效果
    isCollapse: false,
    // 是否开启菜单手风琴效果
    isUniqueOpened: false,

    contractGoods: [
      {label: '有', value: 0},{label: '缺', value: 1},{label: '暂缺', value: 2}
    ],
    
    // 机动车交通事故责任强制保险单
    insurancePolicy: [
      {label: '正本', value: 0},{label: '副本', value: 1},{label: '电子保单', value: 2}
    ],

    // 违章材料状态
    violation: [
      {label: '无', value: 0},{label: '代办', value: 1},{label: '客户自行处理', value: 2}
    ],

    // 预约阶段名称
    formatPhaseName: [
      {label: '默认', value: 0},{label: '预约', value: 1},{label: '检测', value: 2},{label: '审核', value: 3},{label: '竞拍', value: 4},{label: '预约成交', value: 5},{label: '成交', value: 6},{label: '手续办理', value: 7},{label: '结算', value: 8}
    ],

    // 客户类型: 
    customerTypeList: [
      {label: '400帮卖', value: 0},{label: '非经营范围', value: 1},{label: '非卖车咨询', value: 2},{label: '未识别', value: 3}
    ],
    //价格满意度
    priceSatisfaction:[
      {label: '价格不满意', value: 0},{label: '价格满意-需考虑', value: 1},{label: '价格满意-无法交车', value: 2},{label: '价格满意-且有成交意向', value: 3},{label: '价格满意-车不卖', value: 4},{label: '未联系-拒接', value: 5},{label: '未联系-无法接通', value: 6}
    ],

    //记录类型-检测报告上传前
    recordTypeBeforeUpload: [],

    //记录类型-检测报告上传后
    recordTypeAfterUpload: [],

    BIG_TITLE : [
      //检测报告上传后
      [
         ["202", "一拍预约"]
        ,["101", "检测回访"]
        ,["102", "竞拍后"]
        ,["103", "成交预约"]
        ,["104", "外部不成交原因"]
        ,["105", "重拍"]
        
        ,["205", "重拍预约"]
        
        ,["106", "维护"]
        ,["107", "上门未成交"]
        ,["108", "客户投诉"]
        ,["109", "备注"]
        ,["110", "来电"]
        ,["111", "转移数据"]
        ,["112", "放弃"]
        ,["113", "确认检测电话"]
        ,["114", "查档"]
        ,["115", "内部不成交原因"]
        ,["116", "商户投诉"]
         
         
        ,["301", "竞争对手信息"]
      
      ],
      //检测报告上传前
      [
         ["201", "400帮卖跟进客户"]
        ,["202", "一拍预约"]
        ,["203", "检测确认电话"]
        ,["204", "检测取消原因"]
        //,["205", "重拍预约"]
        ,["209", "非经营范围"]
        ,["206", "非卖跟进客户"]
        ,["207", "放弃客户"]
        ,["208", "一检维护"],
        ["210", "检测维护"],
        ["211", "检测跟进"]
        
        //,["106", "维护"]
        ,["101", "检测回访"]
        ,["108", "客户投诉"]
        ,["109", "备注"]
        //,["110", "来电"]
  
        ,["112", "放弃"]
        ,["114", "查档"]
        ,["116", "商户投诉"]
        ,["301", "竞争对手信息"]
        ,["401","未识别"]
      ]
    ],

    SMALL_TITLE : [
      ["10101", "延期竞价"]
      ,["10102", "终止竞价"]
      ,["10103", "核实车辆信息"]
      ,["10104", "已打"]
      ,["10106", "无法联系客户"]
      ,["10105", "其他"]
      
       ,["10201", "成交跟进第一天"]
      ,["10202", "成交跟进第二天"]
      ,["10203", "成交跟进第三天"]
      ,["10204", "流拍重拍"]
      ,["10205", "流拍不拍"]
      ,["10206", "其他"]
      ,["10207", "成交维护第一次"]
      ,["10208", "成交维护第二次"]
      
      ,["10301", "成交预约"]
      ,["10302", "商户回复"]
      ,["10303", "客户回复"]
      ,["10304", "确认成交时间"]
      ,["10305", "成交回访"]
      ,["10306", "其他"]
      
      ,["10401", "车已卖"]
      ,["10402", "车不卖"]
      ,["10403", "车准备卖给其他渠道"]
      ,["10404", "无法交车"]
      ,["10405", "想重拍"]
      //,["10406", "不接受车开新成交条件"]
      ,["10407", "无法联系客户"]
      //,["10499", "其他"] 去掉其它，防止坐席不问客户乱选
      
      ,["10501", "重拍跟进第一天"]
      ,["10502", "重拍跟进第二天"]
      ,["10503", "重拍跟进第三天"]
      ,["10504", "重拍不检"]
      ,["10505", "其他重拍"]
      ,["10506", "其他"]
      
      ,["10601", "有明确下次联系时间"]
      ,["10602", "过段时间再卖"]
      ,["10603", "不接受车开新价格"]
      ,["10604", "不接受车开新成交条件"]
      ,["10605", "无法联系客户"]
      ,["10606", "车不卖"]
      ,["10607", "车已卖"]
      ,["10608", "无明确卖车意向"]
      
      ,["10701", "重拍需检"]
      ,["10702", "重拍不检"]
      ,["10703", "其他"]
      
      ,["10801", "投诉互动"]
      ,["10802", "投诉上海分公司"]
      ,["10803", "投诉深圳分公司"]
      ,["10804", "投诉东莞分公司"]
      ,["10805", "投诉南京分公司"]
      ,["10806", "投诉苏州分公司"]
      ,["10807", "投诉成都分公司"]
      ,["10808", "投诉无锡分公司"]
      ,["10809", "投诉常州分公司"]
      ,["10810", "投诉南通分公司"]
      ,["10899", "投诉其它"]
      
      ,["10901", "备注"]
      ,["10902", "车在外地"]
      ,["10903", "客户跟进"]
      ,["10904", "售前预案"]
      
      ,["11001", "来电"]

      ,["11101", "检测 —> 维护"]
      ,["11102", "维护 —> 检测"]
      
      ,["11201", "重复信息"]
      ,["11202", "新建信息"]
      ,["11203", "非业务咨询"]  
      ,["11301", "无法联系客户"]
      ,["11401", "查档信息"]  
      ,["11501", "产能不足"]
      ,["11502", "营运时间"]
      ,["11503", "检测差异"]
      ,["11504", "办证风险"]
      ,["11505", "办证时间"]
      ,["11506", "服务态度"]
      ,["11507", "交易地点"]
      ,["11508", "付款方式"]
      ,["11509", "工作差错"]
      //,["11599", "其它"]去掉其它，防止坐席不问客户乱选
      ,["11601", "投诉互动"]
      ,["11602", "投诉上海分公司"]
      ,["11603", "投诉深圳分公司"]
      ,["11604", "投诉东莞分公司"]
      ,["11605", "投诉南京分公司"]
      ,["11606", "投诉苏州分公司"]
      ,["11607", "投诉成都分公司"]
      ,["11608", "投诉无锡分公司"]
      ,["11609", "投诉常州分公司"]
      ,["11610", "投诉南通分公司"]
      ,["11699", "投诉其它"]
 //-  --------------------  华丽的分割线  --------------------------/

      ,["20101", "电话报价"]
      ,["20102", "不配合去地铁站"]
      ,["20103", "新车未到"]
      ,["20104", "三天无法交车"]
      ,["20105", "预约检测跟进"]
      ,["20107", "新车未订"]
      ,["20108", "无法联系客户"]
      ,["20109", "无法准确识别"]
      
      ,["20106", "其它"]  
      ,["20201", "上门检测"]
      ,["20202", "门店检测"]
      
      ,["20301", "确认检测时间"]
      //,["20302", "确认重拍时间"]
      
//  		,["20401", "未上门检测取消"]
//  		,["20402", "未上门检测改约"]
//  		,["20403", "上门未检测取消"]
//  		,["20404", "上门未检测改约"]
//  		,["20405", "未到店检测取消"]
//  		,["20406", "未到店检测改约"]
//  		,["20407", "已到店检测取消"]
//  		,["20408", "已到店检测改约"]
       ,["20409", "未到店检测取消"]
       ,["20410", "九天无法交车"]
       ,["20411", "不接受成交条件"]
       ,["20412", "非经营范围"]
      
      ,["20501", "门店重拍"]
      ,["20502", "上门重拍"]
      
      ,["20601", "客户无明确卖车意向"]
      ,["20602", "客户告知不卖"]
      ,["20603", "三月内不能交车"]
      ,["20605", "无法联系客户"]
      ,["20606", "仅需办理车辆手续"]
      ,["20607", "非代办人"]
      ,["20608", "目前不能来本地卖车"]
      ,["20699", "其它"]
      
      //,["20701", "二手车经销商"]
      ,["20702", "公司总机"]
      ,["20703", "买车"]
      ,["20704", "只想办理过户手续"]
      ,["20705", "重复信息"]
      ,["20707", "车已卖"]
      ,["20708", "用户操作错误"]
      ,["20706", "其它"]  
      ,["20801", "有明确下次联系时间"]
      ,["20802", "过段时间再卖"]
      ,["20803", "不接受车开新价格"]
      ,["20804", "不接受车开新成交条件"]
      ,["20805", "无法联系客户"]
      ,["20806", "车不卖"]
      ,["20807", "车已卖"]
      
      ,["20901", "不能正常办理手续"]
      ,["20902", "车辆不能检测"]
      ,["20903", "经销商车辆"]
      ,["20904", "三月内[含]有过户"]
      ,["20905", "抵押贷款车"]			 
      ,["20906", "异地车牌"]
      ,["20907", "无法联系客户"]
      ,["20908", "非经营车型"]
//  		,["20909", "目前不能来本地卖车"]
      ,["20910", "政策问题"]
      ,["20999", "其它"]  
      ,["21001", "有明确下次联系时间"]
      ,["21002", "过段时间再卖"]
      ,["21003", "不接受车开新流程"]
      ,["21004", "不接受车开新成交条件"]
      ,["21005", "车已卖"]
      ,["21006", "车不卖"]
      ,["21007", "无法联系客户"]
      ,["21099", "其它"]
      
      ,["21101", "第一天"]
      ,["21102", "第二天"]
      ,["21103", "第三天"]

      ,["30101", "4S店"]
      ,["30102", "黄牛"]
      ,["30103", "二手车经销商"]
      ,["30104", "修车车行"]
      ,["30105", "网络"]
      ,["30106", "平安好车"]
      ,["30107", "安美途"]
      ,["30108", "其他"]
      ,["40101","无法联系"]
    ],

    //是否黄牛
    isFineList: [{label: '是', value: 1},{label: '否', value: 0}],

    //业务状态
    serviceStateList: [{label: '跟进', value: 0},{label: '成交', value: 1},{label: '放弃', value: 2},{label: '确认放弃', value: 3}],
    //商户提差异（差异类型）
    diffTypeslist:[{id:51,checked: false,title: '外观', content: ""},{id:52,checked: false,title: '内饰', content: ""},{id:53,checked: false,title: '机械', content: ""},{id:54,checked: false,title: '骨架', content: ""},{id:55,checked: false,title: '识别', content: ""},{id:56,checked: false,title: '手续', content: ""}],

    //检测状态
    checkStatusList: [{label: '待分配', value: 0},{label: '待检测', value: 1},{label: '检测中', value: 2},{label: '检测成功', value: 3},{label: '检测失败', value: 4}],
    //检测状态2
    checkStatusList2: [{label: '待检测', value: 1},{label: '检测中', value: 2},{label: '检测完成', value: 3},{label: '检测失败', value: 4}],

    //分配规则分配方式
    assignWayList: [{label: '轮询', value: 1},{label: '比例', value: 2}],

    //询价类型(检测类型)
    checkTypeList1: [{label: '检测询价', value: 11},{label: '重拍重检', value: 12},{label: '重拍不检测(改报告)', value: 13},{label: '重拍不检(不改报告)', value: 14},{label: '入库重拍重检', value: 16},{label: '入库重拍', value: 17},{label: '折扣重拍', value: 20},{label: '其他重拍', value: 15}],

    //商户意愿
    merchantStatusList: [{label: '待确认', value: 0},{label: '确认', value: 1},{label: '放弃', value: 2},{label: '改约', value: 3},{label: '确认后取消（违约）', value: 4},{label: '报价审核', value: 5}],
    //付款状态（退款状态）
    paymentStatuslist:[{label:'待付款',value:31},{label:'付款中',value:32},{label:'付款成功',value:33},{label:'付款失败',value:34}],
    //批量支付状态
    BatchPayStatuslist:[{label:"付款中",value:'32'},{label:"付款成功",value:'33'},{label:"付款失败",value:'34'}],
    //付款方式
    paymentMethodlist:[{label:"上海银行银企直连",value:1},{label:"线下付款",value:5}],
    paymentRadiolist:[{label:"超级网银（低于100万  推荐)",value:1},{label:"同城同行",value:2},{label:"异地",value:3},{label:"同城对公",value:4}],

    //办证要求
    certRequireList:[{cityName:'上海（沪）',areaName:'武宁快城',
    upCustomer:{needPresent:1,notGo:0,notGoCondition:'“二手车流通”APP申报卖方信息',needOriginal:0,needCopies:1,idRemark:'',needPicture:1}}],
    //逾期原因
    beOverduelist:[{id:1,name:'客户原因逾期'},{id:2,name:'商户办证逾期'},{id:3,name:'商户借用证件逾期'},{id:4,name:'办证公司原因逾期'},{id:5,name:'违章供应商原因逾期'},{id:6,name:'车开新原因逾期'}],
    //承担方-受益方
    whichSidelist:['车开新','客户','商户','办证公司'],
    //承担方-受益方（id）
    whichSidelistId:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司'},{id:5,name:'违章供应商'},],
    //车辆评级
    carConditionlist:['事故车','改装车','车况差'],
    //车辆评级结果
    ratingResultslist:[
      {value:'C',label:'车龄在3个月内；≤4000KM，准新车'},
      {value:'A',label:'车龄在3个月至1年内；≤20000KM,在二手车中最好的'},
      {value:'R',label:'车龄2年；≤35000KM，在二手车中较好的'},
      {value:'S',label:'车龄3年；≤正常行驶，为提高舒适需简单修理'},
      {value:'I',label:'车龄4年；正常行驶，为提高舒适需部件更换'},
      {value:'N',label:'车龄5年；行驶基本正常，为保证行驶安全需修理或部件更换'},
      {value:'G',label:'车龄在6年-7年内；曾经大修或翻新的车辆'},
      {value:'O',label:'车龄在8年-10年内；即将大修的车辆'},
      {value:'K',label:'车龄在10年以上；曾经发生重大事故的车辆'},
    ],
    //检测报告详情itemcode对应字段表
    CAR_CODE:[
        {
          "itemCode": "0001",
          "item": "车主姓名"
        },
        {
          "itemCode": "0002",
          "item": "车主电话"
        },
        {
          "itemCode": "0003",
          "item": "车主地址"
        },
        {
          "itemCode": "0004",
          "item": "车主邮箱"
        },
        {
          "itemCode": "0005",
          "item": "联系人姓名"
        },
        {
          "itemCode": "0006",
          "item": "联系人电话"
        },
        {
          "itemCode": "0007",
          "item": "联系人地址"
        },
        {
          "itemCode": "0008",
          "item": "联系人邮箱"
        },
        {
          "itemCode": "0009",
          "item": "车主次要电话"
        },
        {
          "itemCode": "0010",
          "item": "是否高端车"
        },
        {
          "itemCode": "1201",
          "item": "换车宝卡号"
        },
        {
          "itemCode": "1301",
          "item": "心理预期价格"
        },
        {
          "itemCode": "1310",
          "item": "备注"
        },
        {
          "itemCode": "1501",
          "item": "外观评级"
        },
        {
          "itemCode": "1502",
          "item": "内饰评级"
        },
        {
          "itemCode": "1503",
          "item": "骨架评星"
        },
        {
          "itemCode": "1504",
          "item": "装置评星"
        },
        {
          "itemCode": "3101",
          "item": "车牌号码"
        },
        {
          "itemCode": "3102",
          "item": "登记日期"
        },
        {
          "itemCode": "3103",
          "item": "公私性质"
        },
        {
          "itemCode": "3104",
          "item": "营运性质"
        },
        {
          "itemCode": "3105",
          "item": "年检有效期"
        },
        {
          "itemCode": "3106",
          "item": "交强险有效期"
        },
        {
          "itemCode": "3109",
          "item": "车牌所在地"
        },
        {
          "itemCode": "3110",
          "item": "沪牌"
        },
        {
          "itemCode": "3111",
          "item": "车辆手续状态"
        },
        {
          "itemCode": "3113",
          "item": "车辆类型"
        },
        {
          "itemCode": "3114",
          "item": "强制报废年限"
        },
        {
          "itemCode": "3115",
          "item": "排放标准"
        },
        {
          "itemCode": "3116",
          "item": "产证变更记录"
        },
        {
          "itemCode": "3117",
          "item": "产证状态"
        },
        {
          "itemCode": "3118",
          "item": "车辆牌照"
        },
        {
          "itemCode": "3119",
          "item": "车辆产证曾属"
        },
        {
          "itemCode": "3121",
          "item": "行驶证"
        },
        {
          "itemCode": "3123",
          "item": "新增额度"
        },
        {
          "itemCode": "3124",
          "item": "私车车主户籍"
        },
        {
          "itemCode": "3129",
          "item": "沪牌额度变更"
        },
        {
          "itemCode": "3133",
          "item": "办证工作日约定"
        },
        {
          "itemCode": "3134",
          "item": "车辆产证现属"
        },
        {
          "itemCode": "3137",
          "item": "车辆购置税"
        },
        {
          "itemCode": "3139",
          "item": "车辆产证曾属"
        },
        {
          "itemCode": "3140",
          "item": "跨省通办"
        },
        {
          "itemCode": "3201",
          "item": "表显里程"
        },
        {
          "itemCode": "3207",
          "item": "原厂钥匙"
        },
        {
          "itemCode": "3209",
          "item": "后配钥匙"
        },
        {
          "itemCode": "3210",
          "item": "备胎"
        },
        {
          "itemCode": "3218",
          "item": "竞价日期"
        },
        {
          "itemCode": "3301",
          "item": "VIN码"
        },
        {
          "itemCode": "3302",
          "item": "生产厂家"
        },
        {
          "itemCode": "3303",
          "item": "车型系列"
        },
        {
          "itemCode": "3304",
          "item": "生产年代"
        },
        {
          "itemCode": "3305",
          "item": "详细车型"
        },
        {
          "itemCode": "3306",
          "item": "外观颜色"
        },
        {
          "itemCode": "3307",
          "item": "标准配置"
        },
        {
          "itemCode": "3309",
          "item": "最终车型代码"
        },
        {
          "itemCode": "3310",
          "item": "年份/车型"
        },
        {
          "itemCode": "3311",
          "item": "补充标配"
        },
        {
          "itemCode": "3312",
          "item": "检测总结"
        },
        {
          "itemCode": "3315",
          "item": "内饰颜色"
        },
        {
          "itemCode": "3321",
          "item": "车辆型号"
        },
        {
          "itemCode": "3322",
          "item": "发动机型号"
        },
        {
          "itemCode": "3323",
          "item": "检测补充"
        },
        {
          "itemCode": "3324",
          "item": "生产日期"
        },
        {
          "itemCode": "3325",
          "item": "产证颜色"
        },
        {
          "itemCode": "3327",
          "item": "发动机号码"
        }
    ],
    carPhotos:[//图片code说明
      [1101,'左前外观照'],
      [1102,'内中内饰照'],
      [1115,'右后外观照'],
      [1116,'左前内饰照'],
      [1117,'行驶证照片'],
      [1103,'仪表标准照'],
      [1104,'铭牌标准照'],
      [1118,'三元催化器照']
    ],
    storeQueue:[//门店队列
      {id:'134',name:'吴中路店'},
      {id:'136',name:'川桥路店'},
      {id:'137',name:'江杨路店'},
      {id:'125',name:'龙吴路店'},
      {id:'126',name:'真南路店'},
      {id:'127',name:'吴中徐汇店'},
      {id:'128',name:'锦安东路店'},
      {id:'129',name:'民京路店'},
      {id:'149',name:'松江店'},
      {id:'131',name:'奉贤店'},
      {id:'132',name:'金沙江店'},
      {id:'133',name:'顾戴路店'},
      {id:'122',name:'新源店'},
      {id:'121',name:'川沙店'},
      {id:'155',name:'三林店'},
      {id:'158',name:'亭知路店'},
      {id:'159',name:'拱极路'},
    ],
    otherQueues:[//其他队列
      {id:'91',name:'总部前台'},
      {id:'103',name:'投诉部'},
    ],
    //虚拟五方格取消原因
    virtualReason:['检测师不配合','产能饱和','时间来不及','调度失误导致','未安排上继续排','未排上跟进中','未排上车已卖','未排上客户放弃'],
    provinceList:[],//三级联动（省）
    cityList:[],//三级联动（省市）
    areaList:[],//三级联动（省市区）
    carBrandList:[],//所有车辆品牌
    customerChannelList:[],//客户渠道列表
    paymentMethodList:[],//收款方式
    BankMerchantPayList:[],//商户支付方式
    walletList:[],//渠道钱包提现列表（新增批量使用）
    intermediateRefundList:[],//中间退费列表（新增批量使用）
    customerDepositList:[],//客户押金列表（新增批量使用）、
    merchantDepositList:[],//商户押金列表（新增批量使用）、
    violationList:[],//违章付款列表（财务）
    cityListSelect:[],//没有级联的市
	},
  mutations: {
		getProvince(state,data){
      state.provinceList = data;
    },
    getCity(state,data){
      state.cityList = data;
    },
    getArea(state,data){
      state.areaList = data;
    },
    getCarBrand(state,data){
      state.carBrandList = data;
    },
    getCustomerChannelList(state,data){//客户渠道列表
      state.customerChannelList = data;
    },
    getPaymentMethodList(state,data){//收款方式
      state.paymentMethodList=data
    },
    getBankMerchantPayList(state,data){//商户支付方式
      state.BankMerchantPayList=data
    },
    getWalletList(state,data){//渠道钱包提现列表（新增批量使用）
      state.walletList=data
    },
    getIntermediateRefundList(state,data){//中间退费列表（新增批量使用）
      state.intermediateRefundList=data
    },
    getViolationList(state,data){//违章付款列表（新增批量使用）
      state.violationList=data
    },
    getCustomerDepositList(state,data){//客户押金列表（新增批量使用）
      state.customerDepositList=data
    },
    getMerchantDepositList(state,data){//商户押金列表（新增批量使用）
      state.merchantDepositList=data
    },
    getCitySelect(state,data){//没有级联的市
      state.cityListSelect=data
    }
	},
  actions: {
    async setProvince({commit},data){
      commit('getProvince',data)
    },
    async setCity({commit},data){
      commit('getCity',data)
    },
    async setArea({commit},data){
      commit('getArea',data)
    },
    async setCarBrand({commit},data){
      commit('getCarBrand',data)
    },
    async setCustomerChannelList({commit},data){//客户渠道列表
      commit('getCustomerChannelList',data)
    },
    async setPaymentMethodList({commit},data){//收款方式
      commit('getPaymentMethodList',data)
    },
    async setBankMerchantPayList({commit},data){//商户支付方式
      commit('getBankMerchantPayList',data)
    },
    async setWalletList({commit},data){//渠道钱包提现列表（新增批量使用）
      commit('getWalletList',data)
    },
    async setIntermediateRefundList({commit},data){//中间退费列表（新增批量使用）
      commit('getIntermediateRefundList',data)
    },
    async setViolationList({commit},data){//违章付款（新增批量使用）
      commit('getViolationList',data)
    },
    async setCustomerDepositList({commit},data){//客户押金列表（新增批量使用）
      commit('getCustomerDepositList',data)
    },
    async setMerchantDepositList({commit},data){//商户押金列表（新增批量使用）
      commit('getMerchantDepositList',data)
    },
    async setCitySelect({commit},data){//没有级联关系的市
      commit('getCitySelect',data)
    }
	},
}