<template>
  <div class="pageContainer">
    <el-tabs v-model="tabClassify" type="card" @tab-click="init(1)">
      <el-tab-pane label="待处理" name="first">
        <el-row :gutter="20" class="searchRowBox clear">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>品牌车型</span>
            <el-input v-model="forms.brandOrModelName" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>办证截至时间</span>
            <el-date-picker v-model="forms.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
          </el-col>
          <el-col :span="1" class="flex flex_align_center flex_between w4 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
          <el-button type="primary" @click="onadd('form')" size="medium" class="fr mr10">新增违章处理</el-button>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="merchantName" label="成交商户名称" align="center">
            <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template>
          </el-table-column>
          <!-- <el-table-column prop="breakRuleDispose" label="违章处理方" align="center">
            <template slot-scope="scope">{{scope.row.car?scope.row.car.breakRuleDispose:''}}</template>
          </el-table-column> -->
          <el-table-column prop="signTime" label="合同签订日期" align="center">
            <template slot-scope="scope">{{scope.row.car.customerContract?scope.row.car.customerContract.signTime:scope.row.car.signTime}}</template>
          </el-table-column>
          <el-table-column prop="merchantDeadline" label="商户办证截止日期" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="160">
            <template slot-scope="scope">
              <el-button type="primary" @click="onconfirm(scope.row,1)" size="mini">跟进违章处理</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        ></el-pagination>
      </el-tab-pane>
      <!-- <el-tab-pane label="跟进中" name="second">
        <el-row :gutter="24" class="searchRowBox">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo1" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>品牌车型</span>
            <el-input v-model="forms.brandOrModelName1" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>办证截至时间</span>
            <el-date-picker v-model="forms.date1" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>下次跟进时间</span>
            <el-date-picker v-model="forms.bookTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
          </el-col>
          <el-col :span="1" class="flex flex_align_center flex_between w4">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="merchantName" label="成交商户名称" align="center"></el-table-column>
          <el-table-column prop="signTime" label="合同签订日期" align="center">
            <template slot-scope="scope">{{scope.row.car.customerContract?scope.row.car.customerContract.signTime:scope.row.car.signTime}}</template>
          </el-table-column>
          <el-table-column prop="nextInviteTime" label="下次跟进时间" align="center"></el-table-column>
          <el-table-column prop="merchantDeadline" label="商户办证截止日期" align="center"></el-table-column>
          <el-table-column prop="sellerAssistantName" label="销售助理" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="160">
            <template slot-scope="scope">
              <el-button type="primary" @click="onconfirm(scope.row,1)" size="mini">跟进违章处理</el-button>
            </template>
          </el-table-column>
        </el-table> 
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        ></el-pagination>
      </el-tab-pane> -->
      <el-tab-pane label="已处理" name="third">
        <el-table :data="querylist2" border stripe class="table-mainbg mt30">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="merchantName" label="成交商户名称" align="center">
            <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
          </el-table-column>
          <!-- <el-table-column prop="breakRuleDispose" label="违章处理方" align="center">
            <template slot-scope="scope">{{scope.row.car?scope.row.car.breakRuleDispose:''}}</template>
          </el-table-column> -->
          <el-table-column prop="signTime" label="合同签订日期" align="center">
            <template slot-scope="scope">{{scope.row.car.customerContract?scope.row.car.customerContract.signTime:scope.row.car.signTime}}</template>
          </el-table-column>
          <!-- <el-table-column prop="updateDate" label="违章处理完成时间" align="center"></el-table-column>
          <el-table-column prop="breakRulePaymentStatusName" label="付款状态" align="center">
            <template slot-scope="scope">{{scope.row.car?scope.row.car.breakRulePaymentStatusName:''}}</template>
          </el-table-column> -->
          <el-table-column prop="breakRuleOperaName" label="违章处理完成操作人" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button type="primary" @click="onconfirm(scope.row,3)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize2"
          :current-page="currentPage2"
          :total="total2"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="新增违章"
      :visible.sync="dialogVisible"
      :show-close="false"
      class="customDialog"
      width="700px"
    >
      <el-form :model="formCar" label-width="120px" :inline="true" class="topFormTitle">
        <el-form-item label="车牌号:">
          <el-input
            type="text"
            v-model="formCar.carNo"
            class="w130"
            size="medium"
            placeholder="请输入完整车牌号"
          ></el-input>
          <el-button type="primary" size="medium" @click="onSearch" plain class="formmodal_but">查询</el-button>
        </el-form-item>
        <el-form-item label="品牌车型:">
          <p class="w150">{{formCar.brandName}}&nbsp;{{formCar.modelName}}</p>
        </el-form-item>
        <el-form-item label="商户办证截止日期:" label-width="150px">
          <p class="w180">{{merchantContract.merchantDeadline}}</p>
        </el-form-item>
        <el-form-item label="商户名称:">
          <p class="w150">{{formCar.carMerchant?formCar.carMerchant.merchantName:formCar.merchantName}}</p>
        </el-form-item>
        <el-form-item label="客户名称:" label-width="150px">
          <p class="w180">{{formCar.customerName}}</p>
        </el-form-item>
      </el-form>
      <i class="lineDotted"></i>
      <el-form
        ref="form"
        :model="form"
        label-width="130px"
        :rules="rules"
        :inline="true"
        :hide-required-asterisk="false"
        class="mt20"
        size="medium"
      >
        <el-form-item label="违章供应商:" prop="supplierObj">
          <el-cascader :options="channllist" :props="props"  v-model="form.supplierObj" clearable ref="refcascader" @change="changeSupplie" :show-all-levels="false"></el-cascader>
          <!-- <el-select
            v-model="form.supplierName"
            filterable
            remote clearable
            placeholder="请输入关键词"
            @change="changeChannl"
            value-key="id"
            class="w180"
          >
            <el-option v-for="item in channllist" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="受益方金额:" prop="beneficiaryAmount">
          <el-input
            type="number"
            v-model.number="form.beneficiaryAmount"
            class="w180"
            size="medium"
            placeholder="请输入受益方金额"
          >
            <template slot="suffix">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="费用承担方:" prop="feeBearerId">
          <el-select
            v-model="form.feeBearerId"
            placeholder="请选择费用承担方"
            class="w180"
            size="medium"
          >
            <el-option label="客户" value="1"></el-option>
            <el-option label="商户" value="2"></el-option>
            <el-option label="车开新" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="承担方金额:" prop="feeBearerAmount">
          <el-input
            type="number"
            v-model.number="form.feeBearerAmount"
            class="w180"
            size="medium"
            placeholder="请输入承担方金额"
          >
            <template slot="suffix">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="驾照扣分:" prop="driveLicenseSubScore">
          <el-input type="number" v-model.number="form.driveLicenseSubScore" class="w180" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="违章次数:" prop="breakRuleCounts">
          <el-input type="number" v-model.number="form.breakRuleCounts" class="w180" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="约定处理日期:" prop="appointDisposeDate">
          <el-date-picker
            v-model="form.appointDisposeDate"
            type="date"
            placeholder="请选择约定完成时间"
            value-format="yyyy-MM-dd"
            class="w180"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="违章成本:" prop="payableAmount">
          <el-input type="number" v-model.number="form.payableAmount" class="w180" size="medium">
            <template slot="suffix">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="footer-modalBut" @click="onSubmitform('form')">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabClassify: "first",
      forms: {
        carNumber: ""
      },
      querylist: [],
      querylist1:[],
      querylist2: [], //
      total: 0,
      pageSize: 20,
      currentPage: 1,
      total1: 0,
      pageSize1: 20,
      currentPage1: 1,
      total2: 0,
      pageSize2: 20,
      currentPage2: 1,
      dialogVisible: false,
      form: {
        supplierObj:[],
        beneficiaryAmount:'',
        feeBearerId:'',
        feeBearerAmount:'',
        driveLicenseSubScore:'',
        breakRuleCounts:'',
        appointDisposeDate:'',
        payableAmount:'',
        benefitCertRuleSupplierId:'',
        benefitCertRuleSupplierName:''
      },
      formCar:{
        carMerchant:{},
      },//车辆详情数据
      merchantContract:{},
      rules: {
        supplierObj:[{ required: true, message: "不能为空" }],
        beneficiaryAmount: [{ required: true, message: "受益方金额不能为空" }],
        feeBearerId: [{ required: true, message: "费用承担方不能为空" }],
        feeBearerAmount: [{ required: true, message: "应收金额不能为空" }],
        driveLicenseSubScore: [{ required: true, message: "驾照扣分不能为空" }],
        breakRuleCounts: [{ required: true, message: "违章次数不能为空" }],
        appointDisposeDate: [{ required: true, message: "约定处理日期不能为空" }],
        payableAmount: [{ required: true, message: "违章本金不能为空" }]
      },
      loading: false,
      channllist:[{id:1,name:'客户'},{id:2,name:'商户'},{id:4,name:'办证公司',children:[]},{id:5,name:'违章供应商',children:[]}],//供应商列表
      props:{
        label:'name',
        value:'id',
        children:'children'
      }
    };
  },
  created() {
    this.init();
    this.postSupplierList();//获取供应商
  },
  methods: {
    changeSupplie(e){
      let node=this.$refs.refcascader.getCheckedNodes();
      if(node.length>0){
        this.form.supplierId=e[0];
        this.form.supplierName=node[0].pathLabels[0];
        this.form.benefitCertRuleSupplierId=node[0].path[1]
        this.form.benefitCertRuleSupplierName=node[0].pathLabels[1]
      }
      // console.log(e,'====',node)
    },
    init(page) {
      let query = {
        // curPage: currentPage, //当前页数
        // pageSize: pageSize,
        taskType: 6,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        // brandOrModelName:brandOrModelName,
        // taskBreakStatuses:[taskStatus],
        // carNo:carNo,
        // merchantDeadlineBegin:this.params.date?`${this.params.date[0]} 00:00:00`:'',
        // merchantDeadlineEnd:this.params.date?`${this.params.date[1]} 23:59:59`:'',
      };
      switch (this.tabClassify) {
        case "first":
          this.currentPage=page?page:this.currentPage;

          query.taskBreakStatuses = [71]; //待处理
          query.pageSize = this.pageSize;
          query.curPage = this.currentPage;
          query.carNo=this.forms.carNo;
          query.brandModelSeriesName=this.forms.brandOrModelName;
          query.merchantDeadlineBegin=this.forms.date?`${this.forms.date[0]}`:'';
          query.merchantDeadlineEnd=this.forms.date?`${this.forms.date[1]}`:'';
          break;
        case "second":
          this.currentPage1=page?page:this.currentPage1;

          query.taskBreakStatuses = [72]; //跟进中
          query.pageSize = this.pageSize1;
          query.curPage = this.currentPage1;
          query.carNo=this.forms.carNo1;
          query.brandModelSeriesName=this.forms.brandOrModelName1;
          query.merchantDeadlineBegin=this.forms.date1?`${this.forms.date1[0]}`:'';
          query.merchantDeadlineEnd=this.forms.date1?`${this.forms.date1[1]}`:'';
          query.nextInviteBeginTime=this.forms.bookTime?`${this.forms.bookTime[0]}`:'';
          query.nextInviteEndTime=this.forms.bookTime?`${this.forms.bookTime[1]}`:'';
          break;
        case "third":
          this.currentPage2=page?page:this.currentPage2;

          query.taskBreakStatuses = [73]; //已处理
          query.pageSize = this.pageSize2;
          query.curPage = this.currentPage2;
          break;
      }
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            switch (this.tabClassify) {
              case "first":
                this.querylist = res.data.records;
                this.total = res.data.total;
                break;
              case "second":
                this.querylist1 = res.data.records;
                this.total1 = res.data.total;
                break;
              case "third":
                this.querylist2 = res.data.records;
                this.total2 = res.data.total;
                break;
            }
          } else {
            switch (this.tabClassify) {
              case "first":
                this.querylist = [];
                this.total = 0;
                break;
              case "second":
                this.querylist1 = [];
                this.total1 = 0;
                break;
              case "third":
                this.querylist2 = [];
                this.total2 = 0;
                break;
            }
          }
        }
      });
    },
    onconfirm(row) {
      let aurl = "/accreditationCommissioner/violationHandlingDetails";
      this.$router.push({
        path: aurl,
        query: {
          // state: e,
          id:row.id
        }
      });
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onadd(formdata) {
      this.ondialogVisible();
      this.$nextTick(()=>{
        if (this.$refs[formdata]) {
          this.$refs[formdata].resetFields();
        }
        this.formCar={};
        this.formCar.carMerchant={};
        this.form.supplierName='';
        this.form.supplierId='';
        this.merchantContract={};
      })
    },
    onSearch() {
      if(!this.formCar.carNo){
        this.$message.warning('请输入车牌号！')
        return
      }
      //搜索车号返回
      new Promise((resolve,reject)=>{
        this.$api.getSimpledetailCar(this.formCar.carNo).then((res)=>{
          if(res){
            if(res.code==0){
              if(res.data){
                this.formCar=res.data;
                this.formCar.carMerchant=res.data.carMerchant?res.data.carMerchant:{};
                resolve(res.data) 
              }else{
                reject(res)
              }
            }else{
              reject(res)
            }
          }
      })
      }).then((row)=>{
        if(!row.merchantId){
          return
        }
        this.$api.getContractMerchant(row.id,row.merchantId).then(res=>{
          if(res.code==0){
            this.merchantContract=res.data?res.data:{};
          }
        })
      }).catch(()=>{
        this.formCar={};
        this.formCar.carMerchant={};
      })
      
    },
    onSubmitform(formdata) {
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.postBreakruleAdd()
        } else {
          return false;
        }
      });
    },
    handleSizeChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.pageSize = data;
          this.currentPage=1;
          break;
        case "second":
          this.pageSize1 = data;
          this.currentPage1=1;
          break;
        case "third":
          this.pageSize2 = data;
          this.currentPage2=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.currentPage = data;
          break;
        case "second":
          this.currentPage1 = data;
          break;
        case "third":
          this.currentPage2 = data;
          break;
      }
      this.init()
    },
    // changeChannl(row) {
    //   this.form.supplierId = row.id;
    //   this.form.supplierName=row.name;
    //   this.$forceUpdate()
    // },
    remoteMethod(data) {
      if (data !== "") {
        this.loading = true;
        setTimeout(() => {
          let query = {
            name: data
          };
          this.$api.postSupplierQuery(query).then(res => {
            if (res) {
              if (res.code == 0) {
                this.loading = false;
                this.channllist = res.data.records;
              } else {
                this.channllist = [];
              }
            }
          });
        }, 200);
      } else {
        this.channllist = [];
      }
    },
    postBreakruleAdd(){
      if(!this.formCar.id){
        this.$message.warning('请输入正确车牌号查询后提交！')
        return
      }
      let query={
        "carId": this.formCar.id,
        "supplierId": this.form.supplierId,//供应商ID
        "supplierName": this.form.supplierName,//供应商名称
        beneficiaryId:5,//受益方Id：1客户，2商户，3车开新，4办证公司，5违章供应商
        "beneficiaryAmount": this.form.beneficiaryAmount,//受益方金额
        "feeBearerAmount": this.form.feeBearerAmount,//承担方金额
        "feeBearerId": this.form.feeBearerId,//费用承担方Id：1客户，2商户
        "driveLicenseSubScore": this.form.driveLicenseSubScore,//驾照扣分
        "breakRuleCounts": this.form.breakRuleCounts,//违章次数
        "appointDisposeDate": this.form.appointDisposeDate,//约定处理日期
        "payableAmount": this.form.payableAmount,//违章处罚金额
        benefitCertRuleSupplierId:this.form.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:this.form.benefitCertRuleSupplierName,
      }
      this.$api.postBreakruleAdd(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.ondialogVisible();
            this.init()
          }
        }
      })
    },
    postSupplierList(){//供应商列表
      this.$api.postSupplierList({}).then(res=>{
        if(res.code==0){
          for(let i=0;i<this.channllist.length;i++){
            let item=this.channllist[i]
            if(['办证公司','违章供应商'].includes(item.name)){
              item.children=res.data;
            }
          }
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
  .formmodal_but {
    margin-left: 35px;
  }
  .lineDotted {
    display: inline-block;
    width: 600px;
    height: 1px;
    background-color: #cccccc;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    margin-left: 15px;
  }
}
</style>