<template>
  <div class="scheduling">
    <el-row :gutter="20">
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>日期：</span>
        <el-date-picker v-model="params.dutyDate" type="month" placeholder="选择月" value-format="yyyy-MM"></el-date-picker>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>检测师姓名:</span>
        <el-select v-model="params.checkerId" placeholder="请选择" clearable>
          <el-option v-for="item in checkerList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <div class="flex flex_align_center status">
      <span class="work">-A（上班）</span>
      <span class="rest">-X（休息）</span>
      <span class="restBox statusBox"></span>
      <span class="leave">-S（请假）</span>
      <span class="leaveBox statusBox mr30"></span>
      <span class="sick">-B（病假）</span>
      <span class="sickBox statusBox mr30"></span>
      <span class="meeting">-H（开会）</span>
      <span class="meetingBox statusBox"></span>
    </div>
    <div style="text-align:right;">
      <el-button type="primary" size="mini" plain @click="importData">导入排班</el-button>
    </div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-for="item in timeData" :key="item.no" :label="getLabel(item.no)" :name="String(item.no)">
        <table class="schedulingTable">
          <thead>
            <tr>
              <th>员工姓名</th><th>员工工号</th><th></th>
              <th v-for="v in item.dayDuties" :key="v.id">{{v.dutyDate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="it in item.timeList" :key="it">
              <td>{{checkerName}}</td><td>{{checkerNo}}</td><td><span class="timeSlot">{{getTimeLabel(it)}}</span></td>
              <td v-for="(v,i) in item.dayDuties" :key="i">
                <el-select v-if="['A','X','Q','B','H'].indexOf(v.statusMap[it])>-1" v-model="v.statusMap[it]" placeholder="请选择" :class="{'selectStatus':true,'status0':v.statusMap[it]==='A','status1':v.statusMap[it]==='X','status2':v.statusMap[it]==='Q','status3':v.statusMap[it]==='B','status4':v.statusMap[it]==='H'}" @change="changeStatus(v.dutyDate,it,v.statusMap[it])">
                  <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        dutyDate: '',
        checkerId: null,
      },
      checkerName: '',
      checkerNo: '',
      checkerList: [],
      timeOptions: [
        {value: 'A', label: '上班'},
        {value: 'X', label: '休息'},
        {value: 'Q', label: '请假'},
        {value: 'B', label: '病假'},
        {value: 'H', label: '开会'},
      ],
      activeName: '1',
      timeData: [],
    }
  },
  created() {
    this.checkerQueryChecker();//获取所有检测师
    this.search();
  },
  methods: {
    checkerQueryChecker() {
      this.$api.checkerQueryChecker().then(res => {
        if(res.code == 0){
          this.checkerList = res.data
        }
      })
    },
    search() {
      this.curCheckerId = this.params.checkerId;
      this.$api.checkerQueryCheckerDuty(this.$$.delete_null_properties(this.params)).then(res => {
        if(res.code == 0){
          this.checkerName = res.data.name;
          this.checkerNo = res.data.no;
          let timeData = res.data.weekDuties;
          timeData.forEach(item => {
            let timeList = [];
            item.dayDuties.forEach(itm => {
              Object.keys(itm.statusMap).forEach(it => {
                if(timeList.indexOf(it) === -1){
                  timeList.push(it);
                }
              })
            })
            timeList.sort();
            item.timeList = timeList;
          })
          this.timeData = timeData;
        }
      })
    },
    getLabel(index) {
      if(index===1){
        return '一周'
      }else if(index===2){
        return '二周'
      }else if(index===3){
        return '三周'
      }else if(index===4){
        return '四周'
      }else if(index===5){
        return '五周'
      }else if(index===6){
        return '六周'
      }
    },
    getTimeLabel(it) {
      let timeArr = it.split('');
      timeArr.splice(2,0,':');
      return timeArr.join('');
    },
    changeStatus(date,time,status) {
      this.$api.checkerUpdateCheckerDuty({
        checkerId: this.curCheckerId,
        dutyDate: date,
        status: status,
        time: time,
      }).then(res => {
        if(res.code == 0){
          this.$message.success(res.message)
        }
      })
    },
    importData() {
      this.$api.checkerImportDingCheckerDayDuty(this.$$.delete_null_properties({
        date: this.params.dutyDate,
        checkerId: this.params.checkerId
      })).then(res => {
        if(res.code == 0){
          this.$message.success(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.scheduling{
  padding: 40px;
  .mr30{margin-right: 30px;}
  .status{
    margin: 20px 0 0;
    .work{
      margin-right: 20px;
    }
    .statusBox{
      width: 42px;
      height: 16px;
      display: inline-block;
      background: #FF0000;
    }
    .restBox{background: #3799FF;margin-right: 30px;}
    .meetingBox{background: #FFC600;}
  }
  .schedulingTable{
    border-collapse: collapse;
    width: 100%;
    th{
      padding: 10px 20px;
      border: 1px solid #cccccc;
      text-align: center;
    }
    td{
      border: 1px solid #cccccc;
      text-align: center;
      .timeSlot{padding: 10px 20px;}
      ::v-deep .status2 .el-input__inner{background-color: #FF0000;color: #fff;}
      ::v-deep .status1 .el-input__inner{background-color: #3799FF;color: #fff;}
      ::v-deep .status3 .el-input__inner{background-color: #FF0000;color: #fff;}
      ::v-deep .status4 .el-input__inner{background-color: #FFC600;color: #fff;}
      ::v-deep .el-input__inner{border-radius: 0;}
    }
  }
}
</style>