<template>
  <!-- 员工列表 -->
  <div class="cargoArea">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>货区列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="mt10 mb20">
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogFormVisible=true" plain>添加货区</el-button>
    </div>
    <el-table :data="cargoAreaList" border stripe class="table-mainbg">
      <el-table-column prop="code" label="编号" align="center"></el-table-column>
      <el-table-column prop="stockObjName" label="仓库名称" align="center"></el-table-column>
      <el-table-column prop="name" label="货区名称" align="center"></el-table-column>
      <el-table-column prop="locationTotalNums" label="货位数" align="center"></el-table-column>
      <el-table-column prop="locationFreeNums" label="空闲货位" align="center"></el-table-column>
      <el-table-column prop="locationUsedNums" label="占用货位" align="center"></el-table-column>
      <el-table-column prop="createDate" label="创建时间" width="185px" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-switch :value="!Boolean(scope.row.isEnable)" @change="changeStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="添加货区" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="formRules" label-width="100px" ref="form">
        <el-form-item label="仓库名称" prop="stockObjId">
          <el-select v-model="form.stockObjId" placeholder="请选择仓库" size="medium">
            <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货区名称" prop="name">
          <el-input v-model="form.name" autocomplete="off" style="width:225px;"></el-input>
        </el-form-item>
        <el-form-item label="货位数量" prop="locationTotalNums">
          <el-input v-model="form.locationTotalNums" autocomplete="off" type="number" style="width:225px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCargoArea">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        name: '',
        isEnable: ''
      },
      cargoAreaList: [],
      dialogFormVisible: false,
      warehouseList: [],
      form: {
        stockObjId: '',
        name: '',
        locationTotalNums: '',
      },
      formRules: {
        stockObjId: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        locationTotalNums: [{ required: true, message: '请输入', trigger: 'blur' }],
      }
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getStockObjList();//获取所有仓库
  },
  methods: {
    getList() {
      this.$api.stockStockObjAreaList().then(res => {
        if(res.code === 0){
          this.cargoAreaList = res.data;
        }
      })
    },
    getStockObjList(){
      this.$api.getStockObjList().then(res => {
        if(res.code === 0){
          this.warehouseList = res.data;
        }
      })
    },
    addCargoArea() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.form))
          params.isEnable = 0;
          this.$api.stockStockObjAreaAdd(params).then(res => {
            if(res.code === 0){
              this.$message.success('操作成功');
              this.dialogFormVisible = false;
              this.$refs['form'].resetFields();
              this.getList();
            }
          })
        } else {
          return false;
        }
      });
      
    },
    changeStatus(row) {
      let str = '';
      if(!row.isEnable){
        str = '此操作将停用货区,是否继续?'
      }else{
        str = '此操作将启用货区,是否继续?'
      }
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
        this.$api.stockStockObjAreaUpdate(row.id,{
          isEnable: row.isEnable? 0 : 1,
          name: row.name
        }).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.getList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });          
      });
    },
  }
}
</script>

<style scoped lang="scss">
.cargoArea{
  padding: 20px 40px;
}
</style>