<template>
  <div class="compensate">
    <!-- 客户赔偿 -->
    <!-- <div class="mt20 mb20">
      <span class="mr20">品牌车型：{{carType}}</span>
      <span>车牌号码：{{carNo}}</span>
    </div> -->
    <el-row>
      <el-col :span="5">品牌车型：{{carType}}</el-col>
      <el-col :span="5">车牌号码：{{carNo}}</el-col>
    </el-row>
    <el-form ref="form" :model="params" label-width="120px" size="medium" :rules="rules">
      <el-form-item label="赔偿门店：">
        <p>{{params.stopName}}</p>
      </el-form-item>
      <!-- <el-form-item label="赔偿类型：">
        <el-radio-group v-model="params.type">
          <el-radio label="当日赔偿"></el-radio>
          <el-radio label="未赔偿"></el-radio>
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item label="赔偿日期：">
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="timeChange" class="w500"> </el-date-picker>
      </el-form-item> -->
      <el-form-item label="赔偿原因：" prop="reason">
        <el-select v-model="params.reason" class="w500" value-key="id" @change="onChange">
          <el-option v-for="item in Reasonlist" :key="item.id" :label="item.reason" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="赔偿金额：">
        <el-input v-model="params.comAmount" class="w500" readonly></el-input>
      </el-form-item>
      <el-form-item label="OK卡：" prop="okCard">
        <el-select v-model="params.okCard" multiple placeholder="请选择" @change="getOKCard" class="w500">
          <el-option v-for="item in oklist" :key="item.id" :label="`${item.cardNo}(${item.amount}元)`" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="总金额">
        <span class="ml20">{{params.amount}} 元</span>
      </el-form-item> -->
      <el-form-item label="备注">
        <el-input type="textarea" v-model="params.remark" class="w500"></el-input>
      </el-form-item>
    </el-form>
    <div class="pb100">
      <div class="ckxTitle">客户赔偿记录</div>
      <el-table :data="recordlist" border stripe class="mt20 table-mainbg">
        <el-table-column property="indemnityDate" label="赔偿日期" align="center"></el-table-column>
        <el-table-column property="shop" label="赔偿门店" align="center"></el-table-column>
        <el-table-column property="carType" label="品牌车型" align="center"></el-table-column>
        <el-table-column property="cardNo" label="OK卡号" align="center"></el-table-column>
        <el-table-column property="amount" label="赔偿金额" align="center"></el-table-column>
        <el-table-column property="operatorName" label="操作人" align="center"></el-table-column>
        <el-table-column property="remark" label="赔偿原因" align="center"></el-table-column>
        <el-table-column property="isVoided" label="是否作废" align="center">
          <template slot-scope="scope">{{scope.row.isVoided?'是':'否'}}</template>
        </el-table-column>
        <el-table-column property="voidedUserName" label="作废操作人" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="onVoid(scope.row)" plain size="mini" v-if="!scope.row.isVoided">作废</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        store: '',
        type: '',
        reason: '',
        createTime: '',
        okCard: '',
        remark: '',
        amount: '',//计算ok卡总金额
        comAmount:'',//赔偿金额
      },
      carType:'',
      carNo:'',
      customerId:'',//客户id
      userlist:{},
      oklist:[],
      okMap:new Map(),
      shopId:'',//门店id
      Reasonlist:[],
      res:{},
      recordlist:[],//记录列表
      rules: {
          reason: [{ required: true, message: '不能为空', trigger: 'change' }],
          okCard: [{ required: true, message: '不能为空', trigger: 'change' }]
      },
    }
  },
  created(){
    if(this.$route.query.carNo) this.carNo=this.$route.query.carNo;
    if(this.$route.query.customerId)this.customerId=this.$route.query.customerId;
    if(this.$route.query.carType)this.carType=this.$route.query.carType;
    this.userlist=this.$store.state.user.userData;
    this.getUser()
    this.getReasonlist()//查询所有赔偿原因
    this.getIndemnitylist()//根据客户Id查询客户赔偿记录
    this.$nextTick(()=>{
      this.$refs['form'].clearValidate();
    })
  },
  methods: {
    getOKCard(value) {
      let num=0;
      for(let i=0;i<value.length;i++){
        let aa=this.okMap.get(value[i])
        num+=aa.amount;
      }
      this.params.amount = num;
    },
    onSubmit() {
      if(this.params.comAmount<this.params.amount){
        this.$message('ok金额不可大于赔偿金额！')
        return
      }else if(this.params.comAmount>this.params.amount){
        this.$message('ok金额不可小于赔偿金额！')
        return
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.postIndemnitySubmit()
        }
      });
    },
    onChange(e){
      this.params.comAmount=e.amount;
      this.params.reason=e.reason;
    },
    onVoid(row){//作废按钮
      this.$api.putIndemnityVoided(row.id).then(res=>{
        if(res.code==0){
          this.getIndemnitylist()
          this.getOKCarlist()
          this.$message.success(res.message)
        }
      })
    },
    getOKCarlist(){//根据门店Id获取ok卡
      this.$api.getOKCarlist(this.shopId).then(res =>{
        if(res.code==0){
          this.oklist=res.data;
          for(let i=0;i<res.data.length;i++){
            this.okMap.set(res.data[i].id,res.data[i])
          }
        }
      })
    },
    getUser(){//获取部门id
      if(!this.userlist.departmentList){
        return
      }
      for(let i=0;i<this.userlist.departmentList.length;i++){
        let item=this.userlist.departmentList[i]
        if(!item.isCurrent){
          this.shopId=item.shop?item.shop.id:'';
          this.params.stopName=item.name;
        }
      }
      if(this.shopId){
        this.getOKCarlist()
      }
    },
    getReasonlist(){//查询所有赔偿原因
      this.$api.getReasonlist().then(res=>{
        if(res.code==0){
          this.Reasonlist=res.data;
        }
      })
    },
    postIndemnitySubmit(){//提交客户赔偿记录
      let query={
        "customerId": this.customerId,
        "shopId": this.shopId,
        "shop": this.params.stopName,
        "indemnityReason": this.params.reason,
        "amount": this.params.comAmount,
        "okCardIds": this.params.okCard,
        "remark": this.params.remark
      }
      this.$api.postIndemnitySubmit(query).then(res=>{
        if(res.code==0){
          this.params.reason=''
          this.params.comAmount='';
          this.params.okCard=[]
          this.params.remark='';
          this.params.amount=0;
          this.getIndemnitylist()
          this.getOKCarlist()
          this.$message.success(res.message)
          this.$nextTick(()=>{
            this.$refs['form'].clearValidate();
          })
        }
      })
    },
    getIndemnitylist(){//根据客户Id查询客户赔偿记录
      this.$api.getIndemnitylist(this.customerId).then(res=>{
        if(res.code==0){
          this.recordlist=res.data;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.compensate{
  padding: 20px 40px;
  .w500{
    width:500px
  }
}
</style>