<template>
  <div class="currencyConterBox">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="退商户押金" name="first">
         <el-row :gutter="24" class="mt15">
            <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
              <span>车牌号</span>
              <el-input v-model="forms.carNo" size="medium"></el-input>
            </el-col>
            <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
              <span>商户名称</span>
              <el-input v-model="forms.merchantName" size="medium"></el-input>
            </el-col>
            <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
              <span>商户编号</span>
              <el-input v-model="forms.merchantNo" size="medium"></el-input>
            </el-col>
            <el-col :xl="10" :lg="10" class="flex flex_align_center flex_between w5 mb10">
              <span>商户结算时间</span>
              <el-date-picker
                v-model="forms.date"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                size="medium"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-col>
            <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w3 mb10">
              <span>付款状态</span>
              <el-select v-model="forms.financePayStatuses" size="medium" clearable multiple>
                <!-- <el-option label="全部" value=""></el-option> -->
                <el-option v-for="item in paymentStatuslist" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-col>
            <el-col :xl="2" :lg="4" class="flex flex_align_center flex_between w3 mb10">
              <el-button type="primary" @click="init(1)" size="medium" class="fr mr10" plain>查询</el-button>
              <el-button type="primary" @click="onBatchPayment" size="medium" class="ml30">批量付款</el-button>
            </el-col>
          </el-row>
          <el-table :data="querylist" border stripe class="table-mainbg mt10" ref="table" :row-key="getRowKeys" @selection-change="handleSelectionChange" >
            <el-table-column type="selection" label="选择" align="center" width="55" :reserve-selection="true"></el-table-column>
            <el-table-column prop="merchantContractNo" label="合同编号" align="center" ></el-table-column>
            <el-table-column prop="carNo" label="车牌号" align="center" ></el-table-column>
            <el-table-column prop="carBrandModelName" label="车型" align="center" ></el-table-column>
            <el-table-column prop="mcTradePrice" label="成交价" align="center" ></el-table-column>
            <el-table-column prop="tradeTypeName" label="成交类型" align="center" ></el-table-column>
            <el-table-column prop="merchantName" label="商户名称" align="center" ></el-table-column>
            <el-table-column prop="merchantNo" label="商户编号" align="center" ></el-table-column>
            <el-table-column prop="appointTradeTime" label="预约成交时间" align="center" ></el-table-column>
            <el-table-column prop="totalPayAmount" label="应付金额" align="center" ></el-table-column>
            <el-table-column prop="financePayStatus" label="付款状态" align="center" >
              <template slot-scope="scope">{{scope.row.financePayStatus==32?'付款中':scope.row.financePayStatus==33?'付款成功':scope.row.financePayStatus==34?'付款失败':'待付款'}}</template>
            </el-table-column>
            <el-table-column  label="操作" align="center" >
              <template slot-scope="scope">
                <el-button type="primary" @click="onRowClick(scope.row,0)" size="mini" plain>查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total">
          </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="批量退商户押金" name="second">
        <el-row :gutter="20" class="mt20">
          <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
            <span>车牌号</span>
            <el-input v-model="forms1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
            <span>商户名称</span>
            <el-input v-model="forms1.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
            <span>商户编号</span>
            <el-input v-model="forms1.merchantNo" size="medium"></el-input>
          </el-col>
          <el-col :xl="10" :lg="10" class="flex flex_align_center flex_between w5 mb10">
            <span>批量支付日期</span>
            <el-date-picker
              v-model="forms1.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :xl="6" :lg="6" class="flex flex_align_center flex_between w5 mb10">
            <span>批量支付状态</span>
            <el-select v-model="forms1.batchPayStatus" clearable>
              <el-option value="">请选择状态</el-option>
              <el-option
                v-for="item in BatchPayStatuslist"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :xl="2" :lg="2" class="flex flex_align_center flex_between w4 mb10">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt10" >
          <el-table-column prop="batchPayOrderNo" label="批次编号" align="center" ></el-table-column>
          <el-table-column prop="batchPayAmount" label="批量支付金额（元）" align="center" ></el-table-column>
          <el-table-column prop="batchPayTime" label="批量支付日期" align="center" ></el-table-column>
          <el-table-column prop="batchPayStatus" label="批量支付状态" align="center" >
            <template slot-scope="scope">{{scope.row.batchPayStatus==32?'付款中':scope.row.batchPayStatus==33?'付款成功':scope.row.batchPayStatus==34?'付款失败':'待付款'}}</template>
          </el-table-column>
          <el-table-column  label="操作" align="center" >
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row,1)" size="mini" plain>查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt30"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next,sizes, jumper"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize1"
        :current-page="currentPage1"
        :total="total1">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
   
    
  </div>
</template>
<script>
export default {
    data(){
      return{
        activeName:'first',
        querylist:[],//
        forms:{
          merchantName:'',
          carNo:'',
          date:'',
          financePayStatuses:[31,32],
        },
        forms1:{},
        total:0,
        currentPage:1,
        pageSize:10,
        total1:0,
        currentPage1:1,
        pageSize1:10,
        paymentStatuslist:[],//付款状态
        selectlist:[],
        querylist1:[],
        BatchPayStatuslist:[],//付款状态
        contlist:[],//选择的数据
      }
    },
    created(){
      this.paymentStatuslist=this.$store.state.setting.paymentStatuslist;
      this.BatchPayStatuslist = this.$store.state.setting.BatchPayStatuslist;

      if(this.$route.params.state) this.forms.financePayStatuses=[Number(this.$route.params.state)];
      
      this.init();
    },
    methods:{
      getRowKeys(row) {
        return row.id
      },
      handleSelectionChange(rows){
        this.selectlist=rows;
      },
      init(page){
        this.currentPage=page?page:this.currentPage;
        
        if (this.activeName == "first") {
          this.postMerchantDepositQuery(page)//条件查询
        } else {
          this.postMerchantBatchQuery(page)//批量条件查询
        }
        
      },
      postMerchantDepositQuery(page){//商户押金退款(单个)
        this.currentPage=page?page:this.currentPage;
        let query={
          "curPage": this.currentPage,//当前页数
          "pageSize": this.pageSize,
          "carNo": this.forms.carNo,
          merchantName:this.forms.merchantName,
          merchantNo:this.forms.merchantNo,
          financePayStatuses:this.forms.financePayStatuses?this.forms.financePayStatuses:'',
          startTime:this.forms.date?this.forms.date[0]:'',
          endTime:this.forms.date?this.forms.date[1]:''
        }
        this.$api.postMerchantDepositQuery(this.$$.delete_null_properties(query)).then((res)=>{
          if(res){
            if(res.code==0){
              this.querylist=res.data.records;
              this.total=res.data.total;
            }else{
              this.querylist=[];
              this.total=0;
            }
          }
        })
      },
      postMerchantBatchQuery(page){//批量条件查询
        this.currentPage1=page?page:this.currentPage1;
        let query={
          curPage:this.currentPage1,
          pageSize:this.pageSize1,
          carNo:this.forms1.carNo,
          merchantName:this.forms1.merchantName,
          merchantNo:this.forms1.merchantNo,
          batchPayStatus:this.forms1.batchPayStatus?[this.forms1.batchPayStatus]:'',//批量支付状态，32付款中，33付款成功，34付款失败
          batchPayBeginTime:this.forms1.date?`${this.forms1.date[0]} 00:00:00`:'',//批量支付开始日期
          batchPayEndTime:this.forms1.date?`${this.forms1.date[1]} 23:59:59`:'',
        }
        this.$api.postMerchantBatchQuery(this.$$.delete_null_properties(query)).then((res)=>{
          if(res){
            if(res.code==0){
              this.querylist1=res.data.records;
              this.total1=res.data.total;
            }else{
              this.querylist1=[];
              this.total1=0;
            }
          }
        })
      },
      handleSizeChange(e){
        switch (this.activeName) {
          case "first":
            this.pageSize = e;
            this.currentPage=1;
            break;
          case "second":
            this.pageSize1 = e;
            this.currentPage1=1;
            break;
        }
        this.init()
      },
      handleCurrentChange(e){
        switch (this.activeName) {
          case "first":
            this.currentPage = e;
            break;
          case "second":
            this.currentPage1 = e;
            break;
        }
        this.init()
      },
      onRowClick(row,state){
        let query={
          state: state
        };
        if(state==0){
          query.id = row.id
        }else{
          query.batchPayOrderNo=row.batchPayOrderNo;
        }
        this.$router.push({
          path:'/finance/merchant/merchantDepositDetails',
          query:query
        })
      },
      onBatchPayment() {
        let arr=[];
        let payType=false;//当前是否有单存在付款完成
        for(let i=0;i<this.selectlist.length;i++){
          if(this.selectlist[i].financePayStatus!=31){
            payType=true;
          }
          arr.push(this.selectlist[i].carNo)
        }
        if(payType){
          this.$message.warning('当前订单中存在不需要付款的数据，请确认再付款！');
          return
        }
        if (this.selectlist.length == 0) {
          this.$message("请选择需要批量付款的合同！");
          return;
        }
        this.$store.dispatch('setting/setMerchantDepositList',this.selectlist); 
        let aurl = "/finance/merchant/merchantDepositDetails";
        this.$router.push({
          path: aurl,
          query: {
            state: 1,
            carNos:arr
          },
        });
      },
    }
}
</script>

<style lang="scss" scoped>
.currencyConterBox{
    padding:20px 40px;
    .rowbox{
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      .line{
        width:15px;
        height:1px;
        background-color: #999999;
        margin: 0 5px;
      }
    }
}
</style>
