<template>
  <div class="pageContainer">
    <el-row :gutter="20" class="">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>商户账号</span>
        <el-input v-model="form.merchantNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>商户姓名</span>
        <el-input v-model="form.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>销售专员</span>
        <el-select v-model="form.maintainerId" size="medium" clearable filterable>
          <el-option v-for="item in userlist" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="3" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="businessCityName" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center">
        <template slot-scope="scope">{{scope.row.merchantType==1?scope.row.authCompanyName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="merchantNo" label="商户账户" align="center"></el-table-column>
      <el-table-column prop="online" label="在线情况" align="center">
        <template slot-scope="scope">{{scope.row.online?'在线':'离线'}}</template>
      </el-table-column>
      <el-table-column prop="loginTime" label="最近登陆时间" align="center"></el-table-column>
      <el-table-column prop="logoutTime" label="最近离线时间" align="center"></el-table-column>
      <el-table-column prop="appVersion" label="APP版本" align="center"></el-table-column>
      <el-table-column prop="maintainerName" label="销售专员" align="center"></el-table-column>
      <!-- <el-table-column prop="name" label="车型库版本" align="center"></el-table-column> -->
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        maintainerId:'',
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      userlist:[],
    };
  },
  created() {
    this.init();
    this.queryUserListByRoleAndDepart()//获取销售专员
  },
  methods: {
    init() {
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
        merchantName:this.form.merchantName,//商户姓名
        merchantNo:this.form.merchantNo,//商户账号
        maintainerId:this.form.maintainerId
      }
      this.$api.getSignedList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
              this.querylist=[];
              this.total=0;
          }
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    handleSizeChange(data) {
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage=data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//获取销售专员
      let query={
        roleName:'销售专员',
        userEnabled:0,//角色是否启用,启用0，禁用1
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>

</style>
