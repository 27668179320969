<template>
  <div class="pageContainer">
    <!--基本信息-->
    <div class="content_modal_textBox">
      <el-form ref="form" :model="form" label-width="100px" :hide-required-asterisk="true" :rules="rules">
        <el-form-item label="合作店简称:" prop="partnerShortName">
         <el-select
          v-model="form.partnerShortName" size="medium" class="w300" clearable filterable 
          placeholder="请选择"
        >
          <el-option
            v-for="item in channllist"
            :key="item.id"
            :label="item.shortName"
            :value="item.id"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="金额:" prop="amount">
          <el-input v-model="form.amount" size="medium" class="w200" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注:"  prop="remark">
          <el-input v-model="form.remark" size="medium" class="w200"></el-input>
        </el-form-item>
        <div class="mt30 ml30">
          <el-button type="primary" size="medium" @click="onSubmit('form')">{{state}}</el-button>
          <el-button type="primary" size="medium" plain @click="onroute">返回</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        partnerShortName: [{ required: true, message: "不能为空" }],
        amount: [{ required: true, message: "不能为空" }],
        // remark: [{ required: true, message: "不能为空" }]
      },
      state:'充值',//状态
      channllist:[],//合作门店
    };
  },
  created() {
    this.postChannelList()//查询所有渠道
  },
  methods: {
    onSubmit(formdata){
      // console.log(state,'----state---')
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.postChannelWalletTopUp()
        } else {
          return false;
        }
      });
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/channel/streamDetail"
      });
    },
    getWalletByChannelId(){
      this.$api.getWalletByChannelId(this.channelId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
          }
        }
      })
    },
    postChannelWalletTopUp(){//钱包充值
      let query={
        "channelId": this.form.partnerShortName,
        "amount": this.form.amount,
        "remark": this.form.remark
      }
      this.$api.postChannelWalletTopUp(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.$message.success(res.message)
            this.onroute();
          }
        }
      })
    },
    postChannelList(){//查询所有渠道
      this.$api.postChannelList({status:3}).then(res=>{
        if(res.code==0){
          this.channllist=res.data
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .w200{
    width: 200px;
  }
}
</style>