<template>
  <div class="pageContainer">
    <!--车辆信息-->
    <div class="content_modal_textBox pb100">
      <div class="ckxTitle mb10">基本信息</div>
     <el-row>
       <el-col :span="5">车牌号：{{form.carNo}}</el-col>
       <el-col :span="5">办证类型：{{form.certTypeName}}</el-col>
       <el-col :span="5">品牌车型：{{form.brandName}}{{form.modelName}}</el-col>
       <el-col :span="5">产证编号：{{form.certNo}}</el-col>
       <el-col :span="4">车辆所在库：{{form.carWarehouseName}}</el-col>
     </el-row>
      <div class="ckxTitle mb10 mt30">办证信息-商户方</div>
      <el-row>
        <el-col :span="5">商户姓名：{{form.carMerchant.merchantName}}</el-col>
        <el-col :span="5">商户电话：{{form.carMerchant.merchantMobile}}</el-col>
        <el-col :span="5"><el-button type="primary" size="mini" plain v-if="form.carMerchant.merchantMobile" @click="callPhone(form.carMerchant.merchantMobile)">呼叫</el-button></el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="5">商户维护人员： {{form.carMerchant.maintainName}}</el-col>
        <el-col :span="5">维护人电话：{{form.carMerchant.maintainMobile}}</el-col>
        <el-col :span="5"><el-button type="primary" size="mini" plain v-if="form.carMerchant.maintainMobile" @click="callPhone(form.carMerchant.maintainMobile)">呼叫</el-button></el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="5">商户办证截止日期：{{res.merchantDeadline}}</el-col>
        <el-col :span="5">经营城市：{{form.carMerchant.businessCityName}}</el-col>
      </el-row>
      <div class="ckxTitle mb10 mt30">商户办证申请</div>
      <el-row>
        <el-col :span="20" class="">
          <span class="mr10">办证办理地:</span>
          <span v-if="state">{{res.provinceName}}{{res.cityName}}{{res.areaName}}</span>
          <el-cascader v-else @change="changeAddress" ref="address"
              v-model="address" :props="props"
              :options="options"></el-cascader>
        </el-col>
      </el-row>
      <div class="justifySpace flex_align_center">
        <div class="ckxTitle mb10 mt30">办证跟踪任务</div>
        <div class="addTask" @click="dialogVisible=true" v-if="!state">新建跟踪任务</div>
      </div>
      <el-table :data="certMaterialTraceList" border stripe class="mt10 table-mainbg">
      <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
      <el-table-column prop="dataType" align="center" label="数据类型"></el-table-column>
      <el-table-column prop="traceName" align="center" label="任务名称"></el-table-column>
      <el-table-column prop="appointFinishTime" align="center" label="约定完成时间"></el-table-column>
      <el-table-column prop="traceTime" align="center" label="跟踪时间"></el-table-column>
      <el-table-column prop="traceStatusName" align="center" label="跟进状态"></el-table-column>
      </el-table>
      
    </div>
    <div class="footer" v-if="!state">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" >保存</el-button>
        <el-button type="primary" @click="onReturn">取消</el-button>
      </div>
    </div>
    <el-dialog
      title="新建跟踪任务"
      :visible.sync="dialogVisible"
      width="500px" class="customDialog">
      <el-form ref="forms" :model="forms" :rules="rules" label-width="130px">
        <el-form-item label="任务名称" prop="itemName">
          <el-input v-model="forms.itemName" class="w300"></el-input>
        </el-form-item>
        <el-form-item label="约定完成时间" prop="date">
          <el-date-picker type="datetime" placeholder="选择日期" v-model="forms.date" value-format="yyyy-MM-dd HH:mm:ss" class="w300"></el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="forms.remark" class="w300"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitModel">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        carMerchant:{},
      },
      res:{},
      rules: {
        date: [{ required: true, message: '不能为空！', trigger: 'blur' }],
        itemName: [{ required: true, message: '不能为空！', trigger: 'blur' }],
      },
      options:[],//城市
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      dialogVisible:false,
      certMaterialTraceList:[],
      state:0,//1查看
      taskId:'',
      address:[],
      addressText:[],
      forms:{},
      userData:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
    };
  },
  created() {
    //软电话是否登陆
    // this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    if(this.$route.query.state) this.state=this.$route.query.state;
    if(this.$route.query.id) this.taskId=this.$route.query.id;
    this.getCertificateTaskDetails()
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.areaList.length==0){
      this.$datadic.getProvince(0).then(res=>{
        this.options=res;
      })
    }else{
      this.options=this.$store.state.setting.areaList;
    }
  },
  methods: {
    getCertificateTaskDetails() {//获取详情
      this.$api.getCertificateTaskDetails(this.taskId).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = res.data;
            this.form=res.data.car;
            if(this.state){
              this.address=[this.res.provinceName,this.res.cityName,this.res.areaName]
            }
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            //办证跟踪任务列表
            this.certMaterialTraceList=res.data.certMaterialTraceList;
          }
        }
      });
    },
    changeAddress(){
      let node = this.$refs.address.getCheckedNodes()
      this.addressText=node[0].pathLabels
    },
    onSubmit(){
      let query={
        taskId:this.taskId,
        provinceId:this.address.length>0?this.address[0]:'',
        provinceName:this.addressText.length>0?this.addressText[0]:'',
        cityId:this.address.length>0?this.address[1]:'',
        cityName:this.addressText.length>0?this.addressText[1]:'',
        areaId:this.address.length>0?this.address[2]:'',
        areaName:this.addressText.length>0?this.addressText[2]:'',
        taskApplyResult:'com_apply'
      }
      this.$api.postCertificateTaskOpera(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getCertificateTaskDetails();//详情
        }
      })
    },
    onSubmitModel(){//提交任务
      this.$refs['forms'].validate(valid => {
        if (valid) {
          let query={
            "certTaskId": this.taskId,
            "carId": this.res.carId,
            "traceName": this.forms.itemName,
            "appointFinishTime": this.forms.date,
            "remark": this.forms.remark
          }
          this.$api.postTraceMaterialAdd(this.$$.delete_null_properties(query)).then(res=>{
            if(res.code==0){
              this.getCertificateTaskDetails(); //获取详情
              this.$message.success(res.message)
              this.dialogVisible=false;
            }
          })
        } else {
          return false;
        }
      });
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/certificationCompany/certificateApplication'
      })
    },
    callPhone(row) {
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row)
      // })//根据员工工号查询坐席信息(拨打电话)
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 30px 50px;
  .addTask{
    cursor:pointer;
    color: #409EFF;
  }
}

</style>