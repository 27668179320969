<template>
  <div>
  <el-tabs value="first">
    <el-tab-pane label="服务记录" name="first">
      <el-table :data="serviceNoteListNew" max-height="500" border>
        <el-table-column prop="id" label="编号" align="center"></el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="180" align="center"></el-table-column>
        <el-table-column  prop="title" label="标题" width="170" align="center">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" width="200"  >
          <template slot-scope="scope">
            <div v-html="scope.row.content" @click="triggerClick">{{scope.row.content}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="serviceUserName" label="坐席"></el-table-column>
        <el-table-column prop="carNo" label="车牌号" width="115"></el-table-column>
        <el-table-column prop="sessionId" label="录音">
          <template slot-scope="scope">
            <div class="el-icon-video-play playIcon" @click="onPlay(scope.row,scope.$index)"  v-if="scope.row.sessionId&&scope.row.sessionId.indexOf('medias')!=-1"></div>
            <!-- <div class="el-icon-video-pause playIcon" @click="onPause(scope.row,scope.$index)"  v-if="scope.row.play"></div> -->
            <!--controls-->
            <!-- <audio :src="scope.row.ossCallRecordUrl" :ref="`audio${scope.$index}`" :autoplay="scope.row.ossCallRecordUrl?true:false" v-if="scope.row.ossCallRecordUrl"></audio> -->
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="检测预约" name="second">
      <el-table :data="customerCheckBookList" max-height="500" border>
        <el-table-column prop="checkBookNo" label="预约号"></el-table-column>
        <el-table-column prop="checkTypeName" label="检测类型"></el-table-column>
        <el-table-column prop="checkBookTaskStatus" label="状态" ></el-table-column>
        <el-table-column prop="bookTime" label="预约时间"></el-table-column>
        <el-table-column prop="bookAddress" label="检测地址"></el-table-column>
        <el-table-column prop="checkUserName" label="坐席"></el-table-column>
        <el-table-column prop="substituteUserName" label="T坐席"></el-table-column>
        <el-table-column prop="submitTime" label="录入时间"></el-table-column>
        <el-table-column prop="phaseName" label="阶段"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small" v-if="scope.row.checkStatus===0" @click="onClose(scope.row)">取消</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="成交预约" name="third">
      <el-table :data="customerDealBookList" max-height="500" border>
        <el-table-column prop="id" label="预约号"></el-table-column>
        <!-- <el-table-column prop="customerStatusName" label="状态" ></el-table-column> -->
        <el-table-column prop="bookTime" label="预约时间"></el-table-column>
        <el-table-column prop="bookShop" label="门店"></el-table-column>
        <el-table-column prop="bookStatusName" label="预约结果"></el-table-column>
        <el-table-column prop="traderName" label="座席"></el-table-column>
        <el-table-column prop="submitTime" label="录入时间"></el-table-column>
        <el-table-column prop="phaseName" label="阶段"></el-table-column>
        <el-table-column label="操作" width="160px" v-if="res.phase>4&&res.dealBookStatus==4">
          <el-button type="primary" size="mini" plain @click="$router.push({path: '/salesManager/chengjiaoyuyue',query:{dealBookId:res.dealBookId,state: `${res.dealType==1?4:3}`}})">改约</el-button>
          <el-button type="primary" size="mini" plain @click="cancelDeal">取消</el-button>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="相关来电" name="fourth">
      <el-table :data="relatedCallList" max-height="500" border>
        <el-table-column prop="phone" label="来电号码"></el-table-column>
        <el-table-column prop="customerName" label="客户名称"></el-table-column>
        <el-table-column prop="carNo" label="车牌号码"></el-table-column>
        <el-table-column prop="carType" label="旧车车型"></el-table-column>
        <el-table-column prop="lastServiceTime" label="上次修改时间"></el-table-column>
        <el-table-column prop="date" label="操作">
          <template  slot-scope="scope">
            <el-button type="primary" size="mini" plain @click="onRouterCustomer(scope.row)">载入</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="竞价历史" name="fifth">
      <el-table :data="priceNoteList" max-height="500" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="submitTime" label="时间"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column prop="carNo" label="车牌号"></el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="相关信息" name="sixth">
      <div class="info">
        <div class="infoTitle flex flex_between flex_align_center">
          <strong>车辆概况</strong>
          <el-button type="primary" size="mini" @click="onRouter">车检报告</el-button>
        </div>
        <table>
          <tr>
            <td rowspan="3">常显信息</td>
            <td>{{getCar('3109')}}</td>
            <td>{{getCar('3304')}}</td>
            <td>{{getCar('3102')}}</td>
            <td>{{getCar('3201')}}</td>
            <td>{{getCar('3105')}}</td>
          </tr>
          <tr>
            <td colspan="5">{{getCar('3305')}}</td>
          </tr>
          <tr>
            <!--过户转籍限制、车辆产证曾属于、车辆产证现属于、强制报废年限、行驶证、产证状态、号牌状态、发动机舱清洗-->
            <td colspan="5">{{`${getCar('3111')}`?`过户转籍限制:${getCar('3111')}、`:''}}{{`${getCar('3119')}`?`产证曾属于:${getCar('3119')}、`:''}}
              {{`${getCar('3134')}`?`产证现属于:${getCar('3134')}、`:''}}{{`${getCar('3114')}`?`强制报废年限:${getCar('3114')}、`:''}}{{`${getCar('3121')}`?`行驶证:${getCar('3121')}、`:''}}{{`${getCar('3117')}`?`产证状态:${getCar('3117')}、`:''}}{{`${getCar('3118')}`?`号牌状态:${getCar('3118')}、`:''}}{{`${getCar('3135')}`?`发动机舱清洗:${getCar('3135')}`:''}}
              </td>
          </tr>
          <tr>
            <td>外观星级</td>
            <td colspan="2">{{getCar('1501')}}
              <!-- <i class="el-icon-star-on" style="color:#0099FF;font-size:20px;"></i>
              <i class="el-icon-star-on" style="color:#0099FF;font-size:20px;"></i> -->
            </td>
            <td>内饰星级</td>
            <td colspan="2">{{getCar('1502')}}
              <!-- <i class="el-icon-star-on" style="color:#0099FF;font-size:20px;"></i>
              <i class="el-icon-star-on" style="color:#0099FF;font-size:20px;"></i> -->
            </td>
          </tr>
          <tr>
            <td rowspan="2">车况</td>
            <!-- skeletonlist.length>0||skeletonlist.length==0&&devicelist.length>0?2:5 -->
            <td :colspan="2" ><!--装置-->
              <template v-if="skeletonlist.length>0">
              <div class="ml20 mr20 flex" v-for="item in skeletonlist" :key="item.defectItemCode">
                <div>{{item.defectItem}}：</div>
                <div>
                  <div v-for="(items,index) in item.defectTypes"  :key="index" class="flex flex_wrap">
                  <span class="ml10">{{items.defectDesc}}</span>
                  <span class="gestureBox" v-if="items.images" >
                    <el-image 
                      class="miniImg"
                      :src="items.images[0]" 
                      :preview-src-list="items.images">
                    </el-image>
                  </span>
                  <!-- <span class="gestureBox" v-if="items.images" @click="onPreview(items.images)">查看</span> -->
                  <div v-if="items.defectDescs.length>0">
                    <div class="reportMinInt" v-for="row in items.defectDescs" :key="row.defectDescCode">
                      <p class="reportP" :class="getClass('1502',items.defectDescCode,row.defectDescCode)">-{{row.defectDesc}}</p>
                      <span class="gestureBox" v-if="row.images" >
                        <el-image 
                          class="miniImg"
                          :src="row.images[0]" 
                          :preview-src-list="row.images">
                        </el-image>
                      </span>
                    </div>
                </div>
                </div>
                </div>
              </div>
              </template>
              <div v-else>装置</div>
            </td>
            <!-- skeletonlist.length>0?3:5 -->
            <td :colspan="3" ><!--骨架-->
              <template v-if="devicelist.length>0">
              <div class="ml20 mr20 flex" v-for="item in devicelist" :key="item.defectItemCode">
                <div>{{item.defectItem}}：</div>
                <div>
                  <div v-for="(items,index) in item.defectTypes"  :key="index" class="flex flex_wrap">
                    <span class="ml10">{{items.defectDesc}}</span>
                    <span class="gestureBox" v-if="items.images" >
                      <el-image 
                          class="miniImg"
                          :src="items.images[0]" 
                          :preview-src-list="items.images">
                        </el-image>
                    </span>
                    <div v-if="items.defectDescs.length>0">
                      <div class="reportMinInt" v-for="row in items.defectDescs" :key="row.defectDescCode">
                        <p class="reportP" :class="getClass('1502',items.defectDescCode,row.defectDescCode)">-{{row.defectDesc}}</p>
                        <span class="gestureBox" v-if="row.images" >
                          <el-image 
                            class="miniImg"
                            :src="row.images[0]" 
                            :preview-src-list="row.images">
                          </el-image>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </template>
              <div v-else>结构</div>
            </td>
          </tr>
          <tr>
            <!-- <td>车况</td> -->
            <td colspan="5">{{resReport.checkResult}}</td>
          </tr>
        </table>
        <!--办证要求-->
      <!-- <div class="content_modal_textBox">
        <div class="tabFont flex flex_between flex_align_center">
          <div>办证需求</div>
          <el-button type="primary" size="mini" @click="accFlag=!accFlag">{{accFlag?'收起':'展开'}}</el-button>
        </div>
        <el-table :data="certRequireList" class="table-nopadding table-notheadBg" v-show="accFlag">
          <el-table-column label="车开新手续办理上下家到场及需提供证件信" align="center">
            <el-table-column prop="cityName" label="城市" align="center"></el-table-column>
            <el-table-column prop="areaName" label="区县" align="center"></el-table-column>
            <el-table-column label="办证上家需到场" align="center">
              <el-table-column prop="upCustomer.needPresent" label="无需到场" align="center">
                <template slot-scope="scope">
                  <i :class="!scope.row.upCustomer.needPresent?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.notGo" label="可不去有条件" align="center">
                <template slot-scope="scope">
                  <i :class="!scope.row.upCustomer.notGo?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.notGoCondition" label="上家不去条件" align="center"></el-table-column>
              <el-table-column prop="upCustomer.needPresent" label="需到场" align="center">
                <template slot-scope="scope">
                  <i :class="!scope.row.upCustomer.needPresent?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.presentRemark" label="备注" align="center"></el-table-column>
            </el-table-column>
            <el-table-column label="上家身份证件" align="center">
              <el-table-column prop="upCustomer.needOriginal" label="原件" align="center">
                <template slot-scope="scope">
                  <i :class="!scope.row.upCustomer.needOriginal?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.needPicture" label="照片件" align="center">
                <template slot-scope="scope">
                  <i :class="!scope.row.upCustomer.needPicture?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.needCopies" label="复印件" align="center">
                <template slot-scope="scope">
                  <i :class="!scope.row.upCustomer.needCopies?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.idRemark" label="备注" align="center"></el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div> -->
      <div class="tabFont flex flex_between flex_align_center">
        <div>客户概况</div>
        <el-button type="primary" size="mini" @click="competitorsFlag=true">竞品对比</el-button>
      </div>
      <el-table :data="recordList" border>
        <el-table-column prop="operateTime" label="标题" align="center"></el-table-column>
        <el-table-column prop="operator" label="操作时间" align="center"></el-table-column>
        <el-table-column prop="followTime" label="内容" align="center"></el-table-column>
        <el-table-column prop="taskStatusName" label="操作人" align="center"></el-table-column>
      </el-table>
        <!-- <table>
          <tr><td colspan="4"><div class="flex flex_align_center flex_between">
            <b>客户概况</b>
            <el-button type="primary" size="mini">竞品对比</el-button>
          </div></td></tr>
          <tr><td>标题</td><td>操作时间</td><td>内容</td><td>操作人</td></tr>
          <tr><td>备注</td><td>20210629  18:30</td><td>数据错分  1426跟进</td><td>1428蒲昌川</td></tr>
          <tr><td>备注</td><td>20210629  18:30</td><td>比昨天低了7100元</td><td>汪善均-吴宝路</td></tr>
          <tr><td>售前预案</td><td>20210629  18:30</td><td>第一名看车后放弃  客户不接受2、3名</td><td>尹盼盼-吴宝路</td></tr>
        </table> -->
        
      </div>
      <el-dialog
        :visible.sync="competitorsFlag" :fullscreen="true">
        <table class="table-custom">
          <tr>
            <td>竞品</td><td>价格有效期</td><td colspan="2">押金</td><td>过户时间</td><td>门店地址</td><td>优势</td><td>弊端</td>
          </tr>
          <tr>
            <td rowspan="3">天天拍</td>
            <td rowspan="3">四天（含报价当天）四天后交车可与商户协商</td>
            <td>上海牌</td><td>2000（不能协商）</td>
            <td>9个工作日（可协商）</td>
            <td rowspan="3">1、闵行区合川路<br/>2、嘉定区曹安路</td>
            <td rowspan="3">1、电话中可以跟商户加价<br/>如果商户不愿加价的，可跟公司申报补贴（车价≤5000；补500；20万＜车价＞5000；补1000-2000；车价≥20万；补2000）<br/>2、5年以内的车、非冷门车、车价＜10万，骨架五星、公司数＜10万，天天拍自己垫资收购（过户时间；沪牌需延长至15个工作日，外牌工作日不变）</td>
            <td rowspan="3">1、预约中商户放弃的概率较高<br/>2、用高价诱导客户到店后进行议价，到场后挑差异，进行压价<br/>3、电话中放弃或到店不成交不主动赔付200元联华ok卡<br/>4、商户不在合同约定时间内办理完成过户<br/>5、外牌如需车主本人到场配合，商户不予报销往返路费的</td>
          </tr>
          <tr>
            <td>沪C&新能源</td>
            <td>5000（不能协商）</td><td>13个工作日（可协商）</td>
          </tr>
          <tr>
            <td>外牌</td>
            <td>5000（不能协商）</td><td>20个工作日（可协商）</td>
          </tr>
          <tr>
            <td rowspan="2">瓜子</td>
            <td rowspan="2">保卖车条件；<br/>具体看车况，现在对于车龄没有要求了</td>
            <td>保卖</td>
            <td>20%（可协商）</td>
            <td>14个工作日（可协商）</td>
            <td rowspan="2">上门提车路</td>
            <td rowspan="2">虚高价</td>
            <td rowspan="2">保卖：<br/>复检环节出问题，以各类明目进行压价，在尾款20%中扣除，压价不成退车<br/>2、保卖不成功的，卖车时间被耽误对于车价会造成影响售卖<br/>售卖：<br/>1、会接到很多电话，甚至外地电话<br/>2、下家违约概率高（贷款办不出，居住证办不出，家里意见不同意等）<br/>3、过户费用及手续的办理需卖方及买方自行协商解决<br/>4、无第三方担保，交易安全性无保障瓜子总部在北京，如发生法律的纠纷需要去北京打官司，劳命伤财</td>
          </tr>
          <tr>
            <td>售卖</td>
            <td>与买方协商</td><td>与买方协商</td>
          </tr>
        </table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="competitorsFlag = false">关闭</el-button>
        </span>
      </el-dialog>
    </el-tab-pane>
  </el-tabs>
  <el-dialog
      title="取消预约"
      :visible.sync="dialogVisible"
      width="400" class="customDialog">
      <el-form ref="form" :model="itemRow" :rules="rules" label-width="100px" size="medium">
        <el-form-item label="取消原因:" prop="cancelReason">
          <el-select v-model="itemRow.cancelReason" clearable>
            <el-option v-for="item in cancellist" :key="item.id" :label="item.reasonName" :value="item.reasonName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="itemRow.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!--图片预览-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />

    <!--播放录音-->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleAudio"
      width="350px">
      <audio controls ref="audioSrc"></audio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAudio = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
const car=new Map();//车辆返回值（车开新数据）
const photo = new Map()//车辆图片集合
export default {
  name:'cService',
  props: {
    serviceNoteList: {//服务记录
      type: Array
    },
    customerCheckBookList: {//检测预约
      type: Array
    },
    customerDealBookList: {//成交预约
      type: Array
    },
    relatedCallList: {//相关来电
      type: Array
    },
    priceNoteList: {//竞价历史
      type: Array
    },
    res:{//详情所有数据
      type:Object,
      default:function(){
        return {}
      }
    },
    resReport:{//检测报告详情
      type:Object,
      default:function(){
        return {}
      }
    },
    recordList:{//客户概况
      type:Array
    }
  },
  components: {
    ElImageViewer
  },
  data() {
    return {
      url: '',
      certRequireList:[],//办证需求
      accFlag:false,//是否展示办证需求
      competitorsFlag:false,//竞品信息
      skeletonlist:[],//骨架数组
      devicelist:[],//装置数组
      dialogVisible:false,
      dialogVisibleAudio:false,
      rules: {
          cancelReason: [{ required: true, message: '请选择取消原因', trigger: 'change' },]
      },
      cancellist:[],
      itemRow:{},
      showViewer:false,
      srcList:[],
      serviceNoteListNew:[],
      ossCallRecordUrl:'',
      play:false,
    }
  },
  watch:{
    resReport(res){
      if(res.details){
        this.carFun(res); 
        this.photoFun(res.photos)
      }
    },
    serviceNoteList(res){
      this.serviceNoteListNew=JSON.parse(JSON.stringify(res))
      for(let i=0;i<this.serviceNoteListNew.length;i++){
        this.serviceNoteListNew[i].ossCallRecordUrl='';
        this.serviceNoteListNew[i].play=false;
      }
    }
  },
  created(){
    this.certRequireList=this.$store.state.setting.certRequireList;
    this.$nextTick(()=>{
      this.visableFlag=true;
    })
    car.clear();//车辆返回值（车开新数据）
    photo.clear()//车辆图片集合
    this.getCheckBookCannelReason()//获取取消原因
    
  },
  methods: {
    onPlay(row,index){
      // this.play=true;
      // if(row.ossCallRecordUrl){
      //   let obj=JSON.parse(JSON.stringify(row));
      //   obj.play=!obj.play;
      //   this.$set(this.serviceNoteListNew,index,obj)
      //   this.$refs[`audio${[index]}`].play()
      //   return
      // }
      let query={
        mainUniqueId:row.sessionId
      }
      this.$api.getCallRecordByMainUniqueId(query).then(res=>{
        if(res.code==0){
          if(res.data){
            this.dialogVisibleAudio=true;
            this.$nextTick(()=>{
              this.$refs.audioSrc.src=res.data.ossCallRecordUrl;
            })
            // for(let i=0;i<this.serviceNoteListNew.length;i++){
            //   let item=this.serviceNoteListNew[i]
            //   if(i===index){
            //     let obj=item;
            //     obj.play=!obj.play;
            //     obj.ossCallRecordUrl=res.data.ossCallRecordUrl
            //     this.$set(this.serviceNoteListNew,i,obj)
            //   }
            // }
          }else{
            this.$message.warning('当前暂无录音地址!')
          }
        }
      })
    },
    onPause(row,index){
      if(row.ossCallRecordUrl){
        for(let i=0;i<this.serviceNoteListNew.length;i++){
          let item =this.serviceNoteListNew[i];
          if(i===index){
            item.play=!item.play;
            this.$set(this.serviceNoteListNew,i,item)
          }
        }
        this.$refs[`audio${[index]}`].pause()
      }
    },
    onClose(row){
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.dialogVisible=true;
    },
    onPreview(row) {
      this.srcList=row;
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
          if (valid) {
            let query={
              "checkBookId": this.res.checkBookId,
              "cancelReason": this.itemRow.cancelReason,
              "remark": this.itemRow.remark
            }
            this.$api.updateCheckBookCancel(query).then(res=>{
              if(res.code==0){
                this.$message.success(res.message)
                this.dialogVisible=false;
                this.$emit('init')
              }
            })
          }
      });
    },
    getCheckBookCannelReason(){//获取取消原因集合
      this.$api.getCheckBookCannelReason({type:'取消'}).then(res=>{
        if(res.code==0){
          this.cancellist=res.data;
        }
      })
    },
    onRouterCustomer(row){
      let userlist=this.$store.state.user.userData;
      let role=this.$$.roleName(userlist)
      let path='';
      switch(role.name){
        case "检测坐席":
          path='/testService/customer';
          break;
        case '在线客服':
          path='/onlineService/customer';
          break;
        case '帮卖经理':
          path='/salesManager/customer';
          break;
          default:
          path='/testService/customer';
      }
      this.$router.push({
        path: path, 
        query: {
          followUpInfoId: row.followUpId
        }
      })
    },
    triggerClick(event){
      let id = event.target.getAttribute('data-checkReportId')
      if(id){
        this.$router.push({
          path:'/testReviewer/auditReport',
          query:{
            id:id,
            state:3,
            title:'检测报告'
          }
        })
      }
    },
    carFun(res){//itemCode查询
      if(!res.details){
        return
      }
      let newArr=JSON.parse(JSON.stringify(res.details))
       for(let i=0,len=newArr.length;i<len;i++){
         car.set(newArr[i].itemCode,newArr[i])
       }
       this.skeletonlist=car.get('1503')?car.get('1503').checkDefects:[]//骨架
       this.devicelist=car.get('1504')?car.get('1504').checkDefects:[]//装置
    },
    photoFun(res){//图片查询
      for(let item in res){
        photo.set(item,res[item])
      }
    },
    getCar(row,type){
      if(type=='obj'){
        return car.get(row)
      }else if(type=='color'){
        return car.get(row)?car.get(row).color:''
      }else{
        return car.get(row)?car.get(row).checkResult:''
      }
    },
    getPhoto(row){
      return photo.get(row)?photo.get(row):[]
    },
    cancelDeal(){//取消成交
      this.$emit('cancelDeal',2)
    },
    onRouter(){
      if(!this.resReport.id){
        this.$message('当前暂无车辆报告！')
        return
      }
      this.$router.push({
        path:'/testReviewer/auditReport',//车辆报告
        query:{
          state:3,//2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
          id:this.resReport.id
        }
      })
    },
    formatterContent(row){
      if(row.content){
        if(row.content.indexOf('《checkReportId=')!=-1){
          let tit=row.content.split('《checkReportId=')
          let a2=tit[1].split('》')
          let ti=`<template>${tit[0]}${a2[1]}</template>`
          
          console.log(tit,a2)
          return ti
        }else{
          return row.content
        }
      }
      return row.content
    },
    getClass(item,pcode,ccode){//获取class颜色
      let row=car.get(item);
      if(row){
        if(pcode&&!ccode){
          for(let i=0;i<row.checkDefects.length;i++){
            let ii=row.checkDefects[i]
            for(let a=0;a<ii.defectTypes.length;a++){
              let aa=ii.defectTypes[a]
              if(aa.defectDescCode==pcode){
                return aa.color?'tipsColor':'green'
              }
            }
          }
        }else if(pcode&&ccode){
          for(let i=0;i<row.checkDefects.length;i++){
            let ii=row.checkDefects[i]
            for(let a=0;a<ii.defectTypes.length;a++){
              let aa=ii.defectTypes[a]
              if(aa.defectDescCode==pcode){
                for(let j=0;j<aa.defectDescs.length;j++){
                  if(aa.defectDescs[j].defectDescCode==ccode){
                    return aa.defectDescs[j].color?'tipsColor':'green'
                  }
                }
              }
            }
          }
        }else{
          return row.color?'tipsColor':'green'
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.info{
    // background: #DBDBDB;
    height: 500px;
    overflow-y: auto;
    .infoTitle{
      background: #fff;
      height: 50px;
      padding: 0 10px;
    }
    table{
      // background: #DBDBDB;
      border-collapse: collapse;
      width: 100%;
      td{
        border: 1px solid #CCCCCC;
        padding: 5px 10px;
      }
    }
    .tabFont{
      font-size:16px;
      margin:10px 0;
    }
    .imgBox{
      width:50px;
      margin:0 20px 0 10px;
    }
    .gestureBox{
      cursor:pointer;
      color:#409eff;
      margin-left: 10px;
    }
    .miniImg{
      width:25px;
      height:25px;
    }
    
  }
 .playIcon{
  font-size:28px;
  color:#409EFF;
}
</style>
