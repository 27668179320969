<template>
  <!-- 报价 -->
  <div class="quotePrice">
    <el-tabs v-model="activeName"  type="card">
      <el-tab-pane label="报价列表" name="first" class="mt20">
        <el-table :data="tableData1" border class="mt20 table-mainbg" stripe>
          <el-table-column prop="name1" label="询价类型"></el-table-column>
          <el-table-column prop="name2" label="车牌号码"></el-table-column>
          <el-table-column prop="name3" label="年份车型"></el-table-column>
          <el-table-column prop="name4" label="行驶证使用性质"></el-table-column>
          <el-table-column prop="name5" label="车辆评级"></el-table-column>
          <el-table-column prop="name6" label="评级选项"></el-table-column>
          <el-table-column prop="name7" label="消费者预期价"></el-table-column>
          <el-table-column prop="name8" label="最高报价"></el-table-column>
          <el-table-column prop="name9" label="报价有效期"></el-table-column>
          <el-table-column prop="name10" label="审核类型"></el-table-column>
        </el-table>
        <div class="title_top_nameBox mt20">审核价格</div>
        <div>
          <span class="mr40">消费者预期价：1元</span>
          <span class="mr40">有效列表次高价：83000元</span>
          <span class="mr40">请输入审核价格：
            <el-input class="inputSmall">
              <template slot="append">元</template>
            </el-input>
          </span>
          <span>
            担保类型：
            <el-radio-group v-model="radio">
              <el-radio :label="1">垫资成交</el-radio>
              <el-radio :label="2">预约成交</el-radio>
              <el-radio :label="3">中介成交</el-radio>
            </el-radio-group>
          </span>
        </div>
        <div class="ckxTitle mt20">消费者需求表</div>
        <div class="mt10"><el-radio-group v-model="radio1">
          <el-radio :label="1">询价</el-radio>
          <el-radio :label="2">单卖旧车</el-radio>
          <el-radio :label="3">置换</el-radio>
        </el-radio-group></div>
        <div class="mt10"><el-radio-group v-model="radio2">
          <el-radio :label="1">新车车型没有选择</el-radio>
          <el-radio :label="2">新车车型选择中</el-radio>
          <el-radio :label="3">新车车型选择已选定</el-radio>
        </el-radio-group></div>
        <div class="mt10"><el-radio-group v-model="radio3">
          <el-radio :label="1">旧车未评估</el-radio>
          <el-radio :label="2">旧车少量评估（5家内）</el-radio>
          <el-radio :label="3">旧车少量评估（5家以上）</el-radio>
        </el-radio-group></div>
        <div class="mt10"><el-radio-group v-model="radio4">
          <el-radio :label="1">先卖旧车才能买新车</el-radio>
          <el-radio :label="2">可先买新车</el-radio>
        </el-radio-group></div>
        <div class="mt10"><el-radio-group v-model="radio5">
          <el-radio :label="1">马上交易（三天内）</el-radio>
          <el-radio :label="2">近期交易（半月内）</el-radio>
          <el-radio :label="3">不确定交易时间（半月以上）</el-radio>
        </el-radio-group></div>
        <div class="red mt20">场次时间：2021年03月23日 11:00:00</div>
        <div class="ckxTitle mt20">有效经纪人报价列表（前二跳价：1900 占比%）</div>
        <el-table :data="tableData2" border class="mt20 table-mainbg" stripe>
          <el-table-column prop="name1" label="姓名"></el-table-column>
          <el-table-column prop="name2" label="账号"></el-table-column>
          <el-table-column prop="name3" label="电话"></el-table-column>
          <el-table-column prop="name4" label="注册地"></el-table-column>
          <el-table-column prop="name5" label="经营地"></el-table-column>
          <el-table-column prop="name6" label="报价"></el-table-column>
          <el-table-column prop="name7" label="报价时间"></el-table-column>
          <el-table-column prop="name8" label="成交价"></el-table-column>
          <el-table-column prop="name9" label="商户服务费"></el-table-column>
          <el-table-column prop="name10" label="商户维护人"></el-table-column>
          <el-table-column prop="name10" label="售后服务经理"></el-table-column>
          <el-table-column prop="name10" label="标志无效"></el-table-column>
          <el-table-column prop="name10" label="所属市场"></el-table-column>
          <el-table-column prop="name10" label="是否违约"></el-table-column>
          <el-table-column prop="name10" label="商户确认"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="手续状态" name="second" class="mt20">
      </el-tab-pane>
      <el-tab-pane label="车辆描述" name="third" class="mt20">
      </el-tab-pane>
      <el-tab-pane label="车况检测" name="fourth" class="mt20">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      tableData1: [{name1: '检测询价',name2: '沪ET1****',name3: '2012/A4L',name4: '私车-非运营',name5: 'O',name6: '5',name7: '1',name8: '85000',name9: '21-3-25',name10: '系统自动审核'}],
      radio: '',
      radio1: '',
      radio2: '',
      radio3: '',
      radio4: '',
      radio5: '',
      tableData2: []
    }
  },
}
</script>

<style scoped lang="scss">
.quotePrice{
  padding: 20px 40px;
  .inputSmall{
    width: 150px;
  }
  .mr40{margin-right: 40px;}
}
</style>