<template>
  <!-- 库存管理 -->
  <div class="inventory">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="全部" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="待入库" name="second" class="mt20"></el-tab-pane>
      <el-tab-pane label="在库" name="third" class="mt20"></el-tab-pane>
      <el-tab-pane lab
      <el-tab-pane label="已出库" name="fifth" class="mt20"></el-tab-pane>
    </el-tabs> -->
    <el-row :gutter="24" class="flex flex_wrap">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>车牌号码</span>
        <el-input clearable v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="8" :lg="6" class="flex flex_align_center flex_between w5 mb15">
        <span>合同完成日期</span>
        <el-date-picker clearable size="medium" v-model="params.customerDeadline" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd"> </el-date-picker>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>成交门店</span>
        <el-select clearable v-model="params.tradeShopIds" :disabled="shopDisabled" size="medium">
          <el-option v-for="item in storeList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>车主姓名</span>
        <el-input clearable v-model="params.customerName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15">
        <span>车主身份证</span>
        <el-input clearable v-model="params.customerIdNo" size="medium"></el-input>
      </el-col>
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>企业名称</span>
        <el-input clearable v-model="params.companyName"></el-input>
      </el-col> -->
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb15">
        <span>营业执照号</span>
        <el-input clearable v-model="params.businessLicense"></el-input>
      </el-col>
      <el-col :xl="4" :lg="4" class="mb15">
        <el-button type="primary" @click="search" size="medium">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" border class="mt5 table-mainbg" stripe>
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column :formatter="formatCarType" label="车型" align="center"></el-table-column>
      <el-table-column prop="customerContractSignTime" label="客户合同签订时间" align="center"></el-table-column>
      <el-table-column prop="customerName"  label="车主姓名/企业名称" align="center">
        <template slot-scope="scope">{{scope.row.customerName}}{{scope.row.companyName}}</template>
      </el-table-column>
      <el-table-column prop="customerIdNo" label="车主身份证号/营业执照号" align="center">
        <template slot-scope="scope">{{scope.row.customerIdNo}}{{scope.row.businessLicense}}</template>
      </el-table-column>
      <el-table-column prop="customerDeadline" label="客户合同材料完成时间" align="center"></el-table-column>
      <el-table-column label="待入库" v-if="['first','second'].includes(activeName)" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockWaitInStoreCounts">{{scope.row.stockWaitInStoreCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/salesManager/inventoryDetail', query: {id:scope.row.id}})">{{scope.row.stockWaitInStoreCounts}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="在库" v-if="['first','third'].includes(activeName)" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockInStoreCounts">{{scope.row.stockInStoreCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/salesManager/inventoryDetail', query: {id:scope.row.id}})">{{scope.row.stockInStoreCounts}}</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="在途" v-if="['first','fourth'].includes(activeName)" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockOnRoadCounts">{{scope.row.stockOnRoadCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/salesManager/inventoryDetail', query: {stockStatus: 13, id:scope.row.id}})">{{scope.row.stockOnRoadCounts}}</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="已出库" v-if="['first','fifth'].includes(activeName)" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.stockOutStoreCounts">{{scope.row.stockOutStoreCounts}}</span>
          <el-button v-else size="mini" type="primary" plain @click="$router.push({path: '/salesManager/inventoryDetail', query: {id:scope.row.id}})">{{scope.row.stockOutStoreCounts}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        carNo: '',
        customerDeadline: '',
        customerIdNo: '',
        tradeShopIds: '',
        customerName: '',
        companyName: '',
        businessLicense: '',
        stockStatus: '',
      },
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      storeList: [],
      shopDisabled:false,
    }
  },
  created() {
    let user=this.$store.state.user.userData;
    let shopobj=this.$$.roleName(user,'department')
    let role=this.$$.roleName(user)
    if(shopobj) if(shopobj.shop){
      this.params.tradeShopIds=shopobj.shop.id;
    }
    if(role.name==='帮卖经理'){
      this.shopDisabled=true;
    }
    this.handleClick();
    this.getShopList()//获取所有门店下拉框
  },
  methods: {
    getShopList() {
      let query={
        isEnabled:0,
        shopTypeList:[0,3]
      }
      this.$api.getShopList(query).then(res => {
        if(res.code == 0){
          this.storeList = res.data;
        }
      })
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    initData() {
      let params = JSON.parse(JSON.stringify(this.params));
      params.curPage = this.page_index;
      params.pageSize = this.page_size;
      if(params.customerDeadline && params.customerDeadline.length){
        params.customerDeadlineBegin = params.customerDeadline[0];
        params.customerDeadlineEnd = params.customerDeadline[1];
      }else{
        delete params.customerDeadline
      }
      if(params.tradeShopIds){
        params.tradeShopIds=[params.tradeShopIds]
      }
      switch(this.activeName){
        case 'first':
          params.carStockStatus=''
          break;
        case 'second':
          params.carStockStatus=[11]
          break;
        case 'third':
          params.carStockStatus=[12]
          break;
        case 'fourth':
          params.carStockStatus=[13]
          break;
        case 'fifth':
          params.carStockStatus=[14]
          break;
      }
      params = this.$$.delete_null_properties(params)
      this.$api.getStockList(params).then(res => {
        if(res.code === 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
        }else{
          this.tableData=[]
          this.page_total=0;
        }
      })
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    formatCarType(row) {
      return row.brandName+ '-' + row.modelName;
    },
    // formatCustomerName(row) {
    //   return `${row.customerName?row.customerName:'-'}/${row.companyName?row.companyName:'-'}`;
    // },
    // formatCustomerNo(row) {
    //   return `${row.customerIdNo?row.customerIdNo:'-'}/${row.businessLicense?row.businessLicense:'-'}`;
    // }
  }
}
</script>

<style scoped lang="scss">
.inventory{
  padding: 20px 40px;
}
</style>