<template>
  <div class="chengjiaoyuyuedz">
    <div class="ckxTitle">竞拍信息</div>
    <div class="mt20">
      <span class="label">品牌车型：</span><span class="value">长安马自达/CX-5</span>
      <span class="label">客户归属：</span><span class="value">上海</span>
    </div>
    <div class="mt20">
      <span class="label">车牌号码：</span><span class="value">沪A78KI97</span>
      <span class="label">定价时间：</span><span class="value">2019-08-09     10:09:00</span>
    </div>
    <div class="mt20">
      <span class="label">当前成交价：</span><span class="value">225</span>
      <span class="label">价格有效截止日：</span><span class="value">2019-08-09     18:00:00</span>
    </div>
    <div class="mt20 mb20">
      <span class="label">商户名次：</span><span class="value">第2名</span>
      <span class="label">成交类型：</span><span class="value">预约/垫资</span>
    </div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="客户意向">
        <el-radio-group v-model="form.intention">
          <el-radio-button label="同意" v-if="!appointed"></el-radio-button>
          <el-radio-button label="改约" v-else></el-radio-button>
          <el-radio-button label="待定" v-if="!appointed"></el-radio-button>
          <el-radio-button label="放弃"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否沟通" v-show="form.intention=='同意'||form.intention=='改约'">
        <el-radio-group v-model="form.communicate">
          <el-radio-button label="是"></el-radio-button>
          <el-radio-button label="否"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发起门店" v-show="form.intention=='同意'||form.intention=='改约'">
        <span>{{form.initiatedStore}}</span>
      </el-form-item>
      <el-form-item label="成交门店" v-show="form.intention=='同意'||form.intention=='改约'">
        <el-input style="width: 220px;" v-model="form.closedStore"></el-input>
      </el-form-item>
      <el-form-item label="预约成交时间" v-show="form.intention=='同意'||form.intention=='改约'">
        <el-time-select v-model="form.testTime" :picker-options="{step: '00:30'}" placeholder="选择时间"></el-time-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input style="width: 500px;" type="textarea" v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        intention: '同意',
        communicate: '',
        initiatedStore: '吴中路店',
        closedStore: '',
        testTime: '',
        remark: '',
        appointed: '',
      },
    }
  },
  methods: {
    doSubmit() {},
    getAppointed() {
      this.appointed = false;
    }
  },
  created() {
    this.getAppointed()
  }
}
</script>

<style scoped lang="scss">
.chengjiaoyuyuedz{
  padding: 20px 40px;
  .label{
    width: 140px;
    text-align: right;
    display: inline-block;
    margin-left: -40px;
  }
  .value{
    width: 350px;
    display: inline-block;
  }
}
</style>