<template>
  <div class="queryCustomer">
    <el-row :gutter="24" class="flex flex_wrap">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>联系电话</span>
        <el-input clearable v-model="params.phone" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>客户类型</span>
        <el-select clearable v-model="params.customerType" placeholder="请选择" size="medium">
          <el-option v-for="item in $store.state.setting.customerTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>车牌号码</span>
        <el-input clearable v-model="params.carNo" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :xl="6" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>创建时间</span>
        <el-date-picker v-model="params.createTime" size="medium" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
    <!-- </el-row> -->
    <!-- <el-row :gutter="20" style="margin-top: 20px;"> -->
      <el-col :xl="6" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>竞价时间</span>
        <el-date-picker v-model="params.priceTime" size="medium" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>客户编号</span>
        <el-input clearable v-model="params.customerNo" size="medium" placeholder="请输入" type="text"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>优质数据</span>
        <el-select clearable v-model="params.isFine" size="medium" placeholder="请选择">
          <el-option v-for="item in $store.state.setting.isFineList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>服务类型</span>
        <el-cascader clearable v-model="params.serviceTypeId" size="medium" :options="serviceOptions" :props="{ value: 'id', label: 'type', children: 'smallTypes' }"></el-cascader>
      </el-col>
    <!-- </el-row> -->
    <!-- <el-row :gutter="20" style="margin-top: 20px;"> -->
      <el-col :xl="6" :lg="8"class="flex flex_align_center flex_between w5 mb15">
        <span>下次跟进时间</span>
        <el-date-picker v-model="params.followUpTime" size="medium" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w5 mb15">
        <span>最后服务时间</span>
        <el-date-picker v-model="params.updateTime" size="medium" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>渠道来源</span>
        <el-cascader clearable v-model="params.channel" size="medium" :options="channelList" :props="{ checkStrictly: true }"></el-cascader>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>业务状态</span>
        <el-select clearable v-model="params.serviceState" placeholder="请选择" size="medium">
          <el-option v-for="item in $store.state.setting.serviceStateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
    <!-- </el-row> -->
    <!-- <el-row :gutter="20" style="margin-top: 20px;"> -->
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15" v-has-any-permission="['testServiceLearder','admin','serviceManager']">
        <span>检维归属</span>
        <el-input clearable v-model="params.jwer" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15" v-has-any-permission="['testServiceLearder','admin','serviceManager']">
        <span>检测归属</span>
        <el-input clearable v-model="params.checker" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15" v-has-any-permission="['testServiceLearder','admin','serviceManager']">
        <span>成交归属</span>
        <el-input clearable v-model="params.trader" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>数据状态</span>
        <el-select clearable v-model="params.validity" placeholder="请选择" size="medium">
          <el-option  label="无效" :value="0"></el-option>
          <el-option  label="有效（未成交）" :value="2"></el-option>
          <el-option  label="有效（已成交）" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>跟进人</span>
        <el-select clearable v-model="params.followUpUserId" placeholder="请选择" size="medium" filterable>
          <el-option v-for="item in employlist" :key="item.id" :label="`[${item.no}]${item.name}`" :value="item.id"></el-option>
        </el-select>
      </el-col>
       <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>门店归属</span>
        <el-select clearable v-model="params.dealShopId" placeholder="请选择" size="medium" filterable>
          <el-option label="空" :value="9999"></el-option>
          <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2" style="text-align:right;" class="mb15">
        <el-button type="primary" size="medium" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="cList" border stripe class="table-mainbg">
      <el-table-column prop="customerNo" label="客户编号" align="center" width="130"/>
      <el-table-column prop="customerName" label="客户名称" align="center" width="115px"/>
      <el-table-column prop="bigType" label="服务大类" align="center" width="115px"/>
      <el-table-column prop="smallType" label="服务小类" align="center"/>
      <el-table-column prop="channel" label="渠道来源" align="center"/>
      <el-table-column prop="carNo" label="车牌号码" width="100" align="center"/>
      <el-table-column prop="customerType" label="客户类型" align="center" :formatter="formatCType"/>
      <el-table-column prop="nextContactTime" label="下次跟进时间" align="center" width="180" />
      <el-table-column prop="createDate" label="创建时间" width="180" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="onRouter(scope.row)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'queryCustomer',
  data() {
    return {
      params: {
        phone: '',
        customerType: '',
        carNo: '',
        createTime: '',
        priceTime: '',
        customerNo: '',
        isFine: '',
        followUpTime: '',
        updateTime: '',
        channel: '',
        serviceTypeId: [],
        serviceState: '',
        jwer: '',
        checker: '',
        trader: '',
        channel:[],
        followUpUserId:'',
      },
      channelList: [],
      serviceOptions: [],
      cList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      roleObj:{},//获取当前登录用户
      employlist:[],//跟进人
      shoplist:[],//门店列表
    }
  },
  created() {
    this.customerGetTaskType();
    this.customerQueryChannel();
    // this.getData();
    this.roleObj=this.$$.roleName(this.$store.state.user.userData)
    this.postSimpleQueryUserList();//跟进人列表
    this.getShopList()//搜索门店
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getData()
    },
    //服务大小类所有选项
    customerGetTaskType() {
      this.$datadic.getTaskType().then(res => {
        this.serviceOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    //所有渠道
    customerQueryChannel() {
      this.$datadic.getCustomerQueryChannel().then(res => {
        this.channelList = res;
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    getData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        phone: this.params.phone,
        customerType: this.params.customerType,
        carNo: this.params.carNo,
        createStartTime: '',
        createEndTime: '',
        priceStartTime: '',
        priceEndTime: '',
        customerNo: this.params.customerNo,
        isFine: this.params.isFine,
        serviceTypeId: '',
        followUpStartTime: '',
        followUpEndTime: '',
        updateStartTime: '',
        updateEndTime: '',
        channelId: '',
        jwer: this.params.jwer,
        checker: this.params.checker,
        trader: this.params.trader,
        serviceState:this.params.serviceState,
        validity:this.params.validity,
        followUpUserId:this.params.followUpUserId,
        dealShopId:this.params.dealShopId,
      }
      //customerDealStatus 有效并且未成交传递0, 有效并且已成交传递1, 传递了 validity = 1, 才需要传递这个参数
      if(this.params.validity===2){
        params.validity=1;
        params.customerDealStatus=0;
      }else if(this.params.validity===1){
        params.customerDealStatus=1;
      }
      if(this.params.channel && this.params.channel.length > 0){
        if(this.params.channel.length==1){
          params.clueId = this.params.channel[0];
        }else{
          params.clueId = this.params.channel[0];
          params.channelId = this.params.channel[1];
        }
      }
      if(this.params.serviceTypeId.length > 0){
        if(this.params.serviceTypeId.length==1){
          params.serviceTypeId = this.params.serviceTypeId[0];
        }else{
          params.serviceTypeId = this.params.serviceTypeId[1];
        }
      }
      if(this.params.createTime && this.params.createTime.length > 0){
        params.createStartTime = this.params.createTime[0];
        params.createEndTime = this.params.createTime[1];
      }else{
        params.createStartTime = '';
        params.createEndTime = '';
      }
      if(this.params.priceTime && this.params.priceTime.length > 0){
        params.priceStartTime = this.params.priceTime[0];
        params.priceEndTime = this.params.priceTime[1];
      }else{
        params.priceStartTime = '';
        params.priceEndTime = '';
      }
      if(this.params.followUpTime && this.params.followUpTime.length > 0){
        params.followUpStartTime = this.params.followUpTime[0];
        params.followUpEndTime = this.params.followUpTime[1];
      }else{
        params.followUpStartTime = '';
        params.followUpEndTime = '';
      }
      if(this.params.updateTime && this.params.updateTime.length > 0){
        params.updateStartTime = this.params.updateTime[0];
        params.updateEndTime = this.params.updateTime[1];
      }else{
        params.updateStartTime = '';
        params.updateEndTime = '';
      }
      
      let query=this.$$.delete_null_properties(params)
      if(JSON.stringify(query)=='{}'){
        this.$message('查询条件不能为空！')
        return
      }
      this.$api.customerQueryCustomer(query).then(res => {
        if(res.code == 0){
          this.page_total = res.data.total;
          this.cList = res.data.records;
        }
      })
    },
    formatCType(row) {
      return this.$$.arrayLookup(this.$store.state.setting.customerTypeList, row.customerType)
    },
    search() {
      this.page_index = 1;
      this.getData();
    },
    onRouter(row){
      let path='';
      switch(this.roleObj.name){
        case '在线坐席':
        case '在线客服':
          path= '/onlineService/customer';
          break;
        case '检测坐席组长':
        case '检测坐席':
          path= '/testService/customer';
          break;
        case '帮卖经理':
          path= '/salesManager/customer';
          break;
          default:
          path= '/salesManager/customer';
      }
      let pathData=this.$router.resolve({
        path: path, 
        query: {
          followUpInfoId:row.followUpId
        }
      })
      window.open(pathData.href,'_blank')
      // this.$router.push({
      //   path: path, 
      //   query: {
      //     followUpInfoId:row.followUpId
      //   }
      // })
    },
    postSimpleQueryUserList(){//跟进人
      let query={
        isEnabled:0,
        userType:1,//用户类型，1:员工，2商户
        "staffStatus": "on"//在职状态：on在职，off离职
      }
      this.$api.postSimpleQueryUserList(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
    getShopList(){//搜索门店
      let query={
        shopTypeList:[0],//门店类型。0客户成交1商户成交2售后服务3其它。商户成交门店可以约预约成交的车
        isEnabled:0
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.queryCustomer{
  padding: 30px 50px;
}
</style>