<template>
  <div class="workbenchList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      <el-breadcrumb-item>工作台列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="20" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>角色名</span>
        <el-input v-model="params.name"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>角色状态</span>
        <el-select v-model="params.isEnabled">
          <el-option label="全部" value=""></el-option>
          <el-option label="启动" :value="0"></el-option>
          <el-option label="禁用" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-search" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" border stripe class="table-mainbg mt20">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="name" label="工作台名称"></el-table-column>
      <el-table-column prop="remark" label="描述"></el-table-column>
      <el-table-column prop="createDate" label="创建日期"></el-table-column>
      <el-table-column label="状态" width="80px" align="center">
        <template slot-scope="scope">
          <el-switch :value="!scope.row.isEnabled" @change="changeStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="可选部门" :formatter="formatDepartment"></el-table-column>
      <el-table-column prop="name" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain icon="el-icon-edit" @click="handleRow(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <!-- 编辑工作台 -->
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible" center>
      <el-form :model="form" label-width="120px">
        <el-form-item label="工作台名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工作台描述">
          <el-input v-model="form.remark" autocomplete="off" type="textarea" :autosize="{minRows:4}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleRole">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        name: '',
        isEnabled: '',
        roleType: "bench",
      },
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      form: {
        name: '',
        remark: '',
      },
      dialogFormVisible: false
    }
  },
  created() {
    this.getBenchList();
  },
  methods: {
    getBenchList() {
      this.$api.roleQuery(this.$$.delete_null_properties(this.params)).then(res => {
        if(res.code == 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
        }
      })
    },
    formatDepartment(row) {
      if(row.departments && row.departments.length){
        return row.departments.map(item => item.name).join(',')
      }
    },
    search() {
      this.page_index = 1;
      this.getBenchList() //改变页码，重新渲染页面
    },
    handleRow(row) {
      this.currentRow = row;
      this.form.name = row.name;
      this.form.remark = row.remark;
      this.dialogFormVisible = true;
    },
    handleRole() {
      this.currentRow.name = this.form.name;
      this.currentRow.remark = this.form.remark;
      this.$api.roleUpdate(this.currentRow.id, this.currentRow).then(res => {
        if(res.code == 0){
          this.$message.success(res.message)
          this.getBenchList();
          this.dialogFormVisible = false;
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getBenchList()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getBenchList()
    },
    changeStatus(row) {
      let str = '';
      if(!row.isEnabled){
        str = '此操作将停用工作台,是否继续?'
      }else{
        str = '此操作将启用工作台,是否继续?'
      }
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(!row.isEnabled){
          row.isEnabled = '1'
        }else{
          row.isEnabled = '0'
        }
        this.$api.roleUpdate(row.id,row).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.getBenchList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });          
      });
    },
  }
}
</script>

<style scoped lang="scss">
.workbenchList{
  padding: 20px;
}
</style>