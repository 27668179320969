<template>
  <!-- 商户合同签订 -->
  <div class="signContract">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card" class="groupbg">
      <el-tab-pane label="待签订" name="first"></el-tab-pane>
      <el-tab-pane label="客户合同签失败商户处理" name="second"></el-tab-pane>
      <el-tab-pane label="签订中" name="third"></el-tab-pane>
      <el-tab-pane label="签订成功" name="fourth"></el-tab-pane>
      <el-tab-pane label="已收款" name="sixth"></el-tab-pane>
      <el-tab-pane label="签订失败" name="fifth"></el-tab-pane>
    </el-tabs>

    <el-row :gutter="20" class="mt30">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户名称</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w5">
        <span>商户预约时间</span>
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" size="medium" value-format="yyyy-MM-dd"> </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3" v-if="['fifth','third','fourth'].includes(activeName)">
        <span>销售助理</span>
        <el-input v-model="params.sellerAssistantName" size="medium"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" plain size="medium" @click="handleClick">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="tradeShopName" align="center" label="成交门店" ></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center">
        <!-- <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template> -->
        <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="carTradedPrice" label="成交价" align="center" ></el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center" ></el-table-column>
      <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
      <el-table-column prop="appointTradeTime" label="商户预约时间" align="center" v-if="['first','third'].includes(activeName)"></el-table-column>
      <el-table-column prop="merchantMobile" label="商户电话" align="center" >
        <template slot-scope="scope">
          <span>{{scope.row.merchantMobile}}</span>
          <el-button size="mini" type="primary" plain @click="callPhone(scope.row)" v-if="scope.row.merchantMobile">呼叫</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="merchantNo" label="商户编号" align="center" v-if="['second'].includes(activeName)"></el-table-column>
      <el-table-column prop="customerContractSignedFailTime" label="客户合同签订失败时间" align="center" v-if="['second'].includes(activeName)"></el-table-column>
      <!-- <el-table-column prop="isPledgeName" label="抵押车" align="center" v-if="['first','fifth','third','fourth','sixth'].includes(activeName)"></el-table-column> -->
      <el-table-column prop="sellerAssistantName" label="销售助理" align="center" v-if="['fifth','third','fourth','sixth'].includes(activeName)"></el-table-column>
      <el-table-column prop="financeReceiveTime" label="收款完成时间" align="center" v-if="['sixth'].includes(activeName)"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onSee(scope.row)">载入合同</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 20,
      activeName: 'first',
      params: {
        name: '',
        carNo: '',
        createTime: '',
        sellerAssistantName: '',
      },
      querylist:[],
      userData:{},
      cno:'',
    }
  },
  created(){
    if(this.$route.params.state) this.activeName=this.$route.params.state;
    if(this.$route.query.state) this.activeName=this.$route.query.state;
    this.userData=this.$store.state.user.userData;
    this.init()
  },
  methods: {
    init(){
      let query = {
        curPage: this.page_index,
        pageSize: this.page_size,
        carNo: this.params.carNo,
        sellerAssistantName: this.params.sellerAssistantName, //销售助理名称
        merchantName: this.params.merchantName, //商户名称
        beginAppointTradeDate: this.params.createTime?this.params.createTime[0]:'', //预约成交日期begin
        endAppointTradeDate: this.params.createTime?this.params.createTime[1]:'', //预约成交日期end
        // contractStatus:[contractStatus]//商户合同状态，21待签订，22签订中，23签订成功，24签订失败，25待付款，26已付款,27客户合同签订失败商户处理
      };
      switch (this.activeName) {
        case "first":
          query.contractStatus=[21];
          query.sellerAssistantName='';
          break;
        case "second":
          query.contractStatus=[27];
          break;
        case "third":
          query.contractStatus=[22];
          break;
        case "fourth":
          query.contractStatus=[23];
          query.financeReceiveStatus=41;
          break;
        case "fifth":
          query.contractStatus=[24];
          break;
        case "sixth":
          query.financeReceiveStatus=43;
          break;
      }
      this.$api.getMerchantList(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.page_total = res.data.total;
          }else{
            this.querylist = [];
            this.page_total = 0;
          }
        }
      });
    },
    handleClick() {
      this.page_index=1;
      this.init()
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onSee(row){
      this.$router.push({
        path: '/salesAssistant/merchantContract',
        query:{
          id:row.id
        }
      })
    },
    callPhone(row) {
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row)
      // })//根据员工工号查询坐席信息(拨打电话)
    },
    postCallout(row){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:row.merchantMobile,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        if(this.cno){
          resolve(true)
          return
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
  }
}
</script>

<style scoped lang="scss">
.signContract{
  padding: 20px 40px;
}
</style>
