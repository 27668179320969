<template>
  <div class="certificateMaterBox">
    <el-row :gutter="24">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w5">
        <span>客户付款状态</span>
        <el-select v-model="forms.customerPayStatuses">
          <el-option
            v-for="item in paymentStatuslist"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col> -->
      <el-col :span="7" class="flex flex_align_center flex_between w5">
        <span>预约成交时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain
          >查询</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column
        prop="customerContractNo"
        label="合同编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carNo"
        label="车牌号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carBrandModelName"
        label="车型"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ccTradePrice"
        label="成交价"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="tradeTypeName"
        label="成交类型"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="appointTradeTime"
        label="预约成交时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalPayAmount"
        label="应付金额"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financePayStatusName"
        label="付客户车款状态"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerReceiptStatusName"
        label="客户退车收款"
        align="center"
      >
        <template slot-scope="scope">{{scope.row.financeGatheringStatus===82?'已收退车款':'-'}}</template>
      </el-table-column>
      <el-table-column
        prop="financePayTime"
        label="付客户车款时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financeMerchantGatheringStatusName"
        label="收商户车款状态"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="scope.row.financeGatheringStatus===81">收客户退车车款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        data: "",
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      paymentStatuslist: [
        { name: "请选择", value: "" },
        { name: "付款成功", value: 33 },
        { name: "已退车", value: 81 },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        financePayStatuses:[33],
        financeGatheringStatuses:[81,82],//退车款财务收款状态，81未收款，82已收款
        // customerPayStatuses:[33],//客户付款状态，33付款成功，81未收款，82已收款
        appointTradeBeginTime:this.forms.date?this.forms.date[0]+' 00:00:00':'',
        appointTradeEndTime:this.forms.date?this.forms.date[1]+' 23:59:59':'',
      };
      this.$api.postCustomerTradeQuery(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onapply(row) {
      this.$confirm("确认客户退车款已收到", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postFinanceGathering(row.id)
        })
        .catch(() => {});
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
    postFinanceGathering(id){//财务收退车款
      this.$api.postFinanceGathering(id).then(res=>{
        if(res.code==0){
          this.init()
          this.$message.success(res.message)
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.certificateMaterBox {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>