<template>
  <div class="handleExceptions">
    <div class="title">物流发货单</div>
    <div class="line">发货单号：{{noteInfo.logistNo}}</div>
    <div class="ckxTitle mb10 mt20">发货信息</div>
    <el-row class="mb10">
      <el-col :span="6">发货方：{{noteInfo.tradeShopName}}</el-col>
      <el-col :span="6">收货方：{{noteInfo.toShopName}}</el-col>
      <el-col :span="6">发货方式：{{noteInfo.logistTypeName}}</el-col>
      <el-col :span="6"></el-col>
    </el-row>
    <el-row class="mb10">
      <!--物流方式，11代驾，12车开新，13快递-->
      <template v-if="noteInfo.logistType==13">
        <el-col :span="6">快递公司：{{noteInfo.expressCompName}}</el-col>
        <el-col :span="6">快递单号：{{noteInfo.expressLogistNo}}</el-col>
      </template>
      <template v-if="noteInfo.logistType==12">
        <el-col :span="6">员工姓名：{{noteInfo.empName}}</el-col>
        <el-col :span="6">手机号：{{noteInfo.empMobile}}</el-col>
      </template>
      <template v-if="noteInfo.logistType==11">
        <el-col :span="6">代驾姓名：{{noteInfo.agencyName}}</el-col>
        <el-col :span="6">代驾手机：{{noteInfo.agencyMobile}}</el-col>
      </template>
      <el-col :span="6">发货时间：{{noteInfo.fromTime}}</el-col>
      <el-col :span="6">预计到达时间：{{noteInfo.preArriveTime}}</el-col>
    </el-row>
    <div class="ckxTitle mt20 mb10">接货信息</div>
    <el-row>
      <el-col :span="6">接货人：{{noteInfo.toName}}</el-col>
      <el-col :span="6">接收时间：{{noteInfo.toReceiveTime}}</el-col>
    </el-row>
    <template v-if="car">
      <div class="ckxTitle">车辆信息</div>
      <el-table :data="carlist" border class="mt10 mb20 table-mainbg" stripe ref="multipleTable">
        <!-- <el-table-column type="selection" width="55" align="center" ></el-table-column> -->
        <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column label="品牌车型" align="center">
          <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
        </el-table-column>
        <el-table-column prop="carColor" label="颜色" align="center"></el-table-column>
        <el-table-column prop="registerDate" label="初次登记日期" align="center"></el-table-column>
        <el-table-column prop="checkExpire" label="年检有效期" align="center"></el-table-column>
        <!-- <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column> -->
      </el-table>
    </template>
    <div class="ckxTitle mt20 mb10">异常手续材料信息</div>
    <el-table :data="logistMaterialsList" border class="table-mainbg" stripe>
      <el-table-column align="center" type="index"></el-table-column>
      <el-table-column align="center" prop="carNo" label="车牌号"></el-table-column>
      <el-table-column align="center" prop="materialName" label="项目"></el-table-column>
      <el-table-column align="center" prop="sendNums" label="数量"></el-table-column>
      <el-table-column prop="name" label="操作" align="center" width="200px">
        <template slot-scope="scope">
          <div v-if="scope.row.exceptionHandling">{{scope.row.exceptionHandling}}</div>
          <template v-if="!scope.row.exceptionHandling&&scope.row.logistStatus===15">
            <el-button size="mini" plain type="primary" @click="handle('核实未发出',scope.row.materialsId,scope.$index)">核实未发出</el-button>
            <el-button size="mini" plain type="primary" @click="handle('遗失',scope.row.materialsId,scope.$index)">遗失</el-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="contractRequiredNums" label="异常描述">
        <template slot-scope="scope">
          <div v-if="scope.row.exceptionHandling">{{scope.row.exceptionDesc}}</div>
          <el-input v-model="logistMaterialsList[scope.$index].exceptionDesc" v-if="!scope.row.exceptionHandling&&scope.row.logistStatus===15"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logistMaterialsList: null,
      noteInfo: {
        tradeShopName: '',
        toShopName: '',
        logistTypeName: '',
        agencyName: '',
        fromTime: '',
        agencyMobile: '',
        preArriveTime: '',
        toName: '',
        toReceiveTime: '',
      },
      car:{},
      carlist:[],
    }
  },
  created() {
    this.logisticsDetail();
  },
  methods: {
    logisticsDetail() {
      this.$api.logisticsDetail(this.$route.query.id,{businessType: "logistics"}).then(res => {
        if(res.code === 0){
          this.car=res.data.logistCarInfo;
          let car=res.data.logistCarInfo?res.data.logistCarInfo:{};
          let row={
            id:car.carId,
            carNo:car.carNo,
            brandName:car.brandName,
            modelName:car.modelName,
            carColor:car.carColor,
            registerDate:car.registerDate,
            checkExpire:car.checkExpire,
          }
          this.carlist = [row];
          this.logistMaterialsList = res.data.logistMaterialsList;
          // this.logistMaterialsList.push({id:1})
          this.noteInfo = res.data;
        }
      })
    },
    handle(exceptionHandling,id,index) {
      this.$api.logisticsExceptionHandling(this.$route.query.id, [{
        materialsId: id,
        exceptionHandling: exceptionHandling,
        exceptionDesc: this.logistMaterialsList[index].exceptionDesc
      }]).then(res => {
        if(res.code === 0){
          this.logisticsDetail();
          this.$message.success(res.message);
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.handleExceptions{
  padding: 20px 40px;
  .title{
    text-align: center;
    padding: 0px 0 20px;
    font-size: 24px;
  }
  .line{
    padding: 12px 0 12px 40px;
    margin: 0 -40px;
    background: #EDEDED;
  }
}
</style>
