<template>
  <div class="pageBox">
    <el-row :gutter="24" class="mb30">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>商户姓名:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>办证状态:</span>
        <el-select v-model="params.state" size="medium" clearable>
          <el-option :value="151" label="逾期待审核"></el-option>
          <el-option :value="152" label="逾期审核通过"></el-option>
          <el-option :value="153" label="逾期审核驳回"></el-option>
        </el-select>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg">
      
      <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
      <el-table-column prop="makerName" label="品牌车型" align="center" >
        <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="tradeShopName" label="成交门店" align="center" ></el-table-column>
      <el-table-column prop="merchantNo" label="商户编号" align="center" >
        <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantNo:''}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" label="商户名称" align="center" >
        <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="merchantDeadline" label="办证截止日期" align="center" ></el-table-column>
      <el-table-column prop="certTypeName" label="过户方式" align="center" ></el-table-column>
      <!-- <el-table-column prop="carNo" label="审核时间" align="center" ></el-table-column>
      <el-table-column prop="carNo" label="审核人" align="center" ></el-table-column> -->
      <el-table-column prop="taskOverdueCheckStatusName" label="办证状态" align="center" ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="[151].includes(scope.row.taskOverdueCheckStatus)">审核</el-button>
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-else>查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], 
      total: 0,
      currentPage: 1,
      pageSize: 20,
      params:{
        state:''
      },
    };
  },
  created() {
    if(this.$route.params.state) this.params.state=Number(this.$route.params.state);
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        // taskType: 1,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        merchantName:this.params.merchantName,
        carNo:this.params.carNo,
      };
      if(!this.params.state){//办证专员-办证逾期审核: 逾期待审核151,逾期审核通过152,逾期审核驳回153
        query.taskOverdueCheckStatuses=[151,152,153]
      }else {
        query.taskOverdueCheckStatuses=[this.params.state]
      }
      
      
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onapply(row) {
      let aurl = "/accreditationCommissioner/certificateRemarksDetails";
      let query={
          id: row.id,
        }
      this.$router.push({
        path: aurl,
        query: query
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.pageBox {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>