<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w3 mb10">
        <span>客户编号</span>
        <el-input v-model="form.customerNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3 mb10">
        <span>车牌号码</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10">
        <span>检测师姓名</span>
        <el-input v-model="form.checkerName" size="medium"></el-input>
      </el-col>
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>检测状态</span>
        <el-select v-model="form.checkStatus" size="medium" clearable>
          <el-option v-for="item in checkStatuslist" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col> -->
      <!-- <el-col :span="6" class="flex flex_align_center flex_between w5">
        <span>检测门店/外勤</span>
        <el-select v-model="form.shop" size="medium" clearable>
          <el-option label="外勤" value="0"></el-option>
          <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col> -->
      <el-col :span="6" class="flex flex_align_center flex_between w3 mb10">
        <span>是否外勤</span>
        <el-select v-model="form.isShop" size="medium" clearable>
          <el-option label="是" :value="0"></el-option>
          <el-option label="否" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5 mb10">
        <span>预约检测时间</span>
        <el-date-picker clearable size="medium" v-model="form.date" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w2 mb10">
        <span>车型</span>
        <el-input v-model="form.carType" size="medium"></el-input>
      </el-col>
      <el-col :span="3" class="flex flex_align_center flex_between mb10">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt10 table-mainbg">
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carType" label="车型" align="center"></el-table-column>
      <el-table-column prop="bookTime" label="预约检测时间" align="center"></el-table-column>
      <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column prop="isShop" label="是否外勤" align="center">
        <template slot-scope="scope">{{scope.row.isShop===1?'门店':scope.row.isShop===0?'外勤':scope.row.isShop}}</template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户姓名" align="center"></el-table-column>
      <el-table-column prop="bookAddress" label="详细地址" align="center"></el-table-column>
      <el-table-column prop="checkerName" label="检测师姓名" align="center"></el-table-column>
      <el-table-column prop="checkStatusName" label="调度状态" align="center"></el-table-column>
      <el-table-column prop="" label="检测调度时间" align="center"></el-table-column>
      <el-table-column prop="" label="归属门店" align="center"></el-table-column>
      <el-table-column prop="startCheckTime" label="检测开始时间" align="center"></el-table-column>
      <el-table-column prop="" label="调度次数" align="center"></el-table-column>
      <el-table-column prop="" label="竞拍次数" align="center"></el-table-column>
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="init(1)" size="mini" plain>取消调度</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        carType:'',
        carNo:'',
        isShop:'',
        customerNo:'',
        date:[]
      },
      querylist: [],
      userlist:[],//
      total: 0,
      currentPage: 1,
      pageSize:10,
      shoplist:[],
      checkStatuslist:[],//检测状态
    };
  },
  created() {
    this.checkStatuslist=this.$store.state.setting.checkStatusList2;
    this.init()
    this.getShopList()//获取门店列表
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        customerNo: this.form.customerNo,
        carNo:this.form.carNo,
        isShop:this.form.isShop,
        carType:this.form.carType,
        bookStartTime:this.form.date?this.form.date[0]:'',
        bookEndTime:this.form.date?this.form.date[1]:'',
        checkerName:this.form.checkerName
      };
      this.$api.postSchedulingHistoryList(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = []
            this.total=0;
          }
        }else{
          this.querylist = []
          this.total=0;
        }
      });
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    getShopList(){//获取门店列表
      let query={
        isEnabled:0,
        shopTypeList:[0]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },

  }
};
</script>
<style lang="scss" scoped>

</style>
