<template>
  <div class="pageContainer bgF5" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
    };
  },
  created() {
    // this.queryCheckTaskGroupByTaskStatus();
    this.$nextTick(() => {
      this.pageHeight=this.$$.workbenchHeight('pageCombox')
    })
  },
  methods: {
    queryCheckTaskGroupByTaskStatus() {
      let query={
        taskStatus:'1,3,4,5,8'
      }
      //状态：1:待审核，2:审核通过，3审核驳回，4审核失败，5待签约，6已签约，7签约失败，8已解约，9佣金待审核，11佣金审核通过，12钱包提现待审核，13钱包提现审核成功，14钱包提现审核失败，15违约赔偿待审核，16违约赔偿审核成功
      this.$api.queryCheckTaskGroupByTaskStatus(query).then((res) => {
        if (res.code == 0) {
          let newArr=[];
          let aurl=''
          for(let i=0;i<res.data.length;i++){
            switch(res.data[i].taskStatusName){
              case '次日待排班'://1
                aurl='/inspectorSupervisor/nextDayScheduling'
                break;
              case '当日候补排班'://3
                aurl='/inspectorSupervisor/alternateScheduling'
                break;
              case '差异评定'://4
                aurl='/storeManager/difEvaluation'
                break;
              default:
                aurl='';
            }
            let obj= {
                num: res.data[i].countsOfTaskStatus,
                name: res.data[i].taskStatusName,
                star: 0,
                url:aurl
              }
            newArr.push(obj)
          }
          this.infolist=[{name:"业务任务",list:newArr}];
          // console.log(this.infolist,'-----555---')
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>
