<template>
  <div class="pageContainer">
    <el-form :model="form" :rules="rules" ref="form" size="mini" :show-message="true" :inline-message="true" :hide-required-asterisk="true">
    <div class="agreeBox">
      <h4>二手机动车出售委托定金支付协议</h4>
      <el-form-item label="甲方：" >
        <el-input v-model="form.customerName" :readonly="!see&&[1,2,4].includes(state)||[2].includes(intState)||!see&&[3].includes(state)&&res.financePayStatus===31?false:true" class="inputText"></el-input>
      </el-form-item>
      <p>乙方：上海铠寻旧机动车经纪有限公司</p>
      <p>甲乙双方本着平等、诚信、自愿的原则，就甲方委托乙方出售车辆一事达成如下协议：</p>
      <p>一、委托出售车辆信息</p>
      <p>车牌号：<span class="text_decoration">{{res.carNo}}；</span></p>
      <p>二、委托出售价格</p>
      <p class="text_decoration">{{res.carTradePriceChinese}}</p>
      <p>三、成交定金支付</p>
      <p>乙方将于甲方签订本协议后当天，替乙方平台购车车商先行向甲方支付成交定金人民币壹仟元整（¥1000元）。甲方收款账号如下：</p>
      <el-form-item label="开户姓名：" >
        <el-input v-model="form.accountName" :readonly="!see&&[1,2,4].includes(state)||intState==2||!see&&[3].includes(state)&&res.financePayStatus===31?false:true" class="inputText"></el-input>
      </el-form-item>
      <el-form-item label="银行简称：" class="bankULBoxConter">
        <!-- <el-input v-model="form.boshBankName" :readonly="state==0?true:false" class="inputText bankInput"  @input="onInputBank"></el-input>
        <ul class="bankULBox" v-show="bankVis&&banklist.length>0">
          <li v-for="item in banklist" :key="item.id" @click="onBankTage(item)">{{item.bankName}}</li>
        </ul> -->
        <el-select v-model="form.boshBankName" filterable placeholder="请选择" class="inputText bankInput" clearable :disabled="!see&&[1,2,4].includes(state)||intState==2||!see&&[3].includes(state)&&res.financePayStatus===31?false:true">
          <el-option
            v-for="item in banklist"
            :key="item.bankCode"
            :label="item.bankName"
            :value="item.bankName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户行：" >
        <el-input v-model="form.bankName" :readonly="!see&&[1,2,4].includes(state)||intState==2||!see&&[3].includes(state)&&res.financePayStatus===31?false:true" class="inputText bankInput"></el-input>
      </el-form-item>
      <el-form-item label="账号：" >
        <el-input v-model="form.bankAccount" :readonly="!see&&[1,2,4].includes(state)||intState==2||!see&&[3].includes(state)&&res.financePayStatus===31?false:true"  class="inputText"></el-input>
      </el-form-item>
      <p>四、车辆交易约定</p>
      <p class="flex">1.本协议签订后，甲方承诺最晚于<el-form-item label="" prop="expiryDate" ><el-date-picker
     v-model="form.expiryDate" :readonly="!see&&[1,2,4].includes(state)||intState==2||!see&&[3].includes(state)&&res.financePayStatus===31?false:true"  class="inputText w250"
      type="datetime"
      placeholder="选择日期"
      value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker></el-form-item>前，至预约成交门店办理车辆交易手续。</p>
      <p>2.甲乙双方签订《二手机动车出售委托书》后，定金将自动转为出售车辆车价款的一部分，结算车价款时直接予以抵扣。</p>
      <p>3.如甲方按约定到店成交，非甲方原因导致不成交，定金壹仟元整（¥1000元）将作为乙方对甲方的赔偿。</p>
      <p>4.如果甲方不能在前述承诺时间内至约定门店办理车辆交易手续的，视为甲方违约，甲方应在48小时内向乙方归还定金¥1000元，并再赔偿乙方¥1000元。</p>

      <p v-if="[0,2,3,5,6,4,16].includes(res.state)">状态：{{res.stateName}}</p>
      <p v-if="[3].includes(res.state)">签约时间：{{res.intentionTreatySigningTime}}</p>
      <p v-if="[5].includes(res.state)">付款时间：{{res.paymentMoneyTime}}</p>
      <p v-if="[6].includes(res.state)">客户合同签订时间：{{res.tradeTime}}</p>
      <p v-if="[0,4].includes(res.state)">失败原因：{{res.remark}}</p>
      <p v-if="[16].includes(res.state)">赔偿原因：{{res.compReason}}</p>
    </div>
    <div class="footer">
      <div class="btn">
          <el-button type="primary" @click="onSubmit(1)" v-if="state==1&&!see&&!intState">保存</el-button>
          <el-button type="primary" @click="onSubmit(2)" v-if="!see&&[1,2,4].includes(state)||intState==2||!see&&[3].includes(state)&&res.financePayStatus===31">提交</el-button>
          <el-button type="primary" @click="onCopy" v-if="[2].includes(state)&&!intState">复制链接</el-button>
          <!-- <el-button type="primary" @click="onDownload" v-if="state==0">下载二维码</el-button> -->
          <el-popover placement="top" v-if="[2].includes(state)&&!intState" class="ml20 mr20">
           <div class="macodeBox">
             <el-image class="codeimg" :src="res.qrCodeUrl" fit="cover">
               <div slot="error">暂无图片</div>
             </el-image>
             <!-- <el-button type="primary" @click="onDownload" size="mini" v-if="state==0" plain>下载二维码</el-button> -->
           </div>
            <el-button slot="reference" type="primary"  v-if="[2].includes(state)&&!intState">查看二维码</el-button>
          </el-popover>
          <el-button type="primary" @click="Visible=true"  v-if="[2].includes(state)&&!intState">发送短信</el-button>
          <el-button type="primary" @click="onReturn" >{{state==2?'取消':'返回'}}</el-button>
      </div>
    </div>
    </el-form>
    <el-dialog
    title="发送短信"
    :visible.sync="Visible"
    width="500px">
  <el-form ref="form" :model="auctModal" label-width="130px" size="medium">
    <el-form-item label="选择手机号：">
      <el-checkbox-group v-model="auctModal.iphone">
        <el-checkbox-button label="0">主要电话</el-checkbox-button>
        <el-checkbox-button label="1">次要电话</el-checkbox-button>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="短信模板内容:">
        <p>{{res.smsTemplate}}</p>
      </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="Visible=false" >取消</el-button>
    <el-button type="primary" @click="sendCustomerSms">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        customerName:'',
        accountName:'',
        boshBankName:'',
        bankName:'',
        bankAccount:'',
        expiryDate:''
      },
      rules:{
        expiryDate:[{ required: true, message: '不可为空！', trigger: 'blur' },],
        // customerName:[{ required: true, message: '不可为空！', trigger: 'blur' },],
        // accountName:[{ required: true, message: '不可为空！', trigger: 'blur' },],
        // boshBankName:[{ required: true, message: '不可为空！', trigger: 'blur' },],
        // bankName:[{ required: true, message: '不可为空！', trigger: 'blur' },],
        // bankAccount:[{ required: true, message: '不可为空！', trigger: 'blur' },],
      },
      state:0,//0-查看，2修改，1新建
      see:0,//是否查看
      intentionMoneyId:'',//意向金Id
      dealBookId:'',
      res:{},//返回值
      banklist:[],//银行列表
      bankVis:false,//是否展示银行信息的搜索内容
      Visible:false,
      auctModal:{
        iphone:[]
      },
      intState:'',//1修改重签，2修改付款信息
      /**状态说明
       * 1 待提交意向协议
       * 2 待签订意向金协议
       * 3 意向金协议签订成功
       * 4 意向金协议签订失败
       * 5 意向金付款成功
       * 0 意向金付款失败
       * 6 客户合同签订成功
       * 7 客户合同签订失败
       * 8 待收返还意向金
       * 9 待收客户违约赔偿
       * 10 已收返还意向金
       * 11 已收客户违约赔偿
       * 12 返还意向金已申请坏账
       * 13 客户违约赔偿已申请坏账
       * 14 返还意向金坏账已处理
       * 15 客户违约赔偿坏账已处理
       * 16 赔偿客户违约金
       * **/
    }
  },
  created() {
    if(this.$route.query.see) this.see = this.$route.query.see;//是否查看
    if(this.$route.query.intentionMoneyId) this.intentionMoneyId = this.$route.query.intentionMoneyId;
    if(this.$route.query.dealBookId) this.dealBookId = this.$route.query.dealBookId;//
    if(this.$route.query.intState) this.intState = this.$route.query.intState;//意向金操作状态
    if(!this.intentionMoneyId){//state=2,新建意向金,state=1,修改
      this.getIntentionMoneyDetail(this.dealBookId)//获取详情
    }else{
      this.getIntentionMoneyDetail(this.dealBookId,this.intentionMoneyId)//获取详情
    }
    this.postbankHeadList()//银行列表查询
  },
  methods: {
    getCheckReport(){
      this.$api.getCheckReport(this.reportId).then(res =>{
        if(res.code==0){
          this.res=res.data
        }
      })
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/salesManager/intentionMoney/intentionMoney"
      });
    },
    onSubmit(state){
      this.$refs['form'].validate((valid) => {
          if (valid) {
            if(!this.res.id){
              this.postIntentionMoneyAdd(state)
            }else{
              if(this.intState==1){
                state=2;
              }else if(this.intState==2){
                state=3
              }
              this.updateIntentionMoney(state)
            }
          }
        });
    },
    onCopy(){//obj为this
        var url=this.res.agreementUrl;
        var input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', url);
        input.select();
        document.execCommand("copy"); // 执行浏览器复制命令
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            this.$message('复制成功!')
        }
        document.body.removeChild(input);
    },
    onDownload(){
      // 主要是为了名字不重复
      var name = `${new Date().getTime()}.jpg`;
      let a = document.createElement("a");
      a.style.display = "none";
      a.download = name;
      a.href = this.res.qrCodeUrl;
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a)
    },
    getIntentionMoneyDetail(dealBookId,intentionMoneyId){//获取详情
      let query={
        dealBookId:dealBookId,// 当意向金协议Id为空时根据此字段查询基础信息
        intentionMoneyId:intentionMoneyId//意向金Id
      }
      this.$api.getIntentionMoneyDetail(query).then(res =>{
        if(res.code==0){
          this.res=res.data;
          this.state=res.data.state;
          
          this.intentionMoneyId=res.data.id?res.data.id:'';
          this.form.customerName=res.data.customerName;
          this.form.accountName=res.data.accountName;
          this.form.boshBankName=res.data.boshBankName;
          this.form.bankName=res.data.bankName;
          this.form.bankAccount=res.data.bankAccount;
          this.form.expiryDate=res.data.expiryDate;
          
        }
      })
    },
    postIntentionMoneyAdd(state){//意向金协议 新增
    //state,如果是保存就是待提交1，如果是提交就是待签订2
      let query={
        "dealBookId": this.dealBookId,//跟进ID
        "state": state,//意向协议状态
        "customerName": this.form.customerName,//客户姓名(甲方)
        "accountName": this.form.accountName,//开户姓名
        "boshBankName": this.form.boshBankName,//银行简称
        "bankName": this.form.bankName,//开户行
        "bankAccount": this.form.bankAccount,//银行账号
        expiryDate:this.form.expiryDate,//意向金有效期截止时间
      }
      this.$api.postIntentionMoneyAdd(this.$$.delete_null_properties(query)).then(res =>{
        if(res.code==0){
          // this.bus.$emit("closeSelected");
          this.getIntentionMoneyDetail(this.dealBookId)//获取详情
          this.$message.success(res.message)
        }
      })
    },
    updateIntentionMoney(state){//意向金协议 更新
    //state,如果是保存就是待提交1，如果是提交就是待签订2
      let query={
        "dealBookId": this.dealBookId,//跟进ID
        "state": state,//意向协议状态
        "customerName": this.form.customerName,//客户姓名(甲方)
        "accountName": this.form.accountName,//开户姓名
        "boshBankName": this.form.boshBankName,//银行简称
        "bankName": this.form.bankName,//开户行
        "bankAccount": this.form.bankAccount,//银行账号
        expiryDate:this.form.expiryDate,//意向金有效期截止时间
      }
      
      this.$api.updateIntentionMoney(this.intentionMoneyId,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          if(this.state===4){
            this.postIntentionMoneyAlter()
          }else{
            this.getIntentionMoneyDetail(this.dealBookId,this.intentionMoneyId)//获取详情
            this.$message.success(res.message)
          }
        }
      })
    },
    postIntentionMoneyAlter(){//意向金协议签订失败 改为 待签订意向金协议
      const query={
        intentionMoneyId:this.intentionMoneyId
      }
      this.$api.postIntentionMoneyAlter(query).then(res=>{
        if(res.code===0){
          this.getIntentionMoneyDetail(this.dealBookId,this.intentionMoneyId)//获取详情
          this.$message.success(res.message)
        }
      })
    },
    postbankHeadList(){//银行列表查询
      this.$api.postbankHeadList({}).then(res=>{
        if(res.code==0){
          this.banklist=res.data
        }
      })
    },
    sendCustomerSms(){//根据客户信息发送短信
      if(this.auctModal.iphone.length==0){
        this.$message('请选择发送手机号！')
        return
      }
      let query={
        "customerId": this.res.customerId,
        "mainPhone": this.auctModal.iphone.includes('0')?true:false,
        "otherPhone": this.auctModal.iphone.includes('1')?true:false,
        "content": this.res.smsTemplate
      }
      this.$api.postSendCustomerSms(this.res.customerId,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.Visible=false;
          this.$message.success('发送成功')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.pageContainer{
  .agreeBox{
    padding-bottom:140px;
    h4{
      text-align:center;
      font-size:28px
    }
    p{
      margin-bottom:10px
    }
  }
  .inputText{
    border:none;
    border-bottom: 1px solid;
    width:200px;
  }
   ::v-deep .el-input__inner{
    border:none;
    font-size:16px
  }
  .el-form-item{
    margin-bottom:10px
  }
  .bankInput{
    width:450px;
  }
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:90px;
      z-index:1;
      background:#ffffff;
      width:450px;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
}
.macodeBox{
  display:flex;
  flex-direction:column;
  .codeimg{
    width:120px;
    height:120px;
    margin-bottom: 10px;
  }
}
</style>
