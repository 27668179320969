<template>
  <div class="capacity">
    <div clas="flex flex_align_center">
      <!-- <el-tabs v-model="activeName" type="card" @tab-click="init">
        <el-tab-pane label="当日检测" name="first"></el-tab-pane>
        <el-tab-pane label="次日检测" name="second"></el-tab-pane>
      </el-tabs> -->
      <el-radio-group v-model="activeName" @change="init" class="">
        <el-radio-button label="first">当日检测</el-radio-button>
        <el-radio-button label="second">次日检测</el-radio-button>
      </el-radio-group>
      <span class="ml30">提示：蓝色代表当前被占用时间</span>
    </div>
    <el-row :gutter="24" class="mt20">
      
      <el-col :span="16" class="flex flex_align_center w4">
        <span>客户地址：</span>
        <div class="flex">
          <AddressInfo ref="addressInfo" @changeAddress='changeAddress' :address = "params.address"/>
        <el-input v-model.trim="params.bookAddress" class="ml20"  placeholder="详细地址"></el-input>
        </div>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w2">
        <span>门店：</span>
        <el-select clearable v-model="params.shopId" placeholder="请选择">
          <el-option v-for="item in uersCapacityList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-search" @click="init">搜索</el-button>
      </el-col>
    </el-row>
    <CapacityTable :tableList="capacityList" :operable = "true" class="mt20" @init="init"/>
  </div>
</template>

<script>
import AddressInfo from '@/components/common/addressInfo.vue';
import CapacityTable from '@/components/common/capacityTable';
import BMap from 'BMap';

export default {
  components: {
    CapacityTable, AddressInfo
  },
  data() {
    return {
      capacityList: [],
      uersCapacityList:[],
      params: {
        shop: '',
        // address: [9, 107, 1150],
        address:[],
        addressName:'',
        todayOrDay: 0,
        bookAddress: ''
      },
      activeName: 'first',
      customerId:'',
      point:{}
    }
  },
  created() {
    if(this.$route.query.customerId){
      this.customerId = this.$route.query.customerId;
      this.init() //所有门店
    } 
    
    if(this.$route.query.followUpInfoId){
      this.followUpInfoId = this.$route.query.followUpInfoId;
      this.customerQueryCustomerByFollowUpId()
    } 
    this.getShopList()//获取门店
  },
  methods: {
    init() {
      let _this=this;
      let address=`${this.params.addressName}${this.params.bookAddress}`
      if(address){
        var myGeo = new BMap.Geocoder();
        myGeo.getPoint(address, function(point){
          if(point){
            _this.point = point;
          }
          _this.getCheckResourceCapacity()
        },)
      }else{
        this.getCheckResourceCapacity()
      }
    },
    getCheckResourceCapacity(){
      let address=`${this.params.addressName}${this.params.bookAddress}`
      let query={
        address:address,
        isToday:this.activeName=='first'?1:0,
        shopId:this.params.shopId,
        customerId:this.customerId,
        lat:this.point.lat,
        lng:this.point.lng,
      }
      this.$api.getCheckResourceCapacity(this.$$.delete_null_properties(query)).then(res => {
        if(res.code == 0){
          this.capacityList = res.data;
        }
      })
    },
    // 客户详情查询
    customerQueryCustomerByFollowUpId() {
      this.$api.customerQueryCustomerByFollowUpId(this.followUpInfoId).then(res => {
        if(res.code == 0){
          this.customerId=res.data.id;
          // this.init() //所有门店
        }
      })
    },
    changeAddress(val,label) {
      this.params.address = val;
      this.params.addressName=`${label[0]}${label[1]}${label[2]}`;
    },
    getShopList(){
      let query={
        isEnabled:0,
        shopTypeList:[0]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.uersCapacityList=res.data;
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.capacity{
  padding: 40px;
}
</style>
