<template>
<div class="addCustomer">
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="customerForm" size="medium">
    <el-row :gutter="20" style="display:flex;">
      <el-col :span="12">
        <div class="leftInfo">
          <div class="formItem">
            <div class="line">
              <div class="title_top_nameBox mb10">客户基本信息</div>
              <el-row :gutter="24" >
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户名称:" prop="customerName" >
                    <el-input v-model="ruleForm.customerName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item prop="mainPhone" label="主要联系电话: " label-width="125px" required>
                    <el-input v-model="ruleForm.mainPhone" placeholder="请输入手机号" style="width:150px;"></el-input>
                    <el-button class="ml10 metaButton " type="primary" size="medium" plain @click="check">排重</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="渠道来源:" prop="channel">
                    <el-cascader v-model="ruleForm.channel" :props="channeProp" :options="channelList" class="ww100"></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item prop="otherPhone" label="次要联系电话: " label-width="125px">
                    <el-input v-model="ruleForm.otherPhone" placeholder="请输入手机号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="备注:">
                    <el-input type="textarea" v-model="ruleForm.remark" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="formItem">
            <div class="line">
              <div class="title_top_nameBox mb10">经营范围</div>
              
              <div>
                <el-form-item label="车牌号码：" prop="carNo" label-width="110px">
                  <el-input v-model="ruleForm.carNo" class="carNo"></el-input>
                  <el-button type="primary" @click="getCarId" size="mini" plain>生成临牌</el-button>
                  <el-button type="primary" size="mini" plain @click="getCustomerByCarNo">排重</el-button>
                </el-form-item>
              </div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="是否抵押贷款：" label-width="125px">
                    <el-radio-group v-model="ruleForm.isMortgage">
                      <el-radio :label="0">否</el-radio>
                      <el-radio :label="1">是</el-radio>
                      <!-- <el-radio :label="-1">未识别</el-radio> -->
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                 <el-col :xl="12" :lg="24">
                  <el-form-item label="品牌车型：" label-width="110px">
                    <el-cascader class="ww100"
                      v-model="ruleForm.carModel" size="medium"
                      placeholder="请选择"
                      :options="optionsCar"
                      :props="propCar" @visible-change="getBaseCarBrandList"
                      filterable></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
               <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="初登年份：" label-width="110px">
                    <el-date-picker v-model="ruleForm.registrationYear" class="ww100" :picker-options="pickerOptions" type="year" value-format="yyyy" placeholder="选择年"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="formItem">
            <div class="title_top_nameBox mb10">业务信息</div>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="24">
                <el-form-item label="客户类型：" label-width="110px" prop="customerType">
                  <el-select v-model="ruleForm.customerType" placeholder="请选择客户类型" class="ww100">
                    <el-option v-for="item in $store.state.setting.customerTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="24">
                <el-form-item label="下次联系时间：" label-width="140px" prop="nextContactTime">
                  <el-date-picker v-model="ruleForm.nextContactTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xl="12" :lg="24">
                <el-form-item label="服务类型：" label-width="110px" prop="service">
                  <el-cascader clearable v-model="ruleForm.service" :options="serviceOptions" :props="{ value: 'id', label: 'type', children: 'smallTypes' }" class="ww100"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="rightInfo">
          <Record :customTradeBookList="customTradeBookList" :priceNoteList="priceNoteList" :serviceNoteList="serviceNoteList" :customHomeBookList="customHomeBookList" :relativeFollowUpList="relativeFollowUpList" @init="init"></Record>
          <div class="title_top_nameBox serviceRecord">服务记录</div>
          <el-form-item label="记录类型："  label-width="110px">
            <el-cascader clearable v-model="ruleForm.recordType" :options="titleList" :props="{ value: 'id', label: 'title', children: 'smallTitles' }" class="recordTypeWidth"></el-cascader>
          </el-form-item>
          <el-form-item label="内容" class="formContent" label-width="0">
            <el-input type="textarea" v-model="ruleForm.content" :rows="4"></el-input>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
  <div class="footer">
    <div class="btn">
      <!-- <el-button type="primary" @click="once">测试</el-button> -->
      <el-button type="primary" @click="doSubmit('load')">保存</el-button>
    </div>
  </div>
  <el-dialog title="车牌排重结果" :visible.sync="dialogTableVisible">
    <el-table :data="customerList" border stripe >
      <el-table-column property="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column property="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column property="customerName" label="客户姓名" align="center"></el-table-column>
      <el-table-column property="phone" label="联系电话" align="center"></el-table-column>
      <el-table-column property="createDate" label="创建时间" align="center"></el-table-column>
      <el-table-column property="phaseName" label="业务状态" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <el-dialog title="电话排重结果" :visible.sync="phoneVisible">
    <el-table :data="phonelist" border stripe >
      <el-table-column property="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column property="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column property="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column property="customerName" label="客户姓名" align="center"></el-table-column>
      <el-table-column property="phone" label="联系电话" align="center"></el-table-column>
      <el-table-column property="createDate" label="创建时间" align="center"></el-table-column>
      <el-table-column property="phaseName" label="业务状态" align="center"></el-table-column>
      <el-table-column property="lastServiceTime" label="最后服务时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</div>
</template>

<script>
import Record from '@/components/common/cServiceRecord.vue'
export default {
  components: {
    Record
  },
  data() {
    let self = this;
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    const checkSecondPhone = (rule, value, callback) => {
      if(value === ''){
        callback()
      }else if (value && value == self.ruleForm.mainPhone) {
        return callback(new Error('次要联系方式不能与主要联系方式一样'))
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    const carNoValitor = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('车牌号不能为空'))
      } else {
        const reg = /[!@#$%^&*(),.?":。，=·～{}|<> ]/;
        if (!reg.test(value)) {
          callback()
        } else {
          return callback(new Error('车牌号不能输入特殊字符'))
        }
      }
    }
    return {
      ruleForm: {
        customerName: '',
        channel: '',
        remark: '',
        mainPhone: '',
        mainPhoneTel:'',//400呼入
        otherPhone: '',
        carNo: '',
        registrationYear: '',
        isMortgage: '',
        customerType: '',
        service: '',
        nextContactTime: '',
        recordType: '',
        content: '',
        seriesId: '',
        brandId: '',
        carModel:[]
      },
      rules: {
        customerName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        channel: [{ required: true, message: '请选择', trigger: 'change' }],
        // mainPhone:[ { validator: checkPhone, trigger: 'blur' } ],
        // otherPhone: [{ validator: checkSecondPhone, trigger: 'blur' }],
        mainPhone: [{required: true, message: '不能为空',trigger: 'blur' }],
        // otherPhone: [{required: true, message: '不能为空',trigger: 'blur' }],
        carNo: [{ validator: carNoValitor,required: true, trigger: 'blur' }],
        customerType: [{ required: true, message: '请选择', trigger: 'change' }],
        service: [{ required: true, message: '请选择', trigger: 'change' }],
        nextContactTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        recordType: [{ required: true, message: '请选择', trigger: 'change' }],
        content: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        carModel:[{ required: true, message: '请输入客户名称', trigger: 'change' }]
      },
      optionsCar:[],//品牌车型
      propCar:{
        label:'name',
        value:'id',
        children:'carSeriesList',
        checkStrictly: true 
      },
      brandOptions: [],
      seriesOptions: [],
      titleList: [],
      brandLoading: false,
      seriesLoading: false,
      channelList: [],
      channeProp:{
        label:'channelName',
        value:'id',
        children:'channelSubs'
      },
      serviceOptions: [],
      serviceNoteList: [],
      customHomeBookList: [],
      customTradeBookList: [],
      relativeFollowUpList: [],
      priceNoteList: [],
      dialogTableVisible: false,
      customerList: [],
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      mainUniqueId:'',//通话id
      phonelist:[],//客户数据
      phoneVisible:false,
    }
  },
  created() {
    //来电通话专用
    if(this.$route.query.mainUniqueId) this.mainUniqueId=this.$route.query.mainUniqueId;
    if(this.$route.query.tel){
      this.getRspDecryptCustomerPhone(this.$route.query.tel)//返回解密后的客户电话
      this.ruleForm.mainPhoneTel=this.$route.query.tel;
      // this.ruleForm.mainPhone=this.$$.hidePhoneNumber(this.$route.params.tel)
    }

    this.customerGetTaskType();//获取所有服务类型来源
    this.customerGetServiceTitle()//获取所有服务记录类型
    //车辆品牌 列表查询
    // if(this.$store.state.setting.carBrandList.length==0){
    //   this.$datadic.getCarBrand().then(res=>{
    //     this.optionsCar=res;
    //   })
    // }else{
    //   this.optionsCar=this.$store.state.setting.carBrandList;
    // }
    //获取所有渠道来源
    if(this.$store.state.setting.customerChannelList.length==0){
      this.$datadic.getCustomerChannel().then(res=>{
        this.channelList=res;
      })
    }else{
      this.channelList=this.$store.state.setting.customerChannelList;
    }
  },
  methods: {
    getBaseCarBrandList(e){
      if(e){
        this.$api.baseCarBrandList().then((res)=>{
          if(res.code==0){
            this.optionsCar=res.data;
          }
        })
      }
    },
    init(){
      // this.customerQueryCustomerByFollowUpId();//重新获取数据
    },
    check() {
      this.$refs['ruleForm'].validateField('mainPhone',(validMsg)=>{
        if (!validMsg) {
          this.$api.customerExistCustomerPhone(this.ruleForm.mainPhone).then(res => {
            if(res.code  === 0){
              if(!res.data){
                this.$message.success('手机号可以使用');
              }else{
                // this.$message.error('手机号重复');
                this.phoneVisible=true;
                this.getByPhoneList(this.ruleForm.mainPhone)
              }
            }
          })
        }else{
          return false;
        }
      })
    },
    getByPhoneList(phone){//根据客户手机号查询有效客户
      this.$api.getByPhoneList(phone).then(res=>{
        if(res.code==0){
          this.phonelist=res.data;
        }else{
          this.phonelist=[];
        }
      })
    },
    getCarId(){
      this.$api.customerGenerateTempCarNo().then(res => {
        if(res.code == 0){
          this.ruleForm.carNo = res.data;
          this.$refs.ruleForm.validateField('carNo', () => {})
        }
      })
    },
    //服务大小类所有选项
    customerGetTaskType() {
      this.$datadic.getTaskType().then(res => {
        this.serviceOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    getCustomerByCarNo() {
      this.customerList = [];
      this.$refs['ruleForm'].validateField('carNo',(validMsg)=>{
        if (!validMsg) {
          this.$api.getCustomerByCarNoList(this.ruleForm.carNo).then(res => {
            if(res.code  === 0){
              if(res.data.length === 0){
                this.$message.success('车牌号可用')
              }else if(res.data.length > 0){
                this.customerList = res.data;
                this.dialogTableVisible = true
              }
            }
          })
        }else{
          return false;
        }
      })
    },
    goCustomer(followUpId) {
      if(followUpId){
        let path='/testService/customer'//全部跳到详情页面
        let user=this.$$.roleName(this.$store.state.user.userData)
        if(user){
          if(user.name.indexOf('帮卖经理')!=-1){
            path='/salesManager/customer'//全部跳到详情页面
          }else if(user.name.indexOf('在线客服')!=-1){
            path='/onlineService/customer'
          }
        }
        this.$router.push({
          path: path, 
          query: {followUpInfoId: followUpId}
        })
      }else{
        this.$message.warning('客户跟进业务ID缺失')
      }
    },
    doSubmit(type) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            mainPhone: this.mainUniqueId?this.ruleForm.mainPhoneTel:this.ruleForm.mainPhone,//外呼进入的数据和新建的不是一个值
            otherPhone: this.ruleForm.otherPhone,
            remark: this.ruleForm.remark,
            customerName: this.ruleForm.customerName,
            // clueId:this.ruleForm.channel[0],
            channelId: this.ruleForm.channel[1],
            carNo: this.ruleForm.carNo,
            registrationYear: this.ruleForm.registrationYear,
            isMortgage: this.ruleForm.isMortgage,
            makerId:this.ruleForm.carModel.length>0?this.ruleForm.carModel[0]:'',//车品牌厂家ID
            seriesId:this.ruleForm.carModel.length>1?this.ruleForm.carModel[1]:'',//车系ID
            customerType: this.ruleForm.customerType,
            serviceType: this.ruleForm.service[1],
            nextContactTime: this.ruleForm.nextContactTime,
            serviceTitle: this.ruleForm.recordType[1],
            content: this.ruleForm.content,
            sessionId:this.mainUniqueId,//通话唯一标识
            isCallIn:this.mainUniqueId?1:0, //1代表创建新客户, 返回了 followId 传递0
          };
          this.$api.customerNewCustomer(this.$$.delete_null_properties(params)).then(res => {
            if(res.code == 0){
              this.$message.success(res.message)
              if(type === 'load') {
                  // let path='/testService/storeDR';//检测坐席（门店检测预约）
                  let path='/testService/customer'//全部跳到详情页面
                  let user=this.$$.roleName(this.$store.state.user.userData)
                  if(user){
                    if(user.name.indexOf('帮卖经理')!=-1){
                      // path='/salesManager/yuyuejiance';//检测预约（帮卖经理）
                      path='/salesManager/customer'//全部跳到详情页面
                    }
                  }
                  
                  this.bus.$emit("closeSelected",'router');
                  this.$router.push({
                    path: path, 
                    query: {followUpInfoId: res.data}
                  })
                }
              
            }
          })
        } else {
          return false;
        }
      });
    },
    //服务记录类型
    customerGetServiceTitle(){
      this.$api.customerGetServiceTitle().then(res => {
        if(res.code == 0){
          this.titleList = res.data
        }
      })
    },
    getRspDecryptCustomerPhone(customerPhone){//返回解密后的客户电话
      let query={
        customerPhone:customerPhone
      }
      this.$api.getRspDecryptCustomerPhone(query).then(res=>{
        if(res.code===0){
          this.ruleForm.mainPhone=res.data;
        }
      })
    },
        
  }

}
</script>

<style scoped lang="scss">
.addCustomer{
  background: #f5f5f5;
  // min-height: 100vh;
  padding-top: 20px;
  // padding-bottom: 130px;
  .leftInfo, .rightInfo{background: #fff;height: 100%;padding-bottom: 140px;}
  .rightInfo{padding: 30px 40px;}
  .customerForm{
    .formItem{
      padding: 30px 40px 10px;
      .ckxTitle{margin-bottom: 30px;}
      &>.flex:last-child{border-width: 0;}
      .tag-read{margin-left: 20px;}
      .carNo{width: 130px;margin-right: 20px;}
      .ml20{margin-left: 20px;}
      .line{border-bottom: 1px dashed #CCCCCC;}
    }
  }
  .serviceRecord{margin: 40px 0 30px;}
  .recordTypeWidth{width: 322px;}
}
// @media screen and (min-width: 1366px) and (max-width:1760px){
//  .addCustomer .customerForm .formItem{
//     padding-left:20px;
//     padding-right:20px;
//   }
//   .addCustomer .rightInfo{
//     padding-left:20px;
//     padding-right:20px;
//   }
//   #app .metaButton{margin-left:0}
// }
</style>
<style>
.addCustomer .formContent .el-form-item__label{ text-align: left; width: 100px !important;}
.addCustomer .formContent .el-form-item__content{ margin-left: 0px;}

</style>
