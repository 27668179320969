<template>
  <div class="pageContainerBox">
    <el-row :gutter="24">
      <el-col :xl="8" :lg="10" class="flex flex_align_center w3 mb10">
        <span>搜索字段</span>
        <div class="flex ml10">
          <el-select v-model="form.type" size="medium" @change="onChange">
            <el-option label="呼入" :value="1"></el-option>
            <el-option label="外呼" :value="2"></el-option>
          </el-select>
          <el-input v-model="form.phone" size="medium" placeholder="请输入手机号码"></el-input>
        </div>
      </el-col>
      <el-col :xl="10" :lg="14" class="flex flex_align_center w3 mb10">
        <span>呼叫状态</span>
        <div class="flex ml30">
          <el-select v-model="form.status" size="medium" class="w150" clearable>
            <el-option label="座席接听" v-if="form.type==1"  :value="1"></el-option>
            <el-option label="座席未接听" :value="2"></el-option>
            <el-option v-if="form.type==2" label="客户未接听" :value="1"></el-option>
            <el-option v-if="form.type==2" label="双方接听" :value="3"></el-option>
          </el-select>
          <el-date-picker
            v-model="form.date" size="medium"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>员工编号</span>
        <el-input v-model="form.cno" size="medium" placeholder="请输入"></el-input>
      </el-col>
      <!-- <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>员工姓名</span>
        <el-input v-model="form.phone" size="medium" placeholder="请输入"></el-input>
      </el-col> -->
      <el-col :xl="2" :lg="2" class="flex flex_align_center flex_between w4 mb10">
        <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt10 table-mainbg">
      <el-table-column prop="cno" label="员工编号" align="center"></el-table-column>
      <el-table-column prop="clientName" label="员工姓名" align="center"></el-table-column>
      <el-table-column prop="customerNumber" label="主叫号码" align="center">
        <template slot-scope="scope">{{stateCall==1?$$.hidePhoneNumber(scope.row.customerNumber):scope.row.clientNumber}}</template>
      </el-table-column>
      <el-table-column prop="clientNumber" label="被叫号码" align="center">
        <template slot-scope="scope">{{stateCall==2?$$.hidePhoneNumber(scope.row.customerNumber):scope.row.clientNumber}}</template>
      </el-table-column>
      <el-table-column prop="status" label="呼叫状态" align="center"></el-table-column>
      <el-table-column prop="ibRingingDuration" label="振铃时长" align="center">
        <template slot-scope="scope">{{scope.row.ibRingingDuration?$$.getFormatTime(scope.row.ibRingingDuration):''}}</template>
      </el-table-column>
      <el-table-column prop="bridgeDuration" label="通话时长" align="center">
        <template slot-scope="scope">{{scope.row.bridgeDuration?$$.getFormatTime(scope.row.bridgeDuration):''}}</template>
      </el-table-column>
      <el-table-column prop="startTime" label="呼叫时间" align="center">
        <template slot-scope="scope">{{scope.row.startTime?$$.getFormatDate(scope.row.startTime):''}}</template>
      </el-table-column>
      <el-table-column label="管理操作" width="120" align="center">
        <template slot-scope="scope">
          <i class="el-icon-video-play iconButton" @click="onsee(scope.row,1)"></i>
          <i class="el-icon-download iconButton" @click="onsee(scope.row,2)"></i>
          <el-button type="text" @click="onsee(scope.row,3)" size="medium">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="350px">
      <audio controls ref="audioSrc"></audio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {
        type:1,
        date:[],
        status:'',
        cno:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dialogVisible:false,
      stateCall:1,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    onChange(){
      this.form.status='';
    },
    init(page) {
      this.currentPage=page?page:this.currentPage;
      if(this.form.date.length==0){
        this.$message.warning('查询时间不可为空！')
        return
      }
      this.stateCall=this.form.type;
      if(this.form.type==1){
        this.postPageCdrIbs()//查询呼入通话记录列表
      }else{
        this.postPageCdrObs()//查询外呼通话记录列表
      }
     
    },
    postPageCdrIbs(){//查询呼入通话记录列表
      let query={
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        customerNumber:this.form.phone,
        startTime:this.form.date?`${this.form.date[0]} 00:00:00`:'',//
        endTime:this.form.date?`${this.form.date[1]} 23:59:59`:'',
        status:this.form.status,
        cno:this.form.cno,
      }
      this.$api.postPageCdrIbs(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          if(res.data){
            let data=JSON.parse(res.data)
            this.querylist = data.cdrIbs;
            this.total = data.totalCount;
          }
        }else{
          this.querylist = []
          this.total=0;
        }
      })
    },
    postPageCdrObs(){//查询外呼通话记录列表
      let query={
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        customerNumber:this.form.phone,
        startTime:this.form.date?`${this.form.date[0]} 00:00:00`:'',//
        endTime:this.form.date?`${this.form.date[1]} 23:59:59`:'',
        status:this.form.status,
        cno:this.form.cno,
      }
      this.$api.postPageCdrObs(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
         let data=JSON.parse(res.data)
          this.querylist = data.cdrObs;
          this.total = data.totalCount;
        }else{
          this.querylist = []
          this.total=0;
        }
      })
    },
    onsee(row,state) {
      switch(state){
        case 1:
          this.getCallRecordPlay(row.uniqueId)
          break;
        case 2:
          this.getCallRecordPlay(row.uniqueId,1)
          break;
        case 3:
         this.getCustomerByPhone(row)
          break;
      }
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    getCustomerByPhone(row){//根据手机号查询最后一个客户记录
      let userData=this.$store.state.user.userData;
      let roleName=this.$$.roleName(userData)
      this.$api.getCustomerByPhone(row.customerNumber).then(res=>{
        if(res.code==0){
          if(res.data){
            let path="/testService/customer";
            if(roleName.name=='帮卖经理'){
              path='/salesManager/customer';
            }
            let pathData=this.$router.resolve({
              path:path,
              query: {
                followUpInfoId:res.data.followUpInfoId
              }
            })
            window.open(pathData.href,'_blank')
           
          }else{//客户不存在时的处理
            if(userData.no==row.cno){
              this.$confirm('客户不存在，请点击下方新建按钮新建客户！', '提示', {
                confirmButtonText: '新建',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                let pathData=this.$router.resolve({
                  path:'/testService/addCustomer',
                  query:{
                    mainUniqueId:row.uniqueId,
                    tel:row.customerNumberEncrypt
                  },
                })
                window.open(pathData.href,'_blank')
              }).catch(() => {});
            }else{
              this.$message.warning('客户不存在！');
            }
          }
        }
      })
    },
    getCallRecordPlay(uniqueid,state){
      let query={
        mainUniqueId:uniqueid
      }
      this.$api.getCallRecordByMainUniqueId(query).then(res=>{
        if(res.code==0){
          if(res.data){
            if(state==1){
              window.open(res.data.ossCallRecordUrl)
            }else{
              this.dialogVisible=true;
              this.$nextTick(()=>{
                this.$refs.audioSrc.src=res.data.ossCallRecordUrl;
              })
            }
          }else{
            this.$message.warning('当前记录暂无录音！')
          }
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainerBox {
  padding:20px 40px 40px;
  .iconButton{
    font-size:18px;
    cursor:pointer;
    margin-right: 10px;
  }
  .iconButton:hover{
    color:#409EFF;
  }
}
</style>
