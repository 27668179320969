<template>
  <!-- 竞拍产品设置 -->
  <div class="productSetting">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>客户编号</span>
        <el-input v-model="params.customerNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>品牌车型</span>
        <el-input v-model="params.carType" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w4">
        <span>预约时间</span>
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" size="medium" @click="init(1)">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column prop="isMortgage" label="是否抵押车" align="center">
        <template slot-scope="scope">{{scope.row.isMortgage?'是':'不是'}}</template>
      </el-table-column>
      <el-table-column prop="bookTime" label="预约时间" align="center"></el-table-column>
      <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
        <el-button type="primary" @click="setProduct(scope.row)" size="mini">竞拍产品设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="total"
    ></el-pagination>

    <el-dialog title="竞拍产品设置" :visible.sync="dialogVisible" width="400px">
      <div style="text-align:center;">
        <el-radio-group v-model="auctionType">
          <el-radio-button label="1">常规竞拍</el-radio-button>
          <el-radio-button label="2">及时拍</el-radio-button>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="putSetAuctionType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carType: '',
        carNo: '',
        customerNo:'',
        createTime: '',
      },
      querylist: [],
      page_index: 1, // 页数
      total: 0, // 总页数
      page_size: 10,
      dialogVisible: false,
      auctionType: ''
    }
  },
  created(){
    this.init()
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    init(page){
      this.page_index=page?page:this.page_index;
      let query={
        "curPage": this.page_index,
        "pageSize": this.page_size,
        carNo:this.params.carNo,
        customerNo:this.params.customerNo,
        carType:this.params.carType,
        bookStartTime:this.params.createTime?this.params.createTime[0]:'',
        bookEndTime:this.params.createTime?this.params.createTime[1]:''
      }
      this.$api.queryAuctionSetting(this.$$.delete_null_properties(query)).then(res => {
        if(res.code==0){
          this.querylist=res.data.records;
          this.total=res.data.total;
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    setProduct(row) {
      this.dialogVisible = true;
      this.itmeRow=JSON.parse(JSON.stringify(row));
      this.auctionType='';
    },
    putSetAuctionType(){
      if(!this.auctionType){
        this.$message.warning('请选择竞拍设置！')
        return
      }
      this.$api.putSetAuctionType(this.itmeRow.id,this.auctionType).then(res=>{
        if(res.code==0){
          this.init()
          this.dialogVisible =!this.dialogVisible ;
          this.$message.success(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.productSetting{
  padding: 20px 40px;
}
</style>