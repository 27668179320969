<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="headBox">
      <el-button type="primary" @click="init" size="medium" plain>所有外勤需求</el-button>
        <el-button type="primary" @click="onRouter" size="medium" plain>百度地图</el-button>
        <el-button type="primary" @click="init" size="medium" plain>参数设置</el-button>
    </el-row>
    <el-tabs v-model="activeName" type="card" @tab-click="init">
      <el-tab-pane label="次日外勤" name="first">
        <el-table :data="querylist" border stripe class="table-mainbg mt20">
          <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
          <el-table-column prop="carNo" label="预约单号" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车辆数" align="center"></el-table-column>
          <el-table-column prop="carNo" label="预约单提交时间" align="center"></el-table-column>
          <el-table-column prop="carNo" label="是否外环外" align="center"></el-table-column>
          <el-table-column prop="carNo" label="预约地址" align="center"></el-table-column>
          <el-table-column prop="carNo" label="预约时间" align="center"></el-table-column>
        </el-table>
        <!-- <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        ></el-pagination> -->
      </el-tab-pane>
      <el-tab-pane label="次日外调" name="second">
        <el-row :gutter="20" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>门店</span>
            <el-select v-model="form.carNumber" size="medium" placeholder="请选择门店" clearable>
              <el-option label="11" value="11"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init" size="medium" plain>查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt30">
          <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
          <el-table-column prop="customerContractNo" label="预约单" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车辆数" align="center"></el-table-column>
          <el-table-column prop="carNo" label="提交时间" align="center"></el-table-column>
          <el-table-column prop="carNo" label="是否外环外" align="center"></el-table-column>
          <el-table-column prop="carNo" label="预约地址" align="center"></el-table-column>
          <el-table-column prop="tradeShopName" label="附近门店" align="center"></el-table-column>
          <el-table-column prop="tradeShopName" label="备注" align="center"></el-table-column>
          <el-table-column prop="tradeShopName" label="检测时间" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="180px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onsee(0,scope.row)"
                size="mini"
              >未排上</el-button>
              <el-button
                type="primary"
                @click="onsee(1,scope.row)"
                size="mini"
              >排班</el-button>
              <el-button
                type="primary"
                @click="onsee(2,scope.row)"
                size="mini"
              >排班未排上</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      carNumber:'',
      form: {
        preArriveTime: "",
        remark:'',
      },
      form1:{},
      querylist: [0],
      querylist1:[1],
      total: 0,
      currentPage: 1,
      pageSize:10,
      total1: 0,
      currentPage1: 1,
      pageSize1:10,
      dialogVisible:false,
      title:''
    };
  },
  created(){
    // this.init()
  },
  methods: {
    init() {
      let curPage=this.currentPage;
      let pageSize=this.pageSize;
      let logistStatus=11;
      let carNo='';
      switch(this.activeName){
        case 'first':
          curPage=this.currentPage;
          pageSize=this.pageSize;
          logistStatus=11;
          carNo=this.form.carNo;
          break;
        case 'second':
          curPage=this.currentPage;
          pageSize=this.pageSize;
          logistStatus=12;
          break;
      }
      let query={
        curPage:curPage,
        pageSize:pageSize,
        logistStatus:[logistStatus],//物流状态：11待发货, 12物流中/待收货, 13已接收，14异常
        carNo:carNo,
      }
      this.$api.getLogisticsList(query).then((res)=>{
        if(res){
          if(res.code==0){
          //   switch(this.activeName){
          //     case 'first':
          //       this.querylist = res.data.records;
          //       this.total = res.data.total;
          //       break;
          //     case 'second':
          //       this.querylist1 = res.data.records;
          //       this.total1 = res.data.total;
          //       break;
          //   }
          // }else{
          //   switch(this.activeName){
          //     case 'first':
          //       this.querylist = [];
          //       this.total = 0;
          //       break;
          //     case 'second':
          //       this.querylist1 = [];
          //       this.total1 = 0;
          //       break;
          //   }
          console.log(res,'---res')
          }
        }
      })
    },
    onsee: function(state) {
      this.dialogVisible=!this.dialogVisible;
      switch(state){//0为未排班，1排班，2排班未排上
        case 0:
          this.title='未排班'
          break;
      }
    },
    handleSizeChange(e) {
      switch(this.activeName){
        case 'first':
          this.pageSize=e;
          this.currentPage=1;
          break;
        case 'second':
          this.pageSize1=e;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(e) {
      switch(this.activeName){
        case 'first':
          this.currentPage=e
          break;
        case 'second':
          this.currentPage1=e
          break;
      }
      this.init();
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmit(formdata){
      this.$refs[formdata].validate(valid => {
        if (valid) {
          // this.postAlarmHandling(this.itemRows.id)
        } else {
          return false;
        }
      });
    },
    onRouter(){
      // this.$router.push({
      //   path:'/testReviewer/auditReport',
      //   query:{
      //     state:state,
      //     id:row.id
      //   }
      // })
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  position:relative;
  .headBox{
    position:absolute;
    right: 50px;
  }
}
</style>
