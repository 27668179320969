<template>
  <!-- 延期竞拍跟进 -->
  <div class="deferredAuction">
    <!-- <h1 class="mb20">延期竞拍跟进</h1> -->
    <el-radio-group v-model="isFollowUp" class="groupbg" @change="init(1)">
      <el-radio-button :label="0">待跟进</el-radio-button>
      <el-radio-button :label="1">已跟进</el-radio-button>
    </el-radio-group>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column prop="isMortgage" label="是否抵押车" align="center">
        <template slot-scope="scope">{{scope.row.isMortgage===0?'不是':scope.row.isMortgage===1?'是':scope.row.isMortgage}}</template>
      </el-table-column>
      <el-table-column prop="bookTime" label="预约时间" align="center"></el-table-column>
      <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column prop="bookCount" label="预约次数" align="center"></el-table-column>
      <el-table-column prop="auctionTime" label="上次竞拍日期" align="center"></el-table-column>
      <el-table-column label="操作" width="120px" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onRouter(scope.row)" size="mini" plain>载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      isFollowUp:0,//是否跟进完成 0-否 1-是
    }
  },
  created(){
    this.init()
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    init(page){
      let user=this.$store.state.user.userData;
      let de=this.$$.roleName(user,'department')
      let bookShopId=''
      if(de.shop){
        bookShopId=de.shop.id;
      }
      this.page_index=page?page:this.page_index;
      
      let query={
        "curPage": this.page_index,
        "pageSize": this.page_size,
        bookShopId:bookShopId,
        isFollowUp:this.isFollowUp,
      }
      this.$api.postQueryNoFollowUp(query).then(res => {
        if(res.code==0){
          this.querylist=res.data.records;
          this.page_total=res.data.total;
        }else{
          this.querylist=[];
          this.page_total=0;
        }
      })
    },
    onRouter(row){
      if(this.isFollowUp===0){
        this.postFollowUpFinish(row.id,row)
      }else{
        this.$router.push({
          path: '/salesManager/customer', 
          query: {
            followUpInfoId: row.followUpId,
          }
        })
      }
    },
    postFollowUpFinish(id,row){//延期竞拍跟进完成
      this.$api.postFollowUpFinish(id).then(res =>{
        if(res.code==0){
          this.$router.push({
            path: '/salesManager/customer', 
            query: {
              followUpInfoId: row.followUpId,
            }
          })
          // this.init()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.deferredAuction{
  padding: 20px 40px;
}
</style>