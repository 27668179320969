<template>
  <div class="yuyuejiance">
    <!-- <ul class="mb20 flex flex_align_center">
      <li class="mr20">品牌车型：{{info.carType}}</li><li class="mr20">客户归属：{{info.onArea}}</li><li class="mr20">车牌号码：{{info.numberPlate}}</li><li class="mr20 red" v-if="info.mortgageLog">抵押车</li>
    </ul> -->
    <el-row class="mb20">
      <el-col :span="6">品牌车型：{{info.carType}}</el-col>
      <el-col :span="6">客户归属：{{info.onArea}}</el-col>
      <el-col :span="6">车牌号码：{{info.numberPlate}}</el-col>
      <el-col :span="6" v-if="info.mortgageLog">抵押车</el-col>
    </el-row>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="检测门店:" prop="storeNo">
        <el-select v-model="form.storeNo" placeholder="请选择" :disabled="disabled" @change="getCheckResource">
          <el-option v-for="item in storeList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    <template v-if="!disabled">
      <el-tabs v-model="activeName" type="card" @tab-click="getCheckResource" class="mb20" size="mini">
        <el-tab-pane label="当日检测" name="first"></el-tab-pane>
        <el-tab-pane label="次日检测" name="second"></el-tab-pane>
      </el-tabs>

      <div class="capacityTable mb20" :style="capacityList.length==0?'border-bottom:1px solid #cccccc':''">
        <div class="tr thead">
          <div class="td store">门店名称</div>
          <div class="td cap flex_align_center flex_center">检测产能</div>
        </div>
        <div class="tr tbody" v-for="item in capacityList" :key="item.storeName">
          <div class="td store">{{item.shopName}}</div>
          <div :class="{'num9': item.checkTimeCapacity.length>9,'cap':true, 'num18': item.checkTimeCapacity.length>18}">
            <div v-for="i in item.checkTimeCapacity" :key="i.time" :class="{'isBackup': i.isBackup,'td':true,'blueBG':i.isOccupy}" @click="timeClick(item,i)">
              <div class="tdRow" v-if="i.isBackup">候补+</div>
              <div class="tdRow">{{i.time}}</div>
              <div class="tdRow taborder" :class="i.color===2?'tipsColor':i.color===1?'yellowColor':''">{{i.haveBeenBookedCount}}单</div>
            </div>
          </div>
        </div>
        <div v-if="capacityList.length==0" class="footerBox">暂无数据</div>
      </div>
        <el-form-item label="检测时间:" prop="bookTime">
          <p>{{form.bookTime}}</p>
        </el-form-item>
      </template>
      <el-form-item label="检测类型:" prop="subType">
        <el-radio-group v-model="form.subType" @change="checkChange" >
          <el-radio v-for="item in subTypeList" :key="item.type" :label="item.type" class="mb10">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input type="textarea" v-model="form.remark" ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      storeList: [],
      capacityList:[],
      subTypeList:[],//检测类型
      info: {
        numberPlate: '',
        carType: '',
        mortgageLog: '',
        onArea: '',
      },
      form: {
        bookTime: '',
        storeNo: '',
        subType: '',
        remark: ''
      },
      rules: {
        'storeNo': [{ required: true, message: '请选择检测门店', trigger: 'change' }],
        'subType': [{ required: true, message: '请选择检测类型', trigger: 'change' }],
        'bookTime': [{ required: true, message: '请选择测时间', trigger: 'blur' }],
      },
      checkBookId:'',//检测预约Id
      followUpInfoId:'',//跟进id
      res:{},//
      disabled:false,
      active:'-',
      active1:'-',
      itemRows:{},
      itemRow:{},
      checkRes:{},//资源数据
    }
  },
  created() {
    if(this.$route.query.followUpInfoId) this.followUpInfoId = this.$route.query.followUpInfoId
    //设置默认门店
    let user=this.$$.roleName(this.$store.state.user.userData,'department');
    if(user){
      this.form.storeNo=user.shop?user.shop.id:'';
    }
    this.customerQueryCustomerByFollowUpId(this.followUpInfoId);
    this.getShopList()//获取门店
    
  },
  methods: {
    // 客户详情查询
    customerQueryCustomerByFollowUpId(followUpInfoId) {
      this.$api.customerQueryCustomerByFollowUpId(followUpInfoId).then(res => {
        if(res.code == 0){
          this.res=res.data;
          this.customerId =res.data.id;
          this.checkBookId = res.data.checkBookId;
          //流拍重拍传参数，不是就不传
          this.getCheckBookCheckType(this.customerId,res.data.enquiryId)//获取检测类型集合
          if(this.checkBookId) this.getQueryCheckBook()//获取检测预约详情
          this.getCheckResource()//获取门店检测时间
          this.info.numberPlate = res.data.carNo;
          this.info.carType = `${res.data.brand}/${res.data.series}`;
          this.info.mortgageLog = res.data.isMortgage;
          this.info.onArea = res.data.onArea;
        }
      })
    },
    getCheckResource(){
      let query={
        // address:`${this.params.addressName}${this.params.bookAddress}`,
        isToday:this.activeName=='first'?1:0,
        shopId:this.form.storeNo,
        customerId:this.customerId
      }
      this.$api.getCheckResourceCapacity(query).then(res => {
        if(res.code == 0){
          this.capacityList = res.data;
          for(let i=0;i<res.data.length;i++){
            for(let j=0;j<res.data[i].checkTimeCapacity.length;j++){
              let item =res.data[i].checkTimeCapacity[j]
              if(item.isOccupy==1){
                this.form.bookTime=`${res.data[i].date} ${item.time}`
                this.postCheckResourceShopSwitch(res.data[i].date,item.time)
                // if(!this.checkBookId){
                //   this.postCheckResourceShopSwitch(res.data[i].date,item.time)
                // }
                return
              }
            }
          }
        }
      })
    },
    postCheckResourceShopSwitch(date,time){
      let query={
            "shopId": this.form.storeNo,
            "date": date,
            "planTime": time,
            // // "resourceNo": 0,
            "customerId": this.customerId,
            "status": 3//状态 0-空闲 3-未确认 4-关闭
          }
      this.$api.postCheckResourceShopSwitch(query).then(res=>{
        if(res.code==0){
          this.checkRes=res.data;
        }
      })
    },
    doSubmit() {
      let validType=false;
      this.$refs['form'].validate(valid => {
        if (valid) {
          validType=true;
        }else{
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      });
      if(!validType){
        return
      }
     let query={
        "checkBookId": this.checkBookId,//检测预约Id(改约时需填)
        "customerId": this.res.id,
        "resourceId": this.checkRes.id,//门店已占用资源Id
        "checkType": this.form.subType,
        "remark": this.form.remark
      }
      this.$api.postCheckBookShopSubmit(query).then(res=>{
        if(res.code==0){
          this.dialogVisible = false;
          this.$message.success(res.message)
          this.bus.$emit("closeSelected",'router');
          this.$router.push({
            path: '/salesManager/customer', 
            query: {followUpInfoId: this.followUpInfoId}
          })
          // this.customerQueryCustomerByFollowUpId(this.followUpInfoId);
        }
      })
    },
    timeClick(rows,row){
      let query={
            "shopId": rows.shopId,
            "date": rows.date,
            "planTime": row.time,
            // "resourceNo": 0,
            "customerId": this.customerId,
            // "status": 3//状态 0-空闲 3-未确认 4-关闭
          }
      this.$confirm('确认选择当前时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          query.status=3;
           //门店检测资源关闭或打开
          this.$api.postCheckResourceShopSwitch(query).then(res=>{
            if(res.code==0){
              this.checkRes=res.data;
              this.form.bookTime=`${rows.date} ${row.time}`
              this.getCheckResource()//获取门店检测时间
            }
          })
        }).catch(() => {
          if(!row.isOccupy){
            return
          }
          query.resourceNo= this.checkRes.resourceNo;
          query.status=0;
           //门店检测资源关闭或打开
          this.$api.postCheckResourceShopSwitch(query).then(res=>{
            if(res.code==0){
              this.getCheckResource()//获取门店检测时间
            }
          })   
        });
    },
    checkChange(row){//是否可编辑
      let obj=this.subTypeList.filter(x=> x.type==row)
      this.disabled=obj[0].isCheck?false:true;
    },
    getShopList(){//获取所有门店
      let query={
        isEnabled:0,
        shopTypeList:[0,3]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.storeList=res.data;
        }
      })
    },
    getCheckBookCheckType(customerId,enquiryId){//获取检测类型集合
      let query={
        enquiryId:enquiryId,
        customerId:customerId
      }
      this.$api.getCheckBookCheckType(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.subTypeList=res.data;
        }
      })
    },
    getQueryCheckBook(){//获取检测预约详情
      this.$api.getQueryCheckBook(this.checkBookId).then(res=>{
        if(res.code==0){
          this.form.bookTime=res.data.bookTime;
          this.form.subType=res.data.checkType;
          this.form.remark=res.data.remark;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.yuyuejiance{
  padding: 20px 40px 140px;
  .capacityTable{
    border: 1px solid #CCCCCC;
    border-bottom:0;
    width: 932px;
    .tr{
      display: flex;
      align-items: stretch;
      border-bottom: 1px solid #CCCCCC;
      // &:last-child{border-bottom: none;}
      &:nth-child(2n+1){background: #EAF4FD;}
      .td{
        width: 120px;
        cursor: pointer;
      }
      .cap{
        width: 810px;
        display: flex;
        flex-wrap: wrap;
        // border: none;
        .td{
          width: 90px;
          border-right: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          // height: 35px;
          margin-bottom: -1px;
          // &:nth-child(9){border-right: none;}
        }
        .isBackup {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #0099FF;
          span:first-child{
            margin-bottom: 10px;
          }
        }
      }
      .store{
        border-right: 1px solid #CCCCCC;
        cursor: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .num9>.td:nth-child(-n+9){border-bottom: 1px solid #CCCCCC;;}
      .num18>.td:nth-child(-n+18){border-bottom: 2px solid #0099FF;;}
      .tdRow{
        width:100%;
        text-align:center;
        height: 50px;
        line-height: 50px;
      }
      .taborder{
        border-top:1px solid #cdcdcd;
      }
    }
    .thead{
      height: 50px;
    }
    .footerBox{
      height:50px;
      line-height: 50px;
      text-align:center;
    }
  }
}
</style>