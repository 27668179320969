<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>客户编号</span>
        <el-input v-model="form.customerNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>线路名称</span>
        <el-input v-model="form.routeNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>预约时间</span>
        <el-date-picker
          v-model="form.date" size="medium"
          type="daterange" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between ">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
        <el-button type="primary" @click="onBatch" size="medium" >批量分配</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt30 table-mainbg" @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="schedulingStatusName" label="检测师回复" align="center">
        <template slot-scope="scope">{{scope.row.schedulingStatus==-1?'-':scope.row.schedulingStatusName}}</template>
      </el-table-column>
      <el-table-column prop="checkerName" label="检测师" align="center">
        <template slot-scope="scope">{{scope.row.schedulingStatus===2?scope.row.voidedSchedulingCheckerName:scope.row.checkerName}}</template>
      </el-table-column>
      <el-table-column prop="routeNo" label="线路名称" align="center"></el-table-column>
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column prop="bookTime" label="预约检测时间" align="center"></el-table-column>
      <el-table-column prop="bookAddress" label="检测地址" align="center"></el-table-column>
      <el-table-column prop="isAssignChecker" label="调度状态" align="center">
        <template slot-scope="scope">{{scope.row.isAssignChecker===1?'已分配':scope.row.isAssignChecker===0?'未分配':scope.row.isAssignChecker}}</template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary" v-if="!scope.row.isAssignChecker"
            @click="onsee(scope.row,1)"
            size="mini"
          >分配</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
    <el-dialog
      title="检测师分配" width="700px"
      :visible.sync="dialogVisible">
      <el-row :gutter="24" class="mb20">
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>检测师姓名</span>
          <el-input v-model="form.userName" size="medium"></el-input>
        </el-col>
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>检测师账号</span>
          <el-input v-model="form.userNo" size="medium"></el-input>
        </el-col>
        <el-col :span="2" class="flex flex_align_center flex_between ">
          <el-button type="primary" @click="getUserRole" size="medium" plain>查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="checklist" border max-height="300">
        <el-table-column prop="name" label="检测师姓名" align="center"></el-table-column>
        <el-table-column prop="no" label="检测师账号" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="onsee(scope.row,2)"
              size="mini"
            >发送调度通知</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize1"
      :current-page="currentPage1"
      :total="total1" size="m"
    ></el-pagination> -->
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        userName:'',
        userNo:''
      },
      querylist: [],
      checklist:[],//
      total: 0,
      currentPage: 1,
      pageSize:10,
      total1: 0,
      currentPage1: 1,
      pageSize1:10,
      dialogVisible:false,
      itemRow:{},
      selectRow:[],
      selectFlag:false,//是否惦记了批量付款
    };
  },
  created() {
    this.init()
    // this.getUserRole()//查询检测师
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        customerNo:this.form.customerNo,
        carNo:this.form.carNo,
        routeNo:this.form.routeNo,
        bookStartTime:this.form.date?`${this.form.date[0]} 00:00:00`:'',
        bookEndTime:this.form.date?`${this.form.date[1]} 23:59:59`:'',
      };
      this.$api.postSchedulingFieldList(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = []
            this.total=0;
          }
        }else{
          this.querylist = []
          this.total=0;
        }
      });
    },
    onsee(row,state) {
      this.form.userName='';
      this.form.userNo='';
      this.getUserRole()//查询检测师
      if(state==1){
        this.itemRow=row;
        this.dialogVisible=true;
        this.selectFlag=false;
      }else{
        if(this.selectFlag){
          let arr=[]
          this.selectRow.filter(x => {arr.push(x.id)})
          this.postAssignCheckerBatch(row.id,arr)
        }else{
          this.postAssignCheckerBatch(row.id,[this.itemRow.id])
        }
      }
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    handleSelectionChange(row){
      this.selectRow=row;
    },
    onBatch(){
      if(this.selectRow.length==0){
        this.$message.warning('请选择检测单！')
        return
      }
      for(let i=0;i<this.selectRow.length;i++){
        let x=this.selectRow[i]
        if(x.isAssignChecker===1){
          this.$message.warning('当前所选选项存在已分配数据！')
          return
        }
      }
      this.form.userName='';
      this.form.userNo='';
      this.getUserRole()//查询检测师
      this.dialogVisible=true;
      this.selectFlag=true;
    },
    postAssignCheckerBatch(checkerId,arr){//批量发送检测调度分配检测任务
      this.$api.postAssignCheckerBatch(checkerId,arr).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.dialogVisible=false;
        }
      })
    },
    getUserRole(){//根据角色和部门查询用户
      let query={
        roleName:'检测师',
        userName:this.form.userName,
        loginName:this.form.userNo
      }
      this.$api.queryUserListByRoleAndRoleLevelAndDepartAndUser(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.checklist=res.data
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>

</style>
