<template>
  <div class="currencyConterBox">
    <el-tabs v-model="tabClassify" type="card" @tab-click="init(1)">
      <el-tab-pane label="4S店钱包提现列表" name="first">
        <el-row :gutter="24" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>合作店名称</span>
            <el-input v-model="forms.channelShorName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>收款账户名</span>
            <el-input v-model="forms.accountName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>提现状态</span>
            <el-select v-model="forms.financePayStatuses" size="medium" clearable multiple>
              <!-- <el-option label="全部" value="" ></el-option> -->
              <el-option
                v-for="item in paymentStatuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" class="flex flex_align_center flex_between w4">
            <span>付款时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row class="mt20">
          <el-col :span="6" class="flex flex_align_center flex_between w4">
            <span>审批时间</span>
            <el-date-picker
              v-model="forms.dates"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              size="medium"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="3" class="flex flex_align_center flex_between w4 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button
            >
          </el-col>
          <el-col :span="3" class="flex flex_align_center flex_between w4">
            <el-button type="primary" @click="onBatchPayment" size="medium"
              >批量付款</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="querylist"
          border
          stripe :row-key="getRowKeys" 
          class="table-mainbg mt20"
          ref="table"
          @selection-change="selectChange"
        >
          <el-table-column
            type="selection"
            label="选择" :reserve-selection="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="channelShorName"
            label="合作店简称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="提现金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="accountName"
            label="账户名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="account"
            label="账号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="depositBankShortName"
            label="银行简称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="depositBank"
            label="开户行"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="applyTime"
            label="申请时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkTime"
            label="审批时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="提现备注"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="financePayStatus"
            label="提现状态"
            align="center">
              <template slot-scope="scope">{{scope.row.financePayStatus==32?'付款中':scope.row.financePayStatus==33?'付款成功':scope.row.financePayStatus==34?'付款失败':'待付款'}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row, 0)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="4S店钱包批量提现列表" name="second">
        <el-row :gutter="24" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>收款账户名</span>
            <el-input v-model="forms1.accountName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>收款账号</span>
            <el-input v-model="forms1.accounts" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>提现状态</span>
            <el-select v-model="forms1.batchPayStatus" size="medium" clearable>
              <el-option label="请选择状态" value=""></el-option>
              <el-option v-for="item in BatchPayStatuslist" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" class="flex flex_align_center flex_between w4">
            <span>付款时间</span>
            <el-date-picker
              v-model="forms1.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="querylist1"
          border
          stripe
          class="table-mainbg mt20"
        >
          <el-table-column
            prop="batchPayOrderNo"
            label="批次编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayAmount"
            label="批量支付金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayTime"
            label="批量支付日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayStatus"
            label="批量支付状态"
            align="center"
          >
          <template slot-scope="scope">{{scope.row.batchPayStatus==32?'付款中':scope.row.batchPayStatus==33?'付款成功':scope.row.batchPayStatus==34?'付款失败':'待付款'}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row, 1)" size="mini">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabClassify: "first",
      querylist: [], //
      querylist1: [], //
      forms: {
        merchantName: "",
        state: "",
        date: "",
        financePayStatuses:[31,32]
      },
      forms1:{},
      total: 0,
      total1: 0,
      currentPage: 1,
      currentPage1: 1,
      pageSize: 10,
      pageSize1: 10,
      paymentStatuslist: [],
      BatchPayStatuslist: [],
      selectlist: [], //选中的数据
      stateType: "", //判断是否从详情来展示对应的tab
    };
  },
  created() {
    this.paymentStatuslist = this.$store.state.setting.paymentStatuslist;
    this.BatchPayStatuslist = this.$store.state.setting.BatchPayStatuslist;
    if(this.$route.params.state) this.forms.financePayStatuses=[Number(this.$route.params.state)];

    this.init();

  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    init(page) {
      if (this.tabClassify == "first") {
        this.postChannelWalletQuery(page)//
      } else {
        this.postChannelWalletBatchQuery(page)
      }
    },
    postChannelWalletQuery(page){//付款条件查询（渠道钱包提现）
      this.currentPage=page?page:this.currentPage;
      let query={
        curPage:this.currentPage,
        pageSize:this.pageSize,
        accountName:this.forms.accountName,
        channelShorName:this.forms.channelShorName,
        financePayStatuses:this.forms.financePayStatuses?this.forms.financePayStatuses:'',//批量支付状态，32付款中，33付款成功，34付款失败
        payBeginTime:this.forms.date?`${this.forms.date[0]} 00:00:00`:'',
        payEndTime:this.forms.date?`${this.forms.date[1]} 23:59:59`:'',
        checkBeginTime:this.forms.dates?`${this.forms.dates[0]} 00:00:00`:'',
        checkEndTime:this.forms.dates?`${this.forms.dates[1]} 23:59:59`:'',
      }
      this.$api.postChannelWalletQuery(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist = res.data.records;
          this.total = res.data.total;
        } else {
          this.querylist = [];
          this.total = 0;
        }
      })
    },
    postChannelWalletBatchQuery(page){//付款批量条件查询（渠道钱包提现）
      this.currentPage=page?page:this.currentPage;
      let query={
        curPage:this.currentPage,
        pageSize:this.pageSize,
        accountName:this.forms1.accountName,
        accounts:this.forms1.accounts?[this.forms1.accounts]:'',
        batchPayStatus:this.forms1.batchPayStatus?[this.forms1.batchPayStatus]:'',//批量支付状态，32付款中，33付款成功，34付款失败
        batchPayBeginTime:this.forms1.date?`${this.forms1.date[0]} 00:00:00`:'',
        batchPayEndTime:this.forms1.date?`${this.forms1.date[1]} 23:59:59`:'',
      }
      this.$api.postChannelWalletBatchQuery(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist1 = res.data.records;
          this.total1 = res.data.total;
        } else {
          this.querylist1 = [];
          this.total1 = 0;
        }
      })
    },
    handleSizeChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.pageSize = data;
          this.currentPage=1;
          break;
        case "second":
          this.pageSize1 = data;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.currentPage = data;
          break;
        case "second":
          this.currentPage1 = data;
          break;
      }
      this.init();
    },
    onRowClick(row, state) {
      let query={};
      if(state==0){
        query.depositPayId = row.id
      }else{
        query.batchPayOrderNo=row.batchPayOrderNo;
      }
      this.$router.push({
        path: "/finance/walletWithdrawalDetail",
        query: query,
      });
    },
    onBatchPayment() {
      let arr=[];
      let payType=false;//当前是否有单存在付款完成
      for(let i=0;i<this.selectlist.length;i++){
        if(this.selectlist[i].financePayStatus!=31){
          payType=true;
        }
        arr.push(this.selectlist[i].account)
      }
      if(payType){
        this.$message.warning('当前订单中存在不需要付款的数据，请确认后再付款！');
        return
      }
      if (this.selectlist.length == 0) {
        this.$message("请选择需要批量付款的合同！");
        return;
      }
      this.$store.dispatch('setting/setWalletList',this.selectlist); 
      let aurl = "/finance/walletWithdrawalDetail";
      this.$router.push({
        path: aurl,
        query: {
          accounts:arr
        },
      });
    },
    selectChange(rows) {
      this.selectlist = rows;
      // console.log(rows,'----rrr--')
    },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
}
</style>