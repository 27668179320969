<template>
  <div class="inventBoxBG">
    <div class="inventBox">
      <template v-if="stateType==0">
      <!--车辆信息-->
      <div class="content_modal_textBox">
        <div class="flex flex_between">
          <div class="ckxTitle mb10">车辆信息</div>
          <div>合同编号：{{form.customerContractNo}}</div>
        </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>车辆号牌</td>
              <td>{{form.carNo}}</td>
              <td>厂牌型号</td>
              <td>{{form.carBrandModelName}}</td>
              <td>发动机号</td>
              <td>{{form.engineNo}}</td>
              <td>颜色</td>
              <td>{{form.carColor}}</td>
            </tr>
            <tr>
              <td>初次登记日期</td>
              <td>{{form.registerDate}}</td>
              <td>年检有效期</td>
              <td>{{form.checkExpire}}</td>
              <td>车架号</td>
              <td>{{form.vinCode}}</td>
              <td>成交类型</td>
              <td>{{form.tradeTypeName}}</td>
            </tr>
            <tr>
              <td>成交价（元）</td>
              <td>{{form.ccTradePrice}}</td>
              <td>报价有效期</td>
              <td>{{form.tradedPriceExpire}}</td>
              <td>预约成交时间</td>
              <td>{{form.appointTradeTime}}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
       <!--车主信息-->
      <div class="content_modal_textBox mt30">
        <div class="ckxTitle mb10">车主信息</div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>车主姓名</td>
              <td>{{form.customerName}}</td>
              <td>客户电话</td>
              <td>{{form.customerMobile}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>收款账号户名</td>
              <td>{{form.accountName}}</td>
              <td>开户行</td>
              <td>{{form.depositBank}}</td>
              <td>开户行简称</td>
              <td>{{form.depositBankShortName}}</td>
              <td>账号</td>
              <td class="wordWrap">{{form.account}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--退客户押金-->
      <div class="content_modal_textBox mt30">
        <div class="ckxTitle mb10">退客户押金</div>
        <div>
          <p class="mb10">甲方退还押金（大写）：<span class="text_decoration">{{form.totalPayAmount}}（￥：{{form.totalPayAmountCn}}）</span> </p>
          <p>客成经理：{{form.sellerName}}</p>
          <template v-if="[31,34].includes(paymentState)">
          <p class="mt15">付款方式：
            <el-select v-model="payTypeState" class="w300">
              <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </p>
          <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
            <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
          </template>
          <template v-if="[33].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
          <p class="tipsColor mt15" v-if="paymentState==31">当前余额（元）：{{form.availableBalance}}</p>
        </div>
      </div>
      <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
      </template>
     <template v-else>
       <div class="head_top_title">
          <i></i>
          <span>批量支付明细</span>
        </div>
       <el-table :data="querylist" border stripe class="table-mainbg">
        <el-table-column prop="customerName" label="车主姓名" align="center"></el-table-column>
        <el-table-column prop="accountName" label="收款账号户名" align="center">
          <!-- <template slot-scope="scope">{{scope.row.carCustomerAccount?scope.row.carCustomerAccount.accountName:''}}</template> -->
        </el-table-column>
        <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column prop="depositBank" label="开户行" align="center">
          <!-- <template slot-scope="scope">{{scope.row.carCustomerAccount?scope.row.carCustomerAccount.depositBank:''}}</template> -->
        </el-table-column>
        <el-table-column prop="depositBankShortName" label="银行简称" align="center">
          <!-- <template slot-scope="scope">{{scope.row.carCustomerAccount?scope.row.carCustomerAccount.depositBankShortName:''}}</template> -->
        </el-table-column>
        <el-table-column prop="account" label="账号" align="center">
          <!-- <template slot-scope="scope">{{scope.row.carCustomerAccount?scope.row.carCustomerAccount.account:''}}</template> -->
        </el-table-column>
        <el-table-column prop="totalPayAmount" label="退款押金金额（元）" align="center"></el-table-column>
      </el-table>
      <p class="mt30"><span class="mr30">批量支付日期：{{form.batchPayTime}}</span><span class="ml30">批量支付金额（元）：{{form.batchPayAmount}}</span></p>
        <template v-if="[31,34].includes(paymentState)">
        <p class="mt15">付款方式：
          <el-select v-model="payTypeState" class="w300">
            <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </p>
          <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
            <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </template>
        <template v-if="[33].includes(paymentState)">
          <p class="mt15">付款方式：{{form.payTypeName}}</p>
        </template>
        <template v-if="paymentState==34">
          <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
        </template>
        <p class="tipsColor mt15" v-if="paymentState===31">当前余额（元）：{{form.availableBalance}}</p>
        <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
     </template>
     
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31||paymentState==34">{{paymentState==34?'重新付款':'开始付款'}}</el-button>
        <!-- <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31">开始付款</el-button> -->
        <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32&&form.orderNo">付款完成</el-button>
        <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32&&form.orderNo">付款失败</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //0单个，1批量
      querylist: [],
      payTypeState:1,//付款方式
      form: {
        carCustomer:{},
        customerContract:{},//客户合同表
        carCustomerAccount:{},//银行卡信息
      },
      paymentState:31,//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      depositPayId:'',//退客户押金付款 ID
      batchPayOrderNo:'',//批量编号（没有批量编号，是需要查批量列表）
      carNos:'',//选择批量支付操作
    };
  },
  created() {
    this.stateType = this.$route.query.state;
    if(this.$route.query.depositPayId) this.depositPayId=this.$route.query.depositPayId;
    if(this.$route.query.batchPayOrderNo) this.batchPayOrderNo=this.$route.query.batchPayOrderNo;
    // if(this.$route.query.carNos){
    if(this.$route.query.state==='1'){
      this.carNos = this.$route.query.carNos;
      let customerDepositList=this.$store.state.setting.customerDepositList;
      this.querylist=JSON.parse(JSON.stringify(customerDepositList))
      let cont=0;
      for(let i=0;i<this.querylist.length;i++){
        let item=this.querylist[i]
        cont+=item.totalPayAmount;
      }
      this.form.batchPayAmount=isNaN(cont)?'-':cont;
    } 
    if(this.stateType==0){
      this.getDepositDetailsByDepositPayId()//
    }else{
      if(this.batchPayOrderNo){
        this.getDepositBatchDetailsByOrderNo()//根据批次编号查询详情
      }else{
        // this.postCustomerDepositBatchQuery(this.carNos)//客户押金退款 批量条件查询
        this.getAvailableAmount()//查询银行账户余额
      }
    }
  },
  methods: {
    getDepositDetailsByDepositPayId(){//根据 depositPayId 查询详情
      this.$api.getDepositDetailsByDepositPayId(this.depositPayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.financePayStatus;
          }
        }
      })
    },
    getDepositBatchDetailsByOrderNo(){//根据批次编号查询详情
      this.$api.getDepositBatchDetailsByOrderNo(this.batchPayOrderNo).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.batchPayStatus;//批量支付状态，32付款中，33付款成功，34付款失败
            // this.form.carCustomer=res.data.carCustomers.length>0?res.data.carCustomers[0]:{};
            this.querylist = this.form.cdpList?this.form.cdpList:[];
          }
        }
      })
    },
    // postCustomerDepositBatchQuery(carNos){//客户押金退款 批量条件查询
    //   let query={
    //     carNos:carNos
    //   }
    //   this.$api.postCustomerDepositBatchQuery(this.$$.delete_null_properties(query)).then(res=>{
    //     if (res.code == 0) {
    //       this.form=res.data.records.length>0?res.data.records[0]:{};
    //       this.payTypeState=this.form.payType==5?5:1;//付款方式
    //       this.paymentState=this.form.batchPayStatus;//批量支付状态，32付款中，33付款成功，34付款失败
    //       this.querylist = this.form.carCustomers?this.form.carCustomers:[];
    //     } else {
    //       this.querylist = [];
    //     }
    //   })
    // },
    onPayment(state){
      switch(state){
        case 1:
          if(!this.payTypeState){
            this.$message('请选择付款方式！')
            return
          }else{
            if(!this.form.payType&&this.payTypeState!=5){
              this.$message('请选择付款方式！')
              return
            }
          }
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            if(this.stateType==0){
              this.postDepositFinancePay();//财务单个付款
            }else{
              this.postDepositFinanceBatchPay();//财务批量付款（客户押金退款）
            }
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postDepositFinanceConfirmPaySuccess()//财务确认付款成功
          }).catch(() => {});
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postDepositFinanceConfirmPayFail()//财务确认付款失败
          }).catch(() => {});
          break;
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/customerDeposit',
        query:{
          state:this.stateType
        }
      })
    },
    postDepositFinancePay(){//财务单个付款
      let query={
        "customerDepositPayId": this.depositPayId,//客户押金退款ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postDepositFinancePay(query).then(res=>{
        if(res.code==0){
          this.getDepositDetailsByDepositPayId();//单个详情
          this.$message.success(res.message)
        }
      })
    },
    postDepositFinanceBatchPay(){//财务批量付款
      let customerDepositPayIds=[];
      for(let i=0;i<this.querylist.length;i++){
        customerDepositPayIds.push(this.querylist[i].id)
      }
      let query={
        "customerDepositPayIds": customerDepositPayIds,//客户押金退款ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postDepositFinanceBatchPay(query).then(res=>{
        if(res.code==0){
          // this.getDepositDetailsByDepositPayId();//单个详情
          this.batchPayOrderNo=res.data;
          this.$message.success(res.message)
          this.$nextTick(()=>{
            this.onReturnPage(res.data)
          })
        }
      })
    },
    postDepositFinanceConfirmPaySuccess(){//财务确认付款成功
      this.$api.postDepositFinanceConfirmPaySuccess(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.getDepositDetailsByDepositPayId();//单个详情
          this.$message.success(res.message)
        }
      })
    },
    postDepositFinanceConfirmPayFail(){//财务确认付款失败
      this.$api.postDepositFinanceConfirmPayFail(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.getDepositDetailsByDepositPayId();//单个详情
          this.$message.success(res.message)
        }
      })
    },
    getAvailableAmount(){//查询银行账户余额
      this.$api.getAvailableAmount().then(res=>{
        if(res.code==0){
          this.form.availableBalance=res.data;
          this.$nextTick(()=>{
            this.$forceUpdate()
          })
        }
      })
    },
    onReturnPage(batchPayOrderNo){//新增批次退费专用
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/customerDepositDetails',
        query:{
          state:1,
          batchPayOrderNo:batchPayOrderNo
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding: 20px 0 0;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px 140px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>