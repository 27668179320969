<template>
  <div>
    <CarAssess :carState="1" ></CarAssess>
  </div>
</template>

<script>
import CarAssess from '@/components/permission/carAssess';
export default {
  components:{
    CarAssess
  },
  data() {
    return {
      // querylist: []
    }
  },
  created() {
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
.priceInquiry{
  padding: 20px 40px;
}
</style>