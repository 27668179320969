<template>
  <!-- 门店预排班 -->
  <div class="preScheduling">
    <el-row :gutter="20" class="mb20 flex flex_align_center">
      <el-col :span="4" class="flex flex_align_center w2">
        <span>月份：</span>
        <el-radio-group v-model="params.month" size="medium">
          <el-radio-button v-for="item in monthList" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>门店：</span>
        <el-select v-model="params.storeNo" placeholder="请选择" @change="changeStore">
          <el-option v-for="item in storeList" :key="item.storeNo" :label="item.storeName" :value="item.storeNo"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w2">
        <el-button type="primary" plain @click="search" size="mini">查询</el-button>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" plain @click="autoScheduling" size="mini">自动排班</el-button>
      </el-col>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane v-for="item in dailySituations" :key="item.no" :label="getLabel(item.no)" :name="String(item.no)" class="mt20">
        <table class="schedulingTable">
          <thead>
            <tr>
              <th>门店</th>
              <th v-for="v in item.dayDuties" :key="v.date">{{v.date + '(' + v.whichDay + ')'}}</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="it in checkerScheduleList" :key="it.checkerId">
              <td>{{curStoreName}}</td>
              <td v-for="v in item.dayDuties" :key="v.date">
                <span class="nameSlot" v-if="it.workDays.indexOf(v.date) > -1">{{it.checkerName}}<i class="el-icon-close" @click="closeName(it.checkerId,v.date)"></i></span>
              </td>
              <td><el-button size="mini" type="primary" plain @click="dialogVisible=true">指派检测师</el-button></td>
            </tr>
            <tr>
              <td>总人数</td>
              <td v-for="v in item.dayDuties" :key="v.date">{{v.checkerCount}}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </el-tab-pane>
    </el-tabs>
    <div style="text-align:right;" class="mt10">
      <el-button plain icon="el-icon-arrow-left" @click="changeS('prev')" :disabled="prevDisbled">上一个门店</el-button>
      <el-button plain icon="el-icon-arrow-right" @click="changeS('next')" :disabled="nextDisbled">下一个门店</el-button>
    </div>

    <div class="mb10">门店预排班表</div>
    <div style="width:100%;height:480px;overflow:auto;">
      <table class="schedulingTable schedulingTable2">
        <thead>
          <tr>
            <th>门店</th>
            <th v-for="(value,key) in dateObj" :key="key">{{key}}</th>
          </tr>
        </thead>
        <tbody v-for="item in preSchedulingList" :key="item.storeNo">
          <tr v-for="ite in item.checkerScheduleList" :key="ite.checkerId">
            <td>{{item.storeName}}</td>
            <td v-for="v in item.dateList" :key="v.date">
              <span class="nameSlot" v-if="ite.workDays.indexOf(v.date) > -1">{{ite.checkerName}}</span>
            </td>
          </tr>
          <tr>
            <td>产能</td>
            <td v-for="v in item.dateList" :key="v.date">{{v.checkerCount}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>总产能</td>
            <td v-for="(value,key) in dateObj" :key="key">{{value}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    

    <!-- 指派检测师 -->
    <el-dialog :visible.sync="dialogVisible" center title="指派检测师">
      <el-form :model="testForm" label-width="150px" ref="testForm" :rules="testFormRules">
        <el-form-item label="选择检测师" prop="testName">
          <el-cascader style="width:100%;" v-model="testForm.testName" :props="testOptions"></el-cascader>
        </el-form-item>
        <el-form-item label="选择时间(可多选)" prop="testDate">
          <el-select style="width:100%;" v-model="testForm.testDate" placeholder="请选择" multiple>
            <el-option v-for="item in weekDayList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTestForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    let self = this;
    return {
      params: {
        month: '',
        storeNo: '',
      },
      storeList: [],
      activeName: '1',
      dailySituations: [],
      checkerScheduleList: [],
      trList: [0, 1, 2, 3, 4],
      dialogVisible: false,
      testOptions: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level } = node;
          if(level === 0){
            resolve([
              {value: 1, label: '门店检测师',leaf: false},
              {value: 0, label: '机动检测师',leaf: false}
            ])
          }else if(level === 1 && node.value === 1){
              const arr = self.storeList.map(item => ({
                value: item.storeNo,
                label: item.storeName,
                leaf: false
              }))
              resolve(arr)
          }else{
            let params = {}
            if(level === 1){
              params.isStore = 0;
            }else{
              params.isStore = 1;
              params.storeNo = node.value
            }
            self.$api.checkerQueryChecker(params).then(res => {
              if(res.code == 0){
                const arr = res.data.map(item => ({
                  value: item.id,
                  label: item.name,
                  leaf: true
                }))
                resolve(arr);
              }
            })
          }
        }
      },
      testForm: {
        testName: '',
        testDate: []
      },
      testFormRules: {
        testName: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        testDate: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      curCKXStore: '',
      preSchedulingList: [{dateList:[]}],
      dateObj: {},
      prevDisbled: false,
      nextDisbled: false,
      curStoreName: '',
      curStoreNo: '',
      weekDayList: [],
    }
  },
  created() {
    if(localStorage.getItem('curCKXStore')){//默认门店
      this.curCKXStore = localStorage.getItem('curCKXStore')
    }
    this.initMonth();//获取月份取值
    this.auctionStoreQueryStores()//获取所有有效门店
  },
  mounted() {
    this.getPreSchedulingList()//获取门店预排班表
  },
  methods: {
    auctionStoreQueryStores() {
      this.$api.auctionStoreQueryStores().then(res => {
        if(res.code == 0){
          this.storeList = res.data;
          if(this.curCKXStore){
            this.params.storeNo = this.curCKXStore;
          }else{
            this.params.storeNo = res.data[0].storeNo;
          }
          this.search();
        }
      })
    },
    autoScheduling() {//自动排班
      this.$api.storeAutoScheduling(this.$$.delete_null_properties({
        month: this.params.month
      })).then(res => {
        if(res.code == 0){
          this.$message.success(res.message)
        }
      })
    },
    //切换门店时把默认门店存在本地,进页面时使用默认门店查预排班信息
    changeStore(value) {
      this.setButton();
      localStorage.setItem('curCKXStore',value)
    },
    initMonth() {
      let curMonth = this.$$.getFormatMomentTime('YYYY-MM', Date.now());
      this.monthList = [
        {value: curMonth, label: '本月'},
        {value: this.getNextMonth(), label: '次月'}
      ]
      this.params.month = curMonth;
    },
    getNextMonth() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 2
      if(month > 12){
        month = month - 12;
        year = year + 1;
      }
      if(month < 10){
        month = '0' + month
      }
      return year + '-' + month;
    },
    search() {
      this.dailySituations = [];
      this.checkerScheduleList = [];
      this.$api.storeQuerySchedule(this.$$.delete_null_properties({
        month: this.params.month,
        storeNo: this.params.storeNo
      })).then(res => {
        if(res.code == 0){
          this.dailySituations = res.data[0].dailySituations;
          this.checkerScheduleList = res.data[0].checkerScheduleList;
          this.curStoreName = res.data[0].storeName;
          this.curStoreNo = res.data[0].storeNo;
          this.activeName = String(res.data[0].dailySituations[0].no)
          this.getWeekDayList();
        }
      })
    },
    getPreSchedulingList() {
      this.$api.storeQuerySchedule(this.$$.delete_null_properties({
        month: this.params.month
      })).then(res => {
        if(res.code == 0){
          this.dateObj = {};
          res.data.forEach(item => {
            let dateList = [];
            item.dailySituations.forEach(ite => {
              ite.dayDuties.forEach(it => {
                dateList.push(it)
                if(!this.dateObj[it.date]){
                  this.dateObj[it.date] = it.checkerCount;
                }else{
                  this.dateObj[it.date] += it.checkerCount
                }
              })
            })
            item.dateList = dateList;
          })
          this.preSchedulingList = res.data;
        }
      })
    },
    handleClick() {
      this.getWeekDayList();
    },
    getLabel(index) {
      if(index===0){
        return '一周'
      }else if(index===1){
        return '二周'
      }else if(index===2){
        return '三周'
      }else if(index===3){
        return '四周'
      }else if(index===4){
        return '五周'
      }else if(index===5){
        return '六周'
      }
    },
    closeName(checkerId, date) {
      this.$confirm('确认删除吗', '提示?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.storeDeleteCheckerSchedule({
          checkerId: checkerId,
          date: date,
          storeNo: this.params.storeNo,
        }).then(res => {
          if(res.code == 0){
            this.$message.success(res.message)
            this.search();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });          
      });
    },
    changeS(type) {
      let index = this.storeList.findIndex((item) => {
        return item.storeNo === this.params.storeNo;
      })
      if(type === 'prev'){
        index --
      }else if(type === 'next') {
        index ++
      }
      this.params.storeNo = this.storeList[index].storeNo;
      this.setButton();
      this.search();
    },
    setButton() {
      let index = this.storeList.findIndex((item) => {
        return item.storeNo === this.params.storeNo;
      })
      if(index === this.storeList.length-1){
        this.nextDisbled = true
        this.prevDisbled = false
      }else if(index === 0){
        this.prevDisbled = true
        this.nextDisbled = false
      }else{
        this.prevDisbled = this.nextDisbled = false
      }
    },
    getWeekDayList() {//获取本周所有date的数组
      this.weekDayList = this.dailySituations.find(item => {
        return String(item.no) === this.activeName;
      }).dayDuties.map(item => ({
        label: item.date+'('+item.whichDay+')',
        value: item.date
      }));
    },
    submitTestForm() {
      this.$refs['testForm'].validate((valid) => {
        if (valid) {
          let params = {
            storeNo: this.curStoreNo,
            checkerId: this.testForm.testName[this.testForm.testName.length-1],
            assignDates: this.testForm.testDate
          }
          this.$api.storeAssignChecker(params).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功')
              this.search()
              this.dialogVisible = false;
              this.$refs['testForm'].resetFields();
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.preScheduling{
  padding: 20px 40px 0;
  .mt100{
    margin-top: 100px;
    text-align: center;
  }
  .schedulingTable{
    border-collapse: collapse;
    width: 100%;
    th{
      padding: 10px 0;
      border: 1px solid #cccccc;
      text-align: center;
    }
    td{
      border: 1px solid #cccccc;
      text-align: center;
      height: 50px;
      .nameSlot{
        background: #0099FF;
        color: #fff;
        display: block;
        position: relative;
        line-height: 50px;
        .el-icon-close{
          color: #fff;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 20px;
          display: none;
          cursor: pointer;
        }
        &:hover .el-icon-close{display: inline-block;}
      }
    }
  }
  .schedulingTable2{
    width: 3500px;
  }
}
</style>