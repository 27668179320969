<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="待发货" name="first"></el-tab-pane>
      <el-tab-pane label="物流中" name="second"></el-tab-pane>
      <el-tab-pane label="已接收" name="third"></el-tab-pane>
      <!-- <el-tab-pane label="异常货物" name="fourth"></el-tab-pane> -->
    </el-tabs>

    <el-row :gutter="20" class="mt30 mb30">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" plain size="medium" @click="init(1)">搜索</el-button>
      </el-col>
    </el-row>

    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <!-- <el-table-column align="center" prop="logistNo" label="发货单号" key="1"></el-table-column> -->
      <el-table-column align="center" prop="fromLogistNo" label="发货方编号" key="2"></el-table-column>
      <!-- <el-table-column align="center" prop="toLogistNo" label="收货方编号" key="3"></el-table-column> -->
      <el-table-column align="center" prop="carNo" label="车牌号码" v-if="activeName==='first'" key="4"></el-table-column>
      <el-table-column align="center" prop="fromObjName" label="发货方" v-if="activeName==='first'" key="5"></el-table-column>
      <el-table-column align="center" prop="toObjName" label="收货方" v-if="activeName==='first'" key="6"></el-table-column>

      <el-table-column align="center" prop="logistTypeName" label="发货方式" v-if="['second','third','fourth'].includes(activeName)" key="7"></el-table-column>
      <el-table-column prop="fromObjName" label="发货方" align="center" v-if="['second','third','fourth'].includes(activeName)" key="8"></el-table-column>
      <el-table-column prop="fromName" label="发货人" align="center" v-if="['second','third','fourth'].includes(activeName)" key="9"></el-table-column>
      <el-table-column prop="fromTime" label="发货时间" align="center" v-if="['second','third','fourth'].includes(activeName)" key="10"></el-table-column>
      <el-table-column prop="toObjName" label="收货方" align="center" v-if="['third'].includes(activeName)" key="11"></el-table-column>
      <el-table-column prop="toName" label="接收人" align="center" v-if="['third','fourth'].includes(activeName)" key="12"></el-table-column>
      <el-table-column prop="toReceiveTime" label="接收时间" align="center" v-if="['third','fourth'].includes(activeName)" key="13"></el-table-column>
      <el-table-column prop="preArriveTime" label="预计到达时间" align="center" v-if="['second','third'].includes(activeName)" key="14">
        <template slot-scope="scope"><p :class="new Date(scope.row.preArriveTime).getTime()<new Date().getTime()?'tipsColor':''">{{scope.row.preArriveTime}}</p></template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="activeName==='second'?'220px':'150px'" key="15">
        <template slot-scope="scope">
          <el-button size="mini" plain type="primary" v-if="activeName==='first'" @click="onsee(scope.row)">填写发货单</el-button>
          <el-button
            type="primary"
            @click="onsee(scope.row)"
            size="mini"
            v-if="['second','third'].includes(activeName)"
          >查看</el-button>
          <el-button
            type="primary"
            @click="onwarning(scope.row)"
            size="mini"
            v-if="activeName==='second'"
            :disabled="new Date(scope.row.preArriveTime).getTime()>new Date().getTime()?true:false"
          >处置预警</el-button>
          <el-button size="mini" plain type="primary" v-if="['fourth'].includes(activeName)" @click="onRouter(scope.row)">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog :visible.sync="dialogVisible" :show-close="false" title="处置预警" class="customDialog" width="500px">
      <el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="110px">
        <el-form-item label="预计到达时间" prop="preArriveTime">
          <el-date-picker v-model="form.preArriveTime" type="datetime" size="medium" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" class="w300"></el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="form.remark" size="medium" class="w300"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      carNumber:'',
      form: {
        preArriveTime: "",
        remark:'',
      },
      rules: {
        preArriveTime: [{ required: true, message: "预计到达时间不能为空" }],
        remark: [{ required: true, message: "备注不能为空" }]
      },
      querylist: [],
      dialogVisible: false,
      total: 0,
      currentPage: 1,
      pageSize:20,
      itemRows:{},
    };
  },
  created(){
    if(this.$route.query.state) this.activeName=this.$route.query.state;
    this.init()
  },
  methods: {
    init(page) {
      let curPage=this.currentPage;
      let pageSize=this.pageSize;
      let logistStatus=11;//11待发货, 12在途, 13待收货，14已接收，15异常
      let carNo='';
      switch(this.activeName){
        case 'first':
          logistStatus=[11];
          carNo=this.form.carNo;
          break;
        case 'second':
          logistStatus=[12];
          break;
        case 'third':
          logistStatus=[14];
          break;
        case 'fourth':
          logistStatus=[15];
          break;
      }
      if(page){
        curPage=page;
      }
      let query={
        curPage:curPage,
        pageSize:pageSize,
        logistStatus:logistStatus,//物流状态：11待发货, 12物流中/待收货, 13已接收，14异常
        carNo:carNo,
      }
      this.$api.getLogisticsList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = [];
            this.total = 0;
          }
        }
      })
    },
    onsee: function(row) {
      this.$router.push({
        path:'/warehouseKeeper/shipmentDetails',
        query:{
          id:row.id
        }
      })
    },
    onRouter(row) {
      let query={};
      let path= '/salesManager/deliverGoods/handleExceptions';
      
      if(row){
        query.id=row.id;
      }
      this.$router.push({
        path:path, 
        query
      })
    },
    handleClick(){
      this.init(1)
    },
    handleSizeChange(e) {
      this.pageSize=e;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage=e
      this.init();
    },
    postAlarmHandling(logistId){//处置预警
      let query={
        "preArriveTime": this.form.preArriveTime,
        "remark": this.form.remark
      }
      this.$api.postAlarmHandling(logistId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.init()
            this.ondialogVisible()
          }
        }
      })
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmit(formdata){
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.postAlarmHandling(this.itemRows.id)
        } else {
          return false;
        }
      });
    },
    onwarning(row){
      this.itemRows=row;
      this.ondialogVisible();
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .w300{
    width: 300px;
  }
}
</style>
