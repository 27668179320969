<template>
  <div>
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive :include="cachedViews"> -->
        <!-- <router-view :key="$route.fullPath"/> -->
        <router-view :key="key"/>
      <!-- </keep-alive> -->
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style>

</style>