<template>
  <div class="pageContainer">
    <div class="ckxTitle">车辆信息</div>
    <el-row :gutter="20" class="mt20">
      <el-col :span="5">车辆号牌：{{form.carNo}}</el-col>
      <el-col :span="5">委托出售价：{{form.carTradePrice}}</el-col>
      <el-col :span="5">意向金签订时间：{{form.intentionTreatySigningTime}}</el-col>
      <el-col :span="5">有效期截止时间：{{form.expiryDate}}</el-col>
    </el-row>
    <div class="ckxTitle mt20 mb10">车主信息</div>
    <el-row :gutter="20">
      <el-col :span="5">姓名：{{form.customerName}}</el-col>
      <!-- <el-col :span="6">手机号：{{form.publicOrPrivateName}}
        <el-button type="primary" @click="onCall" size="mini" plain>拨打</el-button>
      </el-col> -->
    </el-row>
    <div class="ckxTitle mt20 mb10">交易失败原因</div>
    <p>{{form.remark}}</p>
   
    <div class="ckxTitle mt20">待收款项</div>
    <el-table :data="moneylist" border class="mt20">
      <el-table-column type="index" align="center" label="序号" width="60px"></el-table-column>
      <el-table-column prop="intentionMoney" label="金额（元）" align="center"></el-table-column>
      <el-table-column prop="stateName" label="状态" align="center"></el-table-column>
      <el-table-column prop="collectMoneyTime" label="收款完成时间" align="center"></el-table-column>
    </el-table>
    <template v-if="[8,9,12,13,14,15].includes(state)">
      <div class="ckxTitle mt20 mb10">坏账原因</div>
      <el-input type="textarea" v-model="forms.badDebtReason" v-if="[8,9].includes(state)" :disabled="see?true:false"></el-input>
      <p v-else>{{form.badDebtReason}}</p>
    </template>
    <div class="footer">
      <div class="btn">
          <el-button type="primary" @click="onbadDebt" v-if="[8,9].includes(state)&&!see" >申请坏账</el-button>
          <el-button type="primary" @click="onReturn" >返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{},
      intentionMoneyId:'',
      state:8,//8-待收款，12-已申请坏账，3-收款失败，4已收款
      moneylist:[],//
      forms:{
        badDebtReason:''
      },
      see:'',
    }
  },
  created(){
    if(this.$route.query.intentionMoneyId) this.intentionMoneyId = this.$route.query.intentionMoneyId;
    if(this.$route.query.see) this.see = this.$route.query.see;//是否查看
    this.getIntentionMoneyDetail(this.intentionMoneyId)//获取详情
  },
  methods:{
    // onCall(){},
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/salesManager/intentionMoney/intentionMoney"
      });
    },
    getIntentionMoneyDetail(intentionMoneyId){//获取详情
      let query={
        // followUpId:followUpId,//业务跟进ID 当意向金协议Id为空时根据此字段查询基础信息
        intentionMoneyId:intentionMoneyId//意向金Id
      }
      this.moneylist=[];
      this.$api.getIntentionMoneyDetail(this.$$.delete_null_properties(query)).then(res =>{
        if(res.code==0){
          this.form=res.data;
          this.state=res.data.state;
          let obj={
            collectMoneyTime:res.data.collectMoneyTime,
            intentionMoney:res.data.intentionMoney,
            stateName:res.data.stateName
          }
          this.moneylist.push(obj)
        }
      })
    },
    onbadDebt(){
      if(!this.forms.badDebtReason){
        this.$message('请填写坏账原因！')
        return
      }
      let state=12;
      if(this.state==9){
        state=13
      }
      let query={
        state:state,//返还意向金已申请¬坏账
        badDebtReason:this.forms.badDebtReason
      }
      this.updateIntentionMoney(query)
    },
    updateIntentionMoney(query){//意向金协议 更新
      this.$api.updateIntentionMoney(this.intentionMoneyId,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.getIntentionMoneyDetail(this.intentionMoneyId)//获取详情
          this.$message.success(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.pageContainer{
  padding: 20px 40px;
}
</style>