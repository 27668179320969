<template>
  <div class="pageBox">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w8" >
        <span>客户合同签订日期:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>办证状态:</span>
        <el-select v-model="params.state" size="medium" clearable>
          <el-option :value="68" label="办证中"></el-option>
          <el-option :value="69" label="办证完成"></el-option>
        </el-select>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30" >
      <el-table-column prop="tradeShopName" label="成交门店" align="center" ></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
      <el-table-column prop="merchantName" label="商户名称" align="center" >
        <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="makerName" label="车型" align="center" >
        <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="certTypeName" label="办证方式" align="center" ></el-table-column>
      <el-table-column prop="contractDate" label="客户合同签订日期" align="center" ></el-table-column>
      <el-table-column prop="customerDeadline" label="办证预计完成日期" align="center" ></el-table-column>
      <!-- <el-table-column prop="carNo" label="过户凭证状态" align="center" ></el-table-column> -->
      <el-table-column prop="taskSelfCheckStatusName" label="办证状态" align="center" ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="dbclick(scope.row)" size="mini">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      querylist: [], 
      total: 0,
      currentPage: 1,
      pageSize: 20,
      params:{
        state:''
      },
    };
  },
  created() {
    if(this.$route.params.state) this.params.state=Number(this.$route.params.state);

    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        taskType: 5,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        taskSelfStatuses:this.params.state?[this.params.state]:[68,69],//办证专员-商户自理费用: 68 待审核,69 审核通过
        carNo:this.params.carNo,
        merchantDeadlineBegin:this.params.date?`${this.params.date[0]}`:'',
        merchantDeadlineEnd:this.params.date?`${this.params.date[1]}`:'',
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    dbclick(row) {
      let aurl = "/accreditationCommissioner/merchantSelfCareDetails";
      this.$router.push({
        path: aurl,
        query: {
          id: row.id
        },
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.pageBox {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>