<template>
  <div class="role">
    <!-- 顶部菜单 -->
    <Sidebar class="sidebarCon" :isMenu="false"/>
    <div class="roleContBox">
      <div class="roleList list">
        <div class="ckxTitle">
          <span>当前角色: </span><span class="bluetxt">{{params.role}}</span>
        </div>
        <ul class="flex flex_wrap">
          <li v-for="(item,index) in role[0].children" :key="index" :class="{'flex':true,'flex_align_center':true,'flex_center':true,'active':curRole===item.id||item.isCurrent==0&&curRole==-1}" @click="selectIndex(item.id,item)">{{item.name}}</li>
        </ul>
      </div>
      <div class="list pb100">
        <div class="title_top_nameBox">
          <span>当前部门: </span><span class="bluetxt">{{params.store}}</span>
        </div>
        <ul class="flex flex_wrap">
          <li @click="selectStore(item.id,item)" v-for="(item,index) in role[1].children" :key="index" :class="{'flex':true,'flex_align_center':true,'flex_center':true,'active':curArr.includes(item.id)||item.isCurrent==0&&curArr.length==0&!nameType}">{{item.name}}</li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onsubmit">去工作台</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/sidebar/horizontal.vue'
export default {
  components: {
    Sidebar,
  },
  data(){
    return {
      role: [
        {
          key: '门店店长',
          children:[]
        }, 
        {
          key: '帮卖经理',
          children:[]
        }
      ],
      curRole: -1,
      curStore: -1,
      curArr:[],
      curNameArr:[],
      nameType:false,//是否点击了部门
      params: {
        role: '',
        store: ''
      },
      userId:'',
      checkedDev:{},//当前选择的部门
    }
  },
  created(){
    this.userId=this.$store.state.user.userId;
    this.getData()
  },
  methods:{
    getData(state){//获取角色
      this.curArr=[];
      this.curNameArr=[];
      this.$api.getUserData(this.userId).then((res)=>{
        if(res.code==0){
          this.role[0].children=res.data.roleList;
          let  arr = [...res.data.departmentList].filter(y => y.isCurrent == 0);//部门
          let  arr1 = [...res.data.roleList].filter(y => y.isCurrent == 0);//角色
          this.params.store=arr.length>0?arr[0].name:'';
          this.curStore =arr.length>0?arr[0].id:-1;
          this.curRole=arr1.length>0?arr1[0].id:-1;
          this.params.role=arr1.length>0?arr1[0].name:'';
          //
          let arrNew=JSON.parse(JSON.stringify(arr))
          for(let i=0;i<arr.length;i++){
            if(arrNew[i].isCurrent==0){
              this.curArr.push(arrNew[i].id)
              this.curNameArr.push(arrNew[i].name)
            }
          }
          if(arr1.length>0&&!state){
            this.getListDepartmentByRoleId(arr1[0].id)
          }
          if(state&&res.data.menuWithCurrentRoleList){
            sessionStorage.setItem('userData',JSON.stringify(res.data))
            this.$store.dispatch('user/setUserData',res.data).then(()=>{
              // console.log(res.data.menuWithCurrentRoleList,'===555====')
             if(res.data.menuWithCurrentRoleList.length>0){
                // this.bus.$emit("closeAllTags");
                // this.$router.go(-1)
                this.$router.push({
                  path:'/workbench/index'
                }).then(()=>{
                  location.reload();
                })
              }else{
                this.$message.warning('当前角色部门下没有菜单，请联系管理员或切换当前角色！')
                return
              }
              let userId=this.$store.state.user.userId;//用户id
              this.$datadic.getUserData(userId,true)
            })
            
          }
          
        }
      })
    },
    selectIndex(index,item){//角色
      this.curRole=index;
      this.params.role=item.name;
      this.getListDepartmentByRoleId(item.id)
    },
    selectStore(index,item){//部门
      this.curStore = index;
      this.nameType=true;
      this.curArr=[index];
      this.curNameArr=[item.name];
      // if(this.params.role=='帮卖经理'){
      //   this.curArr=[index];
      //   this.curNameArr=[item.name];
      // }else{
      //   if (this.curArr.includes(index)) {
      //     this.curArr.splice(this.curArr.findIndex(e => index === e), 1)
      //   }else{
      //     this.curArr.push(index)
      //   }
      //   if (this.curNameArr.includes(item.name)) {
      //     this.curNameArr.splice(this.curNameArr.findIndex(e => item.name === e), 1)
      //   }else{
      //     this.curNameArr.push(item.name)
      //   }
      // }
      this.params.store = String(this.curNameArr);
    },
    onsubmit(){
      if(this.role[0].children.length==0&&this.role[1].children.length==0){
        this.$message.warning('请联系管理员！')
        return
      }
      let itemRole = this.role[0].children.find(item => {
          return item.isCurrent == 0;
      });
      if(this.curRole==-1&&!itemRole){
        this.$message.warning('请选择当前角色！')
        return
      }else{
        if(this.curRole==-1){
          this.curRole=itemRole.id
        }
      }
      if(this.curStore==-1&&this.curArr.length==0){
        this.$message.warning('请选择当前部门！')
        return
      }
      // if(this.params.role=='帮卖经理'){
      //   if(this.curArr.length!=1){
      //     this.$message('帮卖经理只能选择一个部门，请重新选择！')
      //     return
      //   }
      // }

      let a1=new Promise((resolve,reject)=>{
          this.changeCurrentRoleByUserId(resolve,reject)
      })
      let a2=new Promise((resolve,reject)=>{
        this.changeCurrentDepartmentByUserId(resolve,reject)
      })
      Promise.all([a1,a2]).then(()=>{
        localStorage.removeItem('menuRole')
        this.getData(true)
      }).catch(()=>{})
    },
    //根据用户id切换其当前角色
    changeCurrentRoleByUserId(resolve,reject){
      let query={
        currentRoleId:this.curRole
      }
      this.$api.changeCurrentRoleByUserId(this.userId,query).then((res)=>{
        if(res.code==0){
          resolve()
        }else{
          reject(res)
        }
      })
    },
    //保存部门
    changeCurrentDepartmentByUserId(resolve,reject){
      let query={
        currentDepartmentIds:String(this.curArr)
      };
      this.$api.changeCurrentDepartmentByUserId(this.userId,this.curRole,query).then((res)=>{
        if(res.code==0){
          resolve()
        }else{
          reject(res)
        }
      })
    },
    getListDepartmentByRoleId(roleId){//根据roleId获取部门
      this.$api.getListDepartmentByRoleId(roleId).then(res=>{
        if(res.code==0){
          this.role[1].children=res.data;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.role{
  // padding: 40px;
  .roleContBox{
    padding: 40px;
  }
  font-size: 20px;
  .list{
    ul{
      margin: 10px -20px 10px 0;
      li{
        width: 176px;
        height: 96px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 24px;
        border: 1px solid #DCDCDC;
        margin: 10px 20px 10px 0;
      }
      li:hover,li.active{background: #0099FF;color: white;border-color: #0099FF;}
    }
  }
  .footer{
    background: #f5f5f5;
    padding-top: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    .btn{
      background: white;
      padding: 20px 0;
      text-align: center;
    }
  }
}
</style>