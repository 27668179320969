import { render, staticRenderFns } from "./priceDetails.vue?vue&type=template&id=3fdf59eb&scoped=true&"
import script from "./priceDetails.vue?vue&type=script&lang=js&"
export * from "./priceDetails.vue?vue&type=script&lang=js&"
import style0 from "./priceDetails.vue?vue&type=style&index=0&id=3fdf59eb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdf59eb",
  null
  
)

export default component.exports