<template>
  <!-- 次日产能排班 -->
  <div class="nextDayScheduling">
    <div class="flex flex_align_center status mb20">
      <span class="mr30">{{nextDay()}}</span>
      <span class="work mr10">-预约成功</span>
      <span class="successBox statusBox mr30"></span>
      <span class="rest mr10">-不可预约</span>
      <span class="disabledBox statusBox mr30"></span>
      <span class="leave mr10">-可预约</span>
      <span class="canBox statusBox mr30"></span>
    </div>
    <table>
      <tr><th>门店名称</th><th>任务单量</th><th>值班人员</th><th>操作</th></tr>
      <tr v-for="item in capacityList" :key="item.storeNo">
        <td>{{item.storeName}}</td>
        <td>
          <div class="timeWrap" v-for="ite in item.checkers" :key="ite.checkerId">
            <div v-for="it in ite.checkTimeCapacity" :key="it.time" :class="{'status0': it.status===0,'status1': it.status===1,'status2': it.status===2,'statusDiv': true}">{{it.time}}</div>
          </div>
        </td>
        <td>
          <div>
            <div v-for="ite in item.staff" :key="ite">{{ite}}</div>
          </div>
        </td>
        <td><el-button plain type="primary" @click="assign(item.storeNo)" size="mini">指派</el-button></td>
      </tr>
    </table>

    <!-- 指派检测师 -->
    <el-dialog :visible.sync="dialogVisible" center title="指派检测师">
      <el-form :model="testForm" label-width="150px" ref="testForm">
        <el-form-item label="选择时间(可多选)" prop="testDate">
          <el-select style="width:100%;" v-model="testForm.testDate" placeholder="请选择" :rules="testFormRules" @change="handleChange">
            <el-option label="上午(9：00-12：00)" :value="1"></el-option>
            <el-option label="下午(13：00-18：00)" :value="2"></el-option>
            <el-option label="全天" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择检测师" prop="testName">
          <el-cascader style="width:100%;" v-model="testForm.testName" :options="testOptions" :show-all-levels="false" :props="{value:'id',label: 'name',children: 'users'}"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTestForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      capacityList: [],
      dialogVisible: false,
      curStoreNo: '',
      testOptions: [],
      testForm: {
        testName: '',
        testDate: 1
      },
      testFormRules: {
        testName: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        testDate: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
    }
  },
  methods: {
    nextDay() {
      const date = new Date(Date.now()+86400000)
      return this.$$.getFormatMomentTime('YYYY/MM/DD', date);
    },
    handleChange() {
      let params = {
        startTime: '',
        endTime: '',
      }
      if(this.testForm.testDate === 1){
        params.startTime = '09:00';
        params.endTime = '12:00';
      }else if(this.testForm.testDate === 1){
        params.startTime = '13:00';
        params.endTime = '18:00';
      }else{
        params.startTime = '09:00';
        params.endTime = '18:00';
      }
      this.$api.checkerQueryLeisureChecker(params).then(res => {
        if(res.code == 0){
          this.testOptions = res.data;
        }
      })
    },
    nextDayQueryScheduleCapacity() {
      this.$api.nextDayQueryScheduleCapacity().then(res => {
        if(res.code == 0){
          res.data.forEach(item => {
            let staff = [];
            item.checkers.forEach(ite => {
              staff.push(ite.checkerName)
            })
            item.staff = staff
          })
          this.capacityList = res.data;
        }
      })
    },
    assign(storeNo) {
      this.curStoreNo = storeNo;
      this.dialogVisible = true;
    },
    submitTestForm() {
      this.$refs['testForm'].validate((valid) => {
        if (valid) {
          let params = {
            storeNo: this.curStoreNo,
            checkerId: this.testForm.testName[this.testForm.testName.length-1],
            isBackup: 0,
            startTime: '',
            endTime: '',
          }
          if(this.testForm.testDate === 1){
            params.startTime = '09:00';
            params.endTime = '12:00';
          }else if(this.testForm.testDate === 1){
            params.startTime = '13:00';
            params.endTime = '18:00';
          }else{
            params.startTime = '09:00';
            params.endTime = '18:00';
          }
          this.$api.nextDayAssignChecker(params).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功')
              this.nextDayQueryScheduleCapacity()
              this.dialogVisible = false;
              this.$refs['testForm'].resetFields();
            }
          })
        } else {
          return false;
        }
      });
    }
  },
  created() {
    this.nextDayQueryScheduleCapacity();
  },
  mounted() {
    this.handleChange();
  }
}
</script>

<style scoped lang="scss">
.nextDayScheduling{
  padding: 20px 40px;
  .status{
    margin: 20px 0 0;
    .statusBox{
      width: 42px;
      height: 16px;
      display: inline-block;
      border: 1px solid #ccc;
    }
    .successBox{background: #3799FF;border-color: #3799FF;}
    .disabledBox{background: #ccc;border-color: #ccc;}
  }
  table{
    border-collapse: collapse;
    width: 100%;
    th{
      padding: 10px 0;
      border: 1px solid #cccccc;
      text-align: center;
    }
    td{
      border: 1px solid #cccccc;
      text-align: center;
      .timeWrap{
        min-width: 900px;
        display: flex;
        flex-wrap: wrap;
        .statusDiv{
          width: 11.1111%;
          padding: 8px;
          text-align: center;
          border: 1px solid #cccccc;
        }
        .status1{background: #3799FF; color: #fff;}
        .status2{background: #ccc; color: #fff;}
      }
    }
  }
}
</style>