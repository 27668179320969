<template>
  <div class="certificateBox pb100">
    <!--基本信息-->
    <div class="content_modal_textBox">
      <div class="head_top_title">
        <i></i>
        <span>基本信息</span>
      </div>
      <div class="content_title_Box evidenDetail_titleOne">
        <div class="content_title_row">
          <label>车牌号：</label>
          <span>{{form.carNo}}</span>
        </div>
        <div class="content_title_row">
          <label>办证类型：</label>
          <span>{{form.certTypeName}}</span>
        </div>
        <div class="content_title_row">
          <label>品牌车型：</label>
          <span>{{form.makerName}}&nbsp;{{form.seriesName}}</span>
        </div>
        <div class="content_title_row">
          <label>业务归属：</label>
          <span>{{form.businessOwnArea}}</span>
        </div>
        <!-- <div class="content_title_row">
          <label>车辆所在库：</label>
          <span>{{res.stockObjInName}}</span>
        </div> -->
        <!-- <div class="content_title_row">
          <label>违章处理方：</label>
          <span>{{form.breakRuleDispose}}</span>
        </div> -->
      </div>
    </div>
    <!--办证信息-客户方-->
    <el-row :gutter="24" class="mt20">
      <el-col :span="12">
        <div class="head_top_title flex_space">
          <div class="ckxTitle">办证信息-客户方</div>
          <span class="seeColor" @click="onrouter(0)">客户合同查看>></span>
        </div>
        <el-row>
          <el-col :span="12">
            <div class="content_title_row">
              <label>客户姓名：</label>
              <span>{{form.customerName}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content_title_row">
              <label>客户电话：</label>
              <span>{{form.customerMobile}}</span>
              <el-button
                type="primary"
                class="ml30"
                @click="oncalll(form.customerMobile,1)"
                size="mini"
                v-show="form.customerMobile"
                plain
              >呼叫</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row class="mt10 mb10">
          <el-col :span="12">
            <div class="content_title_row">
              <label>帮卖经理：</label>
              <span>{{customerContract.sellerName}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content_title_row">
              <label>客户电话：</label>
              <span>{{form.carCustomer.customerPhone}}</span>
              <el-button
                type="primary"
                class="ml30"
                @click="oncalll(form.carCustomer.customerPhone,2)"
                size="mini"
                v-show="form.sellerMobile"
                plain
              >呼叫</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="content_title_row">
              <label>客户办证截止日期：</label>
              <span>{{customerContract.customerDeadline}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content_title_row">
              <label>成交门店：</label>
              <span class="content_title_rightSpan">{{customerContract.tradeShopName}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="12">
            <div class="content_title_row">
              <label>身份证号：</label>
              <span>{{form.customerIdNo}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content_title_row">
              <label>客户合同签订日期：</label>
              <span class="content_title_rightSpan">{{customerContract.signTime}}</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <div class="head_top_title flex_space">
          <div class="alignCenter">
            <i></i>
            <span>办证信息-商户方</span>
          </div>
          <span class="seeColor" @click="onrouter(1)">商户合同查看>></span>
        </div>
        <el-row class="mt10">
          <el-col :span="12">
            <div class="content_title_row">
              <label>商户姓名：</label>
              <span>{{res.merchantName}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content_title_row">
              <label>商户电话：</label>
              <span>{{res.merchantMobile}}</span>
              <el-button
                type="primary"
                class="ml30"
                @click="oncalll(res.merchantMobile)"
                size="mini"
                v-show="res.merchantMobile"
                plain
              >呼叫</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="content_title_row">
              <label>商户维护人员：</label>
              <span>{{form.carMerchant.maintainerName}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content_title_row">
              <label>商户电话：</label>
              <span>{{form.carMerchant.merchantOtherMobile}}</span>
              <el-button
                type="primary"
                class="ml30"
                @click="oncalll(form.carMerchant.merchantOtherMobile)"
                size="mini"
                v-show="form.carMerchant.merchantOtherMobile"
                plain
              >呼叫</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row class="mt10 mb10">
          <el-col :span="24">
            <div class="content_title_row">
              <label>商户办证截止日期：</label>
              <span>{{res.merchantDeadline}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="content_title_row w100">
              <label>经营城市：</label>
              <span>{{form.carMerchant.businessCityName}}</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--违章信息-->
    <div class="content_modal_textBox mt30">
      <div class="ckxTitle mb25">违章信息</div>
     <el-table :data="breakRuleList" border v-if="[1,2].includes(state)">
        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
        <el-table-column prop="typeName" label="类型" align="center"></el-table-column>
        <el-table-column prop="breakRuleCounts" label="违章描述" align="center">
          <template slot-scope="scope"><span class="lineBox">{{scope.row.breakRuleCounts}}</span>次</template>
        </el-table-column>
        <el-table-column prop="driveLicenseSubScore" label="驾照扣分" align="center"></el-table-column>
        <el-table-column prop="payableAmount" label="违章本金（元）" align="center"></el-table-column>
        <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
        <el-table-column prop="feeBearerAmount" label="承担方金额（元）" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center">
          <template slot-scope="scope">{{scope.row.beneficiaryName}}{{[4,5].includes(scope.row.beneficiaryId)?scope.row.benefitCertRuleSupplierName?'-'+scope.row.benefitCertRuleSupplierName:'':''}}</template>
        </el-table-column>
        <el-table-column prop="beneficiaryAmount" label="受益方金额（元）" align="center"></el-table-column>
        <el-table-column prop="appointDisposeDate" label="约定处理日期" align="center"></el-table-column>
        <!-- <el-table-column prop="statusName" label="处理状态" align="center" v-if="state==3"></el-table-column> -->
        <el-table-column label="费用确认" align="center" width="100">
          <template slot-scope="scope">
           <el-checkbox v-model="scope.row.check" v-if="scope.row.status==71"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="breakRuleList" border v-if="[3].includes(state)">
        <el-table-column prop="id" label="ID" align="center" width="90"></el-table-column>
        <el-table-column prop="name" label="项目" align="center"></el-table-column>
        <el-table-column prop="breakRuleDetailsStatusName" label="合同状态" align="center">
          <template slot-scope="scope">{{scope.row.breakRuleDetailsStatusName?scope.row.breakRuleDetailsStatusName:'-'}}</template>
        </el-table-column>
        <!-- <el-table-column prop="breakRuleDetails" label="违章情况" align="center">
          <template slot-scope="scope"><span class="lineBox">{{scope.row.breakRuleCounts}}</span>次</template>
        </el-table-column> -->
        <el-table-column prop="appointDisposeDate" label="约定处理日期" align="center">
           <template slot-scope="scope">{{scope.row.appointDisposeDate?scope.row.appointDisposeDate:'-'}}</template>
        </el-table-column>
        <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
        <el-table-column prop="feeBearerAmount" label="承担方金额" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center">
          <template slot-scope="scope">{{scope.row.beneficiaryName}}{{[4,5].includes(scope.row.beneficiaryId)?scope.row.benefitCertRuleSupplierName?'-'+scope.row.benefitCertRuleSupplierName:'':''}}</template>
        </el-table-column>
        <el-table-column prop="beneficiaryAmount" label="受益方金额" align="center"></el-table-column>
        <el-table-column prop="statusName" label="处理状态" align="center"></el-table-column>
        <el-table-column prop="breakFinishTime" label="违章处理完成时间" align="center">
           <template slot-scope="scope">{{scope.row.breakFinishTime?scope.row.breakFinishTime:'-'}}</template>
        </el-table-column>
        <!-- <el-table-column prop="paymentStatusName" label="付款状态" align="center">
           <template slot-scope="scope">{{scope.row.paymentStatusName?scope.row.paymentStatusName:'-'}}</template>
        </el-table-column>
        <el-table-column prop="payedTime" label="付款完成时间" align="center">
           <template slot-scope="scope">{{scope.row.payedTime?scope.row.payedTime:'-'}}</template>
        </el-table-column> -->
      </el-table>
    </div>
    <!--跟进记录-->
    <div class="content_modal_textBox mt30 mb30">
      <div class="ckxTitle mb15">跟进记录</div>
      <el-table :data="certInviteTraceList" border>
        <el-table-column prop="operateTime" label="操作时间" align="center"></el-table-column>
        <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
        <el-table-column prop="nextInviteTime" label="下次跟进时间" align="center">
          <template slot-scope="scope">{{scope.row.nextInviteTime?scope.row.nextInviteTime:'-'}}</template>
        </el-table-column>
        <el-table-column prop="certInviteStatusName" label="跟进结果" align="center"></el-table-column>
        <el-table-column prop="description" label="结果描述" align="center"></el-table-column>
      </el-table>
    </div>

    <div class="footer" v-if="state!=3">
      <div class="btn">
        <el-button type="primary" @click="onClick(1)">费用确认</el-button>
        <el-button type="primary" @click="onClick(2)">违章处理成功</el-button>
        <el-button type="primary" @click="onClick(3)">新增跟进记录</el-button>
        <el-button type="primary" @click="onClick(4)">新增违章</el-button>
      </div>
    </div>
    <!--新增违章-->
    <el-dialog
      :title="`${ruleText}`"
      :visible.sync="dialogVisible"
      :show-close="false"
      class="customDialog"
      width="750px"
    >
      <el-form
        ref="forms"
        :model="forms"
        label-width="150px"
        :rules="rules"
        :hide-required-asterisk="false"
        class="mt20"
      >
      <template v-if="modelState==4">
        <el-row>
          <el-col :span="12">
            <el-form-item label="序号:">
              <p>{{this.breakRuleList.length+1}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型:">
              <p>{{ruleText}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
           <el-col :span="12">
            <el-form-item label="违章供应商:" prop="supplierObj">
               <el-cascader :options="supplierList" 
               ref="refcascader" @change="changeSupplie(1)" :props="props"  v-model="forms.supplierObj" :show-all-levels="false" clearable></el-cascader>
              <!-- <el-select
            v-model="forms.supplierName"
            size="medium">
              <el-option v-for="item in supplierList" :key="item.id" :value="item.name" :label="item.name"></el-option>
            </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="受益方金额(元):" prop="beneficiaryAmount">
              <el-input
                type="number"
                v-model.number="forms.beneficiaryAmount"
                class="w180"
                size="medium"
                placeholder="请输入受益方金额"
              >
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="费用承担方:" prop="feeBearerId">
              <el-select v-model="forms.feeBearerId" placeholder="请选择费用承担方" class="w180" size="medium">
                <el-option label="客户" value="1"></el-option>
                <el-option label="商户" value="2"></el-option>
                <el-option label="车开新" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="承担方金额(元):" prop="feeBearerAmount">
              <el-input
                type="number"
                v-model.number="forms.feeBearerAmount"
                class="w180"
                size="medium"
                placeholder="请输入承担方金额"
              >
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="驾照扣分:" prop="driveLicenseSubScore">
              <el-input
                type="number"
                v-model.number="forms.driveLicenseSubScore"
                class="w180"
                size="medium"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="违章次数:" prop="breakRuleCounts">
              <el-input type="number" v-model.number="forms.breakRuleCounts" class="w180" size="medium"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="约定处理日期:" prop="appointDisposeDate">
              <el-date-picker
                v-model="forms.appointDisposeDate"
                type="date"
                placeholder="请选择约定完成时间"
                value-format="yyyy-MM-dd"
                class="w180"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="违章成本(元):" prop="payableAmount">
              <el-input type="number" v-model.number="forms.payableAmount" class="w180" size="medium">
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </template>
        <template v-if="modelState==3">
          <el-form-item label="下次跟进时间:" prop="nextInviteTime">
            <el-date-picker
              v-model="forms.nextInviteTime"
              type="datetime"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              class="w200"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="forms.description"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitform">保存</el-button>
        <el-button @click="dialogVisible=false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="违章费用确认"
      :show-close="false"
      :visible.sync="comfirmDialogVisible"
      class="customDialog"
      width="1300px"
    >
      <el-table :data="breakRuleList1" border height="500px">
        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
        <el-table-column prop="typeName" label="类型" align="center"></el-table-column>
        <el-table-column prop="breakRuleCounts" label="违章描述" align="center">
          <template slot-scope="scope"><span class="lineBox">{{scope.row.breakRuleCounts}}</span>次</template>
        </el-table-column>
        <el-table-column prop="driveLicenseSubScore" label="驾照扣分" align="center"></el-table-column>
        <el-table-column prop="payableAmount" label="违章本金（元）" align="center"></el-table-column>
        <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
        <el-table-column prop="feeBearerAmount" label="承担方金额（元）" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="违章处理供应商（受益方）" align="center">
          <template slot-scope="scope">
            <el-cascader :options="supplierList" 
               :ref="`refcascaderT${scope.$index}`" @change="changeSupplie(2,scope.row,scope.$index)" :props="props"  v-model="scope.row.beneficiaryIdArr" :show-all-levels="false" clearable></el-cascader>
            <!-- <el-select
            v-model="scope.row.beneficiaryId"
            size="medium">
              <el-option v-for="item in supplierList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select> -->
          </template>
        </el-table-column>
        <el-table-column prop="beneficiaryAmount" label="受益方金额（元）" align="center">
          <template slot-scope="scope">
            <el-input
            type="number"
            v-model.number="scope.row.beneficiaryAmount"
            size="medium"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="appointDisposeDate" label="约定处理日期" align="center"  width="180">
          <template slot-scope="scope">
            <el-date-picker v-model="scope.row.appointDisposeDate" type="date" class="w150" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="费用确认" align="center">
          <template slot-scope="scope">
           <el-checkbox v-model="scope.row.check" @input="forceUpdate"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitform">保存</el-button>
        <el-button @click="comfirmDialogVisible=false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state:1,//1-待处理-跟进违章处理，2-跟进中-跟进违章处理，3已处理-查看
      modelState:1,//1费用确认，2违章处理成功，3新增跟进记录，4新增违章
      breakRuleList1: [], //违章费用确认
      breakRuleList:[],//违章信息列表
      certInviteTraceList: [],//办证跟踪任务列表
      form: {
        carCustomer: {}, //	车辆客户信息
        carMerchant: {}, //车辆商户信息
      }, //展示数据
      carMerchant: {}, //车辆商户信息
      customerContract:{},//客户合同
      res:{},
      followupForm: {
        bepresentType: 0,
        followupResult: "继续跟进",
        desc: "",
        nextTime: "",
        InviteTime: ""
      },
      forms: {
        supplierObj:[],
        supplierName:'',
        payableAmount: "",
        feeBearerId: "",
        feeBearerAmount: "",
        driveLicenseSubScore: "",
        breakRuleCounts: "",
        appointDisposeDate: "",
        beneficiaryAmount: ""
      },
      dialogVisible: false,
      comfirmDialogVisible: false, //二次确认弹出框
      tabClassify: "first",
      rules: {
        supplierObj:[{ required: true, message: "不能为空" }],
        beneficiaryAmount: [{ required: true, message: "受益方金额不能为空" }],
        feeBearerId: [{ required: true, message: "费用承担方不能为空" }],
        feeBearerAmount: [{ required: true, message: "承担方金额不能为空" }],
        driveLicenseSubScore: [{ required: true, message: "驾照扣分不能为空" }],
        breakRuleCounts: [{ required: true, message: "违章次数不能为空" }],
        appointDisposeDate: [
          { required: true, message: "约定处理日期不能为空" }
        ],
        payableAmount: [{ required: true, message: "违章本金不能为空" }],
        nextInviteTime: [{ required: true, message: "下次跟进时间不能为空" }]
      },
      transferType: false, //是否过户完成
      taskId: "",
      loading: false,
      channllist: [],
      itemRuleRow:{},//违章信息当前数据
      supplierList:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司',children:[]},{id:5,name:'违章供应商',children:[]}],//供应商列表
      ruleText:'新增',
      userData:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
      props:{
        label:'name',
        value:'id',
        children:'children'
      }
    };
  },
  created() {
    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    // if(this.$route.query.state) this.state = Number(this.$route.query.state);
    if(this.$route.query.id) this.taskId = this.$route.query.id;
    this.getCertificateTaskDetails(); //获取详情

    this.postSupplierList();//查询供应商列表【不分页】
  },
  methods: {
    forceUpdate(){
      this.$nextTick(()=>{
        this.$forceUpdate();
      })
    },
    changeSupplie(type,row,index){
      if(type==1){//1form表单，2表格数据
        let node=this.$refs.refcascader.getCheckedNodes();
        if(node.length>0){
          this.forms.beneficiaryId=node[0].path[0];
          this.forms.benefitCertRuleSupplierId=node[0].path[1]
          this.forms.benefitCertRuleSupplierName=node[0].pathLabels[1]
        }

      }else{
        let node=this.$refs[`refcascaderT${index}`].getCheckedNodes();
        if(node.length>0){
          for(let i=0;i<this.breakRuleList1.length;i++){
            let item=this.breakRuleList1[i]
            if(i===index){
              item.beneficiaryId=node[0].path[0];
              item.benefitCertRuleSupplierId=node[0].path[1]
              item.benefitCertRuleSupplierName=node[0].pathLabels[1];
            }
          }
        }
        // console.log(this.breakRuleList1,'===breakRuleList1===',node,index)
      }
    },
    onClick(state){
      this.modelState=state;
      switch(state){
        case 1://费用确认
          var oldArr=JSON.parse(JSON.stringify(this.breakRuleList))
          var arr=[];
          for(var i=0;i<oldArr.length;i++){
            if(oldArr[i].status==71){
              if(oldArr[i].check!=true){
                oldArr[i].check=false;
              }
              if(oldArr[i].benefitCertRuleSupplierId){
                oldArr[i].beneficiaryIdArr=[oldArr[i].beneficiaryId,oldArr[i].benefitCertRuleSupplierId]
              }else{
                oldArr[i].beneficiaryIdArr=[oldArr[i].beneficiaryId]
              }
              
              arr.push(oldArr[i])
            }
          }
          this.breakRuleList1=arr;
          this.comfirmDialogVisible = !this.comfirmDialogVisible;
          break;
        case 2://违章处理成功
          this.$confirm('确认违章已全部处理完成！',  {
            confirmButtonText: '处理完成',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postTaskOperaRule('break_check_success')
          }).catch(() => {});
          break;
        case 3://新增跟进记录
          this.ruleText='新增跟进记录'
          this.ondialogVisible()
          break;
        case 4://新增违章
          this.ruleText='新增违章'
          this.ondialogVisible()
          this.$nextTick(()=>{
            this.$refs['forms'].clearValidate()
          })
          break;
      }
    },
    oncalll: function(row,phoneType) {
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row,phoneType)
      // })//根据员工工号查询坐席信息(拨打电话)
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber,phoneType){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      if(phoneType){
        query.phoneType=phoneType;
        query.customerId=this.form.carCustomer.id;
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitFollow(formdata) {
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.ondialogVisible();
          //此时重新加载身份证跟进信息列表
        } else {
          return false;
        }
      });
    },
    onSubmitform() {
      if(this.modelState==4){
        this.$refs['forms'].validate(valid => {
          if (valid) {
            this.postBreakruleAdd();//新增违章
          }
        });
      }else if(this.modelState==3){//新增跟进记录
        this.$refs['forms'].validate(valid => {
          if (valid) {
            this.postBreakRuleTraceAdd()
          } else {
            return false;
          }
        });
      }else if(this.modelState==1){//费用确认
        let arr=[];
        for(let i=0;i<this.breakRuleList1.length;i++){
          let item=this.breakRuleList1[i]
          if(item.check==true){
            let obj={
              breakRuleId:item.id,
              beneficiaryId:item.beneficiaryId,
              beneficiaryAmount:item.beneficiaryAmount,
              appointDisposeDate:item.appointDisposeDate,
              benefitCertRuleSupplierId:item.benefitCertRuleSupplierId,
              benefitCertRuleSupplierName:item.benefitCertRuleSupplierName
            }
            arr.push(obj)
          }
        }
        if(arr.length==0){
          this.$message('请选择需要确认的费用！')
          return
        }
        let query={
          taskId:this.taskId,
          taskBreakRuleCheckResult:'break_confirm',
          breakRuleConfirmForms:arr
        }
        this.$api.postTaskOperaRuleConfirm(query).then(res=>{
          if(res.code==0){
            this.comfirmDialogVisible=false;
            this.$message.success(res.message)
            this.getCertificateTaskDetails()//详情
          }
        })
      }
    },
    // changeChannl(row) {
    //   this.form.channelId = row.id;
    //   this.forms.supplierName = row.name;
    // },
    remoteMethod(data) {
      if (data !== "") {
        this.loading = true;
        setTimeout(() => {
          let query = {
            name: data
          };
          this.$api.postSupplierQuery(query).then(res => {
            if (res) {
              if (res.code == 0) {
                this.loading = false;
                this.channllist = res.data.records;
              } else {
                this.channllist = [];
              }
            }
          });
        }, 200);
      } else {
        this.channllist = [];
      }
    },
    onrouterPage() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/accreditationCommissioner/violationHandling"
      });
    },
    onrouter(state) {
      //跳转客户商户合同
      let aurl = "/salesManager/contractMaking";
      let id=this.form.customerContract?this.form.customerContract.id:'';//客户合同
      if (state) {
        aurl = "/salesAssistant/merchantContract";
        id=this.res.merchantContractId;//商户合同,注意：car表里面没有放商户合同表
      }
      if(!id){
        this.$message('当前暂无合同！')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    getCertificateTaskDetails() {
      //获取详情
      this.$api.getCertificateTaskDetails(this.taskId).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = res.data;
            this.form=JSON.parse(JSON.stringify(res.data.car));
            if(res.data.car){
              if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;//商户表
            }
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            if(res.data.car.customerContract) this.customerContract=res.data.car.customerContract;
            switch(res.data.taskBreakStatus){
              case 71:
                this.state=1;
                break;
              case 72:
                this.state=2;
                break;
              case 73:
                this.state=3;
                break;
            }
            this.certInviteTraceList=res.data.breakRuleTraceList;//违章跟进记录列表
            this.breakRuleList=res.data.breakRuleList;//违章信息列表
            if(this.state==1){
              for(let i=0;i<this.breakRuleList.length;i++){
                let item=this.breakRuleList[i]
                if(item.status==73){
                  item.check=true;
                }
              }
            }
          }
        }
      });
    },
    postBreakruleAdd() {
      //新增违章
      let query = {
        "carId": this.form.id,
        // "supplierId": this.forms.channelId,//供应商ID
        // "supplierName": this.forms.supplierName,//供应商名称
        beneficiaryId:this.forms.beneficiaryId,//受益方Id：1客户，2商户，3车开新，4办证公司，5违章供应商
        "payableAmount": this.forms.payableAmount,//应付金额
        "feeBearerAmount": this.forms.feeBearerAmount,//应收金额
        "feeBearerId": this.forms.feeBearerId,//费用承担方Id：1客户，2商户
        "driveLicenseSubScore": this.forms.driveLicenseSubScore,//驾照扣分
        "breakRuleCounts": this.forms.breakRuleCounts,//违章次数
        "appointDisposeDate": this.forms.appointDisposeDate,//约定处理日期
        beneficiaryAmount:this.forms.beneficiaryAmount,
        benefitCertRuleSupplierId:this.forms.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:this.forms.benefitCertRuleSupplierName,
      }
      this.$api.postBreakruleAdd(query).then(res => {
        if (res) {
          if (res.code == 0) {
            this.ondialogVisible();
            this.forms={}
            this.$nextTick(()=>{
              this.$refs['forms'].clearValidate()
            })
            this.getCertificateTaskDetails();
          }
        }
      });
    },
    postTaskOperaRule(state) {//违章处理操作【费用确认
      let query = {
        taskId: this.taskId,
        taskBreakRuleCheckResult: state,//违章操作类型：费用确认 break_confirm, 继续跟进 break_trace_continue，处理完成 break_check_success
      };
      this.$api.postTaskOperaRule(query).then(res => {
        if (res) {
          if (res.code == 0) {
            this.comfirmDialogVisible=false;
            this.dialogVisible=false;
            this.$message.success(res.message)
            this.getCertificateTaskDetails();
            // this.onrouterPage();
          }
        }
      });
    },
    postBreakRuleTraceAdd(){//新增 违章跟进记录
      let query={
        "certTaskId": this.taskId,
        "nextInviteTime": this.forms.nextInviteTime,
        "description": this.forms.description
      }
      this.$api.postBreakRuleTraceAdd(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.dialogVisible=false;
          this.$message.success(res.message)
          this.getCertificateTaskDetails()
        }
      })
    },
    postBreakruleUpdate(id){//更新 违章处理
      let query = {
        carNo: this.form.carNo, //车牌号
        // supplierId: this.forms.channelId, //供应商ID
        // supplierName: this.forms.supplierName, //供应商名称
        beneficiaryId:this.forms.beneficiaryId,//受益方Id：1客户，2商户，3车开新，4办证公司，5违章供应商
        payableAmount: this.forms.payableAmount, //应付金额
        feeBearerAmount: this.forms.feeBearerAmount, //应收金额
        feeBearerId: this.forms.feeBearerId, //费用承担方Id：1客户，2商户
        driveLicenseSubScore: this.forms.driveLicenseSubScore, //驾照扣分
        breakRuleCounts: this.forms.breakRuleCounts, //违章次数
        appointDisposeDate: this.forms.appointDisposeDate, //约定处理日期
        beneficiaryAmount:this.forms.beneficiaryAmount,
        benefitCertRuleSupplierId:this.forms.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:this.forms.benefitCertRuleSupplierName,
      };
      this.$api.postBreakruleUpdate(id,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.ondialogVisible();
            this.getCertificateTaskDetails()
          }
        }
      })
    },
    postSupplierList(){//供应商列表
      this.$api.postSupplierList({}).then(res=>{
        if(res.code==0){
          for(let i=0;i<this.supplierList.length;i++){
            let item=this.supplierList[i]
            if(['办证公司','违章供应商'].includes(item.name)){
              item.children=res.data;
            }
          }
          // console.log(this.supplierList,'===this.supplierList===')
        }
      })
    },
  }
};
</script>
<style >
.el-textarea__inner {
  height: 100px;
}
</style>
<style lang="scss" scoped>
.certificateBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .form_title_spanInbox {
    display: inline-block;
  }
  .evidenDetail_titleOne {
    // margin-top: 20px;
    .content_title_row {
      // flex: 1;
      margin-right: 100px;
      margin-bottom: 10px;
    }
    .content_title_row:last-child {
      margin-right: 0;
    }
  }
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    .content_title_row {
      // flex: 1;
      label {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
    .content_title_row:nth-child(even) {
      text-align: right;
      span.content_title_rightSpan {
        display: inline-block;
        text-align: left;
        width: 215px;
      }
    }
  }
  .info_content_box {
    display: flex;
    // justify-content: space-between;
  }
  .content_modal_textcontenBox {
    width: 80%;
  }
  .evidenDetail_titleTwo {
    // width: 50%;
    justify-content: space-between;

    .content_title_row {
      // flex: 1;
      width: 50%;
      margin-bottom: 10px;
      .button_primary {
        width: 88px;
        margin-left: 30px;
      }
    }
  }
  .flex_space {
    display: flex;
    justify-content: space-between;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .w100 {
    width: 100%;
  }
  .lineBox{
    min-width:15px;
    border-bottom:1px solid;
    display:inline-block;
  }
}
@media only screen and (max-width: 1700px) {
  .certificateBox .content_title_Box .content_title_row:nth-child(even) {
    text-align: left;
  }
  .certificateBox
    .content_title_Box
    .content_title_row:nth-child(even)
    span.content_title_rightSpan {
    width: auto;
  }
  .certificateBox .evidenDetail_titleTwo .content_title_row {
    clear: both;
  }
  .certificateBox .evidenDetail_titleTwo .content_title_row .button_primary {
    float: right;
  }
}
@media only screen and (max-width: 1500px) {
  .certificateBox .content_modal_textcontenBox {
    width: 95%;
  }
}
@media only screen and (max-width: 1350px) {
  .certificateBox .evidenDetail_titleTwo .content_title_row .button_primary {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 1280px) {
  .certificateBox .evidenDetail_titleTwo .content_title_row .button_primary {
    margin-left: 0px;
    margin-top: 5px;
    float: none;
  }
}
</style>