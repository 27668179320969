<template>
  <!-- 商户预约 -->
  <div class="contenPageBox">
    <el-tabs v-model="activeName" type="card" @tab-click="tabCLick">
      <el-row :gutter="24" class="mt20">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>车牌号</span>
        <el-input v-model="params.licenseNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>4S店简称</span>
        <el-input v-model="params.channelShortName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>订单状态</span>
        <el-select v-model="params.taskStatus" size="medium" clearable>
          <template v-if="activeName==='first'">
            <el-option v-for="(item,k) in statelist" :key="k" :label="item" :value="k"></el-option>
          </template>
          <template v-else>
            <el-option v-for="item in statelist1" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </template>
          
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2"  class="mb10">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
      <el-tab-pane label="成交前" name="first">
        <el-table :data="querylist" border stripe class="mt10 table-mainbg">
          <el-table-column prop="taskId" align="center" label="任务ID" ></el-table-column>
          <el-table-column prop="taskType" align="center" label="任务类型" ></el-table-column>
          <el-table-column prop="vin" label="车架号" align="center"  ></el-table-column>
          <el-table-column prop="brand" align="center" label="品牌" ></el-table-column>
          <el-table-column prop="licenseNo" align="center" label="原车牌号码" ></el-table-column>
          <el-table-column prop="modifiedLicenseNo" align="center" label="修改后的车牌" ></el-table-column>
          <el-table-column prop="reservePrice" align="center" label="保留价" ></el-table-column>
          <el-table-column prop="dealerCode" align="center" label="经销商编号" ></el-table-column>
          <el-table-column prop="channelShortName" align="center" label="4S店简称" ></el-table-column>
          <el-table-column prop="extensionerName" align="center" label="渠道拓展专员" ></el-table-column>
          <el-table-column prop="taskStatusName" align="center" label="订单状态" ></el-table-column>
          <el-table-column prop="auctionReportPushStatus" align="center" label="拍卖结果是否发送" >
            <template slot-scope="scope">{{scope.row.auctionReportPushStatus===0?'发送成功':scope.row.auctionReportPushStatus===1?'发送失败':scope.row.auctionReportPushStatus===2?'未发送':scope.row.auctionReportPushStatus}}</template>
          </el-table-column>
          <el-table-column prop="checkerName" align="center" label="检测师" ></el-table-column>
          <el-table-column prop="transferTime" align="center" label="推送时间" ></el-table-column>
          <el-table-column prop="isSparkUC" align="center" label="订单类型" >
            <template slot-scope="scope">{{scope.row.isSparkUC===0?'宝马推送':scope.row.isSparkUC===1?'车开新自建':scope.row.isSparkUC}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150px" >
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,1)" v-if="[60,70].includes(scope.row.taskStatus)">修订拍卖结果</el-button>
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,0)" v-if="[10].includes(scope.row.certStatus)||[10,20].includes(scope.row.pushStatus)">查看拍卖结果</el-button>
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,5)" v-if="[40,60,70].includes(scope.row.taskStatus)||[10].includes(scope.row.certStatus)||[10,20].includes(scope.row.pushStatus)">查看报告</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          :current-page="page_index"
          :total="page_total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="成交后" name="second">
        <el-table :data="querylist1" border stripe class="mt10 table-mainbg">
          <el-table-column prop="taskId" align="center" label="任务ID" ></el-table-column>
          <el-table-column prop="taskType" align="center" label="任务类型" ></el-table-column>
          <el-table-column prop="vin" label="车架号" align="center"  ></el-table-column>
          <el-table-column prop="brand" align="center" label="品牌" ></el-table-column>
          <el-table-column prop="carNo" align="center" label="原车牌号码" ></el-table-column>
          <el-table-column prop="modifiedLicenseNo" align="center" label="修改后的车牌" ></el-table-column>
          <el-table-column prop="resversePrice" align="center" label="保留价" ></el-table-column>
          <el-table-column prop="dealerCode" align="center" label="经销商编号" ></el-table-column>
          <el-table-column prop="storeName" align="center" label="4S店简称" ></el-table-column>
          <el-table-column prop="extensionerName" align="center" label="渠道拓展专员" ></el-table-column>
          <el-table-column prop="statusName" align="center" label="订单状态" >
            <!-- <template slot-scope="scope">{{scope.row.orderStatus===10?'待传资金凭证':scope.row.orderStatus===20?'已传资金凭证':scope.row.orderStatus}}</template> -->
          </el-table-column>
          <el-table-column prop="checkerName" align="center" label="检测师" ></el-table-column>
          <el-table-column prop="pushTime" align="center" label="推送时间" ></el-table-column>
          <el-table-column prop="isSparkUC" align="center" label="订单类型" >
            <template slot-scope="scope">{{scope.row.isSparkUC===0?'宝马推送':scope.row.isSparkUC===1?'车开新自建':scope.row.isSparkUC}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150px" >
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,1)" v-if="[60,70].includes(scope.row.taskStatus)">修订拍卖结果</el-button>
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,2)" v-if="[10].includes(scope.row.orderStatus)||[10].includes(scope.row.certStatus)||[10,20].includes(scope.row.pushStatus)">查看拍卖结果</el-button>
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,1)" v-if="[10].includes(scope.row.pushStatus)">修订付款信息</el-button>
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,0)" v-if="[20].includes(scope.row.pushStatus)">查看付款信息</el-button>
              <el-button size="mini" type="primary" plain @click="onOperate(scope.row,5)" v-if="[40,60,70].includes(scope.row.taskStatus)||[10].includes(scope.row.orderStatus)||[10].includes(scope.row.certStatus)||[10,20].includes(scope.row.pushStatus)">查看报告</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size1"
          :current-page="page_index1"
          :total="page_total1"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    
    
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        channelShortName: '',
        licenseNo: '',
        taskStatus: '',
      },
      querylist: [],
      querylist1:[],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      itemRow:{},
      statelist:[],
      // statelist:[{id:10,name:'待分配'},{id:20,name:'待检测'},{id:30,name:'已检测'},{id:40,name:'检测失败'},{id:60,name:'未成交'},{id:50,name:'已成交'}],
      statelist1:[{id:70,name:'待上传资金凭证'},{id:80,name:'待上传过户凭证'},{id:90,name:'待同步付款结果'},{id:100,name:'已同步付款结果'}],
      activeName:'first'
    }
  },
  created() {
    this.getBmwCheckTaskStatus()
    this.initData();
  },
  watch:{
    activeName(e){
      this.params.taskStatus='';
    }
  },
  methods: {
    tabCLick(){
      this.params.taskStatus='';
      this.initData(1)
    },
    onOperate(row,state){
      this.itemRow=JSON.parse(JSON.stringify(row))
      switch(state){
        case 5://查看报告
          var aurl='http://third.part.test.kx.cn'
          if(process.env.NODE_ENV==='production'){
            aurl='http://third.part.kx.cn'
          }
          if(this.activeName==='first'){
            if(!row.checkReportId){
              this.$message.warning('当前数据暂无检测报告id！')
              return
            }
          //  checkReportId
            window.open( `${aurl}/internalPage/auditReport?id=${row.checkReportId}`,'_blank')
          }else{
            if(!row.inspectionNo){
              this.$message.warning('当前数据暂无检测报告id！')
              return
            }
            window.open( `${aurl}/internalPage/auditReport?id=${row.inspectionNo}`,'_blank')
          }
          
          break;
        case 2://查看拍卖结果
          if(!row.biddingCode){
            this.$message.warning('当前数据无关联biddingCode！')
            return
          }
          // this.$router.push({
          //   path:'/BMW/BMWPushDetails',
          //   query:{
          //     biddingCode:row.biddingCode,
          //     state:0
          //   }
          // })
          var pathData1=this.$router.resolve({
                path:'/BMW/BMWPushDetails',
                query:{
                  biddingCode:row.biddingCode,
                  state:0
                }
              })
              window.open(pathData1.href,'_blank')
          break;
          default:
            if(this.activeName==='first'){
              if(!row.biddingCode){
                this.$message.warning('当前数据无关联biddingCode！')
                return
              }
              // this.$router.push({
              //   path:'/BMW/BMWPushDetails',
              //   query:{
              //     biddingCode:row.biddingCode,
              //     state:state
              //   }
              // })
              var pathData2=this.$router.resolve({
                    path:'/BMW/BMWPushDetails',
                    query:{
                      biddingCode:row.biddingCode,
                      state:state
                    }
                  })
                  window.open(pathData2.href,'_blank')
            }else{
              // this.$router.push({
              //   path:'/BMW/BMWPushDetails',
              //   query:{
              //     id:row.id,
              //     state:state
              //   }
              // })
              var pathData3=this.$router.resolve({
                    path:'/BMW/BMWPushDetails',
                    query:{
                      id:row.id,
                      state:state
                    }
                  })
              window.open(pathData3.href,'_blank')
            }
      }
    },
    handleSizeChange(row){
      if(this.activeName==='first'){
        this.page_size=row;
        this.page_index=1;
      }else{
        this.page_size1=row;
        this.page_index1=1;
      }
      this.initData()
    },
    handleCurrentChange(row){
      if(this.activeName==='first'){
        this.page_index=row;
      }else{
        this.page_index1=row;
      }
      this.initData()
    },
    search() {
      if(this.activeName==='first'){
        this.page_index = 1;
      }else{
        this.page_index1 = 1;
      }
      this.initData() ;
    },
    initData() {
      let query={}
     if(this.activeName==='first'){
       query.curPage= this.page_index;
       query.pageSize= this.page_size;
       query.taskStatus=this.params.taskStatus;
       query.licenseNo=this.params.licenseNo;
       query.channelShortName=this.params.channelShortName;
       this.postPageTaskInfo(query)
     }else{
       query.carNo=this.params.licenseNo;
       query.storeName=this.params.channelShortName;
       query.curPage= this.page_index1;
       query.pageSize= this.page_size1;
       switch(this.params.taskStatus){
         case 70:
           query.orderStatus=10;
           break;
          case 80:
           query.certStatus=10;
           break;
          case 90:
           query.pushStatus=10;
           break;
          case 100:
           query.pushStatus=20;
           break;
       }
       this.postPaymentVoucherBmwPage(query)
     }
    },
    postPaymentVoucherBmwPage(query){//成交后列表
      this.$api.postPaymentVoucherBmwPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist1 = res.data.records;
            this.page_total1 = res.data.total;
          } else {
            this.querylist1 = [];
            this.page_total1 = 0;
          }
        }else{
          this.querylist1 = [];
          this.page_total1 = 0;
        }
      });
    },
    postPageTaskInfo(query){//成交前列表
      this.$api.postPageTaskInfo(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.page_total = res.data.total;
          } else {
            this.querylist = [];
            this.page_total = 0;
          }
        }
      });
    },
    getBmwCheckTaskStatus(){
      this.$api.getBmwCheckTaskStatus().then(res=>{
        if(res.code==0){
          this.statelist=res.data;
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px;
}
</style>
