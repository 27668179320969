<template>
  <div :class="stateType==0?'receivBoxBG':''">
    <div class="headBox" >
      <h4 class="headTitleTop">物流接货单</h4>
      <div class="headTitleBox" v-if="stateType==0">发货单号：{{form.logistNo}}</div>
    </div>
    <div class="receivBox pb140">
      <!--发货信息-->
      <div class="content_modal_textBox w50" :class="stateType==0?'pt30':''">
        <div class="head_top_title">
          <i></i>
          <span>发货信息</span>
        </div>
        <el-row >
          <el-col :span="12" >
            <label>发货方：</label>
            <span>{{form.fromShopName}}</span>
          </el-col>
          <el-col :span="12" >
            <label>收货方：</label>
            <span>{{form.toShopName}}</span>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="6">发货方式：{{form.logistTypeName}}</el-col>
          <el-col :span="6" v-if="form.fromShopType==43">发货时间：{{form.fromTime}}</el-col>
        </el-row>
        <el-row class="mt10" v-if="form.fromShopType!=43">
          <!--物流方式，11代驾，12车开新，13快递-->
          <template v-if="form.logistType==13">
            <el-col :span="6">快递公司：{{form.expressCompName}}</el-col>
            <el-col :span="6">快递单号：{{form.expressLogistNo}}</el-col>
          </template>
          <template v-if="form.logistType==12">
            <el-col :span="6">员工姓名：{{form.empName}}</el-col>
            <el-col :span="6">手机号：{{form.empMobile}}</el-col>
          </template>
          <template v-if="form.logistType==11">
            <el-col :span="6">代驾姓名：{{form.agencyName}}</el-col>
            <el-col :span="6">代驾手机：{{form.agencyMobile}}</el-col>
          </template>
          
          <el-col :span="6">发货时间：{{form.fromTime}}</el-col>
          <el-col :span="6">预计到达时间：{{form.preArriveTime}}</el-col>
        </el-row>
      </div>
      <!--接货信息-->
      <div class="content_modal_textBox w50" v-if="stateType==1">
        <div class="head_top_title">
          <i></i>
          <span>接货信息</span>
        </div>
        <el-row>
          <el-col :span="12" class="conter_text_row">
            <label>接货人：</label>
            <span>{{form.toName}}</span>
          </el-col>
          <el-col :span="12" class="conter_text_row">
            <label>接收时间：</label>
            <span>{{form.toReceiveTime}}</span>
          </el-col>
        </el-row>
      </div>
      <!--车辆信息- carId有值说明有车-->
      <div class="content_modal_textBox mt10" v-if="carId">
        <div class="head_top_title">
          <i></i>
          <span>车辆信息</span>
        </div>
        <el-table :data="cardIdlist" border class="table-mainbg table_carlist" ref="carTable" @selection-change="handleSelectionChange($event,1)">
          <el-table-column type="selection" width="50" align="center" v-if="stateType==0" :selectable="selectEnable"></el-table-column>
          <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="carColor" label="颜色" align="center"></el-table-column>
          <el-table-column prop="registerDate" label="初次登记时间" align="center"></el-table-column>
          <el-table-column prop="checkExpire" label="年检有效期" align="center"></el-table-column>
          <el-table-column prop="logistStatusName" label="物流状态" align="center"></el-table-column>
          <!-- <el-table-column prop="stockStatusName" label="货位" align="center"></el-table-column> -->
          <el-table-column label="操作" align="center" width="200px" >
            <template slot-scope="scope">
              <p v-if="scope.row.logistStatus==14||scope.row.logistStatus==15">{{scope.row.logistStatusName}}</p>
              <div v-else>
                <el-button
                type="primary"
                @click="onreceive('车辆',scope.row)"
                size="mini"
                class="group-custom"
              >接收入库</el-button>
              <!-- <el-button
                type="primary"
                @click="onabnormal(scope.row)"
                size="mini"
                class="group-custom"
              >异常</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--手续材料信息-->
      <div class="content_modal_textBox mt30">
        <div class="head_top_title">
          <i></i>
          <span>手续材料信息</span>
        </div>
        <el-table :data="carlist" border class="table-mainbg" @selection-change="handleSelectionChange($event,2)">
          <el-table-column type="selection" width="50" align="center" v-if="stateType==0" :selectable="selectEnable" key="1"></el-table-column>
          <el-table-column type="index" label="序号" align="center" width="80" key="2"></el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
          <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
          <el-table-column prop="sendNums" label="数量" align="center"></el-table-column>
          <el-table-column prop="logistStatusName" label="物流状态" align="center"></el-table-column>
          <!-- <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column> -->
          <!-- <el-table-column prop="stockStatusName" label="货位" align="center"></el-table-column> -->
          <el-table-column label="操作" align="center" width="200px" >
            <template slot-scope="scope">
              <p v-if="scope.row.logistStatus==14||scope.row.logistStatus==15">{{scope.row.logistStatusName}}</p>
              <div v-else>
                <el-button
                type="primary"
                @click="onreceive('手续',scope.row)"
                size="mini"
                v-if="scope.row.logistStatus!=14"
                class="group-custom"
              >接收入库</el-button>
              <el-button
                type="primary"
                @click="onabnormal(scope.row)"
                size="mini"
                v-if="scope.row.logistStatus!=14"
                class="group-custom"
              >异常</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--留存照片-->
      <!-- <div class="content_modal_textBox mt20" :class="stateType==0?'pb100':''">
        <div class="head_top_title">
          <i></i>
          <span>留存照片</span>
        </div>
        <div class="photo_content_box">
          <div class="photobox_row" v-for="item in imgList" :key="item.id">
             <el-image 
              :src="item.fullFilePath" 
              :preview-src-list="[item.fullFilePath]">
               <div slot="error">暂无图片</div>
            </el-image>
          </div>
          <div v-if="imgList.length==0">暂无图片</div>
        </div>
      </div> -->

      <div class="footer" v-if="stateType==0">
        <div class="btn">
          <el-button type="primary" @click="onsave">批量入库</el-button>
        </div>
      </div>

      <el-dialog
        :title="visTilte=='手续'?'请确认收货清单':visTilte=='车辆'?'请选择入库货位':'一键接收入库'"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="700px"
      >
        <el-form
          ref="modalform"
          :model="modalform"
          :rules="rules"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <div v-if="visTilte=='车辆'||visTilte=='一键入库'">
            <p class="visHeadTop">{{visTilte=='车辆'?'车辆入库':'车辆信息'}}</p>

            <el-form-item label="仓库" prop="stockObjIn">
              <el-select v-model="modalform.stockObjIn" placeholder="请选择仓库" size="medium" class="w200 mr20" value-key="id" @change="selectChange($event,1,'car')" @visible-change="handleChangeFlag($event,1)">
                <el-option v-for="item in stockObjList" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable==1?true:false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货区" >
              <el-select v-model="modalform.stockObjArea" placeholder="请选择货区" size="medium" class="w200 mr20" value-key="id" @change="selectChange($event,2,'car')" @visible-change="handleChangeFlag($event,1)">
                <el-option v-for="item in goodsArealist" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable==1?true:false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货位" >
              <el-select v-model="modalform.stockObjLocationId" placeholder="请选择货位" size="medium" class="w200">
                <el-option v-for="item in placelist" :key="item.id" :label="item.name" :value="item.id" :disabled="item.isEnable==1?true:false">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 12px">{{ item.isFree===1?'已占用':'' }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="visTilte=='手续'||visTilte=='一键入库'">
            <p class="visHeadTop">手续入库</p>

            <el-form-item label="仓库" prop="stockObjIn1">
              <el-select v-model="modalform.stockObjIn1" placeholder="请选择仓库" size="medium" class="w200 mr20" value-key="id" @change="selectChange($event,1,'pro')" @visible-change="handleChangeFlag($event,2)">
                <el-option v-for="item in stockObjList" :key="item.id" :label="item.name" :value="item"  :disabled="item.isEnable==1?true:false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货区" >
              <el-select v-model="modalform.stockObjArea1" placeholder="请选择货区" size="medium" class="w200 mr20" value-key="id" @change="selectChange($event,2,'pro')" @visible-change="handleChangeFlag($event,2)">
                <el-option v-for="item in goodsArealist" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable==1?true:false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货位" >
              <el-select v-model="modalform.stockObjLocationId1" placeholder="请选择货位" size="medium" class="w200">
                <el-option v-for="item in placelist" :key="item.id" :label="item.name" :value="item.id" :disabled="item.isEnable==1?true:false">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 12px">{{ item.isFree===1?'已占用':'' }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmitFollow('modalform')">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0,
      cardIdlist: [], 
      carlist:[],
      form: {},
      modalform:{
        stockObjIn: '',
        stockObjArea: '',
        stockObjLocationId: '',
        stockObjIn1: '',
        stockObjArea1: '',
        stockObjLocationId1: '',
      },
      dialogVisible: false,
      visTilte: "车辆",
      rules: {
        stockObjIn: [{ required: true, message: "仓库不能为空" , trigger: 'change' }],
        stockObjArea: [{ required: true, message: "货区不能为空" , trigger: 'change' }],
        stockObjLocationId: [{ required: true, message: "货位不能为空" , trigger: 'change' }],
        stockObjIn1: [{ required: true, message: "仓库不能为空" , trigger: 'change' }],
        stockObjArea1: [{ required: true, message: "货区不能为空", trigger: 'change'  }],
        stockObjLocationId1: [{ required: true, message: "货位不能为空" , trigger: 'change' }],
      },
      currentRow:[],
      logistId:'',
      imgList:[],
      stockObjList:[],//仓库
      goodsArealist:[],//货区
      placelist:[],//货位
      changeFlag:false,//是否切换了下拉(三级联动处理)
      changeFlag1:false,
      itemRow:{},//选择当前的数据
      currentRowCar:[],
      carId:'',
    };
  },
  watch:{
    'modalform.stockObjIn'(){
      if( this.changeFlag ){
        this.modalform.stockObjArea=null;
      }
    },
    'modalform.stockObjArea'(){
      if( this.changeFlag ){
        this.modalform.stockObjLocationId=null;
      }
    },
    'modalform.stockObjIn1'(){
      if( this.changeFlag1 ){
        this.modalform.stockObjArea1=null;
      }
    },
    'modalform.stockObjArea1'(){
      if( this.changeFlag1 ){
        this.modalform.stockObjLocationId1=null;
      }
    },
  },
  created() {
    this.logistId=this.$route.query.id;
    // if(this.$route.query.carId){
    //   this.carId = this.$route.query.carId;
    // }
    this.getLogisticsDetail()
    this.getStockObjList()//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
  },
  methods: {
    selectEnable(row) {
        if (row.logistStatus!=14&&row.logistStatus!=15) {
          return true
        }
     },
    getLogisticsDetail(){//获取详情
      let query={
        "businessId": this.carId,
        "businessType": "logistics",//办证 cert_gh, 客户合同 customer_contact, 商户合同 merchant_contact, 物流/库存 logistics, 车辆/客户材料 materials
        // "businessName": "留存照片"
      }
      this.cardIdlist=[];
      if(!this.carId){
        query=null
      }
      this.$api.getLogisticsDetail(this.logistId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            //物流状态：11待发货, 12在途, 13待收货，14已接收，15异常，默认11
            if(res.data.logistStatus===14||res.data.logistStatus===15){
              this.stateType=1;
            }else{
              this.stateType=0;
            }
            
            let car=res.data.logistCarInfo?res.data.logistCarInfo:{};

            this.carId=car.id;
            let obj={
              id:car.id,
              carNo:car.carNo,
              brandName:car.brandName,//车辆品牌名称
              modelName:car.modelName,//
              carColor:car.carColor,
              registerDate:car.registerDate,
              checkExpire:car.checkExpire,
              logistStatusName:car.logistStatusName,
              logistStatus:car.logistStatus,
              sendNums:1,
            }
            this.cardIdlist.push(obj);
            if(!res.data.logistMaterialsList){
              this.carlist=[];//车辆材料列表
            }else{
              this.carlist=res.data.logistMaterialsList;//车辆材料列表
            }
            this.imgList=res.data.imgList?res.data.imgList:[];//留存照片
          }
        }
      })
    },
    handleChangeFlag($event,state){
      switch(state){
        case 1:
          this.changeFlag = $event;
          break;
        case 2:
          this.changeFlag1 = $event;
          break;
      }
    },
    selectChange($event,state,type){
      switch(state){
        case 1:
          this.goodsArealist=$event.stockObjAreaList;
          this.placelist=[];
          if(type=='car'){
            this.modalform.stockObjInId=$event.id;
          }else{
            this.modalform.stockObjInId1=$event.id;
          }
          break;
        case 2:
          this.placelist=$event.stockObjLocationList;
          if(type=='car'){
            this.modalform.stockObjAreaId=$event.id;
          }else{
            this.modalform.stockObjAreaId1=$event.id;
          }
          break;
      }
    },
    onreceive(type,row) {
      //接收入库
      this.visTilte = type;
      this.ondialogVisible();
      this.$nextTick(() => {
        this.$refs["modalform"].resetFields();
        this.$refs["modalform"].clearValidate();
      });
      this.itemRow=row;
    },
    onabnormal(row) {
      this.$confirm('你确认要将此手续操作为异常手续', '异常提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(row.materialsId){
            this.postLogistHandling([{materialsId:row.materialsId}],'','exception')//异常
          }else{
            this.postLogistHandling('',this.form.carId,'exception')//异常
          }
          
        })
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitFollow(formdata) {
      this.$refs[formdata].validate(valid => {
        if (valid) {
          let materialsIds=[]
          let handlerType='receive';
          let carId='';
          switch(this.visTilte){
            case '车辆':
              carId=this.form.carId;
              break;
            case '手续':
              materialsIds.push({materialsId:this.itemRow.materialsId,sendNums:this.itemRow.sendNums})
              break;
            case '一键入库':
              for(let i=0;i<this.currentRow.length;i++){
                materialsIds.push({materialsId:this.currentRow[i].materialsId,sendNums:this.currentRow[i].sendNums})
              }
              if(this.currentRowCar.length>0){
                carId=this.form.carId;
              }
              handlerType='receive';
              break;
          }
          this.postLogistHandling(materialsIds,carId,handlerType);
        } else {
          return false;
        }
      });
    },
    onsave() {
      this.visTilte = "一键入库";
      let carId='';
       if(this.currentRow.length==0&&this.currentRowCar.length==0){
         this.$message('最少选择一项材料入库！')
         return
       }
       let materialsIds=[]
       let handlerType='receive';
        switch(this.visTilte){
          case '车辆':
            carId=this.form.carId;
            break;
          case '手续':
            materialsIds.push({materialsId:this.itemRow.materialsId,sendNums:this.itemRow.sendNums})
            break;
          case '一键入库':
            for(let i=0;i<this.currentRow.length;i++){
              materialsIds.push({materialsId:this.currentRow[i].materialsId,sendNums:this.currentRow[i].sendNums})
            }
            if(this.currentRowCar.length>0){
              carId=this.form.carId;
            }
            break;
        }
        this.$confirm('请确认是否批量入库！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postLogistHandling(materialsIds,carId,handlerType);
        }).catch(() => {          
        });
        
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/accreditationCommissioner/certificateManagement"
      });
    },
    handleSelectionChange(selection,state){
      if(state==1){
        this.currentRowCar=selection;
        if(selection.length>1){
          this.$refs.carTable.clearSelection();
          this.$refs.carTable.toggleRowSelection(selection.pop());
        }
      }else{
        this.currentRow=selection;
      }
      
    },
    postLogistHandling(materialsIds,carId,handlerType){//出库，出库并发货（出库对象为交付中心，出库类型调拨出库），入库，一键出库 等处理；
      let query={
        "logistId": this.logistId,
        "handlerType": handlerType,//处理类型：单收single_receive，一键收货once_receive，异常exception；carId和materialsIds不能全空materialsIds不能全空
        "carId": carId,
        "materialsFormList": materialsIds,
        
      }
      if(handlerType!='exception'){
        query.carStockObjInId= this.modalform.stockObjInId;//车辆入库仓库ID
        // "carStockObjOutId": 1,//车辆出库仓库ID
        query.carStockObjAreaId= this.modalform.stockObjAreaId;//车辆入库仓库货区ID
        query.carStockObjLocationId= this.modalform.stockObjLocationId;//车辆入库仓库货位ID
        query.materialsStockObjInId= this.modalform.stockObjInId1;//材料入库仓库ID
        query.materialsStockObjOutId= this.modalform.stockObjLocationId1;//材料出库仓库ID
        query.materialsStockObjAreaId= this.modalform.stockObjAreaId1;//材料入库仓库货区ID
        query.materialsStockObjLocationId= this.modalform.stockObjLocationId1;//材料入库仓库货位ID
      }
      this.$api.postLogistHandling(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.dialogVisible=false;
             this.getStockObjList()//重新查询库位
            this.getLogisticsDetail()
          }
        }
      })
    },
    getStockObjList(){//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
      this.$api.getStockObjList({}).then((res)=>{
        if(res){
          if(res.code==0){
            this.stockObjList=res.data;
          }
        }
      })
    },
  }
};
</script>
<style >
/* .el-textarea__inner{
    height: 100px;
} */
.table_carlist thead th .el-checkbox{
    display: none;
  }
</style>
<style lang="scss" scoped>
.receivBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.headBox {
  background: #ffffff;
  .headTitleTop {
    text-align: center;
    font-size: 24px;
    padding: 30px;
  }
  .headTitleBox {
    height: 40px;
    background: #ededed;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
}
.receivBox {
  background-color: #ffffff;
  padding: 0 40px 30px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .conter_text_row {
    margin-bottom: 15px;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .w90 {
    width: 90%;
  }
  .w100 {
    width: 100%;
  }
  .w200 {
    width: 150px;
  }
  .pt30{
    padding-top: 30px;
  }
  .formRadiogrop_row {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 334px;
      margin-right: 32px;
      height: 195px;
      background: #f5f5f5;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        max-height: 100%;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
  .visHeadTop {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .receivBox .w50 {
    width: 100%;
  }
}
</style>