<template>
  <div class="examineBox">
    <!--基本信息-->
    <div class="ckxTitle mb10">基本账户信息</div>
    <table class="table-oddwholebg table-custom">
      <tbody>
        <tr>
          <td>是否提供发票</td>
          <td>{{res.needBill===1?'提供':res.needBill===0?'不提供':res.needBill}}</td>
          <td>钱包流水</td>
          <td>
            <el-button type="primary" @click="onsee()" size="mini" plain>查看钱包流水</el-button>
          </td>
        </tr>
        <tr>
          <td>佣金类型</td>
          <td>{{res.commission.commissionType===0?'固定值':res.commission.commissionType===1?'补贴车价':res.commission.commissionType===2?'按车价比例':res.commission.commissionType===3?'按成交量':res.commission.commissionType}}</td>
          <td>佣金</td>
          <td>
            <span v-if="[0].includes(res.commission.commissionType)">固定值:{{res.commission.fixedAmount}}</span>
            <span v-if="[1].includes(res.commission.commissionType)">佣金比例:{{res.commission.subsidyRate*100}}%&nbsp;最小值:{{res.commission.minAmount}}&nbsp;最大值:{{res.commission.maxAmount}}</span>
            <span v-if="[2].includes(res.commission.commissionType)">佣金比例:{{res.commission.priceRate*100}}%&nbsp;最小值:{{res.commission.minAmount}}&nbsp;最大值:{{res.commission.maxAmount}}</span>
            <template v-if="[3].includes(res.commission.commissionType)">
              <span>成交量大于等于:{{res.commission.volBigTradeNum}}&nbsp;按:{{res.commission.volBigTradePrice}}</span>
              <span>成交量小于等于:{{res.commission.volSmallTradeNum}}&nbsp;按:{{res.commission.volSmallTradePrice}}</span>
            </template>
          </td>
        </tr>
          <tr>
          <td>总余额</td>
          <td>{{form.wallet.balance}}元</td>
          <td>可提现余额</td>
          <td>{{form.wallet.availableAmount}}元</td>
        </tr>
      </tbody>
    </table>

    <el-form ref="form" :model="form" :rules="rules" label-width="135px" :hide-required-asterisk="true">
      <!--账户信息-->
      <div class="ckxTitle mt10 mb10">对公账户信息</div>
      <el-row>
        <el-col :span="6">
          <el-form-item label="对公账号户名:">
            <el-input v-model="obj.accountName1" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="对公账号:">
            <el-input v-model="obj.account1" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="银行简称:">
            <el-select
              v-model="obj.depositBankShortName1"
              filterable :disabled="disabled"  size="medium" 
              placeholder="请输入关键词" >
              <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开户行:" class="bankULBoxConter">
            <el-input v-model="obj.depositBank1" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="ckxTitle mt10 mb10">对私账户信息</div>
      <el-row>
        <el-col :span="6">
          <el-form-item label="对私账号户名:">
            <el-input v-model="obj.accountName2" size="medium" :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="对私主账号:">
            <el-input v-model="obj.account2" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="银行简称:">
            <!-- <el-input v-model="obj.depositBankShortName2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input> -->
            <el-select
              v-model="obj.depositBankShortName2"
              filterable :disabled="disabled"  size="medium" 
              placeholder="请输入关键词" >
              <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开户行:" class="bankULBoxConter">
            <el-input v-model="obj.depositBank2" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="ckxTitle mt10 mb10">对私副账户信息</div>
      <el-row>
        <el-col :span="6">
          <el-form-item label="对私副账号户名:">
            <el-input v-model="obj.accountName3" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="对私副账号:">
            <el-input v-model="obj.account3" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="银行简称:">
            <el-select
              v-model="obj.depositBankShortName3"
              filterable :disabled="disabled"  size="medium" 
              placeholder="请输入关键词">
              <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开户行:" class="bankULBoxConter">
            <el-input v-model="obj.depositBank3" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="flex flex_between flex_wrap">
        <div class="ckxTitle mb10">临时账户信息<span class="tipsColor">（一天有效期/24小时）</span><span class="ml30">临时账号有效期截至时间为：<span class="tipsColor">{{obj.expireTime4?obj.expireTime4:'-'}}&nbsp;{{obj.enableStatus4===0?'已禁用':''}}</span></span></div>
        <div class="flex flex_align_center mb10">
          <el-radio-group v-model="form.enableStatus4" @input="forceUpdate">
            <el-radio :label="1">生效临时账号</el-radio>
            <el-radio :label="0">禁用临时账号</el-radio>
          </el-radio-group>
          <el-button type="primary" @click="onUpdate()" size="mini" plain class="ml20" :disabled="obj.accountName4&&obj.account4&&obj.depositBankShortName4&&obj.depositBank4?false:true">更新</el-button>
        </div>
      </div>
      <el-row>
        <el-col :span="6">
          <el-form-item label="临时账号户名:">
            <el-input v-model="obj.accountName4" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="临时账号:">
            <el-input v-model="obj.account4" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="银行简称:">
            <el-select
              v-model="obj.depositBankShortName4"
              filterable :disabled="disabled"  size="medium" 
              placeholder="请输入关键词" >
              <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开户行:" class="bankULBoxConter">
            <el-input v-model="obj.depositBank4" size="medium" :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="ckxTitle mt10 mb10">审核备注信息</div>
      <el-form-item label="审核备注:" >
        <el-input type="textarea" v-model="form.accountAuditRemark" size="medium"></el-input>
      </el-form-item>

      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onSubmitform('form')" v-if="!res.accountAuditStatus">审核通过</el-button>
          <el-button type="primary" @click="onroute()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import BigNumber from 'bignumber.js'
export default {
  data() {
    return {
      stateType: '',//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
      activeName: "first",
      materialList: [],//材料
      checkList:[],
      form: {
        accountAuditRemark:'',
        enableStatus4:'',
        enableStatus:'',
        wallet:{}
      },
      dialogVisible: false,
      rules: {
        accountAuditRemark: [{ required: true, message: "审核备注不能为空" }],
        expiredTime:[{ required: true, message: "不能为空" }],
      },
      addresslist:[],
      areaArr:[],//选择的地区
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      walletId: "", //钱包ID
      id:'',//列表id
      obj: {
        enableStatus4:'',
      }, //佣金
      cobj:{},//联系人
      disabled:true,//是否可编辑
      querylist:[],
      bankVis:false,//是否展示银行信息的搜索内容
      bankVis2:false,
      bankVis3:false,
      banklistSear:[],//搜索的银行数据
      banklist:[],
      res:{
        commission:{},
      },
    };
  },
  created() {
    // if(this.$route.query.state) this.stateType = this.$route.query.state;
    if(this.$route.query.id) this.id=this.$route.query.id;
    this.getByChannelIdDetail(); //获取详情
    
  },
  methods: {
    forceUpdate(){
      this.$forceUpdate()
    },
    onUpdate(){
      let query={
        channelId:this.id,
        operaTempAccountType:this.form.enableStatus4,
      }
      this.$api.postOperaTempAccount(query).then(res=>{
        if(res.code===0){
          this.getByChannelIdDetail(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    onSubmitform() {
      let query={
        channelId:this.id,
        accountAuditRemark:this.form.accountAuditRemark,
        accountAuditStatusList:[1]
      }
      this.$api.postAccountAuditSuccess(query).then(res=>{
        if(res.code===0){
          this.getByChannelIdDetail(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    onroute() {
      this.bus.$emit("closeSelected");
      this.$router.push({
        path: '/finance/accountReview'
      });
    },
    onsee() {//钱包流水
      this.$router.push({
        // path: "/channel/streamDetail"
        name:'streamDetail',
        params:{
          shortName:this.res.shortName
        }
      });
    },
    getByChannelIdDetail() {
      this.checkList=[];//当前选择的值
      //详情
      this.$api.getByChannelIdDetail(this.id).then(res => {
        if (res.code == 0) {
          this.res = JSON.parse(JSON.stringify(res.data));
          this.form = JSON.parse(JSON.stringify(res.data));
          // this.form.accountAuditRemark=this.form.accountAuditRemark?this.form.accountAuditRemark:'';
         //数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
          this.stateType=res.data.status;
         
          
          //钱包
          this.form.wallet = res.data.wallet?res.data.wallet:{}
          //区域
          this.areaArr=[res.data.provinceId,res.data.cityId,res.data.areaId]
          // 	跟进任务
          this.materialList=res.data.materialList;
          for(let i=0;i<res.data.materialList.length;i++){
            let item=res.data.materialList[i]
            if(item.relation){
              this.checkList.push(item.id)
            }
          }
          //佣金
          this.obj=res.data.commission?res.data.commission:{};
          
          if(this.obj.subsidyRate){
            let x= new BigNumber(this.obj.subsidyRate);
            this.obj.subsidyRate=Number(x.times(100))
          }
          if(this.obj.priceRate){
            const xx= new BigNumber(this.obj.priceRate);
            this.obj.priceRate=Number(xx.times(100))
          }
          //账户
          for (let i = 0; res.data.accountList.length > i; i++) {
            let oldArr = res.data.accountList;
            if (oldArr[i].accountType == 0) {
              this.obj["account1"] = oldArr[i].account;
              this.obj["accountName1"] = oldArr[i].accountName;
              this.obj["depositBank1"] = oldArr[i].bank;
              this.obj["depositBankShortName1"] =
                oldArr[i].bankShortName;
            } else if (oldArr[i].accountType == 1) {
              this.obj["account2"] = oldArr[i].account;
              this.obj["accountName2"] = oldArr[i].accountName;
              this.obj["depositBank2"] = oldArr[i].bank;
              this.obj["depositBankShortName2"] =
                oldArr[i].bankShortName;
            } else if (oldArr[i].accountType == 2) {
              this.obj["account3"] = oldArr[i].account;
              this.obj["accountName3"] = oldArr[i].accountName;
              this.obj["depositBank3"] = oldArr[i].bank;
              this.obj["depositBankShortName3"] =
                oldArr[i].bankShortName;
            }else if (oldArr[i].accountType == 3) {
              this.obj["account4"] = oldArr[i].account;
              this.obj["accountName4"] = oldArr[i].accountName;
              this.obj["depositBank4"] = oldArr[i].bank;
              this.obj["depositBankShortName4"] =
                oldArr[i].bankShortName;
              this.obj["expireTime4"] =
                oldArr[i].expireTime;
              this.obj["enableStatus4"] =
                oldArr[i].enableStatus;
              this.form.enableStatus4 =
                oldArr[i].enableStatus;
            }
          }
          //联系人
          for (let i = 0; res.data.contactList.length > i; i++) {
            let oldArr = res.data.contactList;
            if (oldArr[i].type == 0) {
              this.cobj["name"] = oldArr[i].name;
              this.cobj["job"] = oldArr[i].job;
              this.cobj["mobile"] = oldArr[i].mobile;
            } else if (oldArr[i].type == 1) {
              this.cobj["name1"] = oldArr[i].name;
              this.cobj["job1"] = oldArr[i].job;
              this.cobj["mobile1"] = oldArr[i].mobile;
            } else if (oldArr[i].type == 2) {
              this.cobj["name2"] = oldArr[i].name;
              this.cobj["job2"] = oldArr[i].job;
              this.cobj["mobile2"] = oldArr[i].mobile;
            }
          }
          this.$nextTick(()=>{
            this.$refs['form'].clearValidate()
          })
        }
      });
    },
    updateChannelStatus(state) {//修改渠道状态
      let auditRemark=this.form.auditRemark;
      if(state===0){
        auditRemark='';
      }
      this.$api.updateChannelStatus(this.id,state,auditRemark).then(res => {
        if (res.code == 0) {
          if(state===0){
            this.$router.push({
              path:'/channel/examineDetails',
              query:{
                id:this.id
              }
            })
            this.getByChannelIdDetail(); //获取详情
          }else{
            this.onroute(state);
          }
          this.$message.success(res.message)
        }
      });
    },
  }
};
</script>
<style >
.el-textarea__inner {
  height: 100px;
}
#app .customName-tab > .el-tabs__header .el-tabs__item {
  min-width: 120px;
}
.custom-group .el-input-group__append {
  width: 50px;
  padding: 0;
  text-align: center;
}
#app .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-left: 0;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
<style lang="scss" scoped>
.examineBox {
  background-color: #ffffff;
  padding: 30px 50px 140px;
  .head_top_title {
    display: flex;
    align-items: center;
    // padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .customName-tab {
    // width: 485px;
    .custom-border-tab {
      border: 1px solid #cccccc;
      padding: 20px 20px 10px;
      margin: 20px 0;
    }
  }
  .tabBox{
    margin:20px 0 30px;
    width:500px
  }
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:5;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
}
</style>