<template>
  <div class="contractorBoxBG">
    <div class="pageContainer">
      <!--基本信息-->
      <el-row>
        <el-col :span="18">
          <div class="head_top_title justifySpace">
            <div class="alignCenter">
              <i></i>
              <span>商户基本信息</span>
            </div>
            <span class="seeColor" @click="onRouterMerchant">合作协议>></span>
          </div>
          <el-form
            ref="form0" :validate-on-rule-change="false"
            :model="form"
            label-width="110px"
            :rules="rules0"
            :hide-required-asterisk="true"
            class="formCustomRow"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="商户ID：">
                  <p>{{form.id}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账号：">
                  <p>{{form.merchantNo}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="商户类别：">
                  <p>{{form.merchantType?'企业':'个人'}}</p><!--0 个人 1公司-->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册城市：">
                  <p v-if="stateType==2||stateType==3">{{form.registerCityName}}</p>
                   <!-- <el-cascader
                    v-model="addressArr3" ref="address3"
                    :options="optionsAddress" :props="propsAddressID" @change="changeAddress(3)"  clearable ></el-cascader> -->
                    <el-select v-model="form.registerCityId" filterable clearable placeholder="请选择" v-if="stateType==0||stateType==1">
                      <el-option v-for="item in cityListSelect" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="保证金：" :class="stateType==0||stateType==2?'w100':''">
                  <p>{{form.margin}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结算状态：" v-if="stateType==1||stateType==3">
                  <p>{{
                    form.settlementStatus?'异常':'正常'}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话1：">
                  <p>{{form.merchantMobile}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话2：" >
                  <span v-if="stateType==2||stateType==3">{{form.linkPhone2}}</span>
                  <el-input
                    type="text" class="w200"
                    v-model="form.merchantOtherMobile"
                    size="medium" @input="forceUpdate"
                    v-if="stateType==0||stateType==1"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <!--业务信息-->
      <el-row class="mt30">
        <el-col :span="18">
          <div class="head_top_title">
            <div class="alignCenter">
              <i></i>
              <span>业务信息</span>
            </div>
            <span class="ml20">{{res.signingState===1&&res.isAuction===1?'(已签约-可竞拍)':res.signingState===1&&res.isAuction===0?'(已签约-不可竞拍)':res.signingState===2?'(已解约)':''}}</span>
          </div>
          <el-form
            ref="form1"
            :model="form"
            label-width="110px"
            :rules="rules1"
            :inline="true"
            :hide-required-asterisk="true"
            class="formCustomRow"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="拓展业务员：">
                  <p>{{form.extensionerName}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- <el-form-item label="办证公司：" prop="certCompanyName" v-if="stateType==1||stateType==3">
                  <p v-if="stateType==3">{{form.certCompanyName}}</p>
                  <el-select
                    v-model="form.certCompanyName"
                    placeholder="请选择办证公司"
                    size="medium"
                    v-if="stateType==1" @input="forceUpdate"
                    @change="changCompany"
                  >
                    <el-option v-for="(item,index) in paraList" :key="index" :label="item.placeName" :value="item.placeNo"></el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="签约日期：" v-if="stateType==0||stateType==2">
                  <p>{{form.signingDate}}</p>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="维护业务员："
                  :class="stateType==0||stateType==1?'mt10':''"
                  prop="maintainerName"
                >
                      <p v-if="stateType==2||stateType==3">{{form.maintainerName}}</p>
                      <el-select
                        v-model="form.maintainerNameObj"
                        placeholder="请选择业务员"
                        size="medium" @input="forceUpdate"
                        v-if="stateType==0||stateType==1"
                        @change="changMaintain($event,0)"
                        value-key="id"
                      >
                        <el-option v-for="(item,index) in employlist" :key="index" :label="item.name" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="结算状态：" v-if="stateType==0||stateType==2">
                  <p>{{
                    form.settlementStatus?'异常':'正常'}}</p>
                </el-form-item>
                <el-form-item
                  label="售后服务员："
                  prop="afterName"
                  v-if="stateType==1||stateType==3"
                  :class="stateType==1?'mt10':''"
                >
                  <p v-if="stateType==3">{{form.afterName}}</p>
                  <el-select
                    v-model="form.afterNameObj"
                    placeholder="请选择售后服务员"
                    size="medium" @input="forceUpdate"
                    v-if="stateType==1" @change="changMaintain($event,1)"
                  >
                    <el-option v-for="(item,index) in employlist" :key="index" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="stateType==0||stateType==1?'经营地址1：':'经营地址：'"
                  :class="stateType==0||stateType==1?'mt15':''" required  style="display:flex">
                      <p v-if="stateType==2||stateType==3">{{form.businessProvinceName}}{{form.businessCityName}}{{form.businessAddress}}</p>
                      <div v-if="stateType==0||stateType==1">
                        <el-form-item >
                          <el-cascader
                            v-model="addressArr" ref="address"
                            :options="optionsAddress" :props="propsAddress" @change="changeAddress(1)"  clearable class="w100"></el-cascader>
                        </el-form-item>
                      </div>
                      <el-form-item prop="businessAddress" class="w100 mt10" v-if="stateType==0||stateType==1">
                        <el-input v-model="form.businessAddress" @input="forceUpdate" size="medium" class="w100 waddrss"></el-input>
                      </el-form-item>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                  :label="stateType==0||stateType==1?'交易市场1：':'交易市场：'"
                  prop="market1"
                  :class="stateType==0||stateType==1?'mt10':''"
                >
                  <p v-if="stateType==2||stateType==3">{{form.tradingMarketName}}</p>
                  <el-select
                    v-model="form.tradingMarketName"
                    placeholder="请选择交易市场"
                    size="medium" @input="forceUpdate"
                    v-if="stateType==0||stateType==1" @change="changMark($event,1)"
                  >
                    <el-option v-for="(item,index) in marketlist" :key="index" :label="item.placeName" :value="item.placeNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="经营地址2：" v-if="stateType==0||stateType==1"
                  :class="stateType==0||stateType==1?'mt20':''" required style="display:flex">
                  <p v-if="stateType==2||stateType==3">{{form.spareBusinessProvinceName}}{{form.spareBusinessCityName}}{{form.spareBusinessAddress}}</p>
                  <div v-if="stateType==0||stateType==1">
                    <el-form-item >
                      <el-cascader
                        v-model="addressArr1" ref="address1"
                        :options="optionsAddress" :props="propsAddress" @change="changeAddress(2)"  clearable class="w100"></el-cascader>
                    </el-form-item>
                  </div>
                  <el-form-item prop="spareBusinessAddress" class="w100 mt10" v-if="stateType==0||stateType==1">
                    <el-input v-model="form.spareBusinessAddress" @input="forceUpdate" size="medium" class="w100 waddrss"></el-input>
                  </el-form-item>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="交易市场2："
                  prop="placeName2"
                  v-if="stateType==0||stateType==1"
                  :class="stateType==0?'mt20':''"
                >
                  <el-select v-model="form.placeName2" placeholder="请选择交易市场" size="medium" @input="forceUpdate" @change="changMark($event,2)">
                    <el-option v-for="(item,index) in marketlist" :key="index" :label="item.placeName" :value="item.placeNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="签约日期：" v-if="stateType==1||stateType==3" class="w100">
                  <p>{{form.signingDate}}</p>
                </el-form-item>
              </el-col>
            </el-row>
              <el-row>
              <el-col :span="12">
                <el-form-item label="解约日期：">
                  <p>{{form.cancelDate}}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="解约原因：">
                  <p>{{form.cancelReason}}</p>
                </el-form-item>
              </el-col>
            </el-row>
              <!-- <el-row>
              <el-col :span="24">
                <el-form-item label="竞拍城市：" prop="onCityName" :class="stateType==0?'mt10':''" class="checkgroupBox">
                  <span v-if="stateType==2||stateType==3">{{String(onCityName)}}</span>
                  <el-checkbox-group v-model="onCityName" v-if="stateType==0||stateType==1" @input="forceUpdate" >
                    <el-checkbox v-for="(item,index) in biddingCitylist" :key="index" :label="item.areaName" :checked="item.checked">{{item.areaName}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row> -->
          </el-form>
        </el-col>
      </el-row>
      <!--银行信息-->
      <el-row class="mt20">
        <el-col :span="18">
          <div class="head_top_title">
            <i></i>
            <span>银行信息</span>
          </div>
          <el-form
            ref="form2"
            :model="form"
            label-width="110px"
            :rules="rules2"
            :inline="true"
            :hide-required-asterisk="true"
            class="formCustomRow"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="银行开户名："
                  :class="stateType==0||stateType==1?'mt10':''"
                >
                  <p v-if="stateType==2||stateType==3">{{form.accountName}}</p>
                  <el-input
                    type="text"
                    v-model="form.accountName"
                    size="medium" @input="forceUpdate"
                    v-if="stateType==0||stateType==1"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="开户行简称："
                  :class="stateType==0||stateType==1?'mt10':''"
                >
                  <p v-if="stateType==2||stateType==3">{{form.boshBankName}}</p>
                  <el-select v-model="form.boshBankName" placeholder="请输入选择" filterable clearable @input="forceUpdate" v-if="stateType==0||stateType==1">
                    <el-option
                      v-for="item in banklist"
                      :key="item.bankCode"
                      :label="item.bankName"
                      :value="item.bankName">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="银行账号："
                  :class="stateType==0||stateType==1?'mt10':''"
                >
                  <p v-if="stateType==2||stateType==3">{{form.bankAccount}}</p>
                  <el-input
                    type="text"
                    v-model="form.bankAccount"
                    size="medium" @input="forceUpdate"
                    v-if="stateType==0||stateType==1"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="开户行支行：" class="bankULBoxConter"
                  :class="stateType==0||stateType==1?'mt10':''"
                >
                  <p v-if="stateType==2||stateType==3">{{form.bankName}}</p>
                  <template v-else>
                    <el-form-item >
                      <el-cascader
                        v-model="addressArr2" ref="address2"
                        :options="optionsAddress" :props="propsAddress"  clearable class="w100"></el-cascader>
                    </el-form-item>
                    <div class="waddrss">
                      <el-input v-model.trim="form.bankName"  @keydown.enter.stop.native="onInputBank" placeholder="请输入开户行银行名称" class="mt10"></el-input>
                      <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
                        <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item)">{{item.bankName}}</li>
                      </ul>
                    </div>
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <!--认证信息-->
      <el-row class="mt30" :class="stateType==0||stateType==1?'pb100':''">
        <el-col :span="24">
          <div class="head_top_title">
            <i></i>
            <span>认证信息</span>
          </div>
            <el-form
              ref="form"
              :model="form"
              :inline="true"
              :hide-required-asterisk="true"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="商户姓名:">
                    <p>{{form.merchantName}}</p>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="6">
                  <el-form-item label="经营地址：">
                    <p>{{form.authRegisterAddress}}</p>
                  </el-form-item>
                </el-col> -->
                <el-col :span="12">
                  <el-form-item label="营业执照号:" v-if="stateType==1||stateType==3">
                    <p>{{form.authBusinessLicense}}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="stateType==1||stateType==3">
                  <el-form-item label="法人/代理人姓名:">
                    <p>{{form.authName}}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="stateType==1||stateType==3?'法人/代理人身份证号:':'身份证号:'">
                    <p>{{form.authIdCard}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="photo_content_box mt10"><!--身份证-->
              <div class="photo_row_imgBox"><!--身份证正面-->
              <el-image :src="form.authFullFace" 
                  :preview-src-list="[form.authFullFace]">
                  <div slot="error">暂无图片 </div>
              </el-image>
              </div>
              <div class="photo_row_imgBox"><!--身份证反面-->
                <el-image :src="form.authReverseFace" 
                    :preview-src-list="[form.authReverseFace]">
                    <div slot="error">暂无图片 </div>
                </el-image>
              </div>
              <div class="photo_row_imgBox"><!--身份证执证照-->
                <el-image :src="form.authPermitPhoto" 
                    :preview-src-list="[form.authPermitPhoto]">
                    <div slot="error">暂无图片 </div>
                </el-image>
              </div>
            </div>
            <div class="photo_content_box" v-if="stateType==1||stateType==3">
              <div class="photo_row_imgBox"><!--营业执照-->
                <el-image :src="form.authBusinessLicensePhoto" 
                    :preview-src-list="[form.authBusinessLicensePhoto]">
                    <div slot="error">暂无图片 </div>
                </el-image>
              </div>
              <div class="photo_row_imgBox" v-if="form.authIsLegal===0"><!--委托书-->
                <el-image :src="form.authPowerOfAttorneyPhoto" 
                    :preview-src-list="[form.authPowerOfAttorneyPhoto]">
                    <div slot="error">暂无图片 </div>
                </el-image>
              </div>
            </div>
        </el-col>
      </el-row>
      <div class="footer" v-if="stateType==0||stateType==1">
        <div class="btn">
          <el-button type="primary" @click="doSubmit('form0')">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //接收状态值，0个人修改，1企业修改
      stateId:'',//商户id
      employlist:[],//维护业务员
      banklist:[],//银行开户行
      biddingCitylist:[],//竞拍城市
      onCityName:[],//必须放在外面的竞拍城市（form）
      marketlist:[],//交易市场
      paraList:[],//办证公司
      form: {},
      dialogVisible: false,
      rules0: {
        linkPhone2: [{ required: true, message: "联系电话2不能为空" },
        { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }]
      },
      rules1: {
        // maintainerName: [{ required: true, message: "维护业务员不能为空" }],
        // certCompanyName: [{ required: true, message: "办证公司不能为空" }],
        // businessAddress: [{ required: true, message: "经营详情地址不能为空" }],
        // spareBusinessAddress: [{ required: true, message: "经营详情地址2不能为空" }],
        // placeNo: [{ required: true, message: "交易市场1不能为空" }],
        // placeNo2: [{ required: true, message: "交易市场2不能为空" }],
        // waiter: [{ required: true, message: "售后服务员不能为空" }],
        // placeName2: [{ required: true, message: "交易市场不能为空" }],
        // biddingCity: [{ required: true, message: "请至少选择一个城市" }]
      },
      rules2: {
        accountName: [{ required: true, message: "银行开户名不能为空" }],
        boshBankName: [{ required: true, message: "开户行简称不能为空" }],
        bankAccount: [{ required: true, message: "银行账号不能为空" }],
        bankName: [{ required: true, message: "开户行支行不能为空" }]
      },
      resultArr: [], //接受验证返回结果数组
      formArr: ["form0", "form1", "form2"], //存放表单ref数组
      tips: "过户",//提示文字
      searchTemp:'',//搜索临时存储
      selectloading:false,
      selectForm1:{},
      selectForm2:{},
      changeFlag:false,
      res:{},
      addressArr:[],
      addressArr1:[],
      optionsAddress:[],
      propsAddress:{
        label:'name',
        value:'id',
        children:'children'
      },
      selectArea:{
        provinceId:'',
        provinceName:'',
        cityId:'',
        cityNo:'',
        cityName:'',
        areaId:'',
        areaName:''
      },//三级联动
      selectArea1:{
        provinceId:'',
        provinceName:'',
        cityId:'',
        cityNo:'',
        cityName:'',
        areaId:'',
        areaName:''
      },//三级联动
      bankVis:false,
      banklistSear:[],
      addressArr2:[],
      cityListSelect:[],
    };
  },
  created() {
    /**state=0,个人修改;state=1,公司修改;state=2,个人查看;state=3,公司查看**/
    this.stateType = this.$route.query.state;
    this.stateId=this.$route.query.id;
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.optionsAddress=res;
      })
    }else{
      this.optionsAddress=this.$store.state.setting.cityList;
    }
    //无级联市
    if(this.$store.state.setting.cityListSelect.length==0){
      this.$datadic.getRegionByConditions(2).then(res=>{
        this.cityListSelect=res;
      })
    }else{
      this.cityListSelect=this.$store.state.setting.cityListSelect;
    }

    this.getMerchantDetails()//获取商户详情

    this.queryUserListByRoleAndDepart()//获取维护人员
    this.postbankHeadList()//根据银行简称查询银行简称
    // this.queryBusinessArea()//获取竞拍城市
    // this.queryBussinessCity()//获取交易市场
    // this.queryBzComByComNo()//根据办证公司编号查询办证公司
  },
  methods: {
    onBankTage(row){
      this.form.bankName=row.bankName;
      this.bankVis=false;
    },
    onInputBank(){
      if(!this.form.boshBankName){
        this.$message.warning('请选择银行简称后再输入银行支行！')
        return
      }
      if(this.addressArr2.length==0){
        this.$message.warning('请选择开户行地区！')
        return
      }
      if(this.form.bankName){
        this.bankVis=true;
        this.postbankList(this.form.bankName)
      }else{
        this.bankVis=false;
      }
    },
    changeAddress(state){
      switch(state){
        case 1:
          const row=this.$refs.address.getCheckedNodes();
          if(row.length>0){
            let item=row[0]
            this.selectArea={
              provinceId:item.pathNodes[0].value,
              provinceName:item.pathNodes[0].label,
              cityId:item.pathNodes[1].value,
              cityName:item.pathNodes[1].label,
              cityNo:item.data.code,
              areaId:item.value,
              areaName:item.label
            }//三级联动
          }else{
            this.selectArea={
              provinceId:'',
              provinceName:'',
              cityId:'',
              cityNo:'',
              cityName:'',
              areaId:'',
              areaName:''
            }//三级联动
          }
        break;
        case 2:
          const row1=this.$refs.address1.getCheckedNodes();
          if(row1.length>0){
            let item=row1[0]
            this.selectArea1={
              provinceId:item.pathNodes[0].value,
              provinceName:item.pathNodes[0].label,
              cityId:item.pathNodes[1].value,
              cityName:item.pathNodes[1].label,
              cityNo:item.data.code,
              areaId:item.value,
              areaName:item.label
            }//三级联动
          }else{
            this.selectArea1={
              provinceId:'',
              provinceName:'',
              cityId:'',
              cityNo:'',
              cityName:'',
              areaId:'',
              areaName:''
            }//三级联动
          }
        break;
      }
    },
    forceUpdate(){
      this.$forceUpdate()
    },
    handleChangeFlag(type) {// select回调，判断当前下拉框是否展示
      this.changeFlag = type;
    },
    onRouterMerchant: function() {
      if(this.res.pdfUrl){
        window.open(this.res.pdfUrl,'_blank')
      }else{
        this.$message.warning('暂无协议！')
      }
      
      // this.$router.push({
      //   path:'/salesAssistant/merchantContract',
      //   query:{
      //     id:this.form.id,
      //     state:3
      //   }
      // })
    },
    doSubmit() {
      this.updateManage()
      // this.formArr.forEach(item => {
      //   //根据表单的ref校验
      //   this.checkForm(item);
      // });
      // Promise.all(this.resultArr)
      //   .then(()=> {
      //     this.updateManage()
      //   })
      //   .catch(()=> {
      //     this.resultArr=[];
      //   });
    },
    //封装验证函数
    checkForm(formName) {
      let result = new Promise((resolve, reject)=> {
        this.$refs[formName].validate(valid => {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
      this.resultArr.push(result); //push 得到promise的结果
    },
    onroute() {
      this.bus.$emit("closeSelected");
      // this.$router.push({
      //   path: "/merchant/contractor"
      // });
    },
    changMaintain(data,type){//维护业务员(0维护员，1售后员)\
      if(type==0){
        this.form.maintainerId=data.id;
        this.form.maintainerName=data.name;
      }else{
        this.form.afterId=data.id;
        this.form.afterName=data.name;
      }
    },
    changCompany(data){//办证公司选择
      this.form.certCompanyName=data
    },
    changMark(data){
      console.log(data,'---9990')
    },
    updateManage(){//根据id修改商户信息
      let query={
        "merchantId": this.form.id,//商户Id
        "merchantOtherMobile": this.form.merchantOtherMobile,//商户其他手机
        "maintainerId": this.form.maintainerId,//维护专员ID（后期维护车商出价等服务的业务员）
        "maintainerName": this.form.maintainerName,//维护专员名称
        "afterId": this.form.afterId,//售后专员ID
        "afterName": this.form.afterName,//售后专员名称
        "accountName": this.form.accountName,//开户姓名
        "boshBankName":this.form.boshBankName,//银行简称
        "bankAccount": this.form.bankAccount,//银行账号
        "bankName": this.form.bankName,//开户行支行
        "bankType": this.form.bankType,//银行卡类型 0-私户 1-公户
        "businessProvinceId": this.selectArea.provinceId,//经营省份ID
        "businessProvinceName": this.selectArea.provinceName,//经营省份名称
        "businessCityId": this.selectArea.cityId,//经营城市ID
        "businessCityName": this.selectArea.cityName,//经营城市名称
        "businessAddress": this.form.businessAddress,//经营地址
        "spareBusinessProvinceId": this.selectArea1.provinceId,//备用经营省份ID
        "spareBusinessProvinceName": this.selectArea1.provinceName,//备用经营省份名称
        "spareBusinessCityId": this.selectArea1.cityId,//备用经营城市ID
        "spareBusinessCityName": this.selectArea1.cityName,//备用经营城市名称
        "spareBusinessAddress": this.form.spareBusinessAddress,//备用经营地址
        registerCityId:this.form.registerCityId,//注册城市
        "certCompanyId": this.form.certCompanyId,//办证公司ID
        "certCompanyName": this.form.certCompanyName,//办证公司名称
        // "tradingMarketId": 0,//交易市场ID
        // "tradingMarketName": "string",//交易市场名称
        // "spareTradingMarketId": 0,//备用交易市场ID
        // "spareTradingMarketName": "string",//备用交易市场名称
        "auctionCity": "上海",//竞拍城市
        bankAccountProvincial:this.addressArr2.length>0?this.addressArr2[0]:'',
        bankAccountCity:this.addressArr2.length>0?this.addressArr2[1]:'',
      }
      
      this.$api.updateSigningMerchant(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getMerchantDetails()//获取商户详情
          // this.onroute()
        }
      })
    },
    getMerchantDetails(){//查询商户信息
      this.$api.getMerchantDetails(this.stateId).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=res.data;
            this.form=JSON.parse(JSON.stringify(res.data));
            let oldArr=this.biddingCitylist;//竞拍城市
            for(let i=0;i<oldArr.length;i++){
              if(res.data.auctionCitys.indexOf(oldArr[i].areaNo)!=-1){
                this.onCityName.push(oldArr[i].areaName)
              }
            }
            this.form.afterNameObj=res.data.afterName;
            this.form.maintainerNameObj=res.data.maintainerName;
            
            this.selectArea={
              provinceId:res.data.businessProvinceId,
              provinceName:res.data.businessProvinceName,
              cityId:res.data.businessCityId,
              cityName:res.data.businessCityName,
            }
            this.selectArea1={
              provinceId:res.data.spareBusinessProvinceId,
              provinceName:res.data.spareBusinessProvinceName,
              cityId:res.data.spareBusinessCityId,
              cityName:res.data.spareBusinessCityName,
            }
            this.addressArr=[res.data.businessProvinceId?res.data.businessProvinceId:'',res.data.businessCityId?res.data.businessCityId:''];
            this.addressArr1=[res.data.spareBusinessProvinceId?res.data.spareBusinessProvinceId:'',res.data.spareBusinessCityId?res.data.spareBusinessCityId:''];
            this.addressArr2=[Number(res.data.bankAccountProvincial),Number(res.data.bankAccountCity)];
            this.$nextTick(()=>{
              this.$refs.form0.clearValidate();
              this.$refs.form1.clearValidate();
              this.$forceUpdate()
              // this.$refs.form2.clearValidate();
            })
          }
        }
      })
    },
    postbankHeadList(){//银行列表查询
      this.$api.postbankHeadList({}).then(res=>{
        if(res.code==0){
          this.banklist=res.data
        }
      })
    },
    postbankList(data){//根据银行简称查询银行支行
      let query={
        bankName:data,
        headBankName:this.form.boshBankName,
        cityNo:this.addressArr2[1]
      }
      this.$api.postbankList(query).then((res)=>{
        if(res.code==0){
           this.banklistSear=res.data;
        }
      })
    },
    queryBusinessArea(){//获取竞拍城市
      this.$api.queryBusinessArea().then((res)=>{
        if(res.code==0){
          this.biddingCitylist=res.data
        }
      })
    },
    queryUserListByRoleAndDepart(){//维护业务员
      let query={
        "roleName": "销售专员"
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
  }
};
</script>
<style >
.el-textarea__inner {
  height: 100px;
}
.el-form-item__error {
  padding: 0;
}
.checkgroupBox .el-form-item__content{
  width: 73%;
}
</style>
<style lang="scss" scoped>
.contractorBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
  // overflow:auto;
}
.pageContainer {
  background-color: #ffffff;
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:1;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .formCustomRow {
    .el-form-item {
      // width: 48%;
      margin-bottom: 0px;
    }
    .w100 {
      width: 100%;
    }
    .waddrss{
      width: 300px;
    }
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 52%;
  }
  .w100 {
    width: 100%;
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    .photo_row_imgBox {
      width: 334px;
      margin-right: 20px;
      margin-bottom: 50px;
      height: 195px;
      background: #f5f5f5;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        max-height: 100%;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
  .checkgroupBox{
    display: flex;
  }
  
}
@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .pageContainer .w50 {
    width: 60%;
  }
  .pageContainer .photo_content_box {
    width: 80%;
  }
}
@media only screen and (max-width: 1400px) {
  .pageContainer .photo_content_box {
    width: 100%;
  }
}
@media only screen and (max-width: 1350px) {
  .pageContainer .w50,
  .pageContainer .photo_content_box {
    width: 100%;
  }
}
@media only screen and (max-width: 1280px) {
  .pageContainer .w50,
  .pageContainer .photo_content_box {
    width: 100%;
  }
}
</style>
