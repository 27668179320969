<template>
  <!-- 延期竞拍跟进 -->
  <div class="deferredAuction">
    <!-- <h1 class="mb20">延期竞拍跟进</h1> -->
    <el-radio-group v-model="isFollowUp" class="groupbg" @change="init(1)">
      <el-radio-button :label="0">待跟进</el-radio-button>
      <el-radio-button :label="1">已跟进</el-radio-button>
    </el-radio-group>
    <el-row :gutter="24" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="carYearAndType" label="年份/车型" align="center"></el-table-column>
      <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
      <el-table-column prop="uploadTime" label="报告上传时间" align="center"></el-table-column>
      <el-table-column prop="extensionAuctionTime" label="延期竞拍日期" align="center"></el-table-column>
      <el-table-column prop="remark" label="延期原因" align="center"></el-table-column>
      <el-table-column label="操作" width="120px" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onRouter(scope.row)" size="mini" plain>载入客户</el-button>
          <!-- <el-button type="primary" size="mini" plain @click="accomplish(scope.row)">完成</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      isFollowUp:0,//是否跟进完成 0-否 1-是
      form:{
        carNo:''
      },
    }
  },
  created(){
    this.init()
  },
  methods: {
    accomplish(row) {
      this.$confirm('您确认已对客户的延期竞拍跟进完成？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postExtensionAuctionFinish(row.id)
      }).catch(() => {});
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    init(page){
      this.page_index=page?page:this.page_index;
      
      let user=this.$store.state.user.userData;
      let role=this.$$.roleName(user)
      let de=this.$$.roleName(user,'department')
      let bookShopId=''
      if(de.shop){
        bookShopId=de.shop.id;
      }
      let query={
        "curPage": this.page_index,
        "pageSize": this.page_size,
        bookShopId:bookShopId,
        isFollowUp:this.isFollowUp,
        carNo:this.form.carNo,
      }
      this.$api.queryExtensionAuction(query).then(res => {
        if(res.code==0){
          this.querylist=res.data.records;
          this.total=res.data.total;
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    onRouter(row){
      if(this.isFollowUp===0){
        this.postExtensionAuctionFinish(row.id,row)
      }else{
        this.$router.push({
          path: '/salesManager/customer', 
          query: {
            followUpInfoId: row.followUpInfoId,
          }
        })
      }
    },
    postExtensionAuctionFinish(id,row){//延期竞拍跟进完成
      this.$api.postExtensionAuctionFinish(id).then(res =>{
        if(res.code==0){
          this.$router.push({
            path: '/salesManager/customer', 
            query: {
              followUpInfoId: row.followUpInfoId,
            }
          })
          // this.init()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.deferredAuction{
  padding: 20px 40px;
}
</style>