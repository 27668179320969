<template>
  <!-- 车辆复检 -->
  <div class="vehicleRecheck">
    <el-tabs v-model="activeName" @tab-click="init(1)" type="card" class="groupbg">
      <el-tab-pane label="待分配" name="first">
        <el-table :data="querylist" border stripe class="table-mainbg mt30">
          <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="validStatusName" label="复检类型" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button type="primary" @click="onOperation(scope.row)" size="mini">分配</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          :current-page="page_index"
          :total="page_total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="已分配" name="second">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>商户姓名</span>
            <el-input v-model="params.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号码</span>
            <el-input v-model="params.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>检测状态</span>
            <el-select v-model="params.checkStatus" size="medium" clearable>
              <el-option v-for="item in statelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt30">
          <el-table-column prop="merchantName" label="商户姓名" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="allotTime" label="分配时间" align="center"></el-table-column>
          <el-table-column prop="validStatusName" label="检测状态" align="center"></el-table-column>
          <el-table-column prop="recheckType" label="复检类型" align="center">
            <template slot-scope="scope">{{scope.row.recheckType==2?'商户差异复检':scope.row.recheckType==1?'商户验车':''}}</template>
          </el-table-column>
          <el-table-column prop="validFinishTime" label="复检完成时间" align="center"></el-table-column>
          <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="140">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="onRouter(scope.row)" :disabled="[1,2,4].includes(scope.row.validStatus)">差异评定</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size1"
          :current-page="page_index1"
          :total="page_total1"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!-- 分配 -->
    <el-dialog :visible.sync="distributionDialog" title="检测师分配">
      <div class="flex flex_around flex_align_center">
        <span>商户姓名：{{itemRow.merchantName}}</span>
        <span>品牌车型：{{itemRow.carType}}</span>
        <span>车牌号码：{{itemRow.carNo}}</span>
      </div>
      <div class="mt20 mr10">请选择检测师:</div>
      <div class="flex flex_wrap">
        <el-radio-group v-model="inspector" size="medium">
          <el-radio v-for="item in userlist" :key="item.id" :label="item.id" class="mt10">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <!-- <div style="padding-left:50px;">注：只展示交付中心的检测师名单</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onModalSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      querylist: [],
      querylist1:[],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      statelist:[{id:1,name:'待复检'},{id:2,name:'复检中'},{id:3,name:'复检完成'},{id:4,name:'复检失败'}],
      distributionDialog: false,
      params: {
        checkStatus:''
      },
      inspector: '',
      itemRow:{},//当前选择的数据
      userlist:[],//检测师列表
    }
  },
  created(){
    this.init()
    this.queryUserListByRoleAndDepart()//获取检测师
  },
  methods: {
    handleSizeChange(row){
      switch(this.activeName){
        case 'first':
          this.page_size=row;
          this.page_index=1;
          break;
        case 'second':
          this.page_size1=row;
          this.page_index1=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(row){
      switch(this.activeName){
        case 'first':
          this.page_index=row;
          break;
        case 'second':
          this.page_index1=row;
          break;
      }
      this.init()
    },
    init(page) {
      let query={
        recheckType:2//复检类型 0-垫资复检 1-商户验车 2-商户差异复检
      }
      switch(this.activeName){
        case 'first':
          this.page_index=page?page:this.page_index;

          query.curPage=this.page_index;
          query.pageSize=this.page_size;
          query.allotStatus=11//验车分配状态：11待分配，12已分配
          break;
        case 'second':
          this.page_index1=page?page:this.page_index1;

          query.curPage=this.page_index1;
          query.pageSize=this.page_size1;
          query.allotStatus=12;
          query.validStatus=this.params.checkStatus;
          query.merchantName=this.params.merchantName;
          query.carNo=this.params.carNo;
          break;
      }
      this.$api.postValidList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          switch(this.activeName){
            case 'first':
              this.querylist=res.data.records;
              this.page_total=res.data.total;
              break;
            case 'second':
              this.querylist1=res.data.records;
              this.page_total1=res.data.total;
              break;
          }
        }else{
          switch(this.activeName){
            case 'first':
              this.querylist=[];
              this.page_total=0;
              break;
            case 'second':
              this.querylist1=[0];
              this.page_total1=0;
              break;
          }
        }
      })
    },
    onOperation(row){
      this.distributionDialog=!this.distributionDialog;
      this.itemRow=JSON.parse(JSON.stringify(row));
    },
    onRouter(row){
      this.$router.push({
        path: '/storeManager/difEvaluationDetail',
        query:{
          carValidId:row.carValidId,
          state:3,//默认没有值为查看，1差异评定，2差异确认，3差异复检
          title:'商户差异评定'
        }
      })
    },
    queryUserListByRoleAndDepart(){//获取检测师
      let query={
        roleName:'检测师'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data
        }
      })
    },
    onModalSubmit(){
      this.$api.postAssignChecker(this.itemRow.id,this.inspector).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.distributionDialog=!this.distributionDialog;
          this.init()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.vehicleRecheck{
  padding: 20px 40px;
}
</style>