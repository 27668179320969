<template>
  <div class="pageContainer">
    <div class="flex flex_align_center">
      <span class="mr10">资源设置时间:</span>
       <el-date-picker
          v-model="month"
          type="month" value-format="yyyy-MM"
          placeholder="选择日期" :picker-options="expireTimeOption" @change="init">
        </el-date-picker>
    </div>
    <el-table class="mt20 fieldTable"
      :data="querylist" 
      border>
      <el-table-column
        :prop="item.state"
        :label="item.name" v-for="(item,index) in Headlist" :key="index" align="center" 
        >
        <template slot-scope="scope">
          <div v-if="index==0">{{scope.row[0]}}</div>
          <el-input v-model="scope.row[index]" v-if="index"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" >确认修改</el-button>
        <el-button type="primary" @click="onReturn" >返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {},
      month:'',
      querylist: [],
      reslist:[],
      Headlist:[],
      //设置失效日期今天之前的日期不可选
      expireTimeOption: {
        disabledDate(date) {
              //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
              return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
          },
      },
      userObj:{},
    }
  },
  created() {
    let date=new Date()
    let am=date .getMonth()+1<10?`0${date .getMonth()+1}`:date .getMonth()+1;
    this.month=`${date.getFullYear()}-${am}`;
    this.userObj=this.$$.roleName(this.$store.state.user.userData);
    // console.log(this.userObj)
    this.init();
  },
  mounted(){
  },
  methods: {
    init(){
      let arr=[];
      let arr1=[];
      this.Headlist=[];
      this.querylist=[];
      this.$api.getCheckResourcesFieldSetting(this.month).then(res=>{
        if(res.code==0){
          this.reslist=res.data;
          this.Headlist.push({name:'日期',state:'title'})
          for(let j=0;j<res.data.length;j++){
            let item=res.data[j]
            let obj={
              name:`${j+1}`,
              state:`${j+1}`
            }
            this.Headlist.push(obj)
            let key=`${j+1}`
            let value=`${item.count?item.count:''}`
            let obj2={}
            obj2['0']='外勤检测师人数'
            obj2[key]=value;
            arr1.push(obj2)
          }
          let obo={}
          for(let j=0;j<arr1.length;j++){
            obo={...obo, ...arr1[j]}
          }
          arr.push(obo)
          this.querylist=JSON.parse(JSON.stringify(arr));
        }
      })
    },
    onSubmit(){
      let arr=JSON.parse(JSON.stringify(this.reslist));
      let newArr=[];
      for(let i=0;i<arr.length;i++){
        let item=this.reslist[i]
        for(let j in this.querylist[0]){
          if(i+1==j){
            item.count=this.querylist[0][j]?this.querylist[0][j]:null;
          }
        }
        newArr.push(item)
      }
     this.$api.postCheckResourceSettingUpdateBatch(newArr).then(res=>{
       if(res.code==0){
         this.$message.success(res.message)
         this.init()
       }
     })
    },
    
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/workbench/index"
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .iconText{
    font-size:50px;
    cursor: pointer;
    margin-left: 20px;
  }
  .paibox{
    margin-left:100px;
  }
  .footerBox{
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding:5px;
  }
  .wall{
    width:100%;
    height:25px;
  }
}
</style>
<style>
.fieldTable td:first-child .cell,
.fieldTable .cell,
.fieldTable td{padding:0}
</style>