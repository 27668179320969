<template>
  <!-- 材料跟进 -->
  <div class="materialsFollowUp">
    <el-tabs v-model="activeName" @tab-click="handleClick"  type="card">
      <el-tab-pane label="待跟进" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="跟进中" name="second" class="mt20"></el-tab-pane>
      <el-tab-pane label="跟进完成" name="third" class="mt20">
      </el-tab-pane>
      <el-row :gutter="20" class="mb20">
        <el-col :span="5" class="flex flex_align_center flex_between w4">
          <span>车牌号码：</span>
          <el-input v-model="params.carNo"></el-input>
        </el-col>
        <el-col :span="5" class="flex flex_align_center flex_between w4">
          <span>客户名称：</span>
          <el-input v-model="params.customerName"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        </el-col>
      </el-row>

      <el-table :data="tableData" border stripe class="mt20 table-mainbg">
        <el-table-column align="center" prop="carNo" label="车牌号码"></el-table-column>
        <el-table-column align="center" :formatter="formatBrand" label="品牌车型"></el-table-column>
        <el-table-column align="center" prop="tradeShopName" label="成交门店"></el-table-column>
        <el-table-column align="center" prop="customerName" label="客户名称"></el-table-column>
        <el-table-column align="center" prop="sellerName" label="帮卖经理"></el-table-column>
        <el-table-column align="center" prop="customerContractDate" label="客户合同签订日期"></el-table-column>
        <el-table-column align="center" v-if="activeName==='second'" prop="lastFollowTime" label="下次跟进时间"></el-table-column>
        <el-table-column align="center" v-if="activeName==='third'" prop="lastFollowTime" label="跟进完成时间"></el-table-column>
        <el-table-column align="center" label="操作" width="100px">
          <template slot-scope="scope">
            <el-button type="primary" v-if="['first','second'].includes(activeName)" @click="$router.push({path: '/salesManager/procedures', query: {id: scope.row.id}})" size="mini">跟进</el-button>
            <el-button type="primary" v-if="['third'].includes(activeName)" @click="$router.push({path: '/salesManager/procedures', query: {id: scope.row.id}})" size="mini">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      activeName: 'first',
      params: {
        carNo: '',
        customerName: ''
      }
    }
  },
  created() {
    if(this.$route.params.state){
      this.activeName=this.$route.params.state
    }
    this.handleClick();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    formatBrand(row) {
      return row.brandName + '-' + row.modelName
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        carNo: this.params.carNo,
        customerName: this.params.customerName
      }
      switch(this.activeName){
        case 'first':
          params.followStatus = [1];
          break;
        case 'second':
          params.followStatus = [2];
          break;
        case 'third':
          params.followStatus = [3];
          break;
      }
      this.$api.carList(params).then(res => {
        if(res.code === 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
        }else{
          this.tableData = [];
          this.page_total = 0;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.materialsFollowUp{
  padding: 20px 40px;
}
</style>