<template>
  <!-- 次日候补排班 -->
  <div class="alternateScheduling">
    <div class="mb20"><span class="mr30">{{nextDay()}}</span></div>
    <table>
      <tr><th>门店名称</th><th>人力</th><th>任务单量</th><th>操作</th></tr>
      <tr v-for="item in capacityList" :key="item.storeNo">
        <td>{{item.storeName}}</td><td>候补</td>
        <td>
          <div class="timeWrap" v-for="ite in item.checkers" :key="ite.checkerId">
            <div v-for="it in ite.checkTimeCapacity" :key="it.time" class="statusDiv">{{`${it.time}(${it.count})`}}</div>
          </div>
        </td>
        <td><el-button plain type="primary" @click="assign(item.storeNo)" size="mini">指派</el-button></td>
      </tr>
    </table>

    <!-- 指派检测师 -->
    <el-dialog :visible.sync="dialogVisible" center title="指派检测师">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
        <div class="flex flex_align_center mb20">
          <span class="dialogLabel">值班时间段：</span>
          <el-form-item prop="startTime" style="position:relative;top:10px;">
            <el-time-select class="mr10 inputSelect" placeholder="起始时间" v-model="ruleForm.startTime" :picker-options="{ start: '09:00', step: '00:30', end: '18:00' }"></el-time-select>
          </el-form-item>
          <el-form-item prop="endTime" style="position:relative;top:10px;">
            <el-time-select class="mr10 inputSelect" placeholder="结束时间" v-model="ruleForm.endTime" :picker-options="{ start: '09:00', step: '00:30', end: '18:00', minTime: ruleForm.startTime}"></el-time-select>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search" plain size="mini">搜索</el-button>
        </div>
        <el-form-item label="选择检测师" prop="testName" label-width="120px">
          <el-cascader ref="testName" style="width:100%;" v-model="ruleForm.testName" :options="testOptions" :show-all-levels="false" :props="{value:'id',label: 'name',children: 'users'}"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTestForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      capacityList: [],
      dialogVisible: false,
      testOptions: [],
      ruleForm:{
        startTime: '',
        endTime: '',
        testName: '',
      },
      rules: {
        startTime: [{ required: true, message: '请选择', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择', trigger: 'change' }],
        testName: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      curStoreNo: '',
    }
  },
  created() {
    this.nextDayQueryBackupCapacity();
  },
  methods: {
    nextDayQueryBackupCapacity() {
      this.$api.nextDayQueryBackupCapacity().then(res => {
        if(res.code == 0){
          this.capacityList = res.data;
        }
      })
    },
    assign(storeNo) {
      this.curStoreNo = storeNo;
      this.dialogVisible = true;
    },
    nextDay() {
      const date = new Date(Date.now()+86400000)
      return this.$$.getFormatMomentTime('YYYY/MM/DD', date);
    },
    handleChange(value) {
      console.log(value);
    },
    search() {
      let type=false;
      this.$refs['ruleForm'].validateField(['startTime', 'endTime'],err=>{
        if(err){
          type = true;
        }
      });
      if(type){
        return;
      }
      this.$api.checkerQueryLeisureChecker({
        startTime: this.ruleForm.startTime,
        endTime: this.ruleForm.endTime,
      }).then(res => {
        if(res.code == 0){
          this.testOptions = res.data;
          console.log(this.$refs.testName);
          this.$refs.testName.dropDownVisible=true
        }
      })
    },
    submitTestForm() {
      this.$refs['ruleForm'].validateField('testName',err=>{
        if(!err){
          let params = {
            storeNo: this.curStoreNo,
            checkerId: this.ruleForm.testName[this.ruleForm.testName.length-1],
            isBackup: 1,
            startTime: this.ruleForm.startTime,
            endTime: this.ruleForm.endTime,
          }
          this.$api.nextDayAssignChecker(params).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功')
              this.nextDayQueryBackupCapacity()
              this.dialogVisible = false;
              this.$refs['ruleForm'].resetFields();
            }
          })
        }
      });
    }
  }

}
</script>

<style scoped lang="scss">
.alternateScheduling{
  padding: 20px 40px 100px;
  table{
    border-collapse: collapse;
    width: 100%;
    th{
      padding: 10px 0;
      border: 1px solid #cccccc;
      text-align: center;
    }
    td{
      border: 1px solid #cccccc;
      text-align: center;
      .timeWrap{
        min-width: 900px;
        display: flex;
        flex-wrap: wrap;
        .statusDiv{
          width: 11.1111%;
          padding: 8px;
          text-align: center;
          border: 1px solid #cccccc;
        }
      }
    }
  }
  .dialogLabel{
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  .inputSelect{width: 150px;}
}
</style>