
const login = resolve => require.ensure([], () => resolve(require('@/views/login/login.vue')));
const role = resolve => require.ensure([], () => resolve(require('@/views/home/role.vue')));
const notFound = resolve => require.ensure([], () => resolve(require('@/views/error/404.vue')));
const noPower = resolve => require.ensure([], () => resolve(require('@/views/error/401.vue')));
const userCenter = resolve => require.ensure([], () => resolve(require('@/views/user/center.vue')));
const updatePsd = resolve => require.ensure([], () => resolve(require('@/views/user/updatePsd.vue')));
const forgetPsd = resolve => require.ensure([], () => resolve(require('@/views/login/forgetPsd.vue')));
const home = resolve => require.ensure([], () => resolve(require('@/views/home/home.vue')));
// const workbench = resolve => require.ensure([], () => resolve(require('@/views/common/workbench.vue')));
// const addCustomer = resolve => require.ensure([], () => resolve(require('@/views/onlineService/addCustomer.vue')));
// const customer = resolve => require.ensure([], () => resolve(require('@/views/onlineService/customer.vue')));
// const listKST = resolve => require.ensure([], () => resolve(require('@/views/onlineService/listKST.vue')));
// const listDZDP = resolve => require.ensure([], () => resolve(require('@/views/onlineService/listDZDP.vue')));
// const queryCustomer = resolve => require.ensure([], () => resolve(require('@/views/common/queryCustomer.vue')));
// const capacity = resolve => require.ensure([], () => resolve(require('@/views/testService/capacity.vue')));
// const user = resolve => require.ensure([], () => resolve(require('@/views/user/index.vue')));


const pathList = [
  {path:'/login', name:'login', component: login, meta:{title:'登录', keepAlive:false}},
  {path:'/', name:'/', redirect: '/workbench/index'},
  {path:'/404', name:'notFound', component: notFound, meta:{title:'找不到此页面', keepAlive:false}},
  {path:'/401', name:'noPower', component: noPower, meta:{title:'没有权限', keepAlive:false}},
  {path:'/role', name:'role', component: role, meta:{title:'身份切换', keepAlive:false}},
  {path:'/forgetPsd', name:'forgetPsd', component: forgetPsd, meta:{title:'忘记密码', keepAlive:false}},
  {
    path: '/redirect',
    component: home,
    meta:{isHide: true},
    children: [
      {
        path: '/redirect/:path*',
        component: (resolve) => require(['@/views/error/redirect'], resolve)
      }
    ]
  },
  {
    path:'/home', 
    name:'home', 
    component: home,
    children: [
      {path:'/user/center', name:'userCenter', component: userCenter, meta:{title:'个人信息', keepAlive:false}},
      {path:'/user/updatePsd', name:'updatePsd', component: updatePsd, meta:{title:'修改密码', keepAlive:false}},
    ]
  },
]

export default pathList;