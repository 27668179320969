<template>
  <div class="expenseAudBoxBG">
    <div class="expenseAudBox pb100 mb30">
      <!--基本信息-->
      <div class="ckxTitle mb10">办证基本信息</div>
      <el-row>
        <el-col :span="5">车牌号：{{form.carNo}}</el-col>
        <!-- <el-col :span="5">车辆持性质：{{form.publicOrPrivateName}}</el-col> -->
        <el-col :span="5">品牌车型：{{form.makerName}}&nbsp;{{form.seriesName}}</el-col>
        <!-- <el-col :span="5">车辆所在库：{{res.stockObjInName}}</el-col> -->
        <el-col :span="4">成交门店：{{res.tradeShopName}}</el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="5">产证编号：{{form.certNo}}</el-col>
        <el-col :span="5">办证类型：{{form.certTypeName}}</el-col>
        <el-col :span="5">办证地区：{{res.provinceName}}{{res.cityName}}{{res.areaName}}</el-col>
        <el-col :span="5">商户办证截止日期：{{res.merchantDeadline}}</el-col>
      </el-row>
      
      <!--商户信息-->
      <div class="flex flex_align_center mb10 mt20">
        <div class="ckxTitle">商户及维护人员信息</div>
        <span class="seeColor ml20" @click="onrouter(1)">商户合同查看>></span>
      </div>
      <el-row class="mt10">
        <el-col :span="5">商户姓名：{{res.merchantName}}</el-col>
        <el-col :span="5">商户电话： {{res.merchantMobile}}</el-col>
        <el-col :span="5">商户维护人员： {{carMerchant.maintainerName}}</el-col>
        <el-col :span="5">商户电话： {{carMerchant.merchantOtherMobile}}</el-col>
      </el-row>
      <!--过户凭证-->
      <!--费用明细（商户自理费用列表和办证材料合并）-->
      <div class="ckxTitle mb15 mt20">办证费用</div>
      <el-table :data="querylist" border class="table_top_bg">
        <el-table-column prop="itemName" label="项目" align="center">
           <template slot-scope="scope">{{scope.row.itemName?scope.row.itemName:scope.row.name}}</template>
        </el-table-column>
        <el-table-column prop="feeBearerName" label="承担方" align="center"></el-table-column>
        <el-table-column prop="feeAmount" label="承担方费用（元）" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center"></el-table-column>
        <el-table-column prop="beneficiaryAmount" label="支付受益方费用（元）" align="center"></el-table-column>
        <!-- <el-table-column prop="feeChecked" label="费用确认" align="center">
          <template slot-scope="scope">
            {{scope.row.feeChecked===1?'已确认':scope.row.feeChecked===2?'未确认':'-'}}
          </template>
        </el-table-column> -->
        <el-table-column prop="remark" label="费用说明" align="center" ></el-table-column>
        <!-- <el-table-column label="操作" align="center"  width="160" v-if="[1].includes(state)">
          <template slot-scope="scope">
            <el-button type="primary" @click="onapply(scope.row,1)" size="mini">修改</el-button>
            <el-button type="primary" @click="onapply(scope.row,2)" size="mini">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      
        
        <!--逾期确认(数据做了处理)-->
        <div class="ckxTitle mt20 mb15">逾期确认</div>
        <el-table :data="carOverdueList" border class="table_top_bg" :key="tableEidt">
          <el-table-column prop="itemName" label="逾期项目" align="center">
            <template slot-scope="scope">
              <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.itemName" size="medium" placeholder="请选择" value-key="id" @input="inOver(scope.row,scope.$index)">
                <el-option :value="item" :label="item.itemName" v-for="item in beOverduelist" :key="item.id"></el-option>
              </el-select>
              <p v-else>{{scope.row.itemName}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="overdueDays" label="超期天数（天）" align="center">
            <template slot-scope="scope">
              <el-input v-if="scope.row.add||scope.row.edit" v-model="scope.row.overdueDays" size="medium" placeholder="请输入" @input="inOver(scope.row,scope.$index,'day')" type="number"></el-input>
              <p v-else>{{scope.row.overdueDays}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="bearer" label="承担方" align="center">
            <template slot-scope="scope">
              <el-cascader :options="whichSidelistSYF" :props="props"  v-if="scope.row.add||scope.row.edit" v-model="scope.row.bearerArr" size="medium" clearable :ref="`refcascaderCYQ${scope.$index}`" @change="changeSupplie(1,scope.$index)" :show-all-levels="false"></el-cascader>
              <!-- <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.bearer" size="medium" placeholder="请选择" @change="forceUpdate()">
                <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistId" :key="item.id"></el-option>
              </el-select> -->
              <p v-else>{{scope.row.bearer===1?'客户':scope.row.bearer===2?'商户':scope.row.bearer===3?'车开新':scope.row.bearer===4?'办证公司':scope.row.bearer===5?'违章供应商':scope.row.bearer}}{{[4,5].includes(scope.row.bearer)?scope.row.bearerCertRuleSupplierName?'-'+scope.row.bearerCertRuleSupplierName:'':''}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="feeAmount" label="处罚承担方金额（元）" align="center"></el-table-column>
          <el-table-column prop="benefit" label="受益方" align="center">
            <template slot-scope="scope">
              <el-cascader :options="whichSidelistSYF" :props="props"  v-if="scope.row.add||scope.row.edit" v-model="scope.row.benefitArr" size="medium" clearable :ref="`refcascaderSYQ${scope.$index}`" @change="changeSupplie(2,scope.$index)" :show-all-levels="false"></el-cascader>

              <!-- <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.benefit" size="medium" placeholder="请选择" @change="forceUpdate()">
                <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistSYF" :key="item.id"></el-option>
              </el-select> -->
              <p v-else>{{scope.row.benefit===1?'客户':scope.row.benefit===2?'商户':scope.row.benefit===3?'车开新':scope.row.benefit===4?'办证公司':scope.row.benefit===5?'违章供应商':scope.row.benefit}}{{[4,5].includes(scope.row.benefit)?scope.row.benefitCertRuleSupplierName?'-'+scope.row.benefitCertRuleSupplierName:'':''}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" label="支付受益方金额" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="scope">
              <el-input v-if="scope.row.add||scope.row.edit" v-model="scope.row.remark" size="medium" placeholder="请输入" type="textarea"></el-input>
              <p v-else>{{scope.row.remark}}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="[2,3].includes(state)" width="160">
            <template slot-scope="scope">
              <el-button type="primary" @click="onClickBut(scope.row,1,1)" size="mini" v-if="scope.row.add">保存</el-button>
              <el-button type="primary" @click="onClickBut(scope.row,1,2)" size="mini" v-if="!scope.row.add&&!scope.row.edit">修改</el-button>
              <el-button type="primary" @click="onClickBut(scope.row,1,4)" size="mini" v-if="scope.row.edit">保存</el-button>
              <el-button type="primary" @click="onClickBut(scope.row,1,3)" size="mini" v-if="!scope.row.add">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--合同备注费用-->
        <!-- <div class="ckxTitle mt20 mb15">合同备注费用</div>
        <el-table :data="contractFeeBackList" border class="table_top_bg">
          <el-table-column prop="itemName" label="项目" align="center"></el-table-column>
          <el-table-column prop="feeAmount" label="承担方费用（元）" align="center"></el-table-column>
          <el-table-column prop="feeBearerName" label="承担方" align="center"></el-table-column>
          <el-table-column prop="beneficiaryId" label="受益方" align="center">
            <template slot-scope="scope">
            <el-select  v-model="scope.row.beneficiaryId" size="medium" placeholder="请选择" @input="forceUpdate">
              <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistId" :key="item.id"></el-option>
            </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="beneficiaryAmount" label="支付受益方金额（元）" align="center">
            <template slot-scope="scope">
              <el-input  v-model="scope.row.beneficiaryAmount" size="medium" placeholder="请输入" type="number" @input="forceUpdate"></el-input>
            </template>
          </el-table-column>
           <el-table-column label="操作" align="center" v-if="[2,3].includes(state)" width="160">
            <template slot-scope="scope">
              <el-button type="primary" @click="onClickButHT(scope.row,2)" size="mini" >保存</el-button>
            </template>
          </el-table-column>
        </el-table> -->
        <!--车辆车续补办费用-->
      <div class="ckxTitle mb15 mt20">车辆手续补办&合同备注费用</div>
      <el-table :data="materialProcedureList" border class="table_top_bg" :key="tableEidt1">
        <el-table-column prop="name" label="延伸项目" align="center">
          <template slot-scope="scope">
              <div v-if="scope.row.add||scope.row.edit" class="flex flex_align_center">
                <div v-if="scope.row.name&&scope.row.name.indexOf('备注')!=-1" class="inputTextMin">{{scope.row.name}}</div>
                <el-input v-if="scope.row.name&&scope.row.name.indexOf('备注')!=-1"  v-model="scope.row.selfDefineRemark" size="medium" placeholder="请输入" @input="forceUpdate"></el-input>
                <el-input v-else v-model="scope.row.name" size="medium" placeholder="请输入" @input="forceUpdate"></el-input>
              </div>
              
              <p v-else>{{scope.row.name}}&nbsp;{{scope.row.selfDefineRemark}}</p>
            </template>
        </el-table-column>
        <el-table-column prop="chargingFeeAmount" label="承担方费用（元）" align="center">
          <template slot-scope="scope">
              <el-input  v-model="scope.row.chargingFeeAmount" size="medium" placeholder="请输入" v-if="scope.row.add||scope.row.edit" @input="forceUpdate" type="number"></el-input>
              <p v-else>{{scope.row.chargingFeeAmount}}</p>
            </template>
        </el-table-column>
        <el-table-column prop="feeBearerName" label="承担方" align="center">
          <template slot-scope="scope">
            <el-cascader :options="whichSidelistSYF" :props="props"  v-if="scope.row.add||scope.row.edit" v-model="scope.row.feeBearerArr" size="medium" clearable :ref="`refcascaderCcar${scope.$index}`" @change="changeSupplie(3,scope.$index)" :show-all-levels="false"></el-cascader>
              <!-- <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.feeBearerId" size="medium" placeholder="请选择">
                <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistId" :key="item.id"></el-option>
              </el-select> -->
              <p v-else>{{scope.row.feeBearerName}}{{[4,5].includes(scope.row.feeBearerId)?scope.row.bearerCertRuleSupplierName?'-'+scope.row.bearerCertRuleSupplierName:'':''}}</p>
            </template>
        </el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center">
          <template slot-scope="scope">
            <el-cascader :options="whichSidelistSYF" :props="props"  v-if="scope.row.add||scope.row.edit" v-model="scope.row.beneficiaryArr" size="medium" clearable :ref="`refcascaderScar${scope.$index}`" @change="changeSupplie(4,scope.$index)" :show-all-levels="false"></el-cascader>
              <!-- <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.beneficiaryId" size="medium" placeholder="请选择">
                <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistSYF" :key="item.id"></el-option>
              </el-select> -->
              <p v-else>{{scope.row.beneficiaryId===1?'客户':scope.row.beneficiaryId===2?'商户':scope.row.beneficiaryId===3?'车开新':scope.row.beneficiaryId===4?'办证公司':scope.row.beneficiaryId===5?'违章供应商':scope.row.beneficiaryId}}{{[4,5].includes(scope.row.beneficiaryId)?scope.row.benefitCertRuleSupplierName?'-'+scope.row.benefitCertRuleSupplierName:'':''}}</p>
            </template>
        </el-table-column>
        <el-table-column prop="payFeeAmount" label="支付受益方金额（元）" align="center">
          <template slot-scope="scope">
              <el-input  v-model="scope.row.payFeeAmount" size="medium" placeholder="请输入" v-if="scope.row.add||scope.row.edit" @input="forceUpdate" type="number"></el-input>
              <p v-else>{{scope.row.payFeeAmount}}</p>
            </template>
        </el-table-column>
        <!-- <el-table-column prop="remark" label="费用说明" align="center" ></el-table-column> -->
        <el-table-column label="操作" align="center" v-if="[2,3].includes(state)" width="160">
          <template slot-scope="scope">
            <el-button type="primary" @click="onClickButHT(scope.row,1)" size="mini" v-if="scope.row.add">保存</el-button>
            <el-button type="primary" @click="onClickButHT(scope.row,2)" size="mini" v-if="!scope.row.add&&!scope.row.edit">修改</el-button>
            <el-button type="primary" @click="onClickButHT(scope.row,4)" size="mini" v-if="scope.row.edit">保存</el-button>
            <el-button type="primary" @click="onClickButHT(scope.row,3)" size="mini" v-if="!scope.row.add">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

        <!--办证异常-->
        <!-- <div class="ckxTitle mt20 mb15">办证异常</div>
        <el-table :data="carRuleabnolist" border class="table_top_bg" v-if="tableEidt1">
          <el-table-column prop="itemName" label="项目" align="center">
            <template slot-scope="scope">
              <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.itemName" placeholder="请选择补办项目" class="w180" size="medium"  value-key="id" @input="onMake(scope.row,scope.$index)">
                <el-option v-for="item in replaceList" :key="item.id" :label="item.itemName" :value="item"></el-option>
              </el-select>
              </el-form-item>
              <p v-else>{{scope.row.itemName}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="feeAmount" label="承担方费用（元）" align="center">
            <template slot-scope="scope">
              <el-input v-if="scope.row.add" v-model="scope.row.feeAmount" size="medium" placeholder="" readonly></el-input>
              <p v-else>{{scope.row.feeAmount}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="bearer" label="承担方" align="center">
            <template slot-scope="scope">
              <el-select v-if="scope.row.add||scope.row.edit" v-model="scope.row.bearer" size="medium" placeholder="请选择">
                <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistId" :key="item.id"></el-option>
              </el-select>
              <p v-else>{{scope.row.bearer===1?'客户':scope.row.bearer===2?'商户':scope.row.bearer===3?'车开新':scope.row.bearer===4?'办证公司':scope.row.bearer===5?'违章供应商':scope.row.bearer}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="benefit" label="受益方" align="center">
            <template slot-scope="scope">
              <el-select v-if="scope.row.add" v-model="scope.row.benefit" size="medium" placeholder="请选择" >
                <el-option :value="item.id" :label="item.name" v-for="item in whichSidelistId" :key="item.id"></el-option>
              </el-select>
              <p v-else>{{scope.row.benefit===1?'客户':scope.row.benefit===2?'商户':scope.row.benefit===3?'车开新':scope.row.benefit===4?'办证公司':scope.row.benefit===5?'违章供应商':scope.row.benefit}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="scope">
              <el-input v-if="scope.row.add||scope.row.edit" v-model="scope.row.remark" size="medium" placeholder="请输入" type="textarea"></el-input>
              <p v-else>{{scope.row.remark}}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="[2,3].includes(state)" width="160">
            <template slot-scope="scope">
              <el-button type="primary" @click="onClickBut(scope.row,2,1)" size="mini" v-if="scope.row.add">保存</el-button>
              <el-button type="primary" @click="onClickBut(scope.row,2,2)" size="mini" v-if="!scope.row.add&&!scope.row.edit">修改</el-button>
              <el-button type="primary" @click="onClickBut(scope.row,2,4)" size="mini" v-if="scope.row.edit">保存</el-button>
              <el-button type="primary" @click="onClickBut(scope.row,2,3)" size="mini" v-if="!scope.row.add">删除</el-button>
            </template>
          </el-table-column>
        </el-table> -->
        <!--暂缺项补充-->
        <div class="ckxTitle mt20 mb15">合同暂缺项补充</div>
        <el-table :data="contractFeeNowNoList" border class="table_top_bg">
          <el-table-column prop="itemName" label="项目" align="center"></el-table-column>
          <el-table-column prop="repairSend" label="补送" align="center">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.repairSend" size="medium" v-if="state!=5">
                <el-radio label="已补送">已补送</el-radio>
                <el-radio label="未补送">未补送</el-radio>
              </el-radio-group>
              <p v-else>{{scope.row.repairSend}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="feeAmount" label="费用（元）" align="center">
            <template slot-scope="scope">
              {{scope.row.repairSend==='已补送'?0:scope.row.feeAmount}}
              <!-- <el-input v-model.number="scope.row.feeAmount" size="medium" placeholder="请输入"></el-input> -->
            </template>
          </el-table-column>
          <el-table-column prop="feeBearerName" label="承担方" align="center" v-if="[2,3,4,5].includes(state)">
            <template slot-scope="scope">{{scope.row.feeBearerName?scope.row.feeBearerName:'-'}}</template>
          </el-table-column>
          <el-table-column prop="beneficiaryName" label="受益方" align="center" v-if="[2,3,4,5].includes(state)">
            <template slot-scope="scope">{{scope.row.beneficiaryName?scope.row.beneficiaryName:'-'}}</template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        </el-table>

        <!--合同缺项结算费用-->
        <div class="ckxTitle mt20 mb15">合同缺项结算费用</div>
        <el-table :data="contractFeeNoList" border class="table_top_bg">
          <el-table-column prop="itemName" label="项目" align="center"></el-table-column>
          <!-- <el-table-column prop="repairSend" label="PDA状态" align="center"></el-table-column>
          <el-table-column prop="feeAmount" label="实际状态" align="center"></el-table-column> -->
          <el-table-column prop="feeBearerName" label="承担方" align="center"></el-table-column>
          <el-table-column prop="feeAmount" label="承担方费用（元）" align="center"></el-table-column>
          <el-table-column prop="beneficiaryName" label="受益方" align="center" ></el-table-column>
          <el-table-column prop="beneficiaryAmount" label="支付受益方金额（元" align="center"></el-table-column>
        </el-table>
        <!--违章处理-->
        <div class="ckxTitle mt20 mb15">违章处理</div>
        <el-table :data="breakRuleList" border class="table_top_bg">
          <el-table-column prop="name" label="项目" align="center"></el-table-column>
          <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
          <el-table-column prop="feeBearerAmount" label="承担方金额（元）" align="center"></el-table-column>
          <el-table-column prop="beneficiaryName" label="受益方" align="center"></el-table-column>
          <el-table-column prop="beneficiaryAmount" label="受益方金额（元）" align="center" ></el-table-column>
        </el-table>
      
      <div class="footer" v-if="[1,2,3,5].includes(state)">
        <div class="btn">
          <el-button type="primary" @click="onButton(1)" v-if="[1,2,3].includes(state)">保存</el-button>

          <el-button type="primary" @click="onButton(2)" v-if="[151].includes(res.taskOverdueCheckStatus)">审核通过</el-button>
          <el-button type="primary" @click="onButton(3)" v-if="[151].includes(res.taskOverdueCheckStatus)">审核驳回</el-button>

          <el-button type="primary" @click="onButton(4)" v-if="[1].includes(state)">增加办证项</el-button>
          <el-button type="primary" @click="onButton(5)" v-if="[2,3].includes(state)">提交审核</el-button>
          <el-button type="primary" @click="onButton(6)" v-if="[2,3].includes(state)">退回代办费用确认</el-button>
          <el-button type="primary" @click="onButton(7)">返回</el-button>
        </div>
      </div>

      <el-dialog
        :title="`${modelText}`"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="700px"
      >
        <el-form
          ref="forms"
          :model="forms"
          label-width="130px"
          :rules="rules"
          :hide-required-asterisk="true"
          class="mt20" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="补办项目:" prop="name">
                <el-select v-model="forms.name" placeholder="请选择补办项目" class="w180" size="medium" v-show="!projectType" @change="onSelectRow" value-key="id">
                  <el-option v-for="item in replaceList" :key="item.id" :label="item.itemName" :value="item"></el-option>
                </el-select>
                <!-- <el-input v-model="forms.name" class="w180" size="medium" v-show="projectType"></el-input> -->
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
            <el-button type="primary" size="medium" @click="projectType=true" plain>选不到加一个</el-button> 
            </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="费用承担方:">
                <el-select disabled
                  v-model="forms.feeBearerId"
                  placeholder=""
                  class="w180"
                  size="medium">
                  <el-option v-for="item in whichSidelistId" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际费用(应付):" >
                <el-input type="number" v-model.number="forms.payFeeAmount" disabled class="w180" size="medium"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="受益方:" >
                <el-select disabled
                  v-model="forms.beneficiaryId"
                  placeholder=""
                  class="w180"
                  size="medium">
                  <el-option v-for="item in whichSidelistId" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="费用说明:">
            <el-input type="textarea" v-model="forms.remark"></el-input>
          </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="footer-modalBut"
            @click="onSubmitform('forms')"
          >提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      projectType:false,//新增补办是否要添加input
      state: 1,//1凭证及费用审核,2添加备注,3修改备注,4查看，5审核-办证逾期审核,6凭证及费用审核查看
      carRuleabnolist:[],//异常费用列表
      contractFeeBackList:[],//合同备注费用
      carOverdueList: [],//车辆逾期列表
      contractFeeNowNoList:[],//合同暂缺项列表
      querylist:[],//费用明细（车辆材料与办证列表）
      fileList: [
        { name: "产证1-2页", url: "", uploader: "", uploaderDate: "" },
        { name: "产证3-4页", url: "", uploader: "", uploaderDate: "" },
        { name: "产证5-6页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户发票", url: "", uploader: "", uploaderDate: "" },
        {
          name: "行驶证照片或临牌照片",
          url: "",
          uploader: "",
          uploaderDate: ""
        },
        { name: "其他的特殊凭证", url: "", uploader: "", uploaderDate: "" }
      ], //图片
      forms: {
        name: '',
        feeBearerId: '',
        payFeeAmount: '',
      },
      form: {
        carCustomer: {}, //	车辆客户信息
        carMerchant: {} //车辆商户信息
      }, //展示数据
      carMerchant:{},
      res:{},
      followupForm:{
        taskResult: "审核通过",
      },
      dialogVisible: false,
      modelText:'添加办证项',
      rules: {
        name: [{ required: true, message: "不能为空" }],
        feeBearerId: [{ required: true, message: "不能为空" }],
        payFeeAmount: [{ required: true, message: "不能为空" }],
      },
      rulesForm: {
        taskRemark: [{ required: true, message: "结果描述不能为空" }]
      },
      tips: "过户", //提示文字
      addlist:[],//逾期确认表（包含新增方法）
      beOverduelist:[],//逾期项
      whichSidelistId:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司',children:[]},{id:5,name:'违章供应商',children:[]}],//供应商列表, //承担方-受益方（id）
      replaceList:[],
      contractFeeNoList:[],//合同缺项列表
      materialProcedureList:[],//车辆手续补办【办证费用明细】列表
      breakRuleList:[],
      tableEidt:1,
      tableEidt1:0,
      whichSidelistSYF:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司',children:[]},{id:5,name:'违章供应商',children:[]}],//收益方去掉了车开新
      props:{
        label:'name',
        value:'id',
        children:'children'
      }
    };
  },
  created() {
    if(this.$route.query.id) this.taskId = this.$route.query.id;
    // this.whichSidelistId=this.$store.state.setting.whichSidelistId;
    this.getCertificateTaskDetails(); //获取详情
    this.postFeeStandardList(2)//获取补办项目
    this.postFeeStandardList(3)//获取逾期项目
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
        document.title=this.$route.query.title;
      })
    }
    this.postSupplierList();//获取供应商
  },
  methods: {
    forceUpdate(){
      this.$nextTick(()=>{
        this.$forceUpdate()
      })
    },
    changeSupplie(type,index){
      let node=[]
      switch(type){
        case 1:
          node=this.$refs[`refcascaderCYQ${index}`].getCheckedNodes();
        break;
        case 2:
           node=this.$refs[`refcascaderSYQ${index}`].getCheckedNodes();
        break;
        case 3:
          node=this.$refs[`refcascaderCcar${index}`].getCheckedNodes();
        break;
        case 4:
          node=this.$refs[`refcascaderScar${index}`].getCheckedNodes();
        break;
      }
      
      if(node.length>0){
        if(type===1||type===2){
          for(let i=0;i<this.carOverdueList.length;i++){
            let item=this.carOverdueList[i]
            if(i===index){
              if(type===1){
                item.bearer=node[0].path[0];
                item.bearerCertRuleSupplierId=node[0].path[1]
                item.bearerCertRuleSupplierName=node[0].pathLabels[1];
              }else{
                item.benefit=node[0].path[0];
                item.benefitCertRuleSupplierId=node[0].path[1]
                item.benefitCertRuleSupplierName=node[0].pathLabels[1];
              }
            }
          }
        }else{
          for(let i=0;i<this.materialProcedureList.length;i++){
            let item=this.materialProcedureList[i]
            if(i===index){
              if(type===3){
                item.feeBearerId=node[0].path[0];
                item.bearerCertRuleSupplierId=node[0].path[1]
                item.bearerCertRuleSupplierName=node[0].pathLabels[1];
              }else{
                item.beneficiaryId=node[0].path[0];
                item.benefitCertRuleSupplierId=node[0].path[1]
                item.benefitCertRuleSupplierName=node[0].pathLabels[1];
              }
            }
          }
        }
      }
    },
    inOver(row,index,state){
      // let arr=JSON.parse(JSON.stringify(this.carOverdueList))
      let arr=this.carOverdueList;
      for(let i=0;i<arr.length;i++){
        let item=arr[i]
        if(index===i){
          if(row.overdueDays&&item.itemName&&row.overdueDays>0){
            if(item.itemName.feeBearerId){
              item.bearerArr=[item.itemName.feeBearerId]
              item.benefitArr=[item.itemName.beneficiaryId]
              item.bearer=item.itemName.feeBearerId//承担方
              item.benefit=item.itemName.beneficiaryId//受益方
            }else{
              for(let j=0;j<this.beOverduelist.length;j++){
                let ob=this.beOverduelist[j]
                if(ob.itemName===item.itemName){
                  item.itemName=ob;
                }
              }
            }
            if(item.itemName.itemName=='商户办证逾期'||item.itemName.itemName=='商户借用证件逾期'){
              item.feeAmount=(Number(row.overdueDays)*100)+200;
            }else{
              item.feeAmount=item.itemName.payFeeAmount*Number(row.overdueDays);
            }
            item.payAmount=item.itemName.payFeeAmount*Number(row.overdueDays)//支付金额
          }else{
            if(!state){
              item.bearer=''//承担方
              item.benefit=''//受益方
              item.payAmount=''//支付金额
              item.feeAmount=''
            }else{
              item.payAmount=''//支付金额
              item.feeAmount=''
            }
            
          }
        }
      }
      this.forceUpdate()
    },
    onMake(row,index){
      for(let i=0;i<this.carRuleabnolist.length;i++){
        let item=this.carRuleabnolist[i]
        if(index===i){
          item.bearer=row.itemName.feeBearerId//承担方
          item.benefit=row.itemName.beneficiaryId//受益方
          item.feeAmount=row.itemName.chargingFeeAmount//费用
        }
      }
      this.forceUpdate()
    },
    onSelectRow(row){
      this.forms.payFeeAmount=row.payFeeAmount;
      this.forms.feeBearerId=row.feeBearerId;
      this.forms.beneficiaryId=row.beneficiaryId;
      this.forms.itemId=row.id;
      this.forms.plateType=row.plateType;
      this.form.ownerType=row.ownerType;
    },
    onButton(state){
      let _this=this;
      switch(state){
        case 1://保存
          var arr,arr1=[]
          for(let i=0;i<this.querylist.length;i++){
            let item=this.querylist[i]
            if(item.kind=='materialProcedure'){//车辆材料手续
              arr.push(item.id)
            }
            if(item.kind=='clearFee'){//办证结算费用
              arr1.push(item.id)
            }
          }
          this.postTaskOperaOverdue('overdue_save')
          break;
        case 2://审核通过
          _this.$confirm('是否审核通过?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postTaskOperaOverdue('overdue_check_success')
          }).catch(() => {});
          break;
        case 3://审核驳回
          _this.$confirm('是否审核驳回?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postTaskOperaOverdue('overdue_check_reject')
          }).catch(() => {});
          break;
        case 4://增加办证项
          this.modelText='添加办证项';
          this.ondialogVisible()
          this.projectType=false;
          this.forms={};
          this.$nextTick(()=>{
            this.$refs['forms'].clearValidate();
          })
          break;
        case 5://提交审核
          _this.$confirm('您确认办证逾期已完成，并提交审核吗？', '逾期完成提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postTaskOperaOverdue('overdue_check_apply')
          }).catch(() => {});
          break;
        case 6://退回代办费用确认
          _this.$confirm('是否退回代办费用确认？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postTaskOperaOverdue('back_voucher_waited')
          }).catch(() => {});
          break;
        case 7://返回
          this.onrouterPage()
          break;
      }
    },
    onClickButHT(row,state){
      switch(state){
        case 1://新增
          this.postProcedureMaterialAdd(row)
          break;
        case 2://修改
          for(let i=0;i<this.materialProcedureList.length;i++){
            let item=this.materialProcedureList[i]
            if(row.id===item.id) {
              item.edit=true;
              switch(item.beneficiaryId){//受益方
                case 1:
                case 2:
                case 3:
                  item.beneficiaryArr=[item.beneficiaryId]
                  break;
                case 4:
                case 5:
                  item.beneficiaryArr=[item.beneficiaryId,item.benefitCertRuleSupplierId]
                  break;
              }
              switch(item.feeBearerId){//承担方
                case 1:
                case 2:
                case 3:
                  item.feeBearerArr=[item.feeBearerId]
                  break;
                case 4:
                case 5:
                  item.feeBearerArr=[item.feeBearerId,item.bearerCertRuleSupplierId]
                  break;
              }
            }
          }
          this.$nextTick(()=>{
            this.tableEidt1=Math.random();
          })
          break;
        case 3://删除
          this.$confirm(`此操作将删除${row.name}，是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postProcedureDelete(row.id)
          }).catch(()=>{})
          break;
        case 4://保存修改
          this.postProcedureUpdate(row,row.id)
          break;
      }
    },
    postProcedureMaterialAdd(row){//新增 车辆材料手续补办
      let query={
        name:row.name,
        carId:this.form.id,
        feeBearerId:row.feeBearerId,
        chargingFeeAmount:row.chargingFeeAmount,
        beneficiaryId:row.beneficiaryId,
        payFeeAmount:row.payFeeAmount,
        bearerCertRuleSupplierId:row.bearerCertRuleSupplierId,
        bearerCertRuleSupplierName:row.bearerCertRuleSupplierName,
        benefitCertRuleSupplierId:row.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:row.benefitCertRuleSupplierName,
      }
      this.$api.postProcedureMaterialAdd(query).then(res=>{
        if(res.code===0){
          this.getCertificateTaskDetails(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    postProcedureUpdate(row,id){//更新 车辆手续补办
      let query={
        name:row.name,
        carId:this.form.id,
        feeBearerId:row.feeBearerId,
        chargingFeeAmount:row.chargingFeeAmount,
        beneficiaryId:row.beneficiaryId,
        payFeeAmount:row.payFeeAmount,
        bearerCertRuleSupplierId:row.bearerCertRuleSupplierId,
        bearerCertRuleSupplierName:row.bearerCertRuleSupplierName,
        benefitCertRuleSupplierId:row.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:row.benefitCertRuleSupplierName,
        selfDefineRemark:row.selfDefineRemark,
      }
      this.$api.postProcedureUpdate(id,query).then(res=>{
        if(res.code===0){
          this.getCertificateTaskDetails(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    postProcedureDelete(id){//车辆材料手续 删除
      this.$api.postProcedureDelete(id).then(res=>{
        if(res.code===0){
          this.getCertificateTaskDetails(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    onClickBut(row,type,state){//列表的按钮操作,type1逾期，type2办证异常
      if(type==1){//逾期
        switch(state){
          case 1://新增
            this.postOverdueExceptAdd(row,1)
            break;
          case 2://修改
            for(let i=0;i<this.carOverdueList.length;i++){
              let item=this.carOverdueList[i]
              if(row.id===item.id) {
                item.edit=true;
              }
            }
            this.$nextTick(()=>{
              this.tableEidt=Math.random();
            })
            break;
          case 3://删除
            this.$confirm(`此操作将删除${row.itemName}，是否继续?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.deleteOverdueExcept(row.id)
            }).catch(()=>{})
            break;
          case 4://保存修改
            this.postOverdueExceptUpdate(row,1,row.id)
            break;
        }
      }else{//办证异常
        switch(state){
          case 1://新增
            this.postOverdueExceptAdd(row,2)
            break;
          case 2://修改
            for(let i=0;i<this.carRuleabnolist.length;i++){
              let item=this.carRuleabnolist[i]
              if(row.id===item.id) {
                item.edit=true;
              }
            }
            this.$nextTick(()=>{
              this.tableEidt1=Math.random();
            })
            
            break;
          case 3://删除
            this.$confirm(`此操作将永久删除办证异常项${row.itemName}，是否继续?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.deleteOverdueExcept(row.id)
            }).catch(()=>{})
            break;
          case 4://保存修改
            this.postOverdueExceptUpdate(row,2,row.id)
            break;
        }
      }
    },
    onapply(row,state){//办证费用操作（车辆材料和办证项目）
      if(state==1){//修改
        this.modelText='修改办证项';
        this.forms={
          "name": row.kind=='materialProcedure'?row.name:row.itemName,//补办项目ID
          "feeBearerId": row.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
          "chargingFeeAmount": row.chargingFeeAmount,//收取费用金额
          // "beneficiaryName": "xx",//受益方
          "payFeeAmount": row.kind=='materialProcedure'?row.payFeeAmount:row.feeAmount,//支付费用金额
          certCompanyId:row.certCompanyId,//办证公司ID
        }
        this.ondialogVisible()
      }else{
        this.$confirm('确认删除，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deleteCertificateFeeClear(row.id).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
              this.getCertificateTaskDetails();//详情
            }
          })
        }).catch(()=>{})
      }
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitform(formdata) {
      //提交frorm
      this.$refs[formdata].validate(valid => {
        if (valid) {
          let query={
            certTaskId:this.taskId,
            "itemId": this.forms.itemId,//补办项目ID
            // "carId": this.form.id,
            // "plateType": this.forms.plateType,
            // "ownerType": this.forms.ownerType,
            remark:this.forms.remark,//办证公司ID
          }
          this.$api.postCertificateFeeClearAdd(query).then((res)=>{
            if(res){
              if(res.code==0){
                this.ondialogVisible(); 
                this.getCertificateTaskDetails();
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    onrouterPage() {
      this.bus.$emit("closeSelected");
      this.$router.push({
        path: "/accreditationCommissioner/certificateRemarks"
      });
    },
    onrouter(state) {
      //跳转客户商户合同
      let aurl = "/salesManager/contractMaking";
      let id=this.form.customerContract?this.form.customerContract.id:'';//客户合同
      if (state) {
        aurl = "/salesAssistant/merchantContract";
        id=this.form.merchantContract?this.form.merchantContract.id:'';//商户合同
      }
      if(!id){
        this.$message.warning('暂无合同！')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    getCertificateTaskDetails() {
      this.carOverdueList=[];
      this.carRuleabnolist=[];
      //获取详情
      let query = {
        businessId: this.taskId, //
        businessType: "certificationCompany" //业务类别
      };
      this.$api.getCertificateTaskDetails(this.taskId, query).then(res => {
        if (res) {
          if (res.code == 0) {
            //1凭证及费用审核,2添加备注,3修改备注,4查看，5审核-办证逾期审核,6凭证及费用审核查看
            switch(res.data.taskOverdueStatus){
            //taskOverdueCheckStatus办证专员-办证逾期任务状态: 逾期备注141,逾期审核142,逾期审核通过143,逾期审核驳回144
              case 144:
              case 141://逾期备注141
                this.state=2;//2
                break;
              case 142://逾期审核
                this.state=5;
                break;
              case 143://逾期审核通过143，逾期审核驳回144
                this.state=4;
                break;
            }
            // if(res.data.taskVoucherFeeCheckStatus===62){//待审核（退回操作）
            //   this.state=4;
            // }
            
            this.res=res.data;
            this.form = res.data.car;
            this.contractFeeNoList=res.data.contractFeeNoList;//合同缺项列表
            this.breakRuleList=res.data.breakRuleList;//违章信息列表
            this.contractFeeBackList=res.data.contractFeeBackList;
            // this.contractFeeBackList=[{beneficiaryId:'',beneficiaryAmount:'',id:1}]
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }else{
              this.carMerchant=res.data.car.carMerchant;
            }
             this.contractFeeNowNoList=res.data.contractFeeNowNoList?res.data.contractFeeNowNoList:[];//合同暂缺项列表
             this.carOverdueList=res.data.overdueList?res.data.overdueList:[];//逾期
            // let overdueExceptionList = res.data.overdueExceptionList;//逾期/异常列表
            // if(overdueExceptionList) for (let i = 0; i < overdueExceptionList.length; i++) {
            //   let items = overdueExceptionList[i];
            //   if (items) {
            //     if (items.type == 2) {//类型：1逾期，2异常
            //       //异常费用表
            //       this.carRuleabnolist.push(items);
            //     } else{
            //       //逾期
            //       this.carOverdueList.push(items);
            //     }
            //   }
            // }
            this.materialProcedureList=res.data.materialProcedureList?res.data.materialProcedureList:[];

            if(this.state==2){
              this.carRuleabnolist.push({add:true,payAmount:'',remark:'',overdueDays:'',itemName:'',bearer:'', feeAmount:'',benefit:''});//新增办证异常
              this.carOverdueList.push({add:true,payAmount:'',remark:'',overdueDays:'',itemName:'',bearer:'', feeAmount:'',benefit:''});//新增输入项
              this.materialProcedureList.push({add:true,payAmount:'',remark:'',overdueDays:'',itemName:'',bearer:'', feeAmount:'',benefit:''});//新增手续补办
            }
            
            
            //办证公司结算费用列表
            // let arrClear=[]
            // if(res.data.merchantSelfFeeList) for(let i=0;i<res.data.merchantSelfFeeList.length;i++){
            //   let iis=res.data.merchantSelfFeeList[i]
            //   if(iis.certComHide===0){//该条数据对办证公司是否隐藏：0否，1是, 默认0
            //     arrClear.push(iis)
            //   }
            // }
            let arrClear=res.data.merchantSelfFeeList?res.data.merchantSelfFeeList:[];
            this.querylist=[...res.data.clearFeeList?res.data.clearFeeList:[],...arrClear]//合并商户自理费用列表和办证列表
            // this.querylist=[...res.data.materialProcedureList?res.data.materialProcedureList:[],...res.data.clearFeeList?res.data.clearFeeList:[]]//合并车辆材料列表和办证列表

            let fileData = res.data.fileList;
            if (fileData) {
              for (let i = 0; i < fileData.length; i++) {
                let item = fileData[i];
                if (item) {
                  if (item.sysFileBusinessList.length > 0) {
                    let name = item.sysFileBusinessList[0];
                    let dateTime = name.operateTime
                      ? name.operateTime.split(" ")[0]
                      : name.operateTime;
                    switch (name.businessName) {
                      case "产证1-2页":
                        this.fileList[0].url = item.fullFilePath;
                        this.fileList[0].uploader = item.createBy; //上传方
                        this.fileList[0].uploaderDate = dateTime; //上传时间
                        break;
                      case "产证3-4页":
                        this.fileList[1].url = item.fullFilePath;
                        this.fileList[1].uploader = item.createBy; //上传方
                        this.fileList[1].uploaderDate = dateTime; //上传时间
                        break;
                      case "产证5-6页":
                        this.fileList[2].url = item.fullFilePath;
                        this.fileList[2].uploader = item.createBy; //上传方
                        this.fileList[2].uploaderDate = dateTime; //上传时间
                        break;
                      case "过户发票":
                        this.fileList[3].url = item.fullFilePath;
                        this.fileList[3].uploader = item.createBy; //上传方
                        this.fileList[3].uploaderDate = dateTime; //上传时间
                        break;
                      case "行驶证照片或临牌照片":
                        this.fileList[4].url = item.fullFilePath;
                        this.fileList[4].uploader = item.createBy; //上传方
                        this.fileList[4].uploaderDate = dateTime; //上传时间
                        break;
                      case "其他的特殊凭证":
                        this.fileList[5].url = item.fullFilePath;
                        this.fileList[5].uploader = item.createBy; //上传方
                        this.fileList[5].uploaderDate = dateTime; //上传时间
                        break;
                    }
                  }
                }
              }
            }
          }
        }
      });
    },
    postTaskOperaOverdue(state){//办证逾期操作【保存 overdue_save, 审核申请 overdue_check_apply, 退回费用确认 back_voucher_waited, 审核通过 overdue_check_success, 审核驳回 overdue_check_reject】
      let arr=[]
      for(let i=0;i<this.contractFeeNowNoList.length;i++){
        let item=this.contractFeeNowNoList[i]
        let obj={
          certTaskId:this.taskId,
          certContractFeeId:item.id,
          sendType:item.repairSend==='已补送'?1:item.repairSend==='未补送'?2:''
        }
        arr.push(obj)
      }
      let query={
        taskId:this.taskId,
        taskOverdueCheckResult:state,
        tempLossForms:arr,//逾期备注暂缺项补充的参数表单
      }
      this.$api.postTaskOperaOverdue(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          if(state==='overdue_check_apply'){
            this.bus.$emit("closeSelected");
            this.$router.push({
              path: "/accreditationCommissioner/certificateRemarks",
              // query:{
              //   id:this.taskId
              // }
            });
          }else if(state=='overdue_check_reject'){
            this.bus.$emit("closeSelected");
            this.$router.push({
              path: "/accreditationCommissioner/certificateRemarksDetails",
              query:{
                id:this.taskId,
                title:'办证逾期详情'
              }
            });
          }else{
            this.getCertificateTaskDetails()//详情
          }
          
        }
      })
    },
    deleteOverdueExcept(id){//办证逾期/异常 删除
      this.$api.deleteOverdueExcept(id).then((res)=>{
        if(res){
          if(res.code==0){
            this.getCertificateTaskDetails();
            this.$message.success(res.message)
          }
        }
      })
    },
    postOverdueExceptAdd(row,type){//办证逾期/异常 新增
      let query={
        "certTaskId": this.taskId,
        "itemName": row.itemName.itemName,
        "type": type,//类型：1逾期，2异常
        "overdueDays": row.overdueDays,
        "bearer": row.bearer,
        "benefit": row.benefit,
        bearerCertRuleSupplierId:row.bearerCertRuleSupplierId,
        bearerCertRuleSupplierName:row.bearerCertRuleSupplierName,
        benefitCertRuleSupplierId:row.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:row.benefitCertRuleSupplierName,
        "remark": row.remark
      }
      this.$api.postOverdueExceptAdd(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails();
          this.$message.success(res.message)
        }
      })
    },
    postFeeStandardList(types){//办证费用标准 列表查询
      this.$api.postFeeStandardList({types:[types]}).then(res=>{
        if(res.code==0){
          if(types===2){
            this.replaceList = res.data;
          }else{
            this.beOverduelist=res.data;
          }
        }
      })
    },
    // getReplaceItem() {
    //   //查询补办项目状态
    //   this.$api.getReplaceItem().then(res => {
    //     if (res) {
    //       if (res.code == 0) {
    //         this.replaceList = res.data;
    //       }
    //     }
    //   });
    // },
    postOverdueExceptUpdate(row,type,id){//办证逾期/异常 更新[不能更新状态]
      let query={
        "certTaskId": this.taskId,
        "itemName": row.itemName.itemName,
        "type": type,//类型：1逾期，2异常
        "overdueDays": row.overdueDays,
        "bearer": row.bearer,
        "benefit": row.benefit,
        bearerCertRuleSupplierId:row.bearerCertRuleSupplierId,
        bearerCertRuleSupplierName:row.bearerCertRuleSupplierName,
        benefitCertRuleSupplierId:row.benefitCertRuleSupplierId,
        benefitCertRuleSupplierName:row.benefitCertRuleSupplierName,
        "remark": row.remark
      }
      this.$api.postOverdueExceptUpdate(id,query).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails();
          this.$message.success(res.message)
        }
      })
    },
    postSupplierList(){//供应商列表
      this.$api.postSupplierList({}).then(res=>{
        if(res.code==0){
          for(let i=0;i<this.whichSidelistSYF.length;i++){
            let item=this.whichSidelistSYF[i]
            if(['办证公司','违章供应商'].includes(item.name)){
              item.children=res.data;
            }
          }
        }
      })
    },
  }
};
</script>
<style >
/* .el-textarea__inner {
  height: 100px;
} */
</style>
<style lang="scss" scoped>
.expenseAudBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.expenseAudBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 342px;
      margin: 0 27px 20px 0;
      .photo_row_imgBox {
        height: 255px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          max-height: 100%;
        }
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          line-height: 44px;
        }
      }
      .photo_row_textBox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
      .photoText{
        margin-left:-20px;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
  .inputTextMin{
    width:60px;
  }
}
</style>
