<template><!--检测报告-->
    <div>
       <div :class="noPosition?'posHeadCont':''">
         <ul class="header flex flex_align_center flex_center">
           <div class="imgBoxTopHed" v-if="imgVis">分享到：
             <el-popover
              placement="bottom"
              width="200" 
              trigger="hover">
              <div class="imgModelBox">
                <el-image :src="res.jjbXcxQrCodeUrl" fit="cover">
                   <div slot="error" class="image-slot">暂无图片</div>
                </el-image>
                <p class="mt10" v-if="res.jjbXcxQrCodeUrl">微信扫一扫分享报告</p>
              </div>
              <div class="imgBoxTop" slot="reference"></div>
            </el-popover>
             
           </div>
          <li>检测师：{{res.checkerName}}</li><li>车牌号码：{{res.carNo}}</li><li>使用性质：{{getCar('3103')}}/{{getCar('3104')}}</li><li>年份车型：{{getCar('3310')}}</li><li>经营区域：{{res.onArea}}</li>
        </ul>
        <el-tabs v-model="activeName" type="card" class="mt20">
          <el-tab-pane label="手续状态" name="first"></el-tab-pane>
          <el-tab-pane label="车辆描述" name="second"></el-tab-pane>
          <el-tab-pane label="车况检测" name="third"></el-tab-pane>
        </el-tabs>
       </div>
        <!-- <div class="greyLine"></div> -->
    <div class="main" :class="noPosition?'contPaddingTop':''">
      
     <!--手续状态--开始-->
    <template v-if="['first'].includes(activeName)">
      <el-row :gutter="24">
        <el-col class="auditInfo" :span="9">
          <!-- <p class="title mt30">审核信息</p> -->
          <table>
            <tr><td></td><td>车开新</td><td>车管所</td></tr>
            <tr><td>车辆手续状态</td><td :class="getCar('3111','color')==1?'tipsColor':''" @click="onRow('3111','车辆手续状态')">{{getCar('3111')}}</td><td>{{carManagerInfo.zt}}</td></tr>
            <tr><td>初次登记日期</td><td :class="getCar('3102','color')==1?'tipsColor':''" @click="onRow('3102','初次登记日期')">{{getCar('3102')}}</td><td>{{carManagerInfo.ccdjrq}}</td></tr>
            <tr><td>年检有效期</td><td :class="getCar('3105','color')==1?'tipsColor':''" @click="onRow('3105','年检有效期')">{{getCar('3105')}}</td><td>{{carManagerInfo.yxqz}}</td></tr>
            <tr><td>车辆类型</td><td :class="getCar('3113','color')==1?'tipsColor':''" @click="onRow('3113','车辆类型')">{{getCar('3113')}}</td><td>{{carManagerInfo.cllx}}</td></tr>
            <tr><td>使用性质</td><td :class="getCar('3104','color')==1?'tipsColor':''" @click="onRow('3104','使用性质')">{{getCar('3104')}}</td><td>{{carManagerInfo.syxz}}</td></tr>
            <tr><td>公私性质</td><td :class="getCar('3103','color')==1?'tipsColor':''" @click="onRow('3103','公私性质')">{{getCar('3103')}}</td><td>--</td></tr>
            <tr><td>生产厂家</td><td :class="getCar('3302','color')==1?'tipsColor':''" @click="onRow('3302','生产厂家')">{{getCar('3302')}}</td><td>{{carManagerInfo.zzcmc}}</td></tr>
            <tr><td>车型系列/车辆品牌</td><td :class="getCar('3303','color')==1?'tipsColor':''" @click="onRow('3303','车型系列/车辆品牌')">{{getCar('3303')}}</td><td>{{carManagerInfo.clpp}}</td></tr>
            <tr><td>发动机型号/发动机序号</td><td :class="getCar('3322','color')==1?'tipsColor':''" @click="onRow('3322','发动机型号/发动机序号')">{{getCar('3322')}}</td><td>{{carManagerInfo.fdjxh}}</td></tr>
            <!-- <tr><td>排量</td><td>--</td><td>{{carManagerInfo.pl}}</td></tr> -->
            <!-- <tr><td>乘坐人数/核定载客</td><td>--</td><td>{{carManagerInfo.hdzk}}</td></tr> -->
            <tr><td>生产年代/出厂日期</td><td :class="getCar('3304','color')==1?'tipsColor':''" @click="onRow('3304','生产年代/出厂日期')">{{getCar('3304')}}</td><td>{{carManagerInfo.ccrq}}</td></tr>
            <tr><td>车辆型号</td><td :class="getCar('3321','color')==1?'tipsColor':''" @click="onRow('3321','车辆型号')">{{getCar('3321')}}</td><td>{{carManagerInfo.clxh}}</td></tr>
            <tr><td>外观颜色</td><td :class="getCar('3306','color')==1?'tipsColor':''" @click="onRow('3306','外观颜色')">{{getCar('3306')}}</td><td>--</td></tr>
            <tr><td>产证颜色/车身颜色</td><td :class="getCar('3325','color')==1?'tipsColor':''" @click="onRow('3325','产证颜色/车身颜色')">{{getCar('3325')}}</td><td>{{carManagerInfo.csys}}</td></tr>
            <tr><td>排放标准/环保达标情况</td><td :class="getCar('3115','color')==1?'tipsColor':''" @click="onRow('3115','排放标准/环保达标情况')">{{getCar('3115')}}</td><td>{{carManagerInfo.hbdbqk}}</td></tr>
            <tr><td>表显里程</td><td class="tipsColor" @click="onRow('3201','表显里程')">{{getCar('3201')}}</td><td>--</td></tr>
          </table>
        </el-col>
        <el-col :span="15">
          <div class="procedures">
            <h3 class="title">车开新手续信息</h3>
            <table class="reportTable">
              <tr>
                <td>车牌所在地</td><td @click="onRow('3109','车牌所在地')">{{getCar('3109')}}</td>
                <td>私车车主户籍</td><td @click="onRow('3124','私车车主户籍')">{{getCar('3124')}}</td>
                <td>车辆牌照</td><td  @click="onRow('3118','车辆牌照')">{{getCar('3118')}}</td>
                <td>产证状态</td><td  @click="onRow('3117','产证状态')">{{getCar('3117')}}</td>
              </tr>
              <tr>
                <td>交强险到期日</td><td @click="onRow('3106','交强险到期日')">{{getCar('3106')}}</td>
                <td>强制报废年限</td><td @click="onRow('3114','强制报废年限')">{{getCar('3114')}}</td>
                <td>行驶证</td><td @click="onRow('3121','行驶证')">{{getCar('3121')}}</td>
                <td>沪牌额度变更</td><td @click="onRow('3129','沪牌额度变更')">{{getCar('3129')}}</td>
              </tr>
              <tr>
                <td>产证变更记录</td><td @click="onRow('3116','产证变更记录')">{{getCar('3116')}}</td>
                <td>车辆产证现属</td><td @click="onRow('3134','车辆产证现属')">{{getCar('3134')}}</td>
                <td>车辆产证曾属</td><td @click="onRow('3119','车辆产证曾属')">{{getCar('3119')}}</td>
                <td>车辆购置税</td><td @click="onRow('3137','车辆购置税')">{{getCar('3137')}}</td>
              </tr>
              <tr>
                <td>过户次数</td><td @click="onRow('3138','过户次数')">{{getCar('3138')}}</td>
                <td>办证工作日</td><td @click="onRow('3133','办证工作日')">{{getCar('3133')}}</td>
                <td>发动机舱清洗</td><td @click="onRow('3135','发动机舱清洗')">{{getCar('3135')}}</td>
                <td>是否领取解押材料</td><td @click="onRow('3136','是否领取解押材料')">{{getCar('3136')}}</td>
              </tr>
            </table>
          </div>
          <div class="vehicleM">
            <h3 class="title">车管信息</h3>
            <table class="reportTable">
              <tr>
                <td>国产进口</td><td>{{carManagerInfo.gcjk}}</td>
                <td>制造国</td><td>{{carManagerInfo.zzg}}</td>
              </tr>
              <tr>
                <td>车外廓长宽高</td><td>{{carManagerInfo.ckg}}</td>
                <td>强制报废日</td><td>{{carManagerInfo.qzbfqz}}</td>
              </tr>
              <tr>
                <td>制造厂名称</td><td>{{carManagerInfo.zzcmc}}</td>
                <td>燃料种类</td><td>{{carManagerInfo.rlzl}}</td>
              </tr>
              <tr>
                <td>功率</td><td>{{carManagerInfo.gl}}</td>
                <td>排量</td><td>{{carManagerInfo.pl}}</td>
              </tr>
              <tr>
                <td>核定载客</td><td>{{carManagerInfo.hdzk}}</td>
                <td></td><td></td>
              </tr>
            </table>
          </div>
        </el-col>
      </el-row>
      
    </template>
     <!--手续状态--结束-->
      
    <!--车辆描述--开始-->
    <template v-if="['second'].includes(activeName)">
      <div class="describe">
        <h3 class="title">车辆描述</h3>
        <table class="reportTable carDescribeBox">
          <tr>
            <td>车辆VIN码</td><td >{{$$.hideVinCode(getCar('3301'))}}</td>
            <td>生产厂家</td><td @click="onRow('3302','生产厂家')" class="w200">{{getCar('3302')}}</td>
            <td>生产年代</td><td @click="onRow('3304','生产年代')" class="w200">{{getCar('3304')}}</td>
            <td>车型代码</td><td @click="onRow('3309','车型代码')">{{getCar('3309')}}</td>
          </tr>
          <tr>
            <td>内饰颜色</td><td @click="onRow('3315','内饰颜色')">{{getCar('3315')}}</td>
            <td>原厂钥匙</td><td @click="onRow('3207','原厂钥匙')">{{getCar('3207')}}</td>
            <td>后配钥匙</td><td @click="onRow('3209','后配钥匙')">{{getCar('3209')}}</td>
            <td>备胎</td><td @click="onRow('3210','备胎')" class="tipsColor">{{getCar('3210')}}</td>
          </tr>
          <tr>
            <td>详细车型</td><td colspan="3" @click="onRow('3305','详细车型')"><span >{{getCar('3305')}}</span></td>
            <td>标准配置</td><td colspan="3" @click="onRow('3307','标准配置')"><span >{{getCar('3307')}}</span></td>
          </tr>
          <tr>
            <td>成交时可提供的充电装置</td><td colspan="3" @click="onRow('3223','成交时可提供的充电装置')">{{getCar('3223')}}</td>
            <td>补充标配</td><td colspan="3" @click="onRow('3311','补充标配')">{{getCar('3311')}}</td>
          </tr>
          <tr>
            <td>加装/改装配置</td><td colspan="3" @click="onRow('3308','加装/改装配置')">{{getCar('3308')}}</td>
            <td>选装配置</td><td colspan="3" @click="onRow('3313','选装配置')">{{getCar('3313')}}</td>
          </tr>
          <tr>
            <td>检测师报备</td><td colspan="3" ><span >{{getCar('3314')}}</span></td>
            <td>表显里程</td><td class="tipsColor" @click="onRow('3201','表显里程')" colspan="3">{{getCar('3201')}}</td>
          </tr>
        </table>
      </div>
      <div class="basicImg mt30">
        <p class="title">基本照片</p>
        <ul class="imgListBox flex">
          <li>
            <el-image :src="getPhoto('1101')[0]" :preview-src-list="getPhoto('1101')">
              <div slot="error">暂无图片</div>
            </el-image>
            <p>左前外观照</p>
          </li>
          <li>
            <div>
              <el-image :src="getPhoto('1102')[0]" :preview-src-list="getPhoto('1102')" fit="contain">
                <div slot="error">暂无图片</div>
              </el-image>
              </div>
            <p>内中内饰照</p>
          </li>
          <li>
            <div><el-image :src="getPhoto('1115')[0]" :preview-src-list="getPhoto('1115')" fit="contain">
              <div slot="error">暂无图片</div>
              </el-image></div>
            <p>右后外观照</p>
          </li>
          <li>
            <div><el-image :src="getPhoto('1116')[0]" :preview-src-list="getPhoto('1116')" fit="contain">
              <div slot="error">暂无图片</div>
              </el-image></div>
            <p>左前内饰照</p>
          </li>
          <li>
            <div><el-image :src="getPhoto('1103')[0]" :preview-src-list="getPhoto('1103')" fit="contain">
              <div slot="error">暂无图片</div>
              </el-image></div>
            <p>仪表标准照</p>
          </li>
          <li>
            <div><el-image :src="getPhoto('1117')[0]" :preview-src-list="getPhoto('1117')" fit="contain">
              <div slot="error">暂无图片</div>
              </el-image></div>
            <p>行驶证照片</p>
          </li>
          <li>
            <div><el-image :src="getPhoto('1104')[0]" :preview-src-list="getPhoto('1104')" fit="contain">
              <div slot="error">暂无图片</div>
              </el-image></div>
            <p>铭牌标准照</p>
          </li>
          <li>
            <div><el-image :src="getPhoto('1118')[0]" :preview-src-list="getPhoto('1118')" fit="contain">
              <div slot="error">暂无图片</div>
              </el-image></div>
            <p>三元催化照</p>
          </li>
        </ul>
      </div>
    </template>
    <!--车辆描述--结束-->

    <!--车况检测--开始-->
    <template v-if="['third'].includes(activeName)">
      <div class="condition">
        <h2 class="title">车况</h2>
        <h3><span class="mr20" :class="getClass('1501')" >外观</span>
          <el-select v-model="appearanceS" placeholder="请选择" class="w300" v-if="['20','23'].includes(state)||restart" @visible-change="onSelect('1501','wg')" @change="onAxiosSe(appearanceS)" value-key="subName">
            <el-option v-for="item in appearancelistS" :key="item.subName" :label="item.subName" :value="item"> </el-option>
          </el-select>
          <span v-else>{{getCar('1501')}}</span>
        </h3>
        <div class="appearance conditionBox mt20">
          <template v-if="appearancelist.length>0">
          <div class="ml20 mr20 flex" v-for="item in appearancelist" :key="item.defectItemCode">
            <div>{{item.defectItem}}：</div>
            <div>
              <div v-for="(items,index) in item.defectTypes"  :key="index" class="reportMinBox" >
                <div class="reportPT" :class="getClass('1501',items.defectDescCode)">{{items.defectDesc}}</div>
                <div v-if="items.defectDescs.length>0">
                  <div class="reportMinInt" v-for="row in items.defectDescs" :key="row.defectDescCode">
                    <p class="reportP" :class="getClass('1501',items.defectDescCode,row.defectDescCode)">-{{row.defectDesc}}</p>
                    <span class="gestureBox absoluteBox" v-if="row.images">
                      <el-image 
                        class="miniImg"
                        :src="row.images[0]" 
                        :preview-src-list="row.images">
                      </el-image>
                    </span>
                  </div>
                </div>
                <div v-if="items.images" class="gestureBox" >
                  <el-image 
                    class="miniImg"
                    :src="items.images[0]" 
                    :preview-src-list="items.images">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
          </template>
            <div v-else class="ml20 mr20">无问题</div>
        </div>
        <h3 class="mt40"><span class="mr20" :class="getClass('1502')">内饰</span>
          <el-select v-model="InteriorS" placeholder="请选择" class="w300" v-if="['20','23'].includes(state)||restart" @visible-change="onSelect('1502','ns')" value-key="subCode" @change="onAxiosSe(InteriorS)" >
            <el-option v-for="item in InteriorlistS" :key="item.subCode" :label="item.subName" :value="item"> </el-option>
          </el-select>
          <span v-else>{{getCar('1502')}}</span>
        </h3>
        <div class="appearance conditionBox mt20">
          <template v-if="Interiorlist.length>0">
           <div class="ml20 mr20 flex" v-for="item in Interiorlist" :key="item.defectItemCode">
              <div>{{item.defectItem}}：</div>
              <div>
                <div v-for="(items,index) in item.defectTypes"  :key="index" class="reportMinBox" >
                  <div class="reportPT" :class="getClass('1502',items.defectDescCode)">{{items.defectDesc}}</div>
                  <div v-if="items.defectDescs.length>0">
                    <div class="reportMinInt" v-for="row in items.defectDescs" :key="row.defectDescCode">
                      <p class="reportP" :class="getClass('1502',items.defectDescCode,row.defectDescCode)">-{{row.defectDesc}}</p>
                      <!-- <span class="gestureBox absoluteBox" v-if="row.images" @click="onPreview(row.images)">查看</span> -->
                      <span class="gestureBox absoluteBox" v-if="row.images">
                        <el-image 
                          class="miniImg"
                          :src="row.images[0]" 
                          :preview-src-list="row.images">
                        </el-image>
                      </span>
                    </div>
                  </div>
                  <div v-if="items.images" class="gestureBox" >
                    <el-image 
                      class="miniImg"
                      :src="items.images[0]" 
                      :preview-src-list="items.images">
                    </el-image>
                  </div>
                </div>
              </div>
            </div>
            </template>
            <div v-else class="ml20 mr20">无问题</div>
        </div>
        <h3 class="mt40"><span class="mr20" :class="getClass('1503')">骨架</span>
        </h3>
        <div class="appearance conditionBox mt20">
          <template v-if="skeletonlist.length>0">
          <div class="ml20 mr20 flex" v-for="item in skeletonlist" :key="item.defectItemCode">
            <div >{{item.defectItem}}：</div>
            <div>
              <div v-for="(items,index) in item.defectTypes"  :key="index" class="reportMinBox" >
                <div class="reportPT" :class="getClass('1503',items.defectDescCode)">{{items.defectDesc}}</div>
                <div v-if="items.defectDescs.length>0">
                  <div class="reportMinInt" v-for="row in items.defectDescs" :key="row.defectDescCode">
                    <p class="reportP" :class="getClass('1503',items.defectDescCode,row.defectDescCode)">-{{row.defectDesc}}</p>
                    <span class="gestureBox absoluteBox" v-if="row.images">
                      <el-image 
                          class="miniImg"
                          :src="row.images[0]" 
                          :preview-src-list="row.images">
                        </el-image>
                    </span>
                  </div>
                </div>
                <div v-if="items.images" class="gestureBox" >
                  <el-image 
                    class="miniImg"
                    :src="items.images[0]" 
                    :preview-src-list="items.images">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
          </template>
          <div v-else class="ml20 mr20">无问题</div>
        </div>
        <h3 class="mt40"><span class="mr20" :class="getClass('1504')" >装置</span>
        </h3>
        <div class="appearance conditionBox mt20">
          <template v-if="devicelist.length>0">
          <div class="ml20 mr20 flex" v-for="item in devicelist" :key="item.defectItemCode">
              <div>{{item.defectItem}}：</div>
              <div>
                <div v-for="(items,index) in item.defectTypes"  :key="index" class="reportMinBox" >
                  <div class="reportPT" :class="getClass('1504',items.defectDescCode)">{{items.defectDesc}}</div>
                  <template v-if="items.defectDescs.length>0">
                    <div class="reportMinInt" v-for="row in items.defectDescs" :key="row.defectDescCode">
                      <p class="reportP" :class="getClass('1504',items.defectDescCode,row.defectDescCode)">-{{row.defectDesc}}</p>
                      <span class="gestureBox absoluteBox" v-if="row.images">
                        <el-image 
                          class="miniImg"
                          :src="row.images[0]" 
                          :preview-src-list="row.images">
                        </el-image>
                      </span>
                    </div>
                  </template>
                  <div v-if="items.images" class="gestureBox" >
                    <el-image 
                      class="miniImg"
                      :src="items.images[0]" 
                      :preview-src-list="items.images">
                    </el-image>
                  </div>
                </div>
              </div>
            </div>
            </template>
            <div v-else class="ml20 mr20">无问题</div>
        </div>
      </div>

    
      <el-row :gutter="24" class="pb100">
        <el-col :span="12">
          <div class="flex flex_between summary mt20">
            <span class="fontBold">总结</span>
            <el-button size="mini" v-if="state==1||['20','23'].includes(state)||restart" plain type="primary" @click="onSumary">修改</el-button>
          </div>
          <div class="mt10">
            <p v-if="state==11||state!=20" class="textFont">修改前</p>
            <el-input class="mb10" type="textarea" :autosize="{ minRows: 7}" disabled v-model="summaryText" ></el-input>
            <p v-if="state==11||state!=20" class="textFont">修改后</p>
            <el-input class="mb10" type="textarea" :autosize="{ minRows: 7}" v-model="checkResult" v-show="checkResult||showSummary||state==11||state!=20" :readonly="['20','23','11'].includes(state)&&showSummary||restart&&showSummary?false:true"></el-input>
          </div>
          <div style="text-align:center" v-show="showSummary" class="mb30">
            <el-button size="mini" plain type="primary" @click="onSummaryUpdate">保存修改</el-button>
          </div>
        </el-col>
        <el-col :span="11" class="mt20">
          <h2 class="fontBold">车辆评级</h2>
          <h3 class="mt10">选择特殊车况</h3>
          <el-checkbox-group v-model="specialResult" class="mt10" @input="onSaveSpec(1)">
            <el-checkbox-button v-for="city in carConditionlist" :label="city" :key="city">{{city}}</el-checkbox-button>
          </el-checkbox-group>
          <h3 class="mt10 mb10">选择评级结果</h3>
          <el-radio-group v-model="ratingResult" class="mt10 gradeRadio" style="width:100%;" :disabled="state==3?true:false" @input="onSaveSpec(2)">
            <el-radio-button v-for="item in ratingResultslist" :key="item.value" style="display:block;margin-bottom:10px;width:100%;" :label="item.value">{{item.value}}&nbsp;{{item.label}}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </template>
    <!--车况检测--结束-->

      <el-dialog
        :title="itemRow.label?itemRow.label:'修改'"
        :visible.sync="dialogVisible"
        width="400px">
        <el-form ref="itemRow" :model="itemRow" size="medium" class="modalForm">
          <el-form-item v-for="(item,index) in resRowlist" :key="index" >
            <template v-if="resRowlist.length==1">
              <el-input v-model="itemRow.title" v-if="['0','M','Z','Y','D'].indexOf(item.dataType)!=-1||item.subAction==0" @input="itemClick(item)" :type="['详细车型','标准配置','补充标配','选装配置','加装/改装配置','检测师报备','成交时可提供的充电装置'].includes(itemRow.label)?'textarea':'text'"></el-input>
              <el-radio-group v-model="itemRow.radio" v-if="item.subAction==1" @input="radioInput(item)">
                <el-radio :label="item.subName"></el-radio>
              </el-radio-group>
              <el-checkbox-group v-model="itemRow.check" v-if="item.subAction==2&&['0','M','Z','Y','D'].indexOf(item.dataType)==-1" @input="itemClick(item)">
                <el-checkbox :label="item.subName"></el-checkbox>
              </el-checkbox-group>
            </template>
            <template v-if="resRowlist.length>1">
              <!-- 信息项数据类型。0手输串 M选择年月 D选择日期 H选择时间 X下级下拉 Y下级横排点选 Z下级竖排点选(X,Y,Z代表有子集) -->
             
              <el-radio-group v-model="itemRow.radio" v-if="item.subAction=='1'&&item.actionLevel!=9" :disabled="item.disabled" @input="radioInput(item)">
                <el-radio :label="item.subName"></el-radio>
              </el-radio-group>
              <el-checkbox-group v-model="itemRow.check" v-if="item.subAction=='2'&&itemRow.obj.item.indexOf('钥匙')==-1||item.actionLevel==9&&itemRow.obj.item.indexOf('钥匙')==-1" :disabled="item.disabled" @input="modelCheck(item,itemRow.check)">
                <el-checkbox :label="item.subName" ></el-checkbox>
              </el-checkbox-group>
               <el-input v-model="itemRow.title" v-if="['0'].includes(item.dataType)&&item.subName===itemRow.radio" :disabled="item.disabled"></el-input>
               <el-date-picker
                v-model="itemRow.title" v-if="['D','M','H'].includes(item.dataType)&&item.subName==itemRow.radio" :disabled="item.disabled"
                align="right"
                :type="['M'].includes(item.dataType)?'month':'date'"
                :value-format="['M'].includes(item.dataType)?'yyyy-MM':'yyyy-MM-dd'"
                placeholder="选择日期">
              </el-date-picker>


               <template v-if="itemRow.obj.item.indexOf('钥匙')!=-1">
                <el-checkbox-group v-model="itemRow.check"  @input="modelCheck(item,itemRow.check)" >
                <el-checkbox :label="item" v-if="item.dataType!=='0'" :disabled="item.disabled">{{item.subName}}</el-checkbox>
              </el-checkbox-group>
               <el-input v-model="itemRow.objInput.key1" v-if="item.subName==='普通钥匙'&&['Y'].includes(item.dataType)" :disabled="item.disabled" @input="forceUpdate">
                 <template slot="append">把</template>
               </el-input>
               <el-input v-model="itemRow.objInput.key2" v-if="item.subName==='遥控钥匙'&&['Y'].includes(item.dataType)" :disabled="item.disabled" @input="forceUpdate">
                 <template slot="append">把</template>
               </el-input>
               <el-input v-model="itemRow.objInput.key3" v-if="item.subName==='智能钥匙'&&['Y'].includes(item.dataType)" :disabled="item.disabled" @input="forceUpdate">
                 <template slot="append">把</template>
               </el-input>
               </template>
            </template>
            
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onUpdate">确 定</el-button>
        </span>
      </el-dialog>
    
    </div>

    <!--图片预览-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
const car=new Map();//车辆返回值（车开新数据）
const photo = new Map()//车辆图片集合
export default {
  components: { ElImageViewer },
  name: 'report',
  props:{
    res:{},
    state:{
      type:String,
      defatult:'20'
    },//2开头-车辆审核，1-车型审核，11车型查看 3其他页面过来，仅查看
    id:null,//父级id
    noPosition:{
      type:Boolean,
      defatult:true
    },
    imgVis:{//是否显示分享二维码
      type:Boolean,
      defatult:false
    }
  },
  data() {
    return {
      activeName: "first",
      grade: 0,
      appearanceS:{},
      appearancelistS:[],
      InteriorS:{},
      InteriorlistS:[],
      showSummary: false,
      carConditionlist:[],//车辆评级
      ratingResultslist:[],//车辆评级结果
      carManagerInfo:{},//车管所信息
      summaryText:'',//总结-修改前
      checkResult:'',//总结-修改后
      appearancelist:[],//外观评级数组
      Interiorlist:[],//内饰评级数组
      skeletonlist:[],//骨架数组
      devicelist:[],//装置数组
      specialResult:[],//特殊车况 事故车、改装车、车况差
      ratingResult:'',//车辆评级 C A R S I N G O K\
      dialogVisible:false,
      itemRow:{
        check:[],
        radio:'',
        title:'',
        objInput:{},
        disabled:false,
        dataType:1,//数据类型 1 直接值 2 单选 3 多选 4 缺陷
        //信息项数据类型。0手输串 M选择年月 D选择日期 H选择时间 X下级下拉 Y下级横排点选 Z下级竖排点选
        /***
         * subAction=9,其他选项不可选择  单选或多选。1-单选 2-多选 0-手输
         * 
        */
      },//单选提示内容
      resRowlist:[],//查询出来的子选择
      resRowTitle:'',
      showViewer:false,
      srcList:[],
      restart:'',//是否重拍，想要修改
      specialResultOld:[],//初始展示的数据禁止点击时使用
    }
  },
  watch:{
    res:{
      handler(res) {
        this.carManagerInfo=res.carManagerInfo?res.carManagerInfo:{};
        this.carFun(res)
        this.photoFun(res.photos)
      },
      deep: true,
    }
  },
  created() {
    this.ratingResultslist=this.$store.state.setting.ratingResultslist;
    this.carConditionlist=this.$store.state.setting.carConditionlist;
    if(this.$route.query.restart) this.restart=this.$route.query.restart;
  },
  mounted(){
    this.forceUpdate()
  },
  methods: {
    onSaveSpec(e){//自动保存数据（车辆评级和结果）
      if(this.state==3&&e===1){//1特殊车况，2评级结果
        this.specialResult=this.specialResultOld;
        return
      }
    },
    radioInput(e){
      this.itemRow.radio=e.subName;
    },
    forceUpdate(){
      this.$forceUpdate()
    },
    onAxiosSe(row){//下拉需要请求接口
      this.updateReportDetail(row.itemCode,row.subCode)//修改报告接口
    },
    carFun(res){//itemCode查询
      if(!res){
        return
      }
      let newArr=JSON.parse(JSON.stringify(res.details))
       for(let i=0,len=newArr.length;i<len;i++){
         car.set(newArr[i].itemCode,newArr[i])
       }
       this.summaryText=car.get('3312')?car.get('3312').checkResult:'--'//修改前-总结
       this.appearanceS=car.get('1501')?car.get('1501').checkResult:{};
       this.appearancelist=car.get('1501')?car.get('1501').checkDefects:[]//外观评级

       this.InteriorS=car.get('1502')?car.get('1502').checkResult:{};
       this.Interiorlist=car.get('1502')?car.get('1502').checkDefects:[]//内饰

       this.skeletonlist=car.get('1503')?car.get('1503').checkDefects:[]//骨架
       this.devicelist=car.get('1504')?car.get('1504').checkDefects:[]//装置
       this.checkResult=res.checkResult;//修改后的检测总结
      
       //特殊车况 事故车、改装车、车况差
       if(res.specialResult){
         let arrNe=res.specialResult?res.specialResult.split(','):[];
         let alrr=[]
         for(let i=0;i<arrNe.length;i++){
           if(['事故车','改装车','车况差'].includes(arrNe[i])){
             alrr.push(arrNe[i])
           }
         }
         this.specialResult=alrr;
       }else{
         let text=car.get('1505')?car.get('1505').checkResult:'';
         if(text.length==1){
           this.specialResult=[text];
         }else{
           this.specialResult=[text.slice(1,text.length)]
         }
       }
       this.specialResultOld=this.specialResult;
      //  console.log(this.specialResult,'====this.specialResult===',res.specialResult)
      //车辆评级 C A R S I N G O K
      if(res.ratingResult){
         this.ratingResult=res.ratingResult;
       }else{
         let text=car.get('1505')?car.get('1505').checkResult:'';
         if(text.length==1){
           this.ratingResult=text;
         }else{
           this.ratingResult=text.charAt(0)
         }
       }
    },
    getClass(item,pcode,ccode){//获取class颜色
      let row=car.get(item);
      if(row){
        if(pcode&&!ccode){
          for(let i=0;i<row.checkDefects.length;i++){
            let ii=row.checkDefects[i]
            for(let a=0;a<ii.defectTypes.length;a++){
              let aa=ii.defectTypes[a]
              if(aa.defectDescCode==pcode){
                return aa.color?'tipsColor':'green'
              }
            }
          }
        }else if(pcode&&ccode){
          for(let i=0;i<row.checkDefects.length;i++){
            let ii=row.checkDefects[i]
            for(let a=0;a<ii.defectTypes.length;a++){
              let aa=ii.defectTypes[a]
              if(aa.defectDescCode==pcode){
                for(let j=0;j<aa.defectDescs.length;j++){
                  if(aa.defectDescs[j].defectDescCode==ccode){
                    return aa.defectDescs[j].color?'tipsColor':'green'
                  }
                }
              }
            }
          }
        }else{
          return row.color?'tipsColor':'green'
        }
      }
    },
    photoFun(res){//图片查询
      for(let item in res){
        photo.set(item,res[item])
      }
    },
    getCar(row,type){
      if(type=='obj'){
        return car.get(row)
      }else if(type=='color'){
        return car.get(row)?car.get(row).color:''
      }else{
        // return car.get(row)?car.get(row).checkDefects
        return car.get(row)?car.get(row).checkResult:'--'
      }
    },
    getPhoto(row){
      return photo.get(row)?photo.get(row):[]
    },
    onRow(code,label){
      // this.itemRow={
      //   check:[],
      //   radio:'',
      //   title:'',
      //   objInput:{},
      //   disabled:false,
      //   dataType:1,
      // };//初始化
      /**
       * 信息项数据类型。0手输串 M选择年月 D选择日期 H选择时间 X下级下拉 Y下级横排点选 Z下级竖排点选
       * dataType值为0的时候代表是输入框，Y代表有子集
       * **/ 
      if(!['20','23','1'].includes(this.state)&&!this.restart){
        return
      }
      
      let obj=this.getCar(code,'obj')
      if(!obj){
        return
      }
      this.dialogVisible=true;
      if(obj.dataType==1){//dataType值为1的时候不需要查询接口
        let objs={
          dataType:obj.dataType,// "dataType数据类型 1 直接值 2 单选 3 多选 4 缺陷"
          itemCode:obj.itemCode,
          subAction:0,
        }
        this.resRowlist=[objs]
        if(obj.item==='VIN码'){
          this.itemRow.title=this.$$.hideVinCode(this.getCar(code))
        }else{
          this.itemRow.title=this.getCar(code)
        }
        
      }else{
        this.itemRow.check=[];
        this.itemRow.title='';
        this.getCheckItemSubsection(code)
      }
      // if(obj.dataType===3){//多选
      //   //单选和多选同时存在
      //   this.itemRow.radio=this.getCar(code)
      // }else{
      //   this.itemRow.radio=this.getCar(code)
      // }
      this.itemRow.radio=this.getCar(code)
      
      this.itemRow.itemCode=obj.itemCode;
      this.itemRow.label=label;
      this.itemRow.obj=obj;

      // console.log(this.itemRow,'---55---',obj,this.itemRow.check)
      // console.log(obj,'===this.itemRow===',this.itemRow.title)
      
    },
    onSelect(code,state){
      this.getCheckItemSubsection(code,state)
    },
    itemClick(row){
      // console.log(row,'-----rrr----')
      // itemRow.disabled
    },
    onUpdate(){
      let obj={};
      let result='';
      let objArr=[];
      /**dataType值为1的时候不需要请求接口修改**/
      if(this.itemRow.obj.dataType===1){
        result=this.itemRow.title;
      }
      for(let i=0;i<this.resRowlist.length;i++){
        let item=this.resRowlist[i]
        //单选
        if(item.subName==this.itemRow.radio){
          objArr.push(item)
        }
        if(this.itemRow.check.includes(item.subName)){
          objArr.push(item)
        }
      }
      let subTitle='';//当选择单选需要输入时使用
      let subCode='';
      let arrT=[];
      /**dataType 信息项数据类型。0手输串 M选择年月 D选择日期 H选择时间 X下级下拉 Y下级横排点选 Z下级竖排点选(X,Y,Z代表有子集)**/
      for(let i=0;i<objArr.length;i++){
        let item=objArr[i];

        if(item.subLevel=='1'){
          subCode=item.subCode;
          if(item.subName===this.itemRow.radio){
            obj=item;
          }
          if(this.itemRow.check.includes(item.subName)){
             arrT.push(item.subCode)
          }
          
        }else if(item.subLevel=='2'){
          subTitle=this.itemRow.title;
        }
      }
      // console.log(this.itemRow.check,'---ccc---',objArr)
      // console.log(obj,'===obj===',arrT,obj.subCode)
      if(this.itemRow.obj.item.indexOf('钥匙')!=-1){
          let ars=[]
          if(this.itemRow.check) for(let i=0;i<this.itemRow.check.length;i++){
            let item=this.itemRow.check[i]
            let str=''
            switch(item.subName){
              case '普通钥匙':
                str=this.itemRow.objInput.key1?`${item.subCode}-${this.itemRow.objInput.key1}把`:''
                break;
              case '遥控钥匙':
                str=this.itemRow.objInput.key2?`${item.subCode}-${this.itemRow.objInput.key2}把`:''
                break;
              case '智能钥匙':
                str=this.itemRow.objInput.key3?`${item.subCode}-${this.itemRow.objInput.key3}把`:''
                break;
                default:
                str=item.subCode
            }
            if(!str){
              this.$message.warning('选择钥匙的数量不可为空！')
              return
            }
            ars.push(str)

          result=String(ars)
        }
      }else if(subTitle){
        result=`${subCode}-${subTitle}`
      }else if(arrT.length>0&&String(obj)==='{}'){
        result=String(arrT)
      }else if(arrT.length>0&&String(obj)!=='{}'){
        if(obj.subCode){
          result=`${String(arrT)},${obj.subCode}`
        }else{
          result=String(arrT)
        }
      }else{
        if(obj.subAction==1){
          if(['Y','X','Z','D','M','H'].includes(obj.dataType)){
            result=`${obj.subCode}-${this.itemRow.title}`
          }else{
            result=obj.subCode;
          }
        }else if(obj.subAction==0){//subAction为0表示手输入，1单选，2多选
          result=`${obj.itemCode}-${obj.subCode}`
        }
      }
      // console.log(this.itemRow,'=====obj===',obj)
      // console.log(this.itemRow,'----obj=====',result)
      // console.log(this.itemRow.itemCode,'=======',result,'---submit')
      this.updateReportDetail(this.itemRow.itemCode,result)
    },
    updateReportDetail(itemCode,result){//修改报告接口
      let query={
        reportId:this.id,
        itemCode:itemCode,
        result:result
      }
      this.$api.updateReportDetail(query).then(res =>{
        if(res.code==0){
          this.dialogVisible=false;
          this.$parent.getDetails()
        }
      })
    },
    onSumary(){
      this.showSummary=true;
      this.checkResult=this.checkResult?this.checkResult:this.summaryText;
    },
    onSummaryUpdate(){
      this.showSummary=true;
      this.$emit('onSummaryUpdate',this.checkResult)
      // this.$api.updateResult(this.id,this.checkResult).then(res =>{
      //   if(res.code==0){
      //     this.$parent.getDetails()
      //   }
      // })
    },
    modelCheck(row,data){//修改弹出框判断输入值是否禁用
      if(row.actionLevel=='9'&&this.itemRow.check.length>0){//actionLevel为9时不可以选择其他选项
        for(let i=0;i<this.resRowlist.length;i++){
          let item=this.resRowlist[i]
          if(item.actionLevel!='9'){
            item.disabled=true;
          }
        }
        this.itemRow.check=[data[data.length-1]];
        this.itemRow.radio='';
      }else if(row.actionLevel=='9'){
        for(let i=0;i<this.resRowlist.length;i++){
          let item=this.resRowlist[i]
          item.disabled=false;
        }
      }
    },
    getCheckItemSubsection(itemCode,state){//获取检测子项信息
      //dataType值为1不需要查
      // this.resRowlist=[]
      this.$api.getCheckItemSubsection(itemCode).then(res =>{
        if(res.code==0){
          if(state=='wg'){
            this.appearancelistS=res.data;
          }else if(state=='ns'){
            this.InteriorlistS=res.data
          }else{
            this.resRowlist=res.data;
            //subAction为0表示手输入，1单选，2多选
            let checkResult=this.itemRow.obj.checkResult;
            // console.log(checkResult,'===checkResult===',this.itemRow)
            // console.log(this.itemRow.obj.item.indexOf('钥匙')!=-1'---555-333--')
            if(this.itemRow.obj.item.indexOf('钥匙')!=-1){
                // let checkPdaResult=this.itemRow.obj.checkPdaResult;

                let inputNameArr=[]
                let objInput={}
                if(checkResult.indexOf('把')!=-1){
                  let a=checkResult.split('把')
                  if(a.length>0){
                    for(let i=0;i<a.length;i++){
                      if(a[i]){
                        let b=a[i].split('-')
                        let c=b[0]
                        if(b[0].indexOf(',')!=-1){
                          c=b[0].split(',')[1]
                        }
                        if(c=='普通钥匙'){
                          objInput.key1=b[1]
                        }else if(c=='遥控钥匙'){
                          objInput.key2=b[1]
                        }else if(c=='智能钥匙'){
                          objInput.key3=b[1]
                        }
                        inputNameArr.push(c)
                      }
                    }
                  }
                  
                }else{
                  inputNameArr=[checkResult];
                }
                let pindArr=[];//组合数据
                for(let i=0;i<this.resRowlist.length;i++){
                  let item=this.resRowlist[i]
                  if(inputNameArr.indexOf('无')!=-1&&item.subName!='无'){
                    item.disabled=true;
                  }
                  for(let j=0;j<inputNameArr.length;j++){
                    if(item.subName===inputNameArr[j]){
                      pindArr.push(item)
                    }

                  }
                }

                this.itemRow.objInput=objInput;
                this.itemRow.check=pindArr;
                // console.log(inputNameArr,'=====^^===',pindArr)
                
            }else{
              if(checkResult){
                let marr=[]
                res.data.map(its=>{
                  if(checkResult.indexOf(',')>-1){
                    let a=checkResult.split(',')
                    for(let j=0;j<a.length;j++){
                      if(its.subName===a[j]){
                        return marr.push(its)
                      }
                    }
                  }else{
                    if(its.subName===checkResult){return marr.push(its)} 
                  }
                })
                let acheck=[];//多选使用
                this.itemRow.radio='';
                for(let a=0;a<marr.length;a++){
                  // console.log(marr[a],'===marr[a]==')
                  if(marr[a].subAction==1&&marr[a].actionLevel!=9){//单选
                    this.itemRow.radio=marr[a].subName
                  }else if(marr[a].subAction==2||marr[a].subAction==1&&marr[a].actionLevel==9){//多选(actionLevel=9为只能选一个的多选无)
                    acheck.push(marr[a].subName)
                  }
                }
                this.itemRow.check=acheck;
                // console.log(marr,'===marr====',checkResult,this.itemRow.radio)

                if(checkResult.indexOf('到期日-')!=-1){
                  let a=checkResult.split('到期日-')
                  this.itemRow.radio='到期日'
                  this.itemRow.title=a[1]
                }else if(checkResult.indexOf('-')!=-1&&checkResult.indexOf('到期日-')==-1){
                  let a=checkResult.split('-')
                  this.itemRow.radio=a[0]
                  this.itemRow.title=a[1]
                }else if(['无','正常','否'].includes(checkResult)){
                  let falg=false;
                  let arr=this.resRowlist.map(item=>{return item.subAction==1?false:true})
                  if(arr.includes(true)){
                    for(let i=0;i<this.resRowlist.length;i++){
                      let item=this.resRowlist[i]
                      if(!['无','正常','否'].includes(item.subName)){
                        item.disabled=true;
                      }
                    }
                  }
                  // this.itemRow.title='';
                }
                
              }
            }
            // console.log(this.itemRow.subAction,'===subName==',this.itemRow.subName)
            // console.log(this.itemRow,this.itemRow.dataType,'====666',this.itemRow.radio,this.itemRow.check)
          }
        }
      })
    },
    onPreview(row) {
      this.srcList=row;
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea.is-disabled .el-textarea__inner{
  color:#333333;
}
.header{
  height: 60px;
  // padding-left: 100px;
  background: #eeeeee;
  // box-shadow: 0px 0px 4px 0px rgba(125, 124, 124, 0.5);
  li{margin-right: 20px;}
  position:relative;
  .imgBoxTopHed{
    position:absolute;
    left:10px;
    top:10px;
    display:flex;
    align-items:center;
  }
  .imgBoxTop{
    position:relative;
    background:url('../../assets/images/weixin.png')100% 100% no-repeat;
    width:40px;
    height:40px;
    border-radius: 50%;
    background-color: #c1c1c1;
    background-position: center;
  }
  
}
.imgModelBox{
  margin:auto;
  p{text-align:center;}
}
.posHeadCont{
  position: fixed;
  width: calc(100% - 285px);
  z-index: 5;
  background: #ffffff;
  padding-top: 20px;
}
  // .greyLine{background: #f5f5f5; height: 20px;}
  .contPaddingTop{
    padding-top: 140px;
  }
  .main{
    .auditInfo{
      .title{
        font-weight: 700;
      }
      table{
        border-collapse: collapse;
        min-width: 360px;
        width: 100%;
        margin-top: 20px;
        td{
          border: 1px solid #ccc;
          padding: 5px 10px;
          text-align: center;
        }
        .blue{color: #0099FF;}
        .red{color: #FF0000;}
      }
    }
    .basicImg{
      flex: 1;
      .title{ font-weight: 700;margin-left:10px}
      .imgListBox{
        flex-wrap: wrap;
        li{
          width: 370px;
          height: 276px;
          position: relative;
          margin: 20px 10px 0;
         div{
            width: 370px;
            height: 232px;
            background: #D8D8D8;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              max-width: 100%;
              max-height: 100%;
            }
         }
         p{
           width: 370px;
            height: 44px;
            background: rgba(51,51,51,0.4000);
            position: absolute;
            z-index: 1;
            bottom: 0;
            text-align:center;
            color:#ffffff;
            font-size:16px;
            line-height:44px;
         }
        }
        
      }
    }
    .procedures, .vehicleM, .describe{
      margin-top: 20px;
      .title{font-weight: 700;margin-bottom: 15px;}
    }
    .reportTable{
      border-collapse: collapse;
      width: 100%;
      td{
        border: 1px solid #ccc;
        padding: 5px 10px;
        text-align: center;
        &:nth-child(2n-1){background: #eeeeee; width: 15%;}
      }
    }
    .carDescribeBox{
      td{
        &:nth-child(2n-1){width: 150px;}
      }
    }
    .condition{
      margin-top: 20px;
    .title{font-weight: 700;margin-bottom: 15px;}
    .conditionBox{
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      // line-height: 50px;
      border: 1px solid #CCCCCC;
      padding:10px 0;
    }
  }
  .mt40{margin-top: 40px;}
  ::v-deep .gradeRadio{
    .el-radio-button__inner{width: 100%;text-align: left;border-left: 1px solid #DCDFE6;border-radius: 4px;}
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{box-shadow: 1px 0 0 0 #409eff;}
  }
  .textFont{
    font-size:14px;
    margin-bottom:10px;
  }
  .imgBox{
    width:50px;
    margin:0 20px 0 10px;
  }
  .modalForm{
    max-height:500px;
    overflow:auto;
    ::v-deep .el-textarea__inner{
      min-height:120px !important;
    }
  }
  .modalForm .el-form-item{
    margin-bottom:0
  }
  }
  .reportMinBox{
    position: relative;
    display: flex;
    margin-right: 10px;
    .reportMinInt{
      position: relative;
      .absoluteBox{
        float: right;
        position: absolute;
        top: 0;
        right: -40px;
      }
       .reportP{
         margin-right: 10px;
      }
    }
  }
  .gestureBox{
    cursor:pointer;
    color:#409eff;
    margin-left: 30px;
  }
  .miniImg{
    width:25px;
    height:25px;
  }

@media (max-width: 768px) {
  .posHeadCont{
    width:896px;
  }
}
</style>
