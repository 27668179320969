<template>
  <!-- 制作客户合同 -->
  <div>
  <div class="contractMaking">
    <div class="mb20">合同编号: {{dataForm.contractNo}}</div>
    <div class="flex flex_between flex_align_center mb10">
      <div class="ckxTitle">车辆信息</div>
      <el-button type="primary" size="medium" plain @click="onRouter">报告查看>></el-button>
    </div>
    <table class="carInfo">
      <tr>
        <td>车牌号码</td><td>{{car.carNo}}
          <!-- <el-button type="primary" @click="onCarEdit" size="mini" plain class="ml20" :disabled="disabledAll">修改车牌</el-button> -->
          </td>
        <td>厂牌型号</td>
        <td>
          <el-cascader class="ww100" ref="carModel"
            v-model="form.carModel" size="medium"
            placeholder="请选择"
            :options="optionsCar"
            :props="propCar" :disabled="disabledAll"
            filterable v-if="car.seriesId"></el-cascader>
            <div class="flex" v-else>
              <el-input v-model="form.makerName" placeholder="请输入" :disabled="disabledAll" class="w160"></el-input>
              <el-input v-model="form.seriesName" placeholder="请输入" :disabled="disabledAll" class="w160"></el-input>
            </div>
        </td>
        <td>年检有效期</td><td>
          <el-date-picker
            v-model="form.checkExpire" value-format="yyyy-MM"
            type="month" placeholder="请选择" :disabled="disabledAll">
          </el-date-picker>
        </td>
        <td>车架号</td><td>
          <el-input v-model="form.vinCode" placeholder="请输入" :disabled="disabledAll"></el-input>
          </td>
      </tr>
      <tr>
        <td>颜色</td><td>
          <el-input v-model="form.carColor" placeholder="请输入" :disabled="disabledAll"></el-input></td>
        <td>发动机号</td><td>
          <el-input v-model="form.engineNo" placeholder="请输入" :disabled="disabledAll"></el-input></td>
        <td>初次登记日期</td><td>
          <el-date-picker
            v-model="form.registerDate" value-format="yyyy-MM-dd" 
            type="date"  placeholder="请选择" :disabled="disabledAll">
          </el-date-picker></td>
        <td></td><td></td>
      </tr>
    </table>
    <div class="ckxTitle mt20 mb10">成交信息</div>
    <!-- <el-row>
      <el-col :span="5">成交价：{{car.mcTradePrice?`${car.mcTradePrice}元`:'-'}}</el-col>
      <el-col :span="5">报价有效期：{{car.tradedPriceExpire}}</el-col>
      <el-col :span="5">成交类型：{{car.tradeTypeName}}</el-col>
    </el-row> -->
    <div class="mb20">
      <span class="mr40">成交价：{{dataForm.carTradedPrice?`${dataForm.carTradedPrice}元`:'-'}}</span>
      <span class="mr40">报价有效期：{{car.tradedPriceExpire}}</span>
      <span class="mr40">成交类型：{{car.tradeTypeName}}</span>
    </div>

    <template v-if="contractFailedType">
    <el-form ref="form" :model="form" :rules="rules" size="medium">
      <div class="ckxTitle mt20 mb10">车辆性质</div>
      <el-row :gutter="24">
        <el-col :span="4">车籍：{{car.carRegister}}</el-col>
        <el-col :span="5">
          <el-form-item label="公私性质：" label-width="100px">
            <el-select v-model="form.publicOrPrivate" class="inputSmall" placeholder="请选择" :disabled="disabledAll" @change="onChangePublic">
              <el-option :value="6" label="公牌"></el-option>
              <el-option :value="5" label="私牌"></el-option>
            </el-select>
          </el-form-item>
          <!-- {{car.publicOrPrivateName}} -->
        </el-col>
        <el-col :span="5">
          <el-form-item label="进口国产：" label-width="100px">
            <el-select v-model="form.inOrOut" class="inputSmall" placeholder="请选择" :disabled="disabledAll">
              <el-option :value="13" label="国产"></el-option>
              <el-option :value="15" label="进口07.10后"></el-option>
              <el-option :value="14" label="进口07.10前"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="车牌颜色：" prop="plateColor" label-width="110px" >
            <el-select v-model="form.plateColor" class="inputSmall" placeholder="请选择" :disabled="disabledAll">
              <el-option v-for="item in colorlist" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="公里数：" label-width="100px" prop="boardShowKmNums" >
            <el-input v-model="form.boardShowKmNums" class="inputSmall" placeholder="请输入" :disabled="disabledAll"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="flex flex_wrap">
           <!--沪牌非新能源显示此字段；-->
        <el-form-item label="车辆手续状态" label-width="130px" prop="carManagerZt" v-if="car.isHuPlate===1">
          <div class="flex">
            <el-input v-model="form.carManagerZt" class="inputSmall" placeholder="请点查询获取值" disabled></el-input>
            <el-button type="primary" @click="onCarSearch" size="mini" class="ml10" plain :disabled="disabledAll">查询</el-button>
          </div>
        </el-form-item>
        <el-form-item label="是否涉水车：" class="ml30 mr30" label-width="110px">
          <el-radio-group v-model="form.isWade" size="small" :disabled="disabledAll">
            <el-radio-button :label="51">非涉水车</el-radio-button>
            <el-radio-button :label="52">涉水车</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否改过公里数：" label-width="180px">
          <el-radio-group v-model="form.isChangeEvenNum" size="small" :disabled="disabledAll">
            <el-radio-button :label="61">从未更改</el-radio-button>
            <el-radio-button :label="63">不确定是否更改</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!--沪新能源车展示 -->
        <template v-if="car.isHuPlate===1&&car.isNewEnergy===82">
        <el-form-item class="ml30 mr30" label="上牌方式：" label-width="110px">
          <el-radio-group v-model="form.plateRegWay" size="small" :disabled="disabledAll">
            <el-radio-button label="新能源额度"></el-radio-button>
            <el-radio-button label="机动车额度"></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出售车辆是否可免费申领上海新能源额度：" label-width="330px">
          <el-radio-group v-model="form.applyShNewEngine" size="small" :disabled="disabledAll">
            <el-radio-button label="可以">可以</el-radio-button>
            <el-radio-button label="不可以">不可以</el-radio-button>
          </el-radio-group>
        </el-form-item>
        </template>
      </div>
      <!--垫资车会展示此版块 成交类型: 21预约成交，22垫资成交-->
      <div v-if="car.tradeType==22">
        <div class="ckxTitle mb10">复检结果</div>
        <template v-if="carValid.validStatus==3">
        <div class="mb10">
          <span class="mr40">复检检测师：{{carValid.checkerName}}</span>
          <span class="mr40">复检结果：{{carValid.hasDifference==11?'无差异':carValid.hasDifference==12?'有差异':carValid.hasDifference}}</span>
          <span class="mr40">复检时间：{{carValid.validFinishTime}}</span>
        </div>
        <div class="mb20">备注：{{carValid.remark}}</div>
        </template>
        <div v-if="carValid.validStatus!=3">{{carValid.validStatusName}}</div>
      </div>
      <!--垫资车且复检结果有差异时会展示此版块 成交类型: 21预约成交，22垫资成交 检测结果，11有差异，12无差异-->
      <div v-if="car.tradeType==22 && carValid.hasDifference==12">
        <div class="ckxTitle mt20 mb10">复检差异评定结果</div>
        <template v-if="carValid.recheckEvaluateResult">
        <div class="mb10">
          <span class="mr40">评定结果：{{carValid.recheckEvaluateResult}}</span>
        </div>
        <div class="mb20">评定备注：{{carValid.evaluateRemark}}</div>
        </template>
        <div v-if="!carValid.recheckEvaluateResult">待评定</div>
      </div>
      <!--是否渠道车辆，71：否，72是，默认71-  说明：渠道4S店客户会展示此版块 -->
      <div v-if="car.isChannelCar==72" class="mt20 mb20">
        <span class="mr40">4S店名：{{channelForm.shortName}}</span>
        <span class="mr40">拓展专员：{{channelForm.extensionerName}}</span>
      </div>
      <div class="ckxTitle mt10 mb20">车主信息</div>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="出售委托人（甲方）：" label-width="190px" prop="customerName">
            <el-input maxlength="50" v-model="form.customerName" class="lineInput formItemWidth" :disabled="disabledAll"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话：" label-width="120px" prop="customerMobile">
            <el-input v-model="form.customerMobile" class="lineInput formItemWidth" :disabled="disabledAll"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车辆持有人性质：" label-width="160px" prop="carOwnerNatureName">
            <el-select v-model="form.carOwnerNatureName" value-key="id" @change="changSelect($event,'车辆持有人性质')" :disabled="disabledAll">
              <el-option v-for="item in carOwnerNaturelist" :key="item.id" :label="item.name" :value="item" v-if="form.publicOrPrivate===6&&item.name==='公司'||form.publicOrPrivate===5&&item.name!=='公司'"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="海车集采集：" label-width="180px" :prop="car.isHuPlate===1?'seaGather':''" :key="car.isHuPlate" v-if="car.isHuPlate===1">
            <el-radio-group v-model="form.seaGather" @change="changSelect($event,'海车集采集')" size="small" :disabled="disabledAll">
              <el-radio-button label="已录"></el-radio-button>
              <el-radio-button label="未录" ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证刷验：" label-width="120px" v-if="car.isHuPlate===1">
            <el-radio-group v-model="form.idFaceValid" @change="changSelect($event,'身份证刷验')" size="small" :disabled="disabledAll">
              <el-radio-button label="已刷"></el-radio-button>
              <el-radio-button label="未刷" ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- 沪牌车需要展示 -->
        <!--isHuPlate-  是否沪牌，1是,2否，默认1 -->
        <el-col :span="8" v-if="car.isHuPlate==1">
          <el-form-item label="车主证件复印件：" label-width="160px" prop="idCopy">
            <el-radio-group v-model="form.idCopy" size="small" :disabled="disabledAll">
              <el-radio-button label="有"></el-radio-button>
              <el-radio-button label="无" ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!--是否渠道车辆，71：否，72是，默认71-  说明：门店客户展示此版块 -->
      <template v-if="car.isChannelCar==71">
        <div  class="ckxTitle mt10 mb20">银行信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="收款账号户名：" prop="accountName">
              <el-input v-model="form.accountName" class="lineInput formItemWidth" :disabled="disabledAll" @input="changeUpdate($event)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账号：" prop="account">
              <el-input v-model="form.account" @input="changeUpdate($event)" class="lineInput formItemWidth" :disabled="disabledAll"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="银行简称：" prop="depositBankShortObj">
              <el-select
                v-model="form.depositBankShortObj"
                filterable :disabled="disabledAll"
                placeholder="请输入关键词" @input="onChangBank($event)" value-key="id">
                <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--是否渠道车辆，71：否，72是，默认71-  说明：门店客户展示此版块 -->
        <el-row :gutter="20" v-if="car.isChannelCar==71">
          <el-col :span="20" class="flex">
            <el-form-item label="开户行：" label-width="100px" class="mr10" >
              <el-cascader
                v-model="addressArr" ref="address"
                :options="optionsAddress" :props="propsAddress" @change="changeAddress" :disabled="disabledAll" clearable></el-cascader>
            </el-form-item>
            <el-form-item label="" class="bankULBoxConter" prop="depositBank">
              <el-input v-model.trim="form.depositBank" class="lineInput  bankInput" :disabled="disabledAll" @input="onInputBank" placeholder="请输入开户行银行名称"></el-input>
              <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
                <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item)">{{item.bankName}}</li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <!--是否渠道车辆，71：否，72是，默认71-  说明：渠道4S店客户会展示此版块 -->
      <template v-if="car.isChannelCar==72">
        <div class="ckxTitle mt10 mb20">银行信息</div>
        <!--是否渠道车辆，71：否，72是，默认71-  说明：渠道4S店客户会展示此版块 -->
        <!-- <el-form-item label="银行账号："  prop="account">
          <el-select v-model="form.account" value-key="id" @change="changSelect($event,'bank')">
            <el-option v-for="item in accountList" :key="item.id" :label="item.account" :value="item"></el-option>
          </el-select>
        </el-form-item> -->
        <div class="mt10 flex">
          <span class="mr40">收款账号户名：{{channelAccount.accountName}}</span>
          <span class="mr40">银行简称：{{channelAccount.depositBankShortName}}</span>
          <span class="mr40">账号：{{channelAccount.account}}</span>
          <span class="mr40">开户行：{{channelAccount.depositBank}}</span>
        </div>
      </template>
      <div class="ckxTitle mb10 mt20">过户手续约定及车辆、过户材料交接表</div>
      <!-- 车主证件 idcardlist-->
      <table class="ownerCertificate">
        <tr>
          <td>车主证件</td><td>状态</td><td>费用</td><td>押金</td><td>费用承担方</td><td>备注</td>
        </tr>
        <tr v-for="(item,index) in idcardlist" :key="`idcar_${index}`">
          <td><i class="tipsColor" >*</i>{{item.materialName}}</td><!--车主证件-->
          <td><!--状态(实际情况)-->
            <div class="statusWrap" v-if="item.materialName.indexOf('居住证')==-1&&item.materialName.indexOf('盖章材料')==-1">
              <el-radio-group v-model="item.realData1" @input="changeUpdate($event,item)" :disabled="disabledAll">
                <el-radio label="有">有</el-radio>
                <el-radio label="暂缺">暂缺</el-radio>
              </el-radio-group>
              <div class="flex flex_align_center mt10"><span>证件号码：</span><el-input v-model="item.realData2" placeholder="请输入内容" style="width:220px;" class="lineInput" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-input></div>
              <div class="mt10" v-if="['暂缺'].includes(item.realData1)">提供日期：<el-date-picker v-model="item.realData3" type="date" placeholder="选择日期" class="lineInput " value-format="yyyy-MM-dd" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-date-picker></div>
            </div>
            <div class="statusWrap" v-if="item.materialName.indexOf('居住证')!=-1||item.materialName.indexOf('盖章材料')!=-1">
              <el-radio-group v-model="item.realData1" @input="changeUpdate($event,item)" :disabled="disabledAll">
                <el-radio label="有">有</el-radio>
                <el-radio label="暂缺">暂缺</el-radio>
              </el-radio-group>
              <div class="mt10" v-if="['暂缺'].includes(item.realData1)">提供日期：<el-date-picker v-model="item.realData2" type="date" placeholder="选择日期" class="lineInput" value-format="yyyy-MM-dd" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-date-picker></div>
            </div>
            <!-- <div class="statusWrap" v-if="item.materialName.indexOf('盖章材料')!=-1">
              <el-radio-group v-model="item.realData1" @input="changeUpdate($event,item)" :disabled="disabledAll">
                <el-radio label="有">有</el-radio>
                <el-radio label="暂缺">暂缺</el-radio>
              </el-radio-group>
            </div> -->
          </td>
          <td>{{item.feeAmount?`${item.feeAmount}元`:'-'}}</td><td>{{item.depositAmount?`${item.depositAmount}元`:'-'}}</td><td>{{item.feeBearerName?item.feeBearerName:'-'}}</td>
          <td :rowspan="idcardlist.length+1" 
          v-if="index==0"><!--备注-->
            <div class="flex flex_align_center">
              <el-checkbox v-model="item.remark1" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-checkbox>
              <el-date-picker class="ml20 mr20 lineInput " v-model="item.remark2" type="daterange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" @input="changeUpdate($event,item)" :disabled="disabledAll==false&&item.remark1===true?false:true"></el-date-picker>
              <span>甲方无法提供证件</span>
            </div>
            <div class="flex flex_align_center mt20">
              <el-checkbox v-model="item.remark3" @input="changeUpdate($event)" :disabled="disabledAll"></el-checkbox>
              <el-date-picker class="ml20 mr20 lineInput" v-model="item.remark4" type="date" value-format="yyyy-MM-dd" @input="changeUpdate($event,item)" placeholder="选择日期" :disabled="disabledAll==false&&item.remark3===true?false:true"></el-date-picker>
              <span>乙方须将证件交回甲方</span>
            </div>
          </td>
        </tr>
        <tr><td :colspan="idcardlist.length>0?5:6">暂缺项条款：自该委托书约定送达日起每延迟一个工作日交予乙方，甲方支付乙方相应逾期补偿金人民币100元/工作日，延长相应工作日</td></tr>
      </table>
      <!-- 项目名称 -->
      <table class="carGoods">
        <tr>
          <td>项目名称</td><td>PDA 采集数据</td><td>实际情况</td><td>选项</td><td>差异选项</td><td>费用</td><td>押金</td><td>费用承担方</td><td>补办要求</td>
        </tr>
        <!-- 需要询问4S点费用，只有“沪电动车牌”会体现这个字段 -->
        <!--isHuPlate是否沪牌，1是,2否，默认1,isNewEnergy是否新能源车，81否，82是,默认81-->
        <tr v-for="(item,index) in carMaterialsList" :key="`mate_${index}`">
          <td><i class="tipsColor" v-if="['车辆牌照','机动车行驶证','机动车登记证书'].includes(item.materialName)">*</i>{{item.materialName}}</td>
          <td>{{item.pdaData}}</td>
          <td><!--实际情况-->
            <div v-if="item.materialName.indexOf('钥匙')>-1">
              <span>有</span>
              <el-input class="ml10 mr10 inputNum" v-model="item.realData" type="number" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-input>
              <span>把</span>
            </div>
            <div v-if="item.materialName.indexOf('钥匙')==-1&&item.materialName.indexOf('税')==-1&&item.materialName.indexOf('年检')==-1&&item.materialName.indexOf('违章')==-1&&item.materialName.indexOf('变更审核单')==-1">
              <el-select v-model="item.realData" class="inputSelect2" size="medium" @change="changeDem(item)" :disabled="disabledAll" clearable>
                <el-option v-for="itemc in $store.state.setting.contractGoods" :key="itemc.value" :label="itemc.label" :value="itemc.label"></el-option>
                <el-option label="无" value="无" v-if="['桩充','线充','备胎'].includes(item.materialName)"></el-option>
              </el-select>
            </div>
            <div v-if="item.materialName.indexOf('税')>-1">
              <el-select v-model="item.realData" class="inputSelect2" size="medium" :disabled="disabledAll" @change="changeDem(item)" clearable>
                <el-option label="已完税" value="已完税"></el-option>
                <el-option label="免税" value="免税"></el-option>
                <el-option label="不确定" value="不确定"></el-option>
              </el-select>
            </div>
            <div v-if="item.materialName.indexOf('年检')>-1">
              <el-select v-model="item.realData" class="inputSelect2" placeholder="请选择" size="medium" :disabled="disabledAll" clearable>
                <el-option label="未过期" value="未过期"></el-option>
                <el-option label="过期" value="过期"></el-option>
                <el-option label="当月到期" value="当月到期"></el-option>
              </el-select>
            </div>
            <div v-if="item.materialName.indexOf('违章')>-1">
              <el-select v-model="item.realData" class="inputSelect2" size="medium" :disabled="disabledAll" clearable>
                <el-option v-for="itemc in $store.state.setting.violation" :key="itemc.value" :label="itemc.label" :value="itemc.label"></el-option>
              </el-select>
            </div>
            <div v-if="item.materialName.indexOf('变更审核单')>-1">
              <el-select v-model="item.realData" class="inputSelect2" size="medium" :disabled="disabledAll" @change="onRealFun(item)" clearable>
                <el-option label="无需变更" value="无需变更"></el-option>
                <el-option label="有" value="有"></el-option>
                <el-option label="暂缺" value="暂缺"></el-option>
              </el-select>
            </div>
          </td>
          <td :colspan="item.materialName.indexOf('违章')>-1?2:0"><!--选项-->
            <div v-if="item.materialName.indexOf('钥匙')>-1">
              <div class="mb20 alignLeft">
                <span class="spanwidth">缺</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options1" type="number" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll==false&&item.options2>0?true:false||disabledAll"></el-input>
                <span>把</span>
              </div>
              <div class="alignLeft">
                <span class="spanwidth">暂缺</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options2" type="number" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll==false&&item.options1>0?true:false||disabledAll"></el-input>
                <span>把</span>
                <el-date-picker class="ml20 mr10 inputDate lineInput dateInput" :disabled="disabledAll==false&&item.options1>0?true:false||disabledAll" v-model="item.options3" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" size="medium" @input="changeUpdate($event,item)" ></el-date-picker>
                <span>客户提供</span>
              </div>
            </div>
            <div class="textleft" 
            v-if="['桩充','线充','备胎'].includes(item.materialName)&&['暂缺'].indexOf(item.realData)!=-1&&['有'].includes(item.pdaData)||
            item.materialName.indexOf('审核单')>-1&&['暂缺'].indexOf(item.realData)!=-1||
            ['车辆牌照'].includes(item.materialName)&&['暂缺'].indexOf(item.realData)!=-1">
              <el-date-picker class="mr10 inputDate lineInput dateInput" v-model="item.options" type="date"  value-format="yyyy-MM-dd" placeholder="选择日期" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-date-picker>
              <span>客户提供</span>
            </div>
            <div v-if="['机动车登记证书','机动车行驶证'].includes(item.materialName)"><!-- ['有'].indexOf(item.pdaData)!=-1这个地方不许PDA的数据做判断--->
              <div v-if="['有'].indexOf(item.realData)!=-1">
                <div class="alignLeft">
                  <el-radio-group v-model="item.options1" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll">
                    <el-radio label="正常">正常</el-radio>
                    <el-radio label="需变更">需变更</el-radio>
                    <el-radio label="如需变更">如需变更</el-radio>
                  </el-radio-group>
                </div>
                <div class="mt10 alignLeft" v-if="['机动车登记证书'].includes(item.materialName)&&car.isHuPlate===1">
                  <span>产证编号：</span>
                  <el-input maxlength="12" type="number" v-model="item.options2" class="inputDate lineInput" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-input>
                </div>
                <div class="flex mt10" v-if="['需变更','如需变更'].includes(item.options1)">
                  <span>变更内容：</span>
                  <el-input maxlength="100" class="inputText" v-model="item.options3" type="textarea" :autosize="{minRows: 2}" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-input>
                </div>
              </div >
              <el-checkbox v-if="['缺'].indexOf(item.realData)!=-1" v-model="item.options4" @input="changeUpdate($event,item)" label="补办">补办</el-checkbox>
              <div v-if="['暂缺'].indexOf(item.realData)!=-1">
                 <el-date-picker class="mr10 inputDate lineInput dateInput" v-model="item.options5" type="date"  value-format="yyyy-MM-dd" placeholder="选择日期" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-date-picker>
                <span>客户提供</span>
              </div>
            </div>
            <div class="textleft" v-if="item.materialName.indexOf('保险单')>-1&&['有'].indexOf(item.realData)!=-1">
              <el-checkbox-group v-model="item.options1" class="size16" @input="changeUpdate($event,item)" :disabled="disabledAll">
                <el-checkbox label="正本">正本</el-checkbox>
                <el-checkbox label="副本">副本</el-checkbox>
                <el-checkbox label="电子保单">电子保单</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="textleft" v-if="item.materialName.indexOf('违章')>-1&&['无'].indexOf(item.realData)==-1">
              <div class="mb10 alignLeft" v-if="item.realData&&item.realData!='无'">
                <el-date-picker class="mr10 inputDate" v-model="item.options1" type="date" placeholder="选择日期" size="medium" @input="changeUpdate($event,item)" value-format="yyyy-MM-dd" :disabled="disabledAll"></el-date-picker>
                <span>前的</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options2" @input="changeUpdate($event,item)" type="number" size="medium" :disabled="disabledAll"></el-input><span>次</span>
              </div>
              <div class="alignLeft" v-if="item.realData&&item.realData!='无'">
                <el-date-picker class="mr10 inputDate" v-model="item.options3" type="date" placeholder="选择日期" size="medium" @input="changeUpdate($event,item)" value-format="yyyy-MM-dd" :disabled="disabledAll"></el-date-picker>
                <span>处理完毕</span>
              </div>
              <div class="alignLeft mt10"  v-if="item.realData=='代办'">
                <span>违章本金</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options4" type="number" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-input>
                <span class="mr40">元</span>
                <span>驾照扣分</span>
                <el-input class="ml10 mr10 inputNum" v-model="item.options5" type="number" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll"></el-input>
                <span>分</span>
              </div>
              <!-- <div class="mt10">
                <el-checkbox v-model="item.options6" v-if="item.options2>=1" @input="changeUpdate($event,item)">一项超过12分</el-checkbox>
              </div> -->
            </div>
          </td>
          <td v-if="item.materialName.indexOf('违章')==-1"><!--差异选项--> 
            <!-- 车辆牌照、机动车登记证、行驶证选择“缺”时出现 -->
            <!-- 车辆牌照(item.realData=1缺)、机动车登记证(goodsValue5=1缺)、行驶证(goodsValue6=1缺)选择“缺”时出现-->
            <div class="alignLeft" v-if="
              ['机动车登记证书','车辆牌照','机动车行驶证'].indexOf(item.materialName)>-1&&['缺'].indexOf(item.realData)!=-1">
              <el-radio-group v-model="item.differenceOptions" @input="changeDem(item)" :disabled="disabledAll">
                <el-radio label="车开新原因">车开新原因</el-radio>
                <el-radio label="客户原因">客户原因</el-radio>
              </el-radio-group>
            </div>
          </td>
          <td><!--费用-->
            <div v-if="['桩充','线充','备胎'].includes(item.materialName)&&['缺'].indexOf(item.realData)!=-1&&['有'].includes(item.pdaData)||item.materialName.indexOf('钥匙')>-1&&item.options1>0">
              <div class="alignLeft">
                <el-input class="ml10 mr10 inputNum" v-model="item.feeAmount" type="number" size="medium" :disabled="disabledAll"></el-input>
                <span>元</span>
              </div>
              <div class="mt10 alignLeft">（4S店报价）</div>
            </div>
            <div v-if="
              ['车辆购置税'].includes(item.materialName)&&['免税'].indexOf(item.realData)!=-1||
              ['机动车交通事故责任强制保险单'].includes(item.materialName)&&['缺'].indexOf(item.realData)!=-1||
              ['年检'].includes(item.materialName)&&['过期'].indexOf(item.realData)!=-1||
              ['违章'].includes(item.materialName)&&['代办'].indexOf(item.realData)!=-1">
              <div class="alignLeft" >
                <el-input class="mr10 inputNum" v-model="item.feeAmount" type="number" size="medium" :disabled="disabledAll"></el-input>
                <span>元</span>
              </div>
              <div class="mt10 alignLeft" v-if="item.materialName.indexOf('违章')==-1">按实结算</div>
            </div>
            <div v-if="['机动车登记证书','机动车行驶证'].indexOf(item.materialName)>-1&&['需变更'].includes(item.options1)&&['有'].includes(item.realData)||
              ['机动车登记证书','机动车行驶证'].indexOf(item.materialName)>-1&&['缺'].includes(item.realData)||
              ['车辆牌照'].indexOf(item.materialName)>-1&&item.realData=='缺'">{{item.feeAmount}}元</div>
          </td>
          <td><!--押金-->
            <div v-if="['桩充','线充','备胎'].includes(item.materialName)&&['暂缺'].indexOf(item.realData)!=-1&&['有'].includes(item.pdaData)||item.materialName.indexOf('钥匙')>-1&&item.options2>0">
              <div class="alignLeft">
                <el-input class="ml10 mr10 inputNum" v-model="item.depositAmount" type="number" size="medium" :disabled="disabledAll"></el-input>
                <span>元</span>
              </div>
              <div class="mt10 alignLeft">（4S店报价）</div>
            </div>
            <div v-if="
              ['车辆购置税'].includes(item.materialName)&&['不确定'].indexOf(item.realData)!=-1||
              ['机动车交通事故责任强制保险单'].includes(item.materialName)&&['暂缺'].indexOf(item.realData)!=-1||
              ['违章'].includes(item.materialName)&&['客户自行处理'].indexOf(item.realData)!=-1">
              <div class="alignLeft">
                <el-input class="mr10 inputNum" v-model="item.depositAmount" type="number" size="medium" :disabled="disabledAll"></el-input>
                <span>元</span>
              </div>
              <div class="mt10 alignLeft" v-if="item.materialName.indexOf('违章')==-1">按实结算</div>
            </div>
            <div v-if="['机动车登记证书','机动车行驶证'].indexOf(item.materialName)>-1&&['如需变更'].includes(item.options1)&&['有'].includes(item.realData)||
              ['机动车登记证书','机动车行驶证'].indexOf(item.materialName)>-1&&['暂缺'].includes(item.realData)||
              ['车辆牌照'].indexOf(item.materialName)>-1&&item.realData=='暂缺'">{{item.depositAmount}}元</div>
          </td>
          <td><!--费用承担方-->
            <!--只有出现暂缺，缺就需要展示费用承担方-->
            <el-select class="inputSelect2" v-model="item.feeBearerNameObj" value-key="id" size="medium" 
            v-if="['缺','暂缺'].includes(item.realData)&&['备胎','线充','桩充'].includes(item.materialName)&&['有'].includes(item.pdaData)||
            ['缺','暂缺','代办','客户自行处理','过期','免税','不确定'].includes(item.realData)&&['备胎','线充','桩充'].indexOf(item.materialName)==-1||
            ['需变更','如需变更'].includes(item.options1)&&['机动车登记证书','机动车行驶证'].indexOf(item.materialName)!=-1||
            item.materialName.indexOf('钥匙')>-1&&item.options1||
            item.materialName.indexOf('钥匙')>-1&&item.options2" :disabled="disabledAll" @input="changeUpdate($event)" @change="onfeeSelect(item)">
              <el-option v-for="itemc in rolelist" :key="itemc.id" :label="itemc.name" :value="itemc"></el-option>
            </el-select>
          </td>
          <td><!--补办要求-->
            <el-select class="inputSelect2" v-model="item.nature" size="medium" clearable
            v-if="['车辆牌照','机动车登记证书','机动车行驶证','车辆购置税'].includes(item.materialName)" :disabled="disabledAll" @input="changeUpdate($event)">
              <el-option label="补办" value="补办"></el-option>
              <el-option v-if="!['车辆牌照','车辆购置税'].includes(item.materialName)" label="变更" value="变更"></el-option>
              <el-option v-if="['机动车登记证书','机动车行驶证'].includes(item.materialName)" label="如需变更" value="如需变更"></el-option>
            </el-select>
          </td>
        </tr>
      </table>
      <!-- 车辆手续 carlistPro-->
      <table class="carProcedures">
        <tr>
          <td>车辆手续</td><td>状态</td><td>费用</td><td>费用承担方</td><td>备注</td><td>补办要求</td>
        </tr>
        <template v-for="(item,index) in carlistPro">
        <tr  :key="`pro_${index}`" v-if="item.materialName.indexOf('额度变更')>-1&&limitFlag||item.materialName.indexOf('额度变更')==-1">
          <td><!--车辆手续-->
            <div v-if="item.materialName.indexOf('额度变更')==-1&&item.materialName.indexOf('备注')==-1">{{item.materialName}}</div>
            <div v-if="item.materialName.indexOf('额度变更')!=-1">
              <div class="alignLeft">额度变更</div>
              <div class="alignLeft mt10" style="width:300px;">
                <el-radio-group v-model="item.materialName1" size="medium" @input="changeUpdate($event,item)" :disabled="disabledAll">
                  <el-radio :label="0">父母与子女</el-radio>
                  <el-radio :label="1">夫妻变更</el-radio>
                  <el-radio :label="2">公司抬头变更</el-radio>
                  <!-- <el-radio :label="3">公司合并或拆分</el-radio> -->
                </el-radio-group>
              </div>
            </div>
            <div v-if="item.materialName.indexOf('备注')!=-1">
              <span>{{item.materialName}}</span>: 
              <el-input class="ml10 lineInput formItemWidth" v-model="item.selfDefineRemark" size="medium" :disabled="disabledAll==false&&item.realData===true?false:true"></el-input>
            </div>
          </td>
          <td><!--状态(实际情况)-->
            <el-checkbox v-model="item.realData" @change="checkBoxChange(item,item.realData)" :disabled="disabledAll">{{item.materialName.indexOf('备注')==-1?'是':''}}</el-checkbox>
          </td>
          <td><!--费用-->
            <div>
              <el-input class="ml10 mr10 inputNum lineInput" v-model="item.feeAmount" type="number" size="medium" :disabled="item.materialName.indexOf('备注')!=-1&&item.realData===true||item.materialName.indexOf('办证加急')!=-1&&disabledAll==false&&car.isHuPlate===2&&item.realData===true?false:true" ></el-input>
              <span>元</span>
            </div>
          </td>
          <td><!--费用承担方-->
            <el-select class="inputSelect2" v-model="item.feeBearerNameObj" size="medium" value-key="id" @input="changeUpdate($event,item)" :disabled="disabledAll==false&&item.realData===true?false:true" @change="onfeeSelect(item)">
              <el-option v-for="item in rolelist" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
          </td>
          <td>{{item.remark}}</td><!--备注-->
          <td><!--补办要求-->
            <el-select class="inputSelect2" v-model="item.nature" size="medium" clearable
            v-if="item.materialName.indexOf('办证加急')==-1" :disabled="disabledAll==false&&item.realData===true?false:true" @input="changeUpdate($event)">
              <el-option v-if="item.materialName.indexOf('额度变更')==-1" label="补办" value="补办"></el-option>
              <el-option label="变更" value="变更"></el-option>
            </el-select>
          </td>
        </tr>
        </template>
      </table>
      <div class="ckxTitle mt20 mb10">过户凭证特殊要求</div>
      <div class="checkList2">
        <!-- <el-radio-group v-model="checkForm.radio" v-if="radiolist.length>0" @input="changeUpdate($event)" :disabled="disabledAll">
          <el-radio v-for="(itemc,index) in radiolist" :key="`radio_${index}`" :label="itemc.id">{{itemc.requireName}}</el-radio>
        </el-radio-group> -->
        <el-checkbox-group v-model="checkedList2" class="mt10" :disabled="disabledAll">
          <el-checkbox v-for="itemc in checkList2" :label="itemc.id" :key="`c2_${itemc.id}`">{{itemc.requireName}}
             <el-input size="medium" v-if="['交易发票指定金额'].includes(itemc.requireName)" class="w150" v-model="itemc.bak" :disabled="disabledAll===false&&checkedList2.includes(itemc.id)?false:true" ></el-input>
          </el-checkbox>
         
        </el-checkbox-group>
        <el-checkbox v-for="(itemc,index) in customChecklist" :key="`check_${index}`" v-model="itemc.isSelected" :disabled="disabledAll">
          <el-input size="medium" v-model="itemc.requireName" :disabled="disabledAll===false&&itemc.isSelected?false:true"></el-input>
        </el-checkbox>
      </div>
      <div class="ckxTitle mt20 mb10">其他约定事项：</div>
      <div class="flex flex_wrap flex_start fontBoxline" v-if="car.isPledge===42">
        <span class="lineSpan">{{covenantsStr1}}</span>
        <el-form-item label="" prop="covenantsStr2" class="lineItemForm">
          <el-date-picker
            v-model="form.covenantsStr2" value-format="yyyy-MM-dd"
            type="date" size="mini" class="lineInput"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <span class="lineSpan">{{covenantsStr3}}</span>
      </div>
      <el-input type="textarea" v-model="covenants" :autosize="{minRows: 4}" size="medium" :disabled="disabledAll" v-else></el-input>
      <div class="ckxTitle mt20 mb10">材料取回约定表（请✔️勾选确认）</div>
      <div>
        <el-checkbox-group v-model="checkedList" :disabled="disabledAll">
          <el-checkbox class="checkboxMar" v-for="item in checkList" :label="item.id" :key="`c_${item.id}`">{{item.requireName}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div>
        <el-form-item label="取回方式：" label-width="100px">
          <el-radio-group v-model="dataForm.getBack" size="small" :disabled="disabledAll">
            <el-radio-button label="甲方取回"></el-radio-button>
            <!-- <el-radio-button label="委托乙方代办托寄" ></el-radio-button> -->
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="flex flex_align_center">
        <div class="ckxTitle">委托出售价格</div>
        <!-- <el-radio-group v-model="dataForm.calcType" size="small" class="ml20" :disabled="disabledAll" @input="onAutooper(dataForm.calcType)">
            <el-radio-button label="autoCalc" >自动计算</el-radio-button>
            <el-radio-button label="fullCarPay" >全押车款</el-radio-button>
        </el-radio-group> -->
        <el-button type="parmary" size="medium" plain class="ml20" @click="onAutooper('autoCalc')" :disabled="disabledAll">自动计算</el-button>
        <el-button type="parmary" size="medium" plain class="ml20" @click="onAutooper('fullCarPay')" :disabled="disabledAll">全押车款</el-button>
      </div>
      <div class="mt10">
        <span>乙方支付的二手车款人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.totalPayAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.totalPayAmount" disabled size="medium"></el-input><span>）</span>
        <span class="tipsColor setFontSize18" v-if="payForm.totalPayAmount>=1000000">金额不可以大于100万</span>
      </div>
      <div class="mt10">明细如下：</div>
      <div class="mt10">
        <span>1. 该车委托出售价格为人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.carTradedPriceCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.carTradedPrice" disabled size="medium"></el-input><span>）（不带牌）</span>
      </div>
      <div class="mt10">
        <span>2. 如该车发生补缴、违章、遗失补证、随车附件缺少等业务且甲方委托乙方办理的，则甲方支付乙方办证押金人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.feeDepositTotalAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.feeDepositTotalAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10">
        <span>3. 甲方支付乙方办证风险押金人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.riskDepositTotalAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.riskDepositTotalAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10" v-if="payForm.channelSubsidyAmount">
        <span>4. 该车的补贴车款为人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.channelSubsidyAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.channelSubsidyAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="mt10" v-if="payForm.intentionMoneyAmount">
        <span>{{payForm.channelSubsidyAmount?5:4}}. 乙方已向甲方支付协议意向金人民币（大写）</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.intentionMoneyAmountCn" disabled size="medium"></el-input><span>（￥</span>
        <el-input class="lineInput formItemWidth" v-model="payForm.intentionMoneyAmount" disabled size="medium"></el-input><span>）</span>
      </div>
      <div class="flex flex_align_center mt20">
        <div class="ckxTitle">办证日期约定</div>
        <el-button type="parmary" size="medium" plain class="ml20" @click="onAutooper('日期')" :disabled="disabledAll">自动计算</el-button>
      </div>
      <div class="mt10" :class="stateType?'pb100':''">
        <span>材料在</span>
        <el-date-picker
          type="date"
          class="lineInput formItemWidth" v-model="tradedCarCertDate" :disabled="disabledAll" size="medium" value-format="yyyy-MM-dd">
        </el-date-picker>
        <!-- <el-input class="lineInput formItemWidth" v-model="tradedCarCertDate" :disabled="disabledAll" size="medium"></el-input> -->
        <span>完成办证手续。</span>
      </div>
    </el-form>
  </template>

  <template v-if="!contractFailedType">
    <div class="ckxTitle mb15">客户合同失败原因</div>
    <el-row>
      <el-col :span="12">签订结果：{{dataForm.contractStatusName}}</el-col>
      <el-col :span="12">是否已付意向金：{{indemnifyRsp.payIntentionMoney===1?'已支付':indemnifyRsp.payIntentionMoney===0?'未支付':indemnifyRsp.payIntentionMoney}}</el-col>
      <el-col :span="24" class="mt10 mb10">责任方：{{dataForm.responsibleParty===51?'客户责任':dataForm.responsibleParty===52?'商户责任':dataForm.responsibleParty===53?'检测差异':dataForm.responsibleParty===54?'车开新责任':dataForm.responsibleParty}}</el-col>
      <el-col :span="24">备注：{{dataForm.contractBreakRemark}}</el-col>
      <el-col :span="24" class="mt10 mb10 flex">
        <p>赔偿情况：</p>
        <el-radio-group v-model="indemnifyRsp.indemnifyType" disabled >
          <el-radio :label="0">无需赔偿</el-radio>
          <el-radio :label="2">车开新赔偿客户：<span class="lineMin">{{indemnifyRsp.indemnifyType==2?indemnifyRsp.indemnifyMoney:'-'}}</span>元</el-radio>
          <el-radio :label="4">客户赔偿车开新：<span class="lineMin">{{indemnifyRsp.indemnifyType==4?indemnifyRsp.indemnifyMoney:'-'}}</span>元</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="24">帮卖经理：{{dataForm.signUserName}}</el-col>
    </el-row>
  </template>

  </div>

  
   <div class="footer" v-if="stateType">
      <div class="btn clear" v-if="loginUserType">
        <el-button type="primary" @click="returnCarInspection" class="fl ml20" :disabled="carValid.allotStatus===1?true:false" v-if="dataForm.tradeType===22">退回验车待分配</el-button><!--垫资车显示此按钮--></el-button>
        <el-button type="primary" @click="updateContract">保存</el-button>
        <el-button type="primary" v-if="stateType==1" @click="saveContract('提交合同')">提交合同</el-button>
        <el-button type="primary" v-if="stateType==2" @click="saveContract('签订成功')">签订成功</el-button>
        <el-button type="primary" v-if="[1,2].includes(stateType)" @click="saveContract('签订失败')">签订失败</el-button>
        <!-- <el-button type="primary" v-if="'非4S店的车辆合同'&&stateType==2" @click="saveContract('打印合同')">打印合同</el-button> -->
        <el-button type="primary" v-if="stateType==2&&car.isChannelCar==71" @click="saveContract('打印合同')">打印合同</el-button>
        <el-button type="primary" @click="onSeeCheck">查看维保</el-button>
        <!-- <el-button type="primary" v-if="stateType==2" @click="saveContract('作废合同')">作废合同</el-button> -->
        <!-- <el-button type="primary" v-if="[1].includes(stateType)&&IntentionType" @click="saveContract('放弃付意向金')">放弃付意向金</el-button> -->
        <el-button type="primary" v-if="IntentionType&&payForm.intentionMoneyAmount" @click="saveContract('放弃付意向金')">放弃付意向金</el-button>
      </div>
      <div class="btn clear pt20 footText" v-else>当前合同只能领取人{{dataForm.sellerName}}[{{dataForm.sellerNo}}]操作</div>
    </div>

    <el-dialog
      title="签订失败"
      :visible.sync="dialogVisible"
      width="500px"
      class="customDialog">
      <el-form :model="modalForm" ref="modalForm" :rules="rulesModal" size="medium" label-width="130px">
        <el-form-item label="责任方:" prop="responsibleParty">
          <el-select v-model="modalForm.responsibleParty" placeholder="请选择责任方">
            <el-option label="请选择责任方" value=""></el-option>
            <el-option label="客户责任" :value="51"></el-option>
            <el-option label="商户责任" :value="52"></el-option>
            <el-option label="检测差异" :value="53"></el-option>
            <el-option label="车开新责任" :value="54"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否已付意向金:" v-show="modalForm.responsibleParty">
          <p class="setFontSize16">{{payForm.intentionMoneyAmount?'已付':'未付'}}</p>
        </el-form-item>
        <el-form-item label="客户赔偿车开新:" v-show="modalForm.responsibleParty==51">
          <p class="setFontSize16">{{payForm.intentionMoneyAmount?'1000元':'0元'}}</p>
        </el-form-item>
        <el-form-item label="车开新赔偿客户:" v-show="[52,53,54].includes(modalForm.responsibleParty)">
          <el-select v-model="modalForm.chauffeur" v-if="car.isChannelCar==72&&modalForm.responsibleParty==52" @change="onChauffeur">
            <el-option :value="0" label="300"></el-option>
            <el-option :value="1" label="1000"></el-option>
          </el-select>
          <p class="setFontSize16" v-else>{{payForm.intentionMoneyAmount?'1000元':'300元'}}</p>
        </el-form-item>
        <el-form-item label="备注:" v-show="modalForm.responsibleParty">
          <el-input type="textarea" v-model="modalForm.remark" class="w300"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleSave">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog
      title=""
      :visible.sync="printModel"
      :fullscreen="true">
      <div class="printTop">
        <el-button type="primary" v-print="print" @click="postContractPrint">打印合同</el-button>
        <el-button type="primary" plain @click="printModel=false">关闭</el-button>
      </div>
      <PrintCarSales  id="printMe" :res="res" v-if="printModel"></PrintCarSales>
    </el-dialog> -->

    <el-dialog
      title="请输入车牌号"
      :visible.sync="dialogVisibleCar" width="350px">
      <p class="mb15">新车牌号码：</p>
      <el-input v-model="formCar" class="w300"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitCar">确定</el-button>
        <el-button @click="dialogVisibleCar = false">取 消</el-button>
      </span>
    </el-dialog>
    
    <!--车辆手续状态(查询)-->
    <el-dialog
      title="请输入车牌号"
      :visible.sync="VisibleCarSearch" width="350px">
      <p class="mb15">车牌号码：{{car.carNo}}</p>
      <div>
        <span>类型：</span>
        <el-radio-group v-model="searchCar.type" size="medium">
          <el-radio label="02">小型</el-radio>
          <el-radio label="01">大型</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitSearch">确认查询</el-button>
        <el-button @click="VisibleCarSearch = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
// import print from 'vue-print-nb'
// import PrintCarSales from '@/components/print/carSales'
export default {
  // directives: {
  //   print   
  // },
  // components:{PrintCarSales},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      indemnifyRsp:{},//客户失败原因
      form: {
        account:'',
        accountName:'',
        depositBank:'',
        depositBankShortName:'',
        depositBankShortObj:'',
        channelAccountId:'',
        plateColor:'',
        boardShowKmNums:'',
        customerMobile:'',
        inOrOut:'',
        isWade:'',
        isChangeEvenNum:'',
        plateRegWay:'',
        applyShNewEngine:'',
        carOwnerNature:'',
        carOwnerNatureName:'',
        customerName:'',
        seaGather:'',
        idFaceValid:'',
        idCopy:'',
        publicOrPrivate:'',
        registerDate:'',//初次登记日期
        engineNo:'',//发动机号
        carColor:'',//颜色
        vinCode:'',//车架号
        checkExpire:'',//年检有效期
        makerId:'',
        makerName:'',
        seriesId:'',
        seriesName:'',
        carModel:[],
        covenantsStr2:'',
        carManagerZt:'',
      },
      car:{},
      bankCustomerForm:{},
      rules: {
        'customerMobile': [{ required: true, validator: checkPhone, trigger: 'blur' }],
        'customerName': [{ required: true, message: '请输入', trigger: 'blur' }],
        'plateColor': [{ required: true, message: '请选择车牌颜色' ,trigger: 'change'}],
        'boardShowKmNums': [{ required: true, message: '请输入公里数', trigger: 'blur' }],
        'seaGather': [{ required: true, message: '请选择海车集采集', trigger: 'blur' }],
        'idCopy': [{ required: true, message: '请选择车主证件复印件', trigger: 'blur' }],
        'carOwnerNatureName': [{ required: true, message: '请选择',trigger: 'change' }],
        'accountName': [{ required: true, message: '请输入', trigger: 'blur' }],
        'account': [{ required: true, message: '请输入', trigger: 'blur' }],
        'depositBank': [{ required: true, message: '请输入',trigger: 'change' }],
        'depositBankShortObj': [{ required: true, message: '请输入', trigger: 'change' }],
        'covenantsStr2': [{ required: true, message: '请输入', trigger: 'blur' }],
        carManagerZt: [{ required: true, message: '请查询手续状态', trigger: 'blur' }],
      },
      rulesModal:{
        responsibleParty: [{ required: true, message: '请选择责任方', trigger: 'change' }],
        merchantBreakType:[{ required: true, message: '请选择违约类型', trigger: 'change' }],
      },
      checkForm:{
        radio:''
      },//过户凭证特殊要求
      covenantsStr1:'甲方委托出售车辆为抵押登记状态车，甲方承诺该车辆抵押登记所涉及贷款或其他债务已全额还清，且在',
      covenantsStr3:'前自行完成解除抵押登记手续，若乙方在实际交易过程中发现该车辆贷款或其他债务未还清或其他任何非乙方原因导致该车辆未能按照上述约定时间完成解除抵押登记的，乙方有权向甲方退车，并由甲方承担乙方由此造成的所有损失。',
      covenants: '',//其他约定事项
      checkList: [],
      checkedList:[],//以选择的列表
      checkList2: [],//过户凭证特殊要求列表
      checkedList2:[],//以选择的列表
      radiolist:[],//过户单选
      contractId:'',//合同id
      stateType:0,//状态
      addressArr:[],//地区
      optionsAddress:[],//默认值地区三级联动
      propsAddress:{
        label:'name',
        value:'id',
        children:'children'
      },
      selectArea:{
        provinceId:'',
        provinceName:'',
        cityId:'',
        cityName:'',
        areaId:'',
        areaName:''
      },//三级联动
      optionsCar:[],//品牌车型
      propCar:{
        label:'name',
        value:'id',
        children:'carSeriesList'
      },
      changeFlag:false,//是否切换了下拉(三级联动处理)
      channelForm:{},//4s店信息
      banklist:[],//银行简称列表
      colorlist:[],//颜色列表
      carOwnerNaturelist:[],//车辆持有人性质 列表查询
      payForm:{},//委托出售价格表单
      tradedCarCertDate:'',//办证日期约定
      carForm:{},//车辆材料form
      rolelist:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'}],//固定角色列表
      dataForm:{},//详情所有数据
      accountList:[],//银行账户列表
      carMaterialsList:[],//车辆材料列表
      idcardlist:[],//身份证列表
      carlistPro:[],//车辆手续列表
      contractRequireList:[],//合同列表（所有复选框）
      customChecklist:[{},{},{}],//自定义复选框和输入框
      dialogVisible:false,
      modalForm:{
        handle:'不做处理',
        chauffeur:'',
        remark:'',
      },
      changTypeSave:false,//是否操作表单
      deepType:false,//是否可以触发监听判断
      printModel:false,//打印模版
      print:{//打印设置
        id:'printMe',
        popTitle:'配置页眉标题'
      },
      IntentionType:false,//是否显示意向金按钮（当付款状态为31待收款的时候才可以显示）
      payId:'',//财务收款ID
      res:{},//打印的数据
      carValid:{},//车辆复检
      priceCalculateType:'',//委托出售价格计算方式：71自动计算，72全押车款
      disabledAll:false,//一键禁用（针对详情）
      bankVis:false,//是否展示银行信息的搜索内容
      banklistSear:[],//搜索的银行数据
      financePayStatus:'',//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，35无需付款, 默认31
      limitFlag:false,//额度变更单默认不展示
      contractFailedType:true,//失败原因展示内容
      dialogVisibleCar:false,
      formCar:'',
      channelAccount:{},//渠道的银行信息
      VisibleCarSearch:false,//查询车辆手续状态
      searchCar:{
        type:'',
      },
      user:{},//当前登录数据
      loginUserType:false,//是否是当前合同领取人操作合同
    }
  },
  watch:{
    form:{//主体表单
      deep:true,
      handler:function(){
        if(this.deepType&&this.stateType){
          this.changTypeSave=true;
        }
      }
    },
    car:{//银行信息
      deep:true,
      handler:function(){
        if(this.deepType&&this.stateType){
          this.changTypeSave=true;
        }
      }
    },
    // 'checkForm.radio':{//过户单选
    //   handler:function(){
    //     if(this.deepType&&this.stateType){
    //       this.changTypeSave=true;
    //     }
    //   }
    // },
    covenants(){//其他约定事项
       if(this.deepType&&this.stateType){
          this.changTypeSave=true;
        }
    },
    checkedList2:{//过户凭证
      handler:function(){
        if(this.deepType&&this.stateType){
          this.changTypeSave=true;
        }
      }
    },
    checkedList:{//材料复选
      handler:function(){
        if(this.deepType&&this.stateType){
          this.changTypeSave=true;
        }
      }
    },
    customChecklist:{//过户要求自定义输入框数组
      deep:true,
      handler:function(){
        if(this.deepType&&this.stateType){
          this.changTypeSave=true;
        }
      }
    },
  },
  created(){
    this.user=this.$store.state.user.userData;
    
    if(this.$route.query.id)this.contractId=this.$route.query.id;
    // if(this.$route.query.state) this.stateType=Number(this.$route.query.state);//0 查看合同 1制作合同 2载入合同
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
      })
    }
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.optionsCar=res;
      })
    }else{
      this.optionsCar=this.$store.state.setting.carBrandList;
    }
    this.getCustomerDetail();//获取详情
   //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.optionsAddress=res;
      })
    }else{
      this.optionsAddress=this.$store.state.setting.cityList;
    }
    this.postbankHeadList()//根据银行简称查询银行简称
    this.queryPlateColor()//车牌颜色 列表查询
  },
  methods: {
    onCarSearch(){
      this.VisibleCarSearch=true;
    },
    onSubmitSearch(){//车辆手续状态
      if(!this.searchCar.type){
        this.$message.warning('请选择车辆类型！')
        return
      }
      let query={
        carNo:this.car.carNo,
        carType:this.searchCar.type,
        // refresh:true,
      }
      this.$api.getCarManagerInfoFromDMVFrTrade(query).then(res=>{
        if(res.code==0){
          if(res.data){
            this.form.carManagerZt=res.data.zt;
            this.VisibleCarSearch=false;
          }
        }
      })
    },
    returnCarInspection(){//退回验车待分配
      this.$confirm('你确认将验车入库状态修改为合同待领取吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.postAlterBsiCCReValid(this.contractId).then(res=>{
          if(res.code===0){
            this.getCustomerDetail();//获取详情
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    onChangePublic(){
      if(this.form.publicOrPrivate===6){//选择公司时的联动处理
        for(let i=0;i<this.carOwnerNaturelist.length;i++){
          let row=this.carOwnerNaturelist[i]
          if(row.name==='公司'){
            this.form.carOwnerNature=row.id;
            this.form.carOwnerNatureName=row.name;
            this.onAutoAssignment('idcar',row)
            return
          }
        }
      }else{
        this.form.carOwnerNatureName=''
      }
      
    },
    onCarEdit(){
      this.dialogVisibleCar=!this.dialogVisibleCar;
    },
    onSubmitCar(){
      if(!this.formCar){
        this.$message.warning('请输入车牌号！')
        return
      }else{
        const reg = /[!@#$%^&*(),.?":。，=·～{}|<> ]/;
        if (reg.test(this.formCar)) {
          this.$message.warning('车牌号不能输入特殊字符！')
          return
        }
      }
      let query={
        newCarNo:this.formCar,
        customerId:this.car.id
      }
      this.$api.postAlterCarNo(query).then(res=>{
        if(res.code===0){
          this.getCustomerDetail();
          this.$message.success(res.message)
          this.dialogVisibleCar=false;
        }
      })
    },
    onSeeCheck(){//查看维保
      this.$api.getMaintenanceReportQuery({vin:this.car.vinCode}).then(res=>{
        if(res.code==0){
          if(res.data.status===3){
            window.open(res.data.result,'_blank')
          }else{
            this.$message.warning(res.data.result)
          }
        }
      })
    },
    onRealFun(row){
      if(['有','暂缺'].includes(row.realData)){
        this.limitFlag=true;
      }else{
        this.limitFlag=false;
      }
      //  this.$forceUpdate()
    },
    changeDem(row){
      for(let i=0;i<this.carMaterialsList.length;i++){
        let item=this.carMaterialsList[i];
        if(item.materialName.indexOf('钥匙')!=-1&&row.materialName.indexOf('钥匙')!=-1){
          let pdaData=item.pdaData?Number(item.pdaData.split('把')[0]):0;//PDA 采集数据
          let a1=item.options1?Number(item.options1):0;//缺
          let a2=item.options2?Number(item.options2):0;//暂缺
          if(pdaData&&a1&&!item.operateFlag||pdaData&&a2&&!item.operateFlag){//钥匙(客户)
            item.feeBearerId=1;//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
            item.feeBearerName='客户';
            item.feeBearerNameObj={
              id:1,
              name:'客户'
            }
          }
          if(!a1){//钥匙(客户)
            item.feeAmount='';//费用金额
          }else if(a2){
            item.depositAmount='';//押金
          }
        }else if(['车辆牌照'].includes(item.materialName)&&['车辆牌照'].includes(row.materialName)){
          //['有','需补领'].includes(item.pdaData)&&
          if(['有'].includes(item.realData)){//车辆牌照(客户)
            if(!item.operateFlag){
              item.feeBearerId='';//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
              item.feeBearerName='';
              item.feeBearerNameObj={
                id:'',
                name:''
              }
            }
            
            item.feeAmount=0;//费用金额
            item.depositAmount=0;//押金
          }else if(['缺'].includes(item.realData)||['缺'].includes(item.realData)&&item.differenceOptions=='客户原因'){//车辆牌照(客户)
            if(!item.operateFlag){
              item.feeBearerId=1;//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
              item.feeBearerName='客户';
              item.feeBearerNameObj={
                id:1,
                name:'客户'
              }
            }
            
            item.feeAmount=300;//费用金额
          }else if(['暂缺'].includes(item.realData)){//车辆牌照(客户)
            if(!item.operateFlag){
              item.feeBearerId=1;//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
              item.feeBearerName='客户';
              item.feeBearerNameObj={
                id:1,
                name:'客户'
              }
            }
            
            item.depositAmount=300;//押金
          }else if(['无','缺'].includes(item.pdaData)&&!item.operateFlag){
            item.feeBearerId=2;//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
            item.feeBearerName='商户';
            item.feeBearerNameObj={
              id:2,
              name:'商户'
            }
          }
          if(item.differenceOptions=='车开新原因'&&!item.operateFlag){
            item.feeBearerId=3;
            item.feeBearerName='车开新';
            item.feeBearerNameObj={
              id:3,
              name:'车开新'
            }
          }
        }else if(['桩充','线充','备胎'].includes(item.materialName)&&['桩充','线充','备胎'].includes(row.materialName)&&!item.operateFlag){
          if(['有'].includes(item.pdaData)&&['暂缺','缺'].includes(item.realData)){//车辆牌照(客户)
            item.feeBearerId=1;//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
            item.feeBearerName='客户';
            item.feeBearerNameObj={
              id:1,
              name:'客户'
            }
          }else if(['无','缺'].includes(item.pdaData)){
            item.feeBearerId=2;//1、普通车辆钥匙、遥控车辆钥匙和智能车辆钥匙实际情况+缺+暂缺不能大于PDA采集数据，如果所选大于时提示“不缺那么多”，费用承担方默认为客户
            item.feeBearerName='商户';
            item.feeBearerNameObj={
              id:2,
              name:'商户'
            }
          }
        }else if(item.materialName.indexOf('登记证书')!=-1&&row.materialName.indexOf('登记证书')!=-1){
          if(['无','需变更'].includes(item.pdaData)&&['无','缺'].includes(item.realData)&&!item.operateFlag){//登记证书（商户）
            item.feeBearerId=2;//1、检测报告展示无或者需变更时，且实际情况也是无，费用承担方为商户
            item.feeBearerName='商户';
            item.feeBearerNameObj={
              id:2,
              name:'商户'
            }
          }else if(['有','需补领'].includes(item.pdaData)&&['暂缺','无','缺'].includes(item.realData)&&!item.operateFlag||
            ['有','需补领'].includes(item.pdaData)&&item.options1.indexOf('需变更')!=-1&&!item.operateFlag||item.differenceOptions=='客户原因'&&!item.operateFlag){//登记证书（客户）
            item.feeBearerId=1;//2、检测报告显示有，实际暂缺；检测报告显示有，实际无，检测报告显示正常，实际需变更或如需变更；费用承担方为客户
            item.feeBearerName='客户';
            item.feeBearerNameObj={
              id:1,
              name:'客户'
            }
          }
          if(item.differenceOptions=='车开新原因'&&!item.operateFlag){
            item.feeBearerId=3;
            item.feeBearerName='车开新';
            item.feeBearerNameObj={
              id:3,
              name:'车开新'
            }
          }
          //处理值展示押金和费用
          if(['需变更'].includes(item.options1)&&['有','需补领'].includes(item.pdaData)&&['有'].includes(item.realData)||
            ['有','需补领'].includes(item.pdaData)&&['缺'].includes(item.realData)){
            item.feeAmount=300;//费用金额
          }else if(['如需变更'].includes(item.options1)&&['有','需补领'].includes(item.pdaData)&&['有'].includes(item.realData)||
          ['有','需补领'].includes(item.pdaData)&&['暂缺'].indexOf(item.realData)!=-1){
            item.depositAmount=300;
          }else if(['正常'].includes(item.options1)&&['有'].includes(item.realData)){
            item.depositAmount='';
            item.feeAmount='';//费用金额
          }
        }else if(item.materialName.indexOf('行驶证')!=-1&&row.materialName.indexOf('行驶证')!=-1){
           if(item.pdaData=='无'&&['缺','无'].includes(item.realData)&&!item.operateFlag||
              item.pdaData=='需变更'&&!item.operateFlag&&item.options1.indexOf('需变更')!=-1&&!item.operateFlag){//行驶证（商户）
              item.feeBearerId=2;//1、检测报告展示无时，实际情况也是无时，费用承担方为商户
              item.feeBearerName='商户';//2、检测报告展示需变更，实际情况也是需变更或如需变更时，费用承担方为商户
              item.feeBearerNameObj={
                id:2,
                name:'商户'
              }
            }else if(['有','需补领'].includes(item.pdaData)&&['暂缺','无','缺'].includes(item.realData)&&!item.operateFlag||
            item.pdaData=='正常'&&['需变更'].includes(item.options1)&&!item.operateFlag||item.differenceOptions=='客户原因'&&!item.operateFlag){//行驶证（客户）
              item.feeBearerId=1;//3、检测报告显示有，实际暂缺；检测报告显示有，实际无，检测报告显示正常，实际需变更或如需变更；费用承担方为客户
              item.feeBearerName='客户';
              item.feeBearerNameObj={
                id:1,
                name:'客户'
              }
            }
            if(item.differenceOptions=='车开新原因'&&!item.operateFlag){
               item.feeBearerId=3;
              item.feeBearerName='车开新';
              item.feeBearerNameObj={
                id:3,
                name:'车开新'
              }
            }
             if(['需变更'].includes(item.options1)&&['有','需补领'].includes(item.pdaData)&&['有'].includes(item.realData)||
              ['有','需补领'].includes(item.pdaData)&&['缺'].includes(item.realData)){
              item.feeAmount=300;//费用金额
            }else if(['如需变更'].includes(item.options1)&&['有','需补领'].includes(item.pdaData)&&['有'].includes(item.realData)||
            ['有','需补领'].includes(item.pdaData)&&['暂缺'].includes(item.realData)){
              item.depositAmount=300;
            }else if(['正常'].includes(item.options1)&&['有'].includes(item.realData)){
              item.depositAmount='';
              item.feeAmount='';//费用金额
            }
        }else if(item.materialName.indexOf('车辆购置税')!=-1&&row.materialName.indexOf('车辆购置税')!=-1){
           if(['已完税','免税车'].includes(item.pdaData)&&['已完税'].includes(item.realData)){
              item.depositAmount='';
              item.feeAmount='';//费用金额
              item.feeBearerId='';//当选择“过期”时，且检测报告的年检有效期已过期时，费用承担方自动展示为商户
              item.feeBearerName='';
              item.feeBearerNameObj={
                id:'',
                name:''
              }
            }
        }else if(item.materialName.indexOf('年检')!=-1&&row.materialName.indexOf('年检')!=-1){
           if(item.pdaData=='已过期'&&['过期'].includes(item.realData)&&!item.operateFlag){//年检（商户）
              item.feeBearerId=2;//当选择“过期”时，且检测报告的年检有效期已过期时，费用承担方自动展示为商户
              item.feeBearerName='商户';
              item.feeBearerNameObj={
                id:2,
                name:'商户'
              }
            }
        }
        // if(item.materialName.indexOf('违章')!=-1&&row.materialName.indexOf('违章')!=-1){
        //   if(item.options2>0){//1次违章自动在“一项超过12分”上打勾
        //     item.options6=true;
        //   }else{
        //     item.options6=false;
        //   }
        // }
      }
      setTimeout(() => {
        this.deepType=true;//触发监听
        // this.forceupdate()
      }, 3000);
      this.$forceUpdate()
    },
    autoCar(materialName,feeAmount){//计算车辆手续
      for(let i=0;i<this.carlistPro.length;i++){
        let item=this.carlistPro[i]
        if(item.materialName==materialName){
          item.feeAmount=feeAmount;
          item.feeBearerNameObj={
            id:null,
            name:''
          }
          item.nature='';
        }
      }
    },
    checkBoxChange(item,val){
      if(item.materialName=='办证加急'){
        if(val){
          if(this.car.isHuPlate===1){
            this.postFeeStandardList(item.materialName,1)
          }
        }
      }else if(item.materialName.indexOf('额度变更')!=-1){
        if(val){
          switch(item.materialName1){
            case 0:
              this.postFeeStandardList('额度变更-父母与子女',2)
              break;
            case 1:
              this.postFeeStandardList('额度变更-夫妻变更',2)
              break;
            case 2:
              this.postFeeStandardList('额度变更-公司抬头变更',2)
              break;
          }
          // this.postFeeStandardList(item.materialName,2)
          // this.onCarFeer(item)//修改费用
        }
      }
      //默认添加补办要求
      if(item.materialName!='办证加急'){
        if(val){
          for(let i=0;i<this.carlistPro.length;i++){
            let ita=this.carlistPro[i]
            if(item.materialName==ita.materialName){
              ita.nature='补办';
            }
          }
        }
      }
      this.changTypeSave=true;
      if(!val){
        this.autoCar(item.materialName,'')
      }
    },
    changeAddress(){
      let row=this.$refs.address.getCheckedNodes();
      if(row.length>0){
        let item=row[0]
        this.selectArea={
          provinceId:item.pathNodes[0].value,
          provinceName:item.pathNodes[0].label,
          cityId:item.pathNodes[1].value,
          cityName:item.pathNodes[1].label,
          cityNo:item.data.code,
          areaId:item.value,
          areaName:item.label
        }//三级联动
      }else{
        this.selectArea={
          provinceId:'',
          provinceName:'',
          cityId:'',
          cityNo:'',
          cityName:'',
          areaId:'',
          areaName:''
        }//三级联动
      }
    },
    changSelect(row,state){
      switch(state){
        case '车辆持有人性质':
          this.form.carOwnerNature=row.id;
          this.form.carOwnerNatureName=row.name;
          this.onAutoAssignment('idcar',row)
          break;
        case 'bank':
          this.form.channelAccountId=row.id;
          this.form.accountName=row.accountName;
          this.form.account=row.account;
          this.form.depositBankShortName=row.bank;
          this.form.depositBank=row.bankShortName;
          break;
        case '海车集采集':
        case '身份证刷验':
          if(['未录','未刷'].includes(row)){
            this.onAutoAssignment('idcar')
          }
          break;
      }
    },
    onAutoAssignment(state,list){//自动关联数据回显
      switch(state){
        case 'idcar':
          if(list){
            let arr=[];
            if(list.customerMaterialsList.length==0||list.customerMaterialsList.length<list.contents.length){
              for(let i=0;i<list.contents.length;i++){
                let obj={
                  materialName:list.contents[i],
                  realData1:'',
                  realData2:'',
                  realData3:'',
                  feeAmount:'',
                  depositAmount:'',
                  feeBearerName:'',
                  remark1:'',
                  remark2:'',
                  remark3:'',
                  remark4:''
                }
                arr.push(obj)
              }
              this.idcardlist=arr;
            }else{
              this.idcardlist=[];
              //车主证件列表
              for(let i=0;i<list.customerMaterialsList.length;i++){
                let item=list.customerMaterialsList[i]
                if(item.realData){
                  if(item.realData.indexOf(',')!=-1){
                    let a=item.realData.split(',')
                    item.realData1=a[0];
                    item.realData2=a[1];
                    item.realData3=a[2];
                  }else{
                    item.realData1=item.realData;
                  }
                }else{
                  item.realData1='';
                  item.realData2='';
                  item.realData3='';
                }
                if(item.remark){
                  if(item.remark.indexOf('|')!=-1){
                    let a=item.remark.split('|')
                    if(a[0]){
                      item.remark1=a[0]=='true'?true:'';
                    }
                    if(a[1]){
                      if(a[1].indexOf(',')!=-1){
                        item.remark2=[a[1].split(',')[0],a[1].split(',')[1]];
                      }
                    }
                    if(a[2]){
                      item.remark3=a[2]=='true'?true:'';
                    }
                    item.remark4=a[3];
                  }
                }
                this.idcardlist.push(item)//身份证列表
              }
            }
          }else{
            for(let i=0;i<this.idcardlist.length;i++){
              let item=this.idcardlist[i]
              if(item.materialName.indexOf('身份证')!=-1){
                item.realData1='暂缺';
              }
            }
          }
          
          this.$forceUpdate()
          // console.log(this.idcardlist,'----this.idcardlist',list)
          break;
      }
    },
    onAutooper(state){//自动计算操作
      switch(state){
        case 'autoCalc':
          this.priceCalculateType=71;
          this.dataForm.calcType=state;
          this.getCalculatePlay(71)//自动计算委托出售价格 客户合同中的押金与费用以及应支付的车款
          break;
        case 'fullCarPay':
          this.priceCalculateType=72;
          this.dataForm.calcType=state;
          this.getCalculatePlay(72)
          // this.getfullPlay()//委托出售价格,全押车款, 客户合同
          break;
        case '日期':
          this.getcertDate()//自动计算办证日期约定 客户合同中的约定完成办证手续日期
          break;
      }
    },
    updateQuery(){
      let newArrCheck=[];//合同要求列表(多个复选框)
      let covType=false;//是否有其他约定事项
      let deleteArr=[];//需要删除掉的合同数据
      let contractRequireListFF=JSON.parse(JSON.stringify(this.contractRequireList))
      for(let i=0;i<contractRequireListFF.length;i++){//所有列表集合
        let item=contractRequireListFF;
        if(item[i].id==this.checkForm.radio&&item[i].radioOrCheck=='radio'){
          item[i].isSelected=1;//选中状态：0未勾选，1勾选，默认0
        }else if(item[i].radioOrCheck=='radio'){
          item[i].isSelected=0;
        }else{
          item[i].isSelected=0
          for(let j=0;j<this.checkedList2.length;j++){//过户凭证特殊要求
            if(this.checkedList2[j]==item[i].id){
              item[i].isSelected=1;//选中状态：0未勾选，1勾选，默认0
            }
          }
          
          for(let j=0;j<this.checkedList.length;j++){////材料取回约定表
            if(this.checkedList[j]==item[i].id){
              item[i].isSelected=1;//选中状态：0未勾选，1勾选，默认0
            }
          }
        }
        let requireName=item[i].requireName;
        let isSelfDefined=item[i].isSelfDefined;
        if(item[i].requireType===13){//其他约定事项
          if(this.car.isPledge===42){
            requireName=`${this.covenantsStr1}${this.form.covenantsStr2}${this.covenantsStr3}`;
          }else{
            requireName=this.covenants;
          }
          
          covType=true;
          isSelfDefined=2;
        }
        let isSelected=item[i].isSelected
        if(isSelected==true){
          isSelected=1;
        }else if(isSelected==false){
          isSelected=0;
        }
        if(requireName){
          let objNew={
            "requireId": item[i].id,//要求ID, 新增时不要填写，更新时必填
            "requireName": requireName,//要求名称
            "requireType": item[i].requireType,//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
            "isSelected": isSelected,//选中状态：0未勾选，1勾选，默认0
            "radioOrCheck":item[i].radioOrCheck,//单选复选：单选radio, 复选check, 默认check
            "isSelfDefined":isSelfDefined,//是否自定义: 1否，2是，默认1
            bak:item[i].bak,
          }
          newArrCheck.push(objNew)
        }else{
          deleteArr.push(item[i].id)
        }
      }
      // if(deleteArr.length>0){//删除合同数组
      //   this.deleteRequire(deleteArr)
      // }
      if(covType==false){
        if(this.car.isPledge===42){
          let objNew={
            "requireName": `${this.covenantsStr1}${this.form.covenantsStr2}${this.covenantsStr3}`,//要求名称
            "requireType": 13,//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
            isSelfDefined:2
          }
          newArrCheck.push(objNew)
        }else if(this.covenants){
          let objNew={
            "requireName": this.covenants,//要求名称
            "requireType": 13,//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
            isSelfDefined:2
          }
          newArrCheck.push(objNew)
        }
      }
      
      //过户凭证特殊要求(复选框判断输入框)
      let oldArr=[];
      let customChecklistFF=JSON.parse(JSON.stringify(this.customChecklist))
      for(let i=0;i<customChecklistFF.length;i++){
        let item=customChecklistFF[i]
        if(item.requireName){
          let type=0;
          if(item.isSelected){
            type=1
          }
          item['requireType']=11;//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
          item['isSelected']=type;//选中状态：0未勾选，1勾选，默认0
          item['isSelfDefined']=2;//是否自定义: 1否，2是，默认1
          oldArr.push(item)
        }
      }
      let arrCheck=[...newArrCheck,...oldArr]//合同要求列表(多个复选框)

      
      let newCarArr=[];//车辆材料列表(大数据)
      let newIdCarArr=[];//单独的车主证件列表
      let idcardlistFF=JSON.parse(JSON.stringify(this.idcardlist))  
      let customerIdNo='';//客户身份证
      let businessLicense='';//经营执照编号
      let certNo='';//产证编号
      for(let i=0;i<idcardlistFF.length;i++){
        let item=idcardlistFF[i]
        
        if(item.materialName.indexOf('身份证')!=-1){
          if(item.realData2){
            customerIdNo=item.realData2;
          }
        }else if(item.materialName.indexOf('营业执照')!=-1){
          if(item.realData2){
            businessLicense=item.realData2;
          }
        }
        if(['有'].includes(item.realData1)){
          item.realData=`${item.realData1?item.realData1:''}${item.realData2?','+item.realData2:''}`
        }else if(['暂缺'].includes(item.realData1)){
          item.realData=`${item.realData1?item.realData1:''}${item.realData2?','+item.realData2:''}${item.realData3?','+item.realData3:''}`

          if(item.materialName.indexOf('居住证')!=-1||item.materialName.indexOf('盖章材料')!=-1){
            item.repairSendTime=item.realData2?`${item.realData2}提供`:item.realData2;
          item.appointFinishTime=item.realData2;
          }else{
            item.repairSendTime=item.realData3?`${item.realData3}提供`:item.realData3;;
            item.appointFinishTime=item.realData3;
          }
          
        }
        item.remark=`${item.remark1?item.remark1:''}|${item.remark1===true&&item.remark2?item.remark2:''}|${item.remark3?item.remark3:''}|${item.remark3===true&&item.remark4?item.remark4:''}`
        let contractRequiredNums=0;//合同中该材料需要的数量, 无/暂无等0，有数字的就是该数字，有1，默认0
        
        if(['有','暂缺'].indexOf(item.realData1)!=-1){
          contractRequiredNums=1;
        }else{
          contractRequiredNums=0;
        }

        if(['有'].includes(item.realData1)){
          item.realNums=1;
        }else if(['暂缺'].includes(item.realData1)){
          item.nowNoHasNums=1;
        }
        item.materialStatus=item.realData1;
        if(item.appointFinishTime){
          if(item.appointFinishTime.indexOf(' ')==-1){
            item.appointFinishTime=`${item.appointFinishTime} 00:00:00`
          }
        }
        let obj={
              id:item.id,
              "carId": item.carId,//车辆ID
              "materialName": item.materialName,//车辆材料名称
              "materialStatus": item.materialStatus,//材料状态：暂缺/缺/有（需变更/如需变更）等等，汉字描述既是状态
              "pdaData": item.pdaData,//PDA采集数据
              "realData": item.realData,//实际情况
              pdaNums:item.pdaNums,//pda的数量
              realNums:item.realNums,//实际有的数量
              noHasNums:item.noHasNums,//缺的数量
              nowNoHasNums:item.nowNoHasNums,//暂缺的数量
              "options": item.options,//选项
              repairSendTime:item.repairSendTime,
              "differenceOptions": item.differenceOptions,//差异选项
              "feeAmount": item.feeAmount,//费用金额，
              "depositAmount":item.depositAmount,//押金金额
              "feeBearerId": item.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
              "feeBearerName": item.feeBearerName,//费用承担方name：客户，商户，车开新
              "feeEntrustId": item.feeEntrustId,//委托办理方Id：1车开新，2渠道，3商户
              "feeEntrustName": item.feeEntrustName,//委托办理方name：1车开新，2渠道，3商户
              "remark": item.remark,//补充信息
              "followerNo": item.followerNo,//跟进人工号
              "followerName": item.followerName,//跟进人名称
              "followTime": item.followTime,//跟进时间(下一次/完成)
              "followStatus": item.followStatus,//跟进状态，1待跟进，2跟进中，3完成，4失败，默认1
              "followStatusName": item.followStatusName,//跟进状态，待跟进，跟进中，完成, 失败
              "replaceType": item.replaceType,//补办方式，4自己处理，5车开新待办，6商户待办
              "replaceTypeName": item.replaceTypeName,//补办方式名称，自己处理，车开新待办，商户待办
              "appointFinishTime": item.appointFinishTime,//约定完成时间
              "appointCertTime": item.appointCertTime,//邀约办证时间
              "stockStatusName": item.stockStatusName,//门店库存状态
              "stockRemovalObj": item.stockRemovalObj,//出库对象
              "sourceType": item.sourceType,//来源类型：新增 add，合同 contract, 办证 cert, 物流 logistics
              "carBreakRuleList": item.carBreakRuleList,//违章处理列表（循环赋值）
              "taskId": item.taskId,//任务ID
              // "carNo": item.carNo,//车牌号
              "supplierId": item.supplierId,//供应商ID
              "supplierName": item.supplierName,//供应商名称
              "payableCertCompanyAmount": item.payableCertCompanyAmount,//支付办证公司费用金额
              "payableMerchantAmount": item.payableMerchantAmount,//支付商户费用金额
              "receivableAmount": item.receivableAmount,//收取费用金额
              "status": item.status,//车辆材料手续状态：0待处理，1处理完成
              "statusName": item.statusName,//车辆材料手续状态：待处理，处理完成
              "paymentStatus": item.paymentStatus,//付款状态：0待付款，1已付款，2付款失败，3已取消
              "paymentStatusName": item.paymentStatusName,//付款状态：待付款，已付款，付款失败，已取消
              "payedTime": item.payedTime,//收款时间
              "carMaterialsProtoId": item.carMaterialsProtoId,//车辆基本材料ID， 即补办材料Id
              "contractRequiredNums": contractRequiredNums,//合同中该材料需要的数量, 无/暂无等0，有数字的就是该数字，有1，默认0
              type:item.type,//材料类型：车 car，客户 customer，商户 merchant, 手续 procedure
              stockStatus:item.stockStatus,//库存状态：11待入库, 12在库, 13在途, 14已出库, 默认11，所有车辆客户合同为签订完成的数据，暂缺和缺的项目处于待入库状态
              isSelected:item.isSelected,//选中状态：0未勾选，1勾选，默认0
              exceptionHandling:item.exceptionHandling,//异常处理情况：核实未发出，遗失
              exceptionDesc:item.exceptionDesc,//异常描述
              stockRemovalType:item.stockRemovalType,//出库类型
              selfDefineRemark:item.selfDefineRemark//自定义的备注，前端专用(车辆备注)
            }
        newIdCarArr.push(obj)
      }
      let otherMaterialsListFF=JSON.parse(JSON.stringify(this.car.otherMaterialsList))  
      for(let i=0;i<otherMaterialsListFF.length;i++){
        let item=otherMaterialsListFF[i]
        if(item){
          if(item.feeBearerNameObj){
            item.feeBearerId=item.feeBearerNameObj.id;
            item.feeBearerName=item.feeBearerNameObj.name;
          }
          switch(item.type){
            case 'car':
              //开始处理数据
              if(item.materialName.indexOf('钥匙')!=-1){
                item.options=`${item.options1?item.options1:''}${item.options2?','+item.options2:''}${item.options3?','+item.options3:''}`
              }else if(item.materialName.indexOf('车辆牌照')!=-1){
                // 处理掉已输入的值
                if(['有'].indexOf(item.realData)!=-1){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                }else if(['缺'].indexOf(item.realData)!=-1){
                  item.feeAmount=300;
                  item.depositAmount=0;
                }else if(['暂缺'].indexOf(item.realData)!=-1){
                  item.feeAmount=0;
                  item.depositAmount=300;
                }
                
              }else if(['桩充','线充','备胎'].includes(item.materialName)){
                if(['有'].includes(item.realData)){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                }else if(['缺'].includes(item.realData)){//输入费用，押金为空
                  item.depositAmount=0;//
                }else if(['暂缺'].includes(item.realData)){//输入押金，费用为空
                  item.feeAmount=0;
                }
              }else if(['机动车登记证书','机动车行驶证'].includes(item.materialName)){
                if(['缺'].indexOf(item.realData)!=-1){
                  item.options=`${item.options4?'补办':''}`
                  item.repairSendTime='';
                  item.appointFinishTime='';
                }else if(['有'].indexOf(item.realData)!=-1){
                  if(['机动车行驶证'].includes(item.materialName)){
                    item.options=`${item.options1?item.options1:''}${item.options3?','+item.options3:''}`;
                  }else{
                    item.options=`${item.options1?item.options1:''}${item.options2?','+item.options2:''}${item.options3?','+item.options3:''}`
                    certNo=item.options2;
                  }
                  // item.feeBearerId='';
                  // item.feeBearerName='';
                  item.differenceOptions='';//差异选项
                  item.repairSendTime='';
                  item.appointFinishTime='';
                  item.followNature=item.options1;//跟进要求[正常，需变更，如需变更]
                }else if(['暂缺'].indexOf(item.realData)!=-1){
                  item.options=`${item.options5?item.options5:''}`
                  item.differenceOptions='';//差异选项
                  item.repairSendTime=item.options?`${item.options}提供`:item.options;
                  item.appointFinishTime=item.options;
                }

                //处理值展示押金和费用
                if(['正常'].includes(item.options1)&&['有'].indexOf(item.realData)!=-1){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                  item.feeBearerId='';
                  item.feeBearerName='';
                }else if(['需变更'].includes(item.options1)&&['有'].includes(item.realData)||['缺'].includes(item.realData)){
                  item.feeAmount=300;//费用金额
                  item.depositAmount=0;//押金
                }else if(['如需变更'].includes(item.options1)&&['有'].includes(item.realData)||['暂缺'].indexOf(item.realData)!=-1){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=300;
                }
              }else if(['车辆购置税'].includes(item.materialName)){
                if(['已完税'].includes(item.realData)){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                }else if(['免税'].includes(item.realData)){//输入费用，押金为空
                  item.depositAmount=0;//押金
                }else if(['不确定'].includes(item.realData)){//输入押金，费用为空
                  item.feeAmount=0;//费用
                } }else if(item.materialName.indexOf('保险单')!=-1){
                if(['有'].indexOf(item.realData)!=-1){
                  item.options=item.options1.length>0?item.options1.toString():''
                }
                //处理费用
                if(['有'].includes(item.realData)){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                }else if(['缺'].includes(item.realData)){//输入费用，押金为空
                  item.depositAmount=0;//
                }else if(['暂缺'].includes(item.realData)){//输入押金，费用为空
                  item.feeAmount=0;
                }
              }else if(item.materialName=='年检'){
                //处理费用
                if(['未过期','当月到期'].includes(item.realData)){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                }else if(['过期'].includes(item.realData)){//输入费用，押金为空
                  item.depositAmount=0;
                }
              }else if(item.materialName=='违章'){
                //处理费用
                if(['无'].includes(item.realData)){
                  item.feeAmount=0;//费用金额
                  item.depositAmount=0;//押金
                }else if(['代办'].includes(item.realData)){//输入费用，押金为空
                  item.depositAmount=0;//
                  item.options=`${item.options1?item.options1:''},${item.options2?item.options2:''},${item.options3?item.options3:''},${item.options4?item.options4:''},${item.options5?item.options5:''}`;
                  item.payableAmount=item.options4;//应付金额【违章本金】
                  item.driveLicenseSubScore=item.options5;//驾照扣分
                  item.breakRuleDetails=`${item.options1?item.options1:'-'}前的${item.options2?item.options2:'-'}次,${item.options3?item.options3:'-'}处理完毕`;//违章情况
                  item.breakRuleCounts=item.options2;//违章次数
                  item.repairSendTime=item.options3?`${item.options3}处理完成`:item.options3;
                }else if(['客户自行处理'].includes(item.realData)){//输入押金，费用为空
                  item.feeAmount=0;
                  item.options=`${item.options1?item.options1:''},${item.options2?item.options2:''},${item.options3?item.options3:''}`;
                  item.breakRuleDetails=`${item.options1?item.options1:'-'}前的${item.options2?item.options2:'-'}次,${item.options3?item.options3:'-'}处理完毕`;//违章情况
                  item.repairSendTime=item.options3?`${item.options3}处理完成`:item.options3;
                }
              }else if(item.materialName.indexOf('审核单')!=-1){
                if(['有'].indexOf(item.realData)!=-1){
                  item.options='';
                }
                //处理费用
                if(['有','无需变更'].includes(item.realData)){
                  item.options='';
                }
              }
              break;
            case 'procedure'://手续
              for(let j=0;j<this.carlistPro.length;j++){
                if(this.carlistPro[j].realData===true){
                  if(this.carlistPro[j].materialName1==0&&item.materialName.indexOf('父母')!=-1){
                    item.isSelected=1;
                    item.realData=this.carlistPro[j].realData===true?'是':'';
                    item.feeAmount=this.carlistPro[j].feeAmount;
                    item.feeBearerId=this.carlistPro[j].feeBearerNameObj.id;
                    item.feeBearerName=this.carlistPro[j].feeBearerNameObj.name;
                    item.nature=this.carlistPro[j].nature;
                  }else if(this.carlistPro[j].materialName1==1&&item.materialName.indexOf('夫妻')!=-1){
                    item.isSelected=1;
                    item.realData=this.carlistPro[j].realData===true?'是':'';
                    item.feeAmount=this.carlistPro[j].feeAmount;
                    item.feeBearerId=this.carlistPro[j].feeBearerNameObj.id;
                    item.feeBearerName=this.carlistPro[j].feeBearerNameObj.name;
                    item.nature=this.carlistPro[j].nature;
                  }else if(this.carlistPro[j].materialName1==2&&item.materialName.indexOf('抬头')!=-1){
                    item.isSelected=1;
                    item.realData=this.carlistPro[j].realData===true?'是':'';
                    item.feeAmount=this.carlistPro[j].feeAmount;
                    item.feeBearerId=this.carlistPro[j].feeBearerNameObj.id;
                    item.feeBearerName=this.carlistPro[j].feeBearerNameObj.name;
                    item.nature=this.carlistPro[j].nature;
                  }else if(this.carlistPro[j].materialName1==0&&item.materialName.indexOf('父母')==-1||
                  this.carlistPro[j].materialName1==1&&item.materialName.indexOf('夫妻')==-1||
                  this.carlistPro[j].materialName1==2&&item.materialName.indexOf('抬头')==-1||
                  this.carlistPro[j].materialName1==3&&item.materialName.indexOf('合并')==-1){
                    item.isSelected=0;
                  }
                  if(this.carlistPro[j].materialName.indexOf('额度变更')==-1&&item.materialName.indexOf('额度变更')==-1){
                    item.realData=item.realData===true?'是':item.realData;//值为
                  }
                  if(this.carlistPro[j].materialName.indexOf('额度变更')!=-1&&item.materialName.indexOf('额度变更')!=-1){
                    if(!this.limitFlag){
                      item.realData='';
                      item.feeAmount='';
                      item.feeBearerId='';
                      item.feeBearerName='';
                      item.isSelected=0;
                      item.nature='';
                    }
                  }
                  
                  item.materialStatus= item.realData;
                }else{
                  if(this.carlistPro[j].materialName.indexOf('额度变更')!=-1&&item.materialName.indexOf('额度变更')!=-1){
                    item.realData='';
                    item.feeAmount='';
                    item.feeBearerId='';
                    item.feeBearerName='';
                    item.isSelected=0;
                  }else if(this.carlistPro[j].materialName.indexOf('备注')!=-1&&item.materialName.indexOf('备注')!=-1&&this.carlistPro[j].materialName===item.materialName){
                    item.selfDefineRemark='';
                    item.feeAmount='';
                    item.feeBearerId='';
                    item.feeBearerName='';
                  }
                }
              }
              break; 
          }
          let contractRequiredNums=0;//合同中该材料需要的数量, 无/暂无等0，有数字的就是该数字，有/暂缺1，默认0
          if(item.materialName.indexOf('钥匙')!=-1){
            
            if(item.options1>0){
              item.materialStatus='缺';
              contractRequiredNums=0;
              item.noHasNums=item.options1;//缺的数量
            }
            if(item.options2>0){
              item.materialStatus='暂缺';
              contractRequiredNums=1;
              item.repairSendTime=item.options3?`${item.options3}提供`:item.options3;
              item.appointFinishTime=item.options3;
              item.nowNoHasNums=item.options2;//暂缺的数量
            }
            if(!item.options1&&!item.options2){
              item.materialStatus='';
              if(item.realData){//当钥匙没有暂缺和缺的时候，realData才需要判断是否有
                item.materialStatus='有';
              }
            }
            if(item.realData){
              contractRequiredNums=1;
              item.pdaNums=item.realData;//有的数量
            }
          }else if(['有','暂缺'].indexOf(item.realData)!=-1){
            contractRequiredNums=1;
            item.materialStatus=item.realData;
          }else{
            contractRequiredNums=0;
            item.materialStatus=item.realData;
          }

          if(['暂缺'].indexOf(item.realData)!=-1){
            item.repairSendTime=item.options?`${item.options}提供`:item.options;
            item.appointFinishTime=item.options;
            item.nowNoHasNums=1;
          }else if(['有','缺'].indexOf(item.realData)!=-1){
            item.repairSendTime=null;
            item.appointFinishTime=null;
          }
          if(['有'].includes(item.realData)){
            item.realNums=1;
            item.noHasNums=0;
            item.nowNoHasNums=0;
          }else if(['缺'].includes(item.realData)){
            item.noHasNums=1;
            item.realNums=0;
            item.nowNoHasNums=0;
          }else if(!['缺','暂缺','有'].includes(item.realData)){
            item.noHasNums=0;
            item.realNums=0;
            item.nowNoHasNums=0;
          }

          if(['有'].includes(item.pdaData)){
            item.pdaNums=1;
          }else if(['无','0把'].includes(item.pdaData)){
            item.pdaNums=0;
          }

          item.feeAmount=item.feeAmount===0?'':item.feeAmount;
          item.depositAmount=item.depositAmount===0?'':item.depositAmount; 

          if(item.appointFinishTime){
            if(item.appointFinishTime.indexOf(' ')==-1){
              item.appointFinishTime=`${item.appointFinishTime} 00:00:00`
            }
          }
          

          let obj={
              id:item.id,
              "carId": item.carId,//车辆ID
              "materialName": item.materialName,//车辆材料名称
              "materialStatus": item.materialStatus,//材料状态：暂缺/缺/有（需变更/如需变更）等等，汉字描述既是状态
              "pdaData": item.pdaData,//PDA采集数据
              "realData": item.realData,//实际情况
              pdaNums:item.pdaNums,//pda的数量
              realNums:item.realNums,//实际有的数量
              noHasNums:item.noHasNums,//缺的数量
              nowNoHasNums:item.nowNoHasNums,//暂缺的数量
              "options": item.options,//选项
              nature:item.nature,//补办要求
              repairSendTime:item.repairSendTime,
              "differenceOptions": item.differenceOptions,//差异选项
              "feeAmount": item.feeAmount,//费用金额，
              "depositAmount":item.depositAmount,//押金金额
              "feeBearerId": item.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
              "feeBearerName": item.feeBearerName,//费用承担方name：客户，商户，车开新
              "feeEntrustId": item.feeEntrustId,//委托办理方Id：1车开新，2渠道，3商户
              "feeEntrustName": item.feeEntrustName,//委托办理方name：1车开新，2渠道，3商户
              "remark": item.remark,//补充信息
              "followerNo": item.followerNo,//跟进人工号
              "followerName": item.followerName,//跟进人名称
              "followTime": item.followTime,//跟进时间(下一次/完成)
              followNature:item.followNature,
              "followStatus": item.followStatus,//跟进状态，1待跟进，2跟进中，3完成，4失败，默认1
              "followStatusName": item.followStatusName,//跟进状态，待跟进，跟进中，完成, 失败
              "replaceType": item.replaceType,//补办方式，4自己处理，5车开新待办，6商户待办
              "replaceTypeName": item.replaceTypeName,//补办方式名称，自己处理，车开新待办，商户待办
              "appointFinishTime": item.appointFinishTime,//约定完成时间
              "appointCertTime": item.appointCertTime,//邀约办证时间
              "stockStatusName": item.stockStatusName,//门店库存状态
              "stockRemovalObj": item.stockRemovalObj,//出库对象
              "sourceType": item.sourceType,//来源类型：新增 add，合同 contract, 办证 cert, 物流 logistics
              "carBreakRuleList": item.carBreakRuleList,//违章处理列表（循环赋值）
              "taskId": item.taskId,//任务ID
              // "carNo": item.carNo,//车牌号
              "supplierId": item.supplierId,//供应商ID
              "supplierName": item.supplierName,//供应商名称
              "payableCertCompanyAmount": item.payableCertCompanyAmount,//支付办证公司费用金额
              "payableMerchantAmount": item.payableMerchantAmount,//支付商户费用金额
              "receivableAmount": item.receivableAmount,//收取费用金额
              "status": item.status,//车辆材料手续状态：0待处理，1处理完成
              "statusName": item.statusName,//车辆材料手续状态：待处理，处理完成
              "paymentStatus": item.paymentStatus,//付款状态：0待付款，1已付款，2付款失败，3已取消
              "paymentStatusName": item.paymentStatusName,//付款状态：待付款，已付款，付款失败，已取消
              "payedTime": item.payedTime,//收款时间
              "carMaterialsProtoId": item.carMaterialsProtoId,//车辆基本材料ID， 即补办材料Id
              "contractRequiredNums": contractRequiredNums,//合同中该材料需要的数量, 无/暂无等0，有数字的就是该数字，有1，默认0
              type:item.type,//材料类型：车 car，客户 customer，商户 merchant, 手续 procedure
              stockStatus:item.stockStatus,//库存状态：11待入库, 12在库, 13在途, 14已出库, 默认11，所有车辆客户合同为签订完成的数据，暂缺和缺的项目处于待入库状态
              isSelected:item.isSelected,//选中状态：0未勾选，1勾选，默认0
              exceptionHandling:item.exceptionHandling,//异常处理情况：核实未发出，遗失
              exceptionDesc:item.exceptionDesc,//异常描述
              stockRemovalType:item.stockRemovalType,//出库类型
              selfDefineRemark:item.selfDefineRemark,//自定义的备注，前端专用(车辆备注)
              payableAmount:item.payableAmount,//应付金额【违章本金】
              driveLicenseSubScore:item.driveLicenseSubScore,//驾照扣分
              breakRuleDetails:item.breakRuleDetails,//违章情况
              breakRuleCounts:item.breakRuleCounts,//违章次数
            }
            newCarArr.push(obj)
        }
      }
      if(this.$refs.carModel){
        let carS=this.$refs.carModel.getCheckedNodes()
        if(carS.length>0){
          this.form.makerId=this.form.carModel[0];
          this.form.seriesId=this.form.carModel[1];
          if(carS[0]){
            this.form.makerName=carS[0].pathLabels[0];
            this.form.seriesName=carS[0].pathLabels[1];
          }
        }
      }
      let checkExpire=this.form.checkExpire;
      if(checkExpire){
        let a=checkExpire.split('-')
        if(a.length===2){
          checkExpire=`${checkExpire}-01`
        }
      }
      
      // console.log(this.form.carModel,carS,'---55----')
      let query={
        //自动计算 autoCalc,全押车款 fullCarPay
        priceCalculateType:this.priceCalculateType?this.priceCalculateType:this.dataForm.calcType=='autoCalc'?71:this.dataForm.calcType=='fullCarPay'?72:'',//委托出售价格计算方式：71自动计算，72全押车款
        "carNo": this.car.carNo,//车牌号
        carId:this.car.id,
        "id": this.contractId,
        "getBack":this.dataForm.getBack,//取回方式
        "carTradedPrice": this.dataForm.carTradedPrice,//车辆成交价格，该车委托出售价格
        customerDeadline:this.tradedCarCertDate,
        publicOrPrivate:this.form.publicOrPrivate,//公私性质
        carManagerZt:this.form.carManagerZt,//车管所车辆手续状态
        // "customerPurpose": "同意",//客户意向: 同意，待定，放弃
        // "weatherCommunicate": "是",//是否沟通: 是，否
        // "startShopId": 1,//发起门店ID
        // "startShopName": "XX店",
        // "tradeShopId": 1,//成交门店ID
        // "tradeShopName": "SS店",
        // "appointTradeTime": "2021-01-02 12:12:12",//预约成交时间
        // "preTradeRemark": "XXX",//成交预约备注
        "customerCarForm": {
          "brandId": this.car.brandId,//车辆品牌ID
          // "id": 1,
          "brandName": this.car.brandName,//车辆品牌名称
          "seriesId": this.car.seriesId,//车辆车系ID
          "seriesName": this.car.seriesName,//车辆车系名称
          "modelId": this.car.modelId,//车辆车型ID
          "modelName": this.car.modelName,
          "plateColor": this.form.plateColor,//车牌颜色，8蓝牌，9黄牌，10黑牌，11绿牌
          "boardShowKmNums": this.form.boardShowKmNums,//公里数
          "isWade": this.form.isWade,//是否涉水车：51否, 52是
          "isChangeEvenNum": this.form.isChangeEvenNum,//是否改过公里数：61否, 62是，63不确定
          "carOwnerNature": this.form.carOwnerNature,//车辆持有人性质，1当地籍，2非当地籍，3港澳,4台胞，5外籍，6军官，7公司
          "seaGather": this.form.seaGather,//海车集采集, 已录，未录
          "idFaceValid": this.form.idFaceValid,//身份证刷验, 已刷，未刷
          "idCopy": this.form.idCopy,//车主证件复印件, 有，无
          customerIdNo:customerIdNo,//身份证
          licenseNo:businessLicense,//营业执照编号
          businessLicense:businessLicense,//营业执照编号
          certNo:certNo,
          inOrOut:this.form.inOrOut,//进口国产
          "account": this.car.isChannelCar===72?this.channelAccount.account:this.form.account?this.form.account.trim():this.form.account,//银行账户
          "accountName": this.car.isChannelCar===72?this.channelAccount.accountName:this.form.accountName,//账户名
          "depositBank": this.car.isChannelCar===72?this.channelAccount.depositBank:this.form.depositBank,//开户行
          "depositBankShortName": this.car.isChannelCar===72?this.channelAccount.depositBankShortName:this.form.depositBankShortName,//银行简称
          depositBankShortCode:this.form.depositBankShortCode,//总行编号
          "provinceId": this.selectArea.provinceId,//省ID
          "provinceName": this.selectArea.provinceName,
          "cityId": this.selectArea.cityId,//市ID
          cityNo: this.selectArea.cityNo,//市编号
          "cityName": this.selectArea.cityName,
          // "areaId": this.selectArea.areaId,//区ID
          // "areaName": this.selectArea.areaName,
          // "address": this.form.bankCustomerForm.address,//地址
          "channelId": this.car.channel?this.car.channel.id:'',//渠道ID
          "channelAccountId": this.form.channelAccountId,//渠道账号ID
          customerName:this.form.customerName,
          customerMobile:this.form.customerMobile,
          plateRegWay:this.form.plateRegWay,
          applyShNewEngine:this.form.applyShNewEngine,
          registerDate:this.form.registerDate,//初次登记日期
          engineNo:this.form.engineNo,//发动机号
          carColor:this.form.carColor,//颜色
          vinCode:this.form.vinCode,//车架号
          checkExpire:checkExpire,//年检有效期
          makerId:this.form.makerId,
          makerName:this.form.makerName,
          seriesId:this.form.seriesId,
          seriesName:this.form.seriesName,
          // form.carModel
        },
        customerMaterialsList:newIdCarArr,//车辆车主证件的材料列表
        otherMaterialsList:newCarArr,//车辆除车主证件之外的材料列表
        "contractRequireFormList": arrCheck//合同要求列表(多个复选框)
      }
      
      return query
    },
    updateContract(saveType){ //更新 客户合同（保存操作）
    // let otherMaterialsListFF=JSON.parse(JSON.stringify(this.car.otherMaterialsList))  
      // for(let i=0;i<otherMaterialsListFF.length;i++){
      //   let item=otherMaterialsListFF[i]
      //   switch(item.type){
      //     case 'car':
      //       if(item.materialName.indexOf('钥匙')!=-1){
      //         let pdaData=item.pdaData&&item.pdaData!='无'?Number(item.pdaData.split('把')[0]):0;//PDA 采集数据
      //         let a1=item.options1?Number(item.options1):0;//缺
      //         let a2=item.options2?Number(item.options2):0;//暂缺
      //         let a3=item.realData?Number(item.realData):0;//实际情况
      //         if(pdaData<(a1+a2+a3)){
      //           this.$message.warning('钥匙数量与PDA数据不一致!')
      //           return
      //         }
      //       }
      //       break;
      //   }
      // }
      if(!this.car.makerId&&!this.form.makerName||!this.car.makerId&&!this.form.seriesName){
        this.$message.warning('厂牌型号不可为空!')
        return
      }
      this.$api.updateContract(this.contractId,this.updateQuery()).then((res)=>{
        if(res){
          if(res.code==0){
            if(saveType==='make'){
              this.getCustomerCheck('make')
              return
            }else if(saveType==='sign_success'){
              this.getCustomerCheck('sign_success')
              return
            }
            this.changTypeSave=false;//是否操作保存按钮
            this.deepType=false;
            this.getCustomerDetail()
            this.$message.success(res.message)
          }
        }
      })
    },
    getCustomerDetail(pintFlag){//获取详情
      if(!pintFlag){
        this.accountList=[]//银行账户列表
        this.carMaterialsList=[];//车辆材料列表
        this.idcardlist=[];//身份证列表
        this.carlistPro=[];//车辆手续列表
        this.contractRequireList=[];//合同列表（所有复选框）
        this.radiolist=[];
        this.checkList= [];
        this.checkedList=[];//以选择的列表
        this.checkList2= [];//过户凭证特殊要求列表
        this.checkedList2=[];//以选择的列表
        this.customChecklist=[{},{},{}]//自定义复选框和输入框
      }
      
      this.$api.getCustomerDetail(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            if(pintFlag){
              this.res=JSON.parse(JSON.stringify(res.data));
              this.printModel=true;
              return
            }
            //是否展示签订失败的页面
            this.contractFailedType=res.data.contractStatus===15?false:true;
            //是否是当前合同领取人操作合同
            if(this.user.no===res.data.sellerNo){
              this.loginUserType=true;
            }
            
            //客户合同状态，11待领取，12制作中，13签订中，14签订成功，15签订失败，16待付款，17已付款
            // this.stateType=0 查看合同 1制作合同 2载入合同
            if(this.$route.query.state==0){
              this.stateType=0;
              this.disabledAll=true;
            }else{
              switch(res.data.contractStatus){
                case 12:
                  this.stateType=1;
                  break;
                case 13:
                  this.stateType=2;
                  break;
                  default:
                  this.stateType=0;
                  this.disabledAll=true;
              }
            }
            this.dataForm=JSON.parse(JSON.stringify(res.data));
            this.car=JSON.parse(JSON.stringify(res.data.car));
            
            this.carValid=res.data.carValid?JSON.parse(JSON.stringify(res.data.carValid)):{};
            this.queryCarOwnerNature()//车辆持有人性质 列表查询
            if(this.stateType){
              this.postFinanceIntentionMoneyList()//条件查询[不分页]意向金列表
            }
            

            if(res.data.isChannelCar==72&&res.data.channelId){//是否渠道车辆，71：否，72是，默认71
              this.getByChannelIdDetail(this.dataForm.channelId);//获取银行卡账户
            }
            
            if(this.car.channel){//4s店表单信息
              this.channelForm={
                extensionerName:this.car.channel.extensionerName,
                shortName:this.car.channel.shortName,
              }
            }
            
            if(!this.car.carCustomer){//客户信息（车主信息）
              this.car.carCustomer={};
            }else{
              //客户的银行信息
              if(this.car.isChannelCar==71){
                this.bankCustomerForm=this.car.carCustomer.carCustomerAccount?this.car.carCustomer.carCustomerAccount:{};
              }
            }
            //渠道的银行信息
            if(this.car.isChannelCar==72){
              if(res.data.contractStatus<14){
                if(this.car.channel){
                  if(this.car.channel.accountList) for(let i=0;i<this.car.channel.accountList.length;i++){
                    let its=this.car.channel.accountList[i]
                    if(its.accountType===0){
                      this.channelAccount={
                        accountName:its.accountName,
                        depositBankShortName:its.bankShortName,
                        account:its.account,
                        depositBank:its.bank,
                      }
                    }
                  }
                }
              }else{
                this.channelAccount={
                  accountName:res.data.accountName,
                  depositBankShortName:res.data.depositBankShortName,
                  account:res.data.account,
                  depositBank:res.data.depositBank,
                }
              }
              
            }
            this.form.inOrOut=this.car.inOrOut;//进口国产
            this.form.plateColor=this.car.plateColor;//车牌颜色
            this.form.boardShowKmNums=this.car.boardShowKmNums;//公里数
            this.form.isWade=this.car.isWade;//是否涉水车
            this.form.isChangeEvenNum=this.car.isChangeEvenNum;//是否改过公里数

            this.form.carOwnerNature=this.car.carCustomer.carOwnerNature;//车辆持有人性质
            this.form.carOwnerNatureName=this.car.carCustomer.carOwnerNatureName;
            this.form.publicOrPrivate=this.car.publicOrPrivate;//公私性质
            this.form.customerName=this.car.carCustomer.customerName;//出售委托人（甲方）
            
            this.form.customerMobile=this.car.carCustomer.customerMobile;//联系电话
            this.form.plateRegWay=this.car.plateRegWay;
            this.form.applyShNewEngine=this.car.applyShNewEngine;
            this.form.seaGather=this.car.carCustomer.seaGather;//海车集采集
            this.form.idFaceValid=this.car.carCustomer.idFaceValid;//身份证刷验
            this.form.idCopy=this.car.carCustomer.idCopy;//车主证件复印件
            this.form.registerDate=this.car.registerDate;//初次登记日期
            this.form.engineNo=this.car.engineNo;//发动机号
            this.form.carColor=this.car.carColor;//颜色
            this.form.vinCode=this.car.vinCode;//车架号
            this.form.checkExpire=this.car.checkExpire;//年检有效期
            if(this.car.seriesId&&this.car.makerId){
              this.form.carModel=[this.car.makerId,this.car.seriesId]
            }
            

            this.form.makerId=this.car.makerId;
            this.form.makerName=this.car.makerName;
            this.form.seriesId=this.car.seriesId;
            this.form.seriesName=this.car.seriesName;

            this.form.carManagerZt=this.car.carManagerZt;
            


            //银行信息
            this.form.account=this.bankCustomerForm.account;
            this.form.accountName=this.bankCustomerForm.accountName;
            this.form.depositBank=this.bankCustomerForm.depositBank?this.bankCustomerForm.depositBank:this.bankCustomerForm.bank;//开户行

            this.form.depositBankShortName=this.bankCustomerForm.depositBankShortName?this.bankCustomerForm.depositBankShortName:this.bankCustomerForm.bankShortName;//银行简称
            
            this.form.depositBankShortObj=this.bankCustomerForm.depositBankShortName;
            this.form.depositBankShortCode=this.bankCustomerForm.depositBankShortCode;
            this.form.channelAccountId=this.res.channelAccountId;
            this.addressArr=[this.bankCustomerForm.provinceId,this.bankCustomerForm.cityId]
            this.selectArea={
              provinceId:this.bankCustomerForm.provinceId,
              provinceName:this.bankCustomerForm.provinceName,
              cityId:this.bankCustomerForm.cityId,
              cityName:this.bankCustomerForm.cityName,
              cityNo:this.bankCustomerForm.cityNo,
              areaId:this.bankCustomerForm.areaId,
              areaName:this.bankCustomerForm.areaName
            }//三级联动

            this.indemnifyRsp=res.data.indemnifyRsp?res.data.indemnifyRsp:{};//失败的客户详情

            //合同要求列表(2个复选列表)
            let contractRequireList=res.data.contractRequireList;
            this.contractRequireList=res.data.contractRequireList;
            for(let i=0;i<contractRequireList.length;i++){
              let item=contractRequireList[i]
              //要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
              switch(item.requireType){
                case 11:
                  if(item.radioOrCheck=='radio'){
                    if(item.isSelected==1){
                      this.checkForm.radio=item.id
                    }
                    this.radiolist.push(item)
                  }else{
                    if(item.isSelfDefined==2){
                      item.isSelected=item.isSelected==1?true:item.isSelected==0?false:item.isSelected
                      this.customChecklist.unshift(item)
                    }else{
                      if(item.isSelected==1){
                        this.checkedList2.push(item.id)//选中
                      }
                      this.checkList2.push(item)//过户凭证特殊要求
                    }
                  }
                  break;
                case 12:
                  if(item.isSelected==1){
                    this.checkedList.push(item.id)//选中
                  }
                  this.checkList.push(item)//过户凭证特殊要求
                  break;
                case 13:
                  if(this.car.isPledge===42){//isPledge是否抵押车42是
                    if(item.requireName){
                      let aat=item.requireName.split('且在')
                      let aat1=aat[1].split('前自行完成')
                      let ac=aat1[0]
                      this.form.covenantsStr2=ac=='null'?'':ac;
                    }
                  }else{
                    this.covenants=item.requireName;
                  }
                  break;
              }
            }
            this.checkList2.sort((a,b)=>{//排序过户凭证特殊要求
              return a.sort - b.sort
            })
            this.checkList.sort((a,b)=>{//排序材料取回约定表
              return a.sort - b.sort
            })
            // console.log(this.checkList,'===this.checkList2==')
            if(this.customChecklist.length>3){
              let num=this.customChecklist.length-3;
              let cont=0;
              for(let i=0;i<this.customChecklist.length;i++){
                let item=this.customChecklist[i];
                if(JSON.stringify(item)=='{}'){
                  cont++
                  if(cont<=num){
                    this.customChecklist.splice(i,1)
                  }
                }
              }
            }
            //测试专用
            // this.car.isHuPlate=1;//是否沪牌，1是,2否，默认1
            // this.car.isNewEnergy=82//是否新能源车，81否，82是,默认81
            // this.car.isChannelCar=71;//渠道4s(是否渠道车辆，71：否，72是)
            //测试结束

            //车主证件列表
            for(let i=0;i<this.car.customerMaterialsList.length;i++){
              let item=this.car.customerMaterialsList[i]
              if(item.realData){
                if(item.realData.indexOf(',')!=-1){
                  let a=item.realData.split(',')
                  item.realData1=a[0];
                  item.realData2=a[1];
                  item.realData3=a[2];
                }else{
                  item.realData1=item.realData;
                }
              }else{
                item.realData1='';
                item.realData2='';
                item.realData3='';
              }
              if(item.remark){
                if(item.remark.indexOf('|')!=-1){
                  let a=item.remark.split('|')
                  if(a[0]){
                    item.remark1=a[0]=='true'?true:'';
                  }
                  if(a[1]){
                    if(a[1].indexOf(',')!=-1){
                      item.remark2=[a[1].split(',')[0],a[1].split(',')[1]];
                    }
                  }
                  if(a[2]){
                    item.remark3=a[2]=='true'?true:'';
                    // item.remark3=JSON.parse(a[2]);
                  }
                  item.remark4=a[3];
                }
              }
              this.idcardlist.push(item)//身份证列表
            }

            //车辆材料列表
            let cardatalist=this.car.otherMaterialsList;
            let TankerArr=[];//油车
            let energyArr=[];//电车
            let nullobj=new Object;
            let isSelectedCheck=false;
            for(let i=0;i<cardatalist.length;i++){
              let item=cardatalist[i];
              item.options1='';
              item.options2='';
              item.options3='';
              item.options4='';
             item.feeBearerNameObj={
                id:item.feeBearerId,
                name:item.feeBearerName
              }
              if(cardatalist[i]){
                nullobj=item;
                
                switch(cardatalist[i].type){
                  case 'car':
                    if(item.pdaData=='无'&&item.materialName.indexOf('车辆牌照')!=-1){
                      item.feeBearerId=2;
                      item.feeBearerName='商户';
                    }else if(item.pdaData=='无'&&['桩充','线充','备胎'].indexOf(item.materialName)!=-1){
                      item.feeBearerId=1;
                      item.feeBearerName='客户';
                    }else if(item.materialName.indexOf('保险单')!=-1){
                      item.options1=item.options?item.options.split(','):[]
                    }else if(item.materialName.indexOf('钥匙')!=-1){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                      }
                    }else if(['机动车登记证书','机动车行驶证'].includes(item.materialName)){
                     if(item.options){
                       if(['缺'].indexOf(item.realData)!=-1){
                         item.options4=item.options=='补办'?true:'';
                       }else if(['暂缺'].indexOf(item.realData)!=-1){
                         item.options5=item.options;
                       }else if(['有'].indexOf(item.realData)!=-1){
                         if(['机动车行驶证'].includes(item.materialName)){
                          item.options1=item.options.split(',')[0]
                          item.options3=item.options.split(',')[1]
                         }else{
                          item.options1=item.options.split(',')[0]
                          item.options2=item.options.split(',')[1]
                          item.options3=item.options.split(',')[2]
                         }
                        
                       }else{
                        item.options1='';
                        item.options2='';
                        item.options3='';
                        item.options4='';
                        item.options5='';
                       }
                      }
                    }else if(item.materialName=='违章'){
                      if(item.options){
                        item.options1=item.options.split(',')[0]
                        item.options2=item.options.split(',')[1]
                        item.options3=item.options.split(',')[2]
                        item.options4=item.options.split(',')[3]
                        item.options5=item.options.split(',')[4]
                        // item.options6=item.options.split(',')[5]?Boolean(item.options.split(',')[5]):item.options.split(',')[5]
                      }
                    }else if(item.materialName.indexOf('变更审核单')!=-1){
                      if(['有','暂缺'].includes(item.realData)){
                        this.limitFlag=true;
                      }else{
                        this.limitFlag=false;
                      }
                    }
                    /**-- 需要询问4S点费用，只有“沪电动车牌”会体现这个字段 
                    *--isHuPlate是否沪牌，1是,2否，默认1,isNewEnergy是否新能源车，81否，82是,默认81**/
                    // if(this.car.isHuPlate==1&&this.car.isNewEnergy!=82&&['桩充','线充'].indexOf(cardatalist[i].materialName)==-1){
                    //   TankerArr.push(cardatalist[i])//油车
                    // }
                    if(this.car.isNewEnergy!=82&&['桩充','线充'].indexOf(cardatalist[i].materialName)==-1){
                      TankerArr.push(cardatalist[i])//油车
                    }
                    energyArr.push(cardatalist[i])//电车
                    
                    break;
                  case 'procedure'://手续
                    if(cardatalist[i].materialName.indexOf('父母')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=0;
                    }else if(cardatalist[i].materialName.indexOf('夫妻')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=1;
                    }else if(cardatalist[i].materialName.indexOf('抬头')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=2;
                    }else if(cardatalist[i].materialName.indexOf('合并')!=-1&&cardatalist[i].isSelected==1){
                      cardatalist[i].materialName1=3;
                    }

                    cardatalist[i].feeBearerNameObj={
                      id:cardatalist[i].feeBearerId,
                      name:cardatalist[i].feeBearerName
                    }
                    cardatalist[i].realData=cardatalist[i].realData==='是'?true:'';
                    
                    if(cardatalist[i].materialName.indexOf('额度变更')!=-1){
                      if(cardatalist[i].isSelected==1){
                        this.carlistPro.push(cardatalist[i])//车辆手续
                        isSelectedCheck=true;
                      }
                    }else if(cardatalist[i].materialName.indexOf('额度变更')==-1){
                        this.carlistPro.push(cardatalist[i])//车辆手续
                    }
                    
                    
                    break; 
                }
              }
            }
            //isNewEnergy是否新能源车，81否，82是,默认81
            if(this.car.isNewEnergy==82){
              this.carMaterialsList=energyArr;
            }else{
              this.carMaterialsList=TankerArr;//油车
            }
            //清空对象值
            let newObj=JSON.parse(JSON.stringify(nullobj))
            Object.keys(newObj).forEach(key => newObj[key] = '')
            if(isSelectedCheck==false){
              newObj.materialName='额度变更';
              this.carlistPro.splice(1,0,newObj)
            }
            //获取自动计算的价格
            this.payForm={
              totalPayAmountCn:this.dataForm.totalPayAmountCn,
              totalPayAmount:this.dataForm.totalPayAmount,
              carTradedPriceCn:this.dataForm.carTradedPriceCn,
              carTradedPrice:this.dataForm.carTradedPrice,
              feeDepositTotalAmountCn:this.dataForm.feeDepositTotalAmountCn,
              feeDepositTotalAmount:this.dataForm.feeDepositTotalAmount,
              riskDepositTotalAmountCn:this.dataForm.riskDepositTotalAmountCn,
              riskDepositTotalAmount:this.dataForm.riskDepositTotalAmount,
              intentionMoneyAmount:this.dataForm.intentionMoneyAmount,
              intentionMoneyAmountCn:this.dataForm.intentionMoneyAmountCn,
              channelSubsidyAmount:this.dataForm.channelSubsidyAmount,
              channelSubsidyAmountCn:this.dataForm.channelSubsidyAmountCn,
            }
            this.tradedCarCertDate=this.dataForm.customerDeadline;
            
            this.$nextTick(()=>{
              if(this.$refs.form){
                this.$refs['form'].clearValidate()
              }
            })
            setTimeout(() => {
              this.deepType=true;//触发监听
              // this.forceupdate()
            }, 3000);
          }
        }
      })
    },
    dialogVisibleSave(){
      this.$refs['modalForm'].validate(valid => {
        if (valid) {
          this.getCustomerSignFail()
        }
      });
      // if(this.modalForm.responsibleParty==2){
        
      // }else{
      //   this.$refs.modalForm.validateField("responsibleParty", err => {
      //     if (!err) {
      //       this.getCustomerSignFail()
      //     }
      //   });
      // }
    },
    saveContract(state){//操作合同按钮
      switch(state){
        case '提交合同':
          if(this.financePayStatus===32){
            this.$message.warning('财务目前处理付款中状态，请稍后重试！')
            return
          }
          //校验
          var validType=false;
          this.$refs['form'].validate(valid => {
            if (valid) {
              validType=true;
            }else{
              this.$nextTick(() => {
                var isError = document.getElementsByClassName("is-error");
                isError[0].scrollIntoView({
                  // 滚动到指定节点
                  // 值有start,center,end，nearest
                  block: "center",
                  // 值有auto、instant,smooth，缓动动画
                  behavior: "smooth",
                });
              });
            }
          });
          if(!validType){
            return
          }
          //车主证件信息列表
          var idcardlistFF=JSON.parse(JSON.stringify(this.idcardlist))  
          for(let i=0;i<idcardlistFF.length;i++){
            let item=idcardlistFF[i]
            if(!item.realData1){
              this.$message({
                type:'warning',
                message:`${item.materialName}状态不可为空！`
              })
              return
            }
            if(item.materialName.indexOf('身份证')!=-1||item.materialName.indexOf('通行证')!=-1){
              if(!item.realData2){
                this.$message({
                  type:'warning',
                  message:`${item.materialName}证件号码不可为空！`
                })
                return
              }
              // if(item.realData1==='暂缺'&&!item.realData3){
              //   this.$message({
              //     type:'warning',
              //     message:`${item.materialName}提供日期不可为空！`
              //   })
              //   return
              // }
            }
          }
          //车辆材料列表校验必填项  
          var carMaterialsListFF=JSON.parse(JSON.stringify(this.carMaterialsList))    
          for(let i=0;i<carMaterialsListFF.length;i++){
            let item=carMaterialsListFF[i];
            if(!item.feeBearerName&&item.feeAmount||!item.feeBearerName&&item.depositAmount){
                /**isNewEnergy是否新能源车，81否，82是,默认81**/ 
                if(this.form.isNewEnergy!=82&&['桩充','线充'].indexOf(item.materialName)==-1){
                  this.$message({
                    type:'warning',
                    message:`${item.materialName}费用承担方不可为空！`
                  })
                  return
                }else if(this.form.isNewEnergy==82){
                  this.$message({
                    type:'warning',
                    message:`${item.materialName}费用承担方不可为空！`
                  })
                  return
                }
                
            }
            if(item.materialName=='车辆牌照'){
              if(!item.realData){
                this.$message({
                  type:'warning',
                  message:'请选择车辆牌照的实际情况！'
                })
                return
              }
              // console.log(item.realData,item.options,'====options5===',item.differenceOptions)
              if(item.realData=='暂缺'&&!item.options){
                this.$message({
                  type:'warning',
                  message:'请选择车辆牌照的提供日期！'
                })
                return
              }
              if(item.realData=='缺'&&!item.differenceOptions){
                this.$message({
                  type:'warning',
                  message:'请选择车辆牌照的差异选项！'
                })
                return
              }
            }else if(item.materialName=='备胎'&&['有'].includes(item.pdaData)){
              if(!item.realData){
                this.$message({
                  type:'warning',
                  message:'请选择备胎的实际情况！'
                })
                return
              }
              if(item.realData=='暂缺'&&!item.options){
                this.$message({
                  type:'warning',
                  message:'请选择备胎的提供日期！'
                })
                return
              }
              // if(item.realData=='暂缺'&&!item.differenceOptions){
              //   this.$message({
              //     type:'warning',
              //     message:'请选择备胎的差异选项！'
              //   })
              //   return
              // }
              if(item.realData=='暂缺'&&!item.depositAmount){
                this.$message({
                  type:'warning',
                  message:'请填写备胎的押金！'
                })
                return
              }
              if(item.realData=='缺'&&!item.feeAmount){
                this.$message({
                  type:'warning',
                  message:'请填写备胎的费用！'
                })
                return
              }
            }else if(item.materialName.indexOf('登记证书')!=-1){
              if(!item.realData){
                this.$message({
                  type:'warning',
                  message:'请选择机动车登记证书的实际情况！'
                })
                return
              }else if(item.realData=='有'){
                if(!item.options1){
                  this.$message({
                    type:'warning',
                    message:'请填写机动车登记证书的选项！'
                  })
                  return
                }
                
                if(!item.options2&&this.car.isHuPlate===1){
                  this.$message({
                    type:'warning',
                    message:'请填写机动车登记证书的产证编号！'
                  })
                  return
                }
                if(item.options1.indexOf('变更')!=-1&&!item.options3){
                  this.$message({
                    type:'warning',
                    message:'请填写机动车登记证书的变更内容！'
                  })
                  return
                }
              }else if(item.realData=='缺'&&!item.differenceOptions){
                this.$message({
                  type:'warning',
                  message:'请选择机动车登记证书的差异选项！'
                })
                return
              }else if(item.realData=='暂缺'&&!item.options5){
                this.$message({
                  type:'warning',
                  message:'请填写机动车登记证书的提供日期！'
                })
                return
              }
            }else if(item.materialName.indexOf('行驶证')!=-1){
              if(!item.realData){
                this.$message({
                  type:'warning',
                  message:'请选择机动车行驶证的实际情况！'
                })
                return
              }else if(item.realData=='有'){
                if(!item.options1){
                  this.$message({
                    type:'warning',
                    message:'请填写机动车行驶证的选项！'
                  })
                  return
                }
                // if(!item.options2&&this.car.isHuPlate===1){
                //   this.$message({
                //     type:'warning',
                //     message:'请填写机动车行驶证的产证编号！'
                //   })
                //   return
                // }
                if(item.options1.indexOf('变更')!=-1&&!item.options3){
                  this.$message({
                    type:'warning',
                    message:'请填写机动车行驶证的变更内容！'
                  })
                  return
                }
              }else if(item.realData=='暂缺'&&!item.options5){
                this.$message({
                  type:'warning',
                  message:'请填写机动车行驶证的提供日期！'
                })
                return
              }
            }else if(item.materialName.indexOf('保险单')!=-1){
              // if(!item.realData){
              //   this.$message({
              //     type:'warning',
              //     message:'请选择机动车交通事故责任强制保险单的实际情况！'
              //   })
              //   return
              // }
              if(item.realData=='有'&&item.options1.length==0&&!['无'].includes(item.pdaData)){
                this.$message({
                  type:'warning',
                  message:'请选择机动车交通事故责任强制保险单的选项！'
                })
                return
              }else if(item.realData=='缺'&&!item.feeAmount&&!['无'].includes(item.pdaData)){
                this.$message({
                  type:'warning',
                  message:'请填写机动车交通事故责任强制保险单的费用！'
                })
                return
              }else if(item.realData=='暂缺'&&!item.depositAmount&&!['无'].includes(item.pdaData)){
                this.$message({
                  type:'warning',
                  message:'请填写机动车交通事故责任强制保险单的押金！'
                })
                return
              }
            }
          }

          for(let i=0;i<this.carlistPro.length;i++){
            let item=this.carlistPro[i]
            if(item.materialName!='办证加急'&&item.realData&&!item.nature){
              this.$message.warning(`${item.materialName}的补办要求不可为空！`)
              return
            }
          }
          if(this.changTypeSave==true){
            this.$message({
              type:'warning',
              message:'请先操作保存按钮！'
            })
            return
          }
          // console.log('---sub--')
          // return
          this.updateContract('make')
          // this.getCustomerCheck('make')
          break;
        case '签订成功':
          if(this.changTypeSave==true){
            this.$message({
              type:'warning',
              message:'请先操作保存按钮！'
            })
            return
          }
          this.updateContract('sign_success')
          // this.getCustomerCheck('sign_success')
          break;
        case '签订失败':
          if(this.financePayStatus===32){
            this.$message.warning('财务目前处理付款中状态，请稍后重试！')
            return
          }
          this.dialogVisible=!this.dialogVisible;
          break;
        case '放弃付意向金':
          this.$confirm('你确认要放弃付意向金吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$api.postCustomerGiveUpPay({payId:this.dataForm.intentionMoneyAmountPayId}).then(res=>{
              if(res.code==0){
                this.getCustomerDetail();//获取详情
                this.$message.success(res.message)
              }
            })
          }).catch(()=>{})
          
          break;
        case '作废合同':
          this.$confirm('你确认要作废当前合同吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
             this.deleteCustomer()
          }).catch(()=>{})
          break;
        case '打印合同':
          this.$confirm('是否已查询违章信息?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({
              path:'/salesManager/printCarSales',
              query:{
                id:this.contractId,
                print:1,//防止给其他人发链接误掉接口
              }
            })
          }).catch(() => {});
          
          // this.getCustomerDetail('print');//获取详情
          break;
      }
    },
    onChauffeur(e){//选择金额（车开新赔偿客户）只有渠道车
      if(e===0){
        this.modalForm.remark='提车前'
      }else{
        this.modalForm.remark='提车后'
      }
    },
    getCustomerCheck(checkType){//领取/制作/签订 合同
    //checkType操作类型，领取get, 制作(提交合同)make, 签订成功sign_success，签订失败sign_fail
      this.$api.getCustomerCheck(this.contractId,checkType).then((res)=>{
        if(res){
          if(res.code==0){
            //checkType操作类型，领取get, 制作(提交合同)make, 签订成功sign_success，签订失败sign_fail
            // switch(checkType){
            //   case 'make':
            //     // this.routePage('third')
                
            //     break;
            //   case 'sign_success':
            //     this.routePage('fourth')
            //     break;
            //   case 'sign_fail':
            //     this.routePage('fifth')
            //     break;
            // }
            this.getCustomerDetail();//获取详情
            this.$message.success(res.message);
          }
        }
      })
    },
    getCustomerSignFail(){//签订失败
      let query={
        "responsibleParty":  this.modalForm.responsibleParty,//责任方: 51客户责任，52商户责任，53检测差异，54车开新责任
        // "breakType": this.modalForm.merchantBreakType,//违约类型: 11报价放弃，12成交预约违约，13预约确认违约，14成交差异违约
        "carId": this.car.id,
        "carAuctionId": this.car.carAuctionId,
        "merchantId": this.dataForm.merchantId,
        "remark": this.modalForm.remark,
        chauffeur:this.modalForm.chauffeur,
      }
      this.$api.getCustomerSignFail(this.contractId,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.routePage('fifth')
          this.$message.success(res.message);
        }
      })
    },
    deleteCustomer(){//删除/作废 客户合同
      this.$api.deleteCustomer(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            this.$message.success(res.message);
            this.routePage()
          }
        }
      })
    },
    routePage(state){
      this.bus.$emit("closeSelected",'router');
      let query;
      if(state){
        query={
          state:state
        }
      }
      this.$router.push({
        path:'/salesManager/signContract',
        query
      })
    },
    postContractPrint(){//根据 合同id 设置合同为已打印状态
      this.$api.postContractPrint(this.contractId).then(()=>{})
    },
    onfeeSelect(row){//只用来处理费用承担方的拉下
      for(let i=0;i<this.carMaterialsList.length;i++){
        let item=this.carMaterialsList[i]
        if(item.id===row.id){
          item.feeBearerName=row.feeBearerNameObj.name;
          item.feeBearerId=row.feeBearerNameObj.id;
          
          item.operateFlag=true;//是否操作了
        }
      }
    },
    changeUpdate($event,item){
      // console.log($event,item,'---222---')
      //作为计算判断条件触发
      if(item){
        // if(item.materialName.indexOf('钥匙')!=-1){
        //   let pdaData=item.pdaData&&item.pdaData!='无'?Number(item.pdaData.split('把')[0]):0;//PDA 采集数据
        //   let a1=item.options1?Number(item.options1):0;//缺
        //   let a2=item.options2?Number(item.options2):0;//暂缺
        //   let a3=item.realData?Number(item.realData):0;//实际情况
        //   if(pdaData<(a1+a2+a3)){
        //     this.$message.warning('钥匙数量与PDA数据不一致!')
        //   }
        //   this.changeDem(item)
        // }else 
        if(item.materialName.indexOf('机动车登记证书')!=-1||item.materialName.indexOf('机动车行驶证')!=-1){
          this.changeDem(item)
        }else if(item.materialName.indexOf('额度变更')!=-1){
          // this.onCarFeer(item)//修改费用
          switch(item.materialName1){
            case 0:
              this.postFeeStandardList('额度变更-父母与子女',2)
              break;
            case 1:
              this.postFeeStandardList('额度变更-夫妻变更',2)
              break;
            case 2:
              this.postFeeStandardList('额度变更-公司抬头变更',2)
              break;
          }
          //  this.postFeeStandardList(item.materialName,2)
        }
      }
      
      this.changTypeSave=true;
      this.$forceUpdate()
    },
    onCarFeer(row){
      for(let i=0;i<this.carlistPro.length;i++){
        let item=this.carlistPro[i]
        if(item.materialName.indexOf('额度变更')!=-1){
          if(item.realData===true||item.realData==='是'){
            switch(item.materialName1){
              case 0:
                item.feeAmount=1000;
                break;
              case 1:
                item.feeAmount=300;
                break;
              case 2:
                item.feeAmount=300;
                break;
            }
          }
        }
      }
    },
    onChangBank(e){
      this.form.depositBankShortName=e.bankName;
      this.form.depositBankShortCode=e.bankCode;
      this.changTypeSave=true;
      this.$forceUpdate()
    },
    remoteMethod(e){
      if(e){
        this.loading = true;
        this.postbankList(e)
      }
    },
    onBankTage(row){
      this.form.depositBank=row.bankName;
      this.bankVis=false;
    },
    onInputBank(e){
      if(e){
        this.bankVis=true;
        this.postbankList(e)
      }else{
        this.bankVis=false;
      }
    },
    postbankHeadList(){//查询银行简称
      this.$api.postbankHeadList({}).then(res=>{
        if(res.code==0){
          this.banklist=res.data;
        }
      })
    },
    postbankList(bankName){//根据银行简称
      let query={
        bankName:bankName,
        headBankCode:this.form.depositBankShortCode,
        cityNo:this.selectArea.cityNo,
      }
      this.$api.postbankList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.banklistSear=res.data;
        }
      })
    },
    queryPlateColor(){//车牌颜色 列表查询
      this.$api.queryPlateColor().then((res)=>{
        if(res){
          if(res.code==0){
            this.colorlist=res.data;
          }
        }
      })
    },
    queryCarOwnerNature(){//车辆持有人性质 列表查询
      this.$api.queryCarOwnerNature(this.car.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.carOwnerNaturelist=res.data;
          }
        }
      })
    },
    getcertDate(){//自动计算办证日期约定 客户合同中的约定完成办证手续日期
      this.$api.getcertDate(this.contractId,this.updateQuery()).then((res)=>{
        if(res){
          if(res.code==0){
            this.tradedCarCertDate=res.data;
          }
        }
      })
    },
    getCalculatePlay(){//自动计算委托出售价格 客户合同中的押金与费用以及应支付的车款
      this.$api.getCalculatePlay(this.contractId,this.updateQuery()).then((res)=>{
        if(res){
          if(res.code==0){
            this.payForm=res.data;
          }
        }
      })
    },
    getfullPlay(){//委托出售价格,全押车款, 客户合同
      this.$api.getfullPlay(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            this.payForm=res.data;
          }
        }
      })
    },
    getByChannelIdDetail(channelId){//获取银行账户
      this.$api.getByChannelIdDetail(channelId).then((res)=>{
        if(res){
          if(res.code==0){
            this.accountList=res.data.accountList;
          }
        }
      })
    },
    deleteRequire(requireIds){//删除/作废 客户合同要求
      this.$api.deleteRequire(requireIds).then((res)=>{
        if(res){
          if(res.code==0){
            this.$message.success(res.message);
          }
        }
      })
    },
    postFinanceIntentionMoneyList(){//条件查询[不分页]意向金列表
      let query={
        // enquiryId:this.dataForm.carAuctionId,//carId
        // merchantId:this.dataForm.merchantId,
        customerContractId:this.contractId,
        payTypes:[1],
        financePayStatuses:[31,32,33,34]
      }
      this.$api.postFinanceIntentionMoneyList(query).then(res=>{
        if(res.code==0){
          if(res.data.length>0){
            this.IntentionType=res.data[0].financePayStatus==31||res.data[0].financePayStatus==34?true:false;//判断展示放弃意向金按钮
            this.payId=res.data[0].financePayStatus==31?res.data[0].id:'';
            this.financePayStatus=res.data.financePayStatus;
          }else{
            this.IntentionType=false;
          }
        }
      })
    },
    postFeeStandardList(itemName,type) {//办证费用标准 列表查询
      let query = {
        // curPage: this.currentPage, //当前页数
        // pageSize: this.pageSize,
        itemName:itemName,
        type:type//类型：1标准服务收费，2延伸服务收费，3办证逾期收费
      };
      this.$api.postFeeStandardList(this.$$.delete_null_properties(query)).then((res) => {
        if (res.code == 0) {
          if(res.data.length>0){
            for(let i=0;i<this.carlistPro.length;i++){
              let item=this.carlistPro[i];
              if(itemName.indexOf(item.materialName)!=-1&&item.realData){
                item.feeAmount=res.data[0].chargingFeeAmount;
                item.feeBearerNameObj={
                  id:1,
                  name:'客户'
                }
              }
            }
            this.$forceUpdate()
          }
        }
      });
    },
    onRouter(){//跳到检测报告
      if(!this.car.checkReportId){
        this.$message('当前合同暂无关联报告！')
        return
      }
      let pathData=this.$router.resolve({
        path: '/testReviewer/auditReport', 
        query:{
          id:this.car.checkReportId,
          state:3////2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
        }
      })
      window.open(pathData.href,'_blank')
      // this.$router.push({
      //   path:'/testReviewer/auditReport',
      //   query:{
      //     id:this.car.checkReportId,
      //     state:3////2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
      //   }
      // })
    },
  }
}
</script>

<style scoped lang="scss">
.contractMaking{
  padding: 20px 40px 100px;
  .mr40{margin-right: 40px;}
  .formItemWidth{width: 220px;}
  .bankInput{
    width:450px;
  }
  table{
    border-collapse: collapse;
    width: 100%;
    td{
      border: 1px solid #ccc;
      padding: 10px 20px;
      // text-align: center;
    }
  }
  .carGoods td {padding: 5px 10px;}
  .carInfo td:nth-child(2n+1){background-color: #f5f5f5;}
  .w160{width:160px;}
  // .carProcedures tr td:nth-child(5){min-width:400px;}
  // .carProcedures td{text-align:left}
  ::v-deep .lineInput{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
  }
  ::v-deep .lineInput .el-input__inner{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
  }
  ::v-deep .lineInput .el-input__inner:disabled{
    // background-color: #ffffff;
    color: #333333;
    text-align: center;
  }
  .inputSmall{width: 150px;}
  .inputNum{width: 80px;}
  ::v-deep .inputNum .el-input__inner{
    padding-right:0
  }
  .inputDate{width: 150px;}
  .inputSelect{width: 150px;}
  .inputSelect2{width: 120px;}
  .carGoods{margin-top: 20px;margin-bottom: 20px;}
  .checkboxMar{margin-bottom: 10px;}
  .formSpan{line-height: 40px;}
  .alignLeft{text-align: left;}
  .inputText{width: 220px;}
  .checkList2{
    ::v-deep .el-checkbox-group .el-checkbox{margin-bottom: 10px; font-size: 16x;}
  }
  .size16 {
    ::v-deep .el-checkbox__label{font-size: 16px;}
  }
  .spanwidth{width: 40px;display: inline-block;text-align: right;}
  .textleft{text-align: left;}
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:5;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
  .fontBoxline{
    align-items: flex-end;
    color: #ff0000;
  }
  .lineItemForm{
    margin-bottom:0;
    position: relative;
    top: -8px;
    ::v-deep .el-input__inner{
      color: #ff0000;
    }
  }
  .lineSpan{
    text-decoration: underline;
    line-height: 40px;
    position: relative; /* 为了使用伪元素 */
  }
  
}
.printTop{
  text-align: center;
  position: absolute;
  top:30px;
  left:50%;
  transform: translate(-50%,-50%);
}
.footText{
  font-size:20px;
  color:#F56C6C;
}
::v-deep .dateInput .el-input__inner{
  padding-right:0;
}

</style>
