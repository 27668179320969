import moment from 'moment';


const $$ = {
  /**
   * 时间格式化
   * @param {String} stampTime YYYY-MM-DD hh:mm:ss
   * @param {Date} date 时间
   * @returns 
   */
  getFormatMomentTime: (stampTime,date) => {
    if (!stampTime) {
      stampTime = 'YYYY-MM-DD HH:mm:ss'
    } 
    return moment(new Date(date)).format(stampTime);
  },
  getFormatTime(ss){//时间秒转化时分秒
    var hh=0;
    var mm=0;
    var ss = ~~ss;// 秒
    if(ss>3599){
        hh=Math.floor(ss/3600);
        mm=Math.floor(ss%3600/60);
        ss=ss%60;
        return (hh>9?hh:'0'+hh)+":"+(mm>9?mm:'0'+mm)+":"+(ss>9?ss:'0'+ss);
    }else if(ss>59){
        mm=Math.floor(ss/60);
        ss=ss%60;
        return "00:"+(mm>9?mm:'0'+mm)+":"+(ss>9?ss:'0'+ss);
    }else{
        return "00:00:"+(ss>9?ss:'0'+ss);
    }
  },
  getFormatDate(num){//时间秒转年月日时分秒
      var date = new Date(num * 1000);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var h = date.getHours();
      var i = date.getMinutes();
      var s = date.getSeconds();
      if(m<10){
        m=`0${m}`
      }
      if(d<10){
        d=`0${d}`
      }
      if(h<10){
        h=`0${h}`
      }
      if(i<10){
        i=`0${i}`
      }
      if(s<10){
        s=`0${s}`
      }
      return y + '年' + m + '月' + d + '日 ' + h + ':' + i + ':' + s;
  },
  
  
  /**获取当前时间 年月日**/
  getTody(state){
    let date=new Date().toLocaleDateString()
    let a=date.split('/')
    let aa=a[1]<10?`0${a[1]}`:a[1]
    let a1=a[2]<10?`0${a[2]}`:a[2]
    if(state){
      return `${a[0]}年${aa}月${a1}日`
    }else{
      return `${a[0]}-${aa}-${a1}`
    }
    
  },
  getTodyTime(type){
    /***
     * type:time时分秒
     * type:minute时分
    */
    // 创建一个 Date 对象
    var today = new Date();
    
    // 获取年、月、日、时、分、秒
    var year = today.getFullYear();
    var month = today.getMonth() + 1; // 月份是从 0 开始计数的，需要加1
    var day = today.getDate();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();
    
    // 格式化输出
    var formattedTime = year + "-" + 
                      (month < 10 ? "0" : "") + month + "-" + 
                      (day < 10 ? "0" : "") + day + " " + 
                      (hours < 10 ? "0" : "") + hours + ":" + 
                      (minutes < 10 ? "0" : "") + minutes + ":" + 
                      (seconds < 10 ? "0" : "") + seconds;
    
    if(type==='minute'){
      formattedTime = year + "-" + 
                      (month < 10 ? "0" : "") + month + "-" + 
                      (day < 10 ? "0" : "") + day + " " + 
                      (hours < 10 ? "0" : "") + hours + ":" + 
                      (minutes < 10 ? "0" : "") + minutes
    }
    return formattedTime;
  },
  /**
   * 秒数转化为时分秒
   * @param {value} 秒
   * **/
  formatSeconds(value) {
    var days = parseInt(value / (1000 * 60 * 60 * 24));
    var hours = parseInt((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((value % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = parseInt((value % (1000 * 60)) / 1000);
    return days + "天" + hours + "小时" + minutes + "分钟" + seconds + "秒";
  },
  GetDateStr(AddDayCount) { 
    var dd = new Date(); 
    dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期 
    var y = dd.getFullYear(); 
    var m = dd.getMonth()+1;//获取当前月份的日期 
    var d = dd.getDate(); 
    if(m<10){
      m='0'+m
    }
    if(d<10){
      d='0'+d
    }
    return y+"-"+m+"-"+d; 
    // GetDateStr(-2); //前天
    // GetDateStr(-1); //昨天
    // GetDateStr(0);  //今天
    // GetDateStr(1);  //明天
    // GetDateStr(2);  //后天
    // GetDateStr(3);  //大后天
  },

  /**
   * url查询参数
   * @param {String} name 参数名
   * @returns 参数值
   */
  getQueryStringRegExp(name) { 
    var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
    if (reg.test(location.href)) return unescape(RegExp.$2.replace(/\+/g, " "));
    return "";
  },
  
  /** 
  * 获取最近一段时间的起始和结束时间(不包括本日)
  * @param {Number} range 时间段范围 单位:天
  * @returns 时间范围 
  **/
  getTimeRange(range){
    let endTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    let endYear = endTime.getFullYear();
    let endMonth = endTime.getMonth()> 9 ? (endTime.getMonth() + 1) : "0" + (endTime.getMonth() + 1);
    let endDay = endTime.getDate()> 9 ? (endTime.getDate()) : "0" + (endTime.getDate())
    let endRange = new Date(endYear + '/' + endMonth + '/' + endDay +' 23:59:59')

    let startTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * (range));
    let startYear = startTime.getFullYear();
    let startMonth = startTime.getMonth()> 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1);
    let startDay = startTime.getDate()> 9 ? (startTime.getDate()) : "0" + (startTime.getDate())
    let startRange = new Date(startYear + '/' + startMonth + '/' + startDay +' 00:00:00')
    return [startRange,endRange]
  },
  timeFormat(date) {
    let format = "hh:mm:ss";
    if (date != "Invalid Date") {
      var o = {
        "M+": date.getMonth() + 1, //month
        "d+": date.getDate(), //day
        "h+": date.getHours(), //hour
        "m+": date.getMinutes(), //minute
        "s+": date.getSeconds(), //second
        "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
        S: date.getMilliseconds(), //millisecond
      };
      if (/(y+)/.test(format))
        format = format.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(format))
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return format;
    }
    return "";
  },

  //重新打开新的vue页面选项卡
  goNewPage(path,fullPath) {
    //从跟进客户到发送短信页面,带上客户编号
    if(path==='/sendSMS' && fullPath.indexOf('/onlineService/customer?')>-1){
      path= path + '?' +fullPath.split('?')[1]
    }
    window.open(path, '_blank');
  },

  //js删除 object中的空值
  delete_null_properties: function delete_null_properties(test, recurse) {
    for (var i in test) {
      if (test[i] === null || test[i] === "") {
        delete test[i];
      } else if (recurse && typeof test[i] === 'object') {
        delete_null_properties(test[i], recurse);
      }
    }
    return test
  },

  // 二维数组查找 - 根据属性值查找对应的另一个属性值
  // arrayLookup(arr,1,'value','label') 在arr中查找value为1对应的label
  arrayLookup(data,value,key='value',targetKey='label'){
    var targetValue = "";
    for (var i = 0; i < data.length; i++) {
      if(data[i][key]==value){
        targetValue = data[i][targetKey];
        break;
      }
    }
    return targetValue;
  },
  // 扁平数组生成树形结构递归处理
  toTree: function toTree(items, id = 0, link = 'parentId'){
    let resultArray = items.filter(item => item[link] == id);
    resultArray.forEach(item => {
      let childrenList = toTree(items, item.id);
      if(childrenList.length>0){
        item.children = childrenList
      }
    })
    return resultArray
  },
  workbenchHeight:(name)=>{
    let screen = document.body.clientHeight;
    let conentHeight=screen - 94;
    let body = document.getElementsByClassName(name)[0].clientHeight;
    if(conentHeight > body){
        return 'calc(100vh - 94px)'
    }
  },
  //阿拉伯数字转中文数字
  noToChinese:function (num) {
    if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
        return "Number is wrong!";
    }
    var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
    var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
    var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }
    // if (a.length > 1) //加上小数部分(如果有小数部分)
    // {
    //     re += BB[6];
    //     for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
    // }
    return re;
  },
  ChineseToNumber:function(chnStr){
    var chnNumChar = {
      零:0,
      一:1,
      二:2,
      三:3,
      四:4,
      五:5,
      六:6,
      七:7,
      八:8,
      九:9
    };
    var chnNameValue = {
      十:{value:10, secUnit:false},
      百:{value:100, secUnit:false},
      千:{value:1000, secUnit:false},
      万:{value:10000, secUnit:true},
      亿:{value:100000000, secUnit:true}
    }
    
    
    var rtn = 0;
    var section = 0;
    var number = 0;
    var secUnit = false;
    var str = chnStr.split('');
  
    for(var i = 0; i < str.length; i++){
      var num = chnNumChar[str[i]];
      if(typeof num !== 'undefined'){
        number = num;
        if(i === str.length - 1){
          section += number;
        }
      }else{
        var unit = chnNameValue[str[i]].value;
        secUnit = chnNameValue[str[i]].secUnit;
        if(secUnit){
          section = (section + number) * unit;
          rtn += section;
          section = 0;
        }else{
          section += (number * unit);
        }
        number = 0;
      }
    }
    return rtn + section;
  },
  roleName:function(res,state){//获取当前角色名称
    if(!res){
      return res
    }
    if(state=='department'){//部门
      if(res.departmentList) for(let i=0;i<res.departmentList.length;i++){
        let item=res.departmentList[i]
        if(item.isCurrent==0){
          return item;
        }
      }
    }else{//角色
      if(res.roleList) for(let i=0;i<res.roleList.length;i++){
        let item=res.roleList[i]
        if(item.isCurrent==0){
          return item;
        }
      }
    }
    
  },
  postExcle:(res)=>{//后台下载xlsx
    const url = window.URL.createObjectURL(new Blob([res.data]))
    var filename = res.headers//下载后文件名
        filename = filename["content-disposition"]
        filename = filename.split(";")[1].split("filename=")[1];
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.download = `${decodeURI(filename)}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  // 获取电脑ip
  getUserIP: (onNewIP)=> {
    let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new MyPeerConnection({
        iceServers: []
      });
    let noop = () => {
      };
    let localIPs = {};
    let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    let iterateIP = (ip) => {
      if (!localIPs[ip]) {onNewIP(ip);}
      localIPs[ip] = true;
    };
    pc.createDataChannel('');
    pc.createOffer().then((sdp) => {
      sdp.sdp.split('\n').forEach(function (line) {
        if (line.indexOf('candidate') < 0) {return;}
        line.match(ipRegex).forEach(iterateIP);
      });
      pc.setLocalDescription(sdp, noop, noop);
    }).catch((reason) => {
      console.log(reason);
    });
    pc.onicecandidate = (ice) => {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) {return;}
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
  },
  hidePhoneNumber(phone) {//隐藏手机号中间4位
    return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
  },
  hideVinCode(vin) {//隐藏VIN码，规则保留前四位和后四位，隐藏中间九位
    if (vin.length !== 17) {
      // return console.log('VIN码长度不够')
      return
    }
    
    // 保留前四位和后四位，隐藏中间九位
    let hiddenVin = vin.substring(0, 4) + '**********' + vin.substring(13);
    return hiddenVin;
  },
  autoMedium() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp"; //移动设备
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4"; //手机浏览器
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb"; //uc手机浏览器
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce"; //windows 移动平台
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        return 'mobile';     //移动端域名
    }else{
      return 'web'
    }
  },
}
export default $$