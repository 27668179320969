<template>
  <div class="pageContainer">
    <el-row :gutter="20">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>客户编号: </span>
        <el-input clearable v-model="params.customerNo" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>客户类型:</span>
        <el-select clearable v-model="params.customerType" placeholder="请选择" size="medium">
          <el-option v-for="item in $store.state.setting.customerTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>车牌号码: </span>
        <el-input clearable v-model="params.carNo" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>渠道来源:</span>
        <el-cascader clearable v-model="params.channel" :options="channelOptions" size="medium"></el-cascader>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="10" class="flex flex_align_center flex_between w4">
        <span>服务类型:</span>
        <el-cascader clearable v-model="params.serviceType" size="medium" :options="serviceTypeOptions" :props="{ value: 'id', label: 'type', children: 'smallTypes' }"></el-cascader>
      </el-col>
      <el-col :span="12" style="text-align:right;">
        <el-button type="primary" size="medium" plain @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="cList" border stripe class="table-mainbg mt20" >
      <el-table-column prop="customerNo" label="客户编号" align="center">
        <template slot-scope="scope">
          <div class="flex flex_align">
            <span>{{scope.row.customerNo}}</span>
            <span class="callLoss" v-if="scope.row.isCallLoss">损</span>
            <span class="expedited" v-if="scope.row.isUrgent">急</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center" width="115px"/>
      <el-table-column prop="bigType" label="服务大类" align="center" width="115px"/>
      <el-table-column prop="smallType" label="服务小类" align="center"/>
      <el-table-column prop="channel" label="渠道来源" align="center" />
      <el-table-column prop="carNo" label="车牌号码" width="100" align="center"/>
      <el-table-column prop="customerType" label="客户类型" align="center" :formatter="formatCType"/>
      <el-table-column prop="createDate" label="创建时间" width="180" align="center"/>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        customerType: '',
        customerNo: '',
        carNo: '',
        channel: '',
        serviceType: '',
      },
      channelOptions: [],
      serviceTypeOptions: [],
      cList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created() {
    this.customerGetTaskType();
    this.customerQueryChannel();
    this.getData();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getData()
    },
    //服务大小类所有选项
    customerGetTaskType() {
      this.$datadic.getTaskType().then(res => {
        this.serviceTypeOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    //所有渠道
    customerQueryChannel() {
      this.$datadic.getCustomerQueryChannel().then(res => {
        this.channelOptions = res;
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    getData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        customerType: this.params.customerType,
        carNo: this.params.carNo,
        customerNo: this.params.customerNo,
        channelId: this.params.channel[1],
        serviceTypeId: this.params.serviceType[1],
      }

      this.$api.customerQuerySubstitution(this.$$.delete_null_properties(params)).then(res => {
        if(res.code == 0){
          this.page_total = res.data.total;
          this.cList = res.data.records;
        }
      })
    },
    formatCType(row) {
      return this.$$.arrayLookup(this.$store.state.setting.customerTypeList, row.customerType)
    },
    search() {
      this.page_index = 1;
      this.getData();
    },
    goCustomer(followUpId) {
      if(followUpId){
        this.$router.push({path: '/testService/customer', query: {followUpInfoId: followUpId}})
      }else{
        this.$message.warning('跟进业务ID缺失')
      }
    },
  }
}
</script>

<style scoped lang="scss">
// .substituteTask{
//   padding: 20px 40px;
// }
.callLoss, .expedited{
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  background: #f4c01e;
  color: #fff;
  margin-left: 5px;
}
.expedited{background: #ff0000;}
</style>