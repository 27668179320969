<template>
  <!-- 线索分配设置 -->
  <div class="resourceAllocation">
    <div class="flex flex_align_center flex_between mb20">
      <div class="ckxTitle">线索分配设置</div>
      <el-button type="primary" plain @click="addRule">添加分配规则</el-button>
    </div>
    <el-table :data="tableData" border stripe class="table-mainbg mt30">
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column prop="ruleName" label="规则名称" align="center"></el-table-column>
      <el-table-column prop="dataSourceName" label="数据源" align="center"></el-table-column>
      <el-table-column prop="groups" label="接收对象" align="center">
        <template slot-scope="scope">{{scope.row.assignWay===2?`${scope.row.groups},${scope.row.otherGroups}`:scope.row.groups}}</template>
      </el-table-column>
      <el-table-column prop="maxCount" label="数量上限" align="center"></el-table-column>
      <el-table-column prop="startTime" label="开始分配时间" align="center"></el-table-column>
      <el-table-column prop="endTime" label="结束分配时间" align="center"></el-table-column>
      <el-table-column prop="assignWay" label="方式" :formatter="formatAssignWay" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="onEidt(scope.row)" size="mini" plain>编辑</el-button>
          <el-button type="primary" @click="delet(scope.row)" size="mini" plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <!-- 添加分配规则 -->
    <el-dialog :title="`${state==1?'添加':'修改'}分配规则`" :visible.sync="addRuleVisible" center width="750px">
      <div class="formWrap">
        <el-form :model="form" :rules="rules" label-width="150px" class="ruleFrom" ref="form" szie="medium">
          <el-form-item label="规则名称" prop="ruleName">
            <el-input v-model="form.ruleName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="分配方式" prop="assignWay">
            <el-radio-group v-model="form.assignWay" size="small" @input="onAss">
              <el-radio-button v-for="item in $store.state.setting.assignWayList" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-row v-if="form.assignWay === 2">
            <el-col :span="10" class="contDIan">
              <el-form-item label="比例数" prop="proportion1">
                <el-input type="number" v-model="form.proportion1" class="inputW"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="1">:</el-col> -->
            <el-col :span="10">
              <el-form-item label="" prop="proportion2">
                <el-input type="number" v-model="form.proportion2" class="inputW" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="接收对象" v-if="form.assignWay === 1" prop="receiver1">
            <el-select v-model="form.receiver1" placeholder="请选择" multiple @input="forceUpdate">
              <el-option :label="item.name" :value="item.id" v-for="item in groupList" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-row  v-if="form.assignWay === 2">
            <el-col :span="10">
              <el-form-item label="接收对象" prop="receiver2">
                <el-select v-model="form.receiver2" placeholder="请选择" class="inputW" @input="forceUpdate">
                  <el-option :label="item.name" :value="item.id" v-for="item in groupList" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="" prop="receiver3" label-width="">
                <el-select v-model="form.receiver3" placeholder="请选择"  class="inputW" @input="forceUpdate">
                  <el-option :label="item.name" :value="item.id" v-for="item in groupList" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="数据源" prop="dataSource">
            <el-select v-model="form.dataSource" placeholder="请选择">
              <el-option :label="item.name" :value="item.code" v-for="item in sourcelist" :key="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据量上限" prop="maxCount">
            <el-input type="number" v-model="form.maxCount"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="10">
              <el-form-item label="每日分配时间" prop="startTime">
                <el-time-select placeholder="起始时间" v-model="form.startTime" :picker-options="{ start: '08:30', step: '00:15', end: '18:30' }" ></el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="" prop="endTime">
              <el-time-select placeholder="结束时间" v-model="form.endTime" :picker-options="{ start: '08:30', step: '00:15', end: '18:30',minTime:form.startTime }" ></el-time-select>
            </el-form-item>
          </el-col>
          </el-row>
          
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRuleVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateOrg = (rule, value, callback) => {
      if(!this.form.proportion1){
        callback(new Error("不能为空！"));
      }else if (String(this.form.proportion1).indexOf('.')!=-1) {
        callback(new Error("请输入整数！"));
      }else if (this.form.proportion1<0) {
        callback(new Error("请输入正数！"));
      } else {
        callback();
      }
    };
    const validateOrg1 = (rule, value, callback) => {
      if(!this.form.proportion2){
        callback(new Error("不能为空！"));
      }else if (String(this.form.proportion2).indexOf('.')!=-1) {
        callback(new Error("请输入整数！"));
      }else if (this.form.proportion2<0) {
        callback(new Error("请输入正数！"));
      }else{
        callback();
      }
    };
    const validateR1 = (rule, value, callback) => {
      if(!this.form.receiver1){
        callback(new Error("不能为空！"));
      }else {
        callback();
      }
    };
    const validateR2 = (rule, value, callback) => {
      if(!this.form.receiver2){
        callback(new Error("不能为空！"));
      }else {
        callback();
      }
    };
    const validateR3 = (rule, value, callback) => {
      if(!this.form.receiver3){
        callback(new Error("不能为空！"));
      }else {
        callback();
      }
    };
    return {
      tableData: [],
      addRuleVisible: false,
      form: {
        ruleName: '',
        assignWay: '1',
        receiver1: '',
        source: '',
        number: '',
        grade: '',
        beginTime: '',
        endTime: '',
        proportion1: '',
        proportion2: '',
        receiver2: '',
        receiver3: '',
      },
      rules:{
        ruleName: [{ required: true, message: '不能为空' }],
        assignWay: [{required: true, message: '不能为空'}],
        maxCount: [{required: true, message: '不能为空'}],
        dataSource: [{required: true, message: '不能为空'}],
        startTime: [{required: true, message: '不能为空'}],
        endTime: [{required: true, message: '不能为空'}],
        receiver1: [{required: true, message: '不能为空',validator: validateR1}],
        receiver2: [{required: true, message: '不能为空',validator: validateR2}],
        receiver3: [{required: true, message: '不能为空',validator: validateR3}],
        proportion1: [{required: true,validator: validateOrg}],
        proportion2: [{required: true,validator: validateOrg1}],
      },
      sourcelist:[],//数据源
      page_total:0,
      page_size:10,
      page_index:1,
      groupList:[],//接受对象
      state:1,//1新增2修改
    }
  },
  created() {
    this.init()//获取所有分配规则
    this.getCustomerAssignSource()//查询所有数据源
    this.checkSeatQueryCheckSeatGroupList();//接受对象
  },
  methods: {
    forceUpdate(){
      this.$forceUpdate()
    },
    init() {
      let query={
        "curPage": this.page_index,
        "pageSize": this.page_size
      }
      this.$api.postCustomerAssignRulePage(query).then(res => {
        if(res.code == 0){
          this.tableData = res.data.records;
          this.page_total=res.data.total
        }
      })
    },
    getCustomerAssignSource() {//查询所有数据源
      this.$api.getCustomerAssignSource().then(res => {
        if(res.code == 0){
          this.sourcelist=res.data;
        }
      })
    },
    checkSeatQueryCheckSeatGroupList() {//获取组
      this.$api.checkSeatQueryCheckSeatGroupList().then(res => {
        if(res.code == 0){
          this.groupList = res.data;
        }
      })
    },
    formatAssignWay(row) {
      if(row.assignWay === 1) {
        return '轮询'
      }else if(row.assignWay === 2){
        return '比例'
      }
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let query={
            "ruleName": this.form.ruleName,
            "dataSource": this.form.dataSource,
            "assignWay": this.form.assignWay,
            // "groups": [],
            "maxCount": this.form.maxCount,
            "startTime": this.form.startTime.split(':').length===2?`${this.form.startTime}:00`:this.form.startTime,
            "endTime": this.form.endTime.split(':').length===2?`${this.form.endTime}:00`:this.form.endTime,
            // "proportion": 0,
            // "otherGroups": []
          }
          if(this.form.assignWay===1){
            query.groups=this.form.receiver1;
          }else if(this.form.assignWay===2){
            query.groups=this.form.receiver2?[this.form.receiver2]:'';
            query.otherGroups=this.form.receiver3?[this.form.receiver3]:'';
            // query.proportion=(Number(this.form.ratio1)/Number(this.form.ratio2)).toFixed(2)
            query.proportion1=this.form.proportion1;
            query.proportion2=this.form.proportion2;
          }
          if(this.state===1){
            this.$api.postCustomerAssignRuleAdd(this.$$.delete_null_properties(query)).then(res=>{
              if(res.code==0){
                this.$message.success(res.message)
                this.page_index=1;
                this.addRuleVisible=false;
                this.init()
              }
            })
          }else{
            this.$api.postCustomerAssignRuleUpdate(this.form.id,this.$$.delete_null_properties(query)).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
              this.page_index=1;
              this.addRuleVisible=false;
              this.init()
            }
          })
          }
          
        }
      });
    },
    onAss(){
      this.$nextTick(()=>{
        this.$refs.form.clearValidate();
        if(this.form.assignWay===1){
          this.form.receiver1=this.form.groupIds.split(',').map(Number);
        }else if(this.form.assignWay===2){
          this.form.receiver2=this.form.groupIds?Number(this.form.groupIds):'';
          this.form.receiver3=this.form.otherGroupIds?Number(this.form.otherGroupIds):'';
        }
        this.forceUpdate()
      })
    },
    addRule() {
      this.state=1;
      this.addRuleVisible=true;
      this.form={}
      this.$nextTick(()=>{
        this.$refs.form.clearValidate();
      })
    },
    onEidt(row){
      this.state=2;
      this.form=JSON.parse(JSON.stringify(row))
      this.addRuleVisible=true;
      if(row.assignWay===1){
        this.form.receiver1=row.groupIds.split(',').map(Number);
      }else if(row.assignWay===2){
        this.form.receiver2=Number(row.groupIds);
        this.form.receiver3=Number(row.otherGroupIds);
      }
      this.$nextTick(()=>{
        this.$refs.form.clearValidate();
      })
    },
    delet(row) {
      this.$confirm(`您确认要删除${row.ruleName}的这个规则吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.postCustomerAssignRuleDelete(row.id).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
              this.page_index=1;
              this.init()
            }
          })
        }).catch(() => {});
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
  },
}
</script>

<style scoped lang="scss">
.resourceAllocation{
  padding: 20px 40px;
  .formWrap{
    max-height: 500px;
    overflow-y: auto;
    padding-right: 50px;
  }
  .inputW{
    width:220px;
  }
  .contDIan{
    position:relative;
  }
  .contDIan:before{
    position:absolute;
    // top:15px;
    right:-130px;
    content:':';
    font-weight: bold;
    font-size: 26px;
  }
}
</style>
