import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import pathList from './path.js';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
import { getToken } from '@/utils/auth' // getToken from cookie
import $$ from '@/utils/index.js' 
import $datadic from '@/utils/datadic';
import $api from '@/api/index.js' 
import ElementUI from 'element-ui';
/**菜单json字段说明
 * path":"/testService/softphone",页面路由
 * "name":"softphone",路由名称
 * "component": "testService/softphone", //文件路由
 * "meta":{
 * children:[],//子菜单
 * "title":"软电话登录",//菜单名称
 * "isNewVuePage": true//是否新开页面
 * "keepAlive":false//是否缓存页面
 * "isHide": true//是否隐藏菜单名称
 * "sysType":1//系统类型：1鸿鹄，2后台管理系统，默认1
 * }
 * 所有工具配备的页面在/views/common文件夹下面，如：
 * {"path":"/softphone", "name":"softphone", "component": "common/softphone", "meta":{"title":"软电话登录","isNewVuePage": true}}
 * **/
/**临时路由**/
// import list1 from '../json/onlineService.json'//在线客服
// import list1 from '../json/testService.json'//检测坐席 或者 重拍坐席
// import list1 from '../json/clerk.json'//门店-文员
// import list1 from '../json/salesManager.json'//帮卖经理
// import list1 from '../json/testReviewer.json'//检测审核
// import list1 from '../json/interactManager.json'//互动部经理
// import list1 from '../json/storeManager.json'//门店店长

// import list1 from '../json/salesAssistant.json'//销售助理 （缺）
// import list1 from '../json/admin.json'//管理员

// import list1 from '../json/accreditationCommissioner.json'//办证专员（）
// import list1 from '../json/merchantDepartment.json' //商户部 （）
// import list1 from '../json/channelDepartment.json' //渠道部 （）
// import list1 from '../json/warehouseKeeper.json' //库管员 （缺）
// import list1 from '../json/finance.json'//财务
// import list1 from '../json/certificationCompany.json'//办证公司
// import list1 from '../json/carRecommendationExpert.json'//荐车达人

import list1 from '../json/index.json'//初使菜单
// let list1=store.state.user.menulist;
// console.log(list1,'----list1')
//解决重复路由冗余导航:“/索引”
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

NProgress.configure({ showSpinner: false })// NProgress Configuration
Vue.use(VueRouter);

// 加载静态路由
const createRouter = () =>
	new VueRouter({
    mode: 'history',
		routes: pathList,
	});

// 创建路由
const router = createRouter();

const whiteList = ['/login','/forgetPsd']// no redirect whitelist


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  NProgress.start()
  if(getToken()){
    if (to.path === '/login'||to.path === '/role') {
      // next({ path: '/' })
      next()
      NProgress.done()
    } else {
      // let roleMenuType=store.state.user.roleMenuType;//当前是否存在菜单
      // let roleMenuType=localStorage.getItem('roleMenuType')
      // console.log(roleMenuType,'====roleMenuType===',to.path)
      // if(roleMenuType===false){
      //   ElementUI.Message({
      //     message: '当前角色没有分配菜单，请联系管理员！',
      //     type: 'warning'
      //   })
      //   return
      // }
      const menuRole=localStorage.getItem('menuRole');
      const menulistRule=localStorage.getItem('menulistRule');
      let sessArr=[]
      if(menuRole){
        sessArr=JSON.parse(menuRole)
      }
      let rulelist=[]
      if(menulistRule){
        rulelist=JSON.parse(menulistRule)
        let whiteMe=['/role','/user/center','/user/updatePsd','/login','/401','/login/forgetPsd']//页面访问白名单
        rulelist=rulelist.concat(whiteMe)
        let at=rulelist.includes(to.path)
        // console.log(at,'===at===')
        // console.log(rulelist,'===555===',to.path)
        // if(at===false){
        //   router.push('/404')
        //   return
        // }
        // console.log(at,'===at===')
      }
      // console.log(rulelist,'===555===',to.path)
      // store.dispatch('GetInfo').then(res => { // 拉取user_info
        // 动态路由，拉取菜单
      if (Object.keys(store.state.routesList.routesList).length <= 0) {
        let userId=localStorage.getItem('userId')
        if(sessArr.length==0){
          sessionMenu(to,next)
          // if(!userId){
          //   console.log('---11---')
          //   sessionMenu(to,next)
          // }else{
          //   console.log('---2---')
          //   loadMenus(to, next)
          // }
        }else{
          localStorageMenus(to, next,sessArr)
        }
      }else{
        /**特别提示：isLink值为存在外链情况，在后台配置外链需要把链接在写outLink字段里面，其他无效！**/
        if(!to.meta.isLink){
          let userData=store.state.user.userData;//用户数据
          if(userData){
            let role=$$.roleName(userData,'department')
            if(userData.loginName!='admin'){
              if(to.name==='workbenchIndex'){
                if(role===undefined||!role){
                  router.push({
                    path:'/role'
                  })
                }
              }
            }
          }
          
          next()
          NProgress.done()
        }
      }
      
      // }).catch((err) => {
      //   console.log(err)
      //   store.dispatch('LogOut').then(() => {
      //     location.reload() // 为了重新实例化vue-router对象 避免bug
      //   })
      // })
    }
  }else{
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

const postRefreshToken = ()=>{//静默登录
  $api.postRefreshToken().then(res=>{
    if(res.code==0){
      localStorage.setItem('token',res.data.token)
      localStorage.setItem('loginObj',JSON.stringify(res.data))
      localStorage.setItem('userId',res.data.userId)
      store.dispatch('user/setUser',res.data.userId); 
    }
  })
}

export const sessionMenu = (to, next) => {
  resetRouter();
  let userId=localStorage.getItem('userId')
  $datadic.getUserData(userId)
}


// const locallist=[]
export const localStorageMenus = (to, next,arr) => {
  // console.log(locallist,'===locallist===',store.state.routesList.routesList)
  let menulist=[];
  for(let i=0;i<arr.length;i++){
    let item=arr[i];
    let children=[];
    let menuhide=true;//默认隐藏一级菜单
    if(item.subMenuList){
      for(let j=0;j<item.subMenuList.length;j++){
        let items=item.subMenuList[j]
        let aobj={
          "path":items.router, 
          "name":items.moduleName, 
          component: () => import(`@/views/${items.moduleAddress}.vue`),
          "meta":{
            "title":items.name,
          }
        }
         items.isFixed==0?aobj.meta.affix=true:'';////是否固定，0是，1否，默认1
          items.isCached==0?aobj.meta.keepAlive=true:aobj.meta.keepAlive=false;
          items.isHide?aobj.meta.isHide=false:aobj.meta.isHide=true;
          items.isNewPage==0?aobj.meta.isNewVuePage=true:'';//是否新开页面，0是，1否，默认1
          aobj.meta.isLink=items.outLink?items.outLink:'';//外链地址
        children.push(aobj)
        /**判断是否需要隐藏一级菜单
         * 规则：当所有二级菜单为隐藏时，则隐藏一级菜单
         * **/
        if(items.isHide==1){//是否隐藏，0是，1否，默认1
          menuhide=false
        }
      }
    }
    let obj={
        "path":item.router, 
        "name":item.moduleName, 
        component: () => import(`@/views/${item.moduleAddress}.vue`),
        "meta":{
          "title":item.name,
          "menuhide":menuhide
        },
      }
    item.isFixed==0?obj.meta.affix=true:'';//是否固定，0是，1否，默认1
    item.isCached==0?obj.meta.keepAlive=true:obj.meta.keepAlive=false;//是否缓存，0是，1否，默认1
    item.isHide?obj.meta.isHide=false:obj.meta.isHide=true;//是否显示，0是，1否，默认0(true为隐藏)
    item.isNewPage==0?obj.meta.isNewVuePage=true:'';//是否新开页面，0是，1否，默认1
    
    /**特别提示：isLink值为存在外链情况，在后台配置外链需要把链接在写outLink字段里面，其他无效！**/
    obj.meta.isLink=item.outLink?item.outLink:'';//外链地址
    children.length>0?obj.children=children:''
    menulist.push(obj)
    // $router.addRoute('home',{ ...obj });
  }
  // locallist=menulist;
  let userObj=sessionStorage.getItem('userData');
  if(userObj){
    userObj=JSON.parse(userObj)
    if(userObj.roleList) for(let i=0;i<userObj.roleList.length;i++){
      let item=userObj.roleList[i]
      if(item.isCurrent==0){
        let roleTitle='';
        switch(item.name){
          case '系统管理员':
          case '管理员':
          case 'admin':
            roleTitle='admin';
            break;
          case '在线客服':
            roleTitle='onlineService';
            break;
          case '检测坐席组长':
          case '检测坐席':
            roleTitle='testService';
            break;
          case '调度文员':
          case '文员':
            roleTitle='clerk';
            break;
          case '帮卖经理':
            roleTitle='salesManager';
            break;
          case '检测审核':
            roleTitle='testReviewer';
            break;
          case '互动部经理':
            roleTitle='interactManager';
            break;
          case '门店店长':
          case '店长':
            roleTitle='storeManager';
            break;
          case '检测主管':
            roleTitle='inspectorSupervisor';
            break;
          case '销售助理':
            roleTitle='salesAssistant';
            break;
          case '办证专员':
            roleTitle='accreditationCommissioner';
            break;
          case '商户部':
            roleTitle='merchantDepartment';
            break;
          case '渠道部':
            roleTitle='channelDepartment';
            break;
          case '库管员':
            roleTitle='warehouseKeeper';
            break;
          case '财务':
            roleTitle='finance';
            break;
        }
        //赋予权限testReviewer，admin,onlineService等名称展示显示内容
        store.dispatch('permission/setPermission', [roleTitle])
      }
    }
  }
  
  store.dispatch('routesList/GenerateRoutes',menulist).then(() => {
    menulist.forEach((route) => {
      router.addRoute('home',{ ...route });
    });
    // console.log(router,'=====111====')
   if(arr.length>0){
      // bus.$emit("closeAllTags");
      let path=menulist[0].children?menulist[0].children[0].path:menulist[0].path;
      router.push({
        path:path
      })
    }
    next({ ...to, replace: true });
  }); 
}

export const loadMenus = (to, next) => {
  // buildMenus().then(res => {      
    const asyncRouter = filterAsyncRouter(list1.data)
    store.dispatch('routesList/GenerateRoutes', asyncRouter).then(() => { // 存储路由
      resetRouter();
      [...asyncRouter, { path: '/*', redirect: '/404' }].forEach((route) => {
        router.addRoute('home',{ ...route });
      });
      if(store.state.user.userData){
        let user=$$.roleName(store.state.user.userData)
        if(user){
          let roleTitle='';
          switch(user.name){
            case '系统管理员':
            case '管理员':
            case 'admin':
              roleTitle='admin';
              break;
            case '在线客服':
              roleTitle='onlineService';
              break;
            case '检测坐席组长':
            case '检测坐席':
              roleTitle='testService';
              break;
            case '调度文员':
            case '文员':
              roleTitle='clerk';
              break;
            case '帮卖经理':
              roleTitle='salesManager';
              break;
            case '检测审核':
              roleTitle='testReviewer';
              break;
            case '互动部经理':
              roleTitle='interactManager';
              break;
            case '门店店长':
            case '店长':
              roleTitle='storeManager';
              break;
            case '检测主管':
              roleTitle='inspectorSupervisor';
              break;
            case '销售助理':
              roleTitle='salesAssistant';
              break;
            case '办证专员':
              roleTitle='accreditationCommissioner';
              break;
            case '商户部':
              roleTitle='merchantDepartment';
              break;
            case '渠道部':
              roleTitle='channelDepartment';
              break;
            case '库管员':
              roleTitle='warehouseKeeper';
              break;
            case '财务':
              roleTitle='finance';
              break;
          }
          //赋予权限testReviewer，admin,onlineService等名称展示显示内容
          store.dispatch('permission/setPermission', [roleTitle])
        }
      }
      next({ ...to, replace: true });
    })
    
  // })
}

export const filterAsyncRouter = (routers) => { // 遍历路由字符串，转换为组件对象
  return routers.filter(router => {
    if (router.component) {
      if (router.component === 'common') { 
        router.component = loadView('common/index')
      } else {
        const component = router.component
        router.component = loadView(component)
      }
    }
    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children)
    }
    return true
  })
}

export const loadView = (path) => {
  return () => Promise.resolve(require(`@/views/${path}`));
}

// 重置路由
export function resetRouter() {
	router.matcher = createRouter().matcher;
}

router.afterEach((to) => {
  if(to.path !== '/login'){
    const loginObj=localStorage.getItem('loginObj')
    let logObj={}
    if(loginObj){
      logObj=JSON.parse(loginObj)

      
      // 获取当前时间的时间戳
      var currentTime = new Date().getTime();
      
      // 计算前5分钟的时间戳
      var fiveMinutesAgo = new Date(logObj.tokenExpireTime);
      // let fiveMinutesAgo=new Date('2023-12-04 11:10:00').getTime()
      let atime=fiveMinutesAgo - (5 * 60 * 1000)
      
      // 判断当前时间是否在前5分钟内
      if (fiveMinutesAgo >= currentTime) {
        if(atime<currentTime){
          // console.log("在前5分钟内");
          postRefreshToken()
        }
        
      }
    }
  }
  
  NProgress.done() // finish progress bar
})

export default router
