<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="pageRowBox">
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户姓名</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>联系电话</span>
        <el-input v-model="forms.linkPhone" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户账户</span>
        <el-input v-model="forms.merchantNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>注册城市</span>
        <el-cascader clearable
        v-model="forms.onCity" placeholder="请选择注册城市" size="medium"
        :options="cityList" :props="props"></el-cascader>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>注册日期</span>
        <el-date-picker type="daterange" placeholder="选择日期" v-model="forms.date" size="medium" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户状态</span>
        <el-select v-model="forms.curStatus" placeholder="请选择办证公司结算状态" size="medium" clearable>
          <el-option label="请选择商户状态" value=""></el-option>
          <el-option label="已签约-可竞拍" value="1"></el-option>
          <el-option label="已签约-不可竞拍" value="2"></el-option>
          <el-option label="已解约" value="3"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>维护业务员</span>
        <el-select v-model="forms.maintainStaff" placeholder="请选择业务员" size="medium" clearable>
          <el-option v-for="(item,index) in employlist" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>经营城市</span>
        <el-cascader
        v-model="forms.businessProvinceId" placeholder="请选择" size="medium" clearable
        :options="cityList" :props="props"></el-cascader>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w4 mb10">
        <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt10 table-mainbg">
      <el-table-column prop="id" label="商户ID" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center">
        <template slot-scope="scope">{{scope.row.merchantType==1?scope.row.authCompanyName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="merchantNo" label="商户账号" align="center"></el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center"></el-table-column>
      <el-table-column prop="businessProvinceName" label="经营省份" align="center"></el-table-column>
      <el-table-column prop="businessCityName" label="经营城市" align="center"></el-table-column>
      <el-table-column prop="margin" label="保证金" align="center"></el-table-column>
      <el-table-column prop="merchantStatus" label="商户状态" align="center">
        <template slot-scope="scope">
          {{scope.row.merchantStatus==0?'体验':scope.row.merchantStatus==1?'签约':scope.row.merchantStatus==2?'暂停':scope.row.merchantStatus==3?'解约':'试用'}}<!--商户状态(0-体验商户 1-已签约可竞拍 2-已签约不可竞拍 3-已解约)-->
        </template>
      </el-table-column>
      <el-table-column prop="maintainerName" label="维护业务员" align="center"></el-table-column>
      <el-table-column prop="registerDate" label="注册时间" align="center"></el-table-column>
      <el-table-column prop="collectMarginDate" label="保证金收款时间" align="center"></el-table-column>
      <el-table-column prop="cancelDate" label="解约日期" align="center"></el-table-column>
      <el-table-column label="操作" width="250" align="center" v-if="visButton">
        <template slot-scope="scope">
          <el-row>
            <el-button type="primary" @click="onupdate(scope.row)" size="mini">修改</el-button>
            <el-button
            :type="scope.row.isAuction?'info':'primary'"
            @click="onAuction(scope.row,scope.row.isAuction?0:1)"
            size="mini"
          >{{scope.row.isAuction?'停止竞拍':'开启竞拍'}}</el-button><!--scope.row.isAuction, 0-不可竞拍 1-可竞拍-->
          <el-button
            type="primary"
            @click="onAuction(scope.row,2)"
            size="mini"
            :disabled="scope.row.signingState==2?true:false"
          >解约</el-button><!--签约状态：0-体验商户 1-已签约 2-已解约-->
          </el-row>
          <el-row class="mt10">
            <el-button type="primary" @click="onsee(scope.row)" size="mini" >查看</el-button>
            <el-button type="primary" @click="onAccreditation(scope.row)" size="mini" :disabled="scope.row.canCertSelf===1?true:false">添加到自理办证</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
    <el-dialog
      width="500px"
      :show-close="false"
      :class="dialogAuction==1?'':'customDialog'"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
    >
      <div v-if="dialogAuction==1">
        您确认开启此商户（<span class="tipsColor">{{rowData.merchantName}}</span>）的竞拍限制吗？
      </div>
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        :rules="rules"
        :hide-required-asterisk="true"
        v-else
      >
        <el-form-item label="停止原因" prop="reason" v-if="dialogAuction==0">
          <el-input type="textarea" v-model="form.reason" class="w90" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="解约日期" prop="releaseDate" v-if="dialogAuction==2">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.releaseDate" value-format="yyyy-MM-dd"
            class="w90"
            size="medium"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="解约原因" prop="releaseReason" v-if="dialogAuction==2">
          <el-input type="textarea" v-model="form.releaseReason" class="w90" size="medium"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="onconfirm('form')">确认</el-button>
      </span>
    </el-dialog>

     <el-dialog
      width="500px"
      :show-close="false"
      class="customDialog"
      :visible.sync="dialogVisibleCert"
      title='添加到自理办证'
    >
      <el-form
        ref="formCert"
        :model="formCert"
        :rules="rulesCert"
      >
        <!-- <el-form-item label="自理资源" prop="isEnabled">
          <el-radio-group v-model="formCert.isEnabled">
            <el-radio :label="0">有效</el-radio>
            <el-radio :label="1">无效</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="截止时间" prop="enableEndDate" >
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="formCert.enableEndDate" value-format="yyyy-MM-dd"
            size="medium" :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCert = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="onConfirmCert('formCert')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms: {
        date: '',//开始签约日期
        curStatus:'',//状态(1:已签约可竞拍,2:已签约停止竞拍,3:已解约)
        linkPhone:'',//联系电话
        maintainStaff: "",//维护业务员
        merchantName: "",//商户姓名
        merchantNo:'',//商户账号
        onCity:'',//注册城市
        provinceId:'',//经营省份
        regCity:''//注册城市编号
      },
      cityList:[],//注册城市
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      employlist:[],//维护业务员
      registerlist:[],//注册城市
      formCert:{
        enableEndDate:'',
        isEnabled:0,
      },
      rulesCert: {
        enableEndDate: [{ required: true, message: "不能为空" }],
        isEnabled: [{ required: true, message: "不能为空" }],
      },
      dialogVisibleCert:false,
      form: {},
      rules: {
        reason: [{ required: true, message: "停止原因不能为空" }],
        releaseDate: [{ required: true, message: "解约日期不能为空" }],
        releaseReason: [{ required: true, message: "解约原因不能为空" }]
      },
      pickerOptions:{
        disabledDate(date) {//禁止选择当前日期之前的日期（今天可选）
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      querylist: [],
      dialogVisible: false,
      dialogTitle: "",
      total: 0,
      currentPage: 1,
      pageSize:10,
      dialogAuction: 0, //0停止竞拍，1为开始竞拍（点击），2解约
      rowData:{},//选择的数据（当行数据）
      changeFlag:false,//
      visButton:true,//是否显示按钮
    };
  },
  created() {
    this.init();
    this.queryUserListByRoleAndDepart()//维护业务员
    //经营省份城市区域3级联动查询(无参数查所有)省市
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.cityList=res;
      })
    }else{
      this.cityList=this.$store.state.setting.cityList;
    }
    let userData=this.$store.state.user.userData;
    let roleName=this.$$.roleName(userData)
    if(["销售专员"].includes(roleName.name)){
      this.visButton=false;
    }
  },
  methods: {
    handleChangeFlag(type) {// select回调，判断当前下拉框是否展示
      this.changeFlag = type;
    },
    getPageSize(){
      //利用Math.ceil算出新的分页 total为什么要-1,因为删完后total还没更新
      let totalPage = Math.ceil((this.total - 1) / this.pageSize);
      //计算出当前页码
      this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage;
      //如果页码为1的话，则无效
      this.currentPage = this.currentPage < 1 ? 1 : this.currentPage;
    },
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query=
      {
        "curPage": this.currentPage, //*当前页
        "merchantNo": this.forms.merchantNo,//商户账号
        "merchantName": this.forms.merchantName,//商户姓名
        "pageSize": this.pageSize,//每页条数
        "merchantMobile": this.forms.linkPhone,//商户注册手机
        "merchantStatus": this.forms.curStatus,//商户状态 1-已签约可竞拍 2-已签约不可竞拍 3-已解约
        "registerCityId": this.forms.onCity?this.forms.onCity[1]:'',//注册城市ID
        "registerStartDate": this.forms.date?this.forms.date[0]:'',//开始签约日期
        "registerEndDate": this.forms.date?this.forms.date[1]:'',//结束签约日期
        "businessProvinceId": this.forms.businessProvinceId?this.forms.businessProvinceId[0]:'',//经营省份ID
        "businessCityId": this.forms.businessProvinceId?this.forms.businessProvinceId[1]:'',//经营城市ID
        "maintainerId": this.forms.maintainStaff//维护专员ID（后期维护车商出价等服务的业务员）
      }
      this.$api.getSignedList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.querylist=res.data.records;
          this.total=res.data.total;
        }
      })
    },
    ondialogVisible: function() {
      this.dialogVisible = !this.dialogVisible;
    },
    onupdate(row) {
      /**state=0,个人修改;state=1,公司修改;state=2,个人查看;state=3,公司查看**/
      this.$router.push({
        path: "/merchant/contractorDetails",
        query: {
          state: row.merchantType?1:0,
          id:row.id
        }
      });
    },
    onAccreditation(row){
      this.rowData=JSON.parse(JSON.stringify(row));
      this.formCert={
        enableEndDate:'',
        isEnabled:0,
      }
      this.dialogVisibleCert=true;
      if(this.$refs['formCert']){
        this.$refs['formCert'].clearValidate();
      }
      
    },
    onConfirmCert(formdata){
      this.$refs[formdata].validate(valid => {
        if (valid) {
          let query={
            merchantId:this.rowData.id,
            // isEnabled:this.formCert.isEnabled,//自理资格是否有效：0有效，1无效
            isEnabled:0,
            enableEndDate:this.formCert.enableEndDate
          }
          this.$api.postCertSelfAdd(query).then(res=>{
            if(res.code===0&&res.data===true){
              this.$router.push({
                path:'/merchant/complaintProcessingManage',
                query:{
                  id:this.rowData.id,
                }
              })
            }
          })
        }
      });
    },
    onAuction(row, state) {
      this.rowData=JSON.parse(JSON.stringify(row));
      //0停止竞拍-1开启竞拍
      if (state == 1) {
        //开启竞拍
        this.dialogTitle = "";
      } else if (state == 0) {
        this.cleanValidate();
        this.dialogTitle = "停止竞拍";
      } else {
        this.cleanValidate();
        this.dialogTitle = "解约";
      }
      this.dialogAuction = state;
      this.ondialogVisible();
    },
    cleanValidate() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = "";
      });
      this.$nextTick(() => {
        if(this.$refs["form"]){
          this.$refs["form"].clearValidate();
        }
      });
    },
    onconfirm(formdata) {
      //确认modal
      if (this.dialogAuction == 1) {
         let query={
          "isAuction": 1,//开启关闭竞拍(0:关闭竞拍 1:开启竞拍)
        }
        //提交确认开启商户竞拍限制
        this.putSwitchAuction(query)
      } else if (this.dialogAuction == 0) {
         let query={
          "reason": this.form.reason,//原因
          "isAuction": 0,//开启关闭竞拍(0:关闭竞拍 1:开启竞拍)
        }
        //停止竞拍
        this.$refs[formdata].validate(valid => {
          if (valid) {
            this.putSwitchAuction(query)
            //此时重新加载身份证跟进信息列表
          } else {
            return false;
          }
        });
      } else {
        //解约
        this.$refs[formdata].validate(valid => {
          if (valid) {
            this.putDissolution()//解约
          } else {
            return false;
          }
        });
      }
    },
    putSwitchAuction(query){//签约商户停止/开启竞拍
      this.$api.putSwitchAuction(this.rowData.id,query).then((res)=>{
        if(res.code==0){
          this.ondialogVisible();
          this.init();
        }
      })
    },
    putDissolution(){//签约商户解约
      let query={
        "date": this.form.releaseDate?this.form.releaseDate+' 00:00:00':this.form.releaseDate,//解约日期
        "reason": this.form.releaseReason,//解约原因
      }
      this.$api.putDissolution(this.rowData.id,query).then((res) => {
        if(res.code==0){
          this.ondialogVisible();
          this.getPageSize()
          this.init();
        }
      })
    },
    onsee(row) {
      /**state=0,个人修改;state=1,公司修改;state=2,个人查看;state=3,公司查看**/
      this.$router.push({
        path: "/merchant/contractorDetails",
        query: {
          state: row.merchantType?3:2,
          id:row.id
        }
      });
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//维护业务员
      let query={
        "roleName": "销售专员"
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
  }
};
</script>
<style>
#app .el-button + .el-button {
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.pageContainer {
  padding:20px 40px 40px;
  .w90 {
    width: 90%;
  }
}
.pageRowBox{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1600px) {
  .rowW{
    width:350px;
  }
}
// @media (max-width: 1400px) {
//   .rowW{
//     width:350px;
//   }
// }
</style>
