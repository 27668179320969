<template>
<transition name="left">
	<el-menu
		router
		:default-active="defaultActive"
		:collapse="setIsCollapse"
		:unique-opened="getThemeConfig.isUniqueOpened"
    :default-openeds="defaultOpeneds"
		:collapse-transition="true"
		background-color="#304156"
		text-color="#bfcbd9"
		active-text-color="#409EFF"
    v-if="isShowLeft"
    class="left"
	>
    <template v-for="(val,$index) in filterRoutesFun(menuList)">
			<el-submenu :index="val.path" v-if="val.children && val.children.length > 0" :key="`men_${$index}`">
				<template slot="title">
          <i class="el-icon-menu"></i>
					<!-- <i :class="val.meta.icon ? val.meta.icon : ''"></i> -->
					<span>{{val.meta.title}}</span>
				</template>
				<SubItem :chil="val.children" />
			</el-submenu>
			<!-- <el-menu-item :index="val.path" :key="val.path" v-else> -->
      <el-menu-item :index="val.path" v-else-if="!val.meta.menuhide||val.meta.title=='首页'">
        
        <i :class="val.meta.title=='首页'||val.meta.title=='我的工作台'?'el-icon-s-home':'el-icon-menu'" ></i>
				<template slot="title" v-if="!val.meta.isLink || (val.meta.isLink && val.meta.isIframe)">
					<span>{{val.meta.title}}</span>
				</template>
				<template slot="title" v-else>
					<a :href="val.meta.isLink" target="_blank">
						{{val.meta.title}}
					</a>
				</template>
			</el-menu-item>
		</template>
	</el-menu>
   </transition>
</template>

<script>
import SubItem from '@/components/sidebar/subItem.vue';
export default {
	name: 'navMenuVertical',
	components: { SubItem },
	data() {
		return {
			defaultActive: this.$route.path,
      isShowLeft:true,
      setIsCollapse:false,//左侧菜单是否展开
      defaultOpeneds:[this.$route.path],
      menuList:this.$store.state.routesList.routesList,
		};
	},
	computed: {
		// 获取布局配置信息
		getThemeConfig() {
			return this.$store.state.setting;
		},
		// 设置左侧菜单是否展开/收起
		// setIsCollapse() {
		// 	return document.body.clientWidth < 1000 ? false : this.$store.state.setting.isCollapse;
		// },
	},
	watch: {
		// 监听路由的变化
		$route: {
			handler(to) {
				this.defaultActive = to.path;
				const clientWidth = document.body.clientWidth;
				if (clientWidth < 1000) this.$store.state.themeConfig.themeConfig.isCollapse = false;
			},
			deep: true,
		},
	},
	created() {
		// this.menuList = this.$store.state.routesList.routesList;
    // console.log(this.$route.path,'===55===')
	},
	methods: {
		// 路由过滤递归函数
		filterRoutesFun(arr) {
			return arr
				.filter((item) => !item.meta.isHide)
				.map((item) => {
					item = Object.assign({}, item);
					if (item.children) item.children = this.filterRoutesFun(item.children);
					return item;
				});
		},
    handleChangeLeft () {
      this.isShowLeft = !this.isShowLeft;
    },
	},
};
</script>
<style lang="scss">
.el-menu--vertical{
  max-height:800px;
  overflow:auto;
}
</style>
<style lang="scss" scoped>
.left{
  overflow: auto;
}
</style>

