<template>
  <div class="kpiBox">
    <div class="title_top_nameBox kpiTitle">KPI指标</div>
    <div class="kpiMain flex flex_between">
      <div class="chartCon bgfff"  v-for="(item,index) in kapilist" :key="index">
        <div class="leftchart mychart" :id="`charid${index}`"></div>
        <div class="chartTitle">
          <span class="box" :style="{'background': item.color}"></span>
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts/core";
import { MarkPointComponent } from "echarts/components";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([MarkPointComponent, GaugeChart, CanvasRenderer]);
export default {
  props:{
    kapilist:{
      type:Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    initChart(id,obj) {
      // console.log(obj,'====obj===')
      const myChart = echarts.init(document.getElementById(id));
      const option = {
        series: [
          {
            type: "gauge",
            radius: "100%",
            progress: {
              show: true,
              width: 21,
              roundCap: true,
              itemStyle: {
                color: obj.color
              }
            },
            axisLine: {
              lineStyle: {
                width: 21,
                color: [[1, obj.colorbg]]
              },
              roundCap: true
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            startAngle: 180,
            endAngle: 0,
            axisLabel: {
              show: false
            },
            anchor: {
              show: false
            },
            pointer: {
              show: false
            },
            title: {
              show: false
            },
            data: [
              {
                value: obj.value,
                detail: {
                  formatter: [`{a|${obj.actualVal}}`, `{b|（本月目标值${obj.targetVal}）}`].join("\n"),
                  rich: {
                    a: {
                      fontSize: 40,
                      color: "#333"
                    },
                    b: {
                      fontSize: 20,
                      color: "#999",
                      lineHeight: 60
                    }
                  },
                  offsetCenter: [0, "-20%"],
                  valueAnimation: true
                }
              }
            ]
          }
        ]
      };
      // console.log(option,'---option----')
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.kpiBox {
  .kpiTitle,
  .dataTitle {
    margin: 30px 0 20px;
  }
  .kpiMain {
    .chartCon {
      width: 49%;
      position: relative;
      .mychart {
        width: 450px;
        height: 300px;
        margin: 90px auto 30px;
      }
      .chartTitle {
        width: 100%;
        position: absolute;
        bottom: 70px;
        font-size: 24px;
        text-align: center;
        .box {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          display: inline-block;
        }
      }
    }
  }
}
</style>