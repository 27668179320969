<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>发起门店</span>
        <el-select v-model="forms.submitShopId" placeholder="请选择" size="medium" clearable>
          <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>成交门店</span>
        <el-select v-model="forms.bookShopId" placeholder="请选择" size="medium" clearable>
          <el-option label="全部" value="0"></el-option>
          <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>业务状态</span>
        <el-select v-model="forms.serviceStatus" placeholder="请选择状态" size="medium" @change="screenState(forms.serviceStatus)" clearable>
          <el-option v-for="item in statelist" :key="item.code" :label="item.label" :value="item.code"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>意向金状态</span>
        <el-select v-model="forms.state" placeholder="请选择状态" size="medium" clearable>
          <el-option v-for="item in newMoney" :key="item.state" :label="item.stateName" :value="item.state"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-button type="primary" @click="init(1)" size="medium" class="fr mt10 mr10" plain>搜索</el-button>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="traderName" label="发送人" align="center"></el-table-column>
      <el-table-column prop="intentionTreatySigningTime" label="签协议时间" align="center"></el-table-column>
      <el-table-column prop="expiryDate" label="有效期截至时间" align="center"></el-table-column>
      <el-table-column prop="stateName" label="状态" align="center"></el-table-column>
      <el-table-column prop="submitShop" label="发起门店" align="center"></el-table-column>
      <el-table-column prop="bookShop" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="remark" label="原因" align="center"></el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onupdate(scope.row)" size="mini" plain v-if="scope.row.state===2">修改</el-button>
          <el-button type="primary" @click="onupdate(scope.row,1)" size="mini" plain v-if="scope.row.state===3&&scope.row.financePayStatus===31">修改重签</el-button>
          <el-button type="primary" @click="onupdate(scope.row,2)" size="mini" plain v-if="[0,3].includes(scope.row.state)">修改付款信息</el-button>
          <el-button type="primary" @click="onsee(scope.row)" size="mini" plain v-if="[8,9].includes(scope.row.state)">申请坏账处理</el-button>
          <el-button type="primary" @click="onsee(scope.row,0)" size="mini" plain>查看</el-button>
          <el-button type="primary" @click="onupdate(scope.row,3)" size="mini" plain v-if="scope.row.state===4">发送重签</el-button> 
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms: {
        bookShopId:'0',
        carNo:'',
        submitShopId:'',
        serviceStatus:'',
        state:''
      },
      querylist: [],
      registerlist:[],//发起门店
      shoplist:[],//成交门店
      total: 0,
      currentPage: 1,
      pageSize:10,
      statelist:[{code:0,label:'完整业务'},{code:1,label:'业务进行中'},{code:2,label:'业务已结束'}],//业务状态
      intentionMoney:[],//意向金状态列表
      newMoney:[],//新筛选的意向金列表
      userData:{},//用户数据
      /**状态说明
       * 1 待提交意向协议
       * 2 待签订意向金协议
       * 3 意向金协议签订成功
       * 4 意向金协议签订失败
       * 5 意向金付款成功
       * 0 意向金付款失败
       * 6 客户合同签订成功
       * 7 客户合同签订失败
       * 8 待收返还意向金
       * 9 待收客户违约赔偿
       * 10 已收返还意向金
       * 11 已收客户违约赔偿
       * 12 返还意向金已申请坏账
       * 13 客户违约赔偿已申请坏账
       * 14 返还意向金坏账已处理
       * 15 客户违约赔偿坏账已处理
       * 16 赔偿客户违约金
       * **/
    };
  },
  created() {
    this.intentionMoney=this.$store.state.user.intentionMoney;
    this.userData=this.$store.state.user.userData;
    let shopObj=this.$$.roleName(this.userData,'department')
    this.forms.submitShopId=shopObj.shop.id;
    
    if(this.intentionMoney.length==0){
      this.getIntentionMoneyState()//获取意向金状态
    }
    if(!this.userData.id){
      this.getUserData()
    }
    this.getShopList()//发起门店列表
    
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query=
      {
        "curPage": this.currentPage, //*当前页
        "pageSize": this.pageSize,//每页条数
        "carNo": this.forms.carNo,
        "bookShopId": this.forms.bookShopId,//门店Id 选择全部门店传0
        "submitShopId": this.forms.submitShopId,//发起方门店Id
        "serviceStatus": this.forms.serviceStatus,//业务状态 0或不传-完整业务 1-业务进行中 2-业务已结束
        "state": this.forms.state//意向金状态
      }
      // this.$$.delete_null_properties(query)//清除对象值为空的属性
      this.$api.postIntentionMoneyList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.querylist=res.data.records;
          this.total=res.data.total;
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    onupdate(row,type) {
      switch(type){
        case 3://发送重签
          this.$confirm('请确认是否发送重签?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const query={
              intentionMoneyId:row.id
            }
            this.$api.postIntentionMoneyResign(query).then(res=>{
              if(res.code===0){
                this.$router.push({
                  path: "/salesManager/intentionMoney/agreement",//协议
                  query: {
                    // state: state,
                    intentionMoneyId:row.id,
                    intState:type
                  }
                });
              }
            })
          }).catch(() => {});
          break;
          default:
          this.$router.push({
            path: "/salesManager/intentionMoney/agreement",//协议
            query: {
              // state: state,
              intentionMoneyId:row.id,
              intState:type
            }
          });
      }
    },
    onsee(row,state) {
       /**状态说明
       * 1 待提交意向协议
       * 2 待签订意向金协议
       * 3 意向金协议签订成功
       * 4 意向金协议签订失败
       * 5 意向金付款成功
       * 0 意向金付款失败
       * 6 客户合同签订成功
       * 7 客户合同签订失败
       * 8 待收返还意向金
       * 9 待收客户违约赔偿
       * 10 已收返还意向金
       * 11 已收客户违约赔偿
       * 12 返还意向金已申请坏账
       * 13 客户违约赔偿已申请坏账
       * 14 返还意向金坏账已处理
       * 15 客户违约赔偿坏账已处理
       * 16 赔偿客户违约金
       * **/
      // console.log(row,'===555===')
      let query={
            intentionMoneyId:row.id
          }
      if(state===0){
        query.see=1;//是否查看，1查看
        
      }
      if([13,8,9].includes(row.state)){
        this.$router.push({
          path: "/salesManager/intentionMoney/intentionMoneyDetails",
          query: query
        });
      }else{
        this.$router.push({
          path: "/salesManager/intentionMoney/agreement",//协议
          query: query
        });
      }
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//维护业务员
      let query={
        "roleName": "拓展专员"
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
    screenState(state){//筛选意向金状态
      this.forms.state='';
      let old=JSON.parse(JSON.stringify(this.intentionMoney))
      let arr=old;
      if(state){
        arr = [...old].filter(y => y.serviceStatus == state);
      }
      this.newMoney=arr;
    },
    getIntentionMoneyState(){//获取意向金状态
      this.$api.getIntentionMoneyState().then(res =>{
        if(res.code==0){
          this.intentionMoney=res.data;
          this.$store.dispatch('user/setIntentionMoney',res.data); 
        }
      })
    },
    getUserData(){//根据用户ID获取用户数据
      let userId=this.$store.state.user.userId;//用户id
      this.$api.getUserData(userId).then((res)=>{
        if(res.code==0){
          this.userData=res.data;
          this.$store.dispatch('user/setUserData',res.data)
        }
      })
    },
    getShopList(){//搜索门店
      let query={
        isEnabled:0,
        shopTypeList:[0]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then(res =>{
        if(res.code==0){
          // this.forms.submitShopId=res.data.length?res.data[0].id:0;
          this.shoplist=res.data;
          this.init();
        }
      })
    },
    postOKcardImport(){//ok卡导入
      this.$api.postOKcardImport().then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  
}
</style>
