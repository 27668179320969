<template>
  <!-- 数据维护 -->
  <div class="contenPageBox" >
    <div class="ckxTitle">成交沟通预约记录重置</div>
    <div class="flex flex_align_center mt15">
      <span>客户编号</span>
      <el-input v-model="form.customerNo" placeholder="请输入客户编号" class="w300 ml10 mr10" size="medium"></el-input>
      <span class="mr10 tipsColor">恢复到客户成交沟通:</span>
      <el-radio-group v-model="form.merchantOrder">
        <el-radio :label="1">第一名</el-radio>
        <el-radio :label="2">第二名</el-radio>
        <el-radio :label="3">第三名</el-radio>
      </el-radio-group>
      <el-button plain size="medium" type="primary" @click="onSubmit(1)" class="ml10">提交</el-button>
    </div>
    <div class="ckxTitle mt20">恢复004数据</div>
    <div class="flex flex_align_center mt15">
      <span>客户编号</span>
      <el-input v-model="form.customerNos" placeholder="客户编号多个的话需要用英文的逗号隔开" class="w300 ml10 mr10" size="medium" type="textarea"></el-input>
      <el-button plain size="medium" type="primary" @click="onSubmit(2)" class="ml10">提交</el-button>
    </div>

    <div class="ckxTitle mt20">修改客户渠道</div>
    <el-row :gutter="24">
      <el-col :xl="12" :lg="12">
        <div class="flex flex_align_center mt15">
          <span>客户跟进Id</span>
          <el-input v-model="form.followUpInfoId" placeholder="请输入followUpInfoId" class="w300 ml10 mr10" size="medium" ></el-input>
          <el-button plain size="medium" type="primary" @click="onChannel(1)" class="ml10">查询</el-button>
        </div>
      </el-col>
      <el-col :xl="12" :lg="12">
        <div class="flex flex_align_center mt15">
          <span>客户ID：{{resForm.customerId}}</span>
          <span class="ml30 mr30">旧渠道ID：{{resForm.oldChannelId}}</span>
          <el-cascader v-model="channelArr" :props="channeProp" :options="channellist" clearable size="medium" filterable placeholder="先查询客户再选渠道"></el-cascader>
          <el-button plain size="medium" type="primary" @click="onChannel(2)" class="ml10">提交</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="flex flex_align_flexend">
      <div class="ckxTitle mt20">退回验车待分配</div>
      <p class="ml30">提示：垫资车在客户合同未成功或失败阶段，修改验车入库状态为待分配[签订成功或者失败的不能修改]</p>
    </div>
    <div class="flex flex_align_center mt15">
      <span>客户合同ID</span>
      <el-input v-model="form.contractId" placeholder="请输入客户合同ID" class="w300 ml10 mr10" size="medium" ></el-input>
      <el-button plain size="medium" type="primary" @click="returnCarInspection" class="ml10">提交</el-button>
    </div>

    <div class="ckxTitle mt20">修改凭证费用审核的结算跟进到待审核阶段（必须要数据先到结算跟进）</div>
    <!-- <div class="flex flex_align_center mt15"> -->
      <!-- <span></span> -->
    <el-row :gutter="24" class=“mt10”>
      <el-col :xl="6" :lg="6">
        <el-input v-model="form.carNo" placeholder="请输入车牌号" class=" ml10 mr10 mt10 w300" size="medium" ></el-input>
      </el-col>
      <el-col :xl="6" :lg="6">
        <el-input v-model="form.customerNo" placeholder="请输入客户合同编号" class="mr10 mt10 w300" size="medium" ></el-input>
      </el-col>
      <el-col :xl="6" :lg="6">  
        <el-select v-model="form.certType" placeholder="办证类型" class="mt10 w300" size="medium" >
          <el-option label="沪牌" value="沪牌"></el-option>
          <el-option label="非沪牌" value="非沪牌"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="6" :lg="6">
        <el-select v-model="form.itemName" placeholder="项目名称" class=" ml10 mr10 mt10 w300" size="medium" >
          <el-option label="过户" value="过户"></el-option>
          <el-option label="转籍" value="转籍"></el-option>
          <el-option label="退牌上沪C" value="退牌上沪C"></el-option>
          <el-option label="退牌上沪B" value="退牌上沪B"></el-option>
          <el-option label="沪C转沪B" value="沪C转沪B"></el-option>
          <el-option label="一网通办" value="一网通办"></el-option>
          <el-option label="二手车待销售" value="二手车待销售"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :xl="6" :lg="6">
        <el-input v-model="form.benefitAmount" placeholder="受益方金额" class=" ml10 mr10 mt10 w300" size="medium" ></el-input>
      </el-col>
      <el-col :xl="6" :lg="6">
        <div class="flex flex_align_center mt10 w5">
          <span>办证完成日期</span>
          <el-date-picker
            v-model="form.date"
            type="date" class="ml10 w200"
            placeholder="办证完成日期" size="medium" 
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :xl="6" :lg="6">
        <div class="flex flex_align_center mt10 w5">
          <span>办证截止日期</span>
           <el-date-picker
            v-model="form.date1"
            type="date" class="ml10 w200"
            placeholder="办证截止日期" size="medium" 
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :xl="6" :lg="6">
        <el-button plain size="medium" type="primary" @click="onCert" class="ml10 mt10">提交</el-button>
      </el-col>
    </el-row>
    <!-- </div> -->
    <el-row :gutter="24">
      <el-col :xl="24" :lg="24">
        <!-- <el-button plain size="medium" type="primary" @click="onCert" class="ml10">提交</el-button> -->
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{
        carNo:'',
        customerNo:'',
        merchantOrder:'',
        customerNos:'',
        contractId:'',//合同id
        followUpInfoId:'',//客户跟进id
        date:'',
        date1:'',
        itemName:'',
        certType:'',
        benefitAmount:'',//受益方金额
      },
      channelArr:[],//渠道
      channellist:[],
      channeProp:{
        label:'channelName',
        value:'id',
        children:'channelSubs'
      },
      resForm:{
        customerId:'',
        oldChannelId:''
      },
    }
  },
  created(){
    
  },
  methods: {
    onCert(){//修改凭证费用审核的结算跟进到待审核阶段
      let query={
          "carNo": this.form.carNo,
          // "carOwnerNatureName": "私户",
          "certFee": {
            "benefitAmount": this.form.benefitAmount,//受益方金额
            "certType": this.form.certType,
            "itemName": this.form.itemName,
            "orderNo": this.form.customerNo,
          },
          "certFinishDate": "2024-09-11",//办证完成日期
          "deadline": "2024-09-20",//办证截止日期
          // "modelName": "宝马-X5",
          "orderNo": this.form.customerNo,
        }
      this.$api.postComApplyClear(query).then(res=>{
        if(res.code==0){
          this.form.customerNo='';
          this.form.carNo='';
          this.form.itemName='';
          this.form.certType='';
        }
      })
    },
    onChannel(state){
      switch(state){
        case 1:
          if(!this.form.followUpInfoId){
            this.$message.warning('请输入客户跟进id！')
            return
          }
          this.customerQueryCustomerByFollowUpId() //查询客户详情
          break;
        case 2:
          if(!this.resForm.customerId){
            this.$message.warning('请先查询客户！')
            return
          }
          if(this.channelArr.length==0){
            this.$message.warning('请选择变更渠道!')
            return
          }
          const query={
            customerId:this.resForm.customerId,
            newChannelId:this.channelArr.length>0?this.channelArr[1]:'',
            oldChannelId:this.resForm.oldChannelId,
          }
          this.$api.postAlterCustomerChannel(query).then(res=>{
            if(res.code==0){
              this.resForm.customerId='';
              this.resForm.oldChannelId='';
              this.form.followUpInfoId="";
              this.channelArr=[];
              this.$message.success(res.message)
            }
          })
          break;
      }
    },
    returnCarInspection(){//退回验车待分配
      if(!this.form.contractId){
        this.$message.warning('请输入客户合同ID！')
        return
      }
      this.$confirm('你确认将验车入库状态修改为合同待领取吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.postAlterBsiCCReValid(this.form.contractId).then(res=>{
          if(res.code===0){
            this.form.contractId='';
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    onSubmit(state){
      switch(state){
        case 1://成交沟通预约记录重置
          if(!this.form.customerNo){
            this.$message.warning('请输入成交沟通预约记录重置的客户编号！')
            return
          }
          this.$confirm('请确认是否成交沟通预约记录重置?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postConfirmMerchantOrder()//成交预约恢复到指定的商户报价，成交待预约的状态
          }).catch(() => {});
          break;
        case 2://批量恢复被放弃的客户
          if(!this.form.customerNos){
            this.$message.warning('请输入恢复被放弃的客户编号！')
            return
          }
          this.$confirm('请确认是否恢复被放弃的客户?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postGiveUpRecover()
          }).catch(() => {});
          break;
        // case 3:
        //   this.routePage()
        //   break;
      }
    },
    postConfirmMerchantOrder(){//成交预约恢复到指定的商户报价，成交待预约的状态
      let query={
        customerNo:this.form.customerNo,
        merchantOrder:this.form.merchantOrder,
      }
      this.$api.postConfirmMerchantOrder(query).then(res=>{
        if(res.code==0){
          this.form.customerNo='';
          this.$message.success(res.message)
        }
      })
    },
    postGiveUpRecover(){//批量恢复被放弃的客户
      let query={
        customerNoList:this.form.customerNos.split(','),
      }
      this.$api.postGiveUpRecover(query).then(res=>{
        if(res.code==0){
          if(res.data===true){
            this.form.customerNos='';
            this.$message.success(res.message)
          }else{
            this.$message.error('操作失败')
          }
        }
      })
    },
    customerQueryCustomerByFollowUpId() {//查询客户详情
      this.customerQueryChannel()//根据返回的channelId,遍历channelList得到channel
      this.$api.customerQueryCustomerByFollowUpId(this.form.followUpInfoId).then(res => {
        if(res.code == 0){
          this.resForm.customerId = res.data.id;
          this.resForm.oldChannelId = res.data.channelId;
        }
      })
    },
    customerQueryChannel(){//渠道列表
      if(this.channelList){
        return
      }
      this.$api.customerQueryChannel().then(res=>{
        if(res.code==0){
          this.channellist=res.data;
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/salesAssistant/signContract'
      })
    },
  }
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px 140px;
  

}
</style>