<template>
  <!-- 宝马合作车辆拍卖结果 -->
  <div class="contenPageBox" >
    <div class="ckxTitle mb15">退回信息</div>
    <table class="table-oddwholebg table-custom" >
      <tbody>
        <tr>
          <td>任务ID</td><td>{{res.taskId}}</td>
          <td>车架号</td><td>{{res.vin}}</td>
          <td>原车牌号</td><td>{{res.taskId}}</td>
          <td>修改后车牌号</td><td>{{res.vin}}</td>
        </tr>
        <tr>
          <td>退回类型</td><td>{{res.inspectionNo}}</td>
          <td>退回原因</td><td>{{res.licenseNo}}</td>
          <td>退回时间</td><td>{{res.taskId}}</td>
          <td></td><td></td>
        </tr>
      </tbody>
    </table>
    <div class="ckxTitle mb15 mt20">检测项修改重传项</div>
    <table class="table-custom mt20 tableLine" >
      <thead>
        <th>选中修改</th>
        <th>字值名称</th>
        <th>值</th>
      </thead>
      <tbody>
        <tr v-for="item in listTest" :key="item.id">
          <td>
            <el-checkbox v-if="item.type==='checkbox'" v-model="item.checked"></el-checkbox>
          </td>
          <td>{{item.name}}</td>
          <td>
            <template v-if="item.checked">
            <el-date-picker v-if="item.dataType==='date'"
              v-model="item.value"
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-input v-else-if="item.dataType==='input'"
              placeholder="请输入" size="medium"
              v-model="item.value">
            </el-input>
            <el-select v-model="item.value" placeholder="请选择" :disabled="disabledAll" size="medium" v-else-if="item.dataType==='select'">
              <template v-if="['动力类型'].includes(item.name)">
                <el-option label="混动" value="0"></el-option>
                <el-option label="燃油" value="1"></el-option>
                <el-option label="纯电" value="2"></el-option>
                <el-option label="检测人员录入" value="3"></el-option>
              </template>
              <template v-if="['是否在经销商名下'].includes(item.name)">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </template>
            </el-select>
            </template>
            <p v-else>{{item.value}}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="ckxTitle mb15 mt20">拍卖结果项修改重传项</div>
    <table class="table-custom mt20 tableLine" >
      <thead>
        <th>选中修改</th>
        <th>字值名称</th>
        <th>值</th>
      </thead>
      <tbody>
        <tr v-for="item in listTest1" :key="item.id">
          <td>
            <el-checkbox v-if="item.type==='checkbox'" v-model="item.checked"></el-checkbox>
          </td>
          <td>{{item.name}}</td>
          <td>
            <template v-if="item.checked">
            <el-date-picker v-if="item.dataType==='date'"
              v-model="item.value"
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-input v-else-if="item.dataType==='input'"
              placeholder="请输入" size="medium"
              v-model="item.value">
              <!-- <template slot="append">次</template> -->
            </el-input>
            <el-select v-model="item.value" placeholder="请选择" :disabled="disabledAll" size="medium" v-else-if="item.dataType==='select'">
              <template v-if="['成交状态'].includes(item.name)">
                <el-option label="成交" value="0"></el-option>
                <el-option label="流拍" value="1"></el-option>
                <el-option label="违约" value="2"></el-option>
              </template>
              <template v-if="['是否在经销商名下'].includes(item.name)">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </template>
              <template v-if="['原车主类型'].includes(item.name)">
                <el-option label="个人" value="0"></el-option>
                <el-option label="公司" value="1"></el-option>
              </template>
            </el-select>
            </template>
            <p v-else>{{item.value}}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="ckxTitle mb15 mt20">结算结果项修改重传项</div>
    <table class="table-custom mt20 tableLine" >
      <thead>
        <th>选中修改</th>
        <th>字值名称</th>
        <th>值</th>
      </thead>
      <tbody>
        <tr v-for="item in listTest2" :key="item.id">
          <td>
            <el-checkbox v-if="item.type==='checkbox'" v-model="item.checked"></el-checkbox>
          </td>
          <td>{{item.name}}</td>
          <td>
            <template v-if="item.checked">
            <el-date-picker v-if="item.dataType==='date'"
              v-model="item.value"
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-input v-else-if="item.dataType==='input'"
              placeholder="请输入" size="medium"
              v-model="item.value">
              <!-- <template slot="append">次</template> -->
            </el-input>
            <el-select v-model="item.value" placeholder="请选择" :disabled="disabledAll" size="medium" v-else-if="item.dataType==='select'">
              <template v-if="['是否在经销商名下'].includes(item.name)">
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </template>
              <template v-if="['原车主类型'].includes(item.name)">
                <el-option label="个人" value="0"></el-option>
                <el-option label="公司" value="1"></el-option>
              </template>
            </el-select>
            <div class="imgRowBox" v-else>
              <div class="imgBox">
                <p v-if="imgurl1.voucherType==='pdf'" @click="onOpen(imgurl1.voucherUrl)">{{imgurl1.voucherUrl}}</p>
                <el-image v-else
                  :src="imgurl1.voucherUrl" 
                  fit="contain"
                  :preview-src-list="[imgurl1.voucherUrl]">
                  <div slot="error" class="image-slot">暂无图片</div>
                </el-image>
              </div>
                <p>资金往来凭证</p>
            </div>
            </template>
            <p v-else>{{item.value}}</p>
          </td>
        </tr>
      </tbody>
    </table>

   
    

    <div class="footer" >
      <div class="btn">
        <el-button type="primary" @click="onSubmit(1)" >发送修改结果</el-button>
        <!-- <el-button type="primary" @click="onSubmit(3)">关闭</el-button> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      returnType:0,//入口状态/**state=0查看没有按钮,1修改**/
      form:{
        payAmount:'',
        buyerPayAmount:'',
        auctionStatus:'',
        biddingStartPrice:'',
        highestBid:'',
        auctionPrice:'',
        numOfBids:'',
        auctionNumber:'',
        auctionCommission:'',
        transferDealer:"",
        preOwnerType:'',
        preOwnerName:'',
        preOwnerId:'',
        transferFinishTime:'',
      },
      // rules:{
      //   carLogistType: [{ required: true, message: '请选择车辆物流方式', trigger: 'change' }],
      //   certType:[{ required: true, message: '请选择办证方式', trigger: 'change' }],
      //   'certCompany':[{ required: true, message: '请选择办证公司', trigger: 'change' }],
      // },
      disabledAll:false,//一键禁用（针对详情）
      res:{},
      showViewer:false,
      srcList:[],
      biddingCode:'',
      channelId:'',
      id:'',//成交后来的详情
      imgurl1:{},
      imgurl2:{},
      imgurl3:{},
      imgurl4:{},
      listTest:[
        {id:1,type:'',name:'检测编号',value:'',checked:false,dataType:''},
        {id:2,type:'checkbox',name:'车牌号',value:'',checked:false,dataType:'input'},
        {id:3,type:'checkbox',name:'上牌时间',value:'',checked:false,dataType:'date'},
        {id:4,type:'',name:'品牌',value:'',checked:false,dataType:''},
        {id:5,type:'',name:'车系',value:'',checked:false,dataType:''},
        {id:6,type:'',name:'车型',value:'',checked:false,dataType:''},
        {id:7,type:'',name:'车款',value:'',checked:false,dataType:''},
        {id:8,type:'checkbox',name:'出厂日期',value:'',checked:false,dataType:'date'},
        {id:9,type:'checkbox',name:'动力类型',value:'',checked:false,dataType:'select'},
        {id:10,type:'checkbox',name:'里程',value:'',checked:false,dataType:'input'},
        {id:11,type:'checkbox',name:'过户日期',value:'',checked:false,dataType:'input'},
        {id:12,type:'checkbox',name:'车龄月',value:'',checked:false,dataType:'input'},
        {id:13,type:'checkbox',name:'是否在经销商名下',value:'',checked:false,dataType:'select'},
        {id:14,type:'checkbox',name:'检测报告URL',value:'',checked:false,dataType:'input'},
        {id:15,type:'checkbox',name:'检测报告名称',value:'',checked:false,dataType:'input'},
        {id:16,type:'checkbox',name:'检测报告下载地址',value:'',checked:false,dataType:'input'},
        {id:17,type:'checkbox',name:'下单来源',value:'',checked:false,dataType:'input'},
        {id:18,type:'checkbox',name:'检测时间',value:'',checked:false,dataType:'date'},
      ],
      listTest1:[
        {id:1,type:'',name:'检测编号',value:'',checked:false,dataType:''},
        {id:2,type:'checkbox',name:'成交状态',value:'',checked:false,dataType:'select'},
        {id:3,type:'checkbox',name:'起拍价',value:'',checked:false,dataType:'input'},
        {id:4,type:'checkbox',name:'最高出价',value:'',checked:false,dataType:'input'},
        {id:5,type:'checkbox',name:'拍卖成交价',value:'',checked:false,dataType:'input'},
        {id:6,type:'checkbox',name:'出价次数',value:'',checked:false,dataType:'input'},
        {id:7,type:'checkbox',name:'参拍人数',value:'',checked:false,dataType:'input'},
        {id:8,type:'checkbox',name:'拍卖编号',value:'',checked:false,dataType:'input'},
        {id:9,type:'checkbox',name:'拍卖佣金',value:'',checked:false,dataType:'input'},
        {id:10,type:'checkbox',name:'是否在经销商名下',value:'',checked:false,dataType:'select'},
        {id:11,type:'checkbox',name:'原车主类型',value:'',checked:false,dataType:'select'},
        {id:12,type:'checkbox',name:'原车主姓名/公司名称',value:'',checked:false,dataType:'input'},
        {id:13,type:'checkbox',name:'原车主身份证号/机构代码证',value:'',checked:false,dataType:'input'},
        {id:14,type:'checkbox',name:'经销商编号',value:'',checked:false,dataType:'input'},
        {id:15,type:'checkbox',name:'起拍时间',value:'',checked:false,dataType:'date'},
        {id:16,type:'checkbox',name:'拍卖结束时间',value:'',checked:false,dataType:'date'},
        {id:17,type:'checkbox',name:'检测Final报告URL',value:'',checked:false,dataType:'input'},
        {id:18,type:'checkbox',name:'检测Final报告名称',value:'',checked:false,dataType:'input'},
        {id:19,type:'checkbox',name:'检测Final报告下载地址',value:'',checked:false,dataType:'input'},
        {id:20,type:'checkbox',name:'拍卖结果URL',value:'',checked:false,dataType:'input'},
        {id:21,type:'checkbox',name:'拍卖结果名称',value:'',checked:false,dataType:'input'},
        {id:22,type:'checkbox',name:'拍卖结果下载地址',value:'',checked:false,dataType:'input'},
      ],
      listTest2:[
        {id:1,type:'',name:'检测编号',value:'',checked:false,dataType:''},
        {id:2,type:'',name:'拍卖编号',value:'',checked:false,dataType:''},
        {id:3,type:'',name:'成交状态',value:'',checked:false,dataType:''},
        {id:4,type:'checkbox',name:'交易发票日期',value:'',checked:false,dataType:'date'},
        {id:5,type:'',name:'付款状',value:'',checked:false,dataType:''},
        {id:6,type:'checkbox',name:'资金往来凭证',value:'',checked:false,dataType:'file'},
        {id:7,type:'checkbox',name:'二手车发票',value:'',checked:false,dataType:'file'},
        {id:8,type:'checkbox',name:'过户后的二手车登记证首页',value:'',checked:false,dataType:'file'},
        {id:9,type:'checkbox',name:'过户后的二手车登记证过户页',value:'',checked:false,dataType:'file'},
        {id:10,type:'checkbox',name:'是否在经销商名下',value:'',checked:false,dataType:'select'},
        {id:11,type:'checkbox',name:'原车主类型',value:'',checked:false,dataType:'select'},
        {id:12,type:'checkbox',name:'原车主姓名/公司名称',value:'',checked:false,dataType:'input'},
        {id:13,type:'checkbox',name:'原车主身份证号/机构代码证',value:'',checked:false,dataType:'input'},
        {id:14,type:'checkbox',name:'买家付款时间',value:'',checked:false,dataType:'date'},
        {id:15,type:'checkbox',name:'买家付款金额',value:'',checked:false,dataType:'input'},
        {id:16,type:'checkbox',name:'支付经销商时间',value:'',checked:false,dataType:'date'},
        {id:17,type:'checkbox',name:'保留价',value:'',checked:false,dataType:'input'},
        {id:18,type:'checkbox',name:'交易过户日期',value:'',checked:false,dataType:'date'},
        {id:17,type:'checkbox',name:'支付金额',value:'',checked:false,dataType:'input'},
      ]
    }
  },
  created(){
    if(this.$route.query.state) this.returnType=Number(this.$route.query.state);
    if(this.$route.query.biddingCode) this.biddingCode=this.$route.query.biddingCode;
    if(this.$route.query.channelId) this.channelId=this.$route.query.channelId;
    //成交后详情
    if(this.$route.query.id) this.id=this.$route.query.id;
    if(this.id){
      this.getDetailsByIdBmw()
    }
    //成交前详情
    if(this.biddingCode){
      this.getAuctionReport()
    }
    
  },
  methods: {
    onOpen(url){
      // let pathData1=this.$router.resolve({
      //   path:'/salesManager/customer',
      // })
      window.open(url,'_blank')
    },
    onSubmit(state){
      switch(state){
        case 1://发送拍卖结果给宝马
          this.$confirm('请确认是否发送拍卖结果给宝马?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postAuctionReportUpdate()
          }).catch(() => {});
          break;
        case 2://发送支付结果给宝马
          if(!this.form.invoiceDate){
            this.$message.warning('交易发票日期不能为空！')
            return
          }
          if(!this.form.buyerPayAmount){
            this.$message.warning('买家付款金额不能为空！')
            return
          }
          if(!this.form.payAmount){
            this.$message.warning('支付经销商金额不能为空！')
            return
          }
          this.$confirm('请确认是否发送支付结果给宝马?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postReceivePaymentInfo()
          }).catch(() => {});
          break;
        case 3:
          this.routePage()
          break;
      }
    },
    postAuctionReportUpdate(){//更新宝马订单任务拍卖报告
      let query={
        "auctionCommission": this.form.auctionCommission,
        "auctionNumber": this.form.auctionNumber,
        "auctionPrice": this.form.auctionPrice,
        "auctionStatus": this.form.auctionStatus,
        "biddingCode": this.res.biddingCode,
        "biddingStartPrice": this.form.biddingStartPrice,
        "highestBid": this.form.highestBid,
        "inspectionNo": this.res.inspectionNo,
        "numOfBids": this.form.numOfBids,
        "taskId": this.res.taskId
      }
      this.$api.postAuctionReportUpdate(query).then(res=>{
        if(res.code==0){
          this.getAuctionReport()
          this.$message.success(res.message)
        }
      })
    },
    postReceivePaymentInfo(){//给宝马发送付款结果
      let query={
        paymentVoucherId:this.id,
        buyerPayAmount:this.form.buyerPayAmount,
        payAmount:this.form.payAmount,
        invoiceDate:this.form.invoiceDate,
        transferDealer:this.form.transferDealer,//是否经销商名下
        preOwnerType:this.form.preOwnerType,//原车主类型
        preOwnerName:this.form.preOwnerName,//原车主姓名
        preOwnerId:this.form.preOwnerId,//原车主身份证号
        transferFinishTime:this.form.transferFinishTime,
      }
      this.$api.postReceivePaymentInfo(query).then(res=>{
        if(res.code==0){
          if(res.data){
            this.getDetailsByIdBmw()//成交后详情
            this.$message.success(res.message)
          }else{
            this.$message.error('操作失败！')
          }
        }
      })
    },
    closeViewer() {
      this.showViewer = false
    },
    onOperate(state){
      switch(state){
        case 1://查看
          this.showViewer=true;
          break;
        case 2://下载
          // window.open(pathData.href,'_blank')
          break;
      }
    },
    getDetailsByIdBmw(){//成交后详情
      this.$api.getDetailsByIdBmw(this.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=JSON.parse(JSON.stringify(res.data));//用来做数据判断
            this.form=JSON.parse(JSON.stringify(res.data));
            if(res.data.paymentVoucherDetaillList){
              for(let i=0;i<res.data.paymentVoucherDetaillList.length;i++){
                let item=res.data.paymentVoucherDetaillList[i]
                switch(item.voucherCode){
                  case '01':
                    this.imgurl1=item;
                    break;
                  case '02':
                    this.imgurl2=item;
                    break;
                  case '03':
                    this.imgurl3=item;
                    break;
                  case '04':
                    this.imgurl4=item;
                    break;
                }
              }
            }
            
          }
        }
      })
    },
    getAuctionReport(){//获取详情
      this.$api.getAuctionReport(this.biddingCode).then((res)=>{
        if(res){
          if(res.code==0){
            
            this.res=JSON.parse(JSON.stringify(res.data));//用来做数据判断
            
            this.form=JSON.parse(JSON.stringify(res.data));
            
          }
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/BMW/BMWPush'
      })
    },
  }
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px 140px;
  .imgRowBox{
    margin:10px 10px 5px;
    width: 200px;
    .imgBox{
      background: #ededed;
      width: 200px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        cursor: pointer;
      }
      .el-image{
        width: 200px;
        height: 150px;
        ::v-deep .image-slot{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
        }
      }
    }
    p{padding:5px 0}
  }
  .tableLine th:nth-of-type(1),
  .tableLine tbody td:nth-of-type(1){
    width: 100px;
  }
  .tableLine th:nth-of-type(2),
  .tableLine tbody td:nth-of-type(2){
    width: 300px;
  }
  .imgRowBox{
    margin:10px 10px 5px;
    width: 200px;
    .imgBox{
      background: #ededed;
      width: 200px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        cursor: pointer;
      }
      .el-image{
        width: 200px;
        height: 150px;
        ::v-deep .image-slot{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
        }
      }
    }
    p{padding:5px 0}
  }

}
.tableLine thead th:nth-of-type(3),
 #app .tableLine tbody tr td:nth-of-type(3){
    text-align:left ;
    padding: 0 10px;
}

</style>