<template>
  <div class="pageContainer bgF5 pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
    };
  },
  created() {
    let newArr=[{
                num: '',
                name: '待价格确认',
                star: 0,
                url:'/warehouseKeeper/priceConfirmation'
              },{
                num: '',
                name: '待发货',
                star: 0,
                url:'/warehouseKeeper/deliveryManagement'
              },{
                num: '',
                name: '待收货',
                star: 0,
                url:'/warehouseKeeper/receivingManagement'
              },{
                num: '',
                name: '需归还钥匙',
                star: 0,
                url:'/warehouseKeeper/lendingManagement'
              }];
    this.infolist=[{name:"业务任务",list:newArr}];
    this.getWorkbenchSellerCount();
    this.getAuctionPriceCount()
  },
  methods: {
    getWorkbenchSellerCount() {
      let user=this.$store.state.user.userData;
      let de={}
      if(user){
        de=this.$$.roleName(user,'department')
      }
      let id=de.shop?de.shop.id:'';
      if(!id){
        return
      }
      this.$api.getWorkbenchSellerCount(id).then((res) => {
        if (res.code == 0) {
          this.infolist[0].list[1].num=res.data.countSendWaited;
          this.infolist[0].list[2].num=res.data.countReceiveWaited;
          this.infolist[0].list[3].num=res.data.countCarKeyGiven;
        }
      });
    },
    getAuctionPriceCount(){//待确认价格
      this.$api.getAuctionPriceCount().then(res=>{
        if(res.code==0){
          this.infolist[0].list[0].num=res.data;
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      })
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>
