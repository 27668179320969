<template>
  <!-- 成交预约 -->
  <div class="reservationDetail">
    <!--基本信息-->
    <div class="flex_space mb10">
      <div class="ckxTitle">商户预约信息</div>
      <span class="seeColor" @click="onSeePrice">查看价格（价格查看）>></span>
    </div>
    <table class="table-oddwholebg table-custom">
      <tbody>
        <tr>
          <td>预约发起门店</td>
          <td>{{form.submitShop}}</td>
          <td>成交门店</td>
          <td>{{form.bookShop}}</td>
          <td>预约成交时间</td>
          <td>{{form.bookTime}}</td>
          <td>客户方备注</td>
          <td>{{form.customerRemark}}</td>
        </tr>
      </tbody>
    </table>
    <div class="flex_space mt20 mb10">
      <div class="ckxTitle">竞拍信息</div>
      <span class="seeColor" @click="onRouterCar">检测报告（车辆报告）>></span>
    </div>
    <table class="table-oddwholebg table-custom">
      <tbody>
        <tr>
          <td>车牌号码</td>
          <td>{{cres.carNo}}</td>
          <td>品牌车型</td>
          <td>{{cres.makerName}}&nbsp;{{cres.seriesName}}</td>
          <td>生产年份</td>
          <td>{{cres.productYear}}</td>
          <td>初登年份</td>
          <td>{{cres.registerDate?cres.registerDate.split('-')[0]:cres.registerDate}}</td>
        </tr>
        <tr>
          <td>成交类型</td>
          <td>{{cres.tradeTypeName}}</td>
          <td>使用性质</td>
          <td>{{cres.isBusiness}}</td>
          <td>车辆类型</td>
          <td>{{cres.carType}}</td>
          <td>抵押车</td>
          <td>{{form.isMortgage===1?'是':'否'}}</td>
        </tr>
        <tr>
          <td>报价时间</td>
          <td>{{auctionPriceDate}}</td>
          <td>价格有效期</td>
          <td>{{form.priceFailureTime}}</td>
          <td>年检有效期</td>
          <td>{{cres.checkExpire}}</td>
          <td>办证工作日</td>
          <td>{{cres.certDateAndRequires}}</td>
        </tr>
          <tr>
          <td>垫资金额</td>
          <td>{{cres.carDzPrice?cres.carDzPrice:'-'}}</td>
          <td>询价类型</td>
          <td>{{cres.enquiryTypeName}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div class="flex_space mt20 mb10">
      <div class="ckxTitle">商户信息</div>
    </div>
    <table class="table-oddwholebg table-custom">
      <tbody>
        <tr>
          <td>商户姓名</td>
          <td>{{mres.merchantName}}</td>
          <td>商户账号</td>
          <td>{{mres.merchantNo}}</td>
          <td>商户电话1</td>
          <td>
            <span>{{mres.merchantMobile}}</span>
            <el-button size="mini" type="primary" plain @click="callPhone(mres.merchantMobile)" class="ml5" v-if="mres.merchantMobile">呼叫</el-button>
          </td>
          <td>商户电话2</td>
          <td>
            <span>{{mres.merchantOtherMobile}}</span>
            <el-button size="mini" type="primary" plain @click="callPhone(mres.merchantOtherMobile)" class="ml5" v-if="mres.merchantOtherMobile">呼叫</el-button>
          </td>
        </tr>
        <tr>
          <td>经营地</td>
          <td>{{mres.businessAddress}}</td>
          <td>维护业务员</td>
          <td>{{mres.maintainerName}}</td>
          <td>维护业务员电话</td>
          <td>
            <span>{{mres.maintainerPhone}}</span>
            <el-button size="mini" type="primary" plain @click="callPhone(mres.maintainerPhone)" class="ml5">呼叫</el-button>
          </td>
          <td>商户名次</td>
          <td>{{form.merchantOrder}}</td>
        </tr>
      </tbody>
    </table>
    <div class="flex_space mt20 mb10">
      <div class="ckxTitle">商户预约信息</div>
      <el-button size="mini" type="primary" plain @click="onTopping">拍车宝置顶</el-button>
    </div>
    <table class="table-oddwholebg table-custom">
      <tbody>
        <tr>
          <td>成交价</td>
          <td>{{insurePriceObj.carTradedPrice}}</td>
          <td>技术服务费</td>
          <td>{{insurePriceObj.feeTechnoAmount}}</td>
          <td>库存周转费</td>
          <td>{{insurePriceObj.feeTurnoverAmount}}</td>
          <td>过户押金</td>
          <td>{{insurePriceObj.depositAmount}}</td>
        </tr>
        <tr>
          <td>拿车无忧服务费</td>
          <td>{{insurePriceObj.insureAmount}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>总价</td>
          <td>{{insurePriceObj.totalPayAmount}}</td>
        </tr>
      </tbody>
    </table>

        <!-- <div class="ckxTitle mt30">客户预约信息</div> -->
        <!-- <el-row class="mt10">
          <el-col :span="5">发起门店：{{form.submitShop}}</el-col>
          <el-col :span="5">预约门店：{{form.bookShop}}</el-col>
          <el-col :span="5">客户预约成交时间：{{form.bookTime}}</el-col>
          <el-col :span="5" v-if="dealType==1&&!state">客户方备注：{{form.customerRemark}}</el-col>
          <el-col :span="4" v-if="dealType==0">是否沟通：{{form.isComm==1?'是':form.isComm==0?'否':form.isComm}}</el-col>
        </el-row> -->
        <template v-if="evaluateList.length>0">
        <div class="ckxTitle mt30">差异信息</div>
        <el-row class="mt10" v-for="item in evaluateList" :key="item.id">
          <el-col :span="6">判定结果：{{item.evaluateResultName}}</el-col>
          <el-col :span="6">影响主要原因：{{item.diffType}}<span class="cursRoute" @click="onRouterEv(item)">（评定结果）</span></el-col>
        </el-row>
        </template>
        <!-- <div class="ckxTitle mt30">看车地点</div>
        <div class="mt10">{{res.bookShop}}：{{res.bookShopAddress}}</div> -->
        <div class="mt30" v-if="form.isDiff">
          <div class="ckxTitle">差异信息</div>
          <div class="mt10">
            <span class="label">判定结果:</span><span class="mr30">{{form.evaluateResult}}</span>
            <span class="label">影响主要原因:</span><span class="mr30">{{form.diffType}}</span>
          </div>
        </div>
    <!-- <div class="ckxTitle mt30 mb10">商户预约信息</div>
    <div class="priceContent mb20" v-if="res.bookStatus===1">
       <div class="priceFontBlod">总价（{{insurePriceObj.totalPayAmount}}）=委托收购价（{{insurePriceObj.carTradedPrice}}）+库存周转费（{{insurePriceObj.feeTurnoverAmount}}）+技术服务费（{{insurePriceObj.feeTechnoAmount}}）+过户手续押金（{{insurePriceObj.depositAmount}}）{{insurePriceObj.merchantInsure?`+拿车无忧（${insurePriceObj.merchantInsure}）`:insurePriceObj.merchantInsure}}{{insurePriceObj.insureAmount?`服务费（${insurePriceObj.insureAmount}）`:insurePriceObj.insureAmount}}</div>
       <el-button type="primary" plain @click="onTopping">将此车在拍车宝置顶</el-button>
    </div> -->
    <el-form ref="form" :model="form" size="medium" class="mt30">
      <el-form-item label="商户意向:">
        <div v-if="state">{{form.merchantStatusName}}</div>
        <el-radio-group v-model="form.merchantStatus" v-else>
          <el-radio-button :label="3">同意</el-radio-button>
          <el-radio-button :label="1">待定</el-radio-button>
          <el-radio-button :label="2" v-if="form.isComm===1||cres.tradeType==21">不同意客户条件</el-radio-button>
          <el-radio-button :label="4">放弃</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <template v-if="!state">
      <el-form-item label="商户建议成交时间:" label-width="150px" v-show="form.merchantStatus==3&&dealType==1">
        <el-date-picker v-model="form.suggestDealTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="看车方式:" v-show="form.merchantStatus==3">
        <el-radio-group v-model="form.isOnSite">
          <el-radio-button :label="0">远程查验</el-radio-button>
          <el-radio-button :label="1">现场查验</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提车方式:" v-show="form.merchantStatus==3">
        <el-radio-group v-model="form.isLogistics">
          <el-radio-button :label="0">自提</el-radio-button>
          <el-radio-button :label="1">物流</el-radio-button>
        </el-radio-group>
      </el-form-item>
      </template>
      <template v-if="form.merchantStatus==4">
        <el-form-item label="责任方:" clearable>
          <el-select v-model="form.responsibleParty">
            <el-option label="请选择责任方" value=""></el-option>
            <el-option label="商户责任" :value="52"></el-option>
            <el-option label="客户责任" :value="51"></el-option>
            <el-option label="检测差异" :value="53"></el-option>
            <el-option label="车开新责任" :value="54"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="违约类型:" v-show="form.responsibleParty===52"><p>成交预约违约</p></el-form-item>
        <el-form-item label="罚商户金额:"  v-show="form.responsibleParty===52"><p>2000元</p></el-form-item>
         <el-form-item label="是否需要赔偿商户:" v-show="[51,53,54].includes(form.responsibleParty)">
          <el-radio-group v-model="form.isComp">
            <el-radio-button :label="0">不需要</el-radio-button>
            <el-radio-button :label="1">需要</el-radio-button>
          </el-radio-group>
        </el-form-item>
         <el-form-item label="车开新赔偿商户金额:" v-show="[51,53,54].includes(form.responsibleParty)&&form.isComp===1"><p>100元</p></el-form-item>
      </template>
      <el-form-item label="备注:">
        <el-input style="width: 500px;" type="textarea" v-model="form.remark" :autosize="{minRows: 4}" :disabled="state?true:false"></el-input>
      </el-form-item>
    </el-form>
    <div class="pb100">
      <div class="ckxTitle">预约沟通过程</div>
      <el-table :data="querylist" border stripe class="table-mainbg mt20">
        <el-table-column prop="createDate" label="操作时间" align="center" ></el-table-column>
        <el-table-column prop="operatorName" label="操作人" align="center" ></el-table-column>
        <el-table-column prop="bookStatusName" label="预约状态" align="center"></el-table-column>
        <el-table-column prop="customerStatusName" label="客户意向" align="center"></el-table-column>
        <el-table-column prop="merchantStatusName" label="商户意向" align="center"></el-table-column>
        <el-table-column prop="bookShop" label="成交门店" align="center"></el-table-column>
        <el-table-column prop="bookTime" label="预约成交时间" align="center"></el-table-column>
        <el-table-column prop="customerRemark" label="客户方备注" align="center"></el-table-column>
        <el-table-column prop="merchantRemark" label="商户备注" align="center"></el-table-column>
        <el-table-column prop="merchantOrder" label="商户名次" align="center"></el-table-column>
        <el-table-column prop="merchantConfirmPrice" label="成交价" align="center"></el-table-column>
        <el-table-column prop="submitParty" label="操作方" align="center">
          <template slot-scope="scope">{{scope.row.submitParty?'商户':'客户'}}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer" v-if="!state">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >保存</el-button>
      </div>
    </div>

    <el-dialog title="前三名商户报价" :visible.sync="showList">
      <el-table :data="gridData" max-height="500px" border stripe>
        <el-table-column prop="merchantName" label="姓名" align="center">
          <!-- <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantName:scope.row.merchantName}}</template> -->
        </el-table-column>
        <el-table-column prop="merchantNo" label="账号" align="center">
          <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantNo:scope.row.merchantNo}}</template>
        </el-table-column>
        <el-table-column prop="merchantMobile" label="电话" align="center">
          <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantMobile:scope.row.merchantMobile}}</template>
        </el-table-column>
        <el-table-column prop="registerCityName" label="注册地" align="center">
          <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.registerCityName:scope.row.registerCityName}}</template>
        </el-table-column>
        <el-table-column prop="businessCityName" label="经营地" align="center">
          <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.businessCityName:scope.row.businessCityName}}</template>
        </el-table-column>
        <el-table-column prop="auctionPrice" label="报价（元）" align="center"></el-table-column>
        <el-table-column prop="auctionTime" label="报价时间" align="center"></el-table-column>
        <el-table-column prop="tradePrice" label="成交价（元）" align="center"></el-table-column>
        <el-table-column prop="maintainerName" label="商户维护人" align="center">
           <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.maintainerName:scope.row.maintainerName}}</template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="'第'+ form.merchantOrder +'名报价审核'" :visible.sync = "showIntention" width="700px" :close-on-click-modal="false" :close-on-press-escape="false" @close="closeModel">
      <div class="mb20">
        <el-row class="mb10">
          <el-col :span="12">商户编号：{{form.merchantNo}}</el-col>
          <el-col :span="12">商户姓名：{{form.merchantName}}</el-col>
        </el-row>
        <el-row class="mb10">
          <el-col :span="12">
            <span>电话1：{{mres.merchantMobile}}</span>
            <el-button type="primary" size="mini" plain @click="callPhone(mres.merchantMobile)"  class="ml10" v-if="mres.merchantMobile">呼叫</el-button>
          </el-col>
          <el-col :span="12">
            <span>电话2：{{mres.merchantOtherMobile}}</span>
            <el-button type="primary" size="mini" plain @click="callPhone(mres.merchantOtherMobile)"  class="ml10" v-if="mres.merchantOtherMobile">呼叫</el-button>
          </el-col>
        </el-row>
        <el-row class="mb10">
          <el-col :span="12">品牌车型：{{form.carType}} </el-col>
          <el-col :span="12">当前成交价：{{cres.tradePrice}}元</el-col>
        </el-row>
        <div class="">
          <el-checkbox v-model="isCustomerReason">客户责任</el-checkbox>
          <el-button size="medium" type="text" class="ml30" @click="onRouterCar">查看复检报告</el-button>
        </div>
       
        <!-- <el-radio-group v-model="customerStatus" class="mr30">
          <el-radio-button label="确认"></el-radio-button>
          <el-radio-button label="放弃"></el-radio-button>
        </el-radio-group> -->
      </div>
      <el-button type="primary" @click="onModalSubmit(1)" size="medium">确认</el-button>
       <el-button type="primary" @click="onModalSubmit(2)" size="medium" >放弃</el-button>
       <el-button type="primary" @click="onSendPrice" size="medium" >发送价格到拍车宝“价格确认”下并置顶</el-button>
      <!-- <div class="mt30">
        <span>提示: 客户责任系统不再处罚商户报价违约100元。</span>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state:'',//值为1表示查看页面
      gridData: [],//报价列表
      showList: false,//报价model
      certRequireList: [], //办证要求
      form: {
        merchantStatus: 0,
        bookTime: '',
        isOnSite: 0,
        isLogistics: 0,
        remake: '',
      },
      customerStatus: '',
      isCustomerReason: '',
      showIntention: false,
      bookTime: '',
      dealBookId:'',//商户成交预约Id
      auctionId:'',//竞拍Id
      dealType:'',//成交类型 0-垫资成交 1-预约成交
      id:'',//列表传过来的预约id
      res:{},//当前页面的详情
      querylist:[],
      cres:{},//车辆基本信息（竞拍信息）
      mres:{},//商户信息
      evaluateList:[],//差异列表
      userData:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
      insurePriceObj:{},//保险价格
      auctionPriceDate:'',//报价时间
    }
  },
  created(){
    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    if(this.$route.query.id) this.id = Number(this.$route.query.id);
    if(this.$route.query.state) this.state = this.$route.query.state
    this.getDealBookMerchantDetail(this.id)
    this.certRequireList=this.$store.state.setting.certRequireList;
  },
  methods: {
    onSeePrice(){
      this.$router.push({
        path:'/testReviewer/offerDetails',
        query:{
          stateType:1,
          carState:1,
          checkReportId:this.cres.checkReportId,
          carAuctionId:this.auctionId
        }
      })
    },
    closeModel(){
      if(this.dealType===1){//成交类型 0-垫资成交 1-预约成交
        this.bus.$emit("closeSelected",'router');
        this.$router.push({
          path:'/salesAssistant/merchantReservation'
        })
      }
    },
    onTopping(){//价格置顶
      this.$confirm('请确认是否将此车置顶?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let query={
            carAuctionId:this.auctionId,
            merchantId:this.res.merchantId,//
            merchantDealBookId:this.res.id
          }
          this.$api.postManuallyOnTop(query).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
            }
          })
        }).catch(() => {});
    },//
    onSendPrice(){//发送价格
      let query={
          carAuctionId:this.auctionId,
          onTop:1,//是否需要置顶, 1需要, 0不需要
          operType:'sendPrice',
        }
        this.$api.postAuctionAuditPrice(query).then(res=>{
          if(res.code==0){
            this.$message.success(res.message)
          }
        })
    },
    getDealBookMerchantDetail(id){//根据商户成交预约Id获取商户预约详情
      this.$api.getDealBookMerchantDetail(id).then(res=>{
        if(res.code==0){
          this.id=res.data.id;
          this.dealBookId=res.data.dealBookId;//商户成交预约Id
          this.auctionId=res.data.enquiryId;//竞拍Id
          this.dealType=res.data.dealType;//成交类型 0-垫资成交 1-预约成交
          this.res=JSON.parse(JSON.stringify(res.data));
          this.form=JSON.parse(JSON.stringify(res.data));
          if(res.data.merchantStatus==-1){
            this.showIntention=true;
          }
          // this.form.merchantStatus=res.data.merchantStatus?res.data.merchantStatus:3;
          if(res.data.merchantStatus===null){//当商户意向没有选择的时候默认选择
            this.form.isOnSite=0;
            this.form.isLogistics=0;
          }

          this.postCalculateExpenses(this.id)//计算商户预约信息中的总价
          if(res.data.enquiryId){
            this.getAuctionInfo(res.data.enquiryId)//获取竞拍详情
            this.postValidMerchantEvaluateList(res.data.enquiryId)//商户差异复检评定列表
          }
          if(res.data.merchantId) this.getMerchantDetails(res.data.merchantId)
          this.getDealBookComm(this.dealBookId)//获取沟通记录
        }
      })
    },
    postCalculateExpenses(merchantDealBookId){//计算商户预约信息中的总价
      this.$api.postCalculateExpenses(merchantDealBookId).then(res=>{
        if(res.code==0){
          this.insurePriceObj=res.data;
        }
      })
    },
    postValidMerchantEvaluateList(carId){//商户差异复检评定列表
      this.$api.postValidMerchantEvaluateList(carId).then(res=>{
        if(res.code==0){
          this.evaluateList=res.data;
        }
      })
    },
    doSubmit() {
      let query={
        "id": this.id,//预约Id
        "merchantStatus": this.form.merchantStatus,//商户意愿 1-待定 2-不同意客户条件 3-同意 4-放弃
        "remark": this.form.remark
      }
      if(this.form.merchantStatus==3){
        query.isOnSite= this.form.isOnSite;//是否现场看车 0-远程查验 1-现场查验
        query.isLogistics= this.form.isLogistics;//是否物流提车 0-自提 1-物流
      }
      if(this.form.merchantStatus==4){
        query.responsibleParty= this.form.responsibleParty;//责任方: 51客户责任，52商户责任，53检测差异，54车开新责任
        query.isComp= this.form.isComp;// 0-不需要 1-需要
      }
      if(this.dealType==1){
        query.suggestDealTime= this.form.suggestDealTime;//商户建议成交时间
      }
      if(this.form.merchantStatus===3){
        // if(!this.form.suggestDealTime&&this.dealType==1){
        //   this.$message.warning('请选择成交时间！')
        //   return
        // }
        if(this.form.isOnSite===''||this.form.isOnSite===undefined||this.form.isOnSite===null){
          this.$message.warning('请选择看车方式！')
          return
        }
       
        if(this.form.isLogistics===''||this.form.isLogistics===undefined||this.form.isLogistics===null){
          this.$message.warning('请选择提车方式！')
          return
        }
      }else if(this.form.merchantStatus===4){
        if(this.form.responsibleParty===''||this.form.responsibleParty===undefined||this.form.responsibleParty===null){
          this.$message.warning('请选择责任方！')
          return
        }
      }

      this.$api.merchantSubmitBook(this.$$.delete_null_properties(query)).then(res => {
        if(res.code == 0){
          if(res.data.nextId){
            this.getDealBookMerchantDetail(res.data.nextId)
            this.getDealBookComm(this.dealBookId)//获取沟通记录
            this.$message.success(res.message)
          }else{
            this.getDealBookMerchantDetail(this.id)
            this.$router.go(-1)
          }
        }
      })
    },
    onModalSubmit(state){//1确认  2放弃
      this.$confirm(`请确认是否当前商户${state==1?'确认':'放弃'}报价`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postConfirmPrice(state)
        }).catch(() => {});
    },
    postConfirmPrice(state){//商户确认报价
      let query={
        "id": this.id,//商户预约Id
        "confirmResult": state==1?1:0,//确认结果 0-放弃 1-确认
        "isCustomerReason": this.isCustomerReason?1:0//放弃是否客户原因 0或不传-非客户原因,1-客户原因
      }
      this.$api.postConfirmPrice(query).then(res=>{
        if(res.code == 0){
          this.showIntention=false;
          if(this.dealType===1){//成交类型 0-垫资成交 1-预约成交
            if(state===1){
              this.closeModel()
            }else{
              if(res.data.nextId){
                this.bus.$emit("closeSelected",'router');
                this.$router.push({
                  path:'/salesAssistant/reservationDetail',
                  query:{
                    id:res.data.nextId
                  }
                })
              }else{
                this.closeModel()
              }
            }
            
          }else{
            if(res.data.nextId){
              this.bus.$emit("closeSelected",'router');
              this.$router.push({
                path:'/salesAssistant/reservationDetail',
                query:{
                  id:res.data.nextId
                }
              })
              // this.getDealBookMerchantDetail(res.data.nextId)
            }else{
              this.getDealBookMerchantDetail(this.id)
            }
            this.getDealBookComm(this.dealBookId)//获取沟通记录
          } 
          
          this.$message.success(res.message)
        }
      })
    },
    onModel(){
      this.showList=!this.showList;

      this.getAuctionPriceFinallyList(this.form.enquiryId)
    },
    onRouterCar(){
      if(!this.form.carValidId){
        this.$message.warning('当前数据暂无复检！')
        return
      }
      this.$router.push({//2开头-车辆审核，1-车型审核，11车型查看，3其他页面过来，仅查看
        // path:'/testReviewer/auditReport',
        path:'/storeManager/difEvaluationDetail',
        query:{//检测id
          // id:this.form.checkReportId,
          carValidId:this.form.carValidId,
          title:'复检详情'
          // state:3
        }
      })
    },
    getDealBookComm(dealBookId){//获取沟通记录
      this.$api.getDealBookComm(dealBookId).then(res=>{
        if(res.code==0){
          this.querylist=res.data;
        }
      })
    },
    getAuctionInfo(auctionId){//车辆简单详情
      this.$api.getAuctionInfo(auctionId).then(res=>{
        if(res.code==0){
          this.cres=res.data;
          if(res.data.auctionPriceList){
            for(let i=0;i<res.data.auctionPriceList.length;i++){
              let item=res.data.auctionPriceList[i]
              if(item.merchantId===this.form.merchantId){
                this.auctionPriceDate=item.auctionTime;
              }
            }
          }
        }
      })
    },
    getMerchantDetails(merchantId){//商户详情
      this.$api.getMerchantDetails(merchantId).then(res=>{
        if(res.code==0){
          this.mres=res.data;
        }
      })
    },
    getAuctionPriceFinallyList(carAuctionId){//根据车Id查询该场次的前三名商户报价【已确认的报价】
      this.$api.getAuctionPriceFinallyList(carAuctionId).then(res=>{
        if(res.code==0){
          this.gridData=res.data;
        }
      })
    },
    onRouterEv(row){
      this.$router.push({
        path: '/storeManager/difEvaluationDetail',
        query:{
          carValidId:row.carValidId,
          // state:3,//默认没有值为查看，1差异评定，2差异确认，3差异复检
          // title:'商户差异评定'
        }
      })
    },
    callPhone(row) {
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row)
      // })//根据员工工号查询坐席信息(拨打电话)
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        if(this.cno){
          resolve(true)
          return
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.reservationDetail{
  padding: 20px 40px 100px;
  .label{width: 120px; text-align: right;display: inline-block;}
  .merchantInfo>div:nth-child(1){width: 45%;}
  .merchantInfo>div:nth-child(2){width: 55%;}
  .merchantInfo>div:nth-child(2) .label{width: 140px;}
  .mt30 {margin-top: 30px;}
  .cursRoute{
    cursor:pointer;
    color:#409EFF;
    border-bottom:1px solid #409EFF;
  }
  .priceContent{
    display:flex;
    justify-content: space-between;
    align-items:center;
  }
  .priceFontBlod{
    font-weight:bold;
  }
  .flex_space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .seeColor{
    color:#409EFF;
    cursor: pointer;
  }
}
</style>