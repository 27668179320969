<template>
  <div class="remindWrap">
    <el-button plain size="mini" type="primary" @click="remindVisible = true" :disabled="disabled">加提醒</el-button>
    <el-dialog title="提醒内容" :visible.sync="remindVisible" center>
      <el-form :model="remindForm" :rules="remindRules" ref="remindForm" label-width="150px">
        <el-form-item label="下次提醒时间：" prop="date" class="mb10">
          <el-date-picker type="datetime" placeholder="选择日期" v-model="remindForm.date" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="提醒内容：" prop="content">
          <el-input type="textarea" v-model="remindForm.content" :autosize="{minRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="remindVisible = false">取 消</el-button>
        <el-button type="primary" @click="remind">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    followUpInfoId: {
      type: Number,
    },
    userId: {
      type: Number,
    },
    type: {
      type: Number,
      default: 6//0：下次联系人提醒 1:沟通消息 2:加急消息 3:400呼损 4:老客户再次进入 5:新数据 6:手工添加提醒
    },
    plateNumber: {
      type: String,
    },
    disabled:{
      type:Boolean,
      default:function(){
        return false
      }
    }
  },
  data() {
    return {
      remindVisible: false,
      remindForm: {
        content: '',
        date:'',
      },
      remindRules: {
        content: [{ required: true, message: '请填写提醒内容', trigger: 'blur' }],
        date: [{ required: true, message: '不能为空', trigger: 'blur' }],
      }
    }
  },
  methods: {
    remind() {
      console.log(this.userId, this.type, this.plateNumber,this.followUpInfoId)
      this.$refs['remindForm'].validate((valid) => {
        if (valid) {
          this.$api.messageSaveUserMessage({
            userId: this.userId,
            type: this.type,
            plateNumber: this.plateNumber,
            content: this.remindForm.content,
            followUpInfoId: this.followUpInfoId,
            reminderTime:this.remindForm.date,
          }).then(res => {
            if(res.code == 0){
              this.$refs['remindForm'].resetFields();
              this.remindVisible = false;
              this.$message.success(res.message)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>