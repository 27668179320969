/** 获取数据字典公用方法,用闭包存储接口数据,避免重复调用 **/

import $api from '../api';
import $store from '../store'
import $router from '../router'
import ElementUI from 'element-ui';

const $datadic = {
  //服务大小类所有选项
  getTaskType: function(){
    let result = [];
    return () => {
      if(result.length === 0){
        return new Promise((resolve, reject) => {
          $api.customerGetServiceType().then(res => {
            if(res.code == 0){
              result = res.data;
              resolve(result);
            }else{
              reject(res.data.message)
            }
          })
        })
      }else{
        return Promise.resolve(result)
      }
    }
  }(),

  //服务记录标题-大小标题(记录类型)
  getServiceTitle: function(){
    let result = [];
    return () => {
      if(result.length === 0){
        return new Promise((resolve, reject) => {
          $api.customerGetServiceTitle().then(res => {
            if(res.code == 0){
              result = res.data;
              resolve(result);
            }else{
              reject(res.data.message)
            }
          })
        })
      }else{
        return Promise.resolve(result)
      }
    }
  }(),

  //短信模板查询条件大小类
  async getSmsQueryTitle(state) {
    let result = [];
   await $api.smsQueryTitle(state).then(res => {
      if(res.code == 0){
        for(let i in res.data){
          let obj = {label: i, value: i, children: []};
          res.data[i].forEach(item => {
            obj.children.push({label: item, value: item})
          })
          result.push(obj)
        }
      }else{
        return Promise.reject(res.data.data)
      }
    })
    return Promise.resolve(result)
  },

  //获取所有客户渠道
  getCustomerQueryChannel: function(){
    let result = [];
    return () => {
      if(result.length === 0){
        return new Promise((resolve, reject) => {
          $api.customerQueryChannel().then(res => {
            if(res.code == 0){
              result = res.data
              result.forEach(item => {
                item.value = item.id;
                item.label = item.channelName;
                if(item.channelSubs){
                  item.children = item.channelSubs
                  item.children.forEach(i => {
                    i.value = i.id;
                    i.label = i.channelName;
                  })
                }
              })
              resolve(result);
            }else{
              reject(res.data.message)
            }
          })
        })
      }else{
        return Promise.resolve(result)
      }
    }
  }(),

  //获取所有记录类型大小标题
  serviceRecordType(type = 'before') {
    const BIG_TITLE = $store.state.setting.BIG_TITLE;
    const SMALL_TITLE = $store.state.setting.SMALL_TITLE;
    let recordTypeAfterUpload = [], recordTypeBeforeUpload = [];
    for(var i = 0; i< BIG_TITLE[0].length; i++){
      recordTypeAfterUpload.push({
        value: BIG_TITLE[0][i][0],
        label: BIG_TITLE[0][i][1],
        children: [],
      })
      for(var j = 0; j< SMALL_TITLE.length; j++){
        if(SMALL_TITLE[j][0].slice(0,3)===BIG_TITLE[0][i][0]){
          recordTypeAfterUpload[i].children.push({
            value: SMALL_TITLE[j][0],
            label: SMALL_TITLE[j][1],
          })
        }
      }
    }
    for(var ii = 0; ii< BIG_TITLE[1].length; ii++){
      recordTypeBeforeUpload.push({
        value: BIG_TITLE[1][ii][0],
        label: BIG_TITLE[1][ii][1],
        children: [],
      })
      for(var jj = 0; jj< SMALL_TITLE.length; jj++){
        if(SMALL_TITLE[jj][0].slice(0,3)===BIG_TITLE[1][ii][0]){
          recordTypeBeforeUpload[ii].children.push({
            value: SMALL_TITLE[jj][0],
            label: SMALL_TITLE[jj][1],
          })
        }
      }
    }
    if(type === 'before') {
      return recordTypeBeforeUpload
    }else if(type === 'after'){
      return recordTypeAfterUpload
    }
  },
  getUserData:function(userId){ //根据用户ID获取用户及角色菜单资源权限
    $api.getUserData(userId).then(res =>{
      if(res){
        if(res.code==0){
          let menulist=[];
          let menulistRule=[];//作为判断是否存在当前页面
          const menuRole=localStorage.getItem('menuRole');
          res.data.menuList=res.data.menuList?res.data.menuList:[];
          res.data.menuWithCurrentRoleList=res.data.menuWithCurrentRoleList?res.data.menuWithCurrentRoleList:[];
          // if(res.data.menuList.length==0&&res.data.menuWithCurrentRoleList.length==0){
          //   ElementUI.Message({
          //     message: '当前角色没有分配菜单，请联系管理员！',
          //     type: 'warning'
          //   })
          //   return
          // }
          if(!res.data.menuWithCurrentRoleList||res.data.menuWithCurrentRoleList.length==0){
            localStorage.setItem('roleMenuType',false)
            $router.push({//跳转到角色页面
              path:'/role'
            })
            // $store.dispatch('user/setRoleMenulist', false)
            return
          }
          if(res.data.menuWithCurrentRoleList.length>0){
            localStorage.setItem('roleMenuType',true)
            // $store.dispatch('user/setRoleMenulist', true)
          }
          if(menuRole) if(menuRole.length>0&&!res.data.menuWithCurrentRoleList){
            localStorage.clear();
            return
          }
          if(!res.data.menuWithCurrentRoleList||res.data.menuWithCurrentRoleList.length==0){
            if(res.data.userType===1){
              $router.push({//跳转到角色页面
                path:'/workbench/index'
              }).then(()=>{
                location.reload();
              })
            }else{
              $router.push({//跳转到角色页面
                path:'/role'
              })
            }
            return
          }
          for(let i=0;i<res.data.menuWithCurrentRoleList.length;i++){
            let item=res.data.menuWithCurrentRoleList[i];
            let children=[];
            let menuhide=true;//默认隐藏一级菜单
            if(item.subMenuList){
              for(let j=0;j<item.subMenuList.length;j++){
                let items=item.subMenuList[j]
                let aobj={
                  "path":items.router, 
                  "name":items.moduleName, 
                  component: () => import(`@/views/${items.moduleAddress}.vue`),
                  "meta":{
                    "title":items.name,
                  }
                }
                 items.isFixed==0?aobj.meta.affix=true:'';////是否固定，0是，1否，默认1
                  items.isCached==0?aobj.meta.keepAlive=true:aobj.meta.keepAlive=false;
                  items.isHide?aobj.meta.isHide=false:aobj.meta.isHide=true;
                  items.isNewPage==0?aobj.meta.isNewVuePage=true:'';//是否新开页面，0是，1否，默认1
                  aobj.meta.isLink=items.outLink?items.outLink:'';//外链地址
                children.push(aobj)
                menulistRule.push(aobj.path)
                /**判断是否需要隐藏一级菜单
                 * 规则：当所有二级菜单为隐藏时，则隐藏一级菜单
                 * **/
                if(items.isHide==1){//是否隐藏，0是，1否，默认1
                  menuhide=false
                }
              }
            }
            let obj={
                "path":item.router, 
                "name":item.moduleName, 
                component: () => import(`@/views/${item.moduleAddress}.vue`),
                "meta":{
                  "title":item.name,
                  "menuhide":menuhide
                },
              }
            item.isFixed==0?obj.meta.affix=true:'';//是否固定，0是，1否，默认1
            item.isCached==0?obj.meta.keepAlive=true:obj.meta.keepAlive=false;//是否缓存，0是，1否，默认1
            item.isHide?obj.meta.isHide=false:obj.meta.isHide=true;//是否显示，0是，1否，默认0(true为隐藏)
            item.isNewPage==0?obj.meta.isNewVuePage=true:'';//是否新开页面，0是，1否，默认1
            
            /**特别提示：isLink值为存在外链情况，在后台配置外链需要把链接在写outLink字段里面，其他无效！**/
            obj.meta.isLink=item.outLink?item.outLink:'';//外链地址
            children.length>0?obj.children=children:''
            menulist.push(obj)
            menulistRule.push(obj.path)
            // $router.addRoute('home',{ ...obj });
          }
          // console.log(menulistRule,'===menulistRule===')
          localStorage.setItem('menulistRule',JSON.stringify(menulistRule))
          localStorage.setItem('menuRole',JSON.stringify(res.data.menuWithCurrentRoleList))
          for(let i=0;i<res.data.roleList.length;i++){
            let item=res.data.roleList[i]
            if(item.isCurrent==0){
              let roleTitle='';
              switch(item.name){
                case '系统管理员':
                case '管理员':
                case 'admin':
                  roleTitle='admin';
                  break;
                case '在线客服':
                  roleTitle='onlineService';
                  break;
                case '检测坐席组长':
                case '检测坐席':
                  roleTitle='testService';
                  break;
                case '调度文员':
                case '文员':
                  roleTitle='clerk';
                  break;
                case '帮卖经理':
                  roleTitle='salesManager';
                  break;
                case '检测审核':
                  roleTitle='testReviewer';
                  break;
                case '互动部经理':
                  roleTitle='interactManager';
                  break;
                case '门店店长':
                case '店长':
                  roleTitle='storeManager';
                  break;
                case '检测主管':
                  roleTitle='inspectorSupervisor';
                  break;
                case '销售助理':
                  roleTitle='salesAssistant';
                  break;
                case '办证专员':
                  roleTitle='accreditationCommissioner';
                  break;
                case '商户部':
                  roleTitle='merchantDepartment';
                  break;
                case '渠道部':
                  roleTitle='channelDepartment';
                  break;
                case '库管员':
                  roleTitle='warehouseKeeper';
                  break;
                case '财务':
                  roleTitle='finance';
                  break;
              }
              //赋予权限testReviewer，admin,onlineService等名称展示显示内容
              $store.dispatch('permission/setPermission', [roleTitle])
            }
          }
          let DeparArr=res.data.sysUserDepartmentList?res.data.sysUserDepartmentList.map(item=>{return item.isCurrent}):[];
          let RoleArr=res.data.sysUserRoleList?res.data.sysUserRoleList.map(item=>{return item.isCurrent}):[];
          
          $store.dispatch('routesList/GenerateRoutes',menulist).then(() => {
            menulist.forEach((route) => {
              $router.addRoute('home',{ ...route });
            });
            // console.log($router,'=====111====')
           if(res.data.menuWithCurrentRoleList.length>0){
              // bus.$emit("closeAllTags");
              let path=menulist[0].children?menulist[0].children[0].path:menulist[0].path;
              // $router.push({
              //   path:path
              // })
              if(RoleArr.includes(0)&&DeparArr.includes(0)){
                $router.push({
                  path:path
                })
              }else{
                $router.push({
                  path:'/role'
                })
                // this.onsubmit(res.data)
              }
              
            }
          }); 
        }
      }
    })
  },
  onsubmit(res){
    let a1=new Promise((resolve,reject)=>{
      let query={
        currentRoleId:res.sysUserRoleList[0].id
      }
      $api.changeCurrentRoleByUserId(res.id,query).then((res)=>{
        if(res.code==0){
          resolve()
        }else{
          reject(res)
        }
      })
    })
    let a2=new Promise((resolve,reject)=>{
      let query={
        currentDepartmentIds:res.sysUserDepartmentList[0].id
      };
      $api.changeCurrentDepartmentByUserId(res.id,res.sysUserRoleList[0].id,query).then((res)=>{
        if(res.code==0){
          resolve()
        }else{
          reject(res)
        }
      })
    })
    Promise.all([a1,a2]).then(()=>{
      $router.push('/').then(()=>{
        location.reload();
      })
    }).catch(()=>{})
  },
  getProvince:function(params){//根据level递归获取行政地区,含级联数据【level：0-查询全部级别；1-查询省；2-查询省市；3-查询省市区】
    switch(params){
      case 1:
        if($store.state.setting.provinceList.length>0){//三级联动（省）
          return
        }
        break;
      case 2:
        if($store.state.setting.cityList.length>0){//三级联动（省市）
          return
        }
        break;
      case 0:  
      case 3:
        if($store.state.setting.areaList.length>0){//三级联动（省市区）
          return
        }
        break;
    }
    return new Promise((resolve, reject) => {
      $api.regionGetRecursionRegionByLevel(params).then((res)=>{
        if(res){
          if(res.code==0){
            switch(params){
              case 1:
                $store.dispatch('setting/setProvince',res.data)//三级联动（省）
                break;
              case 2:
                $store.dispatch('setting/setCity',res.data)//三级联动（省市）
                break;
              case 0:  
              case 3:
                $store.dispatch('setting/setArea',res.data)//三级联动（省市区）
                break;
            }
            resolve(res.data);
          }else{
            switch(params){
              case 1:
                $store.dispatch('setting/setProvince',[])//三级联动（省）
                break;
              case 2:
                $store.dispatch('setting/setCity',[])//三级联动（省市）
                break;
              case 0:
              case 3:
                $store.dispatch('setting/setArea',[])//三级联动（省市区）
                break;
            }
            reject([]);
          }
        }
      })
    }).catch(() =>{
      return Promise.resolve([]);
    })
  },
  getRegionByConditions(params){
    switch(params){
      // case 1:
      //   if($store.state.setting.provinceList.length>0){//三级联动（省）
      //     return
      //   }
      //   break;
      case 2:
        if($store.state.setting.cityListSelect.length>0){//无级联市
          return
        }
        break;
      // case 0:  
      // case 3:
      //   if($store.state.setting.areaList.length>0){//三级联动（省市区）
      //     return
      //   }
        // break;
    }
    return new Promise((resolve, reject) => {
      let query={
        level:params
      }
      $api.getRegionByConditions(query).then((res)=>{
        if(res){
          if(res.code==0){
            switch(params){
              // case 1:
              //   $store.dispatch('setting/setProvinceSelect',res.data)//三级联动（省）
              //   break;
              case 2:
                $store.dispatch('setting/setCitySelect',res.data)//三级联动（省市）
                break;
              // case 0:  
              // case 3:
              //   $store.dispatch('setting/setArea',res.data)//三级联动（省市区）
              //   break;
            }
            resolve(res.data);
          }else{
            switch(params){
              // case 1:
              //   $store.dispatch('setting/setProvince',[])//三级联动（省）
              //   break;
              case 2:
                $store.dispatch('setting/setCitySelect',[])//三级联动（省市）
                break;
              // case 0:
              // case 3:
              //   $store.dispatch('setting/setArea',[])//三级联动（省市区）
              //   break;
            }
            reject([]);
          }
        }
      })
    }).catch(() =>{
      return Promise.resolve([]);
    })
  },
  getCarBrand:function(params){//车辆品牌 列表查询
    if($store.state.setting.carBrandList.length>0){
      return
    }
    return new Promise((resolve, reject) => {
      $api.baseCarBrandList(params).then((res)=>{
        if(res){
          if(res.code==0){
            $store.dispatch('setting/setCarBrand',res.data)
            resolve(res.data);
          }else{
            $store.dispatch('setting/setCarBrand',[])
            reject([]);
          }
        }
      })
    }).catch(() =>{
      return Promise.resolve([]);
    })
  },
  getCustomerChannel:function(params){//客户渠道查询
    if($store.state.setting.customerChannelList.length>0){
      return
    }
    return new Promise((resolve, reject) => {
      $api.customerQueryChannel(params).then((res)=>{
        if(res){
          if(res.code==0){
            $store.dispatch('setting/setCustomerChannelList',res.data)
            resolve(res.data);
          }else{
            $store.dispatch('setting/setCustomerChannelList',[])
            reject([]);
          }
        }
      })
    }).catch(() =>{
      return Promise.resolve([]);
    })
  },
  getBankFinance:function(params){//财务支付方式列表
    if($store.state.setting.paymentMethodList.length>0){
      return
    }
    return new Promise((resolve, reject) => {
      $api.getBankFinancePayTypeList(params).then((res)=>{
        if(res){
          if(res.code==0){
            $store.dispatch('setting/setPaymentMethodList',res.data)
            resolve(res.data);
          }else{
            $store.dispatch('setting/setPaymentMethodList',[])
            reject([]);
          }
        }
      })
    }).catch(() =>{
      return Promise.resolve([]);
    })
  },
  getBankMerchantPayTypeList:function(params){//商户支付方式列表
    if($store.state.setting.BankMerchantPayList.length>0){
      return
    }
    return new Promise((resolve, reject) => {
      $api.getBankMerchantPayTypeList(params).then((res)=>{
        if(res){
          if(res.code==0){
            $store.dispatch('setting/setBankMerchantPayList',res.data)
            resolve(res.data);
          }else{
            $store.dispatch('setting/setBankMerchantPayList',[])
            reject([]);
          }
        }
      })
    }).catch(() =>{
      return Promise.resolve([]);
    })
  },
}

export default $datadic