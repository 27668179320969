<template>
  <div class="inventBoxBG">
    <div class="inventBox">
      <!--车辆详情-->
      <div class="content_modal_textBox">
        <div class="head_top_title">
          <i></i>
          <span>车辆详情</span>
        </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>车辆号牌</td>
              <td>{{res.carNo}}</td>
              <td>厂牌型号</td>
              <td>{{res.makerName}}&nbsp;{{res.seriesName}}</td>
              <td>发动机号</td>
              <td>{{res.engineNo}}</td>
              <td>颜色</td>
              <td>{{res.carColor}}</td>
            </tr>
            <tr>
              <td>初登日期</td>
              <td>{{res.registerDate}}</td>
              <td>年检有效期</td>
              <td>{{res.checkExpire}}</td>
              <td>车架号</td>
              <td class="wordWrap">{{res.vinCode}}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex">
        <!--合同约定时间-->
        <div class="content_modal_textBox mt30 w50 mr30">
          <div class="head_top_title">
            <i></i>
            <span>合同约定时间</span>
          </div>
          <el-table :data="contractlist" border>
            <el-table-column prop="name" label="合同类型" align="center"></el-table-column>
            <el-table-column prop="contractDate" label="签订时间" align="center"></el-table-column>
            <el-table-column prop="tradedCarCertDate" label="材料完成时间" align="center"></el-table-column>
          </el-table>
        </div>
        <!--商户信息-->
        <div class="content_modal_textBox mt30 w50">
          <div class="head_top_title">
            <i></i>
            <span>商户信息</span>
          </div>
          <table class="table-oddwholebg table-custom">
            <tbody>
              <tr>
                <td>商户姓名</td>
                <td>{{res.merchantName}}</td>
                <td>办证公司</td>
                <td>{{res.certCompany}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--车辆库存-->
      <div class="content_modal_textBox mt30">
        <div class="head_top_title">
          <i></i>
          <span>车辆库存</span>
        </div>
        <el-table :data="carlist" border @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column>
          <el-table-column prop="stockObjName" label="所在库" align="center"></el-table-column>
          <el-table-column prop="stockObj" label="库位" align="center">
            <template slot-scope="scope">{{scope.row.stockObjAreaName}}{{scope.row.stockObjLocationName?'-'+scope.row.stockObjLocationName:''}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onoper(scope.row,1)"
                size="mini"
                class="group-custom"
              >明细</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--手续材料管理-->
      <div class="content_modal_textBox mt30" :class="stateType!=2?'pb100':''">
        <div class="head_top_title flex flex_between">
          <div class="flex flex_align_center">
            <i></i>
            <span>手续材料管理</span>
          </div>
          <el-button type="primary" @click="showAddProjectDialog" size="medium">添加项目</el-button>
        </div>
        <el-table :data="carMaterialsList" border @selection-change="handleSelectionChange2">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column prop="materialName" label="内容" align="center"></el-table-column>
          <!-- <el-table-column prop="contractRequiredNums" label="数量" align="center"></el-table-column> -->
          <el-table-column prop="materialType" label="材料类型" align="center">
            <!-- <template slot-scope="scope">{{scope.row.type=='customer'?'主项':'其他'}}</template> -->
          </el-table-column>
          <el-table-column prop="materialStatus" label="材料状态" align="center"></el-table-column>
          <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column>
          <el-table-column prop="stockObjName" label="所在库" align="center"></el-table-column>
          <el-table-column prop="stockObjInName" label="库位" align="center">
            <template slot-scope="scope">{{scope.row.stockObjAreaName}}{{scope.row.stockObjLocationName?'-'+scope.row.stockObjLocationName:''}}</template>
          </el-table-column>
          <el-table-column prop="updateBy" label="操作人" align="center"></el-table-column>
          <el-table-column prop="updateDate" label="上次操作时间" align="center"></el-table-column>
          <el-table-column prop="screenshotList" label="附件缩略图" align="center" width="200px">
            <template slot-scope="scope">
              <el-upload
                action
                accept="image/png, image/gif, image/jpeg,image/jpg" class="tableUpload"
                :on-change="(file, fileList)=>requestUpload(file,fileList,scope.row)"
                :file-list="handleFileList(scope.row.screenshotList)"
                :auto-upload="false"
                list-type="picture-card"
              >
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url"/>
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file,scope.row)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
                <el-button type="primary" size="mini" class="group-custom">上传附件</el-button>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onoper(scope.row,2)"
                size="mini"
                class="group-custom"
              >明细</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="handleIn">入库</el-button>
          <el-button type="primary" @click="handleOut">出库</el-button>
          <!-- <el-button type="primary" @click="doSubmit('form','一键出库')">一键出库</el-button> -->
        </div>
      </div>
      <!--查看缩略图-->
      <el-dialog :visible.sync="dialogVisibleImg">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
      <!--添加项目-->
      <el-dialog title="添加项目" :visible.sync="showAddProject" class="customDialog addProject">
        <el-checkbox-group v-model="checkList" class="modalCheckBox">
          <el-checkbox v-for="item in projectList" :key="item.id"  :label="item">{{item.materialName}}{{item.selfDefineRemark}}</el-checkbox>
        </el-checkbox-group>
        <!-- <el-checkbox-group v-model="checkList">
          <el-checkbox label="机动车照片"></el-checkbox>
          <el-checkbox label="其他1">
            <label>其他1：</label>
            <el-input v-model="form.text" placeholder size="small"></el-input>
          </el-checkbox>
        </el-checkbox-group> -->
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitProject">提交</el-button>
        </span>
      </el-dialog>
      <!--车辆操作明细-->
      <el-dialog title="车辆库存流转明细" :visible.sync="dialogVisibleCar">
        <el-table :data="modallist" border>
          <!-- <el-table-column prop="stockObjOutName" label="出库仓库" align="center"></el-table-column>
          <el-table-column prop="stockObjInName" label="入库仓库" align="center"></el-table-column>
          <el-table-column prop="operaTypeName" label="出入库类型" align="center"></el-table-column>
          <el-table-column prop="inObjEmpName" label="接收人" align="center"></el-table-column>
          <el-table-column prop="operaEmpName" label="操作人" align="center"></el-table-column>
          <el-table-column prop="operaTime" label="操作时间" align="center"></el-table-column> -->
          <el-table-column prop="stockTypeName" label="出入库类型" align="center"></el-table-column>
          <el-table-column prop="outObjName" label="出库对象" align="center"></el-table-column>
          <el-table-column prop="operaTime" label="操作时间" align="center"></el-table-column>
          <el-table-column prop="operaTypeName" label="操作" align="center"></el-table-column>
          <el-table-column prop="operaEmpName" label="操作人" align="center"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleCar = false">关闭</el-button>
        </span>
      </el-dialog>
      <!--操作明细-->
      <el-dialog :title="`${itemRows.materialName}-操作明细`" :visible.sync="dialogVisible">
        <el-table :data="modallist" border>
          <el-table-column prop="stockTypeName" label="出入库类型" align="center"></el-table-column>
          <el-table-column prop="outObjName" label="出库对象" align="center"></el-table-column>
          <el-table-column prop="operaTime" label="操作时间" align="center"></el-table-column>
          <el-table-column prop="operaTypeName" label="操作" align="center"></el-table-column>
          <el-table-column prop="operaEmpName" label="操作人" align="center"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
      <!--入库信息-->
      <el-dialog
        title="入库信息"
        :visible.sync="dialogVisibleEnter"
        :show-close="false"
        class="customDialog"
        width="710px"
      >
        <el-form
          ref="modalform"
          :model="modalform"
          :rules="modalformRules"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <div v-if="modalType=='car'||modalType=='auto'">
             <p class="fontbold">车辆入库</p>
            <div class=" mt10">
              <el-form-item label="仓库:" prop="reissue">
                <el-select v-model="modalform.stockObjIn" placeholder="请选择仓库" size="medium" class="w150 mr20" value-key="id" @change="selectChange($event,1,'car')" @visible-change="handleChangeFlag($event,1)">
                  <el-option v-for="item in stockObjList" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable===1?true:false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货区:" prop="reissue">
                <el-select v-model="modalform.stockObjArea" placeholder="请选择货区" size="medium" class="w150 mr20" value-key="id" @change="selectChange($event,2,'car')" @visible-change="handleChangeFlag($event,1)">
                  <el-option v-for="item in goodsArealist" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable===1?true:false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货位:" prop="reissue">
                <el-select v-model="modalform.stockObjLocationId" placeholder="请选择货位" size="medium" class="w150">
                  <el-option v-for="item in placelist" :key="item.id" :label="item.name" :value="item.id" :disabled="item.isEnable===1?true:false">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6;font-size:12px">{{ item.isFree===1?'已占用':'' }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div v-if="modalType=='pro'||modalType=='auto'">
            <p class="fontbold">手续入库</p>
            <div class=" mt10">
              <el-form-item label="仓库:" prop="reissue">
                <el-select v-model="modalform.stockObjIn1" placeholder="请选择仓库" size="medium" class="w150 mr20" value-key="id" @change="selectChange($event,1,'pro')" @visible-change="handleChangeFlag($event,2)">
                  <el-option v-for="item in stockObjList" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable===1?true:false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货区:" prop="reissue">
                <el-select v-model="modalform.stockObjArea1" placeholder="请选择货区" size="medium" class="w150 mr20" value-key="id" @change="selectChange($event,2,'pro')" @visible-change="handleChangeFlag($event,2)">
                  <el-option v-for="item in goodsArealist" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable===1?true:false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货位:" prop="reissue">
                <el-select v-model="modalform.stockObjLocationId1" placeholder="请选择货位" size="medium" class="w150">
                  <el-option v-for="item in placelist" :key="item.id" :label="item.name" :value="item.id" :disabled="item.isEnable===1?true:false">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6;font-size:12px">{{ item.isFree===1?'已占用':'' }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="入库对象:" prop="stockObjInOut" class="w100">
            <el-select v-model="modalform.stockObjInOut" placeholder="请选择入库对象" size="medium" @input="forceUpdate">
              <el-option label="车商" :value="1"></el-option>
              <el-option label="办证公司" :value="2"></el-option>
              <el-option label="门店" :value="3"></el-option>
              <el-option label="客户" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入库类型:" prop="stockInType">
            <el-select v-model="modalform.stockInType" placeholder="请选择入库类型" size="medium" @input="forceUpdate">
              <el-option label="材料补送入库" value="22"></el-option>
              <el-option label="证件归还入库" value="26"></el-option>
              <el-option label="调拨入库" value="23"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleEnter=false" plain>取消</el-button>
          <el-button type="primary" @click="submitIn">确认入库</el-button>
        </span>
      </el-dialog>
      <!--出库信息-->
      <el-dialog
        title="出库信息"
        :visible.sync="dialogVisibleOut"
        :show-close="false"
        class="customDialog"
        width="700px"
      >
        <el-form
          ref="outForm"
          :model="outForm"
          label-width="100px"
          :rules="outFormRules"
          :hide-required-asterisk="true"
        >
          <el-form-item label="出库对象:">
            <div class="flex">
              <el-form-item prop="stockObjInOut">
                <el-select v-model="outForm.stockObjInOut" placeholder="请选择出库对象" size="medium" class="mr30">
                  <el-option label="车商" :value="1"></el-option>
                  <el-option label="办证公司" :value="2"></el-option>
                  <el-option label="门店" :value="3"></el-option>
                  <el-option label="客户" :value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="reissue" v-if="outForm.stockObjInOut=='3'">
                <el-select v-model="outForm.materialsOutShopId" placeholder="请选择出库门店" size="medium">
                  <el-option v-for="item in departmentlist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <p v-if="outForm.stockObjInOut=='1'||outForm.stockObjInOut=='2'">商户姓名：{{res.merchantName}}</p>
              <p v-if="outForm.stockObjInOut=='4'">客户姓名：{{res.customerName}}</p>
            </div>
          </el-form-item>
          <el-form-item label="出库类型:" prop="stockOutType">
            <el-select v-model="outForm.stockOutType" placeholder="请选择出库类型" size="medium">
              <el-option label="办证出库" value="35"></el-option>
              <el-option label="证件归还出库" value="32"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleOut=false" plain>取消</el-button>
          <el-button type="primary" @click="submitOut" v-if="[1,4].includes(outForm.stockObjInOut)">出库</el-button>
          <el-button type="primary" @click="submitOut('send')" v-else>出库并发货</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //0重拍价格确认，1人工审核，2人工提醒
      carMaterialsList: [],
      carlist:[],
      tabClassify: "first",
      form: {
        type: "垫资成交",
        outObj: "",
        outType:"",
      },
      inOutForm: {
        stockObjInId: '',
        stockInType: '',
        stockObjOutId: '',
        stockOutType: '',
        handlerType: ''
      },
      modalform:{
        stockObjIn: '',
        stockObjArea: '',
        stockObjLocationId: '',
        stockObjIn1: '',
        stockObjArea1: '',
        stockObjLocationId1: '',
      },
      modalformRules:{},
      dialogVisibleCar:false,//车辆明细
      dialogVisible: false,
      dialogVisibleImg: false,
      showAddProject: false,
      dialogVisibleEnter: false, //入库
      dialogVisibleOut: false, //出库
      dialogImageUrl: "",
      checkList: [],
      fileList: [], //附件缩略图列表
      rules: {
        name: [{ required: true, message: "项目名称不能为空" }],
        reissue: [{ required: true, message: "补办方式不能为空" }],
        outObj: [{ required: true, message: "出库对象不能为空" }],
        outType: [{ required: true, message: "出库类型不能为空" }],
        remarks: [{ required: true, message: "备注不能为空" }]
      },
      outForm:{},
      outFormRules:{
        stockObjInOut: [{ required: true, message: "出库对象不能为空", }],
        stockOutType: [{ required: true, message: "出库类型不能为空", }],
      },
      stockId:'',
      contractlist:[{name:'客户合同',contractDate:''},{name:'商户合同',contractDate:''}],//合同类型
      modallist:[],
      multipleSelection:[],
      multipleSelection2:[],
      itemRows:{},
      stockObjList:[],//仓库
      goodsArealist:[],//货区
      placelist:[],//货位
      changeFlag:false,//是否切换了下拉(三级联动处理)
      changeFlag1:false,
      modalType:'car',//默认展示车辆
      res:{},
      projectList: [],
    };
  },
  watch:{
    'modalform.stockObjIn'(){
      if( this.changeFlag ){
        this.modalform.stockObjArea=null;
      }
    },
    'modalform.stockObjArea'(){
      if( this.changeFlag ){
        this.modalform.stockObjLocationId=null;
      }
    },
    'modalform.stockObjIn1'(){
      if( this.changeFlag1 ){
        this.modalform.stockObjArea1=null;
      }
    },
    'modalform.stockObjArea1'(){
      if( this.changeFlag1 ){
        this.modalform.stockObjLocationId1=null;
      }
    },
  },
  created() {
    let state = this.$route.query.state;
    this.stockId=this.$route.query.id;
    switch (state) {
      case "first":
        this.stateType = 0;
        break;
      case "second":
        this.stateType = 1;
        break;
      case "third":
        this.stateType = 2;
        break;
      case "fourth":
        this.stateType = 3;
        break;
      case "five":
        this.stateType = 4;
        break;
    }
    this.getStockDetail();
    this.getStockObjList()//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
    this.getShopList();//获取门店
  },
  methods: {
    getStockDetail(){//获取详情
      this.carlist=[];
      let query={
        businessId:this.stockId,
        businessType:'materials',
        // businessName:''
      }
      this.$api.getStockDetail(this.stockId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=res.data;
            this.form=res.data.stockCarInfo?res.data.stockCarInfo:{};

            this.contractlist[0].contractDate=this.res.customerContractSignTime;
            this.contractlist[0].tradedCarCertDate=this.res.customerDeadline;
            this.contractlist[1].contractDate=this.res.merchantContractSignTime;
            this.contractlist[1].tradedCarCertDate=this.res.merchantDeadline;
            
            //车辆库存
            let obj={
              materialName:'车辆',
              stockStatusName:this.form.stockStatusName,
              stockObjName:this.form.stockObjName,
              stockObjAreaName:this.form.stockObjAreaName,
              stockObjLocationName:this.form.stockObjLocationName,
            }
            this.carlist.push(obj)
            //车辆材料手续列表
            this.carMaterialsList=this.res.stockMaterialsInfos;
            //附件缩略图列表
            this.fileList=this.form.screenshotList;
          }
        }
      })
    },
    handleChangeFlag($event,state){
      switch(state){
        case 1:
          this.changeFlag = $event;
          break;
        case 2:
          this.changeFlag1 = $event;
          break;
      }
    },
    selectChange($event,state,type){
      switch(state){
        case 1:
          this.goodsArealist=$event.stockObjAreaList;
          if(type=='car'){
            this.modalform.stockObjInId=$event.id;
          }else{
            this.modalform.stockObjInId1=$event.id;
          }
          break;
        case 2:
          this.placelist=$event.stockObjLocationList;
          if(type=='car'){
            this.modalform.stockObjAreaId=$event.id;
          }else{
            this.modalform.stockObjAreaId1=$event.id;
          }
          break;
      }
    },
    handleIn() {
      if(this.multipleSelection.length>0&&this.multipleSelection2.length>0){
        this.modalType='auto'
      }else if(this.multipleSelection.length>0){
        this.modalType='car'
      }else if(this.multipleSelection2.length>0){
        this.modalType='pro'
      }else{
        this.$message.warning('请选择入库材料！')
        return
      }
      this.dialogVisibleEnter = true;
      // this.modalform={}
      this.modalform={
        stockObjIn: '',
        stockObjArea: '',
        stockObjLocationId: '',
        stockObjIn1: '',
        stockObjArea1: '',
        stockObjLocationId1: '',
      }
      this.$nextTick(()=>{
        if (this.$refs['modalform']) {
          this.$refs['modalform'].resetFields();
        }
      })
    },
    handleOut() {
      if(this.multipleSelection2.length > 0){
         this.$nextTick(()=>{
           if (this.$refs['outForm']) {
              this.$refs['outForm'].resetFields();
            }
         })
        // this.validateMaterials(this.multipleSelection2).then(res => {
        //   if(res) {
            this.dialogVisibleOut = true;
            this.inOutForm.handlerType = 'stock_out'
          // }else{
          //   this.$message.warning('请上传材料附件')
          // }
        // })
      }else if(this.multipleSelection.length>0){
        this.dialogVisibleOut = true;
        this.inOutForm.handlerType = 'stock_out'
      }else{
        this.$message.warning('请选择出库材料！')
      }
    },
    forceUpdate(){
      this.$forceUpdate()
    },
    submitIn() {
      let carSelected=1;//是否选择车辆：0已选择，1未选择，默认1
      if(this.multipleSelection.length>0){
        carSelected=0;
      }
      let params = 
      {
        "stockId": this.stockId,
        "handlerType": "stock_in",//处理类型：出库stock_out，出库并发货stock_out_send，入库stock_in ；carId和materialsIds不能全空
        "carId": this.res.carId,
        carSelected:carSelected,//是否选择车辆：0已选择，1未选择，默认1
        "materialsIds": this.multipleSelection2.map(item => item.materialsId?item.materialsId:item.id),
        "stockInType": this.modalform.stockInType,//车辆入库类型：21采购入库, 22材料补送入库, 23调拨入库, 24退车入库，25其他，26证件归还入库
        // "stockOutType": 31,//车辆出库类型：31调拨出库, 32证件归还出库, 33证件借用出库, 34其他
        "outObjId": this.modalform.stockObjInOut,//车辆入/出库对象：1车商，2办证公司，3门店，4客户，5交付中心，根据operaType判断入库/出库
        "carStockObjInId": this.modalform.stockObjInId,//车辆入库仓库ID
        // "carStockObjOutId": 1,//车辆出库仓库ID
        "carStockObjAreaId": this.modalform.stockObjAreaId,//车辆入库仓库货区ID
        "carStockObjLocationId": this.modalform.stockObjLocationId,//车辆入库仓库货位ID
        "materialsStockObjInId": this.modalform.stockObjInId1,//材料入库仓库ID
        // "materialsStockObjOutId": this.modalform.stockObjLocationId1,//材料出库仓库ID
        "materialsStockObjAreaId": this.modalform.stockObjAreaId1,//材料入库仓库货区ID
        "materialsStockObjLocationId": this.modalform.stockObjLocationId1//材料入库仓库货位ID
      }
      this.postStockHandling(params);
    },
    submitOut(type) {
      this.$refs['outForm'].validate(valid => {
        if (valid) {
          let handlerType='stock_out';
          if(type === 'send') {
            handlerType='stock_out_send';
          }
          let carSelected=1;///是否选择车辆：0已选择，1未选择，默认1
          if(this.multipleSelection.length>0){
            carSelected=0;
          }
          let materialsOutShopId=this.outForm.materialsOutShopId;//出库门店
          if(this.outForm.stockObjInOut!=3){
            materialsOutShopId='';
          }

          let params = {
            "stockId": this.stockId,
            "handlerType": handlerType,//处理类型：出库stock_out，出库并发货stock_out_send，入库stock_in ；carId和materialsIds不能全空
            "carId": this.res.carId,
            carSelected:carSelected,//是否选择车辆：0已选择，1未选择，默认1
            "materialsIds": this.multipleSelection2.map(item => item.materialsId?item.materialsId:item.id),
            "stockOutType": this.outForm.stockOutType,//车辆出库类型：31调拨出库, 32证件归还出库, 33证件借用出库, 34其他，35办证出库
            "outObjId": this.outForm.stockObjInOut,//车辆入/出库对象：1车商，2办证公司，3门店，4客户，5交付中心，根据operaType判断入库/出库
            // "carStockObjOutId": this.outForm.carStockObjOutId,//车辆出库仓库ID
            "outShopId": materialsOutShopId,//材料出库仓库ID
          }
          this.postStockHandling(this.$$.delete_null_properties(params));
        } else {
          return false;
        }
      });
      
    },
    postStockHandling(params) {
      this.$api.postStockHandling(params).then(res => {
        if(res.code == 0){
          this.$message.success(res.message);
          this.dialogVisibleEnter = this.dialogVisibleOut = false;
          if(params.handlerType==='stock_out_send'){//出库并发货stock_out_send
            this.bus.$emit("closeSelected",'router');
            this.$router.push({
              path: "/warehouseKeeper/shipmentDetails",
              query:{
                id:res.data
              }
            });
          }else{
            this.getStockDetail();
          }
          // this.getStockObjList()//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
        }
      })
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onoper(row,state) {
      this.itemRows=row;
      //查看明细
      if(state==1){
        this.dialogVisibleCar=true;
        this.getStockFlow()//车辆/材料出入库流水 查询
      }else{
        this.ondialogVisible();
        this.getStockFlow(row.materialsId?row.materialsId:row.id)//车辆/材料出入库流水 查询
      }
    },
    requestUpload(file,fileList,row) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId",row.materialsId);
      obj.append("businessName", row.materialName);
      obj.append("businessType", "materials");
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.getStockDetail(); //获取详情
          this.$message.success("上传成功");
        }
      });
    },
    //将fullFilePath换成url
    handleFileList(arr) {
      let newArr=[]
      if(arr && arr.length){
        arr.forEach(item => {
          if(item){
            item.url = item.fullFilePath;
            newArr.push(item)
          }
        })
      }else{
        newArr = [];
      }
      return newArr
    },
    validateMaterials(arr) {
      // 校验三项图片是否上传
      //机动车交通事故责任强制保险单(1张) 机动车行驶证(2张) 机动车登记证书(3张)
      return new Promise((resolve) => {
        let valid = true;
      
        arr.forEach(item => {
          if(item.materialName === '机动车交通事故责任强制保险单' && item.contractRequiredNums>0 && item.screenshotList && item.screenshotList.length < 1){
            valid = false
          }
          if(item.materialName === '机动车行驶证' && item.contractRequiredNums>0 && item.screenshotList && item.screenshotList.length < 1){
            valid = false
          }
          if(item.materialName === '机动车登记证书' && item.contractRequiredNums>0 && item.screenshotList && item.screenshotList.length < 1){
            valid = false
          }
        })
        resolve(valid)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    handleRemove(file,row) {
      let query={
        businessId:row.id,
        businessType:'materials',
        fileId:file.id,
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          this.getStockDetail(); //获取详情
          this.$message.success(res.message)
        }
      })
    },
    onsubmitCheck() {
      if(this.checkList.length==0){
        this.$message('请选择至少一个项目！');
      }else{
        this.showAddProject=!this.showAddProject
      }
    },
    onSubmitOut(formdata, type) {
      this.$refs[formdata].validate(valid => {
        if (valid) {
          if (type == "出库并发货") {
            this.$confirm("您确认为该车创建发货任务?", "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.dialogVisibleOut = false;
                //跳到物流发货单
                this.bus.$emit("closeSelected",'router');
                this.$router.push({
                  path: "/salesManager/deliverGoods/carDeliveryNote"
                });
              })
              .catch(() => {});
          } else {
            this.dialogVisibleOut = false;
            this.bus.$emit("closeSelected",'router');
            this.$router.push({
              path: "/warehouseKeeper/inventoryManagement"
            });
          }
        } else {
          return false;
        }
      });
    },
    submitProject() {
      if(!this.carMaterialsList){
        this.carMaterialsList = this.checkList
      }else{
        let ids = this.carMaterialsList.map(item => item.id);
        this.checkList.forEach(item => {
          if(!item.screenshotList) {//初始化附件图片列表,避免报错
            item.screenshotList = [];
          }
          if(ids.indexOf(item.id) === -1) {//去重
            this.carMaterialsList.push(item)
          }
        })
      }
      this.showAddProject = false;
      // this.$message.success('操作成功');
    },
    getStockFlow(materialsId){//车辆/材料出入库流水 查询
      let query={}
      if(!materialsId){
        query.carId=this.res.carId;
      }else{
        query.materialsId=materialsId;
      }
      this.$api.getStockFlow(this.stockId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.modallist=res.data;
          }
        }
      })
    },
    getStockObjList(){//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
      this.$api.getStockObjList({}).then((res)=>{
        if(res){
          if(res.code==0){
            this.stockObjList=res.data;
          }
        }
      })
    },
    getShopList(){//获取部门（门店）
      let query={
        isEnabled:0,
        shopTypeList:[0,3]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.departmentlist=res.data;
          }
        }
      })
    },
    showAddProjectDialog() {//点击添加项目,查询所有项目
      this.$api.getMaterialsList({carId:this.res.carId}).then(res => {
        if(res.code === 0){
          this.projectList = res.data;
          this.showAddProject = true;
        }
      })
    },
  }
};
</script>
<style>
.el-upload-list--picture-card .el-upload-list__item {
  width: 35px;
  height: 28px;
}
.el-upload-list__item-actions{
  line-height: 28px;
}
.el-upload--picture-card {
  width: 96px;
  height: 0;
  border: 0;
  line-height: 0;
  vertical-align: super;
}
.el-upload--picture-card .el-icon-plus {
  display: none;
}
.el-checkbox__label {
  font-size: 16px;
}
</style>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .w100 {
    width: 100%;
  }
  .w150 {
    width: 150px;
  }
  .fontbold {
    font-weight: bold;
  }
  .addProject{
    ::v-deep .el-checkbox-group{
      display: flex;
      flex-direction: column;
    }
  }
  .modalCheckBox{
    max-height:500px;
    overflow:auto;
  }
}
.tableUpload{
  ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width: 100px;
    height: 50px;
  }
  ::v-deep .el-upload--picture-card{
    height: 28px;
    line-height: 26px;
    border: none;
    background-color: transparent;
  }
  ::v-deep .el-upload-list__item-actions{
    line-height: 26px;
  }
  ::v-deep .el-upload--picture-card .el-icon-plus {
    display: none;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  .priceBox .w50 {
    width: 100%;
  }
}
</style>