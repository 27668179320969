<template>
	<div>
		<template v-for="(val,index) in chil">
			<el-submenu :index="val.path" :key="`${index}A_${val.path}`" v-if="val.children && val.children.length > 0">
				<template slot="title">
					<!-- <i :class="val.meta.icon"></i> -->
					<span>{{val.meta.title}}</span>
				</template>
				<sub-item :chil="val.children" />
			</el-submenu>
			<el-menu-item :index="val.path" :key="`${index}_${val.path}`" v-else>
				<template slot="title" v-if="val.meta.isLink">
					<a :href="val.meta.isLink" target="_blank" class="setFontSize14 menuLink">
						{{val.meta.title}}
					</a>
				</template>
				<template slot="title" v-else-if="val.meta.isNewVuePage">
					<span class="menuLink" @click="$$.goNewPage(val.path)">{{val.meta.title}}</span>
				</template>
				<template slot="title" v-else>
					<router-link :to="val.path" class="setFontSize14 menuLink">{{val.meta.title}}</router-link>
				</template>
			</el-menu-item>
		</template>
	</div>
</template>

<script>
export default {
	name: 'subItem',
	props: {
		chil: {
			type: Array,
			default() {
				return [];
			},
		},
	},
};
</script>

<style scoped>
.setFontSize14{color: inherit;font-size: 14px;}
.menuLink{display: block;margin: 0 -10px; padding: 0px 10px;}
</style>
