<template>
  <div class="pageContainer">
    <el-row :gutter="20" class="pageRowBox">
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>商户姓名</span>
        <el-input v-model="form.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>注册电话</span>
        <el-input v-model="form.regPhone" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>商户账户</span>
        <el-input v-model="form.merchantNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>注册城市</span>
        <el-cascader clearable
        v-model="form.regCity" placeholder="请选择注册城市" size="medium"
        :options="cityList" :props="props"></el-cascader>
        <!-- <el-select v-model="form.regCity" placeholder="请选择注册城市" size="medium">
          <el-option v-for="item in registerlist" :key="item.areaNo" :label="item.name" :value="item.code"></el-option>
        </el-select> -->
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>注册日期</span>
        <el-date-picker
          type="date"
          placeholder="选择日期"
          v-model="form.beginRegDate"
          class="w90"
          size="medium"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>账号状态</span>
        <el-select v-model="form.isExpires" placeholder="请选择账号状态" size="medium" clearable>
          <el-option label="正常" :value="0"></el-option>
          <el-option label="已过期" :value="1"></el-option>
          <el-option label="全部" value=""></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 rowW mb10">
        <span>维护业务员</span>
        <el-select v-model="form.maintainStaff" placeholder="请选择业务员" size="medium" clearable>
          <el-option v-for="(item,index) in employlist" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="3" class="flex flex_align_center flex_between w4 mb10">
        <el-button type="primary" class="button_primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt15 table-mainbg">
      <el-table-column prop="id" label="商户ID" align="center"></el-table-column>
      <el-table-column prop="merchantNo" label="商户账号" align="center"></el-table-column>
      <el-table-column prop="merchantMobile" label="注册电话" align="center"></el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center"></el-table-column>
      <el-table-column prop="registerDate" label="注册日期" align="center"></el-table-column>
      <el-table-column prop="daysRemaining" label="剩余体验天数" align="center"></el-table-column>
      <el-table-column prop="maintainerName" label="维护业务员" align="center"></el-table-column>
      <el-table-column prop="isExpires" label="账号状态" align="center">
        <template slot-scope="scope">{{scope.row.isExpires===0?'正常':scope.row.isExpires===1?'已过期':scope.row.isExpires}}</template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" v-if="visButton">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onreplace(scope.row,0)"
            size="mini"
            class="group-custom"
          >增加体验期</el-button>
          <el-button
            type="primary"
            @click="onreplace(scope.row,1)"
            size="mini"
            class="group-custom"
          >更换</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
    <el-dialog
      width="500px"
      :show-close="false"
      :class="dialogAuction==0?'':'customDialog'"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
    >
      <div v-show="dialogAuction==0">
        您确认增加（
        <span class="tipsColor">{{tipsName}}</span>）天体验期吗？
      </div>
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        :rules="rules"
        :hide-required-asterisk="true"
        v-show="dialogAuction==1"
      >
        <el-form-item label="维护经理" prop="manager">
          <el-select v-model="form.manager" placeholder="请选择维护经理" size="medium">
            <el-option v-for="item in employlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="onconfirm('form')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        manager: [{ required: true, message: "维护经理不能为空" }]
      },
      querylist: [],
      dialogVisible: false,
      dialogTitle: "",
      total: 0,
      currentPage: 1,
      pageSize:10,
      dialogAuction: 0, //0增加体验期，1更换维护经理
      tipsName: 7,
      cityList:[],//注册城市
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      employlist:[],//维护业务员
      visButton:true,//是否显示按钮
    };
  },
  created() {
    //经营省份城市区域3级联动查询(无参数查所有)省市
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.cityList=res;
      })
    }else{
      this.cityList=this.$store.state.setting.cityList;
    }
    let userData=this.$store.state.user.userData;
    let roleName=this.$$.roleName(userData)
    if(["销售专员"].includes(roleName.name)){
      this.visButton=false;
    }
    this.queryUserListByRoleAndDepart()//维护业务员列表查询
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        "curPage": this.currentPage,
        "merchantNo": this.form.merchantNo,//商户账号
        "merchantName": this.form.merchantName,//商户姓名
        "pageSize": this.pageSize,
        "isExpires":this.form.isExpires,//账号是否正常，是否过期
        "merchantMobile": this.form.regPhone,//注册电话
        "registerCityId": this.form.regCity?this.form.regCity[1]:'',//注册城市ID
        "registerStartDate": this.form.beginRegDate?`${this.form.beginRegDate} 00:00:00`:'',//开始注册日期
        "registerEndDate": this.form.beginRegDate?`${this.form.beginRegDate} 23:59:59`:'',//注册结束日期
        "maintainerId": this.form.maintainStaff,//维护业务员
      }
      this.$api.getExperienceList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
            this.querylist=[];
            this.total=0;
          }
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    ondialogVisible: function() {
      this.dialogVisible = !this.dialogVisible;
    },
    onreplace(row, state) {
      this.itemrow=row; 
      if (state == 0) {
        //0,增加体验期，1更换
        this.dialogTitle = "增加体验期";
        this.dialogAuction = 0;
      } else {
        this.dialogTitle = "更换维护经理";
        this.dialogAuction = 1;
      }
      this.ondialogVisible();
    },
    onconfirm(formdata) {
      //确认modal/***/dialogAuction: 0, //0增加体验期，1更换维护经理
      if (this.dialogAuction == 0) {
        //体验商户增加有效期
        this.putExperienceDelay();
      } else {
        this.$refs[formdata].validate(valid => {
          if (valid) {
            //体验商户更换维护业务员
            this.putUpdateMaintainer();
          } else {
            return false;
          }
        });
      }
    },
    putExperienceDelay(){//体验商户增加有效期
      let query={
        days:this.tipsName
      }
      this.$api.putExperienceDelay(this.itemrow.id,query).then((res) => {
        if(res.code==0){
          this.ondialogVisible()
          this.init()
        }
      })
    },
    putUpdateMaintainer(){//体验商户更换维护业务员
      let query={
        userId:this.form.manager
      }
      this.$api.putUpdateMaintainer(this.itemrow.id,query).then((res) => {
        if(res.code==0){
          this.ondialogVisible()
          this.init()
        }
      })
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//维护业务员
      let query={
        "roleName": "销售专员"
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
  }
};
</script>
<style>
#app .el-button + .el-button {
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.pageContainer {
  .inputW {
    width: 180px;
  }
}
.pageRowBox{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1600px) {
  .rowW{
    width:300px;
  }
}
@media (max-width: 1400px) {
  .rowW{
    width:350px;
  }
}
</style>
