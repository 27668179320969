<template>
  <div class="pageCombox" ref="pageCombox">
    <accreditationCommissioner v-if="roleName.indexOf('办证专员')!=-1" ></accreditationCommissioner>
    <channel v-else-if="roleName.indexOf('渠道')!=-1" ></channel>
    <merchant v-else-if="roleName.indexOf('拓展经理')!=-1||roleName.indexOf('销售专员')!=-1" ></merchant>
    <warehouseKeeper v-else-if="roleName.indexOf('库管员')!=-1" ></warehouseKeeper>
    <salesAssistant v-else-if="roleName.indexOf('销售助理')!=-1" ></salesAssistant>
    <inspectorSupervisor v-else-if="['检测主管','检测师主管'].indexOf(roleName)!=-1"></inspectorSupervisor>
    <storeManager v-else-if="roleName.indexOf('门店店长')!=-1"></storeManager>
    <clerk v-else-if="roleName.indexOf('文员')!=-1"></clerk>
    <salesManager v-else-if="roleName.indexOf('帮卖经理')!=-1"></salesManager>
    <testReviewer v-else-if="roleName.indexOf('检测审核')!=-1"></testReviewer>
    <!-- <interactManager v-else-if="roleName.indexOf('互动部经理')!=-1"></interactManager> -->
    <testService v-else-if="['检测坐席'].includes(roleName)"></testService>
    <onlineService v-else-if="roleName.indexOf('在线客服')!=-1" ></onlineService>
    <finance v-else-if="roleName.indexOf('财务')!=-1||roleName.indexOf('出纳')!=-1"></finance>
    <certificationCompany v-else-if="roleName.indexOf('办证公司')!=-1"></certificationCompany>
    <!-- <admin v-else-if="roleName.indexOf('管理员')!=-1"></admin> -->
    <div class="blankBox" v-else>
      <div class="mt30">暂无数据</div>
    </div>
  </div>
</template>

<script>
import accreditationCommissioner from '@/views/accreditationCommissioner';//办证专员
import channel from '@/views/channel';//渠道部
import merchant from '@/views/merchant';//商户部
import warehouseKeeper from '@/views/warehouseKeeper';//库管员
import salesAssistant from '@/views/salesAssistant';//销售助理
import inspectorSupervisor from '@/views/inspectorSupervisor';//检测主管
import storeManager from '@/views/storeManager';//门店店长
import clerk from '@/views/clerk';//门店-文员
import salesManager from '@/views/salesManager';//帮卖经理
import testReviewer from '@/views/testReviewer';//检测审核
import interactManager from '@/views/interactManager';//互动部经理
import testService from '@/views/testService';//检测坐席
import onlineService from '@/views/onlineService';//在线客服
import finance from '@/views/finance';//财务部
import certificationCompany from '@/views/certificationCompany';//办证公司
// import admin from '@/views/admin/index.vue';//管理员
export default {
  name: "workbench",
  components: {
    accreditationCommissioner,//办证专员
    channel,//渠道部
    merchant,//商户部
    warehouseKeeper,//库管员
    salesAssistant,//销售助理
    inspectorSupervisor,//检测师主管
    storeManager,//门店店长
    clerk,//文员
    salesManager,//帮卖经理
    testReviewer,//检测审核
    interactManager,//互动部经理
    testService,//检测坐席
    onlineService,//在线客服
    finance,//财务部
    certificationCompany,//办证公司
    // admin,//管理员
  },
  data() {
    return {
      userId:'',
      roleName:'',
      pageHeight:''
    };
  },
  created(){
    this.userId=this.$store.state.user.userId;
    this.getCurrentRoleByUserId()//根据用户id查询当前角色
  },
  methods:{
    getCurrentRoleByUserId(){//根据用户id查询当前角色
      this.$api.getCurrentRoleByUserId(this.userId).then(res => {
        if(res){
          if(res.code==0){
            this.roleName=res.data.name;
          }
        }
      })
    }
  }
};
</script>

<style lang="scss">
.blankBox{
  padding:30px;
  text-align:center;
}
</style>
