<template>
  <div class="pageContainer">
    <!--基本信息-->
    <div class="content_modal_textBox">
      <el-form ref="form" :model="form" label-width="130px" :hide-required-asterisk="true" :rules="rules" size="medium" >
        <el-form-item label="合作店简称:" prop="partnerShortName">
           <el-select
          v-model="form.partnerShortName" size="medium" class="w300"
          placeholder="请选择" filterable
          @change="changeChannl"
          value-key="id"
        >
          <el-option
            v-for="item in channllist"
            :key="item.id"
            :label="item.shortName"
            :value="item"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="账户信息:" prop="account">
          <el-select  v-model="form.account" size="medium" class="selectW" placeholder="请选择账户信息">
            <el-option v-for="item in accountList" :key="item.id" :label="`${item.accountTypeName}-账户名：${item.accountName}，账号：${item.account}`" :value="item.id" :disabled="item.accountType===3&&item.enableStatus===0?true:false" v-if="item.accountName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提现金额:" prop="amount">
          <el-input v-model="form.amount" size="medium" class="w300" type="number"></el-input>
        </el-form-item>
        <el-form-item label="可提现金额:" >
          {{res.wallet?res.wallet.availableAmount:'-'}}
        </el-form-item>
        <el-form-item label="提现备注:" prop="remark">
          <el-input type="textarea" v-model="form.remark" size="medium" class="w300"></el-input>
        </el-form-item>
        <el-form-item label="银行回执单备注:">
          <el-input type="textarea" v-model="form.bankRemark" size="medium" class="w300" maxlength="10"></el-input>
        </el-form-item>
        <div class="mt30 withfootBox">
          <el-button type="primary" size="medium" @click="onSubmit('form')">保存</el-button>
          <el-button type="primary" size="medium" plain @click="onroute">返回</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        amount: "",
        account:'',
        partnerShortName:'',
        remark:'',
        bankRemark:''
      },
      rules: {
        amount:[{ required: true, message: "提现金额不能为空" }],
        partnerShortName:[{ required: true, message: "合作店简称不能为空" }],
        account: [{ required: true, message: "账户信息不能为空" }],
        remark:[{ required: true, message: "备注不能为空" }]
      },
      channllist:[],//合作门店
      accountList:[],//账户列表
      res:{
        wallet:{}
      },//账户信息
    };
  },
  created() {
    this.user=this.$store.state.user.userData;
    this.postChannelList()//查询所有渠道
  },
  methods: {
    changeChannl(row){
      this.form.channelId=row.id;
      this.form.account='';
      this.res=row;
      this.$api.getByChannelIdDetail(row.id).then(res=>{
        if(res.code==0){
          this.res=res.data
          this.accountList=res.data.accountList;
        }
      })
    },
    onSubmit(formdata){
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.postChannelWalletWithdraw()//提现
        } else {
          return false;
        }
      });
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/channel/walletManagement"
      });
    },
    postChannelWalletWithdraw(){//提现
      let query={
        channelId:this.form.channelId,//渠道ID
        channelAccountId:this.form.account,//该渠道的银行账户，提现必填，其他不必填
        amount:this.form.amount,//充值或冲账或提现金额
        remark:this.form.remark,//备注信息
        bankRemark:this.form.bankRemark,
      }
      this.$api.postChannelWalletWithdraw(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.$refs['form'].resetFields();
            this.form.bankRemark='';
            this.res.wallet.availableAmount='';
            // this.onroute();
            this.$message.success(res.message)
          }
        }
      })
    },
    postChannelList(){//查询所有渠道
      let query={
        status:3
      }
      let roleName=this.$$.roleName(this.user)
      if(roleName.name==="拓展专员"){
        query.extensionerId=this.user.id;
      }
      this.$api.postChannelList(query).then(res=>{
        if(res.code==0){
          this.channllist=res.data
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .el-form-item{
    margin-bottom: 20px;
  }
  .w200{
    width: 200px;
  }
  .withfootBox{
    width: 300px;
    display: flex;
    justify-content: center;
  }
  .selectW{
    width:700px
  }
}
</style>