<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户姓名</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>注册手机号</span>
        <el-input v-model="forms.regPhone" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>注册城市</span>
        <el-cascader clearable
        v-model="forms.regCity" placeholder="请选择注册城市" size="medium"
        :options="cityList" :props="props"></el-cascader>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10">
        <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt10">
      <el-table-column prop="id" label="商户ID" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center">
        <template slot-scope="scope">{{scope.row.merchantType==1?scope.row.authCompanyName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="merchantNo" label="商户账户" align="center"></el-table-column>
      <el-table-column prop="merchantMobile" label="注册手机号" align="center"></el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center"></el-table-column>
      <el-table-column prop="merchantType" label="商户类别" align="center">
        <template slot-scope="scope">{{scope.row.merchantType?'企业':'个人'}}</template>
      </el-table-column>
      <el-table-column prop="authRejectDate" label="驳回时间" align="center"></el-table-column>
      <el-table-column prop="authRejectReason" label="驳回原因" align="center"></el-table-column>
      <el-table-column prop="maintainerName" label="商户经理" align="center"></el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onsee(scope.row)"
            size="mini"
            class="group-custom"
          >查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms:{},
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      cityList:[],//注册城市
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
    };
  },
  created() {
    this.init();
    //经营省份城市区域3级联动查询(无参数查所有)省市
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.cityList=res;
      })
    }else{
      this.cityList=this.$store.state.setting.cityList;
    }
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
        merchantName:this.forms.merchantName,//商户姓名
        merchantMobile:this.forms.regPhone,//注册电话
        registerCityId:this.forms.regCity?this.forms.regCity[1]:'',//注册城市编号
      }
      this.$api.postAuthFailList(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
              this.querylist=[];
              this.total=0;
          }
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    onsee(row) {
      this.$router.push({//接收状态值，0个人(可操作)，1企业(可操作),2个人查看，3企业查看
        path:'/merchant/authenticationDetails',
        query:{//merchantType商户类型 0-个人 1-企业
          // state:row.merchantType?3:2,
          id:row.id
        }
      })
    },
    handleSizeChange(data) {
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage=data;
      this.init()
    },
  }
};
</script>
<style lang="scss" scoped>
.pageRowBox{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1600px) {
  .rowW{
    width:300px;
  }
}
@media (max-width: 1400px) {
  .rowW{
    width:350px;
  }
}
</style>
