<template>
  <!-- 用户数据分组 -->
  <div class="userGrouping">
    <div class="flex flex_align_center flex_between mb20">
      <div class="ckxTitle">用户数据分组</div>
      <el-button type="primary" plain @click="addGroup">新增数据组</el-button>
    </div>
    <el-table :data="groupList" style="width: 100%;" row-key="uniqueId" border :tree-props="{children: 'users', hasChildren: 'hasChildren'}">
      <el-table-column prop="name" label="数据组名称"> </el-table-column>
      <el-table-column prop="createDate" label="创建日期"> </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="addUser(scope.row.uniqueId,scope.row)" size="mini" plain v-if="scope.row.users!==undefined">新增用户</el-button>
          <el-button type="warning" @click="editGroup(scope.row.id,scope.row.name)" size="mini" plain v-if="scope.row.users!==undefined">编辑</el-button>
          <el-button type="danger" @click="deleteGroup(scope.row.id)" size="mini" plain v-if="scope.row.users!==undefined && scope.row.users===null">删除</el-button>
          <el-button type="danger" @click="delUser(scope.row.uniqueId)" size="mini" plain v-if="scope.row.users===undefined">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑数据组" :visible.sync="editGroupVisible">
      <div class="editGroupCon">
        <div class="treeWrap">
          <el-tree :data="userList" show-checkbox node-key="id" ref="tree" :default-expand-all="true" :props="{children: 'userList',label: 'name'}" @check="changeTree"></el-tree>
        </div>
        <div class="editWrap">
          <div class="mb20 title">{{curGroup.name}}</div>
          <div class="tagList">
            <el-tag :key="tag.id" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">{{tag.name}}</el-tag>
          </div>
          <div class="footerBtn">
            <el-button type="primary" @click="submitEdit">确 定</el-button>
            <el-button type="primary" @click="cancelEdit">取 消</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
    title="提示"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15">{{`请输入${carState==1?'新增':'编辑'}数据组的名字`}}：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      carState:1,//显示内容
      groupList: [],
      userList: [],
      curGroup: {},
      editGroupVisible: false,
      dynamicTags: [],
      itemRow:[],
      itemId:'',
    }
  },
  created() {
    this.checkSeatQueryCheckSeatGroupList();//数据组
  },
  mounted() {
    this.queryUserListByRoleAndDepart();
  },
  methods: {
    queryUserListByRoleAndDepart(){//根据角色和部门查询用户
      this.$api.queryUserListByRoleAndDepart({roleName:'检测坐席'}).then( res=>{
        if(res.code == 0){
          this.userList = res.data;
        }
      })
    },
    checkSeatQueryCheckSeatGroupList() {
      this.$api.checkSeatQueryCheckSeatGroupList().then(res => {
        if(res.code == 0){
          let groupList = [];
          if(res.data.length){
            for(let i=0;i<res.data.length;i++){
              let item=res.data[i]
              if(!item.groupId){
                item.uniqueId = String(item.id)
              }
              if(item.users){
                for(let j=0;j<item.users.length;j++){
                  if(item.users[j]){
                    item.users[j].uniqueId=item.id + 'and' +item.users[j].id;
                  }else{
                    item.users=[];
                  }
                }
              }else{
                item.users=[];
              }
              groupList.push(item)
            }
          }
          this.groupList = groupList;
        }
      })
    },
    editGroup(id,name) {
      this.itemId=id;
      this.dialogVisibleCar=true;
      this.formCar='';
      this.carState=2;//编辑
    },
    addUser(uniqueId,row) {
      this.itemRow=JSON.parse(JSON.stringify(row));
      this.curGroup = this.groupList.find(item => item.uniqueId === uniqueId);
      this.editGroupVisible = true;
      let arr=row.users?row.users.map(item=> {return item.id}):[]
      this.$nextTick(()=>{
        this.$refs.tree.setCheckedKeys(arr);
        this.dynamicTags=JSON.parse(JSON.stringify(row.users))
      })
    },
    delUser(uniqueId) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = uniqueId.split('and')
        this.$api.checkSeatDeleteGroupUser({
          checkSeatId: arr[1],
          groupId: arr[0]
        }).then(res => {
          if(res.code == 0){
            this.$message.success(res.message);
            this.checkSeatQueryCheckSeatGroupList();
          }
        })
      }).catch(() => {});
    },
    onSubmitCar(){//修改车牌号
      let _this=this;
      if(!this.formCar){
        this.$message.warning('请输入数据组名字！')
        return
      }
      if(this.carState===1){
        _this.$api.checkSeatSaveOrUpdateCheckSeatGroup({
          name: this.formCar
        }).then(res => {
          if(res.code == 0){
            this.dialogVisibleCar=false;
            _this.$message.success(res.message);
            _this.checkSeatQueryCheckSeatGroupList();
          }
        })
      }else if(this.carState===2){
         _this.$api.checkSeatSaveOrUpdateCheckSeatGroup({
          name: this.formCar,
          id: this.itemId
        }).then(res => {
          if(res.code == 0){
            this.dialogVisibleCar=false;
            _this.$message.success(res.message)
            _this.checkSeatQueryCheckSeatGroupList();
          }
        })
      }
    },
    addGroup() {
      this.dialogVisibleCar=true;
      this.formCar='';
      this.carState=1;//新增数据组
    },
    deleteGroup(id) {//删除组
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.checkSeatDeleteGroup({
          groupId: id
        }).then(res => {
          if(res.code == 0){
            this.$message.success(res.message);
            this.checkSeatQueryCheckSeatGroupList();
          }
        })
      }).catch(() => {});
    },
    handleClose(tag) {
      let currentNode = this.$refs.tree.getCheckedNodes()
      let delArr = [];
      currentNode.forEach(item => {
        if(item.id==tag.id){
          delArr.push(item.id)
        }
      })
      delArr=Array.from(new Set(delArr))
      let nodes = currentNode.filter(item => {
        return delArr.indexOf(item.id) === -1;
      })
      this.$refs.tree.setCheckedKeys([]);
      this.$refs.tree.setCheckedNodes(nodes);
      this.changeTree();
    },
    changeTree() {
      let currentNode = this.$refs.tree.getCheckedNodes();
      let obj = {};
      //只保留员工级别并且去重
      this.dynamicTags = currentNode.reduce((result, item) => {
        if(!item.userList && !obj[item.id]){
          obj[item.id] = true;
          result.push(item);
        }
        return result
      }, [])
    },
    cancelEdit() {
      this.$refs.tree.setCheckedKeys([]);
      this.editGroupVisible = false;
    },
    submitEdit() {
      if(this.dynamicTags.length==0){
        this.$message('请选择人员！')
        return
      }
      let arr=this.dynamicTags.map(item => item.id)
      let old=this.itemRow.users?this.itemRow.users.map(item => item.id):[]
      let newArr =  [...arr].filter(x => [...old].every(y => y !== x));
      this.$api.checkSeatAddGroupUsers({
        groupId: this.curGroup.id,
        userIds: newArr
      }).then(res => {
        if(res.code == 0){
          this.$message.success(res.message);
          this.$refs.tree.setCheckedKeys([]);
          this.editGroupVisible = false;
          this.checkSeatQueryCheckSeatGroupList();
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.userGrouping{
  padding: 20px 40px;
  .center{
    text-align:center;
  }
  .editGroupCon{
    border: 1px solid #ccc;
    display: flex;
    max-height: 500px;
    overflow-y: auto;
    margin-top: -30px;
    .treeWrap{
      width: 40%;
      border-right: 1px solid #ccc;
      padding: 10px;
    }
    .editWrap{
      flex: 1;
      padding: 10px;
      .title{
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }
      .footerBtn{
        text-align: center;
        margin-top: 30px;
      }
      .tagList{
        min-height: 84px;
      }
    }
  }
  ::v-deep .el-tag {margin-bottom: 10px;}
  ::v-deep .el-tag + .el-tag {
    margin-left: 10px;
  }
}
</style>
