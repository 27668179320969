<template>
  <div class="priceBoxBG">
    <div class="priceBox">
      <!--基本信息-->
      <el-row>
        <el-col :xl="12" CP:lg="24">
          <div class="head_top_title flex flex_between">
            <div class="ckxTitle">基本信息</div>
            <div class="seeColor" @click="onRouterCar">车辆报告 ></div>
          </div>
          <table class="table-oddwholebg table-custom">
            <tbody>
              <tr>
                <td>询价类型</td>
                <td>{{res.enquiryTypeName}}</td>
                <td>年份车型</td>
                <td>{{res.productYear}}/{{res.seriesName}}</td>
              </tr>
              <tr>
                <td>审核类型</td>
                <td>{{res.auctionPriceAuditTypeName}}</td>
                <td>车辆评级</td>
                <td>{{res.carGradeResult}}</td>
              </tr>
              <tr>
                <td>使用性质</td>
                <td>{{res.publicOrPrivateName}}-{{res.isBusiness}}</td>
                <td>最高价</td>
                <td>{{res.firstAuctionPrice}}</td>
              </tr>
              <tr>
                <td>{{stateType==501?'价格有效期':'成交类型'}}</td>
                <td>{{stateType==501?res.tradedPriceExpire:res.tradeTypeName}}</td>
                <td>次高价</td>
                <td>{{res.secondAuctionPrice}}</td>
              </tr>
              <tr>
                <td>场次时间</td>
                <td>{{res.auctionEndTime}}</td>
                <td>成交价</td>
                <td>{{res.tradePrice}}</td>
              </tr>
            </tbody>
          </table>
        </el-col>
        <el-col :xl="12" :lg="24">
          <div class="contentRowBox">
            <div class="phoneBox">
              <div>
                <span>商户电话1：{{merchantMobile}}</span>
                <el-button type="primary" @click="oncalll(merchantMobile)" size="small" plain v-if="merchantMobile">呼叫</el-button>
              </div>
              <div class="phoneRow">
                <span>商户电话2：{{merchantOtherMobile}}</span>
                <el-button type="primary" @click="oncalll(merchantOtherMobile)" size="small" plain v-if="merchantOtherMobile">呼叫</el-button>
              </div>
            </div>
            <div class="tisFontBox" v-if="res.auctionPriceAuditType===1002">
              <p class="mb5 mt10" >人工审核提示：</p>
              <p>{{res.auctionPriceManualAuditReasonName}}</p>
            </div>
          </div>
        </el-col>
      </el-row>
     
      
      <!--成交类型-->
      <!-- <div class="content_modal_textBox mt30" v-if="stateType==501">
        <div class="head_top_title">
          <i></i>
          <span>成交类型</span>
        </div>
        <el-radio-group v-model="res.tradeType" size="medium" >
          <el-radio-button label="21">预约成交</el-radio-button>
          <el-radio-button label="22">垫资成交</el-radio-button>
        </el-radio-group>
      </div> -->
      <!--价格列表-->
      <div class="content_modal_textBox mt30">
        <div class="head_top_title">
          <i></i>
          <span>价格列表</span>
        </div>
        <el-table :data="validAuctionList" border>
          <el-table-column prop="merchantName" label="姓名" align="center">
            <!-- <template slot-scope="scope">{{scope.row.merchant.merchantName}}</template> -->
          </el-table-column>
          <el-table-column prop="merchantNo" label="账号" align="center">
            <template slot-scope="scope">{{scope.row.merchant.merchantNo}}</template>
          </el-table-column>
          <el-table-column prop="merchantMobile" label="电话" align="center">
            <template slot-scope="scope">{{scope.row.merchant.merchantMobile}}</template>
          </el-table-column>
          <el-table-column prop="registerCityName" label="注册地" align="center">
            <template slot-scope="scope">{{scope.row.merchant.registerCityName}}</template>
          </el-table-column>
          <el-table-column prop="businessCityName" label="经营地" align="center">
            <template slot-scope="scope">{{scope.row.merchant.businessCityName}}</template>
          </el-table-column>
          <el-table-column prop="auctionTime" label="报价时间" align="center"></el-table-column>
          <el-table-column prop="auctionPrice" label="报价" align="center"></el-table-column>
          <el-table-column prop="tradePrice" label="成交价" align="center"></el-table-column>
          <el-table-column prop="tradeServiceCharge" label="商户服务费" align="center"></el-table-column>
          <el-table-column prop="tradingMarketName" label="所属市场" align="center"></el-table-column>
          <el-table-column prop="isBreak" label="是否违约" align="center" v-if="stateType!=501">
            <template slot-scope="scope">{{scope.row.isBreak>0?'是':'否'}}</template>
          </el-table-column>
          <el-table-column prop="auctionPriceConfirmStatusName" label="商户确认" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="110" >
            <template slot-scope="scope">
              <el-button v-if="scope.$index==0&&scope.row.auctionPriceConfirmStatus==301"
                type="primary"
                @click="onoper(scope.row)"
                size="mini"
              >{{stateType=='501'?'放弃':'置为无效'}}</el-button>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
       <!--无效报价列表-->
      <div class="content_modal_textBox mt30" :class="stateType!=2?'pb100':''">
        <div class="head_top_title">
          <i></i>
          <span>无效报价列表</span>
        </div>
        <el-table :data="invalidAuctionList" border>
          <el-table-column prop="merchantName" label="姓名" align="center">
            <!-- <template slot-scope="scope">{{scope.row.merchant.merchantName}}</template> -->
          </el-table-column>
          <el-table-column prop="merchantNo" label="账号" align="center">
            <template slot-scope="scope">{{scope.row.merchant.merchantNo}}</template>
          </el-table-column>
          <el-table-column prop="merchantMobile" label="电话" align="center">
            <template slot-scope="scope">{{scope.row.merchant.merchantMobile}}</template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="积分" align="center"></el-table-column> -->
          <el-table-column prop="auctionTime" label="报价时间" align="center"></el-table-column>
          <el-table-column prop="auctionPrice" label="报价" align="center"></el-table-column>
          <el-table-column prop="breakAmount" label="违约费" align="center"></el-table-column>
          <el-table-column prop="auctionPriceConfirmStatusName" label="商户确认" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="footer" v-if="[501,502].includes(stateType)&&res.auctionPriceSendStatus==601&&noState">
      <!-- <div class="footer"> -->
        <div class="btn">
          <!-- <el-button type="primary" @click="doSubmit(1)" v-if="stateType==501">审核通过</el-button> -->

          <!-- <el-button type="primary" @click="doSubmit(3)" >发送价格</el-button> -->
          <el-button type="primary" @click="doSubmit(3)" v-if="[502,501].includes(stateType)&&noState">发送价格</el-button>

          <!-- <el-button type="primary" @click="doSubmit(2)" v-if="stateType==501">重拍</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0,//竞拍价格审核操作类型：501重拍价格确认，502人工审核，503人工提醒，504自动审核,默认504自动审核
      validAuctionList:[],//有效的竞拍
      invalidAuctionList: [], //无效的竞拍
      form: {
        type: '垫资成交'
      },
      res:{},//
      carAuctionId:'',//车id
      merchantMobile:'',
      merchantOtherMobile:'',
      merchantId:'',
      userData:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
      noState:false,//是否全部置为无效(默认展示按钮)
      timer:null
    };
  },
  // watch:{
  //   noState:function(e){
  //     if(e){
  //       if(!this.timer&&this.noState){
  //         this.timer=setInterval(()=>{
  //           this.getReAuctionDetailByCarId()
  //           this.getAuctionPriceValidList()//根据竞拍Id获取有效价格列表
  //           this.getAuctionPriceInvalidList()//根据竞拍Id获取无效价格列表
  //           // console.log(this.timer,'---8888---',new Date())
  //         },1000*30)
  //       }
  //     }
  //   }
  // },
  created() {
    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    if(this.$route.query.carAuctionId) this.carAuctionId = this.$route.query.carAuctionId
    this.getReAuctionDetailByCarId()
    this.getAuctionPriceValidList()//根据竞拍Id获取有效价格列表
    this.getAuctionPriceInvalidList()//根据竞拍Id获取无效价格列表

    
    // console.log(this.timer,'====444==',this.noState)
  },
  methods: {
    oncalll(row) {
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row)
      // })//根据员工工号查询坐席信息(拨打电话)
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    doSubmit(state) {
      switch(state){
        // case 1:
        //   this.postOperaStatus('check_success')// 审核通过
        //   break;
        case 2:
          this.postAuctionAuditPrice('reAuction')// 重拍
          break;
        case 3:
          this.postAuctionAuditPrice('sendPrice')// 发送价格
          break;
      }
    },
    onRouter(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/warehouseKeeper/priceConfirmation"
      });
    },
    onoper(row) {
      this.$confirm(`您确认要把“${row.merchant.merchantName}”的车辆置为无效报价吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postAuctionConfirmPrice('giveUp',row.merchant.id)
        }).catch(() => {});
    },
    onRouterCar(){//跳转路由(车辆报告)
      this.$router.push({
        path:'/testReviewer/auditReport',
        query:{
          state:'3',//2开头-车辆审核，1-车型审核，11车型查看
          id:this.res.checkReportId
        }
      })
    },
    getReAuctionDetailByCarId(){//查询详情
      this.$api.getAuctionInfo(this.carAuctionId).then(res=>{
        if(res.code==0){
          this.res=res.data;
          this.carAuctionId=res.data.id;
          this.stateType=res.data.auctionPriceAuditOperType;
        }
      })
    },
    getAuctionPriceValidList(){//根据竞拍Id获取有效价格列表
      this.$api.getAuctionPriceValidList(this.carAuctionId).then(res=>{
        if(res.code==0){
          this.validAuctionList=res.data;
          
          if(res.data.length>0){
            let arr=res.data[0]
            if(arr.auctionPriceConfirmStatus==301){
              this.merchantMobile=arr.merchant.merchantMobile;
              this.merchantOtherMobile=arr.merchant.merchantOtherMobile;
              this.merchantId=arr.merchant.id;
            }
          }
          for(let i=0;i<res.data.length;i++){
            let item=res.data[i]
            if(item.auctionPriceConfirmStatus==301){
              this.noState=true;
            }
          }
          if(res.data.length==0){
            this.noState=false;
          }
        }
      })
    },
    getAuctionPriceInvalidList(){//根据竞拍Id获取无效价格列表
      this.$api.getAuctionPriceInvalidList(this.carAuctionId).then(res=>{
        if(res.code==0){
          this.invalidAuctionList=res.data;
        }
      })
    },
    postAuctionConfirmPrice(operaType,merchantId){//根据 carId和merchantId 进行 竞拍价格确认 操作【确认价格，放弃价格，置为无效】//operaType：confirm 确认价格，giveUp 放弃价格，invalid 置为无效
      let query={
        "carAuctionId": this.carAuctionId,
        "merchantId": merchantId,
        // "responsibleParty": 51,
        "operType": operaType
      }
      this.$api.postAuctionConfirmPrice(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getReAuctionDetailByCarId()
          this.getAuctionPriceValidList()//根据竞拍Id获取有效价格列表
          this.getAuctionPriceInvalidList()//根据竞拍Id获取无效价格列表
        }
      })
    },
    postAuctionAuditPrice(operaType){//价格审核操作 发送价格/重拍
      if(!this.res.tradeType&&this.stateType==501){
        this.$message('请选择成交类型！')
        return
      }
      let query={
        "carAuctionId": this.carAuctionId,
        "operType": operaType,
        tradeType:this.res.tradeType
      }
      this.$api.postAuctionAuditPrice(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getReAuctionDetailByCarId()
          this.getAuctionPriceValidList()//根据竞拍Id获取有效价格列表
          this.getAuctionPriceInvalidList()//根据竞拍Id获取无效价格列表
        }
      })
    }
  },
  // beforeDestroy(){
  //   clearInterval(this.timer)
  //   this.timer=null;
  //   // console.log(this.timer,'----dddd---')
  // },
};
</script>
<style lang="scss" scoped>
.priceBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.priceBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .phoneRow {
    margin-left: 100px;
  }
  .phoneBox {
    display: flex;
    margin-top: 20px;
    span {
      margin-right: 15px;
    }
  }
  .contentRowBox{
    padding:0 30px;
  }
  .tisFontBox{
    color: #ff0000;
    margin-top:30px;
    p{
      font-size:20px;
    }
  }
}
@media only screen and (max-width:1500px)and (min-width:1200px) {
    .priceBox .w50{
        width: 100%;
    }
} 
</style>