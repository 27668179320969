<template>
  <!-- 查看发货单 -->
  <div class="getDeliverGoods">
    <div class="title">物流发货单</div>
    <div class="line">发货单号：{{noteInfo.logistNo}}</div>
    <div class="ckxTitle mb10 mt20">发货信息</div>
    <el-row>
      <el-col :span="6">发货方：{{noteInfo.tradeShopName}}</el-col>
      <el-col :span="6">收货方：{{noteInfo.toShopName}}</el-col>
      <el-col :span="6">发货方式：{{noteInfo.logistTypeName}}</el-col>
      <el-col :span="6">代驾姓名：{{noteInfo.agencyName}}</el-col>
    </el-row>
    <el-row class="mt10">
      <el-col :span="6">发货时间：{{noteInfo.fromTime}}</el-col>
      <el-col :span="6">代驾手机：{{noteInfo.agencyMobile}}</el-col>
      <el-col :span="6">预计到达时间：{{noteInfo.preArriveTime}}</el-col>
    </el-row>
    <div class="ckxTitle mt20">车辆信息</div>
    <el-table :data="onRoadOrOutStoreCar" border class="mt10 mb20 table-mainbg" stripe>
      <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center"></el-table-column>
      <el-table-column prop="carColor" label="颜色" align="center"></el-table-column>
      <el-table-column prop="registerDate" label="初次登记日期" align="center"></el-table-column>
      <el-table-column prop="checkExpire" label="年检有效期" align="center"></el-table-column>
    </el-table>
    <div class="ckxTitle mt20 mb10">手续材料信息</div>
    <el-table :data="carMaterialsList" border class="mt10 mb20 table-mainbg" stripe>
      <el-table-column type="index" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
      <el-table-column prop="contractRequiredNums" label="数量" align="center"></el-table-column>
    </el-table>
    <div class="ckxTitle mb10">留存照片</div>
    <div class="flex">
      <el-upload disabled class="avatar-uploader mr30" action :show-file-list="false" accept="image/png, image/gif, image/jpeg,image/jpg" :on-change="(file, fileList)=>requestUpload(file,fileList,1)" :auto-upload="false">
        <!-- <img v-if="imageUrl1" :src="imageUrl1" class="avatar"> -->
        <el-image v-if="imageUrl1" :src="imageUrl1" class="avatar" :preview-src-list="[imageUrl1]">
          <div slot="error">暂无图片</div>
        </el-image>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <span class="imgName">油表指针</span>
      </el-upload>
      <el-upload disabled class="avatar-uploader mr30" action :show-file-list="false" accept="image/png, image/gif, image/jpeg,image/jpg" :on-change="(file, fileList)=>requestUpload(file,fileList,2)" :auto-upload="false">
        <el-image v-if="imageUrl2" :src="imageUrl2" class="avatar" :preview-src-list="[imageUrl2]">
          <div slot="error">暂无图片</div>
        </el-image>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <span class="imgName">三元催化</span>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onRoadOrOutStoreCar: [],
      carMaterialsList: null,
      noteInfo: {
        tradeShopName: '',
        toShopName: '',
        logistTypeName: '',
        agencyName: '',
        fromTime: '',
        agencyMobile: '',
        preArriveTime: '',
      },
      imageUrl1: null,
      imageUrl2: null,
    }
  },
  created() {
    this.logisticsDetail();
  },
  methods: {
    logisticsDetail() {
      this.$api.logisticsDetail(this.$route.query.id,{businessId:this.$route.query.id,businessType: "logistics"}).then(res => {
        if(res.code === 0){
          let car=res.data.logistCarInfo;
          this.onRoadOrOutStoreCar = [{
            carNo:res.data.carNo,
            brandName:`${car.brandName}-${car.modelName}`,
            carColor:car.carColor,
            registerDate:car.registerDate,
            checkExpire:car.checkExpire
          }];
          this.carMaterialsList = res.data.logistMaterialsList;
          this.noteInfo = res.data;
          this.imageUrl1 = res.data.imgList.find(item => item.sysFileBusinessList[0].businessId===1).fullFilePath;
          this.imageUrl2 = res.data.imgList.find(item => item.sysFileBusinessList[0].businessId===2).fullFilePath;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.getDeliverGoods{
  padding: 20px 40px;
  .title{
    text-align: center;
    padding: 0px 0 20px;
    font-size: 24px;
  }
  .line{
    padding: 12px 0 12px 40px;
    margin: 0 -40px;
    background: #EDEDED;
  }
  .imgList{
    &>img{
      width: 178px;
      height: 178px;
      margin-right: 30px;
    }
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar-uploader{
    position: relative;
    .imgName{
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background: rgba(255,255,255,0.6);
      line-height: 40px;
    }
  }
}
</style>