import { render, staticRenderFns } from "./compensate.vue?vue&type=template&id=3c76c998&scoped=true&"
import script from "./compensate.vue?vue&type=script&lang=js&"
export * from "./compensate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c76c998",
  null
  
)

export default component.exports