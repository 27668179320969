<template>
  <div class="currencyConterBox">
    <el-row :gutter="24" class="">
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>商户账号</span>
        <el-input v-model="forms.merchantNo" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>商户名称</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>付款状态</span>
        <el-select v-model="forms.financePayStatuses" size="medium" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in paymentStatuslist"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
        <el-button type="primary" @click="init(1)" size="medium" plain
          >查询</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="querylist"
      border
      stripe
      class="table-mainbg mt20"
      @row-click="onRowclick"
    >
      <el-table-column
        prop="merchantNo"
        label="商户账号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantName"
        label="商户姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refundAmount"
        label="金额"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop="account"
        label="账号"
        align="center">
        <template slot-scope="scope">{{scope.row.merchantAccount?scope.row.merchantAccount.account:''}}</template>
      </el-table-column> -->
      <el-table-column
        prop="financePayStatus"
        label="付款状态"
        align="center" >
        <template slot-scope="scope">{{scope.row.financePayStatus==32?'付款中':scope.row.financePayStatus==33?'付款成功':scope.row.financePayStatus==34?'付款失败':'待付款'}}</template>
      </el-table-column>
      <el-table-column
        prop="refundType"
        label="退款类型"
        align="center" >
        <template slot-scope="scope">{{scope.row.refundType==2?'保证金':'预收车款'}}</template>
      </el-table-column>
      <el-table-column
        prop="applyName"
        label="申请人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="applyTime"
        label="申请时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="updateBy"
        label="操作人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="updateDate"
        label="操作时间"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        merchantName: "",
        merchantNo: "",
        date: "",
        financePayStatuses:'',
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      paymentStatuslist: [], //付款状态
    };
  },
  created() {
    this.paymentStatuslist = this.$store.state.setting.paymentStatuslist;
    if(this.$route.params.state) this.forms.financePayStatuses=Number(this.$route.params.state);

    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        merchantNo: this.forms.merchantNo,
        merchantName:this.forms.merchantName,
        financePayStatuses:this.forms.financePayStatuses?[this.forms.financePayStatuses]:'',
        refundTypes:[1],//退款类型：1预收车款，2保证金，默认1
      };
      this.$api.postMerchantRefundPayQuery(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(e){
      this.pageSize=e;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(e){
      this.currentPage=e;
      this.init()
    },
    onRowclick(row) {
      this.$router.push({
        path: "/finance/merchant/advanceChargeDetails",
        query: {
          id: row.id,
          type: 1, //1为预付款详情，2为保证金详情
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
  .rowbox {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    .line {
      width: 15px;
      height: 1px;
      background-color: #999999;
      margin: 0 5px;
    }
  }
}
</style>