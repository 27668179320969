<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="结算跟进" name="first"></el-tab-pane>
      <el-tab-pane label="待审核" name="second"></el-tab-pane>
      <el-tab-pane label="审核驳回" name="fourth"></el-tab-pane>
      <el-tab-pane label="审核通过" name="third"></el-tab-pane>
    </el-tabs>
    <el-row :gutter="20" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>车牌号 :</span>
        <el-input clearable v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-search" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="carNo" key='1' label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="makerName" key='2' label="品牌车型" align="center">
        <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="certTypeName" key='3' label="办证类型" align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="certCompany" key='4' label="办证公司" align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="tradeShopName" key='5' label="成交门店" align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="merchantNo" key='6' label="商户编号" align="center" v-if="['third'].includes(activeName)">
        <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantNo:''}}</template>
      </el-table-column>
      <el-table-column prop="customerName" key='7' label="客户名称" align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="merchantName" key='8' label="商户名称" align="center" >
        <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="certTypeName" key='9' label="过户方式" align="center" v-if="['second','fourth'].includes(activeName)"></el-table-column>
      <el-table-column prop="transferFinishTime" key='10' label="过户完成时间" align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="tradeShopName" key='11' label="成交门店" align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="sellerAssistantName" key='12' label="销售助理" align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="merchantDeadline" key='13' label="办证截止日期" align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="certTypeName" key='14' label="过户方式" align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="checkSuccessTime" key='15' label="审核时间" align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="checkOperator" key='16' label="审核人" align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="taskOverdueStatusName" key='17' label="办证状态" align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="updateDate" key='18' label="凭证提交时间" align="center" v-if="['second'].includes(activeName)"></el-table-column>
      <el-table-column prop="checkRejectTime" key='19' label="审核驳回时间" align="center" v-if="['fourth'].includes(activeName)"></el-table-column>
      <el-table-column label="操作" key='20' align="center" v-if="['second','third','fourth'].includes(activeName)" :width="150">
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="['second'].includes(activeName)">凭证及费用审核</el-button>
          <el-button type="primary" @click="onapply(scope.row,1)" size="mini" v-if="['third'].includes(activeName)&&scope.row.taskOverdueStatus==141">添加备注</el-button>
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="['fourth'].includes(activeName)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      params:{
        carNo:''
      },
    };
  },
  created() {
    if(this.$route.params.state){
      this.activeName=this.$route.params.state;
    }

    this.init();
  },
  methods: {
    init(page) {
      let taskStatus = 61;
      switch (this.activeName) {
        case "first":
          taskStatus = 61; //待结算
          break;
        case "second":
          taskStatus = 62; //待审核
          break;
        case "third":
          taskStatus = 63; //审核通过
          break;
        case "fourth":
          taskStatus = 64; //审核驳回
          break;
      }
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo:this.params.carNo,
        taskType: 5,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
      };
      if(this.activeName=='first'){
        query.taskSelfStatuses=[68];//结算跟进里面的车辆需要饱含商户自理的车辆
        // query.customerClearStatuses=[121];
        query.taskComClearStatuses=[121]
      }else{
        query.taskVoucherStatuses=[taskStatus]
      }
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onapply(row,state) {
      let aurl = "/accreditationCommissioner/expenseAuditDetails";
      let query={
        id:row.id,
      }
      if(state){
        aurl='/accreditationCommissioner/certificateRemarksDetails';
        query.title='办证逾期详情';
      }
      this.$router.push({
        path: aurl,
        query: query
      });
    },
    changState() {
      this.init();
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init()
    }
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>