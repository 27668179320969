import axios from "axios";
import qs from 'qs'
// import Cookies from 'js-cookie'
import ElementUI from 'element-ui';
import { Loading } from 'element-ui'
import Cookies from 'js-cookie'
import store from '../store';
import router from '../router';
 
const instance = axios.create({
});

instance.defaults.baseURL = process.env.VUE_APP_API_URL
let loadingInstance, loadingCount = 0 //申明loading
// request拦截器
instance.interceptors.request.use(
  config => {
    let loadingType=store.state.user.loadingType
    if (loadingCount == 0&&loadingType==true) {
      loadingInstance = Loading.service({
          lock: true,
          customClass: "loadginText",
          text: "数据加载中，请稍后...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.1)"
      })
  }
  loadingCount++
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token=localStorage.getItem('token')
    let pathname=window.location.pathname;
    config.headers['router'] = `${pathname}`;
    // const token=Cookies.get('token')
    if(token){
      config.headers['Authorization'] = token;
      const userId=localStorage.getItem('userId')
      config.headers['userId'] = userId;
      config.headers['sys_type'] = 1;//系统类型：1鸿鹄，2后台管理系统，默认1
      config.headers['Login-Source'] = 'web';//登录系统区分
      
    }
    if(config.method=='post'){
      /* 20211029 lxx修改 */
      if(config.headers['application']){
        config.headers['Content-Type'] = config.headers['application']
        delete config.headers['application']
      }else{
        config.headers['Content-Type'] = "application/x-www-form-urlencoded"
        config.data = qs.stringify(config.data)
      }
    }
    
   
    // config.headers['Content-Type'] = "application/json"
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    loadingCount--
    if (loadingInstance && loadingCount == 0) {
        loadingInstance.close()
    }
    // console.log(response,'----response----')
    if (response.status === 200) {
        if(response.data.code==0){
          return Promise.resolve(response.data)
        }else{
          if(response){
            if(response.data.data){
              ElementUI.Message({
                message: response.data.data,
                type: 'error'
              })
            }else{
              if(response.data.message){
                ElementUI.Message({
                  message: response.data.message,
                  type: 'error'
                })
              }
            }
          }
          // console.log(response.data.message,'---22')
          return Promise.resolve(response)
        }
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    loadingCount--
    if (loadingInstance && loadingCount == 0) {
        loadingInstance.close()
    }
    switch(error.response.status){
      case 401:
        ElementUI.Message({
          message: '请重新登录！',
          type: 'warning'
        })
        
        router.push('/login')
        sessionStorage.clear(); // 清除缓存/token等
        localStorage.clear()
        // Cookies.remove('username')
        // Cookies.remove('ckxPassword')
        // Cookies.remove('rememberMe')
        Cookies.remove('token')
        store.dispatch('routesList/setRoutesList', []); // 清空 vuex 路由列表缓存
        store.dispatch('user/setUserData', []); 
        // location.reload();
        break;
    }
    return Promise.reject(error)
  }
)
export default instance;
