<template>
  <div class="inventBoxBG">
    <div class="inventBox">
       <div class="title_top_nameBox mb20">车辆信息</div>
       <el-table :data="carlist" border stripe class="table-mainbg">
        <el-table-column prop="carNo" label="车辆号码" align="center"></el-table-column>
        <el-table-column prop="carBrandModelName" label="厂牌型号" align="center"></el-table-column>
        <el-table-column prop="engineNo" label="发动机编号" align="center"></el-table-column>
        <el-table-column prop="carColor" label="颜色" align="center"></el-table-column>
        <el-table-column prop="registerDate" label="初登日期" align="center"></el-table-column>
        <el-table-column prop="checkExpire" label="年检有效期" align="center"></el-table-column>
        <el-table-column prop="vinCode" label="车架号" align="center"></el-table-column>
      </el-table>
      <div class="title_top_nameBox mb20 mt30">商户信息</div>
       <el-table :data="merchantlist" border stripe class="table-mainbg">
        <el-table-column prop="merchantNo" label="商户编号" align="center"></el-table-column>
        <el-table-column prop="merchantName" label="商户姓名" align="center"></el-table-column>
        <el-table-column prop="walletAvailableAmount" label="钱包余额" align="center"></el-table-column>
        <el-table-column prop="certCompanyName" label="办证公司" align="center"></el-table-column>
        <el-table-column prop="isOtherCity" label="是否异地商户" align="center"></el-table-column>
      </el-table>
      <div class="title_top_nameBox mb20 mt30">成交信息</div>
       <el-table :data="deallist" border stripe class="table-mainbg">
        <el-table-column prop="carTradedPrice" label="成交价（元）" align="center"></el-table-column>
        <el-table-column prop="tradedPriceExpire" label="报价有效期" align="center"></el-table-column>
        <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
        <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
        <el-table-column prop="appointTradeShopName" label="预约门店" align="center"></el-table-column>
      </el-table>
      <div class="title_top_nameBox mt30">委托收购价格</div>
      <p class="mb10 mt20">甲方支付乙方人民币（大写）：<span class="text_decoration">{{form.merchantContract.totalPayAmountCn}}（￥：{{form.merchantContract.totalPayAmount}}）</span> </p>
      <p class="mt20">明细如下：</p>
      <p class="mb10 mt10">①该车委托收购价格为人民币（大写）：<span class="text_decoration">{{form.merchantContract.carTradedPrice}}（￥：{{form.merchantContract.carTradedPriceCn}}）</span> </p>
      <p class="mb10 mt10">②甲方支付乙方交易技术服务费为人民币（大写）：<span class="text_decoration">{{form.merchantContract.feeTechnoAmount}}（￥：{{form.merchantContract.feeTechnoAmountCn}}）</span> </p>
      <p class="mt10 mb10">③甲方预付过户手续保证金：￥{{form.merchantContract.depositAmount}}元</p>
      <p class="mt10 mb10">④库存周转费：￥{{form.merchantContract.feeTurnoverAmount}}元</p>
      <p class="mt10 mb10" v-if="form.merchantContract.insureAmount">⑤拿车无忧（{{form.merchantContract.merchantInsure}}）服务费：￥{{form.merchantContract.insureAmount}}元</p>
      <div class="title_top_nameBox mt30">收款明细</div>
       <p class="mt15">收款方式：
         <el-select v-model="checkListModal" multiple placeholder="请选择" @change="changePay" value-key="id" :disabled="[81].includes(form.financeGatheringStatus)?false:true">
            <el-option
               v-for="item in paymentMethodList" :key="item.id" :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </p>
        <div class="flex alignCenter " v-for="item in merchantPayTypeList" :key="item.id">
          <el-checkbox v-model="item.payTypeState" class="checkText" :disabled="[81].includes(form.financeGatheringStatus)?false:true">{{item.payTypeName}}</el-checkbox>
          <div class="ml20 mt10">
            <span>收款时间：</span>
            <el-date-picker
              v-model="item.date" class="fontInput"
              type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间" :disabled="[81].includes(form.financeGatheringStatus)?false:true">
            </el-date-picker>
          </div>
          <div class="mr20 ml20">
            <span>收款金额：</span>
            <el-input v-model="item.amount" class="w150 fontInput" size="medium" :disabled="[81].includes(form.financeGatheringStatus)?false:true"></el-input>
          </div>
          <div>
            <span>服务费：</span>
            <el-input v-model="item.fee" class="w150 fontInput" size="medium" :disabled="[81].includes(form.financeGatheringStatus)?false:true"></el-input>
          </div>
        </div>
        <!-- <el-radio-group v-model="form.data" class="mt15">
          <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
        </el-radio-group> -->
        <div class="mt20" v-if="[82].includes(form.financeGatheringStatus)">
          <span class="mr20">收款人：{{form.financeGatheringEmpName}}</span>
          <span class="mr20 ml20">确认时间：{{form.financeGatheringTime}}</span>
        </div>
        <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onPayment" v-if="[81].includes(form.financeGatheringStatus)">收款成功</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carlist:[],//车辆信息
      merchantlist:[],//商户信息
      deallist:[],//成交信息
      merchantPayTypeList:[],//商户付款方式集合
      form: {
        merchantContract:{},//商户合同
        merchant:{},
      },
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      stateType:1,//1为预付款详情，2为保证金详情，页面来源
      cities: [],//展示支付方式
      checkListModal:[],//选中支付方式
      merchantCarTradePayId:'',//商户成交/退车辆付款ID
      paymentMethodList:[],//收款方式
    };
  },
  created() {
    this.stateType = this.$route.query.type;
    if(this.$route.query.id) this.merchantCarTradePayId=this.$route.query.id;
    this.getDetailByMerchantCarTradePayId()//详情
    //商户支付方式列表
    if(this.$store.state.setting.BankMerchantPayList.length==0){
      this.$datadic.getBankMerchantPayTypeList().then(res=>{
        this.paymentMethodList=res;
      })
    }else{
      this.paymentMethodList=this.$store.state.setting.BankMerchantPayList;
    }
  },
  methods: {
    getDetailByMerchantCarTradePayId(){//获取详情
      this.$api.getDetailByMerchantCarTradePayId(this.merchantCarTradePayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            // this.getMerchantPayTypeListByMerchantId(res.data.merchantId);//获取支付方式列表
            //根据车辆ID查询商户支付方式列表
            this.getMerchantPayTypeListByCarId(res.data.carId)
            
            let obj={
              carNo:this.form.carNo,
              carBrandModelName:this.form.carBrandModelName,
              engineNo:this.form.engineNo,
              carColor:this.form.carColor,
              registerDate:this.form.registerDate,
              checkExpire:this.form.checkExpire,
              vinCode:this.form.vinCode
            }
            this.carlist=[obj];//车辆信息
            let obj1={}//商户信息
            let obj2={}//成交信息
            if(this.form.merchant){
              let as=this.form.merchant;
                obj1.merchantNo=as.merchantNo;
                obj1.merchantName=this.form.merchantName;
                obj1.isOtherCity=as.isOtherCity;
                obj1.walletAvailableAmount=as.walletAvailableAmount;
            }
            if(this.form.merchantContract){
              let as=this.form.merchantContract;
                obj1.certCompanyName=as.certCompanyName;
                obj2.carTradedPrice=as.carTradedPrice;
                obj2.appointTradeShopName=res.data.appointTradeShopName;
            }
            obj2.tradedPriceExpire=this.form.tradedPriceExpire;
            obj2.tradeTypeName=this.form.tradeTypeName;
            obj2.tradeShopName=this.form.tradeShopName;
            this.merchantlist=[obj1];//商户信息
            this.deallist=[obj2];//成交信息
            
            
          }
        }
      })
    },
    changePay(e){
      let arr=[];
      let objMe={};
      if(this.form.merchantContract){
        objMe=this.form.merchantContract
      }
      let time=this.$$.getTodyTime()
      for(let i=0;i<e.length;i++){
        let obj={
          date:time,
          payTypeState:true,//默认选中
          payType:e[i].id,
          payTypeName:e[i].name,
          fee:'',
          amount:''
        }
        if(i==0){
          let acount=objMe.totalPayAmount-(objMe.feeTechnoAmount?objMe.feeTechnoAmount:0);

          obj.fee=objMe.feeTechnoAmount;
          obj.amount=acount;
        }
         arr.push(obj)
      }
      this.merchantPayTypeList=arr;
    },
    onPayment(){
      let arr=[];
      for(let i=0;i<this.merchantPayTypeList.length;i++){
        let item=this.merchantPayTypeList[i]
        if(item.payType){
          let obj={
            id:item.id,
            financeReceiveTime:item.date,
            payType:item.payType,
            merchantId:item.merchantId,
            amount:item.amount,
            fee:item.fee
          }
          if(item.amount===''){
            this.$message.warning('收款金额不能为空！')
            return
          }
          arr.push(obj)
        }
      }
      if(arr.length==0){
        this.$message.warning('请选择收款方式！')
        return
      }
      this.$confirm('请确认是否收款成功?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => { 
        this.postMerchantTradeFinanceGathering(arr)
      }).catch(() => {});
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/merchant/carCollection',
        query:{
          state:this.stateType
        }
      })
    },
    // getMerchantPayTypeListByMerchantId(merchantId){//根据商户ID查询商户支付方式列表
    //   this.$api.getMerchantPayTypeListByMerchantId(merchantId).then(res=>{
    //     if(res.code==0){
    //       this.cities=res.data;
    //     }
    //   })
    // },
    postMerchantTradeFinanceGathering(arr){//财务确认付款成功
      let query={
        merchantCarTradePayId:this.merchantCarTradePayId,
        payTypeForms:arr
      }
      this.$api.postMerchantTradeFinanceGathering(query).then(res=>{
        if(res.code==0){
          this.getDetailByMerchantCarTradePayId()//详情
          this.$message.success(res.message)
        }
      })
    },
    getMerchantPayTypeListByCarId(carId){//根据车辆ID查询商户支付方式列表
      this.$api.getMerchantPayTypeListByCarId(carId).then(res=>{
        if(res.code==0){
          if(res.data.length>0){
            let merchantPayTypeList=res.data;
            let arr=[]
            for(let i=0;i<merchantPayTypeList.length;i++){
              let item=merchantPayTypeList[i];
              item.payTypeState=true;
              item.date=item.financeReceiveTime;
              let obj={
                contents:null,
                id:item.payType,
                name:item.payTypeName
              }
              arr.push(obj)
            }
            this.merchantPayTypeList=merchantPayTypeList;//商户付款方式集合
            this.checkListModal=arr;
          }
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px 140px;
  .checkText{
    margin-top:10px;
  }
}

</style>
<style>
.fontInput .el-input__inner{
  border:0;
  border-bottom: 1px solid #333333;
}
.checkText .el-checkbox__label{
  font-size:16px
}
</style>