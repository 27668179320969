<template>
  <!-- 库存车处理 -->
  <div class="pageContainer">
    <el-tabs v-model="activeName" @tab-click="init(1)" type="card">
      <el-tab-pane label="待重拍" name="first"></el-tab-pane>
      <el-tab-pane label="待检测" name="second"></el-tab-pane>
      <el-tab-pane label="竞拍中" name="third"></el-tab-pane>
      <el-tab-pane label="确认成交" name="fourth"></el-tab-pane>
      <el-tab-pane label="退车" name="fifth"></el-tab-pane>
    </el-tabs>
     <el-row :gutter="24" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" >
        <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt15 table-mainbg">
        <el-table-column prop="carNo" label="车牌号" align="center" key="1"></el-table-column>
        <el-table-column prop="brandName" label="品牌车型" align="center" key="2">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
        </el-table-column>
        <el-table-column prop="checkerNo" label="检测师账号" align="center" v-if="['second','third'].includes(activeName)" key="3"></el-table-column>
        <el-table-column prop="checkerName" label="检测师姓名" align="center" v-if="['second','third'].includes(activeName)" key="4"></el-table-column>
        <el-table-column prop="allotCheckTime" label="检测分配时间" align="center" v-if="['second'].includes(activeName)" key="5"></el-table-column>
        <el-table-column prop="enquiryTypeName" label="检测类型" align="center" v-if="['second','third'].includes(activeName)" key="6"></el-table-column>
        <el-table-column prop="mcTradePrice" label="成交价" align="center" v-if="['fourth','fifth'].includes(activeName)" key="7"></el-table-column>
        <el-table-column prop="carDzPrice" label="垫资金额" align="center" v-if="['first','fourth','fifth'].includes(activeName)" key="8"></el-table-column>
        <el-table-column prop="diffDzPrice" label="差额" align="center" v-if="['fourth','fifth'].includes(activeName)" key="9"></el-table-column>
        <el-table-column prop="preTradedMerchantName" label="上次成交商户" align="center" v-if="['fourth','fifth'].includes(activeName)" key="10"></el-table-column>
        <el-table-column prop="merchantName" label="本次成交商户" align="center" v-if="['fourth','fifth'].includes(activeName)" key="11"></el-table-column>
        <el-table-column prop="tradeShopName" label="成交门店" align="center" v-if="['first','fifth'].includes(activeName)" key="12"></el-table-column>
        <el-table-column prop="priceConfirmTime" label="价格确认时间" align="center" v-if="['fourth'].includes(activeName)" key="13"></el-table-column>
        <el-table-column prop="returnTime" label="退车时间" align="center" v-if="['fifth'].includes(activeName)" key="14"></el-table-column>
        <el-table-column prop="businessOwnerName" label="业务归属" align="center" v-if="['first'].includes(activeName)" key="15"></el-table-column>
        <el-table-column prop="ifMerchantSubmitDiff" label="车商是否提差异" align="center" v-if="['first'].includes(activeName)" key="16"> </el-table-column>
        <el-table-column prop="checkTime" label="车辆检测时间" align="center" v-if="['first'].includes(activeName)" key="17"></el-table-column>
        <el-table-column prop="carCheckFinishTime" label="检测完成时间" align="center" v-if="['third'].includes(activeName)" key="18"></el-table-column>
        <el-table-column prop="auctionBeginTime" label="竞拍开始时间" align="center" v-if="['third'].includes(activeName)" key="19" ></el-table-column>
        <!-- <el-table-column prop="giveUpTime" label="放弃时间" align="center"></el-table-column> -->
        <el-table-column label="操作" align="center" v-if="['first','fourth'].includes(activeName)" key="20" :width="['fourth'].includes(activeName)?'350px':''">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="['fourth'].includes(activeName)&&scope.row.stockCarIsBook===1" @click="onDeal(scope.row)">约成交</el-button>
            <el-button type="primary" size="mini" v-if="['first','fourth'].includes(activeName)" @click="onRecheck(scope.row)">重拍</el-button>
            <!-- <el-button type="primary" size="mini" @click="onRecheck(scope.row,1)"  v-if="['third'].includes(activeName)">查看</el-button> -->
              
              <el-button type="primary" size="mini" v-if="['fourth'].includes(activeName)" @click="onReturnCar(scope.row)">退车</el-button>
              <el-button type="primary" size="mini" v-if="['fourth'].includes(activeName)" @click="$router.push({path: '/salesManager/consign',query:{enquiryId:scope.row.carAuctionId}})">寄售</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <el-dialog :visible.sync="visible" title="库存车重拍" class="customDialog">
      <template v-if="modalState==1">
        <p>此车辆需重新检测，请分配检测师</p>
        <div class="mt20 mb20 flex ">
          <span class="mr10 w130">请选择检测师:</span>
          <el-radio-group v-model="inspector" size="medium">
            <!-- <el-radio-button v-for="item in roleList" :key="item.id" :label="item.id" >{{item.name}}</el-radio-button> -->
            <el-radio class="mb10" v-for="item in roleList" :key="item.id" :label="item.id" >{{item.name}}</el-radio>
          </el-radio-group>
        </div>
      </template>
      <template v-if="modalState==2">
        <p>此车辆无需重检，您确认重拍吗？</p>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible=false">取消</el-button>
        <el-button type="primary" @click="onModalSubmit">{{modalState==1?'提交':'确认'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      activeName: 'first',
      visible:false,
      modalState:1,//1需重检 2无需重检
      itemRow:{},
      inspector:'',
      roleList:[],//检测师列表
      forms:{
        carNo:''
      }
    }
  },
  created(){
    this.init()
    this.queryUserListByRoleAndDepart()//获取检测师
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    init(page) {
      this.page_index=page?page:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        carNo:this.forms.carNo
      }
      switch(this.activeName){
        case 'first'://库存车重检类型：1011库存车待重拍，1012库存车确认成交，1013库存车退车，1014库存车需重检【待检测】，1015库存车无需重检【竞拍中】
          query.stockCarReAuctionTypes=[1011]
          break;
        case 'second':
          query.stockCarReAuctionTypes=[1014]
          break;
        case 'third':
          query.stockCarReAuctionTypes=[1015]
          break;
        case 'fourth':
          query.stockCarReAuctionTypes=[1012]
          break;
        case 'fifth':
          query.stockCarReAuctionTypes=[1013]
          break;
      }
      this.$api.carList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist=res.data.records;
          this.page_total=res.data.total;
        }else{
          this.querylist=[];
          this.page_total=0;
        }
      })
    },
    onRecheck(row){//state 1需重检 2无需重检
      if(row.needRecheck=='需重检'){
        this.modalState=1;
      }else if(row.needRecheck=='无需重检'){
        this.modalState=2;
      }
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.visible=!this.visible
    },
    onDeal(row){//成交类型: 21预约成交，22垫资成交
      this.$api.getDealBookMerchantId(row.carAuctionId,row.merchantId).then(res=>{
        if(res.code==0){
          if(res.data){
            this.$router.push({
              path:'/salesAssistant/reservationDetail',
              query:{
                id:res.data
              }
            })
          }else{
            this.$message.warning('当前车辆未获取到商户预约ID!')
          }
        }
      })
    },
    onReturnCar(row){//退车
      this.$confirm(`你确认要将车牌号为${row.carNo}的车移入退车吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.getStockCarRetreat(row.id).then(res=>{
            if(res.code==0){
              this.page_index=1;
              this.init(1)
              this.$message.success(res.message)
            }
          })
        }).catch(() => {});
    },
    onModalSubmit(){//1需重检 2无需重检
      if(this.modalState==1){
        if(!this.inspector){
          this.$message('请选择检测师！')
          return
        }
        this.postStockCarReAuction(1014,this.inspector)
      }else{
        this.postStockCarReAuction(1015)
      }
    },
    postStockCarReAuction(stockCarReAuctionType,checkerId){//库存车重拍
      let query={
        "carId": this.itemRow.id,
        "checkerId": checkerId,
        "stockCarReAuctionType": stockCarReAuctionType//库存车重检类型：1014需重检，1015无需重检
      }
      this.$api.postStockCarReAuction(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.visible=false;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    queryUserListByRoleAndDepart(){//获取检测师
      let query={
        roleName:'检测师'
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.roleList=res.data;
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.pageContainer{
  .w130{
    width:130px;
  }
}
</style>