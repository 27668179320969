<template>
  <div class="app-wrapper">
    <!-- 顶部菜单 -->
    <Sidebar class="sidebarCon" :userlist="userlist" @handleChangeLeft="handleChangeLeft"/>
    <TagsView :class="{'left205':Collapse, 'isAdmin': isAdmin,'left64':!Collapse}" class="tagsViewCon"></TagsView>
    <Appmain :class="{'appMain':Collapse, 'isAdmin': isAdmin,'padding64':!Collapse}"></Appmain>
    <VerticalSidebar class="leftMain" v-if="isAdmin" ref="leftMeun"></VerticalSidebar>
  </div>
</template>

<script>
import Sidebar from '@/components/sidebar/horizontal.vue'
import VerticalSidebar from '@/components/sidebar/vertical.vue'
import Appmain from '@/components/home/appmain.vue'
import TagsView from '@/components/TagsView/index.vue'
export default {
  components: {
    Sidebar, Appmain, TagsView, VerticalSidebar
  },
  data() {
    return {
      isAdmin: true,//是否开启左侧菜单导航
      userId:'',
      userlist:{},
      Collapse:true,//是否展开
      mainClass:'padding205',//是否展示间隔padding
      autoMedium:'web',//当前设备是什么
    }
  },
  created() {
    this.userId=this.$store.state.user.userId;
    
    // if(this.$store.state.permission.permission.indexOf('admin') > -1){
    //   this.isAdmin = true
    // }else{
    //   this.isAdmin = false;
    // }
    let userData=sessionStorage.getItem('userData')
    // let userData=this.$store.state.user.userData;
    if(JSON.stringify(userData)=='{}'||!userData){
      this.getUserData()
    }else{
      this.userlist=JSON.parse(userData);
    }
   
  },
  mounted(){
    this.autoMedium=this.$$.autoMedium()//设备
    if(this.autoMedium=='mobile'){
      this.handleChangeLeft()
    }
  },
  methods:{
    getUserData(){
      this.$api.getUserData(this.userId).then((res)=>{
        if(res.code==0){
          this.userlist=res.data;
          sessionStorage.setItem('userData',JSON.stringify(res.data))
          this.$store.dispatch('user/setUserData',res.data)
        }
      })
    },
    handleChangeLeft(){
      // this.isAdmin=!this.isAdmin;
      if(this.$refs.leftMeun){
        this.$refs.leftMeun.setIsCollapse=!this.$refs.leftMeun.setIsCollapse;
        this.Collapse=!this.Collapse;
        // this.$refs.leftMeun.handleChangeLeft()
      }
    },
  }
}
</script>
<style>
.leftMain:not(.el-menu--collapse) {
  width: 205px;
  min-height: 400px;
}
</style>
<style scoped lang="scss">
.app-wrapper{
  position: relative;
  height: 100%;
  width: 100%;
  .appMain{
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 94px;
    min-height: calc(100vh - 94px);
  }
  .sidebarCon{
    width: 100%;
    position: fixed;
    z-index: 5;
  }
  .tagsViewCon {
    height: 34px;
    width: 100%;
    position: fixed;
    background: #fff;
    top: 60px;
    left: 0;
    z-index: 5;
    border-bottom: 1px solid #d8dce5;
    -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
  }
  .isAdmin.left205{left: 205px;}
  .isAdmin.left64{left: 64px;}
  .isAdmin.appMain{padding-left: 205px;}
  .isAdmin.padding64{padding-left: 64px;padding-top: 94px;}
  .leftMain{
    // width: 205px;
    position: fixed;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
    z-index:9999;
  }
}
@media (max-width: 1024px) {
  .app-wrapper .leftMain{
    height: 100%;
  }
  .isAdmin{
    width:1000px;
  }
}
</style>