<template>
  <div class="pageContainer bgF5 pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist" :title="''"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:''
    };
  },
  created() {
    let customObj=[{
            num: '',
            name: '外勤检测资源设置',
            star: 0,
            url:'/clerk/fieldResourceSetting'
          },{
            num: '',
            name: '外勤检测调度',
            star: 0,
            url:'/clerk/fieldInspectionDispatching'
          },{
            num: '',
            name: '门店检测资源设置',
            star: 0,
            url:'/clerk/storeResourceSettings'
          }]
          this.infolist=[{name:"",list:customObj}];
    this.init();
  },
  methods: {
    init() {
      this.$api.getSchedulingFieldCount().then(res=>{
        if(res.code==0){
          this.infolist[0].list[1].num=res.data;
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      }) 
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>
