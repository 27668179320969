<template>
  <!-- 历史合同查询 -->
  <div class="historicalContract">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>客户名称</span>
        <el-input v-model="params.customerName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>成交归属</span>
        <el-select v-model="params.sellerNo" placeholder="" size="medium" clearable>
          <el-option v-for="(item,index) in sellerNamelist" :key="index" :label="item.name" :value="item.no"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
        <span>成交门店</span>
        <el-select v-model="params.tradeShopId" size="medium" placeholder="" clearable>
          <!-- <el-option label="请选择" value=""></el-option> -->
          <el-option v-for="item in storelist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="7" :lg="10" class="flex flex_align_center flex_between w5 mb15">
        <span>签订成功时间</span>
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" value-format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']" @change="timeChange" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="1" :lg="6" class="mb15">
        <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg  mt5">
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
      <!-- <el-table-column prop="contractNo" label="合同编号" align="center" ></el-table-column> -->
      <el-table-column prop="makerName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.makerName}}/{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center" ></el-table-column>
      <el-table-column prop="customerDeadline" label="客户办证截止日期" align="center" ></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
      <el-table-column prop="carTradedPrice" label="成交价" align="center" width="100px"></el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center" width="100px"></el-table-column>
      <!-- <el-table-column prop="isPledgeName" label="抵押车" align="center" width="80px"></el-table-column> -->
      <el-table-column prop="sellerName" label="成交归属" align="center"></el-table-column>
      <el-table-column prop="signTime" label="签订成功时间" align="center"></el-table-column>
      <el-table-column prop="paymentFinishedTime" label="付款完成时间" align="center" width="190px"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onsee(scope.row)" size="mini">查看合同</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo:'',
        sellerNo: '',
        tradeShopId: '',
        createTime: '',
        customerName:''
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      sellerNamelist:[],
      storelist:[],
    }
  },
  created(){
    this.getlistTradeOwned();//成交归属下拉 列表
    this.getShopList()//查询所有部门(成交门店)
    this.init()
  },
  methods: {
    init(page){
      this.page_index=page?page:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        contractStatus:[14],//客户合同状态，11待领取，12制作中，13签订中，14签订成功，15签订失败，16待付款，17已付款
        carNo:this.params.carNo,//车牌号
        sellerNo:this.params.sellerNo,//帮卖经理工号，成交归属人
        tradeShopIds:this.params.tradeShopId?[this.params.tradeShopId]:'',//成交门店ID
        beginSignSuccessDate:this.params.createTimeBegin,//签订成功日期begin
        endSignSuccessDate:this.params.createTimeEnd,//签订成功日期end
        customerName:this.params.customerName,
      }
      this.$api.getCustomerList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.page_total=res.data.total;
          }else{
            this.querylist=[];
            this.page_total=0;
          }
        }
      })
    },
    //时间控件改变, 数据展示间隔也相应变化.
    timeChange(){
      if(this.params.createTime === null || this.params.createTime.length=== 0){
        this.params.createTimeBegin = this.params.createTimeEnd ='';
      }else{
        this.params.createTimeBegin = this.params.createTime[0];
        this.params.createTimeEnd = this.params.createTime[1];
      }
    },
    onsee(row){
      // this.$router.push({
      //   path: '/salesManager/contractMaking',
      //   query: {id:row.id}
      // })
      const pathData=this.$router.resolve({
          path:'/salesManager/contractMaking',
          query: { 
            id:row.id,
          }
      })
      window.open(pathData.href,'_blank')
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    getlistTradeOwned(){//成交归属下拉 列表
      this.$api.getlistTradeOwned('帮卖经理').then((res)=>{
        if(res){
          if(res.code==0){
            this.sellerNamelist=res.data;
          }else{
            this.sellerNamelist=[];
          }
        }
      })
    },
    getShopList(){//查询所有部门(成交门店)
      let query={
        isEnabled:0,
        shopTypeList:[0,3]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      this.$api.getShopList(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.storelist=res.data
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.historicalContract{
  padding: 20px 40px;
}
</style>