<template>
  <div class="walletBG">
    <div class="pageContainer">
      <!--基本信息-->
      <el-form
          ref="form"
          :model="form"
          label-width="150px"
          :hide-required-asterisk="true"
          :rules="rules"
        >
        <el-form-item label="合作店简称:">
          <el-input v-model="form.shortName" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <el-form-item label="车牌号码:">
          <el-input v-model="form.carNo" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <el-form-item label="违约类型:">
          <el-input v-model="form.flowTypeName" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <el-form-item label="收支类型:">
          <el-input v-model="form.inOrOutName" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <el-form-item label="赔偿金额（元）:">
          <el-input v-model="form.amount" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <el-form-item label="审核前金额（元）:">
          <el-input v-model="form.walletCurrentAvailableAmount" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <el-form-item label="审核后金额（元）:">
          <el-input v-model="form.walletAfterAmount" size="medium" disabled class="w300"></el-input>
        </el-form-item>
        <div class="mt30 comfootbox">
          <el-button type="primary" size="medium" @click="onSubmit">审核通过</el-button>
          <el-button type="primary" size="medium" @click="onroute">暂时不审核</el-button>
        </div>
        </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        explain:[{ required: true, message: "审批说明不能为空" }]
      },
      id:''
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getChannelAuditTask()//详情
  },
  methods: {
    onSubmit() {
     this.$api.postChannelDefaultAudit(this.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.onroute();
          }
        }
      })
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/channel/compensate"
      });
    },
    getChannelAuditTask(){//获取详情
      this.$api.getChannelAuditTask(this.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data
            this.form.inOrOutName==this.form.inOrOut==1?'支出':'收入'
          }
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .comfootbox{
    width:500px;
    display: flex;
    justify-content: center;
  }
  .w300{
    width: 300px;
  }
}
</style>