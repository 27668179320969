<template>
    <div class="vehicleFormBox">
      <!--基本信息-->
      <div class="ckxTitle mb15">基本信息</div>
      <el-row>
        <el-col :span="6">车牌号：{{form.carNo}}</el-col>
        <el-col :span="6">办证类型：{{form.certTypeName}}</el-col>
        <el-col :span="6">品牌车型：{{form.makerName}}&nbsp;{{form.seriesName}}</el-col>
        <el-col :span="6">产证编号：{{form.certNo}}</el-col>
        <!-- <el-col :span="4">车辆所在库：{{res.stockObjInName}}</el-col> -->
      </el-row>
      <!--办证信息-->
      <div class="flex flex_align_center mb15 mt30">
        <div class="ckxTitle ">{{state==1?'办证信息':'办证信息-客户方'}}</div>
        <span class="seeColor ml20" @click="onrouter(0)">客户合同查看>></span>
      </div>
      <div class="content_title_Box content_title_space">
        <div class="content_title_row">
          <label>客户姓名：</label>
          <span>{{form.customerName}}</span>
        </div>
        <div class="content_title_row">
          <label>客户电话：</label>
          <span>{{form.customerMobile}}</span>
          <el-button
            type="primary"
            class="ml30"
            @click="oncalll(form.customerMobile,1)"
            size="mini"
            plain
            v-show="form.customerMobile"
          >呼叫</el-button>
        </div>
        <div class="content_title_row">
          <label>客户办证截止日期：</label>
          <span>{{customerContract.customerDeadline}}</span>
        </div>
        <div class="content_title_row">
          <label>成交门店：</label>
          <span>{{customerContract.tradeShopName}}</span>
        </div>
        <div class="content_title_row">
          <label>帮卖经理：</label>
          <span>{{customerContract.sellerName}}</span>
        </div>
        <div class="content_title_row">
          <label>客户电话：</label>
          <span>{{form.carCustomer.customerPhone}}</span>
          <el-button
            type="primary"
            class="ml30"
            @click="oncalll(form.carCustomer.customerPhone,2)"
            size="mini"
            plain
            v-show="form.carCustomer.customerPhone"
          >呼叫</el-button>
        </div>
        <div class="content_title_row">
          <label>身份证号：</label>
          <span>{{form.customerIdNo}}</span>
        </div>
        <div class="content_title_row">
          <label>客户签订日期：</label>
          <span>{{customerContract.signTime}}</span>
        </div>
        <div class="content_title_row">
          <label>代办公司：</label>
          <span>{{form.certCompany}}</span>
        </div>
      </div>
      <!--待补办项清单-->
      <div class=" mt20" v-if="state==1">
        <div class="ckxTitle mb15">待补办项目</div>
        <el-table :data="procedureList1" border ref="seleTable1" @selection-change="selectChange($event,1)" v-if="state">
          <el-table-column type="selection" label="全选" align="center" width="55"></el-table-column>
          <el-table-column prop="typeName" label="补办类型" align="center"></el-table-column>
          <el-table-column prop="name" label="项目" align="center">
            <template slot-scope="scope">{{scope.row.name}}&nbsp;{{scope.row.selfDefineRemark}}</template>
          </el-table-column>
          <el-table-column prop="nature" label="补办要求" align="center"></el-table-column>
          <el-table-column prop="feeBearerName" label="承担方" align="center">
            <template slot-scope="scope">{{scope.row.feeBearerName}}{{`${[4,5].includes(scope.row.feeBearerId)?scope.row.bearerCertRuleSupplierName?'-'+scope.row.bearerCertRuleSupplierName:'':''}`}}</template>
          </el-table-column>
          <el-table-column prop="chargingFeeAmount" label="收取费用" align="center"></el-table-column>
          <el-table-column prop="statusName" label="补办状态" align="center"></el-table-column>
          <el-table-column label="操作" align="center"  width="260px">
            <template slot-scope="scope">
              <template v-if="state==1">
                 <el-button type="primary" @click="unpdate(scope.row,1)" size="mini">补办下单</el-button>
                 <el-button type="primary" @click="unpdate(scope.row,2)" size="mini" v-if="['合同约定'].includes(scope.row.typeName)&&['如需变更'].includes(scope.row.nature)||scope.row.type===0">编辑</el-button>
                <el-button type="primary" @click="unpdate(scope.row,3)" size="mini">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-row class="mt10">
          <el-button type="primary" @click="onOrder(1)" size="mini" >批量补办下单</el-button>
        </el-row>

        <div class="ckxTitle mb15 mt20">补办中项目</div>
        <el-table :data="procedureList2" border ref="seleTable2" @selection-change="selectChange($event,2)" v-if="state">
          <el-table-column type="selection" label="全选" align="center" width="55"></el-table-column>
          <el-table-column prop="typeName" label="补办类型" align="center"></el-table-column>
          <el-table-column prop="name" label="项目" align="center">
            <template slot-scope="scope">{{scope.row.name}}&nbsp;{{scope.row.selfDefineRemark}}</template>
          </el-table-column>
          <el-table-column prop="nature" label="补办要求" align="center"></el-table-column>
          <el-table-column prop="feeBearerName" label="承担方" align="center">
            <template slot-scope="scope">{{scope.row.feeBearerName}}{{`${[4,5].includes(scope.row.feeBearerId)?scope.row.bearerCertRuleSupplierName?scope.row.bearerCertRuleSupplierName:'':''}`}}</template>
          </el-table-column>
          <el-table-column prop="chargingFeeAmount" label="收取费用" align="center"></el-table-column>
          <el-table-column prop="statusName" label="补办状态" align="center"></el-table-column>
          <el-table-column prop="beneficiaryName" label="受益方" align="center">
            <template slot-scope="scope">{{scope.row.beneficiaryName}}{{`${[4,5].includes(scope.row.beneficiaryId)?scope.row.benefitCertRuleSupplierName?'-'+scope.row.benefitCertRuleSupplierName:'':''}`}}</template>
          </el-table-column>
          <el-table-column prop="payFeeAmount" label="受益方金额" align="center"></el-table-column>
          <el-table-column label="操作" align="center"  width="230px">
            <template slot-scope="scope">
              <template v-if="scope.row.status===82">
                 <el-button type="primary" @click="unpdate(scope.row,4)" size="mini">补办完成</el-button>
                <el-button type="primary" @click="unpdate(scope.row,5)" size="mini">退回待补办</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-row class="mt10">
          <el-button type="primary" @click="onOrder(2)" size="mini" >批量补办完成</el-button>
          <el-button type="primary" @click="onOrder(3)" size="mini" >退回待补办</el-button>
        </el-row>
      </div>
      <div class=" mt20" v-if="state==2">
        <div class="ckxTitle mb15">车辆补办材料列表</div>
        <el-table :data="procedureList" border >
          <el-table-column type="index" label="序号" align="center" width="80px" ></el-table-column>
          <el-table-column prop="typeName" label="补办类型" align="center"></el-table-column>
          <el-table-column prop="name" label="项目" align="center">
            <template slot-scope="scope">{{scope.row.name}}&nbsp;{{scope.row.selfDefineRemark}}</template>
          </el-table-column>
          <el-table-column prop="nature" label="补办要求" align="center"></el-table-column>
          <el-table-column prop="feeBearerName" label="承担方" align="center">
           <template slot-scope="scope">{{scope.row.feeBearerName}}{{`${[4,5].includes(scope.row.feeBearerId)?scope.row.bearerCertRuleSupplierName?'-'+scope.row.bearerCertRuleSupplierName:'':''}`}}</template>
          </el-table-column>
          <el-table-column prop="chargingFeeAmount" label="收取费用" align="center"></el-table-column>
          <el-table-column prop="beneficiaryName" label="受益方" align="center">
            <template slot-scope="scope">{{scope.row.beneficiaryName}}{{`${[4,5].includes(scope.row.beneficiaryId)?scope.row.benefitCertRuleSupplierName?'-'+scope.row.benefitCertRuleSupplierName:'':''}`}}</template>
          </el-table-column>
          <el-table-column prop="payFeeAmount" label="受益方金额" align="center"></el-table-column>
          <el-table-column prop="statusName" label="补办状态" align="center"></el-table-column>
          <el-table-column prop="finishTime" label="下单时间" align="center" > </el-table-column>
        </el-table>
      </div>
      
      <div class="footer" v-if="state==1">
        <div class="btn">
          <!-- <el-button type="primary" @click="onOrder">确认下单</el-button> -->
          <el-button type="primary" @click="onAdd('forms')" >添加补办项</el-button>
        </div>
      </div>

      <el-dialog
        title="确认下单"
        :visible.sync="dialogVisibleTab"
        :show-close="false"
        class="customDialog"
        width="660px"
      >
        <p class="mb10">补办项目</p>
        <el-table :data="procedureListSelect" border @selection-change="selectChange($event,3)" ref="multipleTable">
          <el-table-column type="selection" label="全选" align="center" width="55" v-if="state==1"></el-table-column>
          <el-table-column type="index" label="序号" align="center" width="80px" v-if="state==2"></el-table-column>
          <el-table-column prop="typeName" label="补办类型" align="center"></el-table-column>
          <el-table-column prop="name" label="项目" align="center">
            <template slot-scope="scope">{{scope.row.name}}&nbsp;{{scope.row.selfDefineRemark}}</template>
          </el-table-column>
          <el-table-column prop="nature" label="补办要求" align="center"></el-table-column>
          <el-table-column prop="feeBearerName" label="承担方" align="center" ></el-table-column>
          <el-table-column prop="chargingFeeAmount" label="收取费用" align="center"></el-table-column>
        </el-table>  
         <p class="mb10 mt10">选择补办方</p>
          <el-cascader :options="supplierList" :props="props"  v-model="forms.certCompanyObj" clearable ref="refcascaderT" @change="changeSupplie(1)" :show-all-levels="false"></el-cascader>
          <!-- <el-select v-model="forms.certProcedureReplacer" placeholder="请选择补办方" class="w180" size="medium" v-show="!projectType">
            <el-option v-for="item in supplierList" :key="item.id" :value="item.value" :label="item.name"></el-option>
          </el-select> -->
          <span slot="footer" class="dialog-footer">
            <el-button
            type="primary"
            @click="confirmOrder">提交</el-button>
            <el-button @click="dialogVisibleTab=false">取消</el-button>
          </span>
      </el-dialog>

      <el-dialog
        :title="`${stateModal}补办项`"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="700px"
      >
        <el-form
          ref="forms"
          :model="forms"
          label-width="150px"
          :rules="rules"
          :hide-required-asterisk="true"
          class="mt20" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="补办项目:" prop="obj">
                <el-select v-model="forms.obj" placeholder="请选择补办项目" class="w180" size="medium" v-show="!projectType" value-key="id" @change="onSelect(forms.obj)" :disabled="stateModal=='新增'||forms.typeName=='新增'?false:true">
                  <el-option v-for="item in replaceList" :key="item.id" :label="item.itemName" :value="item"></el-option>
                </el-select>
                <el-input v-model="forms.name" class="w180" size="medium" v-show="projectType"  ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补办要求:" >
                <el-select
                  v-model="forms.nature"
                  placeholder="请选择补办要求"
                  class="w180"
                  size="medium" disabled>
                  <el-option label="需变更" value="需变更"></el-option>
                  <el-option label="补办" value="补办"></el-option>
                </el-select>
              </el-form-item>
            <!-- <el-button type="primary" size="medium" @click="projectType=true" plain>选不到加一个</el-button>  -->
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="费用承担方:" prop="feeBearerObj">
                <el-cascader :options="supplierList1" :props="props"  v-model="forms.feeBearerObj" clearable ref="refcascader" @change="changeSupplie(2)" :show-all-levels="false" :disabled="stateModal=='新增'||forms.typeName=='新增'?false:true"></el-cascader>
                <!-- <el-select
                  v-model="forms.feeBearerId"
                  placeholder="请选择费用承担方"
                  class="w180" :disabled="stateModal=='新增'||forms.typeName=='新增'?false:true"
                  size="medium">
                  <el-option v-for="item in whichSidelistId" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="承担方费用(元):" prop="chargingFeeAmount">
                <el-input type="number" v-model.number="forms.chargingFeeAmount" class="w180" size="medium"  :disabled="stateModal=='新增'||forms.typeName=='新增'?false:true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="footer-modalBut"
            @click="onSubmitform('forms',stateModal)"
          >提交</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
export default {
  data() {
    return {
      state: null,//1待补办-补办手续，2补办中-查看，3补办完成-查看
      procedureList: [],//车辆补办材料列表
      form: {
        carCustomer: {}, //	车辆客户信息
        carMerchant: {} //车辆商户信息
      }, //展示数据
      customerContract:{},
      res:{},
      forms: {
        certCompanyId: "",
        payFeeAmount: "",
        chargingFeeAmount: "",
        feeBearerObj: [],
        obj:{},
        name:''
      },
      dialogVisible: false,
      rules: {
        carNo: [{ required: true, message: "车牌号码不能为空" }],
        certCompanyId: [{ required: true, message: "补办项目不能为空" }],
        name: [{ required: true, message: "补办项目不能为空" }],
        feeBearerObj: [{ required: true, message: "费用承担方不能为空" }],
        chargingFeeAmount: [{ required: true, message: "收取费用不能为空" }],
        payFeeAmount: [{ required: true, message: "支付费用不能为空" }]
      },
      stateModal: "新增", //默认状态
      taskId: "",
      replaceList: [], //补办项目
      paperContent:'',
      projectType:false,//新增补办是否要添加input
      whichSidelistId:[],//受益方下拉查询
      selectlist:[],//选中的补办项
      numCount:0,//费用合计
      userData:{},
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
      dialogVisibleTab:false,
      procedureListSelect:[],//选中的数据
      procedureList1:[],//待补办
      procedureList2:[],//补办中
      selectlist1:[],
      selectlist2:[],//选中
      supplierList:[{id:1,name:'客户',value:'customer'},{id:2,name:'商户',value:'merchant'},{id:3,name:'车开新',value:'ckx'},{id:4,name:'办证公司',value:'cert_com',children:[]}],//供应商列表,//受益方下拉查询
      supplierList1:[{id:1,name:'客户'},{id:2,name:'商户'},{id:3,name:'车开新'},{id:4,name:'办证公司',children:[]},{id:5,name:'违章供应商',children:[]}],//供应商列表,//受益方下拉
      props:{
        label:'name',
        value:'id',
        children:'children'
      }
    };
  },
  created() {
    //软电话是否登陆
    // this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;

    if(this.$route.query.id) this.taskId = this.$route.query.id;
    this.whichSidelistId=this.$store.state.setting.whichSidelistId;
    this.getCertificateTaskDetails(); //获取详情

    this.postSupplierList();//获取供应商
  },
  methods: {
     changeSupplie(type){
      if(type===1){//1为补办下单，2为form表单
        let node=this.$refs.refcascaderT.getCheckedNodes();
        if(node.length>0){
          if(node[0].parent){
            this.forms.certProcedureReplacer=node[0].parent.data.value;
            this.forms.cprCertRuleSupplierId=node[0].path[1];
            this.forms.cprCertRuleSupplierName=node[0].pathLabels[1];
          }else{
            this.forms.certProcedureReplacer=node[0].data.value;
          }
          
        }
      }else{
        let node=this.$refs.refcascader.getCheckedNodes();
        if(node.length>0){
          this.forms.feeBearerId=node[0].path[0];
          if(node[0].path[1]){
            this.forms.bearerCertRuleSupplierId=node[0].path[1]
            this.forms.bearerCertRuleSupplierName=node[0].pathLabels[1]
          }
        }
      }
    },
    onSelect(row){
      if(row.feeBearerId){
         this.forms.feeBearerId= row.feeBearerId;
        this.forms.feeBearerObj= row.feeBearerId;
      }else{
         this.forms.feeBearerObj= '';
      }
      if(row.itemName.indexOf('补办')!=-1||['机动车行驶证','车辆牌照'].includes(row.itemName)){
        this.forms.nature='补办'
      }else if(row.itemName.indexOf('变更')!=-1){
        this.forms.nature='需变更'
      }
      if(row.payFeeAmount) this.forms.payFeeAmount= row.payFeeAmount;
      if(row.chargingFeeAmount) this.forms.chargingFeeAmount= row.chargingFeeAmount;
      if(row.itemName) this.forms.name=row.itemName;
    },
    oncalll: function(row,phoneType) {
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row,phoneType)
      // })//根据员工工号查询坐席信息(拨打电话)
      
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(customerNumber,phoneType){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      if(phoneType){
        query.phoneType=phoneType;
        query.customerId=this.form.carCustomer.id;
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    onAdd(formdata) {
      this.ondialogVisible();
      this.stateModal = "新增";
      for(let key in this.forms){
        this.forms[key]=""
      }
      this.$nextTick(()=>{
        if (this.$refs[formdata]) {
          this.$refs[formdata].clearValidate();
        }
      })
      this.$forceUpdate()
    },
    unpdate(row,state) {
      switch(state){
        case 1://补办下单
          this.dialogVisibleTab=true;
          this.procedureListSelect=JSON.parse(JSON.stringify([row]))
          this.$nextTick(()=>{
            if(this.procedureListSelect){
              this.procedureListSelect.map(item => {
                this.$refs.multipleTable.toggleRowSelection(item);
              });
            }
          })
          break;
        case 2://编辑
          this.stateModal = "修改";
          this.forms= JSON.parse(JSON.stringify(row))
          this.forms.obj=row.name;
          if(row.bearerCertRuleSupplierId){
            this.forms.feeBearerObj=row.feeBearerId?[row.feeBearerId,row.bearerCertRuleSupplierId]:[];
          }else{
            this.forms.feeBearerObj=row.feeBearerId?[row.feeBearerId]:[];
          }
          
          this.ondialogVisible();
          break;
        case 3://删除
          this.$confirm('是否确认删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$api.postProcedureDelete(row.id).then(res=>{
              if(res.code==0){
                this.$message.success(res.message)
                this.getCertificateTaskDetails(); //获取详情
              }
            })
          }).catch(() => {});
          break;
        case 4://补办完成
          this.$confirm('确认补办已完成！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postTaskOperaProcedure([row.id],'procedure_finish')
          }).catch(() => {});
          break;
        case 5://退回待补办
          this.$confirm('确认退回吗！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postTaskOperaProcedure([row.id],'procedure_reject')
          }).catch(() => {});
          break;
      }
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitform(formdata, state) {
      //提交frorm
      this.$refs[formdata].validate(valid => {
        if (valid) {
          if (state == "新增") {
            this.postProcedureMaterialAdd();
          }else{
            this.postProcedureUpdate();
          }
        } else {
          return false;
        }
      });
    },
    selectChange(rows,type){
      switch(type){
        case 1://待补办项目
          this.selectlist1=rows;
          break;
        case 2://补办中项目
          this.selectlist2=rows;
          break;
        case 3://确认下单弹出框补办要求
          this.selectlist=rows;
          break;
      }
      
      // this.numCount = rows.reduce((prev, next) => {
      //   return prev + next.chargingFeeAmount;
      // }, 0);
    },
    onrouterPage() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/accreditationCommissioner/vehicleFormalities"
      });
    },
    onrouter(state) {
      //跳转客户商户合同
      let aurl = "/salesManager/contractMaking";
      let id=this.form.customerContract?this.form.customerContract.id:'';//客户合同
      if (state) {
        aurl = "/salesAssistant/merchantContract";
        id=this.form.merchantContract?this.form.merchantContract.id:'';//商户合同
      }
      if(!id){
        this.$message('暂无合同!')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    getCertificateTaskDetails() {
      //获取详情
      this.$api.getCertificateTaskDetails(this.taskId, {}).then(res => {
        if (res) {
          if (res.code == 0) {
            //1待补办-补办手续，2补办中-查看，3补办完成-查看
            switch(res.data.taskProcedureStatus){
              case 81://81待补办 82补办中 83补办完成
              case 82:
                this.state=1;
                var arrPro=[];
                let arrPro1=[];
                if(res.data.materialProcedureList){
                  for(var i=0;i<res.data.materialProcedureList.length;i++){
                    var item=res.data.materialProcedureList[i]
                    if(item.status===81){
                      arrPro.push(item)
                    }else if(item.status===82){
                      arrPro1.push(item)
                    }
                  }
                }
                this.procedureList1=arrPro;//车辆材料列表 
                this.procedureList2=arrPro1;//车辆材料列表 
                break;
              case 83://81待补办 82补办中 83补办完成
                this.state=2;
                this.procedureList=res.data.materialProcedureList;//车辆材料列表 
                break;
            }
            this.res=JSON.parse(JSON.stringify(res.data));
            this.form = JSON.parse(JSON.stringify(res.data.car));
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            if(res.data.car.customerContract){
              this.customerContract=res.data.car.customerContract;
            }
            this.getReplaceItem(this.form.isHuPlateName); //获取补办项目
          }
        }
      });
    },
    getReplaceItem(plateType) {
      //查询补办项目状态
      let query={
        plateType:plateType,
        types:[2]
      }
      this.$api.postFeeStandardList(query).then(res => {
        if (res) {
          if (res.code == 0) {
            this.replaceList = res.data;
          }
        }
      });
    },
    postProcedureMaterialAdd(){//新增补办
      let query={
        "name": this.forms.name,//补办项目ID
        "carId": this.form.id,
        "feeBearerId": this.forms.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
        "chargingFeeAmount": this.forms.chargingFeeAmount,//收取费用金额
        bearerCertRuleSupplierId:this.forms.bearerCertRuleSupplierId,//费用承担方供应商ID
        bearerCertRuleSupplierName:this.forms.bearerCertRuleSupplierName,//费用承担方供应商名字
        // "beneficiaryName": "xx",
        // "payFeeAmount": this.forms.payFeeAmount,//支付费用金额
        // certCompanyId:this.forms.certCompanyId,
        nature:this.forms.nature
      }
      this.$api.postProcedureMaterialAdd(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.ondialogVisible();
            this.getCertificateTaskDetails();
          }
        }
      })
    },
    onOrder(state){//确认下单
      switch(state){
        case 1://批量补办下单
          if(this.selectlist1.length==0){
            this.$message.warning('请选择需要下单的补办项！')
            return
          }
          for(let i=0;i<this.selectlist1.length;i++){
            let item=this.selectlist1[i]
            if(!item.nature){
              this.$message.warning('补办要求不可为空，请编辑保存后再操作！')
              return
            }
          }
          
          this.dialogVisibleTab=true;
          this.procedureListSelect=JSON.parse(JSON.stringify(this.selectlist1))
          this.$nextTick(()=>{
            if(this.procedureListSelect){
              this.procedureListSelect.map(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
              });
            }
          })
          break;
        case 2://批量补办完成
          var arr1=[]
          this.selectlist2.filter(x=> {arr1.push(x.id)})
          if(this.selectlist2.length==0){
            this.$message.warning('请选择需要补办完成的补办项！')
            return
          }
          this.$confirm('确认补办已完成！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postTaskOperaProcedure(arr1,'procedure_finish')
          }).catch(() => {});
          break;
        case 3://退回待补办
          var arr=[]
          this.selectlist2.filter(x=> {arr.push(x.id)})
          if(this.selectlist2.length==0){
            this.$message.warning('请选择需要补办完成的补办项！')
            return
          }
          this.$confirm('确认退回吗！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postTaskOperaProcedure(arr,'procedure_reject')
          }).catch(() => {});
          break;
      }
      
    },
    postTaskOperaProcedure(arrId,state){//
      let query={
        taskId:this.taskId,
        "taskProcedureResult": state,
        // certProcedureReplacer:this.forms.certProcedureReplacer,
        certMaterialProcedureIds:arrId,
      }//手续补办操作【确认补办/提交 procedure_confirm, 补办完成 procedure_finish, 补办退回 procedure_reject】
      this.$api.postTaskOperaProcedure(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.getCertificateTaskDetails(); //获取详情
            this.$message.success(res.message)
          }
        }
      })
    },
    confirmOrder(){//确认下单
      let arr=[]
      this.procedureListSelect.filter(x=> {arr.push(x.id)})
      if(this.procedureListSelect.length==0){
        this.$message('请选择需要下单的补办项！')
        return
      }
      let query={
        taskId:this.taskId,
        "taskProcedureResult": "procedure_confirm",
        certProcedureReplacer:this.forms.certProcedureReplacer,
        certMaterialProcedureIds:arr,
        cprCertRuleSupplierId:this.forms.cprCertRuleSupplierId,//办证材料手续补办方供应商ID
        cprCertRuleSupplierName:this.forms.cprCertRuleSupplierName,//办证材料手续补办方供应商名字
      }//手续补办操作【确认补办/提交 procedure_confirm, 补办完成 procedure_finish, 补办退回 procedure_reject】
      this.$api.postTaskOperaProcedure(query).then((res)=>{
        if(res){
          if(res.code==0){
            // this.onrouterPage()
            this.dialogVisibleTab=false;
            this.getCertificateTaskDetails(); //获取详情
            this.$message.success(res.message)
          }
        }
      })
    },
    postProcedureUpdate(){//修改补办项
      let query={
        "name": this.forms.name,//补办项目ID
        "carId": this.form.id,
        "feeBearerId": this.forms.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
        "chargingFeeAmount": this.forms.chargingFeeAmount,//收取费用金额
        // "beneficiaryName": "xx",
        // "payFeeAmount": this.forms.payFeeAmount,//支付费用金额
        // certCompanyId:this.forms.certCompanyId,
        nature:this.forms.nature
      }
      this.$api.postProcedureUpdate(this.forms.id,query).then(res => {
        if (res) {
          if (res.code == 0) {
            this.ondialogVisible();
            this.getCertificateTaskDetails();
            this.$message.success(res.message)
          }
        }
      });
    },
    postSupplierList(){//供应商列表
      this.$api.postSupplierList({}).then(res=>{
        if(res.code==0){
          for(let i=0;i<this.supplierList.length;i++){
            let item=this.supplierList[i]
            if(['办证公司','违章供应商'].includes(item.name)){
              item.children=res.data;
            }
          }
          for(let i=0;i<this.supplierList1.length;i++){
            let item=this.supplierList1[i]
            if(['办证公司','违章供应商'].includes(item.name)){
              item.children=res.data;
            }
          }
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.vehicleFormBox {
  padding: 30px 40px 150px;
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    .content_title_row {
      // flex: 1;
      label {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .content_title_space {
    .content_title_row {
      width: 25%;
      margin-bottom: 10px;
    }
  }
  .flex_space {
    display: flex;
    justify-content: space-between;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
}
</style>
