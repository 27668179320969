<template>
  <div class="listKST">
    <el-row :gutter="20">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>客户编号: </span>
        <el-input clearable v-model="params.customerNo" placeholder="请输入" type="text" @keyup.enter.native="search"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>车牌号码: </span>
        <el-input clearable v-model="params.carNo" placeholder="请输入" type="text" @keyup.enter.native="search"></el-input>
      </el-col>
      <el-col :span="12" class="flex flex_align_center flex_between w4">
        <span>创建时间: </span>
        <el-date-picker clearable v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:20px;">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>是否入库:</span>
        <el-select clearable v-model="params.isPutStorage" placeholder="请选择" @change="search">
          <el-option v-for="item in isPutStorageOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>处理人:</span>
        <el-select clearable v-model="params.createUserId" placeholder="请选择" @change="search">
          <el-option v-for="item in createUserIdOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="12" style="text-align:right;">
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table  border stripe class="table-mainbg" :data="KSTList" size="small" style="width: 100%;margin-top:30px">
      <el-table-column prop="customerNo" label="客户编号" align="center" width="130"/>
      <el-table-column prop="customerName" label="客户名称" align="center" width="115px"/>
      <el-table-column prop="channel" label="渠道入口" align="center"/>
      <el-table-column prop="carNo" label="车牌号码" width="100" align="center"/>
      <el-table-column prop="customerType" label="客户类型" align="center" :formatter="formatCType"/>
      <el-table-column prop="createDate" label="创建时间" width="180" align="center"/>
      <el-table-column prop="isPutStorage" label="是否入线索库" align="center" :formatter="formatIsPutStorage"/>
      <el-table-column prop="isRepeat" label="是否重复" align="center" width="115px">
        <template slot-scope="scope">{{scope.row.isRepeat===1?'是':scope.row.isRepeat===0?'否':scope.row.isRepeat}}</template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      <el-table-column prop="createUserName" label="处理人" align="center" width="115px"/>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      params: {
        customerNo: '',
        carNo: '',
        createTime: '',
        isPutStorage: '',
        createUserId: ''
      },
      isPutStorageOptions: [
        {label:'是', value:1},
        {label:'否', value:0}
      ],
      createUserIdOptions: [],
      KSTList: []
    }
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getData()
    },
    getData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        createUserId: this.params.createUserId,
        customerNo: this.params.customerNo,
        carNo: this.params.carNo,
        isPutStorage: this.params.isPutStorage,
        createStartTime: '',
        createEndTime: '',
        clueId: 2
      }
      if(this.params.createTime && this.params.createTime.length > 0){
        params.createStartTime = this.params.createTime[0];
        params.createEndTime = this.params.createTime[1];
      }else{
        params.createStartTime = '';
        params.createEndTime = '';
      }

      this.$api.customerQueryClueCustomer(this.$$.delete_null_properties(params)).then(res => {
        if(res.code == 0){
          this.page_total = res.data.total;
          this.KSTList = res.data.records;
        }
      })
    },
    formatCType(row) {
      return this.$$.arrayLookup(this.$store.state.setting.customerTypeList, row.customerType)
    },
    formatIsPutStorage(row) {
      if(row.isPutStorage === 1){
        return '是'
      }else if(row.isPutStorage === 0){
        return '否'
      }else{
        return ''
      }
    },
    search() {
      this.page_index = 1;
      this.getData();
    },
    goCustomer(followUpId) {
      if(followUpId){
        this.$router.push({path: '/onlineService/customer', query: {followUpInfoId: followUpId}})
      }else{
        this.$message.warning('客户跟进业务ID缺失')
      }
    },
    queryUserListByRoleAndDepart(){//根据角色和部门查询用户
      this.$api.queryUserListByRoleAndDepart({roleName:'在线客服'}).then( res=>{
        if(res.code == 0){
          this.createUserIdOptions = res.data;
        }
      })
    },
  },
  created() {
    this.search();
    this.queryUserListByRoleAndDepart();
  }
}
</script>

<style scoped lang="scss">
.listKST{
  padding: 30px 50px;
}
</style>