<template>
  <div class="certificateMaterBox">
    <el-tabs v-model="tabClassify" type="card" @tab-click="init(1)">
      <el-tab-pane label="中间退费确认列表" name="first">
        <el-row :gutter="24" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>退费状态</span>
            <el-select v-model="forms.financePayStatuses" multiple clearable>
              <!-- <el-option label="全部" value="" ></el-option> -->
              <el-option
                v-for="item in paymentStatuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="flex flex_align_center flex_between w4">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button
            >
          </el-col>
          <el-col :span="3" class="flex flex_align_center flex_between w4">
            <el-button type="primary" @click="onBatchPayment" size="medium"
              >批量付款</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="querylist"
          border
          stripe :row-key="getRowKeys" 
          class="table-mainbg mt20"
          @selection-change="selectChange"
        >
          <el-table-column
            type="selection"
            label="选择" :reserve-selection="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="fadeFeeSerialNum"
            label="退费序号"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="depositYetBackAmountApply"
            label="退费金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fadeFeeDesc"
            label="退费说明"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="applyTime"
            label="申请时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="financePayTime"
            label="退费时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="applyName"
            label="退费操作人"
            align="center"
          ></el-table-column>
          <el-table-column prop="financePayStatus" label="退费状态"  align="center">
            <template slot-scope="scope">{{scope.row.financePayStatus==31?'待付款':scope.row.financePayStatus==32?'付款中':scope.row.financePayStatus==33?'付款成功':'付款失败'}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="160px">
            <template slot-scope="scope">
              <el-button type="primary" @click="onapply(scope.row)" size="mini" plain>打印</el-button>
              <el-button type="primary" @click="onRowClick(scope.row,0)" size="mini" plain>查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="批量付中间退费列表" name="second">
        <el-row :gutter="20" class="mt30">
          <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>批量支付日期</span>
            <el-date-picker
              v-model="forms1.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w5">
            <span>批量支付状态</span>
            <el-select v-model="forms1.batchPayStatus" clearable>
              <!-- <el-option value="">请选择状态</el-option> -->
              <el-option
                v-for="item in BatchPayStatuslist"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="flex flex_align_center flex_between w4">
            <el-button type="primary" @click="init(1)" size="medium" plain
              >查询</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="querylist1"
          border
          stripe
          class="table-mainbg mt20"
        >
          <el-table-column
            prop="batchPayOrderNo"
            label="批次编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayAmount"
            label="批量支付金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayTime"
            label="批量支付日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batchPayStatus"
            label="批量支付状态"
            align="center"
          >
            <template slot-scope="scope">{{scope.row.batchPayStatus==32?'付款中':scope.row.batchPayStatus==33?'付款成功':'付款失败'}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100px">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRowClick(scope.row,1)" size="mini" plain>查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="退费确认"
      :visible.sync="dialogVisible" class="modalBox">
      <el-form ref="form" :model="form" label-width="120px" size="mini" class="modalForm" id="printMe">
        <div class="ckxTitle">合同信息</div>
        <el-form-item label="客户合同编号:">
          <p class="text_decoration">{{form.customerContractNo}}</p>
           <!-- <el-input v-model="form.customerContractNo" class="text_decoration"></el-input> -->
        </el-form-item>
        <el-form-item label="车主姓名:">
          <p class="text_decoration">{{form.customerName}}</p>
        </el-form-item>
        <el-form-item label="车牌号:">
          <p class="text_decoration">{{form.carNo}}</p>
        </el-form-item>
        <el-form-item label="收款账号名:">
          <p class="text_decoration">{{form.accountName}}</p>
        </el-form-item>
        <el-form-item label="开户行:">
          <p class="text_decoration">{{form.depositBank}}</p>
        </el-form-item>
        <el-form-item label="账号:">
          <p class="text_decoration">{{form.account}}</p>
        </el-form-item>
        <div class="ckxTitle">退费信息</div>
        <el-form-item label="退费序号:">
          <p class="text_decoration">{{form.fadeFeeSerialNum}}</p>
        </el-form-item>
        <el-form-item label="退费金额:">
          <p class="text_decoration">{{form.depositYetBackAmountApply}}</p>
        </el-form-item>
        <el-form-item label="退费方式:">
          <p class="text_decoration">{{form.payType==2?'同城同行':form.payType==3?'异地':form.payType==4?'同城对公':'超级网银'}}（为空默认为网银支付）</p>
          <!-- <el-input v-model="form.customerContractNo" class="text_decoration"></el-input>（为空默认为网银支付） -->
        </el-form-item>
        <el-form-item label="客户账户:">
          <p class="text_decoration">{{form.account}}（为空则默认客户合同中银行账号）</p>
        </el-form-item>
        <el-form-item label="退费说明:">
          <p class="text_decoration">{{form.fadeFeeDesc}}</p>
        </el-form-item>
        <el-form-item label="退费提示:">
          <p class="text_decoration">客户风险押金：{{form.riskDepositTotalAmount}}(元)</p>
          <!-- <p class="text_decoration">客户风险押金：{{form.riskDepositTotalAmount}}(元)&nbsp;&nbsp;已退费金额：{{form.depositYetBackAmountYes}}(元)</p> -->
        </el-form-item>
        <el-form-item label="退费结果:">
          <p class="text_decoration">{{form.financePayStatus==33?'付款成功':form.financePayStatus==34?'付款失败':form.financePayStatus==31?'待付款':form.financePayStatus==32?'付款中':form.financePayStatus}}</p>
        </el-form-item>
        <el-form-item label="失败原因:" v-if="form.financePayStatus==34">
          <p class="text_decoration">{{form.bankRspTips}}</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" v-print="'#printMe'">打印</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import print from 'vue-print-nb'
export default {
  directives: {
    print   
  },
  data() {
    return {
      tabClassify: "first",
      querylist: [], //
      querylist1: [], //
      forms: {
        data: "",
        financePayStatuses:[31,32],
      },
      form:{},
      forms1:{},
      total: 0,
      total1: 0,
      currentPage: 1,
      currentPage1: 1,
      pageSize: 10,
      pageSize1: 10,
      paymentStatuslist: this.$store.state.setting.paymentStatuslist,
      BatchPayStatuslist: this.$store.state.setting.BatchPayStatuslist,
      selectlist: [],
      dialogVisible:false,
    };
  },
  created() {
    if(this.$route.params.state) this.forms.financePayStatuses=[Number(this.$route.params.state)];
    this.init();
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    init(page) {
      if (this.tabClassify == "first") {
        this.postMiddleQuery(page)//中间退费条件查询
      } else {
        this.postMiddleBatchQuery(page)
      }
    },
    onapply(row) {
      this.itemRow=JSON.parse(JSON.stringify(row));
      this.getDetailsByFeeId(row.id)
    },
    handleSizeChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.pageSize = data;
          this.currentPage=1;
          break;
        case "second":
          this.pageSize1 = data;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(data) {
      switch (this.tabClassify) {
        case "first":
          this.currentPage = data;
          break;
        case "second":
          this.currentPage1 = data;
          break;
      }
      this.init();
    },
    onRowClick(row, state) {
      let query={};
      if(state==0){
        query.middleFadeFeeId = row.id
      }else{
        query.batchPayOrderNo=row.batchPayOrderNo;
      }
      this.$router.push({
        path: "/finance/intermediateRefundDetail",
        query: query,
      });
    },
    onBatchPayment() {
      let arr=[];
      let payType=false;//当前是否有单存在付款完成
      for(let i=0;i<this.selectlist.length;i++){
        if(this.selectlist[i].financePayStatus!=31){
          payType=true;
        }
        arr.push(this.selectlist[i].carNo)
      }
      if(payType){
        this.$message.warning('当前订单中存在不需要付款的数据，请确认再付款！');
        return
      }
      if (this.selectlist.length == 0) {
        this.$message("请选择需要批量付款的合同！");
        return;
      }
      this.$store.dispatch('setting/setIntermediateRefundList',this.selectlist); 
      let aurl = "/finance/intermediateRefundDetail";
      this.$router.push({
        path: aurl,
        query: {
          carNos:arr
        },
      });
    },
    selectChange(rows) {
      this.selectlist = rows;
      // console.log(rows,'----rrr--')
    },
    postMiddleQuery(page){//中间退费条件查询
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
        "carNo": this.forms.carNo,
        "financePayStatuses": this.forms.financePayStatuses.length>0?this.forms.financePayStatuses:[31,32,33,34]//财务付款状态，31待付款，32付款中，33付款成功，34付款失败
      }
      this.$api.postMiddleQuery(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist = res.data.records;
          this.total = res.data.total;
        } else {
          this.querylist = [];
          this.total = 0;
        }
      })
    },
    postMiddleBatchQuery(page){//中间退费批量条件查询
      this.currentPage1=page?page:this.currentPage1;
      let query={
        "curPage": this.currentPage1,
        "batchPayBeginTime": this.forms1.date?this.forms1.date[0]+' 00:00:00':'',
        "pageSize": this.pageSize1,
        "batchPayEndTime":this.forms1.date?this.forms1.date[1]+' 23:59:59':'',
        "carNos": this.forms1.carNo?[this.forms1.carNo]:'',
        "batchPayStatuses": this.forms1.batchPayStatus?[this.forms1.batchPayStatus]:[32,33,34]
      }
      this.$api.postMiddleBatchQuery(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist1 = res.data.records;
          this.total1 = res.data.total;
        } else {
          this.querylist1 = [];
          this.total1 = 0;
        }
      })
    },
    getDetailsByFeeId(id){//根据feeId查询详情(单个)
      this.$api.getDetailsByFeeId(id).then((res)=>{
        if(res){
          if(res.code==0){
            this.dialogVisible=!this.dialogVisible;
            this.form=res.data;
          }
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.certificateMaterBox {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
  .modalForm{
    .el-form-item{
      margin-bottom:0;
    }

  }
}
</style>
<style >
.el-dialog__body{
  padding:20px
}
</style>