<template>
  <!-- 数据维护 -->
  <div class="contenPageBox" >
    <div class="ckxTitle">客户门店归属变更</div>
    <div class="flex flex_align_center mt15">
      <span>变更前门店归属</span>
      <el-select v-model="oldShopObj" placeholder="请选择变更前门店归属" class="w300 ml10 mr10" size="medium" value-key="id" @change="changeShop($event,1)" filterable>
        <el-option v-for="item in shoplist" :key="item.id" :value="item" :label="item.shopName"></el-option>
      </el-select>
      <span class="mr10 tipsColor">变更后门店归属:</span>
      <el-select v-model="newShopObj" placeholder="请选择变更前门店归属" class="w300 ml10 mr10" size="medium" value-key="id" @change="changeShop($event,2)" filterable>
        <el-option v-for="item in shoplist" :key="item.id" :value="item" :label="item.shopName"></el-option>
      </el-select>
      <el-button plain size="medium" type="primary" @click="onSubmit(1)" class="ml10">提交</el-button>
    </div>
    

    
    <el-row :gutter="24">
      <el-col :xl="24" :lg="24"></el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{
        oldShopId:'',
        newShopId:'',
      },
      oldShopObj:{},
      newShopObj:{},
      shoplist:[],
      // channelArr:[],//渠道
      // channellist:[],
      // channeProp:{
      //   label:'channelName',
      //   value:'id',
      //   children:'channelSubs'
      // },
      // resForm:{
      //   customerId:'',
      //   oldChannelId:''
      // },
    }
  },
  created(){
    this.getShopList()//客户门店列表
  },
  methods: {
    onSubmit(state){
      switch(state){
        case 1://客户门店归属变更
          if(!this.form.oldShopId||!this.form.newShopId){
            this.$message.warning('请选择门店归属！')
            return
          }
          this.$confirm(`你确定将所有门店归属为${this.oldShopObj.shopName}变更为${this.newShopObj.shopName}吗！`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postAlterShopOwner()//修改门店归属
          }).catch(() => {});
          break;
        // case 2://批量恢复被放弃的客户
        //   if(!this.form.customerNos){
        //     this.$message.warning('请输入恢复被放弃的客户编号！')
        //     return
        //   }
        //   this.$confirm('请确认是否恢复被放弃的客户?', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //     this.postGiveUpRecover()
        //   }).catch(() => {});
        //   break;
        // case 3:
        //   this.routePage()
        //   break;
      }
    },
    changeShop(e,state){
      switch(state){
        case 1:
          this.form.oldShopId=e.id;
          break;
        case 2:
          this.form.newShopId=e.id;
          break;
      }
    },
    postAlterShopOwner(){//修改门店归属
      let query={
        newShopId:this.form.newShopId,
        oldShopId:this.form.oldShopId,
      }
      this.$api.postAlterShopOwner(query).then(res=>{
        if(res.code==0){
          this.form.newShopId='';
          this.form.oldShopId='';
          this.oldShopObj={};
          this.newShopObj={};
          this.$message.success(res.message)
        }
      })
    },
    getShopList(){//搜索门店
      if(this.shoplist.length>0){
        return
      }
      let query={
        isEnabled:0,
        shopTypeList:[0]// 门店类型。0客户成交1商户成交2售后服务3其它。商户成交门店可以约预约成交的车
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
    // routePage(){
    //   this.bus.$emit("closeSelected");
    //   this.$router.push({
    //     path:'/salesAssistant/signContract'
    //   })
    // },
  }
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px 140px;
  

}
</style>