<template>
  <div class="pageContainer bgF5" ref="pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
    <!-- <kpi :kapilist="kapilist" ref="kpi"></kpi>
    <div class="title_top_nameBox mt30 mb20">数据看版</div>
    <div class="dataBoard">
      <h4>当月签约客户数明细</h4>
      <table>
        <tr>
          <td>日期</td>
        </tr>
        <tr>
          <td>签约客户数</td>
        </tr>
      </table>
      <h4>成交转化率排名</h4>
      <el-table
        :data="dealNumberList"
        border>
        <el-table-column
          prop="date"
          :label="item.name" v-for="item in dealNameList" :key="item.id" align="center">
        </el-table-column>
      </el-table>
    </div> -->
  </div>
</template>

<script>
import task from "@/components/task/task";
// import kpi from "@/components/kpi/kpi";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
      // kapilist:[{
      //   title:'​截至目前成交转化率',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',//计算了百分比之后的值
      //   color:'#0099FF',
      //   colorbg:'#CAE9FF'
      // },{
      //   title:'当月截止目前成交客户数',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',
      //   color:'#1FC678',
      //   colorbg:'#A5E7CE'
      // }],//KPI指标
      // dealNameList:[],//成交转化率名称
      // dealNumberList:[],//值
    };
  },
  created() {
    // this.dealNameList.push({id:0,name:'排名'})
    // for(let i=1;i<11;i++){
    //   let num=this.$$.noToChinese(i)
    //   if(num=='一十'){
    //     num='十'
    //   }
    //   let obj={
    //     id:i,
    //     name:`第${num}名`
    //   }
    //   this.dealNameList.push(obj)
    // }
    let newArr1=[{
            num: '',
            name: '差异待评定',
            star: 0,
            url:'/storeManager/difEvaluation'
          }]
    this.infolist=[{name:"",list:newArr1}];
    let userData=this.$store.state.user.userData;
    let shop=this.$$.roleName(userData,'department')
    if(shop){
      if(shop.shop) this.init(shop.shop.id)
    }else{
      this.$router.push({
        path:'/role'
      })
    }
  },
  methods: {
    init(shopId) {
      this.$api.postDiffEvaluateCount(shopId).then((res) => {
        if (res.code == 0) {
          
          this.infolist[0].list[0].num=res.data;
          // console.log(this.infolist,'-----555---')
          //  this.kapilist[0].targetVal=res.data.validQuotationTargetCount;//目标值
          // this.kapilist[0].actualVal=res.data.validQuotationCount;//实际值
          // this.kapilist[0].value=(res.data.validQuotationCount/res.data.validQuotationTargetCount*10000)/100;//计算了百分比之后的值
          // this.kapilist[1].targetVal=res.data.netGrowthTargetCount;//目标值
          // this.kapilist[1].actualVal=res.data.netGrowthCount;//实际值
          // this.kapilist[1].value=(Number(res.data.netGrowthCount)/Number(res.data.netGrowthTargetCount)*10000)/100;//计算了百分比之后的值
          // this.$refs.kpi.initChart('charid0',this.kapilist[0]);
          // this.$refs.kpi.initChart('charid1',this.kapilist[1]);
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
    // kpi
  }
};
</script>

<style lang="scss" scoped>
.dataBoard{
  background:#ffffff;
  text-align: center;
  padding: 20px;
  h4{
    font-size: 16px;
    padding: 20px 0;
  }
}

</style>
