<template>
  <div class="conterPage">
    <div>收款单号：{{res.orderNo}}</div>
    <div class="ckxTitle mt10">车主信息:</div>
    <div class="mt15 mb15">姓名：{{res.customerName}}</div>
    <div class="ckxTitle mb15">车辆信息:</div>
    <el-row :gutter="24">
      <el-col :span="5">车辆号牌：{{res.carNo}}</el-col>
      <el-col :span="5">委托出售价：{{res.carTradedPrice?res.carTradedPrice:'-'}}元</el-col>
      <el-col :span="5">状态：{{res.financeGatheringStatusName}}</el-col>
      <el-col :span="7">意向协议签订时间：{{res.dealSignTime}}</el-col>
    </el-row>
    <div class="ckxTitle mt15">交易失败原因</div>
    <div class="mb15 mt15">{{res.remark?res.remark:'-'}}</div>
    <div class="pb100">
      <div class="ckxTitle">待收款项</div>
      <el-table :data="querylist" border stripe class="table-mainbg mt20">
        <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="intentionMoney" label="金额（元）" align="center" ></el-table-column>
        <el-table-column prop="payTypeName" label="状态" align="center"></el-table-column>
        <el-table-column prop="financeGatheringTime" label="收款完成时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" v-if="res.financeGatheringStatus!=93&&res.financeGatheringStatus!=94">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="onOper(scope.row)">收款</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onReturn" >返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state:'',//值为1表示查看页面
      querylist: [],//报价列表
      payId:'',//列表传过来的id
      res:{},//当前页面的详情
    }
  },
  created(){
    if(this.$route.query.id) this.payId = Number(this.$route.query.id);
    if(this.$route.query.state) this.state = this.$route.query.state
    this.getDetailsByPayId(this.payId)
  },
  methods: {
    getDetailsByPayId(id){//根据商户成交预约Id获取商户预约详情
      this.$api.getDetailsByPayId(id).then(res=>{
        if(res.code==0){
          this.res=res.data;
          this.querylist=[{
            intentionMoney:res.data.intentionMoney,
            payTypeName:res.data.payTypeName,
            financeGatheringTime:res.data.financeGatheringTime,
            carNo:res.data.carNo,
          }]
        }
      })
    },
    onOper(row){
      this.$confirm(`确认车牌号${row.carNo}返还意向金已收到`, '收款确认', {
          confirmButtonText: '收到',
          cancelButtonText: '没有收到',
          type: 'warning'
        }).then(() => {
          this.postFinanceConfirmGathering(1)
        }).catch(() => {
          this.postFinanceConfirmGathering(2)
        });
    },
    postFinanceConfirmGathering(operaType){//1财务确认收款成功,2财务确认收款失败
      this.$api.postFinanceConfirmGathering(operaType,this.payId).then(res=>{
        if(res.code==0){
          this.getDetailsByPayId(this.payId)
        }
      })
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/intentionCollection',
        // query:{
        //   state:this.stateType
        // }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.conterPage{
  padding: 20px 40px 100px;
  // .label{width: 120px; text-align: right;display: inline-block;}
  // .merchantInfo>div:nth-child(1){width: 45%;}
  // .merchantInfo>div:nth-child(2){width: 55%;}
  // .merchantInfo>div:nth-child(2) .label{width: 140px;}
  // .mt30 {margin-top: 30px;}
}
</style>