<template>
  <!-- 次日外勤预约单 -->
  <div class="nextDayFieldDRList">
    <div class="ckxTitle mb20">次日外勤预约单</div>
    <el-table v-loading="loading" :data="tableData" size="small" style="width: 100%;" border class="table-mainbg" stripe>
      <el-table-column type="index" label="序号" align="center"/>
      <el-table-column prop="checkBookNo" label="预约单号" align="center"/>
      <el-table-column prop="carNum" label="车辆数" align="center"/>
      <el-table-column prop="submitTime" label="预约单提交时间" align="center"/>
      <el-table-column prop="isOuter" label="是否外环外" align="center"/>
      <el-table-column :formatter="formatAddress" label="预约地址" align="center"/>
      <el-table-column :formatter="formatBookTime" label="预约时间" align="center"/>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableData: [],
    }
  },
  created() {
    this.checkBookQueryFieldBook()//获取外勤预约单
  },
  methods: {
    checkBookQueryFieldBook() {
      this.$api.checkBookQueryFieldBook(
        {bookDay: this.$$.getFormatMomentTime('YYYY-MM-DD', new Date(Date.now()+86400000))}
      ).then(res => {
        if(res.code == 0){
          this.tableData = res.data
        }
      })
    },
    formatBookTime(row) {
      return row.bookDay + '-' + row.bookTime;
    },
    formatAddress(row) {
      return row.pcrRegion + row.bookAddress;
    }
  }
}
</script>

<style scoped lang="scss">
.nextDayFieldDRList{
  padding: 20px 40px;
}
</style>