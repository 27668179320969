<template>
  <div class="currencyConterBox">
    <div class="inventBox">
      <template v-if="stateType==0">
      <!--账户信息-->
      <div class="content_modal_textBox">
        <div class="head_top_title">
            <i></i>
            <span>账户信息</span>
          </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>合作店简称</td>
              <td>{{form.channelShorName}}</td>
              <td>收款人</td>
              <td class="wordWrap">{{form.accountName}}</td>
              <td>账号</td>
              <td class="wordWrap">{{form.account}}</td>
              <td>银行简称</td>
              <td>{{form.depositBankShortName}}</td>
              <td>开户行</td>
              <td>{{form.depositBank}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--提现-->
      <div class="content_modal_textBox mt30">
        <div class="head_top_title">
          <i></i>
          <span>提现</span>
        </div>
        <div>
          <p class="mb10">提现金额（大写）：<span class="text_decoration">{{form.amountCn}}（￥：{{form.amount}}）</span> </p>
          <template v-if="paymentState==31">
          <p class="mt15">付款方式：
            <el-select v-model="payTypeState" class="w300" clearable>
              <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </p>
          <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
            <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
          </template>
          <template v-if="[33,34].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
          <p class="tipsColor mt15" v-if="paymentState===31">当前余额（元）：{{form.availableBalance}}</p>
          <!-- <el-row :gutter="24" class="mt20">
            <el-col :span="8" class="flex flex_align_center flex_between w6">
              <span class="lableSpan">回退原因：</span>
              <el-input v-model="form.merchantName" type="textarea" size="medium"></el-input>
            </el-col>
          </el-row> -->
           <p class="mt20">提现备注：{{form.remark}}</p>
           <p class="mb30 mt20">银行回执单备注：{{form.bankRemark}}</p>
           <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
           <!-- <el-button type="primary" size="medium" @click="onReturnPay">财务回退</el-button> -->
        </div>
      </div>
      </template>
     <template v-else>
       <div class="head_top_title">
          <i></i>
          <span>批量支付4S店提现明细</span>
        </div>
       <el-table :data="querylist" border stripe class="table-mainbg">
        <el-table-column prop="accountName" label="收款账号户名" align="center"></el-table-column>
        <el-table-column prop="depositBank" label="开户行" align="center"></el-table-column>
        <el-table-column prop="depositBankShortName" label="银行简称" align="center"></el-table-column>
        <el-table-column prop="account" label="账号" align="center"></el-table-column>
        <el-table-column prop="amount" label="提现金额（元）" align="center"></el-table-column>
        <!-- <el-table-column prop="amount" label="收款状态" align="center"></el-table-column> -->
        <!-- <el-table-column label="操作" align="center"></el-table-column> -->
      </el-table>
      <p class="mt30"><span class="mr30">批量支付日期：{{form.batchPayTime?form.batchPayTime:'-'}}</span><span class="ml30">批量支付金额（元）：{{form.batchPayAmount}}</span></p>
      <template v-if="paymentState==31">
       <p class="mt15">付款方式：
         <el-select v-model="payTypeState" class="w300" clearable>
          <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </p>
        <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
          <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
        </template>
        <template v-if="[33,34].includes(paymentState)">
          <p class="mt15">付款方式：{{form.payTypeName}}</p>
        </template>
        <template v-if="paymentState==34">
          <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
        </template>
        <p class="tipsColor mt15" v-if="!batchPayOrderNo">当前余额（元）：{{form.availableBalance}}</p>
        <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
        
     </template>
     <div class="flex flex_align_center mt30" v-show="paymentState===31">
        <el-button type="primary" @click="onPayment(4)" size="medium" plain>财务回退</el-button>
        <div class="flex ml20 flex_align_center">
          <label>回退原因：</label>
          <el-input v-model="rollbackReason" type="textarea" class="w300" size="medium" placeholder="请输入"></el-input>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31">开始付款</el-button>
        <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32&&form.orderNo">付款完成</el-button>
        <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32&&form.orderNo">付款失败</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>

    <!-- <el-dialog
    title="请确认是否财务是否回退！"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15">新车牌号码：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog> -->

  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //0单个，1批量
      querylist: [],
      form: {},
      payTypeState:1,//付款方式
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      depositPayId:'',//退客户押金付款 ID
      batchPayOrderNo:'',//批量编号（没有批量编号，是需要查批量列表）
      accounts:'',//选择批量支付操作
      paymentState:31,//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
      walletList:[],//渠道钱包列表数据
      rollbackReason:'',//财务回退
    };
  },
  created() {
    // this.stateType = this.$route.query.state;
    if(this.$route.query.depositPayId){
      this.depositPayId=this.$route.query.depositPayId;
      this.stateType=0;
    }
    if(this.$route.query.batchPayOrderNo){
      this.batchPayOrderNo=this.$route.query.batchPayOrderNo;
      this.stateType=1;
    }
    if(this.$route.query.accounts){
      this.stateType=1;
      this.accounts = this.$route.query.accounts;
      this.walletList=this.$store.state.setting.walletList;
      this.querylist=JSON.parse(JSON.stringify(this.walletList))
      let cont=0;
      for(let i=0;i<this.querylist.length;i++){
        let item=this.querylist[i]
        cont+=item.amount;
      }
      this.form.batchPayAmount=cont;
    } 
    if(this.stateType==0){
      this.getChannelDetailByWalletPayId()//
    }else{
      if(this.batchPayOrderNo){
        this.getChannelBatchDetailsByOrderNo()//根据批次编号查询详情
      }else{
        this.getAvailableAmount()//查询银行账户余额
      }
    }
  },
  methods: {
    getChannelDetailByWalletPayId(){//获取详情
      this.$api.getChannelDetailByWalletPayId(this.depositPayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.financePayStatus;
          }
        }
      })
    },
    getChannelBatchDetailsByOrderNo(){//根据批次编号查询详情（渠道钱包提现）
      this.$api.getChannelBatchDetailsByOrderNo(this.batchPayOrderNo).then(res=>{
        if(res.code==0){
          this.form=res.data;
          this.payTypeState=res.data.payType==5?5:1;//付款方式
          this.paymentState=res.data.batchPayStatus;//批量支付状态，32付款中，33付款成功，34付款失败
          this.querylist = this.form.cwpList?this.form.cwpList:[];
        }
      })
    },
    onReturnPay(){//回退功能
      let _this=this;
      
    },
    onPayment(state){
      switch(state){
        case 1:
          if(!this.payTypeState){
            this.$message('请选择付款方式！')
            return
          }else{
            if(!this.form.payType&&this.payTypeState!=5){
              this.$message('请选择付款方式！')
              return
            }
          }
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            if(this.stateType==0){
              this.postChannelWalletFinancePay();//财务单个付款
            }else{
              this.postChannelWalletFinanceBatchPay()//财务批量付款
            }
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postChannelWalletFinanceConfirmPaySuccess()//财务确认付款成功
          }).catch(() => {});
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postChannelWalletFinanceConfirmPayFail()//财务确认付款失败
          }).catch(() => {});
          break;
        case 4://财务回退
          this.$confirm('请确认是否财务回退？注意：回退后页面会进入列表', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postChannelWalletPayRollback()//待付款状态下，根据渠道钱包支付ID回退到提现任务待审核状态
          }).catch(() => {});
          break;
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/walletWithdrawal'
      })
    },
    postChannelWalletFinancePay(){//财务付款单个
      let query={
        "walletPayId": this.depositPayId,//列表过来的id
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postChannelWalletFinancePay(query).then(res=>{
        if(res.code==0){
          this.getChannelDetailByWalletPayId()//
          this.$message.success(res.message)
        }
      })
    },
    postChannelWalletFinanceBatchPay(){//财务批量付款
      let walletPayIds=[];
      for(let i=0;i<this.querylist.length;i++){
        walletPayIds.push(this.querylist[i].id)
      }
      let query={
        "walletPayIds": walletPayIds,//渠道钱包支付ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postChannelWalletFinanceBatchPay(query).then(res=>{
        if(res.code==0){
          this.batchPayOrderNo=res.data;
          this.$message.success(res.message)
          this.$nextTick(()=>{
            this.onReturnPage(res.data)
          })
        }
      })
    },
    postChannelWalletFinanceConfirmPaySuccess(){//财务确认付款成功
      this.$api.postChannelWalletFinanceConfirmPaySuccess(this.form.orderNo).then(res=>{
        if(res.code==0){
          if(this.stateType==0){
            this.getChannelDetailByWalletPayId()//
          }else{
            this.getChannelBatchDetailsByOrderNo()//根据批次编号查询详情
          }
          this.$message.success(res.message)
        }
      })
    },
    postChannelWalletFinanceConfirmPayFail(){//财务确认付款失败
      this.$api.postChannelWalletFinanceConfirmPayFail(this.form.orderNo).then(res=>{
        if(res.code==0){
          if(this.stateType==0){
            this.getChannelDetailByWalletPayId()//
          }else{
            this.getChannelBatchDetailsByOrderNo()//根据批次编号查询详情
          }
          this.$message.success(res.message)
        }
      })
    },
    getAvailableAmount(){//查询银行账户余额
      this.$api.getAvailableAmount().then(res=>{
        if(res.code==0){
          this.form.availableBalance=res.data;
          this.$nextTick(()=>{
            this.$forceUpdate()
          })
        }
      })
    },
    onReturnPage(batchPayOrderNo){//新增批次退费专用
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/walletWithdrawalDetail',
        query:{
          batchPayOrderNo:batchPayOrderNo
        }
      })
    },
    postChannelWalletPayRollback(){//待付款状态下，根据渠道钱包支付ID回退到提现任务待审核状态
      let query={
        rollbackReason:this.rollbackReason,
        walletPayId:this.depositPayId
      }
      this.$api.postChannelWalletPayRollback(query).then(res=>{
        if(res.code==0){
          this.onReturn()
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.currencyConterBox {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px;
  overflow-y:auto;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .lableSpan{
    display:inline-block;
    width: 120px;
  }
}
</style>