<template>
  <div class="inspectorsGrouping">
    <el-tree
      :data="data"
      :props="{label: 'name'}"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span>
          <el-button v-if="data.isStore" class="ml20" type="primary" plain size="mini" @click="() => append(data)">添加检测师</el-button>
          <el-button v-if="node.parent.data.isStore" class="ml20" type="primary" plain size="mini" @click="() => remove(node, data)">删除检测师</el-button>
        </span>
      </span>
    </el-tree>

    <!-- 添加检测师 -->
    <el-dialog :visible.sync="dialogVisible" center title="添加检测师">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="选择检测师:" prop="name">
          <el-select v-model="ruleForm.name" filterable remote reserve-keyword placeholder="请输入姓名" :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTestForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      dialogVisible: false,
      ruleForm: { name: '' },
      rules: { name: [{ required: true, message: '请选择', trigger: 'change' }] },
      loading: false,
      curGroupId: null,
      options: [],
    }
  },
  created() {
    this.checkerQueryCheckerGroupList()
  },
  methods: {
    checkerQueryCheckerGroupList() {
      this.$api.checkerQueryCheckerGroupList().then(res => {
        if(res.code == 0){
          this.data = res.data
          this.handleData(this.data);
        }
      })
    },
    handleData(data) {
      data.forEach(item => {
        if(item.users&&item.users.length){
          item.children = item.users
          this.handleData(item.children)
        }
        if(item.groups&&item.groups.length){
          item.children = item.groups
          this.handleData(item.children)
        }
      })
    },
    append(data) {
      this.dialogVisible = true;
      this.curGroupId = data.id;
    },
    remove(node, data) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.checkerDeleteGroupUser({
          checkerId: data.id,
          groupId: node.parent.data.id
        }).then(res => {
          if(res.code == 0){
            this.$message.success('操作成功')
            this.checkerQueryCheckerGroupList()
          }
        })
      }).catch(() => {});
    },
    submitTestForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            groupId: this.curGroupId,
            userIds: this.ruleForm.name
          }
          this.$api.checkerAddGroupUsers(params).then(res => {
            if(res.code == 0){
              this.$message.success('操作成功')
              this.checkerQueryCheckerGroupList()
              this.dialogVisible = false;
              this.$refs['ruleForm'].resetFields();
            }
          })
        } else {
          return false;
        }
      });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.$api.checkerQueryChecker({name: query}).then(res => {
          if(res.code == 0){
            this.loading = false;
            this.options = res.data;
          }
        })
      } else {
        this.options = [];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.inspectorsGrouping{
  padding: 20px 40px;
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-right: 8px;
  }
  ::v-deep .el-tree-node__content{
    height: 36px;
    margin-bottom: 2px;
  }
}
</style>