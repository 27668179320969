<template>
  <div class :class="stateType==0?'examineBoxBG':''">
    <div class="examineBox">
      <el-form ref="form" :model="form" :rules="rules" label-width="135px" :hide-required-asterisk="true">

      <!--基本信息-->
      <div class="mb10">
        <div class="content_modal_textBox">
          <div class="ckxTitle">基本信息</div>
          <el-row>
            <el-col :span="6">
              <el-form-item label="合作店简称:">
                <el-input v-model="form.shortName" size="medium" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合作店全称:">
                <el-input v-model="form.name" size="medium" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属集团:">
                <el-input v-model="form.ownerGroup" size="medium" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="所属区域:">
                <el-cascader :disabled="disabled"
                  v-model="areaArr"
                  :options="addresslist" class="ww100"
                  :props="props"></el-cascader>
                <!-- <el-input v-model="form.area" size="medium" :disabled="disabled"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="地址:">
                <el-input v-model="form.address" size="medium" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
           
            <el-col :span="6">
              <el-form-item label="品牌:">
                <el-input v-model="form.brand" size="medium" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="拓展专员:">
                <el-select v-model="form.extensionerId" size="medium" :disabled="disabled" clearable filterable >
                  <el-option v-for="(item,index) in userlist" :key="index" :value="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!--联系人信息-->
      <div class="ckxTitle">联系人信息</div>
      <el-row>
        <el-col :span="6">
          <el-form-item label="对接人:">
            <el-input v-model="cobj.name" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="职务:">
            <el-input v-model="cobj.job" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="联系方式:">
            <el-input v-model="cobj.mobile" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="负责人:">
            <el-input v-model="cobj.name1" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="职务:">
            <el-input v-model="cobj.job1" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="联系方式:">
            <el-input v-model="cobj.mobile1" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="集团对接人:">
            <el-input v-model="cobj.name2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="职务:">
            <el-input v-model="cobj.job2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="联系方式:">
            <el-input v-model="cobj.mobile2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--账户信息-->
      <div class="mt20">
        <div class="content_modal_textBox">
            <div class="head_top_title mb20">
              <i></i>
              <span>账户信息</span>
            </div>
              <el-form-item label="是否提供发票">
                <el-radio-group v-model="form.needBill" size="medium" class="radioButton" :disabled="disabled">
                  <el-radio-button :label="0">不提供</el-radio-button>
                  <el-radio-button :label="1">提供</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-tabs v-model="activeName" type="card" class="customName-tab mt20" >
                <el-tab-pane label="佣金固定值" name="first" :disabled="disabled"></el-tab-pane>
                <el-tab-pane label="补贴车价" name="second" :disabled="disabled"></el-tab-pane>
                <el-tab-pane label="按车价比例" name="third" :disabled="disabled"></el-tab-pane>
                <el-tab-pane label="按成交量" name="fourth" :disabled="disabled"></el-tab-pane>
              </el-tabs>
              <div class="tabBox" v-show="activeName=='first'">
                <el-row class="custom-border-tab">
                    <el-col :span="24">
                      <el-form-item label="佣金值:">
                        <el-input v-model="obj.fixedAmount" size="medium" :disabled="disabled"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
              </div>
              <div class="tabBox" v-show="activeName=='second'">
                <el-row class="custom-border-tab">
                  <el-col :span="24">
                    <el-form-item label="佣金比（%）:">
                      <el-input v-model="obj.subsidyRate" size="medium" :disabled="disabled"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="最小值（元）:">
                      <el-input v-model="obj.minAmount" size="medium" :disabled="disabled"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="最大值（元）:">
                      <el-input v-model="obj.maxAmount" size="medium" :disabled="disabled"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="tabBox" v-show="activeName=='third'">
                  <el-row class="custom-border-tab">
                    <el-col :span="24">
                      <el-form-item label="佣金比（%）:">
                        <el-input v-model="obj.priceRate" size="medium" :disabled="disabled"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="最小值（元）:">
                        <el-input v-model="obj.minAmount" size="medium" :disabled="disabled"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="最大值（元）:">
                        <el-input v-model="obj.maxAmount" size="medium" :disabled="disabled"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
              </div>
              <div class="tabBox" v-show="activeName=='fourth'">
                <el-row class="custom-border-tab">
                    <el-col :span="24">
                      <el-form-item label="成交量大于等于:" class="custom-group">
                        <el-input v-model="obj.volBigTradeNum" size="medium" :disabled="disabled">
                          <template slot="append">台</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="按:" class="custom-group">
                        <el-input v-model="obj.volBigTradePrice" size="medium" :disabled="disabled">
                          <template slot="append">元/台</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="成交量小于等于:" class="custom-group">
                        <el-input v-model="obj.volSmallTradeNum" size="medium" :disabled="disabled">
                          <template slot="append">台</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="按:" class="custom-group">
                        <el-input v-model="obj.volSmallTradePrice" size="medium" :disabled="disabled">
                          <template slot="append">元/台</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
              </div>
              <el-row v-if="[3].includes(stateType)">
                <el-col :span="6">
                  <el-form-item label="余额:">
                    <p>{{form.wallet.balance}}元</p>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="可提现金额:">
                    <p>{{form.wallet.availableAmount}}元</p>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="[3].includes(stateType)">
                  <el-form-item label>
                    <el-button type="primary" size="mini" plain @click="onsee">查看钱包流水</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="ckxTitle mt10 mb10">对公账户信息</div>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="对公账号户名:">
                    <el-input v-model="obj.accountName1" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对公账号:">
                    <el-input v-model="obj.account1" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="银行简称:">
                    <el-select
                      v-model="obj.depositBankShortName1"
                      filterable :disabled="disabled"  size="medium" 
                      placeholder="请输入关键词" @input="forceUpdate">
                      <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行:" class="bankULBoxConter">
                    <el-input v-model="obj.depositBank1" size="medium" :disabled="disabled" @input="forceUpdate($event,1)"></el-input>
                    <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
                      <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item,1)">{{item.bankName}}</li>
                    </ul>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="ckxTitle mt10 mb10">对私账户信息</div>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="对私账号户名:">
                    <el-input v-model="obj.accountName2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对私主账号:">
                    <el-input v-model="obj.account2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="银行简称:">
                    <!-- <el-input v-model="obj.depositBankShortName2" size="medium" :disabled="disabled" @input="forceUpdate"></el-input> -->
                    <el-select
                      v-model="obj.depositBankShortName2"
                      filterable :disabled="disabled"  size="medium" 
                      placeholder="请输入关键词" @input="forceUpdate">
                      <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行:" class="bankULBoxConter">
                    <el-input v-model="obj.depositBank2" size="medium" :disabled="disabled" @input="forceUpdate($event,2)"></el-input>
                    <ul class="bankULBox" v-show="bankVis2&&banklistSear.length>0">
                      <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item,2)">{{item.bankName}}</li>
                    </ul>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="ckxTitle mt10 mb10">对私副账户信息</div>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="对私副账号户名:">
                    <el-input v-model="obj.accountName3" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对私副账号:">
                    <el-input v-model="obj.account3" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="银行简称:">
                    <!-- <el-input v-model="obj.depositBankShortName3" size="medium" :disabled="disabled" @input="forceUpdate"></el-input> -->
                    <el-select
                      v-model="obj.depositBankShortName3"
                      filterable :disabled="disabled"  size="medium" 
                      placeholder="请输入关键词" @input="forceUpdate">
                      <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行:" class="bankULBoxConter">
                    <el-input v-model="obj.depositBank3" size="medium" :disabled="disabled" @input="forceUpdate($event,3)"></el-input>
                    <ul class="bankULBox" v-show="bankVis3&&banklistSear.length>0">
                      <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item,3)">{{item.bankName}}</li>
                    </ul>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="ckxTitle mb10">临时账户信息<span class="tipsColor">（一天有效期/24小时）</span><span class="ml40">临时账号有效期截至时间为：<span class="tipsColor">{{obj.expireTime4?obj.expireTime4:'-'}}&nbsp;{{obj.enableStatus4===0?'已禁用':''}}</span></span></div>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="临时账号户名:">
                    <el-input v-model="obj.accountName4" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="临时账号:">
                    <el-input v-model="obj.account4" size="medium" :disabled="disabled" @input="forceUpdate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="银行简称:">
                    <el-select
                      v-model="obj.depositBankShortName4"
                      filterable :disabled="disabled"  size="medium" 
                      placeholder="请输入关键词" @input="forceUpdate">
                      <el-option v-for="(item,index) in banklist" :key="index" :label="item.bankName" :value="item.bankName"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行:" class="bankULBoxConter">
                    <el-input v-model="obj.depositBank4" size="medium" :disabled="disabled" @input="forceUpdate($event,1)"></el-input>
                    <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
                      <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item,1)">{{item.bankName}}</li>
                    </ul>
                  </el-form-item>
                </el-col>
              </el-row>
              
        </div>
      </div>
      
      <div class="mt20 pb100">
        <!--成交后所需材料-->
        <div class="content_modal_textBox">
          <div class="head_top_title mb20">
            <i></i>
            <span>成交后所需材料</span>
          </div>
          <el-checkbox-group v-model="checkList" class="mb20" :disabled="disabled">
            <el-checkbox v-for="item of materialList" :key="item.id" :label="item.id" class="mb10">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        
        <div class="content_modal_textBox mb20">
          <div class="head_top_title mb10">
            <i></i>
            <span>客户合同签定时，机动车登记证书缺或暂缺是否全押车款</span>
          </div>
          <el-radio-group v-model="form.isAllIn" :disabled="disabled">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div class="ckxTitle mb10">修改记录</div>
        <el-table :data="querylist" border stripe  class="mb20">
          <el-table-column prop="operatorName" label="修改人" align="center"></el-table-column>
          <el-table-column prop="updateDate" label="修改时间" align="center"></el-table-column>
          <el-table-column prop="statusName" label="状态" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="scope">{{scope.row.remark?scope.row.remark:''}}</template>
          </el-table-column>
        </el-table>
        <el-row v-if="[0].includes(stateType)">
          <el-col :span="12">
            <el-form-item label="审核备注:" prop="auditRemark">
              <el-input type="textarea" v-model="form.auditRemark" size="medium"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="12">
            <el-form-item label="签订时间:" v-if="[1,3].includes(stateType)">
              <el-date-picker
                v-model="form.signTime" :disabled="true"
                type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="签订时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="解约时间:" v-if="[4].includes(stateType)">
              <el-date-picker
                v-model="form.signTime" :disabled="disabled"
                type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同截止时间:" v-if="[1,3,4].includes(stateType)" >
            <el-date-picker
              v-model="form.expiredTime" :disabled="disabled===false||[1].includes(stateType)?false:true"
              type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间">
            </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="footer">
        <div class="btn">
          <!-- this.disabled =[0,3,5,6,7].includes(this.stateType)?false:true;数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败 -->
          <el-button type="primary" @click="onSubmitform('form','submit')" v-if="[3,5,6].includes(stateType)">保存</el-button>
          <el-button type="primary" v-if="[0].includes(stateType)" @click="onSubmitform('form',1)">审核通过</el-button>
          <el-button type="primary" v-if="[0].includes(stateType)" @click="onSubmitform('form',2)">审核驳回</el-button>
          <el-button type="primary" v-if="[0].includes(stateType)" @click="onSubmitform('form',7)">审核失败</el-button>
          <el-button type="primary" v-if="[7].includes(stateType)" @click="onSubmitform('form',0)">重新审核</el-button>
          <el-button type="primary" v-if="[1].includes(stateType)" @click="onSubmitform('form',3)">签约成功</el-button>
          <el-button type="primary" v-if="[1].includes(stateType)" @click="onSubmitform('form',5)">签约失败</el-button>
          <el-button type="primary" v-if="[3].includes(stateType)" @click="onSubmitform('form',4)">解约</el-button>
          <el-button type="primary" v-if="[4,5,6].includes(stateType)" @click="onSubmitform('form',1)">重新签约</el-button>
          <el-button type="primary" @click="onroute('')">返回</el-button>
        </div>
      </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import BigNumber from 'bignumber.js'
export default {
  data() {
    return {
      stateType: '',//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
      activeName: "first",
      materialList: [],//材料
      checkList:[],
      form: {
        wallet:{}
      },
      dialogVisible: false,
      rules: {
        auditRemark: [{ required: true, message: "审核备注不能为空" }],
        expiredTime:[{ required: true, message: "不能为空" }],
      },
      addresslist:[],
      areaArr:[],//选择的地区
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      walletId: "", //钱包ID
      id:'',//列表id
      obj: {}, //佣金
      cobj:{},//联系人
      disabled:false,//是否可编辑
      querylist:[],
      bankVis:false,//是否展示银行信息的搜索内容
      bankVis2:false,
      bankVis3:false,
      banklistSear:[],//搜索的银行数据
      banklist:[],
      userlist:[],
      res:{},
    };
  },
  created() {
    // if(this.$route.query.state) this.stateType = this.$route.query.state;
    if(this.$route.query.id) this.id=this.$route.query.id;
    
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.areaList.length==0){
      this.$datadic.getProvince(0).then(res=>{
        this.addresslist=res;
      })
    }else{
      this.addresslist=this.$store.state.setting.areaList;
    }
    this.getByChannelIdDetail(); //获取详情
    this.getRecordList(this.id)
    this.queryUserListByRoleAndDepart()//获取拓展专员
    
  },
  methods: {
    onSubmitform(formdata, state) {
      //提交frorm
      // if(this.disabled){
      //   this.updateChannelStatus(state)
      // }else{
        this.$refs[formdata].validate(valid => {
          if (valid) {
            this.updateChannelDetail(state); 
          }else{
            this.$nextTick(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].scrollIntoView({
                // 滚动到指定节点
                // 值有start,center,end，nearest
                block: "center",
                // 值有auto、instant,smooth，缓动动画
                behavior: "smooth",
              });
            });
          }
        });
      // }
    },
    onroute(state) {//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
      let url = "";
      if (!state) {
        state=this.stateType;
      }
      switch (state) {
        case 0://0-待审核
        case 1://1-审核通过(待签约)
          url = "/channel/stayExamine";
          break;
        // case 1://1-审核通过(待签约)
        //   url = "/channel/staySign";
        //   break;
        case 2://2-审核驳回
          url = "/channel/reviewRejected";
          break;
        case 3://3-已签约
          url = "/channel/alreadySign";
          break;
        case 4://4-已解约
          url = "/channel/rescind";
          break;
        case 5://5-签约失败
          url = "/channel/signingFailed";
          break;
        case 6://6-账号过期
          url = "/channel/accountExpired";
          break;
        case 7://7-审核失败
          url = "/channel/auditFailed";
          break;
      }
      this.bus.$emit("closeSelected");
      if(url){
        this.$router.push({
          path: url
        });
      }
    },
    onsee() {
      this.$router.push({
        // path: "/channel/streamDetail"
        name:'streamDetail',
        params:{
          shortName:this.res.shortName
        }
      });
    },
    getByChannelIdDetail() {
      this.checkList=[];//当前选择的值
      //详情
      this.$api.getByChannelIdDetail(this.id).then(res => {
        if (res.code == 0) {
          this.res = JSON.parse(JSON.stringify(res.data));
          this.form = JSON.parse(JSON.stringify(res.data));
          this.form.auditRemark=this.form.auditRemark?this.form.auditRemark:'';
         //数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
          this.stateType=res.data.status;
          this.disabled =[0,3,5,6,7].includes(this.stateType)?false:true;
          switch(res.data.status){
            case 0:
            case 3:
            case 5:
            case 6:
            case 7:
              this.postbankHeadList();
              break;
          }
          //钱包
          this.form.wallet = res.data.wallet?res.data.wallet:{}
          //区域
          this.areaArr=[res.data.provinceId,res.data.cityId,res.data.areaId]
          // 	跟进任务
          this.materialList=res.data.materialList;
          for(let i=0;i<res.data.materialList.length;i++){
            let item=res.data.materialList[i]
            if(item.relation){
              this.checkList.push(item.id)
            }
          }
          //佣金
          this.obj=res.data.commission?res.data.commission:{};
          
          if(this.obj.subsidyRate){
            let x= new BigNumber(this.obj.subsidyRate);
            this.obj.subsidyRate=Number(x.times(100))
          }
          if(this.obj.priceRate){
            const xx= new BigNumber(this.obj.priceRate);
            this.obj.priceRate=Number(xx.times(100))
          }
          //账户
          for (let i = 0; res.data.accountList.length > i; i++) {
            let oldArr = res.data.accountList;
            if (oldArr[i].accountType == 0) {
              this.obj["account1"] = oldArr[i].account;
              this.obj["accountName1"] = oldArr[i].accountName;
              this.obj["depositBank1"] = oldArr[i].bank;
              this.obj["depositBankShortName1"] =
                oldArr[i].bankShortName;
            } else if (oldArr[i].accountType == 1) {
              this.obj["account2"] = oldArr[i].account;
              this.obj["accountName2"] = oldArr[i].accountName;
              this.obj["depositBank2"] = oldArr[i].bank;
              this.obj["depositBankShortName2"] =
                oldArr[i].bankShortName;
            } else if (oldArr[i].accountType == 2) {
              this.obj["account3"] = oldArr[i].account;
              this.obj["accountName3"] = oldArr[i].accountName;
              this.obj["depositBank3"] = oldArr[i].bank;
              this.obj["depositBankShortName3"] =
                oldArr[i].bankShortName;
            }else if (oldArr[i].accountType == 3) {
              this.obj["account4"] = oldArr[i].account;
              this.obj["accountName4"] = oldArr[i].accountName;
              this.obj["depositBank4"] = oldArr[i].bank;
              this.obj["depositBankShortName4"] =
                oldArr[i].bankShortName;
              this.obj["expireTime4"] =
                oldArr[i].expireTime;
              this.obj["enableStatus4"] =
                oldArr[i].enableStatus;
            }
          }
          //联系人
          for (let i = 0; res.data.contactList.length > i; i++) {
            let oldArr = res.data.contactList;
            if (oldArr[i].type == 0) {
              this.cobj["name"] = oldArr[i].name;
              this.cobj["job"] = oldArr[i].job;
              this.cobj["mobile"] = oldArr[i].mobile;
            } else if (oldArr[i].type == 1) {
              this.cobj["name1"] = oldArr[i].name;
              this.cobj["job1"] = oldArr[i].job;
              this.cobj["mobile1"] = oldArr[i].mobile;
            } else if (oldArr[i].type == 2) {
              this.cobj["name2"] = oldArr[i].name;
              this.cobj["job2"] = oldArr[i].job;
              this.cobj["mobile2"] = oldArr[i].mobile;
            }
          }
          switch(this.obj.commissionType){
            case 0:
              this.activeName='first';
              break;
            case 1:
              this.activeName='second';
              break;
            case 2:
              this.activeName='third';
              break;
            case 3:
              this.activeName='fourth';
              break;
          }
          this.$nextTick(()=>{
            this.$refs['form'].clearValidate()
          })
        }
      });
    },
    updateChannelDetail(state){//修改渠道详情内容
      if(this.form.isAllIn!==0&&this.form.isAllIn!==1){
        this.$message('请选择是否属于全押车款！')
        return
      }
      let arr=[
        {
          accountType:0,
          accountTypeName:'对公账号',
          channelId:this.id,
          account:this.obj.account1,
          accountName:this.obj.accountName1,
          bank:this.obj.depositBank1,
          bankShortName:this.obj.depositBankShortName1
        },
        {
          accountType:1,
          accountTypeName:'对私主账号',
          channelId:this.id,
          account:this.obj.account2,
          accountName:this.obj.accountName2,
          bank:this.obj.depositBank2,
          bankShortName:this.obj.depositBankShortName2
        },
        {
          accountType:2,
          accountTypeName:'对私副账号',
          channelId:this.id,
          account:this.obj.account3,
          accountName:this.obj.accountName3,
          bank:this.obj.depositBank3,
          bankShortName:this.obj.depositBankShortName3
        },
        {
          accountType:3,
          accountTypeName:'临时账号',
          channelId:this.id,
          account:this.obj.account4,
          accountName:this.obj.accountName4,
          bank:this.obj.depositBank4,
          bankShortName:this.obj.depositBankShortName4
        }
      ]
      let newArr1=[
          {
            channelId:this.id,
            type:0,
            name:this.cobj.name,
            mobile:this.cobj.mobile,
            job:this.cobj.job
          },
          {
            channelId:this.id,
            type:1,
            name:this.cobj.name1,
            mobile:this.cobj.mobile1,
            job:this.cobj.job1
          },
          {
            channelId:this.id,
            type:2,
            name:this.cobj.name2,
            mobile:this.cobj.mobile2,
            job:this.cobj.job2
          }
        ]
      let andNewArr=[];//联系人信息列表
      for(let i=0;i<newArr1.length;i++){
        let item=newArr1[i]
        for(let i=0;i<this.form.contactList.length;i++){
          let ims=this.form.contactList[i]
          if(item.type===ims.type){
            item.id=ims.id;
          }
        }
        if(!item.name&&!item.mobile&&!item.job&&!item.id){
          break;
        }
        andNewArr.push(item)
      }

      let arrMate=JSON.parse(JSON.stringify(this.form.materialList))
      for(let i=0;i<arrMate.length;i++){
        let item=arrMate[i]
        item.relation=false;
        if(this.checkList.includes(item.id)){
          item.relation=true;
        }
      }
      let query={
        "id": this.id,
        "name": this.form.name,//合作店简称
        "shortName": this.form.shortName,//合作店全称
        "ownerGroup": this.form.ownerGroup,//所属集团
        "brand": this.form.brand,
        "provinceId": this.areaArr.length>0?this.areaArr[0]:'',
        "cityId":  this.areaArr.length>0?this.areaArr[1]:'',
        "areaId":  this.areaArr.length>0?this.areaArr[2]:'',
        "address": this.form.address,
        "needBill": this.form.needBill,
        expiredTime:this.form.expiredTime,
        isAllIn:this.form.isAllIn,
        materialList:arrMate,
        contactList:andNewArr,
        "accountList": arr,
        "commission": {
          "channelId": this.obj.channelId?this.obj.channelId:this.id,
          "id": this.obj.id,
        },
        extensionerId:this.form.extensionerId,//拓展专员
        // isUpdateStatus:0//是否修改状态 修改状态不生成修改记录 0-不修改 1-修改
      }
      if(state!='submit'){
        query.isUpdateStatus=1;
      }
      // if([1,3].includes(state)){
        switch(this.activeName){
          case 'first':
            if(this.obj.fixedAmount===''||this.obj.fixedAmount===null){
              this.$message('佣金值不能为空！')
              return
            }
            query.commission['commissionType']=0;
            query.commission['fixedAmount']=this.obj.fixedAmount;
            break;
          case 'second':
            if(this.obj.subsidyRate===''||this.obj.subsidyRate===null){
              this.$message('佣金比不能为空！')
              return
            }
            query.commission['commissionType']=1;
            const x= new BigNumber(this.obj.subsidyRate);
            query.commission['subsidyRate']=Number(x.div(100));
            query.commission['minAmount']=this.obj.minAmount;
            query.commission['maxAmount']=this.obj.maxAmount;
            break;
          case 'third':
            if(this.obj.priceRate===''||this.obj.priceRate===null){
              this.$message('佣金比不能为空！')
              return
            }
            query.commission['commissionType']=2;
            const xx= new BigNumber(this.obj.priceRate);
            query.commission['priceRate']=Number(xx.div(100));
            query.commission['minAmount']=this.obj.minAmount;
            query.commission['maxAmount']=this.obj.maxAmount;
            break;
          case 'fourth':
            if(this.obj.volBigTradeNum===''||this.obj.volBigTradeNum===null||this.obj.volBigTradePrice===''||this.obj.volBigTradePrice===null||this.obj.volSmallTradeNum===''||this.obj.volSmallTradeNum===null||this.obj.volSmallTradePrice===''||this.obj.volSmallTradePrice===null){
              this.$message('成交量不能为空！')
              return
            }
            query.commission['commissionType']=3;
            query.commission['volBigTradeNum']=this.obj.volBigTradeNum;
            query.commission['volBigTradePrice']=this.obj.volBigTradePrice;
            query.commission['volSmallTradeNum']=this.obj.volSmallTradeNum;
            query.commission['volSmallTradePrice']=this.obj.volSmallTradePrice;
            break;
        }
      // }
      //这个地方不能使用delete_null_properties这个方法影响修改为空的数据
      this.$api.updateChannelDetail(this.id,query).then(res=>{
        if(res.code==0){
          if(state!='submit'){
            this.updateChannelStatus(state)//修改渠道状态
          }else{
            this.getByChannelIdDetail(); //获取详情
            this.getRecordList(this.id)
          }
        }
      })
    },
    updateChannelStatus(state) {//修改渠道状态
      let auditRemark=this.form.auditRemark;
      if(state===0){
        auditRemark='';
      }
      this.$api.updateChannelStatus(this.id,state,auditRemark).then(res => {
        if (res.code == 0) {
          if(state===0){
            this.$router.push({
              path:'/channel/examineDetails',
              query:{
                id:this.id
              }
            })
            this.getByChannelIdDetail(); //获取详情
            this.getRecordList(this.id)
          }else{
            this.onroute(state);
          }
          this.$message.success(res.message)
        }
      });
    },
    getRecordList(id){//根据渠道ID查询更新记录
      this.$api.getRecordList(id).then(res=>{
        if(res.code==0){
          this.querylist=res.data;
        }
      })
    },
    forceUpdate($event,state){
      //暂时不需要请求分行数据
      // if(state){
      //   if($event){
      //     this.postbankList($event,state)
      //   }else{
      //     this.bankVis=false;
      //     this.bankVis2=false;
      //     this.bankVis3=false;
      //   }
      // }
      this.$forceUpdate()
    },
    onBankTage(row,state){
      switch(state){
        case 1:
          this.obj.depositBank1=row.bankName;
          break;
        case 2:
          this.obj.depositBank2=row.bankName;
          break;
        case 3:
          this.obj.depositBank3=row.bankName;
          break;
      }
      this.bankVis=false;
      this.bankVis2=false;
      this.bankVis3=false;
      this.$forceUpdate()
    },
    postbankHeadList(){//查询银行简称
      this.$api.postbankHeadList({}).then(res=>{
        if(res.code==0){
          this.banklist=res.data;
        }
      })
    },
    postbankList(bankName,state){//根据银行简称
      let query={
        bankName:bankName,
        // headBankName:headBankName,
      }
      switch(state){
        case 1:
          query.headBankName=this.obj.depositBankShortName1;
          break;
        case 2:
          query.headBankName=this.obj.depositBankShortName2;
          break;
        case 3:
          query.headBankName=this.obj.depositBankShortName3;
          break;
      }
      this.$api.postbankList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.banklistSear=res.data;
          switch(state){
            case 1:
              this.bankVis=true;
              break;
            case 2:
              this.bankVis2=true;
              break;
            case 3:
              this.bankVis2=true;
              break;
          }
        }
      })
    },
    queryUserListByRoleAndDepart(){//获取拓展专员
      let query={
        roleName:'拓展专员',
        userEnabled:0,//角色是否启用,启用0，禁用1
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
  }
};
</script>
<style >
.el-textarea__inner {
  height: 100px;
}
#app .customName-tab > .el-tabs__header .el-tabs__item {
  min-width: 120px;
}
.custom-group .el-input-group__append {
  width: 50px;
  padding: 0;
  text-align: center;
}
#app .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-left: 0;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
<style lang="scss" scoped>
.examineBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.examineBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .head_top_title {
    display: flex;
    align-items: center;
    // padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .customName-tab {
    // width: 485px;
    .custom-border-tab {
      border: 1px solid #cccccc;
      padding: 20px 20px 10px;
      margin: 20px 0;
    }
  }
  .tabBox{
    margin:20px 0 30px;
    width:500px
  }
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:5;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
}
</style>