<template>
  <div :class="stateType==0?'shipmentBoxBG':''">
    <div class="headBox">
      <h4 class="headTitleTop">物流发货单</h4>
      <div class="headTitleBox">发货单号：{{form.logistNo}}</div>
    </div>
    <div class="shipmentBox">
      <!--物流信息-->
      <div class="content_title_Box w50 pt30" v-if="stateType==0">
        <div class="head_top_title">
          <i></i>
          <span>物流信息</span>
        </div>
        <el-form
          ref="form"
          :model="form"
          label-width="130px"
          :rules="rules"
          :inline="true"
          :hide-required-asterisk="true"
          class="formCustomRow"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货方:" class="mb10">
                <p>{{form.fromObjName}}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="flex">
              <el-form-item label="收货方:" class="mb10">
                <el-select v-model="form.toShopTypeName" placeholder="请选择收货方" size="medium" class="w200" @change="selectChage($event,'收货方')">
                  <el-option label="办证公司" :value="42"></el-option>
                  <el-option label="门店" :value="41"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" class="mb10" v-show="form.toObjType==41">
                <el-select v-model="form.toShopName" placeholder="请选择分公司" class="w150" @change="selectChage($event,'门店')" value-key="id" filterable>
                  <el-option v-for="item in departmentlist" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="物流方式:">
                  <el-radio-group v-model="form.logistType" size="medium" class="radioButton" @change="changeLogistType">
                    <el-radio-button :label="11">代驾</el-radio-button>
                    <el-radio-button :label="12" v-show="form.toObjType==41">车开新</el-radio-button>
                    <el-radio-button :label="13">快递</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="form.logistType==11">
              <el-col :span="12">
                <el-form-item label="代驾姓名:" prop="agencyName">
                  <el-input v-model="form.agencyName" size="medium" class="w200"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="代驾手机:" prop="agencyMobile">
                  <el-input v-model="form.agencyMobile" size="medium" class="w200"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="form.logistType==12">
              <el-col :span="12">
                <el-form-item label="员工姓名:">
                  <el-select v-model="form.empName" size="medium" class="w200" value-key="id" @change="empSelect" filterable>
                    <el-option v-for="item in emplist" :key="item.id" :label="item.name" :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号:" >
                  <el-input v-model="form.empMobile" size="medium" class="w200"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="form.logistType==13">
              <el-col :span="12">
                <el-form-item label="快递公司:">
                  <el-input v-model="form.expressCompName" size="medium" class="w200"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="快递单号:" >
                  <el-input v-model="form.expressLogistNo" size="medium" class="w200"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="出发时间:">
                  <el-date-picker
                    v-model="form.fromTime"
                    type="datetime"
                    size="medium"
                    placeholder="选择日期"
                    class="w200" value-format="yyyy-MM-dd HH:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="预计到达时间:">
                  <el-date-picker
                    v-model="form.preArriveTime"
                    type="datetime"
                    size="medium"
                    placeholder="选择日期"
                    class="w200" value-format="yyyy-MM-dd HH:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
          </el-row>
        </el-form>
      </div>
      <!--手续材料信息-->
      <div class="content_modal_textBox mt30" :class="stateType==0?'pb100':''">
        <div class="head_top_title flex flex_between">
          <div class="flex flex_align_center">
            <i></i>
            <span>手续材料信息</span>
          </div>
          <el-button type="primary" size="medium" plain v-if="stateType==0" @click="onadd">添加手续材料</el-button>
        </div>
        <el-table :data="carlist" border class="table-mainbg" @selection-change="materialsSelectionChange" ref="materialsCar">
          <el-table-column type="selection" align="center" v-if="stateType==0"></el-table-column>
          <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
          <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
          <el-table-column prop="sendNums" label="数量" align="center"></el-table-column>
          <!-- <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column> -->
          <el-table-column label="操作" align="center" v-if="stateType==0">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" plain v-if="scope.row.carId" @click="delProject(scope.$index)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <template v-if="stateType==0&&car.isHuPlate==1&&form.logistType==12"> -->
        <!-- <template v-if="stateType==0">
          <div class="ckxTitle mt30 mb20">验车单</div>
          <el-table :data="vaildcarlist" border class="table-mainbg" @selection-change="vaildSelectionChange">
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
            <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
            <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
            <el-table-column prop="contractRequiredNums" label="数量" align="center"></el-table-column>
          </el-table>
        </template> -->
      </div>
      <!--物流信息-->
      <div class="content_modal_textBox w50 mt30" v-if="stateType!=0" :class="stateType==1?'pb100':''">
        <div class="head_top_title">
          <i></i>
          <span>物流信息</span>
        </div>
        <el-row>
          <el-col :span="12" class="conter_text_row">
            <label>物流方式：</label>
            <span>{{form.logistTypeName}}</span>
          </el-col>
          <el-col :span="12" class="conter_text_row">
            <label>接收方：</label>
              <span>{{form.toObjName}}</span>
            </el-col>
            <template v-if="form.logistType==11">
              <el-col :span="12" class="conter_text_row">
              <label>代驾姓名：</label>
              <span>{{form.agencyName}}</span>
            </el-col>
            <el-col :span="12" class="conter_text_row">
              <label>代驾手机：</label>
              <span>{{form.agencyMobile}}</span>
            </el-col>
          </template>
          <template v-if="form.logistType==12">
            <el-col :span="12" class="conter_text_row">
              <label>员工姓名：</label>
              <span>{{form.empName}}</span>
            </el-col>
            <el-col :span="12" class="conter_text_row">
              <label>手机号：</label>
              <span>{{form.empMobile}}</span>
            </el-col>
          </template>
          <template v-if="form.logistType==13">
            <el-col :span="12" class="conter_text_row">
              <label>快递公司：</label>
              <span>{{form.expressCompName}}</span>
            </el-col>
            <el-col :span="12" class="conter_text_row">
              <label>快递单号：</label>
              <span>{{form.expressLogistNo}}</span>
            </el-col>
          </template>
          
          <el-col :span="12" class="conter_text_row">
            <label>发货时间：</label>
            <span>{{form.fromTime}}</span>
          </el-col>
          <el-col :span="12" class="conter_text_row">
            <label>预计到达时间：</label>
            <span>{{form.preArriveTime}}</span>
          </el-col>
        </el-row>
      </div>
      <!--接货信息-->
      <div class="content_modal_textBox w50 mt15" v-if="stateType==2">
        <div class="head_top_title">
          <i></i>
          <span>接货信息</span>
        </div>
        <el-row>
          <el-col :span="12" class="conter_text_row">
            <label>接货人：</label>
            <span>{{form.toOperaName}}</span>
          </el-col>
          <el-col :span="12" class="conter_text_row">
            <label>接收时间：</label>
            <span>{{form.toReceiveTime}}</span>
          </el-col>
        </el-row>
      </div>
      <!--处置预警历史-->
      <div class="content_modal_textBox mt10 pb100" v-if="stateType==2">
        <div class="head_top_title">
          <i></i>
          <span>处置预警历史</span>
        </div>
        <el-table :data="alarmList" border class="table-mainbg">
          <el-table-column prop="createDate" label="处置时间" align="center" ></el-table-column>
          <el-table-column prop="createBy" label="操作人" align="center"></el-table-column>
          <el-table-column prop="preArriveTime" label="修改预计到达时间" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        </el-table>
      </div>

      <div class="footer" v-if="stateType==0">
        <div class="btn">
          <el-button type="primary" @click="onsave(1)">保存</el-button>
          <el-button type="primary" @click="onsave(2)">提交</el-button>
        </div>
      </div>

      <el-dialog
        title="添加手续"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
      >
        <el-form
          ref="modalForm"
          :model="modalForm"
          :rules="rules"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <el-form-item label="车牌号:">
            <el-input v-model="modalForm.carNo" size="medium"></el-input>
          </el-form-item>
          <el-button type="primary" @click="onsearch" size="medium">查询</el-button>
        </el-form>
        <el-table :data="MaterialsList" border class="table-mainbg" @selection-change="selectChange" max-height="450px">
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
          <el-table-column prop="materialName" label="项目" align="center"></el-table-column>
          <el-table-column prop="sendNums" label="数量" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.sendNums"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
          <el-table-column prop="stockStatusName" label="库存状态" align="center"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible=false" plain>取消</el-button>
          <el-button type="primary" @click="onSubmitFollow('modalForm')">确认添加</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0,
      alarmList: [], //处置预警列表
      form: {
        toObjType:42//收货方门店类型：41门店，42办证公司
      },
      car:{},
      dialogVisible: false,
      rules: {
        agencyName: [{ required: true, message: "代驾姓名不能为空" }],
        agencyMobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/,
            message: "请输入合法手机号",
            trigger: "blur"
          }
        ],
        // completeDate: [{ required: true, message: "约定完成日期不能为空" }],
        // remarks: [{ required: true, message: "备注不能为空" }]
      },
      currentRows: [],
      logistId:'',
      carlist:[],
      MaterialsList:[],
      modalForm:{},
      departmentlist:[],//门店列表
      emplist:[],//员工列表
      vaildcarlist:[],//验车单列表
      materialsCarS:[],
      vaildCarS:[],
    };
  },
  created() {
    this.logistId=this.$route.query.id;
    this.getLogisticsDetail();
    // this.getMaterialsList();//车辆材料/手续 列表查询
    this.getShopsByUserId();//获取部门（门店）
    
  },
  methods: {
    changeLogistType(e){
      if(e==12&&this.emplist.length==0){
        this.postSimpleQueryUserList()//查询简单的用户列表[不分页]
      }
    },
    delProject(index) {
      this.carlist.splice(index, 1)
    },
    materialsSelectionChange(rows){
      this.materialsCarS=rows;
    },
    vaildSelectionChange(rows){
      this.vaildCarS=rows;
    },
    getLogisticsDetail(){//获取详情
      let query={
        "businessId": this.logistId,
        // "curPage": 1,
        "businessType": "logistics",//办证 cert_gh, 客户合同 customer_contact, 商户合同 merchant_contact, 物流/库存 logistics, 车辆/客户材料 materials
        // "pageSize": 10,
        // "businessName": "留存照片"
      }
      this.$api.getLogisticsDetail(this.logistId,this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.car=res.data.logistCarInfo;
            //物流状态：11待发货, 12在途, 13待收货，14已接收，15异常，默认11
            switch (res.data.logistStatus) {
              case 11:
                this.stateType = 0;
                break;
              case 12:
              case 13:
                this.stateType = 1;
                break;
              case 14:
              case 15:
                this.stateType = 2;
                break;
            }
            if(!res.data.logistMaterialsList){
              this.carlist=[];//车辆材料列表
            }else{
              this.carlist=res.data.logistMaterialsList;//车辆材料列表
            }
            if(this.form.toObjType==41){
              this.form.toShopTypeName='门店';
            }else if(this.form.toObjType==42){
              this.form.toShopTypeName='办证公司';
            }
            this.alarmList=res.data.alarmList;//处置预警列表
            // this.imgList=res.data.imgList;//留存照片
            this.vaildcarlist=[{
              carNo:res.data.carNo,
              materialName:'验车单',
              sendNums:1
            }]

          }
        }
      })
    },
    empSelect(row){
      this.form.empMobile=row.mobile;
      this.form.empName=row.name;
    },
    selectChage(e,sate){
      switch(sate){
        case '收货方':
          this.form.toObjType=e;
          break;
        case '门店':
          this.form.toShopId=e.id;
          break;
      }
    },
    postSimpleQueryUserList(departmentName){//查询简单的用户列表[不分页]
      let query={
        isEnabled:0//账号是否启用,启用0，禁用1，默认0
      }
      this.$api.postSimpleQueryUserList(query).then(res=>{
        if(res.code==0){
          this.emplist=res.data;
        }
      })
    },
    onreceive(type) {
      //接收入库
      this.visTilte = type;
      this.ondialogVisible();
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onadd() {
      this.ondialogVisible();
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    onsearch() {
      if(!this.modalForm.carNo){
        this.$message.warning('请输入车牌号再查询！')
        return
      }
      this.getMaterialsList();//车辆材料/手续 列表查询
    },
    selectChange(selection) {
      this.currentRows = selection;
    },
    onSubmitFollow() {
      if(this.currentRows.length==0){
        this.$message('请至少选择一条数据');
        return
      }
      let newArr=this.carlist.concat(this.currentRows);
      let obj={};
      this.carlist =  newArr.reduce((cur,next) => {
          obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
          return cur;
      },[]) //设置cur默认类型为数组，并且初始值为空的数组
      this.ondialogVisible();
    },
    onsave(state) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if(state==1){
            this.updateLogistics('save')
          }else{
            this.updateLogistics('submit')
          }
        } else {
          return false;
        }
      });
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/warehouseKeeper/deliveryManagement"
      });
    },
    getMaterialsList(){//车辆材料/手续 列表查询
      // let query={
      //   carNo:this.modalForm.carNo,
      //   stockStatus:12,//库存状态：11待入库, 12在库, 13在途, 14已出库, 默认11，客户合同签订完成自动进入在库状态，暂缺和缺的项目处于待入库状态
      // }
      this.$api.getStockMaterialsRela(this.modalForm.carNo).then((res)=>{
        if(res){
          if(res.code==0){
            let arr=res.data?res.data:[];
            let newArr=[];
            for(let i=0;i<arr.length;i++){
              let item=arr[i]
              if(item){
                if(item.materialName.indexOf('钥匙')==-1){
                  item.sendNums=1;
                }
                newArr.push(item)
              }
            }
            this.MaterialsList=newArr;
          }else{
            this.MaterialsList=[];
          }
        }
      })
    },
    updateLogistics(operaType){
      let newArr=[]
      for(let i=0;i<this.materialsCarS.length;i++){
        let obj={
          materialsId:this.materialsCarS[i].materialsId,
          sendNums:this.materialsCarS[i].sendNums
        }
        newArr.push(obj)
      }
      let query={
        "carId": this.form.carId,
        "id": this.$store.state.user.userId,//用户ID,主键
        "logistType": this.form.logistType,//物流方式，11代驾，12车开新，13快递
        operaType:operaType,//操作类型：保存 save，提交 submit， save只保存数据，提交会变更状态
        "agencyName": this.form.agencyName,//代驾姓名
        "agencyMobile": this.form.agencyMobile,//代驾手机
        // "fromNo": 1617,//发货人工号
        // "fromName": this.form.fromName,//发货人姓名
        // "fromMobile": this.form.fromMobile,//发货人电话
        "empName": this.form.empName,//员工姓名
        "empMobile": this.form.empMobile,//员工电话
        "expressCompName": this.form.expressCompName,//快递公司
        "expressLogistNo": this.form.expressLogistNo,//快递单编号
        // "fromShopId": 1,//发货方门店ID
        // "fromShopType": 41,//发货方门店类型：41门店，42办证公司
        "fromTime": this.form.fromTime,//发货时间
        "preArriveTime": this.form.preArriveTime,//预计到达时间
        "toShopId": this.form.toShopId,//收货方门店ID
        "toObjType": this.form.toObjType,//收货方门店类型：41门店，42办证公司
        // "toNo": this.form.certCompanyId,//收货人工号
        // "toName": this.form.certCompanyId,//收货人姓名
        "toMobile": this.form.toMobile,//收货人手机号
        "toReceiveTime": this.form.toReceiveTime,//接收时间
        "materialType": 31,//材料类型：31车辆，32身份证，33暂住证，34车辆登记证
        "certCompanyId": this.form.certCompanyId,//办证公司ID
        "materialsFormList": newArr//待发货的材料ID列表
      }
      this.$api.updateLogistics(this.logistId,this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            if(operaType=='save'){
              this.getLogisticsDetail()
            }else{
              this.onroute();
            }
          }
        }
      })
    },
    getShopsByUserId(){//获取部门（门店）
      this.$api.getShopsByUserId().then((res)=>{
        if(res){
          if(res.code==0){
            this.departmentlist=res.data;
          }
        }
      })
    },
  }
};
</script>
<style >
/* .el-textarea__inner{
    height: 100px;
} */
/* .table_carlist thead th .el-checkbox {
  display: none;
} */
.el-form-item {
  margin-bottom: 20px;
}
</style>
<style lang="scss" scoped>
.shipmentBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.headBox {
  background: #ffffff;
  .headTitleTop {
    text-align: center;
    font-size: 24px;
    padding: 30px;
  }
  .headTitleBox {
    height: 40px;
    background: #ededed;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
}
.shipmentBox {
  background-color: #ffffff;
  padding: 0 40px 30px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .conter_text_row {
    margin-bottom: 15px;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 70%;
  }
  .w90 {
    width: 90%;
  }
  .w100 {
    width: 100%;
  }
  .w200 {
    width: 220px;
  }
  .pt30 {
    padding-top: 30px;
  }
  .formRadiogrop_row {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 334px;
      margin-right: 32px;
      height: 195px;
      background: #f5f5f5;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        max-height: 100%;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
  .visHeadTop {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .shipmentBox .w50 {
    width: 100%;
  }
}
</style>