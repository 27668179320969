<template>
    <div class="currencyConterBox">
        <el-row :gutter="24" class="">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>联系电话</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>客户类型</span>
            <el-select v-model="forms.merchantName" size="medium">
              <el-option label="400帮卖" value="1"></el-option>
              <el-option label="非经营范围" value="1"></el-option>
              <el-option label="非卖车咨询" value="1"></el-option>
              <el-option label="未识别" value="1"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>创建时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
         <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>竞价时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt10">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>客户编号</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>优质数据</span>
            <el-select v-model="forms.merchantName" size="medium">
              <el-option label="优质" value="1"></el-option>
              <el-option label="非优质" value="1"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w4">
            <span>服务大小类</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>下次跟进时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
         <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>最后服务时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt10">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>渠道来源</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>检维归属</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w4">
            <span>检测归属</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>成交归属</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="6" class="flex flex_align_center flex_between w5">
            <span>业务状态</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt10 clear">
          <el-button type="primary" @click="onsearch" size="medium" class="fr mr10" plain>查询</el-button>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg mt20" @row-click="onRowClick">
          <el-table-column prop="name" label="业务所属" align="center" ></el-table-column>
          <el-table-column prop="name" label="询价类型" align="center" ></el-table-column>
          <el-table-column prop="name" label="车牌号码" align="center" ></el-table-column>
          <el-table-column prop="name" label="年份车型" align="center" ></el-table-column>
          <el-table-column prop="name" label="是否抵押" align="center" ></el-table-column>
          <el-table-column prop="name" label="行驶证使用性质" align="center" ></el-table-column>
          <el-table-column prop="name" label="车辆评级" align="center" ></el-table-column>
          <el-table-column prop="name" label="归属门店" align="center" ></el-table-column>
          <el-table-column prop="name" label="是否带牌" align="center" ></el-table-column>
          <el-table-column prop="name" label="最高报价" align="center" ></el-table-column>
          <el-table-column prop="name" label="核定车价" align="center" ></el-table-column>
          <el-table-column prop="name" label="中标经纪人" align="center" ></el-table-column>
          <el-table-column prop="name" label="有效期截止" align="center" ></el-table-column>
          <el-table-column prop="name" label="审核时间" align="center" ></el-table-column>
        </el-table>
          <el-pagination class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total">
          </el-pagination>
    </div>
</template>
<script>
export default {
    data(){
        return{
            tabClassify:'first',
            querylist:[{'商户编号':'22',id:1,name:9},{name:'11',id:2}],//
            forms:{
              merchantName:'',
              state:'',
              date:''
            },
            selectValue:'',
            total:0,
            currentPage:1,
            pageSize:10,
            paymentStatuslist:[],//付款状态
        }
    },
    created(){
      this.paymentStatuslist=this.$store.state.setting.paymentStatuslist;
      // this.init();
    },
    methods:{
      init(){
        let query={
          "curPage": this.currentPage,//当前页数
          "pageSize": this.pageSize,
          "taskStatus": ''
        }
        this.$api.postfollowTasklist(query).then((res)=>{
          if(res){
            if(res.code==0){
              this.querylist=res.data.records;
              this.total=res.data.total;
            }else{
               this.querylist=[];
                this.total=0;
            }
          }
        })
      },
      onsearch(){
        this.init()
      },
      handleSizeChange(e){
        this.pageSize = e;
        this.currentPage=1;
        this.init()
      },
      handleCurrentChange(e){
        this.currentPage = e;
        this.init()
      },
      onRowClick(row){
        this.$router.push({
          path:'/finance/merchant/merchantDepositDetails',
          query:{
            id:row.id
          }
        })
      }
    }
}
</script>

<style lang="scss" scoped>
.currencyConterBox{
    padding: 40px;
    .rowbox{
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      .line{
        width:15px;
        height:1px;
        background-color: #999999;
        margin: 0 5px;
      }
    }
}
</style>