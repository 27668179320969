<template>
  <div class="offerBoxBG">
    <el-radio-group v-model="stateType" class="offerButtonBox">
      <el-radio-button label="1" v-if="priceExpired==1||this.carState!=3">报价列表</el-radio-button>
      <el-radio-button label="2">车辆报告</el-radio-button>
    </el-radio-group>
    <div class="offerOneBox" v-show="stateType==1">
      <!--基本信息-->
      <div class="content_modal_textBox w50 mt20">
        <div class="ckxTitle mb10">基本信息</div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>询价类型</td>
              <td>{{res.enquiryTypeName}}</td>
              <td>年份车型</td>
              <td>{{res.productYear}}/{{res.seriesName}}</td>
            </tr>
            <tr>
              <td>审核类型</td>
              <td>{{res.auctionPriceAuditTypeName}}</td>
              <td>车辆评级</td>
              <td>{{res.carGradeResult}}</td>
            </tr>
            <tr>
              <td>使用性质</td>
              <td>{{res.isBusiness}}</td>
              <td>最高价</td>
              <td>{{res.firstAuctionPrice}}</td>
            </tr>
            <tr>
              <td>成交类型</td>
              <td>{{res.tradeTypeName}}</td>
              <td>次高价</td>
              <td>{{res.secondAuctionPrice}}</td>
            </tr>
            <tr>
              <td>场次时间</td>
              <td>{{res.auctionSeatBeginTime}}</td>
              <td>成交价</td>
              <td>{{res.tradePrice}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--价格列表-->
      <div class="content_modal_textBox mt30" v-if="carState!=3">
        <el-radio-group v-model="priceType" class="mb20">
          <el-radio-button :label="1" >有效价格列表</el-radio-button>
          <el-radio-button :label="2">无效价格列表</el-radio-button>
        </el-radio-group>
        <!-- <div class="ckxTitle mb10">有效价格列表 </div> -->
        <el-table :data="querylist" border v-show="priceType===1">
          <el-table-column prop="merchantName" label="车商姓名" align="center"></el-table-column>
          <el-table-column prop="merchantNo" label="车商账号" align="center">
            <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantNo:scope.row.merchantNo}}</template>
          </el-table-column>
          <el-table-column prop="merchantMobile" label="电话" align="center">
            <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantMobile:scope.row.merchantMobile}}</template>
          </el-table-column>
          <el-table-column prop="registerCityName" label="注册地" align="center">
            <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.registerCityName:scope.row.registerCityName}}</template>
          </el-table-column>
          <el-table-column prop="businessCityName" label="经营地" align="center">
            <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.businessCityName:scope.row.businessCityName}}</template>
          </el-table-column>
          <el-table-column prop="auctionTime" label="报价时间" align="center"></el-table-column>
          <el-table-column prop="auctionPrice" label="报价" align="center"></el-table-column>
          <el-table-column prop="tradePrice" label="成交价" align="center"></el-table-column>
          <el-table-column prop="tradeServiceCharge" label="商户服务费" align="center"></el-table-column>
          <el-table-column prop="maintainerName" label="商户维护人" align="center">
            <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.maintainerName:scope.row.maintainerName}}</template>
          </el-table-column>
          <el-table-column prop="sellerAssistantName" label="销售助理" align="center"></el-table-column>
          <el-table-column prop="tradingMarketName" label="所属市场" align="center">
            <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.tradingMarketName:scope.row.tradingMarketName}}</template>
          </el-table-column>
          <el-table-column prop="auctionPriceConfirmStatusName" label="商户确认" align="center"></el-table-column>
        </el-table>
        <el-table :data="invalidAuctionList" border v-show="priceType===2">
          <el-table-column prop="merchantName" label="姓名" align="center">
            <!-- <template slot-scope="scope">{{scope.row.merchant.merchantName}}</template> -->
          </el-table-column>
          <el-table-column prop="merchantNo" label="账号" align="center">
            <template slot-scope="scope">{{scope.row.merchant.merchantNo}}</template>
          </el-table-column>
          <el-table-column prop="merchantMobile" label="电话" align="center">
            <template slot-scope="scope">{{scope.row.merchant.merchantMobile}}</template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="积分" align="center"></el-table-column> -->
          <el-table-column prop="auctionTime" label="报价时间" align="center"></el-table-column>
          <el-table-column prop="auctionPrice" label="报价" align="center"></el-table-column>
          <el-table-column prop="breakAmount" label="违约费" align="center"></el-table-column>
          <el-table-column prop="auctionPriceConfirmStatusName" label="商户确认" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <div v-show="stateType==2">
      <Report pstate="3" :pid="checkReportId" ref="report" class="reportBoxCont" :imgVis="true"></Report>
    </div><!--pstate=3代表查看--->
  </div>
</template>
<script>
import Report from '../testReviewer/auditReport';//检测报告
export default {
  components:{
    Report
  },
  data() {
    return {
      res:{},//
      stateType:1,//1报价，2报告
      carState:2,//1全部权限，2部分受限查看，3受限查看
      carAuctionId:'',//
      reportId:'',//检测id
      querylist:[],
      checkReportId:'',
      priceExpired:'',//价格过期标志 0-未过期 1-已过期
      invalidAuctionList:[],//无效报价列表
      priceType:1,//1有效报价，2无效报价
    };
  },
  created() {
    if(this.$route.query.stateType) this.stateType= this.$route.query.stateType;//切换tab
    if(this.$route.query.carState) this.carState= Number(this.$route.query.carState);//车辆查看权限标志
    if(this.$route.query.carAuctionId) this.carAuctionId = this.$route.query.carAuctionId;//竞拍id
    if(this.$route.query.checkReportId) this.checkReportId = this.$route.query.checkReportId;//检测报告id
    if(this.$route.query.priceExpired) this.priceExpired = this.$route.query.priceExpired;//价格过期标志
    this.getAuctionInfo();
    this.getDetails()
    this.getAuctionPriceInvalidList()//根据竞拍Id获取无效价格列表
  },
  methods: {
    getAuctionInfo(){//根据竞拍Id获取竞拍详情
      this.$api.getAuctionInfo(this.carAuctionId).then(res=>{
        if(res.code==0){
          this.res=res.data;
        }
      })
    },
    async getDetails(){
      let res={}
      //1全部权限，2部分受限查看，3受限查看
      switch(this.carState){
        case 1:
          res = await this.$api.getAuctionPriceValidList(this.carAuctionId)
          break;
        case 2:
          res = await this.$api.getAuctionPriceThreeList(this.carAuctionId)
          break;
      }
      if(res.code==0){
        this.querylist=res.data;
      }else{
        this.querylist=[];
      }
    },
    getAuctionPriceInvalidList(){//根据竞拍Id获取无效价格列表
      this.$api.getAuctionPriceInvalidList(this.carAuctionId).then(res=>{
        if(res.code===0){
          this.invalidAuctionList=res.data;
        }else{
          this.invalidAuctionList=[]
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.offerBoxBG{
  // padding-top: 20px;
  .offerOneBox{
    padding:60px 40px 0;
  }
  .offerButtonBox{
    // margin:30px 30px 10px;
    position: fixed;
    width: 100%;
    z-index: 5;
    padding-left:40px;
    background: #ffffff;
    padding-top: 20px;
  }
  .reportBoxCont{
    padding-top:60px;
  }
}
@media (max-width: 768px) {
  .offerBoxBG{
    width:896px;
  }
}
</style>