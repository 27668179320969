<template>
  <div class="lendingBoxBG">
    <div class="lendingBox">
      <!--物流信息-->
      <div class="content_title_Box w50">
        <!-- <div class="head_top_title">
          <i></i>
          <span>物流信息</span>
        </div>-->
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          :rules="rules"
          :hide-required-asterisk="true"
          class="formCustomRow"
          size="medium"
        >
          <el-form-item label="领取人身份:" class="mb10">
            <p v-if="stateType!=2">{{form.receiverTypeName}}</p>
            <el-select
              v-model="form.receiverType"
              placeholder="请选择领取人身份"
              size="medium" filterable clearable
              class="w300" v-else>
              <el-option label="车商" :value="11"></el-option>
              <el-option label="检测师" :value="12"></el-option>
              <el-option label="泊车员" :value="13"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="领取人:" class="mb10">
            <p v-if="stateType!=2">{{form.receiverName}}</p>
            <template v-else>
              <el-select v-if="form.receiverType==12"
                v-model="form.receiverName"
                placeholder="请选择领取人"
                size="medium" filterable clearable
                class="w300">
                <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.name"></el-option>
              </el-select>
              <el-input v-else v-model="form.receiverName" class="w300"></el-input>
            </template>
            
          </el-form-item>
          <el-form-item label="手机号码:" class="mb10">
            <p v-if="stateType!=2">{{form.receiverMobile}}</p>
            <el-input v-else v-model="form.receiverMobile" class="w300"></el-input>
          </el-form-item>
          <el-form-item label="领取时间:" class="mb10">
            <p v-if="stateType!=2">{{form.getTime}}</p>
            <el-date-picker
              v-model="form.getTime"
              type="datetime"
              class="w300"
              placeholder="选择领取时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-else
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="预计归还时间:" prop="preGiveTime">
            <p v-if="stateType==0">{{form.preGiveTime}}</p>
            <el-date-picker
              v-model="form.preGiveTime"
              type="datetime"
              placeholder="选择预计归还时间"
              class="w300"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-else
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="处置备注:" class="mb10" v-if="stateType==1">
            <el-input type="textarea" v-model="form.alarmRemark" class="w300"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="camera_outer">
        <div v-if="imgSrc" class="phontoBox">
          <img :src="imgSrc"/>
        </div>
        <div v-else>
          <video
            id="videoCamera"
            :width="videoWidth"
            :height="videoHeight"
            autoplay
            class="phontoBox"
          ></video>
          <canvas
            style="display:none;"
            id="canvasCamera"
            :width="videoWidth"
            :height="videoHeight"
            class="phontoBox"
          ></canvas>
        </div>
        <div class="photobuttonBox" v-if="stateType==2">
          <el-button @click="setRemake()" type="primary" plain class="mt20" v-if="imgSrc">重拍</el-button>
          <el-button @click="setImage()" type="primary" plain class="mt20" v-else>拍照</el-button>
        </div>
      </div> -->

      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onroute" v-if="stateType!=0">取消</el-button>
          <el-button type="primary" @click="onsave(1)" v-if="stateType==0">归还入库</el-button>
          <el-button type="primary" @click="onsave(2)" v-if="stateType==1">确认</el-button>
          <el-button type="primary" @click="onsave(3)" v-if="stateType==2">确认出库</el-button>
        </div>
      </div>

      <el-dialog
        title="请选择入库货位"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="680px"
      >
        <p class="visHeadTop">钥匙入库</p>
        <el-form
          ref="modalform"
          :model="modalform"
          :rules="rulesForm"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <el-form-item label="仓库:" prop="stockObjIn">
            <el-select v-model="modalform.stockObjIn" placeholder="请选择仓库" size="medium" class="w150 mr10" value-key="id" @change="selectChange($event,1)" @visible-change="handleChangeFlag">
                <el-option v-for="item in stockObjList" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable===1?true:false"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="货区:" prop="stockObjArea">
            <el-select v-model="modalform.stockObjArea" placeholder="请选择货区" size="medium" class="w150 mr10" value-key="id" @change="selectChange($event,2)" @visible-change="handleChangeFlag">
                <el-option v-for="item in goodsArealist" :key="item.id" :label="item.name" :value="item" :disabled="item.isEnable===1?true:false"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="货位:" class="nomr" prop="stockObjLocationId">
            <el-select v-model="modalform.stockObjLocationId" placeholder="请选择货位" size="medium" class="w150">
                <el-option v-for="item in placelist" :key="item.id" :label="item.name" :value="item.id" :disabled="item.isEnable===1?true:false">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6;font-size:12px">{{ item.isFree===1?'已占用':'' }}</span>
                </el-option>
              </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmitFollow('modalform')">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 3,//state=0;归还钥匙,1处置预警,2钥匙借用,3仅查看
      form: {
        preGiveTime:'',
        receiverType:'',
      },
      dialogVisible: false,
      rules: {
        name: [{ required: true, message: "代驾姓名不能为空" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/,
            message: "请输入合法手机号",
            trigger: "blur"
          }
        ],
        preGiveTime: [{ required: true, message: "预计归还时间不能为空" }],
        remarks: [{ required: true, message: "备注不能为空" }]
      },
      rulesForm:{
        stockObjIn: [{ required: true, message: "仓库不能为空" , trigger: 'change' }],
        stockObjArea: [{ required: true, message: "货区不能为空" , trigger: 'change' }],
        stockObjLocationId: [{ required: true, message: "货位不能为空" , trigger: 'change' }]
      },
      videoWidth: 342,
      videoHeight: 255,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      carKeyId:'',
      imgList:[],
      changeFlag:'',
      modalform:{
        stockObjIn: '',
        stockObjArea: '',
        stockObjLocationId: '',
      },
      goodsArealist:[],
      placelist:[],
      stockObjList:[],
      userlist:[],//领取人列表
    };
  },
  watch:{
    'modalform.stockObjIn'(){
      if( this.changeFlag ){
        this.modalform.stockObjArea=null;
      }
    },
    'modalform.stockObjArea'(){
      if( this.changeFlag ){
        this.modalform.stockObjLocationId=null;
      }
    },
  },
  created() {
    //state=0;归还钥匙,1处置预警,2钥匙借用
    if(this.$route.query.state) this.stateType = Number(this.$route.query.state);
    this.carKeyId=this.$route.query.id;
    if(this.stateType!==2){
      this.getCarKeyDetail();//获取详情
    }
    
    this.getStockObjList()//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
    this.queryUserListByRoleAndDepart();//获取领取人，选择检测师展示
  },
  mounted() {
    //暂时注释，后期会使用到
    // if (this.stateType == 2) {
    //   this.getCompetence(); //调用摄像头
    // }
  },
  methods: {
    getCarKeyDetail(){//获取详情
      let query={
        "businessId": this.carKeyId,
        // "curPage": 1,
        "businessType":'car_key',// "办证 cert_gh, 客户合同 customer_contact, 商户合同 merchant_contact, 物流/库存 logistics, 车辆/客户材料 materials ...等等，也可以根据具体的业务自己取名",
        // "pageSize": 10,
        "businessName": "钥匙出借"
      }
      this.$api.getCarKeyDetail(this.carKeyId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data?res.data:{};
            //state=0;归还钥匙,1处置预警,2钥匙借用
            //keyStatus 钥匙状态：1待领取，2领取中/待归还，3已归还，4钥匙码已失效, 默认1
            // if(this.$route.query.state==2&&res.data.keyStatus===2){
            //   this.stateType=3;
            // }else{
            //   this.stateType = this.$route.query.state;
            // }
            this.$nextTick(()=>{
              this.$refs['form'].clearValidate();
            })
            // this.imgList=res.data.imgList;
          }
        }
      })
    },
    handleChangeFlag($event){
      this.changeFlag = $event;
    },
    selectChange($event,state){
      switch(state){
        case 1:
          this.goodsArealist=$event.stockObjAreaList;
          this.modalform.stockObjInId=$event.id;
          break;
        case 2:
          this.placelist=$event.stockObjLocationList;
          this.modalform.stockObjAreaId=$event.id;
          break;
      }
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitFollow(formdata) {
      this.$refs[formdata].validate(valid => {
        if (valid) {
          let query={
            "carId": this.form.carId,
            "handlerType": 'key_give',//处理类型：借用key_borrow，归还key_give；
            "keyStockObjInId": this.modalform.stockObjInId,//车钥匙入库仓库ID
            "keyStockObjAreaId": this.modalform.stockObjAreaId,//车钥匙入库仓库货区ID
            "keyStockObjLocationId": this.modalform.stockObjLocationId,//车钥匙入库仓库货位ID
            // "keyStockObjOutId": 1//车钥匙出库仓库ID
          }
          this.postCarKeyHandling(query);//车辆钥匙 借用key_borrow，归还key_give 等处理；
        } else {
          return false;
        }
      });
    },
    onsave(type) {
      if (type == 1) {
        this.$nextTick(() => {
          if(this.$refs["modalform"]){
            this.$refs["modalform"].resetFields();
          }
        })
        this.ondialogVisible();
        return;
      } else if (type == 3) {//确认出库
        //暂时注释后期需要使用
        // if (!this.imgSrc) {
        //   this.$message("请拍照！");
        //   return;
        // }else{
        //   // let obj = new FormData();
        //   // obj.append("mFiles", this.imgSrc);
        //   // obj.append("mFiles", this.thisCancas.toDataURL());
        //   // obj.append("businessId", this.carKeyId);
        //   // obj.append("businessName", '钥匙出借');
        //   // obj.append("businessType", "car_key");
        //   // this.postFileUpload(obj);
        // }
         let query={
          "carId": this.carKeyId,
          "handlerType": 'key_borrow',//处理类型：借用key_borrow，归还key_give；
          "receiverType": this.form.receiverType,//领取人类型: 11车商，12检测师，13泊车员
          "receiverName": this.form.receiverName,//领取人名称
          "receiverMobile": this.form.receiverMobile,//领取人电话
          "getTime": this.form.getTime,//领取时间
          "preGiveTime": this.form.preGiveTime//预计归还时间
        }
        this.postCarKeyHandling(query);//车辆钥匙 借用key_borrow，归还key_give 等处理；
      }else if(type==2){
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.postAlarmAdd()//车辆钥匙-处置预警 新增
          } else {
            return false;
          }
        });
      }
    },
    onroute() {
      this.bus.$emit("closeSelected");
      // this.$router.push({
      //   path: "/warehouseKeeper/lendingManagement"
      // });
    },
    // 调用权限（打开摄像头功能）
    getCompetence() {
      var _this = this;
      _this.thisCancas = document.getElementById("canvasCamera");
      _this.thisContext = this.thisCancas.getContext("2d");
      _this.thisVideo = document.getElementById("videoCamera");
      _this.thisVideo.style.display = "block";
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)"
        }
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          _this.thisVideo.onloadedmetadata = function() {
            _this.thisVideo.play();
          };
        })
        .catch(err => {
          console.log(err);
        });
    },
    //  绘制图片（拍照功能）
    setImage() {
      var _this = this;
      // canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL("image/png");
      _this.imgSrc = image; //赋值并预览图片
      this.stopNavigator();
    },
    setRemake() {
      //重拍
      this.imgSrc = "";
      this.$nextTick(() => {
        this.getCompetence();
      });
    },
    // 关闭摄像头
    stopNavigator() {
      if(this.thisVideo.srcObject){
        this.thisVideo.srcObject.getTracks()[0].stop();
      }
    },
    // base64转文件，此处没用到
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    postCarKeyHandling(query){//车辆钥匙 借用key_borrow，归还key_give 等处理；
      this.$api.postCarKeyHandling(query).then((res)=>{
        if(res){
          if(res.code==0){
             this.onroute();
             this.ondialogVisible();
          }
        }
      })
    },
    getStockObjList(){//仓库列表查询，参数全空查全部，同时联动查询仓库，货区，货位
      this.$api.getStockObjList({}).then((res)=>{
        if(res){
          if(res.code==0){
            this.stockObjList=res.data;
          }
        }
      })
    },
    postAlarmAdd(){//车辆钥匙-处置预警 新增
      let query={
        "carId": this.form.carId,
        "newPreGiveTime": this.form.preGiveTime,//最新预计归还时间
        "alarmRemark": this.form.alarmRemark//预警处置备注
      }
      this.$api.postAlarmAdd(query).then((res)=>{
        if(res){
          if(res.code==0){
             this.onroute();
          }
        }
      })
    },
    postFileUpload(query) {
      //上传图片
      this.$api.postFileUpload(query).then(res => {
        if (res) {
          if (res.code == 0) {
            this.getCertDetailsByTaskId(); //获取详情
            this.$message({
              type: "success",
              message: "上传成功"
            });
          } else {
            this.$message({
              type: "error",
              message: "上传失败"
            });
          }
        }
      });
    },
    queryUserListByRoleAndDepart(){//根据角色和部门查询用户
      let query={
        roleName:'检测师',
        departmentName:'交付中心'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
  },
  // destroyed(){
  //   if (this.stateType == 2) {
  //     this.stopNavigator();
  //   }
  // }
};
</script>
<style >
/* .el-form-item {
  margin-bottom: 20px;
} */
</style>
<style lang="scss" scoped>
.lendingBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.lendingBox {
  background-color: #ffffff;
  padding: 30px 40px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .conter_text_row {
    margin-bottom: 15px;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .nomr {
    margin-right: 0;
  }
  .phontoBox {
    width: 342px;
    height: 255px;
    border: 1px solid #dddddd;
    margin-top: 50px;
  }
  .photobuttonBox {
    width: 342px;
    text-align: center;
  }
  .visHeadTop {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .shipmentBox .w50 {
    width: 100%;
  }
}
</style>