<template>
  <div class="fieldSetting">
    <!-- <el-select v-model="cityName" multiple placeholder="请选择" class="w300">
      <el-option
        v-for="item in citylist"
        :key="item.code"
        :label="item.name"
        :value="item.code">
      </el-option>
    </el-select> -->
    <el-tabs v-model="activeName" @tab-click="postSeatlist">
      <el-tab-pane label="常规拍" name="first">
        <el-row class="normal flex" :gutter="20">
          <el-col  :xl="12" :lg="24" class="mb20" v-for="(item,index) in formList" :key="index" style="padding-right:40px;">
            <div class="flex auctionNumber">
              <div class="leftTitle" @click="onHead(item)"><span class="leftTitleSpan">{{item.seatName}}</span></div>
              <div class="rightMain"><AuctionForm :formData="item" @onButtom="onButtom" @updateEnableSeat="updateEnableSeat" :brandlist="brandlist" :channelList="channelList" :citylist="citylist" :checkList="checkList"></AuctionForm></div>
              <div class="closeAuction" @click="closeAuction(index)"><i class="el-icon-close"></i></div>
            </div>
          </el-col>
          <el-col :xl="12" :lg="24" class="flex flex_center flex_align_center"><el-button type="primary" icon="el-icon-circle-plus-outline" @click="addform">添加新的场次</el-button></el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="及时拍" name="second">
        <el-form :model="formData" :rules="rules" ref="formData" label-width="120px" :hide-required-asterisk="true" >
          <el-form-item label="竞拍开始时间: " prop="auditSuccessAuctionBeginLength">
            <el-input class="itemWidth w500" type="number" v-model="formData.auditSuccessAuctionBeginLength">
              <template slot="prepend">审核通过后</template>
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
          <el-form-item label="竞拍时长" prop="auctionTimeLength">
            <el-input class="itemWidth w500" type="number" v-model="formData.auctionTimeLength">
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
          <el-form-item label-width="110px" label="车辆询价类型">
            <el-checkbox-group v-model="formData.enquiryTypes" @input="onInput">
              <el-checkbox v-for="item in checkList" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onButtom(1)">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
    title="提示"
    :visible.sync="dialogVisible" width="350px">
    <p class="mb15" >请输入场次名称：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onHead()">确定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import AuctionForm from '@/components/vehicle/auctionForm'
export default {
  components: {
    AuctionForm
  },
  data() {
    return {
      dialogVisible:false,
      formCar:'',
      activeName: 'first',
      // cityName:'',
      formList: [],
      formData: {
        auctionTimeLength: '',duraauditSuccessAuctionBeginLengthtion: '',enquiryTypes: []
      },//及时拍
      checkList: [],//询价类型
      rules: {
        auctionTimeLength: [{ required: true, message: '请输入', trigger: 'blur' },],
        auditSuccessAuctionBeginLength: [{required: true, message: '请选择日期', trigger: 'change'}]
      },
      citylist:[],//省市列表
      brandlist:[],//车辆品牌列表
      channelList:[],//渠道列表
      itemRow:{},//当前数据
    }
  },
  created(){
    this.checkList=this.$store.state.setting.checkTypeList1;
    
    this.postSeatlist()//获取列表
    //仅仅获取所有品牌
    this.getBrandList()
    this.customerQueryChannel()//根据条件查询渠道
    this.getCarNoLocationShort()//车牌所在地缩写查询（车牌归属）
  },
  methods: {
    getBrandList(){//仅仅获取所有品牌
      this.$api.getBrandList().then(res=>{
        if(res.code==0){
          this.brandlist=res.data;
        }
      })
    },
    getCarNoLocationShort(){//车牌所在地缩写查询（车牌归属）
      this.$api.getCarNoLocationShort().then(res=>{
        if(res.code==0){
          this.citylist=res.data;
        }
      })
    },
    postSeatlist(){
      let seatType=21;//竞拍场次类型：21常规拍，22及时拍
      switch(this.activeName){
        case 'first':
          seatType=21
          break;
        case 'second':
          seatType=22
          break;
      }
      this.$api.postSeatlist(seatType).then(res=>{
        if(res.code==0){
          if(seatType==21){
            let arr=[]
            for(let i=0;i<res.data.length;i++){
              let item=res.data[i]
              let enquiryType=item.enquiryType?item.enquiryType.split(','):[item.enquiryType]
              let enquiryTypeA=enquiryType.map(a=>{
                return +a
              })
              let obj={
                id:item.id,
                seatName:item.seatName,
                inSeatEndLength: item.inSeatEndLength,
                auctionBeginTime: item.auctionBeginTime,
                businessOwners: item.businessOwner==3?[1,2]:item.businessOwner?[item.businessOwner]:[],//车辆业务归属
                auctionTimeLength: item.auctionTimeLength,
                brandIds: item.brandIds?Number(item.brandIds[0]):'',
                maxAddedCar: item.maxAddedCar,
                plateOwnerCityName: item.plateOwnerCityName,
                sourceType: item.sourceFirstTypeId?[item.sourceFirstTypeId,item.sourceSecondTypeId]:[],//渠道来源
                isEnable: item.isEnable==1?false:true,
                maxYears:item.maxYears,
                enquiryTypes:enquiryTypeA,//车辆询价类型
              }
              arr.push(obj)
            }
            this.formList=arr;
            // this.formList=res.data
          }else{
            this.formData=res.data.length>0?res.data[0]:{}
             let enquiryType=this.formData.enquiryType?this.formData.enquiryType.split(','):[]
              let enquiryTypeA=enquiryType.map(a=>{
                return +a
              })
            this.formData.enquiryTypes=enquiryTypeA
          }
        }
      })
    },
    closeAuction(index) {
      this.formList.splice(index, 1)
    },
    onHead(row){
      if(row){
        this.dialogVisible=true;
        this.itemRow=JSON.parse(JSON.stringify(row))
      }else{
        if(!this.formCar){
          this.$message.warning('请输入场次名称！')
          return
        }
        for(let i=0;i<this.formList.length;i++){
          let item=this.formList[i]
          if(item.id===this.itemRow.id){
            item.seatName=this.formCar;
            this.dialogVisible=false;
            return
          }
        }
      }
    },
    addform() {
      // let newobj={};
      let num=this.formList.length+1;
      // if(this.formList.length>0){
      //   newobj=this.formList[this.formList.length-1];
      //   // let numt=newobj.seatName.replace(/^(\s|第)+|(\s|场)+$/g, '')
      //   // num=this.$$.ChineseToNumber(numt)+1;
      // }
      // let num=this.$$.getTody('年')
      // let numtext=this.$$.noToChinese(num)
      this.formList.push({seatName:`第${num}场`,inSeatEndLength: '',auctionBeginTime: '',businessOwners: [],auctionTimeLength: '',brandIds: '',maxAddedCar: '',plateOwnerCityName: '',sourceType: '',isEnable: true,maxYears:'',enquiryTypes:[],})
    },
    onButtom(state,obj){
      if(this.activeName=='first'){//常规拍
        switch(state){//state  1保存 2修改  3删除
          case 1:
            this.postAuctionCommonAdd(obj)
            break;
          case 2:
            this.updateCommonSeat(obj.id,obj)
            break;
          case 3:
            if(obj.id){
              this.deleteSeat(obj.id)
            }else{
              this.formList.splice(this.formList.length-1,1)
            }
            break;
        }
      }else{//及时拍
        this.$refs['formData'].validate((valid) => {
          if (valid) {
            this.updateTimelySeat()
          }
        });
      }
    },
    postAuctionCommonAdd(obj){//竞拍场次【常规拍】 新增
      let bus=obj.businessOwners;
      let abus=null
      if(bus) if(bus.length==2){
        abus=3
      }else{
        abus=String(bus)
      }
      // let today=this.$$.getTody()
      let query={
        "seatName": obj.seatName,//场次名称
        "auctionBeginTime": `${obj.auctionBeginTime}`,//竞拍开始时间
        "inSeatEndLength": obj.inSeatEndLength,//进场截止时长
        "auctionTimeLength": obj.auctionTimeLength,//竞拍时长
        "businessOwner":abus,//车辆业务归属：1门店，2渠道
        "brandIds": obj.brandIds?[obj.brandIds]:[0],//车辆品牌Id，如果不传==全部
        "maxAddedCar": obj.maxAddedCar,//上架车辆上限[本场次最大的展示车辆数，超过这个上限自动进入下一场]
        "plateOwnerCityName": obj.plateOwnerCityName,//车牌归属城市ID
        // "sourceType": obj.sourceType?obj.sourceType[obj.sourceType.length-1]:'',//渠道来源类型，101shop，102channel,默认101
        sourceFirstTypeId:obj.sourceType?obj.sourceType[0]:0,
        sourceFirstTypeName:obj.pathLabels?obj.pathLabels[0]:'',
        sourceSecondTypeId:obj.sourceType?obj.sourceType[1]:0,
        sourceSecondTypeName:obj.pathLabels?obj.pathLabels[1]:'',
        "maxYears": obj.maxYears,//车辆年限
        "enquiryTypes": obj.enquiryTypes.length>0?obj.enquiryTypes:'',//车辆询价类型
        // "isEnable": obj.isEnable?0:1,//是否有效
        // "seatCityIds": [9],//竞拍场次所在城市ID
      }
      this.$api.postAuctionCommonAdd(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.postSeatlist()
          this.$message.success(res.message)
        }
      })
    },
    customerQueryChannel() {
      this.$datadic.getCustomerQueryChannel().then(res => {
        this.channelList = res;
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    updateEnableSeat(auctionSeatId,type){//竞拍场次【常规拍】 启用/暂停
      this.$api.updateEnableSeat(auctionSeatId,type).then(res=>{
        if(res.code==0){
          this.postSeatlist()
          this.$message.success(res.message)
        }
      })
    },
    // getRegionByConditions(){//获取省
    //   let query={
    //     level:1
    //   }
    //   this.$api.getRegionByConditions(query).then(res=>{
    //     if(res.code==0){
    //       this.citylist=res.data
    //     }
    //   })
    // },
    updateCommonSeat(auctionSeatId,obj){//竞拍场次【常规拍】 修改
      // let today=this.$$.getTody()
      let bus=obj.businessOwners;
      let abus=null
      if(bus) if(bus.length==2){
        abus=3
      }else{
        abus=String(bus)
      }
      let query={
        "seatName": obj.seatName,//场次名称
        "auctionBeginTime": `${obj.auctionBeginTime}`,//竞拍开始时间
        "inSeatEndLength": obj.inSeatEndLength,//进场截止时长
        "auctionTimeLength": obj.auctionTimeLength,//竞拍时长
        "businessOwner": abus,//车辆业务归属：1门店，2渠道
        "brandIds": obj.brandIds?[obj.brandIds]:[0],//车辆品牌Id，如果不传==全部
        "maxAddedCar": obj.maxAddedCar,//上架车辆上限[本场次最大的展示车辆数，超过这个上限自动进入下一场]
        "plateOwnerCityName": obj.plateOwnerCityName,//车牌归属城市ID
        // "sourceType": obj.sourceType,//渠道来源类型，101shop，102channel,默认101
        sourceFirstTypeId:obj.sourceType?obj.sourceType[0]:0,
        sourceFirstTypeName:obj.pathLabels?obj.pathLabels[0]:'',
        sourceSecondTypeId:obj.sourceType?obj.sourceType[1]:0,
        sourceSecondTypeName:obj.pathLabels?obj.pathLabels[1]:'',
        "maxYears": obj.maxYears,//车辆年限
        "enquiryTypes": obj.enquiryTypes.length>0?obj.enquiryTypes:'',//车辆询价类型
        "isEnable": obj.isEnable?0:1,//是否有效
        // "seatCityIds": [9],//竞拍场次所在城市ID
      }
      this.$api.updateCommonSeat(auctionSeatId,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.postSeatlist()
          this.$message.success(res.message)
        }
      })
    },
    updateTimelySeat(){//竞拍场次【及时拍】 修改
      let query={
        "auditSuccessAuctionBeginLength": this.formData.auditSuccessAuctionBeginLength,//审核通过后，可以开始竞拍的时长[单位：分钟]
        "auctionTimeLength": this.formData.auctionTimeLength,//竞拍时长[
        "enquiryTypes": this.formData.enquiryTypes.length>0?this.formData.enquiryTypes:'',//车辆询价类型
      }
      this.$api.updateTimelySeat(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.postSeatlist()
          this.$message.success(res.message)
        }
      })
    },
    deleteSeat(id){//竞拍场次 删除
      this.$api.deleteSeat(id).then(res=>{
        if(res.code==0){
          this.postSeatlist()
          this.$message.success(res.message)
        }
      })
    },
    onInput(){
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.fieldSetting{
  padding: 20px 40px;
  .w500{
      width:500px;
    }
  .normal{
    flex-wrap: wrap;
    .auctionNumber{
      align-items: stretch;
      position: relative;
      min-width: 672px;
      .leftTitle{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #409EFF;
        color: white;
        width: 90px;
        line-height: 36px;
        padding: 0 36px;
        .leftTitleSpan{
          // -webkit-writing-mode:tb-rl;
          // writing-mode: tb-rl;
          // writing-mode:vertical-rl;
          width:10px;
          text-align: center;
          white-space:normal; word-break:break-all;
        }
      }
      .rightMain{
        flex: 1;
        padding: 30px 30px 10px;
        border: 1px solid #E8E8E8;
        border-left: none;
      }
      .closeAuction{
        position: absolute;
        top: 0;
        right: -30px;
        width: 30px;
        height: 30px;
        background: #f5f5f5;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>