<template>
    <div class="currencyConterBox">
        <el-row :gutter="24" class="">
          <el-col :span="7" class="flex flex_align_center flex_between w3">
            <span>创建时间</span>
            <el-date-picker
              v-model="forms.create"
              type="daterange" clearable
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>

          <el-col :span="7" class="flex flex_align_center flex_between w3">
            <span>使用时间</span>
            <el-date-picker
              v-model="forms.usetime"
              type="daterange" clearable
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w2">
            <span>门店</span>
            <el-select v-model="forms.shopIds" size="medium" clearable>
              <el-option v-for="item in CollectShoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w2">
            <span>状态</span>
            <el-select v-model="forms.cardStatuses" size="medium" clearable>
              <el-option label="全部" value=""></el-option>
              <el-option label="未领取" :value="51"></el-option>
              <el-option label="未使用" :value="52"></el-option>
              <el-option label="已使用" :value="53"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt20 flex flex_end">
          <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
          <el-button type="primary" @click="onAdd" size="medium" plain>新建</el-button>
          <el-button type="primary" @click="onImport" size="medium" plain>导入</el-button>
          <el-button type="primary" @click="onStock" size="medium" plain>库存设置</el-button>
          <el-button type="primary" @click="onCollect" size="medium" plain>领用</el-button>
          <el-button type="primary" @click="onExport" size="medium" plain class="mr10">导出报表</el-button>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg mt20">
          <el-table-column prop="cardNo" label="卡号" align="center" ></el-table-column>
          <el-table-column prop="amount" label="金额" align="center" ></el-table-column>
          <el-table-column prop="shopName" label="门店" align="center" ></el-table-column>
          <el-table-column prop="creator" label="创建人" align="center" ></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" ></el-table-column>
          <el-table-column prop="cardStatus" label="状态" align="center" >
            <template slot-scope="scope">{{scope.row.cardStatus==52?'未使用':scope.row.cardStatus==53?'已使用':'未领取'}}</template>
          </el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center" ></el-table-column>
          <el-table-column label="操作" align="center" width="180" >
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onUpdate(scope.row)"
                size="mini"
                class="group-custom"
              >修改</el-button>
              <el-button
                type="primary"
                @click="deleteOkCard(scope.row)"
                size="mini"
                class="group-custom"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
          <el-pagination class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total">
          </el-pagination>

           <el-dialog
            :title="`${title}`"
            :visible.sync="dialogVisible">
            <el-form ref="itemRow" :model="itemRow" :rules="rules" label-width="80px" size="medium" v-show="modalType==0||modalType==1">
              <el-form-item label="卡号" prop="cardNo">
                <el-input v-model="itemRow.cardNo" class="w200"></el-input>
              </el-form-item>
              <el-form-item label="金额" prop="amount">
                <el-input v-model="itemRow.amount" class="w200"></el-input>
              </el-form-item>
              <el-form-item label="领取门店">
                <el-select v-model="itemRow.shopId" placeholder="请选择门店" class="w200" clearable>
                  <el-option v-for="item in CollectShoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div v-show="modalType==2" class="modalBox">
              <el-button type="primary" plain class="mb10 pointer" size="mini" @click="downloadFile">（下载导入模版）</el-button>
              <el-upload
                ref="upload"
                :auto-upload="false"
                :show-file-list="false"
                action=""
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :http-request="httpRequest"
                :on-change="handleChange">
                <el-input v-model="uploadText" size="small" readonly>
                   <template slot="append">浏览</template>
                </el-input>
              </el-upload>
            </div>
            <div v-show="modalType==3" class="conterModal">
              <p v-for="item in CollectShoplistLimit" :key="item.id" class="modalTitle">门店，{{item.shopName}}，库存上限，<el-input v-model.number="item.storeMaxAmount" size="small" class="w100" @input="forceUpdate"></el-input></p>
            </div>
            <div v-show="modalType==4" >
              <p >门店：
                <el-select v-model="itemRow.storeid" placeholder="请选择门店" class="w150 mr10"  size="small" @change="changeStore" @input="forceUpdate">
                  <el-option v-for="item in CollectShoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
                </el-select>该门店现剩余<span class="tipsColor ml10 mr10">{{NotUsed}}</span>张OK卡未使用，可领<span class="tipsColor mr10 ml10">{{NotReceived}}</span>张</p>
              <div class="checkBox">
                <el-checkbox-group v-model="checkList">
                   <el-checkbox v-for="item in oklist" :key="item.id" :label="item.id" class="mb10">{{item.cardNo}}（{{item.amount}}元）</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false" size="medium">取 消</el-button>
              <el-button type="primary" @click="onSaveModal" size="medium">确 定</el-button>
            </span>
          </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return{
            querylist:[],//
            forms:{
              cardStatuses:51,
              create:'',
              usetime:'',
              shopIds:''
            },
            rules:{
              cardNo:[{ required: true, message: '不能为空！', trigger: 'blur' }],
              amount:[{ required: true, message: '不能为空！', trigger: 'blur' }],
            },
            selectValue:'',
            total:0,
            currentPage:1,
            pageSize:10,
            dialogVisible:false,
            itemRow:{
              name:''
            },//当前选中的数据
            title:'新建OK卡',
            modalType:0,//0-新建，1-修改，2-导入，3-库存设置，4-领用
            shoplist:[0,1,2,1,1,1,1,1,1,1,1,1],//库存门店列表
            checkList:[],//选中的ok卡值
            uploadText:'',
            userlist:{},
            shopIdlist:[],//门店列表
            CollectShoplist:[],//领用门店
            CollectShoplistLimit:[],//库存上限
            oklist:[],//门店的ok卡
            NotReceived:'-',//未领取
            NotUsed:'-',//未使用
            dowurl:'https://newsys-test02.oss-cn-shanghai.aliyuncs.com/okCard/okCard.xlsx',//下载模版链接
        }
    },
    created(){
      this.getUser()//获取用户数据
      this.init();
      this.getShopList()//获取所有门店
    },
    methods:{
      init(page){
        this.currentPage=page?page:this.currentPage;
        let query={
          // "cardNo": 94281658295881020,//卡号
          "curPage": this.currentPage,
          "pageSize": this.pageSize,
          "shopIds": this.forms.shopIds?[this.forms.shopIds]:[],
          // "creator": "张三",
          "createBeginTime": this.forms.create?this.forms.create[0]+' 00:00:00':'',//创建开始时间
          "createEndTime":this.forms.create?this.forms.create[1]+' 23:59:59':'',//创建结束时间
          "cardStatuses": this.forms.cardStatuses?[this.forms.cardStatuses]:[],//卡片状态：51未领取，52未使用，53已使用， 默认51
          "useBeginTime": this.forms.usetime?this.forms.usetime[0]+' 00:00:00':'',//使用开始时间
          "useEndTime": this.forms.usetime?this.forms.usetime[1]+' 23:59:59':'',//使用结束时间
          // "carNo": "沪AB.123"
        }
        this.$api.postOKcardList(this.$$.delete_null_properties(query)).then((res)=>{
          if(res){
            if(res.code==0){
              this.querylist=res.data.records;
              this.total=res.data.total;
            }else{
              this.querylist=[];
              this.total=0;
            }
          }
        })
      },
      getUser(){//获取部门id
       this.userlist=this.$store.state.user.userData;
        if(!this.userlist.departmentList){
          return
        }
        this.shopIdlist=this.userlist.departmentList?this.userlist.departmentList:[]
      },
      handleSizeChange(e){
        this.pageSize=e;
        this.currentPage=1;
        this.init()
      },
      handleCurrentChange(e){
        this.currentPage=e;
        this.init()
      },
      onAdd(){
        this.dialogVisible=!this.dialogVisible;
        this.title='新建OK卡';
        this.itemRow={};
        this.modalType=0;//0-新建，1-修改，2-导入，3-库存设置，4-领用
        this.$nextTick(()=>{
          this.$refs['itemRow'].clearValidate()
        })
      },
      onUpdate(row){
        this.dialogVisible=!this.dialogVisible;
        this.itemRow=JSON.parse(JSON.stringify(row));
        this.title='修改OK卡';
        this.modalType=1;//0-新建，1-修改，2-导入，3-库存设置，4-领用
        this.$nextTick(()=>{
          this.$refs['itemRow'].clearValidate()
        })
      },
      onDelect(){
        //
      },
      onSaveModal(){
        switch(this.modalType){//0-新建，1-修改，2-导入，3-库存设置，4-领用
          case 0:
            this.$refs['itemRow'].validate((valid) => {
              if (valid) {
                this.postOKcardAdd()
              }
            });
            break;
          case 1:
            this.$refs['itemRow'].validate((valid) => {
              if (valid) {
               this.postOKcardUpdate()
              }
            });
            break;
          case 2:
            if(!this.uploadText){
              this.$message('请选择ok卡模版表！')
              return
            }
            this.$refs.upload.submit();
            break;
          case 3:
            this.postOKcardStore()
            break;
          case 4:
            this.getOKcard()
            break;
        }
      },
      onImport(){//导入
        this.dialogVisible=!this.dialogVisible;
        this.title='导入OK卡';
        this.modalType=2;//0-新建，1-修改，2-导入，3-库存设置，4-领用
        this.uploadText='';
      },
      onStock(){//库存设置
        this.dialogVisible=!this.dialogVisible;
        this.getOKCardStore()
        this.title='门店库存上限设置';
        this.modalType=3;//0-新建，1-修改，2-导入，3-库存设置，4-领用
      },
      onCollect(){//领用
        this.dialogVisible=!this.dialogVisible;
        this.title='领用OK卡';
        this.modalType=4;//0-新建，1-修改，2-导入，3-库存设置，4-领用
        this.itemRow.storeid=''
        this.checkList=[]
        this.NotReceived='-';//未领取
        this.NotUsed='-';//未使用
        this.postOKcardListArr()//获取所有的ok卡
      },
      changeStore(e){//切换门店
        this.getOKCardStore(e)
      },
      downloadFile(){//下载模版链接
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.dowurl
        link.download = `ok卡模版.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      onExport(){//导出
        let query={
          // "curPage": this.currentPage,
          // "pageSize": this.pageSize,
          "shopIds": this.forms.shopIds?[this.forms.shopIds]:'',
          // "creator": "张三",
          "createBeginTime": this.forms.create?this.forms.create[0]+' 00:00:00':'',//创建开始时间
          "createEndTime":this.forms.create?this.forms.create[1]+' 23:59:59':'',//创建结束时间
          "cardStatuses": this.forms.cardStatuses?[this.forms.cardStatuses]:'',//卡片状态：51未领取，52未使用，53已使用， 默认51
          "useBeginTime": this.forms.usetime?this.forms.usetime[0]+' 00:00:00':'',//使用开始时间
          "useEndTime": this.forms.usetime?this.forms.usetime[1]+' 23:59:59':'',//使用结束时间
          // "carNo": "沪AB.123"
        }
        this.$api.postOKcardExport(this.$$.delete_null_properties(query)).then(res =>{
          this.$$.postExcle(res)
        })
      },
      handleChange(data){//选择文件
        this.uploadText=data.name;
      },
      httpRequest(data){//上传文件
        const formData = new FormData() //FormData对象，添加参数只能通过append('key', value)的形式添加
        formData.append('file', data.file) //添加文件对象
        this.$api.postOKcardImport(formData).then(res =>{
          if(res.code==0){
            this.init(1)
            this.dialogVisible=!this.dialogVisible;
            this.$message.success(res.message)
          }
        })
      },
      postOKcardAdd(){//ok卡新增
        if(!this.itemRow.cardNo){
          this.$message('请填写卡号！')
          return
        }
        if(!this.itemRow.amount){
          this.$message('请填写金额！')
          return
        }
        let query={
          "cardNo": this.itemRow.cardNo,
          "amount": this.itemRow.amount,
          "shopId": this.itemRow.shopId,
          "carNo": this.itemRow.carNo
        }
        this.$api.postOKcardAdd(this.$$.delete_null_properties(query)).then(res=>{
          if(res.code==0){
            this.init(1)
            this.dialogVisible=false;
            this.itemRow={}
            this.$message.success(res.message)
          }
        })
      },
      postOKcardUpdate(){//ok卡修改
        let query={
          "cardNo": this.itemRow.cardNo,
          "amount": this.itemRow.amount,
          "shopId": this.itemRow.shopId,
          "carNo": this.itemRow.carNo
        }
        this.$api.postOKcardUpdate(this.itemRow.id,this.$$.delete_null_properties(query)).then(res=>{
          if(res.code==0){
            this.init(1)
            this.dialogVisible=false;
            this.itemRow={}
            this.$message.success(res.message)
          }
        })
      },
      deleteOkCard(row){//ok卡 删除
        let query={
          okCarIds:row.id
        }
        this.$api.deleteOkCard(query).then(res=>{
          if(res.code==0){
            this.init(1)
            this.itemRow={}
            this.$message.success(res.message)
          }
        })
      },
      getOKCardStore(shopId){//获取所有门店
        let query={
          shopId:shopId
        }
        this.$api.getOKCardStore(this.$$.delete_null_properties(query)).then(res=>{
          if(res.code==0){
            if(this.modalType===3){//0-新建，1-修改，2-导入，3-库存设置，4-领用
              if(res.data) if(res.data.length>0){
                for(let i=0;i<this.CollectShoplistLimit.length;i++){
                  let item=this.CollectShoplistLimit[i];
                  if(item){
                    for(let j=0;j<res.data.length;j++){
                      let it=res.data[j]
                      if(it.shopId===item.id){
                        item.storeMaxAmount=it.storeMaxAmount;
                      }
                    }
                  } 
                }
              }
              this.$forceUpdate()
            }
            
            if(shopId){
              this.NotReceived=res.data[0]?res.data[0].canGetAmount:'-';//未领取
              this.NotUsed=res.data[0]?res.data[0].storeNoUseAmount:0;//未使用
            }
          }
        })
      },
      forceUpdate(){
        this.$forceUpdate()
      },
      postOKcardListArr(){//ok卡 查询列表【不分页】
        let query={
          "cardStatuses": [51]//卡片状态：51未领取，52未使用，53已使用， 默认51
        }
        this.$api.postOKcardListArr(query).then(res=>{
          if(res.code==0){
            this.oklist=res.data;
          }
        })
      },
      postOKcardStore(){//门店库存设置
        let query=[]
        for(let i=0;i<this.CollectShoplistLimit.length;i++){
          let item=this.CollectShoplistLimit[i]
          let obj={
            // "id": item.id,
            "storeMaxAmount": item.storeMaxAmount,
            "shopId": item.id
          }
          query.push(obj)
        }
        this.$api.postOKcardStore(query).then(res =>{
          if(res.code==0){
            this.getOKCardStore()
            this.dialogVisible=!this.dialogVisible;
            this.$message.success(res.message)
          }
        })
      },
      getOKcard(){//ok卡领取
        if(!this.itemRow.storeid){
          this.$message('请选择领取门店！')
          return
        }
        if(this.checkList.length==0){
          this.$message('请选择领取的ok卡！')
          return
        }
        this.$api.getOKcard(this.itemRow.storeid,String(this.checkList)).then(res=>{
          if(res.code==0){
            this.init(1)
            this.getOKCardStore()
            this.dialogVisible=!this.dialogVisible;
            this.$message.success(res.message)
          }
        })
      },
      getShopList(){//搜索门店
        let query={
          isEnabled:0,
          shopTypeList:[0]//门店类型。0客户成交1商户成交2售后服务3其它
        }
        this.$api.getShopList(query).then(res=>{
          if(res.code==0){
            this.CollectShoplist=res.data;
            this.CollectShoplistLimit=res.data;
          }
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.currencyConterBox{
  padding: 40px;
  .w100{
    width: 100px;
  }
  .conterModal{
    height:300px;
    overflow-y: auto;
    .modalTitle{
      margin-bottom:10px;
    }
  }
  .checkBox{
    height:300px;
    overflow-y: auto;
    margin:20px 0 0
  }
  .modalBox{
    text-align: center;
  }
}
</style>