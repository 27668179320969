<template>
  <div class="addCustomer pageContainer">
    <div class="ckxTitle">客户基本信息</div>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="150px" class="customerForm">
      <el-form-item label="客户名称: " prop="customerName">
        <el-input v-model="ruleForm.customerName" ></el-input>
      </el-form-item>
      <el-form-item label="品牌车型: " prop="cascValue">
        <el-cascader
        v-model="ruleForm.cascValue"
        :options="brandOptions" :props="props"></el-cascader>
      </el-form-item>
      <el-form-item label="线索入口: " prop="clue">
        <!--一期需要使用渠道来源的数据，二期需要使用快商通数据-->
        <!-- <el-select v-model="ruleForm.clue" class="inputWidth" clearable>
          <el-option v-for="item in clueList" :key="item.id" :label="item.clueFullName" :value="item.id"></el-option>
        </el-select> -->
        <el-cascader v-model="ruleForm.clue" :options="channelList" clearable></el-cascader>
      </el-form-item>
      <el-form-item label="客户归属: ">
        <el-input value="上海" disabled  class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="主要联系电话: " required>
        <el-input v-model="ruleForm.mobile" placeholder="请输入手机号" class="inputWidth"></el-input>
        <el-button class="ml20" type="primary" size="medium" plain @click="check">排重</el-button>
      </el-form-item>
      <el-form-item prop="secondMobile" label="次要联系电话: ">
        <el-input v-model="ruleForm.secondMobile" placeholder="请输入手机号" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="备注: ">
        <el-input v-model="ruleForm.remark" type="textarea" :autosize="{minRows: 4}"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="doSubmit">提交</el-button>
        <el-button type="primary" @click="doSubmit('load')">提交并载入</el-button>
      </div>
    </div>

    <el-dialog title="电话排重结果" :visible.sync="phoneVisible">
      <el-table :data="phonelist" border stripe >
        <el-table-column property="customerNo" label="客户编号" align="center"></el-table-column>
        <el-table-column property="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column property="carType" label="品牌车型" align="center"></el-table-column>
        <el-table-column property="customerName" label="客户姓名" align="center"></el-table-column>
        <el-table-column property="phone" label="联系电话" align="center"></el-table-column>
        <el-table-column property="createDate" label="创建时间" align="center"></el-table-column>
        <el-table-column property="phaseName" label="业务状态" align="center"></el-table-column>
        <el-table-column property="lastServiceTime" label="最后服务时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'addCustomer',
  data() {
    let self = this;
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    const checkSecondPhone = (rule, value, callback) => {
      if(value === ''){
        callback()
      }else if (value && value == self.ruleForm.mobile) {
        return callback(new Error('次要联系方式不能与主要联系方式一样'))
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      clueList: [],
      ruleForm: {
        customerName: '',
        brandId: '',
        seriesId: '',
        clue: '',
        mobile: '',
        secondMobile: '',
        remark: '',
        cascValue:[],//
      },
      rules: {
        clue: [{ required: true, message: '请选择', trigger: 'change' }],
        mobile:[{ validator: checkPhone, trigger: 'blur' } ],
        secondMobile: [{ validator: checkSecondPhone, trigger: 'blur' }],
        customerName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        cascValue: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      seriesOptions: [],
      brandOptions: [],
      props:{
        label:'name',
        value:'id',
        children:'carSeriesList'
      },
      phonelist:[],//客户数据
      channelList:[],
      phoneVisible:false,
    }
  },
  created() {
    //获取所有渠道
    // this.customerQueryCustomerClue();
    this.customerQueryChannel()
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.brandOptions=res;
      })
    }else{
      this.brandOptions=this.$store.state.setting.carBrandList;
    }
  },
  methods: {
    doSubmit(type) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            customerName: this.ruleForm.customerName,
            mainPhone: this.ruleForm.mobile,
            otherPhone: this.ruleForm.secondMobile,
            onArea: '001',
            remark: this.ruleForm.remark,
            clueId: this.ruleForm.clue?this.ruleForm.clue[0]:'',
            channelId: this.ruleForm.clue?this.ruleForm.clue[1]:'',
            seriesId: this.ruleForm.cascValue.length>0?this.ruleForm.cascValue[1]:'',
            makerId: this.ruleForm.cascValue.length>0?this.ruleForm.cascValue[0]:'',
            // brandId: this.ruleForm.brandId
          };
          this.$api.customerSaveCustomer(params).then(res => {
            if(res.code == 0){
              if(type === 'load') {
                this.$router.push({path: '/onlineService/customer', query: {followUpInfoId: res.data}})
              }else{
                this.$router.push({path: '/workbench/index'})
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    customerQueryCustomerClue() {//快商通数据
      this.$api.customerQueryCustomerClue(1).then(res => {
        if(res.code == 0){
          this.clueList = res.data;
        }
      })
    },
    customerQueryChannel() {//渠道来源（一期使用二期替换快商通）
      this.$datadic.getCustomerQueryChannel().then(res => {
        this.channelList = res;
        for(let i=0;i<this.channelList.length;i++){
          let item=this.channelList[i]
          for(let j=0;j<item.channelSubs.length;j++){
            let iss=item.channelSubs[j]
            if(iss.id==this.channelId){
              this.ruleForm.channel = [item.id, iss.id]
              return
            }
          }
        }
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    check() {
      let _this=this;
      this.$refs['ruleForm'].validateField('mobile',(validMsg)=>{
        if (!validMsg) {
          this.$api.customerExistCustomerPhone(this.ruleForm.mobile).then(res => {
            if(res.code  === 0){
              if(!res.data){
                this.$message.success('手机号可以使用');
              }else{
                // this.$message.error('手机号重复');
                _this.phoneVisible=true;
                _this.getByPhoneList(_this.ruleForm.mobile)
              }
            }
          })
        }else{
          return false;
        }
      })
    },
    goCustomer(followUpId) {
      if(followUpId){
        let path='/onlineService/customer'//在线客服
        let user=this.$$.roleName(this.$store.state.user.userData)
        if(user){
          if(user.name.indexOf('帮卖经理')!=-1){
            path='/salesManager/customer'//全部跳到详情页面
          }else if(user.name.indexOf('检测坐席')!=-1){
            path='/testService/customer'//全部跳到详情页面
          }
        }
        this.$router.push({
          path: path, 
          query: {followUpInfoId: followUpId}
        })
      }else{
        this.$message.warning('客户跟进业务ID缺失')
      }
    },
    getByPhoneList(phone){//根据客户手机号查询有效客户
      this.$api.getByPhoneList(phone).then(res=>{
        if(res.code==0){
          this.phonelist=res.data;
        }else{
          this.phonelist=[];
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.customerForm{
  margin-top: 20px;
  width: 815px;
  .inputWidth{width: 220px;}
}
</style>