<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="待补办" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="补办完成" name="second" class="mt20"></el-tab-pane>
    </el-tabs>

    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>客户姓名:</span>
        <el-input v-model="params.customerName" size="medium"></el-input>
      </el-col>
      <el-col :span="10" class="flex flex_align_center flex_between w5" v-if="activeName=='first'">
        <span>补办提交日期:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="10" class="flex flex_align_center flex_between w8" v-if="activeName=='second'">
        <span>商户办证截止日期:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border stripe class="mt20 table-mainbg" @row-dblclick="onRouter">
      <el-table-column prop="carNo" align="center" label="车牌号码" key="1"></el-table-column>
      <el-table-column prop="brandName" align="center" label="车型" key="2">
        <template slot-scope="scope">{{ scope.row.brandName }}&nbsp;{{scope.row.modelName}}</template>
      </el-table-column>
      <el-table-column prop="customerName" align="center" label="客户名称" key="3"></el-table-column>
      <el-table-column prop="merchantName" align="center" label="商户名称" key="4"></el-table-column>
      <el-table-column prop="merchantDeadline" align="center" label="办证截止日期" key="5"></el-table-column>
      <el-table-column prop="materialProcedureConfirmTime" align="center" label="补办提交时间" key="6"></el-table-column>
      <!-- <el-table-column prop="updateDate" align="center" label="补办完成时间" v-if="activeName=='second'" key="7"></el-table-column> -->
      <!-- <el-table-column prop="bookShop" align="center" label="补办项目" key="8"></el-table-column> -->
      <el-table-column prop="replaceAmount" align="center" label="补办费用" key="9"></el-table-column>
      <el-table-column prop="taskProcedureStatusName" align="center" label="状态" key="10"></el-table-column>
      <el-table-column label="操作" align="center" width="150px" v-if="activeName=='first'" key="11">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="onRouter(scope.row,1)">去处理</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        customerName: '',
        carNo: '',
      },
      querylist: [],
      page_index: 1, // 页数
      total: 0, // 总页数
      page_size: 10,
      itemRow:{},
    }
  },
  created() {
    this.handleClick();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let taskStatus=[81,82];//办证-车辆手续补办: 81 待补办,82 补办中,83 补办完成
      switch(this.activeName){
        case 'first':
          taskStatus=[81,82];
          break;
        case 'second':
          taskStatus=[83];
          break;
      }
      let query = {
        curPage: this.page_index, //当前页数
        pageSize: this.page_size,
        taskType: 7,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        customerName:this.params.customerName,
        taskProcedureStatuses:taskStatus,
        carNo:this.params.carNo,
        merchantDeadlineBegin:this.params.date?`${this.params.date[0]}`:'',
        merchantDeadlineEnd:this.params.date?`${this.params.date[1]}`:'',
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    onRouter(row){
      this.$router.push({
        path: '/certificationCompany/completeFormalitiesDetails', 
        query: {
          id:row.id
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.pageContainer{
  padding: 20px 40px;
}
</style>