<template>
<!--商户合同历史-->
  <div class="currencyConterBox">
    <el-row :gutter="24" class="flex flex_wrap">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <!-- <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>商户姓名</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>车型</span>
        <el-input v-model="forms.carModel" size="medium"></el-input> -->
      </el-col>
      <el-col :xl="2" :lg="2" class="ml10">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="querylist"
      border
      stripe
      class="table-mainbg mt10"
    >
      <el-table-column
        prop="tradeShopName"
        label="成交门店"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carNo"
        label="车牌号码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="seriesName"
        label="车型"
        align="center"
      >
        <template slot-scope="scope">{{scope.row.makerName}}/{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="certCompanyName" label="办证公司" align="center" >
        <template slot-scope="scope">{{scope.row.certCompanyName?scope.row.certCompanyName:'商户自理'}}</template>
      </el-table-column>
      <el-table-column prop="contractDate" label="商户合同签订时间" align="center" ></el-table-column>
      <el-table-column prop="merchantDeadline" label="商户办证截至日期" align="center" ></el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <el-button type="primary" @click="onOperate(scope.row)" size="mini" plain>变更办证公司</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        merchantName: "",
        carNo: "",
        date: "",
        financePayStatuses: "",
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      paymentStatuslist: [], //付款状态
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage = page ? page : this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        financeReceiveStatus:43,//财务车款收款状态，41待收款，42收款中，43收款成功，44收款失败
        merchantName: this.forms.merchantName,
        carModel:this.forms.carModel,
      };
      this.$api.postAlterCertTypePage(this.$$.delete_null_properties(query)).then((res) => {
          if (res) {
            if (res.code == 0) {
              this.querylist = res.data.records;
              this.total = res.data.total;
            } else {
              this.querylist = [];
              this.total = 0;
            }
          }
        });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.currentPage = 1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.init();
    },
    onOperate(row){
      this.$router.push({
        path: '/salesAssistant/merchantContract',
        query:{
          id:row.id,
          state:0,
          changeState:1,
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
  .rowbox {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    .line {
      width: 15px;
      height: 1px;
      background-color: #999999;
      margin: 0 5px;
    }
  }
}
</style>