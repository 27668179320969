<template>
    <div class="currencyConterBox">
        <el-row :gutter="24" class="">
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>商户名称</span>
            <el-input v-model="forms.merchantName" size="medium"></el-input>
          </el-col>
          <el-col :span="4" class="flex flex_align_center flex_between w3">
            <span>收款状态</span>
            <el-select v-model="forms.financeGatheringStatuses" size="medium" clearable>
              <el-option label="未收款" :value="81"></el-option>
              <el-option label="已收款" :value="82"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" class="flex flex_align_center flex_between w5">
            <span>预约成交时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg mt20">
          <el-table-column prop="merchantContractNo" label="合同编号" align="center" ></el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center" ></el-table-column>
          <el-table-column prop="carBrandModelName" label="车型" align="center" ></el-table-column>
          <el-table-column prop="totalPayAmount" label="应收金额（元）" align="center" ></el-table-column>
          <el-table-column prop="mcTradePrice" label="成交价（元）" align="center" ></el-table-column>
          <el-table-column prop="tradeTypeName" label="成交类型" align="center" ></el-table-column>
          <el-table-column prop="merchantName" label="商户名称" align="center" ></el-table-column>
          <el-table-column prop="appointTradeTime" label="预约成交时间" align="center" ></el-table-column>
          <el-table-column prop="financeGatheringStatusName" label="收款状态" align="center" ></el-table-column>
          <el-table-column label="操作" align="center" width="120" >
            <template slot-scope="scope">
              <el-button v-if="scope.row.financeGatheringStatus===81"
                type="primary"
                @click="operation(scope.row)"
                size="mini"
                class="group-custom"
              >收款</el-button>
              <el-button v-else
                type="primary"
                @click="operation(scope.row)"
                size="mini" plain
                class="group-custom"
              >查看</el-button>
            </template>
          </el-table-column>
        </el-table>
          <el-pagination class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total">
          </el-pagination>
    </div>
</template>
<script>
export default {
    data(){
        return{
            querylist:[],//
            forms:{
              merchantName:'',
              carNo:'',
              date:'',
              financeGatheringStatuses:''
            },
            total:0,
            currentPage:1,
            pageSize:10,
        }
    },
    created(){
      if(this.$route.params.state) this.forms.financeGatheringStatuses=Number(this.$route.params.state);
      this.init();
    },
    methods:{
      init(page){
        this.currentPage=page?page:this.currentPage;
        let query={
          "curPage": this.currentPage,//当前页数
          "pageSize": this.pageSize,
          "carNo": this.forms.carNo,
          merchantName:this.forms.merchantName,
          financeGatheringStatuses:this.forms.financeGatheringStatuses?[this.forms.financeGatheringStatuses]:'',//财务收款状态，81未收款，82已收款
          appointTradeBeginTime:this.forms.date?`${this.forms.date[0]} 00:00:00`:"",
          appointTradeEndTime:this.forms.date?`${this.forms.date[1]} 23:59:59`:"",
        }
        this.$api.postMerchantTradeQuery(this.$$.delete_null_properties(query)).then((res)=>{
          if(res){
            if(res.code==0){
              this.querylist=res.data.records;
              this.total=res.data.total;
            }else{
              this.querylist=[];
              this.total=0;
            }
          }
        })
      },
      handleSizeChange(e){
        this.pageSize=e;
        this.currentPage=1;
        this.init()
      },
      handleCurrentChange(e){
        this.currentPage=e;
        this.init()
      },
      operation(row){
        //打印收据
        this.$router.push({
          path:'/finance/merchant/carCollectionDetails',
          query:{
            id:row.id
          }
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.currencyConterBox{
    padding: 40px;
    .rowbox{
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      .line{
        width:15px;
        height:1px;
        background-color: #999999;
        margin: 0 5px;
      }
    }
}
</style>