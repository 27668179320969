<template>
  <div class="applyforBoxBG">
    <div class="applyforBox pb100">
      <!--基本信息-->
      <div class="flex_space mb10">
        <div class="ckxTitle">客户端信息</div>
        <span class="seeColor" @click="onrouter(0)">客户合同查看>></span>
      </div>
      <table class="table-oddwholebg table-custom">
        <tbody>
          <tr>
            <td>客户姓名</td>
            <td>{{form.customerName}}</td>
            <td>成交价</td>
            <td>{{form.ccTradePrice}}</td>
            <td>成交门店</td>
            <td>{{customerContract.tradeShopName}}</td>
            <td>帮卖经理</td>
            <td>{{customerContract.sellerName}}</td>
          </tr>
          <tr>
            <td>车牌号码</td>
            <td>{{form.carNo}}</td>
            <td>车辆型号</td>
            <td>{{form.makerName}}&nbsp;{{form.seriesName}}</td>
            <td>4S店简称</td>
            <td>{{form.channel?form.channel.shortName:''}}</td>
            <td>拓展专员</td>
            <td>{{form.channel?form.channel.extensionerName:''}}</td>
          </tr>
          <tr>
            <td>车架号</td>
            <td>{{form.vinCode}}</td>
            <td>发动机号</td>
            <td>{{form.engineNo}}</td>
            <td>车辆颜色</td>
            <td>{{form.carColor}}</td>
            <td>产证编号</td>
            <td>{{form.certNo}}</td>
          </tr>
           <tr>
            <td>客户办证截止时间</td>
            <td>{{customerContract.customerDeadline}}</td>
            <td>初次登记日期</td>
            <td>{{form.registerDate}}</td>
            <td>年检有效期</td>
            <td>{{checkExpire}}</td>
            <td>交强险有效期</td>
            <td>{{form.trafficAccidentsEndDate}}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex_space mt20 mb10">
        <div class="ckxTitle">商户端信息</div>
        <span class="seeColor" @click="onrouter(1)">商户合同查看>></span>
      </div>
      <table class="table-oddwholebg table-custom">
        <tbody>
          <tr>
            <td>商户姓名</td>
            <td>{{res.merchantName}}</td>
            <td>商户电话</td>
            <td>{{res.merchantMobile}}</td>
            <td>维护业务员</td>
            <td>{{res.maintainerName}}</td>
            <td>维护业务员电话</td>
            <td>{{res.maintainerMobile}}</td>
          </tr>
          <tr>
            <!-- <td>商户申请时间</td>
            <td>{{form.applyTime}}</td> -->
            <td>商户办证截止时间</td>
            <td>{{merchantContract.merchantDeadline}}</td>
            <td>经营地</td>
            <td>{{carMerchant.businessCityName}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="flex_space mt20 mb10">
        <div class="ckxTitle">办证信息</div>
        <span class="seeColor" @click="onrouter(2)">查看报告>></span>
      </div>
      <table class="table-oddwholebg table-custom">
        <tbody>
          <tr>
            <td>办证公司</td>
            <td>{{form.certType===12?form.certCompany:form.certTypeName}}</td>
            <td v-if="state!=1">办证办理地</td>
            <td v-if="state!=1">{{res.provinceName}}{{res.cityName}}{{res.areaName}}</td>
          </tr>
          <tr v-if="state!=1">
            <td>提交申请时间</td>
            <td>{{res.certApplyTime}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt20">过户凭证特殊要求</div>
      <div class="">
        <span v-for="(item,index) in contractRequireList" :key="item.id" class="mr30 tipsColor">{{index+1}}、{{item.requireName}}{{item.bak}}</span>
        <span v-if="contractRequireList.length===0">-</span>
      </div>

      <div class="footer">
        <div class="btn clear">
          <el-button type="primary" @click="onSubmit(1)" class="fl ml30">退车</el-button>
          <el-button type="primary" @click="dialogVisible=true">{{state==1?'代商户申请':'修改商户办证申请'}}</el-button>
          <el-button type="primary" @click="onReturn">关闭</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="二次确认" :visible.sync="dialogVisible" width="500px" class="customDialog">
      <div>
        <span class="mr10">办证办理地</span>
        <el-cascader ref="address"
        v-model="address" @change="changeAddress"
        :options="addresslist" :props="props"></el-cascader>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit(2)">确认申请</el-button>
        <el-button type="primary" @click="onSubmit(3)">确认申请并邀约客户</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      taskId: "",
      res: {}, //展示数据
      form:{},
      state:1,//1-代商户申请，2-修改商户申请
      address:[],//地址ID
      addressText:[],//地址名称
      addresslist:[],//地址
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      carMerchant:{},//商户详情
      customerContract:{},//客户合同详情
      merchantContract:{},//商户合同详情
      certMaterialTraceList:[],//办证跟踪任务列表
      userData:{},
      cno:'',
      contractRequireList:[],
      checkExpire:'',//年检有效期
    };
  },
  created() {
    if(this.$route.query.id) this.taskId = this.$route.query.id;
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.addresslist=res;
      })
    }else{
      this.addresslist=this.$store.state.setting.cityList;
    }
    this.userData=this.$store.state.user.userData;
    this.getCertificateTaskDetails(); //获取详情
  },
  methods: {
    onrouter(state) {
      //跳转客户商户合同
      let aurl = "";
      let id='';
      if (state==0) {
        aurl = "/salesManager/contractMaking";
        id=this.customerContract.id;//客户合同
      }else if (state==1) {
        aurl = "/salesAssistant/merchantContract";
        id=this.merchantContract.id;//商户合同
      }else if (state==2) {
        aurl = "/testReviewer/auditReport";
        id=this.form.checkReportId;//审核报告
      }
      if(!id&&state!=2){
        this.$message('当前暂无合同！')
        return
      }else if(!id&&state===2){
        this.$message('当前暂无审核报告！')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    postCallout(customerNumber){//外呼
      let query={
        cno:this.cno,
        customerNumber:customerNumber,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    changeAddress(){
      let node=this.$refs.address.getCheckedNodes()
      this.addressText=node[0].pathLabels;
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmit(type) {
      // let stateType='';
      switch(type){
        case 1:
          this.$confirm('请确认是否退车?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // stateType='apply_return_car';
            this.postCertificateTaskOpera(type,'apply_return_car')
          }).catch(() => {});
          break;
        case 2:
        case 3:
          if(this.state==1){
            // stateType='apply_confirm'
            this.postCertificateTaskOpera(type,'apply_confirm')
          }else{
            // stateType='apply_update'
            this.postCertificateTaskOpera(type,'apply_update')
          }
          break;
      }
    },
    postCertificateTaskOpera(type,stateType){
      let query = {
        taskId: this.taskId,
        provinceId:this.address.length>0?this.address[0]:'',
        provinceName:this.addressText.length>0?this.addressText[0]:'',
        cityId:this.address.length>0?this.address[1]:'',
        cityName:this.addressText.length>0?this.addressText[1]:'',
        areaId:this.address.length>0?this.address[2]:'',
        areaName:this.addressText.length>0?this.addressText[2]:'',
        taskApplyResult: stateType //办证申请操作类型：办证专员确认申请 apply_confirm，确认申请并邀约客户 apply_invite，退车 apply_return_car,办证公司申请 com_apply
      };
      this.$api.postCertificateTaskOpera(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.ondialogVisible();
            if (type==3) {
              this.$router.push({
                path: "/accreditationCommissioner/certificateInvitation",
                query: {
                  id: this.taskId
                }
              });
            }else{
              this.getCertificateTaskDetails(); //获取详情
            }
            this.$message({
              type: "success",
              message: res.message
            });
            this.onReturn()
          }
        }
      });
    },
    getCertificateTaskDetails() {//获取详情
      this.$api.getCertificateTaskDetails(this.taskId).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = JSON.parse(JSON.stringify(res.data));
            this.form=JSON.parse(JSON.stringify(res.data.car));
            if(this.form.checkExpire){
              let al=this.form.checkExpire.split('-')
              this.checkExpire=`${al[0]}年${al[1]}月`
            }
            this.certMaterialTraceList=res.data.certMaterialTraceList;
            if(res.data.car){
               if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;
               if(res.data.car.customerContract){
                 this.customerContract=res.data.car.customerContract;
                 let contractRequireList=res.data.car.customerContract.contractRequireList?res.data.car.customerContract.contractRequireList:[];
                  let newCR=[]
                  contractRequireList.map((item) =>{
                    if(item.isSelected===1&&item.requireType===11){//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
                      newCR.push(item)
                    }
                  })
                  this.contractRequireList=newCR;
               }
               if(res.data.car.merchantContract) this.merchantContract=res.data.car.merchantContract;
               
            }
            
            if(res.data.taskApplyStatus==22){
              this.state=2;
            }else{
              this.state=1;
            }
          }
        }
      });
    },
    onReturn(){
      this.bus.$emit("closeSelected");
    },
  }
};
</script>
<style lang="scss" scoped>
.mt100 {
  margin-top: 10%;
}
.applyforBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}

.applyforBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .flex_space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .seeColor{
    color:#409EFF;
    cursor: pointer;
  }
  .content_titleOne {
    margin-bottom: 10px;
    width: 50%;
    .content_title_row {
      margin: 0 100px 20px 0;
    }
  }
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    .content_title_row {
      // flex: 1;
      label {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .button_primary {
    width: 88px;
    margin-left: 30px;
  }

  .content_modal_textcontenBox {
    width: 50%;
    .content_title_Box {
      justify-content: space-between;
      .content_title_row {
        width: 50%;
        margin-bottom: 10px;
      }
      .w100 {
        width: 100%;
      }
    }
  }
  .spanleng {
    display: inline-block;
    min-width: 80px;
  }
}
@media only screen and (max-width: 1300px) {
  .applyforBox .content_titleOne,
  .applyforBox .content_modal_textcontenBox {
    width: 95%;
  }
}
</style>
