<template>
  <div class="inventBoxBG">
    <div class="inventBox">
      <template v-if="stateType==0">
       <div class="title_top_nameBox mb20">车辆信息</div>
       <table class="table-oddwholebg table-custom mt20">
          <tbody>
            <tr>
              <td>车辆号牌</td>
              <td>{{form.carNo}}</td>
              <td>厂牌型号</td>
              <td>{{form.carBrandModelName}}</td>
              <td>发动机号</td>
              <td>{{form.engineNo}}</td>
              <td>颜色</td>
              <td>{{form.carColor}}</td>
            </tr>
            <tr>
              <td>初次登记日期</td>
              <td>{{form.registerDate}}</td>
              <td>年检有效期</td>
              <td>{{form.checkExpire}}</td>
              <td>车架号</td>
              <td>{{form.vinCode}}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <el-row :gutter="24" class="mt20">
          <el-col :span="4">成交价：{{form.ccTradePrice}}</el-col>
          <el-col :span="4">报价有效期：{{form.tradedPriceExpire}}</el-col>
          <el-col :span="4">成交类型：{{form.tradeTypeName}}</el-col>
          <el-col :span="5">成交门店：{{form.tradeShopName}}</el-col>
          <el-col :span="5">预约门店：{{form.appointTradeShopName}}</el-col>
        </el-row>
        <div class="title_top_nameBox mt30">商户信息</div>
        <el-row :gutter="24" class="mt20">
          <el-col :span="4">商户姓名：{{form.merchantAccount.merchantName}}</el-col>
          <el-col :span="4">收款人：{{form.merchantAccount.accountName}}</el-col>
          <el-col :span="6">收款银行：{{form.merchantAccount.depositBank}}</el-col>
          <el-col :span="5">账号：{{form.merchantAccount.account}}</el-col>
          <el-col :span="4">商户电话：{{form.merchantMobile}}</el-col>
        </el-row>
        <div class="title_top_nameBox mt30">退押金</div>
        <p class="mb10 mt20">车开新退还金额：<span class="text_decoration">{{form.totalPayAmountCn}}（￥：{{form.totalPayAmount}}）</span> </p>
        <template v-if="[31,34].includes(paymentState)">
       <p class="mt15">付款方式：
         <el-select v-model="payTypeState" class="w300" clearable>
          <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </p>
        <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
          <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
        </template>
          <template v-if="[33].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
        <p class="tipsColor mt15" v-if="paymentState===31">当前余额：{{form.availableBalance}}</p>
        <p class="tipsColor mt15" v-if="paymentState!==31">银行返回提示语：{{form.bankRspTips}}</p>
        </template>
        <template v-else>
        <div class="head_top_title">
            <i></i>
            <span>批量支付明细</span>
          </div>
        <el-table :data="querylist" border stripe class="table-mainbg">
          <el-table-column prop="merchantName" label="商户姓名" align="center">
            <template slot-scope="scope" >{{scope.row.mdpList?scope.row.mdpList[0].merchantName:scope.row.merchantName}}</template>
          </el-table-column>
          <el-table-column prop="accountName" label="收款账号户名" align="center">
            <template slot-scope="scope">
              {{scope.row.mdpList?scope.row.mdpList[0].merchantAccount?scope.row.mdpList[0].merchantAccount.accountName:'':scope.row.merchantAccount?scope.row.merchantAccount.accountName:''}}
            </template>
          </el-table-column>
          <el-table-column prop="carNo" label="车牌号" align="center">
            <template slot-scope="scope">{{scope.row.mdpList?scope.row.mdpList[0].carNo:scope.row.carNo}}</template>
          </el-table-column>
          <el-table-column prop="depositBank" label="开户行" align="center">
            <template slot-scope="scope">
              {{scope.row.mdpList?scope.row.mdpList[0].merchantAccount?scope.row.mdpList[0].merchantAccount.depositBank:'':scope.row.merchantAccount?scope.row.merchantAccount.depositBank:''}}
            </template>
          </el-table-column>
          <el-table-column prop="depositBankShortName" label="银行简称" align="center">
            <template slot-scope="scope">
              {{scope.row.mdpList?scope.row.mdpList[0].merchantAccount?scope.row.mdpList[0].merchantAccount.depositBankShortName:'':scope.row.merchantAccount?scope.row.merchantAccount.depositBankShortName:''}}
            </template>
          </el-table-column>
          <el-table-column prop="account" label="账号" align="center">
            <template slot-scope="scope">
              {{scope.row.mdpList?scope.row.mdpList[0].merchantAccount?scope.row.mdpList[0].merchantAccount.account:'':scope.row.merchantAccount?scope.row.merchantAccount.account:''}}
            </template>
            
          </el-table-column>
          <el-table-column prop="totalPayAmount" label="退款押金金额（元）" align="center">
            <template slot-scope="scope">
              {{scope.row.batchPayAmount?scope.row.batchPayAmount:scope.row.totalPayAmount}}
            </template>
          </el-table-column>
          <el-table-column prop="bankRspTips" label="银行反馈信息" align="center">
            <!-- <template slot-scope="scope">{{scope.row.mdpList?scope.row.mdpList[0].bankRspTips:scope.row.bankRspTips}}</template> -->
          </el-table-column>
          <el-table-column  label="操作" align="center" >
            <template slot-scope="scope">
              <el-button type="primary" @click="onPaymentFailed(scope.row)" size="mini" plain v-if="[31,32].includes(scope.row.batchPayStatus)&&batchPayOrderNo">付款失败</el-button>
            </template>
          </el-table-column>
        </el-table>
        <p class="mt30"><span class="mr30">批量支付日期：{{form.batchPayTime}}</span><span class="ml30">批量支付金额（元）：{{form.batchPayAmount}}</span></p>
          <template v-if="[31,34].includes(paymentState)">
          <p class="mt15">付款方式：
            <el-select v-model="payTypeState" class="w300">
              <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </p>
            <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
              <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
            </el-radio-group>
          </template>
          <!-- <template v-if="[33].includes(paymentState)">
            <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template> -->
          <template v-if="paymentState==34">
            <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
          <p class="tipsColor mt15" v-if="paymentState===31">当前余额（元）：{{form.availableBalance}}</p>
      </template>
    
      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31||paymentState==34">{{paymentState==34?'重新付款':'开始付款'}}</el-button>
          <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32">{{batchPayOrderNo?'批量付款成功':'付款完成'}}</el-button>
          <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32&&depositPayId">付款失败</el-button>
          <el-button type="primary" @click="onReturn">返回</el-button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //0单个，1批量
      querylist: [],
      form: {
        merchantAccount:{},
      },
      payTypeState:1,//付款方式
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      paymentState:31,//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
      depositPayId:'',//退商户押金付款 ID
      batchPayOrderNo:'',
      merchantId:'',//是否是从批量付款失败过来的
    };
  },
  created() {
    this.stateType = this.$route.query.state;
    if(this.$route.query.id) this.depositPayId=this.$route.query.id;
    if(this.$route.query.batchPayOrderNo) this.batchPayOrderNo=this.$route.query.batchPayOrderNo;
    // if(this.$route.query.merchantId){
    //   this.merchantId=this.$route.query.merchantId;//是否是从批量付款失败过来的
    //   this.getBatchGroupDetailsByOrderNo()
    //   return
    // } 

    
    if(this.stateType==0){
      this.getMerchantDepositDetailsByDepositPayId()//详情
    }else{
      if(this.batchPayOrderNo){
        this.getBatchDetailsByOrderNoMerchant()//根据批次编号查询详情
      }else{
        if(this.$route.query.state==='1'){
          this.carNos = this.$route.query.carNos;
          let merchantDepositList=this.$store.state.setting.merchantDepositList;
          this.querylist=JSON.parse(JSON.stringify(merchantDepositList))
          let cont=0;
          for(let i=0;i<this.querylist.length;i++){
            let item=this.querylist[i]
            cont+=item.totalPayAmount;
          }
          this.form.batchPayAmount=isNaN(cont)?'-':cont;
        } 
        // this.postCustomerDepositBatchQuery(this.carNos)//客户押金退款 批量条件查询
        this.getAvailableAmount()//查询银行账户余额
      }
    }

  },
  methods: {
    onPaymentFailed(row){
      this.$confirm('请确认是否付款失败?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postFinanceBatchConfirmPayFail(row)
      }).catch(() => {});
      
    },
    // getBatchGroupDetailsByOrderNo(){//根据商户Id查询退费任务数量
    //   let query={
    //     batchPayOrderNo:this.batchPayOrderNo,
    //     merchantId:this.merchantId
    //   }
    //   this.$api.getBatchGroupDetailsByOrderNo(query).then(res=>{
    //     if(res.code==0){
    //       this.form=res.data;
    //       this.payTypeState=res.data.payType==5?5:1;//付款方式
    //       this.paymentState=res.data.batchPayStatus;//批量支付状态，32付款中，33付款成功，34付款失败
    //       if(this.paymentState===34){
    //         this.form.payType='';
    //       }
    //       this.querylist = this.form.mdpList?this.form.mdpList:[];
    //     }
    //   })
    // },
    getMerchantDepositDetailsByDepositPayId(){//获取详情
      this.$api.getMerchantDepositDetailsByDepositPayId(this.depositPayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.paymentState=res.data.financePayStatus;
            this.form.merchantAccount=this.form.merchantAccount?this.form.merchantAccount:{}
          }
        }
      })
    },
    getBatchDetailsByOrderNoMerchant(){//根据批次编号查询详情
      this.$api.getBatchDetailsByOrderNoMerchant(this.batchPayOrderNo).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.batchPayStatus;//批量支付状态，32付款中，33付款成功，34付款失败
            if(this.paymentState===34){
              this.form.payType='';
            }
            // this.form.carCustomer=res.data.carCustomers.length>0?res.data.carCustomers[0]:{};
            this.querylist = this.form.batchMerchantDepositPayList?this.form.batchMerchantDepositPayList:[];
          }
        }
      })
    },
    onPayment(state){
      switch(state){
        case 1:
          if(!this.payTypeState){
            this.$message('请选择付款方式！')
            return
          }else{
            if(!this.form.payType&&this.payTypeState!=5){
              this.$message('请选择付款方式！')
              return
            }
          } 
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            if(this.merchantId){//是否是从批量付款失败过来的
              return
            }
            if(this.stateType==0){
              this.postMerchantDepositFinancePay();//财务单个付款
            }else{
              this.postMerchantDepositFinanceBatchPay();//财务批量付款（商户押金退款）
            }
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            if(this.batchPayOrderNo){
              this.postFinanceBatchConfirmPaySuccess()//财务确认批量付款成功
            }else{
              this.postMerchantDepositFinanceConfirmPaySuccess()//成功
            }
          }).catch(() => {});
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postMerchantDepositFinanceConfirmPayFail()//失败
          }).catch(() => {});
          break;
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/merchant/merchantDeposit',
      })
    },
    postFinanceBatchPayByMerOrd(){//财务批量付款失败后再次付款
      let query={
        "batchPayOrderNo": "string",
        "merchantDepositPayIds": [
          1,
          2
        ],
        "merchantId": this.merchantId,
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postFinanceBatchPayByMerOrd(query).then(res=>{
        if(res.code===0){
          this.$message.success(res.message)
        }
      })
    },
    postMerchantDepositFinancePay(){//财务付款
      let query={
        "merchantDepositPayId": this.depositPayId,//客户成交/退车辆付款ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postMerchantDepositFinancePay(query).then(res=>{
        if(res.code==0){
          this.getMerchantDepositDetailsByDepositPayId();
          this.$message.success(res.message)
        }
      })
    },
    postMerchantDepositFinanceBatchPay(){//财务批量付款
      let merchantDepositPayIds=[];
      for(let i=0;i<this.querylist.length;i++){
        merchantDepositPayIds.push(this.querylist[i].id)
      }
      let query={
        "merchantDepositPayIds": merchantDepositPayIds,//商户押金退款ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postMerchantDepositFinanceBatchPay(query).then(res=>{
        if(res.code==0){
          this.batchPayOrderNo=res.data;
          this.$message.success(res.message)
          this.$nextTick(()=>{
            this.onReturnPage(res.data)
          })
        }
      })
    },
    onReturnPage(batchPayOrderNo){//新增批次退费专用
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/merchant/merchantDepositDetails',
        query:{
          state:1,
          batchPayOrderNo:batchPayOrderNo
        }
      })
    },
    postFinanceBatchConfirmPaySuccess(){//财务确认批量付款成功
      let query={
        batchPayOrderNo:this.batchPayOrderNo
      }
      this.$api.postFinanceBatchConfirmPaySuccess(query).then(res=>{
        if(res.code==0){
          this.getBatchDetailsByOrderNoMerchant();
          this.$message.success(res.message)
        }
      })
    },
    postFinanceBatchConfirmPayFail(row){//财务确认批量付款失败
      let query={
        batchPayOrderNo:this.batchPayOrderNo,
        merchantId:row.merchantId
      }
      this.$api.postFinanceBatchConfirmPayFail(query).then(res=>{
        if(res.code==0){
          this.getBatchDetailsByOrderNoMerchant()//根据批次编号查询详情
          // this.$router.push({
          //   path:'/finance/merchant/merchantDepositDetails',
          //   query:{
          //     state:1,
          //     batchPayOrderNo:this.batchPayOrderNo,
          //     merchantId:row.merchantId,//重复
          //   }
          // })
          this.$message.success(res.message)
        }
      })
    },
    postMerchantDepositFinanceConfirmPaySuccess(){//财务确认付款成功
      let orderNo=this.form.orderNo;
      this.$api.postMerchantDepositFinanceConfirmPaySuccess(orderNo).then(res=>{
        if(res.code==0){
          this.getMerchantDepositDetailsByDepositPayId();
          this.$message.success(res.message)
        }
      })
    },
    postMerchantDepositFinanceConfirmPayFail(){//财务确认付款失败
      let orderNo=this.form.orderNo;
      this.$api.postMerchantDepositFinanceConfirmPayFail(orderNo).then(res=>{
        if(res.code==0){
          this.getMerchantDepositDetailsByDepositPayId();
          this.$message.success(res.message)
        }
      })
    },
    getAvailableAmount(){//查询银行账户余额
      this.$api.getAvailableAmount().then(res=>{
        if(res.code==0){
          this.form.availableBalance=res.data;
          this.$nextTick(()=>{
            this.$forceUpdate()
          })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px 100px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>
