<template>
  <div class="pageContainer">
    <!--车辆信息-->
    <div class="pb100">
      <div class="ckxTitle mb10">车辆信息</div>
      <table class="table-cusHeadbg table-custom">
        <thead>
          <tr>
            <td>车牌号码</td>
            <td>车型</td>
            <td>年检有效期</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{form.carNo}}</td>
            <td>{{form.brandName}}&nbsp;{{form.modelName}}</td>
            <td>{{form.checkExpire}}</td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt30">商户信息</div>
      <table class="table-cusHeadbg table-custom">
        <thead>
          <tr>
            <td>商户姓名</td>
            <td>商户电话</td>
            <td>商户经营城市</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{carMerchant.merchantName}}</td>
            <td>{{carMerchant.merchantMobile}}</td>
            <td>{{form.businessCityName}}</td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt30">车辆材料补办信息</div>
      <el-table :data="materialProcedureList" border stripe class="mt20 table-mainbg">
        <el-table-column type="index" align="center" label="序号" width="60px"></el-table-column>
        <el-table-column prop="name" align="center" label="补办项目"></el-table-column>
        <el-table-column prop="merchantDeadline" align="center" label="商户办证截止日期"></el-table-column>
        <el-table-column prop="finishTime" align="center" label="补办完成日期" v-if="state==2"></el-table-column>
        <el-table-column prop="imgUrl" align="center" label="补办项目图片">
          <template slot-scope="scope">
            <!-- <el-image class="imgBox"
                :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"
                fit="contain" v-if="state==2||scope.row.imgUrl">
                  <div slot="error">暂无图片</div>
                </el-image> -->
              <!-- <div @click="onUpload(scope.row)" v-if="state==1&&!scope.row.imgUrl&&[81,82].includes(scope.row.status)"> -->
              <el-upload
              class="tableUpload"
              action
              accept="image/png, image/gif, image/jpeg,image/jpg"
              list-type="picture-card" 
              :on-change="(file, fileList)=>requestUpload(file,fileList,scope.row)"
              :file-list="scope.row.imgUrl?[{name:scope.row.imgUrl,url:scope.row.imgUrl}]:[]"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file,scope.row)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            <!-- </div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" v-if="state==1">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="onSee(scope.row,1)" v-if="[81,82].includes(scope.row.status)">补办完成</el-button>
            <el-button size="mini" type="primary" plain @click="onSee(scope.row,2)" v-if="[81,82].includes(scope.row.status)">无需补办退回</el-button>
            <p v-if="[83,84].includes(scope.row.status)">{{scope.row.statusName}}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" v-if="state==1">提交</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: { },//车表
      carMerchant:{},//商户表
      res:{},
      rules: {
        name: [{ required: true, message: '不能为空！', trigger: 'blur' }],
      },
      state:1,//1待补办，2已补办
      taskId:'',
      materialProcedureList:[],
      dialogImageUrl: '',
      dialogVisible: false,
    };
  },
  created() {
    // if(this.$route.query.state) this.state=this.$route.query.state;//状态
    if(this.$route.query.id) this.taskId=this.$route.query.id;
    this.getCertificateTaskDetails();//详情
  },
  methods: {
    getCertificateTaskDetails() {//获取详情
      let query={
        "businessId": this.taskId,
        "businessType": "vehicleFormalities",
      }
      this.$api.getCertificateTaskDetails(this.taskId,query).then(res => {
        if (res) {
          if (res.code == 0) {
            //办证-车辆手续补办: 81 待补办,82 补办中,83 补办完成
            if(res.data.taskProcedureStatus==83){
              this.state=2;
            }else{
              this.state=1;
            }
            this.res = res.data;
            this.form=res.data.car;
            if(res.data.car) if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;//商户表
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            this.materialProcedureList=res.data.materialProcedureList;//车辆材料
          }
        }
      });
    },
    handleRemove(file,row) {
      this.itemRow=JSON.parse(JSON.stringify(row));
      let fileId='';
      for(let i=0;i<this.res.fileList.length;i++){
        let item=this.res.fileList[i]
        if(item.sysFileBusinessList[0].businessName===row.name){
          fileId=item.sysFileBusinessList[0].fileId;
        }
      }
      let query={
        businessId:this.taskId,
        businessType:'vehicleFormalities',
        fileId:fileId,
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          this.postProcedureUpdate('')
          // this.getCertificateTaskDetails(); //获取详情
          // this.$message.success(res.message)
        }
      })
    },
    onSubmit(){//提交
      let query={
        taskId:this.taskId,
        taskProcedureResult:'procedure_com_submit'
      }
      this.$api.postTaskOperaProcedure(query).then(res=>{
        if(res.code==0){
          this.onReturn()
          this.$message.success(res.message)
        }
      })
    },
    onUpload(row){
      this.itemRow=row;
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/certificationCompany/completeFormalities'
      })
    },
    requestUpload(file,fileList,row) {
      this.itemRow=JSON.parse(JSON.stringify(row));
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.taskId);
      obj.append("businessName", row.name);
      obj.append("businessType", "vehicleFormalities");
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.postProcedureUpdate(res.data[0].fullFilePath);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    onSee(row,state){
      if(state==1){//完成
        if(!row.imgUrl){
          this.$message('请先上传图片！')
          return
        }
        this.postMaterialOpera(row.id,'finish');
      }else{
        this.postMaterialOpera(row.id,'reject');
      }
    },
    postMaterialOpera(id,operaType){
      this.$api.postMaterialOpera(id,operaType).then((res)=>{
        if(res){
          if(res.code==0){
            this.getCertificateTaskDetails()//查询车辆材料列表
            this.$message.success(res.message)
          }
        }
      })
    },
    postProcedureUpdate(imgUrl){//更新
      let query={
        carId:this.form.id,
        imgUrl:imgUrl
      }
      this.$api.postProcedureUpdate(this.itemRow.id,query).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails()//查询车辆材料列表
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 20px 50px;
  .uploadCont{
    display:flex;
    flex-wrap:wrap;
  }
  .uploadBox{
    width: 334px;
    height: 195px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }
  .textFont{
    margin-top: 10px;
  }
  .imgBox{
    width: 148px;
    height: 148px;
  }
}

</style>
