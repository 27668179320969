<template>
  <div class="pageContainer bgF5" ref="pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
    };
  },
  created() {
    this.getWorkbenchCertCount();
    
  },
  methods: {
    getWorkbenchCertCount() {
      this.$api.getWorkbenchCertCount().then((res) => {
        if (res.code == 0) {
          let newArr=[{
            num: res.data.countApplyWaited,
            name: '办证申请提醒',
            star: 0,
            url:'/accreditationCommissioner/certificateApplication'
          },{
            num: res.data.countInviteWaited,
            name: '办证邀约',
            star: 0,
            url:'/accreditationCommissioner/InvitationCertification'
          },{
            num: res.data.countInviteFollowing,
            name: '当日邀约跟进',
            star: 0,
            url:'/accreditationCommissioner/InvitationCertification',
            urlName:'InvitationCertification',
            params:{state:'second'}
          }]
       let newArr1=[{
            num: res.data.countProcessWaited,
            name: '​待办证',
            star: 0,
            url:'/accreditationCommissioner/certificateManagement'
          },{
            num: res.data.countProcessFollowing,
            name: '当日办证跟进',
            star: 0,
            url:'/accreditationCommissioner/certificateManagement',
            urlName:'certificateManagement',
            params:{state:'second',date:'today'}
          },
          // {
          //   num: res.data.countProcessFollowing,
          //   name: '过户待审',
          //   star: 0,
          //   url:'/accreditationCommissioner/certificateManagement?state=second'
          // },
          {
            num: res.data.countPlateWaited,
            name: '沪牌待查验',
            star: 1,
            url:'/accreditationCommissioner/brandCheck',
            urlName:'brandCheck',
            params:{state:'second'}
          }]
          let newArr2=[{
            num: res.data.countClearFollow,
            name: '结算跟进',
            star: 0,
            url:'/accreditationCommissioner/expenseAudit'
          },{
            num: res.data.countVoucherCheckWaited,
            name: '办证公司费用确认',
            star: 0,
            url:'/accreditationCommissioner/expenseAudit',
            urlName:'expenseAudit',
            params:{state:'second'}
          },{
            num: res.data.countSelfDoing,
            name: '商户自理费用确认',
            star: 0,
            url:'/accreditationCommissioner/merchantSelfCare',
            urlName:'merchantSelfCare',
            params:{state:68}
          },{
            num: res.data.countOverdueApplyWaited,
            name: '办证逾期',
            star: 0,
            url:'/accreditationCommissioner/certificateRemarks',
            urlName:'certificateRemarks',
            params:{state:141}
          },{
            num: res.data.countOverdueChecking,
            name: '办证逾期审核',
            star: 0,
            url:'/accreditationCommissioner/certificateHandling',
            urlName:'certificateHandling',
            params:{state:151}
          }]
          let newArr3=[{
            num: res.data.countBreakRuleWaited,
            name: '违章处理',
            star: 1,
            url:'/accreditationCommissioner/violationHandling'
          },{
            num: res.data.countProcedureWaited,
            name: '补办手续',
            star: 1,
            url:'/accreditationCommissioner/vehicleFormalities'
          }]
          this.infolist=[{name:"办证前",list:newArr},{name:"办证中",list:newArr1},{name:"办证结算",list:newArr2},{name:"延伸服务",list:newArr3}];
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss">
</style>
