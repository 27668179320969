<template>
  <!-- 车辆审核 -->
  <div class="vehicleAudit">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="待审核" name="first"></el-tab-pane>
      <el-tab-pane label="审核通过" name="second"></el-tab-pane>
      <el-tab-pane label="审核驳回" name="third"></el-tab-pane>
      <el-tab-pane label="暂时不拍" name="fourth"></el-tab-pane>
      <el-tab-pane label="延期竞拍" name="fifth"></el-tab-pane>
      <el-tab-pane label="终止竞拍" name="sixth"></el-tab-pane>   
      <el-row :gutter="24" class="mt20">
        <el-col  :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
          <span>询价类型</span>
          <el-select clearable v-model="params.secondAucFlag" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option v-for="item in checkTypeList" :key="item.type"  :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-col>
        <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15">
          <span>品牌车型</span>
          <el-input v-model="params.makerBrand"></el-input>
        </el-col>
        <el-col :xl="4" :lg="5" class="flex flex_align_center flex_between w3 mb15">
          <span>车牌号码</span>
          <el-input v-model="params.carNo"></el-input>
        </el-col>
        <el-col :xl="4" :lg="5" class="flex flex_align_center flex_between w3 mb15" v-if="['first','third','fourth','fifth','sixth'].includes(activeName)">
          <span>检测师</span>
          <el-select v-model="params.checkerId" clearable>
            <el-option v-for="item in checkerlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb15" v-else>
          <span>审核人</span>
          <el-select v-model="params.auditUserId" clearable>
            <el-option v-for="item in examinelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- <el-input v-model="params.checkAuditWorker"></el-input> -->
        </el-col>
        <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w3 mb15" v-if="['second'].includes(activeName)">
          <span>审核时间</span>
          <el-date-picker v-model="params.checkAuditTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd"></el-date-picker>
        </el-col>
        <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w3 mb15" v-if="['third'].includes(activeName)">
          <span>驳回时间</span>
          <el-date-picker v-model="params.reTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd"></el-date-picker>
        </el-col>
        <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w5 mb15" v-if="['sixth'].includes(activeName)">
          <span>报告上传时间</span>
          <el-date-picker v-model="params.handleTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd"></el-date-picker>
        </el-col>
        <el-col :xl="2" :lg="2" class="mb15">
          <el-button type="primary" size="medium" plain @click="search">搜索</el-button>
        </el-col>
      </el-row>

      <el-table :data="tableList" border :row-class-name="tableRowClassName" class="table-mainbg mt5">
        <el-table-column prop="area" label="所属区域" align="center" key="1"></el-table-column>
        <el-table-column prop="checkTypeName" label="询价类型" align="center" key="2">
          <template></template>
        </el-table-column>
        <el-table-column prop="carNo" label="车牌号码" align="center" key="3"></el-table-column>
        <el-table-column prop="isMortgage" label="是否抵押车" :formatter="formatIsMortgageCar" width="110" align="center" v-if="['first', 'second'].includes(activeName)" key="4"></el-table-column>
        <el-table-column prop="dealShop" label="跟进门店" align="center" v-if="['first', 'second'].includes(activeName)" key="5"></el-table-column>
        <el-table-column prop="channelName" label="渠道" align="center" v-if="['first', 'second'].includes(activeName)" key="6"></el-table-column>
        <el-table-column prop="carYearAndType" label="年份/车型" align="center" key="7">
          <template slot-scope="scope"><span :class="scope.row.isCarInfoDiff==1&&['first'].includes(activeName)?'tipsColor':''">{{scope.row.carYearAndType}}</span></template>
        </el-table-column>
        <el-table-column prop="productDate" label="生产日期" align="center" v-if="['first', 'second','fourth'].includes(activeName)" key="8"></el-table-column>
        <el-table-column prop="vehicleModel" label="车辆型号" align="center" v-if="['sixth'].includes(activeName)" key="9"></el-table-column>
        <el-table-column prop="uploadTime" label="报告上传时间" align="center" key="10"></el-table-column>
        <el-table-column prop="checkSpentTime" label="检测时长" align="center" key="11"></el-table-column>
        <el-table-column prop="checkerName" label="检测师" align="center" key="12"></el-table-column>
        <el-table-column prop="remark" label="不拍原因" align="center" v-if="['fourth'].includes(activeName)" key="13"></el-table-column>
        <el-table-column prop="auditTime" label="审核时间" align="center" v-if="['second','fourth'].includes(activeName)" key="14"></el-table-column>
        <el-table-column prop="auditTime" label="驳回时间" align="center" v-if="['third'].includes(activeName)" key="15"></el-table-column>
        <el-table-column prop="remark" label="终止竞拍原因" align="center" v-if="['sixth'].includes(activeName)" key="16"></el-table-column>
        <el-table-column prop="stopAuctionTime" label="终止竞拍时间" align="center" v-if="['sixth'].includes(activeName)" key="17"></el-table-column>
        <el-table-column prop="auditUserName" label="审核人" align="center" v-if="['second','third','fourth','sixth'].includes(activeName)" key="18"></el-table-column>
        <el-table-column prop="extensionAuctionTime" label="延期竞拍时间" align="center" v-if="['fifth'].includes(activeName)" key="19"></el-table-column>
        <el-table-column prop="remark" label="延期原因" align="center" v-if="['fifth'].includes(activeName)" key="20"></el-table-column>
        <el-table-column label="操作" align="center" key="21" >
          <template slot-scope="scope">
            <el-button v-if="['third'].includes(activeName)" type="primary" size="mini" plain @click="onTips(scope.row)">提醒</el-button>
            <el-button v-if="['first','fourth'].includes(activeName)" type="primary" size="mini" plain @click="onRouter(scope.row)">审核报告</el-button>
            <el-button v-if="['second','third','sixth'].includes(activeName)" type="primary" size="mini" plain @click="onRouter(scope.row)">查看报告</el-button>
            <el-button v-if="['sixth'].includes(activeName)&&scope.row.isExpired==0" type="primary" size="mini" plain @click="onRouter(scope.row,'restart')" class="mt10 ml0">重新审核</el-button>
            <el-button v-if="['sixth'].includes(activeName)&&scope.row.isExpired==0" type="primary" size="mini" plain @click="onOperation(scope.row,1)" class="mt10 ml0">重新上拍</el-button>
            <el-button v-if="['fifth'].includes(activeName)" type="primary" size="mini" plain @click="ondelay(scope.row,5)" >终止竞拍</el-button>
            <el-button v-if="['fifth'].includes(activeName)" type="primary" size="mini" plain @click="ondelay(scope.row,3)" class="mt10 ml0">暂时不拍</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    </el-tabs>
    <el-dialog :title="modalState==3?'暂时不拍':'终止竞拍'" :visible.sync="visible1">
      <el-form :model="form1">
        <el-form-item :label="modalState==3?'暂不拍原因':'终止竞拍原因'" label-width="130px">
          <el-input v-model="form1.remark" type="textarea" :autosize="{ minRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible1=false">取 消</el-button>
        <el-button type="primary" @click="onModalSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        auditStatus: 0,
        carNo: '',//车牌号码
        checkAuditTime: '',//审核时间
        checkAuditWorker: '',
        checkerName: '',
        handleTime: '',
        makerBrand: '',
        rejectDate: '',
        secondAucFlag: '',
        pType: '',
        style: '',
        createTime: '',
        createTimeBegin: '',
        createTimeEnd: '',
      },
      tableList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      examinelist:[],//审核人
      checkerlist:[],//检测师
      visible1:false,//
      form1:{},
      modalState:3,//
      itemRow:{},
      checkTypeList:[],
      timer:null,
    }
  },
  created() {
    if(this.$route.query.state){
      this.activeName=this.$route.query.state
    }
    this.getCheckBookCheckType()//获取检测类型集合
    this.init();
    this.queryUserListByRoleAndDepart('检测审核',1)//审核人
    this.queryUserListByRoleAndDepart('检测师',2)//审核人
  },
  mounted(){
    this.timer = setInterval(() => {
      this.$store.dispatch('user/setLoading',false); 
      this.getPageSize()
      this.init()
    }, 1000*3);
  },
  methods: {
    tableRowClassName({row}){//是否有人正在进行审核
      if(row.isSomeoneAudit===1&&this.activeName.includes('first')){
        return 'lightPinkBG'
      }
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    handleClick() {
      if(this.activeName !== 'first'){
        clearInterval(this.timer);
      }else{
        this.timer = setInterval(() => {
          this.$store.dispatch('user/setLoading',false); 
          this.getPageSize()
          this.init()
        }, 1000*3);
      }
      this.page_index = 1;
      this.page_total =0;
      this.tableList = [];
      this.init()
    },
    getPageSize(){//在删除成功后调用此方法
      let totalPage = Math.ceil((this.page_total - 1) / this.page_size);
      //计算出当前页码
      this.page_index = this.page_index > totalPage ? totalPage : this.page_index;
      //如果页码为1的话，则无效
      this.page_index = this.page_index < 1 ? 1 : this.page_index;
    },
    init(){//报告状态 0-待审核,1-审核通过,2-审核驳回,3-暂时不拍,4-延期竞拍,5-终止竞拍
      if(this.activeName === 'first'){
        this.params.auditStatus = 0;
      }else if(this.activeName === 'second'){
        this.params.auditStatus = 1;
      }else if(this.activeName === 'third'){
        this.params.auditStatus = 2;
      }else if(this.activeName === 'fourth'){
        this.params.auditStatus = 3;
      }else if(this.activeName === 'fifth'){
        this.params.auditStatus = 4;
      }else if(this.activeName === 'sixth'){
        this.params.auditStatus = 5;
      }
      let params = JSON.parse(JSON.stringify(this.params));
      let query={
          "checkType": params.secondAucFlag,//询价类型
          "curPage": this.page_index,
          // "customerNo": "string",//客户全局编号
          "pageSize": this.page_size,
          "carNo": params.carNo,
          "carType": params.makerBrand,//车型
          // "isShop": 0,
          "checkerId": params.checkerId,//分配的检测师userId
          "auditUserId": params.auditUserId,//检测审核员工userId
          "checkStatus": 0//检测状态 （0-待分配；1-待检测（待复检）；2-检测中（复检中）；3-检测成功（复检成功）；4-检测失败（复检失败））
        }
        switch(this.activeName){
          case 'second':
            query.auditStartTime= params.checkAuditTime?params.checkAuditTime[0]+' 00:00:00':'';//审核（驳回）时间-start
            query.auditEndTime= params.checkAuditTime?params.checkAuditTime[1]+' 23:59:59':'';//审核（驳回）时间-end
            break;
          case 'third':
            query.auditStartTime= params.reTime?params.reTime[0]+' 00:00:00':'';//审核（驳回）时间-start
            query.auditEndTime= params.reTime?params.reTime[1]+' 23:59:59':'';//审核（驳回）时间-end
            break;
          case 'sixth':
            query.uploadStartTime=params.handleTime?params.handleTime[0]+' 00:00:00':'';//报告上传时间-start
            query.uploadEndTime= params.handleTime?params.handleTime[1]+' 23:59:59':'';//报告上传时间-end
            break;
        }
      this.$api.postQueryAudit(params.auditStatus,this.$$.delete_null_properties(query)).then(res => {
        if(res.code == 0){
          this.tableList = res.data.records;
          this.page_total = res.data.total;
        }else{
          this.tableList = [];
          this.page_total = 0;
        }
      })
    },
    formatIsMortgageCar(row) {
      if(row.isMortgage === 0){
        return '否'
      }else if(row.isMortgage === 1){
        return '是'
      }else{
        return '';
      }
    },
    search() {
      this.page_index = 1;
      this.init() //改变页码，重新渲染页面
    },
    onRouter(row,restart){
      if(['first'].includes(this.activeName)){
        let query={
          id:row.id,
          status:1,//检查审核人过程： 1-准备进行审核 2-退出审核页面
        }
        this.$api.postCheckAuditCourseStatus(row.id,query).then(res=>{
          if(res.code==0){
            let operateIds=this.$store.state.user.operateIds;//点击打开的id
            operateIds.push(row.id)
            this.$store.dispatch('user/setOperateFlag',Array.from(new Set(operateIds))); //当前是否有操作检测报告id
            this.$router.push({
              path: '/testReviewer/auditReport',
              query: {
                id:row.id,
                state:`2${this.params.auditStatus}`,//2开头-车辆审核，1-车型审核，11车型查看
                restart:restart,//特定值需要重新审核
                // from: activeName, 
                // checkNo: scope.row.checkNo
              }
            })
          }
        })
      }else{
        this.$router.push({
          path: '/testReviewer/auditReport',
          query: {
            id:row.id,
            state:`2${this.params.auditStatus}`,//2开头-车辆审核，1-车型审核，11车型查看
            restart:restart,//特定值需要重新审核
            // from: activeName, 
            // checkNo: scope.row.checkNo
          }
        })
      }
      
    },
    onOperation(row,state){//操作重新上拍与重新审核,state==0,重新上拍
      this.$confirm(`您确认此车辆${state==1?'重新上拍':'重新审核'}！`, '提示', { 
        confirmButtonText: '确定', 
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postAuditReport(row.id,state)
      }).catch(() => {});
      
    },
    ondelay(row,state){//终止竞拍，暂时不拍
      this.modalState=state;
      this.visible1=!this.visible1;
      this.itemRow=row;
      this.form1={};
    },
    onModalSubmit(){//弹出框确认
      this.postAuditReport(this.itemRow.id,this.modalState,this.form1.remark)
    },
    onTips(row){//提醒检测师
      this.$api.postReminderChecker(row.id).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    //根据角色和部门查询用户
    queryUserListByRoleAndDepart(roleName,state){
      let query={
        roleName:roleName
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res =>{
        if(res.code==0){
          if(state==1){
            this.examinelist=res.data
          }else{
            this.checkerlist=res.data;
          }
        }
      })
    },
    postAuditReport(id,auditStatus,remark){//车辆报告审核
      let query={
        "auditStatus": auditStatus,//报告状态 0-待审核,1-审核通过,2-驳回重传,3-暂时不拍,5-终止竞拍
        remark:remark,
      }
      this.$api.postAuditReport(id,this.$$.delete_null_properties(query)).then(res =>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.visible1=false;
        }
      })
    },
    getCheckBookCheckType(){//获取检测类型集合
      this.$api.getCheckBookCheckType({}).then(res=>{
        if(res.code==0){
          this.checkTypeList=res.data;
        }
      })
    },
  },
  beforeDestroy() {
    this.$store.dispatch('user/setLoading',true); 
    clearInterval(this.timer);
  },
}
</script>

<style scoped lang="scss">
.vehicleAudit{
  padding: 40px;
}
</style>