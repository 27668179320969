import { render, staticRenderFns } from "./intentionPaymentDetails.vue?vue&type=template&id=0bcc2f07&scoped=true&"
import script from "./intentionPaymentDetails.vue?vue&type=script&lang=js&"
export * from "./intentionPaymentDetails.vue?vue&type=script&lang=js&"
import style0 from "./intentionPaymentDetails.vue?vue&type=style&index=0&id=0bcc2f07&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bcc2f07",
  null
  
)

export default component.exports