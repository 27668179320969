<template>
  <div class="productSetting">
    <!-- <el-radio-group v-model="radio1" class="mb20">
      <el-radio-button label="上海"></el-radio-button>
      <el-radio-button label="北京"></el-radio-button>
      <el-radio-button label="广州"></el-radio-button>
      <el-radio-button label="深圳"></el-radio-button>
    </el-radio-group> -->
    <el-tabs v-model="activeName" @tab-click="init">
      <el-tab-pane label="常规拍" name="first">
        
        <el-form :model="formData" :rules="rules" ref="formData" label-width="130px" :hide-required-asterisk="true">
          <div class="mb20">其他设置</div>
        <p class="mb10">交易服务费收费标准:</p>
        <el-row v-for="(item,index) in formData.rateList" :key="index" >
          <el-col :xl="6" :lg="12">
            <el-form-item label="成交价: ">
            <el-col :span="12">
              <el-form-item :prop="`rateList.${index}.operatorName`" :rules="rules.operatorName">
              <el-select  v-model="item.operatorName" @input="forceUpdate" clearable>
                <el-option label="大于" value="大于"></el-option>
                <el-option label="小于" value="小于"></el-option>
                <el-option label="大于等于" value="大于等于"></el-option>
                <el-option label="小于等于" value="小于等于"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
               <el-form-item label="" 
                :prop="`rateList.${index}.tradePrice`"
                :rules="rules.tradePrice">
              <el-input  v-model="item.tradePrice" @input="forceUpdate"></el-input>
              </el-form-item>
            </el-col>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="12">
            <el-form-item label="按成交价比例: " :prop="`rateList.${index}.tradeServiceChargePercentForm`"  :rules="rules.tradeServiceChargePercentForm">
              <el-input  v-model="item.tradeServiceChargePercentForm" @input="forceUpdate"><template slot="append">%</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="5" :lg="12">
            <el-form-item label="最低收取: " :prop="`rateList.${index}.tradeServiceChargeMin`"  :rules="rules.tradeServiceChargeMin">
              <el-input v-model="item.tradeServiceChargeMin" @input="forceUpdate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="5" :lg="12">
            <el-form-item label="最高收取: " :prop="`rateList.${index}.tradeServiceChargeMax`"  :rules="rules.tradeServiceChargeMax">
              <el-input  v-model="item.tradeServiceChargeMax" @input="forceUpdate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
          <el-form-item label="最小竞价单位: " prop="auctionPriceUnitMin" :rules="rules.auctionPriceUnitMin">
            <el-input class="itemWidth" type="number" v-model="formData.auctionPriceUnitMin"><template slot="append">元</template></el-input>
          </el-form-item>
          <el-form-item label="最低报价限额: " prop="auctionPriceLimitMin" :rules="rules.auctionPriceLimitMin">
            <el-input class="itemWidth" type="number" v-model="formData.auctionPriceLimitMin"><template slot="append">元</template></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="及时拍" name="second">
        <el-form :model="formData1"  ref="formData1" label-width="130px" :hide-required-asterisk="true">
          <div class="mb20">其他设置</div>
        <p class="mb10">交易服务费收费标准:</p>
        <el-row v-for="(item,index) in formData1.rateList" :key="index" >
          <el-col :xl="6" :lg="12">
            <el-form-item label="成交价: ">
            <el-col :span="12">
              <el-form-item  :prop="`rateList.${index}.operatorName`"  :rules="rules.operatorName">
              <el-select  v-model="item.operatorName" @input="forceUpdate" clearable>
                <el-option label="大于" value="大于"></el-option>
                <el-option label="小于" value="小于"></el-option>
                <el-option label="大于等于" value="大于等于"></el-option>
                <el-option label="小于等于" value="小于等于"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="" 
                :prop="`rateList.${index}.tradePrice`"
                :rules="rules.tradePrice">
              <el-input  v-model="item.tradePrice" @input="forceUpdate"></el-input>
              </el-form-item>
            </el-col>
            </el-form-item> 
          </el-col>
          <el-col :xl="6" :lg="12">
            <el-form-item label="按成交价比例: " 
            :prop="`rateList.${index}.tradeServiceChargePercentForm`"
            :rules="rules.tradeServiceChargePercentForm">
              <el-input  v-model="item.tradeServiceChargePercentForm" @input="forceUpdate"><template slot="append">%</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="5" :lg="12">
            <el-form-item label="最低收取: " 
            :prop="`rateList.${index}.tradeServiceChargeMin`"
            :rules="rules.tradeServiceChargeMin">
              <el-input v-model="item.tradeServiceChargeMin" @input="forceUpdate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="5" :lg="12">
            <el-form-item label="最高收取: " 
            :prop="`rateList.${index}.tradeServiceChargeMax`"
            :rules="rules.tradeServiceChargeMax">
              <el-input  v-model="item.tradeServiceChargeMax" @input="forceUpdate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

          <el-form-item label="最小竞价单位: " prop="auctionPriceUnitMin" :rules="rules.auctionPriceUnitMin">
            <el-input class="itemWidth"  v-model="formData1.auctionPriceUnitMin"><template slot="append">元</template></el-input>
          </el-form-item>
          <el-form-item label="最低报价限额: " prop="auctionPriceLimitMin" :rules="rules.auctionPriceLimitMin">
            <el-input class="itemWidth" v-model="formData1.auctionPriceLimitMin"><template slot="append">元</template></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // radio1: '上海',
      activeName: 'first',
      formData: {
        rateList:[
          {operatorName:'',tradePrice:'',tradeServiceChargePercentForm:'',radeServiceChargeMax:'',tradeServiceChargeMin:'',auctionPriceUnitMin:'',auctionPriceLimitMin:''},{}
          ],
        auctionPriceUnitMin: '',
        auctionPriceLimitMin: '',
      },
      formData1:{
        rateList:[{},{}]
      },
      rules: {
        operatorName: [{ required: true, message: '请输入'}],
        tradePrice: [{ required: true, message: '请输入'}],
        tradeServiceChargePercentForm: [{ required: true, message: '请输入'}],
        tradeServiceChargeMax: [{ required: true, message: '请输入'},],
        tradeServiceChargeMin: [{ required: true, message: '请输入' },],
        auctionPriceUnitMin: [{ required: true, message: '请输入' },],
        auctionPriceLimitMin: [{ required: true, message: '请输入'},],
      },
      
    }
  },
  created(){
    this.init()
  },
  methods:{
    init(){//竞拍场次设置 条件查询
      let seatTypes=21
      if(this.activeName=='first'){
        seatTypes=21
      }else{
        seatTypes=22
      }
      this.$api.postSeatRatelist(seatTypes).then(res=>{
        if(res.code==0){
          if(this.activeName=='first'){
            if(res.data){
              this.formData=res.data;
              if(this.formData.rateList.length==0){
                this.formData.rateList=[{},{}]
              }
              for(let i=0;i<this.formData.rateList.length;i++){
                let item= this.formData.rateList[i]
                item.tradeServiceChargePercentForm=item.tradeServiceChargePercentForm?item.tradeServiceChargePercentForm.split('%')[0]:item.tradeServiceChargePercentForm;
              }
            }
          }else{
            if(res.data){
              this.formData1=res.data;
              if(this.formData1.rateList.length==0){
                this.formData1.rateList=[{},{}]
              }
              for(let i=0;i<this.formData1.rateList.length;i++){
                let item= this.formData1.rateList[i]
                item.tradeServiceChargePercentForm=item.tradeServiceChargePercentForm?item.tradeServiceChargePercentForm.split('%')[0]:item.tradeServiceChargePercentForm;
              }
            }
          }
          this.$nextTick(()=>{
            this.$refs['formData'].clearValidate();
            this.$refs['formData1'].clearValidate();
          })
        }
      })
    },
    postSeatRateAdd(){//竞拍场次设置 新增
      let query={}
      if(this.activeName=='first'){
        let arr=JSON.parse(JSON.stringify(this.formData.rateList))
        for(let i=0;i<arr.length;i++){
          let item= arr[i]
          item.tradeServiceChargePercentForm=item.tradeServiceChargePercentForm?`${item.tradeServiceChargePercentForm}%`:'';
        }
        query.seatType= 21,//竞拍场次类型：21常规拍，22及时拍
        query.rateList= arr,
        query.auctionPriceUnitMin= this.formData.auctionPriceUnitMin,//最小竞价单位
        query.auctionPriceLimitMin= this.formData.auctionPriceLimitMin//最低报价限额
      }else{
        let arr=JSON.parse(JSON.stringify(this.formData1.rateList))
        for(let i=0;i<arr.length;i++){
          let item= arr[i]
          item.tradeServiceChargePercentForm=item.tradeServiceChargePercentForm?`${item.tradeServiceChargePercentForm}%`:'';
        }
        query.seatType= 22,//竞拍场次类型：21常规拍，22及时拍
        query.rateList=arr,
        query.auctionPriceUnitMin= this.formData1.auctionPriceUnitMin,//最小竞价单位
        query.auctionPriceLimitMin= this.formData1.auctionPriceLimitMin//最低报价限额
      }
      this.$api.postSeatRateAdd(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    onSubmit(){
      let name='formData1'
      if(this.activeName=='first'){
        name='formData'
      }else{
        name='formData1'
      }
      console.log(this.formData,'===formData===',name)
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.postSeatRateAdd()
        }
      });
    },
    forceUpdate(){
      this.$forceUpdate()
    },
  }
}
</script>

<style scoped lang="scss">
.productSetting{
  padding: 20px 40px;
  .itemWidth{
    width:300px;
  }
}
</style>