<template>
  <div class="pageContainer">
    <el-form
      ref="form"
      :model="form"
      label-width="130px"
      :hide-required-asterisk="true"
      :rules="rules"
    >
      <el-form-item label="合作店简称:">
        <el-input v-model="form.shortName" size="medium" disabled class="w600"></el-input>
      </el-form-item>
      <el-form-item label="账户信息:">
        <!-- <el-input v-model="form.desc" size="medium"  class="w600"></el-input> -->
        <el-select  v-model="form.desc" size="medium" class="w600" placeholder="请选择账户信息" @change="onChannel" value-key="id" >
          <el-option v-for="item in accountList" :key="item.id" :label="`${item.accountTypeName}-账户名：${item.accountName}，账号：${item.account}`" :value="item" :disabled="item.accountType===3&&item.enableStatus===0?true:false"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="提现金额:" prop="amount">
        <el-input v-model="form.amount" size="medium"  class="w600" disabled></el-input>
      </el-form-item>
      <el-form-item label="提现备注:">
        <el-input v-model="form.remark" size="medium"  class="w600" ></el-input>
      </el-form-item>
       <el-form-item label="银行回执单备注:">
        <el-input type="textarea" v-model="form.bankRemark" size="medium" class="w600" maxlength="10" ></el-input>
      </el-form-item>
      <el-form-item label="审批说明:" prop="auditRemark">
        <el-input v-model="form.auditRemark" size="medium" class="w600" type="textarea"></el-input>
      </el-form-item>
      <div class="mt30 walletfootbox">
        <el-button type="primary" size="medium" @click="onSubmit('form',1)">审核通过</el-button>
        <el-button type="primary" size="medium" @click="onSubmit('form',3)">审核不通过</el-button>
        <el-button type="primary" size="medium" plain @click="onroute">返回</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        remark:'',
        bankRemark:'',
        auditRemark: "",
        amount:'',
        channelAccountId:'',
        desc:'',
      },
      rules: {
        amount: [{ required: true, message: "审批说明不能为空" }],
        auditRemark: [{ required: true, message: "审批说明不能为空" }],
        auditRemark: [{ required: true, message: "审批说明不能为空" }],
        auditRemark: [{ required: true, message: "审批说明不能为空" }],
      },
      accountList:[],//账户列表
      walletId:'',//钱包id
      res:{},
    };
  },
  created() {
    this.walletId = this.$route.query.id;
    this.getChannelAuditTask()//获取钱包数据
    
  },
  methods: {
    onChannel(row){
      if(row){
        this.form.channelAccountId=row.id
      }
      // console.log(row,'---55---',this.form.channelAccountId)
    },
    getChannl(){
      this.$api.getByChannelIdDetail(this.res.channelId).then(res=>{
        if(res.code==0){
          this.accountList=res.data.accountList;
        }
      })
    },
    onSubmit(formdata, state) {
      this.$confirm(`请确认是否${state==1?'审核通过':'审核不通过'}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$refs[formdata].validate(valid => {
            if (valid) {
              //此处提交数据到后台，刷新列表
              let query={
                "amount": this.form.amount,//充值或冲账或提现金额
                "bankRemark": this.form.bankRemark,
                // "carNo": "string",
                // "carTradePrice": 0,
                "channelAccountId": this.form.channelAccountId,
                "channelId": this.res.channelId,
                // "flowType": 0,
                "remark": this.form.remark,
                // "token": "string"
              }
              if(state===3){
                this.postChannelWithdrawAudit(state);
              }else{
                this.$api.postWalletWithdrawUp(this.res.id,query).then(res=>{
                  if(res.code===0){
                    this.postChannelWithdrawAudit(state);
                  }
                })
              }
            } else {
              return false;
            }
          });
        }).catch(() => {});
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/channel/walletManagement"
      });
    },
    getChannelAuditTask(){//获取详情
      this.$api.getChannelAuditTask(this.walletId).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=JSON.parse(JSON.stringify(res.data));
            this.getChannl()//获取账户信息
            this.form.desc=`${res.data.accountType}--账户名：${res.data.accountName}，账号：${res.data.account}`;
            this.form.auditRemark=res.data.auditRemark;
            this.form.amount=res.data.amount;
            this.form.channelAccountId=res.data.channelAccountId;
            this.form.shortName=res.data.shortName;
            this.form.bankRemark=res.data.bankRemark;
            this.form.remark=res.data.remark;
            this.$nextTick(()=>{
              this.$refs['form'].clearValidate();
            })
          }
        }
      })
    },
    postChannelWithdrawAudit(status){//钱包提现审核
      this.$api.postChannelWithdrawAudit(this.res.id,status,this.form.auditRemark).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.onroute()
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .walletfootbox {
    width: 500px;
    display: flex;
    justify-content: center;
  }
  .w600 {
    width: 800px;
  }
}
</style>