<template>
  <div class="currencyConterBox">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="10" :lg="10" class="flex flex_align_center flex_between w4 mb10">
        <span>结算时间</span>
        <el-date-picker
          v-model="forms.date"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          size="medium"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-col>
      <el-col :xl="4" :lg="6"  class="flex flex_align_center flex_between w3 mb10">
        <span>商户名称</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6"  class="flex flex_align_center flex_between w3 mb10">
        <el-button type="primary" @click="init(1)" size="medium" plain
          >查询</el-button>
          <el-button type="primary" @click="onExport(1)" size="medium" plain
          >导出财务报表</el-button>
          <el-button type="primary" @click="onExport(2)" size="medium" plain
          >导出报表</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt10" :span-method="objectSpanMethod">
      <el-table-column prop="carNo" label="车牌号" align="center" ></el-table-column>
      <el-table-column prop="appointTradeShopName" label="预约门店" align="center" ></el-table-column>
      <el-table-column prop="clearDate" label="结算日期" align="center" ></el-table-column>
      <el-table-column prop="signDate" label="客户成交日期" align="center" ></el-table-column>
      <el-table-column prop="merchantNo" label="商户编号" align="center" ></el-table-column>
      <el-table-column prop="merchantName" label="商户名称" align="center" ></el-table-column>
      <el-table-column prop="certCompany" label="办证公司名称" align="center" ></el-table-column>
      <el-table-column prop="itemName" label="费用明细" align="center" ></el-table-column>
      <el-table-column prop="_1feeAmount" label="客户" align="center" ></el-table-column>
      <el-table-column prop="_2feeAmount" label="商户" align="center" ></el-table-column>
      <el-table-column prop="_4feeAmount" label="办证公司" align="center" ></el-table-column>
      <el-table-column prop="_3feeAmount" label="车开新" align="center" ></el-table-column>
      <el-table-column prop="_5feeAmount" label="违章供应商" align="center" ></el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        merchantName: "",
        state: "",
        date: "",
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      testArr: [],
      testPosition: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        certClearTimeBegin:this.forms.date?this.forms.date[0]:'',
        certClearTimeEnd:this.forms.date?this.forms.date[1]:'',
      };
      this.$api.postThirdClearPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
            this.rowspan(this.testArr, this.testPosition, "carId");
            // this.rowspan(this.testArr, this.testPosition, "merchantNo");
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
     // 获取数据
    rowspan(spanArr, position, spanName) {
      this.querylist.forEach((item, index) => {
        if (index === 0) {
          spanArr.push(1);
          position = 0;
        } else {
          if (
            this.querylist[index][spanName] ===
            this.querylist[index - 1][spanName]
          ) {
            spanArr[position] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            position = index;
          }
        }
      });
    },
    // 表格合并行
    objectSpanMethod({ rowIndex, columnIndex }) {
      if ([0,1,2,3,4,5,6].includes(columnIndex)) {
        const _row = this.testArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    handleSizeChange(e) {
      this.pageSize=e;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage=e;
      this.init();
    },
    onExport(state){
       let query = {
        // curPage: this.currentPage, //当前页数
        // pageSize: this.pageSize,
        carNo: this.forms.carNo,
        certClearTimeBegin:this.forms.date?this.forms.date[0]:'',
        certClearTimeEnd:this.forms.date?this.forms.date[1]:'',
      };
      if(state===1){
        this.$api.postThirdClearFinanceExport(this.$$.delete_null_properties(query)).then(res=>{
          this.$$.postExcle(res)
        })
      }else{
        this.$api.postThirdClearExport(this.$$.delete_null_properties(query)).then(res=>{
          this.$$.postExcle(res)
        })
      }
      
    },
       
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
}
</style>