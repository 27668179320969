<template>
  <div class="info">
    <div class="title_top_nameBox mb20 mt20">发货信息</div>
    <template  v-if="state">
    <div class="mb10">
      <span class="label">发货方：</span><span class="value mr30">{{tradeShopName}}</span><span class="label">收货方：</span><span class="value">{{takeDelivery}}</span>
    </div>
    <el-form :model="shipperInfo" :rules="rules" ref="shipperInfo" label-width="150px" style="width: 450px;">
      <el-form-item label="物流方式：" prop="logistType">
        <el-radio-group v-model="shipperInfo.logistType" size="small" @change="changeLogistType">
          <el-radio-button :label="11">代驾</el-radio-button>
          <el-radio-button :label="12">车开新</el-radio-button>
          <el-radio-button :label="13">快递</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="代驾姓名：" v-if="shipperInfo.logistType===11">
        <el-input v-model="shipperInfo.agencyName"></el-input>
      </el-form-item>
      <el-form-item label="代驾手机：" v-if="shipperInfo.logistType===11">
        <el-input v-model="shipperInfo.agencyMobile"></el-input>
      </el-form-item>
      <el-form-item label="员工姓名：" v-if="shipperInfo.logistType===12">
        <el-select v-model="shipperInfo.empName" placeholder="请选择" clearable filterable value-key="id" @change="changeUser">
          <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号：" v-if="shipperInfo.logistType===12">
        <el-input v-model="shipperInfo.empMobile"></el-input>
      </el-form-item>
      <el-form-item label="快递公司：" v-if="shipperInfo.logistType===13">
        <el-input v-model="shipperInfo.expressCompName"></el-input>
      </el-form-item>
      <el-form-item label="快递单号：" v-if="shipperInfo.logistType===13">
        <el-input v-model="shipperInfo.expressLogistNo"></el-input>
      </el-form-item>
      <el-form-item label="发货时间：" >
        <el-date-picker v-model="shipperInfo.fromTime" type="datetime" placeholder="选择日期时间" align="right" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="预计到达时间：" >
        <el-date-picker v-model="shipperInfo.preArriveTime" type="datetime" placeholder="选择日期时间" align="right" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
    </el-form>
    </template>
    <template v-if="!state">
      <el-row class="mt10">
        <el-col :span="6">发货方：{{shipperInfo.fromObjName}}</el-col>
        <el-col :span="6">收货方：{{shipperInfo.toObjName}}</el-col>
        <el-col :span="6">发货方式：{{shipperInfo.logistTypeName}}</el-col>
      </el-row>
      <el-row class="mt10 mb20">
        <!--物流方式，11代驾，12车开新，13快递-->
        <template v-if="shipperInfo.logistType==13">
          <el-col :span="6">快递公司：{{shipperInfo.expressCompName}}</el-col>
          <el-col :span="6">快递单号：{{shipperInfo.expressLogistNo}}</el-col>
        </template>
        <template v-if="shipperInfo.logistType==12">
          <el-col :span="6">员工姓名：{{shipperInfo.empName}}</el-col>
          <el-col :span="6">手机号：{{shipperInfo.empMobile}}</el-col>
        </template>
        <template v-if="shipperInfo.logistType==11">
          <el-col :span="6">代驾姓名：{{shipperInfo.agencyName}}</el-col>
          <el-col :span="6">代驾手机：{{shipperInfo.agencyMobile}}</el-col>
        </template>
        
        <el-col :span="6">发货时间：{{shipperInfo.fromTime}}</el-col>
        <el-col :span="6">预计到达时间：{{shipperInfo.preArriveTime}}</el-col>
      </el-row>
    <!-- <el-row class="mt10">
      <el-col :span="6">发货方：{{shipperInfo.tradeShopName}}</el-col>
      <el-col :span="6">收货方：{{shipperInfo.toShopName}}</el-col>
      <el-col :span="6">发货方式：{{shipperInfo.logistTypeName}}</el-col>
      <el-col :span="6">代驾姓名：{{shipperInfo.agencyName}}</el-col>
    </el-row>
    <el-row class="mt10 mb20">
      <el-col :span="6">发货时间：{{shipperInfo.fromTime}}</el-col>
      <el-col :span="6">代驾手机：{{shipperInfo.agencyMobile}}</el-col>
      <el-col :span="6">预计到达时间：{{shipperInfo.preArriveTime}}</el-col>
    </el-row> -->
    </template>
  </div>
</template>

<script>
export default {
  props:{
    tradeShopName: {
      type: String
    },
    takeDelivery: {//收获方
      type: String
    },
    shipperInfo: {
      type: Object,
      default() {
        return {
          logistType: 11,
          agencyName: '',
          agencyMobile: '',
          empMobile: '',
          empName: '',
          expressCompName: '',
          expressLogistNo: '',
          fromTime: '',
          preArriveTime: ''
        }
      }
    },
    roleItem: {
      type: Object,
      default() {
        return {}
      }
    },
    state:{
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      rules: {
        logistType: [{ required: true, message: '请选择', trigger: 'change' }],
        agencyName: [{required: true, message: '请输入', trigger: 'blur'}],
        agencyMobile: [{validator: checkPhone,required: true, trigger: 'blur'}],
        empName: [{required: true, message: '请选择', trigger: 'change'}],
        empMobile: [{validator: checkPhone,required: true, trigger: 'blur'}],
        expressCompName: [{required: true, message: '请输入', trigger: 'blur'}],
        expressLogistNo: [{required: true, message: '请输入', trigger: 'blur'}],
        fromTime: [{ required: true, message: '请选择时间', trigger: 'change' }],
        preArriveTime: [{ required: true, message: '请选择时间', trigger: 'change' }]
      },
      userlist:[],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  created(){
    
  },
  methods: {
    changeUser(e){
      if(e){
        this.shipperInfo.empMobile=e.mobile;
        this.shipperInfo.empName=e.name;
      }else{
        this.shipperInfo.empMobile='';
        this.shipperInfo.empName='';
      }
    },
    postSimpleQueryUserList(departmentIds){//根据角色和部门查询用户
      let query={
        isEnabled:0
      }
      this.$api.postSimpleQueryUserList(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
    changeLogistType(value) {
      if(value === 11){
        this.$refs['shipperInfo'].clearValidate(['agencyName','agencyMobile','empName','empMobile','expressCompName','expressLogistNo'])
      }else if(value === 12) {
        this.$refs['shipperInfo'].clearValidate(['agencyName','agencyMobile','empName','empMobile','expressCompName','expressLogistNo'])
        if(this.userlist.length==0){
          this.postSimpleQueryUserList()
        }
      }else if(value === 13) {
        this.$refs['shipperInfo'].clearValidate(['agencyName','agencyMobile','empName','empMobile','expressCompName','expressLogistNo'])
      }
    },
  }
}
</script>

<style scoped lang="scss">
.info{
  .label{
    width: 150px;
    display: inline-block;
    text-align: right;
    padding-right: 12px;
  }
}
</style>