import Cookies from 'js-cookie'
import store from '@/store'

// const TokenKey = Config.TokenKey

export function getToken() {//业务需要永久登录
  return localStorage.getItem('token')
  // return Cookies.get('token')
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set('token', token, { expires: store.state.setting.tokenCookieExpires })
  } else return Cookies.set('token', token)
}

export function removeToken() {
  return Cookies.remove('token')
}
