<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>调拨时间</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
      </el-col>
      <el-col :span="3" class="flex flex_align_center flex_between ">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
        <el-button type="primary" @click="onAdd" size="medium" plain>新增资金调拨</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="serialNo" label="编号" align="center"></el-table-column>
      <el-table-column prop="ckxBankAccountOut" label="转出账号" align="center"></el-table-column>
      <el-table-column prop="ckxBankAccountIn" label="转入账号" align="center"></el-table-column>
      <el-table-column prop="amount" label="转账金额" align="center"></el-table-column>
      <el-table-column prop="operaTime" label="调拨时间" align="center"></el-table-column>
      <el-table-column prop="operaName" label="操作人员" align="center"></el-table-column>
      <el-table-column prop="allotStatus" label="转账状态" align="center"></el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog
      :visible.sync="visible" width="800px" class="customDialog" title="资金调拨">
      <el-form ref="form" :model="form" :rules="rules" >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="转出账号" prop="outAccount">
            <el-select v-model="form.outAccount" placeholder="请选择" class="w250" @change="onSelect(form.outAccount,1)" value-key="id">
              <el-option v-for="item in banklist" :key="item.id" :label="`${item.account}(${item.accountName})`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="转出账号当前余额（元）:">{{form.outAmount}}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="转入账号" prop="inAccount">
              <el-select v-model="form.inAccount" placeholder="请选择"  class="w250" @change="onSelect(form.inAccount,2)" value-key="id">
                <el-option v-for="item in banklist" :key="item.id" :label="`${item.account}(${item.accountName})`" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="转入账号当前余额（元）:">{{form.inAmount}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="转账金额" prop="amount">
              <el-input v-model="form.amount" type="number" class="w250">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">付款</el-button>
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      if (value===''||value===null||value===undefined) {
        return callback(new Error('金额不能为空'))
      } else {
        if (value>0||value==0) {
          callback()
        } else {
          return callback(new Error('金额不能为负数'))
        }
      }
    }
    return {
      form:{},
      params:{},
      rules: {
        outAccount: [{ required: true, message: '不能为空'}],
        inAccount: [{ required: true, message: '不能为空'}],
        amount: [{ required: true,validator: checkAmount, trigger: 'blur'}],
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      banklist:[],
      visible:false,
    };
  },
  created() {
    this.init()
    this.postBankCkxFundAccountList()//车开新银行账号列表【不分页】
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage:this.currentPage,
        pageSize: this.pageSize,
        operaTimeBegin: this.params.date?this.params.date[0]:'',
        operaTimeEnd:this.params.date?this.params.date[1]:'',
      };
      this.$api.postBankCkxFundPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = []
            this.total=0;
          }
        }else{
          this.querylist = []
          this.total=0;
        }
      });
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    onAdd(){
      this.visible=true;
    },
    onSubmit(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let query={
            "ckxBankAccountInId": this.form.ckxBankAccountInId,
            "ckxBankAccountOutId": this.form.ckxBankAccountOutId,
            "amount": this.form.amount
          }
          this.$api.postBankCkxFundCreate(this.$$.delete_null_properties(query)).then(res=>{
            if(res.code==0){
              this.visible=false;
              this.init()
              this.$message.success(res.message)
            }
          })
        }
      });
    },
    postBankCkxFundAccountList(){//车开新银行账号列表【不分页】
      this.$api.postBankCkxFundAccountList().then(res=>{
        if(res.code==0){
          this.banklist=res.data;
        }
      })
    },
    onSelect(row,state){
      if(state==1){
        this.form.ckxBankAccountOutId=row.id;
      }else{
        this.form.ckxBankAccountInId=row.id;
      }
      let query={
        account:row.account
      }
      this.$api.getAvailableAmount(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          if(state==1){
            this.form.outAmount=res.data;
          }else{
            this.form.inAmount=res.data;
          }
        }else{
          if(state==1){
            this.form.outAmount='';
          }else{
            this.form.inAmount='';
          }
        }
        this.$forceUpdate()
      })
    },

  }
};
</script>
<style lang="scss" scoped>

</style>
