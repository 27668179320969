<template>
  <!-- 中间退费 -->
  <div class="refund">
    <el-row :gutter="20" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border class="mt20 table-mainbg" stripe :row-class-name="tableRowClassName">
        <el-table-column prop="customerContractNo" label="合同编号" align="center"></el-table-column>
        <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
        <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
        <el-table-column prop="ccSignDate" label="客户合同签订时间" align="center"></el-table-column>
        <el-table-column prop="brandName" label="品牌车型" align="center">
          <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.seriesName}}</template>
        </el-table-column>
        <el-table-column prop="sellerName" label="成交归属" align="center"></el-table-column>
        <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="$router.push({path: '/salesManager/refund/applyRefund', query: {id:scope.row.customerContractId}})">申请</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt30"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next,sizes, jumper"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        :current-page="curPage"
        :total="total"
      ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      querylist: [],
      querylist1: [],
      querylist2: [],
      curPage: 1, // 页数
      total: 0, // 总页数
      pageSize: 10,
      carNo: '',
    }
  },
  created(){
    // if(this.$route.query.state) this.activeName=this.$route.query.state;
    this.init()
  },
  methods: {
    tableRowClassName({row}){
      if(row.isBmwCar===1){// 1 是 0 不是
        return 'tipsColor'
      }
    },
    init(page) {
      this.curPage=page?page:this.curPage;
      let query={
        "curPage": this.curPage,
        "pageSize": this.pageSize,
        "carNo": this.carNo,
      }
      this.$api.postContractCustomer(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.total = res.data.total;
        }else{
          this.querylist = [];
          this.total = 0;
        }
      })
    },
    handleSizeChange(row){
      this.pageSize=row;
      this.curPage=1;
      this.init()
    },
    handleCurrentChange(row){
      this.curPage=row;
      this.init()
    },
  }, 
}
</script>

<style scoped lang="scss">
.refund{
  padding: 20px 40px;
}
</style>
