<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店简称</span>
        <el-input v-model="form.partnerShortName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>违约类型</span>
        <el-select v-model="form.lossType" placeholder="请选择违约类型" size="medium" clearable>
          <el-option v-for="item in losstypelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>审核状态</span>
        <el-select v-model="form.status" placeholder="请选择审核状态" size="medium" clearable @change="changeTask">
          <el-option v-for="item in statuslist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="onsearch" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt30 table-mainbg">
      <el-table-column prop="shortName" label="合作店简称" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="flowTypeName" label="违约类型" align="center"></el-table-column>
      <el-table-column prop="auditTime" label="审核通过时间" align="center"></el-table-column>
      <el-table-column prop="statusName" label="审核状态" align="center">
      </el-table-column>
      <el-table-column label="操作" width="100" align="center" v-if="activeName=='待审核'">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onexamine(scope.row)"
            size="mini"
          >审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{},
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      losstypelist:[],//违约类型
      statuslist:[],//审核状态
      activeName: "待审核"
    };
  },
  created() {
    this.init();
    this.getLossGivenTypes()//违约类型
    this.getLossGivenCheckStatus()//获取审核状态
  },
  methods: {
    init() {
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        shortName: this.form.partnerShortName, //合作店简称
        carNo: this.form.carNo, //违约车牌号码
        flowType: this.form.lossType, //违约类型
        status:this.form.status
      };
      this.$api.postChannelDefaultPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }
        }
      });
    },
    changeTask(data){
      this.form.taskStatus=data
    },
    onexamine(row){
      this.$router.push({
        path:'/channel/compensateDetails',
        query:{
          id:row.id
        }
      })
    },
    onsearch() {
      this.currentPage=1;
      this.init();
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    getLossGivenTypes(){//违约类型
      this.$api.getLossGivenTypes().then((res)=>{
        if(res){
          if(res.code==0){
            this.losstypelist=res.data;
          }
        }
      })
    },
    getLossGivenCheckStatus(){//获取审核状态
      this.$api.getLossGivenCheckStatus().then((res)=>{
        if(res){
          if(res.code==0){
            this.statuslist=res.data;
          }
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
