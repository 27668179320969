<template>
  <div class="deliveryNote">
    <div class="title">物流发货单</div>
    <div class="line">发货单号：{{logistNo}}</div>
    <DeliverInfo ref="deliverInfo" :shipper="shipper" :tradeShopName="roleItem.name" :takeDelivery="stockItem.name" :roleItem="roleItem"></DeliverInfo>
    <MaterialsInfo ref="mList" :logistMaterialsList="logistMaterialsList" :roleItem="roleItem"></MaterialsInfo>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >提交发货单</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DeliverInfo from '@/components/deliver/info.vue';
import MaterialsInfo from '@/components/deliver/materialsInfo.vue';
export default {
  components: {
    DeliverInfo, MaterialsInfo
  },
  data() {
    return {
      logistNo: null,
      shipper: null,
      carMaterialsList: [],
      logistMaterialsList:[],//材料
      roleItem:{},//当前角色数据
      stockItem:{},//获取收货方
    }
  },
  created(){
    let user=this.$store.state.user.userData;
    this.roleItem=this.$$.roleName(user,'department')
    this.getStockObjDetail()//获取收货方
    // this.getCarMaterialsList()//获取材料列表
  },
  methods: {
    doSubmit() {
      this.$refs.deliverInfo.$refs.shipperInfo.validate(valid => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.$refs.deliverInfo.shipperInfo))
          let arr=[];
          if(this.$refs.mList.logistMaterialsListNew && this.$refs.mList.logistMaterialsListNew.length){
            // arr = this.$refs.mList.logistMaterialsList.map(item => item.id)
            for(let i=0;i<this.$refs.mList.logistMaterialsListNew.length;i++){
              let item=this.$refs.mList.logistMaterialsListNew[i]
              let obj={
                materialsId:item.id,
                sendNums:item.sendNums
              }
              if(!item.sendNums){
                this.$message('材料数量不可为空！')
                return 
              }
              arr.push(obj)
            }
          }
          // console.log(params.fromNo,'====params.fromNo===')
          // console.log(this.stockItem,'====roleItem===')
          let query={
            // "carId": 1,
            // "id": 1,
            "logistType": params.logistType,
            "operaType": "submit",//操作类型：保存 save，提交 submit， save只保存数据，提交会变更状态
            "agencyName": params.agencyName,
            "agencyMobile": params.agencyMobile,
            "fromNo": params.fromNo?params.fromNo.no:'',
            "fromName": params.fromNo?params.fromNo.name:'',
            "fromMobile": params.fromMobile,
            "expressCompName": params.expressCompName,
            "expressLogistNo": params.expressLogistNo,
            "fromShopId": this.roleItem.shop?this.roleItem.shop.id:'',//发货方门店ID
            fromShopName:this.roleItem.shop?this.roleItem.shop.shopName:'',
            // "fromShopType": 41,
            "fromTime": params.fromTime,
            "preArriveTime": params.preArriveTime,
            "toShopId": this.stockItem.id,
            // "toShopType": 41,
            // "toNo": 1617,
            // "toName": "张刘王",
            // "toMobile": 1842323212,
            // "toReceiveTime": "2021-01-02 12:12:12",
            // "materialType": 31,
            // "certCompanyId": 1,//办证公司ID
            "materialsFormList": arr
          }
          console.log(params,'===333===params==',query)
          this.$api.logisticsAdd(this.$$.delete_null_properties(query)).then(res => {
            if(res.code === 0){
              this.routePage()
              this.$message.success(res.message)
            }
          })
        } else {
          return false;
        }
      });
    },
    getCarMaterialsList(carNo){//待入库和在库状态的材料 查询列表
      let query={
        shopIds:[this.roleItem.shop.id],
        carNo:carNo
      }
      this.$api.getMaterialsList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.carMaterialsList=res.data;
        }
      })
    },
    getStockObjDetail(){//根据仓库名称查询详情查询
      this.$api.getStockObjDetail('交付中心').then(res=>{
        if(res.code==0){
          this.stockItem=res.data;
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/salesManager/deliverGoods/deliverGoods',
        query:{
          state:'second'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.deliveryNote{
  padding: 20px 40px 110px;
  .title{
    text-align: center;
    padding: 0px 0 20px;
    font-size: 24px;
  }
  .line{
    padding: 12px 0 12px 40px;
    margin: 0 -40px;
    background: #EDEDED;
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar-uploader{
    position: relative;
    .imgName{
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.1);
      line-height: 40px;
    } 
  }
}
</style>