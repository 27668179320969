<template>
  <div class="pageContainer bgF5 pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:''
    };
  },
  created() {
    this.getChannelQueryStatus();
  },
  methods: {
    getChannelQueryStatus() {
      let query={
        taskStatus:'1,3,4,5,8'
      }
      //状态：1:待审核，2:审核通过，3审核驳回，4审核失败，5待签约，6已签约，7签约失败，8已解约，9佣金待审核，11佣金审核通过，12钱包提现待审核，13钱包提现审核成功，14钱包提现审核失败，15违约赔偿待审核，16违约赔偿审核成功
      this.$api.getChannelQueryStatus(query).then((res) => {
        if (res.code == 0) {
          let newArr=[];
          let aurl=''
          for(let i=0;i<res.data.length;i++){
            switch(res.data[i].name){
              case '待审核'://1
                aurl='/channel/stayExamine'
                break;
              case '审核驳回'://3
                aurl='/channel/reviewRejected'
                break;
              case '审核失败'://4
                aurl='/channel/auditFailed'
                break;
              case '审核通过(待签约)':
              case '审核通过':
              case '待签约'://5
                aurl='/channel/staySign'
                break;
              case '已签约'://6
                aurl='/channel/alreadySign'
                break;
              case '签约失败'://7
                aurl='/channel/signingFailed'
                break;
              case '账号过期'://
                aurl='/channel/accountExpired'
                break;
              case '已解约'://8
              case '签约解除':
                aurl='/channel/rescind'
                break;
              default:
                aurl=''
            }
            let obj= {
                num: res.data[i].count,
                name: res.data[i].name,
                star: 0,
                url:aurl
              }
            newArr.push(obj)
          }
          let customObj=[{
            num: '',
            name: '检测管理',
            star: 0,
            url:'/channel/detectionManagement'
          },{
            num: '',
            name: '大客户车辆信息',
            star: 0,
            url:'/channel/customerCar'
          }]
          this.infolist=[{name:"",list:newArr},{name:"",list:customObj}];
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>