import { render, staticRenderFns } from "./insuredMerchantDetails.vue?vue&type=template&id=11d1392b&scoped=true&"
import script from "./insuredMerchantDetails.vue?vue&type=script&lang=js&"
export * from "./insuredMerchantDetails.vue?vue&type=script&lang=js&"
import style0 from "./insuredMerchantDetails.vue?vue&type=style&index=0&id=11d1392b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11d1392b",
  null
  
)

export default component.exports