<template>
  <div class="center">
    <div class="ckxTitle">
      <span>用户信息</span>
    </div>
    <div class="title">
      <span>归属部门：</span>
      <span class="department bluetxt">{{user.role}}</span>
      <span>当前角色：</span>
      <span class="bluetxt">{{user.store}}</span>
    </div>
    <div class="userInfo">
      <div>姓名: &nbsp; {{user.info.name}}</div>
      <div>邮箱: &nbsp; {{user.info.email?user.info.email:'-'}}</div>
      <div>手机: &nbsp; {{user.info.tel}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        role: '-',
        store: '-',
        info:{
          name: '-',
          email: '-',
          tel: '-',
        }
      },
    }
  },
  created(){
    this.userId=this.$store.state.user.userId;
    this.init();
  },
  methods:{
    init(){
      let that=this;
      this.$api.getUserData(this.userId).then((res)=>{
        if(res.code==0){
          let userData=res.data
          that.user.info={
            name:userData.name,
            email:userData.email,
            tel:userData.mobile
          }
          let roleList=userData.roleList;
          let departmentList=userData.departmentList;
          for(let i=0;i<roleList.length;i++){
            if(roleList[i].isCurrent==0){
              this.user.store=roleList[i].name;//角色
            }
          }
          for(let i=0;i<departmentList.length;i++){
            if(departmentList[i].isCurrent==0){
              this.user.role=departmentList[i].name;//部门
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.center{
  font-size: 20px;
  padding: 40px;
  .title{
    line-height: 36px;
    margin-top: 15px;
    padding-left: 17px;
    .department{
      margin-right: 60px;
    }
  }
  .userInfo{padding-left: 50px;line-height: 56px;}
}
</style>