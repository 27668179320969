<template>
<!-- 商城寄售 -->
  <div class="consign">
    <!-- <div class="ckxTitle mb20">寄售商城</div> -->
    
    <el-table :data="tableData" border stripe class="table-mainbg">
      <el-table-column prop="carNo" label="车牌" align="center"></el-table-column>
      <el-table-column prop="tradePrice" label="寄售价格" align="center"></el-table-column>
      <el-table-column prop="auctionEndTime" label="寄售结束时间" align="center"></el-table-column>
      <el-table-column prop="carAuctionStatusName" label="寄售状态" align="center"></el-table-column>
      <el-table-column prop="contactPhone" label="联系人电话" align="center"></el-table-column>
      <el-table-column prop="concernCounts" label="关注人数" align="center"></el-table-column>
      <el-table-column prop="styleName" label="车系与年款" align="center">
        <template slot-scope="scope">{{scope.row.seriesName}}&nbsp;&nbsp;{{scope.row.styleName}}</template>
      </el-table-column>
      <el-table-column prop="notifyType" label="通知类型" align="center">
        <template slot-scope="scope">{{scope.row.notifyType==31?'个人':scope.row.notifyType==32?'全部':scope.row.notifyType}}</template>
      </el-table-column>
      <el-table-column prop="operatorName" label="操作人" align="center"></el-table-column>
      <el-table-column label="操作" align="center" :width="tableData.length>0?[202].includes(tableData[0].carAuctionStatus)?'320px':'120px':''">
        <template slot-scope="scope">
          <el-button type="primary" @click="onOperator(scope.row,1)" size="mini" v-if="[201,202].includes(scope.row.carAuctionStatus)">终止寄售</el-button>
          <el-button type="primary" @click="onOperator(scope.row,2)" size="mini" v-if="[202].includes(scope.row.carAuctionStatus)">修改价格</el-button>
          <el-button type="primary" @click="onOperator(scope.row,3)" size="mini" v-if="scope.row.isUpdatePrice===1&&[202].includes(scope.row.carAuctionStatus)">降价通知</el-button>
          <el-button type="primary" @click="onOperator(scope.row,4)" size="mini" v-if="[203,204,205].includes(scope.row.carAuctionStatus)">发寄售</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <el-dialog
      title="发布寄售"
      :visible.sync="dialogVisible"
      width="600px">
      <el-form ref="form" :model="params" label-width="160px">
        <el-form-item label="寄售结束时间">
          <el-date-picker v-model="params.auctionEndTime" :picker-options="pickerOptions" type="date" value-format="yyyy-MM-dd" class="ww100"> </el-date-picker>
        </el-form-item>
        <el-form-item label="寄售价格">
          <el-input v-model="params.tradePrice" ty="number"></el-input>
        </el-form-item>
        <el-form-item label="操作人联系电话">
          <el-input v-model="params.contactPhone"></el-input>
        </el-form-item>
        <el-form-item label="需追加检测报告总结">
          <el-input type="textarea" v-model="params.appendReport"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">发寄售</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="降价通知" class="consignModal"
      :visible.sync="dialogVisibleRadio"
      width="700px">
      <el-row class="mb10">
        <el-col :span="24">
          <span>通知对象：</span>
          <el-radio-group v-model="notifyType">
            <el-radio :label="32">全部车商</el-radio>
            <el-radio :label="31">指定车商</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <div v-show="notifyType===31">
      <p class="mb10">选择车商</p>
      <el-row :gutter="24" class="mb10">
        <!-- <el-col :span="4">选择车商</el-col> -->
        <el-col :span="6">
          <el-input v-model="form.merchantName" placeholder="请输入车商姓名"  size="small"></el-input>
        </el-col>
        <el-col :span="10">
          <el-input v-model="form.merchantMobile" placeholder="请输入车商手机号" size="small"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="getSignedList(1)" size="small">查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="signedList" border stripe class="table-mainbg" size="small"
      @select="select" ref="multipleTable"
      @row-click="rowClick"
      @selection-change="selectionChange" >
        <el-table-column type="selection" label="" align="center" width="55">
          <template></template>
        </el-table-column>
        <el-table-column prop="merchantNo" label="车商Id" align="center"></el-table-column>
        <el-table-column prop="merchantName" label="车商姓名" align="center"></el-table-column>
        <el-table-column prop="merchantMobile" label="车商手机号" align="center"></el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[5,10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="total" 
    ></el-pagination>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitNote">发送通知</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
    title="修改价格"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15">寄售价格（元）：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      params: {
        auctionEndTime: '',
        tradePrice: '',
        contactPhone: '',
        appendReport: '',
      },
      tableData: [],
      page_index: 1, // 页数
      total: 0, // 总页数
      page_size: 10,
      dialogVisible:false,
      dialogVisibleRadio:false,
      itemRow:{},
      enquiryId:'',//询价id
      notifyType:'',
      signedList:[],
      form:{
        merchantName:'',
        merchantMobile:''
      },
      selectData:[],//选择的数据
      resId:'',
      pickerOptions:{
        disabledDate(date) {//禁止选择当前日期之前的日期（今天可选）
          let hours=new Date().getHours()
          if(hours<16){//可以选择今天包含今天
            return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
          }else{//不可以选择今天，只能选择今天以后的
            return date.getTime() <= Date.now();
          }
        },
      },
      customerId:'',
    }
  },
  created() {
    if(this.$route.query.enquiryId){
      this.enquiryId=this.$route.query.enquiryId;
      this.getConsignSaleInfo(this.enquiryId)
    }
    if(this.$route.query.customerId) this.customerId=this.$route.query.customerId;
  },
  methods: {
    getConsignSaleInfo(enquiryId){
      this.$api.getConsignSaleInfo(enquiryId).then(res=>{
        if(res.code==0){
          if(res.data){
            this.tableData=[res.data]
            this.resId=res.data.id;
            if(res.data.isUpdatePrice===1&&[202].includes(res.data.carAuctionStatus)){
              this.getSignedList()
            }
          }else{
            this.dialogVisible=true;
          }
        }
      })
    },
    onSubmit() {
      this.postConsignSaleCreate()
    },
    onOperator(row,state){
      let _this=this;
      this.params=JSON.parse(JSON.stringify(row))
      this.itemRow=JSON.parse(JSON.stringify(row))
      switch(state){
        case 1://终止寄售
          _this.$confirm('确认终止寄售吗！', '终止寄售', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postConsignSaleStop(row.id)
          }).catch(() => {});
          break;
        case 2://修改价格
          this.dialogVisibleCar=true;
          this.formCar='';
          break;
        case 3://降价通知
          _this.dialogVisibleRadio=true;
          break;
        case 4://发寄售
          _this.dialogVisible=true;
          _this.params={
            auctionEndTime: '',
            tradePrice: '',
            contactPhone: '',
            appendReport: '',
          }
          break;
      }
    },
    onSubmitCar(){//修改车牌号
      let _this=this;
      if(!this.formCar){
        this.$message.warning('请输入价格！')
        return
      }
      this.putConsignSaleUpdate(this.itemRow.id,this.formCar)
    },
    postConsignSaleCreate(){//新增车辆寄售信息
      let query={
        "linkAuctionId": this.enquiryId,
        "auctionEndTime": this.params.auctionEndTime,
        "tradePrice": this.params.tradePrice,
        "contactPhone": this.params.contactPhone,
        "appendReport": this.params.appendReport,
        customerId:this.customerId,
      }
      this.$api.postConsignSaleCreate(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.getConsignSaleInfo(this.enquiryId)
          this.$message.success(res.message)
          this.dialogVisible=false;
        }
      })
    },
    postConsignSaleSendPrice(){//发送降价通知
      let query={
        "id": this.resId,
        "notifyType": this.notifyType,
      }
      let arr=this.selectData.map(item=>{return item.id})
      if(this.notifyType===31){
        // query.notifyMerchantId= row.id;
        // query.notifyMerchantName= row.merchantName;
        query.notifyMerchantIdList=arr
      }
      this.$api.postConsignSaleSendPrice(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.getConsignSaleInfo(this.enquiryId)
          this.$message.success(res.message)
          this.dialogVisibleRadio=false;
        }
      })
    },
    postConsignSaleStop(id){//终止寄售
      this.$api.postConsignSaleStop(id).then(res=>{
        if(res.code==0){
          this.getConsignSaleInfo(this.enquiryId)
          this.$message.success(res.message)
        }
      })
    },
    putConsignSaleUpdate(id,tradePrice){//修改价格
      let query={
        "tradePrice": tradePrice
      }
      this.$api.putConsignSaleUpdate(id,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          if(this.formCar){
            this.dialogVisibleCar=true;
          }
          this.getConsignSaleInfo(this.enquiryId)
          this.$message.success(res.message)
        }
      })
    },
    getSignedList(curPage){//查询车商列表
      this.page_index=curPage?curPage:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        merchantName:this.form.merchantName,
        merchantMobile:this.form.merchantMobile,
      }
      this.$api.getSignedList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.signedList=res.data.records;
          this.total=res.data.total;
        }else{
          this.signedList=[]
          this.total=0
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getSignedList()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getSignedList()
    },
    onSubmitNote(){
      this.postConsignSaleSendPrice()
    },
    select(selection, row) {
      // 清除 所有勾选项
      this.$refs.multipleTable.clearSelection()
      // 当表格数据都没有被勾选的时候 就返回
      // 主要用于将当前勾选的表格状态清除
      if(selection.length == 0) return 
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 表格的选中 可以获得当前选中的数据
    selectionChange(val) {
      // 将选中的数据存储起来
        this.selectData = val
        // if(val.length>0){
        //   this.selectData=[val[val.length-1]]
        // }
    },
    // 表格某一行的单击事件
    rowClick(row) {
        const selectData = this.selectData
        this.$refs.multipleTable.clearSelection()
        if( selectData.length == 1 ) {
            selectData.forEach(item => {
              // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
                if (item == row) {
                    this.$refs.multipleTable.toggleRowSelection(row, false);
                }
                // 不然就让当前的一行勾选
                else {
                    this.$refs.multipleTable.toggleRowSelection(row, true);
                }
            })
        } 
        else {
            this.$refs.multipleTable.toggleRowSelection(row, true);
        }
    },
  }
}
</script>

<style scoped lang="scss">
.consign{
  padding: 40px 40px 100px;
  .consignModal{
    ::v-deep.el-dialog__body{
    padding:0 20px;
  }
  }
}
</style>