<template>
  <div class="pageContainer bgF5" ref="pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
    <!-- <kpi :kapilist="kapilist" ref="kpi"></kpi>
    <div class="ckxTitle mt30 mb20">数据看版</div>
    <div class="dataBoard">
      <h4>当月签约客户数明细</h4>
      <el-table
        :data="dataNumberList" 
        border>
        <el-table-column
          :fixed="index===0?true:false||index===dataNameList.length-1?'right':false"
          :prop="item.state"
          :label="item.name" v-for="(item,index) in dataNameList" :key="index" align="center">
        </el-table-column>
      </el-table>
      <h4>成交转化率排名</h4>
      <el-table
        :data="dealNumberList"
        border>
        <el-table-column
          prop="date"
          :label="item.name" v-for="item in dealNameList" :key="item.id" align="center">
        </el-table-column>
      </el-table>
    </div> -->
  </div>
</template>

<script>
import task from "@/components/task/task";
// import kpi from "@/components/kpi/kpi";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
      // kapilist:[{
      //   title:'​截至目前成交转化率',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',//计算了百分比之后的值
      //   color:'#0099FF',
      //   colorbg:'#CAE9FF'
      // },{
      //   title:'当月截止目前成交客户数',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',
      //   color:'#1FC678',
      //   colorbg:'#A5E7CE'
      // }],//KPI指标
      // dealNameList:[],//成交转化率名称
      // dealNumberList:[],//值
      // dataNameList:[],//数据看板名称
      // dataNumberList:[],//值
    };
  },
  created() {
    // this.dealNameList.push({id:0,name:'排名'})
    // for(let i=1;i<11;i++){
    //   let num=this.$$.noToChinese(i)
    //   if(num=='一十'){
    //     num='十'
    //   }
    //   let obj={
    //     id:i,
    //     name:`第${num}名`
    //   }
    //   this.dealNameList.push(obj)
    // }

    // //数据看板
    // this.dataNameList.push({id:0,name:'日期',state:'name'})
    // let arr=[]
    // for(let i=1; i < 31; i++){
    //   let obj={
    //     id:i,
    //     name:String(i),
    //     state:'name'+i
    //   }
    //   this.dataNameList.push(obj)
    //   //看板值
    //   // let obj1=`{name${i}:${i*10}}`
    //   let obj1=`{name${i}:''}`
    //   let objA=eval("("+obj1+")")
    //   arr.push(objA)
    // }
    // let newObj={}
    // arr.push({name:'签约客户数'})
    // for(let i=0;i < arr.length; i++){
    //   newObj = { ...newObj, ...arr[i] }
    // }
    // this.dataNumberList.push(newObj)
    // this.dataNameList.push({id:0,name:'合计',state:''})
    let newArr1=[
      {
        num: '',
        name: '检测待预约',
        star: 0,
        url:'/salesManager/testOrder'
      },
      {
        num: '',
        name: '当日待分配',
        star: 0,
        url:'/salesManager/testAllocation',
        urlName:'testAllocation',
        params:{state:'first'}
      },
      {
        num: '',
        name: '次日检测跟进',
        star: 0,
        url:'/salesManager/followUpNext'
      },
      {
        num: '',
        name: '延期竞拍跟进',
        star: 0,
        url:'/salesManager/deferredAuction'
      }
    ]
    let newArr2=[
      {
        num: '',
        name: '成交待预约',
        star: 0,
        url:'/salesManager/transactionReservation'
      },
      {
        num: '',
        name: '新任务',
        star: 0,
        url:'/salesManager/transactionReservation',
        urlName:'transactionReservation',
        params:{state:'second'}
      },
      {
        num: '',
        name: '当日预约跟进',
        star: 0,
        url:'/salesManager/transactionReservation',
        urlName:'transactionReservation',
        params:{state:'third'}
      },
      {
        num: '',
        name: '验车待分配',
        star: 0,
        url:'/salesManager/yancherukufenpei'
      },
      {
        num: '',
        name: '待签约',
        star: 0,
        url:'/salesManager/signContract',
        urlName:'signContract',
        params:{state:'second'}
      },
      {
        num: '',
        name: '签约中',
        star: 0,
        url:'/salesManager/signContract',
        urlName:'signContract',
        params:{state:'third'}
      }
    ]
    let newArr3=[
      {
        num: '',
        name: '当日手续跟进',
        star: 0,
        url:'/salesManager/materialsFollowUp',
        urlName:'materialsFollowUp',
        params:{state:'second'}
      },
      {
        num: '',
        name: '待发货',
        star: 0,
        url:'/salesManager/deliverGoods/deliverGoods'
      }
    ]
    this.infolist=[{name:"检测",list:newArr1},{name:"成交",list:newArr2},{name:"交割",list:newArr3}];

    let user=this.$store.state.user.userData;
    let de=this.$$.roleName(user,'department')
    let id='';
    if(de){
      id=de.shop?de.shop.id:'';
    }
    if(id){
      this.init(id);
      this.getCheckBookFollowUpCount(id)//帮卖经理-工作台（检测待预约）
      this.getCheckCount(id)//帮卖经理-工作台（检测待分配/次日检测跟进）
      this.getExtensionAuctionNoFollowUpCount(id)//帮卖经理-工作台（竞拍延期跟进）
      this.getDealBookCount(id)//帮卖经理-工作台（成交待预约/新任务/当日预约跟进）
    }
  },
  methods: {
    init(id) {
      this.$api.getWorkbenchSellerCount(id).then((res) => {
        if (res.code == 0) {
          this.infolist[1].list[3].num=res.data.countAllotCar;//统计验车待分配数量
          this.infolist[1].list[4].num=res.data.countCcWaitedSign;//统计客户合同待签约数量
          this.infolist[1].list[5].num=res.data.countCcSigning;//统计客户合同签约中数量
          this.infolist[2].list[0].num=res.data.countMpLoss;//当日手续跟进
          this.infolist[2].list[1].num=res.data.countSendWaited;//待发货
          // this.infolist[2].list[0].num=res.data.countCcLoss//统计客户合同手续跟进数量
          // this.infolist[2].list[1].num=res.data.countSendWaited//统计待发货数量

          // console.log(this.infolist,'-----555---')
          //  this.kapilist[0].targetVal=res.data.validQuotationTargetCount;//目标值
          // this.kapilist[0].actualVal=res.data.validQuotationCount;//实际值
          // this.kapilist[0].value=(res.data.validQuotationCount/res.data.validQuotationTargetCount*10000)/100;//计算了百分比之后的值
          // this.kapilist[1].targetVal=res.data.netGrowthTargetCount;//目标值
          // this.kapilist[1].actualVal=res.data.netGrowthCount;//实际值
          // this.kapilist[1].value=(Number(res.data.netGrowthCount)/Number(res.data.netGrowthTargetCount)*10000)/100;//计算了百分比之后的值
          // this.$refs.kpi.initChart('charid0',this.kapilist[0]);
          // this.$refs.kpi.initChart('charid1',this.kapilist[1]);
          // this.$nextTick(() => {
          //   this.pageHeight=this.$$.workbenchHeight('pageCombox')
          // })
        }
      });
    },
    getCheckBookFollowUpCount(id){//帮卖经理-工作台（检测待预约）
      this.$api.getCheckBookFollowUpCount(id).then(res=>{
        if(res.code===0){
          this.infolist[0].list[0].num=res.data;
        }
      })
    },
    getCheckCount(id){//帮卖经理-工作台（检测待分配/次日检测跟进）
      this.$api.getCheckCount(id).then(res=>{
        if(res.code===0){
          this.infolist[0].list[1].num=res.data.countNoAssign;//待分配
          this.infolist[0].list[2].num=res.data.countNextDayFollowUp;//次日检测跟进
        }
      })
    },
    getExtensionAuctionNoFollowUpCount(id){//帮卖经理-工作台（竞拍延期跟进）
      this.$api.getExtensionAuctionNoFollowUpCount(id).then(res=>{
        if(res.code===0){
          this.infolist[0].list[3].num=res.data;//竞拍延期跟进
        }
      })
    },
    getDealBookCount(id){//帮卖经理-工作台（成交待预约/新任务/当日预约跟进）
      this.$api.getDealBookCount(id).then(res=>{
        if(res.code===0){
          this.infolist[1].list[0].num=res.data.countNoBook;//成交待预约
          this.infolist[1].list[1].num=res.data.countNew;//新任务
          this.infolist[1].list[2].num=res.data.countTodayFollowUp;//当日预约跟进
        }
      })
    },
  },
  components: {
    task, //业务
    // kpi
  }
};
</script>

<style lang="scss" scoped>
.dataBoard{
  background:#ffffff;
  text-align: center;
  padding: 20px;
  h4{
    font-size: 16px;
    padding: 20px 0;
  }
}

</style>
