<template>
  <div class="pageContainer">
    <!--基本信息-->
    <template v-if="stateType==0||stateType==2">
      <el-row>
        <el-col :span="5">姓名：{{form.merchantName}}</el-col>
        <el-col :span="7">身份证号：{{form.authIdCard}}</el-col>
        <el-col :span="5">经营地址：{{form.businessProvinceName}}{{form.businessCityName}}{{form.businessAddress}}</el-col>
      </el-row>
    </template>
    <template v-if="stateType==1||stateType==3">
      <el-row>
        <el-col :span="5">公司名称：{{form.authCompanyName}}</el-col>
        <el-col :span="5">姓名：{{form.merchantName}}</el-col>
        <el-col :span="5">营业执照号：{{form.authBusinessLicense}}</el-col>
        <el-col :span="5">身份证号：{{form.authIdCard}}</el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="5">注册地址：{{form.authRegisterProvinceName}}{{form.authRegisterCityName}}{{form.authRegisterAddress}}</el-col>
        <el-col :span="5">是否法人：{{form.authIsLegal?'是':'否'}}</el-col>
        <el-col :span="5">经营地址：{{form.businessProvinceName}}{{form.businessCityName}}{{form.businessAddress}}</el-col>
      </el-row>
    </template>
    <!-- <div class="content_title_Box ">
      <el-form
        ref="form"
        :model="form"
        label-width="110px"
        :rules="rules"
        :inline="true"
        :hide-required-asterisk="true"
        class="formCustomRow"
      >
        <template v-if="stateType==0||stateType==2">
          <el-form-item label="姓名：">
            <p>{{form.merchantName}}</p>
          </el-form-item>
          <el-form-item label="身份证号：">
            <p>{{form.authIdCard}}</p>
          </el-form-item>
          <el-form-item label="经营地址：">
            <p>{{form.businessAddress}}</p>
          </el-form-item>
        </template>
        <template v-if="stateType==1||stateType==3">
          <el-form-item label="公司名称：">
            <p>{{form.authCompanyName}}</p>
          </el-form-item>
          <el-form-item label="姓名：">
            <p>{{form.merchantName}}</p>
          </el-form-item>
          <el-form-item label="营业执照号：">
            <p>{{form.authBusinessLicense}}</p>
          </el-form-item>
          <el-form-item label="身份证号：">
            <p>{{form.authIdCard}}</p>
          </el-form-item>
          <el-form-item label="注册地址：">
            <p>{{form.authRegisterAddress}}</p>
          </el-form-item>
          <el-form-item label="是否法人：">
            <p>{{form.authIsLegal?'是':'否'}}</p>
          </el-form-item>
          <el-form-item label="经营地址：">
            <p>{{form.businessAddress}}</p>
          </el-form-item>
        </template>
      </el-form>
    </div> -->
    <!--认证信息-->
    <div class="authBox mt30 pb100">
      <div class="content_modal_textBox">
        <div class="content_title_Box">
          <div class="photo_content_box mt10" v-if="stateType==1||stateType==3">
            <div class="photo_row_imgBox"><!--公司营业执照照片-->
              <el-image :src="form.authBusinessLicensePhoto" :preview-src-list="[form.authBusinessLicensePhoto]" alt="公司营业执照照片">
                <div slot="error">暂无公司营业执照图片</div>
              </el-image>
              <!-- <img
                :src="form.authBusinessLicensePhoto" alt="公司营业执照照片"
              /> -->
            </div>
            <div class="photo_row_imgBox" v-if="form.authIsLegal===0"><!--公司委托书照片（不是法人展示委托书）-->
              <el-image :src="form.authPowerOfAttorneyPhoto" :preview-src-list="[form.authPowerOfAttorneyPhoto]" alt="公司委托书照片">
                <div slot="error">暂无公司委托书图片</div>
              </el-image>
            </div>
          </div>
          <div class="photo_content_box mt10">
            <div class="photo_row_imgBox"><!--身份证前照片-->
              <el-image :src="form.authFullFace" :preview-src-list="[form.authFullFace]" alt="身份证前照片">
                <div slot="error">暂无图片</div>
              </el-image>
              <!-- <img
                :src="form.authFullFace" alt="身份证前照片"
              /> -->
            </div>
            <div class="photo_row_imgBox"><!--身份证反面照片-->
              <el-image :src="form.authReverseFace" :preview-src-list="[form.authReverseFace]" alt="身份证反面照片">
                <div slot="error">暂无图片</div>
              </el-image>
              <!-- <img
                :src="form.authReverseFace" alt="身份证反面照片"
              /> -->
            </div>
            <div class="photo_row_imgBox"><!--身份证个人照片-->
              <el-image :src="form.authPermitPhoto" :preview-src-list="[form.authPermitPhoto]" alt="身份证个人照片">
                <div slot="error">暂无图片</div>
              </el-image>
              <!-- <img
                :src="form.authPermitPhoto" alt="身份证个人照片"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="stateType==0||stateType==1">
      <div class="btn">
        <el-button type="primary" @click="onauthenBut(0)">审核驳回</el-button>
        <el-button type="primary" @click="onauthenBut(1)">认证通过</el-button>
      </div>
    </div>

     <el-dialog
      width="500px"
      :show-close="false"
      :class="dialogAuction==1?'':'customDialog'"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
    >
      <div v-if="dialogAuction==1"> 您确认该商户认证通过吗？</div>
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        :rules="rules"
        :hide-required-asterisk="true"
        v-else
      >
        <el-form-item label="驳回原因" prop="rejectReason" >
          <el-input type="textarea" v-model="form.rejectReason" size="medium"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="onSubmit('form')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //接收状态值，0个人(可操作)，1企业(可操作),2个人查看，3企业查看
      form: {},
      dialogVisible: false,
      dialogTitle:'',
      rules: {
        rejectReason: [{ required: true, message: "驳回原因不能为空" }]
      },
      dialogAuction:0,
      merchantId:''
    };
  },
  created() {
    // if(this.$route.query.state) this.stateType = Number(this.$route.query.state);
    this.merchantId=this.$route.query.id;
    this.getMerchantDetails()//获取详情
  },
  methods: {
    onauthenBut(state) {
      this.ondialogVisible();
      if(state==0){
        this.dialogAuction=0;
        this.dialogTitle='审核驳回';
        // Object.keys(this.form).forEach(key => {
        //   this.form[key] = "";
        // });
        // this.$nextTick(() => {
        //   this.$refs["form"].clearValidate();
        // });
      }else{
        this.dialogAuction=1;
        this.dialogTitle='';
      }
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmit(formdata) {
      if(this.dialogAuction==0){
        this.$refs[formdata].validate(valid => {
          if (valid) {
            this.ondialogVisible();
            this.postManualAudit(0);//审核结果 0-驳回 1-通过
          } else {
            return false;
          }
        });
      }else{
        this.postManualAudit(1);//审核结果 0-驳回 1-通过
      }
      
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/merchant/authentication"
      });
    },
    getMerchantDetails(){//获取详情
      this.$api.getMerchantDetails(this.merchantId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            //stateType接收状态值，0个人(可操作)，1企业(可操作),2个人查看，3企业查看
            if(res.data.merchantType===0){//个人
              if(res.data.authStatus===0){//认证状态 0-待认证 1-认证通过 2-认证驳回
                this.stateType=0
              }else{
                this.stateType=2
              }
            }
            if(res.data.merchantType===1){//企业
              if(res.data.authStatus===0){//认证状态 0-待认证 1-认证通过 2-认证驳回
                this.stateType=1
              }else{
                this.stateType=3
              }
            }
          }
        }
      })
    },
    postManualAudit(auditStatus){//审核
      let query={
        result:auditStatus,//审核结果 0-驳回 1-通过
        reason:this.form.rejectReason,//认证审核驳回原因
      }
      this.$api.postManualAudit(this.merchantId,query).then((res)=>{
        if(res){
          if(res.code==0){
            this.onroute();
          }
        }
      })
    }
  }
};
</script>
<style >
.el-textarea__inner {
  height: 100px;
}
</style>
<style lang="scss" scoped>
.contractorBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.pageContainer {
  background-color: #ffffff;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    .content_title_row {
      label {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
    .el-form-item {
      margin-bottom: 0;
      width: 48%;
    }
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .w90 {
    width: 90px;
  }
  .w100 {
    width: 100%;
  }
  .authBox {
    margin-bottom: -30px;
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -10px;
    .photo_row_imgBox {
      width: 334px;
      margin: 0 10px 50px;
      height: 195px;
      background: #f5f5f5;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        max-height: 100%;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
}
@media only screen  and (min-width:1400px) and (max-width:1700px) {
    .pageContainer .w50{
        width: 60%;
    }
    .pageContainer .photo_content_box{
        width: 80%;
    }

}
@media only screen and (max-width:1400px) {
    .pageContainer .w50{
        width: 60%;
    }
    .pageContainer .photo_content_box{
        width: 100%;
    }
}
@media only screen and (max-width:1350px) {
    .pageContainer .w50,
    .pageContainer .photo_content_box{
        width: 100%;
    }
}
@media only screen and (max-width:1280px) {
    .pageContainer .w50,
    .pageContainer .photo_content_box{
        width: 100%;
    }
}
</style>
