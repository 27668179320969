<template>
  <!-- 商户预约 -->
  <div class="contenPageBox">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>经销商名称</span>
        <el-input v-model="params.dealerNameCn" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>经销商编号</span>
        <el-input v-model="params.dealerCode" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>合作店名称</span>
        <el-input v-model="params.channelShortName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>绑定状态</span>
        <el-select v-model="params.bindStatus" size="medium" clearable>
          <el-option label="未绑定" :value="1"></el-option>
          <el-option label="已绑定" :value="0"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2"  class="mb10">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border class="mt10 table-mainbg" :row-class-name="tableRowClassName">
      <el-table-column prop="dealerCode" align="center" label="经销商编号" ></el-table-column>
      <el-table-column prop="dealerNameCn" align="center" label="经销商名称" ></el-table-column>
      <el-table-column prop="dealerAdd" label="经销商地址" align="center"  ></el-table-column>
      <el-table-column prop="updatedTime" align="center" label="更新时间" ></el-table-column>
      <el-table-column prop="channelShortName" align="center" label="合作店简称" ></el-table-column>
      <el-table-column prop="extensionerName" align="center" label="拓展专员" ></el-table-column>
      <el-table-column prop="bindStatusName" align="center" label="绑定状态" ></el-table-column>
      <el-table-column label="操作" align="center" width="150px" >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="onOperate(scope.row)">绑定已签约4S店</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <el-dialog
    title="合作店绑定"
    :visible.sync="dialogVisibleCar" width="850px">
    <p class="mb10" >宝马经销商名称：{{itemRow.dealerNameCn}}</p>
    <p class="mb10" >选择车开新要绑定的合作店：</p>
    <div class="flex flex_align_center">
      <label>合作店简称</label>
      <el-input v-model="shortName" class="w200 ml5" size="medium"></el-input>
      <el-button type="primary" @click="getPageNoRole(1)" size="medium" plain class="ml10">查询</el-button>
    </div>
    <el-table :data="channelList" border stripe class="mt10 " max-height="400px" size="mini">
      <el-table-column prop="channelCode" align="center" label="渠道编号" width="100px"></el-table-column>
      <el-table-column prop="shortName" align="center" label="合作店简称" ></el-table-column>
      <el-table-column prop="name" label="合作店全称" align="center"  ></el-table-column>
      <el-table-column label="操作" align="center" width="110px" >
        <template slot-scope="scope">
          <p v-if="itemRow.channelShortName===scope.row.shortName">已绑定</p>
          <el-button size="mini" type="primary" plain @click="onSubmit(scope.row)" v-else>确定绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt10"
      background
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size1"
      :current-page="page_index1"
      :total="page_total1" :small="true"
    ></el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisibleCar = false">关闭</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibleCar:false,
      shortName:'',
      params: {
        channelShortName: '',
        dealerNameCn: '',
        bindStatus:'',
        dealerCode:'',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      itemRow:{},
      channelList:[],
    }
  },
  created() {
    this.initData();
    this.getPageNoRole();//获取渠道列表
  },
  methods: {
    tableRowClassName({row}){
      if(row.bindStatus===0){
        return 'lightGrayBG'
      }
    },
    onOperate(row){
      this.dialogVisibleCar=true;
      this.itemRow=JSON.parse(JSON.stringify(row))
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    handleSizeChange1(row){
      this.page_size1=row;
      this.page_index1=1;
      this.getPageNoRole()
    },
    handleCurrentChange1(row){
      this.page_index1=row;
      this.getPageNoRole()
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let query={
        curPage: this.page_index,
        pageSize: this.page_size, 
        dealerNameCn:this.params.dealerNameCn,
        bindStatus:this.params.bindStatus,
        channelShortName:this.params.channelShortName,
        dealerCode:this.params.dealerCode,
      }
     this.$api.postPageDealerInfo(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.page_total = res.data.total;
          } else {
            this.querylist = [];
            this.page_total = 0;
          }
        }
      });
      
    },
    onSubmit(row) {
      this.$confirm(`请确认是否绑定${row.shortName}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          channelId:row.id,
          dealerInfoId:this.itemRow.id
        }
        this.$api.postBindDealerChannel(this.$$.delete_null_properties(query)).then( res => {
          if(res.code == 0){
            this.itemRow.channelShortName=row.shortName;
            this.initData()
            this.$message.success(res.message);
          }
        })
      }).catch(() => {});
    },
    getPageNoRole(page){//渠道列表
      if(page){
        this.page_index1=page;
      }
      let query={
        curPage: this.page_index1,
        pageSize: this.page_size1, 
        shortName:this.shortName,
        status:3,//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
      }
      this.$api.getPageNoRole(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.channelList = res.data.records;
          this.page_total1 = res.data.total;
        } else {
          this.channelList = [];
          this.page_total1 = 0;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px;
}
</style>