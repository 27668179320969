<template>
  <!-- 制作预案 -->
  <div class="makePlan">
    <div class="ckxTitle">竞拍信息</div>
    <el-row class="mt20 mb20">
      <el-col :span="4">品牌车型：{{resAuction.brandName}}/{{resAuction.modelName}}</el-col>
      <el-col :span="4">车牌号码：{{resAuction.carNo}}</el-col>
      <el-col :span="4">客户归属：{{resAuction.affRegion}}</el-col>
      <el-col :span="4">成交类型：{{resAuction.tradeTypeName}}</el-col>
      <el-col :span="4">定价时间：{{resAuction.auctionEndTime}}</el-col>
      <el-col :span="4">价格有效截止日：{{resAuction.tradedPriceExpire}}</el-col>
    </el-row>
    <!-- <div class="mt20 mb20">
      <span class="mr20">品牌车型：{{resAuction.brandName}}/{{resAuction.modelName}}</span>
      <span class="mr20">车牌号码：{{resAuction.carNo}}</span>
      <span class="mr20">客户归属：{{resAuction.affRegion}}</span>
      <span class="mr20">成交类型：{{resAuction.tradeTypeName}}</span>
      <span class="mr20">定价时间：{{resAuction.auctionEndTime}}</span>
      <span>价格有效截止日：{{resAuction.tradedPriceExpire}}</span>
    </div> -->
    <div class="ckxTitle">服务记录</div>
    <el-table :data="serviceNoteList" max-height="500" border class="mt10">
        <el-table-column prop="id" label="编号" align="center"></el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="180" align="center"></el-table-column>
        <el-table-column label="标题" width="170" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" width="200" align="center">
          <template slot-scope="scope">
            <div v-html="scope.row.content" @click="triggerClick">{{scope.row.content}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="serviceUserName" label="坐席" align="center"></el-table-column>
        <el-table-column prop="carNo" label="车牌号" width="115" align="center"></el-table-column>
        <el-table-column prop="sessionId" label="录音" align="center">
          <template slot-scope="scope">
            <audio :src="scope.sessionId"></audio>
          </template>
        </el-table-column>
      </el-table>
    <div class="ckxTitle mt20 mb10">价格信息</div>
    <div class="pb100">
      <!-- <span class="mr20">商户名次：{{resAuction.auctionPriceOrder?`第${resAuction.auctionPriceOrder}名`:''}}</span> -->
      <span class="mr20">当前成交价：{{resAuction.tradePrice?`${resAuction.tradePrice}元`:''}}</span>
      <span class="mr20">上次成交价：{{resAuction.lastTradePrice?`${resAuction.lastTradePrice}元`:'-'}}</span>
      <el-popover placement="right" width="350" trigger="click">
        <el-table :data="tradelist" border >
          <el-table-column property="auctionPriceDate" label="竞拍日期" align="center"></el-table-column>
          <el-table-column property="tradePrice" label="成交价格" align="center"></el-table-column>
        </el-table>
        <el-button type="primary" plain slot="reference" >成交历史价</el-button>
      </el-popover>
      <span class="mr20 ml20">二手车估价：<a class="mr20 blueTxt" href="http://www.kx.cn/InternalUse" target="_blank">精真估 </a></span>
      <span class="mr20">新车价：</span>
      <a class="mr20 blueTxt" href="https://www.autohome.com.cn/shanghai/" target="_blank">汽车之家 </a>
      <a class="mr20 blueTxt" href="https://www.yiche.com/" target="_blank">易车</a>
      <div class="mt10 mb10 flex flex_align_center flex_between">
        <div class="ckxTitle">车开新历史车价参考</div>
       <!-- <div class="blueTxt cursor" @click="carModalFlag=true">更多历史车价>></div> -->
      </div>
      <el-table :data="tradelistArr" border>
        <el-table-column property="modelName" label="车型" align="center">
          <template slot-scope="scope">{{scope.row.brandName}}{{scope.row.seriesName}}{{scope.row.modelName}}</template>
        </el-table-column>
        <el-table-column property="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column property="registerDate" label="上牌日期" align="center"></el-table-column>
        <el-table-column property="boardShowKmNums" label="表显里程" align="center"></el-table-column>
        <el-table-column property="tradePrice" label="成交价" align="center"></el-table-column>
        <el-table-column property="auctionDate" label="竞拍日期" align="center"></el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[5,10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

      <div class="mt20 mb10 flex flex_align_center flex_between">
        <div class="ckxTitle">车检报告信息</div>
        <router-link class="blueTxt" :to="{path:'/testReviewer/auditReport',query:{state:3,id:checkReportId}}">查看详细报告>></router-link>
      </div>
      <el-row :gutter="20">
        <el-col :span="10">
          <table>
            <tr><td></td><td>车开新</td><td>车管所</td></tr>
            <tr><td>车辆手续状态</td><td :class="getCar('3111','color')==1?'tipsColor':''" @click="onRow('3111','车辆手续状态')">{{getCar('3111')}}</td><td>{{carManagerInfo.zt}}</td></tr>
            <tr><td>初次登记日期</td><td :class="getCar('3102','color')==1?'tipsColor':''" @click="onRow('3102','初次登记日期')">{{getCar('3102')}}</td><td>{{carManagerInfo.ccdjrq}}</td></tr>
            <tr><td>年检有效期</td><td :class="getCar('3105','color')==1?'tipsColor':''" @click="onRow('3105','年检有效期')">{{getCar('3105')}}</td><td>{{carManagerInfo.yxqz}}</td></tr>
            <tr><td>车辆类型</td><td :class="getCar('3113','color')==1?'tipsColor':''" @click="onRow('3113','车辆类型')">{{getCar('3113')}}</td><td>{{carManagerInfo.cllx}}</td></tr>
            <tr><td>使用性质</td><td :class="getCar('3104','color')==1?'tipsColor':''" @click="onRow('3104','使用性质')">{{getCar('3104')}}</td><td>{{carManagerInfo.syxz}}</td></tr>
            <tr><td>公私性质</td><td :class="getCar('3103','color')==1?'tipsColor':''" @click="onRow('3103','公私性质')">{{getCar('3103')}}</td><td>--</td></tr>
            <tr><td>生产厂家</td><td>--</td><td>{{carManagerInfo.zzcmc}}</td></tr>
            <tr><td>车型系列/车辆品牌</td><td :class="getCar('3303','color')==1?'tipsColor':''" @click="onRow('3303','车型系列/车辆品牌')">{{getCar('3303')}}</td><td>{{carManagerInfo.clpp}}</td></tr>
            <!-- <tr><td>发动机型号/发动机序号</td><td :class="getCar('3322','color')==1?'tipsColor':''" @click="onRow('3322','发动机型号/发动机序号')">{{getCar('3322')}}</td><td>{{carManagerInfo.fdjxh}}</td></tr> -->
            <!-- <tr><td>排量</td><td>--</td><td>{{carManagerInfo.pl}}</td></tr> -->
            <!-- <tr><td>乘坐人数/核定载客</td><td>--</td><td>{{carManagerInfo.hdzk}}</td></tr> -->
            <tr><td>生产年代/出厂日期</td><td :class="getCar('3304','color')==1?'tipsColor':''" @click="onRow('3304','生产年代/出厂日期')">{{getCar('3304')}}</td><td>{{carManagerInfo.ccrq}}</td></tr>
            <!-- <tr><td>车辆型号</td><td :class="getCar('3321','color')==1?'tipsColor':''" @click="onRow('3321','车辆型号')">{{getCar('3321')}}</td><td>{{carManagerInfo.clxh}}</td></tr> -->
            <!-- <tr><td>外观颜色</td><td :class="getCar('3306','color')==1?'tipsColor':''" @click="onRow('3306','外观颜色')">{{getCar('3306')}}</td><td>--</td></tr> -->
            <!-- <tr><td>产证颜色/车身颜色</td><td :class="getCar('3325','color')==1?'tipsColor':''" @click="onRow('3325','产证颜色/车身颜色')">{{getCar('3325')}}</td><td>{{carManagerInfo.csys}}</td></tr> -->
            <tr><td>排放标准/环保达标情况</td><td :class="getCar('3115','color')==1?'tipsColor':''" @click="onRow('3115','排放标准/环保达标情况')">{{getCar('3115')}}</td><td>{{carManagerInfo.hbdbqk}}</td></tr>
            <tr><td>表显里程</td><td :class="getCar('3201','color')==1?'tipsColor':''" @click="onRow('3201','表显里程')">{{getCar('3201')}}</td><td>--</td></tr>
          </table>
        </el-col>
        <el-col :span="14">
          <table>
            <tr>
              <td>详细车型</td><td colspan="3">{{getCar('3305')}}</td>
            </tr>
            <tr>
              <td>标准配置</td><td colspan="3">{{getCar('3307')}}</td>
            </tr>
            <tr>
              <td>车牌所在地</td><td>{{getCar('3109')}}</td>
              <td>私车车主户籍</td><td>{{getCar('3124')}}</td>
            </tr>
            <tr>
              <td>交强险到期日</td><td>{{getCar('3106')}}</td>
              <td>强制报废年限</td><td>{{getCar('3114')}}</td>
            </tr>
            <tr>
              <td>产证变更记录</td><td>{{getCar('3116')}}</td>
              <td>车辆产证现属</td><td>{{getCar('3134')}}</td>
            </tr>
            <tr>
              <td>办证工作日</td><td>{{getCar('3133')}}</td>
              <td>发动机舱清洗</td><td>{{getCar('3135')}}</td>
            </tr>
            <tr>
              <td>车辆牌照</td><td>{{getCar('3118')}}</td>
              <td>产证状态</td><td>{{getCar('3117')}}</td>
            </tr>
            <tr>
              <td>行驶证</td><td>{{getCar('3121')}}</td>
              <td>沪牌额度变更</td><td>{{getCar('3129')}}</td>
            </tr>
            <tr>
              <td>车辆产证曾属</td><td>{{getCar('3139')}}</td>
              <td>车辆购置税</td><td>{{getCar('3137')}}</td>
            </tr>
            <tr>
              <td>是否领取解押材料</td><td>{{getCar('3136')}}</td>
              <td></td><td></td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <div class="mt10 mb10">车况总结：</div>
      <el-input v-model="res.checkResult" type="textarea" :disabled="disabled" class="textareaBox"></el-input>
      <div class="ckxTitle mt10 mb10">预案内容</div>
      <el-input v-model="planContent" type="textarea" :autosize="{ minRows:4}"></el-input>
    </div>
    <div class="footer">
      <div class="btn">
        <!-- <el-button type="primary">保存</el-button> -->
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="carModalFlag">
      <el-table :data="tradelistArr" border>
        <el-table-column property="modelName" label="车型" align="center">
          <template slot-scope="scope">{{scope.row.brandName}}{{scope.row.seriesName}}{{scope.row.modelName}}</template>
        </el-table-column>
        <el-table-column property="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column property="registerDate" label="上牌日期" align="center"></el-table-column>
        <el-table-column property="boardShowKmNums" label="表显里程" align="center"></el-table-column>
        <el-table-column property="tradePrice" label="成交价" align="center"></el-table-column>
        <el-table-column property="auctionDate" label="竞拍日期" align="center"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="carModalFlag = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const car=new Map();//车辆返回值（车开新数据）
export default {
  data() {
    return {
      serviceNoteList:[],//服务记录
      gridData: [
        {date: '20210508', price: '98元'},
        {date: '20210509', price: '102元'},
        {date: '20210510', price: '112元'}
      ],
      planContent: '',
      salePlanId:'',//销售预案Id
      followUpInfoId:'',//业务跟进Id
      checkReportId:'',//检测报告id
      enquiryId:'',//询价ID
      res:{},//检测详情
      carManagerInfo:{},//车管所信息
      resAuction:{},//竞拍详情
      carModalFlag:false,
      disabled:true,
      tradeRes:{},//精真估数据
      tradelist:[],//成交信息列表
      tradelistArr:[],//所有车辆信息列表
      total: 0,
      currentPage: 1,
      pageSize:5,
    }
  },
  created(){
    if(this.$route.query.followUpInfoId)this.followUpInfoId=this.$route.query.followUpInfoId;
    if(this.$route.query.checkReportId)this.checkReportId=this.$route.query.checkReportId;
    if(this.$route.query.enquiryId) this.enquiryId=this.$route.query.enquiryId; this.getCheckReport()//获取竞拍详情
    if(this.$route.query.salePlanId){
      this.salePlanId=this.$route.query.salePlanId;
      this.getDealBookSalePlan()//获取历史销售预案
    }
    this.getAuctionInfo()//获取竞拍信息详情
    this.getCustomerRecordList()
  },
  methods:{
    getCustomerRecordList(){//根据业务跟进ID查询客户概况
      this.$api.getCustomerRecordList(this.followUpInfoId,0).then(res=>{
        if(res.code==0){
          this.serviceNoteList=res.data;
          
        }
      })
    },
    getCheckReport(){//获取检测报告
      this.$api.getCheckReport(this.checkReportId).then(res=>{
        if(res.code==0){
          this.res=res.data;
          this.carManagerInfo=res.data.carManagerInfo?res.data.carManagerInfo:{};
          this.carFun(this.res)
        }
      })
    },
    // getDetailsByCarId(){//竞拍 查询详情
    //   this.$api.getDetailsByCarId(this.enquiryId).then(res=>{
    //     if(res.code==0){
    //       this.resAuction=res.data;
    //     }
    //   })
    // },
    getAuctionInfo(){//查询详情
      this.$api.getAuctionInfo(this.enquiryId).then(res=>{
        if(res.code==0){
          this.resAuction=res.data;
          this.getAuctionList(res.data.customerNo)
          this.getListTradedCarHistory()//查询车辆历史成交信息
        }
      })
    },
    triggerClick(event){
      let id = event.target.getAttribute('data-checkReportId')
      if(id){
        this.$router.push({
          path:'/testReviewer/auditReport',
          query:{
            id:id,
            state:3,
            title:'检测报告'
          }
        })
      }
    },
    onRow(){},
    carFun(res){//itemCode查询
      if(!res){
        return
      }
      let newArr=JSON.parse(JSON.stringify(res.details))
       for(let i=0,len=newArr.length;i<len;i++){
         car.set(newArr[i].itemCode,newArr[i])
       }
    },
    getCar(row,type){
      if(type=='obj'){
        return car.get(row)
      }else if(type=='color'){
        return car.get(row)?car.get(row).color:''
      }else{
        return car.get(row)?car.get(row).checkResult:'--'
      }
    },
    onSubmit(){//提交
      if(this.salePlanId){
        this.putDealBookSalePlan()
      }else{
        this.postAddPlan()
      }
    },
    postAddPlan(){//新增销售预案
      this.$api.postAddPlan(this.followUpInfoId,this.enquiryId,this.planContent).then(res=>{
        if(res.code==0){
          this.$router.push({
            path: '/salesManager/makePlan',
            query:{
              salePlanId:res.data,
              followUpInfoId:this.followUpInfoId,
              checkReportId:this.checkReportId,
              enquiryId:this.enquiryId
            }})
          this.$message.success(res.message)
        }
      })
    },
    putDealBookSalePlan(){//修改销售预案
      this.$api.putDealBookSalePlan(this.salePlanId,this.planContent).then(res=>{
        if(res.code==0){
          this.getDealBookSalePlan()
          this.$message.success(res.message)
        }
      })
    },
    getDealBookSalePlan(){//获取销售预案详情
      this.$api.getDealBookSalePlan(this.salePlanId).then(res=>{
        if(res.code==0){
          this.planContent=res.data.plan
        }
      })
    },
    getAuctionList(customerNo){//根据客户编号查询历史竞拍信息
      this.$api.getAuctionList(customerNo).then(res=>{
        if(res.code==0){
          this.tradelist=res.data;
        }
      })
    },
    getListTradedCarHistory(){//查询车辆历史成交信息
      let query={
        // "brandId": 1,
        "curPage": this.currentPage,
        "brandName": this.resAuction.brandName,
        "pageSize": this.pageSize,
        // "makerId": 1,
        // "makerName": "上汽",
        // "seriesId": 1,
        "seriesName": this.resAuction.seriesName,
        // "styleId": 1,
        "styleName": this.resAuction.styleName,
        // "modelId": 1,
        "modelName": this.resAuction.modelName
      }
      this.$api.getListTradedCarHistory(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.tradelistArr=res.data.records;
          this.total=res.data.total;
        }
      })
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.getListTradedCarHistory(this.res.carNo)
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.getListTradedCarHistory(this.res.carNo)
    },
  },
}
</script>

<style>
.textareaBox .el-textarea__inner{
  height:180px;
}
</style>
<style lang="scss" scoped>
.makePlan{
  padding: 20px 40px 110px;
  .blueTxt{color: #0099FF;}
  table{
    border-collapse: collapse;
    min-width: 360px;
    width: 100%;
    td{
      border: 1px solid #ccc;
      padding: 15px 20px;
      text-align: center;
    }
    .blue{color: #0099FF;}
    .red{color: #FF0000;}
  }
  
}
</style>