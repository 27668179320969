<template>
  <div class="testAllocation">
    <!--检测调度-->
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)" class="radioButton">
      <el-tab-pane label="待分配" name="first">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>客户编号</span>
            <el-input v-model="params.customerNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>品牌车型</span>
            <el-input v-model="params.carType" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" size="medium" value-format="yyyy-MM-dd"> </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="mt20 table-mainbg">
          <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="carType" label="品牌车型" align="center"></el-table-column>
          <el-table-column prop="isMortgage" label="是否抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isMortgage==1?'是':scope.row.isMortgage==0?'否':scope.row.isMortgage==-1?'未识别':scope.row.isMortgage}}</template>
          </el-table-column>
          <el-table-column prop="bookTime" label="预约时间" align="center"></el-table-column>
          <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
          <el-table-column prop="bookCount" label="预约次数" align="center"></el-table-column>
          <el-table-column prop="auctionTime" label="上次竞拍日期" align="center"></el-table-column>
          <el-table-column label="操作" width="160" align="center" >
            <template slot-scope="scope">
              <el-button type="primary" @click="onRouter(scope.row)" size="mini" v-if="role==1">载入</el-button>
              <el-button type="primary" @click="onModel(scope.row)" size="mini" v-if="role==1||role==2">分配</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
        class="mt30"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next,sizes, jumper"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page_size"
        :current-page="page_index"
        :total="page_total"
      ></el-pagination>

      </el-tab-pane>
      <el-tab-pane label="已分配" name="second">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>客户编号</span>
            <el-input v-model="params1.customerNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>品牌车型</span>
            <el-input v-model="params1.carType" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w4">
            <span>预约时间</span>
            <el-date-picker v-model="params1.date" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" size="medium" value-format="yyyy-MM-dd"> </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>检测状态</span>
            <el-select v-model="params1.checkStatus" size="medium" clearable>
              <el-option label="待调度" value="0"></el-option>
              <el-option label="待检测" value="1"></el-option>
              <el-option label="检测中" value="2"></el-option>
              <el-option label="检测完成" value="3"></el-option>
              <el-option label="检测失败" value="4"></el-option>
            </el-select>
          </el-col>
          <el-col :span="19" style="text-align:right;">
            <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="mt20 table-mainbg">
          <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="carType" label="品牌车型" align="center"></el-table-column>
          <el-table-column prop="isMortgage" label="是否抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isMortgage==1?'是':scope.row.isMortgage==0?'否':scope.row.isMortgage==-1?'未识别':scope.row.isMortgage}}</template>
          </el-table-column>
          <el-table-column prop="bookTime" label="预约时间" align="center"></el-table-column>
          <el-table-column prop="assignCheckerTime" label="分配时间" align="center"></el-table-column>
          <el-table-column prop="bookCount" label="预约次数" align="center"></el-table-column>
          <el-table-column prop="checkStatusName" label="检测状态" align="center"></el-table-column>
          <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
          <el-table-column prop="assignTradeUserName" label="成交归属" align="center"></el-table-column>
          <el-table-column label="操作" align="center" v-if="role==1">
            <template slot-scope="scope">
              <el-button type="primary" @click="onRouter(scope.row)" size="mini">载入</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
        class="mt30"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next,sizes, jumper"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page_size1"
        :current-page="page_index1"
        :total="page_total1"
      ></el-pagination>

      </el-tab-pane>
    </el-tabs>
    <el-dialog :visible.sync="distributionDialog" title="检测师分配" center>
      <div class="flex flex_around flex_align_center">
        <span>客户姓名：{{itemRow.customerName}}</span>
        <span>品牌车型：{{itemRow.carType}}</span>
        <span>车牌号码：{{itemRow.carNo}}</span>
      </div>
      <!-- <div class="mt20 mr10">请选择检测师:</div>
      <div class="flex flex_wrap" >
        <el-radio-group v-model="inspector" size="medium">
          <el-radio v-for="item in rolelist" :key="item.id" :label="item.id" class="mt10" >{{item.name}}</el-radio>
        </el-radio-group>
      </div> -->
      <el-row :gutter="24" class="mb20 mt20">
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>检测师姓名</span>
          <el-input v-model="form.userName" size="medium"></el-input>
        </el-col>
        <el-col :span="10" class="flex flex_align_center flex_between w4">
          <span>检测师账号</span>
          <el-input v-model="form.userNo" size="medium"></el-input>
        </el-col>
        <el-col :span="2" class="flex flex_align_center flex_between ">
          <el-button type="primary" @click="getUserRole" size="medium" plain>查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="rolelist" border max-height="300">
        <el-table-column prop="name" label="检测师姓名" align="center"></el-table-column>
        <el-table-column prop="no" label="检测师账号" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="onSubmit(scope.row)"
              size="mini"
            >完成分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        customerNo: '',
        carType: '',
        carNo: '',
        date: '',
      },
      params1:{
        date:'',
        customerNo: '',
        carType: '',
        carNo: '',
      },
      querylist: [],
      querylist1: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      activeName: 'first',
      distributionDialog: false,
      inspector: '',
      role:1,//1帮卖经理，2外勤调度
      itemRow:{},
      rolelist:[],//检测师列表
      form:{
        userName:'',
        userNo:''
      },
    }
  },
  created(){
    let user=this.$store.state.user.userData;
    if(user){
      for(let i=0;i<user.roleList.length;i++){
        let item=user.roleList[i]
        if(item.isCurrent==1){
          if(item.name=='外勤调度'){
            this.role=2
          }else{
            this.role=1;
          }
          break;
        }
      }
    }
    // let a=this.$$.GetDateStr(0)
    // let b=this.$$.GetDateStr(1)
    // this.params.date=[a,b]
    // this.params1.date=[a,b]
    this.init()
    // this.queryUserListByRoleAndDepart()//获取检测师

  },
  methods: {
    init(page){
      // console.log(this.params.date,'===params.date===',this.params1.date)
      if(this.activeName=='first'){
        this.postQueryNoAssign(page)
      }else{
        this.postQueryNoAssigned(page)
      }
    },
    postQueryNoAssign(page){//待分配检测师列表
      this.page_index=page?page:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        customerNo:this.params.customerNo,
        carNo:this.params.carNo,
        carType:this.params.carType,
        bookStartTime:this.params.date?`${this.params.date[0]} 00:00:00`:'',
        bookEndTime:this.params.date?`${this.params.date[1]} 23:59:59`:'',
        isShop:this.role==2?0:1,//是否门店检测 0：外勤；1：门店
        isFromWorkbench:0,//查询是否来自工作台, 工作台的路由传递 1, 其他传递0
      }
      if(this.$route.params.state){
        query.isFromWorkbench=1;
      }
      this.$api.postQueryNoAssign(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.page_total = res.data.total;
          }else{
            this.querylist = []
            this.page_total=0;
          }
        }else{
          this.querylist = []
          this.page_total=0;
        }
      });
    },
    postQueryNoAssigned(page){//已分配检测师列表
      this.page_index1=page?page:this.page_index1;
      let query={
        curPage:this.page_index1,
        pageSize:this.page_size1,
        customerNo:this.params1.customerNo,
        carNo:this.params1.carNo,
        carType:this.params1.carType,
        bookStartTime:this.params1.date?`${this.params1.date[0]} 00:00:00`:'',
        bookEndTime:this.params1.date?`${this.params1.date[1]} 23:59:59`:'',
        checkStatus:this.params1.checkStatus,
        isShop:this.role==2?0:1,//是否门店检测 0：外勤；1：门店
      }
      this.$api.postQueryNoAssigned(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist1 = res.data.records;
            this.page_total1 = res.data.total;
          }else{
            this.querylist1 = []
            this.page_total1=0;
          }
        }else{
          this.querylist1 = []
          this.page_total1=0;
        }
      });
    },
    handleSizeChange(row){
      if(this.activeName=='first'){
        this.page_size=row;
        this.page_index=1;
      }else{
        this.page_size1=row;
        this.page_index1=1;
      }
      this.init()
    },
    handleCurrentChange(row){
      if(this.activeName=='first'){
        this.page_index=row;
      }else{
        this.page_index1=row;
      }
      this.init()
    },
    onModel(row){
      this.itemRow=row;
      this.form.userName='';
      this.form.userNo='';
      this.getUserRole()//查询检测师
      this.distributionDialog=true
    },
    getUserRole(){//获取检测师
      let query={
        roleName:'检测师',
        userName:this.form.userName,
        loginName:this.form.userNo
      }
      this.$api.queryUserListByRoleAndRoleLevelAndDepartAndUser(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.rolelist=res.data;
        }
      })
    },
    onSubmit(row){//给指定检测师分配检测任务
      this.$api.postAssignChecker(this.itemRow.id,row.id).then(res=>{
        if(res.code==0){
          this.init(1)
          this.distributionDialog = false;
          this.$message.success(res.message)
        }
      })
    },
    onRouter(row){
      this.$router.push({
        path: '/salesManager/customer', 
        query: {
          followUpInfoId: row.followUpId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.testAllocation{
  padding: 20px 40px;
}
</style>