<template>
  <!-- 差异评定 -->
  <div class="difEvaluation">
    <el-tabs v-model="activeName"  type="card"  @tab-click="handleClick">
      <el-tab-pane label="待评定" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="已评定" name="second" class="mt20"></el-tab-pane>
      <el-table :data="tableData" border stripe class="mt20 table-mainbg" >
        <el-table-column prop="merchantName" label="商户姓名" align="center"></el-table-column>
        <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
        <el-table-column :formatter="formatCarType" label="品牌车型" align="center"></el-table-column>
        <el-table-column prop="validFinishTime" label="复检完成时间" align="center"></el-table-column>
        <!-- <el-table-column prop="sellerValidFinishTime" label="验车完成时间" align="center"></el-table-column> -->
        <el-table-column prop="recheckTypeName" label="差异类型" align="center"></el-table-column>
        <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
        <el-table-column prop="evaluateUserName" label="差异评定人" align="center" v-if="['second'].includes(activeName)"></el-table-column>
        <el-table-column prop="evaluateTime" label="差异评定时间" align="center" v-if="['second'].includes(activeName)"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="activeName==='first'" size="mini" type="primary" @click="onRouter(scope.row)">差异评定</el-button>
            <el-button v-if="activeName==='second'" size="mini" type="primary" @click="onRouter(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      evaluateStatuses: [91]
    }
  },
  created() {
    this.handleClick();
  },
  methods: {
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    formatCarType(row) {
      return row.brandName+ '/' + row.modelName;
    },
    initData() {
      let user=this.$store.state.user.userData;
      let role=this.$$.roleName(user,'department')
      let tradeShopId='';
      if(role){
        tradeShopId=role.shop?role.shop.id:'';
      }
      
      let params = {};
      if(this.activeName === 'first') {
        params.evaluateStatus = 91
      }else if(this.activeName === 'second') {
        params.evaluateStatus = 92
      }
      params.curPage = this.page_index;
      params.pageSize = this.page_size;
      params.recheckType=0;
      params.tradeShopId=tradeShopId;
      this.$api.postValidEvaluatePage(this.$$.delete_null_properties(params)).then(res => {
        if(res.code === 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
        }else{
          this.tableData=[]
          this.page_total=0;
        }
      })
    },
    onRouter(row){
      this.$router.push({
        path: '/storeManager/difEvaluationDetail', 
        query: {
          carValidId:row.carValidId,
          state:1//默认没有值为查看，1差异评定，2差异确认，3差异复检
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.difEvaluation{
  padding: 20px 40px;
}
</style>