<template>
  <div class="pageContainer">
    <el-table :data="querylist" border stripe class="table-mainbg">
      <el-table-column prop="auctionPriceAuditTypeName" label="审核类型" align="center"></el-table-column>
      <el-table-column prop="affRegion" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="enquiryTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="firstAuctionPrice" label="最高报价" align="center"></el-table-column>
      <el-table-column prop="secondAuctionPrice" label="次高报价" align="center"></el-table-column>
      <el-table-column prop="breakAppointCounts" label="违约数" align="center"></el-table-column>
      <el-table-column prop="auctionPriceConfirmStatusName" label="确认状态" align="center"></el-table-column>
      <el-table-column prop="auctionPriceSendStatusName" label="报价发送状态" align="center"></el-table-column>
      <el-table-column prop="tradeMerchantName" label="商户名称" align="center"></el-table-column>
      <el-table-column prop="validPriceCount" label="有效报价人数" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onrouter(scope.row)"
            size="mini"
            class="group-custom"
          >{{scope.row.auctionPriceAuditOperTypeName}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:20,
      timer:null,
    };
  },
  created() {
    this.init()
  },
  mounted(){
    if(!this.timer){
      this.timer=setInterval(()=>{
        this.init()
      },1000*30)
    }
  },
  methods: {
    init() {
      let query={
        curPage:this.currentPage,
        pageSize:this.pageSize,
        // auctionPriceConfirmStatus:[301,302,303]//竞拍价格确认状态：301待确认，302已确认，303已放弃
        // carAuctionStatus:205,//车辆竞拍状态：201待竞拍，202竞拍中，203暂时不拍，205竞拍终止，205竞拍结束
      }
      this.$api.postCarConfirmPage(this.$$.delete_null_properties(query)).then(res=>{
        if (res.code == 0) {
          this.querylist = res.data.records;
          this.total = res.data.total;
        }else{
          this.querylist = []
          this.total=0;
        }
      })
    },
    onrouter(row){
      this.$router.push({
        path:'/warehouseKeeper/priceDetails',
        query:{
          carAuctionId:row.id
        }
      })
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
  },
  beforeDestroy(){
    clearInterval(this.timer)
    this.timer=null;
  },
};
</script>

<style lang="scss" scoped>
</style>
