<template>
  <!-- 发货管理 --> 
  <div class="deliverGoods">
    <el-tabs v-model="activeName"  type="card" @tab-click="handleClick">
      <el-tab-pane label="待发货" name="first">
        <div style="text-align:right;" class="mb10">
          <el-button @click="onRouter(1)" size="mini" type="primary" plain>手续材料发货</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="物流中" name="second" class="mt20"></el-tab-pane>
      <el-tab-pane label="已接收" name="third" class="mt20"></el-tab-pane>
      <el-tab-pane label="异常货物" name="fourth" class="mt20"></el-tab-pane>
    </el-tabs>

    <el-table :data="tableData" border class="table-mainbg" stripe>
      <!-- <el-table-column align="center" prop="customerContractNo" label="客户合同编号" v-if="activeName==='first'" key="1"></el-table-column>
      <el-table-column align="center" prop="carNo" label="车牌号码" v-if="activeName==='first'" key="2"></el-table-column>
      <el-table-column align="center" :formatter="formatBrand" label="品牌车型" v-if="activeName==='first'" key="3"></el-table-column>
      <el-table-column align="center" prop="tradeShopName" label="成交门店" v-if="activeName==='first'" key="4"></el-table-column>
      <el-table-column align="center" prop="inStorageTime" label="入库时间" v-if="activeName==='first'" key="5"></el-table-column> -->
      <el-table-column align="center" prop="fromLogistNo" label="发货方编号" key="1"></el-table-column>
      <el-table-column align="center" prop="toLogistNo" label="收货方编号" key="2"></el-table-column>
      <el-table-column align="center" prop="carNo" label="车牌号码" v-if="activeName==='first'" key="3"></el-table-column>
      <el-table-column align="center" prop="fromObjName" label="发货方" v-if="activeName==='first'" key="4"></el-table-column>
      <el-table-column align="center" prop="toObjName" label="收货方" v-if="activeName==='first'" key="5"></el-table-column>
      
      <el-table-column align="center" prop="logistTypeName" label="发货方式" v-if="['second','third','fourth'].includes(activeName)" key="7"></el-table-column>
      <el-table-column align="center" prop="fromShopName" label="发货方" v-if="['second','third','fourth'].includes(activeName)" key="8"></el-table-column>
      <el-table-column align="center" prop="fromName" label="发货人" v-if="['second','third','fourth'].includes(activeName)" key="9"></el-table-column>
      <el-table-column align="center" prop="fromTime" label="发货时间" width="180px" v-if="['second','third','fourth'].includes(activeName)" key="10"></el-table-column>
      <el-table-column align="center" prop="preArriveTime" label="预计到达时间" width="180px" v-if="activeName==='second'" key="11" :formatter="formatterTime">
        <!-- <template slot-scope="scope">
          <span :class="{'red': scope.row.preArriveTime && Date.now() > new Date(scope.row.preArriveTime.replaceAll('-','/')).getTime()}">{{scope.row.preArriveTime}}</span>
        </template> -->
      </el-table-column>
      <el-table-column align="center" prop="toShopName" label="收货方" v-if="['second','third','fourth'].includes(activeName)" key="12"></el-table-column>
      <el-table-column align="center" prop="toReceiveTime" label="接收时间" width="180px" v-if="['third','fourth'].includes(activeName)" key="13"></el-table-column>
      <el-table-column align="center" prop="toName" label="接收人" v-if="['third','fourth'].includes(activeName)" key="14"></el-table-column>
      <el-table-column prop="name" label="操作" align="center" :width="activeName==='second'?'220px':'150px'" key="15">
        <template slot-scope="scope">
          <el-button size="mini" plain type="primary" v-if="activeName==='first'" @click="onRouter(2,scope.row)">填写发货单</el-button>
          <el-button size="mini" plain type="primary" v-if="['second','third'].includes(activeName)" @click="onRouter(3,scope.row)">查看</el-button>
          <!-- <el-button size="mini" plain type="primary" v-if="activeName==='second' && scope.row.preArriveTime && Date.now() > new Date(scope.row.preArriveTime.replaceAll('-','/')).getTime()" @click="warning(scope.row.id)">处置预警</el-button> -->
          <el-button size="mini" plain type="primary" v-if="activeName==='second' && scope.row.preArriveTime && Date.now() > new Date(scope.row.preArriveTime.replace(new RegExp('-','gm'),'/')).getTime()" @click="warning(scope.row.id)">处置预警</el-button>
          <el-button size="mini" plain type="primary" v-if="['fourth'].includes(activeName)" @click="onRouter(4,scope.row)">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <el-dialog title="处置预警" :visible.sync="dialogVisible" center>
      <el-form :model="form" label-width="150px">
        <el-form-item label="预计到达时间：" prop="preArriveTime">
          <el-date-picker v-model="form.preArriveTime" type="datetime" placeholder="选择日期时间" align="right" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input style="width:300px" type="textarea" v-model="form.remark" :autosize="{minRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitWarning">确 定</el-button>
      </div>
    </el-dialog>

    <!-- <div class="msgBox">
      <div class="msgRow" v-for="(item,index) in timeoutlist" :key="item.id">
        <div class="msgHead">
          <p>提示</p>
          <i class="el-icon-close cursor" @click="closeImg(index)"></i>
        </div>
        <div class="mt10">发货单{{item.logistNo}}超时，请及时<span class="blue cursor" @click="onRouter(3,item)">处理</span></div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      dialogVisible: false,
      form: {
        preArriveTime: '',
        remark: ''
      },
      rules: {
        preArriveTime: [{ required: true, message: '请选择时间', trigger: 'change' }],
        remark: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      curLogistId: null,
      roleItem:{},
      timeoutlist:[],
    }
  },
  created() {
    if(this.$route.query.state) this.activeName=this.$route.query.state;
    let user=this.$store.state.user.userData;
    this.roleItem=this.$$.roleName(user,'department')
    this.handleClick();
  },
  methods: {
    closeImg(index){
      this.timeoutlist.splice(index,1)
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    initData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        // fromShopId:this.roleItem?this.roleItem.shop?this.roleItem.shop.id:'':''
      }
      
      switch(this.activeName){
        case 'first':
          params.logistStatus = [11];
          break;
        case 'second':
          params.logistStatus = [12];
          break;
        case 'third':
          params.logistStatus = [14];
          break;
        case 'fourth':
          params.logistStatus = [15];
          break;
      }//11待发货, 12在途, 13待收货，14已接收，15异常
      this.$api.logisticsList(this.$$.delete_null_properties(params)).then(res => {
        if(res.code === 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
          let arr=[];
          if(this.activeName==='second'){
            for(let i=0;i<this.tableData.length;i++){
              let item=this.tableData[i]
              if(item.preArriveTime){
                let time = new Date(item.preArriveTime.replace(new RegExp('-',"gm"),'/')).getTime() 
                if(Date.now()>time){
                  arr.push(item)
                }
              }
            }
          }
          this.timeoutlist=arr;
        }
      })
    },
    formatBrand(row) {
      return row.brandName + '-' + row.modelName
    },
    formatterTime(row){
      if(row.preArriveTime){
        let time = new Date(row.preArriveTime.replace(new RegExp('-',"gm"),'/')).getTime() 
        if(Date.now()>time){
          return <span class="red">{row.preArriveTime}</span>
        }
      }
      
      return row.preArriveTime
    },
    onRouter(state,row) {
      // $router.push({path: '/salesManager/deliverGoods/deliveryNote'})
      let query={};
      let path= '/salesManager/deliverGoods/carDeliveryNote';
      switch(state){
        case 1://新增
          query.title='手续材料发货单';
          query.state=1;
          break;
        case 2://修改
          query.title='车辆&材料发货单';
          query.state=2;
          break;
        case 3:
          query.title='查看发货单';
          // path= '/salesManager/deliverGoods/getDeliverGoods'
          break;
        case 4:
          path= '/salesManager/deliverGoods/handleExceptions'
          break;
      }
      if(row){
        query.id=row.id;
        // query.carId=row.carId;
      }
      this.$router.push({
        path:path, 
        query
      })
    },
    warning(id) {
      this.curLogistId = id;
      this.dialogVisible = true;
    },
    submitWarning() {
      this.$api.logisticsAlarmHandling(this.curLogistId,this.form).then(res => {
        if(res.code === 0){
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.initData()
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.deliverGoods{
  padding: 20px 40px;
  .msgBox{
    position: fixed;
    top:90px;
    right:20px;
    max-height:90vh;
    overflow:auto;
    padding:5px;
    z-index: 1;
    .msgRow{
      background:#ffffff;
      border: 1px solid #ebeef5;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s;
      border-radius:10px;
      padding:15px;
      margin-bottom: 20px;
      .msgHead{
        display:flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
