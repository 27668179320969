<template>
  <div class="conterPage">
    <div class="ckxTitle mb15">车辆信息:</div>
    <el-row :gutter="20">
      <el-col :span="5">车辆号牌：{{res.carNo}}</el-col>
      <el-col :span="5">委托出售价：{{res.carTradedPrice?res.carTradedPrice:'-'}}元</el-col>
      <el-col :span="5" v-if="[103,104,105,106].includes(res.financeBadDebtStatus)">意向金签订时间：{{res.dealSignTime}}</el-col>
      <el-col :span="5" v-if="[103,104,105,106].includes(res.financeBadDebtStatus)">有效期截止时间：{{res.expiryDate}}</el-col>
      <el-col :span="5" v-if="[101,102].includes(res.financeBadDebtStatus)">状态：{{res.financeBadDebtStatusName}}</el-col>
    </el-row>
    <template v-if="[101,102].includes(res.financeBadDebtStatus)">
      <div class="ckxTitle mt15 mb10">收款账号信息</div>
      <el-row :gutter="20">
        <el-col :span="5">开户名：{{res.accountName}}</el-col>
        <el-col :span="5">开户行：{{res.bankName}}</el-col>
        <el-col :span="5">账号：{{res.bankAccount}}</el-col>
      </el-row>
      <div class="ckxTitle mt10 mb10">意向金</div>
      <el-row :gutter="20">
        <el-col :span="5">车开新付意向金<span class="noline">{{res.intentionMoney}}元</span></el-col>
        <el-col :span="5">付款方式：{{res.financePayTypeName}}</el-col>
      </el-row>
    </template>
    <template v-if="[103,104,105,106].includes(res.financeBadDebtStatus)">
      <div class="ckxTitle mt30">车主信息:</div>
      <div class="mt15 mb15">姓名：{{res.customerName}}</div>
      <div class="ckxTitle mt15">交易失败原因</div>
      <div class="mb15 mt15">{{res.remark?res.remark:'-'}}</div>
    </template>
    <template v-if="[101,102].includes(res.financeBadDebtStatus)">
      <div class="ckxTitle mt15">赔偿原因</div>
      <div class="mb15 mt15">{{res.remark?res.remark:'-'}}</div>
    </template>
    <template v-else>
      <div class="ckxTitle mt15">坏账原因</div>
      <div class="mb15 mt15">{{res.badDebtReason?res.badDebtReason:'-'}}</div>
    </template>
    <div class="pb100"  v-if="[103,104,105,106].includes(res.financeBadDebtStatus)">
      <div class="ckxTitle">坏账明细</div>
      <el-table :data="querylist" border stripe class="table-mainbg mt20">
        <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="payTypeName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="intentionMoney" label="金额（元）" align="center" ></el-table-column>
        <el-table-column prop="financeGatheringStatusName" label="状态" align="center"></el-table-column>
        <el-table-column prop="financeBadDebtTime" label="坏账处理时间" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" v-if="[101,103,105].includes(res.financeBadDebtStatus)" >标记为已处理</el-button>
        <el-button type="primary" @click="onReturn" >返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state:'',//值为1表示查看页面
      querylist: [],//报价列表
      payId:'',//列表传过来的id
      res:{},//当前页面的详情
    }
  },
  created(){
    if(this.$route.query.id) this.payId = Number(this.$route.query.id);
    if(this.$route.query.state) this.state = this.$route.query.state
    this.getDetailsByPayId(this.payId)
  },
  methods: {
    getDetailsByPayId(id){//根据商户成交预约Id获取商户预约详情
      this.$api.getDetailsByPayId(id).then(res=>{
        if(res.code==0){
          this.res=res.data;
          this.querylist=[{
            payTypeName:res.data.payTypeName,
            intentionMoney:res.data.intentionMoney,
            financeBadDebtTime:res.data.financeBadDebtTime,
            financeGatheringStatusName:res.data.financeGatheringStatusName,
          }]
        }
      })
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/intentionBadDebt',
        // query:{
        //   state:this.stateType
        // }
      })
    },
    onSubmit(){
      this.postFinanceConfirmBadDebt(this.payId)
    },
    postFinanceConfirmBadDebt(payIds){//财务坏账处理：财务确认赔偿客户违约金,财务确认客户返还意向金坏账,财务确认客户违约赔偿坏账
      let query={
        payIds:payIds
      }
      this.$api.postFinanceConfirmBadDebt(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getDetailsByPayId(this.payId)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.conterPage{
  padding: 20px 40px 100px;
  // .label{width: 120px; text-align: right;display: inline-block;}
  // .merchantInfo>div:nth-child(1){width: 45%;}
  // .merchantInfo>div:nth-child(2){width: 55%;}
  // .merchantInfo>div:nth-child(2) .label{width: 140px;}
  // .mt30 {margin-top: 30px;}
  .noline{
    min-width:100px;
    border-bottom:1px solid #999999;
    display:inline-block;
    margin-left:5px;
  }
}
</style>