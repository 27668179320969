<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="待申请" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="已申请" name="second" class="mt20"></el-tab-pane>
    </el-tabs>

    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户姓名:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="10" class="flex flex_align_center flex_between w5" >
        <span>办证截止日期:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border stripe class="mt20 table-mainbg" :cell-class-name="cellName">
      <el-table-column prop="merchantNo" align="center" label="商户ID">
        <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantNo:''}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" align="center" label="商户姓名"></el-table-column>
      <el-table-column prop="merchantMobile" align="center" label="商户手机号">
        <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantMobile:''}}</template>
      </el-table-column>
      <el-table-column prop="carNo" align="center" label="车牌号码"></el-table-column>
      <el-table-column prop="modelName" align="center" label="品牌车型">
         <template slot-scope="scope">{{ scope.row.brandName }}&nbsp;{{ scope.row.modelName }}</template>
      </el-table-column>
      <el-table-column prop="merchantDeadline" align="center" label="办证截止日期"></el-table-column>
      <el-table-column prop="isUrgent" align="center" label="是否加急"></el-table-column>
      <el-table-column label="操作" align="center" width="120px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain v-if="activeName=='first'" @click="$router.push({path: '/certificationCompany/certificateApplicationDetail', query: {id: scope.row.id}})">申请办证</el-button>
          <el-button size="mini" type="primary" plain v-if="activeName=='second'" @click="$router.push({path: '/certificationCompany/certificateApplicationDetail', query: {id: scope.row.id,state:1}})">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        customerName: '',
        carNo: '',
        date: '',
      },
      querylist: [],
      currentPage: 1, // 页数
      total: 0, // 总页数
      pageSize: 10,
      itemRow:{},
    }
  },
  created() {
    this.init();
  },
  methods: {
    cellName({row}){
      if(row.isUrgent=='加急'){
        return 'tipsColor'
      }
    },
    handleSizeChange(row){
      this.pageSize=row;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(row){
      this.currentPage=row;
      this.init()
    },
    init(page) {
      let taskStatus = 101; //待申请
      if (this.activeName == "second") {
        taskStatus = 102; //待申请
      } else {
        taskStatus = 101; //已申请
      }
      this.currentPage=page?page:this.currentPage;

      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        taskType: 1,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        merchantName:this.params.merchantName,
        taskComApplyStatuses:[taskStatus],
        carNo:this.params.carNo,
        merchantDeadlineBegin:this.params.date?`${this.params.date[0]}`:'',
        merchantDeadlineEnd:this.params.date?`${this.params.date[1]}`:'',
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleClick() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.init() ;
    },
  },
}
</script>

<style scoped lang="scss">
.pageContainer{
  padding: 20px 40px;
}
</style>