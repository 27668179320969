<template>
  <div>
    <div class="printTop">
      <el-button type="primary" @click="postContractPrint" v-print="'#printMe'">打印合同</el-button>
      <el-button type="primary" plain @click="onReturn">关闭</el-button>
    </div>
    <PrintCarSales  id="printMe" :res="res" ></PrintCarSales>
  </div>
</template>
<script>
import print from 'vue-print-nb'
import PrintCarSales from '@/components/print/carSales'
export default {
  directives: {
    print   
  },
  components:{PrintCarSales},
  data(){
    return{
      contractId:'',
      printModel:false,//打印模版
      res:{},
    }
  },
  created(){
    if(this.$route.query.id){
      this.contractId=this.$route.query.id;
    }
    
    this.getCustomerDetail()
  },
  methods:{
    getCustomerDetail(){//获取详情
      this.$api.getCustomerDetail(this.contractId).then((res)=>{
        if(res.code==0){
          this.res=res.data
        }
      })
    },
    onReturn(){
      this.bus.$emit("closeSelected");
    },
    postContractPrint(){//根据 合同id 设置合同为已打印状态
      if(this.$route.query.print){//防止给其他人发链接误掉接口
        this.$api.postContractPrint(this.contractId).then(()=>{})
      }
      
    },
  }
}
</script>

<style lang="scss" scoped>
.printTop{
  display:flex;
  justify-content: center;
  margin-top:30px;
  // text-align: center;
  // position: absolute;
  // top:30px;
  // left:50%;
  // transform: translate(-50%,-50%);
}

</style>