<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="pageRowBox">
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户姓名</span>
        <el-input v-model="form.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户账号</span>
        <el-input v-model="form.merchantNo" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>车牌号</span>
        <el-input v-model="form.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>违约状态</span>
        <el-select v-model="form.appointStatus" placeholder="请选择违约类型" size="medium" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option v-for="item in breakState" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>违约日期</span>
        <el-date-picker
          v-model="form.date"
          type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="-"
          start-placeholder="开始日期" 
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>支付日期</span>
        <el-date-picker
          v-model="form.date1"
          type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="-"
          start-placeholder="开始日期" 
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"></el-date-picker>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10">
        <el-button type="primary" @click="onsearch" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt10 table-mainbg">
      <el-table-column prop="merchantNo" label="商户账号" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center"></el-table-column>
      <el-table-column prop="breakTypeName" label="违约类型" align="center"></el-table-column>
      <el-table-column prop="breakAmount" label="违约金额" align="center"></el-table-column>
      <el-table-column prop="carType" label="生产厂商车系" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号" align="center">
      </el-table-column>
      <el-table-column prop="breakOpenTime" label="违约时间" align="center">
      </el-table-column>
      <el-table-column prop="appointStatusName" label="违约状态" align="center"></el-table-column>
      <el-table-column prop="breakHandlerTime" label="支付时间" align="center"></el-table-column>
      <el-table-column label="操作" width="120" align="center" >
        <template slot-scope="scope">
          <el-button
            type="primary" v-if="scope.row.appointStatus===22"
            @click="onexamine(scope.row)"
            size="mini"
          >处理申诉</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog
    title="商户申诉处理"
    :visible.sync="dialogVisible"
    width="500">
      <el-row>
        <el-col :span="12">商户账号：{{itemRow.merchantNo}}</el-col>
        <el-col :span="12">商户姓名：{{itemRow.merchantName}}</el-col>
        <el-col :span="12" class="mt10">车牌号：{{itemRow.carNo}}</el-col>
        <el-col :span="12" class="mt10">生产厂家车系：{{itemRow.carType}}</el-col>
        <el-col :span="12" class="mt10">违约类型：{{itemRow.breakTypeName}}</el-col>
        <el-col :span="12" class="mt10">违约金额：{{itemRow.breakAmount}}元</el-col>
        <el-col :span="24" class="mt10">违约时间：{{itemRow.breakOpenTime}}</el-col>
      </el-row>
      <p class="fontBold mt10">申诉理由：</p>
      <p>{{itemRow.appealContent?itemRow.appealContent:'-'}}</p>
      <p class="mt10 fontBold">驳回理由：</p>
      <el-input type="textarea" v-model="itemRow.appealRejectReason" class="textareaConter"></el-input>
    <!-- <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="活动名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form> -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit(4)">通过</el-button>
      <el-button type="primary" @click="onSubmit(5)">驳回</el-button>
      <el-button @click="dialogVisible = false">关闭</el-button>
    </span>
  </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible:false,
      form:{
        appointStatus:'',
        date:'',
        carNo:'',
        merchantNo:'',
        merchantName:'',
      },
      itemRow:{
        appealRejectReason:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      userlist:[],//
      breakState:[{id:21,name:'待确认'},{id:22,name:'已申诉'},{id:24,name:'待付款'},{id:23,name:'申诉成功'},{id:25,name:'已付款'}],//违约状态
    };
  },
  created() {
    if(this.$route.params.state) this.form.appointStatus=this.$route.params.state
    this.init();
    // this.queryUserListByRoleAndDepart()//拓展专员
  },
  methods: {
    init() {
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        merchantNo: this.form.merchantNo, 
        carNo: this.form.carNo, //违约车牌号码
        merchantName: this.form.merchantName, //
        appointStatuses:this.form.appointStatus?[this.form.appointStatus]:'',
        breakOpenTimeBegin:this.form.date?this.form.date[0]:'',
        breakOpenTimeEnd:this.form.date?this.form.date[1]:'',
        breakHandlerTimeBegin:this.form.date1?this.form.date1[0]:'',
        breakHandlerTimeEnd:this.form.date1?this.form.date1[1]:'',
      };
      this.$api.postBreakAppointPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onSubmit(operaType){
      if(!this.itemRow.appealRejectReason&&operaType===5){
        this.$message.warning('请填写驳回原因！')
        return
      }
      let query={
        breakAppointId:this.itemRow.id,
        operaType:operaType,
        appealRejectReason:this.itemRow.appealRejectReason,
      }
      this.$api.getBreakAppointAppeal(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code===0){
          this.dialogVisible=false;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    onexamine(row){
      this.dialogVisible=true;
      this.itemRow=JSON.parse(JSON.stringify(row))
      // this.$router.push({
      //   path:'/channel/compensateDetails',
      //   query:{
      //     id:row.id
      //   }
      // })
    },
    onsearch() {
      this.currentPage=1;
      this.init();
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    // queryUserListByRoleAndDepart(){//获取审核状态
    //   this.$api.queryUserListByRoleAndDepart({roleName:'拓展专员'}).then((res)=>{
    //     if(res){
    //       if(res.code==0){
    //         this.userlist=res.data;
    //       }
    //     }
    //   })
    // }
  }
};
</script>
<style lang="scss" scoped>
.fontBold{
  font-weight:bold;
}
.textareaConter{
  margin-top:5px;
 ::v-deep .el-textarea__inner{
    min-height:100px !important;
 }
}
.pageRowBox{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1600px) {
  .rowW{
    width:350px;
  }
}
</style>
