<template>
  <div class="printBox">
    <!-- <div class="printBG"></div> -->
    
    <img src="../../assets/images/carsinglogo.jpg" class="imgpage"/>
    <div class="pageTop" >
    <h3>二手机动车出售委托书</h3>
    <div class="flex flex_between">
      <p class="bold">出售委托人（甲方）:<span class="spanW min150 bold">{{car.customerName}}</span></p>
      <p class="bold">编号：<span class="spanW min150 bold">{{res.contractNo}}</span></p>
    </div>
    <p class="bold">受托人（乙方）：上海铠寻旧机动车经纪有限公司</p>
    <p class="bold">经甲、乙双方友好协商，就甲方委托乙方办理出售其所拥有的二手机动车事宜达成本委托书。</p>
    <p class="bold">一、委托出售车辆基本状况：</p>
    <table class="table-custom mt10">
      <tr>
        <td width="100px">车辆号牌</td><td width="90px">{{car.carNo}}</td>
        <td width="85px">厂牌型号</td><td>{{car.makerName}}&nbsp;{{car.seriesName}}</td>
        <td width="70px">发动机号</td><td class="wordWrap">{{car.engineNo}}</td>
        <td width="50px">颜色</td><td width="80px">{{car.carColor}}</td>
      </tr>
       <tr>
        <td width="100px">初次登记日期</td><td width="90px">{{car.registerDate}}</td>
        <td width="85px">年检有效期</td><td>{{car.checkExpire}}</td>
        <td width="70px">车架号</td><td colspan="3">{{car.vinCode}}</td>
      </tr>
    </table>
    <p class="bold mt10">二、委托出售价格</p>
    <p class="p1">1.该车委托出售价格为人民币（大写<span class="spanW min150">{{res.carTradedPriceCn}}</span>）（￥<span class="spanW min100">{{res.carTradedPrice}}</span>）。</p>
    <p class="p1">2.如该车发生补缴、违章、遗失补证、随车附件缺少等业务且甲方委托乙方办理的，则甲方支付乙方办证押金人民币（大写<span class="spanW min150">{{res.feeDepositTotalAmountCn}}</span>）（￥<span class="spanW min100">{{res.feeDepositTotalAmount}}</span>）。</p>
    <p class="p1">3.甲方支付乙方办证风险押金人民币（大写<span class="spanW min150">{{res.riskDepositTotalAmountCn}}</span>）（￥<span class="spanW min100">{{res.riskDepositTotalAmount}}</span>）。</p>
    <p class="p1">4.出售委托书签订当日乙方支付的金额为人民币（大写<span class="spanW min150">{{res.totalPayAmountCn}}</span>）（￥<span class="spanW min100">{{res.totalPayAmount}}</span>）。</p>
    <p class="p1" v-if="res.channelSubsidyAmount">5.该车的补贴车款为人民币：（大写<span class="spanW min150">{{res.channelSubsidyAmountCn}}</span>）（￥<span class="spanW min100">{{res.channelSubsidyAmount}}</span>）。</p>
    <p class="p1" v-if="res.intentionMoneyAmount">{{res.channelSubsidyAmount?6:5}}.乙方已向甲方支付意向金人民币：（大写<span class="spanW min150">{{res.intentionMoneyAmountCn}}</span>）（￥<span class="spanW min100">{{res.intentionMoneyAmount}}</span>）。</p>
    <p class="bold indent2">甲方委托代办事项及附件缺少等费用于委托出售车款中扣除，费用及手续约定见《过户手续约定及车辆、过户材料交接表》。</p>
    <p class="indent2">办证结束后，乙方据实际办证情况对甲方所交押金实行多退少补。《机动车交通事故责任强制险》已包含在车辆委托出售价格中。</p>
    <p class="bold">三、甲方收款账号</p>
    <div class="flex mt10 mb10">
      <div><span>户名：</span><span class="spanW min300">{{carCustomerAccount.accountName}}</span></div>
      <div><span>开户行：</span><span class="spanW min300">{{carCustomerAccount.depositBank}}</span></div>
    </div>
    <div><span>账号：</span><span class="spanW min300">{{carCustomerAccount.account}}</span></div>
    <p class="bold mt10">四、甲、乙双方的权利和义务</p>
    <p class="p1">1.甲方应提供车辆过户手续所需相关证件及材料的原件，并保证均完整、真实、合法、有效。</p>
    <p class="p1">如甲方在签订本委托书时不能提供车辆过户手续所需车主全部有效证件，甲方需按附件一<span class="bold">《证件及材料补送约定表》</span>的约定送达证件。</p>
    <p class="p1" v-if="tableobj.date1=='暂缺'">2.甲方签订本委托书时暂缺机动车登记证书，且甲方在附件一<span class="bold">《证件及材料补送约定表》</span>约定日前将机动车登记证书交付乙方，乙方将在收到甲方交付的机动车登记证书当日支付甲方扣除办证押金及办证风险押金后的全部车款。</p>
    <p class="p1">{{tableobj.date1=='暂缺'?3:2}}.本出售委托书中约定的需甲方承担的一切费用，乙方均为代收代付，仅提供收据。</p>
    <p class="bold p1">{{tableobj.date1=='暂缺'?4:3}}.甲方应承诺事项：</p>
    <p class="indent2">1)甲方承诺在本委托书签订时如实向乙方完全披露委托出售车辆重大交通事故发生情况、车辆使用中的重大隐患及静态检测无法发现的其他车辆问题。</p>
    <p class="indent2">2)甲方承诺甲方委托出售的车辆不存在任何权属上的纠纷或法律责任，包括但不限于无抵押登记、海关监管、银行按揭未还清贷款、公检法查封、拼装、偷盗、走私等情况。</p>
    <p class="indent2">3)甲方承诺甲方委托出售车辆无不满足公安部102号令及办证地车管所规定的办理转移登记条件等情况（如：车架焊接或发动机更换且没在车管部门做过合法的变更、机动车与该车档案记载内容不一致等情况）。</p>
    <p class="indent2">4)甲方承诺，就甲方委托出售的车辆是否为“涉水车”（涉水车，即指该车辆属于在本委托书签订前曾经在严重积水路面行驶、或车辆局部或整体被水淹，导致车辆机械、构件、零部件、内饰等的损坏，或其他外观不能直接识别的各种损坏）事宜，向乙方完全、如实地披露如下事实（在如下□中打√选择）：</p>
    <div class="pl36" v-if="car.isWade==52">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售的车辆为“涉水车”。</el-checkbox>
    </div>
    <div class="pl36" v-if="car.isWade==51">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售的车辆非“涉水车”。</el-checkbox>
    </div>
    <p class="indent2">5)甲方确认委托出售的车辆交付时表显公里数为<span class="spanW">{{car.boardShowKmNums}}</span>，并完全、如实地披露如下事实（在如下□中打√选择）：</p>
    <div class="pl36" v-if="car.isChangeEvenNum==61">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售的车辆公里数从未经过更改。</el-checkbox>
    </div>
    <div class="pl36" v-if="car.isChangeEvenNum==63">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售的车辆公里数仅供参考，不确定是否经过更改。</el-checkbox>
    </div>
    <!--沪牌非新能源和非沪牌车辆不显示6.7两项-->
    <template v-if="car.isHuPlate==1&&car.isNewEnergy==82">
    <p class="indent2">6)甲方委托出售车辆的车牌是否系通过上海市免费发放的新能源汽车专用牌照额度而获取，向乙方完全、如实地披露如下事实（在如下□中打√选择）：</p>
    <div class="pl36" v-if="car.plateRegWay==='新能源额度'">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售车辆的车牌是通过免费额度获取。</el-checkbox>
    </div>
    <div class="pl36" v-if="car.plateRegWay==='机动车额度'">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售车辆的车牌不是通过免费额度获取。</el-checkbox>
    </div>
    <p class="indent2">7)甲方委托出售的新能源车辆是否符合上海市免费发放的新能源汽车专用牌照额度的申领要求，向乙方完全、如实地披露如下事实（在如下□中打√选择）：</p>
    <div class="pl36" v-if="car.applyShNewEngine==='可以'">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售的新能源车辆符合上海市免费发放的新能源汽车专用牌照额度的申领要求。</el-checkbox>
    </div>
    <div class="pl36" v-if="car.applyShNewEngine==='不可以'">
      <el-checkbox v-model="checked" :disabled="disabled">甲方委托出售的新能源车辆不符合上海市免费发放的新能源汽车专用牌照额度的申领要求。</el-checkbox>
    </div>
    </template>


    <p class="indent2">{{car.isHuPlate==1&&car.isNewEnergy==82?'8':'6'}})甲方全权委托乙方以甲方名义签署办证手续中必需的书面材料《二手车买卖合同》并授权乙方可对部分事项转委托，乙方承诺在代理办证事宜过程中按照本委托书的内容合法的行使代理权限，由于代理权限行使不当引起的纠纷由乙方负责。</p>
    <p class="indent2">如在本委托书签订后，乙方发现甲方上述承诺事项之任一不属实的，乙方有权无条件向甲方退车，要求甲方在乙方发出通知之日起两日内向乙方退还全部车价款；甲方未能如期支付前述款项的，应按照逾期支付款项的每日千分之一向乙方支付逾期付款违约金；若因此造成乙方任何损失的，甲方应予以全额赔偿；乙方并保留向甲方追溯其他相关的一切经济、法律责任的权利。</p>
    <p class="bold p1">{{tableobj.date1=='暂缺'?5:4}}.甲方义务：</p>
    <p class="indent2">1)委托出售车辆在<span class="spanW min100">{{todayPrint}}</span>前发生的交通违章和事故以及一切相关经济法律责任由甲方承担。如办证中发现该车于上述时间点前的交通违章尚未处理，乙方有权全权代处理，违章费用按实收取，每单加收相应手续费，并延长一个办证工作日，涉及扣分的违章，需甲方自行处理，如需委托乙方处理的，手续费另计。</p>
    <p class="indent2">2)甲方委托乙方代为处理交通违章的，需提供可处理该车违章的驾驶证复印件。</p>
    

    </div>

     <div  style="page-break-before:always;"></div>
    <img src="../../assets/images/carsinglogo.jpg" class="imgpage"/>

    <div class="pageTop">
    <p class="indent2">3)甲方自行处理交通违章的，对于签订本合同时能够查询到的存量违章，甲方应在约定的日期前处理完毕；对于交通违章发生在<span class="spanW min100">{{todayPrint}}</span>前，但由于违章记录延后录入导致交付车辆时尚未能够查询到的新增违章，甲方应在乙方告知其存在新增违章后的3个自然日内处理完毕，并顺延相应办证工作日（若需行驶证原件的，甲方办结违章起始时间以乙方通知甲方可领取的次日起算）。针对上述存量及新增违章，甲方逾期未处理的，甲方应支付乙方人民币100元/工作日的逾期违约金。</p>
    <p class="indent2">4)若甲方在约定的违章处理截止日期后的7个工作日内仍未将违章处理完毕的，乙方有权向甲方退车，甲方除应返还乙方全额已支付车款及支付违约金外，若因此造成乙方任何损失的，甲方应予以全额赔偿。</p>
    <p class="indent2">5)甲方签订本委托书时暂缺机动车登记证书或行驶证的，如后续发生以下情况，乙方有权向甲方退车，甲方除应返还乙方全额已支付的车款外，甲方还应全额赔偿乙方对该车实际已产生的相关办证费用损失，具体情况如下：</p>
    <p class="indent2">①甲方所提供的机动车登记证书或行驶证存在需变更项。</p>
    <p class="indent2">②甲方所提供的机动车登记证书显示有抵押未解除。</p>
    <p class="indent2">③甲方无法或未按期提供机动车登记证书或行驶证。</p>
    <p class="indent2">6)本出售委托书签订后，如发生不可抗力事件需要甲方配合的，甲方有义务提供相应证件、材料等积极配合，如该车发生交通事故，甲方有义务配合提供保单及证件等协助办理理赔事宜。</p>
    <!--此项只有非沪牌车辆显示，isHuPlate是否沪牌，1是,2否，默认1-->
    <p class="indent2" v-if="car.isHuPlate==2">7)如受托车辆为非沪牌照且甲、乙双方约定异地办证的，在车辆办证过程中，应车辆所在地车辆管理所政策，甲方必须到场办理手续的，乙方负责与甲方协调约定办证日，甲方需在乙方发出通知次日起5个工作日内配合办证，逾期不配合，甲方应支付乙方人民币100元/工作日的逾期违约金，乙方承担甲方到场办理手续而产生的合理交通费（仅限上海至车牌所在地城市间交通费用，不含市内交通费用）及住宿费。如存在受托车辆需补领机动车登记证书而需车主本人到场，乙方均不承担甲方的任何交通费及住宿费（除补领机动车登记证书后因车辆管理所信息未归档，导致甲方无法当日配合完成办证，需甲方二次配合的，乙方承担甲方第二次到场办理手续而产生的合理交通费及住宿费）。</p>
    <p class="indent2">8)如甲方在乙方发出通知次日起10个工作日仍不配合提供证件或不配合到场办理造成办证不成功的，乙方有权向甲方退车，甲方除应返还乙方全额已支付车款及支付违约金外，若因此造成乙方任何损失的，甲方应予以全额赔偿。</p>
    <p class="indent2">9)如因甲方不配合提供证件或不配合到场办理造成乙方办证延误的，每延迟一个工作日配合乙方，甲方须承担逾期违约金人民币100元/工作日，并延长相应办证工作日。</p>
    <p class="indent2">10)甲方同意在办证手续办理结束后，采取<span class="bold">《材料取回约定表》</span>约定方式取回材料。</p>
    <p class="indent2">11)甲方在交付乙方委托出售车辆时确认已将车上所有物品清理完毕，如有未取走物品，视为放弃，乙方可自行处理。</p>
    <p class="indent2">12)如因甲方违反本委托书任一条款，除应承担本委托书所约定违约责任外，甲方还应赔偿乙方遭受的的全部损失（包括但不限于向任何第三方支付的赔偿、律师费、诉讼费、保全费、诉讼保全责任保险费/担保费、公证费、差旅费等乙方所支出的合理费用）。</p>
    <p class="bold p1">{{tableobj.date1=='暂缺'?6:5}}.乙方应承诺：</p>
    <p class="indent2">1)乙方承诺该二手车相关材料在<span class="spanW">{{res.customerDeadline}}</span>完成办证手续（发生政府部门政策变化或不可抗力因素除外，材料说明见<span class="bold">《过户手续约定及车辆、过户材料交接表》</span>）。</p>
    <p class="indent2">如未能在约定时间内完成办证手续，乙方将支付甲方人民币100元/工作日的逾期补偿金。</p>
    <p class="indent2">2)办证手续实际完成日次日（遇节假日顺延）起甲方可以办理材料取回手续（遇节假日顺延，发生政府部门政策变化或不可抗力因素除外）。</p>
    <p class="indent2">3)乙方不能使用甲方的相关证件从事与车辆办证无关的活动，否则由此造成的一切后果由乙方负责。</p>
    <p class="indent2">4)乙方提取甲方委托出售的车辆后，车辆所发生的一切交通违章和事故，以及所产生的相关法律责任和经济责任均由乙方承担，但甲方应提供相关证件及材料，协助乙方办理理赔事宜。</p>
    <p class="bold">五、争议处理</p>

     <p class="p1">1.双方产生的与本委托书有关的任何争议，由双方协商解决，如协商不成，可向乙方所在地上海市闵行区人民法院提起诉讼解决。</p>
    <p class="p1">2.委托书订立后，甲方如要求撤销委托，收回委托出售车辆，则要视乙方处理该二手车进程而定。</p>
    <p class="indent2">1)如已将该二手车处理，则甲方不能要求撤销本委托书并收回二手车。</p>
    <p class="indent2">2)如未将该二手车处理，则乙方在收回垫付的二手车款后，乙方将委托出售的二手车退还甲方，期间所有费用由甲方承担。
上述“已将该二手车处理”包括乙方已将车辆完成转籍、过户、已与商户签订该车辆的收购委托书或已将本委托书第二条第4点的车款支付给甲方。</p>
    <p class="bold">六、其他约定事项：</p>
    <template v-if="covenants">
      <p class="p1 fontDecoration" >{{covenants}}</p>
    </template>
    <template v-if="!covenants">
      <p class="p1 wcon" :style="!covenants?'padding-top:20px':''">{{covenants}}</p>
      <p class="p1 wcon"></p>
      <p class="p1 wcon"></p>
    </template>
    
    <p class="bold">七、本委托书一式两份，附件一与本委托书具有同等法律效力，经甲、乙双方签字盖章后生效。委托书生效后，双方对该委托书内容的变更或补充应采取书面形式，作为本委托书的附件。附件与本委托书具有同等的法律效力。</p>
    <div class="flex mt20">
      <div class="leftBox">
        <p>甲方（签章）：</p>
        <p>授权代表：</p>
        <p>联系电话：{{car.customerMobile}}</p>
        <p>日期：{{today}}</p>
      </div>
      <div class="rightBox">
        <p>乙方（签章）：</p>
        <p>授权代表：</p>
        <p>联系电话：</p>
        <p>日期：{{today}}</p>
      </div>
    </div>

    </div>

    <div  style="page-break-before:always;"></div>
    <img src="../../assets/images/carsinglogo.jpg" class="imgpage"/>

    <div class="pageTop">
   
    
    <h4>过户手续约定及车辆、过户材料交接表</h4>
    <table class="table-custom mt10 carTabcus">
      <tr>
        <td colspan="3">一、车主证件</td>
      </tr>
      <tr>
        <td>项目</td><td width="90px">状态</td><td width="200px">备注</td>
      </tr>
       <tr v-for="(item,index) in idcardlist" :key="`car${index}`">
        <td>
          <p v-if="item.materialName.indexOf('居住证')==-1&&item.materialName.indexOf('盖章材料')==-1">{{item.materialName}}：<span v-if="index==0">姓名<span class="spanW min20 mr10 ml10">{{car.customerName}}</span></span>证件号码<span class="spanW min50 ml10">{{item.realData2}}</span></p>
          <p v-if="item.materialName.indexOf('居住证')!=-1||item.materialName.indexOf('盖章材料')!=-1">
            <span>{{item.materialName}}</span>
            <!-- <span>{{item.realData3}}</span> -->
          </p>
        </td>
        <td width="90px">{{item.realData1}}</td>
        <td width="200px" :rowspan="index==0?idcardlist.length:''" v-if="index==0">
          <p v-if="item.remark1">{{item.remark2}}甲方无法提供证件</p>
          <p v-if="item.remark3">{{item.remark4}}乙方须将证件交回甲方</p>
        </td>
      </tr>
    </table>
    <table class="table-custom mt10 carTabcus">
      <thead>
      <tr>
        <td colspan="6">二、车辆材料、附件、凭证、税费</td>
      </tr>
       <tr class="carTableFont">
        <td>项目名称</td><td width="90px">实际状态</td><td>选项</td><td width="110px">费用(单位:元)</td><td width="110px">押金(单位:元)</td><td width="90px">费用承担方</td>
      </tr>
      </thead>
      <tbody>
        <tr>
          <td>商品车</td><td>有</td><td></td><td></td><td></td><td></td>
      </tr>
      <template v-for="(item,index) in carMaterialsList">
      <tr class="carTableFont" :key="`mate_${index}`" v-if="item.realData">
        <td>{{item.materialName}}</td>
        <td width="90px"><!--实际状态-->
          <p v-if="item.materialName.indexOf('钥匙')>-1">{{item.realData?`有${item.realData}把`:''}}</p>
          <p v-if="item.materialName.indexOf('钥匙')==-1">{{item.realData}}</p>
        </td>
        <td>
          <p v-if="item.materialName.indexOf('钥匙')>-1">{{item.options1?`缺${item.options1}把,&nbsp;`:''}}{{item.options2?`暂缺${item.options2}把`:''}}{{item.options3?`,&nbsp;${item.options3}客户提供`:''}}</p>
          <p v-if="['车辆牌照','桩充','线充','备胎'].indexOf(item.materialName)>-1||item.materialName.indexOf('审核单')>-1">{{item.options?`${item.options}客户提供`:''}}</p>
          <p v-if="item.materialName.indexOf('登记证书')>-1||
            item.materialName.indexOf('行驶证')>-1">
            <template v-if="item.realData=='有'">{{item.options1}}&nbsp;&nbsp;{{item.options2?`产证编号：${item.options2}`:''}}&nbsp;&nbsp;{{item.options3?`变更内容：${item.options3}`:''}}</template>
            <template v-if="item.realData=='缺'">{{item.options1}}</template>
            <template v-if="item.realData=='暂缺'">{{item.options1?`${item.options1}客户提供`:''}}</template>
          </p>
          <p v-if="item.materialName.indexOf('保险单')>-1">{{String(item.options1)}}</p>
          <div v-if="item.materialName.indexOf('违章')>-1">
            <p v-if="item.realData&&item.realData!='无'">{{item.options1}}前的{{item.options2}}次</p>
            <p v-if="item.realData&&item.realData!='无'">{{item.options3}}处理完毕</p>
            <p v-if="item.realData=='代办'">违章本金{{item.options4}}元&nbsp;&nbsp;驾照扣分{{item.options5}}分</p>
            <!-- <p v-if="item.options6">一项超过12分</p> -->
          </div>
        </td>
        <td width="110px">{{item.feeAmount?item.feeAmount:''}}</td>
        <td width="110px">{{item.depositAmount?item.depositAmount:''}}</td>
        <td width="90px">{{item.feeBearerName}}</td>
      </tr>
      </template>
      </tbody>
      <tr v-if="res.riskDepositTotalAmount">
        <td>办证风险押金</td><td colspan="3">{{getCarNoName()}}</td><td>{{res.riskDepositTotalAmount}}</td><td>甲方</td>
      </tr>
    </table>
    </div>
    <div  style="page-break-before:always;"></div>
    <img src="../../assets/images/carsinglogo.jpg" class="imgpage"/>
    <div class="pageTop">
    <table class="table-custom mt10" v-if="proceduresType">
      <tr>
        <td colspan="6">三、附加手续办理</td>
      </tr>
       <tr>
        <td colspan="2">项目</td><td>状态</td><td>费用（单位：元）</td><td>费用承担方</td><td>备注</td>
      </tr>
      <template v-for="(item,index) in carlistPro">
      <tr :key="`pro_${index}`" v-if="item.materialName.indexOf('备注')!=-1&&item.selfDefineRemark||item.materialName.indexOf('备注')==-1&&item.materialName.indexOf('额度变更')==-1||item.materialName.indexOf('额度变更')>-1&&limitFlag">
        <td :colspan="item.materialName.indexOf('额度变更')==-1?'2':''" >{{item.materialName}}{{item.materialName.indexOf('备注')!=-1?`：${item.selfDefineRemark}`:''}}</td>
        <td v-if="item.materialName.indexOf('额度变更')!=-1">变更手续</td>
        <td >{{item.realData?'是':''}}</td>
        <td>{{item.feeAmount}}</td>
        <td>{{item.feeBearerName}}</td>
        <td>{{item.remark}}</td>
      </tr>
      </template>
    </table>
    <h4 class="mt10">材料取回约定表（请√勾选确认）：</h4>
    <table class="table-custom mt10">
      <tr>
        <td>
          <div class=" ">材料：
          <el-checkbox-group v-model="checkedList" :disabled="disabled" @change="change(0)">
          <el-checkbox class="checkboxMar" v-for="item in checkList" :label="item.id" v-if="checkedList.includes(item.id)" :key="`c_${item.id}`">{{item.requireName}}</el-checkbox>
          <el-radio v-model="res.getBack" label="甲方取回" v-if="res.getBack" text-color="#333333" :disabled="disabled">甲方取回</el-radio>
        </el-checkbox-group>
        </div>
        </td>
      </tr>
       <tr>
        <td>如办证结束后，甲方所选材料取回方式与本协议不一致的，以录音电话内容为准，作为对本协议的补充具有同等法律效力。
如甲方办证材料取回人非车主本人，需在取回办证材料时出示本委托书。</td>
      </tr>
    </table>
    <div class="flex flex_between mt10">
      <span class="bold">附件一</span>
      <span class="bold">证件及材料补送约定表（补送项目请√勾选）</span>
      <span></span>
    </div>
    <table class="table-custom mt10" v-if="temporarilyList.length>0">
      <tr v-for="(item,index) in temporarilyList" :key="item.id" :style="temporarilyList.length==2&&index==1||temporarilyList.length==1?'border-bottom: 2px solid':''">
        <td :colspan="[0,1,2].includes(index)?'':2">
          <el-checkbox v-model="tableobj.checked3" :disabled="disabled" class="bold">{{item.materialName}}</el-checkbox><span class="spanW min100 pl10">{{item.repairSendTime?item.repairSendTime.indexOf('提供')!=-1?item.repairSendTime.split('提供')[0]:item.repairSendTime:item.repairSendTime}}</span>
          <span>{{[0,1,2].includes(index)?'甲方提供。':'甲方提供，逾期未提供按缺项条款处理，乙方据此扣除甲方对应手续押金。'}}</span>
        </td>
        <td rowspan="3" v-if="index==0">自该委托书约定送达日<span class="borderB">（未约定具体日期的，需参照<span class="bold">《送达时间及地点约定》</span>）</span>起每延迟一个工作日交予乙方，甲方支付乙方相应逾期补偿金人民币100元/工作日，并延长相应工作日。</td>
      </tr>
    </table>
    <!-- </div>
    <div  style="page-break-before:always;"></div>
     <img src="../../assets/images/carsinglogo.jpg" class="imgpage"/>
    <div class="pageTop"> -->
    <h4>送达时间及地点约定</h4>
    <table class="table-custom mt10">
      <!--沪牌显示-->
      <template v-if="car.isHuPlate==1">
      <tr>
        <td rowspan="3" width="120px">沪牌车辆（不含办证工作日为3个月的沪新能源车牌）</td>
        <td>选择一（无需延长工作日）：甲方于乙方邀约当日13:00前将身份证件送至乙方各门店 或16:00前将身份证件送至乙方吴宝路店 或于乙方邀约后第一个工作日10:00前将身份证件送至乙方指定办证点。</td>
      </tr>
       <tr>
        <td>选择二（需顺延一个工作日）：甲方于乙方邀约后第一个工作日13:00前将身份证件送至乙方各门店 或16:00前将身份证件送至乙方吴宝路店 或于乙方邀约后第二个工作日10:00前将身份证件送至乙方指定办证点。</td>
      </tr>
      <tr>
        <td>注：送达时间晚于“选择二”，视作甲方逾期。</td>
      </tr>
      </template>
      <!--非沪牌显示-->
      <template v-if="car.isHuPlate==2">
        <tr>
          <td>非沪牌车辆及办证工作日为3个月的沪新能源车牌</td>
          <td><p>私车：甲方于乙方邀约当日至邀约后第三个工作日18:00前将办证所需证件送至乙方吴宝路店或当地办证点。</p>
          <p>公车：甲方于乙方邀约当日至邀约后第五个工作日18:00前将办证所需证件送至乙方吴宝路店或当地办证点。</p><p>送达时间晚于上述时间，视作甲方逾期。</p></td>
        </tr>
      </template>
      <tr>
        <td colspan="2">
          <!--沪牌显示-->
          <p v-if="car.isHuPlate==1">注：沪牌车辆成交时甲方无法在二手车流通APP上传车主信息（中国籍需刷验车主身份证），甲方需在出售委托书签订次日13:00前完成上传或刷验（出售委托书中有明确约定证件无法提供时间的除外）。超过约定时间，工作日顺延，视为甲方逾期。</p>
          <!--非沪牌显示-->
          <p v-if="car.isHuPlate==2">注：非沪牌车辆甲方证件包含不限于“身份证原件”、“身份证复印件”、“身份证照片”、“车主半身照”、“车主过户邀约视频”、“车主与委托书合照”、“苏E车辆至吴宝路店或当地办证点做信息预录入”、“浙C车辆至吴宝路店或当地办证点签委托书”、“苏B车辆无锡车务通录入”、“闽A、闽K车辆福州车务通录入”。</p>
        </td>
      </tr>
    </table>
    <table class="table-custom mt10 table-center addtable">
      <tr>
        <td>送达地点</td><td>名称</td><td>地址</td>
      </tr>
      <tr>
        <td>武宁路市场</td><td>上海汇诚二手车经营有限公司</td><td>上海市普陀区中山北路2907号交易大楼4楼办证大厅A-04室</td>
      </tr>
      <tr v-for="(item,index) in shoplist" :key="item.id">
        <td :rowspan="shoplist.length" v-if="index==0">车开新</td><td>{{item.shopName}}</td><td>{{item.shopAddr}}</td>
      </tr>
    </table>
    <p class="indent2 mt10 mb10">甲、乙双方均已阅读以上表格的内容，并签章确认（本委托书签订于上海市闵行区）。</p>
    <div class="flex flex_around pb100">
      <div>甲方（签章）：</div>
      <div>乙方（签章）：</div>
      <div>时间：{{today}}</div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    res:{
      type:Object
    },
  },
  data(){
    return{
      intentionType:true,// 此项付意向金的显示
      today:'',
      checked:true,
      disabled:true,
      checkList:[],//材料取回约定表
      checkedList:[],//选中的值
      checkedList1:[],
      covenants:'',//其他约定事项：
      idcardlist:[],//过户手续约定及车辆、过户材料交接表
      carlistPro:[],//车辆手续列表
      proceduresType:false,//附加手续
      carMaterialsList:[],//车辆材料列表
      car:{},//车辆基本信息
      carCustomerAccount:{},//车辆客户银行账户信息
      checkList1:[],//车主证件
      checkList11:[],
      checkList2:[],//车钥匙
      checkList22:[],
      tableobj:{
        checked1:false,//机动车登记证书
        date1:'',
        checked2:false,//在用额度转移变更审核单
        date2:'',
        checked3:true,//机动车交通事故责任强制保险单
        date3:'',
        checked4:false,//备胎
        date4:'',
        checked5:false,//线充
        date5:'',
        checked6:false,//桩充
        date6:'',
        checked7:false,//车辆牌照
        date7:'',
        checked8:false,//机动车行驶证
        date8:'',
        key1:'',//钥匙1
        key2:'',//钥匙2
        key3:'',//钥匙3
        date9:'',//钥匙提供时间
        date10:'',//违章时间
      },
      shoplist:[],
      temporarilyList:[],//材料暂缺项
      limitFlag:false,//额度变更单默认不展示
      todayPrint:'',//当前打印时间
    }
  },
  watch:{
    checked:function(e){
      if(!e){
        this.checked=true;
      }
    },
    res:function(data){
      if(this.res.car) this.car=this.res.car;
      if(this.res.car) if(this.res.car.carCustomer) this.carCustomerAccount=this.res.car.carCustomer.carCustomerAccount?this.res.car.carCustomer.carCustomerAccount:{};
      if(this.car.checkExpire){
        let w=this.car.checkExpire.split('-');
        this.car.checkExpire=`${w[0]}-${w[1]}`
      }
      this.getDetail()
    }
  },
  created(){
    let tdy=this.$$.getTodyTime(1);
    this.today=tdy.split(' ')[0]

    this.todayPrint=this.$$.getTodyTime('minute');
    
    this.getShopList();//获取门店
  },
  methods:{
    getCarNoName(){
      if(this.car.isHuPlate===1){//是否沪牌，1是,2否，默认1
        if(this.car.isNewEnergy===81){//是否新能源车，81否，82是,默认81
          if(this.car.carNo.indexOf('沪C')!=-1||this.car.carNo.indexOf('沪c')!=-1){
            return '沪C'
          }else{
            return "沪大牌"
          }
        }else{
          return '新能源'
        }
      }else{
        return '非沪'
      }
    },
    getDetail(){
      //合同要求列表(2个复选列表)
      let contractRequireList=this.res.contractRequireList;
      if(contractRequireList)
      for(let i=0;i<contractRequireList.length;i++){
        let item=contractRequireList[i]
        //要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
        switch(item.requireType){
          case 12:
            if(item.isSelected==1){
              this.checkedList.push(item.id)//选中
            }
            this.checkList.push(item)//过户凭证特殊要求
            break;
          case 13:
              this.covenants=item.requireName;
            break;
        }
      }

      //车主证件列表
      let arrCard=[];
      let oldCard=this.car.customerMaterialsList?JSON.parse(JSON.stringify(this.car.customerMaterialsList)):[];
      for(let i=0;i<oldCard.length;i++){
        let item=oldCard[i]
        
        if(item.realData){
          if(item.realData.indexOf(',')!=-1){
            let a=item.realData.split(',')
            item.realData1=a[0];
            item.realData2=a[1];
            item.realData3=a[2];
          }else{
            item.realData1=item.realData;
          }
          if(['暂缺'].includes(item.realData1)){
            this.temporarilyList.push(item)
          }
        }else{
          item.realData1='';
          item.realData2='';
          item.realData3='';
        }
        if(item.remark){
          if(item.remark.indexOf('|')!=-1){
            let a=item.remark.split('|')
            if(a[0]){
              item.remark1=a[0]=='true'?true:'';
            }
            if(a[1]){
              if(a[1].indexOf(',')!=-1){
                item.remark2=`${a[1].split(',')[0]}至${a[1].split(',')[1]}`;
              }
            }
            if(a[2]){
              item.remark3=a[2]=='true'?true:'';
            }
            item.remark4=a[3];
          }
        }
        arrCard.push(item)//身份证列表
      }
      this.idcardlist=arrCard;

      //车辆材料列表
      let cardatalist=this.car.otherMaterialsList?this.car.otherMaterialsList:[];
      let TankerArr=[];//油车
      let energyArr=[];//电车
      this.checkList2=[];//钥匙列表
      let nullobj=new Object;
      let isSelectedCheck=false;
      let proceArr=[];//是否有选择附加手续
      for(let i=0;i<cardatalist.length;i++){
        let item=cardatalist[i];
        item.options1='';
        item.options2='';
        item.options3='';
        item.options4='';
        
        if(item){
          nullobj=item;
          
          switch(item.type){
            case 'car':
              if(item.pdaData=='无'&&item.materialName.indexOf('车辆牌照')!=-1){
                item.feeBearerId=2;
                item.feeBearerName='商户';
              }else if(item.pdaData=='无'&&['桩充','线充','备胎'].indexOf(item.materialName)!=-1){
                item.feeBearerId=1;
                item.feeBearerName='客户';
              }else if(item.materialName.indexOf('保险单')!=-1){
                item.options1=item.options;
                // item.options1=item.options?JSON.parse(item.options):[]
              }else if(item.materialName.indexOf('钥匙')!=-1||item.materialName.indexOf('登记证书')!=-1){
                if(item.options){
                  item.options1=item.options.split(',')[0]
                  item.options2=item.options.split(',')[1]
                  item.options3=item.options.split(',')[2]
                }
              }else if(item.materialName.indexOf('行驶证')!=-1){
                if(item.options){
                  item.options1=item.options.split(',')[0]
                  item.options2=item.options.split(',')[1]
                }
              }else if(item.materialName=='违章'){
                if(item.options){
                  item.options1=item.options.split(',')[0]
                  item.options2=item.options.split(',')[1]
                  item.options3=item.options.split(',')[2]
                  item.options4=item.options.split(',')[3]
                  item.options5=item.options.split(',')[4]
                  this.tableobj.date10=item.options1;//违章时间
                  // item.options6=item.options.split(',')[5]?Boolean(item.options.split(',')[5]):item.options.split(',')[5]
                }
              }
              /**-- 需要询问4S点费用，只有“沪电动车牌”会体现这个字段 
              *--isHuPlate是否沪牌，1是,2否，默认1,isNewEnergy是否新能源车，81否，82是,默认81**/
              //isNewEnergy是否新能源车，81否，82是,默认81
              if(this.car.isNewEnergy!=82&&['桩充','线充'].indexOf(cardatalist[i].materialName)==-1){
                TankerArr.push(cardatalist[i])//油车
              }
              energyArr.push(cardatalist[i])//电车
              if(item.materialName.indexOf('机动车登记证书')!=-1){
                this.tableobj.date1=item.realData;//暂缺才显示
              }

              //项目选择表
              if(item.realData=='有'){
                if(item.materialName.indexOf('机动车登记证书')!=-1){
                  this.tableobj.checked1=true;
                }else if(item.materialName.indexOf('在用额度转移变更审核单')!=-1){
                  this.tableobj.checked2=true;
                  this.tableobj.date2=item.options;
                  if(['有','暂缺'].includes(item.realData)){
                    this.limitFlag=true;
                  }else{
                    this.limitFlag=false;
                  }
                }
                // else if(item.materialName.indexOf('保险单')!=-1){
                //   // this.tableobj.checked3=true;
                //   this.tableobj.date3=item.options;
                // }else if(item.materialName.indexOf('备胎')!=-1){
                //   this.tableobj.checked4=true;
                //   this.tableobj.date4=item.options;
                // }else if(item.materialName.indexOf('线充')!=-1){
                //   this.tableobj.checked5=true;
                //   this.tableobj.date5=item.options;
                // }else if(item.materialName.indexOf('桩充')!=-1){
                //   this.tableobj.checked6=true;
                //   this.tableobj.date6=item.options;
                // }else if(item.materialName.indexOf('车辆牌照')!=-1){
                //   this.tableobj.checked7=true;
                //   this.tableobj.date7=item.options;
                // }else if(item.materialName.indexOf('机动车行驶证')!=-1){
                //   this.tableobj.checked8=true;
                //   this.tableobj.date8=item.nature;
                // }
              }
              if(item.materialName.indexOf('钥匙')!=-1){
                if(item.materialName.indexOf('普通')!=-1&&item.realData){
                  this.checkList2.push(1)
                  this.tableobj.key1=item.realData;
                }else if(item.materialName.indexOf('遥控')!=-1&&item.realData){
                  this.checkList2.push(2)
                  this.tableobj.key2=item.realData;
                }else if(item.materialName.indexOf('智能')!=-1&&item.realData){
                  this.checkList2.push(3)
                  this.tableobj.key3=item.realData;
                }
                if(item.options2&&item.options2!=='0'){//暂缺
                  this.temporarilyList.push(item)
                }
                if(item.options3){//提供日期
                  this.tableobj.date9=item.options3;
                }
              }
              //材料暂缺项
              if(['暂缺'].includes(item.realData)){
                this.temporarilyList.push(item)
              }
              if(this.checkList2.length>0){
                this.checkList2.push(0)
              }
              this.checkList22=JSON.parse(JSON.stringify(this.checkList2))//防止点击
              break;
            case 'procedure'://手续
              if(item.materialName.indexOf('父母')!=-1&&item.isSelected==1){
                item.materialName1=0;
              }else if(item.materialName.indexOf('夫妻')!=-1&&item.isSelected==1){
                item.materialName1=1;
              }else if(item.materialName.indexOf('抬头')!=-1&&item.isSelected==1){
                item.materialName1=2;
              }else if(item.materialName.indexOf('合并')!=-1&&item.isSelected==1){
                item.materialName1=3;
              }
              // item.realData=item.realData?Boolean(item.realData):item.realData
              // item.realData=item.realData=='是'?Boolean(item.realData):item.realData;
              /**当附加手续没有选择的时候不展示**/
              
              // if(item.realData=='是'||item.realData==true){
              //   this.proceduresType=false;
              //   proceArr.
              // }
              proceArr.push(item.realData)
              

              if(item.materialName.indexOf('额度变更')!=-1&&item.realData==='是'){
                if(item.isSelected==1){
                  this.carlistPro.push(item)//车辆手续
                  isSelectedCheck=true;
                }
              }else if(item.materialName.indexOf('额度变更')==-1&&item.realData==='是'){
                  this.carlistPro.push(item)//车辆手续
              }
              break; 
          }
        }
      }
      proceArr.map(item=>{
        if(item=='是'){
          this.proceduresType=true;
        }
      })
      if(this.car.isNewEnergy==82){
        this.carMaterialsList=energyArr;
      }else{
        this.carMaterialsList=TankerArr;//油车
      }
      //清空对象值
      let newObj=JSON.parse(JSON.stringify(nullobj))
      Object.keys(newObj).forEach(key => newObj[key] = '')
      if(isSelectedCheck==false){
        newObj.materialName='额度变更';
        this.carlistPro.splice(1,0,newObj)
      }
      this.checkedList1=JSON.parse(JSON.stringify(this.checkedList))
    },
    change(state){
      switch(state){
        case 0://材料取回约定表
          this.checkedList=JSON.parse(JSON.stringify(this.checkedList1))
          break;
        case 1:
          this.checkList1=JSON.parse(JSON.stringify(this.checkList11))
          break;
        case 2:
          this.checkList2=JSON.parse(JSON.stringify(this.checkList22))
          break;
      }
    },
    getShopList(){//获取门店列表
      let query={
        shopTypeList:[0],
        isEnabled:0,
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
/**
*A4纸大小210mm*297mm-794px*1123px
**/
@import url('../../assets/css/print.css') print;

@media print{
  .pageTop{
    min-height:calc(297mm - 20mm);
  }
 
}
 .table-custom{
    border: 2px solid #000;
    // table-layout: inherit;
  }
  .carTabcus{
    table-layout: inherit;
  }
  .carTabcus td{
    padding:3px 5px;
  }
  label,.el-checkbox__label,p,span,td{font-size:12px}
  ::v-deep .el-radio__label,
  ::v-deep .el-checkbox__label,::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label{color: #000000;
    font-size:12px;}
  ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner{
    background-color:#333333;
  }


#app .table-custom{
  border: 2px solid #000;
  
}
#app .carTabcus{
  table-layout: inherit;
}
#app .carTabcus td{
  padding:3px 5px;
}

// .carTableFont td:nth-child(2),
// .carTableFont td:nth-child(6){
//   width:90px;
//   -webkit-print-color-adjust: exact;
//   -moz-print-color-adjust: exact;
//   color-adjust: exact;
// }

.printBox{
  width: 205mm;
  margin:auto;
  position: relative;
  .el-checkbox__input.is-disabled+span.el-checkbox__label{color: #000000;}
  .printBG{
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .imgpage{
    height:31px;
    width:100%;
    margin-top:10px;
  }
  .pageTop{
    border:5px solid #000000;
    padding:10px;
  }
  h3{
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h4{
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  }
  .p1{
    text-indent:24px;
  }
  .bold{
    font-weight:bold;
  }
  .indent2{
    text-indent:36px;
  }
  .leftBox{
    width:40%;
    margin-left:100px;
  }
  .rightBox{
    width:40%
  }
  .ml100{
    margin-left:80px;
    margin-right:50px;
  }
  .m50{
    margin-left:50px;
  }
  .spanW{
    padding:0 10px;
    // min-width:100px;
    border-bottom:1px solid #666666;
    // display:inline-block;
  }
  .min20{
    min-width:20px;
  }
  .min50{
    min-width:50px;
  }
  .min100{
    min-width:120px;
  }
  .min150{
    min-width:100px;
  }
  .min300{
    min-width:300px;
  }
  .borderB{
    border-bottom:1px solid #666666
  }
  .pl36{
    padding-left:36px
  }
  .pl10{
    padding-left:10px;
  }
  .wcon{
    width:100%;
    border-bottom:1px solid #666666;
    margin-bottom:20px;
  }
  .fontDecoration{
    text-decoration: underline;
    margin-bottom:10px;
  }
  .el-checkbox__inner{
    border-color:#000000;
  }
  .el-checkbox__label,
  .el-checkbox__input.is-checked+.el-checkbox__label{
    color:#000000;
  }
  .bold .el-checkbox__label{
    font-weight:bold;
  }
}
.addtable tr td:nth-of-type(1){width:100px}
#app .table-custom td{height:auto;text-align:left;padding:0 5px;}
#app .table-center td{text-align:center}
.is-disabled{color: #333333;}
</style>
