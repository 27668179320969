<template>
  <!-- 收货管理 -->
  <div class="receiveGoods">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="待收货" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="已收货" name="second" class="mt20"></el-tab-pane>
      <!-- <el-tab-pane label="异常" name="third" class="mt20"></el-tab-pane> -->
    </el-tabs>
    <el-table :data="tableData" border class="mt20 table-mainbg" stripe>
        <el-table-column prop="logistNo" label="收货单" align="center"></el-table-column>
        <el-table-column prop="logistTypeName" label="发货方式" align="center"></el-table-column>
        <el-table-column prop="expressLogistNo" label="快递单号" align="center">
          <template slot-scope="scope">{{scope.row.expressLogistNo?scope.row.expressLogistNo:'-'}}</template>
        </el-table-column>
        <el-table-column prop="agencyMobile" label="代驾手机号" align="center">
          <template slot-scope="scope">{{scope.row.agencyMobile?scope.row.agencyMobile:'-'}}</template>
        </el-table-column>
        <el-table-column prop="empName" label="带货员工姓名" align="center">
          <template slot-scope="scope">{{scope.row.empName?scope.row.empName:'-'}}</template>
        </el-table-column>
        <el-table-column prop="fromShopName" label="发货方" align="center"></el-table-column>
        <el-table-column  prop="fromName" label="发货人" align="center"></el-table-column>
        <el-table-column prop="fromTime" label="发货时间" align="center"></el-table-column>
        <!-- <el-table-column prop="toReceiveTime" label="接收时间" v-if="['second'].includes(activeName)" align="center"></el-table-column> -->
        <el-table-column prop="preArriveTime" label="预计到达时间" align="center"></el-table-column>
        <el-table-column prop="toShopName" label="收货方" align="center"></el-table-column>
        <!-- <el-table-column prop="toName" label="接收人" v-if="['second'].includes(activeName)" align="center"></el-table-column> -->
        
        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-if="['first'].includes(activeName)" @click="$router.push({path: '/salesManager/receiveGoods/receiveDeliveryNote', query:{id: scope.row.id}})">接收</el-button>
            <el-button size="mini" type="primary" v-if="['second','third'].includes(activeName)" @click="$router.push({path: '/salesManager/receiveGoods/receiveDeliveryNote', query:{id: scope.row.id}})">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      roleItem:{},
    }
  },
  created() {
    if(this.$route.query.state) this.activeName=this.$route.query.state;
    let user=this.$store.state.user.userData;
    this.roleItem=this.$$.roleName(user,'department')
    this.handleClick();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    initData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        // toShopType:41,
        // toShopId:this.roleItem.shop?this.roleItem.shop.id:''
      }
      switch(this.activeName){
        case 'first':
          params.logistStatus = [13];
          break;
        case 'second':
          params.logistStatus = [14];
          break;
        case 'third':
          params.logistStatus = [15];
          break;
      }//11待发货, 12在途, 13待收货，14已接收，15异常
      this.$api.logisticsList(params).then(res => {
        if(res.code === 0){
          this.tableData = res.data.records;
          this.page_total = res.data.total;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.receiveGoods{
  padding: 20px 40px;
}
</style>