<template>
  <div class="currencyConterBox">
    <el-row :gutter="24" class="">
      <el-col :span="8" class="flex flex_align_center flex_between w4">
        <span>收款时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          size="medium"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
        <el-button type="primary" @click="init(1)" size="medium" plain
          >查询</el-button
        >
        <el-button type="primary" @click="onExport(1)" size="medium" plain
          >导出报表</el-button
        >
        <el-button type="primary" @click="onExport(2)" size="medium" plain
          >导出财务报表</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="querylist"  border stripe class="table-mainbg mt20">
      <el-table-column
        prop="merchantNo"
        label="商户编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantName"
        label="商户姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carNo"
        label="车牌号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financeGatheringTime"
        label="收款时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="tradeShopName"
        label="成交门店"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mcTradePrice"
        label="车款"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="depositAmount"
        label="手续保证金"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="feeTurnoverAmount"
        label="库存周转费"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="feeTechnoAmount"
        label="技术服务费"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalPayAmount"
        label="应收金额"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="payTypeName" label="银行/现金" align="center" >
        <template slot-scope="scope">
          <div v-for="item in scope.row.merchantPayTypeList" :key="item.id" class="tableBord">{{item.payTypeName}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="realAmount" label="实收金额" align="center" >
        <template slot-scope="scope">
          <div v-for="item in scope.row.merchantPayTypeList" :key="item.id" class="tableBord">{{item.realAmount}}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>

export default {
  data() {
    return {
      querylist: [], //
      forms: {
        carNo: "",
        date: "",
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      testArr: [],
      testPosition: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        financeReceiveBeginTime: this.forms.date?`${this.forms.date[0]} 00:00:00`:'',
        financeReceiveEndTime: this.forms.date?`${this.forms.date[1]} 23:59:59`:'',
        carNo:this.forms.carNo
      };
      this.$api.postReportMerchantCarTradePay(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;

            // this.rowspan(this.testArr, this.testPosition, "carNo");
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize=e;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage=e;
      this.init();
    },
    onExport(state) {//1报表 2财务报表
      let query = {
          financeReceiveBeginTime: this.forms.date?`${this.forms.date[0]} 00:00:00`:'',
          financeReceiveEndTime: this.forms.date?`${this.forms.date[1]} 23:59:59`:'',
          carNo:this.forms.carNo
        };
      if(state==1){
        this.$api.exportReportMerchantCarTradePay(this.$$.delete_null_properties(query)).then(res=>{
          this.$$.postExcle(res)
        })
      }else{
        this.$api.exportReportMerchantCarTradePayFinance(this.$$.delete_null_properties(query)).then(res=>{
          this.$$.postExcle(res)
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
  .tableFaBox{
    padding:0 !important;
  }
  .tableBord:nth-of-type(1){
    border:none;
    // margin-top:-10px;
  }
  // .tableBord:last-child{
  //   // margin-bottom:-10px;
  // }
  .tableBord{
    border-top:1px solid #cccccc;
    margin:0 -10px;
    height: 45px;
    display:flex;
    justify-content: center;
    align-items: center;

  }
}
</style>