<template>
  <div class="accountChangeDetail">
    <div class="ckxTitle">基本信息</div>
    <el-row :gutter="20" class="mt20">
      <el-col :span="6">车牌号：{{form.carNo}}</el-col>
      <el-col :span="6">品牌车型：{{form.brandName}}&nbsp;{{form.modelName}}</el-col>
      <el-col :span="6">发动机号：{{form.engineNo}}</el-col>
      <el-col :span="6">颜色：{{form.carColor}}</el-col>
    </el-row>
    <el-row :gutter="20" class="mt10">
      <el-col :span="6">初次登记日期：{{form.registerDate}}</el-col>
      <el-col :span="6">年检有效期：{{form.checkExpire}}</el-col>
      <!-- <el-col :span="6">发动机号：12343jjkljkjioikjklffdf</el-col> -->
    </el-row>
    <el-row :gutter="20" class="mt10">
      <el-col :span="6">成交价：{{res.carTradedPrice}}元</el-col>
      <el-col :span="6">报价有效期：{{form.tradedPriceExpire}}</el-col>
      <el-col :span="6">成交类型：{{form.tradeTypeName}}</el-col>
    </el-row>
    <div class="ckxTitle mt20 mb10">车辆性质</div>
    <el-row :gutter="20">
      <el-col :span="6">车籍：{{form.carRegister}}</el-col>
      <el-col :span="6">公私性质：{{form.publicOrPrivateName}}</el-col>
      <el-col :span="6">进口国产：{{form.inOrOutName}}</el-col>
      <el-col :span="6">车牌颜色：{{form.plateColorName}}</el-col>
    </el-row>
    <div class="ckxTitle mt20 mb10">车主信息</div>
    <el-row :gutter="20">
      <el-col :span="6">出售委托人（甲方）：{{form.carCustomer.customerName}}</el-col>
      <el-col :span="6">联系电话：{{form.carCustomer.customerMobile}}</el-col>
      <el-col :span="6">车辆持有人性质：{{form.carCustomer.carOwnerNatureName}}</el-col>
    </el-row>
    <div class="flex flex_align_center flex_between mt20 mb10">
      <div class="ckxTitle">银行信息</div>
      <el-button type="primary" @click="onupdate" size="medium" plain>修改</el-button>
    </div>
    <el-row :gutter="20" class="mb10">
      <el-col :span="12">户名：{{bankform.accountName}}</el-col>
      <el-col :span="12">银行简称：{{bankform.depositBankShortName}}</el-col>
    </el-row>
    <el-row :gutter="20" class="mb10"><el-col :span="12">账号：{{bankform.account}}</el-col>
    <el-col :span="12">开户行：{{bankform.depositBank}}</el-col></el-row>
   
    <div class="ckxTitle mt20">银行变更历史</div>
    <el-table :data="carCustomerAccountList" border class="mt20">
      <el-table-column type="index" align="center" label="序号" width="60px"></el-table-column>
      <el-table-column prop="accountName" label="户名" align="center"></el-table-column>
      <el-table-column prop="account" label="账号" align="center"></el-table-column>
      <el-table-column prop="depositBankShortName" label="银行简称" align="center"></el-table-column>
      <el-table-column prop="depositBank" label="开户行" align="center"></el-table-column>
      <el-table-column prop="updateDate" label="修改时间" align="center"></el-table-column>
      <el-table-column prop="updateBy" label="操作人" align="center"></el-table-column>
    </el-table>
    <el-dialog
      title="修改银行账号"
      :visible.sync="dialogVisible"
      class="customDialog"
      width="600px">
      <el-form :model="formModal" ref="formModal" :rules="rules" label-width="150px">
        <el-form-item label="户名">
          <!-- <span class="setFontSize16">{{bankform.accountName}}</span> -->
        <el-input v-model="formModal.accountName" placeholder="请输入账号" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="formModal.account" placeholder="请输入账号" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="银行简称" prop="depositBankShortObj"  class="bankULBoxConter">
          <el-select v-model="formModal.depositBankShortObj" filterable placeholder="请选择" class="inputText bankInput" @input="onChangBank($event)" value-key="id">
            <el-option
              v-for="(item,index) in banklist"
              :key="index"
              :label="item.bankName"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户行" required class="cousItem">
          <el-cascader
              v-model="addressArr" ref="address"
              :options="optionsAddress" :props="propsAddress" @change="changeAddress" ></el-cascader>
      </el-form-item>
        <el-form-item label="" prop="depositBank" class="bankULBoxConter">
          <!-- <el-input v-model="formModal.address" placeholder="请输入地址" size="medium" class="lineInput"></el-input> -->
          <el-input v-model="formModal.depositBank" placeholder="请输入开户行名称" size="medium" @input="onInputBank"></el-input>
          <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
              <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item)">{{item.bankName}}</li>
            </ul>
        </el-form-item>
        <el-form-item label="修改账号的目的" prop="objective">
          <el-radio-group v-model="formModal.objective">
            <el-radio :label="1">付客户车款</el-radio>
            <el-radio :label="2">中间退费</el-radio>
            <el-radio :label="3">退客户押金</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onsave('formModal')">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      carCustomerAccountList: [],
      dialogVisible:false,
      formModal:{
        provinceName:'',
        cityName:'',
        areaName:''
      },
      rules:{
        'account': [{ required: true, message: '请输入账号', trigger: 'blur' }],
        'depositBankShortObj': [{ required: true, message: '请输入银行简称', trigger: 'blur' }],
        'provinceName': [{ required: true, message: '请选择省', trigger: 'change' }],
        'cityName': [{ required: true, message: '请选择市', trigger: 'change' }],
        'areaName': [{ required: true, message: '请选择区', trigger: 'change' }],
        'depositBank': [{ required: true, message: '请输入', trigger: 'blur' }],
        objective:[{ required: true, message: '请输入', trigger: 'blur' }],
      },
      form:{
        carCustomer:{}
      },
      res:{},
      bankform:{},//银行form
      addressArr:[],//地区
      optionsAddress:[],//默认值地区三级联动
      propsAddress:{
        label:'name',
        value:'id',
        children:'children'
      },
      selectArea:{},
      contractId:'',
      banklist:[],
      bankVis:false,//是否展示银行信息的搜索内容
      banklistSear:[],//搜索的银行数据
    }
  },
  created(){
    this.contractId=this.$route.query.id
    this.getCustomerDetail();//获取详情
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.optionsAddress=res;
      })
    }else{
      this.optionsAddress=this.$store.state.setting.cityList;
    }
    this.postbankHeadList();//获取银行信息
  },
  methods:{
    getCustomerDetail(){
      this.$api.getCustomerDetail(this.contractId).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=res.data;
            this.form=res.data.car;
            if(res.data.car.carCustomer.carCustomerAccount){
              this.bankform=res.data.car.carCustomer.carCustomerAccount;
              this.addressArr=[this.bankform.provinceId,this.bankform.cityId]
              this.selectArea={
                provinceId:this.bankform.provinceId,
                provinceName:this.bankform.provinceName,
                cityId:this.bankform.cityId,
                cityName:this.bankform.cityName,
                cityNo:this.bankform.cityNo,
                areaId:this.bankform.areaId,
                areaName:this.bankform.areaName
              }//三级联动
            }else{
              this.bankform={};
              this.selectArea={};
              this.addressArr=[];
            }
            this.getAccountHistory();//列表记录
          }
        }
      })
    },
    onupdate(){
      this.dialogVisible=!this.dialogVisible;
      this.formModal={
        provinceName:this.bankform.provinceName,
        cityName:this.bankform.cityName,
        areaName:this.bankform.areaName,
        cityId:this.bankform.cityId,
        provinceId:this.bankform.provinceId,
        areaId:this.bankform.areaId,
        address:this.bankform.address,
        account:this.bankform.account,
        accountName:this.bankform.accountName,
        depositBank:this.bankform.depositBank,
        depositBankShortCode:this.bankform.depositBankShortCode,
        depositBankShortName:this.bankform.depositBankShortName,
        depositBankShortObj:this.bankform.depositBankShortName,
      }
    },
    onsave(formdate){
      // console.log('========',this.bankform,this.selectArea)
      //校验
      let validType=false;
      this.$refs[formdate].validate(valid => {
        if (valid) {
          validType=true;
        }
      });
      if(!validType){
        return
      }
      switch(this.formModal.objective){
        case 1://付客户车款
          this.postCustomerTradeQuery()
          break;
        case 2://中间退费
          this.postMiddleQuery()
          break;
        case 3://退客户押金
          this.postCustomerDeposit()
          break;
      }
      
      
    },
    postCustomerCarTradePayAccount(orderNo){//修改客户成交/退车辆付款账号【只修改某一个合同相关的】
      let query={
        "account": this.formModal.account.trim(),//银行账户
        "accountName": this.formModal.accountName,//账户名
        "customerMobile": this.form.carCustomer.customerMobile,
        "customerName": this.form.carCustomer.customerName,
        "depositBank": this.formModal.depositBank,//开户行
        "depositBankShortName": this.formModal.depositBankShortName,//银行简称
        "provinceId": this.selectArea.provinceId,//省ID
        "provinceName": this.selectArea.provinceName,//省名称
        "cityId": this.selectArea.cityId,//市ID
        "cityName": this.selectArea.cityName,//市名称
      }
      this.$api.postCustomerCarTradePayAccount(orderNo,query).then(res=>{
        if(res.code===0){
          this.getCustomerDetail();//获取详情
          this.dialogVisible=!this.dialogVisible;
          this.$message.success(res.message)
        }
      })
    },
    postCustomerDepositPayAccount(orderNo){//修改客户押金退款账号【只修改某一个合同相关的】
      let query={
        "account": this.formModal.account.trim(),//银行账户
        "accountName": this.formModal.accountName,//账户名
        "customerMobile": this.form.carCustomer.customerMobile,
        "customerName": this.form.carCustomer.customerName,
        "depositBank": this.formModal.depositBank,//开户行
        "depositBankShortName": this.formModal.depositBankShortName,//银行简称
        "provinceId": this.selectArea.provinceId,//省ID
        "provinceName": this.selectArea.provinceName,//省名称
        "cityId": this.selectArea.cityId,//市ID
        "cityName": this.selectArea.cityName,//市名
      }
      this.$api.postCustomerDepositPayAccount(orderNo,query).then(res=>{
        if(res.code===0){
          this.getCustomerDetail();//获取详情
          this.dialogVisible=!this.dialogVisible;
          this.$message.success(res.message)
        }
      })
    },
    postMiddleFadeFeeAccount(orderNo){//修改中间退费付款账号【只修改某一个合同相关的】
      let query={
        "account": this.formModal.account.trim(),//银行账户
        "accountName": this.formModal.accountName,//账户名
        "customerMobile": this.form.carCustomer.customerMobile,
        "customerName": this.form.carCustomer.customerName,
        "depositBank": this.formModal.depositBank,//开户行
        "depositBankShortName": this.formModal.depositBankShortName,//银行简称
        "provinceId": this.selectArea.provinceId,//省ID
        "provinceName": this.selectArea.provinceName,//省名称
        "cityId": this.selectArea.cityId,//市ID
        "cityName": this.selectArea.cityName,//市名
      }
      this.$api.postMiddleFadeFeeAccount(orderNo,query).then(res=>{
        if(res.code===0){
          this.getCustomerDetail();//获取详情
          this.dialogVisible=!this.dialogVisible;
          this.$message.success(res.message)
        }
      })
    },
    updateAccount(orderNo){//修改银行信息
      // let query={
      //   "carCustomerId": this.form.carCustomer.id,//客户ID
      //   "id": this.bankform.id,
      //   "account": this.formModal.account.trim(),//银行账户
      //   "accountName": this.formModal.accountName,//账户名
      //   "depositBank": this.formModal.depositBank,//开户行
      //   "depositBankShortName": this.formModal.depositBankShortName,//银行简称
      //   depositBankShortCode:this.formModal.depositBankShortCode,//银行简称
      //   "provinceId": this.selectArea.provinceId,//省ID
      //   "provinceName": this.selectArea.provinceName,//省名称
      //   "cityId": this.selectArea.cityId,//市ID
      //   "cityName": this.selectArea.cityName,//市名称
      //   cityNo:this.selectArea.cityNo,
      //   // "areaId": this.selectArea.areaId,//区ID
      //   // "areaName": this.selectArea.areaName,//区名称
      //   "address": this.formModal.address//地址
      // }
      // this.$api.updateAccount(query).then((res)=>{
      //   if(res){
      //     if(res.code==0){
            switch(this.formModal.objective){
              case 1://付客户车款
                this.postCustomerCarTradePayAccount(orderNo)
                break;
              case 2://中间退费
                this.postMiddleFadeFeeAccount(orderNo)
                break;
              case 3://退客户押金
                this.postCustomerDepositPayAccount(orderNo)
                break;
            }
            // this.dialogVisible=!this.dialogVisible;
            // this.getCustomerDetail()
            // this.$message.success(res.message)
        //   }
        // }
      // })
    },
    changeAddress(state){
      let row=this.$refs.address.getCheckedNodes();
      if(row){
        let item=row[0]
        this.selectArea={
          provinceId:item.pathNodes[0].value,
          provinceName:item.pathNodes[0].label,
          cityId:item.pathNodes[1].value,
          cityNo:item.data.code,
          cityName:item.pathNodes[1].label,
          // areaId:item.value,
          // areaName:item.label
        }//三级联动
        if(state!=1){
          this.formModal.depositBank='';
          this.bankVis=false;
        }
      }
    },
    onBankTage(row){
      this.formModal.depositBank=row.bankName;
      this.bankVis=false;
    },
    onChangBank(e){
      this.formModal.depositBankShortName=e.bankName;
      this.formModal.depositBankShortCode=e.bankCode;
      this.formModal.depositBank='';
      this.$forceUpdate()
    },
    onInputBank(e){
      this.changeAddress(1)
      if(e){
        this.bankVis=true;
        this.postbankList(e)
      }else{
        this.bankVis=false;
      }
    },
    postbankHeadList(){//根据银行简称查询银行简称
      let query={}
      this.$api.postbankHeadList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.banklist=res.data;
        }
      })
    },
    getAccountHistory(){//根据客户ID查询客户银行账号历史记录
      let query={
        customerId:this.form.carCustomer.id
      }
      this.$api.getAccountHistory(query).then(res=>{
        if(res.code==0){
          this.carCustomerAccountList=res.data;
        }
      })
    },
    postbankList(e){//查询银行支行列表，不支持全部查询【不分页】
      let query={
        bankName:e,
        headBankCode:this.formModal.depositBankShortCode,
        cityNo:this.selectArea.cityNo,
      }
      this.$api.postbankList(query).then(res=>{
        if(res.code==0){
          this.banklistSear=res.data;
        }
      })
    },
    postCustomerTradeQuery(){//客户成交/退车辆付款条件查询
      let query={
        carNo:this.form.carNo
      }
      this.$api.postCustomerTradeQuery(query).then(res=>{
        if(res.code===0){
          if(res.data.records.length>0){
            let orderNo=res.data.records[0].orderNo;
            this.updateAccount(orderNo)
          }else{
            this.$message.warning('当前数据查询不到付客户车款的订单号！')
            return
          }
        }
      })
    },
    postMiddleQuery(){//中间退费条件查询
      let query={
        carNo:this.form.carNo
      }
      this.$api.postMiddleQuery(query).then(res=>{
        if(res.code===0){
          if(res.data.records.length>0){
            let orderNo=res.data.records[0].orderNo;
            this.updateAccount(orderNo)
          }else{
            this.$message.warning('当前数据查询不到中间退费的订单号！')
            return
          }
        }
      })
    },
    postCustomerDeposit(){//客户押金退款 条件查询
      let query={
        carNo:this.form.carNo
      }
      this.$api.postCustomerDeposit(query).then(res=>{
        if(res.code===0){
          if(res.data.records.length>0){
            let orderNo=res.data.records[0].orderNo;
            this.updateAccount(orderNo)
          }else{
            this.$message.warning('当前数据查询不到客户押金的订单号！')
            return
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.accountChangeDetail{
  padding: 20px 40px 40px;
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:1;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
}
#app .customDialog .cousItem{
  margin-bottom: 10px;
}
</style>