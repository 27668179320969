<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="待审核" name="first">
        <el-row :gutter="24" class="mt30 mb30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>询价类型</span>
            <el-select v-model="form.checkType" clearable size="medium">
              <el-option label="请选择" value=""></el-option>
              <el-option v-for="item in checkTypeList" :key="item.type"  :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>品牌车型</span>
            <el-input v-model="form.carType" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号码</span>
            <el-input v-model="form.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>检测师</span>
            <el-select v-model="form.checkerId" size="medium" clearable>
              <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2" >
            <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg">
          <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
          <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="isMortgage" label="是否抵押车" align="center">
            <template slot-scope="scope">{{scope.row.isMortgage===0?'否':scope.row.isMortgage===1?'是':scope.row.isMortgage===-1?'未识别':scope.row.isMortgage}}</template>
          </el-table-column>
          <el-table-column prop="dealShop" label="跟进门店" align="center"></el-table-column>
          <el-table-column prop="channelName" label="渠道" align="center"></el-table-column>
          <el-table-column prop="carYearAndType" label="年份/车型" align="center"></el-table-column>
          <el-table-column prop="productDate" label="生产日期" align="center"></el-table-column>
          <el-table-column prop="uploadTime" label="报告上传时间" align="center"></el-table-column>
          <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="140px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onsee(1,scope.row)"
                size="mini"
              >车型审核</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="审核驳回" name="second">
        <el-row :gutter="20" class="mt30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>询价类型</span>
            <el-select v-model="form1.checkType" clearable size="medium">
              <el-option label="请选择" value=""></el-option>
              <el-option v-for="item in checkTypeList" :key="item.type"  :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>品牌车型</span>
            <el-input v-model="form1.carType" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号码</span>
            <el-input v-model="form1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>检测师</span>
            <el-select v-model="form1.checkerId" size="medium">
              <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
        </el-row>
         <el-row :gutter="20" class="mt20 mb30">
           <el-col :span="8" class="flex flex_align_center flex_between w4">
            <span>驳回时间</span>
            <el-date-picker
              v-model="form1.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt30">
           <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
          <el-table-column prop="checkTypeName" label="询价类型" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="carYearAndType" label="年份/车型" align="center"></el-table-column>
          <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
          <el-table-column prop="uploadTime" label="报告上传时间" align="center"></el-table-column>
          <el-table-column prop="auditTime" label="驳回时间" align="center"></el-table-column>
          <el-table-column prop="auditUserName" label="审核人" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="180px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onTips(scope.row)"
                size="mini"
              >提醒</el-button>
              <el-button
                type="primary"
                @click="onsee(11,scope.row)"
                size="mini"
              >查看报告</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      form: {
        carNo: "",
        carType:'',
      },
      form1:{},
      querylist: [],
      querylist1:[],
      total: 0,
      currentPage: 1,
      pageSize:10,
      total1: 0,
      currentPage1: 1,
      pageSize1:10,
      checkTypeList:[],//询价类型
      userlist:[],//检测师列表
      timer:null,
    };
  },
  created(){
    if(this.$route.query.state){
      this.activeName=this.$route.query.state
    }
    this.init()
    this.getCheckBookCheckType()//获取检测类型集合
    this.queryUserListByRoleAndDepart()//获取检测师
  },
  mounted(){
    this.timer = setInterval(() => {
      this.$store.dispatch('user/setLoading',false); 
      this.getPageSize()
      this.init()
    }, 1000*3);
  },
  methods: {
    handleClick() {
      if(this.activeName !== 'first'){
        clearInterval(this.timer);
      }else{
        this.timer = setInterval(() => {
          this.$store.dispatch('user/setLoading',false); 
          this.getPageSize()
          this.init()
        }, 1000*3);
      }
      this.currentPage = 1;
      this.init()
    },
    getPageSize(){//在删除成功后调用此方法
      let totalPage = Math.ceil((this.total - 1) / this.pageSize);
      //计算出当前页码
      this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage;
      //如果页码为1的话，则无效
      this.currentPage = this.currentPage < 1 ? 1 : this.currentPage;
    },
    init(page) {
      let auditStatus=0;//车型审核状态 0-待审核 1-审核通过 2-审核驳回
      let query={
        "checkType": this.form.checkType,//询价类型
        "curPage":this.currentPage,
        "pageSize": this.pageSize,
        "carNo": this.form.carNo,//车牌号码
        "carType": this.form.carType,//车型
        "checkerId": this.form.checkerId,//分配的检测师userId
      }
      if(this.activeName=='second'){
        this.currentPage1=page?page:this.currentPage1;
        query.curPage=this.currentPage1;
        query.pageSize=this.pageSize1;
        auditStatus=2;
        query.carNo=this.form1.carNo;
        query.carType=this.form1.carType;
        query.checkType=this.form1.checkType;
        query.auditStartTime=this.form1.date?`${this.form1.date[0]} 00:00:00`:'';
        query.auditEndTime=this.form1.date?`${this.form1.date[1]} 23:59:59`:'';
        query.checkerId=this.form1.checkerId
      }else{
        this.currentPage=page?page:this.currentPage;
        query.curPage=this.currentPage;
      }
      this.$api.postAuditCarTypeList(auditStatus,this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            switch(this.activeName){
              case 'first':
                this.querylist = res.data.records;
                this.total = res.data.total;
                break;
              case 'second':
                this.querylist1 = res.data.records;
                this.total1 = res.data.total;
                break;
            }
          }else{
            switch(this.activeName){
              case 'first':
                this.querylist = [];
                this.total = 0;
                break;
              case 'second':
                this.querylist1 = [];
                this.total1 = 0;
                break;
            }
          }
        }
      })
    },
    onsee: function(state,row) {
      this.$router.push({
        path:'/testReviewer/auditReport',
        query:{
          state:state,
          id:row.id
        }
      })
    },
    handleSizeChange(e) {
      switch(this.activeName){
        case 'first':
          this.pageSize=e;
          this.currentPage=1;
          break;
        case 'second':
          this.pageSize1=e;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(e) {
      switch(this.activeName){
        case 'first':
          this.currentPage=e
          break;
        case 'second':
          this.currentPage1=e
          break;
      }
      this.init();
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onTips(row){//提醒检测师
      this.$api.postReminderChecker(row.id).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    getCheckBookCheckType(){//获取检测类型集合
      this.$api.getCheckBookCheckType({}).then(res=>{
        if(res.code==0){
          this.checkTypeList=res.data;
        }
      })
    },
    queryUserListByRoleAndDepart(){
      let query={
        roleName:'检测师'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    }
  },
  beforeDestroy() {
    this.$store.dispatch('user/setLoading',true); 
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .w300{
    width: 300px;
  }
}
</style>
