<template>
  <div class="pageContainer bgF5" ref="pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
    <!-- <kpi :kapilist="kapilist" ref="kpi"></kpi> -->
    <!-- <div class="ckxTitle mt30 mb20">数据看版</div>
    <div class="dataBoard">
      <h4>当月400帮卖数明细</h4>
      <el-table
        :data="dataNumberList" 
        border>
        <el-table-column
          :fixed="index===0?true:false||index===dataNameList.length-1?'right':false"
          :prop="item.state"
          :label="item.name" v-for="(item,index) in dataNameList" :key="index" align="center">
        </el-table-column>
      </el-table>
      <h4>检测转化率排名</h4>
      <el-table
        :data="dealNumberList"
        border>
        <el-table-column
          :prop="item.state"
          :label="item.name" v-for="item in dealNameList" :key="item.id" align="center">
        </el-table-column>
      </el-table>
    </div> -->
  </div>
</template>

<script>
import task from "@/components/task/task";
// import kpi from "@/components/kpi/kpi";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
      // kapilist:[{
      //   title:'​截至目前进站转化率',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',//计算了百分比之后的值
      //   color:'#0099FF',
      //   colorbg:'#CAE9FF'
      // },{
      //   title:'当月截止目前有效沟通数',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',
      //   color:'#1FC678',
      //   colorbg:'#A5E7CE'
      // }],//KPI指标
      // dealNameList:[],//成交转化率名称
      // dealNumberList:[],//值
      // dataNameList:[],//数据看板名称
      // dataNumberList:[],//值
    };
  },
  created() {
    // this.dealNameList.push({id:0,name:'排名',state:'ranking'})
    // for(let i=1;i<11;i++){
    //   let num=this.$$.noToChinese(i)
    //   let state='ab';
    //   if(num=='一十'){
    //     num='十'
    //   }
    //   if(i==1){
    //     state='ab'+i
    //   }
    //   let obj={
    //     id:i,
    //     name:`第${num}名`,
    //     state:state
    //   }
    //   this.dealNameList.push(obj)
    // }
    // this.dealNumberList.push({id:1,ranking:'检测转化率',ab1:'-'},{id:2,ranking:'检测量'},{id:3,ranking:'姓名'})
    // this.queryCheckTaskGroupByTaskStatus();
    //数据看板
    // this.dataNameList.push({id:0,name:'日期',state:'name'})
    // let arr=[]
    // for(let i=1; i < 31; i++){
    //   let obj={
    //     id:i,
    //     name:String(i),
    //     state:'name'+i
    //   }
    //   this.dataNameList.push(obj)
    //   //看板值
    //   // let obj1=`{name${i}:${i*10}}`
    //   let obj1=`{name${i}:''}`
    //   let objA=eval("("+obj1+")")
    //   arr.push(objA)
    // }
    // let newObj={}
    // arr.push({name:'基础客户数'})
    // for(let i=0;i < arr.length; i++){
    //   newObj = { ...newObj, ...arr[i] }
    // }
    // this.dataNumberList.push(newObj)
    // this.dataNameList.push({id:0,name:'合计',state:''})


    let newArr1=[
      {
        num: '',
        name: '快商通',
        star: 0,
        url:'/onlineService/listKST'
      },
      {
        num: '',
        name: '大众点评',
        star: 0,
        url:'/onlineService/listDZDP'
      }
    ]
    this.infolist=[{name:"",list:newArr1}];
    this.$nextTick(() => {
      this.pageHeight=this.$$.workbenchHeight('pageCombox')
    })
  },
  methods: {
    queryCheckTaskGroupByTaskStatus() {
      let query={
        taskStatus:'1,3,4,5,8'
      }
      //状态：1:待审核，2:审核通过，3审核驳回，4审核失败，5待签约，6已签约，7签约失败，8已解约，9佣金待审核，11佣金审核通过，12钱包提现待审核，13钱包提现审核成功，14钱包提现审核失败，15违约赔偿待审核，16违约赔偿审核成功
      this.$api.queryCheckTaskGroupByTaskStatus(query).then((res) => {
        if (res.code == 0) {
          let newArr1=[
            {
              num: '',
              name: '快商通',
              star: 0,
              url:'/onlineService/listKST'
            },
            {
              num: '',
              name: '大众点评',
              star: 0,
              url:'/onlineService/listDZDP'
            }
          ]
          this.infolist=[{name:"",list:newArr1}];
          // console.log(this.infolist,'-----555---')
          //  this.kapilist[0].targetVal=res.data.validQuotationTargetCount;//目标值
          // this.kapilist[0].actualVal=res.data.validQuotationCount;//实际值
          // this.kapilist[0].value=(res.data.validQuotationCount/res.data.validQuotationTargetCount*10000)/100;//计算了百分比之后的值
          // this.kapilist[1].targetVal=res.data.netGrowthTargetCount;//目标值
          // this.kapilist[1].actualVal=res.data.netGrowthCount;//实际值
          // this.kapilist[1].value=(Number(res.data.netGrowthCount)/Number(res.data.netGrowthTargetCount)*10000)/100;//计算了百分比之后的值
          // this.$refs.kpi.initChart('charid0',this.kapilist[0]);
          // this.$refs.kpi.initChart('charid1',this.kapilist[1]);
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
    // kpi
  }
};
</script>

<style lang="scss" scoped>
.dataBoard{
  background:#ffffff;
  text-align: center;
  padding: 20px;
  h4{
    font-size: 16px;
    padding: 20px 0;
  }
}

</style>
