<template>
  <div class="pageContainer">
    <!--车辆信息-->
    <div class="content_modal_textBox pb100">
      <div class="ckxTitle mb10">车辆信息</div>
      <table class="table-cusHeadbg table-custom">
        <thead>
          <tr>
            <td>车牌号码</td>
            <td>车型</td>
            <td>商户办证截至时间</td>
            <td>办证完成时间</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{form.carNo}}</td>
            <td>{{form.brandName}}&nbsp;{{form.modelName}}</td>
            <td>{{res.merchantDeadline}}</td>
            <td>{{res.processFinishDate}}</td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt30">商户信息</div>
      <table class="table-cusHeadbg table-custom">
        <thead>
          <tr>
            <td>商户ID</td>
            <td>商户姓名</td>
            <td>商户电话</td>
            <td>商户经营城市</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{carMerchant.merchantNo}}</td>
            <td>{{carMerchant.merchantName}}</td>
            <td>{{carMerchant.merchantMobile}}</td>
            <td>{{form.businessCityName}}</td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt30">过户凭证</div>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) in transferlist" :key="index" >
          <div class="photo_row_imgBox">
            <el-image :src="item.img"  :preview-src-list="[item.img]" v-if="state!=1">
              <div slot="error">暂无图片</div>
            </el-image>
            <div @click="uploadClick(item)" v-if="state==1">
            <el-upload
            class="photoupload"
            action
            accept="image/png, image/gif, image/jpeg,image/jpg"
            :show-file-list="false"
            :on-change="requestUpload"
            :file-list="fileList" 
            :auto-upload="false">
             <img :src="item.img" v-if="item.img"/>
              <i class="el-icon-plus" v-else></i>
            </el-upload>
            </div>
          </div>
          <p class="photo_row_textBox">{{item.title}}</p>
        </div>
      </div>
      <div class="ckxTitle mb10 mt30">特殊凭证</div>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) in speciallist" :key="index" >
          <div class="photo_row_imgBox">
            <el-image :src="item.img"  :preview-src-list="[item.img]" v-if="state!=1">
              <div slot="error">暂无图片</div>
            </el-image>
            <div @click="uploadClick(item)" v-if="state==1">
            <el-upload
            class="photoupload"
            action
            accept="image/png, image/gif, image/jpeg,image/jpg"
            :show-file-list="false"
            :on-change="requestUpload"
            :file-list="fileList" 
            :auto-upload="false">
             <img :src="item.img" v-if="item.img"/>
              <i class="el-icon-plus" v-else></i>
            </el-upload>
            </div>
          </div>
          <p class="photo_row_textBox">{{item.title}}</p>
        </div>
      </div>
      <div class="ckxTitle mb10 mt30">费用明细</div>
      <!--车辆手续补办列表-->
      <div class="mb10">补办费用</div>
      <el-table :data="materialProcedureList" border stripe class="mt20 table-mainbg">
      <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
      <el-table-column prop="name" align="center" label="补办项目"></el-table-column>
        <el-table-column prop="payFeeAmount" align="center" label="补办费用（元）"></el-table-column>
      </el-table>
      <!--办证公司结算费用列表-->
      <div class="mb10 mt30">办证费用</div>
       <el-table :data="clearFeeList" border stripe class="mt20 table-mainbg">
        <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
        <el-table-column prop="certType" align="center" label="办证类型"></el-table-column>
        <el-table-column prop="itemId" align="center" label="手续类型">
          <template slot-scope="scope">
            <el-select v-model="scope.row.itemName" @change="onSelect(scope.row)" v-if="state==1">
              <el-option v-for="item in standardList" :key="item.id" :label="item.name" :value="item.name" ></el-option>
            </el-select>
            <div v-else>{{scope.row.itemName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="feeAmount" align="center" label="补办费用（元）"></el-table-column>
      </el-table>
      <div class="flex mt20">
        <div>费用合计：￥{{clearAmount?clearAmount:'-'}}</div>
        <div class="ml40" v-if="[123,124].includes(res.taskComClearStatus)">{{res.taskComClearStatus==123?`审核驳回时间:${res.checkRejectTime}`:res.taskComClearStatus==124?`审核通过时间:${res.checkSuccessTime}`:''}}</div>
      </div>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" v-if="state==1">提交</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},//car表
      carMerchant:{},//商户表
      rules: {
        name: [{ required: true, message: '不能为空！', trigger: 'blur' }],
      },
      state:3,//1去结算，2重新结算，3查看
      fileList:[],
      transferlist:[{id:1,title:'产证1-2页'},{id:2,title:'产证3-4页'},{id:3,title:'产证5-6页'},{id:4,title:'过户发票'},{id:5,title:'行驶证照片或临牌照片'}],//过户凭证
      speciallist:[{id:1,title:'过户后产证1-2页'},{id:2,title:'过户后产证3-4页'},{id:3,title:'过户后产证5-6页'}],//特殊凭证
      projectlist:[{id:1,title:"过户"},{id:2,title:"转籍"},{id:5,title:"退牌上沪C"},{id:3,title:"退牌上沪B"},{id:4,title:"沪C转沪B"}],//办证项目
      uploadRow:{},
      clearFeeList:[],//办证公司结算费用列表
      materialProcedureList:[],//车辆手续补办列表
      res:{},
      standardList:[],//办证费用
      clearAmount:'',//总计
    };
  },
  created() {
    if(this.$route.query.id){
      this.taskId=this.$route.query.id;
      this.getCertificateTaskDetails()
    } 
    this.getCertKind()//查询办证项目类型状态
  },
  methods: {
    onSelect(row){
      this.postFeeStandardList(row.itemName,row.plateType,row.ownerType);//获取办证费用标准
    },
    uploadClick(row){
      this.uploadRow=row;
    },
    onSubmit(){
      let arr=[]
      let itemId=null
      if(this.clearFeeList) for(let i=0;i<this.clearFeeList.length;i++){
        let item=this.clearFeeList[i]
        arr.push(item.itemId)
        itemId=item.itemId;//永远只会有一条数据
      }
      let query={
        taskId:this.taskId,
        taskVoucherFeeCheckResult:'com_clear_apply',
        certFeeId:itemId,
      }
      this.$api.postTaskOperaVoucher(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.onReturn()
        }
      })
    },
    onReturn(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/certificationCompany/settlementManagement'
      })
    },
    requestUpload(file) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.taskId);
      obj.append("businessName", this.uploadRow.title);
      obj.append("businessType", 'certificationCompany');
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.getCertificateTaskDetails(); //获取详情
          this.$message.success(res.message);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    getCertificateTaskDetails() {//获取详情
      let query={
          "businessId": this.taskId,
          "businessType":"certificationCompany",
          // "businessName": ""
      }
      this.$api.getCertificateTaskDetails(this.taskId,query).then(res => {
        if (res) {
          if (res.code == 0) {
            // 121 待结算,122 审核中,123 审核驳回,124 审核通过,125 结算完成
            //1去结算，2重新结算，3查看
            switch(res.data.taskComClearStatus){
              case 121://待结算
              case 123://审核驳回
                this.state=1;
                break;
              case 122://审核中
                this.state=2;
                break;
            }
            this.res = res.data;
            this.form=res.data.car;
            if(res.data.car) if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;//商户表
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            //补办费用
            this.materialProcedureList=res.data.materialProcedureList;
            //办证公司结算费用列表
            let arrClear=[]
            let cont=0;
            for(let i=0;i<res.data.clearFeeList.length;i++){
              let iis=res.data.clearFeeList[i]
              if(iis.certComHide===0){
                arrClear.push(iis)
                cont+=iis.feeAmount;
              }
            }
            this.clearFeeList=arrClear;
            this.clearAmount=cont;
            //图片展示处理
            let fileList=res.data.fileList;
            if(fileList.length>0){
              for(let i=0;i<fileList.length;i++){
                let item=fileList[i].sysFileBusinessList[0];
                //过户凭证
                for(let j=0;j<this.transferlist.length;j++){
                  let ii=this.transferlist[j]
                  if(ii.title==item.businessName){
                    ii.img=fileList[i].fullFilePath;
                  }
                }
                //特殊凭证
                for(let i=0;i<this.speciallist.length;i++){
                  for(let j=0;j<res.data.fileList.length;j++){
                    let aitem=res.data.fileList[j]
                    if(this.speciallist[i].title===aitem.sysFileBusinessList[0].businessName){
                      this.speciallist[i].img=aitem.fullFilePath
                    }
                  }
                }
              }
            }
          }
        }
      });
    },
    getCertKind(){
      this.$api.getCertKind().then(res=>{
        if(res.code==0){
          this.standardList=res.data;
        }
      })
    },
    postFeeStandardList(itemName,plateType,ownerType){//办证费用标准
      let query={
        itemName:itemName,
        plateType:plateType,
        ownerType:ownerType,
        types:[1]
      }
      this.$api.postFeeStandardList(query).then(res=>{
        if(res.code==0){
          for(let i=0;i<this.clearFeeList.length;i++){
            let item=this.clearFeeList[i]
            if(item.itemName===itemName&&item.plateType===plateType&&item.ownerType===ownerType){
              if(res.data.length==0){
                item.feeAmount=null;
              }else{
                item.itemId=res.data[0].id;
                item.feeAmount=res.data[0].payFeeAmount;
              }
            }
          }
          this.onClearAmount()//计算总金额
        }
      })
    },
    onClearAmount(){//计算总金额
      let arr=[]
      let arr1=[]
      if(this.clearFeeList){
        arr=JSON.parse(JSON.stringify(this.clearFeeList))
      }
      if(this.materialProcedureList){
        arr1=JSON.parse(JSON.stringify(this.materialProcedureList))
      }
      let cont1=0;
      let cont2=0;
      for(let i=0;i<arr.length;i++){
        cont1+=arr[i].feeAmount;
      }
      for(let i=0;i<arr1.length;i++){
        cont2+=arr[i].feeAmount;
      }
      this.clearAmount=cont1+cont2;
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 20px 50px;
  .uploadCont{
    display:flex;
    flex-wrap:wrap;
  }
  .uploadBox{
    width: 334px;
    height: 195px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }
  .textFont{
    margin-top: 10px;
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    .photobox_row {
      width: 334px;
      margin: 0 16px 20px;
      .photoupload {
        width: 334px;
        height: 195px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 30px;
        }
      }
      .photo_row_imgBox {
        height: 195px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: 195px;
          // max-height: 100%;
        }
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          line-height: 30px;
        }
      }
      .photo_row_textBox {
        text-align: center;
        // display: flex;
        // justify-content: space-between;
        margin-top: 10px;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
}

</style>
