<template>
  <div class="pageContainer pageCombox bgF5" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
    };
  },
  created() {
    let newArr=[
      {
        num: '',
        name: '商户待预约',
        star: 0,
        url:'/salesAssistant/merchantReservation'
      },
      {
        num: '',
        name: '跟进中',
        star: 0,
        url:'/salesAssistant/merchantReservation',
        urlName:'merchantReservation',
        params:{state:'second'}
      },
      {
        num: '',
        name: '待签约',
        star: 0,
        url:'/salesAssistant/signContract'
      },
      {
        num: '',
        name: '签约中',
        star: 0,
        url:'/salesAssistant/signContract',
        urlName:'salesAssistantSignContract',
        params:{state:'third'}
      },
      {
        num: '',
        name: '待商户验车',
        star: 0,
        url:'/salesAssistant/vehicleInspection'
      },
      {
        num: '',
        name: '库存车待重拍',
        star: 0,
        url:'/salesAssistant/inventoryCar'
      }
    ];
    this.infolist=[{name:"业务任务",list:newArr}];
    this.init();
  },
  methods: {
    init() {
      this.$api.getDealBookMerchantCount().then((res) => {
        if (res.code == 0) {
          this.infolist[0].list[0].num=res.data.countNoBook;
          this.infolist[0].list[1].num=res.data.countFollowUpIng;
          // this.$nextTick(() => {
          //   this.pageHeight=this.$$.workbenchHeight('pageCombox')
          // })
        }
      });
      this.$api.getWorkbenchSalesCount().then((res) => {
        if (res.code == 0) {
          this.infolist[0].list[2].num=res.data.countMcWaitedSign;
          this.infolist[0].list[3].num=res.data.countMcSigning;
          this.infolist[0].list[4].num=res.data.countMerchantValidCarWaited;
          this.infolist[0].list[5].num=res.data.countStockCarReAuctionWaited;
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>
