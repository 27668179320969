<template>
  <div class="certificateManageBox">
     <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="办证中" name="first"></el-tab-pane>
      <el-tab-pane label="办证跟进" name="second"></el-tab-pane>
      <el-tab-pane label="办证完成" name="third"></el-tab-pane>
      <el-tab-pane label="退车车辆" name="fourth"></el-tab-pane>
    </el-tabs>
    <el-row :gutter="20" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>车牌号 :</span>
        <el-input clearable v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-search" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column prop="carNo" label="车牌号码" align="center" key="1"></el-table-column>
      <el-table-column prop="makerName" label="品牌车型" align="center" key="2">
        <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="certCompany" label="办证公司" align="center" key="3"></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center" key="4"></el-table-column>
      <el-table-column prop="merchantName" label="成交商户名称" align="center" key="5">
        <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="inviteSuccessTime" label="邀约办证日期" align="center" v-if="['second','first'].includes(activeName)" key="6"></el-table-column>
       <el-table-column prop="transferBeginTime" label="办证开始日期" align="center" v-if="['second'].includes(activeName)" key="7">
        <template slot-scope="scope">{{scope.row.transferBeginTime?scope.row.transferBeginTime.split(' ')[0]:scope.row.transferBeginTime}}</template>
      </el-table-column>
      <el-table-column prop="merchantDeadline" label="办证截止日期" align="center" v-if="['second','first'].includes(activeName)" key="8"></el-table-column>
      <el-table-column prop="transferFinishTime" label="过户完成时间" align="center" v-if="['third'].includes(activeName)" key="9"></el-table-column>
      <el-table-column prop="returnTime" label="操作退车时间" align="center" key="11" v-if="['fourth'].includes(activeName)"></el-table-column>
      <el-table-column prop="tradeShopName" label="成交门店" align="center" key="12"></el-table-column>
      <el-table-column prop="sellerAssistantName" label="销售助理" align="center" key="13"></el-table-column>
      <el-table-column prop="nextInviteTime" label="下次跟进时间" align="center" v-if="['second'].includes(activeName)" key="14">
      </el-table-column>
      <el-table-column prop="inviteOperator" label="跟进人" align="center" v-if="['second','first'].includes(activeName)" key="15"></el-table-column>
      <el-table-column label="操作" align="center" width="120" key="16">
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row,1)" size="mini" v-if="['first'].includes(activeName)">开始跟进</el-button>
          <el-button type="primary" @click="onapply(scope.row,2)" size="mini" v-if="['second'].includes(activeName)">跟进</el-button>
          <el-button type="primary" @click="onapply(scope.row,3)" size="mini" v-if="['third','fourth'].includes(activeName)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      isToday:'',
      params:{
        carNo:''
      }
    };
  },
  created() {
    if(this.$route.params.state) this.activeName=this.$route.params.state;
    if(this.$route.params.date) this.isToday=this.$route.params.date;
    
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo:this.params.carNo,
        taskType: 3,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        // taskProcessStatuses:[41],
      };
      switch(this.activeName){
        case 'first':
          query.taskProcessStatuses=[41];//待办证
          break;
        case 'second':
          query.taskProcessStatuses=[42];//跟进中
          break;
        case 'third':
          query.taskProcessStatuses=[43];//过户完成
          break;
        case 'fourth':
          query.carRejectStatus=1;//退车
          break;
      }
      
      if(this.isToday==='today'&&this.activeName==='second'){
        query.nextInviteBeginTime=`${this.$$.getTody()} 00:00:00`;
        query.nextInviteEndTime=`${this.$$.getTody()} 23:59:59`;
      }
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onapply(row) {
      let aurl = "/accreditationCommissioner/certificateFollowup";
      this.$router.push({
        path: aurl,
        query: {
          // state: state,
          id: row.id
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init()
    }
  }
};
</script>

<style lang="scss" scoped>
.certificateManageBox {
  padding: 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>