<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户姓名</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户账号</span>
        <el-input v-model="forms.merchantNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>注册城市</span>
        <el-select v-model="forms.registerCityName" size="medium" clearable>
          <el-option label="全部城市" value=""></el-option>
          <el-option v-for="item in citylist" :key="item.id" :label="item.name" :value="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>退款状态</span>
        <el-select v-model="forms.refundStatuses" size="medium" clearable multiple>
           <!-- <el-option label="全部" value="" ></el-option> -->
           <el-option v-for="item in paymentStatuslist" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>退款类型</span>
        <el-select v-model="forms.refundType" size="medium" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="预收车款" value="1"></el-option>
          <el-option label="保证金" value="2"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt10 flex flex_end">
      <el-button type="primary" @click="init(1)" size="medium" class="" plain>搜索</el-button>
      <el-button type="primary" @click="onAdd" size="medium" class="mr10" plain>新增商户退款</el-button>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column prop="merchantName" label="商户姓名" align="center" ></el-table-column>
      <el-table-column prop="merchantNo" label="商户账号" align="center" ></el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center" ></el-table-column>
      <el-table-column prop="refundType" label="退款类型" align="center" >
        <template slot-scope="scope">{{scope.row.refundType==2?'保证金':'预收车款'}}</template>
      </el-table-column>
      <el-table-column prop="payerName" label="付款人信息" align="center" ></el-table-column>
      <el-table-column prop="refundAmount" label="退款金额" align="center" ></el-table-column>
      <el-table-column prop="refundKind" label="退款方式" align="center" >
        <template slot-scope="scope">{{scope.row.refundKind==2?'同城同行':scope.row.refundKind==3?'异地':scope.row.refundKind==4?'同城对公':scope.row.refundKind==5?'上海银行浦东分行(4034)':'超级网银'}}</template>
      </el-table-column>
      <el-table-column prop="refundStatus" label="退款状态" align="center" >
        <template slot-scope="scope">{{scope.row.refundStatus==32?'付款中':scope.row.refundStatus==33?'付款成功':scope.row.refundStatus==34?'付款失败':scope.row.refundStatus==31?'待付款':scope.row.refundStatus}}</template>
      </el-table-column>
      <el-table-column prop="applyTime" label="申请时间" align="center" ></el-table-column>
      <el-table-column prop="refundTime" label="退款时间" align="center" ></el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onsee(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
    <el-dialog :title="modalState==1?'新增商户退款':'查看商户退款'" :visible.sync="dialogVisible" :width="modalState==1?'800px':'1110px'" class="customDialog">
      <el-form ref="itemRow" :model="itemRow" label-width="120px" :rules="rules" :inline="true" v-if="modalState==1" :hide-required-asterisk="true">
        <el-form-item label="商户账号:" prop="merchantNo">
          <el-input v-model="itemRow.merchantNo" class="w250" size="medium" @blur="inputBlur(itemRow.merchantNo)"></el-input>
        </el-form-item>
        <el-form-item label="申请金额:" prop="refundAmount">
          <el-input v-model="itemRow.refundAmount" class="w250" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="申请退款时间:" prop="applyTime">
          <el-date-picker
            v-model="itemRow.applyTime"
            type="datetime" size="medium"
            placeholder="选择日期" class="w250"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商户姓名:">
          <p class="w250">{{res.merchantName}}</p>
        </el-form-item>
        <el-form-item label="付款人姓名:">
          <el-input v-model="itemRow.payerName" class="w250" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="退款类型:">
          <p class="w250">{{res.refundType==2?'保证金':'预收车款'}}</p>
        </el-form-item>
        <el-form-item label="银行账号:">
          <p class="w250">{{res.account}}</p>
        </el-form-item>
        <el-form-item label="开户行简称:">
          <p class="w250">{{res.depositBankShortName}}</p>
        </el-form-item>
        <el-form-item label="开户行全称:">
          <p class="w250">{{res.depositBank}}</p>
        </el-form-item>
        <el-form-item label="退款方式:">
          <p class="w250">网银转账</p>
        </el-form-item>
      </el-form>
      <table class="table-oddwholebg table-custom" v-show="modalState==2">
        <tbody class="modelTbody">
          <tr>
            <td>商户账号</td>
            <td><el-input v-model="form.merchantNo"  size="medium" :disabled="disabledModel"></el-input></td>
            <td>商户姓名</td>
            <td><el-input v-model="form.merchantName"  size="medium" :disabled="disabledModel"></el-input></td>
            <td>付款人</td>
            <td><el-input v-model="form.merchantAccount.accountName"  size="medium" :disabled="disabledModel"></el-input></td>
          </tr>
          <tr>
            <td>退款类型</td>
            <td>{{form.refundType==1?'预付车款':form.refundType==2?'保证金':form.refundType}}</td>
            <td>退款金额（元）</td>
            <td><el-input v-model="form.refundAmount"  size="medium" :disabled="disabledModel"></el-input></td>
            <td>申请退款时间</td>
            <td><el-date-picker
                v-model="form.applyTime" :disabled="disabledModel"
                type="datetime" 
                placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </td>
          </tr>
          <tr>
            <td>开户行简称</td>
            <td><el-input v-model="form.merchantAccount.depositBankShortName"  size="medium" :disabled="disabledModel"></el-input></td>
            <td>开户行全称</td>
            <td><el-input v-model="form.merchantAccount.depositBank"  size="medium" :disabled="disabledModel"></el-input></td>
            <td>银行账号</td>
            <td><el-input v-model="form.merchantAccount.account"  size="medium" :disabled="disabledModel"></el-input></td>
          </tr>
          <tr>
            <td>退款方式</td>
            <td>{{form.refundKind==1?'超级网银':form.refundKind==2?'同城同行':form.refundKind==3?'异地':form.refundKind==4?'同城对公':form.refundKind==5?'上海银行浦东分行(4034)':form.refundKind}}</td>
            <td>退款状态</td>
            <td>{{form.refundStatus==31?'待付款':form.refundStatus==32?'付款中':form.refundStatus==33?'付款成功':form.refundStatus==34?'付款失败':form.refundStatus}}</td>
            <td>申请退回理由</td>
            <td>{{form.financeRejectReason}}</td>
          </tr>
          <tr>
            <td>申请人</td>
            <td>{{form.applyName}}</td>
            <td>退款人</td>
            <td>{{form.payerName}}</td>
            <td>退款时间</td>
            <td>{{form.refundTime}}</td>
          </tr>
        </tbody>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" v-if="modalState===1">确认</el-button>
        <el-button type="primary" @click="onSubmit" v-if="form.refundStatus===34&&modalState===2">修改并申请付款</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        refundStatuses:[31,32]
      },
      form:{
        merchantAccount:{}
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      citylist:[],//城市列表
      dialogVisible: false,
      modalState:1,//1新增 2查看
      itemRow: {
        merchantNo:'',
        refundAmount:'',
        applyTime:'',
        payerName:''
      },//可输入
      disabledModel:false,//是否可修改
      res:{},//获取商户详情返回值
      rules:{
        merchantNo:[{ required: true, message: '不能为空', trigger: 'blur' },],
        refundAmount:[{ required: true, message: '不能为空', trigger: 'blur' },],
        applyTime:[{ required: true, message: '不能为空', trigger: 'blur' },]
      },
      paymentStatuslist:[],//付款状态（退款状态）
    };
  },
  created() {
    this.paymentStatuslist=this.$store.state.setting.paymentStatuslist;
    this.getRegionByConditions()//获取城市列表
    this.init(1);
  },
  methods: {
    init(page,state) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        "curPage": this.currentPage,
        "merchantName": this.forms.merchantName,//商户名称
        "pageSize": this.pageSize,
        "merchantNo": this.forms.merchantNo,//商户账号
        "registerCityName": this.forms.registerCityName,//注册城市名称
        "refundTypes": this.forms.refundType?[this.forms.refundType]:'',//退款类型：1预收车款，2保证金，默认1
        // "refundStatuses": this.forms.refundStatuses//退款状态：31待付款，32付款中，33付款成功，34付款失败
        "refundStatuses": this.forms.refundStatuses?this.forms.refundStatuses:[31,32,33,34]//退款状态：31待付款，32付款中，33付款成功，34付款失败
      }
      this.$api.postRefundList(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize=e;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage=e;
      this.init();
    },
    onAdd(){
      this.dialogVisible=!this.dialogVisible;
      this.modalState=1;
      this.res={}
      this.form={
        merchantAccount:{}
      }
      this.itemRow= {
        merchantNo:'',
        refundAmount:'',
        applyTime:'',
        payerName:''
      };
      this.$nextTick(()=>{
        this.$refs['itemRow'].resetFields();
      })
    },
    onSubmit(){
      if(this.modalState===2){
        this.postRefundUpdate()
      }else{
        this.$refs['itemRow'].validate((valid) => {
          if (valid) {
            this.postRefundAdd()
          }
        });
      }
    },
    onsee(row){
      // this.itemRow=JSON.parse(JSON.stringify(row));
      // this.form=JSON.parse(JSON.stringify(row));
      // this.form.merchantAccount=this.form.merchantAccount?this.form.merchantAccount:{}
      
      this.modalState=2;
      this.$api.getRefundDetailsByRefundIdMerchant(row.id).then(res=>{
        if(res.code===0){
          this.dialogVisible=!this.dialogVisible;
          this.itemRow=res.data;
          this.form=res.data;
          this.form.merchantAccount=this.form.merchantAccount?this.form.merchantAccount:{};
          if(this.form.refundStatus===34){
            this.disabledModel=false;
          }else{
            this.disabledModel=true;
          }
        }
      })
    },
    getRegionByConditions(){//获取城市列表
      let query={
        level:2
      }
      this.$api.getRegionByConditions(query).then(res=>{
        if(res.code==0){
          this.citylist=res.data;
        }
      })
    },
    inputBlur(e){//输入商户账号查询数据
      if(e){
        this.getAccountDetailsByMerchantNo(e)
      }
    },
    postRefundUpdate(){//商户退款 修改
      // console.log(this.form,'---------5555---')
      let query={
        "merchantId": this.form.merchantId,
        "merchantName": this.form.merchantName,
        "merchantNo": this.form.merchantNo,
        // "registerCityName": "string",
        "refundType": this.form.refundType,
        "payerName": this.form.payerName,
        "refundAmount": this.form.refundAmount,
        "applyTime":this.form.applyTime,//申请退款时间
        depositBankShortName:this.form.merchantAccount.depositBankShortName,
        depositBank:this.form.merchantAccount.depositBank,
        account:this.form.merchantAccount.account,
        // "financeRejectReason": "商户填错了！"
      }
      this.$api.postRefundUpdate(this.form.id,query).then(res=>{
        if(res.code==0){
          this.dialogVisible=false;
          this.init();
          this.$message.success(res.message)
        }
      })
    },
    postRefundAdd(){//商户退款 新增
      let query={
        "merchantId": this.res.merchantId,//商户ID
        "merchantName": this.res.merchantName,//商户名称
        "merchantNo": this.itemRow.merchantNo,
        "refundType": 1,//退款类型：1预收车款，2保证金，默认1
        "payerName": this.itemRow.payerName,
        "refundAmount": this.itemRow.refundAmount,
        applyTime:this.itemRow.applyTime
      }
      this.$api.postRefundAdd(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.dialogVisible=!this.dialogVisible;
          this.currentPage=1;
          this.init();
          this.$message.success(res.message)
        }
      })
    },
    getAccountDetailsByMerchantNo(merchantNo){//根据商户账号查询商户银行账号
      this.$api.getAccountDetailsByMerchantNo(merchantNo).then(res=>{
        if(res.code==0){
          this.res=res.data;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.pageContainer {
  // padding: 20px 40px;
  .rowbox {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    .line {
      width: 15px;
      height: 1px;
      background-color: #999999;
      margin: 0 5px;
    }
  }
  .modelTbody tr td:nth-child(2n+1){
    width:120px;
  }
}
</style>