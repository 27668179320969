import Vue from 'vue';
import ElementUI,{Message}  from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.css';
import './assets/css/style.css';
import $$ from './utils';
import $api from './api';
import $datadic from './utils/datadic';
import { hasPermission, hasNoPermission, hasAnyPermission } from '@/utils/permission'
import 'default-passive-events'
// import Print from 'vue-print-nb'

Vue.use(ElementUI);
[hasPermission, hasNoPermission, hasAnyPermission].map((plugin) => {
  Vue.use(plugin)
})
// Vue.use(Print);

Vue.prototype.bus = new Vue();
Vue.prototype.$$ = $$;
Vue.prototype.$api = $api;
Vue.prototype.$datadic = $datadic;
Vue.prototype.$message=Message;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
