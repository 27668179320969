<template>
  <div class="pageContainer bgF5" ref="pageCombox" :style="{'height':pageHeight}">
    <div class="flex flex_between flex_align_center">
      <div class="title_top_nameBox">付款管理</div>
      <el-button type="primary" plain size="mini" @click="onRouer">报表查询</el-button>
    </div>
     <div
      :class="[{'flex':true,'top':true,'flex_wrap':true},showTips?'showTipsCon':'closeTipsCon']" class="conterBox"
    >
      <div class="businessCon" v-for="item in infolist" :key="item.id">
        <ul class="business flex flex_wrap" >
          <li class="bTitle">{{item.name}}</li>
          <li class="bLi" v-for="v in item.list" :key="v.id" @click="tagRouter(v)">
           
            <div
              :class="['bMain', 'flex', 'flex_column', 'flex_center', 'flex_align_center', {'star1':v.star===1,'star2':v.star===2}]"
            >
              <span class="num">{{v.num}}</span>
              <span>{{v.name}}</span>
            </div>
          </li>
        </ul>
      </div>
      </div>
      <div class="title_top_nameBox mt30">收款管理</div>
     <div
      :class="[{'flex':true,'top':true,'flex_wrap':true},showTips?'showTipsCon':'closeTipsCon']" class="conterBox"
    >
      <div class="businessCon" v-for="item in infolist1" :key="item.id">
        <ul class="business flex flex_wrap" >
          <li class="bTitle">{{item.name}}</li>
          <li class="bLi" v-for="v in item.list" :key="v.id" @click="tagRouter(v)">
           
            <div
              :class="['bMain', 'flex', 'flex_column', 'flex_center', 'flex_align_center', {'star1':v.star===1,'star2':v.star===2}]"
            >
              <span class="num">{{v.num}}</span>
              <span>{{v.name}}</span>
            </div>
          </li>
        </ul>
      </div>
      </div>

    <el-row :gutter="24" class="mt30">
      <el-col :span="12">
        <task :infolist="infolist4" title="归档&OK卡管理" :showTipModel="false"></task>
      </el-col>
      <el-col :span="12">
        <task :infolist="infolist5" title="渠道业务账号审核" :showTipModel="false"></task>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  components: {
    task, //业务
  },
  data() {
    return {
      infolist:[],//付款管理
      infolist1:[],//收款管理
      infolist4:[],//
      infolist5:[],//
      pageHeight:'',
      showTips: false,
    };
  },
  created() {
    this.infolist5=[{name:"",list:[{
      num: '',
      name: '账号信息审核',
      star: 0,
      url:'/finance/accountReview'
    }]}];
    this.init();
  },
  methods: {
    onRouer(){
      this.$router.push({
        path:'/finance/reportIndex'
      })
    },
    init() {
      this.$api.getFinanceCountWorkBench().then((res) => {
        if (res.code == 0) {
          let newArr1=[//客户
            {
              num: res.data.countWaitedCustomerCarTradePay,
              name: '付车款',
              star: 0,
              url:'/finance/customerCarPayment',
              urlName:'customerCarPayment',
              params:{state:31}
            },//state=31为财务待付款状态
            {
              num: res.data.countWaitedCustomerDepositPay,
              name: '退押金',
              star: 0,
              url:'/finance/customerDeposit',
              urlName:'customerDeposit',
              params:{state:31}
            },
            {
              num: res.data.countWaitedMiddleFadeFeePay,
              name: '中间退费',
              star: 0,
              url:'/finance/intermediateRefund',
              urlName:'intermediateRefund',
              params:{state:31}
            },
            {
              num: res.data.countWaitedIntentionMoneyPay,
              name: '付意向金',
              star: 1,
              url:'/finance/intentionPayment',
              urlName:'intentionPayment',
              params:{state:31}
            }
          ]
          let newArr2=[//商户
            {
              num: res.data.countWaitedMerchantRefundPay,
              name: '退预付款',
              star: 0,
              url:'/finance/merchant/advanceCharge',
              urlName:'advanceCharge',
              params:{state:31}
            },
            {
              num: res.data.countWaitedMerchantDepositPay,
              name: '退押金',
              star: 0,
              url:'/finance/merchant/merchantDeposit',
              urlName:'merchantDeposit',
              params:{state:31}
            },
            {
              // num: res.data.countWaitedMerchantCarTradePay,
              num:'',
              name: '退车付款',
              star: 0,
              url:'/finance/merchant/returnCar',
              urlName:'returnCar',
              params:{state:31}
            },
            {
              num: res.data.countWaitedMerchantRefundBzPay,
              name: '退保证金',
              star: 0,
              url:'/finance/merchant/returnBond',
              urlName:'returnBond',
              params:{state:31}
            }
          ]
          let newArr3=[
            {
              num: res.data.countWaitedChannelWalletPay,
              name: '4S店钱包提现',
              star: 0,
              url:'/finance/walletWithdrawal',
              urlName:'walletWithdrawal',
              params:{state:31}
            }
          ]
          let newArr4=[
            {
              num: res.data.countWaitedBreakRulePay,
              name: '违章处理费',
              star: 0,
              url:'/finance/illegalPayment',
              urlName:'illegalPayment',
              params:{state:31}
            }
          ]
          this.infolist=[{name:"客户",list:newArr1},{name:"商户",list:newArr2},{name:"4S店",list:newArr3},{name:"办证公司",list:newArr4}];
          let infoArr1=[//4S店
            {
              num: res.data.countWaitedMerchantReceiptCarTradePay,
              name: '收车款',
              star: 0,
              url:'/finance/merchant/carCollection',
              urlName:'carCollection',
              params:{state:81}
            },
            {
              num: res.data.countWaitedMerchantReceiptRefundBzPay,
              name: '收保证金',
              star: 1,
              url:'/finance/merchant/marginManagement',
              urlName:'marginManagement',
              params:{state:91}
            }
          ]
          let infoArr2=[//客户
            {
              // num: res.data.countWaitedCustomerReceiptCarTradePay,
              num:'',
              name: '退车收车款',
              star: 0,
              url:'/finance/customerReturnCar'
            },
            {
              num: res.data.countWaitedCustomerReceiptIntentionMoneyPay,
              name: '意向金收款',
              star: 1,
              url:'/finance/intentionCollection',
              urlName:'intentionCollection',
              params:{state:91}
            },
            {
              num: res.data.countWaitedBadIntentionMoney,
              name: '意向金坏账处理',//产品路由跳转有误
              star: 1,
              url:'/finance/intentionBadDebt',
              urlName:'intentionBadDebt',
              params:{state:'待处理'}
            }
          ]
          this.infolist1=[{name:"商户",list:infoArr1},{name:"客户",list:infoArr2}];
          let infoArr3=[
            {
              num: '',
              name: '归档管理',
              star: 0,
              url:'/finance/customerContractFiling'
            },
            {
              num: '',
              name: 'OK卡管理',
              star: 0,
              url:'/finance/okCard'
            }
          ]
          this.infolist4=[{name:"",list:infoArr3}];
        }
      });
      
          // this.$nextTick(() => {
          //   this.pageHeight=this.$$.workbenchHeight('pageCombox')
          // })
    },
    tagRouter(row){
      let query={}
      if(row.params){
        query.name=row.urlName;
        query.params=row.params;
      }else{
        query.path=row.url;
      }
      this.$router.push(query)
    }
  },
};
</script>

<style lang="scss" scoped>
.pageContainer{
  .conterBox{
    background: #fff;
    padding-top:30px;
    margin-top:20px;
  }
  .businessCon {
    width:50%;
    &:nth-of-type(2) .bTitle{
      background: linear-gradient(315deg, #ffc600 0%, #fe9400 100%);
    }
    &:nth-child(3) .bTitle {
      background: linear-gradient(315deg, #35e0a4 0%, #18be6b 100%);
    }
    &:nth-child(4) .bTitle {
      background: linear-gradient(315deg, #D4CFF6 0%, #AEA7F4 100%);
    }
    &:nth-child(2) .bLi .bMain {
      color: #fe9600;
      box-shadow: 0px 0px 10px 3px rgba(254, 150, 0, 0.2);
    }
    &:nth-child(3) .bLi .bMain {
      color: #19bf6d;
      box-shadow: 0px 0px 10px 3px rgba(28, 195, 115, 0.2);
    }
    &:nth-child(4) .bLi .bMain {
      color: #AEA7F4;
      box-shadow: 0px 0px 10px 3px rgba(173, 166, 243, 0.2);
    }
  }
  .business {
    padding: 0 10px 0;
    .bTitle {
      width: 100%;
      padding-left: 10px;
      background: linear-gradient(315deg, #74cdff 0%, #409eff 100%);
      color: #fff;
      font-size: 20px;
      height: 50px;
      line-height: 50px;
      margin: 0 1.6%;
      text-align: center;
    }
    .bLi {
      width: 21.8%;
      cursor: pointer;
      margin: 0 1.6% 35px;
      .bMain {
        color: #0099ff;
        height: 130px;
        font-size: 24px;
        box-shadow: 0px 0px 10px 3px rgba(0, 196, 255, 0.2);
        .num {
          font-size: 40px;
        }
      }
      .star1 {
        background: url(../../assets/images/common/starBg.png) no-repeat;
        background-size: 74px 72px;
        background-position: center;
      }
      .star2 {
        background: url(../../assets/images/common/starBg2.png) no-repeat;
        background-size: 168px 72px;
        background-position: center;
      }
    }
  }
  .tips {
    margin-right: -40px;
    margin-top: 30px;
    .closeTips {
      background: #43a0ff;
      color: #fff;
      margin-left: 70px;
      position: relative;
      height: 100%;
      width: 10px;
      cursor: pointer;
      .txt {
        font-size: 24px;
        width: 40px;
        height: 152px;
        padding-top: 9px;
        text-align: center;
        line-height: 32px;
        position: absolute;
        right: 0;
        top: 0;
        background: #43a0ff;
      }
    }
    .tipsTable {
      width: 600px;
      margin-left: 18px;
      .tableHeader {
        height: 45px;
        background: #0099ff;
        color: #fff;
        padding: 0 10px;
        .closeIcon {
          padding: 10px;
          margin-right: -10px;
          font-size: 20px;
        }
      }
    }
  }
}

</style>
