<template>
  <div class="pageContainer">
    <el-table :data="querylist" border stripe class="table-mainbg">
      <el-table-column prop="id" label="商户ID" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center">
        <template slot-scope="scope">{{scope.row.merchantType==1?scope.row.authCompanyName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="merchantNo" label="商户账号" align="center"></el-table-column>
      <el-table-column prop="merchantMobile" label="注册手机号" align="center"></el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center"></el-table-column>
      <el-table-column prop="registerDate" label="注册日期" align="center"></el-table-column>
      <el-table-column prop="merchantType" label="商户类别" align="center">
        <template slot-scope="scope">{{scope.row.merchantType==0?'个人':scope.row.merchantType==1?'企业':''}}</template>
      </el-table-column>
      <el-table-column prop="authSubmitDate" label="提交时间" align="center"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onexamine(scope.row)"
            size="mini"
            class="group-custom"
          >认证审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
      }
      this.$api.postAuthList(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
              this.querylist=[];
              this.total=0;
          }
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    onexamine(row) {
      //确认modal
      this.$router.push({//接收状态值，0个人(可操作)，1企业(可操作),2个人查看，3企业查看
        path: "/merchant/authenticationDetails",
        query:{//merchantType商户类型 0-个人 1-企业
          // state:row.merchantType?1:0,
          id:row.id
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage=data;
      this.init()
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
