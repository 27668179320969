<template>
  <div class="pageContainer">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>客户编号: </span>
        <el-input clearable v-model="params.customerNo" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
     <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码: </span>
        <el-input clearable v-model="params.carNo" placeholder="请输入" type="text" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>业务状态:</span>
        <el-select clearable v-model="params.checkStatus" placeholder="请选择" size="medium">
          <el-option v-for="item in $store.state.setting.checkStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w4">
        <span>预约时间：</span>
        <el-date-picker v-model="params.bookTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" plain size="medium" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="cList" border stripe class="table-mainbg mt20" >
      <el-table-column prop="customerNo" label="客户编号" align="center"/>
      <el-table-column prop="customerName" label="客户名称" align="center" width="115px"/>
      <el-table-column prop="carNo" label="车牌号码" width="100" align="center"/>
      <el-table-column prop="bookShop" label="预约门店" align="center" width="115px"/>
      <el-table-column prop="submitTime" label="提交时间" align="center"/>
      <el-table-column prop="bookTime" label="预约检测时间" align="center" width="180"/>
      <el-table-column prop="checkStatus" label="检测状态" align="center" :formatter="formatCheckStatus"/>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo: '',
        bookTime: [],
        customerNo: '',
        checkStatus: '',
      },
      cList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getData()
    },
    getData() {
      let params = {
        curPage: this.page_index,
        pageSize: this.page_size,
        carNo: this.params.carNo,
        customerNo: this.params.customerNo,
        checkStatus: this.params.checkStatus,
        // bookStatus: 1,
        bookStartTime: '',
        bookEndTime: ''
      }

      if(this.params.bookTime && this.params.bookTime.length > 0){
        params.bookStartTime = this.params.bookTime[0];
        params.bookEndTime = this.params.bookTime[1];
      }else{
        params.bookStartTime = '';
        params.bookEndTime = '';
      }

      this.$api.checkBookQueryBookSuccess(this.$$.delete_null_properties(params)).then(res => {
        if(res.code == 0){
          this.page_total = res.data.total;
          this.cList = res.data.records;
        }
      })
    },
    search() {
      this.page_index = 1;
      this.getData();
    },
    goCustomer(followUpId) {
      if(followUpId){
        this.$router.push({path: '/testService/customer', query: {followUpInfoId: followUpId}})
      }else{
        this.$message.warning('跟进业务ID缺失')
      }
    },
    formatCheckStatus(row) {
      return this.$$.arrayLookup(this.$store.state.setting.checkStatusList, row.checkStatus)
    }
  }
}
</script>

<style scoped lang="scss">
// .successTask{
//   padding: 20px 40px;
// }
</style>