<template>
  <!-- 员工列表 -->
  <div class="warehouse">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>仓库列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="mt10 mb20">
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="onAdd" plain>创建仓库</el-button>
    </div>
    <el-table :data="warehouseList" border stripe class="table-mainbg">
      <el-table-column prop="code" label="编号" align="center"></el-table-column>
      <el-table-column prop="name" label="仓库名称" align="center"></el-table-column>
      <!-- <el-table-column prop="objAreaTypeName" label="仓库类型" align="center"></el-table-column> -->
      <el-table-column prop="createDate" label="创建时间" width="185px" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-switch :value="!Boolean(scope.row.isEnable)" @change="changeStatus(scope.row)"></el-switch>
          <el-button type="primary" size="mini" class="ml20" @click="onEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="onState==1?'创建仓库':'编辑仓库'" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="formRules" label-width="100px" ref="form">
        <el-form-item label="所在城市" prop="selectArea">
          <el-cascader
              v-model="form.selectArea" ref="address"
              :options="optionsAddress" :props="propsAddress" @change="changeAddress" clearable></el-cascader>
          <!-- <el-select v-model="form.cityId" placeholder="请选择城市" size="medium">
            <el-option v-for="item in areaList" :key="item.areaNo" :label="item.areaName" :value="item.areaNo"></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="仓库名称" prop="name">
          <el-input v-model="form.name" autocomplete="off" style="width:225px;"></el-input>
        </el-form-item>
        <!-- <el-form-item label="仓库类型" prop="objAreaType">
          <el-select v-model="form.objAreaType" placeholder="请选择">
            <el-option label="不可添加货区" :value="41"></el-option>
            <el-option label="可添加货区" :value="42"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="所属门店" prop="shopId">
          <el-select v-model="form.shopId" placeholder="请选择部门" size="medium">
            <el-option v-for="item in departmentList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addWarehouse">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        name: '',
        isEnable: ''
      },
      warehouseList: [],
      dialogFormVisible: false,
      areaList: [],
      departmentList: [],
      form: {
        selectArea:'',
        cityId: '',
        name: '',
        objAreaType: '',
        shopId: ''
      },
      selectArea:{},
      formRules: {
        selectArea: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        objAreaType: [{ required: true, message: '请选择', trigger: 'change' }],
        shopId: [{required: true, message: '请选择', trigger: 'change' }]
      },
      optionsAddress:[],//默认值地区三级联动
      propsAddress:{
        label:'name',
        value:'id',
        children:'children'
      },
      onState:1,//1新建，2修改
      itemRow:{},
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getShopList()//查询(成交门店)
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.optionsAddress=res;
      })
    }else{
      this.optionsAddress=this.$store.state.setting.cityList;
    }
  },
  methods: {
    onAdd(){
      this.dialogFormVisible=true;
      this.onState=1;
    },
    onEdit(row){
      this.onState=2;
      this.dialogFormVisible=true;
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.form.name=row.name;
      this.form.shopId=row.shopId;
      this.form.selectArea=[row.provinceId,row.cityId];
      this.selectArea.provinceId=row.provinceId;
      this.selectArea.cityId=row.cityId;
    },
    getList() {
      this.$api.getStockObjList().then(res => {
        if(res.code == 0){
          this.warehouseList = res.data;
        }
      })
    },
    changeAddress(){
      let row=this.$refs.address.getCheckedNodes();
      if(row.length>0){
        let item=row[0]
        this.selectArea={
          provinceId:item.pathNodes[0].value,
          provinceName:item.pathNodes[0].label,
          cityId:item.pathNodes[1].value,
          cityName:item.pathNodes[1].label,
          // areaId:item.value,
          // areaName:item.label
        }//三级联动
      }else{
        this.selectArea={
          provinceId:'',
          provinceName:'',
          cityId:'',
          cityName:'',
          // areaId:'',
          // areaName:''
        }//三级联动
      }
    },
    getShopList(){
      let query={
        isEnabled:0//是否启用,启用0，禁用1
      }
      this.$api.getShopList(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.departmentList=res.data
          }
        }
      })
    },
    addWarehouse() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.onState==1){
            let query={
              "name": this.form.name,
              "isEnable": 0,
              "cityId": this.selectArea.cityId,
              provinceId:this.selectArea.provinceId,
              "shopId": this.form.shopId,
              // "objAreaType": this.form.objAreaType,
            }
            this.$api.stockStockObjAdd(query).then(res => {
              if(res.code === 0){
                this.$message.success('操作成功');
                this.dialogFormVisible = false;
                this.$refs['form'].resetFields();
                this.getList();
              }
            })
          }else{
            let query={
              "name": this.form.name,
              "cityId": this.selectArea.cityId,
              provinceId:this.selectArea.provinceId,
              "shopId": this.form.shopId,
              // "objAreaType": this.form.objAreaType,
            }
            this.stockStockObjUpdate(this.itemRow.id,query)
          }
          
        } else {
          return false;
        }
      });
      
    },
    changeStatus(row) {
      let str = '';
      if(!row.isEnable){
        str = '此操作将停用仓库,是否继续?'
      }else{
        str = '此操作将启用仓库,是否继续?'
      }
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.stockStockObjUpdate(row.id,{
          isEnable: row.isEnable? 0 : 1,
          name: row.name
        })
      }).catch(() => {});
    },
    stockStockObjUpdate(id,query){
      this.$api.stockStockObjUpdate(id,query).then(res => {
          if(res.code == 0){
            this.dialogFormVisible=false;
            this.$message.success(res.message)
            this.getList();
          }
        })
    },
  }
}
</script>

<style scoped lang="scss">
.warehouse{
  padding: 20px 40px;
}
</style>
