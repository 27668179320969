<template>
<!--商户合同历史-->
  <div class="currencyConterBox">
    <el-row :gutter="24" class="flex flex_wrap">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>车牌号</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>商户姓名</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>车型</span>
        <el-input v-model="forms.carModel" size="medium"></el-input>
      </el-col>
      <!-- <el-col :xl="8" :lg="8" class="flex flex_align_center flex_between w5 mb10">
        <span>商户预约时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-col> -->
      <!-- <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w5 mb10">
        <span>商户合同状态</span>
        <el-select v-model="forms.contractStatus" size="medium" clearable>
          <el-option label="签订失败" :value="24"></el-option>
          <el-option label="签订成功" :value="23"></el-option>
        </el-select>
      </el-col> -->
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>收款状态</span>
        <el-select v-model="forms.financeReceiveStatus" size="medium" clearable>
          <!-- <el-option label="待收款" :value="41"></el-option> -->
          <el-option label="已收款" :value="43"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2" class="ml10">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="querylist"
      border
      stripe
      class="table-mainbg mt10"
    >
      <el-table-column
        prop="tradeShopName"
        label="成交门店"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carNo"
        label="车牌号码"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="seriesName"
        label="车型"
        align="center"
      >
        <template slot-scope="scope">{{scope.row.car?scope.row.car.makerName:scope.row.makerName}}/{{scope.row.car?scope.row.car.seriesName:scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column
        prop="merchantName"
        label="商户姓名"
        align="center"
      ></el-table-column>
      <el-table-column prop="merchantDeadline" label="商户办证截止日期" align="center" ></el-table-column>
      <el-table-column prop="certCompanyName" label="办证公司" align="center" ></el-table-column>
      <el-table-column prop="status" label="办证状态" align="center" >
        <template slot-scope="scope">{{scope.row.status===1?'待办证':scope.row.status===2?'办证中':scope.row.status===3?'结算中':scope.row.status===4?'交易完成':scope.row.status}}</template>
      </el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center" ></el-table-column>
      <el-table-column prop="carTradedPrice" label="商户成交价" align="center" ></el-table-column>
      <el-table-column prop="financeReceiveTime" label="收款完成时间" align="center" ></el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <el-button type="primary" @click="onDownload(scope.row)" size="mini" plain>查看</el-button>
          <!-- <el-button type="primary" @click="onDownload(scope.row)" size="mini" plain>下载合同</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        merchantName: "",
        carNo: "",
        date: "",
        financePayStatuses: "",
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      paymentStatuslist: [], //付款状态
    };
  },
  created() {
    this.paymentStatuslist = this.$store.state.setting.paymentStatuslist;
    if (this.$route.params.state)
      this.forms.financePayStatuses = Number(this.$route.params.state);

    this.init();
  },
  methods: {
    init(page) {
      this.currentPage = page ? page : this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        // beginAppointTradeDate:this.forms.date?this.forms.date[0]:'',//预约成交日期
        // endAppointTradeDate:this.forms.date?this.forms.date[1]:'',//预约成交日期
        financeReceiveStatus:this.forms.financeReceiveStatus,//财务车款收款状态，41待收款，42收款中，43收款成功，44收款失败
        merchantName: this.forms.merchantName,
        carModel:this.forms.carModel,
        // contractStatus:this.forms.contractStatus?[this.forms.contractStatus]:''
      };
      this.$api.getMerchantList(this.$$.delete_null_properties(query)).then((res) => {
          if (res) {
            if (res.code == 0) {
              this.querylist = res.data.records;
              this.total = res.data.total;
            } else {
              this.querylist = [];
              this.total = 0;
            }
          }
        });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.currentPage = 1;
      this.init();
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.init();
    },
    onDownload(row){
      // this.$router.push({
      //   path: '/salesAssistant/merchantContract',
      //   query:{
      //     id:row.id,
      //     state:0,
      //   }
      // })
      const pathData=this.$router.resolve({
        path: '/salesAssistant/merchantContract',
        query:{
          id:row.id,
          state:0,
        }
      })
      window.open(pathData.href,'_blank')
    },
    // getPageSize(){//在删除成功后调用此方法
    //   let totalPage = Math.ceil((this.total - 1) / this.pageSize);
    //   //计算出当前页码
    //   this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage;
    //   //如果页码为1的话，则无效
    //   this.currentPage = this.currentPage < 1 ? 1 : this.currentPage;
    // },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
  .rowbox {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    .line {
      width: 15px;
      height: 1px;
      background-color: #999999;
      margin: 0 5px;
    }
  }
}
</style>