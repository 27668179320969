<template>
  <div class="pageContainer bgF5" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
    <!-- <kpi :kapilist="kapilist" ref="kpi"></kpi> -->
  </div>
</template>

<script>
import task from "@/components/task/task";
// import kpi from "@/components/kpi/kpi";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
      // kapilist:[{
      //   title:'​截至目前有效报价数',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',//计算了百分比之后的值
      //   color:'#0099FF',
      //   colorbg:'#CAE9FF'
      // },{
      //   title:'当月净增商户数',
      //   targetVal:0,//目标值
      //   actualVal:0,//实际值
      //   value:'0',
      //   color:'#1FC678',
      //   colorbg:'#A5E7CE'
      // }],//KPI指标
    };
  },
  created() {
    this.getHomePage();
  },
  methods: {
    getHomePage() {
      let userData=this.$store.state.user.userData;
      let roleObj=this.$$.roleName(userData)
      this.$api.getHomePage().then((res) => {
        if (res.code == 0) {
          let newArr=[
            {
              num: res.data.countNoAuth,
              name: '待人工认证',
              star: 0,
              url:'/merchant/authentication'
            },
            {
              num: res.data.countNoSetMargin,
              name: '待设置保证金',
              star: 0,
              url:'/merchant/certificationPassed'
            },
            {
              num: res.data.countCollection,
              name: '待收保证金',
              star: 0,
              url:'/merchant/collectionBond'
            },
            {
              num: res.data.countAuthNoPass,
              name: '认证驳回',
              star: 0,
              url:'/merchant/authenticationRejection'
            },
          ]
          if(roleObj.name.indexOf('拓展经理')!=-1){
            newArr.push({
              num: res.data.countYetComplain,
              name: '商户已申诉',
              star: 0,
              url:'/merchant/complaintProcessing',
              urlName:'complaintProcessing',
              params:{state:22}
            }) 
          }
          this.infolist=[{name:"",list:newArr}];

          // this.kapilist[0].targetVal=res.data.validQuotationTargetCount;//目标值
          // this.kapilist[0].actualVal=res.data.validQuotationCount;//实际值
          // this.kapilist[0].value=(res.data.validQuotationCount/res.data.validQuotationTargetCount*10000)/100;//计算了百分比之后的值
          // this.kapilist[1].targetVal=res.data.netGrowthTargetCount;//目标值
          // this.kapilist[1].actualVal=res.data.netGrowthCount;//实际值
          // this.kapilist[1].value=(Number(res.data.netGrowthCount)/Number(res.data.netGrowthTargetCount)*10000)/100;//计算了百分比之后的值
          // this.$refs.kpi.initChart('charid0',this.kapilist[0]);
          // this.$refs.kpi.initChart('charid1',this.kapilist[1]);
          this.$nextTick(() => {
            // this.kapilist[0].targetVal=0;//目标值
            // this.kapilist[0].actualVal=0;//实际值
            // this.kapilist[0].value=0;//计算了百分比之后的值
            // this.kapilist[1].targetVal=0;//目标值
            // this.kapilist[1].actualVal=0;//实际值
            // this.kapilist[1].value=0;//计算了百分比之后的值
            // this.$refs.kpi.initChart('charid0',this.kapilist[0]);
            // this.$refs.kpi.initChart('charid1',this.kapilist[1]);

            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
    // kpi
  }
};
</script>

<style lang="scss" scoped>
</style>
