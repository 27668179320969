<template>
  <div class="login flex">
    <div class="bg">
      <div class="date">
        <div class="top">{{dateNow.slice(-2)}}</div>
        <div class="dateNow">{{dateNow.slice(0,-2)}}</div>
        <div>{{month}}</div>
        <div class="week">{{week}}</div>
      </div>
    </div>
    <div class="loginRight">
      <!-- <img class="logo" src="../../assets/images/logo.png" alt="车开新"> -->
       <div class="logoTextBox">
         <img class="logoText" src="../../assets/images/headText.jpg" alt=""> 
       </div>
      <!-- <p class="loginheadText">鸿鹄拍卖平台</p> -->
      <div class="name">{{setting.system}}</div>
      <el-form :model="loginForm" :rules="rules" ref="loginForm" class="loginForm">
        <el-form-item prop="userName" required >
          <el-input v-model="loginForm.userName"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input auto-complete="off" v-model="loginForm.password" show-password @keyup.enter.native="handleLogin">
          </el-input>
        </el-form-item>
        <div class="flex flex_between">
          <el-checkbox v-model="checked" class="rem">记住密码</el-checkbox>
          <span class="forgetPsd" @click="$router.push({path:'/forgetPsd'})">忘记密码？</span>
        </div>
        <el-button class="submit" round type="primary" @click="handleLogin">登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      dateNow: '',
      month: '',
      week: '',
      loginForm: {
        userName: '',
        password: ''
      },
      rules: {
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' },],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' },],
      },
      checked: false,
      setting:{},
      ip:'',
    }
  },
  created() {
    // this.getToday();//获取日期
    this.getCookie();// 获取用户名密码等Cookie
    // this.getLocationCity()
  },
  methods: {
    // getLocationCity(){
    //   this.$api.getLocationCity().then(res=>{
    //     if(res.code==0){
    //       this.ip=res.data.ip;
    //     }
    //   })
    // },
    getToday() {
      this.dateNow = moment().format('Do');
      this.month = moment().format('YYYY.MM');
      let d = moment().format('d')
      switch(d) {
        case '0':
          this.week = '周日 Sunday';
          break;
        case '1':
          this.week = '周一 Monday';
          break;
        case '2':
          this.week = '周二 Tuesday';
          break;
        case '3':
          this.week = '周三 Wednesday';
          break;
        case '4':
          this.week = '周四 Thursday';
          break;
        case '5':
          this.week = '周五 Friday';
          break;
        case '6':
          this.week = '周六 Saturday';
          break;
        default:
          break;
      }
    },
    getCookie() {
      const username = Cookies.get('username')
      let password = Cookies.get('ckxPassword')
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        userName: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : password,
        loginSource:'web',
      }
      this.checked=rememberMe === undefined ? false : Boolean(rememberMe)
    },
    handleLogin() {
      
      sessionStorage.clear(); // 清除缓存/token等
      localStorage.clear()
      Cookies.remove('username')
      Cookies.remove('ckxPassword')
      Cookies.remove('rememberMe')
      Cookies.remove('token')
      
      this.$refs['loginForm'].validate((valid) => {
          if (valid) {
            // this.loginForm.customerIp=this.ip;
            this.$api.postYetLogin(this.loginForm).then(res=>{
              if(res.code==0){
                if(res.data===true){
                  this.$confirm('当前账号已经被登录了, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.postLogin();
                  }).catch(() => {});
                }else{
                  this.postLogin();
                }
              }
            })
             
          } else {
            return false;
          }
        });
    },
    postLogin(){
      this.$api.postLogin(this.loginForm).then((res)=>{
        if(res.code==0){
          this.bus.$emit("closeAllTags")
          if(this.checked==true){
            Cookies.set('username',this.loginForm.userName,{ expires: 7 })
            Cookies.set('ckxPassword',this.loginForm.password,{ expires: 7 })
            Cookies.set('rememberMe',this.checked,{ expires: 7 })
          }
        //  Cookies.set('token',res.data.token)
          localStorage.setItem('token',res.data.token)
          localStorage.setItem('loginObj',JSON.stringify(res.data))
          localStorage.setItem('userId',res.data.userId)
          this.$store.dispatch('user/setUser',res.data.userId); 
          if(!res.data.currentRoleId){
            this.$router.push({
              path:'/role'
            })
          }else{
            localStorage.setItem('roleId',res.data.currentRoleId)
            this.$store.dispatch('user/setRole',res.data.currentRoleId); 
          }
          let roleTitle='';
          switch(res.data.username){
          case '系统管理员':
          case '管理员':
          case 'admin':
            roleTitle='admin';
            break;
          }
          this.$store.dispatch('permission/setPermission', [roleTitle])
          this.$datadic.getUserData(res.data.userId)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.login{
  height: 100%;
}
.bg{
  width: 60%;
  background: url('../../assets/images/loginBg.png');
  height: 100%;
  position: relative;
  .date{
    position: absolute;
    top: 50px;
    right: 75px;
    color: white;
    font-size: 20px;
    .dateNow{
      font-size: 100px;
      font-weight: 700;
    }
    .top{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.loginRight{
  flex: 1;
  min-width: 520px;
  .logo{
    margin: 300px auto 0;
    width: 231px;
    display: block;
  }
  .name{
    font-size: 40px;
    font-weight: 500;
    margin: 35px auto 0;
    text-align: center;
  }
  .loginForm{
    width: 480px;
    height: 300px;
    margin: 85px auto 0;
    .togglePsd{
      font-size: 20px;
      cursor: pointer;
    }
    .submit{
      width: 100%;
      margin-top: 20px;
    }
    .forgetPsd{
      cursor: pointer;
    }
  }
  .logoTextBox{
    display:flex;
  }
  .logoText{
    margin:300px auto 0;
    text-align: center;
    width:390px;
  }
  // .loginheadText{
  //   margin:300px auto 0;
  //   text-align: center;
  //   font-size:40px;
  // }
}
@media (max-width: 768px) {
  .bg{
    display: none;
  }
  .loginRight .logoText{
    margin-top:50px;
    width:300px;
  }
  .loginRight{
    min-width: auto;
  }
  .loginRight .loginForm{
    width: auto;
    margin: 0 20px;
  }
}
</style>
<style>
#app,html,body{height: 100%;font-size: 16px;color: #333;}
.login .el-input__inner{height: 60px; line-height: 60px;padding: 10px 50px 10px 25px;border-radius:60px}
.login .el-input__suffix{right: 25px;}
.login .el-form-item__content{line-height: 60px;}
.login .el-input .el-input__clear{font-size: 20px;}
.login .el-button{padding: 14px 23px;border-radius: 30px; font-size: 30px;}
.login .el-checkbox__label{font-size: 16px;}
</style>