<template>
  <!-- 接收发货单 -->
  <div class="receiveDeliveryNote">
    <div class="title">物流发货单</div>
    <div class="line">发货单号：{{noteInfo.logistNo}}</div>
    <div class="ckxTitle mb10 mt20">发货信息</div>
    <el-row>
      <el-col :span="6">发货方：{{noteInfo.fromShopName}}</el-col>
      <el-col :span="6">收货方：{{noteInfo.toShopName}}</el-col>
    </el-row>
    <el-row class="mt10">
      <el-col :span="6">发货方式：{{noteInfo.logistTypeName}}</el-col>
      <el-col :span="6" v-if="noteInfo.fromObjType==43">发货时间：{{noteInfo.fromTime}}</el-col>
    </el-row>
    <el-row class="mt10" v-if="noteInfo.fromObjType!=43">
      
      <!--物流方式，11代驾，12车开新，13快递-->
      <template v-if="noteInfo.logistType==13">
        <el-col :span="6">快递公司：{{noteInfo.expressCompName}}</el-col>
        <el-col :span="6">快递单号：{{noteInfo.expressLogistNo}}</el-col>
      </template>
      <template v-if="noteInfo.logistType==12">
        <el-col :span="6">员工姓名：{{noteInfo.empName}}</el-col>
        <el-col :span="6">手机号：{{noteInfo.empMobile}}</el-col>
      </template>
      <template v-if="noteInfo.logistType==11">
        <el-col :span="6">代驾姓名：{{noteInfo.agencyName}}</el-col>
        <el-col :span="6">代驾手机：{{noteInfo.agencyMobile}}</el-col>
      </template>
      
      <el-col :span="6">发货时间：{{noteInfo.fromTime}}</el-col>
      <el-col :span="6">预计到达时间：{{noteInfo.preArriveTime}}</el-col>
    </el-row>
    <template v-if="noteInfo.logistStatus==14">
      <div class="ckxTitle mt20 mb10">接货信息</div>
    <el-row class="mt10">
      <el-col :span="6">接货人：{{noteInfo.toName}}</el-col>
      <el-col :span="6">接收时间：{{noteInfo.toReceiveTime}}</el-col>
    </el-row>
    </template>
    <template v-if="noteInfo.logistCarInfo">
      <div class="ckxTitle mb10 mt20">车辆信息</div>
      <el-table :data="carlist" border class="table-mainbg" stripe>
        <el-table-column type="index" align="center" label="序号" width="70"></el-table-column>
        <el-table-column align="center" prop="carNo" label="车牌号"></el-table-column>
        <el-table-column align="center" prop="name" label="项目"></el-table-column>
      </el-table>
    </template>
    <div class="flex justifySpace alignCenter">
      <div class="ckxTitle mb10 mt20">手续材料信息</div>
      <el-button size="medium" type="primary" @click="onAdd" v-if="noteInfo.fromObjType===43&&noteInfo.logistStatus==13">新增</el-button>
    </div>
    <el-table :data="logistMaterialsList" border class="table-mainbg" >
      <el-table-column type="index" align="center" label="序号" width="70"></el-table-column>
      <el-table-column align="center" prop="carNo" label="车牌号"></el-table-column>
      <el-table-column align="center" prop="materialName" label="项目"></el-table-column>
      <el-table-column align="center" prop="sendNums" label="数量"></el-table-column>
      <el-table-column label="操作" align="center" width="100px" v-if="noteInfo.fromObjType===43&&noteInfo.logistStatus==13">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="onDelect(scope.row,scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer" v-if="noteInfo.logistStatus==13">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >确认接收</el-button>
      </div>
    </div>

    <el-dialog
      title="添加接收材料信息"
      :visible.sync="dialogVisible">
      <div class="flex alignCenter">
        <div class="modalSpan">车牌号:</div>
        <el-input v-model="form.carNo" size="medium"  class="ml10 mr10"></el-input>
        <el-button type="primary" @click="onSearch" size="medium">查询</el-button>
      </div>
      <div class="flex mt10" v-show="materialsType===1">
        <div class="modalSpan">项目名称:</div>
        <div class="ww100 checkHeight">
          <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="item in materialsList" :key="item.id" :label="item" class="mb10">{{item.materialName}}
            <el-input v-model="item.sendNums" class="inputW100"  v-if="item.materialName&&item.materialName.indexOf('钥匙')!=-1" size="mini">
              <template slot="append">把</template>
            </el-input>
          </el-checkbox>
        </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-show="materialsType===1">
        <el-button type="primary" @click="onSubmitModal">添加</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logistMaterialsList: [],
      noteInfo: {
        expressLogistNo: ''
      },
      dialogVisible:false,
      form:{},
      materialsList:[],
      materialsType:0,//0初始化状态，1有结果，2无结果
      roleItem:{},
      checkList:[],
      carlist:[],
    }
  },
  created() {
    let user=this.$store.state.user.userData;
    this.roleItem=this.$$.roleName(user,'department')
    this.logisticsDetail();
  },
  methods: {
    logisticsDetail() {
      this.$api.logisticsDetail(this.$route.query.id,{businessId:this.$route.query.id,businessType: "logistics"}).then(res => {
        if(res.code === 0){
          this.logistMaterialsList = res.data.logistMaterialsList;
          this.noteInfo = res.data;
          this.carlist=[{
            carNo:res.data.carNo,
            name:'车辆'
          }]
        }
      })
    },
    doSubmit() {
      let arr=[]
      if(this.logistMaterialsList){
        arr=this.logistMaterialsList.map(item => {
          let obj={
            materialsId:item.materialsId,
            sendNums:item.sendNums
          }
          return obj
        })
      }
      
      let params = {
        logistId: this.$route.query.id,
        handlerType: 'receive',
        carId: this.noteInfo.carId,
        materialsFormList: this.logistMaterialsList?arr: null
      }
      this.$api.logisticsLogistHandling(this.$$.delete_null_properties(params)).then(res => {
        if(res.code === 0){
          this.$message.success(res.message)
          this.logisticsDetail();
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/salesManager/receiveGoods/receiveGoods',
        query:{
          state:'second'
        }
      })
    },
    onSearch(){
      this.getMaterialsList(this.form.carNo)
    },
    onAdd(){
      this.dialogVisible=true;
      // this.materialsType=0;
      // this.checkList=[];
      // this.form={};
    },
    onDelect(row,$index){
      this.logistMaterialsList.splice($index,1)
    },
    onSubmitModal(){
      let arr=[]
      let oldArr=JSON.parse(JSON.stringify(this.logistMaterialsList))
      for(let i=0;i<this.checkList.length;i++){
        let item=this.checkList[i]
        if(item) if(item.materialName.indexOf('钥匙')==-1){
          item.sendNums=1
        }
        arr.push(item)
      }
      this.dialogVisible=false;
      let newArr=oldArr.concat(arr)
      let obj = {};

      let peon = newArr.reduce((cur,next) => {
          obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
          return cur;
      },[]) //设置cur默认类型为数组，并且初始值为空的数组
      this.logistMaterialsList=peon;
    },
    getMaterialsList(carNo){//车辆材料/手续 列表查询
      let query={
        carNo:carNo,
        shopIds:this.roleItem.shop?[this.roleItem.shop.id]:''
      }
      this.$api.getMaterialsList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          if(res.data){
            this.materialsList=res.data;
            let arr=[]
            if(this.logistMaterialsList){
              for(let i=0;i<this.materialsList.length;i++){
                let item=this.materialsList[i]
                for(let j=0;j<this.logistMaterialsList.length;j++){
                  let it=this.logistMaterialsList[j]
                  if(item.id===it.id){
                    item.sendNums=it.sendNums;
                    arr.push(item)
                  }
                }
              }
            } 
            this.checkList=arr;
            if(res.data.length==0){
              this.materialsType=2;
              this.$message.warning('车牌号不正确，请重新输入车牌后再次查询！')
            }else{
              this.materialsType=1;
            }
          }else{
            this.materialsType=2;
            this.$message.warning('车牌号不正确，请重新输入车牌后再次查询！')
          } 
        }else{
          this.materialsList=[];
          this.materialsType=2;
          this.$message.warning('车牌号不正确，请重新输入车牌后再次查询！')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.receiveDeliveryNote{
  padding: 20px 40px;
  .title{
    text-align: center;
    padding: 0px 0 20px;
    font-size: 24px;
  }
  .line{
    padding: 12px 0 12px 40px;
    margin: 0 -40px;
    background: #EDEDED;
  }
  .modalSpan{
    width:100px;
  }
  .inputW100{
    width:150px;
  }
  .checkHeight{
    height:350px;
    overflow:auto;
  }
}
</style>