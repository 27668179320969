<template>
  <div class="pageContainer bgF5" ref="pageCombox" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
    <kpi :kapilist="kapilist" ref="kpi"></kpi>
    <div class="title_top_nameBox mt30 mb20">数据看版</div>
    <!-- <div >
      <el-card class="box-card">
        <p><span class="fontTextNumber">300</span>条</p>
        <p>当月分配优质客户数</p>
      </el-card>
       <el-card class="box-card">
        <p><span class="fontTextNumber">300</span>条</p>
        <p>当月分配优质客户数</p>
      </el-card>
    </div> -->
    <el-row :gutter="12" class="dataBoard">
      <el-col :span="4">
        <el-card class="box-card">
          <p><span class="fontTextNumber">300</span>条</p>
          <p>当月分配优质客户数</p>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="box-card">
          <p><span class="fontTextNumber">300</span>条</p>
          <p>当月取消优质客户数</p>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="box-card">
          <p><span class="fontTextNumber">300</span>条</p>
          <p>当月优质基础客户数</p>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="box-card">
          <p><span class="fontTextNumber">300</span>条</p>
          <p>当月新一检数</p>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="box-card">
          <p><span class="fontTextNumber">300</span>条</p>
          <p>组内新任务</p>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="box-card">
          <p><span class="fontTextNumber">300</span>条</p>
          <p>组内在跟进的任务</p>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt30">
      <el-col :span="10">
        <h4>当月组内检测转化率排名</h4>
        <el-table :data="dataToBe" border stripe class="mt20 table-mainbg">
          <el-table-column prop="name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="name" label="检测转率" align="center"></el-table-column>
          <el-table-column prop="name" label="检测转化率排名" align="center"></el-table-column>
        </el-table>
      </el-col>
      <el-col :span="10">
        <h4>当月组检测转化率排名</h4>
        <el-table :data="dataToBe" border stripe class="mt20 table-mainbg">
          <el-table-column prop="name" label="组名" align="center"></el-table-column>
          <el-table-column prop="name" label="检测转率" align="center"></el-table-column>
          <el-table-column prop="name" label="检测转化率排名" align="center"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import task from "@/components/task/task";
import kpi from "@/components/kpi/kpi";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
      kapilist:[{
        title:'​截至目前组内检测转化率',
        targetVal:0,//目标值
        actualVal:0,//实际值
        value:'0',//计算了百分比之后的值
        color:'#0099FF',
        colorbg:'#CAE9FF'
      },{
        title:'当月截止目前组内挖掘台数',
        targetVal:0,//目标值
        actualVal:0,//实际值
        value:'0',
        color:'#1FC678',
        colorbg:'#A5E7CE'
      }],//KPI指标
      dealNameList:[],//成交转化率名称
      dealNumberList:[],//值
    };
  },
  created() {
    this.queryCheckTaskGroupByTaskStatus();
  },
  methods: {
    queryCheckTaskGroupByTaskStatus() {
      let query={
        taskStatus:'1,3,4,5,8'
      }
      //状态：1:待审核，2:审核通过，3审核驳回，4审核失败，5待签约，6已签约，7签约失败，8已解约，9佣金待审核，11佣金审核通过，12钱包提现待审核，13钱包提现审核成功，14钱包提现审核失败，15违约赔偿待审核，16违约赔偿审核成功
      this.$api.queryCheckTaskGroupByTaskStatus(query).then((res) => {
        if (res.code == 0) {
          let newArr1=[
            {
              num: '',
              name: '呼叫监控',
              star: 0,
              url:''
            },
            {
              num: '',
              name: '新增录音入库',
              star: 0,
              url:''
            },
            {
              num: '',
              name: '创建案例入库',
              star: 0,
              url:''
            }
          ]
          this.infolist=[{name:"",list:newArr1}];
          // console.log(this.infolist,'-----555---')
          //  this.kapilist[0].targetVal=res.data.validQuotationTargetCount;//目标值
          // this.kapilist[0].actualVal=res.data.validQuotationCount;//实际值
          // this.kapilist[0].value=(res.data.validQuotationCount/res.data.validQuotationTargetCount*10000)/100;//计算了百分比之后的值
          // this.kapilist[1].targetVal=res.data.netGrowthTargetCount;//目标值
          // this.kapilist[1].actualVal=res.data.netGrowthCount;//实际值
          // this.kapilist[1].value=(Number(res.data.netGrowthCount)/Number(res.data.netGrowthTargetCount)*10000)/100;//计算了百分比之后的值
          this.$refs.kpi.initChart('charid0',this.kapilist[0]);
          this.$refs.kpi.initChart('charid1',this.kapilist[1]);
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
    kpi
  }
};
</script>

<style lang="scss" scoped>
.dataBoard{
  // background:#ffffff;
  text-align: center;
  // padding: 20px;
  // display: flex;
  // flex-wrap: wrap;
  .fontTextNumber{
    display: inline-block;
    font-size:30px;
    margin:0 5px 10px 0;
  }

  h4{
    font-size: 16px;
    padding: 20px 0;
  }
}

</style>
