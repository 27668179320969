<template><!--顶部菜单组件-->
	<div class="el-menu-horizontal-warp" :style="!isMenu?'justify-content:space-between':''">
		<!-- <div class="logo"></div> -->
    <div class="logoText">{{autoMedium=='mobile'?'鸿鹄':'鸿鹄拍卖平台'}}</div>
		<!-- 左部菜单栏出现时默认工作台展示 -->
		<el-scrollbar class="ckxScroll" @wheel.native.prevent="onElMenuHorizontalScroll" ref="elMenuHorizontalScrollRef" v-if="isMenu">
			<el-menu v-if="hideHorizontalRoute" background-color="#0099FF" mode="horizontal" 
      text-color="#fff" active-text-color="#ffd04b">
        <div class="clickMeun">
          <i :class="!activeClass?'el-icon-s-fold':'el-icon-s-unfold'"  @click="handleChangeLeft" ></i>
        </div>
				<!-- <el-menu-item index="1">
					<template slot="title">
							<span>我的工作台</span>
						</template>
				</el-menu-item> -->
			</el-menu>
			<el-menu v-else 
      :default-active="defaultActive" 
       background-color="#0099FF" 
       mode="horizontal" 
       @select="onHorizontalSelect" text-color="#fff" active-text-color="#ffd04b">
				<template v-for="val in filterRoutesFun(menuList)">
					<el-submenu :index="val.path" v-if="val.children && val.children.length > 0" :key="val.path">
						<template slot="title">
							<!-- <i :class="val.meta.icon ? val.meta.icon : ''"></i> -->
							<span>{{val.meta.title}}</span>
						</template>
						<SubItem :chil="val.children" />
					</el-submenu>
          <!-- <el-menu-item :index="val.path" :key="val.path" v-else> -->
					<el-menu-item :index="val.path" :key="val.path" v-else-if="!val.meta.menuhide||val.meta.title=='首页'">
						<template slot="title" v-if="val.meta.isLink">
							<a :href="val.meta.isLink" target="_blank" class="setFontSize14 menuLink">
								<!-- <i :class="val.meta.icon ? val.meta.icon : ''"></i> -->
								{{val.meta.title}}
							</a>
						</template>
						<template slot="title" v-else-if="val.meta.isNewVuePage">
							<span class="menuLink" @click="$$.goNewPage(val.path,$route.fullPath)">{{val.meta.title}}</span>
						</template>
						<template slot="title" v-else-if="val.meta.badge">
							<!-- <i :class="val.meta.icon ? val.meta.icon : ''"></i> -->
							<Badge :valPpath="val.path" :metaTitle="val.meta.title"/>
						</template>
            <!--单菜单-->
						<template slot="title" v-else>
							<router-link :to="val.path" class="setFontSize14 menuLink">{{val.meta.title}}</router-link>
						</template>
					</el-menu-item>
				</template>
			</el-menu>
		</el-scrollbar>
		<div class="flex wrapRight">
			<span>当前角色：{{roleName}}</span>
			<el-dropdown :show-timeout="70" :hide-timeout="50" @command="onDropdownCommand">
				<span class="el-dropdown-link">
          <div class="headImgBox">
            <i class="el-icon-user-solid" size="40"></i>
          </div>
          <div>{{userName}}</div>
					<!-- <el-avatar :size="40" fit="fill" :src="url"></el-avatar> -->
					<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item  command="/user/center">个人信息</el-dropdown-item>
					<el-dropdown-item  command="/user/updatePsd">修改密码</el-dropdown-item>
					<el-dropdown-item  command="/role">身份切换</el-dropdown-item>
					<el-dropdown-item  command="logOut">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
import SubItem from '@/components/sidebar/subItem.vue';
import Badge from '@/components/common/badge.vue';
import Cookies from 'js-cookie'
export default {
	name: 'navMenuHorizontal',
  components: { SubItem, Badge },
  props:{
    userlist:{
      type:Object,
      default(){
        return {}
      }
    },
    isMenu:{
      type:Boolean,
      default:true
    }
  },
	data() {
		return {
			defaultActive: null,
			url: '',
			menuList: null,
      hideHorizontalRoute: true,
      roleName:'-',
      userName:'',
      roleId:'',
      activeClass:true,
      autoMedium:'web'
		};
  },
  watch:{
    // 监听路由的变化
		$route: {
			handler(to) {
				this.setCurrentRouterHighlight(to.path);
			},
			deep: true,
		},
    userlist:function(res){
      if(res){
        let currentData=res.roleList;
        this.userName=res.no;
        for(let i=0;i<currentData.length;i++){
          if(currentData[i].isCurrent==0){
            this.roleName=currentData[i].name;//角色
          }
        }
        let arr=currentData.map(item => {return item.isCurrent})
        if(arr.includes(0)){
          return
        }else{
          this.$router.push({path:'/role'})
        }
      }
    }
  },
  created() {
    this.autoMedium=this.$$.autoMedium()//设备
    this.roleId=this.$store.state.user.roleId;
		// 管理员为左侧菜单,保留顶部菜单样式
		// if(this.$store.state.permission.permission.indexOf('admin') > -1){
		// 	this.hideHorizontalRoute = true;
		// }else{
		// 	this.hideHorizontalRoute = false
			this.menuList = this.$store.state.routesList.routesList;
    // }
	},
	mounted() {
		this.initElMenuOffsetLeft();
    this.setCurrentRouterHighlight(this.$route.path);
    if(JSON.stringify(this.userlist)!='{}'){
      let currentData=this.userlist.roleList;
      this.userName=this.userlist.no
      for(let i=0;i<currentData.length;i++){
        if(currentData[i].isCurrent==0){
          this.roleName=currentData[i].name;//角色
        }
      }
      let arr=currentData.map(item => {return item.isCurrent})
      if(arr.includes(0)){
        return
      }else{
        this.$router.push({path:'/role'})
      }
    }
     
  },
	methods: {
		// 设置横向滚动条可以鼠标滚轮滚动
		onElMenuHorizontalScroll(e) {
			const eventDelta = e.wheelDelta || -e.deltaY * 40;
			this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft = this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft + eventDelta / 4;
		},
		// 初始化数据，页面刷新时，滚动条滚动到对应位置
		initElMenuOffsetLeft() {
			this.$nextTick(() => {
				let els = document.querySelector('.el-menu.el-menu--horizontal li.is-active');
				if (!els) return false;
				this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft = els.offsetLeft;
			});
		},
		// 路由过滤递归函数
		filterRoutesFun(arr) {
			return arr
				.filter((item) => !item.meta.isHide)
				.map((item) => {
					item = Object.assign({}, item);
					if (item.children) item.children = this.filterRoutesFun(item.children);
					return item;
				});
		},
		// 传送当前子级数据到菜单中
		setSendClassicChildren(path) {
			const currentPathSplit = path.split('/');
			let currentData = {};
			this.filterRoutesFun(this.$store.state.routesList.routesList).map((v, k) => {
				if (v.path === `/${currentPathSplit[1]}`) {
					v['k'] = k;
					currentData['item'] = [{ ...v }];
					currentData['children'] = [{ ...v }];
					if (v.children) currentData['children'] = v.children;
				}
			});
			return currentData;
		},
		// 菜单激活回调
		onHorizontalSelect(path) {
			// this.bus.$emit('setSendClassicChildren', this.setSendClassicChildren(path));
			this.setSendClassicChildren(path)
		},
		// 设置页面当前路由高亮
		setCurrentRouterHighlight(path) {
			this.defaultActive = path;
		},
		onDropdownCommand(path) {
			if (path === 'logOut') {
				setTimeout(() => {
					this.$msgbox({
						closeOnClickModal: false,
						closeOnPressEscape: false,
						title: '提示',
						message: '此操作将退出登录, 是否继续?',
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if (action === 'confirm') {
								instance.confirmButtonLoading = true;
								instance.confirmButtonText = '退出中';
								setTimeout(() => {
									done();
									setTimeout(() => {
										instance.confirmButtonLoading = false;
									}, 300);
								}, 700);
							} else {
								done();
							}
						},
					})
						.then(() => {
              this.$api.postLogout().then((res)=>{
                if(res.code==0){
                  sessionStorage.clear(); // 清除缓存/token等
                  localStorage.clear()
                  Cookies.remove('username')
                  Cookies.remove('ckxPassword')
                  Cookies.remove('rememberMe')
                  Cookies.remove('token')
                  this.$store.dispatch('routesList/setRoutesList', []); // 清空 vuex 路由列表缓存
                  // resetRouter(); // 删除/重置路由
                  // this.$router.push('/login');
                  location.reload();
                  setTimeout(() => {
                    this.$message.success(res.message);
                  }, 300);
                }
              })
						})
						.catch(() => {});
				}, 150);
			}else {
				this.$router.push({path: path},()=>{});
			}
		},
    handleChangeLeft(){//通过父级去控制展示左侧菜单
      this.activeClass=!this.activeClass;
      this.$emit('handleChangeLeft')
    },
	},
};
</script>
<style lang="scss">
.el-menu--horizontal .el-menu{
  max-height: 700px;
  overflow: auto;
}
</style>
<style scoped lang="scss">

.el-menu-horizontal-warp {
	overflow: hidden;
	display: flex;
	background: #0099FF;
	align-items: stretch;
  .logoText{
    color:#ffffff;
    font-size:24px;
    margin: auto 20px;
  }
	.logo{
		background: url(../../assets/images/logo.png) no-repeat 0% 9px / 100% auto;
		width: 115px;
		margin: 0 30px 0 20px;
		height: 60px;
	}
	::v-deep .el-scrollbar__bar.is-vertical {
		display: none;
	}
	::v-deep .el-scrollbar__wrap {
		overflow-y: hidden !important;
	}
	::v-deep a {
		width: 100%;
	}
	::v-deep .el-menu.el-menu--horizontal{
		border-bottom: none;
	}
	::v-deep .el-submenu__title i{
		color: #fff;
	}
	.el-menu.el-menu--horizontal {
		display: flex;
		height: 100%;
		width: 100%;
		box-sizing: border-box;
	}
	.ckxScroll{
		flex: 1;
		overflow: hidden;
    position: relative;
    height: 100%;
	}
	.wrapRight{
		color: #fff;
		&>span{
			line-height: 60px;
			margin-right: 20px;
		}
		::v-deep .el-dropdown{
			display: flex;
			align-items: center;
			.el-dropdown-link{
				display: flex;
				align-items: center;
				color: #fff;
				margin-right: 10px;
				.el-avatar{
					margin-right: 10px;
				}
			}
		}
	}
	::v-deep .el-badge{
		height: 60px;
		display: block;
		margin: 0 -20px;
    padding: 0px 20px;
	}
	.menuLink{
		display: block;
		margin: 0 -20px;
    padding: 0px 20px;
	}
  .clickMeun{
    display:flex;
    align-items:center;
    color: #ffffff;
    font-size: 30px;
    height:60px;
    i{
      cursor: pointer;
    }
  }
  .headImgBox{
    width:30px;
    height:30px;
    border:2px solid #ffffff;
    border-radius:2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:10px;
  }
}
@media (max-width: 768px) {
  .el-menu-horizontal-warp .logoText{
    font-size:20px;
  }
}
</style>
