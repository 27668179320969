<template>
  <div class="expenseAudBoxBG">
    <div class="expenseAudBox pb100 mb30">
      <!--基本信息-->
      <div class="ckxTitle mb10">办证基本信息</div>
      <el-row>
        <el-col :span="6">车牌号：{{form.carNo}}</el-col>
        <!-- <el-col :span="5">车辆持性质：{{form.publicOrPrivateName}}</el-col> -->
        <el-col :span="6">品牌车型：{{form.makerName}}&nbsp;{{form.seriesName}}</el-col>
        <!-- <el-col :span="5">车辆所在库：{{res.stockObjInName}}</el-col> -->
        <el-col :span="6">客户成交门店：{{res.tradeShopName}}</el-col>
        <el-col :span="6">客户办证截止日期：{{res.customerDeadline}}</el-col>
      </el-row>
      <el-row class="mt10">
        <el-col :span="6">4S店简称：{{form.channel?form.channel.shortName:''}}</el-col>
        <el-col :span="6">渠道拓展专员：{{form.channel?form.channel.extensionerName:''}}</el-col>
        <el-col :span="6">办证类型：{{form.certTypeName}}</el-col>
        <el-col :span="6">商户办证截止日期：{{res.merchantDeadline}}</el-col>
      </el-row>
      
      <!--商户信息-->
      <div class="flex flex_align_center mb10 mt20">
        <div class="ckxTitle">商户及维护人员信息</div>
        <span class="seeColor ml20" @click="onrouter(1)">商户合同查看>></span>
      </div>
      <el-row class="mt10">
        <el-col :span="6">商户姓名：{{res.merchantName}}</el-col>
        <el-col :span="6">商户电话： {{res.merchantMobile}}</el-col>
        <el-col :span="6">商户维护人员： {{carMerchant.maintainerName}}</el-col>
        <el-col :span="6">商户电话： {{carMerchant.merchantOtherMobile}}</el-col>
      </el-row>
      <!--过户凭证-->
      <template v-if="[1,6].includes(state)" >
      <div class="ckxTitle mb15 mt20">过户凭证特殊要求</div>
      <div class="">
        <span v-for="(item,index) in contractRequireList" :key="item.id" class="mr30 tipsColor">{{index+1}}、{{item.requireName}}</span>
        <span v-if="contractRequireList.length===0">-</span>
      </div>
      <div class="ckxTitle mb15 mt20">过户凭证</div>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) of fileListImg" :key="index">
          <div class="photo_row_imgBox" @mouseenter="divMouseover(item,index,'gh')"
              @mouseleave="divMouseout(item)" >
            <div class="dialogBG" v-show="dialogFlag&&diaIndex==='gh_'+index">
            <i class="el-icon-delete cursor mr20" @click="delectImg(item,index,1)" ></i>
            <i class="el-icon-zoom-in cursor " @click="onPreview"></i>
          </div>
            <el-image v-if="item.url||res.taskVoucherFeeCheckStatus!==62"
              :src="item.url" 
              :preview-src-list="[item.url]">
              <div slot="error">暂无图片</div>
            </el-image>
            <div @click="onUpload(item,index,1)" v-if="!item.url&&res.taskVoucherFeeCheckStatus===62" >
             <el-upload
              class="tableUpload"
              action
              accept="image/png, image/gif, image/jpeg,image/jpg"
              :on-change="requestUpload"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <!-- <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file,scope.row)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span> -->
                </div>
              </el-upload>
              </div>
            <div class="photo_row_imgtiltle">{{item.name}}</div>
            <!-- <div class="photo_row_imgtiltle clear">
              <el-checkbox v-model="item.checked" class="fl ml20"></el-checkbox>
              <span class="photoText">{{item.name}}</span>
            </div> -->
          </div>
          <div class="photo_row_textBox">
            <span>上传方：{{item.uploader}}</span>
            <span>上传日期：{{item.uploaderDate}}</span>
          </div>
        </div>
      </div>
      <!---特殊凭证-->
      <div class="ckxTitle mb15 mt20">特殊凭证</div>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) of fileList1" :key="index">
          <div class="photo_row_imgBox"  @mouseenter="divMouseover(item,index,'ts')"
              @mouseleave="divMouseout(item)">
            <div class="dialogBG" v-show="dialogFlag&&diaIndex==='ts_'+index">
              <i class="el-icon-delete cursor mr20" @click="delectImg(item,index,2)" ></i>
              <i class="el-icon-zoom-in cursor " @click="onPreview"></i>
            </div>
            <el-image 
              :src="item.url" v-if="item.url||res.taskVoucherFeeCheckStatus!==62"
              :preview-src-list="[item.url]">
              <div slot="error">暂无图片</div>
            </el-image>
            <div @click="onUpload(item,index,2)" v-if="!item.url&&res.taskVoucherFeeCheckStatus===62" >
             <el-upload
              class="tableUpload"
              action
              accept="image/png, image/gif, image/jpeg,image/jpg"
              :on-change="requestUpload"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <!-- <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span> -->
                </div>
              </el-upload>
              </div>
            <div class="photo_row_imgtiltle">{{item.name}}</div>
            <!-- <div class="photo_row_imgtiltle clear">
              <el-checkbox v-model="item.checked" class="fl ml20"></el-checkbox>
              <span class="photoText">{{item.name}}</span>
            </div> -->
          </div>
          <div class="photo_row_textBox">
            <span>上传方：{{item.uploader}}</span>
            <span>上传日期：{{item.uploaderDate}}</span>
          </div>
        </div>
      </div>
      </template>
      <!--车辆手续补办费-->
      <div class="ckxTitle mb15 mt20">车辆手续补办费</div>
      <el-table :data="materialProcedureList" border class="table_top_bg">
        <el-table-column prop="name" label="项目" align="center">
          <template slot-scope="scope">{{scope.row.name}}&nbsp;{{scope.row.selfDefineRemark}}</template>
        </el-table-column>
        <el-table-column prop="nature" label="补办要求" align="center"></el-table-column>
        <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
        <el-table-column prop="chargingFeeAmount" label="承担金额（元）" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center"></el-table-column>
        <el-table-column prop="payFeeAmount" label="受益金额（元）" align="center"></el-table-column>
        <!-- <el-table-column prop="remark" label="费用说明" align="center" ></el-table-column> -->
        <!-- <el-table-column prop="bearerCostAmount" label="费用确认" align="center">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.check" v-if="[1].includes(state)"></el-checkbox>
            <p v-if="[2,3,4,6].includes(state)">--</p>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="操作" align="center"  width="160" v-if="[1].includes(state)">
          <template slot-scope="scope">
            <el-button type="primary" @click="onapply(scope.row,1)" size="mini">修改</el-button>
            <el-button type="primary" @click="onapply(scope.row,2)" size="mini">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!--办证费用-->
      <div class="ckxTitle mb15 mt20">办证费用</div>
      <el-table :data="clearFeeList" border class="table_top_bg">
        <el-table-column prop="itemName" label="项目" align="center"></el-table-column>
        <el-table-column prop="nature" label="补办要求" align="center">
        </el-table-column>
        <el-table-column prop="feeBearerName" label="费用承担方" align="center"></el-table-column>
        <el-table-column prop="feeAmount" label="承担金额（元）" align="center"></el-table-column>
        <el-table-column prop="beneficiaryName" label="受益方" align="center"></el-table-column>
        <el-table-column prop="beneficiaryAmount" label="受益金额（元）" align="center"></el-table-column>
        <el-table-column prop="remark" label="费用说明" align="center" ></el-table-column>
      </el-table>
      
      <div class="footer" v-if="[1,2,3,5].includes(state)">
        <div class="btn">
          <!-- <el-button type="primary" @click="onButton(1)" v-if="[1,2,3].includes(state)">保存</el-button> -->
          <el-button type="primary" @click="onButton(2)" v-if="[1,5].includes(state)">审核通过</el-button>
          <el-button type="primary" @click="onButton(3)" v-if="[1,5].includes(state)">审核驳回</el-button>
          <!-- <el-button type="primary" @click="onButton(4)" v-if="[1].includes(state)">增加办证项</el-button> -->
          <el-button type="primary" @click="onButton(5)" v-if="[2,3].includes(state)">提交审核</el-button>
          <el-button type="primary" @click="onButton(6)" v-if="[2,3].includes(state)">退回代办费用确认</el-button>
          <el-button type="primary" @click="onButton(7)">返回</el-button>
        </div>
      </div>

      <el-dialog
        :title="`${modelText}`"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="700px"
      >
        <el-form
          ref="forms"
          :model="forms"
          label-width="130px"
          :rules="rules"
          :hide-required-asterisk="true"
          class="mt20" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="补办项目:" prop="name">
                <el-select v-model="forms.name" placeholder="请选择补办项目" class="w180" size="medium" v-show="!projectType" @change="onSelectRow" value-key="id">
                  <el-option v-for="item in replaceList" :key="item.id" :label="item.itemName" :value="item"></el-option>
                </el-select>
                <!-- <el-input v-model="forms.name" class="w180" size="medium" v-show="projectType"></el-input> -->
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
            <el-button type="primary" size="medium" @click="projectType=true" plain>选不到加一个</el-button> 
            </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="费用承担方:">
                <el-select disabled
                  v-model="forms.feeBearerId"
                  placeholder=""
                  class="w180"
                  size="medium">
                  <el-option v-for="item in whichSidelistId" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际费用(应付):" >
                <el-input type="number" v-model.number="forms.payFeeAmount" disabled class="w180" size="medium"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="受益方:" >
                <el-select disabled
                  v-model="forms.beneficiaryId"
                  placeholder=""
                  class="w180"
                  size="medium">
                  <el-option v-for="item in whichSidelistId" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="费用说明:">
            <el-input type="textarea" v-model="forms.remark"></el-input>
          </el-form-item>
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item label="办证供应商:" prop="certCompanyId">
                <el-select
                  v-model="forms.certCompanyId"
                  class="w180">
                  <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付费用:" prop="payFeeAmount">
                <el-input type="number" v-model.number="forms.payFeeAmount" class="w180" size="medium"></el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="footer-modalBut"
            @click="onSubmitform('forms')"
          >提交</el-button>
        </span>
      </el-dialog>
    </div>

     <el-image-viewer v-show="showViewer" :on-close="closeViewer" :url-list="srcList" />

     <el-dialog
    title="审核驳回"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" >驳回原因：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  name:'expenseAuditDetails',
  components: { ElImageViewer },
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      projectType:false,//新增补办是否要添加input
      state: 1,//1凭证及费用审核,2添加备注,3修改备注,4查看，5审核-办证逾期审核,6凭证及费用审核查看
      carRuleabnolist:[],//异常费用列表
      contractFeeBackList:[],//合同备注费用
      carOverdueList: [],//车辆逾期列表
      contractFeeNowNoList:[],//合同暂缺项列表
      querylist:[],//费用明细（车辆材料与办证列表）
      clearFeeList:[],
      materialProcedureList:[],
      fileListImg: [
        // { name: "产证1-2页", url: "", uploader: "", uploaderDate: "" },
        // { name: "产证3-4页", url: "", uploader: "", uploaderDate: "" },
        // { name: "产证5-6页", url: "", uploader: "", uploaderDate: "" },
        // { name: "过户发票", url: "", uploader: "", uploaderDate: "" },
        {
          name: "",
          url: "",
          uploader: "",
          uploaderDate: ""
        }
      ], //图片
      fileList1: [
        // { name: "过户后产证1-2页", url: "", uploader: "", uploaderDate: "" },
        // { name: "过户后产证3-4页", url: "", uploader: "", uploaderDate: "" },
        // { name: "过户后产证5-6页", url: "", uploader: "", uploaderDate: "" }
        {
          name: "",
          url: "",
          uploader: "",
          uploaderDate: ""
        }
      ], //图片
      forms: {
        name: '',
        feeBearerId: '',
        payFeeAmount: '',
      },
      form: {
        carCustomer: {}, //	车辆客户信息
        carMerchant: {} //车辆商户信息
      }, //展示数据
      carMerchant:{},
      res:{},
      followupForm:{
        taskResult: "审核通过",
      },
      dialogVisible: false,
      modelText:'添加办证项',
      rules: {
        name: [{ required: true, message: "不能为空" }],
        feeBearerId: [{ required: true, message: "不能为空" }],
        payFeeAmount: [{ required: true, message: "不能为空" }],
      },
      rulesForm: {
        taskRemark: [{ required: true, message: "结果描述不能为空" }]
      },
      tips: "过户", //提示文字
      addlist:[],//逾期确认表（包含新增方法）
      beOverduelist:[],//逾期项
      whichSidelist:[],//受益方-承担方
      whichSidelistId:[], //承担方-受益方（id）
      companyList:[],//办证公司
      replaceList:[],
      tableEidt:true,
      tableEidt1:true,
      itemRow:{},
      srcList:[],
      showViewer:false,
      dialogFlag:false,
      diaIndex:'',
      contractRequireList:[],//当前选中的特殊凭证
    };
  },
  created() {
    if(this.$route.query.state) this.state = Number(this.$route.query.state);
    if(this.$route.query.id) this.taskId = this.$route.query.id;
    this.whichSidelist=this.$store.state.setting.whichSidelist;
    this.whichSidelistId=this.$store.state.setting.whichSidelistId;
    this.getCertificateTaskDetails(); //获取详情
    this.postFeeStandardList(2)//获取补办项目
    this.postFeeStandardList(3)//获取逾期项目
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
      })
    }
  },
  methods: {
    onPreview() {
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    divMouseover(row,index,state){//移入
      if(row.url){
        this.dialogFlag=true;
        this.diaIndex=`${state}_${index}`;
        this.srcList=[row.url]
      }
    },
    divMouseout(row){//移除
      if(row.url){
        this.dialogFlag=false;
        this.diaIndex='';
      }
    },
    delectImg(row,index,typeImg){
      this.itemRow=row;
      this.itemRow.index=index;
      this.itemRow.typeImg=typeImg;
      this.postFileDelete(row.fileId,index)
    },
    onUpload(row,index,typeImg){
      this.itemRow=row;
      this.itemRow.index=index;
      this.itemRow.typeImg=typeImg;//类型

    },
    requestUpload(file) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.taskId);
      obj.append("businessName", file.name);
      obj.append("businessKind", this.itemRow.typeImg===1?'过户凭证':this.itemRow.typeImg===2?'特殊凭证':'');
      obj.append("businessType", "certificationCompany");//
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.getCertificateTaskDetails()
          this.$message.success(res.message)
        }
      });
    },
    postFileDelete(fileId,index){//删除文件
      let query={
        businessId:this.taskId,
        businessType:'certificationCompany',
        fileId:fileId
      }
      this.$api.postFileDelete(query).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails()
          this.$message.success(res.message)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onSelectRow(row){
      this.forms.payFeeAmount=row.payFeeAmount;
      this.forms.feeBearerId=row.feeBearerId;
      this.forms.beneficiaryId=row.beneficiaryId;
      this.forms.itemId=row.id;
      this.forms.plateType=row.plateType;
      this.form.ownerType=row.ownerType;
    },
    onButton(state){
      let _this=this;
      switch(state){
        case 1://保存
          var arr,arr1=[]
          for(let i=0;i<this.querylist.length;i++){
            let item=this.querylist[i]
            if(item.kind=='materialProcedure'){//车辆材料手续
              arr.push(item.id)
            }
            if(item.kind=='clearFee'){//办证结算费用
              arr1.push(item.id)
            }
          }
          if(_this.state==1){
            _this.postTaskOperaVoucher('voucher_save',arr,arr1)
          }else{
            _this.postTaskOperaOverdue('overdue_save')
          }
          
          break;
        case 2://审核通过
          if(_this.state==1){
            _this.$confirm('您确定反馈材料和费用已通过，并标注为办证完成吗？', '办证完成提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              var arr1=[]
              for(let i=0;i<this.querylist.length;i++){
                let item=this.querylist[i]
                // if(item.kind=='materialProcedure'){//车辆材料手续
                //   arr.push(item.id)
                // }
                if(item.kind=='clearFee'&&item.check===true){//办证结算费用
                  arr1.push(item.id)
                }
              }
              // for(let i=0;i<this.fileList.length;i++){
              //   let item=this.fileList[i]
              //   if(item.checked===true){
              //     imgArr.push(item.id)
              //   }
              // }
              // console.log(this.querylist,'===999===',this.fileList,imgArr)
              _this.postTaskOperaVoucher('voucher_check_success','',arr1)
            }).catch(() => {});
          }else if(_this.state===5){
            _this.postTaskOperaOverdue('overdue_check_success')
          }
          break;
        case 3://审核驳回
          if(_this.state==1){
            this.dialogVisibleCar=true;
            this.formCar='';
          }else if(_this.state===5){
            _this.postTaskOperaOverdue('overdue_check_reject')
          }
          break;
        case 4://增加办证项
          _this.modelText='添加办证项';
          _this.ondialogVisible()
          _this.projectType=false;
          _this.forms={};
          _this.$nextTick(()=>{
            _this.$refs['forms'].clearValidate();
          })
          break;
        case 5://提交审核
          _this.$confirm('您确认办证逾期已完成，并提交审核吗？', '逾期完成提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postTaskOperaOverdue('overdue_check_apply')
          }).catch(() => {});
          break;
        case 6://退回代办费用确认
          _this.$confirm('是否退回代办费用确认？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.postTaskOperaOverdue('back_voucher_waited')
          }).catch(() => {});
          break;
        case 7://返回
          _this.onrouterPage()
          break;
      }
    },
    onSubmitCar(){//修改车牌号
      if(!this.formCar){
        this.$message.warning('请输入驳回原因！')
        return
      }
      this.postTaskOperaVoucher('voucher_check_reject','','',this.formCar)
    },
    onClickBut(row,type,state){//列表的按钮操作,type1逾期，type2办证异常
      if(type==1){//逾期
        switch(state){
          case 1://新增
            this.postOverdueExceptAdd(row,1)
            break;
          case 2://修改
            this.tableEidt=false;
            for(let i=0;i<this.carOverdueList.length;i++){
              let item=this.carOverdueList[i]
              if(row.id===item.id) {
                item.edit=true;
              }
            }
            this.$nextTick(()=>{
              this.tableEidt=true;
            })
            break;
          case 3://删除
            this.$confirm('此操作将删除商户借用证件逾期项，是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.deleteOverdueExcept(row.id)
            })
            break;
          case 4://保存修改
            this.postOverdueExceptUpdate(row,1,row.id)
            break;
        }
      }else{//办证异常
        switch(state){
          case 1://新增
            this.postOverdueExceptAdd(row,2)
            break;
          case 2://修改
            this.tableEidt1=false;
            for(let i=0;i<this.carRuleabnolist.length;i++){
              let item=this.carRuleabnolist[i]
              if(row.id===item.id) {
                item.edit=true;
              }
            }
            this.$nextTick(()=>{
              this.tableEidt1=true;
            })
            
            break;
          case 3://删除
            this.$confirm('此操作将永久删除办证异常项客户补差，是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.deleteOverdueExcept(row.id)
            })
            break;
          case 4://保存修改
            this.postOverdueExceptUpdate(row,2,row.id)
            break;
        }
      }
    },
    onapply(row,state){//办证费用操作（车辆材料和办证项目）
      if(state==1){//修改
        this.modelText='修改办证项';
        this.forms={
          "name": row.kind=='materialProcedure'?row.name:row.itemName,//补办项目ID
          "feeBearerId": row.feeBearerId,//费用承担方Id：1客户，2商户，3车开新
          "chargingFeeAmount": row.chargingFeeAmount,//收取费用金额
          // "beneficiaryName": "xx",//受益方
          "payFeeAmount": row.kind=='materialProcedure'?row.payFeeAmount:row.feeAmount,//支付费用金额
          certCompanyId:row.certCompanyId,//办证公司ID
        }
        this.ondialogVisible()
      }else{
        this.$confirm('确认删除，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deleteCertificateFeeClear(row.id).then(res=>{
            if(res.code==0){
              this.$message.success(res.message)
              this.getCertificateTaskDetails();//详情
            }
          })
        })
      }
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitform(formdata) {
      //提交frorm
      this.$refs[formdata].validate(valid => {
        if (valid) {
          let query={
            certTaskId:this.taskId,
            "itemId": this.forms.itemId,//补办项目ID
            // "carId": this.form.id,
            // "plateType": this.forms.plateType,
            // "ownerType": this.forms.ownerType,
            remark:this.forms.remark,//办证公司ID
          }
          this.$api.postCertificateFeeClearAdd(query).then((res)=>{
            if(res){
              if(res.code==0){
                this.ondialogVisible(); 
                this.getCertificateTaskDetails();
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    onrouterPage() {
      this.bus.$emit("closeSelected");
      this.$router.push({
        path: "/accreditationCommissioner/expenseAudit"
      });
    },
    onrouter(state) {
      //跳转客户商户合同
      let aurl = "/salesManager/contractMaking";
      let id=this.form.customerContract?this.form.customerContract.id:'';//客户合同
      if (state) {
        aurl = "/salesAssistant/merchantContract";
        id=this.form.merchantContract?this.form.merchantContract.id:'';//商户合同
      }
      if(!id){
        this.$message.warning('暂无合同！')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    getCertificateTaskDetails() {
      this.carOverdueList=[];
      this.carRuleabnolist=[];
      this.fileListImg=[{
                  url:'',
                  uploader:'',//上传方
                  uploaderDate:'',//上传时间
                  fileId:'',
                  name:''
                }];
      this.fileList1=[{
                  url:'',
                  uploader:'',//上传方
                  uploaderDate:'',//上传时间
                  fileId:'',
                  name:''
                }];
      //获取详情
      let query = {
        businessId: this.taskId, //
        businessType: "certificationCompany" //业务类别
      };
      this.$api.getCertificateTaskDetails(this.taskId, query).then(res => {
        if (res) {
          if (res.code == 0) {
            //1凭证及费用审核,2添加备注,3修改备注,4查看，5审核-办证逾期审核,6凭证及费用审核查看
            if(!this.$route.query.state){
            if(res.data.taskVoucherFeeCheckStatus===63){//taskVoucherFeeCheckStatus办证专员-凭证费用审核: 61 待结算,62 待审核,63 审核通过,64 审核驳回
              switch(res.data.taskOverdueCheckStatus){
            //taskOverdueCheckStatus办证专员-办证逾期任务状态: 逾期备注141,逾期审核142,逾期审核通过143,逾期审核驳回144
                case 141://逾期备注141
                  this.state=2;//2
                  break;
                case 142://逾期审核
                  this.state=5;
                  break;
                case 144:
                case 143://逾期审核通过143，逾期审核驳回144
                  this.state=4;
                  break;
              }
            }else if(res.data.taskVoucherFeeCheckStatus===64){
              this.state=6;
            }else if(res.data.taskVoucherFeeCheckStatus===62){
              this.state=1;
            }
            }
            
            this.res=res.data;
            this.form = res.data.car;
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }else{
              this.carMerchant=res.data.car.carMerchant;
            }
             this.contractFeeNowNoList=res.data.contractFeeNowNoList;//合同暂缺项列表
            let overdueExceptionList = res.data.overdueExceptionList;//逾期/异常列表
            if(overdueExceptionList) for (let i = 0; i < overdueExceptionList.length; i++) {
              let items = overdueExceptionList[i];
              if (items) {
                if (items.type == 2) {//类型：1逾期，2异常
                  //异常费用表
                  this.carRuleabnolist.push(items);
                } else{
                  //逾期
                  this.carOverdueList.push(items);
                }
              }
            }
            if(this.state==2){
              this.carRuleabnolist.push({add:true})//新增办证异常
              this.carOverdueList.push({add:true})//新增输入项
            }
            this.clearFeeList=res.data.clearFeeList;
            this.materialProcedureList=res.data.materialProcedureList;
            
            //办证公司结算费用列表
            // let arrClear=[]
            // if(res.data.clearFeeList) for(let i=0;i<res.data.clearFeeList.length;i++){
            //   let iis=res.data.clearFeeList[i]
            //   if(iis.certComHide===0){//该条数据对办证公司是否隐藏：0否，1是, 默认0
            //     arrClear.push(iis)
            //   }
            // }
            // this.querylist=[...res.data.materialProcedureList?res.data.materialProcedureList:[],...arrClear]//合并车辆材料列表和办证列表
            // this.querylist=[...res.data.materialProcedureList?res.data.materialProcedureList:[],...res.data.clearFeeList?res.data.clearFeeList:[]]//合并车辆材料列表和办证列表
            let contractRequireList=res.data.car.customerContract.contractRequireList?res.data.car.customerContract.contractRequireList:[];
            let newCR=[]
            contractRequireList.map((item) =>{
               if(item.isSelected===1){
                 newCR.push(item)
                }
            })
            this.contractRequireList=newCR;
            let fileData = res.data.fileList;
            let arr1=[];//过户凭证
            let arr2=[];//特殊凭证
            if (fileData) {
              for (let i = 0; i < fileData.length; i++) {
                let item = fileData[i];
                if (item) {
                  if (item.sysFileBusinessList.length > 0) {
                    let name = item.sysFileBusinessList[0];
                    let dateTime = name.operateTime
                      ? name.operateTime.split(" ")[0]
                      : name.operateTime;

                    switch (name.businessKind) {
                      case "过户凭证":
                      case "标准凭证":
                        var obj={
                          url:item.fullFilePath,
                          uploader:item.createBy,
                          uploaderDate:dateTime,
                          fileId:item.id,
                          name:name.businessName
                        }
                        arr1.push(obj)
                        break;
                      case "特殊凭证":
                        var obj1={
                          url:item.fullFilePath,
                          uploader:item.createBy,//上传方
                          uploaderDate:dateTime,//上传时间
                          fileId:item.id,
                          name:name.businessName
                        }
                        arr2.push(obj1)
                        break;
                    }
                  }
                }
              }
              if(arr1.length>0){
                if(res.data.taskVoucherFeeCheckStatus===62){//taskVoucherFeeCheckStatus办证专员-凭证费用审核: 61 待结算,62 待审核,63 审核通过,64 审核驳回
                  let ot={
                    url:'',
                    uploader:'',//上传方
                    uploaderDate:'',//上传时间
                    fileId:'',
                    name:''
                  }
                  this.fileListImg=arr1.concat(ot);
                }else{
                  this.fileListImg=arr1;
                }
                
              }
              if(arr2.length>0){
                if(res.data.taskVoucherFeeCheckStatus===62){
                  let ot={
                    url:'',
                    uploader:'',//上传方
                    uploaderDate:'',//上传时间
                    fileId:'',
                    name:''
                  }
                  this.fileList1=arr2.concat(ot);
                }else{
                  this.fileList1=arr2;
                }
              }
              // this.$nextTick(()=>{
              //   this.$forceUpdate()
              // })
            }
          }
        }
      });
    },
    postTaskOperaOverdue(state){//办证逾期操作【保存 overdue_save, 审核申请 overdue_check_apply, 退回费用确认 back_voucher_waited, 审核通过 overdue_check_success, 审核驳回 overdue_check_reject】
      let arr=[]
      for(let i=0;i<this.contractFeeNowNoList.length;i++){
        let item=this.contractFeeNowNoList[i]
        let obj={
          certTaskId:this.taskId,
          certContractFeeId:item.id,
          sendType:item.repairSend
        }
        arr.push(obj)
      }
      let query={
        taskId:this.taskId,
        taskOverdueCheckResult:state,
        tempLossForms:arr,//逾期备注暂缺项补充的参数表单
      }
      this.$api.postTaskOperaOverdue(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          if(state==='overdue_check_apply'){
            this.onrouterPage()
          }else{
            this.getCertificateTaskDetails()//详情
          }
        }
      })
    },
    postTaskOperaVoucher(state,arr,arr1,remark){//凭证费用审核操作【保存 voucher_save, 审核通过 voucher_check_success, 审核驳回 voucher_check_reject, 办证公司申请结算 com_clear_apply】
      let query={
        taskId:this.taskId,
        taskVoucherFeeCheckResult:state,
        // certMaterialProcedureIds:arr,//办证材料手续IDs(车辆材料手续)
        // certClearFeeIds:arr1,//办证费用ids【办证公司结算详情页面里的那个办证费用】
        voucherFeeCheckRejectReason:remark,//凭证费用审核驳回原因
      }
      this.$api.postTaskOperaVoucher(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          if(this.formCar){
            this.dialogVisibleCar=false;
          }//单独驳回使用
          if(state==='voucher_check_success'){
            this.onrouterPage()
          }else{
            this.getCertificateTaskDetails()//详情
          }
        }
      })
    },
    deleteOverdueExcept(id){//办证逾期/异常 删除
      this.$api.deleteOverdueExcept(id).then((res)=>{
        if(res){
          if(res.code==0){
            this.getCertificateTaskDetails();
            this.$message.success(res.message)
          }
        }
      })
    },
    postOverdueExceptAdd(row,type){//办证逾期/异常 新增
      let query={
        "certTaskId": this.taskId,
        "itemName": row.itemName.itemName,
        "type": type,//类型：1逾期，2异常
        "overdueDays": row.overdueDays,
        "bearer": row.bearer,
        "benefit": row.benefit,
        "remark": row.remark
      }
      this.$api.postOverdueExceptAdd(query).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails();
          this.$message.success(res.message)
        }
      })
    },
    postFeeStandardList(types){//办证费用标准 列表查询
      this.$api.postFeeStandardList({types:[types]}).then(res=>{
        if(res.code==0){
          if(types===2){
            this.replaceList = res.data;
          }else{
            this.beOverduelist=res.data;
          }
        }
      })
    },
    // getReplaceItem() {
    //   //查询补办项目状态
    //   this.$api.getReplaceItem().then(res => {
    //     if (res) {
    //       if (res.code == 0) {
    //         this.replaceList = res.data;
    //       }
    //     }
    //   });
    // },
    postOverdueExceptUpdate(row,type,id){//办证逾期/异常 更新[不能更新状态]
      let query={
        "certTaskId": this.taskId,
        "itemName": row.itemName,
        "type": type,//类型：1逾期，2异常
        "overdueDays": row.overdueDays,
        "bearer": row.bearer,
        "benefit": row.benefit,
        "remark": row.remark
      }
      this.$api.postOverdueExceptUpdate(id,query).then(res=>{
        if(res.code==0){
          this.getCertificateTaskDetails();
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style >
/* .el-textarea__inner {
  height: 100px;
} */
</style>
<style lang="scss" scoped>
.expenseAudBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.expenseAudBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 342px;
      margin: 0 27px 20px 0;
      .photo_row_imgBox {
        height: 255px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          max-height: 100%;
        }
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          line-height: 44px;
        }
      }
      .photo_row_textBox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
      .photoText{
        margin-left:-20px;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
  .dialogBG{
      position:absolute;
      left:0;
      top:0;
      z-index:1;
      width:100%;
      height:100%;
      background:rgb(255,255,255,0.7);
      display:flex;
      justify-content:center;
      align-items: center;
      font-size: 30px;
      .el-icon-delete{
        position:absolute;
        left:10px;
        top:10px;
      }
    }
}
</style>
