<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="待办证" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="跟进中" name="second" class="mt20"></el-tab-pane>
      <el-tab-pane label="办证完成" name="third" class="mt20"></el-tab-pane>
    </el-tabs>

    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户名称:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w8" >
        <span>商户办证截止日期:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4" v-if="activeName=='first'">
        <span>上传查验单:</span>
        <el-select v-model="params.state" size="medium" clearable>
          <el-option :value="1">需要未上传</el-option>
          <el-option :value="2">需要已上传</el-option>
          <el-option :value="3">不需要</el-option>
        </el-select>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border stripe class="mt20 table-mainbg" :cell-class-name="cellName">
      <el-table-column prop="merchantNo" align="center" label="商户ID">
        <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantNo:''}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" align="center" label="商户姓名"></el-table-column>
      <el-table-column prop="merchantMobile" align="center" label="商户手机号">
        <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantMobile:''}}</template>
      </el-table-column>
      <el-table-column prop="carNo" align="center" label="车牌号码"></el-table-column>
      <el-table-column prop="modelName" align="center" label="车型">
         <template slot-scope="scope">{{scope.row.car?scope.row.car.seriesName:''}}{{scope.row.modelName}}</template>
      </el-table-column>
      <el-table-column prop="merchantDeadline" align="center" label="商户办证截止日期"></el-table-column>
      <el-table-column prop="provinceName" align="center" label="办证办理地">
        <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.areaName}}</template>
      </el-table-column>
      <el-table-column prop="isUrgent" align="center" label="是否加急"></el-table-column>
      <el-table-column prop="isUploadCheckImg" align="center" label="上传查验单" v-if="activeName=='first'">
        <template slot-scope="scope">{{scope.row.car.isHuPlate===1&&scope.row.isUploadCheckImg==='已上传'?'需要已上传':scope.row.car.isHuPlate===1&&!scope.row.isUploadCheckImg?'需要未上传':'不需要'}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="activeName=='first'?'220px':'120px'">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="onSee(scope.row,1)" v-if="activeName=='first'">{{scope.row.isUploadCheckImg=='已上传'?'修改查验单':'上传查验单'}}</el-button>
          <el-button size="mini" type="primary" plain @click="onSee(scope.row,2)" v-if="['first','second'].includes(activeName)">跟进办证</el-button>
          <el-button size="mini" type="primary" plain @click="onSee(scope.row,3)" v-if="activeName=='third'">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog
      :title="titleModel"
      :visible.sync="dialogVisible"
      width="500px">
      <div class="imgButBox" v-show="checkImgUrl">
        <el-image class="imgCheck" :fit="'cover'"
      :src="checkImgUrl" 
      :preview-src-list="[checkImgUrl]">
          <div slot="error">暂无图片</div>
        </el-image>
        <div class="imgDelect" @click="onDeleteRow">
          <i class="el-icon-close"></i>
        </div>
      </div>
      
      <el-upload
      class="tableUpload"
      action v-show="!checkImgUrl"
      list-type="picture-card"
      accept="image/png, image/gif, image/jpeg,image/jpg" 
      :on-change="requestUpload"
      :file-list="fileList"
      :auto-upload="false">
        <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
        <div slot="file" slot-scope="{file}">
          <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="requestUpload('',1)">上传</el-button>
        <el-button @click="dialogVisible = false">暂不上传</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1">
      <img width="100%" :src="checkImgUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        carNo: '',
      },
      querylist: [],
      currentPage: 1, // 页数
      total: 0, // 总页数
      pageSize: 10,
      itemRow:{},
      dialogVisible:false,
      dialogVisible1:false,
      fileList:[],
      obj:new FormData(),
      fileFlag:false,//是否
      titleModel:'上传查验单',
      checkImgUrl:'',
    }
  },
  created() {
    this.init();
  },
  methods: {
    handleSizeChange(row){
      this.pageSize=row;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(row){
      this.currentPage=row;
      this.init()
    },
    search() {
      this.currentPage = 1;
      this.init() ;
    },
    init() {
      let taskStatus=111;
      switch(this.activeName){//办证公司-办证中: 111 待办证,112 跟进中,113 办证完成
        case 'first':
          taskStatus=111;
          break;
        case 'second':
          taskStatus=112;
          break;
        case 'third':
          taskStatus=113;
          break;
      }
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        taskType: 2,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        merchantName:this.params.merchantName,
        taskComHandlingStatuses:[taskStatus],
        carNo:this.params.carNo,
        merchantDeadlineBegin:this.params.date?`${this.params.date[0]}`:'',
        merchantDeadlineEnd:this.params.date?`${this.params.date[1]}`:'',
      };
      switch(this.params.state){
        case 1:
          query.needUploadCheckImg='需要';
          query.isUploadCheckImg='未上传';
          break;
        case 2:
          query.needUploadCheckImg='需要';
          query.isUploadCheckImg='已上传';
          break;
        case 3:
          query.needUploadCheckImg='不需要';
          break;
      }
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    cellName({row}){
      if(row.isUrgent==='加急'){
        return 'tipsColor'
      }
    },
    handleClick() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.init() ;
    },
    onSee(row,state){
      this.itemRow=row;
      this.checkImgUrl=row.checkImgUrl;
      if(state==1){
        if(row.isUploadCheckImg=='已上传'){
          this.titleModel='修改查验单'
        }
        this.dialogVisible=true;
      }else{
        this.$router.push({
          path: '/certificationCompany/certificateProcessingDetail', 
          query: {
            id: row.id,
            // state:state
          }
        })
      }
    },
    requestUpload(file,type) {
      if(file){
        this.fileFlag=true;
        // this.obj = new FormData();
        this.obj.append("mFiles", file.raw);
        this.obj.append("businessId", this.itemRow.id);
        this.obj.append("businessName", this.$$.getFormatMomentTime(null,new Date()));
        this.obj.append("businessType", "查验单");
      }
      if(type==1&&this.fileFlag){
        this.$api.postFileUpload(this.obj).then(res => {
          if (res.code == 0) {
            this.fileFlag=false;
            this.checkImgUrl=res.data[0].fullFilePath;
            this.postTaskOperaProcess();
          }
        });
      }
      
    },
    postTaskOperaProcess(){//s
      let query={
        orderNo:this.itemRow.customerNo,
        imgUrl:this.checkImgUrl,
        imgName:'查验单'
        // taskId:this.itemRow.id,
        // checkImgUrl:this.checkImgUrl,
        // taskProcessManageResult:'com_process_upload'
      }
      this.$api.postTaskOperaProcessUpload(query).then(res=>{
        if(res.code==0){
          this.init();
          this.dialogVisible=false;
          this.$message.success(res.message);
        }
      })
    },
    handlePictureCardPreview() {
      // this.dialogImageUrl = file.url;
      this.dialogVisible1 = true;
    },
    handleRemove(){},
    onDeleteRow(){
      this.checkImgUrl='';
    },
  },
}
</script>

<style scoped lang="scss">
.pageContainer{
  padding: 20px 40px;
  .tag{
     position: absolute;
    width: 53px;
    height: 53px;
    top:0;
    right: 0;
    background: #F56C6C;
    clip-path: polygon(0 0, 100% 0, 100% 100%); /*三角*/
    color:#ffffff;
    .fontText{
      width:53px;
      height:53px;
      font-size:12px;
      transform: rotate(45deg);
    }
  }
  .imgCheck{
    width:148px;
    height:148px;
  }
  .imgButBox{
    position: relative;
    width:148px;
    height:148px;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    .imgDelect{
      position: absolute;
      right: -13px;
      top: -13px;
      cursor: pointer;
    }
  }
  .el-icon-close{
    font-size:28px;
    font-weight:bold;
  }
}
</style>