<template>
  <!-- 宝马合作车辆拍卖结果 -->
  <div class="contenPageBox" >
    <table class="table-oddwholebg table-custom" v-if="biddingCode">
      <tbody>
        <tr>
          <td>任务ID</td><td>{{res.taskId}}</td>
          <td>拍卖编号</td><td>{{res.biddingCode}}</td>
        </tr>
        <tr>
          <td>检测编号</td><td>{{res.inspectionNo}}</td>
          <td>原车主姓名/公司名称</td><td>{{res.preOwnerName}}</td>
        </tr>
        <tr>
          <td>车架号</td><td>{{res.vin}}</td>
          <td>原车主身份证号/机构代码号</td><td>{{res.preOwnerId}}</td>
        </tr>
        <tr>
          <td>成交状态</td>
          <td>
            <el-select v-model="form.auctionStatus" placeholder="请选择" :disabled="disabledAll" size="medium">
              <el-option label="成功" value="0"></el-option>
              <el-option label="流拍" value="1"></el-option>
              <!-- <el-option label="违约" value="2"></el-option> -->
            </el-select>
          </td>
          <td>经销商编号</td><td>{{res.dealerCode}}</td>
        </tr>
        <tr>
          <td>起拍价</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.biddingStartPrice">
              <template slot="append">元</template>
            </el-input>
          </td>
          <td>起拍时间</td><td>{{res.biddingStartTime}}</td>
        </tr>
        <tr>
          <td>最高出价</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.highestBid">
              <template slot="append">元</template>
            </el-input>
          </td>
          <td>拍卖结束时间</td><td>{{res.biddingEndTime}}</td>
        </tr>
        <tr>
          <td>拍卖成交价</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.auctionPrice" type="number" >
              <template slot="append">元</template>
            </el-input>
          </td>
          <td>检测Final报告URL</td><td><span class="color cursor" @click="onOpen(res.inspFinalRptUrl)">{{res.inspFinalRptUrl}}</span></td>
        </tr>
        <tr>
          <td>出价次数</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.numOfBids">
              <template slot="append">次</template>
            </el-input>
          </td>
          <td>检测Final报告名称</td><td>{{res.inspFinalRptName}}</td>
        </tr>
        <tr>
          <td>参拍人数</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.auctionNumber">
              <template slot="append">人</template>
            </el-input>
          </td>
          <td>检测Final报告下载地址</td><td><span class="color cursor" @click="onOpen(res.inspFinalRptDlUrl)">{{res.inspFinalRptDlUrl}}</span></td>
        </tr>
        <tr>
          <td>拍卖佣金</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.auctionCommission">
              <template slot="append">元</template>
            </el-input>
          </td>
          <td>拍卖结果URL</td><td>{{res.aucRptUrl}}</td>
        </tr>
        <tr>
          <td>是否经销商名下</td>
          <td>{{res.transferDealer}}</td>
          <td>拍卖结果名称</td><td>{{res.aucRptName}}</td>
        </tr>
         <tr>
          <td>原车类型</td><td>{{res.preOwnerType==0?'个人':res.preOwnerType==1?'公户':res.preOwnerType}}</td>
          <td>拍卖结果下载地址</td><td>{{res.aucRptDlUrl}}</td>
        </tr>
      </tbody>
    </table>

    <table class="table-oddwholebg table-custom" v-if="id">
      <tbody>
        <tr>
          <td>车架号</td><td>{{res.vin}}</td>
          <td>拍卖编码</td><td>{{res.biddingCode}}</td>
        </tr>
        <tr>
          <td>检测编号</td><td>{{res.inspectionNo}}</td>
          <td>是否经销商名下</td>
          <td>
            <el-select v-model="form.transferDealer" size="medium">
              <el-option label="Y" value="Y"></el-option>
              <el-option label="N" value="N"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>成交状态</td><td>{{res.auctionStatus===0?"成交":res.auctionStatus}}</td>
          <td>原车主类型</td>
          <td>
            <!-- {{res.preOwnerType==0?'个人':res.preOwnerType==1?'公户':res.preOwnerType}} -->
            <el-select v-model="form.preOwnerType" size="medium">
              <el-option label="个人" value="0"></el-option>
              <el-option label="公户" value="1"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>交易发票日期</td>
          <td>
            <el-date-picker
              v-model="form.invoiceDate"
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </td>
          <td>原车主姓名/公司名称</td>
          <td>
            <!-- {{res.preOwnerName}} -->
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.preOwnerName"></el-input>
          </td>
        </tr>
        <tr>
          <td>付款状态</td>
          <td>{{res.pushStatus===10?'待同步付款结果':res.pushStatus===20?'已同步付款结果':res.pushStatus}}</td>
          <td>原车主身份证号/机构代码号</td>
          <td>
            <!-- {{res.preOwnerId}} -->
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.preOwnerId"></el-input>
          </td>
        </tr>
        <tr>
          <td>买家付款时间</td>
          <td>{{res.buyerPayTime}} </td>
          <td>支付经销商时间</td><td>{{res.payDlTime}}</td>
        </tr>
        <tr>
          <td>买家付款金额</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.buyerPayAmount" type="number" disabled>
              <template slot="append">元</template>
            </el-input>
          </td>
          <td>支付经销商金额</td>
          <td>
            <el-input
              placeholder="请输入" size="medium"
              v-model="form.payAmount" type="number" disabled>
              <template slot="append">元</template>
            </el-input>
          </td>
        </tr>
        <tr>
          <td>过户日期</td>
          <td>
             <el-date-picker
              v-model="form.transferFinishTime"
              type="datetime" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </td>
          <td>保留价</td>
          <td>{{res.resversePrice}}</td>
        </tr>
        <tr>
          <td>资金往来凭证</td>
          <td colspan="3">
            <div class="imgRowBox">
              <div class="imgBox">
                <p v-if="imgurl1.voucherType==='pdf'" @click="onOpen(imgurl1.voucherUrl)">{{imgurl1.voucherUrl}}</p>
                <el-image v-else
                  :src="imgurl1.voucherUrl" 
                  fit="contain"
                  :preview-src-list="[imgurl1.voucherUrl]">
                  <div slot="error" class="image-slot">暂无图片</div>
                </el-image>
              </div>
                <p>资金往来凭证</p>
            </div>
          </td>
        </tr>
        <tr>
          <td>过户凭证</td>
          <td colspan="3">
            <div class="flex flex_wrap">
              <div class="imgRowBox">
                <div class="imgBox">
                  <p v-if="imgurl2.voucherType==='pdf'" @click="onOpen(imgurl2.voucherUrl)">{{imgurl2.voucherUrl}}</p>
                  <el-image v-else
                    :src="imgurl2.voucherUrl" 
                    fit="contain"
                    :preview-src-list="[imgurl2.voucherUrl]">
                    <div slot="error" class="image-slot">暂无图片</div>
                  </el-image>
                </div>
                  <p>二手车交易发票</p>
              </div>
              <div class="imgRowBox">
                <div class="imgBox">
                  <p v-if="imgurl3.voucherType==='pdf'" @click="onOpen(imgurl3.voucherUrl)">{{imgurl3.voucherUrl}}</p>
                  <el-image v-else
                    :src="imgurl3.voucherUrl" 
                    fit="contain"
                    :preview-src-list="[imgurl3.voucherUrl]">
                    <div slot="error" class="image-slot">暂无图片</div>
                  </el-image>
                </div>
                  <p>过户后二手车登记证首页</p>
              </div>
              <div class="imgRowBox">
                <div class="imgBox">
                  <p v-if="imgurl4.voucherType==='pdf'" @click="onOpen(imgurl4.voucherUrl)">{{imgurl4.voucherUrl}}</p>
                  <el-image v-else
                    :src="imgurl4.voucherUrl" 
                    fit="contain"
                    :preview-src-list="[imgurl4.voucherUrl]">
                    <div slot="error" class="image-slot">暂无图片</div>
                  </el-image>
                </div>
                  <p>过户后二手车登记证过户页</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    

    <div class="footer" >
      <div class="btn">
        <el-button type="primary" @click="onSubmit(1)" v-if="biddingCode&&stateType">发送拍卖结果给宝马</el-button>
        <el-button type="primary" @click="onSubmit(2)" v-if="id&&stateType">发送支付结果给宝马</el-button>
        <el-button type="primary" @click="onSubmit(3)">关闭</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      stateType:0,//入口状态/**state=0查看没有按钮,1修改**/
      form:{
        payAmount:'',
        buyerPayAmount:'',
        auctionStatus:'',
        biddingStartPrice:'',
        highestBid:'',
        auctionPrice:'',
        numOfBids:'',
        auctionNumber:'',
        auctionCommission:'',
        transferDealer:"",
        preOwnerType:'',
        preOwnerName:'',
        preOwnerId:'',
        transferFinishTime:'',
      },
      // rules:{
      //   carLogistType: [{ required: true, message: '请选择车辆物流方式', trigger: 'change' }],
      //   certType:[{ required: true, message: '请选择办证方式', trigger: 'change' }],
      //   'certCompany':[{ required: true, message: '请选择办证公司', trigger: 'change' }],
      // },
      disabledAll:false,//一键禁用（针对详情）
      res:{},
      showViewer:false,
      srcList:[],
      biddingCode:'',
      channelId:'',
      id:'',//成交后来的详情
      imgurl1:{},
      imgurl2:{},
      imgurl3:{},
      imgurl4:{},
    }
  },
  created(){
    if(this.$route.query.state) this.stateType=Number(this.$route.query.state);
    if(this.$route.query.biddingCode) this.biddingCode=this.$route.query.biddingCode;
    if(this.$route.query.channelId) this.channelId=this.$route.query.channelId;
    //成交后详情
    if(this.$route.query.id) this.id=this.$route.query.id;
    if(this.id){
      this.getDetailsByIdBmw()
    }
    //成交前详情
    if(this.biddingCode){
      this.getAuctionReport()
    }
    
  },
  methods: {
    onOpen(url){
      // let pathData1=this.$router.resolve({
      //   path:'/salesManager/customer',
      // })
      window.open(url,'_blank')
    },
    onSubmit(state){
      switch(state){
        case 1://发送拍卖结果给宝马
          this.$confirm('请确认是否发送拍卖结果给宝马?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postAuctionReportUpdate()
          }).catch(() => {});
          break;
        case 2://发送支付结果给宝马
          if(!this.form.invoiceDate){
            this.$message.warning('交易发票日期不能为空！')
            return
          }
          if(!this.form.buyerPayAmount){
            this.$message.warning('买家付款金额不能为空！')
            return
          }
          if(!this.form.payAmount){
            this.$message.warning('支付经销商金额不能为空！')
            return
          }
          this.$confirm('请确认是否发送支付结果给宝马?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postReceivePaymentInfo()
          }).catch(() => {});
          break;
        case 3:
          this.routePage()
          break;
      }
    },
    postAuctionReportUpdate(){//更新宝马订单任务拍卖报告
      let query={
        "auctionCommission": this.form.auctionCommission,
        "auctionNumber": this.form.auctionNumber,
        "auctionPrice": this.form.auctionPrice,
        "auctionStatus": this.form.auctionStatus,
        "biddingCode": this.res.biddingCode,
        "biddingStartPrice": this.form.biddingStartPrice,
        "highestBid": this.form.highestBid,
        "inspectionNo": this.res.inspectionNo,
        "numOfBids": this.form.numOfBids,
        "taskId": this.res.taskId
      }
      this.$api.postAuctionReportUpdate(query).then(res=>{
        if(res.code==0){
          this.getAuctionReport()
          this.$message.success(res.message)
        }
      })
    },
    postReceivePaymentInfo(){//给宝马发送付款结果
      let query={
        paymentVoucherId:this.id,
        buyerPayAmount:this.form.buyerPayAmount,
        payAmount:this.form.payAmount,
        invoiceDate:this.form.invoiceDate,
        transferDealer:this.form.transferDealer,//是否经销商名下
        preOwnerType:this.form.preOwnerType,//原车主类型
        preOwnerName:this.form.preOwnerName,//原车主姓名
        preOwnerId:this.form.preOwnerId,//原车主身份证号
        transferFinishTime:this.form.transferFinishTime,
      }
      this.$api.postReceivePaymentInfo(query).then(res=>{
        if(res.code==0){
          if(res.data){
            this.getDetailsByIdBmw()//成交后详情
            this.$message.success(res.message)
          }else{
            this.$message.error('操作失败！')
          }
        }
      })
    },
    closeViewer() {
      this.showViewer = false
    },
    onOperate(state){
      switch(state){
        case 1://查看
          this.showViewer=true;
          break;
        case 2://下载
          // window.open(pathData.href,'_blank')
          break;
      }
    },
    getDetailsByIdBmw(){//成交后详情
      this.$api.getDetailsByIdBmw(this.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=JSON.parse(JSON.stringify(res.data));//用来做数据判断
            this.form=JSON.parse(JSON.stringify(res.data));
            if(res.data.paymentVoucherDetaillList){
              for(let i=0;i<res.data.paymentVoucherDetaillList.length;i++){
                let item=res.data.paymentVoucherDetaillList[i]
                switch(item.voucherCode){
                  case '01':
                    this.imgurl1=item;
                    break;
                  case '02':
                    this.imgurl2=item;
                    break;
                  case '03':
                    this.imgurl3=item;
                    break;
                  case '04':
                    this.imgurl4=item;
                    break;
                }
              }
            }
            
          }
        }
      })
    },
    getAuctionReport(){//获取详情
      this.$api.getAuctionReport(this.biddingCode).then((res)=>{
        if(res){
          if(res.code==0){
            
            this.res=JSON.parse(JSON.stringify(res.data));//用来做数据判断
            
            this.form=JSON.parse(JSON.stringify(res.data));
            
          }
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/BMW/BMWPush'
      })
    },
  }
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px 140px;
  .imgRowBox{
    margin:10px 10px 5px;
    width: 200px;
    .imgBox{
      background: #ededed;
      width: 200px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        cursor: pointer;
      }
      .el-image{
        width: 200px;
        height: 150px;
        ::v-deep .image-slot{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
        }
      }
    }
    p{padding:5px 0}
  }
  

}
</style>
