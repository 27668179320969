<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="mb20">
      <el-col :span="6" class="flex flex_align_center flex_between w5">
        <span>拓展专员姓名</span>
        <el-select v-model="forms.extensionerId" size="medium"  clearable filterable >
          <el-option v-for="(item,index) in userlist" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <!-- <el-input v-model="forms.extensionerName" size="medium" ></el-input> -->
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店简称</span>
        <el-input v-model="forms.name" size="medium"></el-input>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg">
      <el-table-column prop="shortName" label="合作店简称" align="center"></el-table-column>
      <el-table-column prop="name" label="合作店全称" align="center"></el-table-column>
      <el-table-column prop="ownerGroup" label="所属集团" align="center"></el-table-column>
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="拓展专员姓名" align="center"></el-table-column>
      <el-table-column prop="statusName" label="状态" align="center">
        <!-- <template slot-scope="scope">{{scope.row.taskStatus==0?'待审核':scope.row.status}}</template> -->
      </el-table-column>
      <el-table-column prop="applyTime" label="申请时间" align="center"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onexamine(scope.row)"
            size="mini"
            class="group-custom"
          >审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms:{},
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      userlist:[],
    };
  },
  created() {
    this.init()//获取列表
    this.queryUserListByRoleAndDepart()//获取拓展专员
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      //数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-账号过期 7-审核失败
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
        extensionerId:this.forms.extensionerId,
        shortName:this.forms.name,
        status:0
      }
      this.$api.getChannelPage(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
            this.querylist=[];
            this.total=0;
          }
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    onexamine(row) {
      //确认modal
      this.$router.push({
        path: "/channel/examineDetails",
        query:{
          // state:row.status,//数据状态 0-待审核 1-审核通过(待签约) 2-审核驳回 3-已签约 4-已解约 5-签约失败 6-已解约 7-已过期
          id:row.id
        }
      });
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//获取拓展专员
      let query={
        roleName:'拓展专员'
      }
      this.$api.queryUserListByRoleAndDepart(query).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
