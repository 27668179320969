<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="待开查验单" name="first"></el-tab-pane>
      <el-tab-pane label="待查验" name="second"></el-tab-pane>
      <el-tab-pane label="已查验" name="third"></el-tab-pane>
    </el-tabs>
    <el-row :gutter="24" class="mt30">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w5">
        <span>成交商户名称:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="carNo" label="车牌号码" key='1' align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" key='2' align="center">
        <!-- <template slot-scope="scope">{{scope.row.car.brandName}}{{scope.row.car.modelName}}&nbsp;{{scope.row.car.styleName}}&nbsp;{{scope.row.car.styleName}}</template> -->
        <template slot-scope="scope">{{scope.row.car.makerName}}&nbsp;{{scope.row.car.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" label="成交商户名称" key='3' align="center">
        <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="certCompany" label="办证公司" key='4' align="center"></el-table-column>
      <el-table-column prop="inviteSuccessTime" label="办证邀约成功时间" key='5' align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <el-table-column prop="contractDate" label="合同签订日期" key='6' align="center"></el-table-column>
      <el-table-column prop="merchantDeadline" label="商户办证截止时间" key='7' align="center" v-if="['first'].includes(activeName)"></el-table-column>
      <!-- <el-table-column prop="preCheckFinishTime" label="预计完成日期" key='8' align="center" v-if="['second'].includes(activeName)"></el-table-column> -->
      <el-table-column prop="validTime" label="查验完成日期" key='9' align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column prop="checkerOwner" label="查验方" key='10' align="center"></el-table-column>
      <el-table-column prop="sellerAssistantName" label="销售助理" key='11' align="center" v-if="['first','second'].includes(activeName)"></el-table-column>
      <el-table-column prop="updateBy" label="操作人" key='12' align="center" v-if="['third'].includes(activeName)"></el-table-column>
      <el-table-column label="操作" key='13' align="center" :width="['first'].includes(activeName)?120:250" v-if="['second','first'].includes(activeName)">
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="['second'].includes(activeName)">去查验</el-button>
          <el-button type="primary" @click="onSubmit(scope.row)" size="mini" v-if="['second'].includes(activeName)">确认查验</el-button>
          <el-button type="primary" @click="onVerified(scope.row)" size="mini" v-if="['first'].includes(activeName)">改为已查验</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      params:{
        carNo:''
      }
    };
  },
  created() {
    if(this.$route.params.state){
      this.activeName=this.$route.params.state;
    }
    
    this.init();
  },
  methods: {
    init(page) {
      let taskStatus=53;
      switch(this.activeName){
        case 'first':
          taskStatus=53;//待开查验单
          break;
        case 'second':
          taskStatus=51;//待查验
          break;
        case 'third':
          taskStatus=52;//已查验
          break;
      }
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        taskType: 4,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        taskPlateStatuses:[taskStatus],
        carNo:this.params.carNo,
        merchantName:this.params.merchantName,
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onVerified(row){
      this.$confirm(`确认将车牌为${row.carNo}的修改为已查验吗？`, '提示', {
          confirmButtonText: '确认修改',
          cancelButtonText: '暂不修改',
          type: 'warning'
        }).then(() => {
         let query={
            taskId:row.id,
            taskPlateCheckResult:'plate_check_confirm'
          }
          this.$api.postTaskOperaPlateFinish(query).then((res)=>{
            if(res){
              if(res.code==0){
                this.init()
              }
            }
          })
        }).catch(() => {});
    },
    onSubmit(row){
      this.$confirm('您确认查验已完成吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         let query={
            taskId:row.id,
            taskPlateCheckResult:'plate_check_confirm'
          }
          this.$api.postTaskOperaPlate(query).then((res)=>{
            if(res){
              if(res.code==0){
                this.init()
              }
            }
          })
        }).catch(() => {});
    },
    onapply(row) {
      let aurl = "/accreditationCommissioner/check";
      this.$router.push({
        path: aurl,
        query: {
          id:row.id
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init()
    }
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>