<template>
  <div class="priceInquiry">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w2">
        <span>年份</span>
        <el-date-picker v-model="params.productYear" type="year" size="medium" placeholder="选择年" value-format="yyyy"> </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车身颜色</span>
        <el-select v-model="params.carColor" placeholder="请选择" size="medium" clearable>
          <!-- <el-option label="所有" value=""></el-option> -->
          <el-option v-for="item in colorOptions" :key="item.id" :label="item.subName" :value="item.subName"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w5">
        <span>价格审核时间</span>
        <el-date-picker v-model="params.auctionPriceAuditTime" type="date" size="medium" value-format="yyyy-MM-dd"> </el-date-picker>
        <!-- <el-date-picker v-model="params.auditTime" type="daterange" size="medium" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="timeChange"> </el-date-picker> -->
      </el-col>
      
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>品牌车型</span>
        <el-input v-model="params.style" size="medium"></el-input>
        <!-- <el-cascader
        v-model="params.style" :props="props"
        :options="brandlist"></el-cascader> -->
      </el-col>
      <el-col v-if="carState==1" :span="5" class="flex flex_align_center flex_between w4">
        <span>商户姓名</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col v-if="carState==1" :span="5" class="flex flex_align_center flex_between w4">
        <span>Vin码</span>
        <el-input v-model="params.vinCode" size="medium"></el-input>
      </el-col>
      <el-col :span="5" style="text-align:right;">
        <el-button type="primary" size="medium" plain @click="init(1)">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="affRegion" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="enquiryTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="isPledge" label="是否抵押车" align="center" v-if="carState==1">
        <template slot-scope="scope">{{scope.row.isPledge==42?'是':scope.row.isPledge==41?'否':''}}</template>
      </el-table-column>
      <el-table-column prop="followShopName" label="跟进门店" align="center" v-if="carState==1"></el-table-column>
      <el-table-column prop="channelName" label="渠道" align="center" v-if="carState==1"></el-table-column>
      <el-table-column prop="productYear" label="年份/车型" align="center">
        <template slot-scope="scope">{{scope.row.productYear}}/{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="isBusiness" label="行驶证使用性质" align="center"></el-table-column>
      <el-table-column prop="grade" label="车辆评级" align="center"></el-table-column>
      <el-table-column prop="auctionPriceCn" label="最高报价" align="center"></el-table-column>
      <el-table-column prop="tradePriceCn" label="核定收车价" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="中标商户" align="center" v-if="carState==1"></el-table-column>
      <el-table-column prop="tradedPriceExpire" label="报价有效期至" align="center"></el-table-column>
      <el-table-column prop="auctionPriceAuditTime" label="价格审核时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="scope">
          <el-button type="primary" @click="onRouter(scope.row,1)" size="mini" v-if="scope.row.priceExpired===1||carState!=3">报价</el-button>
          <el-button type="primary" size="mini" @click="onRouter(scope.row,2)">车辆报告</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt30"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next,sizes, jumper"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page_size"
        :current-page="page_index"
        :total="total"
      ></el-pagination>
  </div>
</template>

<script>
export default {
  props:{
    /**carState 1全部权限，2部分受限查看，3受限查看**/
    carState:{
      type:Number
    },
  },
  data() {
    return {
      params: {
        year: '',
        color: '',
        auditTime: '',
        auditTimeBegin: '',
        auditTimeEnd: '',
        carNo: '',
        style: '',
        name: '',
        vin: ''
      },
      page_size:10,
      page_index:1,
      total:0,
      querylist:[],
      colorOptions: [],
      brandlist:[],//品牌列表
      props:{
        label:'name',
        value:'id',
        children:'carSeriesList'
      }
    }
  },
  created() {
    this.init()
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.brandlist=res;
      })
    }else{
      this.brandlist=this.$store.state.setting.carBrandList;
    }
    this.getQueryCarColor();//车身颜色
  },
  methods: {
    async init(page){
      this.page_index=page?page:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        "carNo": this.params.carNo,
        "vinCode": this.params.vinCode,
        "productYear": this.params.productYear,
        "carColor": this.params.carColor,
        "auctionPriceAuditStartTime": this.params.auctionPriceAuditTime?`${this.params.auctionPriceAuditTime} 00:00:00`:'',
        "auctionPriceAuditEndTime": this.params.auctionPriceAuditTime?`${this.params.auctionPriceAuditTime} 23:59:59`:'',
        "merchantName": this.params.merchantName,
        carType:this.params.style
        // "brandName": this.params.style.length?this.params.style[0]:'',
        // "modelName": this.params.style.length?this.params.style[1]:''
      }
      let res={}
      /**carState 1全部权限，2部分受限查看，3受限查看**/
      switch(this.carState){
        case 1:
         res= await this.$api.postAssessAllList(this.$$.delete_null_properties(query))
          break;
        case 2:
         res= await this.$api.postAssessPartList(this.$$.delete_null_properties(query))
          break;
        case 3:
         res= await this.$api.postAssessNoList(this.$$.delete_null_properties(query))
          break;
      }
      if (res.code == 0) {
        this.querylist = res.data.records;
        this.total = res.data.total;
      }else{
        this.querylist = []
        this.total=0;
      }
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onRouter(row,state){
      let query={
          stateType:state,
          carState:this.carState,
          checkReportId:row.checkReportId,
          carAuctionId:row.carAuctionId,
        }
      if(this.carState==3){
        query.priceExpired=row.priceExpired//价格有效期
      }
      let pathData=this.$router.resolve({
          path:'/testReviewer/offerDetails',
          query:query
      })
      window.open(pathData.href,'_blank')
      // this.$router.push({
      //   path:'/testReviewer/offerDetails',
      //   query:query
      // })
    },
    getQueryCarColor(){//车身颜色
      this.$api.getCheckItemSubsection(3325).then(res=>{
        if(res.code==0){
          this.colorOptions=res.data;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.priceInquiry{
  padding: 20px 40px;
}
@media (max-width: 768px) {
  .priceInquiry{
    width:896px;
  }
}
</style>