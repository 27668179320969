<template>
  <div class="inventBoxBG">
    <!--付违章详情-->
    <div class="inventBox">
      <template v-if="stateType==0">
      <!--车辆信息-->
      <div class="content_modal_textBox">
        <div class="flex flex_between">
          <div class="head_top_title">
            <i></i>
            <span>车辆信息</span>
          </div>
          <!-- <div>合同编号：{{form.customerContract.contractNo}}</div> -->
        </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>车辆号牌</td>
              <td>{{form.carNo}}</td>
              <td>厂牌型号</td>
              <td>{{form.carBrandModelName}}</td>
              <td>发动机号</td>
              <td>{{form.engineNo}}</td>
              <td>颜色</td>
              <td>{{form.carColor}}</td>
            </tr>
            <tr>
              <td>初次登记日期</td>
              <td>{{form.registerDate}}</td>
              <td>年检有效期</td>
              <td>{{form.checkExpire}}</td>
              <td>车架号</td>
              <td>{{form.vinCode}}</td>
              <td>成交类型</td>
              <td>{{car.tradeTypeName}}</td>
            </tr>
            <tr>
              <td>成交价（元）</td>
              <td>{{form.ccTradePrice}}</td>
              <td>报价有效期</td>
              <td>{{form.tradedPriceExpire}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
       <!--收款人信息-->
      <div class="content_modal_textBox mt30">
        <div class="head_top_title">
          <i></i>
          <span>收款人信息</span>
        </div>
        <table class="table-oddwholebg table-custom">
          <tbody>
            <tr>
              <td>收款账号户名</td>
              <td>{{form.accountName}}</td>
              <td>开户行</td>
              <td>{{form.depositBank}}</td>
            </tr>
            <tr>
              <td>银行简称</td>
              <td>{{form.depositBankShortName}}</td>
              <td>账号</td>
              <td>{{form.account}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--付违章费用-->
      <div class="content_modal_textBox mt30">
        <div class="head_top_title">
          <i></i>
          <span>付违章费用</span>
        </div>
        <div>
          <p class="mb10">甲方付违章费用（大写）：<span class="text_decoration">{{form.breakRuleAmount}}（￥：{{form.breakRuleAmountCn}}）</span> </p>
          <template v-if="paymentState==31">
          <p class="mt15">付款方式：
            <el-select v-model="payTypeState" class="w300">
              <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </p>
          <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
            <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
          </template>
          <template v-if="[33,34].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
          <p class="tipsColor mt15">当前余额：{{form.availableBalance}}</p>
          <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
        </div>
      </div>
      </template>
     <template v-else>
       <div class="head_top_title">
          <i></i>
          <span>批量违章支付明细</span>
        </div>
       <el-table :data="querylist" border stripe class="table-mainbg">
        <!-- <el-table-column prop="customerName" label="车主姓名" align="center"></el-table-column> -->
        <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
        <el-table-column prop="accountName" label="收款账号户名" align="center"></el-table-column>
        <el-table-column prop="depositBank" label="开户行" align="center"></el-table-column>
        <el-table-column prop="depositBankShortName" label="银行简称" align="center"></el-table-column>
        <el-table-column prop="account" label="账号" align="center"></el-table-column>
        <el-table-column prop="breakRuleAmount" label="违章金额（元）" align="center"></el-table-column>
      </el-table>
      <p class="mt30"><span class="mr30">批量支付日期：{{form.batchPayTime?form.batchPayTime:'-'}}</span><span class="ml30">批量支付金额（元）：{{form.batchPayAmount}}</span></p>
      <template v-if="paymentState==31">
       <p class="mt15">付款方式：
         <el-select v-model="payTypeState" class="w300">
          <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </p>
        <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
          <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
        </template>
          <template v-if="[33,34].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
        <p class="tipsColor mt15">当前余额：{{form.availableBalance}}</p>
        <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
     </template>
      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31">开始付款</el-button>
          <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32&&form.orderNo">付款完成</el-button>
          <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32&&form.orderNo">付款失败</el-button>
          <el-button type="primary" @click="onReturn">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateType: 0, //0单个，1批量
      querylist: [],
      form: {
        carCustomer:{},
        customerContract:{},
        supplier:{},//客户银行账号列表
      },
      carCustomerAccount:{},
      car:{},
      forms1:{},
      payTypeState:1,//付款方式
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      breakRulePayId:'',//退客户押金付款 ID
      batchPayOrderNo:'',//批量编号（没有批量编号，是需要查批量列表）
      breakRulePayIds:'',//选择批量支付操作
      paymentState:31,//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
    };
  },
  created() {
    // this.stateType = this.$route.query.state;
    if(this.$route.query.breakRulePayId){
      this.breakRulePayId=this.$route.query.breakRulePayId;
      this.stateType=0;
    }
    if(this.$route.query.batchPayOrderNo){
      this.batchPayOrderNo=this.$route.query.batchPayOrderNo;
      this.stateType=1;
    }
    if(this.$route.query.carNos){
      this.stateType=1;
      this.carNos = this.$route.query.carNos;
      let violationList=this.$store.state.setting.violationList;
      this.querylist=JSON.parse(JSON.stringify(violationList))
      let cont=0;
      for(let i=0;i<this.querylist.length;i++){
        let item=this.querylist[i]
        cont+=item.breakRuleAmount;
      }
      this.form.batchPayAmount=cont;
    } 
    if(this.stateType==0){
      this.getBreakRuleDetailByBreakRulePayId()
    }else{
      if(this.batchPayOrderNo){
        this.getBreakRuleBatchDetailsByOrderNo(this.batchPayOrderNo)//根据批次编号查询详情
      }else{
        this.getAvailableAmount()//查询银行账户余额
      }
    }
    
  },
  methods: {
    getBreakRuleDetailByBreakRulePayId(){//获取详情
      this.$api.getBreakRuleDetailByBreakRulePayId(this.breakRulePayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.financePayStatus;
            this.form.supplier=res.data.supplier?res.data.supplier:{};
            this.form.customerContract=res.data.customerContract?res.data.customerContract:{};
            if(res.data.car.carCustomer){//银行信息
              this.carCustomerAccount=res.data.car.carCustomer.carCustomerAccount?res.data.car.carCustomer.carCustomerAccount:{};
            }
            this.car=res.data.car;
          }
        }
      })
    },
    getBreakRuleBatchDetailsByOrderNo(batchPayOrderNo){//根据批次编号查询详情（违章付款）
      this.$api.getBreakRuleBatchDetailsByOrderNo(batchPayOrderNo).then(res=>{
        if(res.code==0){
          this.form=res.data;
          this.payTypeState=res.data.payType==5?5:1;//付款方式
          this.paymentState=res.data.batchPayStatus;//批量支付状态，32付款中，33付款成功，34付款失败
          this.querylist = this.form.brpList?this.form.brpList:[];
        }
      })
    },
    onPayment(state){
      switch(state){
        case 1:
          if(!this.payTypeState){
            this.$message('请选择付款方式！')
            return
          }else{
            if(!this.form.payType&&this.payTypeState!=5){
              this.$message('请选择付款方式！')
              return
            }
          }
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            if(this.stateType==0){
              this.postBreakRuleFinancePay();//财务单个付款
            }else{
              this.postBreakRuleFinanceBatchPay()//财务批量付款
            }
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postBreakRuleFinanceConfirmPaySuccess()//财务确认付款成功
          }).catch(() => {});
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postBreakRuleFinanceConfirmPayFail()//财务确认付款失败
          }).catch(() => {});
          break;
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/finance/illegalPayment',
        query:{
          state:this.stateType
        }
      })
    },
    postBreakRuleFinancePay(){//财务付款单个
      let query={
        "breakRulePayId": this.breakRulePayId,//
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postBreakRuleFinancePay(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getBreakRuleDetailByBreakRulePayId()//
        }
      })
    },
    postBreakRuleFinanceBatchPay(){//财务批量付款
      let breakRulePayIds=[];
      for(let i=0;i<this.querylist.length;i++){
        breakRulePayIds.push(this.querylist[i].id)
      }
      let query={
        "breakRulePayIds": breakRulePayIds,//违章IDS
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postBreakRuleFinanceBatchPay(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.bus.$emit("closeSelected",'router');
          this.$router.push({
            path: "/finance/illegalPaymentDetail",
            query:{
              state:this.stateType,
              batchPayOrderNo:res.data,
            }
          });
          // this.batchPayOrderNo=res.data;
          // this.getBreakRuleBatchDetailsByOrderNo(this.batchPayOrderNo)//根据批次编号查询详情
        }
      })
    },
    postBreakRuleFinanceConfirmPaySuccess(){//财务确认付款成功
      this.$api.postBreakRuleFinanceConfirmPaySuccess(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          if(this.stateType==0){
            this.getBreakRuleDetailByBreakRulePayId()//
          }else{
            if(this.batchPayOrderNo){
              this.getBreakRuleBatchDetailsByOrderNo(this.batchPayOrderNo)//根据批次编号查询详情
            }
          }
        }
      })
    },
    postBreakRuleFinanceConfirmPayFail(){//财务确认付款失败
      this.$api.postBreakRuleFinanceConfirmPayFail(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          if(this.stateType==0){
            this.getBreakRuleDetailByBreakRulePayId()//
          }else{
            if(this.batchPayOrderNo){
              this.getBreakRuleBatchDetailsByOrderNo(this.batchPayOrderNo)//根据批次编号查询详情
            }
          }
        }
      })
    },
    getAvailableAmount(){//查询银行账户余额
      this.$api.getAvailableAmount().then(res=>{
        if(res.code==0){
          this.form.availableBalance=res.data;
          this.$nextTick(()=>{
            this.$forceUpdate()
          })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>