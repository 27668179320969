export default {
  namespaced: true,
	state: {
		routesList: []
	},
  mutations: {
		// 设置路由，菜单中使用到
		getRoutesList(state, data) {
			state.routesList = data;
		},
    SET_ROUTERS: (state, routers) => {
      state.routesList = routers
    }
	},
  actions: {
		// 设置路由，菜单中使用到
		async setRoutesList({ commit }, data) {
			commit('getRoutesList', data);
		},
    GenerateRoutes({ commit }, asyncRouter) {
      commit('SET_ROUTERS', asyncRouter)
    }
	},
}