<template>
<!-- 门店-检测预约-预约后 -->
  <div class="reschedule">
    <div class="ckxTitle serviceRecord">预约成功</div>
    <div class="infoCon flex">
      <div class="half"><span class="label">客户姓名：</span><span>{{res.customer.customerName}}</span></div>
      <div class="half"><span v-if="res.customer.isMortgage == 1">抵押车</span></div>
      <div class="half"><span class="label">车牌号码：</span><span>{{res.customer.carNo}}</span></div>
      <div class="half"><span class="label">品牌车型：</span><span>{{res.customer.carType}}</span></div>
      <div class="half"><span class="label">检测时间：</span><span>{{res.bookTime}}</span></div>
      <div class="half"><span class="label">检测门店：</span><span>{{res.bookShop}}</span></div>
      <div class="half"><span class="label">询价类型：</span><span>{{res.checkTypeName}}</span></div>
      <div class="all flex">
        <span class="label">备注：</span>
        <el-input type="textarea" v-model="res.remark" :rows="4" disabled></el-input>
      </div>
    </div>
    <el-dialog
      title="取消预约"
      :visible.sync="dialogVisible"
      width="400" class="customDialog">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="medium">
        <el-form-item label="取消原因:" prop="cancelReason">
          <el-select v-model="form.cancelReason">
            <el-option v-for="item in cancellist" :key="item.id" :label="item.reasonName" :value="item.reasonName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="reschedule">改约</el-button>
        <el-button type="primary" @click="dialogVisible = true">取消预约</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{
        remark: '',
      },
      rules: {
          cancelReason: [{ required: true, message: '请选择取消原因', trigger: 'change' },]
      },
      res:{
        customer:{}
      },
      dialogVisible:false,
      checkBookId: '',
      cancellist:[],
    }
  },
  created() {
    if(this.$route.query.checkBookId){
      this.checkBookId = this.$route.query.checkBookId;
      this.getQueryCheckBook();
    }else{
      this.$message.warning('未获取用户编号')
    }
    this.getCheckBookCannelReason()//获取取消原因
  },
  methods: {
    reschedule() {
      this.$router.push({
        path: '/testService/storeDR', 
        query: {
          customerId:this.res.customer.id,
          followUpInfoId:this.res.customer.followUpId
        }
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
          if (valid) {
            let query={
              "checkBookId": this.checkBookId,
              "cancelReason": this.form.cancelReason,
              "remark": this.form.remark
            }
            this.$api.updateCheckBookCancel(query).then(res=>{
              if(res.code==0){
                this.$message.success(res.message)
                this.dialogVisible=false;
                this.bus.$emit("closeSelected");
                // this.$router.go(-1);
              }
            })
          }
      });
    },
    // 客户详情查询
    getQueryCheckBook() {
      this.$api.getQueryCheckBook(this.checkBookId).then(res => {
        if(res.code == 0){
          this.res=res.data;
        }
      })
    },
    getCheckBookCannelReason(){//获取取消原因集合
      this.$api.getCheckBookCannelReason({type:'取消'}).then(res=>{
        if(res.code==0){
          this.cancellist=res.data;
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.reschedule{
  padding: 40px;
  .infoCon{
    margin-top: 20px;
    padding-left: 20px;
    width: 560px;
    flex-wrap: wrap;
    .half{
      width: 50%;
      margin-bottom: 15px;
    }
    .all{
      width: 100%;
      margin-bottom: 15px;
      .label{width: 70px;}
    }
    .label{
      margin-right: 5px;
    }
    
  }
}
</style>