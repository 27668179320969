<template>
  <!-- 差异评定详情 -->
  <div class="difEvaluationDetail">
    <el-tabs v-model="activeName"  type="card" class="cardBox" v-if="diffFlag">
      <el-tab-pane label="复检结果" name="first">
      </el-tab-pane>
      <el-tab-pane label="初检报告" name="second">
        <!-- <div class="line flex flex_align_center flex_center">
          <span class="mr30">检测师：{{res.checkerName}}</span>
          <span class="mr30">车牌号码：{{res.carNo}}</span>
          <span class="mr30">使用性质：{{result1.brandName+'-'+result1.modelName}}</span>
          <span class="mr30">年份车型：{{result1.carNo}}</span>
          <span class="mr30">经营区域：{{result1.carNo}}</span>
        </div> -->
      </el-tab-pane>
      <el-tab-pane label="竞拍价格" name="third">
      </el-tab-pane>
    </el-tabs>
    <div :class="diffFlag?'contReportBox':'mt20'" v-show="activeName==='first'">
        <template v-if="result1.merchantDiffList.length>0">
        <div class="ckxTitle mb15">商户提出差异</div>
        <table class="table-custom table-cusHeadbg mb20">
          <thead>
            <tr>
              <td>差异分类</td>
              <td>差异描述</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in result1.merchantDiffList" :key="item.id">
              <td>{{item.judgeType==1?'外观':item.judgeType==2?'内饰':item.judgeType==3?'机械':item.judgeType==4?'骨架':item.judgeType==5?'识别':item.judgeType==6?'手续':''}}</td>
              <td>{{item.judgeContent}}</td>
            </tr>
          </tbody>
        </table>
        </template>
        <div class="ckxTitle mb15">复检测结果</div>
        <div class="line flex flex_align_center flex_center">
          <span class="mr30">复检时间：{{result1.uploadTime}}</span>
          <span class="mr30">检测师：{{result1.checkerName}}</span>
          <span class="mr30">车型：{{result1.carType}}</span>
          <span class="mr30">车牌号：{{result1.carNo}}</span>
        </div>
        <div class="nodiff" v-if="result1.checkDiffList.length==0">无差异</div>
        <el-row :gutter="50" v-for="(item, index) in result1.checkDiffList" :key="item.id">
          <el-col :span="6">
            <div class="mt20 mb15 strong">差异{{index+1}}</div>
            <table class="tableLine">
              <tr><td>{{item.differenceType==0?'类型程度不准确':item.differenceType==1?'原报告未描述':item.differenceType==2?'非差异':''}}</td></tr>
              <tr><td>{{item.differenceFuc1?'实车':''}}{{item.differenceFuc1&&item.differenceFuc2?'、':''}}{{item.differenceFuc2?'记录':''}}{{item.differenceFuc2&&item.differenceFuc3||item.differenceFuc1&&item.differenceFuc3?'、':''}}{{item.differenceFuc3?'路试':''}}</td></tr>
              <tr><td class="tipsColor">{{item.judgeType==1?'外观':item.judgeType==2?'内饰':item.judgeType==3?'机械':item.judgeType==4?'骨架':item.judgeType==5?'识别':item.judgeType==6?'手续':''}}</td></tr>
              <tr><td>{{item.judgeContent}}</td></tr>
              <tr><td>{{item.finalPunishType}}</td></tr>
            </table>
          </el-col>
          <el-col :span="18">
            <div class="imgList" v-if="item.photoList&&item.photoList.length">
              <el-image class="imgBox"
              v-for="(ite,indexx) in item.photoList" :key="`${indexx}_img`" :src="ite" fit="contain"
              :preview-src-list="[ite]">
                <div slot="error">暂无图片</div>
              </el-image>
            </div>
          </el-col>
        </el-row>
        <div class="title_top_nameBox mt20 mb10">其他备注</div>
        <el-input type="textarea" v-model="result1.remark" :autosize="{minRows: 4}" :disabled="true"></el-input>
        <div class="title_top_nameBox mt20 mb10">差异评定</div>
        <el-form :model="evaForm" :rules="evaRules" ref="evaForm"  class="contsForm"  :hide-required-asterisk="true" :disabled="disabled">
          <el-form-item label="是否有差异：" prop="hasDifference">
            <el-radio-group v-model="evaForm.hasDifference" @change="onChange" :disabled="evaluateStatus==92">
              <el-radio :label="11">无差异</el-radio>
              <el-radio :label="12">有差异</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="最终处罚差异类型：" prop="diffLevelType">
            <el-radio-group v-model="evaForm.diffLevelType" :disabled="evaForm.hasDifference==11||evaluateStatus==92">
              <el-radio label="A1">A1</el-radio>
              <el-radio label="A2">A2</el-radio>
              <el-radio label="B1">B1</el-radio>
              <el-radio label="B2">B2</el-radio>
              <el-radio label="B3">B3</el-radio>
              <el-radio label="B4">B4</el-radio>
              <el-radio label="C1">C1</el-radio>
              <el-radio label="C2">C2</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="车价影响：" prop="evaluateResult">
            <el-radio-group v-model="evaForm.evaluateResult" :disabled="evaForm.hasDifference==11||evaluateStatus==92">
              <el-radio :label="33">重判影响车价</el-radio>
              <el-radio :label="32">轻判影响车价</el-radio>
              <el-radio :label="31">不影响车价</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="影响成交的主要原因：" prop="diffType">
            <!-- <template v-if="state==2">{{evaForm.diffType}}</template> -->
            <!-- <template v-if="state==1"> -->
            <div class="flex">
              <el-checkbox-group v-model="evaForm.diffType" :disabled="evaluateStatus==92">
                <el-checkbox label="综合差异" :disabled="evaForm.hasDifference==11" class="mr20"></el-checkbox>
                <el-checkbox v-for="item in diffList" :key="item" :label="item" :disabled="evaForm.hasDifference==11||evaForm.diffType.includes('综合差异')"></el-checkbox>
              </el-checkbox-group>
            </div>
            <!-- </template> -->
          </el-form-item>
          <el-form-item label="确认方式：" prop="confirmType">
            <el-radio-group v-model="evaForm.confirmType" :disabled="evaForm.hasDifference==11||evaluateStatus==92">
              <el-radio label="现场确认">现场确认</el-radio>
              <el-radio label="电话确认">电话确认</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="差异评定备注：" >
            <el-input type="textarea" v-model="evaForm.remark" :disabled="evaluateStatus==92"></el-input>
          </el-form-item>
          <template v-if="state==2">
            <div class="ckxTitle mt20 mb10" >垫资复检商户沟通结果</div>
            <el-form-item label="商户确认结果：" prop="merchantConfirmResult">
              <el-radio-group v-model="evaForm.merchantConfirmResult" :disabled="merchantConfirmStatus==24">
                <el-radio label="确认成交"></el-radio>
                <el-radio label="不成交"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="差异商户沟通备注：" >
              <el-input type="textarea" v-model="evaForm.merchantRemark" :disabled="merchantConfirmStatus==24"></el-input>
            </el-form-item>
          </template>
        </el-form>
        <div class="footer" v-if="evaluateStatus==91&&state==1||merchantConfirmStatus==23&&state==2||state==3&&evaluateStatus==91">
          <div class="btn">
            <el-button type="primary" @click="doSubmit">提交</el-button>
          </div>
        </div>
    </div>
    <Report v-show="activeName==='second'" :res="res" :state="stateReport" ref="report" class="contReportBox"></Report>
    <div v-show="activeName==='third'" class="contPadd">
      <div class="ckxTitle mb10 mt20">竞拍信息</div>
        <div>
          <span class="label">品牌车型：</span><span class="value">{{cres.makerName}}/{{cres.seriesName}}</span>
          <span class="label">客户归属：</span><span class="value">{{cres.affRegion}}</span>
        </div>
        <div class="mt20">
          <span class="label">车牌号码：</span><span class="value">{{cres.carNo}}</span>
          <span class="label">竞拍结束时间：</span><span class="value">{{cres.auctionEndTime}}</span>
        </div>
        <div class="mt20">
          <span class="label">生产年份：</span><span class="value">{{cres.productYear}}</span>
          <span class="label">价格有效截止日：</span><span class="value">{{cres.tradedPriceExpire}}</span>
        </div>
        <div class="mt20 mb20">
          <span class="label">登记年份：</span><span class="value">{{cres.registerDate}}</span>
          <span class="label">当前成交价：</span><span class="value">{{cres.tradePrice}}</span>
        </div>
        <div class="mt20 mb20">
          <span class="label">成交类型：</span><span class="value">{{cres.tradeTypeName}}</span>
        </div>
        <el-table :data="gridData"  border stripe>
          <el-table-column type="index" label="排名" align="center" width="60px"></el-table-column>
        <el-table-column property="merchantName" label="姓名" align="center">
          <template slot-scope="scope">{{scope.row.merchant.merchantName}}</template>
        </el-table-column>
        <el-table-column property="merchantNo" label="账号" align="center">
          <template slot-scope="scope">{{scope.row.merchant.merchantNo}}</template>
        </el-table-column>
        <!-- <el-table-column property="merchantMobile" label="电话" align="center">
          <template slot-scope="scope">{{scope.row.merchant.merchantMobile}}</template>
        </el-table-column> -->
        <el-table-column property="registerCityName" label="注册地" align="center">
          <template slot-scope="scope">{{scope.row.merchant.registerCityName}}</template>
        </el-table-column>
        <el-table-column property="businessCityName" label="经营地" align="center">
          <template slot-scope="scope">{{scope.row.merchant.businessCityName}}</template>
        </el-table-column>
        <el-table-column property="auctionPrice" label="报价（元）" align="center"></el-table-column>
        <el-table-column property="auctionTime" label="报价时间" align="center"></el-table-column>
        <el-table-column property="tradePrice" label="成交价（元）" align="center"></el-table-column>
        <el-table-column property="maintainerName" label="商户维护人" align="center">
           <template slot-scope="scope">{{scope.row.merchant.maintainerName}}</template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
    :title="`第${resConfirm.merchantOrder?resConfirm.merchantOrder:' '}名报价审核`"
    :visible.sync="dialogVisible" 
    width="600px">
      <p class="setFontSize16 mb15">提示：客户责任系统不再处罚商户报价违约100元。</p>
      <el-checkbox v-model="isCustomerReason">客户责任</el-checkbox>
      <div class="flex mt15">
        <el-button type="primary" @click="onSubmitModel(1)" size="medium" >确定</el-button>
        <el-button type="primary" @click="onSubmitModel(2)" size="medium">放弃</el-button>
        <el-button type="primary" @click="onSubmitModel(3)" plain size="medium">发送价格到拍车宝【价格确认下】并置顶</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Report from '@/components/common/report';//检测报告
export default {
  components: {
    Report
  },
  data() {
    return {
      activeName: 'first',
      evaForm: {
        diffType: []
      },
      evaRules: {
        hasDifference: [ { required: true, message: '请选择', trigger: 'change' } ],
        evaluateResult: [ { required: true, message: '请选择', trigger: 'change' } ],
        diffLevelType: [ { required: true, message: '请选择', trigger: 'change' } ],
        confirmType: [ { required: true, message: '请选择', trigger: 'change' } ],
        diffType: [ { required: true, message: '请选择', trigger: 'change' } ],
        merchantConfirmResult: [ { required: true, message: '请选择', trigger: 'change' } ],
      },
      result1: {
        reCheckFinishTime: '',
        checkerName: '',
        brandName: '',
        modelName: '',
        carNo: '',
        merchantDiffList: [],
        checkDiffList:[],
      },
      gridData: [],
      res:{},//数据返回值
      stateReport:'3',//2开头-车辆审核，1-车型审核，11车型查看
      recheckReportId:'',//复检报告ID
      state:'',//默认没有值为查看，1差异评定，2差异确认，3差异复检
      diffList:[],//影响成交的主要原因
      diffFlag:true,//是否展示内容（只有差异评定复检的页面展示）
      carValidId:'',//
      evaluateStatus:'',//评定状态：91待评定，92已评定，默认91
      merchantConfirmStatus:'',//差异确定状态：23待确认，24已确认
      carAuctionId:'',//竞拍id
      cres:{},//竞拍详情
      titleModel:'-',
      dialogVisible:false,
      isCustomerReason:'',
      resConfirm:{},//提交以后得到的返回值
      disabled:false,
    }
  },
  created() {
    if(this.$route.query.state) this.state = this.$route.query.state;
    if(this.$route.query.carValidId) {//获取
      this.carValidId=this.$route.query.carValidId;
      this.getValidEvaluateDetail(this.carValidId) //获取差异详情-复检结果
    }
    if(this.$route.query.title){
      this.$nextTick(()=>{
        this.bus.$emit('updateVisitedTitle',this.$route.query.title)
      })
      if(this.$route.query.title.indexOf('复检详情')!=-1){
        this.diffFlag=false;
        this.disabled=true;
      }
    }
  },
  methods: {
    onSubmitModel(state){
      switch(state){
        case 1://确认
          this.$confirm(`请确认是否当前商户确认报价`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postConfirmPrice(state)
        }).catch(() => {});
          break;
        case 2://放弃
          this.$confirm(`请确认是否当前商户放弃报价`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postConfirmPrice(state)
          }).catch(() => {});
          break;
        case 3://发送价格到拍车宝【价格确认下】并置顶
          this.$confirm('请确认是否发送价格到拍车宝【价格确认下】并置顶?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postAuctionAuditPrice()
          }).catch(() => {});
          break;
      }
    },
    postConfirmPrice(state){//商户确认报价
      let query={
        id:this.resConfirm.nextId,
        "confirmResult": state==1?1:0,//确认结果 0-放弃 1-确认
        "isCustomerReason": this.isCustomerReason?1:0//放弃是否客户原因 0或不传-非客户原因,1-客户原因
      }
      this.$api.postConfirmPrice(query).then(res=>{
        if(res.code == 0){
          this.dialogVisible=false;
          if(res.data.nextId){
              this.bus.$emit("closeSelected",'router');
              this.$router.push({
                path:'/salesAssistant/reservationDetail',
                query:{
                  id:res.data.nextId
                }
              })
            }else{
              this.getValidEvaluateDetail(this.carValidId) //获取差异详情-复检结果
            }
          
          this.$message.success(res.message)
        }
      })
    },
    postAuctionAuditPrice(){//发送价格
      let query={
          carAuctionId:this.carAuctionId,
          onTop:1,//是否需要置顶, 1需要, 0不需要
          operType:'sendPrice',
        }
        this.$api.postAuctionAuditPrice(query).then(res=>{
          if(res.code==0){
            this.$message.success(res.message)
          }
        })
    },
    getRecheckReport(recheckReportId){//获取复检报告
      let query={
        reportId:recheckReportId
      }
      this.$api.getRecheckReport(query).then(res=>{
        if(res.code==0){
          this.result1=res.data;
          this.result1.checkDiffList=res.data.checkDiffList?res.data.checkDiffList:[];
          this.diffList=res.data.checkDiffList.map((item)=>{
            return item.judgeType==1?'外观':item.judgeType==2?'内饰':item.judgeType==3?'机械':item.judgeType==4?'骨架':item.judgeType==5?'识别':item.judgeType==6?'手续':'';
          })
          this.getDetails(res.data.linkReportId)//获取详情(复检详情初检报告)
        }
      })
    },
    getValidEvaluateDetail(id) {//获取差异评定查询详情
      this.$api.getValidEvaluateDetail(id).then( res => {
        if(res.code === 0){
          this.getRecheckReport(res.data.recheckReportId);//获取复检报告
          this.getAuctionPriceFinallyList(res.data.carAuctionId)//获取前三名报价
          this.getAuctionInfo(res.data.carAuctionId)//获取竞拍详情

          this.evaForm = res.data;
          this.evaluateStatus=res.data.evaluateStatus;
          this.carAuctionId=res.data.carAuctionId;
          this.merchantConfirmStatus=res.data.merchantConfirmStatus;
          if(res.data.diffType){
            let arr=res.data.diffType.split(',')
            this.evaForm.diffType=arr
          }else{
            this.evaForm.diffType=[]
          }
          this.$nextTick(()=>{
            this.$refs['evaForm'].clearValidate();
          })
        }
      })
    },
    onChange(){
      this.$nextTick(()=>{
        this.$refs['evaForm'].clearValidate();
      })
    },
    getDetails(reportId){//获取检测报告
      this.$api.getCheckReport(reportId).then(res =>{
        if(res.code==0){
          this.res=res.data
        }
      })
    },
    doSubmit() {
      if(this.state==2){//默认没有值为查看，1差异评定，2差异确认，3差异复检
        this.$refs.evaForm.validateField('merchantConfirmResult',res=>{
          if(!res){
            this.postValidDiffConfirm()
          }
        })
      }else if(this.state==1||this.state==3){
        let validFlag=true;//验证是否通过(默认通过)
        if(this.evaForm.hasDifference==12){
          this.$refs['evaForm'].validate((valid) => {
            if(!valid) {
              validFlag=false;
            }
          });
        }else{
          this.$refs.evaForm.validateField('hasDifference',res=>{
            if(res){
              validFlag=false
            }
          })
        }
        if(validFlag){
          this.postValidDiffEvaluate()//提交差异
        }
      }
    },
    postValidDiffEvaluate(){//提交差异(差异评定过来的页面)
      let query={
        hasDifference:this.evaForm.hasDifference,
        "remark": this.evaForm.remark,
      }
      if(this.evaForm.hasDifference==12){
        query.evaluateResult= this.evaForm.evaluateResult;
        query.diffLevelType= this.evaForm.diffLevelType;
        query.diffType= this.evaForm.diffType.length>0?this.evaForm.diffType.toString():'';
        query.confirmType= this.evaForm.confirmType;
      }
      this.$api.postValidDiffEvaluate(this.evaForm.id,this.$$.delete_null_properties(query)).then(res => {
        if(res.code === 0){
          this.$message.success('操作成功')
          this.getValidEvaluateDetail(this.carValidId) //获取差异详情-复检结果
        }
      })
    },
    postValidDiffConfirm(){//差异确认(差异确认页面)
      let query={
        "merchantConfirmResult": this.evaForm.merchantConfirmResult,//商户确认结果,
        "merchantRemark": this.evaForm.merchantRemark
      }
      this.$api.postValidDiffConfirm(this.evaForm.id,this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          if(this.evaForm.merchantConfirmResult==='不成交'){
            this.resConfirm=res.data;
            this.dialogVisible=true;
          }else{
            this.$message.success('操作成功')
            this.getValidEvaluateDetail(this.carValidId) //获取差异详情-复检结果
          }
          
        }
      })
    },
    getAuctionPriceFinallyList(carAuctionId){//根据车Id查询该场次的前三名商户报价【已确认的报价】
      this.$api.getAuctionPriceFinallyList(carAuctionId).then(res=>{
        if(res.code==0){
          this.gridData=res.data;
        }
      })
    },
    getAuctionInfo(carAuctionId){//根据竞拍Id获取竞拍详情
      this.$api.getAuctionInfo(carAuctionId).then(res=>{
        if(res.code==0){
          this.cres=res.data;
        }
      })
    },
    // routePage(){
    //   this.bus.$emit("closeSelected");
    //   this.$router.push({
    //     path:'/salesAssistant/vehicleRecheck'
    //   })
    // },
  }
}
</script>

<style lang="scss">
.difEvaluationDetail{
  padding: 0 40px 120px;
  .cardBox{
    position: fixed;
    width: 100%;
    z-index: 5;
    background: #ffffff;
    padding-top: 20px;
  }
  .contPadd{
    padding-top:60px;
  }
  .contReportBox{
     padding-top:82px;
  }
  .strong{ font-weight: 700; }
  .line{
    height: 60px;
    background-color: #eeeeee;
  }
  .tableLine{
    width: 100%;
    border-collapse: collapse;
    td{
      border: 1px solid #ccc;
      padding: 10px 20px;
      height:45px;
    }
  }
  .imgList{
    padding-top: 55px;
    .imgBox{
      width: 32%;
      // height: auto;
      height:250px;
      display: inline-block;
      margin-right: 2%;
      background: rgb(0, 0, 0,0.1);
      &:nth-last-child(1){margin-right: 0px;}
    }
  }
  .label{
    width: 140px;
    text-align: right;
    display: inline-block;
    margin-left: -40px;
  }
  .value{
    width: 350px;
    display: inline-block;
  }
  .contsForm{
    .el-form-item{
      margin-bottom:5px;
    }
    .el-form-item__error{
      padding-top:0;
      line-height:0;
    }
  }
  .nodiff{
    font-size:18px;
    text-align:center;
    padding:40px;
    margin-top: 20px;
    border: 1px solid #cccccc;
  }
}

</style>
