<template>
  <!-- 验车入库分配 -->
  <div class="yancherukufenpei">
    <el-row :gutter="24" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>客户编号</span>
        <el-input v-model="params.customerNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>品牌车型</span>
        <el-input v-model="params.brandName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w4">
        <span>预约时间</span>
        <el-date-picker size="medium"
        v-model="params.date" 
        type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" 
        :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" > </el-date-picker>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" size="medium" plain @click="init(1)">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="customerNo" label="客户编号" align="center"></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.brandName}}/{{scope.row.modelName}}</template>
      </el-table-column>
      <el-table-column prop="isPledgeName" label="是否抵押车" align="center"></el-table-column>
      <el-table-column prop="allotTime" label="分配时间" align="center"></el-table-column>
      <el-table-column prop="validFinishTime" label="验车完成时间" align="center"></el-table-column>
      <el-table-column prop="validStatusName" label="验车状态" align="center"></el-table-column>
      <el-table-column prop="hasDifferenceName" label="是否有差异" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onRouter(scope.row)" :disabled="[1,2].includes(scope.row.validStatus)">查看报告</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo: '',
        date: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      itemRow:{},
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page){
      this.page_index=page?page:this.page_index;

      let query={
        recheckType:0,//复检类型 0-垫资复检 1-商户验车 2-商户差异复检
        curPage:this.page_index,
        pageSize:this.page_size,
        allotStatus:12,//验车分配状态：11待分配，12已分配
        customerNo:this.params.customerNo,
        carNo:this.params.carNo,
        appointTimeBegin:this.params.date?this.params.date[0]:'',
        appointTimeEnd:this.params.date?this.params.date[1]:'',
        carType:this.params.brandName,
      }//帮卖经理-验车分配状态：11待分配，12已分配

         
      this.$api.postValidList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.page_total = res.data.total;
        }else{
          this.querylist = [];
          this.page_total = 0;
        }
      })
    },
    handleSizeChange(row){
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onRouter(row){
      this.$router.push({
        path:'/storeManager/difEvaluationDetail',
        query:{
          carValidId:row.carValidId,
          title:'复检报告'
        }
      })
    },
  }
}
</script>


<style scoped lang="scss">
.yancherukufenpei{
  padding: 20px 40px;
}
</style>