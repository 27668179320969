<template>
  <div class="inventBoxBG">
    <div class="inventBox">
       <div class="title_top_nameBox mb20">账户信息</div>
       <el-table :data="querylist" border stripe class="table-mainbg">
        <el-table-column prop="merchantNo" label="商户账号" align="center"></el-table-column>
        <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
        <el-table-column prop="account" label="账号" align="center"></el-table-column>
        <el-table-column prop="depositBankShortName" label="银行简称" align="center"></el-table-column>
        <el-table-column prop="depositBank" label="开户行" align="center"></el-table-column>
      </el-table>
      <div class="title_top_nameBox mt30">{{stateType==1?'退商户预付车款':'退商户保证金'}}</div>
      <p class="mb10 mt20">退费{{stateType==1?'押金':'金额'}}（大写）：<span class="text_decoration">{{form.refundAmountCn}}（￥：{{form.refundAmount}}）</span> </p>
      <template v-if="[31,34].includes(paymentState)">
       <p class="mt15">付款方式：
         <el-select v-model="payTypeState" class="w300" clearable>
          <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </p>
        <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
          <el-radio-button v-for="(item,index) in paymentRadiolist" :key="index" :label="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
         </template>
          <template v-if="[32,33].includes(paymentState)">
             <p class="mt15">付款方式：{{form.payTypeName}}</p>
          </template>
          <template v-if="paymentState==34">
             <p class="mt15">付款失败原因：{{form.bankRspTips}}</p>
          </template>
          <p class="mt15" v-if="form.financeRejectReason&&form.financePayStatus==34">驳回原因：{{form.financeRejectReason}}</p>
        <p class="tipsColor mt15" v-if="paymentState===31">当前余额：{{form.availableBalance}}</p>
        <p class="tipsColor mt15" >银行返回提示语：{{form.bankRspTips}}</p>
    
      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onPayment(1)" v-show="[31,34].includes(paymentState)">开始付款</el-button>
          <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32">付款完成</el-button>
          <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32">付款失败</el-button>
          <el-button type="primary" @click="onPayment(4)" v-show="paymentState==31">退回商成</el-button>
          <el-button type="primary" @click="onReturn">返回</el-button>
        </div>
      </div>
    </div>

    <el-dialog
    title="提示"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" >请输入退回原因：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      querylist: [],
      form: {},
      payTypeState:1,//付款方式
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      stateType:1,//1为预付款详情，2为保证金详情，页面来源
      paymentState:'',//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
      merchantRefundPayId:'',//商户退款ID
    };
  },
  created() {
    this.stateType = this.$route.query.type;
    if(this.$route.query.id) this.merchantRefundPayId=this.$route.query.id;
    this.getRefundDetailsByRefundId()//获取详情
  },
  methods: {
    getRefundDetailsByRefundId(){//获取详情
      this.$api.getRefundDetailsByRefundId(this.merchantRefundPayId).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data;
            this.paymentState=res.data.financePayStatus;
            let obj={
              merchantNo:res.data.merchantNo,
              merchantName:res.data.merchantName,
            }
            if(this.form.merchantAccount){
              obj.account=this.form.merchantAccount.account;
              obj.depositBank=this.form.merchantAccount.depositBank;
              obj.depositBankShortName=this.form.merchantAccount.depositBankShortName
            }
            this.querylist=[obj]
          }
        }
      })
    },
    onPayment(state){
      let _this=this;
      switch(state){
        case 1:
          if(!_this.payTypeState){
            _this.$message('请选择付款方式！')
            return
          }else{
            if(!_this.form.payType&&_this.payTypeState!=5){
              _this.$message('请选择付款方式！')
              return
            }
          }
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            _this.postMerchantRefundPayFinancePay();//付款
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            _this.postMerchantRefundPayFinanceConfirmPaySuccess()//成功
          }).catch(() => {});
          
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
           _this.postMerchantRefundPayFinanceConfirmPayFail()//失败
          }).catch(() => {});
          break;
        case 4:
          _this.dialogVisibleCar=true;
          _this.formCar='';
          break;
      }
    },
    onSubmitCar(){//修改退回原因
      let _this=this;
      if(!this.formCar){
        this.$message.warning('请输入退回原因!')
        return
      }
      let query={
        orderNo:_this.form.orderNo,
        rejectReason:this.formCar
      }
      _this.$api.postPayFinanceReject(query).then(res=>{
        if(res.code==0){
          _this.$message.success(res.message)
          this.dialogVisibleCar=false;
          _this.getRefundDetailsByRefundId()//获取详情
        }
      })
    },
    onReturn(){
      this.bus.$emit("closeSelected");
      // this.$router.push({
      //   path:'/finance/merchant/returnBond',
      //   query:{
      //     state:this.stateType
      //   }
      // })
    },
    postMerchantRefundPayFinancePay(){//财务付款
      let query={
        "merchantRefundPayId": this.merchantRefundPayId,//客户成交/退车辆付款ID
        "payType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postMerchantRefundPayFinancePay(query).then(res=>{
        if(res.code==0){
          this.getRefundDetailsByRefundId();
          this.$message.success(res.message)
        }
      })
    },
    postMerchantRefundPayFinanceConfirmPaySuccess(){//财务确认付款成功
      this.$api.postMerchantRefundPayFinanceConfirmPaySuccess(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.getRefundDetailsByRefundId();
          this.$message.success(res.message)
        }
      })
    },
    postMerchantRefundPayFinanceConfirmPayFail(){//财务确认付款失败
      this.$api.postMerchantRefundPayFinanceConfirmPayFail(this.form.orderNo).then(res=>{
        if(res.code==0){
          this.getRefundDetailsByRefundId();
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px 120px;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>