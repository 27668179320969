<template>
  <div class="currencyConterBox">
    <el-row :gutter="24" class="">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10">
        <span>子渠道编号</span>
        <el-input v-model="forms.channelCode" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10">
        <span>合作店简称</span>
        <el-input v-model="forms.shortName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10">
        <span>审核状态</span>
        <el-select v-model="forms.status" size="medium" placeholder="请选择" clearable>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2" class="flex flex_align_center flex_between w3 ml30 mb10">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt10">
      <el-table-column prop="channelCode" label="渠道编号" align="center" ></el-table-column>
      <el-table-column prop="shortName" label="合作店简称" align="center" ></el-table-column>
      <el-table-column prop="ownerGroup" label="所属集团" align="center" ></el-table-column>
      <el-table-column prop="area" label="区域" align="center" ></el-table-column>
      <el-table-column prop="address" label="公司地址" align="center" ></el-table-column>
      <el-table-column prop="accountAuditStatusName" label="审核状态" align="center" ></el-table-column>
      <el-table-column prop="accountSubmitTime" label="提交时间" align="center" ></el-table-column>
      <el-table-column prop="accountAuditTime" label="审核时间" align="center" ></el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <el-button type="primary" @click="onSee(scope.row)" size="mini" plain v-if="scope.row.accountAuditStatus===1">详情</el-button>
          <el-button type="primary" @click="onSee(scope.row)" size="mini" plain v-else>审核</el-button>
        </template>
      </el-table-column>
    </el-table>
      <el-pagination class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total">
      </el-pagination>
  </div>
</template>
<script>
export default {
  data(){
    return{
      querylist:[],//
      forms:{
        carNo:'',
        state:'',
        date:''
      },
      total:0,
      currentPage:1,
      pageSize:10,
    }
  },
  created(){
    this.init();
  },
  methods:{
    init(page){
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,//当前页数
        "pageSize": this.pageSize,
        channelCode:this.forms.channelCode,
        shortName:this.forms.shortName,
        accountAuditStatusList:this.forms.status||this.forms.status==0?[this.forms.status]:'',
      }
      this.$api.postPageByAccountStatus(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
              this.querylist=[];
              this.total=0;
          }
        }
      })
    },
    onSee(row){
      this.$router.push({
        path:'/finance/accountReviewDetails',
        query:{
          id:row.id
        }
      })
    },
    handleSizeChange(e){
      this.pageSize=e;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(e){
      this.currentPage=e;
      this.init()
    },
  }
}
</script>

<style lang="scss" scoped>
.currencyConterBox{
    padding: 40px;
}
</style>