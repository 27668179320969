<template>
  <!-- 车辆复检 -->
  <div class="vehicleRecheck">
    <el-row :gutter="20" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户姓名</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>检测状态</span>
        <el-select v-model="params.checkStatus" size="medium" clearable>
          <el-option v-for="item in statelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="merchantName" label="商户姓名" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="makerName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="allotTime" label="分配时间" align="center"></el-table-column>
      <el-table-column prop="validStatusName" label="检测状态" align="center"></el-table-column>
      <el-table-column prop="recheckType" label="复检类型" align="center">
        <template slot-scope="scope">{{scope.row.recheckType==2?'商户差异复检':scope.row.recheckType==1?'商户验车':''}}</template>
      </el-table-column>
      <el-table-column prop="validFinishTime" label="复检完成时间" align="center"></el-table-column>
      <el-table-column prop="checkerName" label="检测师" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="onRouter(scope.row)" :disabled="[1,2,4].includes(scope.row.validStatus)">差异评定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      statelist:[{id:1,name:'待复检'},{id:2,name:'复检中'},{id:3,name:'复检完成'},{id:4,name:'复检失败'}],
      distributionDialog: false,
      params: {
        checkStatus:''
      },
      itemRow:{},//当前选择的数据
    }
  },
  created(){
    this.init()
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    init(page) {
      this.page_index=page?page:this.page_index;
      let query={
        recheckType:2,//复检类型 0-垫资复检 1-商户验车 2-商户差异复检
        curPage:this.page_index,
        pageSize:this.page_size,
        allotStatus:12,//验车分配状态：11待分配，12已分配
        validStatus:this.params.checkStatus,
        merchantName:this.params.merchantName,
        carNo:this.params.carNo,
      }
      this.$api.postValidList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist=res.data.records;
          this.page_total=res.data.total;
        }else{
          this.querylist=[];
          this.page_total=0;
        }
      })
    },
    onRouter(row){
      this.$router.push({
        path: '/storeManager/difEvaluationDetail',
        query:{
          carValidId:row.carValidId,
          state:3,//默认没有值为查看，1差异评定，2差异确认，3差异复检
          title:'商户差异评定'
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.vehicleRecheck{
  padding: 20px 40px;
}
</style>