<template>
  <div class="pageContainer bgF5" :style="{'height':pageHeight}">
    <task :infolist="infolist" :title="''"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
    };
  },
  created() {
    this.getHomePage();
  },
  methods: {
    getHomePage() {
      this.$api.getHomePage().then((res) => {
        if (res.code == 0) {
          let newArr= [
            {
              num: '',
              name: '待申请办证',
              minTitle:'（代商户）',
              star: 0,
              url:'/certificationCompany/certificateApplication'
            },
            {
              num: '',
              name: '待办证',
              star: 0,
              url:'/certificationCompany/certificateProcessing'
            }
          ]
          let newArr1= [
            {
              num: '',
              name: '待结算',
              star: 0,
              url:'/certificationCompany/settlementManagement'
            },
            {
              num: '',
              name: '结算驳回',
              star: 0,
              url:'/certificationCompany/settlementManagement?state=third'
            }
          ]
          let newArr2= [
            {
              num: '',
              name: '补办手续',
              star: 0,
              url:'/certificationCompany/completeFormalities'
            }
          ]
          this.infolist=[{name:"办证",list:newArr},{name:"结算",list:newArr1},{name:"延伸服务",list:newArr2}];
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>
