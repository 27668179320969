export default {
  namespaced: true,
	state: {
		permission: []
	},
  mutations: {
		// 设置路由，菜单中使用到
		getPermission(state, data) {
			state.permission = data;
		},
    setPermission: (state, permission) => {
      state.permission = permission
    }
	},
  actions: {
		// 设置路由，菜单中使用到
		async getPermission({ commit }, data) {
			commit('getPermission', data);
		},
    setPermission({ commit }, permission) {
      commit('setPermission', permission)
    }
	},
}