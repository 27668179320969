<template>
  <!-- 商户验车 -->
  <div class="vehicleInspection">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="待验车" name="first">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>商户名称</span>
            <el-input v-model="params.merchantName"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号码</span>
            <el-input v-model="params.carNo"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>商户预约时间</span>
             <el-date-picker v-model="params.date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>销售助理</span>
            <el-input v-model="params.sellerAssistantName"></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>处理状态</span>
            <el-select v-model="params.status" placeholder="请选择状态" clearable>
              <el-option label="请选择" value=""></el-option>
              <el-option label="未发送" :value="21"></el-option>
              <el-option label="已发送" :value="22"></el-option>
              <el-option label="未分配" :value="11"></el-option>
              <el-option label="已分配" :value="12"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg mt30">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="makerName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="mcTradePrice" label="成交价" align="center"></el-table-column>
          <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
          <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
          <el-table-column prop="appointTime" label="商户预约时间" align="center">
            <template slot-scope="scope">
              <!--过期时间标红-->
              <!-- <p :class="new Date(scope.row.appointTime).getTime()<new Date(new Date().toLocaleDateString()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p> -->
              <p :class="new Date(scope.row.appointTime?scope.row.appointTime.split(' ')[0]:scope.row.appointTime).getTime()==new Date($$.getTody()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="isPledgeName" label="抵押车" align="center"></el-table-column>
          <el-table-column prop="validTypeName" label="查验方式" align="center"></el-table-column>
          <el-table-column prop="allotStatus" label="处理状态" align="center"><!--商户验车查验方式：51现场查验，52远程查验-->
            <template slot-scope="scope">{{scope.row.validType==51?scope.row.sendStatusName:scope.row.allotStatusName}}</template>
          </el-table-column>
          <!-- <el-table-column prop="merchantSendStatusName" label="发送状态" align="center"></el-table-column> -->
          <el-table-column prop="followUpUserName" label="销售助理" align="center"></el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template slot-scope="scope">
              <el-button type="primary" @click="ondistribution(scope.row,1)" size="mini">发送看车单</el-button>
              <el-button type="primary" @click="ondistribution(scope.row,2)" size="mini">分配检测师</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          :current-page="page_index"
          :total="page_total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="验车中" name="second">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>商户名称</span>
            <el-input v-model="params1.merchantName"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号码</span>
            <el-input v-model="params1.carNo"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>商户预约时间</span>
            <el-date-picker v-model="params1.date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>销售助理</span>
            <el-input v-model="params1.sellerAssistantName"></el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt30">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="ccTradePrice" label="成交价" align="center"></el-table-column>
          <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
          <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
          <el-table-column prop="appointTime" label="商户预约时间" align="center">
            <template slot-scope="scope">
               <p :class="new Date(scope.row.appointTime?scope.row.appointTime.split(' ')[0]:scope.row.appointTime).getTime()==new Date($$.getTody()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p>
              <!-- <p :class="new Date(scope.row.appointTime).getTime()<new Date(new Date().toLocaleDateString()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p> -->
            </template>
          </el-table-column>
          <el-table-column prop="isPledgeName" label="抵押车" align="center"></el-table-column>
          <el-table-column prop="validTypeName" label="查验方式" align="center"></el-table-column>
          <el-table-column prop="followUpUserName" label="销售助理" align="center"></el-table-column>
          <el-table-column prop="checkerName" label="查验人员" align="center">
            <template slot-scope="scope">{{scope.row.validType==51?scope.row.merchantName:scope.row.checkerName}}</template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size1"
          :current-page="page_index1"
          :total="page_total1"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="验车完成" name="third">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>商户名称</span>
            <el-input v-model="params2.merchantName"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号码</span>
            <el-input v-model="params2.carNo"></el-input>
          </el-col>
          <el-col :span="7" class="flex flex_align_center flex_between w5">
            <span>商户预约时间</span>
            <el-date-picker v-model="params2.date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>销售助理</span>
            <el-input v-model="params2.sellerAssistantName"></el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist2" border stripe class="table-mainbg mt30">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.makerName}}&nbsp;{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="ccTradePrice" label="成交价" align="center"></el-table-column>
          <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
          <el-table-column prop="merchantName" label="商户名称" align="center"></el-table-column>
          <el-table-column prop="appointTime" label="商户预约时间" align="center">
            <template slot-scope="scope">
               <p :class="new Date(scope.row.appointTime?scope.row.appointTime.split(' ')[0]:scope.row.appointTime).getTime()==new Date($$.getTody()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p>
              <!-- <p :class="new Date(scope.row.appointTime).getTime()<new Date(new Date().toLocaleDateString()).getTime()?'tipsColor':''">{{scope.row.appointTime}}</p> -->
            </template>
          </el-table-column>
          <el-table-column prop="isPledgeName" label="抵押车" align="center"></el-table-column>
          <el-table-column prop="validTypeName" label="查验方式" align="center"></el-table-column>
          <el-table-column prop="followUpUserName" label="销售助理" align="center"></el-table-column>
          <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onresults(scope.row)" size="mini">结果处理</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size2"
          :current-page="page_index2"
          :total="page_total2"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!-- 发送看车单 -->
    <el-dialog title="发送看车单" :visible.sync="sendTicketDialog" center>
      <div><span class="mr30">商户：{{modalform.merchantName}}</span><span>成交类型：{{modalform.tradeTypeName}}</span></div>
      <div class="mt10"><span>看车时间：{{modalform.merchantValidTime}}</span></div>
      <div class="mt20 bold">看车地点</div>
      <div class="mt10"><span>地址：{{modalform.merchantValidAddress}}</span></div>
      <!-- <div class="mt10"><span>存放位置：{{modalform.stockObjInName}}{{modalform.stockObjAreaName}}{{modalform.stockObjLocationName}}</span></div> -->
      <div class="mt20 bold">车辆信息</div>
      <div class="mt10"><span class="mr30">车型：{{modalform.makerName}}&nbsp;{{modalform.seriesName}}</span><span>车牌：{{modalform.carNo}}</span></div>
      <!-- <div class="mt20 tipsColor" style="text-align:center;">钥匙领取码：{{modalform.keyCode}}</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sendTicketDialog = false">取消</el-button>
        <el-button type="primary" @click="onsaveDis(1)">确认发送</el-button>
      </span>
    </el-dialog>
    <!-- 分配 -->
    <el-dialog :visible.sync="distributionDialog" title="检测师分配" center>
      <div class="flex flex_around flex_align_center">
        <span>客户姓名：{{itemrow.customerName}}</span>
        <span>品牌车型：{{itemrow.makerName}}/{{itemrow.seriesName}}</span>
        <span>车牌号码：{{itemrow.carNo}}</span>
      </div>
      <div class="mt20 mr10">请选择检测师:</div>
      <div class="flex flex_wrap" >
        <el-radio-group v-model="inspector" size="medium">
          <el-radio v-for="item in roleList" :key="item.id" :label="item.id" class="mt10">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <!-- <div style="padding-left:50px;">注：只展示预约门店的检测师名单</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onsaveDis(2)">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      querylist: [],
      querylist1:[],
      querylist2:[],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      page_index2: 1, // 页数
      page_total2: 0, // 总页数
      page_size2: 10,
      activeName: 'first',
      params: {
        merchantName: '',
        carNo: '',
        date: '',
        status: ''
      },
      params1:{},
      params2:{},
      sendTicketDialog: false,
      distributionDialog: false,
      inspector: '',
      itemrow:{},
      roleList:[],//检测师列表
      modalform:{},
    }
  },
  created(){
    this.init()
    this.queryUserListByRoleAndDepart();//获取检测师
  },
  methods: {
    init(page){
      let query={}
      query.recheckType=1;//复检类型 0-垫资复检 1-商户验车 2-商户差异复检
      switch(this.activeName){
        case 'first':
          this.page_index=page?page:this.page_index;

          query.validStatus=1;//验车状态，1待验车，2验车中，3验车完成，4取消验车
          query.merchantName=this.params.merchantName;//商户名称
          query.carNo=this.params.carNo;
          query.followUpUserName=this.params.sellerAssistantName;//销售助理
          query.appointTimeBegin=this.params.date?`${this.params.date} 00:00:00`:'';
          query.appointTimeEnd=this.params.date?`${this.params.date} 23:59:59`:'';//客户预约检测时间
          query.curPage=this.page_index;
          query.pageSize=this.page_size;
          switch(this.params.status){
            case 11:
            case 12:
              query.allotStatus=this.params.status;
            break;
            case 21:
            case 22:
              query.sendStatus=this.params.status;
            break;
          }
          break;
        case 'second':
          this.page_index1=page?page:this.page_index1;

          query.validStatus=2;
          query.merchantName=this.params1.merchantName;
          query.carNo=this.params1.carNo;
          query.followUpUserName=this.params1.sellerAssistantName;
          query.appointTimeBegin=this.params1.date?`${this.params1.date} 00:00:00`:'';
          query.appointTimeEnd=this.params1.date?`${this.params1.date} 23:59:59`:'';
          query.curPage=this.page_index1;
          query.pageSize=this.page_size1;
          break;
        case 'third':
          this.page_index2=page?page:this.page_index2;

          query.validStatus=3;
          query.merchantName=this.params2.merchantName;
          query.carNo=this.params2.carNo;
          query.followUpUserName=this.params2.sellerAssistantName;
          query.appointTimeBegin=this.params2.date?`${this.params2.date} 00:00:00`:'';
          query.appointTimeEnd=this.params2.date?`${this.params2.date} 23:59:59`:'';
          query.curPage=this.page_index2;
          query.pageSize=this.page_size2;
          break;
      }
      this.$api.postValidList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            switch(this.activeName){
              case 'first':
                this.querylist = res.data.records;
                this.page_total= res.data.total;
                break;
              case 'second':
                this.querylist1 = res.data.records;
                this.page_total1= res.data.total;
                break;
              case 'third':
                this.querylist2 = res.data.records;
                this.page_total2= res.data.total;
                break;
            }
          }else{
            switch(this.activeName){
              case 'first':
                this.querylist = [];
                this.page_total = 0;
                break;
              case 'second':
                this.querylist1 = [];
                this.page_total1 = 0;
                break;
              case 'third':
                this.querylist2 = [];
                this.page_total2 = 0;
                break;
            }
          }
        }
      })
    },
    handleSizeChange(row){
      switch(this.activeName){
        case 'first':
          this.page_size=row;
          this.page_index=1;
          break;
        case 'second':
          this.page_size1=row;
          this.page_index1=1;
          break;
        case 'third':
          this.page_size2=row;
          this.page_index2=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(row){
      switch(this.activeName){
        case 'first':
          this.page_index=row;
          break;
        case 'second':
          this.page_index1=row;
          break;
        case 'third':
          this.page_index2=row;
          break;
      }
      this.init()
    },
    onresults(row){
      this.$router.push({
        path: '/salesAssistant/handleDif',
        query:{
          carValidId:row.carValidId,
          title:'验车结果'
        }
      })
    },
    onsaveDis(state){
      if(state==1){
        this.postValidCarValid('merchant_send')//发送看车单
      }else{
        this.postValidCarValid('checker_allot',this.inspector)//分配检测师
      }
    },
    postValidCarValid(operaType,checkerId){//验车操作，包含 【分配检测师 商户验车-发送看车单, 商户验车-远程查验-检测师领取验车检测任务, 商户验车-本地查验-商户领取完钥匙, 商户验车完成, 商户取消验车】 等操作
      let query={
        checkerId:checkerId,
        operaType:operaType,
        validId:this.itemrow.id
      }
      this.$api.postValidCarValid(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            if(checkerId){
              this.distributionDialog=false;
            }else{
              this.sendTicketDialog=false;
            }
            this.init()
          }
        }
      })
    },
    ondistribution(row,state){
      this.itemrow=JSON.parse(JSON.stringify(row));
      if(state==1){
        this.getSendDetail()//交付中心-商户验车 查询商户看车单详情
        this.sendTicketDialog=true;
      }else{
        this.distributionDialog=true;
      }
    },
    queryUserListByRoleAndDepart(){
      let query={
        departmentName:'交付中心',
        roleName:'检测师'
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.roleList=res.data;
          }
        }
      })
    },
    getSendDetail(){//交付中心-商户验车 查询商户看车单详情
      this.$api.getSendDetail(this.itemrow.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.modalform=res.data?res.data:{};
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.vehicleInspection{
  padding: 20px 40px;
}
</style>