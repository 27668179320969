<template>
  <div class="accountChange">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>客户名</span>
        <el-input v-model="params.customerName" size="medium"></el-input>
      </el-col>
      <el-col :span="7" class="flex flex_align_center flex_between w5">
        <span>签订成功时间</span>
        <el-date-picker v-model="params.createTime" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="2">
       <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column prop="contractNo" label="合同编号" align="center" ></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
      <el-table-column prop="brandName" label="品牌车型" align="center">
        <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
      </el-table-column>
      <el-table-column prop="carTradedPrice" label="成交价" align="center"></el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
      <el-table-column prop="customerName" label="客户名称" align="center"></el-table-column>
      <el-table-column prop="sellerName" label="成交归属" align="center"></el-table-column>
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="appointTradeTime" label="预约成交时间" align="center"></el-table-column>
      <el-table-column prop="paymentFinishedTime" label="付款完成时间" align="center"></el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="onsee(scope.row)" size="mini">修改银行账号</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        customerName: '',
        carNo: '',
        createTime: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created(){
    this.init()
  },
  methods: {
    init(page){
      this.page_index=page?page:this.page_index;
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        contractStatus:[14],//客户合同状态，11待领取，12制作中，13签订中，14签订成功，15签订失败，16待付款，17已付款
        carNo:this.params.carNo,//车牌号
        customerName:this.params.customerName,//客户名称
        beginSignSuccessDate:this.params.createTime?this.params.createTime[0]:'',//签订成功日期begin
        endSignSuccessDate:this.params.createTime?this.params.createTime[1]:'',//签订成功日期end
      }
      this.$api.getCustomerList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.page_total=res.data.total;
          }else{
            this.querylist=[];
            this.page_total=0;
          }
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    onsee(row){
      this.$router.push({
        path: '/salesManager/accountChangeDetail',
        query:{
          id:row.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.accountChange{
  padding: 20px 40px;
}
</style>