<template>
<div class="customer" :style="`padding-bottom:${res.validity===1?'100px':''}`">
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="130px" class="customerForm">
    <el-row :gutter="20" style="display:flex;">
      <el-col :span="12">
        <div class="leftInfo">
            <div class="formItem">
              <div class="ckxTitle">客户基本信息</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="客户名称：" prop="customerName">
                    <el-input v-model="ruleForm.customerName" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="主要联系电话：">
                    <el-input v-model="ruleForm.mainPhone" placeholder="请输入手机号" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户归属：">
                    <el-input value="上海" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="次要联系电话：" >
                    <el-col :xl="res.otherPhone?18:24" :lg="res.otherPhone?18:24" class="colsePadding">
                      <el-input v-model="ruleForm.otherPhone" placeholder="请输入手机号" :disabled="res.otherPhone?true:false" ></el-input>
                    </el-col>
                     <el-col :xl="4" :lg="6" v-if="res.otherPhone">
                       <el-button plain size="mini" type="primary" @click="getPhoneVis">修改次要电话</el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="渠道来源：">
                    <el-cascader v-model="ruleForm.channel" :options="channelList" :disabled="res.channel?true:false"></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="创建时间：">
                    <p>{{ruleForm.createDate}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户编号：">
                    <p>{{ruleForm.customerNo}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="formItem">
              <div class="ckxTitle">经营范围</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="车牌号码：" prop="carNo">
                    <el-input v-model="ruleForm.carNo" class="carNo" :disabled="res.carNo?true:false"></el-input>
                    <template v-if="!res.carNo">
                      <el-button plain size="mini" type="primary" @click="getCarId(1)" :disabled="res.validity===1?false:true" >生成临牌</el-button>
                      <el-button type="primary" size="mini" plain @click="getCustomerByCarNo">排重</el-button>
                    </template>
                     <el-button plain size="mini" type="primary" @click="getCarId(2)" v-else >修改车牌</el-button>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="初登年份：">
                    <el-date-picker v-model="ruleForm.registrationYear" type="year" placeholder="选择年" value-format="yyyy"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="是否黄牛：">
                    <el-radio-group v-model="ruleForm.isHn">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="是否抵押贷款：">
                    <el-radio-group v-model="ruleForm.isMortgage">
                      <el-radio :label="0">否</el-radio>
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="-1">未识别</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="品牌车型：">
                  <el-cascader
                    v-model="ruleForm.carModel" size="medium"
                    placeholder="请选择"
                    :options="optionsCar"
                    :props="propCar"
                    filterable></el-cascader>
                </el-form-item>
                </el-col>
              </el-row>
              <div>
                
              </div>
            </div>
            <div class="formItem">
              <div class="ckxTitle">业务信息</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="客户类型：" label-width="140px" prop="customerType">
                    <el-select v-model="ruleForm.customerType" placeholder="请选择客户类型" clearable>
                      <el-option v-for="item in $store.state.setting.customerTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="创建人：">
                    <span>{{ruleForm.createUserName}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="下次联系时间：" label-width="140px" prop="nextContactTime">
                    <el-date-picker v-model="ruleForm.nextContactTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="检维归属：">
                    <div class="flex">
                      <span>{{ruleForm.assignMaintainUserName}}</span>
                      <Remind class="ml20" :userId='ruleForm.assignMaintainUserId' v-if="ruleForm.assignMaintainUserName" 
                      :disabled="res.validity===1?false:true" :plateNumber="ruleForm.carNo" :followUpInfoId="followUpInfoId"></Remind>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="是否入库：" label-width="140px" prop="isPutStorage">
                    <el-radio-group v-model="ruleForm.isPutStorage">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="12" :md="24">
                  <el-form-item label="成交归属：">
                    <div class="flex">
                      <span>{{ruleForm.assignTradeUserName}}</span>
                      <Remind class="ml20" :userId='ruleForm.assignTradeUserId' v-if="ruleForm.assignTradeUserName" :disabled="res.validity===1?false:true"  :plateNumber="ruleForm.carNo" :followUpInfoId="followUpInfoId"></Remind>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <!-- 预约状态 -->
          <div class="aStatus" v-show="res.validity===0">
            <div class="div1"><div class="div2"></div></div>
            <div class="div3">已放弃</div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="rightInfo">
          <Record :customerDealBookList="customerDealBookList" :priceNoteList="priceNoteList" :res="res" :serviceNoteList="serviceNoteList" :customerCheckBookList="customerCheckBookList" :relatedCallList="relatedCallList" 
          :resReport="resReport"
          :recordList="recordList"
          @cancelDeal="cancelDeal" @init="init"></Record>
          <div class="ckxTitle serviceRecord">服务记录</div>
          <el-form-item label="记录类型："  label-width="110px">
            <el-cascader v-model="ruleForm.recordType" :options="titleList" class="recordTypeWidth" :props="{ value: 'id', label: 'title', children: 'smallTitles' }"></el-cascader>
          </el-form-item>
          <el-form-item label="内容" class="formContent" label-width="0">
            <el-input type="textarea" v-model="ruleForm.content" :rows="4"></el-input>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
  <div class="footer" v-if="res.validity===1">
    <div class="btn">
      <el-button type="primary" @click="doSubmit">保存</el-button>
      <!-- <el-button type="primary" @click="copyBuild" v-has-any-permission="['onlineService','admin']">复制新建</el-button> -->
      <el-button type="primary" @click="copyBuild" >复制新建</el-button>
      <el-button type="primary" @click="giveUpCustomer" >放弃</el-button>
    </div>
  </div>

  <el-dialog title="车牌排重结果" :visible.sync="dialogTableVisible">
    <el-table :data="customerList" border stripe >
      <el-table-column property="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column property="carType" label="品牌车型" align="center"></el-table-column>
      <el-table-column property="customerName" label="客户姓名" align="center"></el-table-column>
      <el-table-column property="phone" label="联系电话" align="center"></el-table-column>
      <el-table-column property="createDate" label="创建时间" align="center"></el-table-column>
      <el-table-column property="phaseName" label="业务状态" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goCustomer(scope.row.followUpId)">载入</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <el-dialog
    title="修改次要电话"
    :visible.sync="dialogVisiblePhone"  width="400px">
    <div class="flex flex_align_center">
      <span>次要联系电话：</span>
      <el-input v-model="formPhone.phone" size="medium" class="w200"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitPhone">提交</el-button>
      <el-button @click="dialogVisiblePhone = false">取 消</el-button>
    </span>
  </el-dialog>

  <el-dialog
    title="请输入车牌号"
    :visible.sync="dialogVisibleCar" width="350px">
    <el-input v-model="formCar" class="w300"></el-input>
    <!-- <el-button plain size="mini" type="primary" @click="getCarId" :disabled="res.validity===1?false:true" >生成临牌</el-button> -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>

</div>
</template>

<script>
import Record from '@/components/common/cServiceRecord.vue'
import Remind from '@/components/common/remind.vue'
export default {
  components: {
    Record, Remind
  },
  data() {
    let self = this;
    const checkSecondPhone = (rule, value, callback) => {
      // if(value === ''){
      //   callback()
      // // }else if (value && value == self.ruleForm.mainPhone) {
      // //   return callback(new Error('次要联系方式不能与主要联系方式一样'))
      // } else {
      //   const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      //   if (reg.test(value)) {
      //     callback()
      //   } else {
      //     return callback(new Error('请输入正确的手机号'))
      //   }
      // }
    }
    return {
      ruleForm: {
        customerName: '',
        seriesId: '',
        mainPhone: '',
        otherPhone: '',
        channel: [],
        brandId: '',
        carModel:[],
        customerNo: '',
        carNo: '',
        isHn: '',
        registrationYear: '',
        isMortgage: '',
        customerType: null,
        nextContactTime: null,
        isPutStorage: '',
        createUserName: '',
        assignMaintainUserName: '',
        assignTradeUserName: '',
        recordType: '',
        content: '',
        id: '',
        createDate: '',
      },
      formCar:'',
      dialogVisibleCar:false,
      dialogVisiblePhone:false,
      formPhone:{
        phone:'',
        customerNo:''
      },
      rules: {
        customerName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        // otherPhone: [{ validator: checkSecondPhone, trigger: 'blur' }],
        otherPhone: [{required: true, message: '不能为空',trigger: 'blur' }],
        customerType: [{ required: true, message: '请选择', trigger: 'change' }],
        nextContactTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        isPutStorage: [{ required: true, message: '请选择', trigger: 'change' }],
        recordType: [{ required: true, message: '请选择', trigger: 'change' }],
        content: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        carNo: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      optionsCar:[],//品牌车型
      propCar:{
        label:'name',
        value:'id',
        children:'carSeriesList',
        checkStrictly: true 
      },
      channelList: [],
      serviceNoteList: [],//服务记录信息
      customerCheckBookList: [],//检测预约列表
      customerDealBookList: [],//成交预约列表
      relatedCallList: [],//相关来电列表
      priceNoteList: [],//竞价历史列表
      resReport:{},//检测报告
      recordList:[],
      titleList: [],
      res:{},
      channelId: null,
      followUpInfoId: '',//业务跟进Id
      dialogTableVisible: false,
      customerList: [],
    }
  },
  created() {
    this.customerGetServiceTitle();//获取记录类型
    if(this.$route.query.followUpInfoId){
      this.followUpInfoId = Number(this.$route.query.followUpInfoId);
      this.customerQueryCustomerByFollowUpId();
    }else{
      this.$message.warning('未获取业务跟进ID')
    }
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.optionsCar=res;
      })
    }else{
      this.optionsCar=this.$store.state.setting.carBrandList;
    }
  },
  methods: {
    cancelDeal(){
      this.$confirm(`您是否确认取消检测预约`, '二次确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          "checkBookId": this.res.checkBookId,
          "cancelReason": `${this.roleName}取消检测`,
          // "remark": ""
        }
        this.$api.updateCheckBookCancel(query).then(res=>{
          if(res.code==0){
            this.customerQueryCustomerByFollowUpId()//获取详情
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    getPhoneVis(){
      this.dialogVisiblePhone=true;
      this.formPhone.phone='';
    },
    init(){
      this.customerQueryCustomerByFollowUpId();//重新获取数据
    },
    onSubmitCar(){//修改车牌号
      let _this=this;
      if(!this.formCar){
        this.$message.warning('请输入车牌号！')
        return
      }else{
        const reg = /[!@#$%^&*(),.?":。，=·～{}|<> ]/;
        if (reg.test(this.formCar)) {
          this.$message.warning('车牌号不能输入特殊字符！')
          return
        }
      }
      let query={
          newCarNo:this.formCar,
          customerId:this.res.id
        }//修改车牌号
        _this.$api.postAlterCarNo(query).then(res=>{
          if(res.code===0){
            _this.customerQueryCustomerByFollowUpId();
            _this.$message.success(res.message)
            this.dialogVisibleCar=false;
          }
        })
    },
    onSubmitPhone(){//修改次要电话
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      if (!reg.test(this.formPhone.phone)) {
        this.$message.warning('请输入正确的手机号!')
        return
      }
      let query={
        "customerId": this.res.id,
        "phone": this.formPhone.phone
      }
      this.$api.postUpdateOtherPhone(this.res.id,query).then(res=>{
        if(res.code==0){
          this.dialogVisiblePhone=false;
          this.customerQueryCustomerByFollowUpId()
          this.$message.success(res.message)
        }
      })
    },
    // 客户详情查询
    async customerQueryCustomerByFollowUpId() {
      let res = await this.$api.customerQueryCustomerByFollowUpId(this.followUpInfoId);
      if(res.code == 0){
        this.res=JSON.parse(JSON.stringify(res.data));
        this.ruleForm=JSON.parse(JSON.stringify(res.data));
        this.channelId=res.data.channelId;

        if(res.data.checkReportId){
          this.getCheckReport()//获取检测报告
        }
        if(res.data.checkBookId){
          this.getCustomerRecordList();//根据业务跟进ID查询客户概况
        }

        //品牌车型
        if(res.data.seriesId){
          this.ruleForm.carModel=[res.data.makerId,res.data.seriesId]
        }else{
          if(res.data.makerId){
            this.ruleForm.carModel=[res.data.makerId]
          }else{
            this.ruleForm.carModel=[]
          }
        }
        this.customerQueryChannel();//渠道来源
        // if(res.data.createDate){
        //   this.ruleForm.createDate = this.$$.getFormatMomentTime('YYYY-MM-DD HH:mm:ss',res.data.createDate);
        // }
        
        this.$set(this.ruleForm, 'assignMaintainUserId', res.data.assignMaintainUserId)
        this.$set(this.ruleForm, 'assignTradeUserId', res.data.assignTradeUserId)
        if(res.data.serviceNoteList.length){//服务记录信息
          this.serviceNoteList = res.data.serviceNoteList;
        }
        if(res.data.customerCheckBookList){//检测预约列表
          this.customerCheckBookList = res.data.customerCheckBookList?res.data.customerCheckBookList:[];
        }
        if(res.data.customerDealBookList){//成交预约列表
          this.customerDealBookList = res.data.customerDealBookList?res.data.customerDealBookList:[];
        }
        if(res.data.relatedCallList){//相关来电列表
          this.relatedCallList = res.data.relatedCallList?res.data.relatedCallList:[];
        }
        if(res.data.priceNoteList){//竞价历史列表
          this.priceNoteList = res.data.priceNoteList?res.data.priceNoteList:[];
        }
        this.$nextTick(()=>{
            this.$refs['ruleForm'].clearValidate();
          })
         
      }
    },
    getCustomerByCarNo() {//拍重
      this.customerList = [];
      this.$refs['ruleForm'].validateField('carNo',(validMsg)=>{
        if (!validMsg) {
          this.$api.getCustomerByCarNoList(this.ruleForm.carNo).then(res => {
            if(res.code  === 0){
              if(res.data.length === 0){
                this.$message.success('车牌号可用')
              }else if(res.data.length > 0){
                this.customerList = res.data;
                this.dialogTableVisible = true
              }
            }
          })
        }else{
          return false;
        }
      })
    },
    goCustomer(followUpId) {
      if(followUpId){
        let path='/testService/customer'//全部跳到详情页面
        let user=this.$$.roleName(this.$store.state.user.userData)
        if(user){
          if(user.name.indexOf('帮卖经理')!=-1){
            path='/salesManager/customer'//全部跳到详情页面
          }else if(user.name.indexOf('在线客服')!=-1){
            path='/onlineService/customer'
          }
        }
        this.$router.push({
          path: path, 
          query: {followUpInfoId: followUpId}
        })
      }else{
        this.$message.warning('客户跟进业务ID缺失')
      }
    },
    // 生成临牌
    getCarId(type){
      if(type==1){
        this.$api.customerGenerateTempCarNo().then(res => {
          if(res.code == 0){
            this.ruleForm.carNo = res.data;
            this.$refs.ruleForm.validateField('carNo', () => {})
          }
        })
      }else{
        this.dialogVisibleCar=true;
        this.formCar='';
      }
    },
    giveUpCustomer(){//放弃
      this.$api.postGiveUpCustomer({followUpInfoId:this.followUpInfoId}).then(res=>{
        if(res.code==0){
          this.customerQueryCustomerByFollowUpId()//详情
          this.$message.success(res.message)
        }
      })
    },
    doSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            id: this.ruleForm.id,
            customerName: this.ruleForm.customerName,
            otherPhone: this.ruleForm.otherPhone,
            isHn: this.ruleForm.isHn,
            channelId: this.ruleForm.channel?this.ruleForm.channel[1]:'',
            customerType: this.ruleForm.customerType,
            isPutStorage: this.ruleForm.isPutStorage,
            content: this.ruleForm.content,
            serviceTitle: this.ruleForm.recordType?this.ruleForm.recordType[1]:'',//小标题ID
            carNo: this.ruleForm.carNo,
            registrationYear: this.ruleForm.registrationYear,
            isMortgage: this.ruleForm.isMortgage,
            makerId: this.ruleForm.carModel.length>0?this.ruleForm.carModel[0]:'',//车品牌厂家ID
            seriesId: this.ruleForm.carModel.length>1?this.ruleForm.carModel[1]:'',//车系ID
            nextContactTime: this.ruleForm.nextContactTime,
          }
          this.$api.customerEditCustomer(this.$$.delete_null_properties(params)).then(res => {
            if(res.code == 0){
              if(this.res.carNo&&!this.res.otherPhone&&this.ruleForm.otherPhone){//修改次要电话
                this.postUpdateOtherPhone().then(()=>{
                  this.customerQueryCustomerByFollowUpId()//获取详情
                })
              }else if(!this.res.carNo&&!this.res.otherPhone&&!this.ruleForm.otherPhone&&this.ruleForm.carNo){
                this.postAlterCarNo().then(()=>{//修改车牌
                  this.customerQueryCustomerByFollowUpId()//获取详情
                })
              }else if(!this.res.carNo&&!this.res.otherPhone&&this.ruleForm.otherPhone&&this.ruleForm.carNo){//一起修改
                Promise.all([this.postUpdateOtherPhone(),this.postAlterCarNo()]).then(()=>{
                  this.customerQueryCustomerByFollowUpId()//获取详情
                })
              }else{
                this.customerQueryCustomerByFollowUpId()//获取详情
              }
              this.$message.success(res.message)
            }
          })
        } else {
          this.$nextTick(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      });
    },
    postUpdateOtherPhone(){//修改次要电话
      return new Promise((resolve,reject)=>{
        if(!this.ruleForm.otherPhone){
          resolve(true)
          return
        }
        let query={
          customerId:this.res.id,
          phone:this.ruleForm.otherPhone
        }
        this.$api.postUpdateOtherPhone(this.res.id,query).then(res=>{
          if(res.code==0){
            resolve(res.data)
          }else{
            reject(res.data)
          }
        })
      })
      
    },
    postAlterCarNo(){//修改车牌号
      return new Promise((resolve,reject)=>{
        let query={
          newCarNo:this.ruleForm.carNo,
          customerId:this.res.id,
        }
        this.$api.postAlterCarNo(this.res.id,query).then(res=>{
          if(res.code==0){
              resolve(res.data)
            }else{
              reject(res.data)
            }
        })
      })
    },
    customerQueryChannel() {
      this.$datadic.getCustomerQueryChannel().then(res => {
        this.channelList = res;
        for(let i=0;i<this.channelList.length;i++){
          let item=this.channelList[i]
          for(let j=0;j<item.channelSubs.length;j++){
            let iss=item.channelSubs[j]
            if(iss.id==this.channelId){
              this.ruleForm.channel = [item.id, iss.id]
              return
            }
          }
        }
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    copyBuild() {//复制新建
      let query={
        copyType:0,//复制类型 0-复制新建 1-同车不同牌 2-同牌不同车
        followUpInfoId:this.followUpInfoId
      }
      this.$api.postCustomerCopyCustomer(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.customerQueryCustomerByFollowUpId()//详情
          setTimeout(()=>{
            this.$router.push({
              path:'/onlineService/customer',
              query:{
                followUpInfoId:res.data
              }
            })
          },500)
          // let url=window.location.href.split('=')
          // let newu=`${url[0]}=${res.data}`
          // window.open(newu, "_blank");
        }
      })
    },
    customerGetServiceTitle() {//服务记录标题-大小标题
      this.$datadic.getServiceTitle().then(res => {
        this.titleList = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    getCheckReport(){//获取检测报告
      this.$api.getCheckReport(this.res.checkReportId).then(res=>{
        if(res.code==0){
          this.resReport=res.data;
        }
      })
    },
    getCustomerRecordList(){//根据业务跟进ID查询客户概况
      this.$api.getCustomerRecordList(this.followUpInfoId,1).then(res=>{
        if(res.code==0){
          this.recordList=res.data;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.customer{
  background: #f5f5f5;
  min-height: 100vh;
  padding-top: 20px;
  // padding-bottom: 100px;
  .leftInfo{position: relative;}
  .leftInfo, .rightInfo{background: #fff;height: 100%;}
  .rightInfo{padding: 30px 40px;}
  .customerForm{
    .formItem{
      padding: 30px 40px 10px;
      .ckxTitle{margin-bottom: 30px;}
      &>.flex:last-child{border-width: 0;}
      .tag-read{margin-left: 20px;}
      .carNo{width: 130px;margin-right: 20px;}
      .ml20{margin-left: 20px;}
    }
  }
  .serviceRecord{margin: 40px 0 30px;}
  .recordTypeWidth{width: 322px;}
  .aStatus{
    position: absolute;
    width: 144px;
    height: 144px;
    top: 0;
    right: 0;
    .div1{
      border: 72px solid transparent;
      border-top: 72px solid #0099FF;
      border-right: 72px solid #0099FF;
      position: relative;
      .div2{
        border: 30px solid transparent;
        border-top: 30px solid #fff;
        border-right: 30px solid #fff;
        position: absolute;
        top: -72px;
        right: -72px;
      }
    }
    .div3{
      position: absolute;
      top: 31px;
      right: -11px;
      color: #fff;
      width: 120px;
      line-height: 40px;
      text-align: center;
      transform: rotate(45deg);
      font-size: 22px;
    }
  }
  .colsePadding{
    margin-left:-12px;
    margin-right:-12px;
  }
}
</style>
<style>
.customer .formContent .el-form-item__label{ text-align: left; width: 100px !important;}
.customer .formContent .el-form-item__content{ margin-left: 0px;}
</style>