import Vue from 'vue'
import Vuex from 'vuex'
import routesList from '@/store/modules/routesList.js';
import permission from '@/store/modules/permission.js';
import setting from '@/store/modules/setting.js';
import tagsView from '@/store/modules/tagsView.js';
import user from '@/store/modules/user.js';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    routesList,
    permission,
    setting,
    tagsView,
    user
  }
})
