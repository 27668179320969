<template>
  <div class="chengjiaoyuyue">
    <div class="ckxTitle">竞拍信息</div>
    <div class="mt20">
      <span class="label">品牌车型：</span><span class="value">{{eres.brandName}}/{{eres.modelName}}</span>
      <span class="label">客户归属：</span><span class="value">{{eres.affRegion}}</span>
    </div>
    <div class="mt20">
      <span class="label">车牌号码：</span><span class="value">{{eres.carNo}}</span>
      <span class="label">价格确认时间：</span><span class="value">{{eres.auctionEndTime}}</span>
    </div>
    <div class="mt20">
      <span class="label">当前成交价：</span><span class="value">{{eres.tradePrice}}</span>
      <span class="label">价格有效截止日期：</span><span class="value">{{eres.tradedPriceExpire}}</span>
    </div>
    <div class="mt20 mb20">
      <span class="label">商户名次：</span><span class="value">第{{eres.merchantOrder}}名</span>
      <span class="label">成交类型：</span><span class="value">{{eres.tradeTypeName}}</span>
    </div>
    <el-form ref="form" :model="form" label-width="120px" size="medium">
      <el-form-item label="客户意向:">
        <el-radio-group v-model="intention" :disabled="priceTimeFlage?false:true">
          <el-radio-button :label="0" v-if="[1,2].includes(state)">同意</el-radio-button>
          <el-radio-button :label="1" v-if="[3,4].includes(state)">改约</el-radio-button>
          <el-radio-button :label="2" v-if="[1,2].includes(state)">待定</el-radio-button>
          <el-radio-button :label="3" v-if="[1,2].includes(state)">放弃</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否沟通:" v-if="form.dealType===0&&[0,1].includes(intention)">
        <el-radio-group v-model="form.isComm">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发起门店:" v-show="[0,1].includes(intention)&&state">
        <el-select style="width: 220px;" v-model="form.submitShop" :disabled="form.submitShopId?true:false" @change="changeShopS" value-key="id">
          <el-option v-for="(item,index) in slaunchShoplist" :key="index" :label="item.name" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="成交门店:" v-show="[0,1].includes(intention)">
        <el-select style="width: 220px;" v-model="form.bookShopId" @change="changeShop" :disabled="priceTimeFlage?false:true">
          <el-option v-for="(item,index) in shoplist" :key="index" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <div v-show="form.dealType===1&&[0,1].includes(intention)">
        <el-radio-group v-model="dateType" @change="queryDealCapacity(form.bookShopId)">
          <el-radio-button label="当日"></el-radio-button>
          <el-radio-button label="次日"></el-radio-button>
        </el-radio-group>
        <CapacityTable :tableList="capacitylist" :state="1" title="成交产能" class="mt10 mb20" @clickTime="clickTime"></CapacityTable>
      </div>
      <el-form-item label="上次预约时间:" v-show="[3,4].includes(state)&&[1].includes(intention)" label-width="150px">
        <p>{{form.bookTime}}</p>
      </el-form-item>
      <el-form-item :label="`${[3,4].includes(state)&&[0,1].includes(intention)?'改约成交时间':'预约成交时间'}:`" v-show="[1,3,4].includes(state)&&[0,1].includes(intention)" >
        <el-date-picker
          v-model="form.bookTimeSu" class="w300"
          type="datetime" default-time="9:00:00" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" 
          :disabled="priceTimeFlage?false:true"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="客户预约成交时间:" v-show="[2].includes(state)&&[0].includes(intention)" label-width="150px">
        <!-- <el-time-select v-model="form.bookTime" :picker-options="{step: '00:30'}" placeholder="选择时间"></el-time-select> -->
        <el-date-picker
          v-model="form.bookTime" class="w300" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :disabled="priceTimeFlage?false:true"
          type="datetime" default-time="9:00:00"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="商户建议成交时间:" label-width="150px" v-if="[2,4].includes(state)&&[0,1].includes(intention)&&form.suggestDealTime">
       <div class="flex">
          <p class="mr20 mb10">{{form.suggestDealTime}}</p>
          <el-radio-group v-model="intention2">
            <el-radio-button :label="1">同意</el-radio-button>
            <el-radio-button :label="0">拒绝</el-radio-button>
          </el-radio-group>
       </div>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input style="width: 500px;" type="textarea" v-model="form.remark" :disabled="priceTimeFlage?false:true"></el-input>
      </el-form-item>
    </el-form>
    <div class="pb100">
      <div class="ckxTitle">预约沟通过程</div>
      <el-table :data="querylist" border stripe class="table-mainbg mt20">
        <el-table-column prop="createDate" label="操作时间" align="center" ></el-table-column>
        <el-table-column prop="operatorName" label="操作人" align="center" ></el-table-column>
        <el-table-column prop="bookStatusName" label="预约状态" align="center"></el-table-column>
        <el-table-column prop="customerStatusName" label="客户意向" align="center"></el-table-column>
        <el-table-column prop="merchantStatusName" label="商户意向" align="center"></el-table-column>
        <el-table-column prop="bookShop" label="成交门店" align="center"></el-table-column>
        <el-table-column prop="bookTime" label="预约成交时间" align="center"></el-table-column>
        <el-table-column prop="customerRemark" label="客户方备注" align="center"></el-table-column>
        <el-table-column prop="merchantRemark" label="商户备注" align="center"></el-table-column>
        <el-table-column prop="merchantOrder" label="商户名次" align="center"></el-table-column>
        <el-table-column prop="merchantConfirmPrice" label="成交价" align="center"></el-table-column>
        <el-table-column prop="submitParty" label="操作方" align="center">
          <template slot-scope="scope">{{scope.row.submitParty?'商户':'客户'}}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer" >
      <div class="btn">
        <el-button type="primary" @click="doSubmit(1)" v-if="priceTimeFlage">提交</el-button>
        <el-button type="primary" @click="doSubmit(2)" v-if="[1,2].includes(state)&&priceTimeFlage">{{this.form.dealType===0?'垫资改预约':'预约改垫资'}}</el-button>
        <el-button type="primary" @click="doSubmit(3)">修改有效期</el-button>
      </div>
    </div>

    <el-dialog
      title="修改价格有效期"
      :visible.sync="dialogVisible"
      width="400px">
      <el-date-picker
      v-model="priceFailureTime"
      type="date" value-format="yyyy-MM-dd"
      placeholder="选择日期时间">
    </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postPriceFailureTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CapacityTable from '@/components/common/capacityTable'
export default {
  components: {
    CapacityTable
  },
  data() {
    return {
      intention: 0,//客户意向
      dateType:'当日',
      intention2: '',//商户操作
      form: {
        initiatedStore: '吴中路店',
        bookShopId: '',
        isComm: '',
        remark: '',
        bookTimeSu:'',
      },
      storeList:[],
      pickerOptions:{
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
        // selectableRange:this.$$.timeFormat(new Date()) + "- " + "23:59:59",
      },
      // storeList: [
      //   {store: '杭州路', list: [{time: '9:00', alternate: false},{time: '9:30', alternate: false},{time: '10:00', alternate: false},{time: '10:30', alternate: false},{time: '12:00', alternate: false},{time: '13:00', alternate: false},{time: '13:30', alternate: false},{time: '14:00', alternate: false},{time: '14:30', alternate: false},{time: '15:00', alternate: false},{time: '15:30', alternate: false},{time: '16:00', alternate: false},]},
      // ],
      dealBookId:'',//成交预约Id
      res:{},//页面详情
      state:1,//1成交预约-垫资车 2成交预约-预约车 3成交改约-垫资车 4成交改约-预约车
      shoplist:[],//门店列表
      slaunchShoplist:[],//发起门店列表
      querylist:[],
      capacitylist:[],//产能
      dialogVisible:false,
      priceFailureTime:'',//价格有效期
      priceTimeFlage:true,//是否在价格有效期内，默认在有效期
      eres:{},//竞拍详情
      enquiryId:'',
    }
  },
  created() {
    if(this.$route.query.dealBookId) this.dealBookId = this.$route.query.dealBookId;
    
    let user=this.$store.state.user.userData;
    if(user){
      this.slaunchShoplist=user.departmentList;
    }
    
    this.getShopList().then(res=>{
      this.shoplist=res.data;
      this.postDealBookDetail();//详情
    }).catch(()=>{
      this.postDealBookDetail();//详情
    });//获取全部门店
   
    this.getDealBookComm()//获取沟通记录
  },
  mounted(){
    
  },
  methods: {
    clickTime(row){//点击时间
      if([1].includes(this.intention)){
        this.form.bookTimeSu=`${row.date} ${row.planTime}:00`
      }else{
        this.form.bookTime=`${row.date} ${row.planTime}:00`
        this.form.bookTimeSu=`${row.date} ${row.planTime}:00`
      }
      this.$forceUpdate()
    },
    doSubmit(state) {
      switch(state){
        case 1:
          this.postSubmitBook()
          break;
        case 2:
          this.$confirm('变更车辆成交类型后，需要重新预约！你确认要修改吗？', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postDealTypeUpdate()
          }).catch(() => {});
          break;
        case 3:
          this.dialogVisible=!this.dialogVisible
          break;
      }
    },
    postDealBookDetail(){//根据成交预约Id获取预约详情
      this.$api.postDealBookDetail(this.dealBookId).then(res=>{
        if(res.code==0){
          this.form=res.data;
          //state 1成交预约-垫资车 2成交预约-预约车 3成交改约-垫资车 4成交改约-预约车
          if(res.data.bookStatus<4&&res.data.dealType==0){//dealType 成交类型 0-垫资成交 1-预约成交
            this.state=1;
          }else if(res.data.bookStatus<4&&res.data.dealType==1){
            this.state=2;
          }else if(res.data.bookStatus==4&&res.data.dealType==0){
            this.state=3;
          }else if(res.data.bookStatus==4&&res.data.dealType==1){
            this.state=4;
          }
          /***客户意愿
           * TO_BE_CONFIRMED(-1, "待确认"),
            BOOK_AGREE(0, "同意"),
            BOOK_CHANGE(1, "改约"),
            FOLLOW_UP_ING(2, "待定"),
            BOOK_CANCEL(3, "放弃");
          */
          if(res.data.customerStatus===1&&res.data.dealType==0){
            this.state=3;
          }else if(res.data.customerStatus===1&&res.data.dealType==1){
            this.state=4;
          }
          /**预约车默认为吴宝路店**/
          if(res.data.dealType===1){
            if(!this.form.bookShopId){
              for(let i=0;i<this.shoplist.length;i++){
                let item= this.shoplist[i]
                if(item.shopName=='吴宝路店'){
                  this.form.bookShopId=item.id;
                  break;
                }
              }
            }
          }
          //选择客户意愿的默认值
          if(res.data.customerStatus){
            this.intention=res.data.customerStatus;
          }else{
            if(this.state==1||this.state==2){
              this.intention=0
            }else{
              this.intention=1
            }
          }
          //是否在价格有效期内
          if(res.data.priceExpired==1){//价格过期标志 0-未过期 1-已过期
            this.priceTimeFlage=false;
          }else{
            this.priceTimeFlage=true;
          }
          // if(!res.data.bookShopId&&res.data.dealType==0){//默认值
          //   this.form.bookShopId=res.data.submitShopId
          // }
          
          if(this.form.bookShopId){
            this.queryDealCapacity(this.form.bookShopId)
          }
          if(res.data.enquiryId){
            this.getAuctionInfo(res.data.enquiryId)//获取竞拍详情
          }
          this.$nextTick(()=>{
            this.$forceUpdate()
          })
        }
      })
    },
    getShopList(){//获取全部门店
      let query={
        isEnabled:0,
        shopTypeList:[0,3]//门店类型。0客户成交1商户成交2售后服务3其它
      }
      return new Promise((resolve,reject)=>{
        this.$api.getShopList(query).then(res=>{
          if(res.code==0){
            resolve(res)
          }else{
            reject(res)
          }
        })
      })
    },
    changeShop(e){//切换门店
      if(!this.form.bookShopId){
        this.$message.warning('请选择成交门店！')
        return
      }
      this.queryDealCapacity(e)
    },
    changeShopS(e){
      this.form.submitShopId=e.id;
      this.submitShop=e.name;
    },
    postSubmitBook(){//成交预约/改约
      let query={
        "id": this.dealBookId,//预约Id
        customerStatus:this.intention,//客户意愿 0同意 1改约 2待定 3放弃
        "remark": this.form.remark
      }
      if([0].includes(this.intention)){
        query.submitShopId=this.form.submitShopId;//发起门店Id
        query.bookShopId= this.form.bookShopId;//成交门店Id
      }else if([1].includes(this.intention)){
        query.submitShopId=this.form.submitShopId;//发起门店Id
        query.bookShopId= this.form.bookShopId;//成交门店Id
        // query.bookTime= this.form.bookTimeSu;//预约时间
      }
      if([2].includes(this.state)&&[0].includes(this.intention)){
        query.bookTime= this.form.bookTime;//预约时间
      }
      if([1,3,4].includes(this.state)&&[0,1].includes(this.intention)){
           query.bookTime= this.form.bookTimeSu;//预约时间
        }
      if(this.state==1||this.state==3){
         query.isComm= this.form.isComm;//是否沟通 0-否 1-是
      }
      if(this.form.suggestDealTime){
        query.agreeMerchantTime= this.intention2;//商户意愿 0拒绝，1同意
      }
      //[2].includes(state)&&[0].includes(intention)&&form.suggestDealTime
      if(![0,1].includes(this.intention2)&&this.form.suggestDealTime&&[2].includes(this.state)&&[0].includes(this.intention)){
        this.$message.warning('请选择商户是否同意！')
        return
      }
     
      if([1].includes(this.intention)&&!this.form.bookTimeSu){
        this.$message.warning('改约成交时间不能为空！')
        return
      }
      if(this.intention==null||this.intention==-1){
        this.$message.warning('请选择客户意向！')
        return;
      }
      if([0].includes(this.intention)){
        if(!query.bookTime){
          this.$message.warning('预约成交时间不能为空！')
          return
        }
        if(!query.bookShopId){
          this.$message.warning('请选择成交门店')
          return
        }
      }
      this.$api.postSubmitBook(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          // this.postDealBookDetail()//详情
          // this.getDealBookComm()//获取沟通记录
          this.$message.success(res.message)
          this.bus.$emit("closeSelected",'router');
          this.$router.push({
            path:'/salesManager/customer',
            query:{
              followUpInfoId:this.form.followUpId
            }
          })
        }
      })
    },
    getDealBookComm(){//获取沟通记录
      this.$api.getDealBookComm(this.dealBookId).then(res=>{
        if(res.code==0){
          this.querylist=res.data;
        }
      })
    },
    queryDealCapacity(id){//查询门店成交产能
      let query={
        shopId:id,
        isToday:this.dateType=='当日'?1:0//今天or明天 1-今天 0-明天
      }
      this.$api.queryDealCapacity(query).then(res=>{
        if(res.code==0){
          this.capacitylist=[res.data];//产能列表
        }
      })
    },
    postDealTypeUpdate(){//预约类型修改
    //成交类型 0-垫资成交 1-预约成交
    // state:1,//1成交预约-垫资车 2成交预约-预约车 3成交改约-垫资车 4成交改约-预约车
      let state;
      if(this.form.dealType===0){
        state=1;
      }else if(this.form.dealType===1){
        state=0;
      }
      this.$api.postDealTypeUpdate(this.dealBookId,state).then(res=>{
        if(res.code==0){
          this.postDealBookDetail();//详情
          this.$message.success(res.message)
        }
      })
    },
    postPriceFailureTime(){//修改价格有效期
      this.$api.postPriceFailureTime(this.dealBookId,this.priceFailureTime).then(res=>{
        if(res.code==0){
          this.postDealBookDetail();//详情
          this.getDealBookComm()//获取沟通记录
          this.dialogVisible=!this.dialogVisible;
          this.$message.success(res.message)
        }
      })
    },
    getAuctionInfo(auctionId){//车辆简单详情
      this.$api.getAuctionInfo(auctionId).then(res=>{
        if(res.code==0){
          this.eres=res.data?res.data:{};
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.chengjiaoyuyue{
  padding: 20px 40px 100px;
  .label{
    width: 150px;
    text-align: right;
    display: inline-block;
    margin-left: -40px;
  }
  .value{
    width: 350px;
    display: inline-block;
  }
}
</style>
