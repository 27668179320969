<template>
  <div class="inventBoxBG">
    <div class="inventBox" >
      <div class="mb20">付款单号：{{res.orderNo}}</div>
      <!--车辆信息-->
      <div class="ckxTitle">车辆信息</div>
      <el-row class="mt10 mb15">
        <el-col :span="5">车辆号牌：{{res.carNo}}</el-col>
        <el-col :span="5">委托出售价：{{res.carTradedPrice?res.carTradedPrice:'-'}}元</el-col>
        <el-col :span="5">状态：{{res.financePayStatusName}}</el-col>
      </el-row>
      <div class="ckxTitle mb10">收款账号信息</div>
      <el-row>
        <el-col :span="5">开户名：{{res.accountName}}</el-col>
        <el-col :span="5">银行简称：{{res.boshBankName}}</el-col>
        <el-col :span="6">开户行：{{res.bankName}}</el-col>
        <el-col :span="5">账号：{{res.bankAccount}}</el-col>
      </el-row>
      <div class="ckxTitle mb10 mt15">意向金</div>
      <div class="pb100">
        <p class="mb10">车开新付意向金：<span class="text_decoration">{{res.intentionMoney?`${res.intentionMoney}元`:res.intentionMoney}}</span> </p>
        <template v-if="paymentState==31">
          <p class="mt15">付款方式：
            <el-select v-model="payTypeState" class="w300" placeholder="请选择付款方式" clearable>
              <el-option v-for="(item,index) in paymentMethodlist" :key="index" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </p>
          <el-radio-group v-model="form.payType" class="mt15" v-show="payTypeState==1">
            <el-radio-button v-for="item in paymentRadiolist" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </template>
        <template v-if="[33,34,35].includes(paymentState)">
            <p class="mt15">付款方式：{{res.financePayTypeName}}</p>
        </template>
        <template v-if="paymentState==34">
          <p class="mt15">失败原因：{{res.bankRspTips}}</p>
        </template>
        <p class="mt15"  v-if="paymentState==33">付款时间：{{res.financePayTime}}</p>
        <p class="mt15"  v-if="paymentState==35">无需付款原因：{{res.remark}}</p>
        <p class="tipsColor mt15" v-if="paymentState==31">当前余额：{{res.availableBalance}}</p>
        <p class="tipsColor mt15" >银行返回提示语：{{res.bankRspTips}}</p>
      </div>
    </div>

    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onPayment(1)" v-show="paymentState==31">开始付款</el-button>
        <el-button type="primary" @click="onPayment(2)" v-show="paymentState==32">付款完成</el-button>
        <el-button type="primary" @click="onPayment(3)" v-show="paymentState==32">付款失败</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      res: {},
      form:{},
      payTypeState:1,//付款方式
      paymentMethodlist:this.$store.state.setting.paymentMethodlist,//付款方式
      paymentRadiolist:this.$store.state.setting.paymentRadiolist,//付款按钮
      carCustomerList:[],//客户银行账号信息
      id:'',//中间退费ID
      paymentState:31,//财务付款状态，31待付款，32付款中，33付款成功，34付款失败，默认31
    };
  },
  created() {
    if(this.$route.query.id) this.payId=this.$route.query.id;
    this.getDetailsByPayId()
  },
  methods: {
    getDetailsByPayId(){//根据feeId查询详情(单个)
      this.$api.getDetailsByPayId(this.payId).then((res)=>{
        if(res){
          if(res.code==0){
            this.res=res.data;
            this.payTypeState=res.data.payType==5?5:1;//付款方式
            this.paymentState=res.data.financePayStatus;
          }
        }
      })
    },
    onPayment(state){
      switch(state){
        case 1:
          if(!this.payTypeState){
            this.$message('请选择付款方式！')
            return
          }else{
            if(!this.form.payType&&this.payTypeState!=5){
              this.$message('请选择付款方式！')
              return
            }
          }
          this.$confirm('请确认是否付款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postIntentionMoneyFinancePay()//财务单个
          }).catch(() => {});
          break;
        case 2:
          this.$confirm('请确认是否付款成功?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postIntentionMoneyFinanceConfirmPaySuccess()//财务确认付款成功
          }).catch(() => {});
          break;
        case 3:
          this.$confirm('请确认是否付款失败?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => { 
            this.postIntentionMoneyFinanceConfirmPayFail()//财务确认付款失败
          }).catch(() => {});
          break;
      }
    },
    onReturn(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/finance/intentionPayment',
        // query:{
        //   state:this.stateType
        // }
      })
    },
    postIntentionMoneyFinancePay(){//财务单个退费
      let query={
        "intentionMoneyPayId": this.payId,
        "financePayType": this.payTypeState==1?this.form.payType:this.payTypeState
      }
      this.$api.postIntentionMoneyFinancePay(query).then(res=>{
        if(res.code==0){
          this.getDetailsByPayId()
          this.$message.success(res.message)
        }
      })
    },
    postIntentionMoneyFinanceConfirmPaySuccess(){//财务确认付款成功
      this.$api.postIntentionMoneyFinanceConfirmPaySuccess(this.res.orderNo).then(res=>{
        if(res.code==0){
          this.getDetailsByPayId()
          this.$message.success(res.message)
        }
      })
    },
    postIntentionMoneyFinanceConfirmPayFail(){//财务确认付款失败
      this.$api.postIntentionMoneyFinanceConfirmPayFail(this.res.orderNo).then(res=>{
        if(res.code==0){
          this.getDetailsByPayId()
          this.$message.success(res.message)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.inventBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.text_decoration{
  text-decoration:underline;
  min-width:100px;
}
.inventBox {
  background-color: #ffffff;
  padding: 30px 50px;
  overflow-y:auto;
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
</style>