<template>
  <!-- 商户预约 -->
  <div class="contenPageBox">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>车牌号</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>4S店简称</span>
        <el-input v-model="params.storeName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>订单状态</span>
        <el-select v-model="params.certStatus" size="medium" clearable>
          <el-option label="待传过户凭证" :value="10"></el-option>
          <el-option label="已传过户凭证" :value="20"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2"  class="mb10">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border stripe class="mt10 table-mainbg">
      <el-table-column prop="taskId" align="center" label="任务ID" ></el-table-column>
      <el-table-column prop="taskType" align="center" label="任务类型" ></el-table-column>
      <el-table-column prop="vin" label="车架号" align="center"  ></el-table-column>
      <el-table-column prop="brand" align="center" label="品牌" ></el-table-column>
      <el-table-column prop="carNo" align="center" label="原车牌号码" ></el-table-column>
      <el-table-column prop="modifiedLicenseNo" align="center" label="修改后的车牌" ></el-table-column>
      <el-table-column prop="resversePrice" align="center" label="保留价" ></el-table-column>
      <el-table-column prop="dealerCode" align="center" label="经销商编号" ></el-table-column>
      <el-table-column prop="storeName" align="center" label="4S店简称" ></el-table-column>
      <el-table-column prop="extensionerName" align="center" label="渠道拓展专员" ></el-table-column>
      <el-table-column prop="certStatus" align="center" label="订单状态" >
        <template slot-scope="scope">{{scope.row.certStatus===10?'待传过户凭证':scope.row.certStatus===20?'已传过户凭证':scope.row.certStatus}}</template>
      </el-table-column>
      <el-table-column prop="checkerName" align="center" label="检测师" ></el-table-column>
      <el-table-column prop="pushTime" align="center" label="推送时间" ></el-table-column>
      <el-table-column label="操作" align="center" width="150px" >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="onOperate(scope.row,1)" v-if="scope.row.certStatus===10">上传过户凭证</el-button>
          <el-button size="mini" type="primary" plain @click="onOperate(scope.row,2)" v-if="scope.row.certStatus===20&&scope.row.pushStatus!=20">修改过户凭证</el-button>
          <el-button size="mini" type="primary" plain @click="onOperate(scope.row,3)" v-if="scope.row.certStatus===20&&scope.row.pushStatus===20">查看过户凭证</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <el-dialog
    :title="`过户凭证上传（车牌号：${itemRow.modifiedLicenseNo?itemRow.modifiedLicenseNo:itemRow.carNo}）`"
    :visible.sync="dialogVisibleCar" width="850px" class="comDialog">
      <div class="mb20">
        <span class="mr10">交易发票日期</span>
        <el-date-picker
          v-model="invoiceDate"
          type="date" :disabled="itemRow.pushStatus===20?true:false"
          placeholder="选择日期"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <p class="mb10 tipsColor setFontSize16">提示：请上传3M以内的文件！</p>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) of fileList" :key="index">
          <div class="photo_row_imgBox"  @mouseenter="divMouseover(item,index,'ts')"
              @mouseleave="divMouseout(item)">
            <div class="dialogBG" v-show="dialogFlag&&diaIndex==='ts_'+index">
              <i class="el-icon-delete cursor mr20" @click="delectImg(item)" v-if="itemRow.pushStatus!=20"></i>
              <i class="el-icon-zoom-in cursor " @click="onPreview" v-if="item.voucherType!=='pdf'"></i>
            </div>
            <el-image 
              :src="item.url" v-if="item.url&&item.voucherType!='pdf'"
              :preview-src-list="[item.url]">
              <div slot="error">暂无图片</div>
            </el-image>
            <p class="ml10 mr10" v-if="item.voucherType==='pdf'">{{item.url}}</p>
            <div @click="onUpload(item)" v-if="!item.url&&itemRow.pushStatus!=20" >
             <el-upload
              action
              accept="application/pdf,image/jpeg"
              :on-change="requestUpload"
              :auto-upload="false" >
                <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                </div>
              </el-upload>
              </div>
            <div class="photo_row_imgtiltle">{{item.name}}</div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUploadSubmit" v-if="itemState!=3">上传</el-button>
        <el-button @click="dialogVisibleCar = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-image-viewer v-show="showViewer" :on-close="closeViewer" :url-list="srcList" style="z-index:3000"/>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: { ElImageViewer },
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      params: {
        certStatus: 10,
        storeName: '',
        carNo: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      itemRow:{},
      fileList: [
        { name: "二手车发票",code:'02', url: "", uploader: "", uploaderDate: "" },
        { name: "过户后二手车登记证首页",code:'03', url: "", uploader: "", uploaderDate: "" },
        { name: "过户后二手车登记证过户页",code:'04', url: "", uploader: "", uploaderDate: "" }
      ], //图片
      srcList:[],
      showViewer:false,
      dialogFlag:false,
      diaIndex:'',
      imgRow:{},
      invoiceDate:'',
      itemState:1,//点击的什么按钮
    }
  },
  created() {
    this.initData();
  },
  methods: {
     onPreview() {
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    divMouseover(row,index,state){//移入
      if(row.url){
        this.dialogFlag=true;
        this.diaIndex=`${state}_${index}`;
        this.srcList=[row.url]
      }
    },
    divMouseout(row){//移除
      if(row.url){
        this.dialogFlag=false;
        this.diaIndex='';
      }
    },
    delectImg(row){
      if(row.id){
        this.imgRow=JSON.parse(JSON.stringify(row));
        this.getDeleteDetailById(row.id)
      }else{
        for(let i=0;i<this.fileList.length;i++){
          let item=this.fileList[i]
          if(item.code===row.code){
            item.url='';
            item.voucherSize='';
            item.voucherType='';
            item.voucherName='';
            this.dialogFlag=false;
            return
          }
        }
        
      }
    },
    onUpload(row){
      this.imgRow=row;
    },
    requestUpload(file) {
      let obj = new FormData();
      obj.append("file", file.raw);
      obj.append("code", this.imgRow.code);
      obj.append("name", this.imgRow.name);
      this.$api.postBmwFileUpload(obj).then(res => {
        if (res.code == 0) {
          for(let i=0;i<this.fileList.length;i++){
            let item=this.fileList[i]
            if(item.code===res.data.voucherCode){
              item.url=res.data.voucherUrl;
              item.voucherSize=res.data.voucherSize;
              item.voucherType=res.data.voucherType;
              item.voucherName=res.data.voucherName;
            }
          }
          this.$message.success(res.message)
        }
      });
    },
    getDeleteDetailById(detailId){//删除文件
      this.$confirm('请确认是否删除图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.getDeleteDetailById(detailId).then(res=>{
          if(res.code==0){
            for(let i=0;i<this.fileList.length;i++){
              let item=this.fileList[i]
              if(item.id===detailId){
                item.url='';
                item.voucherSize='';
                item.voucherType='';
                item.voucherName='';
              }
            }
            // this.getDetailsByIdBmw(this.itemRow.id)
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    onOperate(row,state){
      this.dialogVisibleCar=true;
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.itemState=state;
      this.getDetailsByIdBmw(row.id)
    },
    getDetailsByIdBmw(id){//查询列表的详情
      this.fileList= [
        { name: "二手车发票",code:'02', url: "", uploader: "", uploaderDate: "" },
        { name: "过户后二手车登记证首页",code:'03', url: "", uploader: "", uploaderDate: "" },
        { name: "过户后二手车登记证过户页",code:'04', url: "", uploader: "", uploaderDate: "" }
      ] //图片
      this.$api.getDetailsByIdBmw(id).then(res=>{
        if(res.code===0){
          this.invoiceDate=res.data.invoiceDate;//交易发票日期
          for(let i=0;i<this.fileList.length;i++){
            let item=this.fileList[i];
            for(let j=0;j<res.data.paymentVoucherDetaillList.length;j++){
              let its=res.data.paymentVoucherDetaillList[j]
              if(item.code===its.voucherCode){
                item.url=its.voucherUrl;
                item.voucherSize=its.voucherSize;
                item.voucherType=its.voucherType;
                item.voucherName=its.voucherName;
                item.id=its.id;
              }
            }
          }
        }
      })
    },
    onUploadSubmit(){
      if(!this.invoiceDate){
        this.$message.warning('交易发票日期不能为空！')
        return
      }
       let andNewArr=[];//联系人信息列表
      for(let i=0;i<this.fileList.length;i++){
        let item=this.fileList[i]
        if(item.url){
          let obj={
              "id": item.id?item.id:'',
              "paymentVoucherId": this.itemRow.id,
              "type": "20",
              "voucherCode": item.code,
              "voucherName": item.voucherName,
              "voucherSize": item.voucherSize,
              "voucherType": item.voucherType,
              "voucherUrl": item.url,
          }
          andNewArr.push(obj)
        }
      }
      if(andNewArr.length===0){
        this.$message.warning('图片不可为空!')
        return
      }
      
      this.$api.postSaveOrUpdatePaymentVoucherDetails(andNewArr).then(res=>{
        if(res.code===0){
          this.postUpdPaymentVoucher()
        }
      })
    },
    postUpdPaymentVoucher(){//用来修改日期
      let query={
        paymentVoucherId:this.itemRow.id,
        invoiceDate:this.invoiceDate,
      }
      this.$api.postUpdPaymentVoucher(query).then(res=>{
        if(res.code==0){
          this.dialogVisibleCar=false;
          this.initData()
          this.$message.success(res.message);
        }
      })
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let query={
        curPage: this.page_index,
        pageSize: this.page_size, 
        carNo:this.params.carNo,
        storeName:this.params.storeName,
        certStatus:this.params.certStatus,
      }
     this.$api.postPaymentVoucherBmwPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.page_total = res.data.total;
          } else {
            this.querylist = [];
            this.page_total = 0;
          }
        }
      });
      
    },
    onSearch(){},
    onSubmit() {
      let query={
      }
      this.$api.merchantSubmitDefault(this.$$.delete_null_properties(query)).then( res => {
        if(res.code == 0){
          this.applyCompensationVisible = false;
          if(this.formCar){
            this.dialogVisibleCar=true;
          }
          this.initData()
          this.$message.success(res.message);
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px;
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 250px;
      margin: 0 27px 20px 0;
      .photo_row_imgBox {
        height: 200px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          max-height: 100%;
        }
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          // font-size: 14px;
          color: #ffffff;
          line-height: 40px;
        }
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
  .dialogBG{
    position:absolute;
    left:0;
    top:0;
    z-index:1;
    width:100%;
    height:100%;
    background:rgb(255,255,255,0.7);
    display:flex;
    justify-content:center;
    align-items: center;
    font-size: 30px;
    .el-icon-delete{
      position:absolute;
      left:10px;
      top:10px;
    }
  }
  ::v-deep .comDialog .el-dialog__body{padding-top:10px}
}
</style>