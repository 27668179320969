<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>达人姓名</span>
        <el-input v-model="forms.name" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>达人手机号</span>
        <el-input v-model="forms.mobile" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>绑定门店</span>
        <el-select v-model="forms.shopId" size="medium" clearable>
          <el-option v-for="item in shoplist" :key="item.id" :value="item.id" :label="item.shopName"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>渠道来源</span>
        <el-select v-model="forms.regChannel" size="medium" clearable>
          <el-option value="个人推荐合作" label="个人推荐合作"></el-option>
          <el-option value="洗车维修店合作" label="洗车维修店合作"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt10">
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>签约状态</span>
        <el-select v-model="forms.signStatus" size="medium" clearable>
          <el-option v-for="item in signStatuslist" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4">
        <span>提交时间</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange" clearable :default-time="['00:00:00', '23:59:59']"
          size="medium" value-format="yyyy-MM-dd HH:mm:ss" 
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="4" class="flex flex_align_center">
        <el-button type="primary" @click="onsearch" size="medium" plain>查询</el-button>
        <el-button type="primary" @click="onUpdateArr" size="medium" plain>批量修改所属专员</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border stripe class="mt20 table-mainbg" @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column prop="name" label="达人姓名" align="center"></el-table-column>
      <el-table-column prop="sex" label="达人性别" align="center"></el-table-column>
      <el-table-column prop="mobile" label="达人手机号" align="center"></el-table-column>
      <el-table-column prop="comName" label="达人所在公司" align="center"></el-table-column>
      <el-table-column prop="shopName" label="绑定门店" align="center"></el-table-column>
      <el-table-column prop="ownerEmpName" label="所属专员" align="center"></el-table-column>
      <el-table-column prop="regChannel" label="渠道来源" align="center"></el-table-column>
      <el-table-column prop="regTime" label="提交时间" align="center"></el-table-column>
      <el-table-column prop="signStatus" label="签约状态" align="center">
        <template slot-scope="scope">{{scope.row.signStatus===1?'待签约':scope.row.signStatus===2?'已签约':scope.row.signStatus===3?'已解约':scope.row.signStatus}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="280">
        <template slot-scope="scope">
          <el-button type="primary" @click="onsee(scope.row,1)" v-if="[1,3].includes(scope.row.signStatus)" size="mini">签约</el-button>
          <el-button type="primary" @click="onsee(scope.row,2)" v-if="scope.row.signStatus===2" size="mini">解约</el-button>
          <el-button type="primary" @click="onsee(scope.row,3)" size="mini">编辑</el-button>
          <el-button type="primary" @click="onsee(scope.row,4)" size="mini">查看推荐客户</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog
      title="达人编辑管理"
      :visible.sync="dialogVisible" width="800px">
      <el-form ref="form" :rules="rules" :model="itemRow" size="medium"  class="formModelBox">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="达人用户ID:"><p>{{itemRow.agentNo}}</p></el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="达人姓名:" prop="name">
              <el-input v-model="itemRow.name" class="w200"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别:" prop="sex">
              <el-select v-model="itemRow.sex" class="w200">
                <el-option value="男" label="男"></el-option>
                <el-option value="女" label="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="达人手机号:" prop="mobile">
              <el-input v-model="itemRow.mobile" class="w200"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="渠道来源:" prop="regChannel">
              <el-select v-model="itemRow.regChannel" filterable class="w200">
                <el-option value="洗车维修店合作" label="洗车维修店合作"></el-option>
                <el-option value="个人推荐合作" label="个人推荐合作"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司名称:" >
              <el-input v-model="itemRow.comName" class="w200"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="绑定门店:" prop="shopId">
              <el-select v-model="itemRow.shopId" filterable class="w200">
                <el-option v-for="item in shoplist" :key="item.id" :value="item.id" :label="item.shopName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="从属专员:" prop="ownerEmpName">
              <el-input v-model="itemRow.ownerEmpName" class="w200" readonly>
                <template slot="append">
                  <el-button type="primary" @click="onEmpName">选择</el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="达人添加客户二微码:">
              <el-image style="width:100px;height:100px" :src="itemRow.qrCodeUrl" :preview-src-list="[itemRow.qrCodeUrl]">
                <div slot="error">暂无图片</div>
              </el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitUpdate">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="所属专员列表"
      :visible.sync="dialogVisibleTable" width="900px">
      <el-row :gutter="24">
        <el-col :span="6" class="flex flex_align_center flex_between w3">
          <span>员工编号:</span>
          <el-input v-model="form.no" size="medium"></el-input>
        </el-col>
        <el-col :span="6" class="flex flex_align_center flex_between w2">
          <span>姓名:</span>
          <el-input v-model="form.name" size="medium"></el-input>
        </el-col>
        <el-col :span="6" class="flex flex_align_center flex_between w3">
          <el-button type="primary" plain @click="onSearchModel" size="medium">查询</el-button>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe class="mt30 table-mainbg" height="400" @selection-change="modelSelectionChange">
        <el-table-column type="selection"  label="选择" align="center"></el-table-column>
        <el-table-column prop="no" label="员工编号" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="80">
        <template slot-scope="scope">
          <el-button type="primary" @click="onSelected(scope.row)" size="mini">选定</el-button>
        </template>
      </el-table-column>
      </el-table>
      <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize1"
      :current-page="currentPage1"
      :total="total1"
    ></el-pagination>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      forms:{
        date:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      signStatuslist:[{id:1,name:'待签约'},{id:2,name:'已签约'},{id:3,name:'已解约'}],
      rolelist:[],
      dialogVisible:false,
      dialogVisibleTable:false,
      itemRow:{},
      form:{},//查询专员
      rules: {
        name: [{ required: true, message: '不能为空',}],
        sex: [{ required: true, message: '不能为空',}],
        mobile: [{ required: true, message: '不能为空',}],
        regChannel: [{ required: true, message: '不能为空',}],
        comName: [{ required: true, message: '不能为空',}],
        shopId: [{ required: true, message: '不能为空',}],
        ownerEmpName: [{ required: true, message: '不能为空',}],
      },
      // modelTitle:'',//table名称
      modellist:[],
      multipleSelection:[],//选中的数据
      userlist:[],
      shoplist:[],
      total1: 0,
      currentPage1: 1,
      pageSize1:10,
      edit:false,//默认编辑
    };
  },
  created() {
    this.init()
    this.getShopList()
    this.getQueryUserPage();//获取所有人员
  },
  methods: {
    onEmpName(){
      this.edit=true;
      this.dialogVisibleTable=true;
    },
    init(page) {
      this.currentPage=page?page:this.currentPage;

      let query={
        "mobile": this.forms.mobile,
        "curPage": this.currentPage,
        "name": this.forms.name,
        "pageSize": this.pageSize,
        "shopId": this.forms.shopId,
        "regChannel": this.forms.regChannel,
        "signStatus": this.forms.signStatus,
        "regTimeBegin": this.forms.date?this.forms.date[0]:'',
        "regTimeEnd": this.forms.date?this.forms.date[1]:'',
      }
      this.$api.postAgentPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.total=res.data.total;
        }else{
          this.querylist = [];
          this.total=0;
        }
      })
    },
    onsearch() {
      this.currentPage=1;
      this.init()
    },
    handleSizeChange(data) {//切换条数
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {//切换页数
      this.currentPage=data;
      this.init()
    },
    handleSizeChange1(data) {//切换条数
      this.pageSize1=data;
      this.currentPage1=1;
      this.getQueryUserPage()
    },
    handleCurrentChange1(data) {//切换页数
      this.currentPage1=data;
      this.getQueryUserPage()
    },
    onSelected(row){//选定人员
      if(this.edit){
        this.itemRow.ownerEmpName=row.name;
        this.itemRow.ownerEmpNo=row.no;
        this.dialogVisibleTable=false;
        return
      }
      let arr=this.multipleSelection.map(item=>{return item.id})
      let query={
        ownerEmpNo:row.no,
        agentIds:String(arr),
      }
      this.$api.postBatchAlterOwnerEmp(query).then(res=>{
        if(res.code==0){
          this.init()
          this.dialogVisibleTable=false;
          this.$message.success(res.message)
        }
      })
    },
    onsee(row,state){
      this.itemRow=JSON.parse(JSON.stringify(row))
      switch(state){
        case 1://签约
          this.$confirm(`达人${row.name}确定要签约吗？`, '提示', {
            confirmButtonText: '确定签约',
            cancelButtonText: '暂不签约',
            type: 'warning'
          }).then(() => {
            this.$api.getAgentSign(row.id).then(res=>{
              if(res.code==0){
                this.init()
                this.$message.success(res.message)
              }
            })
          }).catch(() => {});
          break;
        case 2://解约
          this.$confirm(`该商户是否解约`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$api.getAgentUnSign(row.id).then(res=>{
              if(res.code==0){
                this.init()
                this.$message.success(res.message)
              }
            })
          }).catch(() => {});
          break;
        case 3://编辑
          this.dialogVisible=!this.dialogVisible;
          this.$nextTick(()=>{
            this.$refs['form'].clearValidate();
          })
          break;
        case 4://查看客户
          this.$router.push({
            path:'/carRecommendationExpert/customerManagement',
            query:{
              id:row.id
            }
          })
          break;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onUpdateArr(){
      this.edit=false;
      if(this.multipleSelection.length==0){
        this.$message('请先选择需要修改从属专员的荐车达人！')
        return
      }else{
        this.dialogVisibleTable=true;
      }
    },
    onSubmitUpdate(){//修改编辑
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let query={
            "openid": this.itemRow.openid,
            "mobile": this.itemRow.mobile,
            "name": this.itemRow.name,
            "ownerEmpNo": this.itemRow.ownerEmpNo,
            "sex": this.itemRow.sex,
            "shopId": this.itemRow.shopId,
            "regChannel": this.itemRow.regChannel,
            "comName": this.itemRow.comName,
            // "qrCodeUrl": "string"
          }
          this.$api.postAgentUpdate(this.itemRow.id,query).then(res=>{
            if(res.code==0){
              this.init();
              this.dialogVisible=false;
              this.$message.success(res.message)
            }
          })
        }
      });
      
    },
    onSubmit(){},
    modelSelectionChange(row){},
    onSearchModel(){
      this.currentPage1=1;
      this.getQueryUserPage()
    },
    getQueryUserPage(){//所有人员
      let query={
        "curPage": this.currentPage1,
        "no": this.form.no,
        "name": this.form.name,
        "pageSize": this.pageSize1,
      }
      this.$api.getQueryUserPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.userlist=res.data.records;
          this.total1=res.data.total;
        }else{
          this.userlist=[];
          this.total1=0;
        }
      })
    },
    getShopList(){//搜索门店
      let query={
        shopTypeList:[0,3],//门店类型。0客户成交1商户成交2售后服务3其它。
        isEnabled:0
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.formModelBox{
  max-height:500px;
  overflow:auto;
}
</style>
