<template>
  <div class="pageContainer">
    <div class="flex flex_between">
      <el-radio-group v-model="isToday" @change="init">
        <el-radio-button label="1">当日</el-radio-button>
        <el-radio-button label="0">次日</el-radio-button>
      </el-radio-group>
      <div class="paibox">
        <span>说明：</span>
        <el-tag type="danger" effect="dark">今日新增</el-tag>
        <el-tag type="warning" effect="dark">正常确认</el-tag>
        <el-tag effect="dark">未确认</el-tag>
        <el-tag type="success" effect="dark">空闲</el-tag>
        <el-tag type="info" effect="dark">关闭</el-tag>
      </div>
      <div v-if="controlFlag">
        <el-button type="primary" @click="onSubmit(1)" size="medium" plain>关闭资源</el-button>
        <el-button type="primary" @click="onSubmit(2)" size="medium" plain>打开资源</el-button>
        <el-button type="primary" @click="onSubmit(3)" size="medium" plain>编辑检测师数量</el-button>
      </div>
    </div>
    <table class="table-custom mt20" v-show="!editState">
      <tr>
        <td width="200px">门店名称</td><td>检测师人数</td>
        <td v-for="(item,index) in headlist" :key="index">{{index}}</td>
      </tr>
      <tr v-for="(item,ii) in querylist" :key="ii">
        <td  width="200px">{{item.shop}}</td>
        <td>{{item.count}}</td>
        <td v-for="(items,indexs) in item.resources" :key="indexs">
          <div class="tagBox" :class="[rows.status==1?'redBG':rows.status==2?'yellowBG':rows.status==3?'blueBG':rows.status==4?'greyBG':'greenBG',i==activeI&&indexs==activeIn&&ii==activeInd?'active':'']" v-for="(rows,i) in items" :key='`_${i}`' @click="shopClick(item,rows,i,indexs,ii)">{{rows.createUserNo}}</div>
        </td>
      </tr>
      <tr v-if="querylist.length==0"><td colspan="2">暂无数据</td></tr>
    </table>
    <div class="w400 pb140">
      <el-table class="mt20 " v-show="editState"
      :data="shoplist"
      border>
      <el-table-column
        prop="shop" align="center"
        label="门店名称"
        >
      </el-table-column>
      <el-table-column
        prop="count" align="center"
        label="检测师人数"
        >
        <template slot-scope="scope">
          <el-input v-model="scope.row.count" type="number"></el-input>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="footer" v-show="editState">
      <div class="btn">
        <el-button type="primary" @click="onSubmit(4)" >保存</el-button>
        <el-button type="primary" @click="onReturn" >返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      editState:0,//是否编辑检测师
      form: {},
      isToday:1,
      day:'',
      querylist: [],
      shoplist:[],//门店列表
      headlist:[],
      activeI:'',
      activeIn:'',
      activeInd:'',
      activeFlag:false,
      itemRows:{},//父数据
      itemRow:{},//子数据
      controlFlag:false,//是否展示操作按钮，文员可操作
    }
  },
  created() {
    this.init();
    let user=this.$$.roleName(this.$store.state.user.userData)
    if(user.name.indexOf('门店')!=-1){
      this.controlFlag=true;
    }
  },
  mounted(){
  },
  methods: {
    init(){
      if(this.editState){
        this.getCheckResourceShopSetting()
      }else{
        this.getCheckResourceShopTask()
      }
    },
    getCheckResourceShopTask(){//获取资源列表
      this.$api.getCheckResourceShopTask(this.isToday).then(res=>{
        if(res.code==0){
          this.day=res.data.day;
          this.querylist=res.data.shopList;
          this.headlist=res.data.shopList.length>0?res.data.shopList[0].resources:[]
        }
      })
    },
    getCheckResourceShopSetting(){//获取门店资源设置(编辑检测师数量)
      this.$api.getCheckResourceShopSetting(this.isToday).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
    shopClick(rows,row,index,i,ii){
      if(!this.controlFlag){
        return
      }
      this.activeI=index;
      this.activeIn=i;
      this.activeInd=ii;
      this.activeFlag=true;
      this.itemRows=rows;
      this.itemRow=row;
    },
    onSubmit(state){
      switch(state){
        case 1:
          if(!this.activeFlag){
            this.$message.warning('请选择资源！')
            return
          }
          this.postCheckResourceShopSwitch(4)
          break;
        case 2:
          if(!this.activeFlag){
            this.$message.warning('请选择资源！')
            return
          }
          this.postCheckResourceShopSwitch(0)
          break;
        case 3:
          this.getCheckResourceShopSetting()
          this.editState=1;
          break;
        case 4:
          this.postCheckResourceSettingUpdateBatch()
          break;
      }
    },
    postCheckResourceShopSwitch(status){//门店检测资源关闭或打开
      let query={
        "shopId": this.itemRows.shopId,
        "date": this.day,
        "planTime": this.activeIn,
        "resourceNo": this.itemRow.resourceNo,
        "status": status//状态 0-空闲 4-关闭
      }
      this.$api.postCheckResourceShopSwitch(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.activeI='';
          this.activeIn='';
          this.activeInd='';
          this.activeFlag=false;
          this.itemRows={};
          this.itemRow={};
        }
      })
    },
    postCheckResourceSettingUpdateBatch(){//批量修改资源设置
      for(let i=0;i<this.shoplist.length;i++){
        let item=this.shoplist[i]
        if(item.count===''){
          this.$message.warning('检测师人数不可为空！')
          return
        }else if(item.count<0){
          this.$message.warning('检测师人数不可小于0！')
          return
        }
      }
      this.$api.postCheckResourceSettingUpdateBatch(this.shoplist).then(res=>{
        if(res.code==0){
          this.editState=0;
          this.$message.success(res.message)
          this.init()
        }
      })
    },
    onReturn(){
      this.editState=0;
      this.init()
    }
  },
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .iconText{
    font-size:50px;
    cursor: pointer;
    margin-left: 20px;
  }
  .paibox{
    margin-left:100px;
    .el-tag{border-radius:0}
  }
  .tagBox{
    // min-width:45px;
    width: 98%;
    height:25px;
    color:#ffffff;
    margin:2px auto;
    cursor: pointer;
    line-height: 24px;
  }
  .active{
    border: 1px solid red;
  }
}
</style>