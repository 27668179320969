<template>
  <div class="certificateBoxBG">
    <div class="certificateBox pb100">
      <!--基本信息-->
      <div class="flex_space mb10">
        <div class="ckxTitle">客户端信息</div>
        <span class="seeColor" @click="onrouter(0)">客户合同查看>></span>
      </div>
      <table class="table-oddwholebg table-custom">
        <tbody>
          <tr>
            <td>客户姓名</td>
            <td>{{form.customerName}}</td>
            <td>成交价</td>
            <td>{{form.ccTradePrice}}</td>
            <td>成交门店</td>
            <td>{{customerContract.tradeShopName}}</td>
            <td>帮卖经理</td>
            <td>{{customerContract.sellerName}}</td>
          </tr>
          <tr>
            <td>车牌号码</td>
            <td>{{form.carNo}}</td>
            <td>车辆型号</td>
            <td>{{form.makerName}}&nbsp;{{form.seriesName}}</td>
            <td>4S店简称</td>
            <td>{{form.channel?form.channel.shortName:''}}</td>
            <td>拓展专员</td>
            <td>{{form.channel?form.channel.extensionerName:''}}</td>
          </tr>
          <tr>
            <td>车架号</td>
            <td>{{form.vinCode}}</td>
            <td>发动机号</td>
            <td>{{form.engineNo}}</td>
            <td>车辆颜色</td>
            <td>{{form.carColor}}</td>
            <td>产证编号</td>
            <td>{{form.certNo}}</td>
          </tr>
           <tr>
            <td>客户办证截止时间</td>
            <td>{{customerContract.customerDeadline}}</td>
            <td>初次登记日期</td>
            <td>{{form.registerDate}}</td>
            <td>年检有效期</td>
            <td>{{checkExpire}}</td>
            <td>交强险有效期</td>
            <td>{{form.trafficAccidentsEndDate}}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex_space mt20 mb10">
        <div class="ckxTitle">商户端信息</div>
        <span class="seeColor" @click="onrouter(1)">商户合同查看>></span>
      </div>
      <table class="table-oddwholebg table-custom">
        <tbody>
          <tr>
            <td>商户姓名</td>
            <td>{{res.merchantName}}</td>
            <td>商户电话</td>
            <td>{{res.merchantMobile}}</td>
            <td>维护业务员</td>
            <td>{{res.maintainerName}}</td>
            <td>维护业务员电话</td>
            <td>{{res.maintainerMobile}}</td>
          </tr>
          <tr>
            <!-- <td>商户申请时间</td>
            <td>{{form.applyTime}}</td> -->
            <td>商户办证截止时间</td>
            <td>{{merchantContract.merchantDeadline}}</td>
            <td>经营地</td>
            <td>{{carMerchant.businessCityName}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="flex_space mt20 mb10">
        <div class="ckxTitle">办证信息</div>
        <span class="seeColor" @click="onrouter(2)">查看报告>></span>
      </div>
      <table class="table-oddwholebg table-custom">
        <tbody>
          <tr>
            <td>办证公司</td>
            <td>{{form.certType===12?form.certCompany:form.certTypeName}}</td>
            <td v-if="state!=1">办证办理地</td>
            <td v-if="state!=1">{{res.provinceName}}{{res.cityName}}{{res.areaName}}</td>
          </tr>
          <tr v-if="state!=1">
            <td>提交申请时间</td>
            <td>{{res.certApplyTime}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt20">过户凭证特殊要求</div>
      <div class="">
        <span v-for="(item,index) in contractRequireList" :key="item.id" class="mr30 tipsColor">{{index+1}}、{{item.requireName}}{{item.bak}}</span>
        <span v-if="contractRequireList.length===0">-</span>
      </div>
      <el-dialog
        title="办证办理城市要求"
        :visible.sync="dialogVisibleTable"
      >
      <!-- 一期不做，二期要补上，不能删掉 -->
       <el-table :data="$store.state.setting.certRequireList" class="table-nopadding table-notheadBg" >
          <el-table-column label="车开新手续办理上下家到场及需提供证件信" align="center">
            <el-table-column prop="cityName" label="城市" align="center"></el-table-column>
            <el-table-column prop="areaName" label="区县" align="center"></el-table-column>
            <el-table-column label="办证上家需到场" align="center">
              <el-table-column prop="upCustomer.needPresent" label="无需到场" align="center">
                <template slot-scope="scope">
                  <!--是否需要到场: 需要0, 不需要1-->
                  <i :class="!scope.row.upCustomer.needPresent?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.notGo" label="可不去有条件" align="center">
                <template slot-scope="scope">
                  <!--有0, 没有1-->
                  <i :class="!scope.row.upCustomer.notGo?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.notGoCondition" label="上家不去条件" align="center"></el-table-column>
              <el-table-column prop="upCustomer.needPresent" label="需到场" align="center">
                <template slot-scope="scope">
                  <!--是否需要到场: 需要0, 不需要1-->
                  <i :class="!scope.row.upCustomer.needPresent?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.presentRemark" label="备注" align="center"></el-table-column>
            </el-table-column>
            <el-table-column label="上家身份证件" align="center">
              <el-table-column prop="upCustomer.needOriginal" label="原件" align="center">
                <template slot-scope="scope">
                  <!--要0, 不要1-->
                  <i :class="!scope.row.upCustomer.needOriginal?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.needPicture" label="照片件" align="center">
                <template slot-scope="scope">
                  <!--要0, 不要1-->
                  <i :class="!scope.row.upCustomer.needPicture?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.needCopies" label="复印件" align="center">
                <template slot-scope="scope">
                  <!--要0, 不要1-->
                  <i :class="!scope.row.upCustomer.needCopies?'el-icon-check':''"></i>
                </template>
              </el-table-column>
              <el-table-column prop="upCustomer.idRemark" label="备注" align="center"></el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-dialog>

      <!--身份证件跟进信息-->
      <div class="content_modal_textBox mt20">
        <div class="justifySpace flex_align_center">
          <div class="ckxTitle">办证跟踪任务</div>
          <el-button type="primary" size="medium" @click="ondialogVisible" v-if="state!=3">新增跟踪任务</el-button>
        </div>
        <el-table :data="certMaterialTraceList" border class="mt10">
          <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
          <el-table-column prop="dataType" label="数据类型" align="center"></el-table-column>
          <el-table-column prop="traceName" label="任务名称" align="center"></el-table-column>
          <el-table-column prop="appointFinishTime" label="约定完成时间" align="center"></el-table-column>
          <el-table-column prop="traceTime" label="跟踪时间" align="center"></el-table-column>
          <el-table-column prop="traceStatusName" label="跟进状态" align="center"></el-table-column>
          <el-table-column label="操作" align="center" v-if="state!=3">
            <template slot-scope="scope" v-if="scope.row.traceStatus==31">
              <el-button
                type="primary" 
                @click="onComplete(scope.row)"
                size="mini">跟踪完成</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
       <!--客户邀约跟进记录-->
      <div class="content_modal_textBox mt20">
        <div class="ckxTitle mb10">办证过程进记录</div>
        <el-table :data="certInviteTraceList" border>
          <el-table-column prop="operateTime" label="操作时间" align="center"></el-table-column>
          <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
          <el-table-column prop="nextInviteTime" label="下次跟进时间" align="center"></el-table-column>
          <el-table-column prop="certInviteStatusName" label="跟进结果" align="center"></el-table-column>
          <el-table-column prop="inviteSuccessTime" label="邀约办证时间" align="center"></el-table-column>
          <el-table-column prop="description" label="结果描述" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="conten_followupBox" v-if="state!=3">
        <!--跟进信息-->
        <div class="content_modal_textBox mt20">
          <div class="ckxTitle">跟进信息</div>
          <div class="content_follow_formBox">
            <el-form
              ref="followupForm"
              :model="followupForm"
              label-width="120px"
              :rules="rulesJXGJ"
              :hide-required-asterisk="true"
            >
              <el-form-item label="跟进结果:">
                <el-radio-group
                  v-model="followupForm.taskResult"
                  size="medium"
                  class="radioButton"
                >
                  <el-radio-button :label="41">继续跟进</el-radio-button>
                  <el-radio-button :label="42">跟进完成</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="下次跟进时间:" prop="followTime" v-show="followupForm.taskResult!=42">
                <el-date-picker
                  v-model="followupForm.followTime"
                  type="datetime"
                  placeholder="请选择下次跟进时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="结果描述:">
                <el-input
                  type="textarea"
                  class="w300"
                  v-model="followupForm.taskRemark"
                  size="medium"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="footer" v-if="state!=3">
        <div class="btn clear">
          <el-button type="primary" @click="returnCar" class="fl ml30">退车</el-button>
          <el-button type="primary" @click="doSubmit('followupForm')">提交</el-button>
          <el-button type="primary" @click="onReturn">关闭</el-button>
        </div>
      </div>

      <el-dialog
        title="新建跟踪任务"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="500px"
      >
        <el-form
          ref="forms"
          :model="forms"
          label-width="135px"
          :rules="rules"
          :hide-required-asterisk="true"
        >
          <el-form-item label="任务名称" prop="itemName">
            <el-input v-model="forms.itemName" class="w300"></el-input>
          </el-form-item>
          <el-form-item label="约定完成时间" prop="appointFinishTime">
            <el-date-picker
              v-model="forms.appointFinishTime"
              type="datetime"
              placeholder="请选择时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              class="w300"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="forms.remark" class="w300" size="medium"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmitFollow()">提交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state:3,//1邀约办证，2跟进邀约，3查看
      cardIdlist: [], //身份证列表（待跟进）
      certMaterialTraceList: [],//办证跟踪任务列表
      certInviteTraceList: [], //客户邀约跟进记录
      certRequireList: [], //办证要求
      form: {
        carCustomer: {}, //	车辆客户信息
        carMerchant: {} //车辆商户信息
      }, //展示数据
      customerContract:{},
      res:{},
      carMerchant:{},//商户表
      customerContract:{},//客户合同表
      merchantContract:{},//商户合同表
      followupForm: {
        //跟进信息表单
        taskResult: 41
      },
      dialogVisible: false,
      tabClassify: "first",
      rules: {
        itemName: [{ required: true, message: "项目名称不能为空" }],
        replaceType: [{ required: true, message: "补办方式不能为空" }],
        appointFinishTime: [
          { required: true, message: "约定完成日期不能为空" }
        ],
        remark: [{ required: true, message: "备注不能为空" }]
      },
      rulesJXGJ: {
        followTime: [{ required: true, message: "下次跟进时间不能为空" }],
        inviteDate: [{ required: true, message: "邀约办证日期不能为空" }],
        needPresent: [{ required: true, message: "请选择是否需要本人到场" }]
      },
      FollowType: 0, //跟进结果，0继续跟进，1邀约成功，2无需邀约
      taskId: "",
      forms: {
        followupResult: "身份证件"
      }, //弹出框表单
      dialogVisibleTable:false,
      contractRequireList:[],
      checkExpire:'',//年检有效期
    };
  },
  created() {
    // if(this.$route.query.state) this.state = this.$route.query.state;
    if(this.$route.query.id) this.taskId = this.$route.query.id;
    this.certRequireList=this.$store.state.setting.certRequireList;//办证要求
    this.getCertificateTaskDetails(); //获取详情
  },
  methods: {
    onComplete(row){//跟踪完成
      this.$confirm('是否确认跟踪完成！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.postTraceMaterialFinish(row.id).then(res=>{
          if(res.code==0){
            this.getCertificateTaskDetails(); //获取详情
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    onSubmitFollow() {//提交跟踪任务
      this.$refs['forms'].validate(valid => {
        if (valid) {
          let query={
            "certTaskId": this.taskId,
            "carId": this.res.carId,
            "traceName": this.forms.itemName,
            "appointFinishTime": this.forms.appointFinishTime,
            "remark": this.forms.remark
          }
          this.$api.postTraceMaterialAdd(this.$$.delete_null_properties(query)).then(res=>{
            if(res.code==0){
              this.getCertificateTaskDetails(); //获取详情
              this.$message.success(res.message)
              this.ondialogVisible()
            }
          })
        } else {
          return false;
        }
      });
    },
    doSubmit() {
      //提交
      let type = false;
      let state='process_success';//【办证专员继续跟进 process_continue，办证专员过户完成 process_success, 办证专员退车 apply_return_car，办证公司继续跟进 com_process_continue，办证公司办证完成 com_process_success, 办证公司上传查验单 com_process_upload】
      if (this.followupForm.taskResult==41){
        this.$refs.followupForm.validateField("followTime", err => {
          if (err) {
            type = true;
          }
        });
        state='process_continue';
      }
      if (type == true) {
        return false;
      }
      let query={
        taskId:this.taskId,
        taskProcessManageResult:state,
        certInviteTraceForm:{
          certTaskId:this.taskId,
          carId:this.res.carId,
          // nextInviteTime:this.followupForm.followTime,
          certInviteStatus:this.followupForm.taskResult,
          description:this.followupForm.taskRemark
        }
      }
      if(this.followupForm.taskResult==41){
        query.certInviteTraceForm.nextInviteTime=this.followupForm.followTime;
      }
      this.$api.postTaskOperaProcess(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.getCertificateTaskDetails();//详情
        }
      })
    },
    onReturn(){//关闭
      this.bus.$emit("closeSelected");
    },
    onrouterPage() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/accreditationCommissioner/certificateManagement"
      });
    },
    onrouter(state) {
      //跳转客户商户合同
      let aurl = "/salesManager/contractMaking";
      let id=this.customerContract.id;//客户合同
      if (state) {
        aurl = "/salesAssistant/merchantContract";
        id=this.merchantContract.id;//商户合同
      }
      if(!id){
        this.$message('当前暂无合同！')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    returnCar() {
      this.$confirm('请确认是否退车?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          taskId:this.taskId,
          taskProcessManageResult:'apply_return_car'
        }
        this.$api.postTaskOperaProcess(query).then(res=>{
          if(res.code==0){
            this.$message.success(res.message)
            this.onrouterPage();//
          }
        })
      }).catch(() => {});
    },
    getCertificateTaskDetails() {//获取详情
      this.$api.getCertificateTaskDetails(this.taskId).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = JSON.parse(JSON.stringify(res.data));
            this.form=JSON.parse(JSON.stringify(res.data.car));
            if(this.form.checkExpire){
              let al=this.form.checkExpire.split('-')
              this.checkExpire=`${al[0]}年${al[1]}月`
            }
            // state:1,//1邀约办证，2跟进邀约，3查看
            switch(res.data.taskProcessStatus){
              case 41:
                this.state=1;
                break;
              case 42:
                this.state=2;
                break;
              case 43:
                this.state=3;
                break;
            }
            //carRejectStatus退车状态：1已退车
            if(res.data.carRejectStatus===1){
              this.state=3;
            }
            
            if(res.data.car){
              if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;//商户表
              if(res.data.car.customerContract){
                this.customerContract=res.data.car.customerContract;//客户合同表
                let contractRequireList=res.data.car.customerContract.contractRequireList?res.data.car.customerContract.contractRequireList:[];
                  let newCR=[]
                  contractRequireList.map((item) =>{
                    if(item.isSelected===1&&item.requireType===11){//要求类型: 11过户凭证特殊要求，12材料取回约定表，13其他约定事项
                      newCR.push(item)
                    }
                  })
                  this.contractRequireList=newCR;
              } 
              if(res.data.car.merchantContract) this.merchantContract=res.data.car.merchantContract;//商户合同表
              if(res.data.car.customerContract) this.customerContract=res.data.car.customerContract;//客户合同表
            }
            if (!res.data.car.carCustomer) this.form.carCustomer = {};
            if (!res.data.car.carMerchant) this.form.carMerchant = {};
            //办证跟踪任务列表
            this.certMaterialTraceList=res.data.certMaterialTraceList;
            //客户邀约跟进记录列表
            this.certInviteTraceList=res.data.certInviteTraceList;
          }
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.certificateBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.certificateBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .flex_space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
}

</style>
