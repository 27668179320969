<template>
  <div class="pageContainer">
    <el-row :gutter="24">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店简称</span>
        <el-input v-model="form.channelName" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>所属区域</span>
        <el-cascader
          v-model="areaArr" clearable
          :options="addresslist"
          :props="props"></el-cascader>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>拓展专员</span>
        <!-- <el-input v-model="form.extensionerName" size="medium"></el-input> -->
        <el-select v-model="form.extensionerId" clearable size="medium" filterable>
          <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <!-- <el-select v-model="form.contactName" size="medium">
          <el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select> -->
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w3">
        <span>渠道编号</span>
        <el-input v-model="form.channelCode" size="medium"></el-input>
      </el-col>
      <el-col :span="4" class="flex flex_align_center flex_between w2">
        <span>状态</span>
        <el-select v-model="form.statuses" size="medium" clearable>
          <el-option v-for="(item,index) in statelist" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt30 table-mainbg">
      <el-table-column prop="channelCode" label="渠道编号" align="center"></el-table-column>
      <el-table-column prop="channelName" label="合作店简称" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="拓展专员" align="center"></el-table-column>
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="bookAddress" label="地址" align="center"></el-table-column>
      <el-table-column prop="checkStatusName" label="状态" align="center"></el-table-column>
      <el-table-column prop="remark" label="失败原因" align="center"></el-table-column>
      <el-table-column prop="submitTime" label="申请时间" align="center"></el-table-column>
      <el-table-column prop="bookTime" label="检测时间" align="center"></el-table-column>
      <el-table-column prop="bookCount" label="预约检测数量" align="center"></el-table-column>
      <el-table-column prop="actualCount" label="实际检测数量" align="center"></el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="scope" v-if="scope.row.checkStatus===0">
          <el-button type="primary" @click="onsee(scope.row,1)" size="mini">分配检测师</el-button>
          <el-button type="primary" @click="onsee(scope.row,2)" size="mini">无产能</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
    <el-dialog title="" :visible.sync="dialogVisible" width="650px">
      <div class="modalTitle">检测信息:</div>
      <el-row :gutter="24">
        <el-col :span="12">合作店简称：{{itemRow.channelName}}</el-col>
      </el-row>
      <el-row class="mt10" :gutter="24">
        <el-col :span="12">开始检测时间：{{itemRow.bookTime}}</el-col>
        <el-col :span="12">预约检测数量（辆）：{{itemRow.bookCount}}</el-col>
      </el-row>
      <template v-if="itemState==1">
        <div class="modalTitle mt15">检测信息:</div>
        <el-input v-model="searchContent" size="medium" placeholder="请输入姓名" @input="getChannelTaskCheckerList(searchContent)"></el-input>
        <el-table :data="checklist" border stripe class="mt20" :height="modalTableHeight" >
          <el-table-column prop="loginName" label="账号" align="center"></el-table-column>
          <el-table-column prop="name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="name" label="已分配单数" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="bottom"
                width="400"
                trigger="click">
                <el-table :data="scope.row.tasks" border stripe>
                  <el-table-column  property="channelName" label="合作店简称"></el-table-column>
                  <el-table-column property="bookTime" label="开始检测时间"></el-table-column>
                  <el-table-column property="bookCount" label="预约检测数量"></el-table-column>
                </el-table>
                <div slot="reference">{{scope.row.tasks.length}}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onarrange(scope.row)" size="mini">发送检测调度</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-if="itemState==2">
        <div class="modalTips">此检测确认无法安排检测师吗！</div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="postTaskChannel">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </template>
      
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        statuses:'',
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      addresslist:[],
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      areaArr:[],//选择的地区
      userlist:[],//拓展专员
      checklist:[],//检测师
      statelist:[{name:'全部',id:''},{name:'未安排',id:0},{name:'已安排',id:1},{name:'检测取消',id:4},{name:'待检测',id:2},{name:'检测完成',id:3},],//状态
      modalTableHeight: 300,
      itemRow:{},
      searchContent:'',
      itemState:1,//
    };
  },
  created() {
    this.init();
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.areaList.length==0){
      this.$datadic.getProvince(0).then(res=>{
        this.addresslist=res;
      })
    }else{
      this.addresslist=this.$store.state.setting.areaList;
    }
    this.queryUserListByRoleAndDepart('拓展专员')//获取拓展专员
    
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        channelName: this.form.channelName, //合作店简称
        "areaId":  this.areaArr.length>0?this.areaArr[2]:'',
        extensionerId:this.form.extensionerId,//拓展专员
        channelCode:this.form.channelCode,//渠道编号
        statuses:this.form.statuses!==''?[this.form.statuses]:''
      };
      this.$api.getChannePage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = []
            this.total=0;
          }
        }
      });
    },
    onsee(row, state) {
      this.itemRow=row;
      this.itemState=state;
      if(state==1){
        this.getChannelTaskCheckerList()//渠道检测任务分配检测师列表
      }
      this.ondialogVisible();
    },
    onarrange(row) {
      //协调检测师操作
     this.postChanneCoordinate(this.itemRow.id,row.id)
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    // selectTable(e){
    //   let oldArr=JSON.parse(JSON.stringify(this.checkOldlist))
    //   let arr;
    //   if(e.trim()){
    //     arr=oldArr.filter((item)=>{
    //       return Object.keys(item).some(()=>{
    //         return String(item['name']).toLowerCase().match(e.trim())
    //       })
    //     })
    //   }else{
    //     arr=this.checkOldlist;
    //   }
    //   this.checklist=arr;
    // },
    postChanneCoordinate(id,checkerId){//协调检测师
      this.$api.postChanneCoordinate(id,checkerId).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.ondialogVisible()
          this.init()
        }
      })
    },
    postTaskChannel(){//渠道检测任务取消
      let query={
        "checkTaskId": this.itemRow.id,
        "isMeet": 0,
        "cancelType": "无产能",
        "cancelReason": "无产能",
        // "cancelPhoto": "string",
        // "remark": "string"
      }
      this.$api.postTaskChannel(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.ondialogVisible()
          this.init(1)
        }
      })
    },
    queryUserListByRoleAndDepart(roleName){//根据角色和部门查询用户
      this.$api.queryUserListByRoleAndDepart({roleName:roleName}).then(res=>{
        if(res.code==0){
          this.userlist=res.data;
        }
      })
    },
    getChannelTaskCheckerList(checkerName){//渠道检测任务分配检测师列表
      let query={
        checkerName:checkerName
      }
      this.$api.getChannelTaskCheckerList(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.checklist=res.data;
        }
      })
    },
    
  }
};
</script>
<style lang="scss" scoped>
.modalTitle{
  font-size:18px;
  margin-bottom:10px;
  font-weight:bold;
}
.modalTips{
  font-size:18px;
  color:#F56C6C;
  font-weight:bold;
  text-align: center;
  margin-top: 20px;
}
</style>
