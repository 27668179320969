<template>
<!-- 客户载入 -->
<div class="customer" :style="`padding-bottom:${res.validity===1?'100px':''}`">
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="110px" class="customerForm" size="medium">
    <el-row :gutter="20" style="display:flex;">
      <el-col :span="12">
        <div class="leftInfo">
          <div class="formItem">
            <div class="line">
              <div class="title_top_nameBox mb15">客户基本信息</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户编号：">
                    <p>{{ruleForm.customerNo}}</p>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="创建时间：">
                    <p>{{ruleForm.createDate}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="渠道来源：" prop="channel">
                    <el-cascader v-model="ruleForm.channel" :options="channelList" :disabled="res.isCopy===0&&res.channel?true:false" class="w250"></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="400帮卖点选时间：" label-width="160px">
                    <p>{{ruleForm.firstComeTime}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户归属：">
                    <p>上海</p>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="客户名称：" prop="customerName" label-width="120px">
                    <el-input v-model="ruleForm.customerName" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="主要联系电话：" label-width="130px">
                    <span class="setFontSize16">{{ruleForm.mainPhone}}</span>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="次要联系电话：" label-width="130px">
                    <span class="setFontSize16">{{ruleForm.otherPhone}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <div>
                <el-form-item label="客户地址：">
                  <AddressInfo @changeAddress='changeAddress' :address = "ruleForm.addr"/>
                  <el-input style="width: 370px;margin-top:10px;" type="text" v-model="ruleForm.address"/>
                </el-form-item>
              </div>
              <div class="flex flex_center" style="margin-bottom:10px">
                <el-button size="mini" plain type="primary" @click="onCall(1)" :disabled="res.validity===1?false:true" >呼叫</el-button>
                <el-button size="mini" plain type="primary" @click="onCall(2)" :disabled="res.validity===1?false:true" >保密呼</el-button>
                <!-- <el-button size="mini" plain type="primary" @click="onCall(3)">首导0</el-button> -->
                <el-button size="mini" plain type="primary" @click="onCall(3)" :disabled="res.validity===1?false:true" >主次互换</el-button>
                <el-button size="mini" plain type="primary" @click="onCall(4)" :disabled="res.validity===1?false:true" >修改次要联系电话</el-button>
              </div>
            </div>
          </div>
          <div class="formItem">
            <div class="line">
              <div class="title_top_nameBox mb15">经营范围</div>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="车牌号码：" prop="carNo" label-width="110px">
                    <el-input v-model="ruleForm.carNo" class="carNo" disabled></el-input>
                    <el-button plain size="mini" type="primary" @click="getCarId" :disabled="res.validity===1&&checkStatus!=3?false:true" >修改车牌</el-button>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="初登年份：">
                    <el-date-picker v-model="ruleForm.registrationYear" type="year" :disabled="checkStatus==3?true:false" :picker-options="pickerOptions" value-format="yyyy" placeholder="选择年"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="是否黄牛：">
                    <el-radio-group v-model="ruleForm.isHn">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :xl="12" :lg="24">
                  <el-form-item label="是否抵押贷款：" label-width="125px">
                    <el-radio-group v-model="ruleForm.isMortgage" >
                      <el-radio :label="0">否</el-radio>
                      <el-radio :label="1">是</el-radio>
                      <!-- <el-radio :label="-1">未识别</el-radio> -->
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :xl="12" :lg="24">
                  <el-form-item label="品牌车型：">
                    <el-cascader
                      v-model="ruleForm.carModel" size="medium"
                      placeholder="请选择"
                      :options="optionsCar"
                      :props="propCar"  @visible-change="getBaseCarBrandList" :disabled="checkStatus==3?true:false" 
                      filterable></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="formItem">
            <div class="title_top_nameBox mb15">业务信息</div>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="24">
                <el-form-item label="客户类型：" label-width="110px" prop="customerType">
                  <el-select v-model="ruleForm.customerType" placeholder="请选择客户类型" :disabled="customerType===0?true:false">
                    <el-option v-for="item in $store.state.setting.customerTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="24">
                <el-form-item label="创建人："><p>{{ruleForm.createUserName}}</p></el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="24">
                <el-form-item label="服务类型：" prop="serviceType" label-width="110px">
                  <el-cascader clearable v-model="ruleForm.serviceType" :options="serviceOptions" :props="{ value: 'id', label: 'type', children: 'smallTypes' }"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="24">
                <el-form-item label="当前跟进人：" label-width="130px">
                  <div class="flex">
                    <span>{{ruleForm.followUpUserName}}</span>
                    <Remind class="ml20" v-if="ruleForm.followUpUserName" :followUpInfoId="followUpInfoId" :disabled="res.validity===1?false:true"  :userId='ruleForm.followUpUserId' :plateNumber="ruleForm.carNo"></Remind>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :xl="12" :lg="24">
                <el-form-item label="下次联系时间：" label-width="140px" prop="nextContactTime">
                  <el-date-picker v-model="ruleForm.nextContactTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xl="12" :lg="24">
                <el-form-item label="检维归属：" label-width="115px">
                  <div class="flex">
                    <span>{{ruleForm.assignMaintainUserName}}</span>
                  <Remind class="ml20" v-if="ruleForm.assignMaintainUserName"  :disabled="res.validity===1?false:true"  :followUpInfoId="followUpInfoId" :userId='ruleForm.assignMaintainUserId' :plateNumber="ruleForm.carNo"></Remind>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :xl="16" :lg="24" v-if="!['大客户'].includes(ruleForm.bigChannelName)">
                <el-form-item label="推荐新能源：" label-width="130px" prop="recommendNewEnergy">
                  <el-radio-group v-model="ruleForm.recommendNewEnergy" :disabled="res.recommendNewEnergy===2?true:false" :class="!ruleForm.recommendNewEnergy?'tipsColor':''" @input="onRecommendNewEnergy">
                    <el-radio :label="1">待跟进</el-radio>
                    <el-radio :label="2">推荐成功</el-radio>
                    <el-radio :label="3">推荐失败</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :xl="24" :lg="24" v-if="[2,3].includes(ruleForm.recommendNewEnergy)">
                <el-form-item label="" label-width="120px" prop="recommendEnergyExplain" v-if="[3].includes(ruleForm.recommendNewEnergy)">
                  <el-select v-model="ruleForm.recommendEnergyExplain" >
                    <el-option v-for="item in failureReasonlist" :key="item.id" :label="item.name" :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" label-width="120px" v-if="[2].includes(ruleForm.recommendNewEnergy)">
                  <el-input type="textarea" :disabled="res.recommendNewEnergy===2?true:false" v-model="ruleForm.recommendEnergyExplain" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="8" :lg="24" class="assignBox">
                <el-form-item label="成交归属：" label-width="115px">
                  <div class="flex">
                    <span>{{ruleForm.assignTradeUserName}}</span>
                    <Remind class="ml20" v-if="ruleForm.assignTradeUserName" :disabled="res.validity===1?false:true"  :followUpInfoId="followUpInfoId" :userId='ruleForm.assignTradeUserId' :plateNumber="ruleForm.carNo"></Remind>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 预约状态 -->
          <div class="aStatus" v-show="res.validity===0">
            <div class="div1"><div class="div2"></div></div>
            <div class="div3">已放弃</div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="rightInfo">
          <Record :customerDealBookList="customerDealBookList" :priceNoteList="priceNoteList" :res="res" :serviceNoteList="serviceNoteList" :customerCheckBookList="customerCheckBookList" :relatedCallList="relatedCallList" :resReport="resReport"
          :recordList="recordList"
          @cancelDeal="cancelDeal" @init="init"></Record>
          <div class="title_top_nameBox serviceRecord">服务记录</div>
          <el-form-item label="记录类型："  label-width="110px">
            <el-cascader v-model="ruleForm.recordType" :options="recordOptions" class="recordTypeWidth" :props="{ value: 'id', label: 'title', children: 'smallTitles' }" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="内容" class="formContent" label-width="0">
            <el-input type="textarea" v-model="ruleForm.content" :rows="4"></el-input>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
  <div class="footer" v-if="res.validity===1">
    <div class="btn">
      <el-button type="primary" @click="doSubmit()" >保存</el-button>
      <el-button type="primary" @click="onRouter(1)" v-if="res.checkBookStatus==1&&res.isShop==1||res.checkBookStatus!=1">门店检测</el-button>
      <el-button type="primary" @click="onRouter(2)" v-if="res.checkBookStatus==1&&res.isShop==0||res.checkBookStatus!=1">外勤检测</el-button>
      <el-button type="primary" @click="onRouter(3)" v-if="res.checkBookStatus==1&&res.isShop==0">外勤回访</el-button>
      <el-popover placement="top" v-model="visible" style="margin-left:20px;margin-right:20px;">
        <div style="margin: 0; display:flex;">
          <el-button size="mini" type="primary" @click="carBrand(1)">同车不同牌</el-button>
          <el-button type="primary" size="mini" @click="carBrand(2)">同牌不同车</el-button>
          <el-button type="primary" size="mini" @click="copyBuild">复制新建</el-button>
        </div>
        <el-button slot="reference" type="primary">一人多车</el-button>
      </el-popover>
      <el-button type="primary" @click="giveUpCustomer" >放弃</el-button>
      <el-button type="primary" @click="onSms" >发送短信</el-button>
    </div>
  </div>
  <el-dialog
    title="外勤回访"
    :visible.sync="dialogVisible">
    <el-form ref="form" :model="form" label-width="350px" :label-position="'left'" :rules="modelFules">
      <el-form-item label="回访结果:" label-width="100px" prop='result'>
        <el-radio-group v-model="form.result">
          <el-radio-button label="1">回访成功</el-radio-button>
          <el-radio-button label="0">回访失败</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <div v-show="form.result==1">
      <el-form-item label="请问我们的检测师是否准时到达?">
        <el-radio-group v-model="form.punctual">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="请问我们的检测师是否穿了车开新的工作服?">
        <el-radio-group v-model="form.workClothes">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="检测师是否提醒您观看介绍视频?">
        <el-radio-group v-model="form.video">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="检测师进入车内前是否给车辆铺设了脚垫?">
        <el-radio-group v-model="form.footPad">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      </div>
      <div v-show="form.result==0">
        <el-form-item label="失败原因:" label-width="100px">
          <el-radio-group v-model="form.failReason">
            <el-radio-button label="1">电话无人接听/关机</el-radio-button>
            <el-radio-button label="2">检测时本人不在场</el-radio-button>
            <el-radio-button label="3">客户拒访</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注:" label-width="100px">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="returnVisit">确定</el-button>
      <el-button @click="dialogVisible = false">取消</el-button>
    </span>
  </el-dialog>

  <el-dialog
    :title="statePhone==1?'修改次要电话':'导入其它客户主号码'"
    :visible.sync="dialogVisiblePhone">
    <div class="flex flex_align_center">
      <span>{{statePhone==1?'次要联系电话：':'客户编号：'}}</span>
      <el-input v-model="formPhone.phone" size="medium" class="w200" v-show="statePhone==1"></el-input>
      <el-input v-model="formPhone.customerNo" size="medium" class="w200" v-show="statePhone==2"></el-input>
      <span class="modelPhoneCur" v-show="statePhone==1" @click="statePhone=2">导入其他客户主联系号码</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitPhone">{{statePhone==1?'提交':'导入'}}</el-button>
      <el-button @click="dialogVisiblePhone = false">取 消</el-button>
    </span>
  </el-dialog>

  <el-dialog
    title="保密呼"
    :visible.sync="dialogVisibleSecrecy">
    <el-form ref="formSec" :model="formSec"  :label-position="'left'" :rules="formSecRules">
      <el-form-item label="选择呼叫号码:" prop='phoneType'>
        <el-radio-group v-model="formSec.phoneType">
          <el-radio :label="0" v-if="ruleForm.mainPhone">主要联系电话</el-radio>
          <el-radio :label="1" v-if="ruleForm.otherPhone">次要联系电话</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择设备:" prop='callerNum'>
        <el-radio-group v-model="formSec.callerNum">
          <el-radio v-for="item in fixedPhonelist" :key="item.phone" :label="item">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitMM">确定</el-button>
      <el-button @click="dialogVisibleSecrecy = false">取 消</el-button>
    </span>
  </el-dialog>
  <el-dialog
    :title="carState===1?'请输入车牌号':'同车不同牌'"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" v-if="carState===2">新车牌号码：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import Record from '@/components/common/cServiceRecord.vue'
import AddressInfo from '@/components/common/addressInfo.vue'
import Remind from '@/components/common/remind.vue'
export default {
  components: {
    Record, AddressInfo, Remind
  },
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      carState:1,//显示内容
      ruleForm: {
        addr: [],
        address: '',
        serviceType: [],
        customerName: '',
        brandId: '',
        seriesId: '',
        mainPhone: '',
        otherPhone: '',
        channel: '',
        customerNo: '',
        carNo: '',
        isHn: '',
        registrationYear: '',
        isMortgage: '',
        customerType: null,
        nextContactTime: null,
        recordType: '',
        content: '',
        id: '',
        createDate: '',
        carModel:[],
        recommendNewEnergy:'',
        recommendEnergyExplain:'',
      },
      followUpInfoId: '',//业务跟进Id
      rules: {
        customerName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        carNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        customerType: [{ required: true, message: '请选择', trigger: 'change' }],
        nextContactTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
        serviceType: [{ required: true, message: '请选择', trigger: 'change' }],
        recordType: [{ required: true, message: '请选择', trigger: 'change' }],
        content: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        channel: [{ required: true, message: '请选择', trigger: 'change' }],
        recommendNewEnergy:[{ required: true, message: '不能为空', trigger: 'change' }],
        recommendEnergyExplain:[{ required: true, message: '不能为空', trigger: 'change' }],
      },
      modelFules:{
        result: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      serviceOptions: [],
      serviceNoteList: [],//服务记录信息
      customerCheckBookList: [],//检测预约列表
      customerDealBookList: [],//成交预约列表
      relatedCallList: [],//相关来电列表
      priceNoteList: [],//竞价历史列表
      resReport:{},
      recordList:[],
      visible: false,
      isBookSuccess: false,
      seriesOptions: [],
      brandOptions: [],
      brandLoading: false,
      seriesLoading: false,
      channelList: [],
      titleList: [],
      channelId: null,
      assignCheckUserName: null,
      createUserName: null,
      assignMaintainUserName: null,
      assignTradeUserName: null,
      firstComeTime: null,
      checkBookId: null,//检测预约Id
      isShop: null,
      dialogVisible:false,//外勤回访
      form:{},//外勤回访
      optionsCar:[],//品牌车型
      propCar:{
        label:'name',
        value:'id',
        children:'carSeriesList',
        checkStrictly: true 
      },
      recordOptions:[],
      res:{},
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      userData:{},
      roleName:'',
      cno:'',//坐席号
      loginType:false,//软电话是否登陆
      dialogVisiblePhone:false,
      statePhone:1,
      formPhone:{
        phone:'',
        customerNo:''
      },
      customerType:'',
      dialogVisibleSecrecy:false,
      formSec:{},
      formSecRules:{
        phoneType: [{ required: true, message: '请选择', trigger: 'change' }],
        callerNum: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      fixedPhonelist:[],//获取拨打保密呼叫的固定手机列表
      checkStatus:'',//检测状态 0-待调度,1-待检测,2-检测中,3-检测成功,4-检测失败
      mainUniqueId:'',//通话唯一标识
      failureReasonlist:[{id:1,name:'单卖'},{id:2,name:'换燃油车'},{id:3,name:'换新能源-品牌不符'},{id:4,name:'无换新车计划'},{id:5,name:'新车已订'},{id:6,name:'客户拒绝'},{id:7,name:'其他'}],
    }
  },
  created() {
    if(this.$route.query.mainUniqueId){
      this.mainUniqueId=this.$route.query.mainUniqueId
    }
    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;
    let roleObj=this.$$.roleName(this.userData)
    this.roleName=roleObj.name;

    if(this.$route.query.followUpInfoId){
      this.followUpInfoId = Number(this.$route.query.followUpInfoId);
      this.customerQueryCustomerByFollowUpId();
      this.customerQueryChannel();//渠道来源
      this.customerGetTaskType();//服务大小类所有选项
      this.customerGetServiceTitle();//获取记录类型
    }else{
      this.$message.warning('客户跟进业务ID缺失');
    }
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.optionsCar=res;
      })
    }else{
      this.optionsCar=this.$store.state.setting.carBrandList;
    }

    this.getFixedPhone()//获取拨打保密呼叫的固定手机列表
  },
  mounted(){},
  methods: {
    getBaseCarBrandList(e){
      if(e){
        this.$api.baseCarBrandList().then((res)=>{
          if(res.code==0){
            this.optionsCar=res.data;
          }
        })
      }
    },
    cancelDeal(){
      // console.lo
      this.$confirm(`您是否确认取消检测预约`, '二次确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          "checkBookId": this.res.checkBookId,
          "cancelReason": `${this.roleName}取消检测`,
          // "remark": ""
        }
        this.$api.updateCheckBookCancel(query).then(res=>{
          if(res.code==0){
            this.customerQueryCustomerByFollowUpId()//获取详情
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    onSms(){
      this.$router.push({
        path:'/sendSMS',
        query:{
          followUpInfoId:this.followUpInfoId
        }
      })
    },
    getCarId(){
      this.dialogVisibleCar=true;
      this.formCar='';
      this.carState=1;//修改车牌
    },
    onSubmitCar(){//修改车牌号
      let _this=this;
      if(!this.formCar){
        this.$message.warning('请输入车牌号！')
        return
      }else{
        const reg = /[!@#$%^&*(),.?":。，=·～{}|<> ]/;
        if (reg.test(this.formCar)) {
          this.$message.warning('车牌号不能输入特殊字符！')
          return
        }
      }
      if(this.carState===1){
        let query={
          newCarNo:this.formCar,
          customerId:this.res.id
        }
        _this.$api.postAlterCarNo(query).then(res=>{
          if(res.code===0){
            _this.customerQueryCustomerByFollowUpId();
            _this.$message.success(res.message)
            this.dialogVisibleCar=false;
          }
        })
      }else if(this.carState===2){
        this.postCustomerCopyCustomer(1,this.formCar)
      }
    },
    onUpload(){//导入联系电话
      this.statePhone=2;
    },
    customerQueryChannel() {
      this.$datadic.getCustomerQueryChannel().then(res => {
        this.channelList = res;
      }).catch(msg => {
        this.$message.error(msg);
      })
    },
    init(){
      this.customerQueryCustomerByFollowUpId();//重新获取数据
    },
    // 客户详情查询
    async customerQueryCustomerByFollowUpId() {
      let res = await this.$api.customerQueryCustomerByFollowUpId(this.followUpInfoId);
      if(res.code == 0){
        this.res=JSON.parse(JSON.stringify(res.data));
        this.ruleForm=JSON.parse(JSON.stringify(res.data));
        this.customerType=res.data.customerType;

        if(res.data.checkReportId) this.getCheckReport()//获取检测报告
        if(res.data.checkBookId){
          this.getCustomerRecordList();//根据业务跟进ID查询客户概况
        }
        if(['大客户'].includes(this.res.bigChannelName)){
          if(this.res.isMortgage===''||this.res.isMortgage===null){
            this.ruleForm.isMortgage=0;
          }
        }
        
        //品牌车型
        if(res.data.seriesId){
          this.ruleForm.carModel=[res.data.makerId,res.data.seriesId]
        }else{
          if(res.data.makerId){
            this.ruleForm.carModel=[res.data.makerId]
          }else{
            this.ruleForm.carModel=[]
          }
        }
        this.channelId = res.data.channelId;
        if(res.data.bigChannelId) this.ruleForm.channel=[res.data.bigChannelId,res.data.channelId];
        this.ruleForm.addr = [res.data.provinceId,res.data.cityId,res.data.regionId]
        if(res.data.bigType){
          this.ruleForm.serviceType = [res.data.bigType,res.data.smallType]
        }
        // this.ruleForm.address = res.data.address;
        this.assignCheckUserName = res.data.assignCheckUserName;
        this.assignTradeUserName = res.data.assignTradeUserName;
        this.assignMaintainUserName = res.data.assignMaintainUserName;
        this.createUserName = res.data.createUserName;
        this.firstComeTime = res.data.firstComeTime;
        this.checkBookId = res.data.checkBookId;
        this.isShop = res.data.isShop;
        // if(res.data.createDate){
        //   this.ruleForm.createDate = this.$$.getFormatMomentTime('YYYY-MM-DD HH:mm:ss',res.data.createDate);
        // }
        
        this.$set(this.ruleForm, 'assignMaintainUserId', res.data.assignMaintainUserId)
        this.$set(this.ruleForm, 'assignTradeUserId', res.data.assignTradeUserId)
        if(res.data.serviceNoteList) if(res.data.serviceNoteList.length){//服务记录信息
          this.serviceNoteList = res.data.serviceNoteList;
        }
        if(res.data.customerCheckBookList){//检测预约列表
          this.customerCheckBookList = res.data.customerCheckBookList?res.data.customerCheckBookList:[];
        }
        this.checkStatus=this.customerCheckBookList.length?this.customerCheckBookList[0].checkStatus:'';//检测状态 0-待调度,1-待检测,2-检测中,3-检测成功,4-检测失败

        if(res.data.customerDealBookList){//成交预约列表
          this.customerDealBookList = res.data.customerDealBookList?res.data.customerDealBookList:[];
        }
        if(res.data.relatedCallList){//相关来电列表
          this.relatedCallList = res.data.relatedCallList?res.data.relatedCallList:[];
        }
        if(res.data.priceNoteList){//竞价历史列表
          this.priceNoteList = res.data.priceNoteList?res.data.priceNoteList:[];
        }
          
        this.$nextTick(()=>{
          if(this.$refs['ruleForm']){
            this.$refs['ruleForm'].clearValidate();
          }
        })

        this.getChannel()//根据返回的channelId,遍历channelList得到channel
      }
    },
    //服务大小类所有选项
    customerGetTaskType() {
      this.$datadic.getTaskType().then(res => {
        this.serviceOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    customerGetServiceTitle() {//服务记录标题-大小标题
      this.$datadic.getServiceTitle().then(res => {
        this.recordOptions = res;
      }).catch((msg) => {
        this.$message.error(msg);
      })
    },
    changeAddress(val) {
      this.ruleForm.addr = val;
    },
    onRecommendNewEnergy(){
      if(this.ruleForm.recommendNewEnergy!==3){
        this.ruleForm.recommendEnergyExplain='';
      }
    },
    onRouter(state){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.ruleForm.customerType!==0&&state!=3){
            if(state===1){
              this.$message.warning('"只有400帮卖”才可以增加门店检测预约!')
              return
            }else if(state===2){
              this.$message.warning('"只有400帮卖”才可以增加外勤检测预约!')
              return
            }
          }
          switch(state){//state=1,门店检测,2=外勤检测,3=外勤回访
            case 1:
              if(this.ruleForm.isMortgage===''||this.ruleForm.isMortgage===null){
                this.$message.warning('是否抵押贷款不可为空！')
                return
              }
              this.doSubmit('no')
              if(this.res.checkBookStatus==1){
                this.$router.push({path: '/testService/reschedule', query: {checkBookId:this.res.checkBookId}})
              }else{
                this.$router.push({path: '/testService/storeDR', query: {customerId:this.res.id,followUpInfoId:this.followUpInfoId}})
              }
              break;
            case 2:
              if(this.ruleForm.isMortgage===''||this.ruleForm.isMortgage===null){
                this.$message.warning('是否抵押贷款不可为空！')
                return
              }
              this.doSubmit('no')
              this.getCheckResourceGetFieldPlan()
              break;
            case 3:
              this.dialogVisible=true;
              break;
          }
        }else{
          this.$nextTick(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      })
    },
    doSubmit(type) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            id: this.ruleForm.id,
            customerName: this.ruleForm.customerName,
            channelId: this.ruleForm.channel?this.ruleForm.channel[1]:'',
            address: this.ruleForm.address,
            // carNo: this.ruleForm.carNo,
            registrationYear: this.ruleForm.registrationYear,
            isHn: this.ruleForm.isHn,
            isMortgage: this.ruleForm.isMortgage,
            makerId:this.ruleForm.carModel.length>0?this.ruleForm.carModel[0]:'',//车品牌厂家ID
            seriesId:this.ruleForm.carModel.length>1?this.ruleForm.carModel[1]:'',//车系ID
            customerType: this.ruleForm.customerType,
            serviceType: this.ruleForm.serviceType[1],
            nextContactTime: this.ruleForm.nextContactTime,
            serviceTitle: this.ruleForm.recordType?this.ruleForm.recordType[1]:'',//小标题ID
            content: this.ruleForm.content,
            provinceId: this.ruleForm.addr[0],
            cityId: this.ruleForm.addr[1],
            regionId: this.ruleForm.addr[2],
            sessionId:this.mainUniqueId,//通话唯一标识
            recommendNewEnergy:this.ruleForm.recommendNewEnergy,//推荐新能源
            recommendEnergyExplain:this.ruleForm.recommendEnergyExplain,
          }
          this.$api.customerEditCustomer(params).then(res => {
            if(res.code == 0){
              if(this.mainUniqueId){
                this.bus.$emit("closeSelected",'router');
                this.$router.push({
                  path: "/testService/customer",
                  query:{
                    followUpInfoId:this.followUpInfoId
                  }
                });
              }else{
                if(!type){
                  this.customerQueryCustomerByFollowUpId()//详情
                  this.$message.success(res.message)
                }
              }
              
            }
          })
        } else {
          this.$nextTick(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest
              block: "center",
              // 值有auto、instant,smooth，缓动动画
              behavior: "smooth",
            });
          });
        }
      });
    },
    giveUpCustomer(){//放弃
      this.$confirm('是否确认放弃此数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.postGiveUpCustomer({followUpInfoId:this.followUpInfoId}).then(res=>{
          if(res.code==0){
            this.customerQueryCustomerByFollowUpId()//详情
            this.$message.success(res.message)
          }
        })
      }).catch(() => {});
    },
    onCall(state){
      switch(state){
        case 1://呼叫
          // this.getCallDetailByCno().then(()=>{
            if(this.ruleForm.mainPhone&&this.ruleForm.otherPhone){
              this.$confirm('请选择当前拨打的电话号码', '提示', {
                confirmButtonText: '主要电话',
                cancelButtonText: '次要电话',
                type: 'warning'
              }).then(() => {
                this.postCallout(1,this.ruleForm.mainPhone)
              }).catch(() => {
                this.postCallout(2,this.ruleForm.otherPhone)        
              });
            }else{
              this.postCallout(1,this.ruleForm.mainPhone)
            }
          // })//根据员工工号查询坐席信息(拨打电话)
          // if(!this.loginType){
          //   this.$message.warning('请先登录软电话！')
          //   return
          // }
          
          break;
        case 2://华为云
          this.dialogVisibleSecrecy=true;
          break;
        case 3://主次互换
          this.$confirm('请确认是否进行主次互换？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$api.postExchangePhone(this.res.id).then(res=>{
                if(res.code==0){
                  this.customerQueryCustomerByFollowUpId();//查询详情
                  this.$message.success(res.message)
                }
              })
            }).catch(() => {});
          break;
        case 4://修改次要联系电话
          this.statePhone=1;
          this.formPhone.phone=this.ruleForm.otherPhone;
          this.dialogVisiblePhone=true;
          break;
      }
    },
    onSubmitMM(){
      this.$refs['formSec'].validate((valid) => {
        if (valid) {
          let query={
            callerNum:this.formSec.callerNum.phone,
            phoneType:this.formSec.phoneType,
            customerId:this.res.id,
          }
          this.$api.postPrivacyCallOut(query).then(res=>{
            if(res.code==0){
              this.$alert(`请在${this.formSec.callerNum.name}手机上拨打${res.data}`, '提示', {
                confirmButtonText: '确定',
                callback: () => {
                  this.dialogVisibleSecrecy=false;
                }
              });
            }
          })
        }
      });
    },
    onSubmitPhone(){//修改次要电话
      let query={
        "customerId": this.res.id,
        // "customerNo": "string",
        // "phone": "string"
      }
      if(this.statePhone==1){
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (!reg.test(this.formPhone.phone)) {
          this.$message.warning('请输入正确的手机号!')
          return
        }
        query.phone=this.formPhone.phone
      }else{
        query.customerNo=this.formPhone.customerNo
      }
      this.$api.postUpdateOtherPhone(this.res.id,query).then(res=>{
        if(res.code==0){
          this.dialogVisiblePhone=false;
          this.customerQueryCustomerByFollowUpId()
          this.$message.success(res.message)
        }
      })
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    postCallout(phoneType,customerNumber){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerId:this.res.id,
        customerNumber:customerNumber,
        phoneType:phoneType,
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    copyBuild() {//复制新建
      if(this.ruleForm.carNo && this.followUpInfoId ){
        this.postCustomerCopyCustomer(0,this.ruleForm.carNo)
      }else{
        this.$message.warning('关键信息缺失')
      }
      // window.open(window.location.href, "_blank");
    },
    refreshSelected() {
      this.bus.$emit('refreshPage')
      // this.$store.dispatch('tagsView/delCachedView', view).then(() => {
      //   const { fullPath } = view
      //   this.$nextTick(() => {
      //     this.$router.replace({
      //       path: '/redirect' + fullPath
      //     })
      //   })
      // })
    },
    getChannel() {
      this.channelList.forEach(item => {
        if(item.channelSubs&&item.channelSubs.length){
          item.channelSubs.forEach(ite => {
            if(ite.id === this.channelId){
              this.ruleForm.channel = [item.id, ite.id]
            }
          })
        }
      })
    },
    returnVisit(){//外勤回访
      this.$refs['form'].validate((valid) => {
        if(valid){
          let query={
            "customerId": this.res.id,
            "checkBookId": this.res.checkBookId,
            "result": this.form.result,
            "punctual": this.form.punctual,//检测师是否准时到达 （0-否 1-是）
            "workClothes": this.form.workClothes,
            "video": this.form.video,
            "footPad": this.form.footPad,
            "failReason": this.form.failReason,
            "remark": this.form.remark
          }
          this.$api.postCheckBookSurvey(this.$$.delete_null_properties(query)).then(res=>{
            if(res.code==0){
              this.dialogVisible=false;
              this.customerQueryCustomerByFollowUpId()//详情
              this.$message.success(res.message)
            }
          })
        }
      })
    },
    carBrand(state){//state=1同车不同牌，state=2同牌不同车
      let _this=this;
      if(state==1){
        this.dialogVisibleCar=true;
        this.formCar='';
        this.carState=2;//修改车牌
      }else{
        this.$confirm('您确定要创建同牌不同车的客户信息吗?', '同牌不同车', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postCustomerCopyCustomer(2)
        }).catch(() => {});
      }
    },
    postCustomerCopyCustomer(copyType,carNo){//复制客户 复制新建/同牌不同车/同车不同牌
      let query={
        "copyType": copyType,//复制类型 0-复制新建 1-同车不同牌 2-同牌不同车
        "followUpInfoId": this.followUpInfoId,
        "carNo": carNo
      }
      this.$api.postCustomerCopyCustomer(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.customerQueryCustomerByFollowUpId();
          this.$message.success(res.message)
          setTimeout(()=>{
            this.$router.push({
              path:'/testService/customer',
              query:{
                followUpInfoId:res.data
              }
            })
          },500)
        }
      })
    },
    getCheckResourceGetFieldPlan(){//根据客户Id获取未取消的五方格勤虚拟任务
      this.$api.getCheckResourceGetFieldPlan(this.res.id).then(res=>{
        if(res.code==0){
          if(res.data){
            this.$router.push({path: '/testService/fieldDR', query: {checkBookId:this.ruleForm.checkBookId,customerId:this.ruleForm.id}})//外勤检测预约
          }else{
            this.$message.warning('需要先申请外勤资源再预约!')
          }
        }
      })
    },
    getFixedPhone(){//获取拨打保密呼叫的固定手机列表
      this.$api.getFixedPhone().then(res=>{
        if(res.code==0){
          this.fixedPhonelist=res.data;
        }
      })
    },
    getCheckReport(){//获取检测报告
      this.$api.getCheckReport(this.res.checkReportId).then(res=>{
        if(res.code==0){
          this.resReport=res.data;
        }
      })
    },
    getCustomerRecordList(){//根据业务跟进ID查询客户概况
      this.$api.getCustomerRecordList(this.followUpInfoId,1).then(res=>{
        if(res.code==0){
          this.recordList=res.data;
        }
      })
    },
  },

}
</script>

<style scoped lang="scss">
.customer{
  background: #f5f5f5;
  min-height: 100vh;
  padding-top: 20px;
  // padding-bottom: 100px;
  .leftInfo, .rightInfo{background: #fff;height: 100%;}
  .rightInfo{padding: 30px 40px;}
  .customerForm{
    .formItem{
      padding: 30px 40px 10px;
      .ckxTitle{margin-bottom: 30px;}
      &>.flex:last-child{border-width: 0;}
      .tag-read{margin-left: 20px;}
      .carNo{width: 130px;margin-right: 20px;}
      .ml20{margin-left: 20px;}
      .line{border-bottom: 1px dashed #CCCCCC;}
    }
  }
  .serviceRecord{margin: 40px 0 30px;}
  .leftInfo{position: relative;}
  .aStatus{
    position: absolute;
    width: 144px;
    height: 144px;
    top: 0;
    right: 0;
    .div1{
      border: 72px solid transparent;
      border-top: 72px solid #0099FF;
      border-right: 72px solid #0099FF;
      position: relative;
      .div2{
        border: 30px solid transparent;
        border-top: 30px solid #fff;
        border-right: 30px solid #fff;
        position: absolute;
        top: -72px;
        right: -72px;
      }
    }
    .div3{
      position: absolute;
      top: 31px;
      right: -11px;
      color: #fff;
      width: 120px;
      line-height: 40px;
      text-align: center;
      transform: rotate(45deg);
      font-size: 22px;
    }
  }
  .recordTypeWidth{width: 322px;}
  .modelPhoneCur{
    color: #409eff;
    cursor: pointer;
    margin-left: 30px;
  }
  ::v-deep .tipsColor .el-radio__label{
    color: #ff0000;
  }
  .assignBox{
    padding-left:0 !important;
  }
}
</style>
<style>
.customer .formContent .el-form-item__label{ text-align: left; width: 100px !important;}
.customer .formContent .el-form-item__content{ margin-left: 0px;}
</style>
