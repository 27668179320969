<template>
  <div class="certificateMaterBox">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="待申请" name="first"></el-tab-pane>
      <el-tab-pane label="已申请" name="second"></el-tab-pane>
    </el-tabs>
    <el-row :gutter="24" class="mt20">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>商户姓名</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>品牌车型</span>
        <el-input v-model="params.brandModelSeriesName" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10">
        <span>办证公司</span>
        <el-select v-model="params.certCompany" size="medium" clearable>
          <el-option label="商户自理" value="商户自理"></el-option>
          <el-option v-for="item in SupplierList" :key="item.id" :label="item.name" :value="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w3 mb10" v-if="['second'].includes(activeName)">
        <span>车牌归属</span>
        <el-select v-model="params.isHuPlate" size="medium" clearable>
          <el-option label="非沪" :value="2"></el-option>
          <el-option label="沪牌" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w5 mb10" >
        <span>办证截止日期</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="2" :lg="2">
        <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt10">
      <el-table-column prop="tradeShopName" label="成交门店" align="center" ></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center" key="1"></el-table-column>
      <el-table-column prop="makerName" label="品牌车型" align="center" >
        <template slot-scope="scope">{{ scope.row.car.makerName }}&nbsp;{{scope.row.car.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center" >
        <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="merchantDeadline" label="商户办证截止日期" align="center" ></el-table-column>
      <el-table-column prop="certCompany" label="办证公司" align="center" ></el-table-column>
      <el-table-column prop="customerName" label="客户姓名" align="center" ></el-table-column>
      <el-table-column prop="extensionerName" label="渠道拓展专员" align="center" >
        <template slot-scope="scope">{{scope.row.car.channel?scope.row.car.channel.extensionerName:''}}</template>
      </el-table-column>
      <el-table-column prop="shortName" label="4S店简称" align="center" >
        <template slot-scope="scope">{{scope.row.car.channel?scope.row.car.channel.shortName:''}}</template>
      </el-table-column>
      <el-table-column prop="certApplyTime" label="提交申请时间" align="center"  v-if="['second'].includes(activeName)"></el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="['first'].includes(activeName)">申请办证</el-button>
          <el-button type="primary" @click="onapply(scope.row)" size="mini" v-if="['second'].includes(activeName)">修改申请</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      querylist: [], 
      total: 0,
      currentPage: 1,
      pageSize: 20,
      params:{
        isHuPlate:2
      },
      SupplierList:[],
    };
  },
  created() {
    this.init();
    this.postSupplierList()//获取供应商
  },
  methods: {
    init(page) {
      let taskStatus = 21; //待申请
      if (this.activeName == "first") {
        taskStatus = 21; //待申请
      } else {
        taskStatus = 22; //已申请
      }
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage:this.currentPage, //当前页数
        pageSize: this.pageSize,
        taskType: 1,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        merchantName:this.params.merchantName,
        taskApplyStatuses:[taskStatus],
        carNo:this.params.carNo,
        merchantDeadlineBegin:this.params.date?`${this.params.date[0]}`:'',
        merchantDeadlineEnd:this.params.date?`${this.params.date[1]}`:'',
        brandModelSeriesName:this.params.brandModelSeriesName,
        isHuPlate:this.params.isHuPlate,
        certCompany:this.params.certCompany,
      };
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onapply(row) {
      let aurl = "/accreditationCommissioner/applyForCertificate";
      this.$router.push({
        path: aurl,
        query: {
          id: row.id
        },
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
    postSupplierList(){//获取供应商
      let query={
        isEnabled:0
      }
      this.$api.postSupplierList(query).then(res=>{
        if(res.code==0){
          this.SupplierList=res.data;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.certificateMaterBox {
  padding:20px 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>
