<template>
  <div class="workbench">
    <div class="title_top_nameBox mb30" v-if="title">{{title}}</div>
    <div
      :class="[{'flex':true,'flex_around':true,'top':true,},showTips?'showTipsCon':'closeTipsCon']"
    >
      <div class="businessCon">
        <ul class="business flex flex_wrap" v-for="item in infolist" :key="item.id">
          <li class="bLi" v-for="v in item.list" :key="v.id" @click="tagRouter(v)">
            <div class="bTitle">{{item.name}}</div>
            <div
              :class="['bMain', 'flex', 'flex_column', 'flex_around', 'flex_align_center', {'star1':v.star===1,'star2':v.star===2}]"
            >
              <span class="num" v-if="v.num!==''&&v.num!==null">{{v.num}}</span>
              <span>{{v.name}}</span>
              <span class="minText" v-if="v.minTitle">{{v.minTitle}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="tips" v-if="showTipModel">
        <div v-if="!showTips" class="closeTips noSelect flex flex_column" @click="showTip">
          <div class="txt"><span>预警提示</span><span class="roundBox">{{newsNum}}</span></div>
          <div class="line"></div>
        </div>
        <div v-else class="tipsTable">
          <div class="tableHeader flex flex_between flex_align_center">
            <span>预警提示</span>
            <i class="el-icon-close pointer closeIcon" @click="showTips=false"></i>
          </div>
          <tipsTableData v-if="tipsFlag" :tipsTableData="tipsTableData" :height="500" @messageQueryMessage="messageQueryMessage" :noticeExpand="noticeExpand" :homePageFlag="true"></tipsTableData>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tipsTableData from "@/components/common/tipsTableData";
export default {
  props: {
    title:{/**  **/
      type:String,
      default:'业务任务'
    },
    tipsFlag:{/** 是否展示温馨提示 **/
      type:Boolean,
      default:true
    },
    noticeExpand:{/** 是否展开温馨提示 **/
      type:Boolean,
      default:false
    },
    showTipModel:{/** 是否有温馨提示 **/
      type:Boolean,
      default:true
    },
    // tipsTableData: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // },
    infolist: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      showTips: false,
      tipsTableData:[],
      newsNum:'-',
      // infolist:[{//参数格式
      //     name:'',
      //     list:[{
      //           num: res.data[i].cnt,
      //           name: res.data[i].title,
      //           star: 0,
      //           url:aurl
      //           }]
      // }]
    };
  },
  created(){
    this.showTips=this.noticeExpand;
    // if(this.tipsFlag){
    //   this.messageQueryMessage()
    // }
    this.messageQueryMessage()
    // this.messageQueryMessageCount()//新消息数量查询
  },
  methods: {
    messageQueryMessageCount(){//新消息数量查询
      this.$api.messageQueryMessageCount({state:0}).then(res=>{
        if(res.code == 0){
          this.newsNum = res.data
        }
      })
    },
    showTip() {
      this.showTips = true;
    },
    tagRouter(row){
      if(!row.url){
        this.$message({
          type:'warning',
          message:'当前无地址链接!'
        })
        return
      }
      let query={}
      if(row.params){
        query.name=row.urlName;
        query.params=row.params;
      }else{
        query.path=row.url;
      }
      this.$router.push(query)
    },
    messageQueryMessage() {
      let query={
        curPage:1,
        pageSize:999,
        // types:[0,3,4,5]
      }
      this.$api.postMessageWarningPage(query).then(res=>{
        if(res.code === 0){
          let num=0;
          this.tipsTableData = res.data.records;
          for(let i=0;i<res.data.records.length;i++){
            let item=res.data.records[i]
            if(item.isRead===0){
              num++
            }
          }
          this.newsNum=num;
        }else{
          this.tipsTableData = [];
        }
      })
    }
  },
  components: {
    tipsTableData
  }
};
</script>
<style lang="scss" scoped>
.workbench {
  .businessCon {
    flex: 1;
  }
  .business {
    background: #fff;
    padding: 35px 10px 0;
    &:nth-child(2) .bLi .bTitle {
      background: linear-gradient(315deg, #ffc600 0%, #fe9400 100%);
    }
    &:nth-child(3) .bLi .bTitle {
      background: linear-gradient(315deg, #35e0a4 0%, #18be6b 100%);
    }
    &:nth-child(4) .bLi .bTitle {
      background: linear-gradient(315deg, #EDA3A3 0%, #FD5B4A 100%);
    }
    &:nth-child(2) .bLi .bMain {
      color: #fe9600;
      box-shadow: 0px 0px 10px 3px rgba(254, 150, 0, 0.2);
    }
    &:nth-child(3) .bLi .bMain {
      color: #19bf6d;
      box-shadow: 0px 0px 10px 3px rgba(28, 195, 115, 0.2);
    }
    &:nth-child(4) .bLi .bMain {
      color: #FD5B4A;
      box-shadow: 0px 0px 10px 3px rgba(238, 158, 157, 0.2);
    }
    .bLi {
      // width: 16.7%;
      width:200px;
      cursor: pointer;
      margin: 0 1.6% 35px;
      .bTitle {
        padding-left: 10px;
        background: linear-gradient(315deg, #74cdff 0%, #409eff 100%);
        color: #fff;
        font-size: 20px;
        height: 50px;
        line-height: 50px;
      }
      .bMain {
        color: #0099ff;
        height: 130px;
        font-size: 24px;
        box-shadow: 0px 0px 10px 3px rgba(0, 196, 255, 0.2);
        padding:10px;
        .num {
          font-size: 40px;
        }
        .minText{
          font-size:16px
        }
      }
      .star1 {
        background: url(../../assets/images/common/starBg.png) no-repeat;
        background-size: 74px 72px;
        background-position: center;
      }
      .star2 {
        background: url(../../assets/images/common/starBg2.png) no-repeat;
        background-size: 168px 72px;
        background-position: center;
      }
    }
  }
  .tips {
    margin-right: -40px;
    margin-top: 30px;
    .closeTips {
      background: #43a0ff;
      color: #fff;
      margin-left: 70px;
      position: relative;
      height: 100%;
      width: 10px;
      cursor: pointer;
      .txt {
        font-size: 24px;
        width: 40px;
        height: 180px;
        padding-top: 9px;
        text-align: center;
        line-height: 32px;
        position: absolute;
        right: 0;
        top: 0;
        background: #43a0ff;
      }
    }
    .tipsTable {
      width: 600px;
      margin-left: 18px;
      .tableHeader {
        height: 45px;
        background: #0099ff;
        color: #fff;
        padding: 0 10px;
        .closeIcon {
          padding: 10px;
          margin-right: -10px;
          font-size: 20px;
        }
      }
    }
  }
  .roundBox{
    background:#ff0000;
    color:#ffffff;
    border-radius: 50%;
    width: 30px;
    font-size: 18px;
    display: inline-block;
  }
}
</style>