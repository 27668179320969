<template>
  <div class="currencyConterBox">
    <el-row :gutter="24">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="forms.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>状态</span>
            <el-select v-model="forms.state" size="medium" clearable multiple>
              <el-option
                v-for="item in paymentStatuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" class="flex flex_align_center flex_between w3">
            <span>申请时间</span>
            <el-date-picker
              v-model="forms.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              size="medium"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
            <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
          </el-col>
          <el-col :span="2" class="flex flex_align_center flex_between w3 ml30">
            <el-button type="primary" @click="onBatchPayment" size="medium" >批量处理坏账</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="querylist"
          border
          stripe
          class="table-mainbg mt20"
          ref="table"
          @selection-change="selectChange"
        >
          <el-table-column
            type="selection"
            label="选择"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="carNo"
            label="车牌号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="applyTime"
            label="申请时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="intentionMoney"
            label="待收金额（元）"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="financeBadDebtStatusName"
            label="状态" 
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="onSee(scope.row,1)" size="mini" >查看详情</el-button>
              <el-button type="primary" @click="onSee(scope.row,2)" size="mini" v-if="[101,103,105].includes(scope.row.financeBadDebtStatus)">处理</el-button>
            </template>  
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        >
        </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [], //
      forms: {
        merchantName: "",
        state: [],
        date: "",
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      paymentStatuslist: [{label:'返还意向金坏账待处理',value:103},{label:'客户违约赔偿坏账待处理',value:105},{label:'返还意向金坏账已处理',value:104},{label:'客户违约赔偿坏账已处理',value:106},{label:'赔偿客户违约金待处理',value:101},{label:'赔偿客户违约金已处理',value:102}],
      selectlist: [], //选中的数据
    };
  },
  created() {
    if(this.$route.params.state){
        this.forms.state=[103,105,101];
      }
    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        applyBeginTime:this.forms.date?this.forms.date[0]:'' ,
        applyEndTime:this.forms.date?this.forms.date[1]:'',
        financeBadDebtStatuses:this.forms.state.length>0?this.forms.state:[101,102,103,104,105,106] //财务坏账处理状态，101赔偿客户违约金待处理，102赔偿客户违约金已处理，103返还意向金坏账待处理，104返还意向金坏账已处理，105客户违约赔偿坏账待处理，106客户违约赔偿坏账已处理
      };
      this.$api.postIntentionMoneyPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist = res.data.records;
          this.total = res.data.total;
        }else{
          this.querylist = [];
          this.total = 0;
        }
      })
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
    onSee(row, state) {
      if(state==1){//查看详情
        this.$router.push({
          path: "/finance/intentionBadDebtDetails",
          query: {
            id:row.id
          }
        });
      }else{
         this.$confirm(`确认车牌号${row.carNo}返还意向金已收到`, '收款确认', {
          confirmButtonText: '收到',
          cancelButtonText: '没有收到',
          distinguishCancelAndClose: true,
          type: 'warning'
        }).then(() => {
          this.postFinanceConfirmBadDebt(row.id)
        }).catch((action) => {
          // if(action==='cancel'){
          //   this.postFinanceConfirmGathering(2,row.id)
          // }
        });
      }
    },
    postFinanceConfirmBadDebt(payIds){//财务坏账处理：财务确认赔偿客户违约金,财务确认客户返还意向金坏账,财务确认客户违约赔偿坏账
      let query={
        payIds:payIds
      }
      this.$api.postFinanceConfirmBadDebt(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init(1)
        }
      })
    },
    onBatchPayment() {
      //批量处理坏账
      if(this.selectlist.length==0){
        this.$message('请选择需要处理的坏账！')
        return
      }
      let arr=this.selectlist.map(item => item.id)
      this.$confirm(`是否批量处理坏账`, '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          distinguishCancelAndClose: true,
          type: 'warning'
        }).then(() => {
          this.postFinanceConfirmBadDebt(String(arr))
        }).catch(() => {
        });
      
    },
    selectChange(rows) {
      this.selectlist = rows;
    },
    postFinanceConfirmGathering(operaType,id){//1财务确认收款成功,2财务确认收款失败
      this.$api.postFinanceConfirmGathering(operaType,id).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init(1)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.currencyConterBox {
  padding: 40px;
}
</style>