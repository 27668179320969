<template>
  <div class="updatePass">
    <el-form :model="codeform" :rules="codeformRule" ref="codeform" label-width="100px" class="codeform" v-show="step===0" :hide-required-asterisk="true">
      <el-form-item label="手机号码" prop="tel">
        <el-input v-model="codeform.tel" :disabled="disable"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <el-input v-model="codeform.code" @keyup.enter.native="nextStep">
          <template slot="append">
            <el-button class="bluetxt" @click="getCode" :disabled="codeText!='获取验证码'">{{codeText}}</el-button>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <el-form :model="psdform" :rules="psdformRule" ref="psdform" label-width="110px" class="psdform" v-show="step===1">
      <el-form-item label="新密码" prop="psd1">
        <el-input v-model="psdform.psd1" @copy.native.capture.prevent="nothing" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="psd2">
        <el-input v-model="psdform.psd2" @keyup.enter.native="doSubmit" show-password></el-input>
      </el-form-item>
    </el-form>
    <div v-show="step===3" class="conterBox">密码修改成功！</div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" v-show="step===0" @click="nextStep">下一步</el-button>
        <el-button type="primary" v-show="step===1" @click="doSubmit">保存</el-button>
        <el-button type="primary" v-show="step===3" @click="onreturn">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  props:{
    disable:{
      type:Boolean,
      default() {
        return true
      }
    }
  },
  data(){
    const checkCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('验证码不能为空'))
      } else {
        // self.$api.checkCode({
        //   phone:self.carRuleForm.mobile,
        //   from:'官网',
        //   auth_code:self.carRuleForm.verificationCode
        // }).then(res=>{
        //   if(res.data.code=='200'){
            callback()
          // }else{
          //   return callback(new Error(res.data.msg))
          // }
        // })
      }
    }
    const newPass = (rule, value, callback) => {
      if (value) {
        //6-20个字符,不包含空格,必须包含数字,字母或字符至少两种
        const reg = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,20}$/;
        if (!reg.test(value)) {
          callback(new Error('密码为8-20个字符,不包含空格,必须包含数字,字母或字符至少两种'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入密码'))
      }
    }
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.psdform.psd1 !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    return {
      codeform: {
        tel: '',
        code: '',
      },
      codeText: '获取验证码',
      codeformRule: {
        code: [{ validator: checkCode, trigger: 'blur' }],
        tel: [{ required: true, trigger: 'blur', message: '请输入手机号码'},{pattern:/^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: '请输入正确的手机号码'}]
      },
      psdform:{
        psd1: '',
        psd2: ''
      },
      psdformRule:{
        psd1: [
          { required: true, validator: newPass, trigger: 'blur' }
        ],
        psd2: [
          { required: true, validator: confirmPass, trigger: 'blur' }
        ]
      },
      timer: null,
      step: 0,
      userId:'',
      timershake:null,
    }
  },
  created(){
    this.userId=this.$store.state.user.userId;
    if(this.userId){
      this.getUserData()
    }
  },
  methods: {
    getCode() {
      this.$refs.codeform.validateField('tel',(valid) =>{
        if(!valid){
          if (!this.codeform.tel) {
            return false
          } else {
            this.$api.postSendSmsCode({
              content:'您的验证码为：',
              mobile: this.codeform.tel,
            }).then((res)=>{
              if(res.code==0){
                this.$message.success('发送成功')
                this.codeText = '60s'
                this.setCodeTimer()
              }
            })
          }
        }
      })
    },
    setCodeTimer(){
      let self = this;
      clearInterval(self.timer);
      let time = 60;
      this.timer = setInterval(() => {
        time --;
        self.codeText = time+'s';
        if(time<=0){
          clearInterval(self.timer)
          this.codeText = '获取验证码'
        }
      }, 1000);
    },
    nextStep() {
      this.$refs['codeform'].validate(valid => {
        if(valid){
          let query={
            mobile:this.codeform.tel,
            smsCode:this.codeform.code,
          }
          this.$api.postValidSmsCode(query).then(res=>{
            if(res.code==0&&res.data===true){
              setTimeout(()=>{
                this.step=1;
              },1000)
            }else{
              this.$message.error('验证码不正确！')
            }
          })
        }else{
          return false
        }
      })
    },
    nothing(){},
    doSubmit() {
      let that=this;
      this.$refs['psdform'].validate(valid => {
        if(valid){
          if(this.timershake){
            clearTimeout(this.timershake)
            this.timershake=null;
          }else{
            this.timershake=setTimeout(()=>{
               that.resetPwd()
            },500)
          }
        }else{
          return false
        }
      })
    },
    getUserData(){//获取用户信息
      this.$api.getUserData(this.userId).then((res)=>{
        if(res.code==0){
          this.codeform.tel=res.data.mobile
        }
      })
    },
    resetPwd(){
      // let password = Cookies.get('ckxPassword')
      let smsCode=this.codeform.code;
      let newPwd=this.psdform.psd1;
      // let oldPwd=password;
      
      // if(this.step==0){
      //   // oldPwd='';
      //   newPwd='';
      // }
      let query={
        newPwd:newPwd,
        noOrMobile:this.codeform.tel,
        // oldPwd:oldPwd,
        smsCode:smsCode,
      }
      this.$api.resetPwd(query).then((res)=>{
        if(res.code==0&&res.data==true){
          if(this.step==1){
            Cookies.set('ckxPassword',newPwd)
            this.$message({
              type:'success',
              message:'修改成功'
            })
            setTimeout(()=>{
              this.step=3;
            },1500)
          }
        }
      })
    },
    onreturn(){
      this.$router.go(-1) 
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>

<style scoped lang="scss">
.updatePass{
  .tel{padding-left: 60px;margin: 40px 0 30px;}
  .codeform{
    width: 500px;
    margin-top: 25px;
  }
  .psdform{
    width: 500px;
    margin-top: 25px;
  }
  .footer{
    background: #f5f5f5;
    padding-top: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    .btn{
      background: white;
      padding: 20px 0;
      text-align: center;
    }
  }
}
</style>
<style scoped lang="scss">
.updatePass .codeform .el-input-group__append .el-button {
  padding: 0 10px;
}
.conterBox{
  font-size:18px;
  text-align: center;
  padding:30px;
}
</style>