<template>
  <el-badge :value="newsNum" :hidden="newsNum<=0">
    <span class="menuLink setFontSize14" @click="$$.goNewPage(valPpath)">{{metaTitle}}</span>
  </el-badge>
</template>

<script>
export default {
  props: {
    metaTitle: {
      type: String
    },
    valPpath: {
      type: String
    }
  },
  data() {
    return {
      newsNum: 0
    }
  },
  created() {
    // this.messageQueryMessageCount();
  },
  methods: {
    messageQueryMessageCount() {
      this.$api.messageQueryMessageCount({state:0}).then(res=>{
        if(res.code == 0){
          this.newsNum = res.data
        }
      })
    },
  },
  
}
</script>

<style scoped lang="scss">
::v-deep .el-badge__content{line-height: 15px;}
.menuLink{display: block;}
::v-deep .el-badge__content.is-fixed{top: 15px;right: 30px;}
</style>