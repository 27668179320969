<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="">
      <el-col :xl="8" :lg="8" class="flex flex_align_center flex_between w3 mb10">
        <span>渠道名称</span>
        <el-cascader clearable
          v-model="forms.channel" filterable ref="channelRef"
          :options="options" :props="props" clearable></el-cascader>
      </el-col>
      <el-col :xl="2" :lg="2" class="flex flex_align_center flex_between w3 ml30 mb10">
        <el-button type="primary" @click="selectTable" size="medium">查询</el-button>
        <el-button type="primary" @click="onAdd" size="medium" plain>添加渠道</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe 
     row-key="id"
    default-expand-all
    :tree-props="{ children: 'channelSubs'}"
    class="table-mainbg mt10">
      <el-table-column prop="id" label="渠道ID" align="center"></el-table-column>
      <el-table-column prop="channelName" label="渠道名称" align="center"></el-table-column>
      <el-table-column prop="channelFullName" label="渠道全称" align="center"></el-table-column>
      <el-table-column prop="isFine" label="是否优质" align="center">
        <template slot-scope="scope">{{scope.row.isFine===0?'否':scope.row.isFine===1?'是':scope.row.isFine}}</template>
      </el-table-column>
      <el-table-column prop="updateDate" label="更新时间" align="center"></el-table-column>
      <el-table-column prop="updateBy" label="更新人" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button
            type="primary" plain
            @click="onrouter(scope.row)"
            size="mini" v-if="scope.row.channelFullName.indexOf('大客户')"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination> -->
    <el-dialog
      :title="stateModel==1?'添加渠道':'修改渠道'"
      :visible.sync="dialogVisible"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="130px">
        <el-form-item label="渠道名称:" prop="channelName">
          <el-input v-model="form.channelName" placeholder="请输入渠道名称"></el-input>
        </el-form-item>
        <el-form-item label="主渠道:" prop="isTop">
          <el-radio-group v-model="form.isTop" :disabled="disabled">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.isTop===1">
        <el-form-item label="选择主渠道:" prop="parentObj.id">
          <el-select v-model="form.parentObj" placeholder="请选择主渠道" @change="onChange(form.parentObj)" value-key="id" filterable>
            <el-option v-for="item in options" :key="item.id" :label="item.channelName" :value="item" :disabled="['大客户'].includes(item.channelName)?true:false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键词:">
          <el-input v-model="form.keyword" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item label="是否优质渠道:" v-if="!['门店','代理'].includes(form.parentName)" prop="isFine">
          <el-radio-group v-model="form.isFine">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        </template>
        <el-form-item label="备注:" >
          <el-input v-model="form.remark" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit('form')">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      forms:{
        channel:[],
      },
      stateModel:1,//1新增2修改
      form:{
        channel:[],
        isTop:'',
        channelName:'',
        parentObj:{
          id:''
        },
        remark:'',
        keyword:'',
        parentName:'',
      },
      options:[],
      props:{
        label:'channelName',
        children:'channelSubs',
        value:'id',
        checkStrictly: true 
      },
      dialogVisible:false,
      rules: {
          channelName: [{ required: true, message: '不能为空'}],
          isTop: [{ required: true, message: '请选择'}],
          isFine: [{ required: true, message: '请选择'}],
          "parentObj.id": [{ required: true, message: '请选择'}],
      },
      disabled:false,
    };
  },
  created() {
    this.init()
  },
  methods: {
    onChange(row){
      this.form.parentId=row.id;
      this.form.parentName=row.channelName;
    },
    onAdd(){
      this.stateModel=1;
      this.dialogVisible=true;
      this.form={
        channel:[],
        isTop:'',
        channelName:'',
        parentObj:{
          id:''
        },
        remark:'',
        keyword:'',
        parentId:'',
        parentName:'',
      }
      this.disabled=false;
      if(this.$refs.form){
        this.$refs['form'].resetFields(); 
        this.$set(this.form,'parentObj',{})
        this.$forceUpdate()
      }
    },
    onSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.stateModel===1){
            this.postCustomerChannelAdd()
          }else{
            this.postCustomerChannelUpdate()
          }
        }
      });
    },
    postCustomerChannelAdd(){//新增客户渠道
      let query={
        // "channelFullName": "string",
        "channelName": this.form.channelName,
        // "id": 1,
        "isFine": this.form.isFine,
        "keyword": this.form.keyword,
        "parentId": this.form.parentId,
        "remark": this.form.remark,
        // "sort": 0
      }
      if(this.form.isTop===0){
        query.parentId=0;
        query.channelFullName=this.form.channelName
      }else{
        query.channelFullName=`${this.form.parentName}-${this.form.channelName}`
      }
      this.$api.postCustomerChannelAdd(query).then(res=>{
        if(res.code==0){
          this.dialogVisible=!this.dialogVisible;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    postCustomerChannelUpdate(){
      let query={
        // "channelFullName": "string",
        "channelName": this.form.channelName,
        "id": this.form.id,
        "isFine": this.form.isFine,
        "keyword": this.form.keyword,
        "parentId": this.form.parentId,
        "remark": this.form.remark,
        // "sort": 0
      }
      if(this.form.isTop===0){
        query.parentId=0;
        query.channelFullName=this.form.channelName
      }else{
        query.channelFullName=`${this.form.parentName}-${this.form.channelName}`
      }
      this.$api.postCustomerChannelUpdate(this.form.id,query).then(res=>{
        if(res.code==0){
          this.dialogVisible=!this.dialogVisible;
          this.init()
          this.$message.success(res.message)
        }
      })
    },
    selectTable(){
      let nodeName=this.$refs.channelRef.getCheckedNodes()
      
      let e='';
      if(nodeName.length>0){
        let names=nodeName[0].path
        if(names.length>1){
          e=names[1]
          this.init(e,1)//是否父节点，0是，1否
        }else{
          e=names[0]
          this.init(e,0)//是否父节点，0是，1否
        }
      }else{
        this.init(e)//是否父节点，0是，1否
      }
    },
    onrouter(row){
      this.stateModel=2;
      this.dialogVisible=true;
      this.form={
        id:row.id,
        // channel:[],
        isTop:row.isTop,
        channelName:row.channelName,
        parentObj:{
          id:''
        },
        remark:row.remark,
        keyword:row.keyword,
        parentId:row.parentId,
        isFine:row.isFine,
      }
      this.disabled=true;
      if(row.channelFullName){
        let ar=row.channelFullName.split('-')
        this.form.parentName=ar[0]
      }
      if(row.parentId){
        this.form.isTop=1;
        this.form.parentObj={
          id:row.parentId
        };
      }else{
        this.form.isTop=0;
      }
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    onCall(row){
      this.$confirm('请确认是否呼叫！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postCallout(row.mainPhone)
      }).catch(() => {     
      });
    },
    init(ccId,isParent){//客户渠道查询
      let query={
        ccId:ccId,
        isParent:isParent//是否父节点，0是，1否
      }
      this.$api.customerQueryChannel(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          if(!ccId){
            this.options=JSON.parse(JSON.stringify(res.data));
          }
          this.querylist=res.data;
        }
      })
    },
  },
  
};
</script>

<style lang="scss" scoped>
</style>
