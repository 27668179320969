<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="pageRowBox">
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户姓名</span>
        <el-input v-model="forms.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>注册手机号</span>
        <el-input v-model="forms.regPhone" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>注册城市</span>
        <el-cascader clearable
        v-model="forms.regCity" placeholder="请选择注册城市" size="medium"
        :options="cityList" :props="props"></el-cascader>
      </el-col>
      <el-col :span="5"  class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>商户类别</span>
        <el-select v-model="forms.merchantType" placeholder="请选择" size="medium" clearable class="cerSelect">
          <el-option label="请选择" value=""></el-option>
          <el-option label="个人" :value="0"></el-option>
          <el-option label="企业" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between mb10 w4 rowW">
        <span>拓展业务员</span>
        <el-select v-model="forms.extenderId" placeholder="请选择" size="medium" clearable class="cerSelect">
          <el-option v-for="item in employlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2"  class="flex flex_align_center flex_between mb10 ">
        <el-button type="primary" @click="init(1)" size="medium" plain>搜索</el-button>
      </el-col>
    </el-row>
      
    <el-table :data="querylist" border stripe class="mt10 table-mainbg">
      <el-table-column prop="id" label="商户ID" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商户姓名" align="center">
        <template slot-scope="scope">{{scope.row.merchantType==1?scope.row.authCompanyName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="merchantNo" label="商户账户" align="center"></el-table-column>
      <el-table-column prop="merchantMobile" label="注册手机号" align="center"></el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="拓展业务员" align="center"></el-table-column>
      <el-table-column prop="merchantType" label="商户类别" align="center">
        <template slot-scope="scope">{{scope.row.merchantType?'企业':'个人'}}</template>
      </el-table-column>
      <el-table-column prop="authDate" label="认证通过时间" align="center"></el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="onsee(scope.row)"
            size="mini"
          >查看</el-button>
          <el-button
            type="primary"
            @click="onSetup(scope.row)"
            size="mini"
          >设置保证金</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="10"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
     <el-dialog
      width="500px"
      :show-close="false"
      class="customDialog"
      title="设置保证金"
      :visible.sync="dialogVisible"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <el-form-item label="注册城市：">
          <p>{{form.registerCityName}}</p>
        </el-form-item>
        <el-form-item label="经营地址：" required class="w90">
          <div class="justifySpace">
            <el-col :span="24">
            <el-form-item prop="businessProvinceObj">
              <el-cascader ref="cascaderRef"
                v-model="form.businessProvinceObj" placeholder="请选择经营城市" size="medium" @change="changeCascader"
                :options="cityList" :props="props"></el-cascader>
            </el-form-item>
          </el-col>
          </div>
          <el-col :span="24">
            <el-form-item prop="businessAddress">
              <el-input v-model="form.businessAddress" size="medium"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="商户经理：" prop="maintainerName">
          <el-select v-model="form.maintainerName" placeholder="请选择商户经理" class="wSelect" clearable>
            <el-option v-for="item in employlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="保证金额：" prop="margin" class=" w90">
          <el-input v-model="form.margin"  size="medium" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="onconfirm('form')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms:{},
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:10,
      dialogVisible:false,
      form: {},
      rules: {
        businessProvinceObj: [{ required: true, message: "不能为空" }],
        businessAddress: [{ required: true, message: "不能为空" }],
        maintainerName: [{ required: true, message: "商户经理不能为空" }],
        margin: [{ required: true, message: "保证金额不能为空" }]
      },
      props:{
        label:'name',
        value:'id',
        children:'children'
      },
      cityList:[],//省市
      employlist:[],//维护人员列表
      itemrow:{},
      selectType:false,//判断是否切换值
    };
  },
  created() {
    this.init();
    //经营省份城市区域3级联动查询(无参数查所有)省市
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.cityList=res;
      })
    }else{
      this.cityList=this.$store.state.setting.cityList;
    }
    this.queryUserListByRoleAndDepart()//维护业务员列表查询
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
        merchantName:this.forms.merchantName,//商户姓名
        merchantMobile:this.forms.regPhone,//注册电话
        registerCityId:this.forms.regCity?this.forms.regCity[1]:'',//注册城市编号
        merchantType:this.forms.merchantType,//商户类型
        extenderId:this.forms.extenderId,
      }
      this.$api.postAuthAssList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist=res.data.records;
            this.total=res.data.total;
          }else{
              this.querylist=[];
              this.total=0;
          }
        }else{
          this.querylist=[];
          this.total=0;
        }
      })
    },
    changeCascader(){
      let node=this.$refs.cascaderRef.getCheckedNodes()[0]
      this.form.businessProvinceId=node.path[0];//经营省份ID
      this.form.businessProvinceName=node.pathLabels[0];//经营省份名称
      this.form.businessCityId=node.path[1];//经营城市ID
      this.form.businessCityName=node.pathLabels[1];
      // console.log(node,'---ttt---',this.form.businessProvinceId,  this.form.businessCityId)
    },
    onsee(row) {
      this.$router.push({//接收状态值，0个人(可操作)，1企业(可操作),2个人查看，3企业查看
        path:'/merchant/authenticationDetails',
        query:{//merchantType商户类型 0-个人 1-企业
          // state:row.merchantType?3:2,
          id:row.id
        }
      })
    },
    ondialogVisible(){
      this.dialogVisible=!this.dialogVisible;
    },
    onSetup(row){
      this.form=JSON.parse(JSON.stringify(row));
      this.form.businessProvinceObj=[row.businessProvinceId,row.businessCityId];
      
      this.itemrow=row;
      this.selectType=false;//恢复下拉初始状态
      this.ondialogVisible();
      this.$nextTick(()=>{
        this.$refs['form'].clearValidate()
      })
    },
    onconfirm(formdata) {
      //确认modal
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.postSetMargin();
        } else {
          return false;
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize=data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      this.currentPage=data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//维护业务员
      let query={
        "roleName": "销售专员"
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
    postSetMargin(){//商户设置保证金
      let query={
        "merchantId": this.itemrow.id,
        "businessProvinceId": this.form.businessProvinceId,//经营省份ID
        "businessProvinceName":this.form.businessProvinceName,//经营省份名称
        "businessCityId": this.form.businessCityId,//经营城市ID
        "businessCityName": this.form.businessCityName,
        "businessAddress": this.form.businessAddress,
        "maintainerId": this.form.maintainerId,
        "maintainerName": this.form.maintainerName,
        "margin": this.form.margin//保证金 单位：（元）
      }
      this.$api.postSetMargin(query).then((res) => {
        if(res.code==0){
          this.ondialogVisible();
          this.init()
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
// #app .customDialog .el-form-item{margin-bottom: 10px;}
.pageContainer {
  .w90 {
    width: 90%;
  }
  .wSelect{
    width: 87%;
  }
}

.pageRowBox{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1600px) {
  .rowW{
    width:300px;
  }
}
@media (max-width: 1400px) {
  .rowW{
    width:350px;
  }
}
</style>
