<template>
  <div class="pageContainer">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>合作店简称</span>
        <el-input v-model="form.partnerShortName" size="medium"  @keyup.enter.native="init(1)"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>账户名</span>
        <el-input v-model="form.accountName" size="medium"  @keyup.enter.native="init(1)"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>账号</span>
        <el-input v-model="form.account" size="medium"  @keyup.enter.native="init(1)"></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w4">
        <span>申请时间</span>
         <el-date-picker v-model="form.date" type="daterange"  range-separator="-" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>提现状态</span>
        <el-select v-model="form.status" placeholder="请选择提现状态" size="medium" clearable>
          <el-option v-for="item in flowlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <el-button type="primary" @click="init(1)" size="medium" plain>查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt30 table-mainbg">
      <el-table-column prop="shortName" label="合作店简称" align="center"></el-table-column>
      <el-table-column prop="amount" label="提现金额" align="center"></el-table-column>
      <el-table-column prop="statusName" label="提现状态" align="center"></el-table-column>
      <el-table-column prop="accountName" label="账户名" align="center"></el-table-column>
      <el-table-column prop="account" label="账号" align="center"></el-table-column>
      <el-table-column prop="bankShortName" label="银行简称" align="center"></el-table-column>
      <el-table-column prop="bank" label="开户行" align="center"></el-table-column>
      <el-table-column prop="remark" label="提现备注" align="center"></el-table-column>
      <el-table-column prop="extensionerName" label="4S店维护人" align="center"></el-table-column>
      <el-table-column prop="applyTime" label="申请时间" align="center"></el-table-column>
      <el-table-column prop="auditTime" label="审核通过时间" align="center"></el-table-column>
      <el-table-column label="操作" width="100" align="center" v-if="['渠道管理'].includes(roleName.name)">
        <template slot-scope="scope" v-if="scope.row.status==0">
          <el-button
            type="primary"
            @click="onsee(scope.row)"
            size="mini"
            class="group-custom"
          >审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      form: {
        date:''
      },
      flowlist:[],
      roleName:{},
    };
  },
  created() {
    let user=this.$store.state.user.userData;
    if(user){
      this.roleName=this.$$.roleName(user)
    }
    
    this.init();
    this.getWithdrawStatus()//提现状态
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        shortName: this.form.partnerShortName, //合作店简称
        accountName: this.form.accountName, //账户名
        account: this.form.account, //账号
        beginApplyTime: this.form.date?`${this.form.date[0]} 00:00:00`:'', //申请时间
        endApplyTime: this.form.date?`${this.form.date[1]} 23:59:59`:'', //申请时间
        status: this.form.status //提现状态
      };
      this.$api.postChannelWithdrawPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist =[]
            this.total=0;
          }
        }else{
          this.querylist = []
          this.total=0;
        }
      });
    },
    onsee(row) {
      this.$router.push({
        path:'/channel/wallet',
        query:{
          id:row.id
        }
      })
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    getWithdrawStatus(){//提现状态
      this.$api.getWithdrawStatus().then((res)=>{
        if(res){
          if(res.code==0){
            this.flowlist=res.data;
          }
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .w90 {
    width: 90%;
  }
  .wSelect{
    width: 87%;
  }
}
</style>
