<template>
  <div class="pageContainer pb140">
    <div class="flex ">
      <el-radio-group v-model="isToday" @change="init">
        <el-radio-button :label="1">当日</el-radio-button>
        <el-radio-button :label="0">次日</el-radio-button>
      </el-radio-group>
      <div class="paibox">
        <span>说明：</span>
        <el-tag  type="success" effect="dark">空闲</el-tag>
        <el-tag effect="dark" class="mr10 ml10">已排班</el-tag>
        <!-- <el-tag type="warning" effect="dark">休假</el-tag> -->
      </div>
    </div>
    <table class="table-custom fieldTable mt20">
      <tr>
        <td width="200px">线路</td>
        <td v-for="(item,index) in headlist" :key="index">{{index}}</td>
      </tr>
      <tr v-for="(item,ii) in querylist" :key="ii">
        <td  width="200px">{{item.routeNo}}</td>
        <td v-for="(items,indexs) in item.schedule" :key="indexs">
          <div class="tagBox" :class="[items==1?'blueBG':'greenBG',indexs==activeIn&&ii==activeInd?'active':'']"  @click="onCellClick(item,items,ii,indexs)"></div>
        </td>
      </tr>
    </table>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" >确认排班</el-button>
        <el-button type="primary" @click="onReturn" >取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {},
      isToday:1,
      querylist: [],
      headlist:[],
      day:'',
      activeIn:'',
      activeInd:'',
      itemRows:{},
      itemRow:{},
      id:'',
    }
  },
  created() {
    if(this.$route.query.id) this.id=this.$route.query.id;
    this.init();
  },
  mounted(){
  },
  methods: {
    init(){
      this.$api.getCheckResourcesFieldRoute(this.isToday).then(res=>{
        if(res.code==0){
          this.day=res.data.day;
          this.querylist=res.data.routeList;
          this.headlist=res.data.routeList.length>0?res.data.routeList[0].schedule:[]
        }
      })
    },
    onSubmit(){
      let query={
        "planId": this.id,//外勤检测预约计划ID
        "status": 1,//状态 0-待排班 1-已排班 2-已取消
        "routeNo": this.itemRows.routeNo,
        "planTime": this.activeIn,
        isToday:this.isToday
        // "cancelReason": "string",
        // "remark": "string"
      }
      if(!this.activeFlag){
        this.$message.warning('请选择时间排班！')
        return
      }
      this.$api.postCheckResourceFieldPlanUpdate(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.activeIn='';
          this.activeInd='';
          this.itemRows={};
          this.itemRow={};
          this.activeFlag=false;
        }
      })
    },
    onCellClick(rows,row,i,ii){
      if(row===1){
        return
      }
      this.activeIn=ii;
      this.activeInd=i;
      this.itemRows=rows;
      this.itemRow=row;
      this.activeFlag=true;
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/testService/virtualPentagon"
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .iconText{
    font-size:50px;
    cursor: pointer;
    margin-left: 20px;
  }
  .paibox{
    margin-left:100px;
  }
  .tagBox{
    width:45px;
    height:25px;
    color:#ffffff;
    margin:2px;
    cursor: pointer;
  }
  .active{
    border: 2px solid red;
  }
}
</style>
<style>
#app .fieldTable td{padding:0;height:35px;}
</style>