import { render, staticRenderFns } from "./deliveryNote.vue?vue&type=template&id=fd2f6762&scoped=true&"
import script from "./deliveryNote.vue?vue&type=script&lang=js&"
export * from "./deliveryNote.vue?vue&type=script&lang=js&"
import style0 from "./deliveryNote.vue?vue&type=style&index=0&id=fd2f6762&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2f6762",
  null
  
)

export default component.exports