<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="待竞拍" name="first">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w2">
            <span>年份</span>
            <el-input v-model="params.productYear" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>品牌车型</span>
            <el-input v-model="params.modelName" size="medium"></el-input>
          </el-col>
          <el-col :span="2" style="text-align:right;">
            <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="mt20 table-mainbg" >
          <el-table-column prop="affRegion" label="所属区域" align="center" width="130"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="productYear" label="年份/车型" align="center">
            <template slot-scope="scope">{{scope.row.productYear}}/{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="carGradeResult" label="车辆评级" align="center" width="100"></el-table-column>
          <el-table-column prop="carAuditFinishTime" label="审核通过时间" align="center"></el-table-column>
          <el-table-column prop="auctionBeginTime" label="竞拍开始时间" align="center"></el-table-column>
          <el-table-column prop="auctionSeatBeginTime" label="场次开始时间" align="center"></el-table-column>
          <el-table-column prop="intervalTime" label="倒计时" align="center"></el-table-column>
          <el-table-column label="操作" width="240px" align="center">
            <template slot-scope="scope" v-if="!scope.row.intervalTimeState">
              <el-button type="primary" size="mini" @click="onRow(scope.row,2)">终止竞拍</el-button>
              <el-button type="primary" size="mini" @click="onRow(scope.row,1)">暂时不拍</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          :current-page="page_index"
          :total="page_total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="竞拍中" name="second">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params1.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w2">
            <span>年份</span>
            <el-input v-model="params1.productYear" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>品牌车型</span>
            <el-input v-model="params1.modelName" size="medium"></el-input>
          </el-col>
          <el-col :span="2" style="text-align:right;">
            <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="mt20 table-mainbg">
          <el-table-column prop="affRegion" label="所属区域" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="productYear" label="年份/车型" align="center">
            <template slot-scope="scope">{{scope.row.productYear}}/{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="carGradeResult" label="车辆评级" align="center" width="100"></el-table-column>
          <el-table-column prop="joinAuctionCounts" label="参拍人数" align="center" width="100"></el-table-column>
          <el-table-column prop="auctionCounts" label="报价人数" align="center" width="100"></el-table-column>
          <el-table-column prop="concernCounts" label="关注人数" align="center" width="100"></el-table-column>
         <el-table-column prop="auctionBeginTime" label="竞拍开始时间" align="center"> </el-table-column>
          <el-table-column prop="auctionEndTime" label="竞拍结束时间" align="center"></el-table-column>
          <el-table-column prop="lastTradePrice" label="上次竞拍价格" align="center"></el-table-column>
          <el-table-column prop="lastTradeMerchantName" label="上次中标商户" align="center"></el-table-column>
          <el-table-column label="操作" width="120px" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="onRow(scope.row,2)">终止竞拍</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size1"
          :current-page="page_index1"
          :total="page_total1"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="竞拍结束" name="third">
        <el-row :gutter="20" class="mt20">
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>车牌号码</span>
            <el-input v-model="params2.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w4">
            <span>品牌车型</span>
            <el-input v-model="params2.modelName" size="medium"></el-input>
            <!-- <el-cascader v-model="params2.seriesId" :options="brandlist" brandlist :props="brandProps" size="medium"></el-cascader> -->
          </el-col>
          <el-col :span="2" style="text-align:right;">
            <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist2" border stripe class="mt20 table-mainbg">
          <el-table-column prop="affRegion" label="所属区域" align="center"></el-table-column>
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="productYear" label="年份/车型" align="center">
            <template slot-scope="scope">{{scope.row.productYear}}/{{scope.row.seriesName}}</template>
          </el-table-column>
          <el-table-column prop="carGradeResult" label="车辆评级" align="center" width="100"></el-table-column>
          <el-table-column prop="joinAuctionCounts" label="参拍人数" align="center" width="100"></el-table-column>
          <el-table-column prop="auctionCounts" label="报价人数" align="center" width="100"></el-table-column>
          <el-table-column prop="concernCounts" label="关注人数" align="center" width="100"></el-table-column>
          <el-table-column prop="auctionBeginTime" label="竞拍开始时间" align="center"></el-table-column>
          <el-table-column prop="auctionEndTime" label="竞拍结束时间" align="center"></el-table-column>
          <el-table-column prop="lastTradePrice" label="上次竞拍价格" align="center"></el-table-column>
          <el-table-column prop="lastTradeMerchantName" label="上次中标商户" align="center"></el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size2"
          :current-page="page_index2"
          :total="page_total2"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="`${itemState==1?'暂时不拍':'终止竞拍'}`" :visible.sync="visible">
      <el-form :model="form2">
        <el-form-item :label="`${itemState==1?'暂时不拍原因':'终止原因'}`" label-width="120px">
          <el-input v-model="form2.remark" type="textarea" :autosize="{ minRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submitModal">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      params: {
        productYear: '',
        carNo: '',
      },
      params1: {},
      params2: {
        seriesId:[]
      },
      querylist: [],
      querylist1:[],
      querylist2:[],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      page_index1: 1, // 页数
      page_total1: 0, // 总页数
      page_size1: 10,
      page_index2: 1, // 页数
      page_total2: 0, // 总页数
      page_size2: 10,
      itemRow:{},
      brandlist:[],//获取所有品牌车系
      brandProps:{
        value:'id',
        label:'name',
        children:'carSeriesList',
      },
      visible:false,
      form2:{},
      itemState:'',
      timerArr:[],
    }
  },

  created(){
    this.init()
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.brandlist=res;
      })
    }else{
      this.brandlist=this.$store.state.setting.carBrandList;
    }
    
  },
  methods: {
    handleSizeChange(row){
      switch(this.activeName){
        case 'first':
          this.page_size=row;
          this.page_index=1;
          break;
        case 'second':
          this.page_size1=row;
          this.page_index1=1;
          break;
        case 'third':
          this.page_size2=row;
          this.page_index2=1;
          break;
      }
      this.init()
    },
    handleCurrentChange(row){
      switch(this.activeName){
        case 'first':
          this.page_index=row;
          break;
        case 'second':
          this.page_index1=row;
          break;
        case 'third':
          this.page_index2=row;
          break;
      }
      this.init()
    },
    init(page){
      //车辆竞拍状态：201待竞拍，202竞拍中，203延期竞拍，204暂时不拍，205竞拍终止，206竞拍结束
      let query={}
      switch(this.activeName){
        case 'first':
          if(this.querylist.length==1){
            this.page_index=page?page:this.page_index;
          }
          

          query.curPage=this.page_index; // 页数
          query.pageSize=this.page_size;
          query.carNo=this.params.carNo
          query.productYear=this.params.productYear;
          query.carType=this.params.modelName;
          query.carAuctionStatus=201;
          break;
        case 'second':
          if(this.querylist1.length==1){
            this.page_index1=page?page:this.page_index1;
          }
          

          query.curPage=this.page_index1; // 页数
          query.pageSize=this.page_size1;
          query.carNo=this.params1.carNo
          query.productYear=this.params1.productYear;
          query.carType=this.params1.modelName;
          query.carAuctionStatus=202
          break;
        case 'third':
          this.page_index2=page?page:this.page_index2;

          query.curPage=this.page_index2; // 页数
          query.pageSize=this.page_size2;
          query.carNo=this.params2.carNo
          query.carType=this.params2.modelName
          query.carAuctionStatus=205
          break;
      }
      this.$api.postCarAuctionPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          //清除存储的定时器
          for(let i=0;i<this.timerArr.length;i++){
            clearInterval(this.timerArr[i])
          }
          this.timerArr=[];//数据重置
          switch(this.activeName){
            case 'first':
              let arr=res.data.records;
              this.page_total=res.data.total;
              for(let i=0;i<arr.length;i++){
                arr[i].intervalTime='';
                this.countDown(arr[i])
              }
              this.querylist=arr;
              break;
            case 'second':
              this.querylist1=res.data.records;
              this.page_total1=res.data.total;
              break;
            case 'third':
              this.querylist2=res.data.records;
              this.page_total2=res.data.total;
              break;
          }
        }else{
          switch(this.activeName){
            case 'first':
              this.querylist=[];
              this.page_total=0;
              break;
            case 'second':
              this.querylist1=[];
              this.page_total1=0;
              break;
            case 'third':
              this.querylist2=[];
              this.page_total2=0;
              break;
          }
        }
      })
    },
    countDown(row){
      if(!row.auctionBeginTime){
        return
      }
      row.countDown=setInterval(() => {
        if(isNaN(row.nowTime)==false){
          row.nowTime =row.nowTime +1000;
          row.nowDate=new Date(row.nowTime)
        }
        row.intervalTime =  this.$$.formatSeconds(new Date(row.auctionBeginTime)- new Date(row.nowTime) );
        if(row.intervalTime=='0天0小时0分钟0秒'){
          row.intervalTimeState=true;
          setTimeout(()=>{
            this.getPageSize()
            this.init();
          },1000*3)
        }else if(row.intervalTime.indexOf('-')!=-1){
          clearInterval(row.countDown)
          row.countDown=null;
          row.intervalTimeState=true;
          row.intervalTime='0天0小时0分钟0秒';
        }
      }, 1000); //这边采取的是1秒调用一次
      //这里采用简单写法，直接在查询的时候调用一次，不用等定时器调用
      row.intervalTime =  this.$$.formatSeconds(new Date(row.auctionBeginTime)- new Date(row.now));
      row.nowTime = new Date(row.now).getTime()//服务器时间更新
      row.nowDate = row.now;//当前时间日期矫正
      if(row.countDown){
        this.timerArr.push(row.countDown)
      }
      
      this.$once('hook:beforeDestroy', () => {
        clearInterval(row.countDown)
        row.countDown = null;
      })
    },
    onRow(row,state){
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.visible=true;
      this.itemState=state;
      this.form2={};
    },
    submitModal(){//按钮操作
      switch(this.itemState){//1暂时不拍,2终止竞拍
        case 1:
          this.postOperaStatus(3)
          break;
        case 2:
          this.postOperaStatus(5)
          break;
      }
    },
    getPageSize(){//在删除成功后调用此方法
      switch(this.activeName){
        case 'first':
          const totalPage = Math.ceil((this.page_total - 1) / this.page_size);
          //计算出当前页码
          this.page_index = this.page_index > totalPage ? totalPage : this.page_index;
          //如果页码为1的话，则无效
          this.page_index = this.page_index < 1 ? 1 : this.page_index;
          break;
        case 'second':
          const totalPage1 = Math.ceil((this.page_total1 - 1) / this.page_size1);
          //计算出当前页码
          this.page_index1 = this.page_index1 > totalPage1 ? totalPage1 : this.page_index1;
          //如果页码为1的话，则无效
          this.page_index1 = this.page_index1 < 1 ? 1 : this.page_index1;
          break;
      }
      
    },
    postOperaStatus(operaType){//进行 竞拍状态 操作
      let query={
        // carId:this.itemRow.id,
        remark:this.form2.remark,
        auditStatus:operaType
      }
      this.$api.postAuditReport(this.itemRow.checkReportId,query).then(res=>{
        if(res.code==0){
          this.visible=!this.visible;
          this.$message.success(res.message)
          this.getPageSize()
          this.init()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
// .auctionManage{
//   padding: 40px;
// }
</style>