<template>
<!-- 检测产能查看 (这个页面被弃用了，检测产能使用clerk/storeResourceSettings这个页面)-->
  <div class="capacity">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" class="mb20">
      <el-tab-pane label="当日检测" name="first"></el-tab-pane>
      <el-tab-pane label="次日检测" name="second"></el-tab-pane>
    </el-tabs>
    <CapacityTable :tableList="capacityList" :bookDay="bookDay"></CapacityTable>
  </div>
</template>

<script>
import CapacityTable from '@/components/common/capacityTable'
export default {
  components: {
    CapacityTable
  },
  data() {
    return {
      capacityList: [
        {store: '川桥路', storeNo: '00101', checkTimeCapacity: [{time: '9:00', isBackup: 0},{time: '9:30', isBackup: 0},{time: '10:00', isBackup: 0},{time: '10:30', isBackup: 0},{time: '12:00', isBackup: 0},{time: '13:00', isBackup: 0},]},
        {store: '吴宝路', storeNo: '00102', checkTimeCapacity: [{time: '9:00', isBackup: 0},{time: '9:30', isBackup: 0},{time: '10:00', isBackup: 0},{time: '10:30', isBackup: 0},{time: '12:00', isBackup: 0},{time: '13:00', isBackup: 0},]},
      ],
      activeName: 'first',
      todayOrDay: 0,
      bookDay: '',
    }
  },
  created() {
    this.handleClick()
  },
  methods: {
    getCheckResourceCapacity() {
      let params = {
        isToday:this.activeName=='first'?1:0,
      }
      this.$api.getCheckResourceCapacity(params).then(res => {
        if(res.code == 0){
          this.capacityList = res.data;
        }
      })
    },
    handleClick(){
      if(this.activeName == 'first'){
        this.bookDay = this.$$.getFormatMomentTime('YYYY-MM-DD', Date.now())
        this.todayOrDay = 0;
      }else if(this.activeName == 'second'){
        this.bookDay = this.$$.getFormatMomentTime('YYYY-MM-DD', Date.now()+86400000)
        this.todayOrDay = 1;
      }
      this.getCheckResourceCapacity();
    }
  }
}
</script>

<style lang="scss" scoped>
.capacity{
  padding: 20px 40px;
}
</style>
