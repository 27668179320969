<template>
  <div class="pageContainer bgF5" :style="{'height':pageHeight}">
    <task :infolist="infolist"></task>
  </div>
</template>

<script>
import task from "@/components/task/task";
export default {
  data() {
    return {
      infolist:[],//业务任务
      pageHeight:'',
    };
  },
  created() {
    this.getHomePage();
  },
  methods: {
    getHomePage() {
      this.$api.getCheckReportAuditCount().then((res) => {
        if (res.code == 0) {
          let newArr= [
            {
              num: res.data.countCarTypeNoAudit,
              name: '车型待审核',
              star: 0,
              url:'/testReviewer/VehicleType'
            },
            {
              num: res.data.countCarTypeAuditNoPass,
              name: '车型审核驳回',
              star: 0,
              url:'/testReviewer/VehicleType?state=second'
            },
            {
              num: res.data.countNoAudit,
              name: '车辆待审核',
              star: 0,
              url:'/testReviewer/vehicleAudit'
            },
            {
              num: res.data.countAuditNoPass,
              name: '车辆审核驳回',
              star: 0,
              url:'/testReviewer/vehicleAudit?state=third'
            },
            {
              num: res.data.countNotAuction,
              name: '暂时不拍',
              star: 0,
              url:'/testReviewer/vehicleAudit?state=fourth'
            }
          ]
          this.infolist=[{name:"业务任务",list:newArr}];
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
        }
      });
    }
  },
  components: {
    task, //业务
  }
};
</script>

<style lang="scss" scoped>
</style>
