<template>
  <div class="pageContainer">
    <!--车辆信息-->
    <div class="content_modal_textBox pb100">
      <div class="ckxTitle mb10">车辆信息</div>
      <table class="table-cusHeadbg table-custom">
        <thead>
          <tr>
            <td>车牌号码</td>
            <td>车型</td>
            <td>年检有效期</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{form.carNo}}</td>
            <td>{{form.seriesName}}&nbsp;{{form.modelName}}</td>
            <td>{{form.checkExpire}}</td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt30">商户信息</div>
      <table class="table-cusHeadbg table-custom">
        <thead>
          <tr>
            <td>商户姓名</td>
            <td>商户电话</td>
            <td>商户经营城市</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{carMerchant.merchantName}}</td>
            <td>{{carMerchant.merchantMobile}}</td>
            <td>{{carMerchant.businessCityName}}</td>
          </tr>
        </tbody>
      </table>
      <div class="ckxTitle mb10 mt30">跟进信息</div>
      <el-form ref="forms" :model="forms" size="medium"  label-width="120px" >
        <el-form-item label="跟进结果:">
          <el-radio-group v-model="forms.certInviteStatus" :disabled="res.taskComHandlingStatus==113">
            <el-radio-button :label="41">继续跟进</el-radio-button>
            <el-radio-button :label="42">办证完成</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <template v-if="forms.certInviteStatus==41">
        <el-form-item label="下次跟进时间:">
          <el-date-picker
            v-model="forms.nextInviteTime"
            type="datetime"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结果描述:">
          <el-input type="textarea" v-model="forms.description" class="w300"></el-input>
        </el-form-item>
        </template>
      </el-form>

      <template v-if="forms.certInviteStatus==42">
      <!--过户凭证-->
      <div class="ckxTitle mb15 mt20">过户凭证</div>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) of fileListImg" :key="index">
          <div class="photo_row_imgBox">
            <el-image 
              :src="item.url" 
              :preview-src-list="[item.url]" v-if="item.url||state==3">
              <div slot="error">暂无图片</div>
            </el-image>
            <div @click="onUpload(item)" v-if="!item.url&&state!=3" class="uploadBoxTop">
                <el-upload
                class="tableUpload"
                action
                accept="image/png, image/gif, image/jpeg,image/jpg"
                :on-change="requestUpload"
                :auto-upload="false" >
                  <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                  <div slot="file" slot-scope="{file}">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url" alt=""
                    >
                    <!-- <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span> -->
                  </div>
                </el-upload>
              </div>
            <div class="photo_row_imgtiltle">{{item.name}}</div>
          </div>
        </div>
      </div>
      <!--特殊凭证-->
      <div class="ckxTitle mb15 mt20">特殊凭证</div>
      <div class="photo_content_box">
        <div class="photobox_row" v-for="(item,index) of fileList1" :key="index">
          <div class="photo_row_imgBox">
            <el-image 
              :src="item.url" v-if="item.url||state==3" 
              :preview-src-list="[item.url]">
              <div slot="error">暂无图片</div>
            </el-image>
            <div @click="onUpload(item)" v-if="!item.url&&state!=3" class="uploadBoxTop">
                <el-upload
                class="tableUpload"
                action
                accept="image/png, image/gif, image/jpeg,image/jpg"
                :on-change="requestUpload"
                :auto-upload="false" >
                  <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                  <div slot="file" slot-scope="{file}">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url" alt=""
                    >
                    <!-- <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span> -->
                  </div>
                </el-upload>
              </div>
            <div class="photo_row_imgtiltle">{{item.name}}</div>
          </div>
        </div>
      </div>
      </template>

      <div class="ckxTitle mb10 mt30">客户邀约跟进记录</div>
      <el-table :data="certInviteTraceList" border stripe class="mt20 table-mainbg">
      <el-table-column prop="operateTime" align="center" label="操作时间"></el-table-column>
      <el-table-column prop="operator" align="center" label="操作人"></el-table-column>
      <el-table-column prop="nextInviteTime" align="center" label="下次跟进时间"></el-table-column>
      <el-table-column prop="certInviteStatusName" align="center" label="跟进结果"></el-table-column>
      <el-table-column prop="description" align="center" label="结果描述"></el-table-column>
    </el-table>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="onSubmit" v-if="state!=3">提交</el-button>
        <el-button type="primary" @click="onReturn">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      res:{},
      forms:{
        certInviteStatus:'41'
      },
      carMerchant:{},//商户表
      rules: {
        name: [{ required: true, message: '不能为空！', trigger: 'blur' }],
      },
      state:1,//1待办证，2跟进中，3待办证完成（查看）
      certInviteTraceList:[],
      fileListImg: [
        { name: "产证1-2页", url: "", uploader: "", uploaderDate: "" },
        { name: "产证3-4页", url: "", uploader: "", uploaderDate: "" },
        { name: "产证5-6页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户发票", url: "", uploader: "", uploaderDate: "" },
        {
          name: "行驶证照片或临牌照片",
          url: "",
          uploader: "",
          uploaderDate: ""
        }
      ], //图片
      fileList1: [
        { name: "过户后产证1-2页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户后产证3-4页", url: "", uploader: "", uploaderDate: "" },
        { name: "过户后产证5-6页", url: "", uploader: "", uploaderDate: "" }
      ], //图片
      taskId:''
    };
  },
  created() {
    if(this.$route.query.id) this.taskId=this.$route.query.id;
    this.getCertificateTaskDetails()
  },
  methods: {
    onUpload(row){
      this.itemRow=row;
    },
    requestUpload(file) {
      let obj = new FormData();
      obj.append("mFiles", file.raw);
      obj.append("businessId", this.taskId);
      obj.append("businessName", this.itemRow.name);
      obj.append("businessType", "certificationCompany");//办证公司上传
      this.$api.postFileUpload(obj).then(res => {
        if (res.code == 0) {
          this.getCertificateTaskDetails();//获取详情
          this.$message.success(res.message)
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onSubmit(){
      this.$refs['forms'].validate((valid) => {
        if (valid) {
          
          let query={
            taskId:this.taskId,
            taskProcessManageResult:'',
            certInviteTraceForm:{
              certTaskId:this.taskId,
              carId:this.res.carId,
              // nextInviteTime:this.forms.nextInviteTime,
              certInviteStatus:this.forms.certInviteStatus,
              description:this.forms.description
            }
          }
          query.taskProcessManageResult='com_process_continue'
          if(this.forms.certInviteStatus==42){
            query.taskProcessManageResult='com_process_success';
            query.certInviteTraceForm.nextInviteTime=this.forms.nextInviteTime;
          }
          this.$api.postTaskOperaProcess(query).then(res=>{
            if(res.code==0){
              this.getCertificateTaskDetails()
              this.$message.success(res.message)
            }
          })
        }
      });
    },
    onReturn(){
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path:'/certificationCompany/certificateProcessing'
      })
    },
    getCertificateTaskDetails() {//获取详情
      let query={
        "businessId": this.taskId,
        "businessType": "certificationCompany",
      }
      this.$api.getCertificateTaskDetails(this.taskId,query).then(res => {
        if (res) {
          if (res.code == 0) {
            //state1 待办证，2跟进中，3待办证完成（查看）
            switch(res.data.taskComHandlingStatus){
              case 111://办证公司-办证中: 111 待办证,112 跟进中,113 办证完成
                this.state=1;
                break;
              case 112:
                this.state=2;
                break;
              case 113:
                this.state=3;
                this.forms.certInviteStatus=42;
                break;
            }
            
            this.res = res.data;
            this.form=res.data.car;
            if(res.data.car) if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            //客户邀约跟进记录列表
            this.certInviteTraceList=res.data.certInviteTraceList;
            if(res.data.fileList){
              for(let i=0;i<this.fileListImg.length;i++){
                for(let j=0;j<res.data.fileList.length;j++){
                  let aitem=res.data.fileList[j]
                  if(this.fileListImg[i].name===aitem.sysFileBusinessList[0].businessName){
                    this.fileListImg[i].url=aitem.fullFilePath
                  }
                }
              }
              for(let i=0;i<this.fileList1.length;i++){
                for(let j=0;j<res.data.fileList.length;j++){
                  let aitem=res.data.fileList[j]
                  if(this.fileList1[i].name===aitem.sysFileBusinessList[0].businessName){
                    this.fileList1[i].url=aitem.fullFilePath
                  }
                }
              }
            }
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 20px 50px;
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    .photobox_row {
      width: 342px;
      margin: 0 27px 20px 0;
      .photo_row_imgBox {
        height: 255px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          line-height: 44px;
        }
      }
      .photo_row_textBox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
}

</style>
