<template>
  <div id="app">
    <!-- <router-view /> -->
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      visitedViews:this.$store.state.tagsView.visitedViews,
    }
  },
  created(){
    let userId=this.$store.state.user.userId;//用户id
    if(!userId){
      userId=localStorage.getItem('userId')
      this.$store.dispatch('user/setUser',userId); 
    }
    let roleId=this.$store.state.user.roleId;//角色id
    if(!roleId){
      roleId=localStorage.getItem('roleId')
      this.$store.dispatch('user/setRole',roleId); 
    }
    let userData=this.$store.state.user.userData;//用户数据
    if(userData){
      if(!userData.id){
        userData=sessionStorage.getItem('userData')
        this.$store.dispatch('user/setUserData',JSON.parse(userData)); 
      }
    }
  },
  watch:{
    visitedViews(res){
      let operateIds=this.$store.state.user.operateIds;//当前是否有操作检测报告id
      let aid=[];
      for(let i=0;i<res.length;i++){
        let item=res[i]
        if(item.path==='/testReviewer/auditReport'&&operateIds.includes(Number(item.query.id))){
          aid.push(Number(item.query.id))
        }
      }
      let arrDiff =  [...operateIds].filter(x => [...aid].every(y => y !== x));
      // console.log(operateIds,'------默认值---，剩余:')
      // console.log(aid,'===相同的值===传值：',arrDiff)
      if(arrDiff.length>0){
        this.postCheckAuditCourseStatus(arrDiff[0],aid)
      }
    }
  },
  mounted(){

  },
  methods:{
    postCheckAuditCourseStatus(reportId,arr){
      let query={
          id:reportId,
          status:2,//检查审核人过程： 1-准备进行审核 2-退出审核页面
        }
      this.$api.postCheckAuditCourseStatus(reportId,query).then(res=>{
          if(res.code==0){
            this.$store.dispatch('user/setOperateFlag',arr);
          }
        })
    },
  },
}
</script>