<template>
  <div class="pageContainer">
    <el-radio-group v-model="formState" @change="init(1)" class="groupbg">
      <el-radio-button :label="0">全部</el-radio-button>
      <el-radio-button :label="1">待办证</el-radio-button>
      <el-radio-button :label="2">办证中</el-radio-button>
      <el-radio-button :label="3">结算中</el-radio-button>
      <el-radio-button :label="4">交易完成</el-radio-button>
    </el-radio-group>
    <el-row :gutter="20" class="searchRowBox clear mt30">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码</span>
        <el-input v-model="forms.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="8" class="flex flex_align_center flex_between w5">
        <span >合同签订日期</span>
        <el-date-picker
          v-model="forms.date"
          type="daterange"
          size="medium"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="searchRowBox clear">
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车主姓名</span>
        <el-input v-model="forms.customerName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w5">
        <span>车主身份证</span>
        <el-input v-model="forms.customerIdNo" size="medium"></el-input>
      </el-col>
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>企业名称</span>
        <el-input v-model="forms.companyName" size="medium"></el-input>
      </el-col> -->
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>营业执照号</span>
        <el-input v-model="forms.businessLicense" size="medium"></el-input>
      </el-col>
      <el-button type="primary" @click="init(1)" size="medium" class="fr mr10" plain>查询</el-button>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt30">
      <el-table-column prop="carNo" label="车牌号" align="center"></el-table-column>
      <el-table-column prop="makerName" label="车型" align="center">
        <template slot-scope="scope">{{scope.row.makerName}}&nbsp;&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="tradeShopName" label="成交门店" align="center"></el-table-column>
      <el-table-column prop="signTime" label="客户合同签订日期" align="center"></el-table-column>
      <el-table-column prop="appointFinishDate" label="约定办证完成时间" align="center"></el-table-column>
      <el-table-column prop="customerName" label="车主姓名/企业名称" align="center">
        <template slot-scope="scope">{{scope.row.customerName}}{{scope.row.companyName}}</template>
      </el-table-column>
      <!-- <el-table-column prop="companyName" label="企业名称" align="center"></el-table-column> -->
      <!-- <el-table-column prop="customerIdNo" label="车主身份证号" align="center"></el-table-column> -->
      <el-table-column prop="businessLicense" label="车主身份证号/营业执照号" align="center">
        <template slot-scope="scope">{{scope.row.customerIdNo}}{{scope.row.businessLicense}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="onconfirm(scope.row)" size="mini">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
   
  </div>
</template>
<script>
export default {
  data() {
    return {
      formState: 0,
      forms: {
        date:''
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(curPage) {
      if(curPage){
        this.currentPage=curPage?curPage:this.currentPage
      }
      let query = {
        curPage: this.currentPage,
        pageSize: this.pageSize,
        carNo: this.forms.carNo,
        contractSignDateBegin: this.forms.date?this.forms.date[0]:'', //查询客户合同签订日期的开始时间
        contractSignDateEnd: this.forms.date?this.forms.date[1]:'', //查询客户合同签订日期的结束时间
        customerName: this.forms.customerName, //客户名称
        customerIdNo: this.forms.customerIdNo, //客户身份证号码
        companyName: this.forms.companyName, //公司名称
        businessLicense: this.forms.businessLicense, //公司营业执照
      };
      switch (this.formState) {
        case 0:
          query.statuses=[1,2,3,4] //不传则查全部状态
          break;
        default:
          query.statuses = [this.formState]; //
      }
      this.$api.postProgressPage(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onconfirm(row) {
      let aurl = "/accreditationCommissioner/certificateQueryDetails";
      this.$router.push({
        path: aurl,
        query: {
          id:row.carId
        }
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  padding: 40px;
  .searchRowBox {
    margin: 20px 0;
  }
  .form_title_spanInbox {
    display: inline-block;
  }
  .formmodal_but {
    margin-left: 35px;
  }
  .lineDotted {
    display: inline-block;
    width: 600px;
    height: 1px;
    background-color: #cccccc;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    margin-left: 15px;
  }
  .w110 {
    width: 110px;
  }
  .dateline {
    width: 32px;
    height: 1px;
    background-color: #333333;
  }
  .w180 {
    width: 180px;
  }
}
</style>