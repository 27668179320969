<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="需归还" name="first">
        <el-row :gutter="20" class="mt30 mb30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="form.carNo" size="medium"></el-input>
          </el-col>
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist" border stripe class="table-mainbg">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
          </el-table-column>
          <el-table-column prop="receiverName" label="领取人" align="center"></el-table-column>
          <el-table-column prop="receiverTypeName" label="领取人身份" align="center"></el-table-column>
          <el-table-column prop="sellerName" label="成交归属" align="center"></el-table-column>
          <el-table-column prop="getTime" label="领取时间" align="center"></el-table-column>
          <el-table-column prop="preGiveTime" label="预计归还时间" align="center">
             <template slot-scope="scope"><p :class="new Date(scope.row.preGiveTime).getTime()<(new Date().getTime()+(1000*10))?'tipsColor':''">{{scope.row.preGiveTime}}</p></template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="220px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onsee(0,scope.row)"
                size="mini"
                class="group-custom"
              >归还钥匙</el-button>
              <el-button
                type="primary"
                @click="onsee(1,scope.row)"
                size="mini"
                class="group-custom" :disabled="new Date(scope.row.preGiveTime).getTime()>(new Date().getTime()+(1000*10))?true:false"
              >处置预警</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="在库车辆" name="second">
        <el-row :gutter="20" class="mt30 mb30">
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>车牌号</span>
            <el-input v-model="form1.carNo" size="medium"></el-input>
          </el-col>
          <!-- <el-col :span="5" class="flex flex_align_center flex_between w3">
            <span>钥匙码</span>
            <el-input v-model="form1.keyCode" size="medium"></el-input>
          </el-col> -->
          <el-col :span="5" class="flex flex_align_center flex_between w3">
            <el-button type="primary" size="medium" @click="init(1)">查询</el-button>
          </el-col>
        </el-row>
        <el-table :data="querylist1" border stripe class="table-mainbg mt30">
          <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
          <el-table-column prop="brandName" label="品牌车型" align="center">
            <template slot-scope="scope">{{scope.row.brandName}}&nbsp;{{scope.row.modelName}}</template>
          </el-table-column>
          <!-- <el-table-column prop="receiverName" label="领取人" align="center"></el-table-column>
          <el-table-column prop="receiverTypeName" label="领取人身份" align="center"></el-table-column> -->
          <el-table-column prop="sellerName" label="成交归属" align="center"></el-table-column>
          <el-table-column prop="inStorageTime" label="入库时间" align="center" ></el-table-column>
          <el-table-column label="操作" align="center" width="120px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onsee(2,scope.row)"
                size="mini"
                class="group-custom"
              >钥匙借用</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="total1"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        title="钥匙借用"
        :visible.sync="dialogVisible"
        :show-close="false"
        class="customDialog"
        width="500px"
      >
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :hide-required-asterisk="true"
          label-width="130px"
          size="medium" 
        >
          <el-form-item label="领取人身份:" prop="receiverType">
            <el-select v-model="form.receiverType" placeholder="请选择领取人身份" size="medium" class="w200 mr10" @change="selectChange">
              <el-option label="车商" value="11"></el-option>
              <el-option label="检测师" value="12"></el-option>
              <el-option label="泊车员" value="13"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="领取人:" prop="receiverName">
            <el-select v-model="form.receiverName" placeholder="请选择领取人" size="medium" class="w200 mr10" v-if="form.receiverType==12">
              <el-option v-for="item in rolelist" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-input v-model="form.receiverName" placeholder="" class="w200" v-else></el-input>
          </el-form-item>
          <el-form-item label="领取时间:" class="nomr" prop="getTime">
            <el-date-picker v-model="form.getTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" class="w200" placeholder="选择领取时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="预计归还时间:" class="nomr" prop="preGiveTime">
            <el-date-picker v-model="form.preGiveTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" class="w200" placeholder="选择预计归还时间"></el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible=false" plain>取消</el-button>
          <el-button type="primary" @click="onSubmit('form')">提交</el-button>
        </span>
      </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      carNumber:'',
      form: {
        preGiveTime: "",
        receiverType:'',
        receiverName:'',
        getTime:'',
      },
      form1:{
        carNo:'',
        carKeyCode:''
      },
      rules: {
        receiverType: [{ required: true, message: "领取人身份不能为空" }],
        receiverName: [{ required: true, message: "领取人不能为空" }],
        getTime: [{ required: true, message: "领取时间不能为空" }],
        preGiveTime: [{ required: true, message: "预计归还时间不能为空" }]
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:20,
      querylist1: [],
      total1: 0,
      currentPage1: 1,
      pageSize1:20,
      dialogVisible:false,
      itemrow:{},
      rolelist:[],//领取人列表
    };
  },
  created(){
    this.init()
    this.queryUserListByRoleAndDepart()//根据角色和部门查询用户
  },
  methods: {
    init(page){
      switch(this.activeName){
        case 'first':
          this.getCarKeyList(page);
          break;
        case 'second':
          this.getStockCarList(page)
          break;
      }
    },
    getCarKeyList(page){//获取钥匙列表
      this.currentPage=page?page:this.currentPage;
      let query={
        "curPage": this.currentPage,
        "pageSize": this.pageSize,
        "carNo": this.form.carNo,//车牌号
        "keyStatuses": [2]//钥匙状态：1待领取，2领取中/待归还，3已归还，4钥匙码已失效
      }
      this.$api.getCarKeyList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist = res.data.records;
            this.total= res.data.total;
          }else{
            this.querylist = [];
            this.total = 0;
          }
        }
      })
    },
    getStockCarList(page){//库存 列表查询
      let query={
        "curPage": page?page:this.currentPage1,
        "pageSize": this.pageSize1,
        "carNo": this.form1.carNo,//车牌号
        // keyCode:this.form1.keyCode,
        "carStockStatus": [12]//车辆库存状态，不传查全部，不是列表查询中的条件：11待入库, 12在库, 13在途, 14已出库
      }
      this.$api.getStockCarList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist1 = res.data.records;
            this.total1= res.data.total;
          }else{
            this.querylist1 = [];
            this.total1 = 0;
          }
        }
      })
    },
    postCarKeyHandling(){//车辆钥匙 借用key_borrow，归还key_give 等处理；
      let query={
        "carId": this.itemrow.carId,
        "handlerType": "key_borrow",//处理类型：借用key_borrow，归还key_give；
        // "keyStockObjInId": 1,//车钥匙入库仓库ID
        // "keyStockObjAreaId": 1,//车钥匙入库仓库货区ID
        // "keyStockObjLocationId": 1,//车钥匙入库仓库货位ID
        // "keyStockObjOutId": 1,//车钥匙出库仓库ID
        "receiverType": this.form.receiverType,//领取人类型: 11车商，12检测师，13泊车员
        "receiverName": this.form.receiverName,//领取人名称
        "receiverMobile": this.form.receiverMobile,//领取人电话
        "getTime": this.form.getTime,//领取时间
        "preGiveTime": this.form.preGiveTime//预计归还时间
      }
      this.$api.postCarKeyHandling(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.onsee(2,this.itemrow);
          }
        }
      })
    },
    selectChange($event){
      if($event==11){
        this.getCarDetail();
      }
    },
    onsee: function(state,row) {
      let query={
          state:state,
          id:row.id
        }
      if(state==2){
        query.id=row.carId//在库车辆为车辆列表取值为carid
      }
      this.$router.push({
        path:'/warehouseKeeper/lendingDetails',
        query:query
      })
    },
    handleSizeChange(e) {
      switch(this.activeName){
        case 'first':
          this.pageSize=e;
          this.currentPage=1;
          break;
        case 'second':
          this.pageSize1=e;
          this.currentPage1=1;
          break;
      }
      this.init();
    },
    handleCurrentChange(e) {
      switch(this.activeName){
        case 'first':
          this.currentPage=e;
          break;
        case 'second':
          this.currentPage1=e;
          break;
      }
      this.init();
    },
    ondialogVisible(state,row){
      if(state){
        this.itemrow=row;
      }
      this.dialogVisible=!this.dialogVisible
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    onSubmit(){
      console.log(this.itemrow,'======')
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.postCarKeyHandling()//车辆钥匙 借用key_borrow，归还key_give 等处理；
        } else {
          return false;
        }
      });
    },
    getCarDetail(){//根据 车牌号 查询车辆以及相关的详情
      this.$api.getCarDetail(this.itemrow.carNo).then((res)=>{
        if(res){
          if(res.code==0){
            if(res.data.carMerchant){
              this.form.receiverName=res.data.carMerchant.merchantName
            }
          }
        }
      })
    },
    queryUserListByRoleAndDepart(){//根据角色和部门查询用户
      let query={
        roleName:'检测师'
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.rolelist=res.data;
          }
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .w300{
    width: 300px;
  }
  .w200{
    width: 250px;
  }
}
</style>
