<template>
  <div class="pageContainer">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="待收货" name="first">
      </el-tab-pane>
      <el-tab-pane label="已接收" name="second">
      </el-tab-pane>
      <el-tab-pane label="异常" name="third">
      </el-tab-pane>

      <el-form :model="form" class="mt30" :inline="true" size="medium">
          <el-form-item label="发货方：" >
            <el-select v-model="form.fromShopType" placeholder="请选择发货方" class="mr30" @change="changeSelect" clearable>
              <!-- <el-option label="办证公司" value="42"></el-option> -->
              <el-option label="门店" value="41"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发货门店：" v-show="storeType">
            <!-- <el-select v-model="form.branchCompanyValue" placeholder="请选择分公司" class="w150" @change="changelink($event,1)" value-key="id">
              <el-option v-for="item in departmentlist" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
            <el-select v-model="form.cityValue" placeholder="请选择城市" class="w150 mr10 ml10" @change="changelink($event,2)" value-key="id">
              <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select> -->
            <el-select v-model="form.shopId" placeholder="请选择门店" class="w150" clearable filterable>
              <el-option v-for="item in shopList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办证公司：" v-show="!storeType">
            <!-- <el-select v-model="form.shopId" placeholder="请选择办证公司" class="w150" clearable filterable>
              <el-option v-for="item in shopList" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
            </el-select> -->
            <span class="setFontSize16">{{company}}</span>
          </el-form-item>
          <el-form-item label="">
            <el-col :span="2">
            <el-button type="primary" plain size="medium" @click="search" class="ml30">搜索</el-button>
          </el-col>
          </el-form-item>
        </el-form>
        <el-table :data="querylist" border stripe class="table-mainbg">
          <el-table-column prop="logistNo" label="发货单" align="center" key="1"></el-table-column>
          <el-table-column prop="fromShopName" label="发货方" align="center" v-if="['first'].includes(activeName)" key="2"></el-table-column>
          <el-table-column prop="fromTime" label="出发时间" align="center" v-if="['first'].includes(activeName)" key="3"></el-table-column>
          <el-table-column prop="preArriveTime" label="预计到达时间" align="center" v-if="['first'].includes(activeName)" key="4"></el-table-column>
          <el-table-column prop="fromShopName" label="发货门店" align="center" v-if="['second','third'].includes(activeName)" key="5"></el-table-column>
          <el-table-column prop="fromName" label="发货人" align="center" v-if="['second','third'].includes(activeName)" key="6"></el-table-column>
          <el-table-column prop="fromTime" label="发货时间" align="center" v-if="['second','third'].includes(activeName)" key="7"></el-table-column>
          <el-table-column prop="toReceiveTime" label="接收时间" align="center" v-if="['second','third'].includes(activeName)" key="8"></el-table-column>
          <el-table-column prop="toName" label="接收人" align="center" v-if="['second','third'].includes(activeName)" key="9"></el-table-column>
          <el-table-column label="操作" align="center" width="120px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="onsee(scope.row)"
                size="mini"
              >{{['first'].includes(activeName)?'接货':'查看'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt30"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next,sizes, jumper"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
        ></el-pagination>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize:20,
      form: {},
      form1:{
        cityId:'',
        shopId:'',
        certCompanyId:''
      },
      departmentlist:[],//门店列表
      cityList:[],//城市
      shopList:[],//门店
      storeType:true,//是否展示门店搜索
      company:'',
      toShopId:'',
    };
  },
  created() {
    this.init();
    this.getShopList().then(res=>{
      if(res.data){
        this.shopList=res.data;
      }
    }).catch(()=>{
    });//获取交付中心
  },
  methods: {
    init() {
      let curPage=this.currentPage;
      let pageSize=this.pageSize;
      let logistStatus=[12];//11待发货, 12在途, 13待收货，14已接收，15异常
      let fromShopType='';
      let branchCompanyId=this.form.branchCompanyId;
      let cityId=this.form.cityId;
      let shopId=this.form.shopId;
      let certCompanyId=this.form.certCompanyId;
      if(this.activeName=='first'){
        logistStatus=[13];
      }else if(this.activeName=='second'){
        logistStatus=[14];
      }else{
        logistStatus=[15];
      }
      let query={
        curPage:curPage,
        pageSize:pageSize,
        logistStatus:logistStatus,//物流状态：11待发货, 12物流中/待收货, 13已接收，14异常
        fromShopType:fromShopType,//发货方门店类型：41门店，42办证公司
        // branchCompanyId:branchCompanyId,//分公司ID,不传查全部
        // cityId:cityId,//城市ID，不传查全部
        fromShopId:shopId,//门店ID，不传查全部
        // toShopId:this.toShopId,//收货门店
        certCompanyId:certCompanyId//办证公司ID
      }
      this.$api.getLogisticsList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res){
          if(res.code==0){
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = [];
            this.total = 0;
          }
        }
      })
    },
    changeSelect(e){
      if(e==42){//发货方门店类型：41门店，42办证公司
        this.postSupplierList();//获取办证公司
        this.storeType=false;
      }else{
        this.form.certCompanyId='';
        this.storeType=true;
      }
    },
    // changelink(e,state){
    //   if(state==1){
    //     this.recursionQueryUserListByDepartIds(e.id)
    //     if(this.activeName=='first'){
    //       this.form.branchCompanyId=e.id;
    //     }else{
    //       this.form1.branchCompanyId=e.id;
    //     }
        
    //   }else if(state==2){
    //     this.shopList=e.shopList;
    //     if(this.activeName=='first'){
    //       this.form.cityId=e.id;
    //     }else{
    //       this.form1.cityId=e.id;
    //     }
        
    //   }
    // },
    onsee(row){
      this.$router.push({
        path: "/warehouseKeeper/receivingDetails",
        query: {
          id:row.id,
          // carId:row.carId
        }
      });
    }, 
    search(){
      this.currentPage=1;
      this.init()
    },
    handleClick() {
      this.currentPage=1;
      this.init();
    },
    handleSizeChange(e) {
      this.pageSize=e;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(e) {
      this.currentPage=e
      this.init()
    },
    getShopList(){//查询交付中心
      return new Promise((resolve,reject)=>{
        let query={
          isEnabled:0
        }
        this.$api.getShopList(query).then((res)=>{
          if(res){
            if(res.code==0){
              resolve(res)
            }else{
              reject(res)
            }
          }
        })
      })
    },
    postSupplierList(){//  获取办证公司
      this.$api.postSupplierList({}).then((res)=>{
        if(res){
          if(res.code==0){
            if(res.data.length>0){
              this.company=res.data[0].companyName;
              this.form.certCompanyId=res.data[0].id;
            }
          }
        }
      })
    },
    recursionQueryUserListByDepartIds(departIds){//三级联动获取城市和门店
      let query={
        departIds:departIds
      }
      this.$api.recursionQueryUserListByDepartIds(query).then((res)=>{
        if(res){
          if(res.code==0){
            if(res.data.length>0){
              this.cityList=res.data[0].cityList;
            }
          }
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  .w150 {
    width: 150px;
  }
}
</style>
