<template>
  <div class="applyRefund">
    <div class="title_top_nameBox mb10">基本信息</div>
    <!-- <div class="mb20"><span class="mr30">车牌号：{{res.carNo}}</span><span class="mr30">办证类型：代办/自理</span><span class="mr30">品牌车型：{{res.carBrandModelName}}</span></div> -->
    <el-row class="mb15">
      <el-col :span="5">车牌号：{{car.carNo}}</el-col>
      <el-col :span="5">办证类型：{{res.certTypeName}}</el-col>
      <el-col :span="5">品牌车型：{{res.brandName}}&nbsp;{{res.seriesName}}</el-col>
    </el-row>
    <!-- <div class="flex flex_align_center mb10">
      <div class="title_top_nameBox mr30">银行信息</div>
    </div>
    <el-row :gutter="20" class="mb10">
      <el-col :span="12">户名：{{carCustomerAccount.accountName}}</el-col>
      <el-col :span="12">银行简称：{{carCustomerAccount.depositBankShortName}}</el-col>
    </el-row>
    <el-row :gutter="20" class="mb20">
      <el-col :span="12">账号：{{carCustomerAccount.account}}</el-col>
      <el-col :span="12">开户行：{{carCustomerAccount.depositBank}}</el-col>
    </el-row> -->
    <div class="title_top_nameBox mb20">退费信息</div>
    <el-table :data="middleFadeFees" border class="table-mainbg mt15" stripe max-height="500px" ref="table">
      <el-table-column type="index" align="center" label="序号" width="70"></el-table-column>
      <el-table-column prop="accountName" align="center" label="户名" ></el-table-column>
      <el-table-column prop="depositBankShortName" align="center" label="银行简称" ></el-table-column>
      <el-table-column prop="account" align="center" label="账号" ></el-table-column>
      <el-table-column prop="applyName" align="center" label="申请人" ></el-table-column>
      <el-table-column prop="applyTime" align="center" label="申请时间"></el-table-column>
      <el-table-column prop="depositYetBackAmountApply" align="center" label="申请金额（元）"></el-table-column>
      <el-table-column prop="fadeFeeDesc" label="退费说明" align="center"></el-table-column>
      <el-table-column prop="applyStatus" label="退费状态" align="center">
        <template slot-scope="scope">{{scope.row.applyStatus===21?'待申请':scope.row.applyStatus===22?'已申请':scope.row.applyStatus===23?'已驳回':scope.row.applyStatus===24?'已退费':scope.row.applyStatus===25?'已撤回':scope.row.applyStatus}}</template>
      </el-table-column>
      <el-table-column prop="bankRspTips" label="驳回原因" align="center">
        <template slot-scope="scope">{{[31,32,33].includes(scope.row.financePayStatus)?'-':scope.row.bankRspTips}}</template>
      </el-table-column>
      <el-table-column prop="feeBackApplyStatusName" label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button type="primary" size="mini" @click="onRestart(scope.row,1)" v-if="scope.row.applyStatus===23">重新申请</el-button> -->
          <el-button type="primary" size="mini" @click="onRestart(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer" v-if="!doDisable">
      <div class="btn">
        <el-button type="primary" @click="doSubmit" >申请退费</el-button>
        <el-button type="primary" @click="routePage" >返回</el-button>
      </div>
    </div>

    <el-dialog title="中间退费申请" :visible.sync="dialogVisible" width="900px">
      <el-row class="mb15" :gutter="24">
        <el-col :span="8">提示：客户风险押金（元）：{{res.riskDepositTotalAmount}}</el-col>
        <el-col :span="8">已退金额（元）：{{res.totalDepositYetBackAmountApply}}</el-col>
        <el-col :span="8">可退金额（元）：{{res.totalDepositYetBackAmountCanApply}}</el-col>
      </el-row>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="申请退费金额（元）" prop="depositYetBackAmountApply" label-width="180px">
          <el-input v-model="ruleForm.depositYetBackAmountApply" class="w200" type="number"></el-input>
        </el-form-item>
        <el-form-item label="退费账号:" prop="state" v-if="itemState===1">
          <div>
            <el-radio-group v-model="ruleForm.state">
              <el-radio :label="1">客户合同账号</el-radio>
              <el-radio :label="2">其它账号</el-radio>
            </el-radio-group>
            <el-radio-group v-model="ruleForm.vehicleSource" class="ml30" size="mini" v-if="ruleForm.state===2">
              <el-radio-button :label="1" v-if="res.isChannelCar===71">门店车源</el-radio-button>
              <el-radio-button :label="2" v-if="res.isChannelCar===72">4S店车源</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-row  v-if="ruleForm.state===1||ruleForm.vehicleSource===1">
          <el-col :span="12">
            <el-form-item label="收款账号名:" >
              <el-input v-model="ruleForm.accountName" class="w200" v-if="ruleForm.vehicleSource===1&&ruleForm.state===2"></el-input>
              <p v-else>{{carCustomerAccount.accountName}}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号:" prop="account">
              <el-input v-model="ruleForm.account" class="w200" v-if="ruleForm.vehicleSource===1&&ruleForm.state===2"></el-input>
              <p v-else>{{carCustomerAccount.account}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row  v-if="ruleForm.state===1||ruleForm.vehicleSource===1">
          <el-col :span="12">
            <el-form-item label="银行简称:" prop="depositBankShortName">
              <el-select class="w200" v-if="ruleForm.vehicleSource===1&&ruleForm.state===2" v-model="ruleForm.depositBankShortName" filterable @input="onChangBank" value-key="id">
                <el-option v-for="item in banklist" :key="item.id" :label="item.bankName" :value="item"></el-option>
              </el-select>
              <p v-else>{{carCustomerAccount.depositBankShortName}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row  v-if="ruleForm.state===1||ruleForm.vehicleSource===1">
          <el-col :span="24" class="flex">
            <template v-if="ruleForm.vehicleSource===1&&ruleForm.state===2">
             <el-form-item label="开户行:" prop="name">
              <el-cascader :options="addresslist" :props="props" clearable v-model="ruleForm.address"> </el-cascader>
            </el-form-item>
            <el-form-item label="" prop="depositBank" label-width="0" class="bankULBoxConter">
              <el-input v-model="ruleForm.depositBank" class="w300"  @input="onInputBank"></el-input>
               <ul class="bankULBox" v-show="bankVis&&banklistSear.length>0">
                <li v-for="item in banklistSear" :key="item.id" @click="onBankTage(item)">{{item.bankName}}</li>
              </ul>
            </el-form-item>
            </template>
             <el-form-item label="开户行:" prop="name"  v-if="ruleForm.state===1">
              <p>{{carCustomerAccount.depositBank}}</p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="账号:" prop="account" v-if="ruleForm.state===2&&ruleForm.vehicleSource===2">
          <el-select v-model="ruleForm.accountObj" class="w300" @input="onSelect" value-key="id">
            <el-option v-for="item in channellist" :key="item.id" :value="item" :label="`${item.accountTypeName}-账户名：${item.accountName}，账号：${item.account}`" :disabled="item.accountType===3&&item.enableStatus===0?true:false" v-if="item.accountName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退费说明:" prop="fadeFeeDesc">
          <el-input type="textarea" v-model="ruleForm.fadeFeeDesc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="itemState==1">
        <el-button type="primary" @click="onSubmitForm('ruleForm')">确定</el-button>
        <el-button  @click="dialogVisible=false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import ChangeBank from '@/components/deliver/changeBank.vue'
export default {
  // components: {
  //   ChangeBank
  // },
  data() {
    var validate = (rule, value, callback) => {
      if (value>this.res.totalDepositYetBackAmountCanApply) {
        callback(new Error('申请退费金额不可大于可退金额'));
      } else if (!value) {
        callback(new Error('不能为空'));
      } else {
        callback();
      }
    };
    return {
      tabClassify:'first',
      dialogVisible: false,
      tableData: [],
      params: {
        refundType: '',
        fadeFeeDesc: '',
      },
      incompleteData: [{}],
      carMaterialsList:[],//退费明细
      completeData: [{}],
      multipleSelection: [],
      doDisable: false,
      id:'',
      res:{},//合同详情数据
      car:{},//合同详情数据
      middleFadeFees:[],
      carCustomerAccount:{},
      carAcc:{},
      paymentMethodList:[],
      feeId:'',//中间退费金额
      rules:{
        depositYetBackAmountApply:[{ required: true, trigger: 'blur',validator:validate }],
        state:[{ required: true, message: '不可为空', trigger: 'blur' }],
        // depositBank:[{ required: true, message: '不可为空', trigger: 'blur' }],
        // account:[{ required: true, message: '不可为空', trigger: 'blur' }],
        // depositBankShortName:[{ required: true, message: '不可为空', trigger: 'blur' }],
        fadeFeeDesc:[{ required: true, message: '不可为空', trigger: 'blur' }],
      },
      ruleForm:{
        state:1,
        depositYetBackAmountApply:'',
        accountName:'',
        account:'',
        depositBankShortName:'',
        depositBank:'',
        fadeFeeDesc:'',
        depositBankShortCode:'',
        address:[],
        vehicleSource:'',
        accountObj:'',
        accountKind:'',//账户性质：1对公账号
      },
      itemState:1,//1新增，2修改
      addresslist:[],
      props:{
        value:'code',
        label:'name',
        children:'children'
      },
      banklist:[],//银行简称
      banklistSear:[],
      bankVis:false,
      channellist:[],//渠道
    }
  }, 
  created() {
    if(this.$route.query.id)this.id=this.$route.query.id;
     //获取所有渠道来源
    if(this.$store.state.setting.paymentMethodList.length==0){
      this.$datadic.getBankFinance().then(res=>{
        this.paymentMethodList=res;
      })
    }else{
      this.paymentMethodList=this.$store.state.setting.paymentMethodList;
    }
    //经营省份城市区域3级联动查询(无参数查所有)
    if(this.$store.state.setting.cityList.length==0){
      this.$datadic.getProvince(2).then(res=>{
        this.addresslist=res;
      })
    }else{
      this.addresslist=this.$store.state.setting.cityList;
    }
    this.getCustomerDetail()
    this.postbankHeadList()//获取银行简称
  },
  methods: {
    onSelect(row){
      // console.log(row,'---rrr---')
      if(row){
        this.ruleForm.accountName=row.accountName;//银行账户
        this.ruleForm.account=row.account;//账户名
        this.ruleForm.depositBank=row.bank;//支行
        this.ruleForm.depositBankShortName=row.bankShortName;//总行
      }
    },
    // getByChannelIdDetail(){//根据渠道ID查询
    //   this.$api.getByChannelIdDetail().then(res=>{
    //     if(res.code===0){
    //       this.channellist=res.data;
    //     }
    //   })
    // },
    getCustomerDetail(){//合同详情
      this.$api.getDetailsByCcId(this.id).then(res=>{
        if(res.code==0){
          this.res=JSON.parse(JSON.stringify(res.data));
          this.car=res.data.carCustomer;
          this.middleFadeFees=res.data.middleFadeFees?res.data.middleFadeFees:[];
          if(res.data.carCustomer){
            this.carCustomerAccount=res.data.carCustomer.carCustomerAccount?res.data.carCustomer.carCustomerAccount:{};
            this.carAcc=res.data.carCustomer.carCustomerAccount?res.data.carCustomer.carCustomerAccount:{};
          }
          this.channellist=res.data.accountList;
          if(this.res.isChannelCar===71){
            this.ruleForm.vehicleSource=1;
          }else if(this.res.isChannelCar===72){
             this.ruleForm.vehicleSource=2;
          }
          // this.ruleForm.state=1;
          // this.ruleForm.depositYetBackAmountApply='';
          // this.ruleForm.accountName=this.carCustomerAccount.accountName;
          // this.ruleForm.account=this.carCustomerAccount.account;
          // this.ruleForm.depositBankShortName=this.carCustomerAccount.depositBankShortName;
          // this.ruleForm.depositBank=this.carCustomerAccount.depositBank;
          // this.ruleForm.fadeFeeDesc=this.carCustomerAccount.accountName;
          
        }
      })
    },
    onRestart(row,type){
      this.dialogVisible=true;
      this.carCustomerAccount.accountName=row.accountName;
      this.carCustomerAccount.account=row.account;
      this.carCustomerAccount.depositBankShortName=row.depositBankShortName;
      this.carCustomerAccount.depositBank=row.depositBank;
      this.carCustomerAccount.depositBankShortCode=row.depositBankShortCode;
      this.ruleForm.depositYetBackAmountApply=row.depositYetBackAmountApply;
      this.ruleForm.fadeFeeDesc=row.fadeFeeDesc;
      this.ruleForm.accountKind=row.accountKind;
      if(type){
        this.itemState=1;
      }else{
        this.itemState=2;
        this.ruleForm.state=1;
      }
    },
    doSubmit() {
      this.itemState=1;
      this.dialogVisible=true;
      this.$nextTick(()=>{
        this.carCustomerAccount=JSON.parse(JSON.stringify(this.carAcc))
        this.ruleForm.state=1;
        this.ruleForm.depositYetBackAmountApply='';
        this.ruleForm.fadeFeeDesc='';
        this.ruleForm.accountName='';
        this.ruleForm.account='';
        this.ruleForm.accountObj='';
        this.ruleForm.depositBankShortName='';
        this.ruleForm.depositBank='';
        this.ruleForm.depositBankShortCode='';
        this.ruleForm.address=[];
        this.ruleForm.accountKind='';
      })
    },
    onSubmitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let query={
            "depositYetBackAmountApply": this.ruleForm.depositYetBackAmountApply,//申请退费金额
            "accountName": this.ruleForm.accountName,//银行账户
            "account": this.ruleForm.account,//账户名
            "depositBank": this.ruleForm.depositBank,//支行
            "depositBankShortName": this.ruleForm.depositBankShortName,//总行
            "fadeFeeDesc": this.ruleForm.fadeFeeDesc//退费说明
          }
          if(this.ruleForm.state==1){
            query.accountName=this.carCustomerAccount.accountName;
            query.account=this.carCustomerAccount.account;
            query.depositBank=this.carCustomerAccount.depositBank;
            query.depositBankShortName=this.carCustomerAccount.depositBankShortName;
          }
           if(this.ruleForm.state==2){
            query.accountKind=this.ruleForm.accountKind;
           }
          this.$api.postCustomerMiddleFeeApply(this.id,[query]).then(res=>{
            if(res.code==0){
              const loading = this.$loading({
                lock: true,
                customClass: "loadginText",
                text: "数据加载中，请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)"
              });
              setTimeout(()=>{
                loading.close();
                this.dialogVisible=false;
                this.$message.success(res.message)
                this.getCustomerDetail()
              },2000)
            }
          })
        }
      });
    },
    onChangBank(e){
      this.ruleForm.depositBankShortName=e.bankName;
      this.ruleForm.depositBankShortCode=e.bankCode;
    },
    onBankTage(row){
      this.ruleForm.depositBank=row.bankName;
      this.bankVis=false;
    },
    onInputBank(e){
      if(e){
        this.bankVis=true;
        this.postbankList(e)
      }else{
        this.bankVis=false;
      }
    },
    postbankList(bankName){//根据银行简称
      let query={
        bankName:bankName,
        headBankCode:this.ruleForm.depositBankShortCode,
        cityNo:this.ruleForm.address.length>0?this.ruleForm.address[1]:'',
      }
      this.$api.postbankList(this.$$.delete_null_properties(query)).then((res)=>{
        if(res.code==0){
          this.banklistSear=res.data;
        }
      })
    },
    postbankHeadList(){//银行列表查询
      this.$api.postbankHeadList({}).then(res=>{
        if(res.code==0){
          this.banklist=res.data;
        }
      })
    },
    routePage(){
      this.bus.$emit("closeSelected");
      this.$router.push({
        path:'/salesManager/refund/refund'
      })
    },
  },
}
</script>

<style scoped lang="scss">
.applyRefund{
  padding: 20px 30px 140px;
  //下拉展示
  .bankULBoxConter{
    position:relative;
    .bankULBox{
      position:absolute;
      left:0;
      z-index:5;
      background:#ffffff;
      width:100%;
      max-height:300px;
      overflow:auto;
      border:1px solid #cccccc;
      box-shadow: 0px 2px 5px #cccccc;
      li:hover{
        background-color:#ECF5FF;
      }
      li{
        font-size: 14px;
        line-height:18px;
        padding:5px 10px;
        cursor:pointer;
      }
    }
  }
}
</style>
