<template>
  <div class="pageContainer">
    <!--基本信息-->
    <div class="content_modal_textBox">
      <el-form ref="form" :model="form" label-width="100px" :hide-required-asterisk="true" :rules="rules">
        <el-form-item label="客户名称:">
          <p>张三</p>
        </el-form-item>
        <el-form-item label="客户电话:">
          <p>张三</p>
        </el-form-item>
        <el-form-item label="车牌号:">
          <p>{{form.carNo}}</p>
        </el-form-item>
        <el-form-item label="所属渠道:">
          <p>{{form.areaName}}</p>
        </el-form-item>
        <el-form-item label="提交时间:">
          <p>{{form.createDate}}</p>
        </el-form-item>
        <el-form-item label="佣金金额:" class="mt10" >
          <el-input v-model="form.amount" size="medium" class="w200" disabled></el-input>
        </el-form-item>
        <div class="mt30">
          <el-button type="primary" size="medium" @click="onSubmit('form','通过')">审核通过</el-button>
          <el-button type="primary" size="medium" @click="onSubmit('form','驳回')">审核不通过</el-button>
          <el-button type="primary" size="medium" plain @click="onroute">返回</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.id=this.$route.query.id;
    this.getCheckTaskByTaskId()//获取详情
  },
  methods: {
    onSubmit(formdata){
      // console.log(state,'----state---')
      this.$refs[formdata].validate(valid => {
        if (valid) {
          //此处提交数据到后台，刷新列表
          this.onroute();
        } else {
          return false;
        }
      });
    },
    onroute() {
      this.bus.$emit("closeSelected",'router');
      this.$router.push({
        path: "/channel/commissionManagement"
      });
    },
    getCheckTaskByTaskId(){
      this.$api.getCheckTaskByTaskId(this.id).then((res)=>{
        if(res){
          if(res.code==0){
            this.form=res.data
          }
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .el-form-item{
    margin-bottom: 0;
  }
  .w200{
    width: 200px;
  }
}
</style>