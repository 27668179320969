export default {
  namespaced: true,
	state: {
    userId: '',//用户id
    roleId:'',//角色id
    loadingType:true,//是否需要开启全局loading，默认开启
    userData:{},//用户数据
    intentionMoney:[],//意向金状态列表
    softloginType:false,//软电话是否登陆
    softStatus:'',//软电话状态
    onlineStatus:'GoLive',//软电话上线下线状态‘GoLive上线，Offline下线’
    softCodeStatus:'',//软电话code
    roleMenuType:false,//当前是否存在菜单
    operateIds:[],//当前是否有操作检测报告id
	},
  mutations: {
		// 用户id
		getUser(state, data) {
			state.userId = data;
    },
    // 角色id
		getRole(state, data) {
			state.roleId = data;
    },
    getLoading(state,data){
      state.loadingType =data;
    },
    getUserData(state, data) {//用户数据
			state.userData = data;
    },
    getIntentionMoney(state,data){//意向金状态
      state.intentionMoney = data
    },
    getSoftloginType(state,data){//软电话是否登陆
      state.softloginType = data
    },
    getSoftStatus(state,data){//软电话状态
      state.softStatus = data
    },
    getSoftCodeStatus(state,data){//软电话code
      state.softCodeStatus = data
    },
    getOnlineStatus(state,data){//软电话上线下线状态‘GoLive上线，Offline下线’
      state.onlineStatus = data
    },
    getRoleMenulist(state,data){//当前是否存在菜单
      state.roleMenuType = data
    },
    getOperateFlag(state,data){//当前是否有操作检测报告id
      state.operateIds = data
    },
	},
  actions: {
		// 用户id
		async setUser({ commit }, data) {
			commit('getUser', data);
    },
    // 角色id
		async setRole({ commit }, data) {
			commit('getRole', data);
    },
    async setLoading({commit},data){
      commit('getLoading',data);
    },
    async setUserData({commit},data){
      commit('getUserData',data);
    },
    async setIntentionMoney({commit},data){
      commit('getIntentionMoney',data)
    },
    async setSoftloginType({commit},data){//软电话是否登陆
      commit('getSoftloginType',data)
    },
    async setSoftStatus({commit},data){//软电话是否登陆
      commit('getSoftStatus',data)
    },
    async setSoftCodeStatus({commit},data){//软电话code
      commit('getSoftCodeStatus',data)
    },
    async setOnlineStatus({commit},data){//软电话上线下线状态‘GoLive上线，Offline下线’
      commit('getOnlineStatus',data)
    },
    async setRoleMenulist({commit},data){//当前是否存在菜单
      commit('getRoleMenulist',data)
    },
    async setOperateFlag({commit},data){//当前是否有操作检测报告id
      commit('getOperateFlag',data)
    },
    // GenerateRoutes({ commit }, asyncRouter) {
    //   commit('SET_ROUTERS', asyncRouter)
    // }
	},
}