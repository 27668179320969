<template>
  <div class="pageContainer">
    <div class="flex flex_align_center">
      <el-radio-group v-model="isToday" @change="init">
        <el-radio-button label="1">当日</el-radio-button>
        <el-radio-button label="0">次日</el-radio-button>
      </el-radio-group>
      <div class="paibox">
        <el-tag  type="success" effect="dark">待预约</el-tag>
        <el-tag effect="dark" class="mr10 ml10">已排班</el-tag>
        <el-tag type="info" effect="dark">已取消</el-tag>
      </div>
      <div class="tipsColor ml30">温馨提示：页面会每两分钟进行一次刷新，如需实时了解资源占用情况，请手动刷新。</div>
    </div>
    <table class="table-custom mt20">
      <tr>
        <td width="200px">检测区域</td><td>新增外勤资源请求</td>
      </tr>
      <tr v-for="item in querylist" :key="item.planAreaId">
        <td  width="200px">{{item.area}}</td>
        <td>
          <div class="flex flex_align_center flex_wrap">
            <div v-for="(items,index) in item.fieldPlanList" :key="`fie_${index}`" class="minBox">
              <table class="table-custom wtable">
                <tr class="colorFont" :class="items.status==1?'blueBG':items.status==2?'greyBG':'greenBG'">
                  <td>{{items.createUserNo}}{{items.createUserName}}</td><td>{{items.createDate}}</td>
                  <td><el-button type="text" @click="onsee(items,items.status==2?2:1)" size="medium">{{items.status==2?'查看':'取消'}}</el-button></td>
                </tr>
                <tr>
                  <td colspan="2">{{items.customerNo}}</td>
                  <td><el-button type="text" @click="onsee(items,3)" size="medium">载入</el-button></td>
                </tr>
                <tr><td colspan="3">渠道：{{items.channelName}}</td></tr>
                <tr><td colspan="3">外环外：{{items.isOuter?'是':'否'}}</td></tr>
                <tr><td colspan="3">{{items.timeRequest}}</td></tr>
                <tr><td colspan="3">{{items.checkAddress}}</td></tr>
              </table>
              <div class="footerBox">
                <span>检测师：{{items.checkerName}} </span>
                <span>{{items.planTime}}</span>
                <template v-if="hasRole">
                <el-button type="primary" @click="onCheck(items,1)" size="mini" v-show="!items.planTime" :disabled="items.status==2?true:false">检测排班</el-button>
                <el-button type="primary" @click="onCheck(items,2)" size="mini" v-show="items.planTime" :disabled="items.status==2?true:false">修改排班</el-button>
                </template>
              </div>
            </div>
            <i class="el-icon-circle-plus-outline iconText" @click="onsee(item,4)" v-show="followUpInfoId"></i>
          </div>
        </td>
      </tr>
    </table>
    <el-dialog
      title=""
      :visible="dialogVisible" :show-close="false"
      width="500px">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <div v-if="textState==4">
          <el-form-item label="客户编号:">
            <p>{{customerNo}}</p>
          </el-form-item>
          <el-form-item label="是否外环外:" prop="isOuter">
            <el-radio-group v-model="form.isOuter">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="时间要求:" prop="timeRequest">
            <el-input type="textarea" v-model="form.timeRequest"></el-input>
          </el-form-item>
          <el-form-item label="检测地址:" prop="checkAddress">
            <el-input type="textarea" v-model="form.checkAddress"></el-input>
          </el-form-item>
        </div>
        <div v-if="textState==1||textState==2">
          <el-form-item label="取消原因:" prop="cancelReason">
            <el-select v-model="form.cancelReason">
              <el-option v-for="item in virtualReason" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="textarea" v-model="form.remark"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmit" v-show="textState==4||textState==1">添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {},
      rules:{
        isOuter: [{ required: true, message: '不能为空！', trigger: 'change' }],
        timeRequest: [{ required: true, message: '不能为空！', trigger: 'blur' }],
        checkAddress: [{ required: true, message: '不能为空！', trigger: 'blur' }],
        cancelReason: [{ required: true, message: '不能为空！', trigger: 'blur' }],
      },
      isToday:1,
      querylist: [],
      dialogVisible:false,
      textState:2,//2查看，1取消
      timer:null,
      hasRole:false,//根据角色是否显示
      followUpInfoId:'',
      customerNo:'',
      date:'',//接口获取日期
      itemRow:{},
      virtualReason:[],//虚拟五方格取消原因
      // visFlage:false,//是否显示添加入口
    };
  },
  created() {
    this.init();
    let user=this.$$.roleName(this.$store.state.user.userData)
    this.hasRole=user.name=='检测坐席组长'?true:false;
    this.virtualReason=this.$store.state.setting.virtualReason;
    this.followUpInfoId=this.getFollowUpInfoId()
    if(this.followUpInfoId){
      this.customerQueryCustomerByFollowUpId(this.followUpInfoId)
    }
  },
  mounted(){
    this.timer = setInterval(() => {
      this.init()
    }, 1000*60*2);
  },
  methods: {
    init(){
      //是否当日 1-当日 0-次日
      this.$api.getCheckResources(this.isToday).then(res=>{
        if(res.code==0){
          this.querylist=res.data.areaList;
          this.date=res.data.day;
        }
      })
    },
    onsee(row,state){
      this.textState=state;
      this.itemRow=row;
      switch(state){//2查看，1取消,3载入,4新增
        case 1:
          this.dialogVisible=true;
          break;
        case 2:
          this.dialogVisible=true;
          this.form=JSON.parse(JSON.stringify(row))
          break;
        case 3:
          this.$router.push({
            path:'/testService/customer',
            query:{
              followUpInfoId:row.followUpId
            }
          })
          break;
        case 4:
          this.dialogVisible=true;
          this.form={};
          break;
      }
    },
    onSubmit(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.textState==4){
            this.postFieldPlanSave()
          }else{
            this.postCheckResourceFieldPlanUpdate()
          }
        }
      });
    },
    postFieldPlanSave(){
      let query={
        customerNo:this.customerNo,
        planAreaId:this.itemRow.planAreaId,//外勤计划区域ID
        isOuter:this.form.isOuter,//是否外环外 0-否 1-是
        timeRequest:this.form.timeRequest,
        checkAddress:this.form.checkAddress,
        date:this.date,
      }
      this.$api.postFieldPlanSave(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init();
          this.dialogVisible=false;
        }
      })
    },
    postCheckResourceFieldPlanUpdate(){//修改（取消和排班）外勤虚拟五方格任务
      let query={
        "planId": this.itemRow.id,//外勤检测预约计划ID
        "status": 2,//状态 0-待排班 1-已排班 2-已取消
        // "routeNo": this.itemRows.routeNo,
        // "planTime": this.activeIn,
        "cancelReason": this.form.cancelReason,
        "remark": this.form.remark
      }
      this.$api.postCheckResourceFieldPlanUpdate(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
          this.init()
          this.dialogVisible=false;
        }
      })
    },
    onCheck(row){
      this.$router.push({
          path:'/testService/fieldInspection',
          query:{
            id:row.id
          }
        })
    },
    getFollowUpInfoId(){//获取id
      let visitedViews=this.$store.state.tagsView.visitedViews;
      let arr=[]
      for(let i=0;i<visitedViews.length;i++){
        let item=visitedViews[i];
        if(["/testService/customer",'/salesManager/customer'].includes(item.path)){
          // console.log(item,'====555===',item.name,item.query.followUpInfoId)
          arr.push(item.query.followUpInfoId)
          // return item.query.followUpInfoId
        }
      }
      if(arr.length>0){
        return arr[arr.length-1]
      }
    },
    // 客户详情查询
    customerQueryCustomerByFollowUpId(followUpInfoId) {
      this.$api.customerQueryCustomerByFollowUpId(followUpInfoId).then(res => {
        if(res.code == 0){
          this.customerNo = res.data.customerNo;
        }
      })
    },
    
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.pageContainer {
  .iconText{
    font-size:50px;
    cursor: pointer;
    margin-left: 20px;
  }
  .paibox{
    margin-left:100px;
  }
  .footerBox{
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding:5px;
  }
  .minBox{
    margin: 5px;
  }
}
#app .wtable{ width:400px;}
#app  .wtable .colorFont td .el-button--text,
#app  .wtable .colorFont td{color:#ffffff;}

#app .wtable td{height: 30px;padding: 0;}
</style>
