<template>
  <div class="pageContainer">
    <el-form
      ref="forms"
      :model="forms"
      label-width="100px"
      :rules="rules" size="medium"
      :hide-required-asterisk="true" >
      <el-form-item label="标题:" prop="title">
        <el-input v-model="forms.title"  ></el-input>
      </el-form-item>
      <el-form-item label="正文:" prop="content">
        <el-input type="textarea" v-model="forms.content" class="textConter"></el-input>
      </el-form-item>
      <!-- <el-form-item label="按钮文案:" prop="name">
        <el-input v-model="forms.name"  ></el-input>
      </el-form-item> -->
    </el-form>
      
    <div class="footer" >
      <div class="btn">
        <el-button type="primary" @click="onSubmitform('forms')" >发送</el-button>
        <!-- <el-button type="primary" @click="onrouterPage">取消</el-button> -->
      </div>
    </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forms: {}, //展示数据
      rules:{
        name: [{ required: true, message: "不能为空" }],
        content:[{ required: true, message: "不能为空" }],
        title:[{ required: true, message: "不能为空" }],
      }
    };
  },
  created() {
  },
  methods: {
    onSubmitform(formdata) {
      //提交frorm
      this.$refs[formdata].validate(valid => {
        if (valid) {
          this.$confirm('请确认是否发布公告?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let query={
              "content": this.forms.content,//
              title:this.forms.title,
            }
            this.$api.postMessageNoticeAdd(query).then((res)=>{
              if(res){
                if(res.code==0){
                  this.$message.success(res.message)
                  setTimeout(()=>{
                    this.$refs[formdata].resetFields();
                  },1000)
                }
              }
            })
          }).catch(() => {});
        }
      });
    },
    onrouterPage() {
      this.bus.$emit("closeSelected");
      // this.$router.push({
      //   path: "/accreditationCommissioner/expenseAudit"
      // });
    },
  }
};
</script>

<style lang="scss" scoped>
.pageContainer {
  // background: #f5f5f5;
  padding-top: 20px;
  .textConter{
    ::v-deep .el-textarea__inner{
      height:220px;
    }
  }
  
}

</style>