<template>
  <div class="auctionManage">
    <el-row :gutter="20">
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车牌号码</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w2">
        <span>年份</span>
        <el-input v-model="params.productYear" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w2">
        <span>车型</span>
        <el-input v-model="params.seriesName" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>车身颜色</span>
        <el-input v-model="params.color" size="medium"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt15">
      <!-- <el-col :span="5" class="flex flex_align_center flex_between w4">
        <span>品牌车型</span>
        <el-input v-model="params.modelName" size="medium"></el-input>
      </el-col> -->
      <el-col :span="8" class="flex flex_align_center flex_between w5">
        <span>价格审核时间</span>
         <el-date-picker
          v-model="params.date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-col>
      <el-col :span="2" style="text-align:right;">
        <el-button type="primary" plain @click="init(1)" size="medium">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt20 table-mainbg">
      <el-table-column prop="affRegion" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="enquiryTypeName" label="询价类型" align="center"></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center"></el-table-column>
      <el-table-column prop="productYear" label="年份/车型" align="center">
        <template slot-scope="scope">{{scope.row.productYear}}/{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="checkTime" label="检测时间" align="center"></el-table-column>
      <el-table-column prop="auctionBeginTime" label="竞拍开始时间" align="center"></el-table-column>
      <el-table-column prop="auctionEndTime" label="竞拍结束时间" align="center"></el-table-column>
      <el-table-column prop="tradePrice" label="成交价" align="center"></el-table-column>
      <el-table-column prop="tradeTypeName" label="成交类型" align="center"></el-table-column>
      <el-table-column prop="auctionPriceAuditTime" label="价格审核时间" align="center"></el-table-column>
      <el-table-column prop="tradedPriceExpire" label="报价到期日" align="center"></el-table-column>
      <el-table-column prop="receiveSmsPhone" label="接收号码" align="center" >
      </el-table-column>
      <el-table-column label="操作" width="240px" align="center">
        <template slot-scope="scope">
          <div class="buttonBox">
            <el-button type="primary" size="mini" @click="onRow(scope.row,1)">重发密码短信</el-button>
            <el-button type="primary" size="mini" @click="onRow(scope.row,2)">重发结果短信</el-button>
            <el-button type="primary" size="mini" @click="onRow(scope.row,3)">更换短信接收号码</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <!-- <el-dialog :title="`${itemState==1?'暂时不拍':'终止竞拍'}`" :visible.sync="visible">
      <el-form :model="form2">
        <el-form-item :label="`${itemState==1?'暂时不拍原因':'终止原因'}`" label-width="120px">
          <el-input v-model="form2.remark" type="textarea" :autosize="{ minRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submitModal">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        productYear: '',
        carNo: '',
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      itemRow:{},
      // brandlist:[],//获取所有品牌车系
      // brandProps:{
      //   value:'id',
      //   label:'name',
      //   children:'carSeriesList',
      // },
      visible:false,
      itemState:'',
    }
  },
  created(){
    this.init()
    //车辆品牌 列表查询
    if(this.$store.state.setting.carBrandList.length==0){
      this.$datadic.getCarBrand().then(res=>{
        this.brandlist=res;
      })
    }else{
      this.brandlist=this.$store.state.setting.carBrandList;
    }
  },
  methods: {
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.init()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.init()
    },
    init(page){
      this.page_index=page?page:this.page_index;
      //车辆竞拍状态：201待竞拍，202竞拍中，203延期竞拍，204暂时不拍，205竞拍终止，206竞拍结束
      let query={
        curPage:this.page_index,
        pageSize:this.page_size,
        // auctionPriceAuditStatus:402,//竞拍价格审核状态：401待审核，402审核通过，403审核失败（入库重拍类型重拍），404流拍
        carNo:this.params.carNo,
        productYear:this.params.productYear,
        carType:this.params.seriesName,
        auctionPriceAuditStartTime:this.params.date?this.params.date[0]:'',
        auctionPriceAuditEndTime:this.params.date?this.params.date[1]:'',
      }
      this.$api.postCarFollowUpPage(this.$$.delete_null_properties(query)).then(res=>{
        if(res.code==0){
          this.querylist=res.data.records;
          this.page_total=res.data.total;
        }else{
          this.querylist=[];
          this.page_total=0;
        }
      })
    },
    onRow(row,state){
      this.itemRow=JSON.parse(JSON.stringify(row))
      this.visible=true;
      this.itemState=state;
    },
    submitModal(){//按钮操作
      switch(this.itemState){//1暂时不拍,2终止竞拍
        case 1:
          this.postOperaStatus(3)
          break;
        case 2:
          this.postOperaStatus(5)
          break;
      }
    },
    postOperaStatus(operaType){//进行 竞拍状态 操作
      let query={
        // carId:this.itemRow.id,
        remark:this.form2.remark,
        auditStatus:operaType
      }
      this.$api.postAuditReport(this.itemRow.checkReportId,query).then(res=>{
        if(res.code==0){
          this.visible=!this.visible;
          this.$message.success(res.message)
          this.init(1)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
#app .el-button+.el-button{
  margin:5px;
}
.auctionManage{
  padding: 40px;
}
</style>