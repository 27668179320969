<template>
  <div class="invitationCertBox">
    <el-tabs v-model="activeName" type="card" @tab-click="init(1)">
      <el-tab-pane label="待邀约" name="first"></el-tab-pane>
      <el-tab-pane label="跟进中" name="second"></el-tab-pane>
      <el-tab-pane label="邀约成功" name="third"></el-tab-pane>
    </el-tabs>
    <el-row :gutter="24" class="mt20" >
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :span="5" class="flex flex_align_center flex_between w3">
        <span>商户姓名:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="10" class="flex flex_align_center flex_between w5" v-show="activeName=='first'">
        <span>办证截止日期:</span>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd" size="medium"> </el-date-picker>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" size="medium" @click="init(1)" plain>搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column
        prop="tradeShopName"
        label="成交门店" 
        align="center" 
      ></el-table-column>
      <el-table-column prop="carNo" label="车牌号码" align="center" ></el-table-column>
      <el-table-column prop="makerName" label="品牌车型" align="center" >
        <template slot-scope="scope"
          >{{ scope.row.car.makerName }}&nbsp;{{ scope.row.car.seriesName }}</template
        >
      </el-table-column>
     
      <el-table-column
        prop="merchantName" 
        label="商户姓名"
        align="center"
      >
       <!-- <template slot-scope="scope">{{scope.row.car.carMerchant?scope.row.car.carMerchant.merchantType==1?scope.row.car.carMerchant.authCompanyName:scope.row.car.carMerchant.merchantName:scope.row.merchantName}}</template> -->
      </el-table-column>
      <el-table-column prop="merchantDeadline" label="商户办证截止日期" align="center" ></el-table-column>
      <el-table-column
        prop="certCompany"
        label="办证公司" 
        align="center"
      ></el-table-column>
       <el-table-column
        prop="customerName"
        label="客户姓名" 
        align="center"
      ></el-table-column>
      
      <el-table-column prop="extensionerName" label="渠道拓展专员" align="center" >
        <template slot-scope="scope">{{scope.row.car.channel?scope.row.car.channel.extensionerName:scope.row.extensionerName}}</template>
      </el-table-column>
      <el-table-column prop="shortName" label="4S店简称" align="center" >
        <template slot-scope="scope">{{scope.row.car.channel?scope.row.car.channel.shortName:scope.row.shortName}}</template>
      </el-table-column>
      <el-table-column prop="nextInviteTime" label="下次跟进时间" align="center" v-if="['second'].includes(activeName)" ></el-table-column>
      <el-table-column prop="inviteSuccessTime" label="邀约成功时间" align="center" v-if="['third'].includes(activeName)" ></el-table-column>
      <!-- <el-table-column
        prop="tradeShopName"
        label="成交门店" key="8" v-if="!['first'].includes(activeName)"
        align="center"
      ></el-table-column> -->
      <!-- <el-table-column
        prop="sellerName"
        label="帮卖经理" key="9"
        align="center" v-if="['first'].includes(activeName)"
      ></el-table-column> -->
      <!-- <el-table-column
        prop="sellerAssistantName"
        label="销售助理" key="10" v-if="!['first'].includes(activeName)"
        align="center"
      ></el-table-column> -->
      <!-- <el-table-column prop="inviteOperator" label="上次跟进人" align="center" v-if="['second'].includes(activeName)" key="11"></el-table-column>
      <el-table-column prop="inviteOperator" label="跟进人" align="center" v-if="['third'].includes(activeName)" key="12"></el-table-column>
      <el-table-column prop="inviteSuccessTime" label="邀约成功提交时间" align="center" v-if="['third'].includes(activeName)" key="13"></el-table-column>
      <el-table-column
        prop="certApplyTime"
        label="商户申请办证时间" key="14"
        align="center" v-if="['first'].includes(activeName)"
      ></el-table-column>
      <el-table-column
        prop="merchantDeadline"
        label="商户办证截止日期" key="15"
        align="center" v-if="['first'].includes(activeName)"
      ></el-table-column> -->
      <el-table-column label="操作" align="center" key="16">
        <template slot-scope="scope">
          <el-button
            type="primary"  v-if="['first'].includes(activeName)"
            @click="onapply(scope.row, 1)"
            size="mini">邀约办证</el-button>
           <el-button
            type="primary" v-if="['second'].includes(activeName)"
            @click="onapply(scope.row, 2)"
            size="mini">跟进邀约</el-button>
           <el-button
            type="primary" v-if="['third'].includes(activeName)"
            @click="onapply(scope.row, 3)"
            size="mini">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      params: {},
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
    };
  },
  created() {
    if(this.$route.params.state){
      this.activeName=this.$route.params.state;
    }

    this.init();
  },
  methods: {
    init(page) {
      this.currentPage=page?page:this.currentPage;
      let query = {
        curPage: this.currentPage, //当前页数
        pageSize: this.pageSize,
        taskType: 2,//任务类型：1办证申请，2办证邀约，3办证过程管理，4沪牌查验，5凭证费用审核，6违章处理，7车辆手续补办
        carNo:this.params.carNo,
        merchantName:this.params.merchantName,
      };
      switch (this.activeName) {
        case "first":
          // query.merchantName=this.params.merchantName;
          query.taskInviteStatuses=[31];//待邀约
          // query.carNo=this.params.carNo,
          query.merchantDeadlineBegin=this.params.date?`${this.params.date[0]}`:'';
          query.merchantDeadlineEnd=this.params.date?`${this.params.date[1]}`:'';
          break;
        case "second":
          query.taskInviteStatuses=[32];//跟进中
          break;
        case "third":
          query.taskInviteStatuses=[33];//邀约成功
          break;
      }
      this.$api.getCertificateTaskPage(this.$$.delete_null_properties(query)).then((res) => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          } else {
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onapply(row) {
      let aurl = "/accreditationCommissioner/certificateInvitation";
      this.$router.push({
        path: aurl,
        query: {
          id: row.id,
        },
      });
    },
    handleSizeChange(data) {
      this.pageSize = data;
      this.currentPage=1;
      this.init();
    },
    handleCurrentChange(data) {
      this.currentPage = data;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.invitationCertBox {
  padding:20px 40px;
  .searchRowBox {
    margin: 30px 0;
  }
}
</style>