<template>
  <div class="certificateBoxBG">
    <div class="certificateBox">
      <!--基本信息-->
      <div class="content_modal_textBox">
        <div class="head_top_title">
          <i></i>
          <span>基本信息</span>
        </div>
        <el-row>
          <el-col :span="6">车牌号：{{form.carNo}}</el-col>
          <el-col :span="6">办证类型：{{form.certTypeName}}</el-col>
          <el-col :span="6">品牌车型：{{form.makerName}}&nbsp;{{form.seriesName}}</el-col>
          <el-col :span="6">业务归属：{{form.businessOwnArea}}</el-col>
        </el-row>
        <!-- <div class="content_title_Box evidenDetail_titleOne">
          <div class="content_title_row">
            <label>车牌号：</label>
            <span>{{form.carNo}}</span>
          </div>
          <div class="content_title_row">
            <label>办证类型：</label>
            <span>{{form.certTypeName}}</span>
          </div>
          <div class="content_title_row">
            <label>品牌车型：</label>
            <span>{{form.brandName}}&nbsp;{{form.modelName}}</span>
          </div>
          <div class="content_title_row">
            <label>业务归属：</label>
            <span>{{form.businessOwnArea}}</span>
          </div>
          <div class="content_title_row">
            <label>车辆所在库：</label>
            <span>{{res.stockObjInName}}</span>
          </div>
          <div class="content_title_row">
            <label>违章处理方：</label>
            <span>{{form.breakRuleDispose}}</span>
          </div>
        </div> -->
      </div>
      <!--办证信息-客户方-->
      <el-row class="info_content_box mt20" :gutter="24">
        <el-col  :lg="12" :md="24">
          <div class="head_top_title flex_space">
            <div class="alignCenter">
              <i></i>
              <span>办证信息-客户方</span>
            </div>
            <span class="seeColor" @click="onrouter(0)">客户合同查看>></span>
          </div>
          <el-row class="mb10">
            <el-col :span="12">
                <div class="content_title_row">
                  <label>客户姓名：</label>
                  <span>{{form.customerName}}</span>
                </div>
            </el-col>
            <el-col :span="12">
              <div class="content_title_row">
                <label>客户电话：</label>
                <span>{{form.customerMobile}}</span>
                <el-button
                  type="primary"
                  class="ml30"
                  @click="oncalll(form.customerMobile,1)"
                  size="mini"
                  plain
                  v-show="form.customerMobile"
                >呼叫</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row class="mb10">
            <el-col :span="12">
              <div class="content_title_row">
                <label>帮卖经理：</label>
                <span>{{customerContract.sellerName}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="content_title_row">
                <label>客户电话：</label>
                <span>{{form.carCustomer.customerPhone}}</span>
                <el-button
                  type="primary"
                  class="ml30"
                  @click="oncalll(form.carCustomer.customerPhone,2)"
                  size="mini"
                  plain
                  v-show="form.carCustomer.customerPhone"
                >呼叫</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row class="mb10">
            <el-col :span="12">
                <div class="content_title_row">
                <label>客户办证截止日期：</label>
                <span>{{customerContract.customerDeadline}}</span>
              </div>
            </el-col>
            <el-col :span="12">
                <div class="content_title_row">
                <label>成交门店：</label>
                <span class="content_title_rightSpan">{{customerContract.tradeShopName}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="mb10">
            <el-col :span="12">
              <div class="content_title_row">
                <label>身份证号：</label>
                <span>{{form.customerIdNo}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="content_title_row">
                <label>客户合同签订日期：</label>
                <span class="content_title_rightSpan">{{customerContract.signTime}}</span>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="12" :md="24">
          <div class="head_top_title flex_space">
            <div class="alignCenter">
              <i></i>
              <span>办证信息-商户方</span>
            </div>
            <span class="seeColor" @click="onrouter(1)">商户合同查看>></span>
          </div>
          <el-row class="mb10">
            <el-col :span="12">
              <div class="content_title_row">
              <label>商户姓名：</label>
              <span>{{carMerchant.merchantType==1?carMerchant.authCompanyName:carMerchant.merchantName}}</span>
            </div>
            </el-col>
            <el-col :span="12">
              <div class="content_title_row">
                <label>商户电话：</label>
                <span>{{carMerchant.merchantMobile}}</span>
                <el-button
                  type="primary"
                  class="ml30"
                  @click="oncalll(carMerchant.merchantMobile)"
                  size="mini"
                  plain
                  v-show="carMerchant.merchantMobile"
                >呼叫</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row class="mb10">
            <el-col :span="12">
              <div class="content_title_row">
                <label>商户维护人员：</label>
                <span>{{carMerchant.maintainerName}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="content_title_row">
                <label>商户电话：</label>
                <span>{{carMerchant.merchantOtherMobile}}</span>
                <el-button
                  type="primary"
                  class="ml30"
                  @click="oncalll(carMerchant.merchantOtherMobile)"
                  size="mini"
                  plain
                  v-show="carMerchant.merchantOtherMobile"
                >呼叫</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row class="mb10">
            <el-col :span="12">
              <div class="content_title_row w100">
                <label>商户办证截止日期：</label>
                <span>{{res.merchantDeadline}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="mb10">
            <el-col :span="12">
              <div class="content_title_row w100">
                <label>经营城市：</label>
                <span>{{carMerchant.businessCityName}}</span>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!--查验单-->
      <div class="content_modal_textBox mt20">
        <div class="head_top_title">
          <i></i>
          <span>查验单</span>
        </div>
        <div class="photo_content_box">
          <div class="photobox_row">
            <div class="photo_row_imgBox">
              <el-image class="imgCheck"
                :src="res.checkImgUrl"
                :preview-src-list="srcList"
                :fit="'contain'"
              >
                <div slot="error">暂无图片</div>
              </el-image>
            </div>
          </div>
        </div>
      </div>
      <!--跟进记录-->
      <div class="content_modal_textBox pb100">
        <div class="head_top_title">
          <i></i>
          <span>跟进记录</span>
        </div>
        <el-table :data="certInviteTraceList" border>
          <el-table-column prop="operateTime" label="操作时间" align="center"></el-table-column>
          <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
          <el-table-column prop="nextInviteTime" label="下次跟进时间" align="center"></el-table-column>
          <el-table-column prop="certInviteStatusName" label="跟进结果" align="center"></el-table-column>
          <el-table-column prop="description" label="结果描述" align="center"></el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <div class="btn">
          <el-button type="primary" @click="onprint" v-print="'#printBox'">打印查验单</el-button>
          <el-button type="primary" @click="doSubmit" v-if="res.taskPlateStatus==51">确认查验</el-button>
        </div>
      </div>
      <el-dialog title="二次确认" :visible.sync="dialogVisible" width="30%">
        <span>您确认查验已完成吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="oncomplete()">提交</el-button>
        </span>
      </el-dialog>
    </div>
    <div id="printBox" v-if="printVisible">
      <img class="iframeImg" :src="res.checkImgUrl" />
    </div>
  </div>
</template>
<script>
import print from "vue-print-nb";
export default {
  directives: {
    print
  },
  data() {
    return {
      stateType: 0,
      dialogVisible: false,
      printVisible: false,
      imglist: [0],
      srcList: [], //图片放大
      certInviteTraceList: [], //跟进记录列表
      form: {
        carCustomer: {}, //	车辆客户信息
        carMerchant: {} //车辆商户信息
      }, //展示数据
      taskId: "",
      res:{},
      carMerchant:{},//商户表
      customerContract:{},
      loginType:false,//软电话是否登陆
      userData:{},
      cno:'',//坐席号
    };
  },
  created() {
    //软电话是否登陆
    this.loginType=this.$store.state.user.softloginType;
    this.userData=this.$store.state.user.userData;
    if(this.$route.query.id) this.taskId = this.$route.query.id;
    this.getCertificateTaskDetails(); //获取详情
  },
  methods: {
    oncalll: function(row,phoneType) {
      // this.getCallDetailByCno().then(()=>{
        this.postCallout(row,phoneType)
      // })//根据员工工号查询坐席信息(拨打电话)
      // if(!this.loginType){
      //   this.$message.warning('请先登录软电话！')
      //   return
      // }
      
    },
    postCallout(customerNumber,phoneType){//外呼
      let query={
        // cno:this.cno,
        cno:this.userData.no,
        customerNumber:customerNumber,
      }
      if(phoneType){
        query.customerId=this.form.carCustomer.id;
        query.phoneType=phoneType;
      }
      this.$api.postCallout(query).then(res=>{
        if(res.code==0){
          this.$message.success(res.message)
        }
      })
    },
    getCallDetailByCno(){//根据员工工号查询坐席信息
      return new Promise((resolve, reject) => {
        let query={
          cno:this.userData.no
        }
        this.$api.getCallDetailByCno(query).then(res=>{
          if(res.code==0){
            if(res.data){
              let resJson=JSON.parse(res.data)
              this.cno=resJson.cno;
              resolve(true)
            }
          }else{
            reject(false)
          }
        })
      }).catch(() =>{
        return Promise.reject(false);
      })
    },
    oncomplete() {
      let query={
        taskId:this.taskId,
        taskPlateCheckResult:'plate_check_confirm'
      }
      this.$api.postTaskOperaPlate(query).then((res)=>{
        if(res){
          if(res.code==0){
            this.ondialogVisible();
            this.getCertificateTaskDetails(); //获取详情
            // this.onrouterPage();
          }
        }
      })
    },
    ondialogVisible() {
      this.dialogVisible = !this.dialogVisible;
    },
    doSubmit() {
      this.$confirm('您确认查验已完成吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          taskId:this.taskId,
          taskPlateCheckResult:'plate_check_confirm'
        }
        this.$api.postTaskOperaPlate(query).then((res)=>{
          if(res){
            if(res.code==0){
              // this.ondialogVisible();
              this.getCertificateTaskDetails(); //获取详情
            }
          }
        })
      }).catch(() => {});
    },
    onprint() {
      //打印查验单
      this.printVisible = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.printVisible = false;
          // this.imglist=[0,2]
        }, 1000);
      });
    },
    onrouterPage() {
      this.bus.$emit("closeSelected");
      this.$router.push({
        path: "/accreditationCommissioner/brandCheck"
      });
    },
    onrouter(state) {
      let aurl = "/salesManager/contractMaking";
      let id=this.form.customerContract?this.form.customerContract.id:'';//客户合同
      if (state) {
        aurl = "/salesAssistant/merchantContract";
        id=this.form.merchantContract?this.form.merchantContract.id:'';//商户合同
      }
      if(!id){
        this.$message('暂无合同！')
        return
      }
      this.$router.push({
        path: aurl,
        query:{
          id:id,
          state:0
        }
      });
    },
    getCertificateTaskDetails() {
      //获取详情
      this.$api.getCertificateTaskDetails(this.taskId).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = res.data;
            this.form = JSON.parse(JSON.stringify(res.data.car));
            if(res.data.car){
              if(res.data.car.carMerchant) this.carMerchant=res.data.car.carMerchant;//商户表
              if(res.data.car.customerContract) this.customerContract=res.data.car.customerContract;//客户合同
            }
            
            if (!res.data.car.carCustomer) {
              this.form.carCustomer = {};
            }
            if (!res.data.car.carMerchant) {
              this.form.carMerchant = {};
            }
            //跟进记录列表
            this.certInviteTraceList=res.data.certInviteTraceList;
            this.srcList = [this.res.checkImgUrl];
          }
        }
      });
    }
  }
};
</script>
<style >
@media print {
  /*去除页眉页脚*/
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}
/*去除页眉页脚*/
}
.el-textarea__inner {
  height: 100px;
}
.imgCheck img{
  max-height: 195px;
}
</style>
<style lang="scss" scoped>
.certificateBoxBG {
  background: #f5f5f5;
  padding-top: 20px;
}
.certificateBox {
  background-color: #ffffff;
  padding: 30px 50px;
  .evidenDetail_titleOne {
    // margin-top: 20px;
    .content_title_row {
      // flex: 1;
      margin-right: 100px;
      margin-bottom: 10px;
    }
    .content_title_row:last-child {
      margin-right: 0;
    }
  }
  .head_top_title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    i {
      display: inline-block;
      width: 6px;
      height: 20px;
      background: #0099ff;
      border-radius: 5px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  .content_title_Box {
    display: flex;
    flex-wrap: wrap;
    .content_title_row {
      // flex: 1;
      label {
        font-size: 16px;
      }
      span {
        font-size: 16px;
      }
    }
    .content_title_row:nth-child(even) {
      text-align: right;
      span.content_title_rightSpan {
        display: inline-block;
        text-align: left;
        width: 215px;
      }
    }
  }
  .flex_space {
    display: flex;
    justify-content: space-between;
  }
  .seeColor {
    color: #0099ff;
    cursor: pointer;
  }
  .seeColor:hover {
    color: rgba(0, 153, 255, 0.8);
  }
  .w50 {
    width: 50%;
  }
  .w90 {
    width: 90%;
  }
  .w100 {
    width: 100%;
  }
  .w300 {
    width: 300px;
  }
  //跟进结果
  .conten_followupBox {
    display: flex;

    .conten_followup_left {
      // width: 35%;
      .content_follow_formBox {
        .content_followRow {
          margin-bottom: 20px;
          display: flex;
          //  align-items: center;
          .follow_label {
            font-size: 16px;
            margin-right: 20px;
            width: 105px;
            text-align: right;
            margin-top: 7px;
          }
          .textarea_follow {
            width: 300px;
          }
        }
      }
    }
  }
  .InviteText_color {
    color: #ff0000;
  }
  .formRadiogrop_row {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .photo_content_box {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    .photobox_row {
      width: 334px;
      margin: 0 16px;
      .photo_row_imgBox {
        height: 195px;
        background: #f5f5f5;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .photo_row_imgtiltle {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(51, 51, 51, 0.5);
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          line-height: 30px;
        }
      }
      .photo_row_textBox {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .photobox_row:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .certificateBox .content_title_Box .content_title_row:nth-child(even) {
    text-align: left;
  }
  .certificateBox
    .content_title_Box
    .content_title_row:nth-child(even)
    span.content_title_rightSpan {
    width: auto;
  }
}

</style>
