<template>
  <!-- 商户预约 -->
  <div class="merchantReservation">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="待预约" name="first" class="mt20"></el-tab-pane>
      <el-tab-pane label="跟进中" name="second" class="mt20"></el-tab-pane>
      <el-tab-pane label="沟通中" name="third" class="mt20"></el-tab-pane>
      <el-tab-pane label="预约成功" name="fourth" class="mt20"></el-tab-pane>
      <el-tab-pane label="预约失败" name="fifth" class="mt20"></el-tab-pane>
    </el-tabs>

    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>商户名称:</span>
        <el-input v-model="params.merchantName" size="medium"></el-input>
      </el-col>
      <!-- <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>商户编号:</span>
        <el-input v-model="params.merchantNo" size="medium"></el-input>
      </el-col> -->
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>车牌号码:</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>品牌车型:</span>
        <!-- <el-cascader
          v-model="params.carType" size="medium"
          placeholder="请选择"
          :options="optionsCar"
          :props="propCar"
          filterable></el-cascader> -->
        <el-input v-model="params.carType" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>成交门店:</span>
        <el-select v-model="params.bookShopId" size="medium" clearable>
          <el-option v-for="item in shoplist" :key="item.id" :label="item.shopName" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>成交类型:</span>
        <el-select v-model="params.dealType" size="medium" placeholder="请选择" clearable>
          <el-option label="垫资成交" value="0"></el-option>
          <el-option label="预约成交" value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w4 mb10" >
        <span>预约时间:</span>
        <el-date-picker v-model="params.bookTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <!-- <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w6 mb10" v-if="['second'].includes(activeName)">
        <span>下次跟进时间:</span>
        <el-date-picker v-model="params.followUpTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col>
      <el-col :xl="6" :lg="8" class="flex flex_align_center flex_between w4 mb10" v-if="['fifth'].includes(activeName)">
        <span>失败时间:</span>
        <el-date-picker v-model="params.failTime" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="$store.state.setting.pickerOptions" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" size="medium"> </el-date-picker>
      </el-col> -->
      <el-col :xl="2" :lg="2"  class="mb10">
        <el-button type="primary" size="medium" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="tableData" border stripe class="mt10 table-mainbg" :cell-class-name="cellName">
      <el-table-column prop="bookShop" align="center" label="成交门店" ></el-table-column>
      <el-table-column prop="carNo" align="center" label="车牌号码" ></el-table-column>
      <el-table-column prop="productYear" align="center" label="年份/车系" >
        <template slot-scope="scope">{{scope.row.productYear}}&nbsp;{{scope.row.seriesName}}</template>
      </el-table-column>
      <el-table-column prop="dealType" label="成交类型" align="center" :formatter="formatDealType"></el-table-column>
      <el-table-column prop="merchantConfirmPrice" label="成交价格" align="center"  ></el-table-column>
      <el-table-column prop="merchantName" align="center" label="商户名称" ></el-table-column>
      <el-table-column prop="merchantMobile" align="center" label="商户电话" ></el-table-column>
      <el-table-column prop="merchantOrder" align="center" label="商户名次" ></el-table-column>
      <el-table-column prop="bookTime" align="center" label="预约时间" ></el-table-column>
       <el-table-column prop="customerStatusName" label="客户意愿" align="center"  ></el-table-column>
       <el-table-column label="是否沟通" align="center"  prop="isComm" >
        <template slot-scope="scope">{{scope.row.isComm===1?'是':scope.row.isComm===0?'否':scope.row.isComm}}</template>
      </el-table-column>
      <el-table-column prop="customerRemark" label="客户备注" align="center" ></el-table-column>
      <el-table-column prop="checkTypeName" align="center" label="询价类型" >
        <template slot-scope="scope">{{scope.row.checkTypeName==='寄售'?'一口价':scope.row.checkTypeName}}</template>
      </el-table-column>
     
      <el-table-column prop="operatorName" align="center" label="操作人" v-if="['second','third','fourth','fifth'].includes(activeName)"></el-table-column>
      <el-table-column label="操作" align="center" :width="['fourth'].includes(activeName)?'200px':'120px'" key="17">
        <template slot-scope="scope">
          <!-- customerDefaultStatus客户违约状态 merchantDefaultStatus商户违约状态-->
          <!--  两者都为-1时不显示 merchantDefaultStatus为3-申请驳回（申请未通过）1-已提交申请（申请查看） -->
          <!-- 0-可提交申请（补偿申请）customerDefaultStatus客户违约状态也为1时违约确认 -->
          <el-button size="mini" type="primary" plain @click="$router.push({path: '/salesAssistant/reservationDetail', query: {id: scope.row.id}})" v-if="['first','second','third'].includes(activeName)">预约</el-button>
          <el-button size="mini" type="primary" plain @click="compensate(scope.row,1)" v-if="['fourth'].includes(activeName)&&scope.row.customerDefaultStatus==0&&scope.row.merchantDefaultStatus!=1&&scope.row.dealType===1">补偿申请</el-button>
          <el-button size="mini" type="primary" plain @click="compensate(scope.row,2)" v-if="['fourth'].includes(activeName)&&scope.row.customerDefaultStatus==1&&scope.row.merchantDefaultStatus!=1&&scope.row.dealType===1">申请查看</el-button>
          <el-button size="mini" type="danger" plain @click="compensate(scope.row,3)" v-if="['fourth'].includes(activeName)&&scope.row.customerDefaultStatus==3&&scope.row.merchantDefaultStatus!=1&&scope.row.dealType===1">申请未通过</el-button>
          <el-button size="mini" type="primary" plain @click="compensate(scope.row,4)" v-if="['fourth'].includes(activeName)&&scope.row.customerDefaultStatus==2&&scope.row.merchantDefaultStatus!=1&&scope.row.dealType===1">申请通过</el-button>
          <el-button size="mini" type="primary" plain @click="compensate(scope.row,5)" v-if="['fourth'].includes(activeName)&&scope.row.merchantDefaultStatus==1&&scope.row.dealType===1">违约确认</el-button>
          <el-button size="mini" type="primary" plain @click="$router.push({path: '/salesAssistant/reservationDetail', query: {id: scope.row.id,state:1}})" v-if="['fourth','fifth'].includes(activeName)">查看</el-button> 
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
    

    <el-dialog :title="titleModal" :visible.sync="applyCompensationVisible" center class="applyCompensation customDialog">
      <div class="mt20">
        <span class="label">{{[1,2,3,4].includes(compensateState)?'客户':'商户'}}预约成交时间：</span><span>{{compensateForm.bookTime}}</span>
      </div>
      <div class="mt20">
        <span class="label">{{[1,2,3,4].includes(compensateState)?'客户':'商户'}}实际到店时间：</span>
        <el-date-picker v-model="compensateForm.arrivalTime" type="datetime" placeholder="选择日期时间" :disabled="[1,3].includes(compensateState)?false:true" class="w300" @change="changeDate" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </div>
      <div class="mt20" v-if="[1,2,4].includes(compensateState)"><span class="label">{{[1,2,4].includes(compensateState)?'客户':'商户'}}迟到时长：</span><span>{{compensateForm.lateTime}}分钟</span></div>
      <div class="mt20">
        <span class="label">{{[5].includes(compensateState)?'商户迟到违约金额：':'补偿商户金额：'}}</span><span v-if="!updateFlag">{{compensateForm.compAmount}}元</span>
        <el-input v-model="compensateForm.compAmount" v-if="updateFlag&&[1,3].includes(compensateState)" class="w300" ></el-input>
        <el-button @click="onAmountBut" size="mini" class="ml30" type="primary" v-if="[1,3].includes(compensateState)" plain>修改</el-button>
      </div>
      <div class="mt20" v-if="[5].includes(compensateState)">
        <span class="label">客户放弃：</span>
        <span>{{compensateForm.isGiveUp===1?'客户放弃成交':compensateForm.isGiveUp===0?'客户继续等':compensateForm.isGiveUp}}</span>
      </div>
      <div class="mt20 flex" v-if="updateFlag&&[1,2,3,4].includes(compensateState)||compensateForm.updateAmountReason">
        <span class="label">修改金额原因：</span>
        <el-input type="textarea" v-model="compensateForm.updateAmountReason" class="w300" :disabled="compensateForm.state"></el-input>
      </div>
      <div class="mt20" v-if="[3,5].includes(compensateState)&&compensateForm.rejectReason">
        <span class="label">驳回原因：</span>
        <span >{{compensateForm.rejectReason}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applyCompensationVisible = false">取消</el-button>
        <el-button type="primary" @click="onReject" v-if="itemRow.merchantDefaultStatus==1">驳回</el-button>
        <el-button type="primary" @click="doSubmit(0)" v-if="itemRow.customerDefaultStatus==1">撤销申请</el-button>
        <el-button type="primary" @click="doSubmit(2)" v-if="itemRow.merchantDefaultStatus==1">审核通过</el-button>
        <el-button type="primary" @click="doSubmit(1)" v-if="itemRow.merchantDefaultStatus!=1&&[0,3].includes(itemRow.customerDefaultStatus)">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
    title="提示"
    :visible.sync="dialogVisibleCar" width="350px">
    <p class="mb15" >驳回原因：</p>
    <el-input v-model="formCar" class="w300"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmitCar">确定</el-button>
      <el-button @click="dialogVisibleCar = false">取 消</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibleCar:false,
      formCar:'',
      activeName: 'first',
      params: {
        customerName: '',
        customerNo: '',
        carNo: '',
        carType: '',
        bookTime: '',
        followUpTime: '',
        failTime: '',
        bookShop:'',
      },
      optionsCar:[],//品牌车型
      propCar:{
        label:'name',
        value:'id',
        children:'carSeriesList'
      },
      tableData: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      applyCompensationVisible: false,
      timeDate2: '',
      compensateForm: {
        bookTime: null,
        arrivalTime: null,
        compAmount: '-',
        lateTime: '-',
      },
      itemRow:{},
      titleModal:'商户补偿金申请',
      updateFlag:false,
      compensateState:'',//点击按钮操作
      shoplist:[],//门店列表
    }
  },
  created() {
    if(this.$route.params.state) this.activeName=this.$route.params.state;
    this.handleClick();
    this.getShopList()//查询成交门店//办证要求
    // //车辆品牌 列表查询(暂时不用)
    // if(this.$store.state.setting.carBrandList.length==0){
    //   this.$datadic.getCarBrand().then(res=>{
    //     this.optionsCar=res;
    //   })
    // }else{
    //   this.optionsCar=this.$store.state.setting.carBrandList;
    // }
  },
  methods: {
    cellName({row}){
      if(!row.checkTypeName){
        return
      }
      if(['寄售','一口价'].includes(row.checkTypeName)||row.checkTypeName.indexOf('入库重拍')!=-1){
        return 'tipsColor'
      }
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let query={
        curPage: this.page_index,
        pageSize: this.page_size, 
      }
       query.bookStartTime=this.params.bookTime?this.params.bookTime[0]:'';
      query.bookEndTime=this.params.bookTime?this.params.bookTime[1]:'';
      query.merchantName=this.params.merchantName;
      query.carNo=this.params.carNo;
      query.carType=this.params.carType;
      query.dealType=this.params.dealType;
      query.bookShopId=this.params.bookShopId;
      // switch(this.activeName){
      //   case 'first':
      //     query.bookStartTime=this.params.bookTime?this.params.bookTime[0]:'';
      //     query.bookEndTime=this.params.bookTime?this.params.bookTime[1]:'';
      //     query.merchantName=this.params.merchantName;
      //     query.carNo=this.params.carNo;
      //     query.carType=this.params.carType;
      //     query.dealType=this.params.dealType;
      //     break;
      //   case 'second':
      //    query.followUpStartTime=this.params.followUpTime?this.params.followUpTime[0]:'';
      //    query.followUpEndTime=this.params.followUpTime?this.params.followUpTime[1]:'';
      //    query.merchantNo=this.params.merchantNo;
      //    query.carNo=this.params.carNo;
      //     break;
      //   case 'fourth':
      //     query.bookStartTime=this.params.bookTime?this.params.bookTime[0]:'';
      //     query.bookEndTime=this.params.bookTime?this.params.bookTime[1]:'';
      //     query.merchantNo=this.params.merchantNo;
      //    query.carNo=this.params.carNo;
      //     break;
      //   case 'fifth':
      //     query.failStartTime= this.params.failTime?this.params.failTime[0]:'';
      //     query.failEndTime= this.params.failTime?this.params.failTime[1]:'';
      //     query.merchantNo=this.params.merchantNo;
      //    query.carNo=this.params.carNo;
      //     break;
      // }
      let params=this.$$.delete_null_properties(query)
      switch(this.activeName){
        case 'first':
          this.merchantQueryNoBookList(params);
          break;
        case 'second':
          this.merchantQueryFollowUpList(params);
          break;
        case 'third':
          this.merchantQueryConcernList(params);
          break;
        case 'fourth':
          this.merchantQueryBookSuccessList(params);
          break;
        case 'fifth':
          this.merchantQueryBookFailList(params);
          break;
      }
      
    },
    async merchantQueryNoBookList(params) {
      let res = await this.$api.merchantQueryNoBookList(params);
      this.handleData(res);
    },
    async merchantQueryFollowUpList(params) {
      let res = await this.$api.merchantQueryFollowUpList(params);
      this.handleData(res);
    },
    async merchantQueryConcernList(params) {
      let res = await this.$api.merchantQueryConcernList(params);
      this.handleData(res);
    },
    async merchantQueryBookSuccessList(params) {
      let res = await this.$api.merchantQueryBookSuccessList(params);
      this.handleData(res);
    },
    async merchantQueryBookFailList(params) {
      let res = await this.$api.merchantQueryBookFailList(params);
      this.handleData(res);
    },
    handleData(res) {//处理接口数据
      if(res.code === 0){
        this.tableData = res.data.records;
        this.page_total = res.data.total;
      }else{
        this.tableData = [];
        this.page_total = 0;
      }
    },
    onAmountBut(){
      this.updateFlag=true;
    },
    compensate(row,state) {
      this.compensateState=state;
      this.compensateForm= {
        bookTime: null,
        arrivalTime: null,
        compAmount: '-',
        lateTime: '-',
      }
      this.itemRow=JSON.parse(JSON.stringify(row));
      let defaultParty=0;//只有按钮是违约状态时，这个地方传值才是商户，其他查询传值时传当前操作方（客户，商户）本身的值
      //违约方 0-客户 1-商户
      if(row.merchantDefaultStatus==1){
        defaultParty=1;
        this.titleModal='商户违约确认';
      }else{
        //商户违约状态 -1-不展示操作 0-可提交申请（补偿申请） 1-已提交申请（申请查看） 2-申请通过 3-申请驳回（申请未通过
        switch(row.customerDefaultStatus){
          case 0:
            this.titleModal='商户补偿金申请';
            break;
          case 2:
          case 1:
            this.titleModal='商户补偿金申请查看';
            break;
          case 3:
            this.titleModal='商户补偿金申请驳回';
            break;
        }
      }
    //  if([1,2].includes(row.customerDefaultStatus)||row.merchantDefaultStatus==1){
      if([2,3,4,5].includes(state)){
        this.$api.merchantQueryDefault({
          dealBookId: state==5?row.id:row.dealBookId,
          defaultParty: defaultParty
        }).then(res => {
          if(res.code == 0){
            this.compensateForm = res.data;
            this.compensateForm.lateTime=this.getLateTime(res.data.bookTime, res.data.arrivalTime);
            this.applyCompensationVisible = true;
            this.updateFlag=false;
          }
        })
      }else{
        this.compensateForm.bookTime=row.bookTime;
        this.applyCompensationVisible = true;
        this.updateFlag=false;
      }
    },
    getLateTime(bookTime, arrivalTime) {
      if(!bookTime || !arrivalTime) {
        return ' / ';
      }else{
        // let time = new Date(arrivalTime.replaceAll('-', '/')).getTime() - new Date(bookTime.replaceAll('-', '/')).getTime();
        let aa=arrivalTime.replace(new RegExp('-',"gm"),'/')
        let bb=bookTime.replace(new RegExp('-',"gm"),'/')
        let time = new Date(aa).getTime() - new Date(bb).getTime();
        return Math.ceil(time/1000/60);
      }
    },
    changeDate(e){
      let time=this.getLateTime(this.compensateForm.bookTime, e);
      this.compensateForm.lateTime = time;
      if(this.compensateForm.bookTime){
        if(time<60||time==60){
          this.compensateForm.compAmount=0;
        }else if(61<=time<120){
          this.compensateForm.compAmount=100;
        }else{
          this.compensateForm.compAmount='-'
        }
      }
    },
    doSubmit(state) {
      let de=this.compensateForm.defaultParty;
      if(state==0||!this.compensateForm.defaultParty){
        de=0
      }
      let query={
        id: this.compensateForm.id?this.compensateForm.id:'',//通过查询得到id，补偿申请没有id
        arrivalTime: this.compensateForm.arrivalTime,
        dealBookId: state==1?this.itemRow.dealBookId:this.compensateForm.dealBookId,//列表id
        defaultParty: state==3?1:de,//违约方 0-客户 1-商户
        state: state,//违约状态 1-提交申请 2-申请通过 3-申请驳回
        compAmount:this.compensateForm.compAmount,//补偿金额 单位 元
        rejectReason:this.compensateForm.rejectReason,//驳回原因
        updateAmountReason:this.compensateForm.updateAmountReason,//修改金额原因
        bookTime:this.compensateForm.bookTime,
      }
      this.$api.merchantSubmitDefault(this.$$.delete_null_properties(query)).then( res => {
        if(res.code == 0){
          this.applyCompensationVisible = false;
          if(this.formCar){
            this.dialogVisibleCar=true;
          }
          this.initData()
          this.$message.success(res.message);
        }
      })
    },
    onReject(){
      this.dialogVisibleCar=true;
      this.formCar='';
    },
    onSubmitCar(){//修改
      if(!this.formCar){
        this.$message.warning('请输入驳回原因！')
        return
      }
      this.compensateForm.rejectReason=this.formCar;
      this.doSubmit(3)//提交
    },
    handleClick() {
      this.page_index = 1;
      this.page_size = 10;
      this.initData() ;
    },
    formatDealType(row) {
      if(row.dealType === 0){
        return '垫资成交'
      }else if(row.dealType === 1){
        return '预约成交'
      }else if(row.dealType === 2){
        return '中介成交'
      }
    },
    formatCustomerContractStatus(row) {
      if(row.customerContractStatus === 0){
        return '待签订'
      }else if(row.customerContractStatus === 1){
        return '签订中'
      }if(row.customerContractStatus === 2){
        return '已签订'
      }if(row.customerContractStatus === 3){
        return '签订失败'
      }
    },
    formatIsMortgage(row) {
      ['否', '是'][row.isMortgage]
    },
    getShopList(){//查询成交门店
      let query={
        isEnabled:0,
        shopTypeList:[0,3]
      }
      this.$api.getShopList(query).then(res=>{
        if(res.code==0){
          this.shoplist=res.data;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.merchantReservation{
  padding: 20px 40px;
  .applyCompensation .label{width: 200px;text-align: right;display: inline-block;margin-right: 20px;}
}
</style>