<template>
  <div class="pageContainer bgF5 pageCombox" :style="{'height':pageHeight}">
    <div v-for="(item,index) in infolist" :key="index" class="">
      <div class="title_top_nameBox">{{item.title}}</div>
      <div class="businessCon">
        <ul class="business flex flex_wrap">
          <li class="bLi" v-for="v in item.list" :key="v.id" @click="tagRouter(v)">{{v.name}} </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infolist:[],
      pageHeight:'',
      title:'费用结算'
    };
  },
  created() {
    this.queryCheckTaskGroupByTaskStatus();
  },
  methods: {
    queryCheckTaskGroupByTaskStatus() {
      let arr1= [
            {
              name: '三方平账',
              url:'/finance/report/balanceAccounts'
            },
            {
              name: '三方平账明细',
              url:'/finance/report/balanceAccountsDetailed'
            },
          ]
          let arr2= [
            {
              name: '付商户明细',
              url:'/finance/report/merchantDetails'
            },
            {
              name: '收商户款明细',
              url:'/finance/report/collectMerchantDetails'
            },
          ]
          let arr3= [
            {
              name: '付客户明细',
              url:'/finance/report/customerDetails'
            },
            {
              name: '中间退费明细',
              url:'/finance/report/intermediateRefund'
            },
            {
              name: '支付车款明细',
              url:'/finance/report/carPayment'
            },
          ]
          let arr4= [
            {
              name: '付办证公司明细',
              url:'/finance/report/certificateCompany'
            }
          ]
          let arr5= [
            {
              name: '成交车辆明细',
              url:'/finance/report/dealCar'
            }
          ]
          let arr6= [
            {
              name: '意向金明细表',
              url:'/finance/report/intentionMoney'
            }
          ]
          this.infolist=[{title:"费用结算",list:arr1},{title:"商户报表",list:arr2},{title:"客户报表",list:arr3},{title:"办证报表",list:arr4},{title:"成交报表",list:arr5},{title:"意向金报表",list:arr6}];
          this.$nextTick(() => {
            this.pageHeight=this.$$.workbenchHeight('pageCombox')
          })
      // let query={
      //   taskStatus:'1,3,4,5,8'
      // }
      //状态：1:待审核，2:审核通过，3审核驳回，4审核失败，5待签约，6已签约，7签约失败，8已解约，9佣金待审核，11佣金审核通过，12钱包提现待审核，13钱包提现审核成功，14钱包提现审核失败，15违约赔偿待审核，16违约赔偿审核成功
      // this.$api.queryCheckTaskGroupByTaskStatus(query).then((res) => {
      //   if (res.code == 0) {
          // let newArr=[];
          // let aurl=''
          // for(let i=0;i<res.data.length;i++){
          //   switch(res.data[i].taskStatusName){
          //     case '三方平账'://1
          //       aurl='/channel/stayExamine'
          //       break;
          //     case '三方平账明细':
          //       aurl='/channel/rescind'
          //       break;
          //     default:
          //       aurl=''
          //   }
          //   let obj= {
          //       num: res.data[i].countsOfTaskStatus,
          //       name: res.data[i].taskStatusName,
          //       star: 0,
          //       url:aurl
          //     }
          //   newArr.push(obj)
          // }
          
      //   }
      // });
    },
    tagRouter(row){
      this.$router.push({
        path:row.url
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.businessCon {
    flex: 1;
  }
  .business {
    margin-top: 30px;
    &:nth-child(2) .bLi {
      color: #fe9600;
      box-shadow: 0px 0px 10px 3px rgba(254, 150, 0, 0.2);
    }
    &:nth-child(3) .bLi {
      color: #19bf6d;
      box-shadow: 0px 0px 10px 3px rgba(28, 195, 115, 0.2);
    }
    &:nth-child(4) .bLi {
      color: #FD5B4A;
      box-shadow: 0px 0px 10px 3px rgba(238, 158, 157, 0.2);
    }
    .bLi {
      width: 16.7%;
      cursor: pointer;
      margin: 0 1.6% 35px;
      background: #ffffff;
      color: #0099ff;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 20px;
      box-shadow: 0px 0px 10px 3px rgba(0, 196, 255, 0.2);
    }
  }
</style>